import { SVGProps } from "react";

function ResizeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0,20) scale(0.003906,-0.003906)">
        <path d="M222 4897 c-122 -122 -222 -227 -222 -232 0 -13 4652 -4665 4665 -4665 13 0 455 442 455 455 0 13 -4652 4665 -4665 4665 -6 0 -110 -100 -233 -223z" />
        <path d="M2142 4897 c-122 -122 -222 -227 -222 -232 0 -13 2732 -2745 2745 -2745 13 0 455 442 455 455 0 13 -2732 2745 -2745 2745 -6 0 -110 -100 -233 -223z" />
        <path d="M4057 4892 l-227 -227 418 -418 417 -417 228 228 c125 125 227 232 227 237 0 13 -812 825 -825 825 -6 0 -112 -102 -238 -228z" />
      </g>
    </svg>
  );
}

export default ResizeIcon;
