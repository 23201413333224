import { Trans } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import chatBackground from "../../../assets/img/Chat_Background.png";

function ConversationBlocked() {
  return (
    <div
      className="h-full"
      style={{
        backgroundImage: `url(${chatBackground})`,
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <Trans i18nKey="inbox:conversation-blocked-notice">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                This conversation is blocked
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  By default, we won't show you messages from a blocked
                  conversation. If you wish to see them, you can unblock this
                  conversation.
                </p>
              </div>
            </div>
          </Trans>
        </div>
      </div>
    </div>
  );
}

export default ConversationBlocked;
