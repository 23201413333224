import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GlobeAltIcon, UploadIcon } from "@heroicons/react/outline";
import { getIn, useFormikContext } from "formik";
import { UploadedFile } from "@hilos/types/uploaded_file";
import TextInputField from "src/components/Form/TextInputField";
import Tabs from "src/components/Tabs";
import { BASE_API_URL } from "src/router/router";
import FlowBuilderMediaFileUpload from "./FlowBuilderMediaFileUpload";
import TextInputFieldWithVariables from "./TextInputFieldWithVariables";

type FileSourceType = "URL" | "UPLOAD";

interface FlowBuilderMediaProps {
  name: string;
  path?: string;
  label: string;
  fileUploadFieldName?: string;
  help?: string;
  media?: "IMAGE" | "VIDEO" | "DOCUMENT";
  enableVariables?: boolean;
  currentStepIndex?: number;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  fileSource?: FileSourceType;
  onChangeFileSource?: (fileSource: FileSourceType) => void;
}

const FILE_SOURCES: FileSourceType[] = ["URL", "UPLOAD"];

function FlowBuilderMedia({
  path = "",
  name,
  fileUploadFieldName,
  label,
  help,
  enableVariables,
  currentStepIndex,
  media = "DOCUMENT",
  icon: RenderIcon,
  fileSource,
  onChangeFileSource,
}: FlowBuilderMediaProps) {
  const [t] = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const fileUploadId = useMemo(() => {
    if (fileUploadFieldName) {
      return getIn(values, `${path}.${fileUploadFieldName}`) || null;
    }
    const value = getIn(values, `${path}.${name}`);

    if (
      !value ||
      typeof value !== "string" ||
      !(
        value.startsWith("https://api.hilos.io") ||
        value.startsWith(BASE_API_URL as string)
      )
    ) {
      return null;
    }

    const urlKeys = value.split("/");
    return urlKeys.splice(-1)[0] || null;
  }, [values, path, name, fileUploadFieldName]);

  const handleClearMedia = useCallback(() => {
    setFieldValue(`${path}.${name}`, null);

    if (fileUploadFieldName) {
      setFieldValue(`${path}.${fileUploadFieldName}`, null);
    }
  }, [path, name, fileUploadFieldName, setFieldValue]);

  const handleChangeTab = useCallback(
    (selectedTabIndex: number) => {
      if (fileSource) {
        const nextFileSource = FILE_SOURCES[selectedTabIndex];

        if (nextFileSource !== fileSource) {
          handleClearMedia();

          if (onChangeFileSource) {
            onChangeFileSource(nextFileSource);
          }
        }
      }
    },
    [fileSource, onChangeFileSource, handleClearMedia]
  );

  const handleFileUploaded = useCallback(
    (file: UploadedFile) => {
      if (fileUploadFieldName) {
        setFieldValue(`${path}.${fileUploadFieldName}`, file.id);
      } else {
        setFieldValue(`${path}.${name}`, file.url);
      }
    },
    [path, name, fileUploadFieldName, setFieldValue]
  );

  return (
    <>
      <Tabs
        tabs={[
          {
            label: t("flows:media.from-url", "From URL"),
            icon: GlobeAltIcon,
          },
          {
            label: t("flows:media.upload-file", "Upload File"),
            icon: UploadIcon,
          },
        ]}
        initialSelectedIndex={fileSource === "UPLOAD" ? 1 : 0}
        onChange={handleChangeTab}
      >
        <div className="pt-2">
          {enableVariables ? (
            <TextInputFieldWithVariables
              type="text"
              path={path}
              name={name}
              variableSeparator=""
              currentStepIndex={currentStepIndex}
              label={label}
              help={help}
              icon={RenderIcon}
              placeholder={t("url", "URL")}
            />
          ) : (
            <TextInputField
              type="url"
              name={`${path}.${name}`}
              label={label}
              help={help}
              icon={RenderIcon}
              placeholder={t("url", "URL")}
            />
          )}
        </div>
        <div className="pt-2">
          <FlowBuilderMediaFileUpload
            media={media}
            fileUploadId={fileUploadId}
            onDelete={handleClearMedia}
            onFileUploaded={handleFileUploaded}
          />
        </div>
      </Tabs>
    </>
  );
}

export default FlowBuilderMedia;
