import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CalendarIcon, CheckIcon, XIcon } from "@heroicons/react/outline";
import { TagData } from "@hilos/types/conversation";
import { UserData } from "@hilos/types/hilos";
import { useChannelAvailability } from "src/hooks/useChannel";
import { userToString } from "../../Helpers";
import BroadcastSearchField from "../../components/Form/BroadcastSearchField";
import DatePickerField from "../../components/Form/DatePickerField";
import RadioButtonsField from "../../components/Form/RadioButtonsField";
import SelectorField from "../../components/Form/SelectorField";
import {
  AVAILABLE_FILTER_FIELDS,
  CONVERSATION_STATUS_OPTIONS,
  FILTER_FIELDS_WITHOUT_COMPARISONS,
  MESSAGE_STATUS_OPTIONS,
} from "./InboxMeta";

interface FilterFieldProps {
  name: string;
  members: UserData[];
  contactTags: TagData[];
  conversationTags: TagData[];
  filter?: { field: string; operator: string };
}

function FilterField({
  name,
  filter,
  contactTags,
  conversationTags,
  members,
}: FilterFieldProps) {
  const { t } = useTranslation();
  const { data: channels } = useChannelAvailability();

  const filterOperatorType = useMemo(
    () =>
      (filter &&
        AVAILABLE_FILTER_FIELDS[filter.field]?.operators?.[filter.operator]
          ?.value) ||
      "text",
    [filter]
  );

  if (!filter) {
    return null;
  }

  if (filter.field === "last_message_on") {
    return (
      <DatePickerField
        showTimeInput
        name={`${name}.value`}
        label={t("select-date", "Select a date...")}
        timeInputLabel={t("time", "Time") + ":"}
        dateFormat="MM/dd/yyyy h:mm aa"
        icon={CalendarIcon}
      />
    );
  }

  if (
    filterOperatorType === "bool" ||
    FILTER_FIELDS_WITHOUT_COMPARISONS.includes(filter.field)
  ) {
    // When _is_null comparison is used options values must change to be consistent
    const isNullComparison = filter.operator === "_is_null";
    const options = [
      {
        value: isNullComparison ? false : true,
        label: t("yes", "Yes"),
        disabled: false,
        icon: CheckIcon,
      },
      {
        value: isNullComparison ? true : false,
        label: t("no", "No"),
        disabled: false,
        icon: XIcon,
      },
    ];

    return <RadioButtonsField options={options} name={`${name}.value`} />;
  }

  if (filter.field === "status") {
    return (
      <div className="w-full grow">
        <SelectorField
          name={`${name}.value`}
          options={CONVERSATION_STATUS_OPTIONS.map((status) => ({
            ...status,
            label: t(status.label),
          }))}
        />
      </div>
    );
  }

  if (filter.field === "last_message_status") {
    return (
      <div className="w-full grow">
        <SelectorField
          name={`${name}.value`}
          options={MESSAGE_STATUS_OPTIONS.map((status) => ({
            ...status,
            label: t(status.label),
          }))}
        />
      </div>
    );
  }

  if (filter.field === "assigned" && filterOperatorType === "object") {
    return (
      <SelectorField
        name={`${name}.value`}
        isMulti={false}
        isCreatable={false}
        options={members.map((user) => ({
          label: userToString(user),
          value: user.id,
        }))}
        placeholder={t("write-to-search", "Write to search...")}
      />
    );
  }

  if (filter.field === "channel" && filterOperatorType === "object") {
    return (
      <SelectorField
        name={`${name}.value`}
        isMulti={true}
        isCreatable={false}
        options={
          channels.map((channel) => ({
            label: channel.channel.name,
            value: channel.channel.id,
          })) || []
        }
        placeholder={t("write-to-search", "Write to search...")}
      />
    );
  }

  if (filter.field === "broadcast" && filterOperatorType === "object") {
    return <BroadcastSearchField name={`${name}.value`} />;
  }

  if (filter.field === "contact_tags" && filterOperatorType === "object") {
    return (
      <SelectorField
        name={`${name}.value`}
        isMulti={false}
        isCreatable={false}
        options={contactTags.map((tag) => ({
          label: tag.name,
          value: tag.id,
        }))}
        placeholder={t("select-tag", "Select a tag...")}
      />
    );
  }

  if (
    filter.field === "last_conversation_tags" &&
    filterOperatorType === "object"
  ) {
    return (
      <SelectorField
        name={`${name}.value`}
        isMulti={false}
        isCreatable={false}
        options={conversationTags.map((tag) => ({
          label: tag.name,
          value: tag.id,
        }))}
        placeholder={t("select-tag", "Select a tag...")}
      />
    );
  }

  return null;
}

export default FilterField;
