import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import {
  FlowExecution,
  FlowExecutionStatusPayload,
} from "src/types/flows/FlowExecution";
import { FlowExecutionReadDetail, PaginatedFlowExecutionListList } from "@hilos/types/private-schema";

export function useFlowExecution(id: string) {
  const fetchFlowExecution = useCallback(async () => {
    const { data } = await axios.get<FlowExecutionReadDetail>(
      buildAPIRoute(API_ROUTES.FLOW_EXECUTION_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data } = useQuery(["flow_execution_detail", id], fetchFlowExecution);

  return data;
}

export default useFlowExecution;

interface useFlowExecutionDetailsParams {
  flowExecutionId: string;
  onFlowExecutionStatusUpdateSuccess: (
    nextStatus: FlowExecutionStatusPayload
  ) => void;
}

export const useUpdateFlowExecutionDetailQuery = (flowExecutionId: string) =>
  useCallback(
    (newFlowExecutionData: Partial<FlowExecution>) => {
      const currentData = queryClient.getQueryData<FlowExecution>([
        "flow_execution_detail",
        flowExecutionId,
      ]);
      queryClient.setQueryData(["flow_execution_detail", flowExecutionId], {
        ...currentData,
        ...newFlowExecutionData,
      });
    },
    [flowExecutionId]
  );

export function useFlowExecutionUpdateDetails({
  flowExecutionId,
  onFlowExecutionStatusUpdateSuccess = (nextStatus) => null,
}: useFlowExecutionDetailsParams) {
  const updateFlowExecutionStatus = useCallback(
    async (payload: FlowExecutionStatusPayload) => {
      const { data } = await axios.patch<FlowExecutionStatusPayload>(
        buildAPIRoute(API_ROUTES.FLOW_EXECUTION_STATUS, {
          ":id": flowExecutionId,
        }),
        payload
      );
      return data;
    },
    [flowExecutionId]
  );

  const {
    mutateAsync: handleUpdateFlowExecutionStatus,
    isLoading: isSubmittingStatus,
  } = useMutation(updateFlowExecutionStatus, {
    onSuccess: onFlowExecutionStatusUpdateSuccess,
  });

  return {
    handleUpdateFlowExecutionStatus,
    isSubmittingStatus,
  };
}

export function useFlowExecutions(flowId: string) {
  const fetchFlowExecutions = useCallback(async () => {
    const { data } = await axios.get<PaginatedFlowExecutionListList>(
      API_ROUTES.FLOW_EXECUTION_LIST, {
        params: {flow: flowId}
      });
    return data;
  }, [flowId]);

  const { data } = useQuery(["flow_executions", flowId], fetchFlowExecutions);

  return data || {count: 0, results: []};
}
