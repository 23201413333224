import * as yup from "yup";
import { getTranslationPayload as t } from "src/i18n";

export const getInitialValues = () => {
  return {
    app_id: "",
    phone_number_id: "",
    app_token: "",
    whatsapp_business_account_id: "",
    access_token: "",
  };
};

export const FIELDS = {
  app_id: {
    key: "app_id",
    label: t(
      "channel:cloud-api.fields.app_id.label",
      "What's your Meta Developer App Id?"
    ),
    // i18n.t("channel:cloud-api.fields.app_id.help")
    help: (
      <>
        Check out our docs on how to get it{" "}
        <a
          href="https://hilos.io/docs/user/getting-started/connect-a-new-number"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-500"
        >
          "Getting the app id".
        </a>
      </>
    ),
    placeholder: "383...",
  },
  phone_number_id: {
    key: "phone_number_id",
    label: t(
      "channel:cloud-api.fields.phone_number_id.label",
      "What's your WhatsApp Phone Number Id?"
    ),
    // i18n.t("channel:cloud-api.fields.phone_number_id.help")
    help: (
      <>
        Check out our docs on how to get it{" "}
        <a
          href="https://hilos.io/docs/user/getting-started/connect-a-new-number"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-500"
        >
          "Getting the phone number id".
        </a>
      </>
    ),
    placeholder: "100...",
  },
  app_token: {
    key: "app_token",
    readOnlyKey: "app_token_ro",
    label: t(
      "channel:cloud-api.fields.app_token.label",
      "What's your App Token?"
    ),
    placeholder: "383...|e7D...",
    // i18n.t("channel:cloud-api.fields.app_token.help")
    help: (
      <>
        Check out our docs on how to get it{" "}
        <a
          href="https://hilos.io/docs/user/getting-started/connect-a-new-number"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-500"
        >
          "Getting the app token".
        </a>
      </>
    ),
  },
  whatsapp_business_account_id: {
    key: "whatsapp_business_account_id",
    label: t(
      "channel:cloud-api.fields.waba_id.label",
      "What's your WhatsApp Business Account Id?"
    ),
    // i18n.t("channel:cloud-api.fields.waba_id.help")
    help: (
      <>
        Check out our docs on how to get it{" "}
        <a
          href="https://hilos.io/docs/user/getting-started/connect-a-new-number"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-500"
        >
          "Getting the WhatsApp Business Account id".
        </a>
      </>
    ),
    placeholder: "100...",
  },
  access_token: {
    key: "access_token",
    readOnlyKey: "access_token_ro",
    label: t(
      "channel:cloud-api.fields.access_token.label",
      "What's your System User Access Token?"
    ),
    placeholder: "EAAF...",
    // i18n.t("channel:cloud-api.fields.access_token.help")
    help: (
      <>
        Check out our docs on how to get it{" "}
        <a
          href="https://hilos.io/docs/user/getting-started/connect-a-new-number"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-500"
        >
          "Getting the access token".
        </a>
      </>
    ),
  },
};

export const schema = yup.object().shape({
  phone_number_id: yup.string().nullable(),
  app_id: yup.string().nullable(),
  app_token: yup.string().nullable(),
  whatsapp_business_account_id: yup.string().nullable(),
  access_token: yup.string().nullable(),
});
