import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArrowSmRightIcon,
  BeakerIcon,
  CheckCircleIcon,
  ClipboardIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { buildRoute } from "src/router/router";
import { AccountStatus } from "src/types/account";

export default function MainDashboardCard({
  QRSandboxCode,
  urlWhatsAppCode,
  session,
}) {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = urlWhatsAppCode;

      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";

      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);

      setIsCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard", error);
    }
  };

  const connectWACard = (
    <div className="soft-shadow relative col-span-1 my-4 gap-10 rounded-md bg-gradient-to-tl from-hilos-alt-300 via-hilos-alt-500 to-hilos-alt-700 p-6 sm:col-span-6  md:flex">
      <div className="flex self-center">
        <div className="shrink-0 rounded-full bg-white bg-opacity-20 p-10 md:ml-5">
          <div className="inline-flex rounded-full bg-green-700 p-6 text-white ring-4 ring-white">
            <FontAwesomeIcon
              // @ts-ignore
              icon={faWhatsapp}
              aria-hidden="true"
              className=" h-12 w-12"
            />
          </div>
        </div>
      </div>
      <div className="mt-8 text-white w-full">
        <h3 className="text-xl font-bold">
          {t("dashboard:connect-number", "Connect your number in 20 minutes")}
        </h3>
        <div className="mt-2 space-y-2">
          <p>
            {t(
              "dashboard:connect-number-subtitle",
              "Connect to WhatsApp's API, and level up your WhatsApp by automating and organising your conversations."
            )}
          </p>
        </div>
        <div className="mt-8 w-full flex justify-end ">
          <Link
            to={buildRoute("config-channel-cloud-api-onboarding")}
            className="z-10 lg:mr-10 inline-flex items-center rounded-md border border-transparent bg-white px-4 py-3 text-sm font-semibold text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
          >
            {t("dashboard:activation.label", "Start now")}
            <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </Link>
        </div>
      </div>
      <span className="absolute -right-24 -bottom-24 z-0 h-96 w-96 rounded-full bg-white opacity-10 md:top-0"></span>
    </div>
  );
  const activeStatusCard = (
    <div className="shadow relative overflow-hidden col-span-1 my-4 gap-10 rounded-md bg-white p-6 sm:col-span-6  md:flex">
      <div className="flex self-center">
        <div className="shrink-0 rounded-full bg-slate-300  bg-opacity-20 p-10 md:ml-5">
          <div className="inline-flex rounded-full bg-slate-500 p-4 text-white ring-4 ring-white">
            <CheckCircleIcon className="h-8 w-8" aria-hidden="true" />
          </div>
        </div>
      </div>
      <span className="absolute -right-24 -bottom-24 z-0 h-96 w-96 rounded-full bg-slate-300 opacity-10 md:top-0" />
      <div className="mt-4 text-gray-800 w-full z-10">
        <h3 className="text-lg font-bold">
          {t(
            "dashboard:discover-hilos",
            "Discover everything you can do with Hilos."
          )}
        </h3>
        <div className="mt-2 space-y-2 text-sm text-gray-600">
          <p>
            <Trans i18nKey="dashboard:discover-hilos-subtitle">
              Your account is succesfully connected to WhatsApp. What are the
              next steps?
              <br />
              <br />
              Check out the Hilos' user guides and discover the different things
              that you can create with us!.
            </Trans>
          </p>
        </div>
        <div className="mt-8 w-full flex justify-end z-20">
          <a
            href="https://hilos.io/docs/en/user/getting-started/sending-your-first-message"
            rel="noreferrer"
            target="_blank"
            className="lg:mr-10 inline-flex items-center rounded-md border border-transparent bg-neutral px-4 py-3 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
          >
            {t("dashboard:user-guides", "User guides")}
            <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </a>
        </div>
      </div>
    </div>
  );
  const inactiveStatusCard = (
    <div className="shadow relative overflow-hidden col-span-1 my-4 gap-10 rounded-md bg-white p-6 sm:col-span-6  md:flex">
      <div className="flex self-center">
        <div className="shrink-0 rounded-full bg-yellow-300  bg-opacity-20 p-10 md:ml-5">
          <div className="inline-flex rounded-full bg-yellow-500 p-6 text-white ring-4 ring-white">
            <ExclamationIcon className="h-12 w-12" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="mt-8 text-gray-800 w-full">
        <h3 className="text-xl font-bold">
          {t(
            "dashboard:account-not-connected",
            "Your account is not yet connected to WhatsApp"
          )}
        </h3>
        <div className="mt-2 space-y-2 text-sm text-gray-600">
          <p>
            <Trans i18nKey="dashboard:connect-to-360">
              <a
                href={buildRoute("config-account")}
                className="font-semibold text-hilos"
              >
                Click here
              </a>{" "}
              to set up your company's WA phone number and 360Dialog's API Key,
              then click save to connect it to Hilos.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="dashboard:problems-contact-us">
              If you are having problems with your account
              <a
                href="https://api.whatsapp.com/send?phone=5215592257050&text=I%20need%20help%20activating%20my%20Whatsapp."
                rel="noreferrer"
                target="_blank"
                className=" py-1 px-2 font-bold text-hilos "
              >
                Contact us!
              </a>
              .
            </Trans>
          </p>
        </div>
        <div className="mt-8 w-full flex justify-end ">
          <a
            href={buildRoute("config-account")}
            rel="noreferrer"
            target="_blank"
            className="z-10 lg:mr-10 inline-flex items-center rounded-md border border-transparent bg-hilos-alt-500 px-4 py-3 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
          >
            {t("settings", "Settings")}
            <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </a>
        </div>
      </div>
      <span className="absolute -right-24 -bottom-24 z-0 h-96 w-96 rounded-full bg-yellow-300 opacity-10 md:top-0"></span>
    </div>
  );
  const connectToSandboxCard = (
    <div className="soft-shadow sm:grid sm:grid-cols-5 relative my-4 gap-10 rounded-md bg-gradient-to-tl from-slate-400 via-slate-500 to-neutral p-6 ">
      <div className=" col-span-2 w-full justify-center flex self-center">
        <div className="flex flex-col text-center">
          <div>
            <h3 className="text-sm font-bold mb-2 text-center text-white justify-self-center">
              {t(
                "settings:onboarding.open-on-your-phone",
                "Open on your phone"
              )}
            </h3>
            <div className="inline-flex rounded-lg bg-white text-white ">
              {QRSandboxCode ? (
                <img
                  className="mx-auto rounded-lg h-40 p-3"
                  src={QRSandboxCode}
                  alt="qr-code"
                />
              ) : (
                <div className="m-2 flex h-[170px] min-h-[170px] min-w-[170px] animate-pulse flex-col items-center rounded-lg p-3 text-center">
                  {t("loading")}
                </div>
              )}
            </div>
          </div>
          <br className="text-white bg-white" />
          <div>
            <h3 className="text-sm font-bold text-center text-white justify-self-center">
              {t(
                "settings:onboarding.open-on-your-computer",
                "Open on your computer"
              )}
            </h3>
            <div className="w-full flex justify-center">
              <a
                href={urlWhatsAppCode}
                rel="noreferrer"
                target="_blank"
                className="spread-shadow-gree h-fit mt-4 inline-block rounded-lg bg-green-600 py-3 px-4 text-xs font-semibold text-white self-center"
              >
                <FontAwesomeIcon
                  // @ts-ignore
                  icon={faWhatsapp}
                  aria-hidden="true"
                  className="mr-2"
                />
                {t("send-whatsapp", "Send WhatsApp")}
              </a>
            </div>
            <div className="w-full flex justify-center mt-4">
              <button
                onClick={handleCopyClick}
                disabled={isCopied}
                className=" border-dashed border-white border rounded-md px-2 py-1 flex text-xs text-white font-semibold"
              >
                <ClipboardIcon className="w-4 h-4 mr-2" />
                {isCopied
                  ? t("dashboard:sandbox.copied-link", "Link copied!")
                  : t("dashboard:sandbox.copy-link", "Copy link")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 mt-8 text-white w-full">
        <div className="flex w-full justify-center mb-6">
          <div className="bg-white bg-opacity-10 rounded-full flex items-center justify-center p-3">
            <BeakerIcon className="w-8 h-8 text-white" />
          </div>
        </div>
        <Trans i18nKey="settings:onboarding.start-sandbox">
          <p className="text-xl font-bold"></p>
          <p className="mt-2 text-sm text-justify"></p>
        </Trans>
        <div className="mt-8 flex items-center justify-center self-center rounded-sm p-2 font-semibold text-white">
          <svg
            className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {t("dashboard:sandbox.waiting", "Waiting for your message...")}
        </div>
      </div>
      <span className="absolute -right-24 -bottom-24 z-0 h-96 w-96 rounded-full bg-white opacity-5"></span>
      <span className="absolute -left-24 -top-48 z-0 h-96 w-96 rounded-full bg-white opacity-5"></span>
    </div>
  );

  switch (session?.account.status) {
    case AccountStatus.NEW:
      return connectToSandboxCard;

    case AccountStatus.IN_SANDBOX:
      return connectWACard;

    case AccountStatus.ACTIVE:
      return activeStatusCard;
    case AccountStatus.INACTIVE:
      return inactiveStatusCard;

    default:
      return <></>;
  }
}
