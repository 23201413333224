import { ExclamationIcon, ShareIcon, XIcon } from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";

import Creatable from "react-select/creatable";
import { OptionVariableGroup } from "../hooks/useVariables";

interface FlowBuilderSelectVariableProps {
  options: OptionVariableGroup[];
  isInboundFlow: boolean;
  hasMissingActionTest: boolean;
  onClose: () => void;
  onSelectVariable: (value: any) => void;
  onCreateVariable: (value: string) => void;
}

function formatGroupLabel(data) {
  return (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <data.icon
          className={`aria-hidden="true" h-4 w-4 text-hilos ${
            data.icon === ShareIcon ? "rotate-90" : ""
          }`}
        />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {data.label}
      </div>
    </div>
  );
}

function FlowBuilderSelectVariable({
  options,
  isInboundFlow,
  hasMissingActionTest,
  onClose,
  onSelectVariable,
  onCreateVariable,
}: FlowBuilderSelectVariableProps) {
  const { t } = useTranslation();
  return (
    <div>
      {isInboundFlow && (
        <div className="my-2 flex rounded-md bg-yellow-50 p-4">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <div className="text-xs text-yellow-700">
              <Trans i18nKey="flows:variables.new-contact-warning">
                For Inbound flows, keep in mind that it's likely that people
                that join the flow won't be saved as a contact, so they won't
                have any <code>contact.*</code> variables set.
              </Trans>
            </div>
          </div>
        </div>
      )}
      {hasMissingActionTest && (
        <div className="my-2 flex rounded-md bg-red-50 p-4">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <div className="text-xs text-red-700">
              <Trans i18nKey="flows:variables.action-test-request">
                Click{" "}
                <code className="bg-green-50 px-2 font-semibold text-green-500">
                  Test Request
                </code>{" "}
                button to get all action step variables.
              </Trans>
            </div>
          </div>
        </div>
      )}

      <p className="my-1 text-xs text-gray-500">
        {t(
          "flows:variables.instructions",
          "You can insert variables from available contact fields, as well as from previous steps from this same flow."
        )}
      </p>

      <div className="flex items-center">
        <div className="grow">
          <Creatable
            options={options}
            formatGroupLabel={formatGroupLabel}
            onChange={onSelectVariable}
            onCreateOption={onCreateVariable}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            placeholder={t("flows:variables.select-placeholder", "Select...")}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                borderColor: "#ddd",
                "&:hover": {
                  borderColor: "#ddd",
                },
                boxShadow: "none",
                "*": {
                  boxShadow: "none !important",
                },
              }),
              option: (base, { isFocused, isSelected }) => {
                return {
                  ...base,
                  wordBreak: "break-all",
                  backgroundColor: isSelected
                    ? "#d54466"
                    : isFocused
                    ? "#d54466"
                    : undefined,
                  color: isSelected ? "#fff" : isFocused ? "#fff" : undefined,
                  fontSize: "small",
                  paddingLeft: "30px",
                };
              },
            }}
          />
        </div>
        <div className="ml-2 grow-0">
          <button
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
            type="button"
            onClick={onClose}
          >
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default FlowBuilderSelectVariable;
