import { useCallback, useState } from "react";

import CreateVariableModal from "./CreateVariableModal";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import FieldContainer from "src/components/Form/FieldContainer";
import FlowBuilderSelectVariable from "./FlowBuilderSelectVariable";
import { classNames } from "src/Helpers";
import useFieldWithVariables from "src/containers/flow/builder/hooks/useFieldWithVariables";
import { useTranslation } from "react-i18next";
import useVariables from "src/containers/flow/builder/hooks/useVariables";

export interface TextInputFieldWithVariablesProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "action"> {
  path: string;
  name: string;
  type?: string;
  label?: string;
  help?: string | React.ReactElement;
  optional?: boolean;
  placeholder?: string;
  className?: string;
  currentStepIndex?: number | null;
  variableSeparator?: string;
  containerClassName?: string;
  action?: React.ReactElement;
  addVariableLabel?: JSX.Element | string;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
}

function TextInputFieldWithVariables({
  path,
  name,
  label,
  help,
  optional,
  className,
  containerClassName,
  disabled,
  action,
  currentStepIndex,
  variableSeparator,
  icon: RenderIcon,
  addVariableLabel,
  ...props
}: TextInputFieldWithVariablesProps) {
  const { t } = useTranslation();
  const [showVariables, setShowVariables] = useState(false);
  const [newVar, setNewVar] = useState("");
  const [showCreateVariableModal, setShowCreateVariableModal] = useState(false);
  const { options, isInboundFlow, hasMissingActionTest, handleGetVariable } =
    useVariables({ currentStepIndex });
  const {
    field,
    error,
    valueWithVariables,
    handleAddVariable,
    handleChange,
    handleCursorPosition,
  } = useFieldWithVariables({
    path,
    name,
    separator: variableSeparator,
    handleGetVariable,
  });

  const handleSelectOption = useCallback(
    (option) => {
      setShowVariables(false);
      if (option && option.value) {
        handleAddVariable(option.value);
      }
    },
    [handleAddVariable]
  );

  const handleCreateOption = useCallback((inputValue) => {
    setNewVar(inputValue);
    setShowCreateVariableModal(true);
  }, []);

  const handleCloseCreateVariableModal = useCallback(() => {
    setShowCreateVariableModal(false);
    setNewVar("");
  }, []);

  return (
    <div>
      <FieldContainer
        name={field.name}
        label={label}
        help={help}
        optional={optional}
        error={error}
      >
        <div className={classNames(label && "mt-1", "flex")}>
          <div
            className={classNames(
              "relative flex flex-grow items-stretch border border-gray-300 focus-within:z-10",
              "focus-within:border-indigo-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500",
              action || !showVariables
                ? "rounded-none rounded-l-md"
                : "rounded-md",
              containerClassName,
              !disabled &&
                error &&
                "border-red-500 focus-within:border-red-500 focus-within:ring-red-500",
              disabled && "border-gray-200 shadow-none"
            )}
          >
            {RenderIcon && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <RenderIcon
                  className={classNames(
                    "h-5 w-5",
                    disabled ? "text-gray-300" : "text-gray-500"
                  )}
                />
              </div>
            )}
            <input
              id={field.name}
              className={classNames(
                "w-full border-none border-gray-300 placeholder-gray-400 sm:text-sm",
                "focus:border-0 focus:outline-none focus:ring-0 sm:text-sm",
                "disabled:bg-gray-50 disabled:text-gray-300 disabled:placeholder-gray-200",
                action ? "rounded-none rounded-l-md" : "rounded-md",
                RenderIcon && "pl-10",
                error && "pr-10",
                className
              )}
              disabled={disabled}
              {...field}
              {...props}
              onKeyUp={handleCursorPosition}
              onKeyDown={handleCursorPosition}
              onClick={handleCursorPosition}
              value={valueWithVariables || ""}
              onChange={handleChange}
            />
            {error && (
              <div className="pointer-events-none absolute inset-y-0 right-0 z-10 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {!showVariables && (
            <button
              className={classNames(
                "inline-flex items-center border-l-0 border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700",
                "hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                action ? "rounded-none" : "rounded-r-md"
              )}
              type="button"
              onClick={() => setShowVariables(true)}
            >
              {addVariableLabel || t("add-variable", "Add variable")}
            </button>
          )}
          {action}
        </div>
      </FieldContainer>

      <div className="mt-2 flex flex-row items-end justify-end">
        {showVariables && (
          <FlowBuilderSelectVariable
            options={options}
            onCreateVariable={handleCreateOption}
            isInboundFlow={isInboundFlow}
            hasMissingActionTest={hasMissingActionTest}
            onClose={() => setShowVariables(false)}
            onSelectVariable={handleSelectOption}
          />
        )}
      </div>
      {showCreateVariableModal && (
        <CreateVariableModal
          isOpen={showCreateVariableModal}
          onClose={handleCloseCreateVariableModal}
          addVariable={handleAddVariable}
          inputValue={newVar}
        />
      )}
    </div>
  );
}

export default TextInputFieldWithVariables;
