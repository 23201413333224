import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import { UserData } from "@hilos/types/hilos";
import { ContactDetailRead, UserBasicEdit } from "@hilos/types/private-schema";
import SelectorField from "src/components/Form/SelectorField";
import { UpdateContactFn } from "src/hooks/useContactDetails";
import { userToString } from "src/Helpers";
import { ERROR_MESSAGES } from "src/constants/Form";

interface ContactAssignmentFormProps {
  members: UserData[];
  contact: ContactDetailRead;
  onClose: () => void;
  onUpdateContact: UpdateContactFn;
}

const schema = yup.object().shape({
  id: yup.number().required(ERROR_MESSAGES.required),
});

const initialValues = {
  id: 0,
};

function ContactAssignmentForm({
  members,
  contact,
  onClose,
  onUpdateContact,
}: ContactAssignmentFormProps) {
  const { t } = useTranslation();
  const currentDefaultAssignees = useMemo(
    () => contact.default_assignees ?? [],
    [contact.default_assignees]
  );
  const onSubmit = useCallback(
    async (user: UserBasicEdit, setSubmitting) => {
      const nextAssigned = [
        ...currentDefaultAssignees.map((user) => user),
        user,
      ]
        .filter((user, index, default_assignees) => {
          return default_assignees.indexOf(user) === index;
        })
        .map((user) => user.id);
      onUpdateContact({
        default_assignees: nextAssigned,
      });
      setSubmitting(false);
      onClose();
    },
    [currentDefaultAssignees, onUpdateContact, onClose]
  );

  return (
    <Formik<UserBasicEdit>
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      initialValues={initialValues}
      enableReinitialize={true}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="space-y-2 px-3 py-2">
            <SelectorField
              name="id"
              isMulti={false}
              isCreatable={false}
              options={members.map((user) => ({
                label: userToString(user),
                value: user.id,
              }))}
              placeholder={t("write-to-search", "Write to search...")}
            />

            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {formik.isSubmitting ? (
                <>
                  <svg
                    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {t("saving", "Saving...")}
                </>
              ) : (
                <div className="inline-flex items-center">
                  {t("save", "Save")}
                  <ArrowSmRightIcon
                    className="ml-1 -mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default ContactAssignmentForm;
