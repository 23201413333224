import { useState } from "react";
import { useTranslation } from "react-i18next";
import PermissionsChecker from "src/components/PermissionsCheck";
import logo from "src/assets/img/logo512.png";
import HeaderNavigation from "../../components/HeaderNavigation";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";
import SubscriptionStatusBadge from "./billing/SubscriptionStatusBadge";
import SubscriptionV2ChangePlanModal from "./billing/SubscriptionV2ChangePlanModal";
import SubscriptionV2PlanComparison from "./billing/SubscriptionV2PlanComparison";

export default function AccountSubscriptionChangePlan() {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const handleHideCheckout = () => {
    setSelectedPlan(null);
  };

  if (!session) {
    return null;
  }

  return (
    <div className="h-screen overflow-y-auto bg-gray-100">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings"),
              url: buildRoute("config-account"),
            },
            {
              name: t("settings:subscription.label", "Your subscription"),
              url: buildRoute("config-account-subscription"),
            },
            {
              name: t("settings:change-subscription.label", "Change plan"),
              url: buildRoute("config-account-subscription-change-plan"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center space-x-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <img
                src={logo}
                className="mr-2 h-7 w-7"
                aria-hidden="true"
                alt="logo"
              />
              {t("settings:subscription.label", "Your Hilos Subscription")}
              {session.account.current_subscription?.status && (
                <SubscriptionStatusBadge
                  status={session.account.current_subscription.status}
                />
              )}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl">
        <PermissionsChecker
          permission="change_stripesubscription"
          action={t(
            "settings:permissions.change_stripesubscription",
            "change your subscription"
          )}
        >
          {session.account.pricing_plan_selected && (
            <SubscriptionV2PlanComparison
              currency={session.account.payment_currency}
              onSelectPlan={setSelectedPlan}
              activePlan={session.account.pricing_plan_selected}
              disableActivePlan={true}
            />
          )}

          {selectedPlan && (
            <SubscriptionV2ChangePlanModal
              selectedPlan={selectedPlan}
              handleHide={handleHideCheckout}
            />
          )}
        </PermissionsChecker>
      </div>
    </div>
  );
}
