import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { WorkflowTaskEdit } from "@hilos/types/private-schema";
import HeaderNavigation from "src/components/HeaderNavigation";
import { classNames } from "src/Helpers";
import { buildRoute } from "../../router/router";
import WorkflowBoardAddTask from "./WorkflowBoardAddTask";
import {
  WorkflowBoardStore,
  useWorkflowBoardContext,
} from "./WorkflowBoardContext";
import WorkflowBoardFilters from "./WorkflowBoardFilters";
import WorkflowBoardTask from "./WorkflowBoardTask";
import Board from "./board/Board";
import { Column } from "./board/Column";
import useWorkflowTasks from "./useWorkflowTasks";

interface WorkflowBoardDataProps {
  id: string;
}

export interface SelectedWorkflowTask {
  id: string;
  state: string;
}

export default function WorkflowBoardData({ id }: WorkflowBoardDataProps) {
  const [t] = useTranslation();
  const [newWorkflowTask, setNewWorkflowTask] =
    useState<Partial<WorkflowTaskEdit> | null>(null);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [selectedStateId, setSelectedStateId] = useState<string | null>(null);

  const { instanceId, name, columns, states, tasksByState, onMoveCard } =
    useWorkflowBoardContext<WorkflowBoardStore>();

  useWorkflowTasks({
    workflowId: id,
  });

  const handleShowAddTask = useCallback((state: string) => {
    setNewWorkflowTask({
      state,
    });
  }, []);

  const handleClickCard = useCallback(
    (nextSelectedTask: SelectedWorkflowTask | null = null) => {
      setSelectedTaskId((nextSelectedTask && nextSelectedTask.id) || null);
      setSelectedStateId((nextSelectedTask && nextSelectedTask.state) || null);
    },
    []
  );

  return (
    <div
      className={classNames(
        "flex flex-col w-full h-full overflow-hidden"
        // selectedTaskId && "bg-gray-100"
      )}
    >
      {Boolean(!selectedTaskId) && (
        <div className="pt-4 px-4 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("workflows"),
                url: buildRoute("workflow-list"),
              },
              {
                name,
                url: buildRoute("workflow-board", { ":id": id }),
              },
            ]}
          />
        </div>
      )}
      <Board
        instanceId={instanceId}
        tasksByState={tasksByState}
        onMoveCard={onMoveCard}
        selectedTaskId={selectedTaskId}
      >
        <WorkflowBoardAddTask
          workflowId={id}
          states={states}
          initialValues={newWorkflowTask}
          onClose={() => setNewWorkflowTask(null)}
        />
        <AnimatePresence mode="popLayout">
          {!selectedStateId && <WorkflowBoardFilters states={states} />}
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          <div className="flex flex-1 overflow-hidden">
            <motion.div
              className={classNames(
                "flex flex-1 flex-row justify-start overflow-hidden gap-2",
                selectedTaskId
                  ? "divide-x "
                  : "px-4 sm:px-6 lg:px-8 overflow-x-scroll"
              )}
            >
              {states.map(
                (state) =>
                  Boolean(
                    state.id &&
                      columns.includes(state.id) &&
                      (!selectedStateId || selectedStateId === state.id)
                  ) && (
                    <Column
                      key={state.id}
                      id={state.id}
                      title={state.name}
                      tasks={tasksByState[state.id]}
                      selectedTaskId={selectedTaskId}
                      onClickCard={handleClickCard}
                      onShowAddTask={handleShowAddTask}
                    />
                  )
              )}

              {selectedTaskId !== null && (
                <WorkflowBoardTask
                  taskId={selectedTaskId}
                  workflowId={id}
                  onClose={() => {
                    setSelectedStateId(null);
                    setSelectedTaskId(null);
                  }}
                />
              )}
            </motion.div>
          </div>
        </AnimatePresence>
      </Board>
    </div>
  );
}
