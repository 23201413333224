import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClipboardListIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import { axiosErr } from "@hilos/types/axios";
import { CategoryEnum, InboxContactRead } from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import StateControlButton from "src/components/StateControlButton";
import APIErrors from "../../../components/base/APIErrors";
import { API_ROUTES, buildAPIRoute } from "../../../router/router";
import {
  CATEGORIES,
  getTemplateVarsFromTemplate,
} from "../../wa/WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "../../wa/WhatsAppTemplatePreview";
import WhatsAppTemplatePreviewForm, {
  initialTemplatePreviewValues,
} from "../../wa/WhatsAppTemplatePreviewForm";
import WhatsAppTemplateSelect from "../../wa/WhatsAppTemplateSelect";

interface WhatsAppTemplateSendProps {
  inboxContact: InboxContactRead;
  onClose: () => void;
  onCancel?: () => void;
}

function WhatsAppTemplateSend({
  inboxContact,
  onClose,
  onCancel,
}: WhatsAppTemplateSendProps) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialTemplatePreviewValues);
  const [selectedTemplate, setSelectedTemplate] = useState<
    WhatsAppTemplate | undefined
  >(undefined);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const templateHasVars = useMemo(() => {
    if (!selectedTemplate) {
      return false;
    }
    return getTemplateVarsFromTemplate(selectedTemplate).length > 0;
  }, [selectedTemplate]);

  const handleSelectTemplate = useCallback(
    (template) => {
      setSelectedTemplate(template);
      setFormValues({ ...initialTemplatePreviewValues });
    },
    [setSelectedTemplate]
  );

  const handleCancel = () => {
    onClose();
    setBackendError("");
    setBackendValidationErrors({});
  };

  const handleCleanUp = useCallback(() => {
    setSelectedTemplate(undefined);
    setFormValues(initialTemplatePreviewValues);
    setBackendError("");
    setBackendValidationErrors({});
    onClose();
  }, [onClose]);

  const sendTemplateMessage = async () => {
    if (!selectedTemplate) {
      return;
    }
    const variableData = [
      formValues.headerURL,
      formValues.headerVar,
      formValues.locationHeaderVars.latitude,
      formValues.locationHeaderVars.longitude,
      formValues.locationHeaderVars.name,
      formValues.locationHeaderVars.address,
      ...formValues.bodyVars,
      ...formValues.buttonVars,
    ].filter((x) => x);

    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.INBOX_WHATSAPP_TEMPLATE_SEND, {
          ":id": selectedTemplate.id,
        }),
        { phone: inboxContact?.contact.phone, variables: variableData }
      );
      handleCleanUp();
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;

      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios?.response?.data);
      } else {
        setBackendError(
          "An error occurred while sending your message. Please try again."
        );
      }
      // The exception needs to be thrown again for the StateControlButton
      // be able to detect that the action failed
      throw err;
    }
  };

  const FIELDS = {
    phone: {
      label: "Contact",
    },
    variables: {
      label: "Variables",
    },
  };

  return (
    <>
      <div className="sm:flex">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <ClipboardListIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        </div>
        <div className="flex items-center self-center text-center sm:mt-0 sm:ml-4 sm:text-left">
          <div className="text-lg font-medium leading-6 text-gray-900">
            {t("inbox:select-template", "Select a template to send")}
          </div>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={FIELDS}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <Formik
          enableReinitialize={true}
          initialValues={{
            whatsapp_template: "",
          }}
          onSubmit={(_) => {}}
        >
          {(formik) => (
            <form className="space-y-4">
              <WhatsAppTemplateSelect
                setSelectedTemplate={handleSelectTemplate}
                fieldName="whatsapp_template"
                channel={inboxContact?.channel.id}
                categories={
                  Object.getOwnPropertyNames(CATEGORIES).filter(
                    (category) =>
                      category !== "AUTHENTICATION" && category !== "OTP"
                  ) as CategoryEnum[]
                }
              />
            </form>
          )}
        </Formik>
      </div>
      {selectedTemplate && (
        <>
          <div className="mt-8 text-center">
            {templateHasVars && (
              <p className="text-sm text-gray-500">
                {t(
                  "inbox:template-with-variables",
                  "This template has variables, set the values you want to use for this message."
                )}
              </p>
            )}
          </div>

          <div
            className={`mt-4 grid grid-cols-1 gap-6 ${
              templateHasVars ? "sm:grid-cols-2" : ""
            }`}
          >
            {templateHasVars && (
              <div>
                <WhatsAppTemplatePreviewForm
                  key={selectedTemplate.id}
                  template={selectedTemplate}
                  onTemplateValuesChange={setFormValues}
                />
              </div>
            )}
            <div>
              <WhatsAppTemplatePreview
                template={selectedTemplate}
                values={formValues}
              />
            </div>
          </div>

          <div className="mt-8 sm:mt-6 sm:flex sm:flex-row-reverse">
            <StateControlButton
              btnClasses="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={sendTemplateMessage}
              submittingText={t("sending", "Sending...")}
              successText={t("sent", "Sent")}
              initialText={
                <>
                  {t("send", "Send")}
                  <FontAwesomeIcon
                    icon={faPaperPlane as IconProp}
                    className="ml-1"
                  />
                </>
              }
              onSuccess={handleCleanUp}
            />
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={onCancel ? onCancel : handleCancel}
            >
              {t("cancel", "Cancel")}
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default WhatsAppTemplateSend;
