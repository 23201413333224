import { useCallback } from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";

interface MessageMenuProps {
  url: string;
}

function MessageMenu({ url }: MessageMenuProps) {
  const handleOpen = useCallback(() => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }, [url]);

  return (
    <div className="invisible absolute z-30 flex h-8 w-full justify-end rounded-lg bg-gradient-to-b from-gray-900 pr-2 pt-2 group-hover:visible">
      <ExternalLinkIcon
        onClick={handleOpen}
        color="white"
        className="invisible h-5 w-5 cursor-pointer group-hover:visible"
      />
    </div>
  );
}

export default MessageMenu;
