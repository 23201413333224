import { Trans, useTranslation } from "react-i18next";
import SwitchField from "../../components/Form/SwitchField";
import AccountFormUpdate from "./AccountFormUpdate";
import AccountInboxConversationComponent from "./AccountInboxConversationComponent";
import * as meta from "./AccountMeta";

export default function AccountInboxComponent() {
  const { t } = useTranslation();
  return (
    <AccountFormUpdate>
      {(_, formik) => (
        <>
          <div className="mt-10 sm:mt-4 ">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <Trans i18nKey="settings:inbox-settings.description">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Inbox settings
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Settings that apply to everyone using the Inbox.
                    </p>
                  </Trans>
                </div>
              </div>

              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="border-b border-gray-200 bg-white px-4 py-4 sm:p-6">
                    <SwitchField
                      name={meta.FIELDS.inbox_add_name_before_message.key}
                      label={t(meta.FIELDS.inbox_add_name_before_message.label)}
                      help={meta.FIELDS.inbox_add_name_before_message.help}
                    />
                  </div>
                  <div className="border-b border-gray-200 bg-white px-4 py-4 sm:p-6">
                    <SwitchField
                      name={meta.FIELDS.agents_can_see_all_conversations.key}
                      label={t(
                        meta.FIELDS.agents_can_see_all_conversations.label
                      )}
                      help={t(
                        meta.FIELDS.agents_can_see_all_conversations.help
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AccountInboxConversationComponent formik={formik} />
        </>
      )}
    </AccountFormUpdate>
  );
}
