import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup1";
import { WorkflowTaskEdit } from "@hilos/types/private-schema";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import ComboboxField from "src/components/Form/ComboboxField";
import SelectorField from "src/components/Form/SelectorField";
import { classNames, formatPhone, userToString } from "src/Helpers";
import { ERROR_MESSAGES } from "src/constants/errors";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

interface OptionItem {
  value: string;
  label: string;
}

interface OptionLabel {
  active: boolean;
  selected: boolean;
  option: OptionItem;
}

interface WorkflowBoardAddTaskFormProps {
  workflowId: string;
  currentStateOptions: OptionItem[];
  initialValues: Partial<WorkflowTaskEdit> | null;
  onClose: () => void;
}

export const contactFormatOptionLabel = ({
  active,
  selected,
  option,
}: OptionLabel) => (
  <div className={selected ? "border-l-2 pl-2 border-hilos" : ""}>
    <div>
      <p className={classNames("truncate", selected && "font-semibold")}>
        {option.label.split("-")[0]}
      </p>
      <p
        className={classNames(
          "truncate text-gray-500 text-xs mt-1",
          selected && "font-semibold"
        )}
      >
        {option.label.split("-")[1]}
      </p>
    </div>

    {selected && (
      <span
        className={classNames(
          "absolute inset-y-0 right-0 flex items-center pr-4 text-hilos"
        )}
      >
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      </span>
    )}
  </div>
);

export default function WorkflowBoardAddTaskForm({
  workflowId,
  currentStateOptions,
  initialValues,
  onClose,
}: WorkflowBoardAddTaskFormProps) {
  const { t } = useTranslation();

  const WorkflowTaskSchema = useMemo(
    () =>
      yup.object().shape({
        state: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
        channel: yup.number().required(t(ERROR_MESSAGES.REQUIRED)),
        phone: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    [t]
  );

  const handleSearchPhoneNumber = useCallback(async (search: string) => {
    try {
      const { data } = await axios.get(API_ROUTES.CONTACT_CREATE_LIST, {
        params: { page_size: 5, search },
      });
      if (data && data.results) {
        return data.results.map((c) => ({
          label: `${userToString(c)} - ${formatPhone(c.canonical_phone)}`,
          value: c.canonical_phone,
        }));
      }
    } catch {}
    return [];
  }, []);

  const handleSubmitForm = useCallback(
    async (values) => {
      try {
        await axios.post(
          buildAPIRoute(API_ROUTES.WORKFLOW_TASK_LIST_CREATE, {
            ":workflow_id": workflowId,
          }),
          {
            ...values,
          }
        );
        onClose();
      } catch {}
    },
    [onClose, workflowId]
  );

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={WorkflowTaskSchema}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
        >
          <div className="bg-indigo-700 px-4 py-6 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-base font-semibold leading-6 text-white">
                {t("workflows:board.new-task-form.title", "New Task")}
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={onClose}
                >
                  <span className="absolute -inset-2.5" />
                  <span className="sr-only">{t("close", "Close")}</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-indigo-300">
                {t(
                  "workflows:board.new-task-form.description",
                  "Get started by filling in the information below to create a new workflow task."
                )}
              </p>
            </div>
          </div>
          <div className="h-0 flex-1 overflow-y-auto">
            <div className="flex flex-1 flex-col justify-between">
              <div className="divide-y divide-gray-200 px-4 sm:px-6">
                <div className="space-y-6 pb-5 pt-6">
                  <SelectorField
                    name="state"
                    label={t(
                      "workflows:board.new-task-form.state.label",
                      "State"
                    )}
                    options={currentStateOptions}
                  />
                  <ChannelSelectField
                    name="channel"
                    label={t(
                      "workflows:board.new-task-form.channel.label",
                      "Channel to use"
                    )}
                  />
                  <ComboboxField
                    name="phone"
                    placeholder={t("search", "Search")}
                    label={t(
                      "workflows:board.new-task-form.phone.label",
                      "Phone or contact"
                    )}
                    help={t(
                      "workflows:board.new-task-form.phone.help",
                      "Search for phone numbers or contact names."
                    )}
                    loadOptions={handleSearchPhoneNumber}
                    formatOptionLabel={contactFormatOptionLabel}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={onClose}
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("save")}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}
