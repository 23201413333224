import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import useHilosStore from "src/hooks/useHilosStore";

interface PermissionsCheckerProps {
  permission?: string;
  action?: string;
  children: any;
  notice?: JSX.Element | null;
  showNotice?: boolean;
}
export default function PermissionsChecker({
  permission,
  action = "complete this action",
  children,
  notice = null,
  showNotice = true,
}: PermissionsCheckerProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  if (!permission) {
    return children;
  }

  if (session?.permissions.includes(permission)) {
    return children;
  }

  if (notice) {
    return notice;
  }

  if (showNotice) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              {t(
                "settings:permissions.notice.title",
                "You don't have permissions to {{action}}",
                {
                  action: action,
                }
              )}
              .
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              {t(
                "settings:permissions.notice.subtitle",
                "Contact an admin on of your team ({{team}}) to continue",
                {
                  team: session?.account.name,
                }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
