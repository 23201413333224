import React, {
  SVGProps,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";

import { classNames } from "src/Helpers";
import { useTranslation } from "react-i18next";

export type TabsRef = {
  focus: (index: number) => void;
};

interface TabsProps {
  tabs: {
    hide?: boolean;
    label: string | string[];
    icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element; // typeof React.Component;
  }[];
  className?: string;
  initialSelectedIndex?: number;
  onChange?: (value: number) => void;
}

const Tabs = forwardRef<TabsRef, React.PropsWithChildren<TabsProps>>(
  ({ tabs, className, children, initialSelectedIndex = 0, onChange }, ref) => {
    const [t] = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

    useImperativeHandle(ref, () => ({
      focus: (index: number) => setSelectedIndex(index),
    }));

    const handleChangeTab = useCallback(
      (nextSelectedIndex) => {
        setSelectedIndex(nextSelectedIndex);

        if (onChange) {
          onChange(nextSelectedIndex);
        }
      },
      [onChange]
    );

    if (!children || !Array.isArray(children)) {
      return null;
    }

    return (
      <div className="flex w-full h-full flex-col">
        <div
          className={classNames(
            "shrink-0 sm:border-b sm:border-gray-200",
            className
          )}
        >
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              {t("select-a-tab", "Select a tab")}
            </label>
            <select
              id="tabs"
              name="tabs"
              className="mb-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={tabs[selectedIndex] && tabs[selectedIndex].label}
              onChange={(event) => {
                event.preventDefault();
                handleChangeTab(Number(event.target.value) || 0);
              }}
            >
              {tabs.map((tab, currentIndex) => {
                if (tab.hide) {
                  return null;
                }
                return <option key={currentIndex}>{tab.label}</option>;
              })}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab, currentIndex) => {
                const selected = currentIndex === selectedIndex;

                if (tab.hide) {
                  return null;
                }

                return (
                  <button
                    key={currentIndex}
                    className={classNames(
                      selected
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                      "flex whitespace-nowrap border-b-2 py-4 px-2 text-sm font-medium"
                    )}
                    aria-current={selected ? "page" : undefined}
                    onClick={(event) => {
                      event.preventDefault();
                      handleChangeTab(currentIndex);
                    }}
                  >
                    {tab.icon !== undefined && (
                      <tab.icon
                        className={classNames(
                          selected
                            ? "text-indigo-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                    )}
                    <span>{tab.label}</span>
                  </button>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="grow">{children[selectedIndex]}</div>
      </div>
    );
  }
);

export default Tabs;
