import { SVGProps } from "react";

function PinIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M 14.707031 2.2929688 L 13.292969 3.7070312 L 14.388672 4.8027344 L 8.1894531 9.7753906 L 6.7070312 8.2929688 L 5.2929688 9.7070312 L 9.09375 13.507812 L 3 19.599609 L 3 21 L 4.4003906 21 L 10.492188 14.90625 L 14.292969 18.707031 L 15.707031 17.292969 L 14.310547 15.896484 L 19.214844 9.6289062 L 20.292969 10.707031 L 21.707031 9.2929688 L 14.707031 2.2929688 z M 15.798828 6.2363281 L 17.779297 8.2167969 L 12.976562 14.359375 L 9.7246094 11.107422 L 15.798828 6.2363281 z"
      />
    </svg>
  );
}

export default PinIcon;
