import { useCallback } from "react";
import axios from "axios";
import { InboxActionData, InboxActionLog } from "@hilos/types/inbox_action";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useInboxActionDetails() {
  const executeAction = useCallback(
    async (contactId: string, action: InboxActionData) => {
      const { data } = await axios.post<InboxActionLog>(
        buildAPIRoute(API_ROUTES.INBOX_ACTION_RUN, {
          ":id": action.id,
          ":contact": contactId,
        })
      );
      return data;
    },
    []
  );

  return {
    executeAction,
  };
}

export default useInboxActionDetails;
