import { ComponentProps, ReactElement, useState } from "react";
import { FormikProps } from "formik";
import SelectorField from "../../components/Form/SelectorField";
import TextInputField from "../../components/Form/TextInputField";
import FieldAddVariableContainer from "../Form/FieldAddVariableContainer";
import InputActionButton from "../Form/InputActionButton";
import HTTPFormBody from "./HTTPFormBody";
import HTTPFormHeaders from "./HTTPFormHeaders";
import HTTPFormNav from "./HTTPFormNav";
import HTTPFormParams from "./HTTPFormParams";
import { HTTPFormMeta } from "./Meta";

export interface HTTPFormProps {
  formik: FormikProps<any>;
  formPrefix: string;
  formValues: {
    method: string;
    url: string;
    headers: { key: string; value: string }[];
    params: { key: string; value: string }[];
    body: string;
    body_type: string;
    body_params: { key: string; value: string }[];
  };
  availableVariables: {
    label: string;
    icon: (props: ComponentProps<"svg">) => ReactElement;
    options: {
      label: string;
      value: string;
    }[];
  }[];
}

export default function HTTPForm({
  formik,
  formPrefix,
  formValues,
  availableVariables,
}: HTTPFormProps) {
  const [currentStep, setCurrentStep] = useState("body");

  const formatOptionLabel = ({ value, label, icon, help, ...props }) => (
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="text-sm">{label}</h4>
          {help && <p className="text-xs">{help}</p>}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SelectorField
        label={HTTPFormMeta.FIELDS.method.label}
        name={`${formPrefix}.${HTTPFormMeta.FIELDS.method.key}`}
        help={HTTPFormMeta.FIELDS.method.help}
        options={HTTPFormMeta.METHOD_TYPES}
        // @ts-ignore
        formatOptionLabel={formatOptionLabel}
      />
      <FieldAddVariableContainer
        formik={formik}
        fieldName={`${formPrefix}.${HTTPFormMeta.FIELDS.url.key}`}
        currentValue={formValues[HTTPFormMeta.FIELDS.url.key]}
        availableVariables={availableVariables}
      >
        {(setShowVariableSelect) => (
          <TextInputField
            label={HTTPFormMeta.FIELDS.url.label}
            name={`${formPrefix}.${HTTPFormMeta.FIELDS.url.key}`}
            placeholder={HTTPFormMeta.FIELDS.url.placeholder}
            type="text"
            action={
              <InputActionButton
                onClick={() => setShowVariableSelect(true)}
                type="button"
              >
                Add variable
              </InputActionButton>
            }
          />
        )}
      </FieldAddVariableContainer>

      <HTTPFormNav currentStep={currentStep} setCurrentStep={setCurrentStep} />
      {currentStep === "headers" && (
        <HTTPFormHeaders
          formik={formik}
          formPrefix={formPrefix}
          formValues={formValues}
          availableVariables={availableVariables}
        />
      )}
      {currentStep === "query" && (
        <HTTPFormParams
          formik={formik}
          formPrefix={formPrefix}
          formValues={formValues}
          availableVariables={availableVariables}
        />
      )}
      {currentStep === "body" && (
        <HTTPFormBody
          formik={formik}
          formPrefix={formPrefix}
          formValues={formValues}
          availableVariables={availableVariables}
        />
      )}
      {/* <FlowFormStepActionTest
        formik={formik}
        index={index}
        step={formik.values.current_draft.steps[index]}
      /> */}
    </>
  );
}
