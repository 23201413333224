import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import {
  ContactImport,
  ContactImportData,
  ContactImportDataStatusEnum,
  ResultEnum,
} from "@hilos/types/private-schema";
import { Link, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { formatPhone, userToString } from "src/Helpers";
import { useEffect, useState } from "react";

import ContactImportDataResultBadge from "./ContactImportDataResultBadge";
import ContactImportDataStatusBadge from "./ContactImportDataStatusBadge";
import ContactImportStatusBadge from "./ContactImportStatusBadge";
import HeaderNavigation from "src/components/HeaderNavigation";
import Table from "src/components/Table/Table";
import { UploadIcon } from "@heroicons/react/outline";
import axios from "axios";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";

export default function ContactImportDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const { formatWithLocale } = useDateFnsFormatLocale();

  const [contactImport, setImportGroup] = useState<ContactImport | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getImportGroup() {
      try {
        const response = await axios.get(
          buildAPIRoute(API_ROUTES.CONTACT_IMPORT_DETAIL, {
            ":id": params.id,
          })
        );
        setImportGroup(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    getImportGroup();
  }, [params]);

  return (
    <>
      {!loading && contactImport ? (
        <div className="h-screen overflow-y-auto bg-gray-50">
          {/* Header */}
          <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
            <HeaderNavigation
              navPages={[
                {
                  name: t("contacts", "Contacts"),
                  url: buildRoute("contact-list"),
                },
                {
                  name: formatPhone(contactImport.name),
                  url: buildRoute("contact-import-detail", {
                    ":id": contactImport.id,
                  }),
                },
              ]}
            />
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <UploadIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                  {contactImport.name}
                </h2>
              </div>
            </div>
          </div>

          <div className="my-6 grid grid-cols-1 grid-rows-2 gap-4 px-6 md:grid-cols-6 md:grid-rows-1">
            <div className="col-span-2 rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("summary", "Summary")}
              </h3>
              <div className="justify-left mt-2 flex items-center text-sm">
                <div className="grow-0 text-gray-400">
                  <UploadIcon
                    className="-ml-1 mr-4 h-7 w-7"
                    aria-hidden="true"
                  />
                </div>
                <div className="grow-1 w-full">
                  <ContactImportStatusBadge status={contactImport.status} />
                  {contactImport.failure_reason && (
                    <p className="font-medium text-red-500">
                      {contactImport.failure_reason}
                    </p>
                  )}
                  <p>Uploaded by {userToString(contactImport.created_by)}</p>
                  <p className="text-gray-400">
                    {t("created-on", "Created on")}{" "}
                    {formatWithLocale(
                      new Date(contactImport.timestamp as string),
                      "LLL d, uuuu, HH:MM"
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("contacts:contacts-total", "Contacts total")}
              </h3>
              <p className="mt-2 text-3xl font-medium text-gray-900">
                {contactImport.results_total}
              </p>
            </div>
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("contacts:contacts-created", "Contacts created")}
              </h3>
              <p className="mt-2 text-3xl font-medium text-gray-900">
                {contactImport.results_created}
              </p>
            </div>
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("contacts:contacts-updated", "Contacts updated")}
              </h3>
              <p className="mt-2 text-3xl font-medium text-gray-900">
                {contactImport.results_updated}
              </p>
            </div>
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("contacts:contacts-ignored", "Contacts ignored")}
              </h3>
              <p className="mt-2 text-3xl font-medium text-gray-900">
                {contactImport.results_ignored}
              </p>
            </div>
          </div>

          <div className="min-h-screen bg-gray-50">
            <div className="h-full sm:px-6 lg:px-8">
              <Table<ContactImportData>
                baseURL={buildAPIRoute(API_ROUTES.CONTACT_IMPORT_RESULT_LIST)}
                initialSearchParams={{ contact_import: contactImport.id }}
                paginated={true}
                tableHead={
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                      >
                        {t("phone", "Phone")}
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                      >
                        {t("status", "Status")}
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                      >
                        {t("result", "Result")}
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                      >
                        {t("errors.label", "Errors")}
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                      >
                        {t("contacts:data-with-error", "Data with error")}
                      </th>
                    </tr>
                  </thead>
                }
                tableRow={(data) => (
                  <>
                    <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                      {formatPhone(data.phone, true, true)}
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                      <ContactImportDataStatusBadge
                        status={data.status as ContactImportDataStatusEnum}
                      />
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                      <ContactImportDataResultBadge
                        result={data.result as ResultEnum}
                      />
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                      {data.errors ? (
                        <>
                          {Object.getOwnPropertyNames(data.errors).map(
                            (fieldName) => (
                              <p
                                className="text-sm text-red-400"
                                key={fieldName}
                              >
                                <span className="font-base font-bold">
                                  {fieldName}
                                </span>{" "}
                                {/* @ts-ignore */}-{" "}
                                {data.errors[fieldName].error}
                              </p>
                            )
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                      {data.errors ? (
                        <>
                          {Object.getOwnPropertyNames(data.errors).map(
                            (fieldName) => (
                              <pre
                                className="text-sm text-gray-500"
                                key={fieldName}
                              >
                                {/* @ts-ignore */}
                                {data.errors[fieldName].original_value}
                              </pre>
                            )
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                  </>
                )}
                dataName={t(
                  "contacts:contact-import-data-rows",
                  "CSV Import Rows"
                )}
                noResults={
                  <>
                    <Trans i18nKey="contacts:filter-no-results">
                      <h4>Couldn't find any results with these filters.</h4>
                      <p className="text-sm text-gray-500">
                        Remove filters to try again.
                      </p>
                    </Trans>
                  </>
                }
                createObject={
                  <h4 className="text-sm text-gray-500">
                    {t(
                      "contacts:no-results-import",
                      "Couldn't find any results for this import yet."
                    )}
                  </h4>
                }
              ></Table>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!loading && (
            <div className="d-flex min-vh-100 align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-5 col-xl-4 my-5">
                    <div className="text-center">
                      <h6 className="text-uppercase text-muted mb-4">
                        {t("errors.error-404", "Error 404")}
                      </h6>

                      <h1 className="display-4 mb-3">
                        {t("contacts:import-not-found", "Import not found")}
                      </h1>

                      <Link
                        to={buildRoute("contact-list")}
                        className="btn btn-primary mt-4"
                      >
                        <span className="fe fe-arrow-left mr-2"></span>
                        {t("contacts:go-to-import", "Go to import list")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
