import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import {
  ConversationsSummaryByTagInPeriodData,
  InboxUsageSummaryByTagData,
} from "@hilos/types/analytics";
import {
  LocalTableCellFormat,
  LocalTableHeaderFormat,
} from "src/components/LocalTable";
import { API_ROUTES } from "../router/router";

export function useInboxUsageSummaryConversations(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url =
      API_ROUTES.ANALYTICS_INBOX_USAGE_TAG_SUMMARY_CONVOS_PER_TAG +
      "?" +
      params;
    const { data } = await axios.get(url);
    return data;
  }, [params]);

  const { data } = useQuery(
    "inbox_usage_summary_by_tag_conversations" + params,
    fetchUsageSummary,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    inboxUsageSummaryConversations: data || undefined,
  };
}

export function useInboxUsageSummaryAnalytics(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url = API_ROUTES.ANALYTICS_INBOX_USAGE_TAG_SUMMARY + "?" + params;
    const { data } = await axios.get<InboxUsageSummaryByTagData>(url);
    return data;
  }, [params]);

  const { data } = useQuery(
    "inbox_usage_summary_by_tag" + params,
    fetchUsageSummary,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { t } = useTranslation();

  const columnsTable = useMemo(() => {
    const columnHelper =
      createColumnHelper<ConversationsSummaryByTagInPeriodData>();
    const defaultColumns = [
      columnHelper.accessor((row) => row?.tag, {
        id: "tag",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:tag", "Tag")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.received_chats, {
        id: "received_chats",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:received-chats", "Received chats")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.distinct_contacts, {
        id: "distinct_contacts",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:distinct-contacts", "Distinct contacts")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_duration, {
        id: "average_duration",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:table.average-duration",
                "Average duration (HH:MM:SS)"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_first_response, {
        id: "average_first_response",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:table.average-first-response",
                "Average first response (HH:MM:SS)"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_satisfaction_rating, {
        id: "average_satisfaction_rating",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:table.average-rating", "Average rating")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
    ];
    return defaultColumns;
  }, [t]);

  return {
    columnsTable,
    inboxUsageSummary: data || ({} as InboxUsageSummaryByTagData),
  };
}
