import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const FlowStepContactUpdateMeta = {
  getInitialValues: function (step) {
    return {
      name: step?.name || "",
      step_type: "CONTACT_UPDATE",
      contact_first_name: "",
      contact_last_name: "",
      contact_email: "",
      contact_external_url: "",
      contact_custom_properties: [],
      next_step_default_idx: step?.next_step_default_idx || "",
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
    });
  },
  validate: function (formValues, step) {
    // No extra validations needed
    return null;
  },
  formatForSave: function (step) {
    return step;
  },
  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    contact_first_name: {
      key: "contact_first_name",
      label: "'Append tags?",
      help: "If set these tags will be appended instead of replacing existing ones.",
    },
    contact_tags: {
      key: "contact_tags",
      label: "Contact tags",
      help: "Tags to add to the contact",
    },
    conversation_tags: {
      key: "conversation_tags",
      label: "Conversation tags",
      help: "Tags to add to the conversation",
    },
    next_step_default_idx: {
      key: "next_step_default_idx",
      label: "Next step",
      help: "If you can't find the step you'd like to go to next, check that it has a name.",
      placeholder: "Step 1 Welcome",
    },
  },
};
