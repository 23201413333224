import { useTranslation } from "react-i18next";
import { ConversationStatusEnum } from "@hilos/types/private-schema";
import { CONVERSATION_STATUS } from "../InboxMeta";

interface ConversationStatusBadgeProps {
  status?: ConversationStatusEnum | null;
}

export default function ConversationStatusBadge({
  status,
}: ConversationStatusBadgeProps) {
  const { t } = useTranslation();

  if (!status || !CONVERSATION_STATUS[status]) {
    return null;
  }

  return (
    <span className="inline-flex items-center rounded bg-blue-100 px-1.5 text-xs font-medium text-blue-800 ml-auto">
      {t(CONVERSATION_STATUS[status].label)}
    </span>
  );
}
