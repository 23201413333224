import { useEffect } from "react";
import { FlowExecutionStatusEnum } from "@hilos/types/private-schema";
import useCreateStateButton from "src/hooks/useCreateStateButton";
import { useFlowExecutionUpdateDetails } from "src/hooks/useFlowExecution";
import {
  FlowExecutionStatus,
  FlowExecutionStatusPayload,
} from "src/types/flows/FlowExecution";

interface useCreateStateButtonsHookArgs {
  flowExecutionId: string;
  flowExecutionStatus?: FlowExecutionStatusEnum;
  onFlowExecutionStatusUpdateSuccess: (
    nextStatus: FlowExecutionStatusPayload
  ) => void;
}

export function useCreateStateButtonsHook({
  flowExecutionId,
  flowExecutionStatus,
  onFlowExecutionStatusUpdateSuccess,
}: useCreateStateButtonsHookArgs) {
  const { handleUpdateFlowExecutionStatus, isSubmittingStatus } =
    useFlowExecutionUpdateDetails({
      flowExecutionId,
      onFlowExecutionStatusUpdateSuccess,
    });

  const [cancelState, handleCancelState] = useCreateStateButton({
    handleActionButton: handleUpdateFlowExecutionStatus,
  });

  const [confirmState, handleConfirmState] = useCreateStateButton({
    handleActionButton: handleUpdateFlowExecutionStatus,
  });

  const stateButtonBtnClasses = {
    btnClassesSuccess:
      "focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-100 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm",
    btnClassesError:
      "focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-50 px-3 py-2 text-sm font-medium leading-4 text-yellow-800 shadow-sm",
  };

  // Check which buttons need to be enabled

  useEffect(() => {
    let deleteDisabledInitial = true;

    switch (flowExecutionStatus) {
      case "READY":
      case "RUNNING":
        deleteDisabledInitial = false;
        break;
    }
    cancelState.setDisabled(deleteDisabledInitial);
  }, [cancelState, flowExecutionStatus]);

  const handleCancel = handleCancelState({
    status: FlowExecutionStatus.CANCELED,
  });
  const handleConfirm = handleConfirmState({
    status: FlowExecutionStatus.CONFIRMED,
  });

  return {
    cancelState,
    handleCancel,

    confirmState,
    handleConfirm,

    isSubmittingStatus,

    stateButtonBtnClasses,
  };
}
