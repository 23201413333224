import { useTranslation } from "react-i18next";
import { CalendarIcon } from "@heroicons/react/outline";
import DatePickerField from "src/components/Form/DatePickerField";
import SwitchField from "src/components/Form/SwitchField";
import { useStepField } from "../../../hooks/useStepField";

interface FlowBuilderStepQuestionDateProps {
  index: number;
}

function FlowBuilderStepQuestionDate({
  index,
}: FlowBuilderStepQuestionDateProps) {
  const [t] = useTranslation();
  const [answerHasRange] = useStepField({
    index,
    name: "answer_has_range",
  });

  return (
    <>
      <SwitchField
        name={`steps.${index}.answer_has_range`}
        label={t(
          "flows:steps.question.answer-has-range.label",
          "Limit answers to values in a specific range?"
        )}
      />
      {answerHasRange && (
        <div className="grid grid-cols-2 items-start space-x-4">
          <div>
            <DatePickerField
              name={`steps.${index}.answer_range_min`}
              label={t(
                "flows:steps.question.answer-range-min.label",
                "Minimum value expected (included)"
              )}
              dateFormat="dd/MM/yyyy"
              icon={CalendarIcon}
            />
          </div>
          <div>
            <DatePickerField
              name={`steps.${index}.answer_range_max`}
              label={t(
                "flows:steps.question.answer-range-max.label",
                "Maximum value expected (included)"
              )}
              dateFormat="dd/MM/yyyy"
              icon={CalendarIcon}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default FlowBuilderStepQuestionDate;
