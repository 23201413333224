import { useTranslation } from "react-i18next";
import { classNames } from "src/Helpers";
import { ERROR_MESSAGES } from "src/constants/errors";

interface FlowBuilderTestFlowExecutionVariablesProps {
  requiredVariables: string[];
  flowExecutionVariables;
  setFlowExecutionVariables;
  allVariablesProvided: boolean;
}
export default function FlowBuilderTestFlowExecutionVariables({
  requiredVariables,
  flowExecutionVariables,
  setFlowExecutionVariables,
  allVariablesProvided,
}: FlowBuilderTestFlowExecutionVariablesProps) {
  const { t } = useTranslation();

  const handleVariableUpdate = (varName: string, value: string) => {
    const nextFlowExecutionVariables = { ...flowExecutionVariables };
    nextFlowExecutionVariables[varName] = value;
    setFlowExecutionVariables(nextFlowExecutionVariables);
  };

  return (
    <div className="rounded-md bg-blue-50 p-4 mt-2">
      <h3 className="text-sm text-blue-700">
        {t(
          "flows:test-modal.flow-execution-variables",
          "Your flow uses these flow execution variables. Provide a value for each so you can properly test."
        )}
      </h3>
      <ul className="space-y-2 pt-2">
        {requiredVariables.map((varName, idx) => (
          <li key={idx} className="flex flex-row gap-2 items-center">
            <code className="grow bg-blue-100 font-mono text-xs font-normal text-black p-2.5 rounded-md">
              {varName}
            </code>
            <div className="w-full">
              <input
                id={varName}
                name={varName}
                className={classNames(
                  "w-full border-none border-gray-300 placeholder-gray-400 sm:text-sm",
                  "focus:border-0 focus:outline-none focus:ring-0 sm:text-sm rounded-md",
                  "disabled:bg-gray-50 disabled:text-gray-300 disabled:placeholder-gray-200 p-2"
                )}
                value={flowExecutionVariables[varName]}
                onChange={(ev) =>
                  handleVariableUpdate(varName, ev.target.value)
                }
              />
            </div>
          </li>
        ))}
      </ul>
      {!allVariablesProvided && (
        <div className="text-xs mt-1.5 text-red-500">
          {t(ERROR_MESSAGES.REQUIRED)}
        </div>
      )}
    </div>
  );
}
