import {
  ClipboardCopyIcon,
  ExclamationIcon,
  ExternalLinkIcon,
  PhoneIcon,
  ViewListIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Fragment, useMemo, useState } from "react";
import {
  TemplateContentValues,
  getButtonVarURLIndex,
  getTemplateComponents,
  hasVarText,
  interpolateTextValue,
} from "../../helpers/template";

import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import { WhatsAppTemplateSimpleRead } from "@hilos/types/private-schema";
import document_placeholder from "../../assets/img/wa/template_document_placeholder.svg";
import image_placeholder from "../../assets/img/wa/template_image_placeholder.svg";
import location_placeholder from "../../assets/img/wa/template_location_placeholder.svg";
import { useTranslation } from "react-i18next";
import video_placeholder from "../../assets/img/wa/template_video_placeholder.svg";

interface WhatsAppTemplatePreviewProps {
  template: WhatsAppTemplate | WhatsAppTemplateSimpleRead;
  values: TemplateContentValues;
  handleSelectMedia?: () => void;
}

function WhatsAppTemplatePreview({
  template,
  values,
  handleSelectMedia,
}: WhatsAppTemplatePreviewProps) {
  const { t } = useTranslation();
  const [showList, setShowList] = useState(false);
  const { header, body, footer, buttons } = getTemplateComponents(template);

  const { locationHeaderVars, headerURL, headerVar, bodyVars, buttonVars } =
    useMemo(() => values || {}, [values]);

  const authenticationTemplateBody = useMemo(() => {
    const baseText = t(
      "templates:template-content.authentication.body.base-text",
      "{{verificationCode}} is your verification code.",
      {
        verificationCode:
          bodyVars && bodyVars[0] && bodyVars[0].length > 0
            ? bodyVars[0]
            : "{{ OTP Code}}",
      }
    );
    const addSecurityRecommendationText = t(
      "templates:template-content.authentication.body.add-security-recommendation",
      "For your security, do not share this code."
    );
    let finalTextComponents = baseText;
    if (body?.add_security_recommendation) {
      finalTextComponents += " " + addSecurityRecommendationText;
    }
    return finalTextComponents;
  }, [body?.add_security_recommendation, bodyVars, t]);

  return (
    <div className="flex items-center justify-around">
      <div className="w-80 space-y-2">
        <div className="space-y-2 rounded-lg bg-whatsapp-outgoing py-2 px-3 text-left text-sm text-gray-900">
          {header && (
            <>
              {header.format === "TEXT" ? (
                <div className="font-bold">
                  {interpolateTextValue(header.text, [headerVar])}
                </div>
              ) : (
                <div className="font-medium">
                  {header.format === "IMAGE" && (
                    <button
                      onClick={() => handleSelectMedia && handleSelectMedia()}
                      className="flex max-h-40 overflow-hidden rounded-lg"
                    >
                      <img
                        src={headerURL || image_placeholder}
                        className="w-full rounded-lg"
                        alt="Preview for template"
                      />
                    </button>
                  )}
                  {header.format === "DOCUMENT" && (
                    <button
                      onClick={() => handleSelectMedia && handleSelectMedia()}
                    >
                      <img
                        src={document_placeholder}
                        className="w-full rounded-lg"
                        alt="Preview for template"
                      />
                    </button>
                  )}
                  {header.format === "VIDEO" && (
                    <img
                      src={video_placeholder}
                      className="w-full rounded-lg"
                      alt="Preview for template"
                    />
                  )}
                  {header.format === "LOCATION" && (
                    <div>
                      <img
                        src={location_placeholder}
                        className="w-full rounded-lg"
                        alt="Preview for template"
                      />
                      <div className="p-2 text-xs bg-white bg-opacity-25">
                        <p className="font-bold">
                          {locationHeaderVars?.name ||
                            t(
                              "templates:format-types.location.title",
                              "{{Location Name}}"
                            )}
                        </p>
                        <p className="text-gray-500">
                          {locationHeaderVars?.address ||
                            t(
                              "templates:format-types.location.subtitle",
                              "{{Adress}}"
                            )}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {template.category === "AUTHENTICATION" ? (
            <div className="whitespace-pre-line break-words">
              {authenticationTemplateBody}
            </div>
          ) : (
            <div className="whitespace-pre-line break-words">
              {body && interpolateTextValue(body.text, bodyVars)}
              {body && !body.text && (
                <div className="mb-4 flex items-center bg-yellow-50 px-4 py-3 text-sm text-yellow-700">
                  <ExclamationIcon className="mr-3 h-6 w-6" />
                  {t(
                    "templates:template-body-required",
                    "Template body required."
                  )}
                </div>
              )}
            </div>
          )}

          {template.category === "AUTHENTICATION" ? (
            <>
              {footer?.code_expiration_minutes && (
                <div className="text-xs text-gray-500">
                  {t(
                    "templates:template-content.authentication.body.add-code-expiration-minutes",
                    "This code expires in {{minutes}} minutes.",
                    {
                      minutes: footer?.code_expiration_minutes || 10,
                    }
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {footer && (
                <div className="text-xs text-gray-500">{footer.text}</div>
              )}
            </>
          )}
        </div>

        {buttons && (
          <div className="space-y-1">
            {buttons.buttons.map(
              (button, idx) =>
                button.text &&
                idx < 3 && (
                  <Fragment key={idx}>
                    {buttons.buttons.length > 3 && idx === 2 ? (
                      <button
                        type="button"
                        onClick={() => setShowList(true)}
                        className="w-full flex items-center justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
                      >
                        <ViewListIcon className="w-4 h-4 mr-4" />
                        {t(
                          "templates:template-content.button-list",
                          "See list"
                        )}
                      </button>
                    ) : (
                      <>
                        {button.type === "QUICK_REPLY" && (
                          <button
                            type="button"
                            className="w-full rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
                          >
                            {t(button.text, button.text)}
                          </button>
                        )}
                        {button.type === "PHONE_NUMBER" && (
                          <a
                            href={`tel:${button.phone_number}`}
                            className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
                          >
                            <PhoneIcon className="mr-2 h-4 w-4" />
                            {t(button.text, button.text)}
                          </a>
                        )}
                        {button.type === "URL" && (
                          <a
                            href={interpolateTextValue(button.url, [
                              hasVarText(button.url) && buttonVars
                                ? buttonVars[
                                    getButtonVarURLIndex(buttons.buttons, idx)
                                  ]
                                : null,
                            ])}
                            target="_blank"
                            rel="noreferrer"
                            className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100 "
                          >
                            <ExternalLinkIcon className="mr-2 h-4 w-4" />
                            {t(button.text, button.text)}
                          </a>
                        )}
                      </>
                    )}
                    {button.type === "OTP" && (
                      <div className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100 last:col-span-2">
                        <ClipboardCopyIcon className="mr-2 h-4 w-4" />
                        {t(button.text, button.text)}
                      </div>
                    )}
                  </Fragment>
                )
            )}
          </div>
        )}
        {showList && (
          <div className=" bg-white rounded-t-md p-2 shadow">
            <div className="relative">
              <div className="flex my-2 justify-center">
                <hr className="h-1 w-10 bg-gray-400 rounded-md" />
              </div>
              <div className="mb-4 text-center text-sm font-semibold">
                {t("templates:template-content.all-options", "All options")}
              </div>
              <button
                onClick={() => setShowList(false)}
                className="absolute right-0 top-0"
              >
                <XIcon className="w-5 h-5 text-black" />
              </button>
            </div>

            {buttons && (
              <div className="space-y-1">
                {buttons.buttons.map(
                  (button, idx) =>
                    button.text && (
                      <Fragment key={idx}>
                        {button.type === "QUICK_REPLY" && (
                          <button
                            type="button"
                            className="w-full rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
                          >
                            {t(button.text, button.text)}
                          </button>
                        )}
                        {button.type === "PHONE_NUMBER" && (
                          <a
                            href={`tel:${button.phone_number}`}
                            className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
                          >
                            <PhoneIcon className="mr-2 h-4 w-4" />
                            {t(button.text, button.text)}
                          </a>
                        )}
                        {button.type === "URL" && (
                          <a
                            href={interpolateTextValue(button.url, [
                              buttonVars ? buttonVars[idx] : null,
                            ])}
                            target="_blank"
                            rel="noreferrer"
                            className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100 "
                          >
                            <ExternalLinkIcon className="mr-2 h-4 w-4" />
                            {t(button.text, button.text)}
                          </a>
                        )}

                        {button.type === "OTP" && (
                          <div className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100 last:col-span-2">
                            <ClipboardCopyIcon className="mr-2 h-4 w-4" />
                            {t(button.text, button.text)}
                          </div>
                        )}
                      </Fragment>
                    )
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default WhatsAppTemplatePreview;
