import { useCallback } from "react";
import ReactDatePicker from "react-datepicker";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useField, useFormikContext } from "formik";
import { classNames } from "../../Helpers";
import FieldContainer from "./FieldContainer";

import "react-datepicker/dist/react-datepicker.css";
import "../base/DatePicker.css";

export interface DatePickerFieldProps {
  name: string;
  label?: string;
  help?: string;
  optional?: boolean;
  dateFormat?: string;
  placeholder?: string;
  showTimeInput?: boolean;
  timeInputLabel?: string;
  minDate?: Date;
  className?: string;
  containerClassName?: string;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
}

function DatePickerField({
  name,
  label,
  help,
  optional,
  dateFormat = "dd/MM/yyyy",
  icon: RenderIcon,
  className,
  containerClassName,
  ...props
}: DatePickerFieldProps) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const error = meta.touched && meta.error;

  const handleChange = useCallback(
    (value) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name]
  );

  return (
    <FieldContainer
      name={name}
      label={label}
      help={help}
      optional={optional}
      error={error}
    >
      <div
        className={
          containerClassName || "relative mt-1 flex rounded-md shadow-sm"
        }
      >
        <>
          {RenderIcon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
              <RenderIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          )}
          <ReactDatePicker
            {...props}
            name={name}
            dateFormat={dateFormat}
            className={classNames(
              "z-10 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
              RenderIcon && "pl-10",
              error && "border-red-500",
              className
            )}
            autoComplete="off"
            selected={(field.value && new Date(field.value)) || null}
            onChange={handleChange}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </>
      </div>
    </FieldContainer>
  );
}

export default DatePickerField;
