import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "../../components/Form/SwitchField";
import TextInputField from "../../components/Form/TextInputField";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import useHilosStore from "../../hooks/useHilosStore";
import * as meta from "./UserProfileMeta";

const formatOptionLabel = ({ value, label, flag, ...props }) => (
  <div className="flex items-center justify-between py-1">
    <div className="flex items-center">
      <div className="mx-2">{flag}</div>
      <div>
        <h4 className="font-medium">{label}</h4>
      </div>
    </div>
  </div>
);

export default function UserProfileForm({ user }) {
  const { t, i18n } = useTranslation();
  const { updateSession } = useHilosStore();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = async (formData) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSubmitted(false);
    try {
      await updateSession(formData);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      if (error.response && error.response.status === 400) {
        setBackendValidationErrors(error.response.data);
      } else {
        setBackendError(
          t(
            "settings:error-saving-data",
            "Ocurrió un error al guardar tus datos, intenta de nuevo."
          )
        );
      }
    } finally {
      setSubmitted(true);
    }
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto mt-6 max-w-3xl">
        <Formik
          enableReinitialize
          validationSchema={meta.schema}
          onSubmit={onSubmit}
          initialValues={user}
        >
          {(formik) => (
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              className="space-y-4"
            >
              <div className="my-4">
                <APIErrors
                  APIError={backendError}
                  APIValidationErrors={backendValidationErrors}
                  fieldTranslations={meta.FIELDS}
                ></APIErrors>
              </div>

              <TextInputField
                placeholder={t(meta.FIELDS.first_name.placeholder)}
                label={t(meta.FIELDS.first_name.label)}
                name={meta.FIELDS.first_name.key}
                type="text"
              />

              <TextInputField
                placeholder={t(meta.FIELDS.last_name.placeholder)}
                label={t(meta.FIELDS.last_name.label)}
                name={meta.FIELDS.last_name.key}
                type="text"
              />

              <TextInputField
                // @ts-ignore
                label={t("components:input.email.label")}
                type="email"
                name={meta.FIELDS.email.key}
                readOnly
                help={
                  <>
                    <Trans i18nKey="settings:switch-email-accounts">
                      <div className="flex items-center">
                        <ExclamationCircleIcon className="h4 w-4 mr-1" />
                        <p>
                          Do you need to switch email accounts? Let us know at{" "}
                          <a
                            href="mailto:hey@hilos.io"
                            className="text-blue-500"
                          >
                            hey@hilos.io
                          </a>
                          .
                        </p>
                      </div>
                    </Trans>
                  </>
                }
              />

              <SelectorField
                label={t(meta.FIELDS.default_language.label)}
                name={meta.FIELDS.default_language.key}
                options={[
                  {
                    label: "English",
                    value: "en",
                    flag: "🇺🇸",
                  },
                  {
                    label: "Español",
                    value: "es",
                    flag: "🇲🇽",
                  },
                ]}
                formatOptionLabel={formatOptionLabel}
                onSelect={(nextLang) => i18n.changeLanguage(nextLang.value)}
              />

              <SwitchField
                label={t(
                  meta.FIELDS.notify_when_msg_comes_in_after_hours.label
                )}
                name={meta.FIELDS.notify_when_msg_comes_in_after_hours.key}
                help={t(meta.FIELDS.notify_when_msg_comes_in_after_hours.help)}
              />

              <div className="mt-5">
                <div className="text-right">
                  <StateButton
                    isSubmitting={formik.isSubmitting}
                    submitted={submitted}
                    success={success}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
