export enum FlowExecutionContactStatus {
  READY = "READY",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}

export interface FlowExecutionContactStatusPayload {
  status: FlowExecutionContactStatus;
}
