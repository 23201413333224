import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ReplyIcon } from "@heroicons/react/outline";
import { WhatsAppMessage } from "@hilos/types/private-schema";
import ForwardMessageForm from "./ForwardMessageForm";

interface ForwardMessageModalProps {
  message: WhatsAppMessage;
  handleSelectedForwardMessage: (message: WhatsAppMessage | undefined) => void;
}

export default function ForwardMessageModal({
  message,
  handleSelectedForwardMessage,
}: ForwardMessageModalProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    handleSelectedForwardMessage(undefined);
  };

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="flex text-gray-800 items-center font-bold">
                  <div className="h-10 w-10 rounded-full flex items-center justify-center text-blue-500 mr-4 bg-blue-100">
                    <ReplyIcon className="h-6 w-6 -scale-x-100" />
                  </div>
                  <div>
                    <div className="text-lg">
                      {t("inbox:forward-message", "Forward message")}
                    </div>
                    <div className="text-sm text-gray-800 font-normal">
                      {t(
                        "inbox:forward-message-description",
                        "Choose one or more contacts to forward this message to."
                      )}
                    </div>
                  </div>
                </div>

                <ForwardMessageForm setShow={handleClose} message={message} />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
