import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";

import { XIcon } from "lucide-react";
import { useCustomFields } from "src/hooks/useContactCustomField";
import { useTranslation } from "react-i18next";

interface CreateVariableModalProps {
  isOpen: boolean;
  onClose: () => void;
  addVariable: (id: string, isNewField: boolean) => void;
  inputValue: string;
}

function Input({
  value,
  setValue,
  inputRef,
}: {
  value: string;
  setValue: (value: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {t("contacts:create_variable_modal.label", "New variable name")}
      </label>
      <div className="mt-2 flex rounded-md shadow-sm overflow-clip">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 bg-slate-200 text-gray-700 flex items-center px-3">
            {`{{contact.`}
          </div>
          <input
            id="variable"
            name="variable"
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            ref={inputRef}
            placeholder="account"
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-24 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-200 sm:text-sm sm:leading-6"
          />
          <div className="pointer-events-none bg-slate-200 absolute inset-y-0 text-gray-700 right-0 flex items-center px-3">
            {`}}`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CreateVariableModal({
  isOpen,
  onClose,
  addVariable,
  inputValue,
}: CreateVariableModalProps) {
  const [variable, setVariable] = useState(inputValue);
  const { createCustomField } = useCustomFields();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    await createCustomField({ name: variable, field_type: "STRING" });
    addVariable(`contact.${variable}`, true);
    onClose();
  };

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900 flex flex-row space-between"
              >
                <span>
                  {t(
                    "contacts:create_variable_modal.title",
                    "Create a new contact variable"
                  )}
                </span>
                <button onClick={onClose} className="ml-auto">
                  <XIcon className="ml-2 h-4 w-4" />
                </button>
              </Dialog.Title>
              <div className="mt-2">
                <Input
                  value={variable}
                  setValue={setVariable}
                  inputRef={inputRef}
                />
              </div>
              <div className="mt-4 flex flex-row justify-end">
                <button
                  type="button"
                  className="inline-flex justify-center w-fit rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={handleSubmit}
                >
                  {t("contacts:create_variable_modal.create", "Add variable")}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
