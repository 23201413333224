import { Trans, useTranslation } from "react-i18next";
import SwitchField from "../../components/Form/SwitchField";
import TextInputField from "../../components/Form/TextInputField";
import AccountCSATFormComponent from "./AccountCSATFormComponent";
import * as meta from "./AccountMeta";

export default function AccountInboxConversationComponent({ formik }) {
  const { t } = useTranslation();

  return (
    <div className="mt-10 sm:mt-4">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <Trans i18nKey="settings:conversation-settings.description">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Conversation settings
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Settings that apply to every conversation.
              </p>
            </Trans>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="self-center border-b border-gray-200 bg-white px-4 py-4 sm:p-6">
              <div>
                <SwitchField
                  name={meta.FIELDS.should_autoclose_conversations.key}
                  label={t(meta.FIELDS.should_autoclose_conversations.label)}
                />
              </div>

              {formik.values[
                meta.FIELDS.should_autoclose_conversations.key
              ] && (
                <div className="mt-2">
                  <TextInputField
                    placeholder={
                      meta.FIELDS.autoclose_conversations_after.placeholder
                    }
                    label={t(meta.FIELDS.autoclose_conversations_after.label)}
                    name={meta.FIELDS.autoclose_conversations_after.key}
                    help={t(meta.FIELDS.autoclose_conversations_after.help)}
                    type="number"
                    max={168}
                    min={1}
                  />
                </div>
              )}
            </div>

            <div className="self-center border-b border-gray-200 bg-white px-4 py-4 sm:p-6">
              <SwitchField
                name={meta.FIELDS.add_as_default_assignee.key}
                label={t(meta.FIELDS.add_as_default_assignee.label)}
                help={t(meta.FIELDS.add_as_default_assignee.help)}
              />
            </div>

            <div className="self-center border-b border-gray-200 bg-white px-4 py-4 sm:p-6">
              <SwitchField
                name={meta.FIELDS.archive_conversations_when_closed.key}
                label={t(meta.FIELDS.archive_conversations_when_closed.label)}
                help={t(meta.FIELDS.archive_conversations_when_closed.help)}
              />
            </div>

            <div className="border-b border-gray-200 bg-white px-4 py-4 sm:p-6">
              <SwitchField
                name={
                  meta.FIELDS.measure_customer_satisfaction_per_conversation.key
                }
                label={t(
                  meta.FIELDS.measure_customer_satisfaction_per_conversation
                    .label
                )}
                help={t(
                  meta.FIELDS.measure_customer_satisfaction_per_conversation
                    .help
                )}
              />
            </div>
            {formik.values[
              meta.FIELDS.measure_customer_satisfaction_per_conversation.key
            ] && <AccountCSATFormComponent formik={formik} />}
          </div>
        </div>
      </div>
    </div>
  );
}
