import "react-funnel-pipeline/dist/index.css";

import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import {
  ChatAlt2Icon,
  ChatIcon,
  ExclamationIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { formatPhone, getPercentage } from "../../Helpers";
import { useMemo, useState } from "react";

import { Broadcast } from "@hilos/types/broadcast";
import BroadcastCSVDownload from "./BroadcastCSVDownload";
import { DateTime } from "luxon";
import DeleteObject from "../../components/DeleteObject";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunnelChart } from "react-funnel-pipeline";
import HeaderNavigation from "../../components/HeaderNavigation";
import { MessageBlastRecipient } from "@hilos/types/private-schema";
import MessageModalPreview from "./MessageModalPreview";
import Table from "../../components/Table/Table";
import { TemplateMeta } from "../flow/FlowStepMeta";
import Tooltip from "src/components/Tooltip";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import WhatsAppTemplatePreview from "../wa/WhatsAppTemplatePreview";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import useBroadcast from "src/hooks/useBroadcast";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { useTranslation } from "react-i18next";

export default function BroadcastDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const cleanTimeout = useCleanTimeout();

  const [loading, setLoading] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const messageBlast = useBroadcast(params.id as string);

  const sendDateIsPast = useMemo(() => {
    if (messageBlast?.start_on) {
      if (
        DateTime.fromISO(messageBlast?.start_on) < DateTime.now() &&
        !["PENDING", "AWAITING_CONFIRMATION"].includes(
          messageBlast?.status as string
        )
      ) {
        return true;
      }
      return false;
    } else {
      if (
        DateTime.fromISO(messageBlast?.created_on as string) < DateTime.now() &&
        !["PENDING", "AWAITING_CONFIRMATION"].includes(
          messageBlast?.status as string
        )
      ) {
        return true;
      }
      return false;
    }
  }, [messageBlast]);

  const sendDate = useMemo(
    () => messageBlast?.start_on || messageBlast?.created_on || null,
    [messageBlast]
  );

  interface FunnelChartRow {
    name: string;
    value: number;
  }

  const statusIcons = {
    Recipients: <UserGroupIcon className="mr-1 h-4 w-4" />,
    Sent: (
      <FontAwesomeIcon
        className="mr-1 h-4 w-4"
        // @ts-ignore
        icon={faCheck}
      />
    ),
    Delivered: (
      <FontAwesomeIcon
        className="mr-1 h-4 w-4"
        // @ts-ignore
        icon={faCheckDouble}
      />
    ),
    Read: (
      <FontAwesomeIcon
        className="mr-1 h-4 w-4"
        // @ts-ignore
        icon={faCheckDouble}
        color="blue"
      />
    ),
    Answered: <ChatAlt2Icon className="mr-1 h-4 w-4" />,
    Failed: <ExclamationCircleIcon className="mr-1 h-4 w-4 " />,
  };

  function getFunnelChartFormattedValue(
    row: FunnelChartRow,
    index: number,
    data: FunnelChartRow[]
  ) {
    const icon: JSX.Element | undefined = statusIcons[row.name];

    return (
      <>
        <div className="flex flex-row items-center justify-center">
          {icon && icon}
          {row.value}
        </div>
      </>
    );
  }

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.MESSAGE_BLAST_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      navigate(buildRoute("broadcast-list"));
    } catch (err) {
      const axiosErr = err as axiosErr;
      setDeleteSuccess(false);
      if (axiosErr?.response?.status === 400) {
        console.error(err);
        setBackendValidationErrors(axiosErr.response.data);
      } else {
        setBackendError(
          t(
            "broadcasts:broadcast-delete-error",
            "An error occurred while attempting to delete this Broadcast, please try again."
          )
        );
      }
      cleanTimeout.current = setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  return (
    <>
      {messageBlast ? (
        <div
          className="h-screen space-y-4 overflow-y-auto bg-gray-50"
          data-tour="broadcast-details"
        >
          <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
            <HeaderNavigation
              navPages={[
                {
                  name: t("home"),
                  url: buildRoute("dashboard"),
                },
                {
                  name: t("broadcasts"),
                  url: buildRoute("broadcast-list"),
                },
                {
                  name: messageBlast.name,
                  url: buildRoute("broadcast-detail", {
                    ":id": messageBlast.id,
                  }),
                },
              ]}
            />
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <ChatIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                  {messageBlast.name}
                </h2>
              </div>
            </div>
          </div>
          <div className="my-6 grid  grid-cols-1  items-center gap-4 px-6 lg:grid-cols-2">
            <div className="col-span-1 h-full">
              <div
                className="h-full rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6"
                data-tour="broadcast-summay"
              >
                <div className="grid h-full grid-cols-1 2xl:grid-cols-2">
                  <div className="mt-2 flex flex-col items-start justify-evenly text-sm sm:h-full">
                    <span
                      data-tour="broadcast-status"
                      className=" items-center rounded-full bg-indigo-100 px-2.5 py-1 text-xs font-medium text-indigo-800"
                    >
                      {t(
                        `broadcasts:status.${
                          messageBlast.status?.toLowerCase() ?? "pending"
                        }`
                      ).toUpperCase()}
                    </span>
                    <div className="text-gray-400">
                      {sendDateIsPast ? (
                        <>{t("sent-on", "Sent on")} </>
                      ) : (
                        <>{t("scheduled-on", "Scheduled on")} </>
                      )}
                      {sendDate &&
                        DateTime.fromISO(sendDate).toLocaleString(
                          DateTime.DATETIME_SHORT_WITH_SECONDS
                        )}
                    </div>

                    <div className="my-2 flex flex-row justify-center w-full">
                      <div className="my-2 flex flex-col items-center">
                        <div className="max-w-[200px] space-y-2">
                          <BroadcastCSVDownload
                            className="w-full"
                            broadcast={messageBlast as unknown as Broadcast}
                          />
                        </div>
                        <div className="w-[200px] m-2">
                          <DeleteObject
                            obj={messageBlast}
                            objDescription={messageBlast.name}
                            actionDescription={t("broadcasts:delete-object")}
                            onDelete={onDelete}
                            deleteSuccess={deleteSuccess}
                            deleteSubmitted={deleteSubmitted}
                            buttonClasses="inline-flex items-center h-full justify-center w-full px-3 py-2 border border-red-600 shadow-sm text-sm leading-4 font-medium rounded-md text-red-600 bg-white hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-tour="broadcast-template-preview" className="my-4">
                    <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                      {t("message-template")} -{" "}
                      {messageBlast.whatsapp_template.name}
                    </h3>
                    <div className="mt-2 flex h-full flex-row items-center justify-center">
                      <WhatsAppTemplatePreview
                        template={
                          messageBlast.whatsapp_template as unknown as WhatsAppTemplate
                        }
                        values={TemplateMeta.getInitialValues()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 h-full">
              <div className="flex h-full flex-col items-stretch justify-center rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
                <FunnelChart
                  data={[
                    {
                      name: t("recipients"),
                      value: messageBlast.recipients.length,
                    },
                    { name: t("failed"), value: messageBlast.failed },
                    { name: t("sent"), value: messageBlast.sent },
                    { name: t("delivered"), value: messageBlast.delivered },
                    { name: t("read"), value: messageBlast.read },
                    { name: t("answered"), value: messageBlast.answered },
                  ]}
                  pallette={[
                    "#102A43",
                    "#FF708A",
                    "#183E63",
                    "#1C4773",
                    "#205283",
                    "#245C94",
                  ]}
                  decorateValue={getFunnelChartFormattedValue}
                  getRowNameStyle={() => ({ fontSize: ".9rem" })}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end mt-2 text-gray-500 text-sm px-6 italic">
            (
            {t(
              "broadcasts:analytics.sync-time",
              "These analytics are updated every 30 minutes"
            )}
            )
          </div>
          <div
            className="mt-2 mb-4 grid grid-cols-2 grid-rows-3 gap-4 px-6 md:grid-cols-3 md:grid-rows-2 lg:grid-cols-6 lg:grid-rows-1"
            data-tour="broadcast-status"
          >
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <div className="inline-flex items-center">
                <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                  {t("recipients", "Recipients")}{" "}
                </h3>
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3 ">
                      {t(
                        "broadcasts:recipients-tooltip",
                        "How many contacts will receive this broadcast"
                      )}
                    </div>
                  }
                />
              </div>
              <p className="mt-2 text-3xl font-medium text-gray-900">
                {messageBlast.recipients.length}
              </p>
            </div>
            {messageBlast.pending > 0 && (
              <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
                <div className="inline-flex items-center">
                  <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                    {t("pending")}
                  </h3>
                  <Tooltip
                    content={
                      <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3">
                        {t(
                          "broadcasts:pending-tooltip",
                          "Messages pending to send"
                        )}
                      </div>
                    }
                  />
                </div>
                <p className="mt-2 text-3xl font-medium text-gray-900">
                  {messageBlast.pending}
                </p>
                <p className="mt-1 text-sm font-light text-gray-900">
                  {getPercentage(
                    messageBlast.pending,
                    messageBlast?.recipients.length
                  )}
                  %
                </p>
              </div>
            )}
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <div className="inline-flex items-center">
                <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                  {t("failed")}
                </h3>
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3">
                      {t(
                        "broadcasts:failed-tooltip",
                        "How many messages failed"
                      )}
                    </div>
                  }
                />
              </div>
              {messageBlast.failed > 0 ? (
                <>
                  <p className="mt-2 text-3xl font-medium text-red-600">
                    {messageBlast.failed}
                  </p>
                  <p className="mt-1 text-sm font-light text-red-600">
                    {getPercentage(
                      messageBlast.failed,
                      messageBlast?.recipients.length
                    )}
                    %
                  </p>
                </>
              ) : (
                <>
                  <p className="mt-2 text-3xl font-medium">
                    {messageBlast.failed}
                  </p>
                  <p className="mt-1 text-sm font-light">
                    {getPercentage(
                      messageBlast.failed,
                      messageBlast?.recipients.length
                    )}
                    %
                  </p>
                </>
              )}
            </div>
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <div className="inline-flex items-center">
                <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                  {t("sent")}
                </h3>
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3">
                      {t(
                        "broadcasts:sent-tooltip",
                        "Messages sent to whatsapp"
                      )}
                    </div>
                  }
                />
              </div>

              <p className="mt-2 text-3xl font-medium text-gray-900">
                {messageBlast.sent}
              </p>
              <p className="mt-1 text-sm font-light text-gray-900">
                {getPercentage(
                  messageBlast.sent,
                  messageBlast?.recipients.length
                )}
                %
              </p>
            </div>
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <div className="inline-flex items-center">
                <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                  {t("delivered", "Delivered")}
                </h3>
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3">
                      {t(
                        "broadcasts:delivered-tooltip",
                        "Messages sent by WhatsApp to the contact's phone"
                      )}
                    </div>
                  }
                />
              </div>

              <p className="mt-2 text-3xl font-medium text-gray-900">
                {messageBlast.delivered}
              </p>
              <p className="mt-1 text-sm font-light text-gray-900">
                {getPercentage(
                  messageBlast.delivered,
                  messageBlast?.recipients.length
                )}
                %
              </p>
            </div>
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <div className="inline-flex items-center">
                <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                  {t("read", "Read")}
                </h3>
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3">
                      {t(
                        "broadcasts:read-tooltip",
                        "Messages read (if the contact has the 2 blue ticks active)"
                      )}
                    </div>
                  }
                />
              </div>

              <p className="mt-2 text-3xl font-medium text-gray-900">
                {messageBlast.read}
              </p>
              <p className="mt-1 text-sm font-light text-gray-900">
                {getPercentage(
                  messageBlast.read,
                  messageBlast?.recipients.length
                )}
                %
              </p>
            </div>
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <div className="inline-flex items-center">
                <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                  {t("answered", "Answered")}
                </h3>
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3">
                      {t(
                        "broadcasts:answered-tooltip",
                        "If the user replied to this message"
                      )}
                    </div>
                  }
                />
              </div>

              <p className="mt-2 text-3xl font-medium text-gray-900">
                {messageBlast.answered}
              </p>
              <p className="mt-1 text-sm font-light text-gray-900">
                {getPercentage(
                  messageBlast.answered,
                  messageBlast?.recipients.length
                )}
                %
              </p>
            </div>
          </div>

          <div className="my-6 md:px-6" data-tour="broadcast-recipient-list">
            <Table<MessageBlastRecipient>
              baseURL={buildAPIRoute(API_ROUTES.MESSAGE_BLAST_RECIPIENT_LIST)}
              paginated={true}
              initialSearchParams={{
                message_blast: messageBlast.id,
              }}
              tableHead={
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("phone")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("message")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("status")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("failed-on", "Failed on")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("sent-on", "Sent on")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("delivered-on", "Delivered on")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("read-on", "Read on")}
                    </th>
                  </tr>
                </thead>
              }
              tableRow={(recipient) => (
                <>
                  <td className="whitespace-nowrap py-3 pl-4 text-sm font-medium text-gray-700">
                    {formatPhone(recipient.phone, true, true)}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm font-medium text-gray-700">
                    {recipient.message ? (
                      <MessageModalPreview
                        previewElement={
                          <span className="flex items-center font-medium text-blue-500">
                            {recipient.message.status === "failed" ? (
                              <ExclamationIcon className="mr-1 h-4 w-4 text-red-500" />
                            ) : (
                              <ChatIcon className="mr-1 h-4 w-4 text-gray-500" />
                            )}
                          </span>
                        }
                        message={recipient.message}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                    {recipient.message ? (
                      <>
                        {recipient.message.status === "failed" ? (
                          <>
                            <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium uppercase text-red-800">
                              {recipient.message.status}
                            </span>
                            <p className="mt-1 text-xs text-red-500">
                              {recipient.message.provider_error_message && (
                                <p className="mt-1">
                                  {recipient.message.provider_error_message}
                                </p>
                              )}
                              <p className="mt-1">
                                {t("error")}{" "}
                                {recipient.message.provider_error_code}
                              </p>
                            </p>
                          </>
                        ) : (
                          <span
                            data-tour="broadcast-recipient-status"
                            className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium uppercase text-green-800"
                          >
                            {t(recipient.message.status.toLowerCase())}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {recipient.status === "CANCELED" ? (
                          <>
                            <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium uppercase text-red-800">
                              {t("canceled")}
                            </span>
                            <p className="text-xs text-red-400">
                              {recipient.reason}
                            </p>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {recipient.message?.failed_on
                      ? DateTime.fromISO(
                          recipient.message.failed_on
                        ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {recipient.message?.sent_on
                      ? DateTime.fromISO(
                          recipient.message.sent_on
                        ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {recipient.message?.delivered_on
                      ? DateTime.fromISO(
                          recipient.message.delivered_on
                        ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {recipient.message?.read_on
                      ? DateTime.fromISO(
                          recipient.message.read_on
                        ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
                      : "-"}
                  </td>
                </>
              )}
              dataName={t("recipients", "recipients")}
              noResults={
                <>
                  <h4>
                    {t(
                      "broadcasts:no-recipients",
                      "Couldn't find any recipients for this broadcast."
                    )}
                  </h4>
                </>
              }
            ></Table>
          </div>
        </div>
      ) : (
        <>
          {!loading && (
            <div className="d-flex min-vh-100 align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-5 col-xl-4 my-5">
                    <div className="text-center">
                      <h6 className="text-uppercase text-muted mb-4">
                        {t("errors.error-404")}
                      </h6>

                      <h1 className="display-4 mb-3">
                        {t("broadcasts:broadcast-not-found")}
                      </h1>

                      <Link
                        to={buildRoute("broadcast-list")}
                        className="btn btn-primary mt-4"
                      >
                        <span className="fe fe-arrow-left mr-2"></span>
                        {t(
                          "broadcasts:go-to-broadcast-list",
                          "Go to broadcast list"
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
