import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ReplyIcon } from "@heroicons/react/outline";
import axios from "axios";
import { classNames } from "src/Helpers";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

export default function ResendMessage({ active, messageId, inboxContactId }) {
  const { t } = useTranslation();

  const handleResendMessage = useCallback(async () => {
    try {
      const result = await axios.post(
        buildAPIRoute(API_ROUTES.CONVERSATION_MESSAGE_RESEND, {
          ":inboxContactId": inboxContactId,
          ":id": messageId,
        })
      );

      if (result.status === 201) {
        queryClient.invalidateQueries("conversation_content");
      }
    } catch {}
  }, [messageId, inboxContactId]);

  return (
    <button
      className={classNames(
        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
        "flex w-full items-center px-4 py-2 text-sm"
      )}
      onClick={handleResendMessage}
    >
      <ReplyIcon
        className="w-3 h-3 mr-1 text-gray-700 transform -scale-x-100"
        strokeWidth={3}
      />
      {t("resend", "Resend")}
    </button>
  );
}
