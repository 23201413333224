import { useTranslation } from "react-i18next";
import SelectorField from "src/components/Form/SelectorField";
import {
  COUNTRIES_WITH_FLAGS,
  formatCountryOptionLabel,
} from "src/constants/Country";

interface FlowBuilderStepQuestionPhoneProps {
  index: number;
}

function FlowBuilderStepQuestionPhone({
  index,
}: FlowBuilderStepQuestionPhoneProps) {
  const [t] = useTranslation();

  return (
    <SelectorField
      name={`steps.${index}.answer_phone_default_country`}
      label={t(
        "flows:steps.question.answer-phone-default-country.label",
        "Default country code for phone number"
      )}
      help={t(
        "flows:steps.question.answer-phone-default-country.help",
        "If set, we'll use this to check that the number provided is a possible phone number. Otherwise the user must answer with +[country code][10 digits]."
      )}
      // @ts-ignore
      options={COUNTRIES_WITH_FLAGS}
      formatOptionLabel={formatCountryOptionLabel}
    />
  );
}

export default FlowBuilderStepQuestionPhone;
