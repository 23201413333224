import { useMemo } from "react";
import { SimpleChannel } from "@hilos/types/private-schema";
import { useChannel } from "src/hooks/useChannel";
import { truncateWords } from "src/Helpers";
import { ChannelData, ChannelType } from "src/types/channel";
import { getAvailableChannelData } from "./Meta";

interface ChannelBadgeProps {
  channel: SimpleChannel | ChannelData;
}

interface ChannelBadgeByIdProps {
  id: string;
}

export default function ChannelBadge({ channel }: ChannelBadgeProps) {
  const channelData = useMemo(
    () => getAvailableChannelData(channel),
    [channel]
  );

  switch (channel.channel_type) {
    case ChannelType.WHATSAPP:
      return (
        <span className="inline-flex items-center rounded bg-green-100 px-1.5 text-xs font-medium text-green-800">
          {channelData && <channelData.icon className="mr-1 text-xs" />}
          {truncateWords(channel.name, 2)}
        </span>
      );
    default:
      return null;
  }
}

export function ChannelBadgeById({ id }: ChannelBadgeByIdProps) {
  const { channel } = useChannel(id);

  if (!channel) {
    return null;
  }

  return <ChannelBadge channel={channel} />;
}
