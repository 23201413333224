import Select from "react-select";
import { ShareIcon, XIcon } from "@heroicons/react/outline";

export default function FieldAddVariableForm({
  addVariable,
  availableVariables,
  setShowVariableSelect,
  helpLabel,
}) {
  const selectVariable = (value) => {
    setShowVariableSelect(false);
    addVariable(value);
  };

  const formatGroupLabel = (data) => (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <data.icon
          className={`aria-hidden="true" h-4 w-4 text-hilos ${
            data.icon === ShareIcon ? "rotate-90" : ""
          }`}
        />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {data.label}
      </div>
    </div>
  );

  return (
    <div>
      <p className="my-1 text-xs text-gray-500">{helpLabel}</p>

      <div className="flex items-center">
        <div className="grow">
          <Select
            options={availableVariables}
            formatGroupLabel={formatGroupLabel}
            // @ts-ignore
            onChange={(option) => selectVariable(option.value)}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                borderColor: "#ddd",
                "&:hover": {
                  borderColor: "#ddd",
                },
                boxShadow: "none",
                "*": {
                  boxShadow: "none !important",
                },
              }),
              option: (base, { isFocused, isSelected }) => {
                return {
                  ...base,
                  wordBreak: "break-all",
                  backgroundColor: isSelected
                    ? "#d54466"
                    : isFocused
                    ? "#d54466"
                    : undefined,
                  color: isSelected ? "#fff" : isFocused ? "#fff" : undefined,
                  fontSize: "small",
                  paddingLeft: "30px",
                };
              },
            }}
          />
        </div>
        <div className="ml-2 grow-0">
          <button
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
            type="button"
            onClick={(_) => setShowVariableSelect(false)}
          >
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
