import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CategoryEnum } from "@hilos/types/private-schema";
import { BodyComponent, WhatsAppTemplate } from "@hilos/types/wa/templates";
import useWhatsAppTemplates from "src/hooks/useWhatsAppTemplates";
import { buildRoute } from "src/router/router";
import SelectorField from "../../components/Form/SelectorField";
import * as templatesMeta from "./WhatsAppTemplateMeta";

interface WhatsAppTemplateOption {
  label: string;
  value: string;
  template?: WhatsAppTemplate;
}

interface WhatsAppTemplateOptionGroup {
  label: string;
  icon: (props: any) => JSX.Element;
  options: WhatsAppTemplateOption[];
}
interface WhatsAppTemplateSelectProps {
  setSelectedTemplate?: (template: WhatsAppTemplate) => void;
  setRequiredFields?: (fields: string[]) => void | null;
  fieldLabel?: string | null;
  fieldName: string;
  fieldHelp?: string;
  renderFieldHelp?: JSX.Element;
  channel: number | null;
  categories?: CategoryEnum[] | null;
}

export default function WhatsAppTemplateSelect({
  setSelectedTemplate,
  setRequiredFields,
  fieldName,
  fieldLabel,
  fieldHelp,
  renderFieldHelp,
  channel,
  categories,
}: WhatsAppTemplateSelectProps) {
  const { t } = useTranslation();
  const { approvedTemplates, getVariablesFromTemplate } = useWhatsAppTemplates(
    channel,
    categories
  );

  const fieldLabelForm = fieldLabel
    ? fieldLabel
    : t("templates:select.label", "WhatsApp template to use");

  const fieldHelpForm = useMemo(
    () =>
      renderFieldHelp || (
        <>
          <Trans i18nKey="templates:select.help">
            Select from your approved templates. If you need to add another, you
            can do so in{" "}
            <Link
              to={buildRoute("whatsapp-template-list")}
              className="text-blue-500"
            >
              our WhatsApp Template Builder
            </Link>
            .
          </Trans>
        </>
      ),
    [renderFieldHelp]
  );

  const formatOptionLabel = ({ value, label, template }) => {
    // We set the full object on Formik, so we can't just print the label.
    // We need to check if its a string or an object and print accordingly.
    // Surely this can be done in a better way?
    return (
      <div className="py-2">
        <div className="flex items-center justify-between">
          {template && (
            <>
              <div>
                <h4 className="break-all font-medium">{label}</h4>
              </div>
              {template.category &&
                templatesMeta.CATEGORIES[template.category] && (
                  <div className="pl-4">
                    {templatesMeta.CATEGORIES[template.category].icon}
                  </div>
                )}
            </>
          )}
        </div>
        <div className="truncate text-xs">
          {templatesMeta.getTemplateShortPreview(template)}
        </div>
      </div>
    );
  };

  const templateOptions = useMemo(() => {
    return approvedTemplates.map((t: WhatsAppTemplate) => {
      return {
        label: t.name,
        value: t.id,
        template: t,
      };
    });
  }, [approvedTemplates]);

  return (
    <div data-tour="broadcast-form-template">
      <SelectorField<WhatsAppTemplateOption, WhatsAppTemplateOptionGroup>
        isMulti={false}
        isCreatable={false}
        label={fieldLabelForm}
        name={fieldName}
        help={fieldHelp}
        renderHelp={fieldHelpForm}
        options={templateOptions}
        filterOption={(candidate, input) => {
          const template = candidate.data.template;
          if (!template) {
            return true;
          }
          if (
            template.components &&
            template.components?.find((c) => c.type === "BODY")
          ) {
            const bodyComponent = template.components?.find(
              (c) => c.type === "BODY"
            ) as BodyComponent | undefined;
            return (
              template.name.toLowerCase().includes(input.toLowerCase()) ||
              !!bodyComponent?.text?.toLowerCase().includes(input.toLowerCase())
            );
          }
          return template.name.includes(input);
        }}
        //@ts-ignore
        formatOptionLabel={formatOptionLabel}
        onSelect={(option) => {
          getVariablesFromTemplate(
            //@ts-ignore
            option.template,
            setRequiredFields
          );
          //@ts-ignore
          setSelectedTemplate && setSelectedTemplate(option.template);
        }}
      />
    </div>
  );
}
