import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import MobileMenu from "../containers/MobileMenu";
import SidenavMenu from "../containers/SidenavMenu";
import NetworkWarning from "../containers/inbox/NetworkWarning";
import useHilosStore from "../hooks/useHilosStore";
import { buildRoute } from "./router";

interface PrivateRouteProps {
  routeName?: string;
}

function PrivateRoute({
  children,
  routeName,
}: PropsWithChildren<PrivateRouteProps>) {
  const { session } = useHilosStore();

  if (!session) {
    return (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page
  if (
    !session.account &&
    !["welcome", "account-selection"].includes(routeName as string)
  ) {
    return <Navigate to={buildRoute("welcome")} />;
  }

  if (routeName !== "welcome") {
    return (
      <div className="flex h-full flex-col">
        <MobileMenu />
        <NetworkWarning />
        <div className="flex min-h-0 flex-1">
          {/* Narrow sidebar */}
          <SidenavMenu />
          <main className="flex-1 overflow-x-hidden">
            {/* Primary column */}
            {children}
          </main>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}

export default PrivateRoute;
