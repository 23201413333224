import {
  CodeIcon,
  DotsHorizontalIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import SelectorField from "../Form/SelectorField";
import { HTTPFormMeta } from "./Meta";

interface HTTPFormBodySelectProps {
  setContentType: (x: string) => void;
  formPrefix: string;
}
interface VariableOption {
  label: string;
  value: string;
  type: string;
}

interface VariableOptionGroup {
  label: string;
  icon: (props: any) => JSX.Element;
  options: VariableOption[];
}

export default function HTTPFormBodySelect({
  setContentType,
  formPrefix,
}: HTTPFormBodySelectProps) {
  const getAvailableOptions: () => VariableOptionGroup[] = () => {
    const structuredTypes: VariableOption[] = HTTPFormMeta.BODY_TYPES.filter(
      (type) => type.type === "STRUCTURED"
    );
    const textTypes: VariableOption[] = HTTPFormMeta.BODY_TYPES.filter(
      (type) => type.type === "TEXT"
    );
    const otherTypes: VariableOption[] = HTTPFormMeta.BODY_TYPES.filter(
      (type) => type.type === "OTHER"
    );
    const groupedVars = [
      {
        label: "Structured",
        icon: MenuIcon,
        options: structuredTypes,
      },
      {
        label: "Text",
        icon: CodeIcon,
        options: textTypes,
      },
      {
        label: "Other",
        icon: DotsHorizontalIcon,
        options: otherTypes,
      },
    ];
    return groupedVars;
  };

  const formatGroupLabel = (data) => (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <data.icon className="h-4 w-4 text-hilos" />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {data.label}
      </div>
    </div>
  );

  const formatOptionLabel = ({ value, label, icon, help }) => (
    <div className="flex items-center py-3">
      <div className="mx-4">{icon}</div>
      <div>
        <h4 className="text-sm">{label}</h4>
        {help && <p className="mb-0 text-xs">{help}</p>}
      </div>
    </div>
  );

  return (
    <>
      <div className="grow">
        <SelectorField<VariableOption, VariableOptionGroup>
          label={HTTPFormMeta.FIELDS.body_type.label}
          name={`${formPrefix}.${HTTPFormMeta.FIELDS.body_type.key}`}
          options={getAvailableOptions()}
          formatGroupLabel={formatGroupLabel}
          // @ts-ignore
          formatOptionLabel={formatOptionLabel}
          // @ts-ignore
          onSelect={(option) => setContentType(option.value)}
        />
      </div>
    </>
  );
}
