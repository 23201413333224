import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/outline";
import { useField } from "formik";
import FieldContainer from "src/components/Form/FieldContainer";
import { DropzoneStyles } from "src/components/base/Form";
import { FIELDS } from "./BroadcastMeta";

export default function MessageBlastFormCSV({
  formik,
  file,
  setFile,
  isTutorialInProgress,
}) {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: "text/csv",
      onDrop: (acceptedFiles, fileRejections) => {
        fileRejections.forEach((file) => {
          formik.setFieldError(
            formik.errors[FIELDS.recipients_file_selected.key],
            `${file.file.name} ${t(
              "broadcasts:csv-not-valid",
              "is not a valid .csv file."
            )}`
          );
        });

        const nextFiles = acceptedFiles.map((file) => {
          if (file.size > 1024 * 20000) {
            formik.setFieldError(
              formik.errors[FIELDS.recipients_file_selected.key],
              t(
                "broadcasts:csv-too-big",
                "The file is too big. Maximum size is 20MB."
              )
            );
          }

          formik.setFieldValue(FIELDS.recipients_file_selected.key, file.name);
          formik.setFieldError(FIELDS.recipients_file_selected.key, undefined);
          return {
            source: file,
            preview: URL.createObjectURL(file),
          };
        });
        setFile(nextFiles[0]);
      },
    });

  const style = useMemo(
    () =>
      ({
        ...DropzoneStyles.baseStyle,
        ...(isDragActive ? DropzoneStyles.activeStyle : {}),
        ...(isDragAccept ? DropzoneStyles.acceptStyle : {}),
      } as React.CSSProperties),
    [isDragActive, isDragAccept]
  );

  const handleCleanFile = () => {
    formik.setFieldValue(
      formik.errors[FIELDS.recipients_file_selected.key],
      ""
    );
    setFile({
      source: undefined,
      preview: undefined,
    });
  };

  const [_, metaRecipientsFile] = useField(FIELDS.recipients_file_selected.key);
  const errorRecipientsFile =
    metaRecipientsFile.touched && metaRecipientsFile.error;

  if (isTutorialInProgress) {
    return (
      <div data-tour="broadcast-form-csv">
        <div className="flex items-stretch rounded-lg border border-gray-200 p-3 shadow-sm">
          <div className="grow self-center text-sm">broadcast_tutorial.csv</div>
          <div className="self-center text-right">
            <button
              className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              type="button"
            >
              <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />
              {t("remove")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {file && file.source ? (
        <FieldContainer
          name={FIELDS.recipients_file_selected.key}
          error={errorRecipientsFile}
        >
          <div className="flex items-stretch rounded-lg border border-gray-200 p-3 shadow-sm">
            <div className="grow self-center text-sm">{file.source.name}</div>
            <div className="self-center text-right">
              <button
                className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                type="button"
                onClick={handleCleanFile}
              >
                <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                {t("remove")}
              </button>
            </div>
          </div>
        </FieldContainer>
      ) : (
        <FieldContainer
          name={FIELDS.recipients_file_selected.key}
          error={errorRecipientsFile}
        >
          <div {...getRootProps({ className: "dropzone", style: style })}>
            <input {...getInputProps()} />
            <p className="mb-0 flex items-center pb-0">
              <DocumentTextIcon className="mr-2 h-4 w-4" aria-hidden="true" />{" "}
              {t("csv-drop", "Click here to select a .csv or drag & drop it.")}
            </p>
          </div>
        </FieldContainer>
      )}

      <p>
        {formik.errors && formik.errors.recipients_file_selected && (
          <span className="mt-1.5 text-xs text-red-500">
            {formik.errors.recipients_file_selected}
          </span>
        )}
      </p>
    </>
  );
}
