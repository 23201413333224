import { Trans } from "react-i18next";
import {
  ChatAltIcon,
  CodeIcon,
  DocumentTextIcon,
  FilterIcon,
  ShareIcon,
} from "@heroicons/react/outline";
import RobotIcon from "src/components/icons/RobotIcon";
import { getTranslationPayload as t } from "src/i18n";
import InboundAnyMessageTriggerDetail from "../../trigger/InboundAnyMessageTriggerDetail";
import InboundSpecificMessageTriggerDetail from "../../trigger/InboundSpecificMessageTriggerDetail";
import IntegrationsHubSpotTriggerDetail from "../../trigger/IntegrationsHubSpotTriggerDetail";
import IntegrationsZapierTriggerDetail from "../../trigger/IntegrationsZapierTriggerDetail";
import OutboundAnyTriggerDetail from "../../trigger/OutboundAnyTriggerDetail";
import OutboundCampaignAPITriggerDetail from "../../trigger/OutboundCampaignAPITriggerDetail";
import OutboundCampaignCSVTriggerDetail from "../../trigger/OutboundCampaignCSVTriggerDetail";
import OutboundCampaignSegmentTriggerDetail from "../../trigger/OutboundCampaignSegmentTriggerDetail";
import ZapierIcon from "../components/icons/ZapierIcon";
import HubSpotIcon from "../components/steps/hubspot/HubspotIcon";

export const TRIGGER_TYPES = {
  INBOUND_ANY_MESSAGE: {
    name: t("flows:trigger.any-message.name", "Any message"),
    value: "ANY_MESSAGE",
    icon: RobotIcon,
    description: t(
      "flows:trigger.any-message.description",
      "Will trigger anytime a customer messages you and there is no other conversation / flow active."
    ),
    help: {
      title: t(
        "flows:trigger.any-message.help.title",
        "A few steps to create a chatbot"
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.any-message.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.any-message.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.any-message.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
    detail: InboundAnyMessageTriggerDetail,
  },
  INBOUND_SPECIFIC_MESSAGE: {
    name: t("flows:trigger.specific-text.name", "Specific text"),
    value: "TEXT",
    icon: ChatAltIcon,
    description: t(
      "flows:trigger.specific-text.description",
      "Trigger when the message contains a specific text"
    ),
    help: {
      title: t(
        "flows:trigger.specific-text.help.title",
        "A few steps to create a chatbot"
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.specific-text.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.specific-text.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.specific-text.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
    detail: InboundSpecificMessageTriggerDetail,
  },
  OUTBOUND_CAMPAIGN_API: {
    name: t(
      "flows:trigger.outbound-campaign-api.name",
      "Start via API (Advanced)"
    ),
    description: t(
      "flows:trigger.outbound-campaign-api.description",
      "After publishing your flow, trigger from your code with a list of contacts"
    ),
    icon: CodeIcon,
    help: {
      title: t(
        "flows:trigger.outbound-campaign-api.help.title",
        "A few steps to create a chatbot"
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.outbound-campaign-api.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.outbound-campaign-api.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.outbound-campaign-api.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
    detail: OutboundCampaignAPITriggerDetail,
  },
  OUTBOUND_CAMPAIGN_CSV: {
    name: t(
      "flows:trigger.outbound-campaign-csv.name",
      "Trigger via CSV upload"
    ),
    description: t(
      "flows:trigger.outbound-campaign-csv.description",
      "After publishing your flow, upload a CSV with contacts to send it."
    ),
    icon: DocumentTextIcon,
    help: {
      title: t(
        "flows:trigger.outbound-campaign-csv.help.title",
        "A few steps to create a chatbot"
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.outbound-campaign-csv.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.outbound-campaign-csv.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.outbound-campaign-csv.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
    detail: OutboundCampaignCSVTriggerDetail,
  },
  OUTBOUND_CAMPAIGN_SEGMENT: {
    name: t(
      "flows:trigger.outbound-campaign-segment.name",
      "Trigger for a contact segment"
    ),
    description: t(
      "flows:trigger.outbound-campaign-segment.description",
      "After publishing your flow, filter your contacts and send it."
    ),
    icon: FilterIcon,
    help: {
      title: t(
        "flows:trigger.outbound-campaign-segment.help.title",
        "A few steps to create a chatbot"
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.outbound-campaign-segment.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.outbound-campaign-segment.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.outbound-campaign-segment.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
    detail: OutboundCampaignSegmentTriggerDetail,
  },
  INTEGRATIONS_HUBSPOT: {
    name: t(
      "flows:trigger.integrations-hubspot.name",
      "Trigger from HubSpot Workflow"
    ),
    description: t(
      "flows:trigger.integrations-hubspot.description",
      "After publishing your flow, use HubSpot's Workflows feature to send it."
    ),
    icon: HubSpotIcon,
    help: {
      title: t(
        "flows:trigger.integrations-hubspot.help.title",
        "A few steps to create a chatbot"
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.integrations-hubspot.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.integrations-hubspot.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.integrations-hubspot.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
    detail: IntegrationsHubSpotTriggerDetail,
  },
  INTEGRATIONS_ZAPIER: {
    name: t("flows:trigger.integrations-zapier.name", "Trigger from Zapier"),
    description: t(
      "flows:trigger.integrations-zapier.description",
      "After publishing your flow, trigger it from Hilos' Zapier integration."
    ),
    icon: ZapierIcon,
    help: {
      title: t(
        "flows:trigger.integrations-zapier.help.title",
        "A few steps to create a chatbot"
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.integrations-zapier.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.integrations-zapier.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.integrations-zapier.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
    detail: IntegrationsZapierTriggerDetail,
  },
  FROM_FLOW: {
    name: t("flows:trigger.from-flow.name", "From another flow"),
    description: t("flows:trigger.from-flow.description", "From another flow"),
    icon: ShareIcon,
    help: {
      title: t(
        "flows:trigger.from-flow.help.title",
        "A few steps to create a chatbot"
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.from-flow.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.from-flow.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.from-flow.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
  },
  OUTBOUND_ANY: {
    name: t("flows:trigger.outbound-any.name", "Outbound (any)"),
    description: t("flows:trigger.outbound-any.description", "Outbound (any)"),
    icon: ShareIcon,
    help: {
      title: t(
        "flows:trigger.outbound-any.help.title",
        "Can be triggered by any of the OUTBOUND methods (HubSpot, Zapier, from API, etc)."
      ),
      video_url: "https://hilos.io/video",
      content: (
        <div>
          <Trans i18nKey="flows:trigger.outbound-any.help.content">
            <div className="text-sm text-gray-500">
              <p className="mt-2">
                For small or beginning businesses, WA gives you WA Business.
                This includes the app on your phone, using it on your computer
                and sharing it between 4 people max using WhatsApp Web.
              </p>
            </div>
          </Trans>
        </div>
      ),
      examples: [
        {
          label: t(
            "flows:trigger.outbound-any.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
        {
          label: t(
            "flows:trigger.outbound-any.help.examples.chatbot",
            "Set your default chatbot"
          ),
          url: "https//hilos.io/docs/chatnot",
        },
      ],
    },
    detail: OutboundAnyTriggerDetail,
  },
};

export const MESSAGE_TYPE_TRIGGER = [
  "INBOUND_ANY_MESSAGE",
  "INBOUND_SPECIFIC_MESSAGE",
];

export const OUTBOUND_CAMPAIGN_TYPE_TRIGGER = [
  "OUTBOUND_CAMPAIGN_SEGMENT",
  "OUTBOUND_CAMPAIGN_CSV",
  "OUTBOUND_CAMPAIGN_API",
  "OUTBOUND_ANY",
];

export const INTEGRATION_TYPE_TRIGGER = [
  "INTEGRATIONS_HUBSPOT",
  "INTEGRATIONS_ZAPIER",
  "OUTBOUND_CAMPAIGN_API",
];

export type TriggerTypes = keyof typeof TRIGGER_TYPES;
