import { EdgeLabelRenderer, EdgeProps, getBezierPath } from "reactflow";
import { classNames } from "src/Helpers";
import useDropZone from "../../hooks/useDropZone";
import useFlowEdge from "../../hooks/useFlowEdge";

function AddStepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  selected,
  source,
}: EdgeProps) {
  const { labelRef, isValidDropzone, onMouseEnter, onMouseLeave } = useFlowEdge(
    {
      id: source,
      selected,
      enterClassName: "indigo-600",
      leaveClassName: "gray-400",
      allowMoveStepPath: true,
    }
  );
  const { isDropzoneActive, onDrop, onDragOver, onDragEnter, onDragLeave } =
    useDropZone({ disabled: !isValidDropzone });
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <g
      id={id}
      data-type="add_step_edge"
      className={classNames(
        "group block",
        !selected && !isDropzoneActive && "opacity-60 group-hover:opacity-100"
      )}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <path
        id={id}
        fill="none"
        style={style}
        strokeDasharray={"6 4"}
        className={classNames(
          "stroke-2",
          isDropzoneActive
            ? isValidDropzone
              ? "stroke-indigo-600"
              : "stroke-red-600"
            : selected
            ? "stroke-indigo-600"
            : "stroke-gray-400 group-hover:stroke-indigo-600"
        )}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        fill="none"
        style={style}
        className="stroke-transparent stroke-[40px]"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -100%) translate(${targetX}px,${targetY}px)`,
          }}
        >
          <div
            ref={(ref) => {
              labelRef.current = ref;
            }}
            className={classNames(
              "flex h-5 w-5 items-center justify-center rounded border-2 bg-white pb-[2px] font-bold",
              isDropzoneActive
                ? isValidDropzone
                  ? "border-indigo-600 text-indigo-600"
                  : "border-red-600 text-red-600"
                : selected
                ? "border-indigo-600 text-indigo-600"
                : "border-gray-400 text-gray-400"
            )}
          >
            +
          </div>
        </div>
      </EdgeLabelRenderer>
    </g>
  );
}

export default AddStepEdge;
