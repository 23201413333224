import { useCallback, useMemo, useState } from "react";
import { useField, useFormikContext } from "formik";
import { Trans } from "react-i18next";

import TextInputField from "src/components/Form/TextInputField";

interface SecretInputFieldProps {
  /** e.g. `dialog360_settings.api_key` or  `meta_cloud_api_settings.access_token`*/
  name: string;
  /** The key name of the formatted sensitive field, e.g. `dialog360_settings.api_key_ro` or `meta_cloud_api_settings.access_token_ro` */
  readOnlyName: string;
  label?: string;
  help?: string | JSX.Element;
  helpReadonlyField?: (handleChangeSecretField: () => void) => JSX.Element;
  placeholder?: string;
}

export default function SecretInputField({
  name,
  readOnlyName,
  label,
  help,
  placeholder,
  helpReadonlyField = (handleChangeSecretField) => (
    <Trans i18nKey="components:secret-input-field:change-secret-field">
      Click{" "}
      <button
        className="text-indigo-500"
        type="button"
        onClick={(_) => handleChangeSecretField()}
      >
        here
      </button>{" "}
      to change it.
    </Trans>
  ),
  ...props
}: SecretInputFieldProps) {
  const [readonly_field, meta] = useField({ name: readOnlyName });
  const { setFieldValue } = useFormikContext();
  const [changeSecretInput, setChangeSecretInput] = useState(false);

  const handleChangeSecretField = useCallback(() => {
    setChangeSecretInput((value) => !value);
    setFieldValue(name, "");
  }, [name, setFieldValue]);

  const memoizedHelpReadOnlyField = useMemo(
    () => helpReadonlyField(handleChangeSecretField),
    [handleChangeSecretField, helpReadonlyField]
  );

  return readonly_field.value && !changeSecretInput ? (
    <TextInputField
      placeholder={placeholder}
      label={label}
      name={readOnlyName}
      help={memoizedHelpReadOnlyField}
      type="text"
      readOnly
      key={readOnlyName}
      {...props}
    />
  ) : (
    <TextInputField
      placeholder={placeholder}
      label={label}
      name={name}
      help={help}
      key={name}
      type="text"
      {...props}
    />
  );
}
