import { useTranslation } from "react-i18next";
import TextInputField from "src/components/Form/TextInputField";
import FlowBuilderMaxAnswerAttempts from "../../FlowBuilderMaxAnswerAttempts";
import FlowBuilderMsgFail from "../../FlowBuilderMsgFail";
import FlowBuilderWaitUntil from "../../FlowBuilderWaitUntil";
import TextAreaFieldWithVariables from "../../TextAreaFieldWithVariables";
import FlowBuilderStepQuestionSingleOption from "../question/FlowBuilderStepQuestionSingleOption";

interface FlowBuilderStepMenuProps {
  id: string;
  index: number;
}

function FlowBuilderStepMenu({ id, index }: FlowBuilderStepMenuProps) {
  const [t] = useTranslation();

  return (
    <div className="space-y-4">
      <TextAreaFieldWithVariables
        path={`steps.${index}`}
        name={"body"}
        label={t("flows:steps.message.body.label", "Message body")}
        placeholder={t(
          "flows:steps.message.body.placeholder",
          "Step 1 Welcome"
        )}
        rows={8}
        currentStepIndex={index}
      />
      <div className="space-y-2">
        <FlowBuilderStepQuestionSingleOption
          isStepMenu={true}
          id={id}
          index={index}
        />
        <TextInputField
          type="text"
          name={`steps.${index}.answer_validation_message`}
          label={t(
            "flows:steps.menu.answer-validation-message.label",
            "If the user didn't select a valid option, answer with"
          )}
          placeholder={t(
            "flows:steps.menu.answer-validation-message.placeholder",
            "Please select one of the options above."
          )}
        />
      </div>
      <FlowBuilderMaxAnswerAttempts id={id} index={index} />
      <FlowBuilderWaitUntil id={id} index={index} />
      <FlowBuilderMsgFail id={id} index={index} />
    </div>
  );
}

export default FlowBuilderStepMenu;
