// @flow
import * as yup from "yup";

export const getInitialValues = () => {
  return {
    d360_channel_id: "",
    d360_channel_name: false,
  };
};

export const schema = yup.object().shape({
  d360_channel_id: yup.string(),
  d360_channel_name: yup.string(),
});
