import * as meta from "./ConversationErrorMeta";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface ConversationErrorMessagesProps {
  errorCode: string | null;
  errorMessage: string | null;
}

const ConversationErrorMessages = ({
  errorCode,
  errorMessage,
}: ConversationErrorMessagesProps) => {
  const { t } = useTranslation();

  const error = useMemo(() => {
    if (!errorCode) {
      return t("errors:unknown-error", "Unknown Error");
    }

    if (errorCode in meta.CONVERSATION_ERROR_MESSAGES) {
      return t(meta.CONVERSATION_ERROR_MESSAGES[errorCode]);
    }

    return errorMessage;
  }, [errorCode, errorMessage, t]);

  return <>{error}</>;
};

export default ConversationErrorMessages;
