import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InboxContactReadV2 } from "@hilos/types/conversation";
import { ContactTag } from "@hilos/types/private-schema";
import {
  classNames,
  contactToString,
  inboxContactToString,
} from "../../Helpers";
import { getDateTimeFromTimestamp } from "../../helpers/date";
import ChannelBadge, { ChannelBadgeById } from "../config/channel/ChannelBadge";
import ContactTagList from "./Conversation/ContactTagList";
import ConversationStatusBadge from "./Conversation/ConversationStatusBadge";
import ConversationTagList from "./Conversation/ConversationTagList";
import WhatsAppMessageOneLinePreview from "./Conversation/WhatsAppMessageOneLinePreview";
import ConversationStatusIndicator from "./ConversationStatusIndicator";
import { getConnectionColor } from "./Helpers";
import UserAvatar from "./UserAvatar";

interface ConversationItemProps {
  inboxContact: InboxContactReadV2;
  isSelected: boolean;
  isSelectedAll: boolean;
  showSelection: boolean;
  isLargeScreen?: boolean;
  currentInboxContactId?: string;
  inboxSettings: {
    show_conversation_status?: boolean;
    show_assignees?: boolean;
    show_channel?: boolean;
    show_conversation_tags?: boolean;
    show_contact_tags?: boolean;
  };
  hasUsedMultipleChannels?: boolean;
  allowQueriesWithSQLFunctions: boolean;
  onSelectContact: (id: string) => void;
}

function ConversationItem({
  inboxContact,
  isSelected,
  isSelectedAll,
  showSelection,
  isLargeScreen = false,
  currentInboxContactId,
  inboxSettings,
  hasUsedMultipleChannels,
  allowQueriesWithSQLFunctions,
  onSelectContact,
}: ConversationItemProps) {
  const { t } = useTranslation();
  const isTouching = useRef(false);
  const location = useLocation();

  const statusColor = useMemo(
    () => getConnectionColor(inboxContact.last_inbound_message_on),
    [inboxContact]
  );

  const [phone, fullName, contactTags] = useMemo(() => {
    if (allowQueriesWithSQLFunctions) {
      return [
        inboxContact.contact__phone,
        inboxContactToString(inboxContact),
        inboxContact.contact_tags,
      ];
    }

    if (inboxContact.contact) {
      return [
        inboxContact.contact.phone,
        contactToString(inboxContact.contact),
        inboxContact.contact.tags as unknown as { tag: ContactTag }[],
      ];
    }

    return [];
  }, [inboxContact, allowQueriesWithSQLFunctions]);

  const [conversationStatus, conversationAssigned, conversationTags] =
    useMemo(() => {
      if (allowQueriesWithSQLFunctions) {
        return [
          inboxContact.last_conversation__status,
          inboxContact.conversation_assigned,
          inboxContact.conversation_tags,
        ];
      }

      if (inboxContact.last_conversation) {
        return [
          inboxContact.last_conversation.status,
          inboxContact.last_conversation.assigned,
          inboxContact.last_conversation.tags,
        ];
      }

      return [];
    }, [inboxContact, allowQueriesWithSQLFunctions]);

  const [
    hasLastMessage,
    lastMessageStatus,
    lastMessageBody,
    lastMessageType,
    lastMessageDeleted,
  ] = useMemo(() => {
    if (allowQueriesWithSQLFunctions) {
      return [
        Boolean(inboxContact.last_message_on),
        inboxContact.last_message__status,
        inboxContact.last_message__body,
        inboxContact.last_message__msg_type,
        inboxContact.last_message__is_deleted,
      ];
    }

    if (inboxContact.last_message) {
      return [
        Boolean(inboxContact.last_message),
        inboxContact.last_message.status,
        inboxContact.last_message.body,
        inboxContact.last_message.msg_type,
        inboxContact.last_message.is_deleted,
      ];
    }

    return [];
  }, [inboxContact, allowQueriesWithSQLFunctions]);

  const backgroundColor = useMemo(
    () =>
      inboxContact.id === currentInboxContactId ? "bg-gray-200" : "bg-white",
    [inboxContact.id, currentInboxContactId]
  );

  const handleTouchStart = useCallback(() => (isTouching.current = true), []);

  const handleTouchEnd = useCallback(() => (isTouching.current = false), []);

  const handleContextMenu = useCallback(
    (event) => {
      if (isTouching.current) {
        onSelectContact(inboxContact.id);
        event.preventDefault();
      }
    },
    [inboxContact.id, onSelectContact]
  );

  const handleChangeCheckbox = useCallback(() => {
    try {
      if (!("ontouchstart" in document.documentElement)) {
        onSelectContact(inboxContact.id);
      }
    } catch {}
  }, [inboxContact, onSelectContact]);

  const handleSelectContact = useCallback(
    (event) => {
      if (event.shiftKey) {
        onSelectContact(inboxContact.id);
        event.preventDefault();
      }
    },
    [inboxContact.id, onSelectContact]
  );

  const hasUnreadMesages =
    inboxContact.is_unread || Number(inboxContact.unseen_messages) > 0;

  return (
    <li
      data-cursor={inboxContact.id}
      data-tour={phone}
      className={classNames(
        "flex flex-row w-full group select-none overflow-hidden",
        backgroundColor
      )}
    >
      <label
        className={classNames(
          "flex shrink-0 h-auto w-12 transition-spacing justify-center items-center appearance-none",
          "outline-none overflow-hidden group-hover:bg-gray-300",
          !isSelectedAll && "cursor-pointer",
          !showSelection && "-ml-8 hover:ml-0"
        )}
        onChange={handleChangeCheckbox}
      >
        <input
          type="checkbox"
          checked={isSelected || isSelectedAll}
          className={classNames(
            "h-4 w-4 rounded border-gray-300 focus:ring-0 focus:ring-offset-0",
            isSelectedAll ? "text-indigo-400" : "text-indigo-600"
          )}
        />
      </label>
      <Link
        to={`/inbox/conversation/${inboxContact.id}${
          isLargeScreen ? "/details" : ""
        }${location.search}`}
        onTouchEnd={handleTouchEnd}
        onTouchStart={handleTouchStart}
        onContextMenu={handleContextMenu}
        onClick={handleSelectContact}
        className="flex flex-col py-3 pr-4 group-hover:bg-gray-300 shrink w-full select-none overflow-hidden"
      >
        <div className="flex w-full items-center justify-between overflow-hidden">
          <div className="flex shrink items-center justify-start overflow-hidden">
            <span
              className={classNames(
                "truncate font-medium text-gray-700",
                hasUnreadMesages && "font-semibold text-gray-800"
              )}
            >
              {fullName}
            </span>
            <ConversationStatusIndicator color={statusColor} />
          </div>
          <div className="relative">
            <div className="flex items-center overflow-hidden space-x-1">
              {inboxSettings.show_assignees && (
                <div className="flex shrink-0 -space-x-1 overflow-hidden pl-1">
                  {conversationAssigned?.map(
                    ({ user }) =>
                      user && (
                        <UserAvatar
                          key={user.id}
                          data={user}
                          className="h-6 w-6 text-xs"
                        />
                      )
                  )}
                </div>
              )}
              {hasUnreadMesages && (
                <div className="ml-2 flex h-6 w-6 grow items-center justify-center rounded-full bg-indigo-500 px-1">
                  <span className="text-xs font-medium text-indigo-100">
                    {inboxContact.unseen_messages || ""}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex my-1">
          {hasUsedMultipleChannels &&
            inboxContact &&
            inboxSettings.show_channel && (
              <div className="mr-auto">
                {inboxContact.channel ? (
                  <ChannelBadge channel={inboxContact.channel} />
                ) : (
                  <ChannelBadgeById id={inboxContact.channel_id} />
                )}
              </div>
            )}
          {inboxSettings.show_conversation_status && (
            <ConversationStatusBadge status={conversationStatus} />
          )}
        </div>
        <div className="flex-col no-scrollbar">
          {inboxSettings.show_conversation_tags && (
            <ConversationTagList tags={conversationTags} />
          )}
          {inboxSettings.show_contact_tags && (
            <ContactTagList tags={contactTags} />
          )}
        </div>
        <div className="mt-0.5 flex flex-row items-center justify-between overflow-hidden space-x-2">
          {hasLastMessage ? (
            <span
              className={classNames(
                "truncate text-xs text-gray-600",
                hasUnreadMesages && "font-semibold text-gray-800"
              )}
            >
              {lastMessageStatus === "failed" && (
                <FontAwesomeIcon
                  // @ts-ignore
                  icon={faExclamationCircle}
                  className="text-red-500"
                />
              )}{" "}
              <WhatsAppMessageOneLinePreview
                body={lastMessageBody}
                type={lastMessageType}
                deleted={lastMessageDeleted}
              />
            </span>
          ) : (
            <span className="text-xs text-gray-600">
              {t("inbox:no-messages", "No messages yet...")}
            </span>
          )}
          {inboxContact.last_message_on && (
            <span
              className={classNames(
                "text-xs text-gray-400",
                hasUnreadMesages && "font-semibold text-gray-800"
              )}
            >
              {getDateTimeFromTimestamp(inboxContact.last_message_on)}
            </span>
          )}
        </div>
      </Link>
    </li>
  );
}

export default ConversationItem;
