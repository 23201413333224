import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";
import {
  contactTagsColorClasses,
  conversationTagsColorClasses,
} from "src/components/Tags";

interface FlowStepTagDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepTagDetailComponent({
  step,
}: FlowStepTagDetailProps) {
  const { t } = useTranslation();
  return (
    <div className="space-y-2">
      {step.conversation_tags && (
        <div>
          <p>
            {step.append_tags
              ? t(
                  "flow-executions:steps-detail.tag.append",
                  "Append conversation tags"
                )
              : t(
                  "flow-executions:steps-detail.tag.replace",
                  "Replace conversation tags with"
                )}
          </p>

          <div className="mt-1 flex flex-wrap items-center space-x-1">
            {step.conversation_tags.length < 1 && (
              <div className="text-sm text-gray-500">
                {t("no-tags", "No tags yet")}.
              </div>
            )}

            <div className="space-y-1">
              {step.conversation_tags.map((tag, idx) => (
                <span
                  key={idx}
                  className={`mr-1 inline-flex items-center rounded-full py-0.5 px-2.5  text-sm font-medium ${conversationTagsColorClasses.spanClass}`}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}

      {step.contact_tags && (
        <div>
          <p>
            {step.append_tags
              ? t(
                  "flow-executions:steps-detail.tag.append",
                  "Append conversation tags"
                )
              : t(
                  "flow-executions:steps-detail.tag.replace",
                  "Replace conversation tags with"
                )}
          </p>

          <div className="mt-1 flex flex-wrap items-center space-x-1">
            {step.contact_tags.length < 1 && (
              <div className="text-sm text-gray-500">
                {t("no-tags", "No tags yet")}.
              </div>
            )}

            <div className="space-y-1">
              {step.contact_tags.map((tag, idx) => (
                <span
                  key={idx}
                  className={`mr-1 inline-flex items-center rounded-full py-0.5 px-2.5  text-sm font-medium ${contactTagsColorClasses.spanClass}`}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
