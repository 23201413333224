import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/solid";
import { FormikProps } from "formik";
import TextInputField from "src/components/Form/TextInputField";

interface SubscriptionV2UserAmountInputProps {
  formik: FormikProps<any>;
  fieldMeta: { label: string; help: string; key: string; min: number };
}

export default function SubscriptionV2UserAmountInput({
  formik,
  fieldMeta,
}: SubscriptionV2UserAmountInputProps) {
  const handleAdjustQuantity = (quantity) => {
    const finalVal = parseInt(formik.values[fieldMeta.key]) + quantity;
    if (finalVal < fieldMeta.min) {
      return;
    }
    formik.setFieldValue(
      fieldMeta.key,
      parseInt(formik.values[fieldMeta.key]) + quantity
    );
    formik.setFieldTouched(fieldMeta.key, true);
  };

  return (
    <>
      <div className="flex items-center">
        <button
          className="rounded-full border-hilos border-2 mx-8 ring-inset hover:ring-2 hover:ring-hilos"
          type="button"
          onClick={() => handleAdjustQuantity(-1)}
        >
          <MinusSmIcon className="h-8 w-8 text-hilos" />
        </button>
        <div className="grow px-2">
          <TextInputField
            label={fieldMeta.label}
            help={fieldMeta.help}
            name={fieldMeta.key}
            type="number"
            min={fieldMeta.min}
          />
        </div>
        <button
          className="rounded-full border-hilos border-2 mx-8 ring-inset hover:ring-2 hover:ring-hilos"
          type="button"
          onClick={() => handleAdjustQuantity(1)}
        >
          <PlusSmIcon className="h-8 w-8 text-hilos" />
        </button>
      </div>
    </>
  );
}
