import { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";

interface UseStepField {
  index: number;
  name: string;
}

export function useStepField({ index, name }: UseStepField) {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FlowData>();

  const value = useMemo(
    () => values.steps?.[index]?.[name] ?? null,
    [values, index, name]
  );

  const setValue = useCallback(
    (nextValue, nextTouched = true) => {
      setFieldValue(`steps.${index}.${name}`, nextValue);
      setFieldTouched(`steps.${index}.${name}`, nextTouched);
    },
    [setFieldValue, setFieldTouched, index, name]
  );

  return [value, setValue];
}
