import { useMemo } from "react";
import { WhatsAppTemplateSimpleRead } from "@hilos/types/private-schema";
import {
  TemplateContentValues,
  getTemplateComponents,
  interpolateTextValue,
} from "../../helpers/template";

interface WhatsAppTemplateTextPreviewProps {
  template: WhatsAppTemplateSimpleRead;
  values: TemplateContentValues;
  isPreviewInMessage?: boolean;
}

function WhatsAppTemplateTextPreview({
  template,
  values,
  isPreviewInMessage = false,
}: WhatsAppTemplateTextPreviewProps) {
  const { header, body } = getTemplateComponents(template);

  const { headerVar, bodyVars } = useMemo(() => values || {}, [values]);

  return (
    <div className="space-y-2">
      <div
        className={` rounded-lg py-2 px-3 text-left text-gray-500 ${
          isPreviewInMessage ? "text-xs space-y-1" : "text-sm space-y-2"
        }`}
      >
        {header && (
          <>
            {header.format === "TEXT" && (
              <div className="font-bold">
                {interpolateTextValue(header.text, [headerVar])}
              </div>
            )}
          </>
        )}
        <div
          className={`whitespace-pre-line break-words truncate ${
            isPreviewInMessage ? "line-clamp-1" : "line-clamp-2"
          }`}
        >
          {body && interpolateTextValue(body.text, bodyVars)}
        </div>
      </div>
    </div>
  );
}

export default WhatsAppTemplateTextPreview;
