import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";

export default function ReportMetricsModal() {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={() => setShow(false)}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-full w-full">
                      <div className="flex h-full flex-col justify-start overflow-hidden">
                        <div className="w-full space-y-2 py-3">
                          <div className="flex items-center justify-between px-4 sm:px-6 py-3 sm:py-5">
                            <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                              {t(
                                "reports:metrics.title",
                                "Metrics explanation"
                              )}
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-100"
                                onClick={() => setShow(false)}
                              >
                                <span className="sr-only">
                                  {t("close-panel", "Close panel")}
                                </span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 sm:px-6 space-y-4">
                          <dl className="divide-y divide-gray-100 mx-3">
                            <div className="px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">
                                {t("reports:received-chats", "Received chats")}
                              </dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {t(
                                  "reports:received-chats-tooltip",
                                  "Amount of conversations started by your contacts in the current time frame."
                                )}
                              </dd>
                            </div>
                            <div className="px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">
                                {t(
                                  "reports:distinct-contacts",
                                  "Distinct contacts"
                                )}
                              </dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {t(
                                  "reports:distinct-contacts-tooltip",
                                  "Amount of contacts that started conversations. Note that every a can start 1 or more conversation."
                                )}
                              </dd>
                            </div>
                            <div className="px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">
                                {t("reports:average-duration", "Avg duration")}
                              </dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {t(
                                  "reports:avg-duration-tooltip",
                                  "Average duration of the conversation, from start to CLOSED. If the conversation was started by a flow, it will show the total time spent in flow + with a human agent."
                                )}
                              </dd>
                            </div>
                            <div className="px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">
                                {t(
                                  "reports:average-first-response",
                                  "Avg first response"
                                )}
                              </dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {t(
                                  "reports:avg-response-time-tooltip",
                                  "Average response time between the conversation being assigned (not created!) and an agent sending the first message."
                                )}
                              </dd>
                            </div>
                            <div className="px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">
                                {t("reports:average-rating", "Avg rating")}
                              </dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {t(
                                  "reports:avg-rating-tooltip",
                                  "If the CSAT survey is enabled, the average rating your contacts gave these conversations."
                                )}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <button
        className="flex items-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        type="button"
        onClick={(_) => setShow(true)}
      >
        <InformationCircleIcon
          className="h-4 w-4 mr-2 text-gray-400"
          aria-hidden="true"
        />{" "}
        {t("reports:metrics.button", "What do these metrics mean?")}
      </button>
    </>
  );
}
