import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";

interface FlowExecutionStepConversationDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepConversationDetail({
  executionStep,
}: FlowExecutionStepConversationDetailProps) {
  return null;
}
