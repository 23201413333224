import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ExclamationIcon,
  PlusSmIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FieldArray } from "formik";
import { FormikProps } from "formik";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import useHilosStore from "src/hooks/useHilosStore";
import customerSatisfactionImg from "../../assets/img/flow/customer_satisfaction_example.webp";
import SwitchField from "../../components/Form/SwitchField";
import TextAreaField from "../../components/Form/TextAreaField";
import TextInputField from "../../components/Form/TextInputField";
import * as meta from "./AccountMeta";

export interface AccountCSATFormComponentProps {
  formik: FormikProps<any>;
}

export default function AccountCSATFormComponent({
  formik,
}: AccountCSATFormComponentProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [showListExample, setShowListExample] = useState(false);

  const toggleShowListExample = useCallback(() => {
    setShowListExample((s) => !s);
  }, [setShowListExample]);

  const getOptionPercentage = (maxLength: number, idx: number) => {
    return ((maxLength - idx) / maxLength) * 100;
  };

  return (
    <div className="space-y-4 bg-white px-4 py-4 sm:p-6">
      {formik.values[
        meta.FIELDS.measure_customer_satisfaction_per_conversation.key
      ] && (
        <div className="mb-4 flex items-center bg-yellow-50 px-4 py-3 text-xs text-yellow-700">
          <ExclamationIcon className="mr-3 h-6 w-6" />
          <div>
            <Trans i18nKey="settings:flow-trigger.help">
              <p>This flow won't be triggered if:</p>
              <p>
                - The conversation has more than 24 hours of customer
                inactivity.
              </p>
              <p>- The conversation is not CLOSED.</p>
            </Trans>
          </div>
        </div>
      )}
      <button
        className="mb-2 flex items-center text-sm text-blue-500"
        type="button"
        onClick={toggleShowListExample}
      >
        <QuestionMarkCircleIcon className="mr-0.5 h-4 w-4" />
        {showListExample
          ? t("hide-example", "Hide example")
          : t("show-example", "Show example")}
      </button>
      {showListExample && (
        <div className="mb-4 px-10 text-center text-sm italic text-gray-500">
          <img
            src={customerSatisfactionImg}
            className="rounded-xl shadow-md"
            alt="Message with list example"
          />
          <p>
            {t(
              "settings:customer-satisfaction-example",
              "Example of a customer satisfaction survey with an interactive list."
            )}
          </p>
        </div>
      )}
      {session?.account.has_multiple_active_channels && (
        <ChannelSelectField
          name={
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .channel.key
          }
          label={t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .channel.label
          )}
          help={t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .channel.help
          )}
        />
      )}
      <TextAreaField
        name={
          meta.FIELDS.measure_customer_satisfaction_per_conversation_data.body
            .key
        }
        label={t(
          meta.FIELDS.measure_customer_satisfaction_per_conversation_data.body
            .label
        )}
        help={t(
          meta.FIELDS.measure_customer_satisfaction_per_conversation_data.body
            .help[0]
        )}
        rows={4}
      ></TextAreaField>
      <div className="mt-4">
        <TextInputField
          label={t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .answer_options_render_list_button_title.label
          )}
          name={
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .answer_options_render_list_button_title.key
          }
          placeholder={t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .answer_options_render_list_button_title.placeholder
          )}
          type="text"
          maxLength={20}
        />
      </div>
      <div className="mt-4">
        <p className="text-sm font-medium text-gray-700">
          {t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .answer_options.label
          )}
        </p>
        <FieldArray
          name={
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .answer_options.key
          }
          render={(arrayHelpers) => (
            <>
              <div className="list-group list-group-flush">
                {formik.values
                  .measure_customer_satisfaction_per_conversation_data &&
                  formik.values
                    .measure_customer_satisfaction_per_conversation_data
                    .answer_options &&
                  formik.values.measure_customer_satisfaction_per_conversation_data.answer_options.map(
                    (_, oIdx) => (
                      <div className="flex items-center py-1" key={oIdx}>
                        <div className="mr-4 grow-0">
                          <code className="text-gray-600 text-sm">
                            {Math.round(
                              getOptionPercentage(
                                formik.values
                                  .measure_customer_satisfaction_per_conversation_data
                                  .answer_options.length,
                                oIdx
                              )
                            )}
                            %
                          </code>
                        </div>
                        <div className="grow">
                          <TextInputField
                            name={`${meta.FIELDS.measure_customer_satisfaction_per_conversation_data.answer_options.key}.${oIdx}`}
                            placeholder={t(
                              meta.FIELDS
                                .measure_customer_satisfaction_per_conversation_data
                                .answer_options.placeholder
                            )}
                            type="text"
                            maxLength={24}
                          />
                        </div>

                        <div className="ml-4 grow-0">
                          <button
                            type="button"
                            className="inline-flex w-full items-center justify-center rounded-md border border-red-500 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-700 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            onClick={(_) => arrayHelpers.remove(oIdx)}
                          >
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    )
                  )}
              </div>

              {formik.errors &&
                formik.errors
                  .measure_customer_satisfaction_per_conversation_data &&
                formik.errors[
                  meta.FIELDS
                    .measure_customer_satisfaction_per_conversation_data
                    .answer_options.key
                ] &&
                typeof formik.errors[
                  meta.FIELDS
                    .measure_customer_satisfaction_per_conversation_data
                    .answer_options.key
                ] === "string" && (
                  <p className="my-2 text-sm text-red-500 ">
                    {
                      formik.errors[
                        meta.FIELDS
                          .measure_customer_satisfaction_per_conversation_data
                          .answer_options.key
                      ] as string
                    }
                  </p>
                )}

              {formik.values.measure_customer_satisfaction_per_conversation_data
                .answer_options &&
                formik.values
                  .measure_customer_satisfaction_per_conversation_data
                  .answer_options.length < 10 && (
                  <button
                    type="button"
                    className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                    onClick={(_) => arrayHelpers.push("")}
                  >
                    <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    {t("add-option", "Add option")}
                  </button>
                )}
            </>
          )}
        ></FieldArray>
      </div>
      <div className="mt-4">
        <TextInputField
          label={t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .answer_validation_message.label
          )}
          name={
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .answer_validation_message.key
          }
          placeholder={t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .answer_validation_message.placeholder
          )}
          type="text"
        />
      </div>
      <div className="mt-4">
        <SwitchField
          name={
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .has_max_answer_attempts.key
          }
          label={t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .has_max_answer_attempts.label
          )}
          help={t(
            meta.FIELDS.measure_customer_satisfaction_per_conversation_data
              .has_max_answer_attempts.help
          )}
        />
        {formik.values.measure_customer_satisfaction_per_conversation_data
          .has_max_answer_attempts && (
          <div className="mt-2">
            <TextInputField
              name={
                meta.FIELDS.measure_customer_satisfaction_per_conversation_data
                  .max_answer_attempts.key
              }
              placeholder="2"
              type="number"
              label={t(
                meta.FIELDS.measure_customer_satisfaction_per_conversation_data
                  .max_answer_attempts.label
              )}
            />
          </div>
        )}
      </div>
      <div className="mt-4 self-center border-t border-gray-200 pt-4">
        <div className="mt-1">
          <TextInputField
            label={t(
              meta.FIELDS
                .autoclose_measure_customer_satisfaction_per_conversation.label
            )}
            name={
              meta.FIELDS
                .autoclose_measure_customer_satisfaction_per_conversation.key
            }
            help={t(
              meta.FIELDS
                .autoclose_measure_customer_satisfaction_per_conversation.help
            )}
            type="number"
            max={24}
            min={1}
          />
        </div>
      </div>
    </div>
  );
}
