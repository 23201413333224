import {
  AtSymbolIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  CursorClickIcon,
  DocumentAddIcon,
  DocumentTextIcon,
  EmojiHappyIcon,
  HashtagIcon,
  InformationCircleIcon,
  LinkIcon,
  LocationMarkerIcon,
  PhoneIcon,
  PhotographIcon,
  VideoCameraIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import * as yup from "yup";
import { TimeWindowMeta } from "src/containers/flow/Helpers";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const FlowStepQuestionMeta = {
  getInitialValues: function (step) {
    return {
      name: step.name,
      step_type: "QUESTION",
      next_step_default_idx: step.next_step_default_idx,
      next_step_alternate_idx: "",
      answer_type: "",
      body: step?.body || "",
      body_type: "text",
      answer_validation_message: "",
      answer_has_range: false,
      answer_allow_decimals: false,
      has_options_from_variable: false,
      save_answer_in_contact_meta: false,
      answer_contact_meta_property: "",
      max_wait_time_amount: "24",
      max_wait_time_unit: "HOUR",
      set_time_window: false,
      time_window_type: "CUSTOM",
      time_windows: [],
      has_max_answer_attempts: false,
      max_answer_attempts: 0,
      answer_failed_next_step_idx: "",
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      next_step_default_idx: yup.string(),
      next_step_alternate_idx: yup.string().when("has_options_from_variable", {
        is: true,
        then: yup.string().required(),
      }),
      max_answer_attempts: yup.string().when("has_max_answer_attempts", {
        is: true,
        then: yup.number().required(ERROR_MESSAGES.required),
      }),
      answer_failed_next_step_idx: yup
        .string()
        .when("has_max_answer_attempts", {
          is: true,
          then: yup.string().required(ERROR_MESSAGES.required),
        }),
      missing_options_message: yup.string().when("has_options_from_variable", {
        is: true,
        then: yup.string().required(),
      }),
      answer_type: yup.string().required(ERROR_MESSAGES.required),
      answer_validation_message: yup.string().when("answer_type", {
        is: (answerType) => ["FREE_TEXT", "ANY"].includes(answerType),
        then: yup.string(),
        otherwise: yup.string().required(ERROR_MESSAGES.required),
      }),
      answer_options: yup
        .mixed()
        .when(["answer_type", "has_options_from_variable"], {
          is: (answerType, hasOptionsFromVariable) =>
            answerType === "SINGLE_OPTION" && !hasOptionsFromVariable,
          then: yup
            .array()
            .of(yup.string().required(ERROR_MESSAGES.required))
            .min(1, ERROR_MESSAGES.minNumber),
          otherwise: yup.array().nullable(),
        })
        .when(["answer_type", "has_options_from_variable"], {
          is: (answerType, hasOptionsFromVariable) =>
            answerType === "BOOL" && !hasOptionsFromVariable,
          then: yup
            .array()
            .of(yup.string().required(ERROR_MESSAGES.required))
            .min(2, "*Provide an option for Yes and another for No")
            .max(2, "*Provide an option for Yes and another for No"),
          otherwise: yup.array().nullable(),
        }),
      has_options_from_variable: yup.bool(),
      options_from_variable: yup.string().when("has_options_from_variable", {
        is: true,
        then: yup.string().required(),
      }),
      option_from_variable_value: yup
        .string()
        .when("has_options_from_variable", {
          is: true,
          then: yup.string().required(),
        }),
      option_from_variable_title: yup
        .string()
        .when("has_options_from_variable", {
          is: true,
          then: yup.string().required(),
        }),
      option_from_variable_description: yup.string().nullable(),
      body: yup.string().required(ERROR_MESSAGES.required),
      answer_allow_decimals: yup.bool(),
      answer_phone_default_country: yup.string(),
      answer_has_range: yup.bool(),
      answer_range_min: yup.string().when("answer_has_range", {
        is: true,
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      answer_range_max: yup.string().when("answer_has_range", {
        is: true,
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      answer_options_render: yup.string().when("answer_type", {
        is: "SINGLE_OPTION",
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      answer_options_render_list_button_title: yup
        .string()
        .when("answer_options_render", {
          is: "LIST",
          then: yup
            .string()
            .max(20, ERROR_MESSAGES.max)
            .required(ERROR_MESSAGES.required),
        }),
      save_answer_in_contact_meta: yup.bool(),
      answer_contact_meta_property: yup
        .string()
        .max(50, ERROR_MESSAGES.max)
        .when("save_answer_in_contact_meta", {
          is: true,
          then: yup
            .string()
            .required(ERROR_MESSAGES.required)
            .max(50, ERROR_MESSAGES.max),
        }),
      max_wait_time_amount: yup
        .number()
        .min(0, ERROR_MESSAGES.minNumberValue)
        .required(ERROR_MESSAGES.required),
      max_wait_time_unit: yup.string().required(ERROR_MESSAGES.required),
      set_time_window: yup.bool(),
      time_window_type: yup.string().when("set_time_window", {
        is: true,
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      time_windows: yup.mixed().when(["set_time_window", "time_window_type"], {
        is: (setTimeWindow, timeWindowType) =>
          setTimeWindow && timeWindowType === "CUSTOM",
        then: yup
          .array()
          .of(TimeWindowMeta.getSchema())
          .min(6, ERROR_MESSAGES.length_error),
      }),
    });
  },
  validate: function () {
    return {};
  },
  formatForSave: function (step) {
    const nextStep = { ...step };
    if (!nextStep.answer_phone_default_country) {
      delete nextStep.answer_phone_default_country;
    }
    if (["FREE_TEXT", "ANY"].includes(nextStep.answer_type)) {
      delete nextStep.answer_validation_message;
    }
    if (
      nextStep.has_options_from_variable ||
      !["SINGLE_OPTION", "BOOL"].includes(nextStep.answer_type)
    ) {
      nextStep[FlowStepQuestionMeta.FIELDS.answer_options.key] = [];
      nextStep[FlowStepQuestionMeta.FIELDS.answer_instructions.key] = "";
    }

    return nextStep;
  },
  ANSWER_TYPES: [
    {
      label: "Free Text",
      value: "FREE_TEXT",
      icon: (
        <div className="h-5 w-5" aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="4 7 4 4 20 4 20 7"></polyline>
            <line x1="9" y1="20" x2="15" y2="20"></line>
            <line x1="12" y1="4" x2="12" y2="20"></line>
          </svg>
        </div>
      ),
      help: "Allow a free text response",
    },
    {
      label: "Single Option",
      value: "SINGLE_OPTION",
      icon: <CheckIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a single option from a list",
    },
    {
      label: "Number",
      value: "NUMBER",
      icon: <HashtagIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a valid number",
    },
    {
      label: "Location",
      value: "LOCATION",
      icon: <LocationMarkerIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a valid location and saves its latitude and longitude",
    },
    {
      label: "URL",
      value: "URL",
      icon: <LinkIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a valid URL",
      instructions: "Be sure to add http:// or https:// at the start.",
    },
    {
      label: "Email",
      value: "EMAIL",
      icon: <AtSymbolIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a valid email",
    },
    {
      label: "File",
      value: "FILE",
      icon: <DocumentAddIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows any file: document, image or video",
    },
    {
      label: "Document",
      value: "DOCUMENT",
      icon: <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a PDF file",
    },
    {
      label: "Image",
      value: "IMAGE",
      icon: <PhotographIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows an image",
    },
    {
      label: "Video",
      value: "VIDEO",
      icon: <VideoCameraIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a video",
    },
    {
      label: "Phone",
      value: "PHONE",
      icon: <PhoneIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a valid phone",
      instructions:
        "Send us your full phone number, like +[country code][10 digits].",
    },
    {
      label: "Yes/No",
      value: "BOOL",
      icon: <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a simple Yes/No answer",
      soon: true,
    },
    {
      label: "Date",
      value: "DATE",
      icon: <CalendarIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a valid date, supports several formats",
      instructions:
        "Please input the date as DD/MM/YYYY or as structured text like April 1st 2022.",
    },
    {
      label: "Time",
      value: "TIME",
      icon: <ClockIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows a valid time",
      soon: true,
    },
    {
      label: "Datetime",
      value: "DATETIME",
      icon: <CalendarIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Requires both a date and time",
      soon: true,
    },
    {
      label: "Any message",
      value: "ANY",
      icon: <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Allows any kind of message: text, media, etc",
    },
  ],
  ANSWER_OPTIONS_RENDER_TYPES: [
    {
      label: "Buttons",
      value: "BUTTONS",
      icon: <CursorClickIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Will show the options as buttons, max 3.",
      single_option_only: true,
    },
    {
      label: "List of options",
      value: "LIST",
      icon: <ViewListIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Will show the options as a dropdown list, max 10.",
      single_option_only: true,
    },
    {
      label: "Emojis",
      value: "EMOJIS",
      icon: <EmojiHappyIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Will show the options as a list of emoji - option text.",
      single_option_only: false,
    },
    {
      label: "Numbers",
      value: "NUMBERS",
      icon: "1...10",
      help: "Will show the options as a list of number - option text.",
      single_option_only: false,
    },
  ],
  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    next_step_default_idx: {
      key: "next_step_default_idx",
      label: "Next step",
      help: "If you can't find the step you'd like to go to next, check that it has a name.",
      placeholder: "Step 1 Welcome",
    },
    body: {
      key: "body",
      label: "Message body",
      help: "",
      placeholder:
        "Hey, thanks for contacting us. What do you need help with today?",
    },
    has_options_from_variable: {
      key: "has_options_from_variable",
      label: "Use a variable as a data source for options?",
      help: "You can select a variable as data source for the options and choose which properties will be used as value and title.",
    },
    options_from_variable: {
      key: "options_from_variable",
      label: "Data source for options",
      placeholder: "Select variable",
    },
    option_from_variable_value: {
      key: "option_from_variable_value",
      label: "Value of each option",
      placeholder: "Select value",
    },
    option_from_variable_title: {
      key: "option_from_variable_title",
      label: "Title of each option",
      placeholder: "Select title",
    },
    option_from_variable_description: {
      key: "option_from_variable_description",
      label: "Longer description for each option",
      placeholder: "Select description",
    },
    missing_options_message: {
      key: "missing_options_message",
      label: "Message to display when no options were found",
      placeholder: "No options can be found...",
    },
    next_step_alternate_idx: {
      key: "next_step_alternate_idx",
      label: "If no results are found, go to step",
      placeholder: "Select step",
    },
    answer_type: {
      key: "answer_type",
      label: "Answer type",
      help: "",
    },
    answer_validation_message: {
      key: "answer_validation_message",
      label: "Answer validation message",
      help: "If the recipient doesn't provide a value according to this question's answer type, we'll try again with this message.",
      placeholder: "Please specify a proper value.",
    },
    answer_options: {
      key: "answer_options",
      label: "Answer options",
      help: "If the recipient doesn't provide a value according to this question's answer type, we'll try again with this message.",
      placeholder: "Check order status.",
    },
    next_steps_for_options_idx: {
      key: "next_steps_for_options_idx",
    },
    answer_instructions: {
      key: "answer_instructions",
      label: "Answer instructions",
      help: "These instructions will be appended to the question message, so the user knows how to properly answer.",
    },
    answer_options_render: {
      key: "answer_options_render",
      label: "How would you like to show these options?",
    },
    answer_options_render_list_button_title: {
      key: "answer_options_render_list_button_title",
      label: "Open list button text",
      placeholder: "For example, Available Options",
    },
    answer_phone_default_country: {
      key: "answer_phone_default_country",
      label: "Default country code for phone number",
      placeholder: "+1",
      help: "If set, we'll use this to check that the number provided is a possible phone number. Otherwise the user must answer with +[country code][10 digits].",
    },
    answer_allow_decimals: {
      key: "answer_allow_decimals",
      label: "Allow decimals in answer?",
    },
    answer_has_range: {
      key: "answer_has_range",
      label: "Limit answers to values in a specific range?",
    },
    answer_range_min: {
      key: "answer_range_min",
      label: "Minimum value expected (included)",
    },
    answer_range_max: {
      key: "answer_range_max",
      label: "Maximum value expected (included)",
    },
    save_answer_in_contact_meta: {
      key: "save_answer_in_contact_meta",
      label: "Save answer in contact?",
    },
    answer_contact_meta_property: {
      key: "answer_contact_meta_property",
      label: "Under what variable name do we save this answer?",
      help: "You'll see the saved value in the contact custom attributes.",
    },
  },
};
