import { MutableRefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { FlowStepData } from "@hilos/types/flow";
import { hasItems } from "src/helpers/utils";
import useWrappedSteps from "src/hooks/useWrappedSteps";
import FlowViewerStepExecutionDetail from "./FlowViewerStepExecutionDetail";
import FlowViewerStepExecutionList from "./FlowViewerStepExecutionList";
import { SelectedItemState } from "./hooks/useFlowLayout";

interface FlowViewerStepDetailProps {
  stepsRef: MutableRefObject<FlowStepData[]>;
  selectedItem: SelectedItemState;
  flowExecutionId?: string;
  flowExecutionContactId?: string;
  onClearSelectItem: () => void;
}

function FlowViewerStepDetail({
  stepsRef,
  selectedItem,
  flowExecutionId,
  flowExecutionContactId,
  onClearSelectItem,
}: FlowViewerStepDetailProps) {
  const [t] = useTranslation();
  const { STEP_TYPES_DYNAMIC } = useWrappedSteps();

  const [id, executionStepId] = useMemo(() => {
    if (selectedItem && selectedItem.id && selectedItem.data) {
      if (flowExecutionId && flowExecutionContactId) {
        if (
          hasItems(selectedItem.data.execution_steps) &&
          selectedItem.data.execution_steps[0]
        ) {
          return [selectedItem.id, selectedItem.data.execution_steps[0]];
        }
      } else {
        return [selectedItem.id, null];
      }
    }
    return [null, null];
  }, [selectedItem, flowExecutionId, flowExecutionContactId]);

  const [step, name] = useMemo(() => {
    try {
      if (id && stepsRef.current) {
        const nextIndex = stepsRef.current.findIndex((step) => step.id === id);

        if (nextIndex !== undefined) {
          const nextStepData = stepsRef.current[nextIndex];

          if (nextStepData && nextStepData.step_type) {
            const nextStepValue = STEP_TYPES_DYNAMIC[nextStepData.step_type];

            if (nextStepValue) {
              return [nextStepValue, nextStepData.name || ""];
            }
          }
        }
      }
    } catch {}

    return [null, null];
  }, [STEP_TYPES_DYNAMIC, id, stepsRef]);

  return (
    <Transition
      show={Boolean(step)}
      enter="transition duration-300 ease-out"
      enterFrom="transform scale-90 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-90 opacity-0"
      className="absolute right-4 top-4 z-10 h-auto w-96 rounded-lg bg-gray-50 pt-2 pb-1 shadow-xl"
    >
      {step && id && (
        <>
          <div className="flex w-full flex-row px-2 justify-between">
            <div className="flex flex-row items-center">
              <step.icon className="h-5 w-5 text-gray-800" />
              <h1 className="text-md font-small ml-2 text-gray-800">
                {t(step.name)}
              </h1>
            </div>
            <button
              type="button"
              className="px-1 text-gray-700 focus:outline-none"
              onClick={onClearSelectItem}
            >
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mx-2 border-b pb-1 flex flex-row items-center">
            <h1 className="bg-gray-50 pr-2 text-lg font-medium outline-none truncate">
              {name}
            </h1>
          </div>
          <div className="max-h-[65vh] w-full overflow-y-auto p-2 md:max-h-[70vh]">
            {executionStepId ? (
              <FlowViewerStepExecutionDetail key={id} id={executionStepId} />
            ) : (
              <FlowViewerStepExecutionList
                key={id}
                stepId={id}
                flowExecutionId={flowExecutionId}
                flowExecutionContactId={flowExecutionContactId}
              />
            )}
          </div>
        </>
      )}
    </Transition>
  );
}

export default FlowViewerStepDetail;
