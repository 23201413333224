import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon, PlusSmIcon, XIcon } from "@heroicons/react/outline";
import { ContactDetailRead } from "@hilos/types/private-schema";
import { userAvatar, userToString } from "../../../../Helpers";
import useAccount from "../../../../hooks/useAccount";
import { UpdateContactFn } from "../../../../hooks/useInboxContactDetails";
import AssignmentForm from "./ContactAssignmentForm";

interface ContactAssignmentListProps {
  contact: ContactDetailRead;
  onUpdateContact: UpdateContactFn;
}

function ContactAssignmentList({
  contact,
  onUpdateContact,
}: ContactAssignmentListProps) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const { members } = useAccount();
  const currentDefaultAssignees = useMemo(
    () => contact.default_assignees ?? [],
    [contact.default_assignees]
  );

  const handleRemoveUser = useCallback(
    (userId: number) => {
      return () => {
        const nextAssignment = [...currentDefaultAssignees]
          .filter((user) => user.id !== userId)
          .map((user) => user.id);
        onUpdateContact({
          default_assignees: nextAssignment,
          overwrite_default_assignees: true,
        });
        setShowForm(false);
      };
    },
    [currentDefaultAssignees, onUpdateContact]
  );

  return (
    <div data-tour="default-assignees">
      <div className="flex items-center px-3">
        <div className="flex flex-1 items-center text-xs font-medium uppercase tracking-wide text-gray-500">
          {t("inbox:default-assignees", "Default assignees")}
          <a
            href="https://hilos.notion.site/Assignees-22cc686baeab42409c0e4539fd6180ca"
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLinkIcon className="ml-1 h-4 w-4" />
          </a>
        </div>
        <div className="flex-shrink-0">
          {showForm ? (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(false)}
            >
              <XIcon className="h-5 w-5" />
            </button>
          ) : (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(true)}
            >
              <PlusSmIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>

      <div className="overflow-y-auto bg-gray-100 ">
        <ul className="divide-y divide-gray-200">
          {currentDefaultAssignees.length === 0 ? (
            <p className="px-3 text-xs text-gray-400">
              {t(
                "inbox:no-default-assignees",
                "No one is assigned by default yet."
              )}
            </p>
          ) : (
            <>
              {currentDefaultAssignees.map((user, idx) => (
                <li key={idx} className="py-1 px-3 hover:bg-gray-200">
                  <div className="flex items-center space-x-2">
                    <div className="flex-shrink-0">
                      {userAvatar(
                        user,
                        `h-6 w-6 text-xs ring-2 ring-gray-100 group-hover:ring-gray-200`
                      )}
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900">
                        {userToString(user)}
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-400 transition duration-150 ease-in-out focus:outline-none"
                        onClick={handleRemoveUser(user.id)}
                      >
                        <XIcon className="h-4 w-4 text-gray-500" />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </>
          )}
          {showForm && (
            <AssignmentForm
              members={members}
              contact={contact}
              onClose={() => setShowForm(false)}
              onUpdateContact={onUpdateContact}
            />
          )}
        </ul>
      </div>
    </div>
  );
}

export default ContactAssignmentList;
