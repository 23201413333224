import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowSmLeftIcon, CheckCircleIcon } from "@heroicons/react/outline";
import axios from "axios";
import logo from "../../assets/img/hilos_imago.png";
import { API_ROUTES, buildAPIRoute } from "../../router/router";
import { ResetPasswordForm } from "./ResetPasswordForm";

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.post(buildAPIRoute(API_ROUTES.RESET_PASSWORD), formData);
      setSuccess(true);
      setSubmitted(true);
    } catch (err) {
      setSuccess(false);
      if (err.response.status === 400) {
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(t("error-general"));
      }
      setSubmitted(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src={logo} alt="Workflow" />
              <Trans i18nKey="settings:reset-password-form.description">
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Reset password
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Input the email you used when signing up for Hilos.
                </p>
              </Trans>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                {submitted && success ? (
                  <div className="rounded-md bg-green-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <Trans i18nKey="settings:reset-password-form.success">
                          <h3 className="text-sm font-medium text-green-800">
                            Success!
                          </h3>
                          <div className="mt-2 space-y-1 text-sm text-green-700">
                            <p>
                              We sent you an email with intructions to reset
                              your password.{" "}
                            </p>
                            <p>
                              If you can't find it on your inbox, please check
                              your <strong>spam</strong> folder or try resetting
                              it again, making sure to use the email you signed
                              up with.
                            </p>
                          </div>
                        </Trans>
                        <div className="mt-4">
                          <div className="-mx-2 -my-1.5 flex">
                            <button
                              type="button"
                              onClick={(_) => navigate(-1)}
                              className="flex items-center rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                            >
                              <ArrowSmLeftIcon
                                className="mr-2 h-5 w-5"
                                aria-readonly={true}
                              />
                              {t("back")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ResetPasswordForm
                    onSubmit={onSubmit}
                    success={success}
                    submitted={submitted}
                  ></ResetPasswordForm>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
