import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/outline";
import { ConversationStatusEnum } from "@hilos/types/private-schema";
import { useFlowExecutionContactCancelCurrent } from "src/hooks/useFlowExecutionContactDetail";
import useHilosStore from "src/hooks/useHilosStore";
import { UpdateConversationStatusFn } from "src/hooks/useInboxContactDetails";
import usePopper, { Portal } from "src/hooks/usePopper";
import { CONVERSATION_STATUS, getConversationStatus } from "../InboxMeta";

interface ConversationHeaderStatusProps {
  inboxContactId: string;
  onUpdateConversationStatus: UpdateConversationStatusFn;
  status: ConversationStatusEnum;
  isSubmittingStatus: boolean;
}

export default function ConversationHeaderStatus({
  inboxContactId,
  onUpdateConversationStatus,
  status,
  isSubmittingStatus,
}: ConversationHeaderStatusProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const cancelCurrent = useFlowExecutionContactCancelCurrent(inboxContactId);

  const handleChangeStatus = useCallback(
    (status: ConversationStatusEnum) => {
      return () => {
        onUpdateConversationStatus(status);
      };
    },
    [onUpdateConversationStatus]
  );
  const [trigger, container] = usePopper({
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  if (isSubmittingStatus) {
    return (
      <div className="flex flex-col sm:flex-row rounded-full bg-gray-200 px-2.5 py-1 text-gray-600">
        <span className="inline-flex items-center text-xs font-medium uppercase">
          {t("updating")}
        </span>
      </div>
    );
  }

  if (status === "FLOW") {
    return (
      <div className="flex flex-col sm:flex-row rounded-full bg-blue-100 px-2.5 py-1 text-blue-800">
        <span className="inline-flex items-center text-xs font-medium uppercase">
          {t(CONVERSATION_STATUS[status].label)}
        </span>
        <div className="flex justify-center ">
          <button
            type="button"
            onClick={cancelCurrent}
            className="font-medium ml-2"
          >
            <XCircleIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    );
  }

  if (status === "RESOLVED") {
    return (
      <div className="flex flex-col sm:flex-row rounded-full bg-blue-100 px-2.5 py-1 text-blue-800">
        <span className="inline-flex items-center text-xs font-medium uppercase">
          {t(CONVERSATION_STATUS[status].label)}
        </span>
        <div className="flex justify-center ">
          <button
            type="button"
            onClick={handleChangeStatus("CLOSED")}
            className="font-medium ml-2"
          >
            <XCircleIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      {CONVERSATION_STATUS[status].next_steps.length < 1 ? (
        <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-1 text-xs font-medium uppercase text-blue-800">
          {t(CONVERSATION_STATUS[status].label)}
        </span>
      ) : (
        <Menu
          as="div"
          data-tour="convo-status-button"
          className="relative inline-block text-left"
        >
          <div>
            <Menu.Button
              className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-1 text-xs font-medium uppercase text-blue-800"
              ref={trigger}
            >
              {t(CONVERSATION_STATUS[status].label)}
              <ChevronDownIcon
                className="-mr-1 ml-1 h-4 w-4"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Portal data-tour="convo-status-menu">
            <Menu.Items
              className="right-0 z-10 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              ref={container}
            >
              <div className="py-0">
                {getConversationStatus(
                  status,
                  session &&
                    session.account &&
                    session.account
                      .measure_customer_satisfaction_per_conversation
                ).next_steps.map((status) => (
                  <Menu.Item key={status}>
                    {({ active }) => (
                      <button
                        type="button"
                        className={`group w-full px-4 py-2 text-left text-sm hover:rounded-md ${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        }`}
                        onClick={handleChangeStatus(
                          status as ConversationStatusEnum
                        )}
                      >
                        {`${t("move-to", "Move to")} ${t(
                          CONVERSATION_STATUS[status].label
                        )}`}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Portal>
        </Menu>
      )}
    </>
  );
}
