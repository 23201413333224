import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowSmRightIcon, MailIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import CheckboxField from "../../components/Form/CheckboxField";
import PasswordField from "../../components/Form/PasswordField";
import TextInputField from "../../components/Form/TextInputField";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import { ERROR_MESSAGES } from "../../constants/Form";
import { buildRoute } from "../../router/router";

const initialValues = {
  email: "",
  password: "",
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email(ERROR_MESSAGES.email)
    .required(ERROR_MESSAGES.required),
  password: yup
    .string()
    .min(8, ERROR_MESSAGES.min)
    .required(ERROR_MESSAGES.required),
});

const FIELDS = {
  email: {
    key: "email",
    label: "Email",
  },
  password: {
    key: "password",
    label: "Password",
  },
};

export const LoginForm = ({
  onSubmit,
  backendError,
  backendValidationErrors,
  success,
  submitted,
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit} className="space-y-4">
          <APIErrors
            APIError={backendError}
            APIValidationErrors={backendValidationErrors}
            fieldTranslations={FIELDS}
          ></APIErrors>

          <TextInputField
            placeholder={t("components:input.email.placeholder", "Email")}
            name={FIELDS.email.key}
            icon={MailIcon}
            type="email"
          />

          <PasswordField
            placeholder={t("components:input.password.placeholder", "Password")}
            name={FIELDS.password.key}
          />

          <div className="flex items-center justify-between">
            <CheckboxField
              name="remember_me"
              label={t("login:remember-me", "Remember me")}
            />

            <div className="text-sm">
              <Link
                to={buildRoute("reset-password")}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {t("login:reset-password", "Forgot your password?")}
              </Link>
            </div>
          </div>

          <div>
            <StateButton
              isSubmitting={formik.isSubmitting}
              disabled={
                formik.errors &&
                Object.getOwnPropertyNames(formik.errors).length > 0
              }
              submittingText={t("sending")}
              initialText={
                <>
                  {t("login:login", "Login")}
                  <ArrowSmRightIcon
                    className="ml-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </>
              }
              successText={t("redirecting", "Redirecting...")}
              submitted={submitted}
              success={success}
            />
          </div>

          <div className="mt-3 text-xs text-gray-400">
            <Trans i18nKey="login:signup">
              Don't have an account yet?{" "}
              <Link
                to={buildRoute("signup")}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Sign up to get started.
              </Link>
            </Trans>
          </div>
        </form>
      )}
    </Formik>
  );
};
