import { ReactElement } from "react";
import ActiveAwareLink from "src/components/ActiveAwareLink";

interface NavigationItemProps {
  name: string;
  target?: string;
  icon: ReactElement;
  href: string;
  isChild?: boolean;
  isMatchEnd?: boolean;
  onCloseMenu?: () => void;
}

function NavigationItem({
  name,
  target,
  icon,
  href,
  isMatchEnd,
  isChild,
  onCloseMenu,
}: NavigationItemProps) {
  return (
    <button onClick={onCloseMenu} type="button" className="w-full">
      <ActiveAwareLink
        to={href}
        className={(isActive) =>
          `group flex items-center rounded-md px-3 py-3 text-sm font-medium sm:px-2 sm:py-2 ${
            isChild ? "pl-5 pr-3" : ""
          } ${
            isActive
              ? "bg-gray-100 text-hilos"
              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 focus:hover:bg-gray-50"
          }`
        }
        target={target}
        isMatchEnd={isMatchEnd}
      >
        {(isActive) => (
          <span
            className={`flex flex-shrink-0 items-center ${
              isActive
                ? "text-hilos"
                : "text-gray-400 group-hover:text-gray-500"
            }`}
          >
            {icon}
            {name}
          </span>
        )}
      </ActiveAwareLink>
    </button>
  );
}
export default NavigationItem;
