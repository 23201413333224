import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import axios from "axios";
import { FormikValues } from "formik";
import debounce from "lodash/debounce";
import { API_ROUTES, buildAPIRoute } from "../../router/router";

function WhatsAppTemplateAutosave({ formik }: FormikValues) {
  const { t } = useTranslation();
  const params = useParams();

  const [show, setShow] = useState(false);
  const shouldAutosave = useRef(false);

  const autosave = useCallback(
    debounce(async (values) => {
      try {
        const data = {
          name: values["name"],
          channel: values["channel"],
          category: values["category"],
          language: values["language"],
          components: values["components"],
        };
        Object.keys(data).forEach((k) => !data[k] && delete data[k]);

        await axios.patch(
          buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_AUTOSAVE, {
            ":id": params.id,
          }),
          data
        );
        setShow(true);
        hideMessage();
      } catch (e) {
        console.log(e);
      }
    }, 1000),
    [params.id]
  );

  const hideMessage = useCallback(
    debounce(async () => {
      setShow(false);
    }, 3000),
    [params.id]
  );

  useEffect(() => {
    // Avoid running on mount
    if (shouldAutosave.current) {
      autosave(formik.values);
    } else {
      shouldAutosave.current = true;
    }
    const interval = setInterval(() => {
      setShow(false);
    }, 1000 * 3);
    return () => clearInterval(interval);
  }, [autosave, formik.values]);

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-10 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-end space-y-4">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-32 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-3">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <CheckIcon
                    className="h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    {t("saved", "Saved!")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default WhatsAppTemplateAutosave;
