import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CalendarIcon, TerminalIcon } from "@heroicons/react/outline";
import IntegrationEventLogs from "src/containers/integrations/IntegrationEventLogs";
import WebhookLogs from "src/containers/integrations/Webhooks/WebhookLogs";
import HeaderNavigation from "src/components/HeaderNavigation";
import { classNames } from "src/Helpers";
import { buildRoute } from "src/router/router";

const TABS = {
  WEBHOOK_LOGS: "webhook-logs",
  INTEGRATION_EVENT_LOGS: "integration-event-logs",
};

export default function WebhookSubscriptionLogList() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TABS.WEBHOOK_LOGS);
  const tabs = {
    WEBHOOK: {
      name: t("integrations:webhooks.webhook-logs", "Webhook Logs"),
      description: t(
        "integrations:webhooks.webhook-logs-description",
        "Find here what you need to debug webhook events."
      ),
      icon: TerminalIcon,
      value: TABS.WEBHOOK_LOGS,
      current: currentTab === TABS.WEBHOOK_LOGS,
    },
    INTEGRATION: {
      name: t(
        "integrations:events.integration-event-logs",
        "Integration Event Logs"
      ),
      description: t(
        "integrations:events.integration-event-logs-description",
        "Find here what you need to debug events from an external app like HubSpot."
      ),
      icon: CalendarIcon,
      value: TABS.INTEGRATION_EVENT_LOGS,
      current: currentTab === TABS.INTEGRATION_EVENT_LOGS,
    },
  };
  const tabList = [tabs.WEBHOOK, tabs.INTEGRATION];

  return (
    <div className="relative flex h-full flex-col">
      <div className="h-screen overflow-y-auto bg-gray-50">
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("monitoring"),
                url: buildRoute("dev-monitoring"),
              },
            ]}
          />
          {currentTab === TABS.WEBHOOK_LOGS ? (
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <tabs.WEBHOOK.icon
                    className="mr-2 h-7 w-7"
                    aria-hidden="true"
                  />
                  {tabs.WEBHOOK.name}
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  {tabs.WEBHOOK.description}
                </p>
              </div>
            </div>
          ) : (
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <tabs.INTEGRATION.icon
                    className="mr-2 h-7 w-7"
                    aria-hidden="true"
                  />
                  {tabs.INTEGRATION.name}
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  {tabs.INTEGRATION.description}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 px-4 sm:px-6 lg:px-8">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              {t("select-a-tab")}
            </label>
            <select
              id="tabs"
              name="tabs"
              className="mb-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={tabList.find((tab) => tab.current)?.value}
              onChange={(e) => setCurrentTab(e.target.value)}
            >
              {tabList.map((tab) => (
                <option key={tab.value} value={tab.value}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabList.map((tab) => (
                  <button
                    key={tab.name}
                    type="button"
                    className={classNames(
                      tab.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                      "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                    onClick={(_) => setCurrentTab(tab.value)}
                  >
                    <tab.icon
                      className={classNames(
                        tab.current
                          ? "text-indigo-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "-ml-0.5 mr-2 h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                    <span>{tab.name}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className="bg-gray-50">
          {currentTab === TABS.WEBHOOK_LOGS && <WebhookLogs />}

          {currentTab === TABS.INTEGRATION_EVENT_LOGS && (
            <IntegrationEventLogs />
          )}
        </div>
      </div>
    </div>
  );
}
