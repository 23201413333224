import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import LocalTable from "src/components/LocalTable";
import { hasItems } from "src/helpers/utils";
import { useInboxUsageDetailAnalytics } from "src/hooks/useInboxUsageDetailAnalytics";
import { API_ROUTES } from "src/router/router";
import { paramsToQueryString } from "../FiltersMeta";
import InboxUsageDownloadCSVButton from "../InboxUsageDownloadCSVButton";
import { InboxReportProps } from "../summary/InboxSummary";

export default function InboxDetail({ params }: InboxReportProps) {
  const { t } = useTranslation();
  const paramsQS = paramsToQueryString(params);
  const { inboxUsageDetail, columnsTable: inboxDetailColumnsTable } =
    useInboxUsageDetailAnalytics(paramsQS + "&period_type_type=WEEK");

  if (!inboxUsageDetail) {
    return null;
  }
  return (
    <>
      <div className="flex flex-row-reverse">
        <InboxUsageDownloadCSVButton
          url={API_ROUTES.ANALYTICS_INBOX_USAGE_DETAIL}
          params={paramsQS}
          report_type="Conversation Detail"
        />
      </div>
      <LocalTable<string[]>
        tableTitle={
          <>
            {t(
              "reports:detail.detail-per-date-period",
              "Conversation detail from all sources"
            )}
          </>
        }
        columns={inboxDetailColumnsTable as unknown as ColumnDef<string[]>[]}
        data={Object.values(inboxUsageDetail)
          .filter((inboxUsageData) => hasItems(inboxUsageData.conversations))
          .flatMap((inboxUsage) => inboxUsage.conversations)}
        columnVisibility={{
          should_be_included: false,
        }}
        enableColumnSorting={true}
        disableSearch={true}
      />
    </>
  );
}
