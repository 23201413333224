import { useTranslation } from "react-i18next";
import { GlobeAltIcon } from "@heroicons/react/outline";
import { TFunction } from "i18next";
import SelectorField from "src/components/Form/SelectorField";
import LineTabs from "src/components/LineTabs";
import { METHOD_TYPES } from "../../constants/http_form";
import TextInputFieldWithVariables from "../TextInputFieldWithVariables";
import HTTPFormBody from "./HTTPFormBody";
import HTTPFormHeaders from "./HTTPFormHeaders";
import HTTPFormParams from "./HTTPFormParams";

interface HTTPFormProps {
  path: string;
  fieldMethodName: string;
  fieldURLName: string;
  fieldBodyName: string;
  fieldHeadersName: string;
  fieldParamsName: string;
  currentStepIndex: number | null;
}

const formatOptionLabel =
  (t: TFunction<"translation", undefined, "translation">) =>
  ({ label, help, color }) =>
    (
      <div className="flex items-center justify-between py-3">
        <div className="flex items-center">
          <div className="mx-4">
            <div
              className="h-5 w-5 rounded-full font-extrabold"
              style={{ color }}
              aria-hidden="true"
            >
              {(label && label[0]) || null}
            </div>
          </div>
          <div>
            <h4 className="font-medium">{label}</h4>
            {help && <p className="text-xs">{t(help)}</p>}
          </div>
        </div>
      </div>
    );

function HTTPForm({
  path,
  fieldMethodName,
  fieldURLName,
  fieldBodyName,
  fieldHeadersName,
  fieldParamsName,
  currentStepIndex,
}: HTTPFormProps) {
  const { t } = useTranslation();

  return (
    <>
      <SelectorField
        name={`${path}.${fieldMethodName}`}
        label={t("components:http-form.method.label", "Method type")}
        options={METHOD_TYPES}
        formatOptionLabel={formatOptionLabel(t)}
      />
      <div className="space-y-4">
        <TextInputFieldWithVariables
          type="text"
          path={path}
          name={fieldURLName}
          label={t("components:http-form.url.label", "URL")}
          placeholder={t(
            "components:http-form.url.placeholder",
            "Enter a valid URL"
          )}
          icon={GlobeAltIcon}
          variableSeparator=""
          currentStepIndex={currentStepIndex}
        />

        <LineTabs
          labels={[
            t("components:http-form.body.label", "Body"),
            t("components:http-form.headers.label", "Headers"),
            t("components:http-form.query.label", "Query"),
          ]}
        >
          <HTTPFormBody
            path={path}
            fieldBodyName={fieldBodyName}
            fieldHeadersName={fieldHeadersName}
            currentStepIndex={currentStepIndex}
          />
          <HTTPFormHeaders
            path={path}
            fieldBodyName={fieldBodyName}
            fieldHeadersName={fieldHeadersName}
            currentStepIndex={currentStepIndex}
          />
          <HTTPFormParams
            path={path}
            fieldURLName={fieldURLName}
            fieldParamsName={fieldParamsName}
            currentStepIndex={currentStepIndex}
          />
        </LineTabs>
      </div>
    </>
  );
}

export default HTTPForm;
