import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";

interface WhatsAppTemplateFormMissingFieldsNoticeProps {
  formik: FormikProps<any>;
}

export default function WhatsAppTemplateFormMissingFieldsNotice({
  formik,
}: WhatsAppTemplateFormMissingFieldsNoticeProps) {
  const { t } = useTranslation();
  return (
    <>
      {formik.errors && Object.getOwnPropertyNames(formik.errors).length > 0 && (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                {t("templates:missing-fields.title", "Missing fields")}
              </h3>
              <div className="text-sm text-yellow-700">
                <p>
                  {t(
                    "templates:missing-fields.description",
                    "There are fields with missing data, complete them to continue submitting this template."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
