interface InboxActionButtonPreviewProps {
  buttonClasses: string;
  buttonIcon: string;
  buttonLabel: string;
}

export default function InboxActionButtonPreview({
  buttonClasses,
  buttonIcon,
  buttonLabel,
}: InboxActionButtonPreviewProps) {
  return (
    <button
      type="button"
      className={`inline-flex w-full items-center justify-center ${buttonClasses}`}
    >
      {buttonIcon && (
        <img src={buttonIcon} className="mr-2 max-h-5 w-5" alt="Button icon" />
      )}
      {buttonLabel}
    </button>
  );
}
