import { API_ROUTES, buildAPIRoute } from "../../router/router";
import { Trans, useTranslation } from "react-i18next";

import { ArrowSmRightIcon } from "@heroicons/react/outline";
import Button from "src/components/Button";
import EmptyStateComponent from "src/components/EmptyStates/EmptyStateComponent";
import FlowCreate from "./FlowCreate";
import { FlowListRead } from "src/types/private-schema";
import FlowListTableRow from "./FlowListTableRow";
import { SessionData } from "src/types/hilos";
import Table from "src/components/Table/Table";

interface FlowListTableProps {
  triggerReload: boolean;
  session: SessionData | null;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  discardUnpublishedChanges: (flow: FlowListRead) => Promise<void>;
  duplicateFlow: (flowId: string) => Promise<void>;
  upgradeFlow: (flowId: string) => Promise<boolean>;
  handleDeleteAction: (flow: FlowListRead) => void;
  handleManageTemplateAction: (flow: FlowListRead) => void;
}

export default function FlowListTable({
  triggerReload,
  setCurrentTab,
  session,
  ...props
}: FlowListTableProps) {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="mb-6 h-full sm:px-6 sm:pt-8 lg:px-8">
        <Table<FlowListRead>
          baseURL={buildAPIRoute(API_ROUTES.FLOW_CREATE_LIST)}
          paginated={true}
          filters={["channel"]}
          triggerReload={triggerReload}
          tableHead={
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("name", "Name")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("created-by", "Created by")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("flows:trigger-type", "Trigger Type")}
                </th>
                {session?.account.has_used_multiple_channels && (
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("channel", "Channel")}
                  </th>
                )}
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500 sticky right-10 bg-gray-50"
                >
                  {t("flows:is-active", "Can be triggered?")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500 sticky right-10 bg-gray-50"
                >
                  {t("status", "Status")}
                </th>
                <th scope="col" className="sticky right-0 bg-gray-50" />
              </tr>
            </thead>
          }
          tableRow={(flow) => (
            <FlowListTableRow flow={flow} session={session} {...props} />
          )}
          dataName={t("flows", "Flows")}
          noResults={
            <Trans i18nKey="flows:no-results-with-filters">
              <h4>Couldn't find any flows with these filters.</h4>
              <p className="text-muted">Remove filters to try again.</p>
            </Trans>
          }
          createObject={
            <EmptyStateComponent routeName="flows" showInfo={true}>
              <div className="flex flex-col items-center justify-center gap-2">
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    setCurrentTab("templates");
                  }}
                >
                  Start with a template
                  <ArrowSmRightIcon
                    className="ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Button>
                <FlowCreate />
              </div>
            </EmptyStateComponent>
          }
        ></Table>
      </div>
    </div>
  );
}
