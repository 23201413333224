import { useTranslation } from "react-i18next";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { InboxContactRead } from "@hilos/types/private-schema";
import { UpdateConversationFn } from "../../../../hooks/useInboxContactDetails";
import AssignmentList from "./AssignmentList";
import ConversationList from "./ConversationList";
import ConversationTagList from "./ConversationTagList";

interface ConversationTabProps {
  inboxContact: InboxContactRead;
  handleUpdateConversation: UpdateConversationFn;
}

function ConversationTab({
  inboxContact,
  handleUpdateConversation,
}: ConversationTabProps) {
  const { t } = useTranslation();
  if (!inboxContact || !inboxContact.last_conversation) {
    return null;
  }

  return (
    <div className="mt-2 flex-1 space-y-5 overflow-x-hidden">
      {["RESOLVED", "CLOSED"].includes(
        inboxContact.last_conversation.status
      ) && (
        <div className="px-2 pt-2">
          <div className="flex rounded-md bg-blue-50 p-4 space-x-3 items-start">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="flex flex-1">
              <p className="text-sm text-blue-700">
                {t(
                  "inbox:labels.last-conversation-not-active",
                  "Showing assigned and tags from last conversation ({{status}})",
                  {
                    status: `${t(
                      inboxContact.last_conversation.status.toLowerCase()
                    )}`.toUpperCase(),
                  }
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      <ConversationTagList
        conversation={inboxContact.last_conversation}
        handleUpdateConversation={handleUpdateConversation}
      />

      <AssignmentList
        conversation={inboxContact.last_conversation}
        onUpdateConversation={handleUpdateConversation}
      />
      <ConversationList inboxContact={inboxContact} />
    </div>
  );
}

export default ConversationTab;
