import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";

interface FlowExecutionStepDelayDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepDelayDetail({
  executionStep,
}: FlowExecutionStepDelayDetailProps) {
  return null;
}
