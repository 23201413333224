import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArrowSmRightIcon,
  BeakerIcon,
  SpeakerphoneIcon,
  SupportIcon,
} from "@heroicons/react/outline";
import AlertIconWithIndicator from "src/components/AlertIconWithIndicator";
import NoticeManager from "src/components/Notice/NoticeManager";
import i18n from "src/i18n";
import { userAvatar, userToString } from "../Helpers";
import logoImago from "../assets/img/hilos_imago_opt.webp";
import LangToggle from "../components/LangToggle";
import useHilosStore from "../hooks/useHilosStore";
import { buildRoute } from "../router/router";
import Navigation from "./Navigation";
import NotificationSlideOver from "./NotificationSlideOver";
import UserNotificationList from "./user/UserNotificationList";

export default function SidenavMenu() {
  const { language } = i18n;
  const { t } = useTranslation();
  const { session, version } = useHilosStore();
  const [showNotifications, setShowNotifications] = useState(false);

  return (
    <nav
      aria-label="Sidebar"
      className="hidden w-56 md:block md:flex-shrink-0 md:overflow-y-auto"
    >
      <div className="flex h-full min-h-0 flex-1 flex-col border-r border-b border-gray-200 bg-white">
        <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
          <div className="my-4 flex flex-shrink-0 items-center px-4">
            <img className="h-8 w-auto" src={logoImago} alt="Hilos" />
          </div>
          <nav className="flex-1" aria-label="Sidebar">
            <div className="mb-2">
              <NoticeManager />
            </div>
            <div className="font-sm space-y-1.5 px-2">
              <Navigation />
            </div>
            <hr className="my-5 border-t border-gray-200" />
            {session.account.status === "IN_SANDBOX" && (
              <div className="m-4 bg-hilos-light px-4 py-4 rounded-md">
                <div className="flex items-center text-xs font-bold leading-7 text-neutral  sm:truncate">
                  <span className="rounded-full bg-white h-5 w-5 inline-flex items-center justify-center mr-2">
                    <BeakerIcon className="h-3 w-3 text-hilos-alt-700" />
                  </span>
                  {t("navigation:sandbox.notice.title", "Sandbox mode")}
                </div>
                <div className=" text-xs text-gray-500">
                  {t(
                    "navigation:sandbox.notice.description",
                    "When you're done testing Hilos, connect your company's number here"
                  )}
                </div>
                <div className="w-full mt-4">
                  <Link
                    to={buildRoute("config-channel-cloud-api-onboarding")}
                    className="z-10 lg:mr-10 w-full inline-flex items-center rounded-md border border-transparent bg-hilos px-2 py-2 text-xs font-medium text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                  >
                    {t(
                      "navigation:sandbox.notice.connect-number",
                      "Connect number"
                    )}
                    <ArrowSmRightIcon
                      className="ml-auto mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
              </div>
            )}
            <div className="font-sm space-y-1.5 px-2 text-gray-600">
              <h3 className="mt-4 px-2 text-tiny uppercase tracking-wider text-gray-400">
                {t("navigation:help.title", "Help")}
              </h3>
              <ul>
                <li className="px-2 py-2 text-sm text-gray-400">
                  <a
                    className="flex items-center"
                    href="https://hilos.io/docs/user/getting-started/prerequisites"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SupportIcon className="mr-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />{" "}
                    {t("navigation:support.getting-started", "Getting started")}
                  </a>
                </li>
                <li className="px-2 py-2 text-sm text-gray-400">
                  <a
                    className="flex items-center"
                    href={
                      language && language.toLowerCase().indexOf("es") === 0
                        ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
                        : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos."
                    }
                  >
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className="ml-0.5 mr-3 text-lg"
                    />{" "}
                    {t("navigation:support.chat", "Chat with support")}
                  </a>
                </li>
                <li className="px-2 py-2 text-sm text-gray-400">
                  <a
                    className="flex items-center"
                    href="https://hilos.canny.io/feedback"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SpeakerphoneIcon className="mr-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />{" "}
                    {t("navigation:support.feedback", "Feedback")}
                  </a>
                </li>
                <li className="px-2 py-2">
                  <LangToggle />
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="border-t border-gray-200 px-4 py-4 flex items-center overflow-hidden">
          <div className="group block grow">
            <Link to={buildRoute("account-profile")}>
              <div className="flex items-center">
                <div>{userAvatar(session)}</div>
                <div className="ml-3 w-full">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 truncate overflow-hidden w-28">
                    {userToString(session)}
                  </p>
                  {version && (
                    <div className="shrink text-xs font-bold text-gray-500">
                      v{version}
                    </div>
                  )}
                </div>
              </div>
            </Link>
          </div>
          <button
            type="button"
            className="text-gray-700 hover:text-gray-900 shrink-0 ml-2"
            onClick={() => setShowNotifications(!showNotifications)}
          >
            <AlertIconWithIndicator />
          </button>

          <NotificationSlideOver
            show={showNotifications}
            onClose={() => setShowNotifications(false)}
          >
            <UserNotificationList onClose={() => setShowNotifications(false)} />
          </NotificationSlideOver>
        </div>
      </div>
    </nav>
  );
}
