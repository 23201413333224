import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowSmRightIcon, PencilAltIcon } from "@heroicons/react/outline";
import { FlowTemplateSimpleListRead } from "@hilos/types/private-schema";
import useHilosStore from "src/hooks/useHilosStore";
import image_placeholder from "src/assets/img/wa/template_image_placeholder.svg";
import { buildRoute } from "src/router/router";

interface FlowTemplateCardProps {
  flowTemplate: FlowTemplateSimpleListRead;
  handleDuplicateFlowTemplate: (flowId: string) => void;
  handleEditTemplate: (flowId: number) => void;
}

export default function FlowTemplateCard({
  flowTemplate,
  handleDuplicateFlowTemplate,
  handleEditTemplate,
}: FlowTemplateCardProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();

  return (
    <div className="m-2 flex w-[300px] flex-col overflow-hidden rounded-xl border border-gray-200 bg-white sm:col-span-2 lg:col-span-2">
      <div className="relative">
        <div>
          {session?.account.id.toString() ===
            process.env.REACT_APP_SANDBOX_ACCOUNT_ID && (
            <button
              className="group absolute right-0 top-0 m-3 items-center rounded-full bg-white bg-opacity-30 p-2 text-sm font-semibold text-white"
              type="button"
              onClick={() => handleEditTemplate(flowTemplate.id)}
            >
              <PencilAltIcon className="h-5 w-5 text-white" />
            </button>
          )}
        </div>
        <img
          src={flowTemplate.preview_image || image_placeholder}
          className="h-40 w-full object-cover"
          alt="img from URL"
        />
      </div>
      <div className=" flex h-full flex-col">
        <p className="mt-6 px-3 text-center font-semibold text-neutral-dark ">
          {flowTemplate.preview_title}
        </p>
        <p className="mt-2 whitespace-pre-line px-4 text-sm text-neutral-dark">
          {flowTemplate.preview_description}
        </p>
        <div className="mb-6 mt-8 flex h-full flex-col justify-end px-3">
          <div className="flex justify-end">
            <Link
              className="font-medium text-sm flex items-center justify-end bg-neutral text-white rounded-md px-4 py-2"
              to={buildRoute("flow-template", {
                ":id": flowTemplate.id,
              })}
            >
              {t("flows:templates.see-more", "See more")}
              <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
