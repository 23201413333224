import { DateRangePickerValue } from "@tremor/react";
import { startOfMonth } from "date-fns";
import qs from "qs";

export interface CalculateQueryParamsParams {
  dateRange: DateRangePickerValue;
  contactTags: string[];
  conversationTags: string[];
  emails: string[];
  workingHours: boolean;
}

export function paramsToQueryString(params: CalculateQueryParamsParams) {
  return qs.stringify(
    {
      start_on: params.dateRange.from,
      end_on: params.dateRange.to,
      contact_tags: params.contactTags,
      conversation_tags: params.conversationTags,
      users: params.emails,
      working_hours: params.workingHours,
      period_type: "SPECIFIC_DATES",
    },
    { arrayFormat: "repeat" }
  );
}

export const INITIAL_PARAMS = {
  dateRange: {
    from: startOfMonth(new Date()),
    to: new Date(),
  },
  contactTags: [],
  conversationTags: [],
  emails: [],
  workingHours: true,
};
