import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { buildRoute } from "src/router/router";

export default function HubspotCtaInstallApp() {
  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <Trans i18nKey="flows:steps.hubspot-contact-upsert.cta.install-app">
            <p className="text-sm text-yellow-700">
              <Link
                to={buildRoute("integrations-hubspot")}
                className="font-medium text-yellow-700 underline hover:text-yellow-600"
              >
                Please connect HubSpot
              </Link>{" "}
              to your account to use this step
            </p>
          </Trans>
        </div>
      </div>
    </div>
  );
}
