import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { LogTypeEnum } from "@hilos/types/private-schema";

interface IntegrationEventLogTypeIconProps {
  logType: LogTypeEnum;
}

export default function IntegrationEventLogTypeIcon({
  logType,
}: IntegrationEventLogTypeIconProps) {
  switch (logType) {
    case "INFO":
      return (
        <InformationCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      );
    case "ERROR":
      return (
        <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      );
    case "SUCCESS":
      return (
        <CheckCircleIcon
          className="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      );
    default:
      return (
        <QuestionMarkCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      );
  }
}
