import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { axiosErr } from "@hilos/types/axios";
import { PublicUserInvitation } from "@hilos/types/private-schema";
import logo from "../../assets/img/hilos_imago.png";
import { getInvitation, signup } from "../../helpers/session";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";
import { SignupForm } from "./SignupForm";

function SignupTeamInvitation() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { setCredentials } = useHilosStore();
  const [loading, setLoading] = useState(true);
  const [invitation, setInvitation] = useState<PublicUserInvitation | null>();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleGetInvitation = useCallback(async () => {
    try {
      const nextInvitation = await getInvitation(params.key);
      setInvitation(nextInvitation);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [params]);

  const onSubmit = async (data) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSuccess(false);
    setSubmitted(false);

    try {
      data.is_accepting_invitation = true;
      const response = await signup(data);
      if (response) {
        const credentials = {
          token_access: response.data.access,
          token_refresh: response.data.refresh,
        };
        setSuccess(true);
        setSubmitted(true);
        setCredentials(credentials, () => {
          navigate(buildRoute("dashboard"));
        });
      }
    } catch (error) {
      const axiosErr = error as axiosErr;
      setSuccess(false);
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
      if (
        axiosErr?.response &&
        axiosErr.response.status === 400 &&
        axiosErr.response.data
      ) {
        setBackendValidationErrors(axiosErr.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
    }
  };

  useEffect(() => {
    handleGetInvitation();
  }, [handleGetInvitation]);

  const inviteSender = invitation?.invited_by || t("someone", "someone");

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            {loading ? (
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <>
                {invitation ? (
                  <>
                    <div>
                      <img className="h-12 w-auto" src={logo} alt="Hilos" />
                      <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                        {t("login:you-are-invited.title", "You're invited!")}
                      </h2>
                      <p className="mt-2 text-sm text-gray-600">
                        {t(
                          "login:you-are-invited.description",
                          "Sign up to accept inviteSender's invite to join their team.",
                          { inviteSender }
                        )}
                      </p>
                    </div>

                    <div className="mt-8">
                      <div className="mt-6">
                        <SignupForm
                          onSubmit={onSubmit}
                          email={invitation.email}
                          backendError={backendError}
                          backendValidationErrors={backendValidationErrors}
                          success={success}
                          submitted={submitted}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <img className="h-12 w-auto" src={logo} alt="Hilos" />
                      <Trans i18nKey="login:invite-expired">
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                          This invite isn't valid or has expired.
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                          Please ask the person that invited you to send your
                          invite again.
                        </p>
                      </Trans>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default SignupTeamInvitation;
