import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { StripeProductAvailableList } from "@hilos/types/private-schema";
import { formatPrice } from "src/Helpers";
import SubscriptionAction from "./SubscriptionAction";

interface SubscriptionPlanCardProps {
  product: StripeProductAvailableList;
  showCheckout: boolean;
  currency: string;
}

export default function SubscriptionPlanCard({
  product,
  showCheckout,
  currency,
}: SubscriptionPlanCardProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex-1 bg-white px-4 py-4 lg:px-10 lg:py-8">
        <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
          {product.name} -
          <span className="ml-2 text-hilos">
            {formatPrice(
              product.available_price &&
                (product.available_price.unit_amount || 0) / 100
            )}
          </span>
          <span className="ml-2 text-xl font-medium tracking-normal text-gray-500">
            {product.available_price?.currency &&
              product.available_price.currency.toLocaleUpperCase()}
            {t("per-month")}
          </span>
        </h3>
        <p className="mt-6 text-base text-gray-500">
          {t("billing:plan.description")}
        </p>
        <div className="mt-6">
          <div className="flex items-center">
            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-hilos">
              {t("settings:billing.features")}
            </h4>
            <div className="flex-1 border-t-2 border-gray-200" />
          </div>
          <ul className="mt-6 space-y-4 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:gap-y-4 lg:space-y-0">
            {product.features?.map((feature) => (
              <li key={feature} className="flex items-start lg:col-span-1">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-3 text-sm text-gray-700">
                  {t(`billing:plan.features.${feature}`)}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {showCheckout && (
        <div className="w-full bg-gray-50 py-8 px-6 text-center lg:flex lg:w-80 lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
          <SubscriptionAction product={product} />
        </div>
      )}
    </>
  );
}
