import { useTranslation } from "react-i18next";
import { ExternalLinkIcon } from "@heroicons/react/outline";

const formatLocationAddress = (location) => {
  if (location.place_name) {
    return `${location.place_name} - ${location.address}`;
  }
  return location.address;
};

export default function LocationMessage({ message, isPreview = false }) {
  const { t } = useTranslation();
  if (!message.location) {
    return (
      <span className="italic text-gray-500">
        {t("errors.location-error", "Error while loading location.")}
      </span>
    );
  }

  return (
    <div className={`${isPreview ? "flex items-center opacity-70" : ""}`}>
      <div
        className={`rounded-lg ${
          isPreview
            ? "w-10 mr-2 h-10 object-cover"
            : "flex max-h-40 overflow-hidden"
        }`}
      >
        <img
          alt={formatLocationAddress(message.location)}
          className="self-center"
          src={message.location.map_screenshot_url}
        />
      </div>
      {isPreview ? (
        <p className="whitespace-pre-wrap break-words text-left truncate line-clamp-2">
          {formatLocationAddress(message.location)}
        </p>
      ) : (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${message.location.latitude},${message.location.longitude}`}
          target="_blank"
          rel="noreferrer"
          className="flex max-h-40 overflow-hidden rounded-lg"
        >
          <p className="mt-2 flex items-center whitespace-pre-wrap text-blue-500">
            <ExternalLinkIcon className="mr-1 h-4 w-4" />
            {formatLocationAddress(message.location)}
          </p>
        </a>
      )}
    </div>
  );
}
