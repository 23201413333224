import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {  PublicUserInvitation } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

export function useInvitationsByUser() {
  const fetchInvitations = useCallback(async () => {
    const { data } = await axios.get<[PublicUserInvitation]>(
      buildAPIRoute(API_ROUTES.PUBLIC_USER_INVITATION_BY_USER)
    );
    return data;
  }, []);

  const { data } = useQuery(["user_invitations"], fetchInvitations);

  return data || [];
}

export const requestInvitationCode = async ({invitationId}) => {
  await axios.post(
    buildAPIRoute(API_ROUTES.PUBLIC_USER_REQUEST_INVITATION_CODE, {':id': invitationId}),
  );
};
export const acceptInvitationCode = async ({code}) => {
  await axios.post(
    buildAPIRoute(API_ROUTES.PUBLIC_USER_ACCEPT_INVITATION),
    code
  );
};