import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  ChatIcon,
  InformationCircleIcon,
  SupportIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import HeaderNavigation from "src/components/HeaderNavigation";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { useChannels } from "src/hooks/useChannel";
import useHilosStore from "src/hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import { axiosErr } from "src/types/axios";
import { ChannelProvider, ChannelType } from "src/types/channel";
import ChannelForm from "./ChannelForm";
import ChannelProxyForm from "./ChannelProxyForm";
import * as meta from "./Meta";
import MetaCloudAPIForm from "./MetaCloudAPIForm";
import * as cloudAPIMeta from "./MetaCloudAPISettingsMeta";

export default function ChannelCloudAPICreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reloadSession, session } = useHilosStore();
  const channels = useChannels();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = async (formValues, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.CHANNEL_LIST_CREATE),
        formValues
      );
      setSubmitting(false);
      setSuccess(true);
      reloadSession();
      setTimeout(() => {
        navigate(buildRoute("config-channel"));
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          "Couldn't save your new channel information. Please try again later."
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  const initialValues = useMemo(() => {
    return {
      ...meta.getInitialValues(
        ChannelType.WHATSAPP,
        ChannelProvider.META_CLOUD_API
      ),
      settings: cloudAPIMeta.getInitialValues(),
    };
  }, []);

  const hasAtLeastOneActiveChannel = useMemo(() => {
    return channels.filter((ch) => ch.status === "ACTIVE").length > 1;
  }, [channels]);

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: "Home",
                url: buildRoute("dashboard"),
              },
              {
                name: "Settings",
                url: buildRoute("config-account"),
              },
              {
                name: "WhatsApp Accounts",
                url: buildRoute("config-channel"),
              },
              {
                name: "Cloud API",
                url: buildRoute("config-channel-cloud-api-create"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ChatIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                {t(
                  "channel:channel-connect-new",
                  "Connect a new WhatsApp account"
                )}
              </h2>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto mt-6 max-w-3xl">
            <Formik
              validationSchema={meta.getSchema(ChannelProvider.META_CLOUD_API)}
              onSubmit={(values, { setSubmitting }) =>
                onSubmit(values, setSubmitting)
              }
              enableReinitialize={true}
              initialValues={initialValues}
            >
              {(formik) => (
                <form noValidate onSubmit={formik.handleSubmit}>
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          {t(
                            "channel:add-phone-number",
                            "Add your new phone number"
                          )}
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">
                          {t(
                            "channel:add-phone-number-description",
                            "Here you can see and change your channel configuration."
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="mt-5 md:col-span-2 md:mt-0">
                      <div className="overflow-hidden bg-white shadow sm:rounded-md">
                        <APIErrors
                          APIError={backendError}
                          APIValidationErrors={backendValidationErrors}
                          fieldTranslations={meta.FIELDS}
                        />

                        {hasAtLeastOneActiveChannel && (
                          <>
                            {session?.account.current_subscription ? (
                              <div className="rounded-t-md bg-blue-50 p-4">
                                <div className="flex">
                                  <div className="flex-shrink-0">
                                    <InformationCircleIcon
                                      className="h-5 w-5 text-blue-400"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-3">
                                    <p className="text-sm text-blue-700">
                                      {t(
                                        "channel:billing-add-more-channels-notice",
                                        "You're about to add an extra channel to your account. Once connected we'll automatically charge your credit card for $50 USD/mo, prorated."
                                      )}
                                    </p>
                                    <p className="mt-3 text-sm">
                                      <a
                                        href="https://hilos.io/pricing"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                                      >
                                        {t("learn-more", "Learn more")}
                                        <span aria-hidden="true"> &rarr;</span>
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="rounded-t-md bg-blue-50 p-4">
                                <div className="flex">
                                  <div className="flex-shrink-0">
                                    <InformationCircleIcon
                                      className="h-5 w-5 text-blue-400"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-3">
                                    <p className="text-sm text-blue-700">
                                      {t(
                                        "channel:billing-add-more-channels-subscribe-first",
                                        "Start your Hilos subscription to add additional WhatsApp channels."
                                      )}
                                    </p>
                                    <p className="mt-3 text-sm">
                                      <Link
                                        to={buildRoute(
                                          "config-account-subscription"
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                                      >
                                        {t("subscribe-now", "Subscribe now")}
                                        <span aria-hidden="true"> &rarr;</span>
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        <ChannelForm />
                      </div>
                    </div>
                  </div>

                  <div className="mt-10 sm:mt-4 md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          {t(
                            "channel:meta-cloud-api-settings",
                            "Meta Cloud API Settings"
                          )}
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">
                          {t(
                            "channel:meta-cloud-api-settings-description",
                            "Here you can see and change your channel configuration."
                          )}
                        </p>
                      </div>
                      <div className="inline-block">
                        <a
                          href="https://hilos.io/docs/user/getting-started/connect-a-new-number"
                          target="_blank"
                          rel="noreferrer"
                          className=" mt-2 flex justify-center  rounded-md border border-gray-200 bg-white py-2 px-4 shadow-sm "
                        >
                          <div className="inline-block items-center justify-center">
                            <SupportIcon
                              className="h-4 w-4 text-gray-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-2 self-center text-sm font-medium leading-tight text-gray-700 ">
                            {t(
                              "channel:meta-cloud-api-settings:how-do-i-fill-this",
                              "How do I fill this?"
                            )}
                          </div>
                        </a>
                      </div>
                    </div>

                    <div className="mt-5 md:col-span-2 md:mt-0">
                      <div className="overflow-hidden bg-white shadow sm:rounded-md">
                        <MetaCloudAPIForm />
                      </div>
                    </div>
                  </div>

                  <ChannelProxyForm formik={formik} />

                  {(session?.account.current_subscription ||
                    !hasAtLeastOneActiveChannel) && (
                    <div className="my-5">
                      <div className="text-right">
                        <StateButton
                          isSubmitting={formik.isSubmitting}
                          submitted={submitted}
                          success={success}
                          submittingText={t("channel:saving", "Saving...")}
                          successText={t("channel:saved", "Channel saved!")}
                          initialText={
                            <>
                              <span className="fe fe-save mr-2"></span>
                              {t("channel:save-and-connect", "Save & Connect")}
                            </>
                          }
                        />
                      </div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
