import { useMemo, useState } from "react";
import {
  FlowExecutionStepReadDetail,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import useWrappedSteps from "src/hooks/useWrappedSteps";
import { STEP_TYPES } from "../builder/constants/steps";
import FlowExecutionStepDetail from "./FlowExecutionStepDetail";
import FlowExecutionStepDetailNav from "./FlowExecutionStepDetailNav";
import FlowStepDetail from "./FlowStepDetail";

interface FlowExecutionStepDetailContainerProps {
  executionStep: FlowExecutionStepReadDetail;
  flowVersion: FlowVersionDetailRead;
}

export default function FlowExecutionStepDetailContainer({
  executionStep,
  flowVersion,
}: FlowExecutionStepDetailContainerProps) {
  const [currentStep, setCurrentStep] = useState("step");
  const { STEP_TYPES_DYNAMIC } = useWrappedSteps();

  const stepInfo = useMemo(() => {
    return STEP_TYPES_DYNAMIC[executionStep.step.step_type];
  }, [STEP_TYPES_DYNAMIC, executionStep.step.step_type]);

  if (!stepInfo) {
    return null;
  }

  return (
    <div className="overflow-x-scroll rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6 space-y-4">
      <div className="flex items-center space-x-2">
        <div className="grow-0 self-center">
          <stepInfo.icon className="h-5 w-5" aria-hidden="true" />
        </div>
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
          {executionStep.step.step_type}
        </span>
        <p className="text-gray-600 text-sm">{executionStep.step.name}</p>
      </div>

      <FlowExecutionStepDetailNav
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />

      {currentStep === "step" && (
        <FlowStepDetail flowVersion={flowVersion} step={executionStep.step} />
      )}
      {currentStep === "execution-step" && (
        <FlowExecutionStepDetail executionStep={executionStep} />
      )}
    </div>
  );
}
