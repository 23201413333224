import { Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "@headlessui/react";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import SelectorField from "src/components/Form/SelectorField";
import { getUpdatedVariablesWithExtraSources } from "src/helpers/variables";
import { classNames } from "src/Helpers";
import { TriggerCategoryProps } from "../FlowBuilderTrigger";
import FlowAdvancedOptionsDisclosure from "../components/FlowBuilderAdvancedOptionsDisclosure";
import {
  OUTBOUND_CAMPAIGN_TYPE_TRIGGER,
  TRIGGER_TYPES,
} from "../constants/triggers";

function CampaignTrigger({ handleTriggerTypeChange }: TriggerCategoryProps) {
  const [t] = useTranslation();
  const { values, setFieldValue } = useFormikContext<FlowData>();

  const availableFlowVariables = useMemo(
    () =>
      values.variables.reduce((nextAvailableFlowVariables, variable) => {
        if (variable.source === "flow") {
          const name = variable.name.replace(
            /^(flow|flow_execution_variable)\./,
            ""
          );
          nextAvailableFlowVariables.push({
            value: name,
            label: name,
          });
        }

        return nextAvailableFlowVariables;
      }, [] as { value: string; label: string }[]),
    [values.variables]
  );

  const handleFlowVariableChange = useCallback(
    (nextValues) => {
      const flowVariableKeys = (nextValues || []).map(({ value }) => value);
      setFieldValue(
        "variables",
        getUpdatedVariablesWithExtraSources({
          flowVariableKeys,
          currentVariables: values.variables,
        })
      );
    },
    [values.variables, setFieldValue]
  );

  const TriggerIcon = useMemo(() => {
    return TRIGGER_TYPES[values.trigger_type].icon;
  }, [values.trigger_type]);

  return (
    <>
      <div className="pb-4">
        <h2 className="text-lg font-medium text-gray-900">
          {t(
            "flows:trigger.campaign-trigger.title",
            "Start your campaign from"
          )}
        </h2>
      </div>

      <div className="space-y-4">
        <div className="-space-y-px rounded-md bg-white">
          <RadioGroup
            value={values.trigger_type}
            onChange={handleTriggerTypeChange}
          >
            {OUTBOUND_CAMPAIGN_TYPE_TRIGGER.map((trigger, idx) => (
              <RadioGroup.Option
                as={Fragment}
                key={t(TRIGGER_TYPES[trigger].name)}
                value={trigger}
              >
                {({ active, checked }) => (
                  <div
                    className={classNames(
                      idx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                      idx === OUTBOUND_CAMPAIGN_TYPE_TRIGGER.length - 1
                        ? "rounded-bl-md rounded-br-md"
                        : "",
                      checked
                        ? "z-10 border-hilos-alt-300 bg-hilos-light"
                        : "border-gray-200",
                      "relative flex cursor-pointer border p-4 focus:outline-none"
                    )}
                  >
                    <span
                      className={classNames(
                        checked
                          ? "border-transparent bg-hilos"
                          : "border-gray-300 bg-white",
                        active ? "ring-2 ring-hilos ring-offset-2" : "",
                        "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                      )}
                      aria-hidden="true"
                    >
                      <span className="h-1.5 w-1.5 rounded-full bg-white" />
                    </span>
                    <div className="block overflow-hidden">
                      <span className="ml-3 flex flex-col">
                        <span className="block text-sm font-medium text-gray-900">
                          {t(TRIGGER_TYPES[trigger].name)}
                        </span>
                        <span
                          className={classNames(
                            trigger === values.trigger_type
                              ? "block"
                              : "hidden",
                            "text-gray-500 text-sm transition-all ease-out duration-200"
                          )}
                        >
                          {t(TRIGGER_TYPES[trigger].description)}
                        </span>
                        <span className="absolute bottom-1 right-1 -z-10">
                          {TriggerIcon && (
                            <TriggerIcon className="h-12 w-12 text-gray-200" />
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>

        <FlowAdvancedOptionsDisclosure
          defaultOpen={
            values.trigger_type === "OUTBOUND_CAMPAIGN_API" &&
            values.flow_execution_variables.length > 0
          }
        >
          <SelectorField
            isMulti
            isCreatable
            name="flow_execution_variables"
            label={t(
              "flows:flow-execution-variables.label",
              "What execution variables will this flow have?"
            )}
            help={t(
              "flows:flow-execution-variables.help",
              "The variables declared here will be available in the flow steps to use and you will provide them when executing this flow."
            )}
            options={availableFlowVariables}
            onSelect={handleFlowVariableChange}
          />
        </FlowAdvancedOptionsDisclosure>
      </div>
    </>
  );
}

export default CampaignTrigger;
