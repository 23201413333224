import { useTranslation } from "react-i18next";
import {
  TableOptions,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Loading from "src/components/Loading";
import { DataTablePagination } from "./DataTablePagination";

type AllowedReactTableProps =
  | "state"
  | "columns"
  | "onPaginationChange"
  | "onRowSelectionChange"
  | "onSortingChange"
  | "onColumnFiltersChange"
  | "onColumnVisibilityChange";

interface DataTableProps<TData>
  extends Pick<TableOptions<TData>, AllowedReactTableProps> {
  data: { results: TData[]; count: number };
  isSuccess: boolean;
  isLoading: boolean;
  showPagination?: boolean;
}

export function DataTable<TData>({
  columns,
  data,
  isSuccess,
  isLoading,
  showPagination = true,
  ...reactTableParams
}: DataTableProps<TData>) {
  const [t] = useTranslation();

  const table = useReactTable({
    ...reactTableParams,
    data: isSuccess ? data.results : [],
    rowCount: data?.count,
    columns,
    manualPagination: true,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (isLoading) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col overflow-hidden rounded-md border">
      <div
        className={`flex overflow-x-auto min-w-full ${
          !showPagination ? "border-b border-gray-200" : ""
        }`}
      >
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500 text-sm truncate"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="whitespace-nowrap px-4 py-3">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="h-24 text-center">
                  {t("no-results", "No results")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showPagination && <DataTablePagination table={table} />}
    </div>
  );
}
