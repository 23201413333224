import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { API_ROUTES, buildAPIRoute } from "../router/router";
import { WebhookSubscriptionLogDetail } from "@hilos/types/private-schema";

function useWebhookSubscriptionLog(id: string) {
  const fetchWebhookSubscriptionLog = useCallback(async () => {
    const { data } = await axios.get<WebhookSubscriptionLogDetail>(
      buildAPIRoute(API_ROUTES.WEBHOOK_SUBSCRIPTION_LOG_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data } = useQuery(
    ["webhook_subscription_log_detail", id],
    fetchWebhookSubscriptionLog
  );

  return data;
}

export default useWebhookSubscriptionLog;
