import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowSmRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { XCircleIcon } from "@heroicons/react/solid";
import { ColumnDef, Row, createColumnHelper } from "@tanstack/react-table";
import languageEncoding from "detect-file-encoding-and-language";
import { FormikProps, useField } from "formik";
import { ParseResult, parse } from "papaparse";
import { MessageBlastPublicEdit } from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import {
  BroadcastFormExtraFormikFields,
  BroadcastFormSteps,
  BroadcastTransformColumnMappingsToRecipient,
  CsvFile,
} from "src/containers/broadcasts/BroadcastForm";
import BroadcastFormCSV from "src/containers/broadcasts/BroadcastFormCSV";
import BroadcastFormCSVTemplateDownload from "src/containers/broadcasts/BroadcastFormCSVTemplateDownload";
import BroadcastFormSelectVariable from "src/containers/broadcasts/BroadcastFormSelectVariable";
import * as meta from "src/containers/broadcasts/BroadcastMeta";
import BroadcastWhatsAppTemplatePreviewModal from "src/containers/broadcasts/BroadcastWhatsAppTemplatePreviewModal";
import CheckboxField from "src/components/Form/CheckboxField";
import LocalTable from "src/components/LocalTable";
import { hasItems } from "src/helpers/utils";
import useBroadcastVariables from "src/hooks/useBroadcastVariables";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { classNames } from "src/Helpers";

interface BroadcastFormStepContactsProps {
  setCurrentStep: (step: BroadcastFormSteps) => void;
  formik: FormikProps<MessageBlastPublicEdit & BroadcastFormExtraFormikFields>;
  requiredFields: string[];
  file: CsvFile;
  setFile: (CsvFile: CsvFile) => void;
  isTutorialInProgress: boolean;
  selectedTemplate?: WhatsAppTemplate;
}

function IncludeRowInBroadcast({ row }: { row: Row<string[]> }) {
  return (
    <div className="flex flex-row justify-center">
      <CheckboxField
        name={`${meta.FIELDS.csv_file_parsed_contents.key}.data.${row.index}.0`}
      />
    </div>
  );
}

function PreviewRowInTemplate({
  formik,
  row,
  selectedTemplate,
}: {
  formik: FormikProps<MessageBlastPublicEdit & BroadcastFormExtraFormikFields>;
  row: Row<string[]>;
  selectedTemplate?: WhatsAppTemplate;
}) {
  if (!selectedTemplate) {
    return <></>;
  }
  const broadcastRecipient = BroadcastTransformColumnMappingsToRecipient(
    formik.values[meta.FIELDS.columns.key],
    row.original
  );
  const initialTemplatePreviewValuesArray = [
    ...(broadcastRecipient.data ?? []),
  ];
  return (
    <div className="flex flex-row justify-center">
      <BroadcastWhatsAppTemplatePreviewModal
        template={selectedTemplate}
        initialTemplatePreviewValuesArray={initialTemplatePreviewValuesArray}
      />
    </div>
  );
}

function removeEmptyEntriesFromResults(
  results: ParseResult<string[]>
): ParseResult<Array<string | boolean>> {
  const newData: string[][] = [];
  for (const row of results.data) {
    for (const cell of row) {
      if (cell.trim().length > 0) {
        newData.push(row);
        break;
      }
    }
  }
  return {
    ...results,
    data: newData,
  };
}

export default function BroadcastFormStepContacts({
  formik,
  setCurrentStep,
  requiredFields,
  file,
  setFile,
  isTutorialInProgress,
  selectedTemplate,
}: BroadcastFormStepContactsProps) {
  const { t } = useTranslation();

  const { variableOptionsGroup } = useBroadcastVariables({
    requiredFields,
    formik,
  });

  const [_1, metaColumns] = useField(meta.FIELDS.columns.key);
  const errorColumns = metaColumns.touched && metaColumns.error;

  const [csvFileParsed, metaCSVFileParsed] = useField<ParseResult<string[]>>(
    meta.FIELDS.csv_file_parsed_contents.key
  );
  const parsedCSVData = useMemo(() => {
    if (!csvFileParsed.value) {
      return [];
    }
    return csvFileParsed.value.data;
  }, [csvFileParsed.value]);
  const errorCSVFileParsed = metaCSVFileParsed.error;

  const columnsTable = useMemo(() => {
    const columnHelper = createColumnHelper<string[]>();
    if (!hasItems(parsedCSVData)) {
      return [];
    }
    const firstRowData = parsedCSVData[0];
    const defaultColumns = [
      columnHelper.display({
        id: "include-row",
        cell: (props) => <IncludeRowInBroadcast row={props.row} />,
        header: () => (
          <>
            <div className="px-2 py-1  text-tiny font-medium text-gray-700">
              {t("send")}
            </div>
          </>
        ),
      }),
      ...firstRowData
        .map((row, index) =>
          columnHelper.accessor((row) => row[index], {
            header: () => (
              // <>
              // {/* Row {row} {index} */}
              <div
                className="min-w-[200px] px-2 py-1 text-left text-tiny font-medium"
                key={`${meta.FIELDS.columns.key}.${index}`}
              >
                <BroadcastFormSelectVariable
                  variableOptionsGroup={variableOptionsGroup}
                  name={`${meta.FIELDS.columns.key}.${index}`}
                />
              </div>
              // </>
            ),
            id: `row_${index}`,
            cell: (info) => {
              if (info.row.original[0]) {
                // The row has the checkbox ticked i.e. should be included
                return <span className="px-2 text-sm">{info.getValue()}</span>;
              }
              // This row will be removed before creating the broadcast
              return (
                <span className="px-2 text-sm font-light text-gray-400 line-through">
                  {info.getValue()}
                </span>
              );
            },
          })
        )
        // We remove the first element of the array that is where we save
        // if the row should be included
        .filter((row, index) => index !== 0),
      columnHelper.display({
        id: "preview-row-in-template",
        cell: (props) => (
          <PreviewRowInTemplate
            formik={formik}
            row={props.row}
            selectedTemplate={selectedTemplate}
          />
        ),
      }),
    ];
    return defaultColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedCSVData, selectedTemplate, t, variableOptionsGroup]);

  const cleanTimeout = useCleanTimeout();
  const parseInProgress = useRef(false);

  const handleSetFile = useCallback(
    async (csvFile?: CsvFile) => {
      if (!csvFile) {
        return;
      }
      setFile(csvFile);
      if (csvFile.source && !parseInProgress.current) {
        parseInProgress.current = true;
        const encodingResults = await languageEncoding(csvFile.source);
        // @ts-expect-error For some unknown reason TS tells us that
        // there is no correct function overload when we add the encoding
        parse(csvFile.source, {
          header: false,
          encoding: encodingResults.encoding,
          complete: (results: ParseResult<string[]>) => {
            const cleanedResults = removeEmptyEntriesFromResults(results);
            const preparedResults = {
              ...results,
              data: cleanedResults.data.map((dataRecord) => [
                true,
                ...dataRecord,
              ]),
            };
            formik.setFieldValue(
              meta.FIELDS.csv_file_parsed_contents.key,
              preparedResults
            );
            if (results.data.length <= 0) {
              formik.setFieldError(
                meta.FIELDS.csv_file_parsed_contents.key,
                // i18n.t("broadcasts:no-rows-detected", "We couldn't detect any rows in your CSV file.")
                "broadcasts:no-rows-detected"
              );
            } else {
              formik.setFieldError(
                meta.FIELDS.csv_file_parsed_contents.key,
                undefined
              );
            }
            if (hasItems(formik.values[meta.FIELDS.columns.key])) {
              // The WA template has already been selected we need to validate that the
              // columns are at least the number of required fields
              const csvColsQuantity = preparedResults.data[0].length;
              if (
                formik.values[meta.FIELDS.columns.key].length > csvColsQuantity
              ) {
                formik.setFieldError(
                  meta.FIELDS.csv_file_parsed_contents.key,
                  // i18n.t("broadcasts:need-more-cols", "Your CSV file doesn't have the minimum required columns to match all the required template variables.")
                  "broadcasts:need-more-cols"
                );
              } else {
                formik.setFieldError(
                  meta.FIELDS.csv_file_parsed_contents.key,
                  undefined
                );
              }
            }
            parseInProgress.current = false;
          },
        });
        cleanTimeout.current = setTimeout(() => {
          parseInProgress.current = false;
        }, 5000);
      } else if (csvFile.source == null) {
        // Remove the old csv_file_parsed_contents
        formik.setFieldValue(
          meta.FIELDS.csv_file_parsed_contents.key,
          undefined
        );
        // Remove any error related to the old csv_file_parsed_contents
        formik.setFieldError(
          meta.FIELDS.csv_file_parsed_contents.key,
          undefined
        );
      }
    },
    [cleanTimeout, formik, setFile]
  );

  const numberOfRecipients = useMemo(() => {
    const results = formik.values[meta.FIELDS.csv_file_parsed_contents.key] as
      | ParseResult<string[]>
      | undefined;
    if (!results?.data) {
      return 0;
    }
    return results.data.filter((result) => result[0]).length;
  }, [formik.values]);

  const nextButtonEnabled = useMemo(() => {
    return !!formik.values.csv_file_parsed_contents && numberOfRecipients > 0;
  }, [formik.values.csv_file_parsed_contents, numberOfRecipients]);

  return (
    <div className="space-y-4">
      <div className="row mt-10 text-center">
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          {t(
            "broadcasts:upload-csv-contacts-send.title",
            "Let us know who to send it to by uploading your CSV file"
          )}
        </h1>
      </div>
      {formik.values[meta.FIELDS.whatsapp_template.key] && (
        <BroadcastFormCSVTemplateDownload
          requiredFields={requiredFields}
          headerURL={formik.values.media_help_upload_url}
        />
      )}
      <div>
        <BroadcastFormCSV
          isTutorialInProgress={isTutorialInProgress}
          formik={formik}
          file={file}
          setFile={handleSetFile}
        />
      </div>

      <div className="space-y-4">
        <div className="bg-gray-50">
          <div>
            {errorColumns && (
              <div className="mb-4 rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      {t(errorColumns)}
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {errorCSVFileParsed && (
              <div className="mb-4 rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      {t(errorCSVFileParsed)}
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {hasItems(parsedCSVData) && (
              <>
                <div className="mb-4 rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-blue-700">
                        {t(
                          "broadcasts:select-the-recipients",
                          "Select the recipients that will receive this Broadcast"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <LocalTable<string[]>
                  tableTitle={
                    <>
                      {t("recipients", "Recipients")} (
                      {/* t("broadcasts:selected_one", "selected {{count}}") */}
                      {/* t("broadcasts:selected_other", "selected {{count}}") */}
                      {t("broadcasts:selected", "selected", {
                        count: numberOfRecipients,
                      })}
                      )
                    </>
                  }
                  columns={columnsTable as unknown as ColumnDef<string[]>[]}
                  data={parsedCSVData}
                  columnVisibility={{
                    should_be_included: false,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="my-14">
        <hr className="my-5" />
        <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
          <div className="col justify-self-start">
            <button
              className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
              type="button"
              onClick={(_) => setCurrentStep("broadcast-details")}
            >
              <ArrowSmLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              {t("broadcasts:message-to-send", "Message to send")}
            </button>
          </div>
          <div className="col text-center">
            <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
              {t("step-n-of-n", "Step {{step}} of {{total}}", {
                step: 2,
                total: 3,
              })}
            </h6>
          </div>
          <div className="justify-self-end">
            <button
              className={classNames(
                "inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm",
                nextButtonEnabled
                  ? "hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                  : "opacity-75"
              )}
              type="button"
              disabled={!nextButtonEnabled}
              onClick={(_) => setCurrentStep("review")}
            >
              {t("broadcasts:review", "Review")}
              <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
