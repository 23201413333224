import { BellIcon } from "@heroicons/react/outline";
import useHilosStore from "src/hooks/useHilosStore";

function AlertIconWithIndicator() {
  const { unreadNotificationCount } = useHilosStore();

  return (
    <div className="relative mr-2 h-5 w-5">
      <BellIcon className="h-5 w-5" aria-hidden="true" />

      <span className="absolute inset-0 object-right-top -mr-6">
        <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-hilos text-white">
          {unreadNotificationCount}
        </div>
      </span>
    </div>
  );
}

export default AlertIconWithIndicator;
