import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import WhatsAppTemplatePreview from "src/containers/wa/WhatsAppTemplatePreview";
import WhatsAppTemplateSelect from "src/containers/wa/WhatsAppTemplateSelect";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import { getTemplateComponents } from "src/helpers/template";
import { hasItems } from "src/helpers/utils";
import { getRequiredVariablesFromTemplate } from "../../../helpers/steps/template";
import { useStepField } from "../../../hooks/useStepField";
import FlowBuilderMaxAnswerAttempts from "../../FlowBuilderMaxAnswerAttempts";
import FlowBuilderMsgFail from "../../FlowBuilderMsgFail";
import FlowBuilderWaitUntil from "../../FlowBuilderWaitUntil";
import FlowStepTemplateForm from "./FlowBuilderStepTemplateForm";

interface FlowBuilderStepTemplateProps {
  id: string;
  index: number;
}

function FlowBuilderStepTemplate({ id, index }: FlowBuilderStepTemplateProps) {
  const [t] = useTranslation();
  const { values } = useFormikContext<FlowData>();
  const [validateAnswerWithButtons] = useStepField({
    index,
    name: "validate_answer_with_buttons",
  });
  const [templateSelected, setTemplateSelected] = useStepField({
    index,
    name: "whatsapp_template_selected",
  });
  const [templateVariables, setTemplateVariables] = useStepField({
    index,
    name: "whatsapp_template_variables",
  });
  const [groupAnswersFromMultipleTexts, _] = useStepField({
    index,
    name: "group_answers_from_multiple_texts",
  });

  const hasQuickReplyButtonsSelectedTemplate = useMemo(() => {
    if (!templateSelected) {
      return false;
    }
    const { buttons } = getTemplateComponents(templateSelected);

    return hasItems(buttons && buttons.buttons);
  }, [templateSelected]);

  const setSelectedTemplate = useCallback(
    (nextTemplate: WhatsAppTemplate | undefined) => {
      const { bodyVariables, buttonVariables } =
        getRequiredVariablesFromTemplate(nextTemplate);

      setTemplateSelected(nextTemplate);
      setTemplateVariables({
        headerURL: "",
        headerVar: "",
        bodyVars: bodyVariables.map(() => ""),
        buttonVars: buttonVariables.map(() => ""),
      });
    },
    [setTemplateSelected, setTemplateVariables]
  );

  return (
    <div className="space-y-4">
      <WhatsAppTemplateSelect
        channel={values.channel as unknown as number}
        fieldName={`steps.${index}.whatsapp_template`}
        fieldLabel={t(
          "flows:steps.template.whatsapp-template.label",
          "WhatsApp template to use"
        )}
        setSelectedTemplate={setSelectedTemplate}
      />

      {!!templateSelected && (
        <>
          <WhatsAppTemplatePreview
            template={templateSelected}
            values={templateVariables || {}}
          />

          <div>
            <FlowStepTemplateForm
              index={index}
              template={templateSelected}
              variables={templateVariables}
            />
          </div>

          <SwitchField
            name={`steps.${index}.save_contact_answer`}
            label={t(
              "flows:steps.template.save-contact-answer.label",
              "Save contact's answer?"
            )}
            help={t(
              "flows:steps.template.save-contact-answer.help",
              "If set, we'll wait for an answer from the contact and save it, as if it were a Question step."
            )}
          />

          {hasQuickReplyButtonsSelectedTemplate ? (
            <>
              <SwitchField
                name={`steps.${index}.validate_answer_with_buttons`}
                label={t(
                  "flows:steps.template.validate-answer-with-buttons.label",
                  "Validate contact answer?"
                )}
                help={t(
                  "flows:steps.template.validate-answer-with-buttons.help",
                  "If set, we'll validate that the contact's answer is one of this template's buttons."
                )}
              />
              {validateAnswerWithButtons && (
                <TextInputField
                  type="text"
                  name={`steps.${index}.answer_validation_message`}
                  label={t(
                    "flows:steps.template.answer-validation-message.label",
                    "Answer validation message"
                  )}
                  help={t(
                    "flows:steps.template.answer-validation-message.help",
                    "If the contact answers with something other than the button values, we'll answer with this message."
                  )}
                  placeholder={t(
                    "flows:steps.template.answer-validation-message.placeholder",
                    "Please choose an option to continue."
                  )}
                />
              )}
            </>
          ) : (
            <div className="border-t border-gray-200 pt-2 space-y-4">
              <SwitchField
                name={`steps.${index}.group_answers_from_multiple_texts`}
                label={t(
                  "flows:steps.question.group-answers-from-multiple-texts.label",
                  "Group multiple messages as final answer?"
                )}
                help={t(
                  "flows:steps.question.group-answers-from-multiple-texts.help",
                  "If people usually send you multiple messages as a response, using this will enable you to capture them all and use them as this step's answer"
                )}
              />
              {groupAnswersFromMultipleTexts && (
                <TextInputField
                  name={`steps.${index}.minutes_to_wait_for_more_messages`}
                  label={t(
                    "flows:steps.question.minutes-to-wait-for-more-messages.label",
                    "Minutes to wait from last message"
                  )}
                  help={t(
                    "flows:steps.question.minutes-to-wait-for-more-messages.help",
                    "Every time we receive a message we'll wait up to this many minutes before we consider this step as answered."
                  )}
                  placeholder={t(
                    "flows:steps.question.minutes-to-wait-for-more-messages.placeholder",
                    "1"
                  )}
                  type="number"
                />
              )}
            </div>
          )}
        </>
      )}
      {validateAnswerWithButtons && (
        <FlowBuilderMaxAnswerAttempts id={id} index={index} />
      )}
      <FlowBuilderWaitUntil id={id} index={index} />
      <FlowBuilderMsgFail id={id} index={index} />
    </div>
  );
}

export default FlowBuilderStepTemplate;
