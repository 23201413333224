import * as Sentry from "@sentry/browser";

import { SearchIcon, XIcon } from "@heroicons/react/outline";
import { useCallback, useState } from "react";
import useQuickReplies, {
  QuickReplyData,
  QuickReplyPayload,
  useQuickReplyUpdateDetails,
} from "../../../hooks/useQuickReplies";

import APIErrors from "src/components/base/APIErrors";
import { AxiosError } from "axios";
import { Dialog } from "@headlessui/react";
import HelpDocsLink from "src/components/HelpDocsLink";
import { InboxContactRead } from "@hilos/types/private-schema";
import QuickRepliesForm from "./QuickRepliesForm";
import QuickRepliesList from "./QuickRepliesList";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import { getContactVariables } from "../../../helpers/utils";
import { interpolate } from "../../../helpers/template";
import { useCustomFields } from "src/hooks/useContactCustomField";
import useHilosStore from "../../../hooks/useHilosStore";
import { usePostHog } from "posthog-js/react";
import { useTranslation } from "react-i18next";

interface QuickRepliesProps {
  inboxContact: InboxContactRead;
  onClose: () => void;
  onSetMessage: (message: string) => void;
}

function QuickReplies({
  inboxContact,
  onClose,
  onSetMessage,
}: QuickRepliesProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const posthog = usePostHog();
  const [selectedQuickReply, setSelectedQuickReply] = useState<
    QuickReplyData | undefined
  >(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState<
    WhatsAppTemplate | undefined
  >(undefined);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const {
    tags,
    pages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    createQuickReply,
    deleteQuickReply,
    handleChangeSearch,
    refetch,
  } = useQuickReplies(inboxContact.channel);
  const { updateQuickReply } = useQuickReplyUpdateDetails();
  const { contactCustomFields } = useCustomFields();

  const handleSetMessage = useCallback(
    (message: string | null = null) => {
      if (message && session) {
        const variables = {
          "user.email": session.email,
          "user.first_name": session.first_name,
          "user.last_name": session.last_name,
          "user.full_name": `${session.first_name || ""} ${
            session.last_name || ""
          }`,
        };
        const contactAvailableVariables =
          getContactVariables(contactCustomFields);

        if (inboxContact.contact) {
          const contactMeta = inboxContact.contact.meta || {};

          for (const variableKey of contactAvailableVariables) {
            if (variableKey) {
              if (inboxContact.contact[variableKey]) {
                variables[`contact.${variableKey}`] =
                  inboxContact.contact[variableKey];
              } else if (contactMeta[variableKey]) {
                variables[`contact.${variableKey}`] = contactMeta[variableKey];
              }
            }
          }
        }

        const interpolatedMessage = interpolate(message, variables);
        onSetMessage(interpolatedMessage);
        onClose();
      }
    },
    [inboxContact.contact, contactCustomFields, session, onSetMessage, onClose]
  );

  const handleCreateQuickReply = useCallback(
    async (params: QuickReplyPayload) => {
      setBackendError("");
      setBackendValidationErrors({});
      try {
        const data = await createQuickReply(params);
        posthog?.capture("quickreply created");
        if (data && data.text) {
          handleSetMessage(data.text);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [createQuickReply, handleSetMessage, posthog]
  );

  const handleDeleteQuickReply = useCallback(
    async (id: string) => {
      setBackendError("");
      setBackendValidationErrors({});
      try {
        deleteQuickReply(id);
        posthog?.capture("quickreply deleted");
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [deleteQuickReply, posthog]
  );

  const handleUpdateQuickReply = useCallback(
    async (formData: QuickReplyPayload, id: string) => {
      setBackendError("");
      setBackendValidationErrors({});
      try {
        const data = await updateQuickReply(formData, id);
        posthog?.capture("quickreply updated");
        if (data) {
          refetch();
          setSelectedQuickReply(undefined);
          setOpenTemplateModal(false);
        }
      } catch (error) {
        type axiosErr =
          | AxiosError<{ status?: number; data?: unknown }, unknown>
          | undefined;
        const errorAxios: axiosErr = error as axiosErr;
        if (errorAxios?.response?.status === 400) {
          setBackendValidationErrors(errorAxios.response.data);
        } else {
          setBackendError("There was an error updating this quick reply.");
        }
        Sentry.captureException(error);
      }
    },
    [posthog, refetch, updateQuickReply]
  );

  const onSelectEditQuickReply = (qk: QuickReplyData) => {
    setBackendError("");
    setBackendValidationErrors({});
    setSelectedQuickReply(qk);
  };

  const onCancelEdit = () => {
    setSelectedQuickReply(undefined);
  };

  return (
    <div className="flex h-full flex-col justify-between overflow-hidden">
      <div className="w-full space-y-2 bg-indigo-700 pt-3">
        <div className="flex items-center justify-between px-4 sm:px-6">
          <Dialog.Title className="text-lg font-medium text-white">
            {t("inbox:conversations.quick-replies.title", "Quick replies")}
          </Dialog.Title>
          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              onClick={onClose}
            >
              <span className="sr-only">{t("close-panel", "Close panel")}</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <p className="text-sm text-indigo-300">
            {t(
              "inbox:conversations.quick-replies.description",
              "Click on a previously saved reply to send, or create a new one. "
            )}
            <HelpDocsLink
              href="https://hilos.io/docs/user/using-hilos/inbox/quick-replies"
              className="text-gray-200"
            />
          </p>
        </div>
        <div className="relative w-full shrink-0">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4 sm:pl-6">
            <SearchIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </div>
          <input
            className="block w-full border-0 border-transparent bg-indigo-800 py-3 pl-12 pr-4 text-white placeholder:text-gray-300 focus:ring-0 sm:pl-14 sm:text-sm"
            type="search"
            placeholder={t("search", "Search...")}
            onChange={(ev) => handleChangeSearch(ev.target.value)}
          />
        </div>
      </div>
      {(backendError || backendValidationErrors) && (
        <APIErrors
          APIError={backendError}
          APIValidationErrors={backendValidationErrors}
          fieldTranslations={{}}
        />
      )}
      <QuickRepliesList
        pages={pages}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onNextPage={fetchNextPage}
        onSetMessage={handleSetMessage}
        onDeleteQuickReply={handleDeleteQuickReply}
        onSelectEditQuickReply={onSelectEditQuickReply}
        contact={inboxContact.contact}
        onCloseModal={onClose}
        setOpenUpdateModal={setOpenTemplateModal}
        setBackendError={setBackendError}
        setBackendValidationErrors={setBackendValidationErrors}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        selectedQuickReply={selectedQuickReply}
        setSelectedQuickReply={setSelectedQuickReply}
        setIsUpdate={setIsUpdate}
      />
      <QuickRepliesForm
        tags={tags}
        onCreateQuickReply={handleCreateQuickReply}
        onUpdateQuickReply={handleUpdateQuickReply}
        quickReply={selectedQuickReply}
        onCancelEdit={onCancelEdit}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        setOpen={setOpenTemplateModal}
        open={openTemplateModal}
        showForm={showForm}
        setShowForm={setShowForm}
        setIsUpdate={setIsUpdate}
        isUpdate={isUpdate}
      />
    </div>
  );
}

export default QuickReplies;
