import { useMemo } from "react";
import {
  WorkflowStateRead,
  WorkflowTaskEdit,
} from "@hilos/types/private-schema";
import SlideOver from "../SlideOver";
import WorkflowBoardAddTaskForm from "./WorkflowBoardAddTaskForm";

interface WorkflowBoardAddTaskProps {
  workflowId: string;
  states: WorkflowStateRead[];
  initialValues: Partial<WorkflowTaskEdit> | null;
  onClose: () => void;
}

export default function WorkflowBoardAddTask({
  workflowId,
  states,
  initialValues,
  onClose,
}: WorkflowBoardAddTaskProps) {
  const currentStateOptions = useMemo(
    () =>
      states.map((state) => ({
        value: state.id,
        label: state.name,
      })),
    [states]
  );

  return (
    <SlideOver show={Boolean(initialValues)} onClose={onClose}>
      <WorkflowBoardAddTaskForm
        workflowId={workflowId}
        currentStateOptions={currentStateOptions}
        initialValues={initialValues}
        onClose={onClose}
      />
    </SlideOver>
  );
}
