import * as yup from "yup";
import { ERROR_MESSAGES } from "../../constants/Form";

export const HTTPFormMeta = {
  getInitialValues: function () {
    return {
      method: "POST",
      url: "",
      headers: [],
      params: [],
      body: "",
      body_type: "JSON",
      body_params: [],
    };
  },
  getSchema: function () {
    return {
      method: yup.string().required(ERROR_MESSAGES.required),
      url: yup.string().required(ERROR_MESSAGES.required),
      headers: yup.array().of(
        yup.object().shape({
          key: yup.string(),
          value: yup.mixed().when("key", {
            is: (val) => val,
            then: yup.string().required(ERROR_MESSAGES.required),
          }),
        })
      ),
      params: yup.array().of(
        yup.object().shape({
          key: yup.string(),
          value: yup.mixed().when("key", {
            is: (val) => val,
            then: yup.string().required(ERROR_MESSAGES.required),
          }),
        })
      ),
      body: yup.string(),
      body_type: yup.string(),
      body_params: yup.mixed().when("bodyType", {
        is: "STRUCTURED",
        then: yup.array().of(
          yup.object().shape({
            key: yup.string(),
            value: yup.mixed().when("key", {
              is: (val) => val,
              then: yup.string().required(ERROR_MESSAGES.required),
            }),
          })
        ),
      }),
    };
  },
  FIELDS: {
    url: {
      key: "url",
      label: "URL",
      help: "",
      placeholder: "Enter a valid URL",
    },
    body: {
      key: "body",
      label: "Request Body",
      help: "",
      placeholder: "Insert request body",
    },
    body_type: {
      key: "body_type",
      label: "Request body type",
    },
    body_params: {
      form_key: {
        key: "key",
        label: "Key",
        help: "",
        placeholder: "Key",
      },
      form_value: {
        key: "value",
        label: "Value",
        help: "",
        placeholder: "Value",
      },
    },
    method: {
      key: "method",
      label: "Method type",
      help: "Select a HTTP Method",
    },
    headers: {
      key: {
        key: "key",
        label: "Key",
        help: "",
        placeholder: "Key",
      },
      value: {
        key: "value",
        label: "Value",
        help: "",
        placeholder: "Value",
      },
    },
    params: {
      key: {
        key: "key",
        label: "Key",
        help: "",
        placeholder: "Key",
      },
      value: {
        key: "value",
        label: "Value",
        help: "",
        placeholder: "Value",
      },
    },
  },
  METHOD_TYPES: [
    {
      label: "GET",
      value: "GET",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-blue-500"
          aria-hidden="true"
        >
          G
        </div>
      ),
      help: "Make a get request",
    },
    {
      label: "POST",
      value: "POST",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-green-500"
          aria-hidden="true"
        >
          P
        </div>
      ),
      help: "Make a post request",
    },
    {
      label: "PUT",
      value: "PUT",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-yellow-500"
          aria-hidden="true"
        >
          P
        </div>
      ),
      help: "Make a put request",
    },
    {
      label: "PATCH",
      value: "PATCH",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-purple-500"
          aria-hidden="true"
        >
          P
        </div>
      ),
      help: "Make a patch request",
    },
    {
      label: "DELETE",
      value: "DELETE",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-red-500"
          aria-hidden="true"
        >
          D
        </div>
      ),
      help: "Make a delete request",
    },
  ],
  BODY_TYPES: [
    {
      label: "Multipart Form",
      value: "multipart/form-data",
      type: "STRUCTURED",
    },
    {
      label: "Form URL Encoded",
      value: "application/x-www-form-urlencoded",
      type: "STRUCTURED",
    },
    {
      label: "JSON",
      value: "application/json",
      type: "TEXT",
    },
    {
      label: "XML",
      value: "application/xml",
      type: "TEXT",
    },
    {
      label: "YAML",
      value: "text/yaml",
      type: "TEXT",
    },
    {
      label: "Plain",
      value: "text/plain",
      type: "TEXT",
    },
    {
      label: "None",
      value: "NONE",
      type: "OTHER",
    },
  ],
};
