import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TagData } from "@hilos/types/conversation";
import { ConversationReadSimple } from "@hilos/types/private-schema";
import Tags, { conversationTagsColorClasses } from "src/components/Tags";
import { UpdateConversationFn } from "src/hooks/useInboxContactDetails";
import { useConversationTags } from "src/hooks/useTags";

interface ConversationDetailsConversationTagListProps {
  conversation: ConversationReadSimple;
  handleUpdateConversation: UpdateConversationFn;
}

function ConversationDetailsConversationTagList({
  conversation,
  handleUpdateConversation,
}: ConversationDetailsConversationTagListProps) {
  const { t } = useTranslation();
  const { tags } = useConversationTags();
  const currentTags = useMemo(
    () => conversation.tags ?? [],
    [conversation.tags]
  );

  const handleUpdateTags = useCallback(
    (nextTags: TagData[]) => {
      handleUpdateConversation({ tags: nextTags });
    },
    [handleUpdateConversation]
  );

  const handleRemoveTag = useCallback(
    (tagToRemove) => {
      return () => {
        const nextTags = [...currentTags];
        handleUpdateTags(
          nextTags.filter((tag) => tag.name !== tagToRemove.name)
        );
      };
    },
    [currentTags, handleUpdateTags]
  );

  return (
    <div className="mt-2">
      <Tags
        data-tour="conversation-tags"
        title={t("conversation-tags", "Conversation Tags")}
        availableTags={tags}
        currentTags={currentTags}
        onUpdateTags={handleUpdateTags}
        onRemoveTag={handleRemoveTag}
        {...conversationTagsColorClasses}
      />
    </div>
  );
}

export default ConversationDetailsConversationTagList;
