import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {
  FlowTemplateDetailRead,
  FlowTemplateSimpleListRead,
} from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useFlowTemplate() {
  const fetchFlowTemplates = useCallback(async () => {
    const { data } = await axios.get<FlowTemplateSimpleListRead[]>(
      buildAPIRoute(API_ROUTES.FLOW_CREATE_TEMPLATE_LIST, {
        templates: true,
      })
    );
    return data;
  }, []);

  const searchFlowTemplates = useCallback(async (search: string) => {
    const { data } = await axios.get(API_ROUTES.FLOW_CREATE_TEMPLATE_LIST, {
      params: { search },
    });

    return data[0];
  }, []);

  const fetchFlowTemplate = useCallback(async (id: string) => {
    const { data } = await axios.get<FlowTemplateDetailRead>(
      buildAPIRoute(API_ROUTES.FLOW_TEMPLATE_DETAIL, {
        ":id": id,
      })
    );

    return data;
  }, []);

  const { data, refetch } = useQuery("flow_templates", fetchFlowTemplates);

  return { data, refetch, searchFlowTemplates, fetchFlowTemplate };
}

export default useFlowTemplate;
