import { ClipboardListIcon, EyeIcon, XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useMemo, useRef, useState } from "react";

import { TemplateContentValues } from "@hilos/helpers/template";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import WhatsAppTemplatePreview from "src/containers/wa/WhatsAppTemplatePreview";
import { getTemplateVarsFromTemplate } from "src/containers/wa/WhatsAppTemplateMeta";
import { hasItems } from "src/helpers/utils";
import { initialTemplatePreviewValues } from "src/containers/wa/WhatsAppTemplatePreviewForm";
import { useTranslation } from "react-i18next";

interface BroadcastWhatsAppTemplatePreviewModalProps {
  template: WhatsAppTemplate;
  initialTemplatePreviewValuesArray: string[];
}

export default function BroadcastWhatsAppTemplatePreviewModal({
  template,
  initialTemplatePreviewValuesArray,
}: BroadcastWhatsAppTemplatePreviewModalProps) {
  const { t } = useTranslation();

  const templateVars = useMemo(() => {
    return getTemplateVarsFromTemplate(template) as string[];
  }, [template]);

  const initialTemplatePreviewValuesMemo =
    useMemo<TemplateContentValues>(() => {
      const initialTemplatePreviewObject: TemplateContentValues = {
        ...initialTemplatePreviewValues,
      };
      const initialTemplatePreviewValuesArrayLocal = [
        ...initialTemplatePreviewValuesArray,
      ];

      if (
        hasItems(
          templateVars.filter((variable) =>
            variable.includes("{{URL for header")
          )
        )
      ) {
        initialTemplatePreviewObject.headerURL =
          initialTemplatePreviewValuesArrayLocal.splice(0, 1)[0];
      } else if (
        hasItems(
          templateVars.filter((variable) => variable.includes("{{Header"))
        )
      ) {
        initialTemplatePreviewObject.headerVar =
          initialTemplatePreviewValuesArrayLocal.splice(0, 1)[0];
      }

      if (
        hasItems(
          templateVars.filter((variable) =>
            variable.includes("{{URL for Button")
          )
        )
      ) {
        const buttonVars = templateVars.filter((variable) =>
          variable.includes("{{URL for Button")
        );

        buttonVars.forEach((_) => {
          if (!initialTemplatePreviewObject.buttonVars) {
            initialTemplatePreviewObject.buttonVars = [];
          }
          initialTemplatePreviewObject.buttonVars.push(
            initialTemplatePreviewValuesArrayLocal.splice(-1, 1)[0]
          );
        });
      }
      initialTemplatePreviewObject.bodyVars =
        initialTemplatePreviewValuesArrayLocal;
      return initialTemplatePreviewObject;
    }, [initialTemplatePreviewValuesArray, templateVars]);

  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ClipboardListIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("templates:template-preview", "Template preview")}
                    </Dialog.Title>
                  </div>
                </div>

                <div className={`mt-8`}>
                  <div>
                    <WhatsAppTemplatePreview
                      template={template}
                      values={initialTemplatePreviewValuesMemo}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <button
        type="button"
        className="text-blue-400"
        onClick={(_) => setOpen(true)}
        id={template.id}
      >
        <button
          className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-2 py-1 text-xs font-medium leading-4 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
          type="button"
        >
          {t("broadcasts:preview", "Preview")}
          <EyeIcon className="ml-2 h-3 w-3" aria-hidden="true" />
        </button>
      </button>
    </>
  );
}
