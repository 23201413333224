import { MultiSelect, MultiSelectItem } from "@tremor/react";

import { useAccountMemberEmails } from "src/hooks/useAccountMemberEmails";
import { useTranslation } from "react-i18next";

interface AccountMemberEmailFilterProps {
  value: string[];
  onValueChange: (value: string[]) => void;
}

export default function AccountMemberEmailFilter({
  value,
  onValueChange,
}: AccountMemberEmailFilterProps) {
  const { emails } = useAccountMemberEmails();
  const { t } = useTranslation();
  return (
    <MultiSelect
      placeholder={t("reports:usage-inbox.user-email", "User email")}
      value={value}
      onValueChange={onValueChange}
      placeholderSearch={t("search", "Search")}
    >
      {emails.map((email) => (
        <MultiSelectItem value={email} key={email}>
          {email}
        </MultiSelectItem>
      ))}
    </MultiSelect>
  );
}
