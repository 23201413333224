import {
  DocumentTextIcon,
  PhotographIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import * as yup from "yup";
import { getStepsAsArray } from "src/containers/flow/Helpers";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const MESSAGE_BODY_TYPES = [
  {
    label: "Text",
    value: "text",
    icon: (
      <div className="h-5 w-5" aria-hidden="true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="4 7 4 4 20 4 20 7"></polyline>
          <line x1="9" y1="20" x2="15" y2="20"></line>
          <line x1="12" y1="4" x2="12" y2="20"></line>
        </svg>
      </div>
    ),
    help: "Text message, can contain variables.",
  },
  {
    label: "Image",
    value: "image",
    icon: <PhotographIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Sends a single image, PNG or JPG",
    accept: "image/png,image/jpg,image/jpeg",
    inputHelp:
      "Make sure it's a public .png or .jpg file and no larger than 15MB.",
  },
  {
    label: "Document",
    value: "document",
    icon: <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Sends a PDF file",
    accept: "application/pdf",
    inputHelp: "Make sure it's a public .pdf file and no larger than 15MB.",
  },
  {
    label: "Video",
    value: "video",
    icon: <VideoCameraIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Sends an MP4 video",
    accept: "video/mp4",
    inputHelp:
      "Make sure it's a public .mp4 file and no larger than 15MB. If you want to use an external video from youtube, etc, use a direct download link. Otherwise we won't be able to use it.",
  },
];

export const FlowStepMessageMeta = {
  getInitialValues: function (step) {
    return {
      name: step?.name || "",
      step_type: "MESSAGE",
      next_step_default_idx: step?.next_step_default_idx || "",
      body: step?.body || "",
      body_type: "text",
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      next_step_default_idx: yup.string(),
      body: yup.string().when("body_type", {
        is: "text",
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      body_type: yup.string().required(ERROR_MESSAGES.required),
      body_file_type: yup.string().when("body_type", {
        is: (bodyType) => bodyType !== "text",
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      body_file_url: yup.string().when("body_file_type", {
        is: (val) => ["URL"].includes(val),
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      body_file: yup.string().when("body_file_type", {
        is: (val) => ["UPLOAD"].includes(val),
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
    });
  },
  validate: function () {
    return {};
  },
  formatForSave: function (step) {
    const nextStep = { ...step };
    if (nextStep.body_file_type === "URL") {
      delete nextStep.body_file;
    } else if (nextStep.body_file_type === "UPLOAD") {
      delete nextStep.body_file_url;
    }
    if (nextStep.body_type === "text") {
      delete nextStep.body_file_url;
      delete nextStep.body_file;
      delete nextStep.body_file_type;
    } else {
      delete nextStep.body;
    }
    return nextStep;
  },
  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    next_step_default_idx: {
      key: "next_step_default_idx",
      label: "Next step",
      help: "If you can't find the step you'd like to go to next, check that it has a name.",
      placeholder: "Step 1 Welcome",
    },
    body_file: {
      key: "body_file",
      label: "File",
      help: "",
    },
    body_file_url: {
      key: "body_file_url",
      label: "File URL",
      help: "",
      placeholder: "https://mysite.com/myimage.png",
    },
    body_file_type: {
      key: "body_file_type",
      label: "File",
      help: "",
    },
    body: {
      key: "body",
      label: "Message body",
      help: "",
      placeholder: "Step 1 Welcome",
    },
    body_type: {
      key: "body_type",
      label: "Message type",
      help: "",
    },
  },
  BODY_TYPES: MESSAGE_BODY_TYPES,
};
