import { usePostHog } from 'posthog-js/react';
import { useCallback} from 'react'
import { Link, LinkProps } from "react-router-dom";

import {PosthogEventProps} from '@hilos/types/posthog'

type LinkTrackedProps<T> = LinkProps & PosthogEventProps<T>

export default function LinkTracked<T extends Record<string, unknown>>({posthogEvent, posthogPayload, children,...props}: LinkTrackedProps<T>) {
  const posthog = usePostHog();
  const onClickLink = useCallback(() => {
    posthog?.capture(posthogEvent, posthogPayload)
  }, [posthog, posthogEvent, posthogPayload])
  return (<Link {...props} onClick={onClickLink}>
      {children}
        </Link>)
}