import {
  AdjustmentsIcon,
  CheckCircleIcon,
  MinusCircleIcon,
  SearchIcon,
  ViewListIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  markNotificationsAsSeen,
  useUserNotifications,
} from "src/hooks/useUserNotification";

import DropdownMenu from "src/components/DropdownMenu";
import InfiniteScroll from "../inbox/Conversation/InfiniteScroll";
import Loading from "src/components/Loading";
import UserNotificationItem from "./UserNotificationItem";
import { classNames } from "src/Helpers";
import { hasItems } from "src/helpers/utils";
import { useTranslation } from "react-i18next";

export default function UserNotificationList({ onClose }) {
  const { t } = useTranslation();
  const {
    pages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    handleChangeSearch,
    seenFilter,
    setSeenFilter,
  } = useUserNotifications();

  return (
    <>
      <div className="px-5 py-3 sm:px-5 flex items-center justify-between bg-gray-50 mt-3">
        <h3 className="leading-5 font-medium text-lg text-gray-900">
          {t("notifications.title", "Notifications")}
        </h3>
        <div className="flex items-center">
          <div className="w-42 relative grow-0">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              className="block w-full border-0 border-transparent py-2 pl-10 focus:ring-0 sm:text-sm bg-transparent"
              type="search"
              placeholder={t("search", "Search")}
              onChange={handleChangeSearch}
            />
          </div>
          <div className="space-x-3 flex items-center">
            <DropdownMenu
              menuItems={[
                (active) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex w-full items-center px-4 py-2 text-sm"
                    )}
                    type="button"
                    onClick={markNotificationsAsSeen}
                  >
                    <CheckCircleIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("filter.mark-all-as-read", "Mark all as read")}
                  </button>
                ),
              ]}
            />
            <DropdownMenu
              icon={
                seenFilter === "ALL"
                  ? AdjustmentsIcon
                  : seenFilter === "READ"
                  ? CheckCircleIcon
                  : MinusCircleIcon
              }
              menuItems={[
                (active) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex w-full items-center px-4 py-2 text-sm"
                    )}
                    type="button"
                    onClick={() => setSeenFilter("ALL")}
                  >
                    <ViewListIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("filter.read-and-unread", "See all")}
                  </button>
                ),
                (active) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex w-full items-center px-4 py-2 text-sm"
                    )}
                    type="button"
                    onClick={() => setSeenFilter("READ")}
                  >
                    <CheckCircleIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("filter.read-only", "See read only")}
                  </button>
                ),
                (active) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex w-full items-center px-4 py-2 text-sm"
                    )}
                    type="button"
                    onClick={() => setSeenFilter("UNREAD")}
                  >
                    <MinusCircleIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("filter.read-only", "See unread only")}
                  </button>
                ),
              ]}
            />
          </div>
          <button
            type="button"
            className="relative rounded-md  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-2"
            onClick={onClose}
          >
            <span className="absolute -inset-2.5" />
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="h-full overflow-y-auto">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {pages &&
            pages.length > 0 &&
            pages[0] &&
            pages[0].count &&
            pages[0].count > 0 ? (
              <InfiniteScroll
                className="h-full grow"
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                onNextPage={fetchNextPage}
              >
                <ul className="divide-y divide-gray-100 bg-gray-50 sm:rounded-xl px-2">
                  {pages.map(
                    (page, pageIndex) =>
                      page &&
                      hasItems(page.results) &&
                      page.results.map((notification, index) => (
                        <li
                          key={notification.id}
                          className="relative flex justify-between px-2 py-3 hover:bg-gray-100 sm:px-3 w-full"
                        >
                          <UserNotificationItem
                            notification={notification}
                            onClose={onClose}
                          />
                        </li>
                      ))
                  )}
                </ul>
              </InfiniteScroll>
            ) : (
              <div className="p-12 text-center">
                <span className="mt-2 block text-sm text-gray-500">
                  {t(
                    "notifications.no-notifications",
                    "No notifications found"
                  )}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
