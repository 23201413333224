import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import useHilosStore from "../../hooks/useHilosStore";

export default function AccountSubscriptionCancelledNotice() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  if (!session) {
    return null;
  }

  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            {t(
              "settings:subscription-canceled-notice.title",
              "Your subscription is currently canceled."
            )}
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            {t(
              "settings:subscription-canceled-notice.subtitle",
              "To continue using Hilos, select a plan and start your subscription again."
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
