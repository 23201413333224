import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "@heroicons/react/outline";
import axios from "axios";
import { DateTime } from "luxon";
import { usePostHog } from "posthog-js/react";
import { FlowVersionSimpleRead } from "@hilos/types/private-schema";
import LoaderSpinner from "src/components/LoaderSpinner";
import { API_ROUTES, buildAPIRoute } from "../../../router/router";

interface FlowVersionResultDownloadProps {
  flowVersion: Omit<
    FlowVersionSimpleRead,
    "first_step" | "steps" | "published_by" | "num_contacts" | "completed"
  >;
  flowExecutionId?: string;
}

export default function FlowVersionResultDownload({
  flowVersion,
  flowExecutionId,
}: FlowVersionResultDownloadProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [loading, setLoading] = useState(false);

  const downloadResults = async () => {
    setLoading(true);
    posthog?.capture("flow_execution results download", {
      flow_execution_id: flowExecutionId,
      flow_version_id: flowVersion.id,
      flow_id: flowVersion.flow.id,
      flow_name: flowVersion.flow.name,
    });
    try {
      const response = await axios.get(
        buildAPIRoute(API_ROUTES.FLOW_VERSION_RESULT_DOWNLOAD, {
          ":id": flowVersion.id,
        }),
        {
          params: { flow_execution: flowExecutionId },
        }
      );
      const hiddenElement = document.createElement("a");
      hiddenElement.href = response.data.url;
      hiddenElement.download = `${
        flowVersion.flow.name
      } Results ${DateTime.now().toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS
      )}`;
      hiddenElement.click();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      className="inline-flex items-center rounded-md border border-transparent bg-blue-100 px-3 py-2 text-sm font-medium leading-4 text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      type="button"
      onClick={downloadResults}
      disabled={loading}
    >
      {loading ? (
        <>
          <LoaderSpinner />
          {t("downloading", "Downloading...")}
        </>
      ) : (
        <>
          <DownloadIcon className="mr-1 h-5 w-5" aria-hidden="true" />
          {t(
            "flows:flow_version.results-download.default-button-text",
            "Download results"
          )}
        </>
      )}
    </button>
  );
}
