import { useCallback } from "react";
import { UseMutateAsyncFunction, useMutation, useQuery } from "react-query";
import axios from "axios";
import { VesselApp } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";
import { queryClient } from "src/HilosProvider";

function useVesselExternalApp(id?: string) {
  const fetchExternalApp = useCallback(async () => {
    const { data } = await axios.get<VesselApp>(
      buildAPIRoute(API_ROUTES.EXTERNAL_APP_VESSEL_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data, isLoading } = useQuery(["external_app", id], fetchExternalApp);

  return {data, isLoading};
}

export default useVesselExternalApp;

export type UpdateVesselDetailsFn = UseMutateAsyncFunction<
  VesselApp | null,
  unknown,
  VesselApp,
  unknown
>;


export function useVesselAppUpdateDetails(id?: string) {
  const updateVesselAppDetails = useCallback(
    async (payload: VesselApp) => {
      const { data } = await axios.patch<VesselApp>(
        buildAPIRoute(API_ROUTES.VESSEL_CONFIG, {
          ':id': id,
        }),
        payload
      );
      return data;
    },
    [id]
  );

  const {
    mutateAsync: handleUpdateVesselApp,
    isLoading: isSubmittingStatus,
  } = useMutation(updateVesselAppDetails, {
    onSuccess: (nextData) => {
      let currentAppData = queryClient.getQueryData<VesselApp>(["external_app", id]);
      if (currentAppData) {
        currentAppData = {
          ...currentAppData,
          ...nextData,
        };
      }
      queryClient.setQueryData(["external_app", id], currentAppData);
    },
  });

  return {
    handleUpdateVesselApp,
    isSubmittingStatus,
  };
}
