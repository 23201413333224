import { QuickReplyData } from "src/hooks/useQuickReplies";

export const getFormValuesFromQR = (quickReply: QuickReplyData) => {
  const data = JSON.parse(JSON.stringify(quickReply));

  delete data["account"];
  delete data["created_by"];
  delete data["created_on"];
  delete data["id"];
  data["tags"] = data["tags"].map((tag) => tag.name);

  return data;
};
