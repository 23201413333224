import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { ChevronRightIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import { onDeleteViewFn } from "@hilos/containers/inbox/ConversationListViewItem";
import { axiosErr } from "@hilos/types/axios";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import { InboxParams } from "@hilos/types/hilos";
import Button from "src/components/Button";
import { getInboxParamsFromView } from "src/helpers/inbox";
import useHilosStore from "src/hooks/useHilosStore";
import useInboxContactViews, {
  InboxContactViewData,
} from "src/hooks/useInboxContactViews";
import { classNames } from "src/Helpers";
import ConversationListViewList from "./ConversationListViewList";
import InboxParamsForm, { InboxParamsValues } from "./InboxParamsForm";

interface ConversationListViewProps {
  inboxParams: InboxParams;
  showViews: boolean;
  currentAvailableChannels: ChannelAvailabilityData[];
  allowQueryCountWithLimit: boolean;
  allowQueriesWithSQLFunctions: boolean;
  setShowViews: (params: ((prev: boolean) => boolean) | boolean) => void;
  onCloseFilters: () => void;
  onChangeFilters: (params: InboxParamsValues) => void;
}

function ConversationListView({
  inboxParams,
  showViews,
  setShowViews,
  currentAvailableChannels,
  allowQueryCountWithLimit,
  allowQueriesWithSQLFunctions,
  onCloseFilters,
  onChangeFilters,
}: ConversationListViewProps) {
  const { session, updateSession } = useHilosStore();
  const { t } = useTranslation();
  const [viewToUpdate, setViewToUpdate] = useState<InboxParamsValues | null>(
    null
  );
  const {
    inboxContactViews,
    refetch,
    isLoading,
    setSearch,
    onGetView,
    onCreateView,
    onUpdateView,
    onDeleteView,
    onChangeSearch,
  } = useInboxContactViews({ showViews });

  const handleSwitchShowViews = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!showViews) {
        refetch();
      }
      setShowViews(!showViews);
    },
    [showViews, setShowViews, refetch]
  );

  const handlePinView = useCallback(
    async (id, isPinned) => {
      let nextInboxContactViewsPinned: string[] = [];

      if (session && session.inbox_contact_views_pinned) {
        nextInboxContactViewsPinned = session.inbox_contact_views_pinned || [];
      }
      const currentIndex = nextInboxContactViewsPinned.indexOf(id);

      if (currentIndex !== -1) {
        if (isPinned) {
          nextInboxContactViewsPinned.splice(currentIndex, 1);
        }
      } else {
        if (!isPinned) {
          nextInboxContactViewsPinned.push(id);
        }
      }

      await updateSession({
        inbox_contact_views_pinned: nextInboxContactViewsPinned,
      });
      refetch();
    },
    [session, updateSession, refetch]
  );

  const handleSelectView = useCallback(
    async (id: string) => {
      if (inboxParams.view && inboxParams.view.id === id) {
        onChangeFilters({
          filters: [],
          ordering: "-last_message_on",
          view: null,
        });
      } else {
        const view = await onGetView(id);
        if (view) {
          try {
            onChangeFilters(getInboxParamsFromView(view));
            setShowViews(false);
          } catch {}
        }
      }
    },
    [inboxParams, setShowViews, onGetView, onChangeFilters]
  );

  const handleClearView = useCallback(() => {
    onChangeFilters({
      filters: [],
      ordering: "-last_message_on",
      view: null,
    });
    setShowViews(false);
  }, [onChangeFilters, setShowViews]);

  const handleUpdateView = useCallback(
    async (id: string) => {
      const view = await onGetView(id);
      if (view) {
        try {
          setSearch("");
          setViewToUpdate(getInboxParamsFromView(view));
          onCloseFilters();
        } catch {}
      }
    },
    [setSearch, onGetView, onCloseFilters]
  );

  const handleDeleteView: onDeleteViewFn = useCallback(
    async (
      formData,
      setIsSubmitting,
      setBackendValidationErrors,
      setBackendError,
      setSubmitted,
      setSuccess,
      setShowDeleteModal
    ) => {
      let nextInboxContactViewsPinned: string[] = [];
      const id = formData.obj.id;

      if (session && session.inbox_contact_views_pinned) {
        nextInboxContactViewsPinned = session.inbox_contact_views_pinned || [];
      }
      const currentIndex = nextInboxContactViewsPinned.indexOf(id);

      if (currentIndex !== -1) {
        nextInboxContactViewsPinned.splice(currentIndex, 1);
      }

      await updateSession({
        inbox_contact_views_pinned: nextInboxContactViewsPinned,
      });

      setBackendValidationErrors({});
      setBackendError("");
      try {
        await onDeleteView(id);
        setIsSubmitting(false);
        setSuccess(true);
      } catch (err) {
        const errorAxios: axiosErr = err as axiosErr;
        setSuccess(false);
        if (errorAxios?.response?.status === 400) {
          Sentry.captureException(err);
          setBackendValidationErrors(errorAxios.response.data);
        } else {
          setBackendError(
            t(
              "inbox:views.error-deleting",
              "An error occurred while deleting the view. Please try again."
            )
          );
        }
      } finally {
        setIsSubmitting(false);
        setSubmitted(true);
        setShowDeleteModal(false);
      }
    },
    [session, updateSession, onDeleteView, t]
  );

  const handleCreateView = useCallback(() => {
    const { filters, ordering } = inboxParams;
    setSearch("");
    setViewToUpdate({ filters, ordering, view: null });
    onCloseFilters();
  }, [inboxParams, setSearch, onCloseFilters]);

  const handleSubmitView = useCallback(
    async (values: InboxParamsValues) => {
      try {
        const { filters, ordering, view } = values;
        if (view) {
          const nextViewData = JSON.stringify({ filters, ordering });
          let nextView: InboxContactViewData | null = {
            ...view,
            is_legacy: false,
            data: nextViewData,
          };

          if (nextView.id) {
            nextView = await onUpdateView(nextView);
          } else {
            nextView = await onCreateView(nextView);
          }

          if (nextView && nextView.id) {
            setViewToUpdate(null);
            if (inboxParams.view && inboxParams.view.id === nextView.id) {
              onChangeFilters({
                filters,
                ordering,
                view,
              });
            }
          }
        }
      } catch {}
    },
    [inboxParams, onCreateView, onUpdateView, onChangeFilters]
  );

  useEffect(() => {
    if (!showViews) {
      setSearch("");
      setViewToUpdate(null);
    }
  }, [showViews, setSearch]);

  return (
    <div
      className="border-t border-b border-gray-200 bg-gray-50"
      data-tour="convo-list-view"
    >
      <div className="flex flex-row min-w-full items-center pl-3 space-x-0.5">
        <button
          className={classNames(
            (viewToUpdate !== null || !inboxParams.view) && "hidden"
          )}
          onClick={handleClearView}
        >
          <XIcon className="w-4 h-4 text-gray-400 hover:text-gray-500" />
        </button>
        <button
          onMouseDown={(event) => {
            if (event.detail > 1) {
              event.preventDefault();
            }
          }}
          onClick={handleSwitchShowViews}
          className="flex w-full items-center justify-between pr-4 py-2 text-xs font-medium text-gray-500"
        >
          {viewToUpdate !== null ? (
            viewToUpdate.view ? (
              <span className="pointer-events-none truncate">
                {t("editing", "Editing ")}
                <span className="font-bold">{viewToUpdate.view.name}</span>
              </span>
            ) : (
              t("inbox:views.new-view", "Create a new view")
            )
          ) : inboxParams.view ? (
            <span className="pointer-events-none truncate">
              {t("viewing", "Viewing ")}
              <span className="font-bold">{inboxParams.view.name}</span>
            </span>
          ) : (
            t("inbox:views.no-view-selected", "No view selected")
          )}
          <ChevronRightIcon
            className={classNames(
              "h-4 w-4 text-gray-400",
              showViews &&
                "rotate-90 transform transition-transform duration-100 ease-in-out"
            )}
          />
        </button>
      </div>
      <Transition
        show={showViews}
        enter="transition duration-100 ease-in"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <div className="flex flex-col pb-4 text-sm text-gray-500">
          {viewToUpdate !== null ? (
            <InboxParamsForm
              isEditingView
              values={viewToUpdate}
              onSubmit={handleSubmitView}
              onClose={() => setViewToUpdate(null)}
            />
          ) : (
            <>
              <div className="relative w-full grow-0 px-4 pt-2 pb-1">
                <input
                  autoFocus
                  type="search"
                  className="block w-full border-0 border-b border-transparent border-gray-200 bg-gray-50 p-0 pb-0.5 focus:border-indigo-400 focus:ring-0 sm:text-sm"
                  placeholder={t(
                    "inbox:views.search-a-view",
                    "Search a view..."
                  )}
                  onChange={onChangeSearch}
                />
              </div>
              <ConversationListViewList
                inboxContactViews={inboxContactViews}
                currentViewId={inboxParams.view && inboxParams.view.id}
                isLoading={isLoading}
                isInboxManager={session?.role.name === "Admin"}
                currentAvailableChannels={currentAvailableChannels}
                allowQueryCountWithLimit={allowQueryCountWithLimit}
                allowQueriesWithSQLFunctions={allowQueriesWithSQLFunctions}
                onSelectView={handleSelectView}
                onUpdateView={handleUpdateView}
                onDeleteView={handleDeleteView}
                onPinView={handlePinView}
              />
              <div className="flex flex-row justify-end px-4 pt-6">
                <Button
                  type="button"
                  onClick={handleCreateView}
                  icon={<PlusIcon className="ml-1 -mr-1 h-5 w-5" />}
                >
                  {t("inbox:views.create", "Create a new view")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Transition>
    </div>
  );
}

export default ConversationListView;
