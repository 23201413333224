import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const FlowStepActionMeta = {
  getInitialValues: function (step) {
    return {
      name: step?.name || "",
      step_type: "ACTION",
      next_step_default_idx: step?.next_step_default_idx || "",
      action_request_method: "POST",
      action_request_url: "",
      action_request_headers: [],
      action_request_params: [],
      action_request_body: "",
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      next_step_default_idx: yup.string(),
      action_request_method: yup.string().required(ERROR_MESSAGES.required),
      action_request_url: yup.string().required(ERROR_MESSAGES.required),
      action_request_body: yup.mixed().when("action_request_method", {
        is: (method) => ["POST", "PATCH", "PUT"].includes(method),
        then: yup.mixed().required(ERROR_MESSAGES.required),
      }),
    });
  },
  validate: function (formValues, step) {
    // Check that if flow is outbound, this step does not happen before a template step
    return {};
  },
  formatForSave: function (step) {
    const nextStep = { ...step };
    if (Array.isArray(nextStep.action_request_body)) {
      nextStep.action_request_body.forEach((obj, idx) => {
        if (!obj.key || !obj.value) {
          nextStep.action_request_body.splice(idx, 1);
        }
      });
      const resultBody = JSON.stringify(
        nextStep.action_request_body.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        )
      );
      nextStep.action_request_body = resultBody;
    }
    nextStep.action_request_headers = nextStep.action_request_headers ?? [];
    nextStep.action_request_params = nextStep.action_request_params ?? [];
    // Remove empty headers and params from array
    nextStep.action_request_headers.forEach((obj, idx) => {
      if (!obj.key || !obj.value) {
        nextStep.action_request_headers.splice(idx, 1);
      }
    });
    nextStep.action_request_params.forEach((obj, idx) => {
      if (!obj.key || !obj.value) {
        nextStep.action_request_params.splice(idx, 1);
      }
    });
    /**Reconvert headers and params to an object to save to HSField*/
    const objHeaders = nextStep.action_request_headers.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );
    nextStep.action_request_headers = objHeaders;
    const objParams = nextStep.action_request_params.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );
    nextStep.action_request_params = objParams;
    return nextStep;
  },
  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    next_step_default_idx: {
      key: "next_step_default_idx",
      label: "Next step",
      help: "If you can't find the step you'd like to go to next, check that it has a name.",
      placeholder: "Step 1 Welcome",
    },
    action_request_url: {
      key: "action_request_url",
      label: "URL",
      help: "",
      placeholder: "Enter a valid URL",
    },
    action_request_body: {
      key: "action_request_body",
      label: "Request Body",
      help: "",
      placeholder: "Insert request body",
      form_key: {
        key: "key",
        label: "Key",
        help: "",
        placeholder: "Key",
      },
      form_value: {
        key: "value",
        label: "Value",
        help: "",
        placeholder: "Value",
      },
    },
    action_request_method: {
      key: "action_request_method",
      label: "Method type",
      help: "Select a HTTP Method",
    },
    action_request_headers: {
      key: {
        key: "key",
        label: "Key",
        help: "",
        placeholder: "Key",
      },
      value: {
        key: "value",
        label: "Value",
        help: "",
        placeholder: "Value",
      },
    },
    action_request_params: {
      key: {
        key: "key",
        label: "Key",
        help: "",
        placeholder: "Key",
      },
      value: {
        key: "value",
        label: "Value",
        help: "",
        placeholder: "Value",
      },
    },
    action_test_response_data: {
      key: "action_test_response_data",
    },
  },
  METHOD_TYPES: [
    {
      label: "GET",
      value: "GET",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-blue-500"
          aria-hidden="true"
        >
          G
        </div>
      ),
      help: "Make a get request",
    },
    {
      label: "POST",
      value: "POST",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-green-500"
          aria-hidden="true"
        >
          P
        </div>
      ),
      help: "Make a post request",
    },
    {
      label: "PUT",
      value: "PUT",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-yellow-500"
          aria-hidden="true"
        >
          P
        </div>
      ),
      help: "Make a put request",
    },
    {
      label: "PATCH",
      value: "PATCH",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-purple-500"
          aria-hidden="true"
        >
          P
        </div>
      ),
      help: "Make a patch request",
    },
    {
      label: "DELETE",
      value: "DELETE",
      icon: (
        <div
          className="h-5 w-5 rounded-full font-extrabold text-red-500"
          aria-hidden="true"
        >
          D
        </div>
      ),
      help: "Make a delete request",
    },
  ],
  BODY_TYPES: [
    {
      label: "Multipart Form",
      value: "multipart/form-data",
      type: "STRUCTURED",
    },
    {
      label: "Form URL Encoded",
      value: "application/x-www-form-urlencoded",
      type: "STRUCTURED",
    },
    {
      label: "JSON",
      value: "application/json",
      type: "TEXT",
    },
    {
      label: "XML",
      value: "application/xml",
      type: "TEXT",
    },
    {
      label: "YAML",
      value: "text/yaml",
      type: "TEXT",
    },
    {
      label: "Plain",
      value: "text/plain",
      type: "TEXT",
    },
    {
      label: "None",
      value: "NONE",
      type: "OTHER",
    },
  ],
};
