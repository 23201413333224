import { Dialog, Transition } from "@headlessui/react";

import { Fragment } from "react";
import MediaDocument from "./MediaDocument";
import MediaImage from "./MediaImage";
import { WhatsAppMessage } from "@hilos/types/private-schema";
import { getValuesFromTemplateContent } from "src/helpers/template";

interface MessageMediaModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  message: WhatsAppMessage;
}

export default function MessageMediaModal({
  show,
  setShow,
  message,
}: MessageMediaModalProps) {
  const renderMediaComponent = () => {
    switch (message.msg_type) {
      case "image":
        return <MediaImage setShow={setShow} message={message} />;
      case "document":
        return <MediaDocument setShow={setShow} message={message} />;
      case "template":
        const { headerURL } = getValuesFromTemplateContent(message.content);
        if (headerURL) {
          if (message.whatsapp_template?.components?.[0]?.format === "IMAGE") {
            return (
              <MediaImage
                setShow={setShow}
                message={message}
                content_url={headerURL}
              />
            );
          } else if (
            message.whatsapp_template.components[0].format === "DOCUMENT"
          ) {
            return (
              <MediaDocument
                setShow={setShow}
                message={message}
                content_url={headerURL}
              />
            );
          }
        }
        return headerURL ? (
          <MediaImage
            setShow={setShow}
            message={message}
            content_url={headerURL}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setShow}
        >
          <div className="flex min-h-screen items-end justify-center text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden bg-black bg-opacity-70 text-left align-bottom shadow-xl transition-all max-h-screen   sm:align-middle">
                {renderMediaComponent()}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
