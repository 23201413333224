import { Fragment } from "react";

interface LinkifyProps {
  message?: string | null;
}

function getURLRegex(exact = false) {
  return new RegExp(
    (exact ? "^" : "(") +
      // protocol identifier (optional)
      // short syntax // still required
      "(?:(?:(?:https?|ftp):)?\\/\\/)" +
      // user:pass BasicAuth (optional)
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
      // IP address exclusion
      // private & local networks
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      "(?:" +
      "(?:" +
      "[a-z0-9\\u00a1-\\uffff]" +
      "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
      ")?" +
      "[a-z0-9\\u00a1-\\uffff]\\." +
      ")+" +
      // TLD identifier name, may end with dot
      "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
      ")" +
      // port number (optional)
      "(?::\\d{2,5})?" +
      // resource path (optional)
      "(?:[/?#]\\S*)?" +
      (exact ? "$" : ")"),
    exact ? "i" : "gi"
  );
}

const LINK_EXP = getURLRegex(true);
const SPLIT_EXP = getURLRegex();

function Linkify({ message }: LinkifyProps) {
  if (!message) {
    return null;
  }

  return (
    <>
      {message.split(SPLIT_EXP).map((part, index) => (
        <Fragment key={index}>
          {part.match(LINK_EXP) ? (
            <a
              className="break-all font-semibold underline"
              href={part}
              onFocus={(e) => {
                e.stopPropagation();
              }}
              target="_blank"
              rel="noreferrer"
            >
              {part}
            </a>
          ) : (
            <span className="break-words">{part}</span>
          )}
        </Fragment>
      ))}
    </>
  );
}

export default Linkify;
