import * as yup from "yup";
import { ContactTag } from "@hilos/types/private-schema";
import { ERROR_MESSAGES } from "src/constants/Form";
import { getTranslationPayload as t } from "src/i18n";

export const getInitialValues = (): ContactTag => {
  return {
    id: 0,
    name: "",
  };
};

export const FIELDS = {
  name: {
    key: "name",
    label: t("name", "Name"),
    optional: false,
  },
};

export const schema = yup.object().shape({
  name: yup.string().required(ERROR_MESSAGES.required),
  id: yup.string(),
});
