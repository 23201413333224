import * as yup from "yup";
import {
  ChannelProviderEnum,
  SimpleChannel,
} from "@hilos/types/private-schema";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import dialog360Logo from "src/assets/img/channels/360Dialog.png";
import cloudAPILogo from "src/assets/img/channels/MetaCloudAPI.png";
import { ERROR_MESSAGES } from "src/constants/Form";
import { getTranslationPayload as t } from "src/i18n";
import { ChannelData, ChannelProvider } from "src/types/channel";
import { schema as Dialog360Schema } from "./Dialog360SettingsMeta";
import { schema as CloudAPISchema } from "./MetaCloudAPISettingsMeta";

export const AVAILABLE_CHANNELS = [
  {
    name: t("channel:tech-provider-cloud-api.name", "WhatsApp"),
    type: "WhatsApp",
    icon: WhatsAppIcon,
    baseColor: "green",
    provider: "TECH_PROVIDER_CLOUD_API",
    provider_link:
      "https://developers.facebook.com/docs/whatsapp/cloud-api/get-started",
    logo: cloudAPILogo,
    description: t(
      "channel:tech-provider-cloud-api.description",
      "Set up a new WhatsApp number or migrate your current WhatsApp Business number."
    ),
    default: true,
    advanced: false,
    feature_flag: null,
    // Only leaving active the main Cloud API one, since this one will use those
    // screens too
    active: true,
    settings_link: "/config/channel/cloud-api/create",
    href: "/config/channel/cloud-api/onboarding",
  },
  {
    name: t("channel:cloud-api.name", "Advanced: WhatsApp via Cloud API"),
    type: "WhatsApp",
    icon: WhatsAppIcon,
    baseColor: "blue",
    provider: "META_CLOUD_API",
    provider_link:
      "https://developers.facebook.com/docs/whatsapp/cloud-api/get-started",
    logo: cloudAPILogo,
    description: t(
      "channel:hilos-cloud-api.description",
      "For advanced users that already are connected via their own Cloud API integration."
    ),
    default: false,
    advanced: true,
    feature_flag: null,
    active: true,
    settings_link: "/config/channel/cloud-api/create",
    href: "/config/channel/cloud-api/create",
  },
  {
    name: t("channel:360-dialog.name", "WhatsApp via 360Dialog"),
    type: "WhatsApp",
    icon: WhatsAppIcon,
    provider: "360DIALOG",
    provider_link: "https://www.360dialog.com/",
    logo: dialog360Logo,
    description: t(
      "channel:360-dialog.description",
      "Migrate a phone number you're already using with the WhatsApp Business API to Hilos via 360Dialog."
    ),
    default: false,
    advanced: true,
    feature_flag: "dialog360",
    active: true, // Change this to false when we're only using Tech Provider
    href: "/config/channel/360-dialog/create",
  },
  {
    name: t(
      "channel:360-dialog-cloud-api.name",
      "WhatsApp via 360Dialog Cloud API"
    ),
    type: "WhatsApp",
    icon: WhatsAppIcon,
    provider: "D360_CLOUD_API",
    provider_link: "https://www.360dialog.com/",
    logo: dialog360Logo,
    description: t(
      "channel:360-dialog.description",
      "Migrate a phone number you're already using with the WhatsApp Business API to Hilos via 360Dialog."
    ),
    status: t("channel:channels-status.live", "LIVE"),
    feature_flag: "dialog360",
    active: false,
    href: "/config/channel/360-dialog/create",
  },
];

export const getAvailableChannelData = (
  channel: ChannelData | SimpleChannel
) => {
  return AVAILABLE_CHANNELS.find(
    (av) => av.provider === channel.channel_provider
  );
};

export const getInitialValues = (channelType, channelProvider) => {
  return {
    name: "Default",
    channel_id: "",
    is_default: false,
    channel_type: channelType,
    channel_provider: channelProvider,
  };
};

export const FIELDS = {
  name: {
    key: "name",
    label: t(
      "channel:fields.name.label",
      "Short name to identify this channel."
    ),
    help: t("channel:fields.name.help", 'For example: "Mexico", "Sales", etc.'),
  },
  is_default: {
    key: "is_default",
    label: t(
      "channel:fields.is_default.label",
      "Use this channel as account default?"
    ),
    help: t(
      "channel:fields.is_default.help",
      "If a contact doesn't have a default channel, we'll fallback to using this one to send messages to them."
    ),
  },
  channel_id: {
    key: "channel_id",
    label: t(
      "channel:fields.channel_id.label",
      "Phone number to use with WhatsApp"
    ),
    placeholder: "+52...",
    help: t(
      "channel:fields.channel_id.help",
      'Enter the number including the " + " and country code.'
    ),
  },
};

export const PROXY_FIELDS = {
  proxy_requests: {
    key: "proxy_requests",
    label: t(
      "channel:fields.proxy_requests.label",
      "Proxy requests from WhatsApp?"
    ),
    help: t(
      "channel:fields.proxy_requests.help",
      "We can proxy all requests we get from WhatsApp to any endpoint. Set this to true if you have another system you'd like to receive WhatsApp's webhooks."
    ),
  },
  proxy_url: {
    key: "proxy_url",
    label: t(
      "channel:fields.proxy_url.label",
      "Webhook endpoint URL to proxy to"
    ),
    placeholder: "https://mycrm.com/webhook/whatsapp",
    help: (
      <>
        <p className="mt-1.5">
          We'll pass along the exact data and headers of WhatsApp's original
          request. Be sure to return{" "}
          <code>
            <span className="font-medium text-indigo-500">HTTP 200</span>
          </code>{" "}
          so that every webhook event is marked as received.
        </p>
        <p className="mt-2">
          You can check the details of these webhooks events in{" "}
          <a
            href="https://developers.facebook.com/docs/whatsapp/cloud-api/webhooks/components"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            WhatsApp's documentation
          </a>
          .
        </p>
      </>
    ),
  },
};

export const getSchema = (
  channel_provider: ChannelProviderEnum | undefined
) => {
  let providerSpecificSchema = yup.object();
  switch (channel_provider) {
    case ChannelProvider.META_CLOUD_API:
      providerSpecificSchema = CloudAPISchema;
      break;
    case ChannelProvider.DIALOG360:
      providerSpecificSchema = Dialog360Schema;
      break;
    case ChannelProvider.D360_CLOUD_API:
      providerSpecificSchema = Dialog360Schema;
      break;
    default:
      providerSpecificSchema = yup.object();
  }

  return yup.object().shape({
    name: yup.string().required(ERROR_MESSAGES.required),
    channel_id: yup.string().required(ERROR_MESSAGES.required),
    is_default: yup.bool(),
    settings: providerSpecificSchema,
    proxy_requests: yup.bool(),
    proxy_url: yup.string().when("proxy_requests", {
      is: true,
      then: yup
        .string()
        .url(ERROR_MESSAGES.url)
        .required(ERROR_MESSAGES.required),
      otherwise: yup.string().url(ERROR_MESSAGES.url),
    }),
  });
};
