import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon, RefreshIcon, StopIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { ExternalAppSyncStatus, VesselApp } from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

interface VesselSyncButtonProps {
  connectedApp?: VesselApp;
  setLoading: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}

export default function VesselSyncButton({
  connectedApp,
  setLoading,
  disabled,
}: VesselSyncButtonProps) {
  const { t } = useTranslation();
  const status: ExternalAppSyncStatus =
    connectedApp?.contact_initial_sync_status || "NOT_SYNCED";

  async function updateSyncStatus(status: ExternalAppSyncStatus) {
    if (disabled) {
      return;
    }
    setLoading(true);
    try {
      const next_page_contact_sync =
        connectedApp?.contact_initial_sync_next_page_contact_sync ?? "";
      const responseHubspotSync = await axios.post(
        buildAPIRoute(API_ROUTES.VESSEL_CONTACT_SYNC, {
          ":id": connectedApp?.id,
        }),
        {
          status,
          initial_sync_next_page_contact_sync: next_page_contact_sync,
        }
      );
      const responseHubspotSyncData: {
        status: ExternalAppSyncStatus;
        initial_sync_next_page_contact_sync?: string;
      } = responseHubspotSync.data;
      const updatedProviderFields = {
        ...connectedApp,
        contact_initial_sync_status: responseHubspotSyncData.status,
        contact_initial_sync_next_page_contact_sync:
          responseHubspotSyncData.initial_sync_next_page_contact_sync,
      };
      queryClient.setQueryData(["external_app", connectedApp?.id], {
        ...connectedApp,
        contact_initial_sync_status: responseHubspotSyncData.status,
        contact_initial_sync_next_page_contact_sync:
          responseHubspotSyncData.initial_sync_next_page_contact_sync,
        provider_specific_fields: updatedProviderFields,
      });
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  }

  switch (status) {
    case "NOT_SYNCED":
    case "SYNC_PAUSED":
      return (
        <div className="flex justify-center">
          <div
            onClick={() => updateSyncStatus("SYNC_QUEUED")}
            className={`focus:ring-offset- inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 ${
              disabled
                ? "opacity-50 hover:cursor-not-allowed"
                : "hover:cursor-pointer"
            }`}
          >
            <RefreshIcon
              className="h-5 w-5 md:-ml-1 md:mr-2"
              aria-hidden="true"
            />
            <span className="hidden md:block">{t("sync", "Sync")}</span>
          </div>
        </div>
      );
    case "SYNCYNG":
    case "SYNC_QUEUED":
      return (
        <div className="flex justify-center">
          <div
            onClick={() => updateSyncStatus("SYNC_PAUSED")}
            className={`focus:ring-offset- inline-flex items-center rounded-md border border-transparent bg-orange-400 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 ${
              disabled
                ? "opacity-50 hover:cursor-not-allowed"
                : "hover:cursor-pointer"
            }`}
          >
            <StopIcon className="h-5 w-5 md:-ml-1 md:mr-2" aria-hidden="true" />
            <span className="hidden md:block">
              {t("pause-sync", "Pause sync")}
            </span>
          </div>
        </div>
      );
    case "SYNCED":
      return (
        <div className="flex justify-center">
          <div
            className={`inline-flex items-center rounded-full bg-green-600 px-6 py-3 text-sm font-medium text-white ${
              disabled && "opacity-50 hover:cursor-not-allowed"
            }`}
          >
            <CheckIcon
              className="h-5 w-5 md:-ml-1 md:mr-2"
              aria-hidden="true"
            />
            <span className="hidden md:block">{t("synced", "Synced")}</span>
          </div>
        </div>
      );
  }
}
