// @flow
import { FilterIcon, UploadIcon } from "@heroicons/react/outline";
import * as yup from "yup";
import { FlowExecutionSimple } from "@hilos/types/flows/FlowExecution";
import {
  FlowDetailRead,
  FlowExecutionReadDetail,
} from "@hilos/types/private-schema";
import { getTranslationPayload as t } from "src/i18n";
import { ERROR_MESSAGES } from "../../../constants/Form";
import * as comparisonMeta from "../ComparisonMeta";

export const getInitialValues = (flow: FlowDetailRead) => {
  return {
    start_on: "",
    execute_for: undefined,
    contact_filters: [],
    contact_list: [],
    flow_execution_variables: Object.fromEntries(
      flow.flow_execution_variables?.map((variable) => [variable, ""]) ?? []
    ),
    source: "FRONTEND",
  } as Partial<FlowExecutionReadDetail>;
};

/**
 * This function is used in the `FlowExecutionUpdate.tsx` to handle when there
 * are new `flow_execution_variables` added to the `flow` so they are also added
 * to the `flowExecution` and the inputs appear and are required when they try
 * to update the `flowExecution`
 * @param flowExecution
 * @returns FlowExecutionReadDetail
 */
export const updateFlowExecutionVariables = (
  flowExecution: FlowExecutionReadDetail
): FlowExecutionReadDetail => {
  return {
    ...flowExecution,
    flow_execution_variables: {
      ...Object.fromEntries(
        flowExecution.flow.flow_execution_variables?.map((variable) => [
          variable,
          "",
        ]) ?? []
      ),
      ...(flowExecution.flow_execution_variables ?? {}),
    },
  };
};

export const getContactListInitialValues = () => {
  return {
    source: "",
    contact_list: [comparisonMeta.getInitialValues()],
    contact_file: {
      upload_group_id: "",
    },
  };
};

const contactListSchema = yup.object().shape({
  source: yup.string().required(ERROR_MESSAGES.required),
  contact_list: yup.mixed().when("source", {
    is: "LIST",
    then: yup.array().min(1, ERROR_MESSAGES.minNumber),
  }),
  contact_file: yup.mixed().when("source", {
    is: "FILE",
    then: yup.object().shape({
      upload_group_id: yup.string().required(ERROR_MESSAGES.required),
    }),
  }),
});

function getSchemaFlowExecutionVariables(flow_execution: FlowExecutionSimple) {
  if (flow_execution.flow_execution_variables) {
    return yup
      .object()
      .shape(
        Object.fromEntries(
          Object.keys(flow_execution.flow_execution_variables).map(
            (variableKey) => [variableKey, yup.string().required()]
          )
        )
      );
  }
  return yup.mixed().notRequired();
}

export const schema = yup.lazy((values: FlowExecutionSimple) => {
  return yup.object().shape({
    start_on: yup.date().min(Date(), ERROR_MESSAGES.minDate).nullable(),
    flow_execution_variables: getSchemaFlowExecutionVariables(values),
    execute_for: yup.string().required(ERROR_MESSAGES.required),
    contact_filters: yup.mixed().when("execution_type", {
      is: "OUTBOUND",
      then: yup.array().of(comparisonMeta.schema),
    }),
    contact_lists: yup.mixed().when("execution_type", {
      is: "OUTBOUND",
      then: yup.array().of(contactListSchema),
    }),
    has_priority: yup.bool(),
  });
});

export const FIELDS = {
  start_on: {
    key: "start_on",
    label: t("flow-executions:input.start_on.label", "Start on"),
    optional: true,
    placeholder: "",
    help: t(
      "flow-executions:input.start_on.help",
      "Schedule this flow to run on this date, if the flow is ready to run."
    ),
  },
  flow_execution_variables: {
    key: "flow_execution_variables",
    label: t(
      "flow-executions:input.flow_execution_variables.label",
      "Execution variables"
    ),
    help: t(
      "flow-executions:input.flow_execution_variables.help",
      "This variables were declared when the flow was created and they are required for it to function correctly"
    ),
  },
  execute_for: {
    key: "execute_for",
    label: t(
      "flow-executions:input.execute_for.label",
      "Who should this flow run with?"
    ),
    help: t(
      "flow-executions:input.execute_for.help",
      "Choose between uploading a .csv or filtering existing contacts."
    ),
  },
  contact_filters: {
    key: "contact_filters",
    label: t("flow-executions:input.contact_filters.label", "Contact filters"),
  },
  contact_list: {
    key: "contact_list",
    label: t("flow-executions:input.contact_list.label", "Contact lists"),
  },
  contact_list_file: {
    key: "contact_list_file",
    label: t("flow-executions:input.contact_list_file.label", "Contact lists"),
  },
  has_priority: {
    key: "has_priority",
    label: t(
      "flow-executions:input.has_priority.label",
      "Run flow even if there are open conversations?"
    ),
    help: t(
      "flow-executions:input.has_priority.help",
      "If set, this flow will automatically close any open conversations for all contacts in this execution."
    ),
  },
};

export const FLOW_EXECUTION_FOR = [
  {
    label: t(
      "flow-executions:flow-execution-for.filter.label",
      "From existing contacts"
    ),
    value: "FILTERS",
    icon: <FilterIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:input.flow-execution-for.filter.help",
      "Filter from your contacts and save this segmentation."
    ),
  },
  {
    label: t("flow-executions:flow-execution-for.upload.label", "Upload .csv"),
    value: "LIST",
    icon: <UploadIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:input.flow-execution-for.upload.help",
      "Upload a file with contacts, and run this flow for them."
    ),
  },
];
