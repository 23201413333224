import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { WhatsAppMessage } from "@hilos/types/private-schema";
import ComboboxField from "src/components/Form/ComboboxField";
import SwitchField from "src/components/Form/SwitchField";
import { formatPhone, userToString } from "src/Helpers";
import { ERROR_MESSAGES } from "src/constants/Form";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { contactFormatOptionLabel } from "../NewConversationForm";

interface ForwardMessageFormProps {
  setShow: (x: boolean) => void;
  message: WhatsAppMessage;
}

const initialValues = {
  phone: "",
  navigate_after_send: true,
};

const schema = yup.object().shape({
  phone: yup
    .string()
    .test(
      "is-valid-phone-number",
      ERROR_MESSAGES.validPhoneNumber,
      (value, context) => (value && isPossiblePhoneNumber(value)) || false
    ),
  navigate_after_send: yup.boolean(),
});

function ForwardMessageForm({ setShow, message }: ForwardMessageFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const { data } = await axios.post(
          buildAPIRoute(API_ROUTES.CONVERSATION_MESSAGE_FORWARD, {
            ":id": message.id,
          }),
          formData
        );

        if (data && data.inbox_contact) {
          setShow(false);
          if (formData.navigate_after_send) {
            navigate(
              "/inbox/conversation/:id".replace(":id", data.inbox_contact)
            );
          }
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    [setShow, message.id, navigate]
  );

  // TODO: Add debounce to search phone number
  const handleSearchPhoneNumber = useCallback(async (search: string) => {
    try {
      const { data } = await axios.get(API_ROUTES.CONTACT_CREATE_LIST, {
        params: { page_size: 5, search },
      });
      if (data && data.results) {
        return data.results.map((c) => ({
          label: `${userToString(c)} - ${formatPhone(c.canonical_phone)}`,
          value: c.canonical_phone,
        }));
      }
    } catch {}
    return [];
  }, []);

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit} className="">
          <div className="space-y-6 pt-6 pb-5">
            <ComboboxField
              name="phone"
              loadOptions={handleSearchPhoneNumber}
              placeholder={t("search", "Search")}
              label={t(
                "inbox:new-conversation-form.phone.label",
                "Phone or contact"
              )}
              help={t(
                "inbox:new-conversation-form.phone.help",
                "Search for phone numbers or contact names."
              )}
              formatOptionLabel={contactFormatOptionLabel}
            />

            <SwitchField
              name="navigate_after_send"
              label={t(
                "inbox:navigate-after-forward",
                "Go to conversation after sending?"
              )}
            />
          </div>

          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {formik.isSubmitting ? (
              <>
                <svg
                  className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {t("sending", "Sending...")}
              </>
            ) : (
              <div className="inline-flex items-center">
                {t("inbox:forward-message", "Forward")}
                <ArrowSmRightIcon
                  className="ml-1 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              </div>
            )}
          </button>
        </form>
      )}
    </Formik>
  );
}

export default ForwardMessageForm;
