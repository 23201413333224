import { EdgeProps, getSmoothStepPath } from "reactflow";
import { classNames } from "src/Helpers";

function StepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  selected,
  source,
}: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <g
      id={id}
      data-type="step_edge"
      className={classNames(
        "group block",
        !selected && "opacity-60 group-hover:opacity-100"
      )}
    >
      <path
        id={id}
        fill="none"
        style={style}
        className={classNames(
          "stroke-2",
          selected
            ? "stroke-indigo-600"
            : "stroke-gray-400 group-hover:stroke-indigo-600"
        )}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        fill="none"
        className="stroke-transparent stroke-[40px]"
        d={edgePath}
        markerEnd={markerEnd}
      />
    </g>
  );
}

export default StepEdge;
