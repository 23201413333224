import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/outline";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";

export default function AccountSubscriptionBillingEmailNotice() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  if (!session) {
    return null;
  }

  return (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">
            {t(
              "settings:billing-email-notice",
              "Sending every payment reminder and invoices emails to {{billing_email}}. ",
              {
                billing_email: session.account.billing_email,
              }
            )}
          </p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <Link
              to={buildRoute("config-account-basic")}
              className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
            >
              {t("settings:billing-email-update", "Update it here")}
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
