import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupBase } from "react-select";
import {
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { FormikProps } from "formik";
import HelpDocsLink from "src/components/HelpDocsLink";
import chatBackground from "src/assets/img/Chat_Background.png";
import SelectorField from "../../components/Form/SelectorField";
import TextAreaField from "../../components/Form/TextAreaField";
import TextInputField from "../../components/Form/TextInputField";
import { getComponentIdx } from "../../helpers/template";
import WhatsAppTemplateFormButtonsComponent from "./WhatsAppTemplateFormButtonsComponent";
import WhatsAppTemplateFormMissingFieldsNotice from "./WhatsAppTemplateFormMissingFieldsNotice";
import * as meta from "./WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "./WhatsAppTemplatePreview";
import { initialTemplatePreviewValues } from "./WhatsAppTemplatePreviewForm";

interface WhatsAppTemplateFormStepContentProps {
  formik: FormikProps<any>;
  setCurrentStep: (step: string) => void;
}

export default function WhatsAppTemplateFormStepContent({
  formik,
  setCurrentStep,
}: WhatsAppTemplateFormStepContentProps) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialTemplatePreviewValues);
  const [bodyVarsCount, setBodyVarsCount] = useState(0);
  const [headerVarsCount, setHeaderVarsCount] = useState(0);
  const [buttonVarsCount, setButtonVarsCount] = useState([0, 0]);

  const formatOptionLabel = ({
    value,
    label,
    icon,
    help,
  }: {
    value: string;
    label: string;
    icon: JSX.Element;
    help: string;
  }): JSX.Element => {
    return (
      <div className="flex items-center py-3">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{t(label, label)}</h4>
          {help && <p className="mb-0 text-xs">{t(help, help)}</p>}
        </div>
      </div>
    );
  };

  const addVariableButton = (
    field: string,
    count: number,
    max: number,
    btnIdx?: number
  ): JSX.Element => {
    const isDisabled: boolean = count >= max;
    return (
      <div className="flex w-full justify-end">
        <button
          className={`ml-auto inline-flex items-center justify-end rounded-md border border-neutral py-1 px-3 text-xs text-neutral ${
            isDisabled ? "opacity-50" : null
          }`}
          type="button"
          disabled={isDisabled}
          onClick={() => addVariable(field, btnIdx)}
        >
          {t("add-variable", "Add variable")}
          <PlusIcon className="ml-2 h-3 w-3 text-neutral" aria-hidden="true" />
        </button>
      </div>
    );
  };

  const addVariable = (field: string, btnIdx?: number): void => {
    switch (field) {
      case "body":
        formik.setFieldValue(
          `components.${getComponentIdx("BODY", formik)}.${
            meta.FIELDS.body.key
          }`,
          formik.values.components[getComponentIdx("BODY", formik)].text +
            `{{${bodyVarsCount + 1}}}`
        );
        setBodyVarsCount(bodyVarsCount + 1);
        break;
      case "header":
        formik.setFieldValue(
          `components.${getComponentIdx("HEADER", formik)}.${
            meta.FIELDS.header.text.key
          }`,
          formik.values.components[getComponentIdx("HEADER", formik)].text +
            `{{${headerVarsCount + 1}}}`
        );
        setHeaderVarsCount(headerVarsCount + 1);
        break;
      case "button":
        if (btnIdx === undefined) return;
        formik.setFieldValue(
          `components.${getComponentIdx("BUTTONS", formik)}.buttons.${btnIdx}.${
            meta.BUTTON_FIELDS.url.key
          }`,
          formik.values.components[getComponentIdx("BUTTONS", formik)].buttons[
            btnIdx
          ].url + `{{${buttonVarsCount[btnIdx] + 1}}}`
        );
        const btnVarsCount = [...buttonVarsCount];
        btnVarsCount[btnIdx] = buttonVarsCount[btnIdx] + 1;
        setButtonVarsCount(btnVarsCount);
        break;

      default:
        break;
    }
  };

  /**
    Removes symbols and emojis that aren't allowed
  */
  function cleanValue(value: string) {
    return value
      .replace(/[^\p{L}\p{N}\p{P}\p{Z}{\$´}=]/gu, "")
      .replace(/\*/g, "");
  }

  /**
    Removing examples if user delete variable symbols {{}}
  */
  function onChangeVariableCount(field: string, index?: number) {
    switch (field) {
      case "header":
        formik.setFieldValue(
          `components.${getComponentIdx("HEADER", formik)}.example`,
          {}
        );
        break;
      case "body":
        formik.setFieldValue(
          `components.${getComponentIdx("BODY", formik)}.example`,
          {}
        );
        break;
      case "button":
        formik.setFieldValue(
          `components.${getComponentIdx(
            "BUTTONS",
            formik
          )}.buttons.${index}.example`,
          []
        );
        break;

      default:
        break;
    }
  }

  const onChangeInputValidate = (
    field: string,
    e: FormEvent<HTMLTextAreaElement> | FormEvent<HTMLInputElement>,
    formikFieldName: string,
    index?: number
  ): void => {
    switch (field) {
      case "header": {
        let value = cleanValue(e.currentTarget.value);
        const matchCount = value.match(/{{([0-9])}}/g)?.length;
        if (matchCount) {
          value = handleVariables(value, matchCount, 1);
          setHeaderVarsCount(1);
        } else {
          setHeaderVarsCount(0);
          onChangeVariableCount("header");
        }
        formik.setFieldValue(formikFieldName, value);
        break;
      }
      case "body": {
        let value = e.currentTarget.value;
        const matchCount = value.match(/{{([0-9])}}/g)?.length;
        if (matchCount) {
          value = handleVariables(value, matchCount, 99);
        }
        if (matchCount !== bodyVarsCount) {
          onChangeVariableCount("body");
        }
        formik.setFieldValue(formikFieldName, value);
        break;
      }
      case "button": {
        let value = cleanValue(e.currentTarget.value);
        formik.setFieldValue(formikFieldName, value);
        break;
      }
      case "button_url": {
        let value = cleanValue(e.currentTarget.value);
        const matchCount = value.match(/{{([0-9])}}/g)?.length;
        if (!matchCount && index !== undefined) {
          const btnVarsCount = [...buttonVarsCount];
          btnVarsCount[index] = 0;
          setButtonVarsCount(btnVarsCount);
          onChangeVariableCount("button", index);
        }
        formik.setFieldValue(formikFieldName, value);
        break;
      }
      case "footer": {
        let value = cleanValue(e.currentTarget.value);
        formik.setFieldValue(formikFieldName, value);
        break;
      }
      default:
        break;
    }
  };

  const handleVariables = (
    val: string,
    varsCount: number,
    maxCount: number
  ): string => {
    for (var i = 0; i < varsCount; i++) {
      let index = i;
      let matchIdx = 0;
      val = val.replace(/{{([0-9])}}/g, (match) =>
        matchIdx < maxCount
          ? matchIdx++ === index
            ? `{{${index + 1}}}`
            : match
          : ""
      );
    }
    return val;
  };

  const handleHeaderFormatChange = (format: string) => {
    formik.setFieldValue(
      `components.${getComponentIdx("HEADER", formik)}.${
        meta.FIELDS.header.text.key
      }`,
      ""
    );
    formik.setFieldValue(
      `components.${getComponentIdx("HEADER", formik)}.example`,
      {}
    );
    formik.setFieldValue(
      `components.${getComponentIdx("HEADER", formik)}.${
        meta.FIELDS.header.format.key
      }`,
      format
    );
  };

  return (
    <div>
      <div className="row mt-10 text-center">
        <div className="mx-auto max-w-2xl text-left">
          <WhatsAppTemplateFormMissingFieldsNotice formik={formik} />
        </div>
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          {t("step-n-of-n", "Step {{step}} of {{total}}", {
            step: 2,
            total: 3,
          })}
        </h6>
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          {t("templates:template-content.title", "Template content")}
        </h1>
        <p className="mb-5 text-sm text-gray-500">
          {t(
            "templates:template-content.description",
            "Select the content you want to include in this template."
          )}
        </p>
      </div>
      <div className="mt-10 grid-rows-1 gap-6 md:grid md:grid-cols-5">
        <div className=" md:col-span-3">
          {/* Header */}
          <div className="bg-white p-5 shadow sm:rounded-md">
            <div className="flex flex-row justify-between">
              <div className="flex">
                {t("header", "Header")}{" "}
                <span className="ml-4 inline-flex items-center justify-center rounded-full bg-neutral-extralight px-2 py-1 text-xs font-semibold leading-none text-neutral">
                  {t("optional", "Optional")}
                </span>
              </div>
              <HelpDocsLink href="https://hilos.io/docs/user/using-hilos/templates/creating-a-template" />
            </div>
            <p className="mt-2 mb-4 text-sm text-gray-400">
              {t(
                "templates:template-content.header.description",
                "Add a title or choose which type of media you'll use for this header."
              )}
            </p>
            <SelectorField<meta.SELECT_OPTION, GroupBase<meta.SELECT_OPTION>>
              label={t(
                meta.FIELDS.header.format.label,
                meta.FIELDS.header.format.label
              )}
              name={`components.${getComponentIdx("HEADER", formik)}.${
                meta.FIELDS.header.format.key
              }`}
              options={meta.FORMAT_TYPES}
              // @ts-ignore
              formatOptionLabel={formatOptionLabel}
              onSelect={(option) => handleHeaderFormatChange(option.value)}
            />
            {formik.values.components[getComponentIdx("HEADER", formik)]
              .format === "TEXT" && (
              <>
                <TextInputField
                  label={t(
                    meta.FIELDS.header.text.label,
                    meta.FIELDS.header.text.label
                  )}
                  name={`components.${getComponentIdx("HEADER", formik)}.${
                    meta.FIELDS.header.text.key
                  }`}
                  help={t(meta.FIELDS.header.text.help)}
                  type="text"
                  maxLength={60}
                  onChange={(e) =>
                    onChangeInputValidate(
                      "header",
                      e,
                      `components.${getComponentIdx("HEADER", formik)}.${
                        meta.FIELDS.header.text.key
                      }`
                    )
                  }
                />
                {addVariableButton("header", headerVarsCount, 1)}
              </>
            )}
          </div>
          {/* Body */}
          <div className="mt-5 bg-white p-5 shadow sm:rounded-md">
            <div className="flex flex-row justify-between">
              <div className="flex">{t("body", "Body")}</div>
              <HelpDocsLink href="https://hilos.io/docs/user/using-hilos/templates/creating-a-template" />
            </div>
            <p className="mt-2 mb-4 text-sm text-gray-400">
              {t(
                "templates:template-content.body.description",
                "Enter the text for your message in the language you've selected."
              )}
            </p>
            <TextAreaField
              label={t(meta.FIELDS.body.label, meta.FIELDS.body.label)}
              name={`components.${getComponentIdx("BODY", formik)}.${
                meta.FIELDS.body.key
              }`}
              help={t(meta.FIELDS.body.help)}
              rows={4}
              maxLength={1024}
              onChange={(e) =>
                onChangeInputValidate(
                  "body",
                  e,
                  `components.${getComponentIdx("BODY", formik)}.${
                    meta.FIELDS.body.key
                  }`
                )
              }
            />
            {addVariableButton("body", bodyVarsCount, 99)}
          </div>
          {/* Footer */}
          <div className="mt-5 bg-white p-5 shadow sm:rounded-md">
            <div className="flex flex-row justify-between">
              <div className="flex">
                {t("footer", "Footer")}{" "}
                <span className="ml-4 inline-flex items-center justify-center rounded-full bg-neutral-extralight px-2 py-1 text-xs font-semibold leading-none text-neutral">
                  {t("optional", "Optional")}
                </span>
              </div>
              <HelpDocsLink href="https://hilos.io/docs/user/using-hilos/templates/creating-a-template" />
            </div>
            <p className="mt-2 mb-4 text-sm text-gray-400">
              {t(
                "templates:template-content.footer.description",
                "Add a short line of text to the bottom of your message template."
              )}
            </p>
            <TextAreaField
              label={t(meta.FIELDS.footer.label, meta.FIELDS.footer.label)}
              name={`components.${getComponentIdx("FOOTER", formik)}.${
                meta.FIELDS.footer.key
              }`}
              rows={2}
              maxLength={60}
              onChange={(e) =>
                onChangeInputValidate(
                  "footer",
                  e,
                  `components.${getComponentIdx("FOOTER", formik)}.${
                    meta.FIELDS.footer.key
                  }`
                )
              }
            />
            {/* Add Variable BTN */}
          </div>
          {/* Buttons */}
          <WhatsAppTemplateFormButtonsComponent
            formik={formik}
            onChangeInputValidate={onChangeInputValidate}
            addVariableButton={addVariableButton}
            buttonVarsCount={buttonVarsCount}
          />
        </div>
        <div
          className="mb-10 p-5 shadow sm:rounded-md md:col-span-2"
          style={{
            backgroundImage: `url(${chatBackground})`,
          }}
        >
          <h6 className="mb-2 text-tiny uppercase tracking-wider text-gray-500">
            {t("templates:preview", "Template preview")}
          </h6>
          <WhatsAppTemplatePreview
            template={formik.values}
            values={formValues}
          />
        </div>
      </div>
      <div className="mx-auto my-14 max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <WhatsAppTemplateFormMissingFieldsNotice formik={formik} />
          <hr className="my-5" />
          <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
            <div className="justify-self-start">
              <button
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-medium leading-4 text-gray-900 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-500"
                type="button"
                onClick={(_) => setCurrentStep("attrs")}
              >
                <ArrowSmLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {t("details", "Details")}
              </button>
            </div>
            <div className="col text-center">
              <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
                {t("step-n-of-n", "Step {{step}} of {{total}}", {
                  step: 2,
                  total: 3,
                })}
              </h6>
            </div>
            <div className="justify-self-end">
              <button
                className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                type="button"
                onClick={(_) => setCurrentStep("end")}
              >
                {t("examples", "Examples")}
                <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
