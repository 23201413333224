import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ClipboardListIcon, XIcon } from "@heroicons/react/outline";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import { getTemplateVarsFromTemplate } from "./WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "./WhatsAppTemplatePreview";
import WhatsAppTemplatePreviewForm, {
  initialTemplatePreviewValues,
} from "./WhatsAppTemplatePreviewForm";

interface WhatsAppTemplatePreviewModalProps {
  template: WhatsAppTemplate;
  disableFileUpload?: boolean;
}

export default function WhatsAppTemplatePreviewModal({
  template,
  disableFileUpload,
}: WhatsAppTemplatePreviewModalProps) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialTemplatePreviewValues);
  const [open, setOpen] = useState(false);

  const templateHasVars = getTemplateVarsFromTemplate(template).length > 0;

  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ClipboardListIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 flex items-center justify-start"
                    >
                      {t("templates:template-preview", "Template preview")}
                      <span className="px-2 rounded-lg ml-2 text-xs py-1 flex bg-slate-100 text-slate-500">
                        {template.name}
                      </span>
                    </Dialog.Title>
                    <div className="mt-2">
                      {templateHasVars ? (
                        <p className="text-sm text-gray-500">
                          {t(
                            "templates:test-template",
                            "Here you can set some test variables to preview how your template will look like to your customers."
                          )}
                        </p>
                      ) : (
                        <p className="text-sm text-gray-500">
                          {t(
                            "templates:no-template-vars",
                            "This template doesn't have any variables, it will be shown as is to your customers."
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={`mt-8 grid grid-cols-1 gap-6 ${
                    templateHasVars ? "sm:grid-cols-2" : ""
                  }`}
                >
                  {templateHasVars && (
                    <div>
                      <WhatsAppTemplatePreviewForm
                        template={template}
                        onTemplateValuesChange={setFormValues}
                        disableFileUpload={!!disableFileUpload}
                      />
                    </div>
                  )}
                  <div>
                    <WhatsAppTemplatePreview
                      template={template}
                      values={formValues}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <button
        type="button"
        className="text-blue-400 flex"
        onClick={(_) => setOpen(true)}
        id={template.id}
      >
        {template.show_in_sandbox && (
          <span className="px-2 rounded-lg mr-2 text-xs py-1 flex bg-slate-100 text-slate-500">
            Sandbox
          </span>
        )}{" "}
        {template.name}
      </button>
    </>
  );
}
