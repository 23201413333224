import { useTranslation } from "react-i18next";

export default function InvoiceStatusBadge({ status }) {
  const { t } = useTranslation();
  switch (status) {
    case "draft":
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
          {t(status)}
        </span>
      );
    case "paid":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
          {t(status)}
        </span>
      );
    case "void":
      // t("void", "Void")
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
          {t(status)}
        </span>
      );
    case "open":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-800">
          {t(status)}
        </span>
      );
  }
  return null;
}
