import { useTranslation } from "react-i18next";

interface LoadingProps {
  color?: string;
  className?: string;
  showText?: boolean;
}

function Loading({
  color = "text-gray-500",
  showText = true,
  className,
}: LoadingProps) {
  const { t } = useTranslation();
  return (
    <div className="flex h-full w-full" data-tour="loading-window">
      <div className="m-auto flex flex-col items-center">
        <svg
          className={`animate-spin 
            ${className ? className : `-ml-1 mr-3 h-5 w-5 ${color}`}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        {showText && (
          <span className={`mt-1 text-sm ${color}`}>
            {t("loading", "Loading...")}
          </span>
        )}
      </div>
    </div>
  );
}

export default Loading;
