import {
  DocumentTextIcon,
  PhotographIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import { getTranslationPayload as t } from "src/i18n";

export const HEADER_MEDIA_TYPES = {
  IMAGE: {
    icon: PhotographIcon,
    label: t("flows:header-media-types.image.label", "URL for header image"),
    help: t(
      "flows:header-media-types.image.help",
      "Images taller than 1.91:1 aspect ratio are cropped vertically by WhatsApp (like in our preview). To communicate the crux in such images, plan to present the most important information at the center of the image."
    ),
  },
  DOCUMENT: {
    icon: DocumentTextIcon,
    label: t(
      "flows:header-media-types.document.label",
      "URL for header document"
    ),
    help: t(
      "flows:header-media-types.document.help",
      "Make sure to use a direct and public URL to the file you wish to use. Otherwise WhatsApp won't be able to access and use your file to send this template."
    ),
  },
  VIDEO: {
    icon: VideoCameraIcon,
    label: t("flows:header-media-types.video.label", "URL for header video"),
    help: t(
      "flows:header-media-types.video.help",
      "Make sure to use a direct and public URL to the file you wish to use. Otherwise WhatsApp won't be able to access and use your file to send this template."
    ),
  },
};
