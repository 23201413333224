import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getFormattedTextWithVariables,
  getValueWithVariable,
} from "src/helpers/variables";
import { useField, useFormikContext } from "formik";

import { FlowData } from "@hilos/types/flow";
import { HilosVariableData } from "@hilos/types/hilos";
import { throttle } from "lodash";
import useTouchField from "./useTouchField";

export interface UseFieldWithVariablesParams {
  path: string;
  name: string;
  separator?: string;
  handleGetVariable: (value: string, key?: string) => HilosVariableData | null;
}

type InitialValuesStatus = "PENDING" | "LOADING" | "READY";

function useFieldWithVariables({
  path,
  name,
  separator = " ",
  handleGetVariable,
}: UseFieldWithVariablesParams) {
  const initialValuesStatusRef = useRef<InitialValuesStatus>("PENDING");
  const cursorPositionRef = useRef<number | null>(null);
  const [valueWithVariables, setValueWithVariables] = useState("");
  const { setFieldValue, setFieldTouched } = useFormikContext<FlowData>();

  const [field, meta, helpers] = useField({ name: `${path}.${name}` });

  const error = useMemo(
    () => meta.touched && meta.error,
    [meta.touched, meta.error]
  );

  const handleUpdateValue = useMemo(
    () =>
      throttle(
        async (nextValueWithVariables: string) => {
          if (initialValuesStatusRef.current !== "READY") {
            return;
          }

          const nextValue = getFormattedTextWithVariables({
            key: "name",
            name: "id",
            value: nextValueWithVariables,
            handleGetVariable,
          });

          const stepName = `${path}.${name}`;
          setFieldValue(stepName, nextValue);
          setFieldTouched(stepName);
        },
        350,
        { leading: true, trailing: true }
      ),
    [path, name, setFieldValue, setFieldTouched, handleGetVariable]
  );

  const handleAddVariable = useCallback(
    (id: string, isNewField = false) => {
      const variable = handleGetVariable(id);
      if (variable || isNewField) {
        const nextValueWithVariables = getValueWithVariable(
          valueWithVariables,
          variable ? variable.name : id,
          separator,
          cursorPositionRef.current
        );
        setValueWithVariables(nextValueWithVariables);
        handleUpdateValue(nextValueWithVariables);
      }
    },
    [separator, valueWithVariables, handleGetVariable, handleUpdateValue]
  );

  const handleCursorPosition = useCallback((event) => {
    cursorPositionRef.current = event.target.selectionStart;
  }, []);

  const handleChangeValue = useCallback(
    (nextValue: string) => {
      setValueWithVariables(nextValue);
      handleUpdateValue(nextValue);
    },
    [handleUpdateValue]
  );

  const handleChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (event) => {
      setValueWithVariables(event.target.value);
      handleUpdateValue(event.target.value);
    },
    [handleUpdateValue]
  );

  useEffect(() => {
    if (initialValuesStatusRef.current === "PENDING") {
      initialValuesStatusRef.current = "LOADING";

      const nextValue = getFormattedTextWithVariables({
        key: "id",
        name: "name",
        value: field.value,
        handleGetVariable,
      });

      setValueWithVariables(nextValue);

      initialValuesStatusRef.current = "READY";
    }
  }, [path, field.value, handleGetVariable]);

  useTouchField(helpers);

  return {
    field,
    error,
    valueWithVariables,
    handleAddVariable,
    handleChange,
    handleChangeValue,
    handleCursorPosition,
  };
}

export default useFieldWithVariables;
