import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HEADER_MEDIA_TYPES } from "../../../constants/steps/templates";
import FlowBuilderMedia from "../../FlowBuilderMedia";

interface FlowBuilderStepTemplateHeaderProps {
  index: number;
  media: "IMAGE" | "VIDEO" | "DOCUMENT";
}

function FlowBuilderStepTemplateHeader({
  index,
  media,
}: FlowBuilderStepTemplateHeaderProps) {
  const [t] = useTranslation();

  const { icon, label, help } = useMemo(
    () => HEADER_MEDIA_TYPES[media],
    [media]
  );

  return (
    <FlowBuilderMedia
      enableVariables
      key={`step-${index}-template-header`}
      path={`steps.${index}.whatsapp_template_variables`}
      name="headerURL"
      currentStepIndex={index}
      icon={icon}
      label={t(label)}
      help={t(help)}
      media={media}
    />
  );
}

export default FlowBuilderStepTemplateHeader;
