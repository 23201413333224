import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ViewGridIcon } from "@heroicons/react/outline";
import HeaderNavigation from "src/components/HeaderNavigation";
import { buildRoute } from "../../router/router";
import WorkflowBoardData from "./WorkflowBoardData";
import { WorkflowBoardProvider } from "./WorkflowBoardProvider";

export default function WorkflowBoard() {
  const [t] = useTranslation();
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <WorkflowBoardProvider id={id}>
      <div className="flex flex-col h-screen overflow-y-auto bg-gray-50">
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("workflows"),
                url: buildRoute("workflow-list"),
              },
              {
                name: t("workflows:workflow-board", "Workflow board"),
                url: buildRoute("workflow-board", { ":id": id }),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ViewGridIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                {t("workflows:workflow-board", "Workflow board")}
              </h2>
            </div>
          </div>
        </div>
        <WorkflowBoardData id={id} />
      </div>
    </WorkflowBoardProvider>
  );
}
