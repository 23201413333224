import { useCallback } from "react";
import { UseMutateAsyncFunction, useMutation, useQuery } from "react-query";
import axios from "axios";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES } from "src/router/router";
import { HubspotApp, HubspotConfigEdit } from "@hilos/types/private-schema";

export type UpdateHubspotDetailsFn = UseMutateAsyncFunction<
  HubspotConfigEdit | null,
  unknown,
  HubspotConfigEdit,
  unknown
>;

export function useHubspotApp() {
  const fetchHubspotApp = useCallback(async () => {
    const { data } = await axios.get<HubspotApp[]>(API_ROUTES.HUBSPOT_APP);
    const hubspotApp = data?.[0];
    return hubspotApp;
  }, []);

  const { data, refetch } = useQuery("hubspot_app", fetchHubspotApp);

  return { data, refetch };
}

export default useHubspotApp;

export function useHubspotAppUpdateDetails() {
  const updateHubspotAppDetails = useCallback(
    async (payload: HubspotConfigEdit ) => {
      const { data } = await axios.patch<HubspotConfigEdit>(
        API_ROUTES.HUBSPOT_CONFIG,
        payload
      );
      return data;
    },
    []
  );

  const {
    mutateAsync: handleUpdateFlowExecutionStatus,
    isLoading: isSubmittingStatus,
  } = useMutation(updateHubspotAppDetails, {
    onSuccess: (nextData) => {
      let currentAppData = queryClient.getQueryData<HubspotApp>("hubspot_app");
      if (currentAppData) {
        currentAppData = {
          ...currentAppData,
          ...nextData,
        };
      }
      queryClient.setQueryData("hubspot_app", currentAppData);
    },
  });

  return {
    handleUpdateFlowExecutionStatus,
    isSubmittingStatus,
  };
}
