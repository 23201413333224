import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { WhatsAppMessage } from "@hilos/types/private-schema";

interface SystemMessageProps {
  message: WhatsAppMessage;
  isPreview?: boolean;
}

export default function SystemMessage({
  message,
  isPreview,
}: SystemMessageProps) {
  const { t } = useTranslation();
  if (
    message.body?.includes("User A changed from") ||
    message.body?.includes("changed from")
  ) {
    return (
      <div
        className={`flex items-center flex-col ${
          !isPreview
            ? "w-full justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm"
            : "text-gray-500"
        }`}
      >
        <div className="flex items-center">
          <ExclamationCircleIcon className="h-4 w-4 mr-1" />
          {t("number-changed", "WhatsApp number has changed")}:{" "}
          {message.body
            .replace("User A changed from", "")
            .replace("to", t("to", "to"))
            .replace("changed from", t("changed-from", "changed from"))}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex items-center flex-col ${
        !isPreview
          ? "w-full justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm"
          : "text-gray-500"
      }`}
    >
      <div className="flex items-center">
        <ExclamationCircleIcon className="h-4 w-4 mr-1" />
        {message.body}
      </div>
    </div>
  );
}
