import { TFunction } from "i18next";
import * as yup from "yup1";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepBaseSchema } from "../flow";

export const FlowStepConditionalMultipleSchema = (t: TFunction) =>
  FlowStepBaseSchema(t).shape({
    paths: yup
      .array()
      .of(
        yup.object().shape({
          conditions: yup
            .array()
            .of(
              yup.object().shape({
                field: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
                comparison: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
                value: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
              })
            )
            .min(1, t(ERROR_MESSAGES.MIN_NUMBER)),
        })
      )
      .min(1, t(ERROR_MESSAGES.MIN_NUMBER)),
  });
