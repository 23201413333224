import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusSmIcon } from "@heroicons/react/outline";
import { getIn, setIn, useFormikContext } from "formik";
import TextInputField from "src/components/Form/TextInputField";
import KeyValueFieldWithVariables from "./KeyValueFieldWithVariables";

export interface MappedValue {
  key: string;
  value: string;
  options?: { label: string; value: string }[];
}

interface MappedValuesFieldProps {
  path: string;
  name: string;
  currentStepIndex: number | null;
  addButtonLabel: string;
  onChangeValues?: (values: MappedValue[]) => void;
  KeyComponent?: (index: number) => JSX.Element;
  objectName: string;
  fieldsOptions?: { [k: string]: { label: string; value: string }[] };
  customOptionsNoticeMessage?: string;
}

function MappedValuesField({
  path,
  name,
  currentStepIndex,
  addButtonLabel,
  onChangeValues,
  KeyComponent,
  objectName,
  fieldsOptions,
  customOptionsNoticeMessage,
}: MappedValuesFieldProps) {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const [lastUpdate, setLastUpdate] = useState(+new Date());

  const values = useMemo<MappedValue[]>(
    () => getIn(formik.values, `${path}.${name}`) || [],
    [formik.values, path, name]
  );

  const handleAddField = useCallback(() => {
    const nextValues = [...values, { key: "", value: "" }];

    formik.setValues((prevState) =>
      setIn(prevState, `${path}.${name}`, nextValues)
    );
    setLastUpdate(+new Date());
  }, [values, formik, path, name]);

  const handleDeleteField = useCallback(
    (indexToDelete) => {
      const nextValues = values.filter((_, index) => index !== indexToDelete);

      formik.setValues((prevState) =>
        setIn(prevState, `${path}.${name}`, nextValues)
      );
      setLastUpdate(+new Date());
    },
    [values, formik, path, name]
  );

  useEffect(() => {
    if (onChangeValues) {
      onChangeValues(values);
    }
  }, [onChangeValues, values]);

  return (
    <>
      <ul className="mt-2">
        {values.map((value, index) => (
          <KeyValueFieldWithVariables
            index={index}
            objectName={objectName}
            key={`${path}.${name}.${index}.${lastUpdate}`}
            path={path}
            name={`${name}.${index}`}
            currentStepIndex={currentStepIndex}
            variableSeparator=""
            onDelete={() => handleDeleteField(index)}
            customOptions={fieldsOptions?.[value.key]}
            customOptionsNoticeMessage={customOptionsNoticeMessage}
            KeyComponent={
              KeyComponent ? (
                KeyComponent(index)
              ) : (
                <TextInputField
                  type="text"
                  name={`${path}.${name}.${index}.key`}
                  placeholder={t(
                    "components:mapped-values-field.from-key.placeholder",
                    "Key"
                  )}
                />
              )
            }
          />
        ))}
      </ul>
      <div className="mt-2">
        <button
          type="button"
          className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
          onClick={handleAddField}
        >
          <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          {addButtonLabel}
        </button>
      </div>
    </>
  );
}

export default MappedValuesField;
