import { Link } from "react-router-dom";
import { ArrowSmRightIcon, ExclamationIcon } from "@heroicons/react/outline";
import useHilosStore from "src/hooks/useHilosStore";
import { buildRoute } from "src/router/router";

function FreeTrialDaysRemainingNotice() {
  const { session } = useHilosStore();
  if (!session?.account) {
    return null;
  }

  const freeTrialDaysRemaining = session.account.remaining_free_trial_days;

  const linkButton = (
    <Link
      to={buildRoute("config-account-subscription")}
      className="inline-flex w-full justify-center rounded-md border border-red-700 bg-white px-2 py-1.5 text-sm font-medium text-red-700"
    >
      Subscribe
      <ArrowSmRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
    </Link>
  );

  if (freeTrialDaysRemaining > 1) {
    return (
      <div className="bg-yellow-50 p-3">
        <div className="space-y-2">
          <div className="flex justify-center">
            <ExclamationIcon
              className="h-6 w-6 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="flex-1 items-center space-y-1">
            <p className="text-sm font-semibold text-yellow-700">
              Your free trial is almost over.
            </p>
            <p className="text-sm text-yellow-700">
              You have {freeTrialDaysRemaining} more days in your free trial,
              after that you'll need to subscribe to continue using Hilos.
            </p>
          </div>
          <div className="w-full">{linkButton}</div>
        </div>
      </div>
    );
  }

  if (freeTrialDaysRemaining === 1) {
    return (
      <div className="bg-red-50 p-3">
        <div className="space-y-2">
          <div className="flex justify-center">
            <ExclamationIcon
              className="h-6 w-6 text-red-400"
              aria-hidden="true"
            />
          </div>
          <div className="flex-1 items-center space-y-1">
            <p className="text-sm font-semibold text-red-700">
              Tomorrow's your last free trial day.
            </p>
            <p className="text-sm text-red-700">
              Don't forget to suscribe to continue using Hilos.
            </p>
          </div>
          <div className="w-full">{linkButton}</div>
        </div>
      </div>
    );
  }

  if (freeTrialDaysRemaining < 1) {
    return (
      <div className="bg-red-50 p-3">
        <div className="space-y-2">
          <div className="flex justify-center">
            <ExclamationIcon
              className="h-6 w-6 text-red-400"
              aria-hidden="true"
            />
          </div>
          <div className="flex-1 items-center space-y-1">
            <p className="text-sm font-semibold text-red-700">
              Your free trial has ended.
            </p>
            <p className="text-sm text-red-700">
              Suscribe to Hilos to continue using it.
            </p>
          </div>
          <div className="w-full">{linkButton}</div>
        </div>
      </div>
    );
  }
  return null;
}

export default FreeTrialDaysRemainingNotice;
