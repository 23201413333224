import { usePostHog } from 'posthog-js/react';
import { useCallback} from 'react'

import {PosthogEventProps} from '@hilos/types/posthog'

type AnchorTrackedProps<T> = React.HTMLProps<HTMLAnchorElement> & PosthogEventProps<T>

export default function AnchorTracked<T extends Record<string, unknown>>({posthogEvent, posthogPayload, children,...props}: AnchorTrackedProps<T>) {
  const posthog = usePostHog();
  const onClick = useCallback(() => {
    posthog?.capture(posthogEvent, posthogPayload)
  }, [posthog, posthogEvent, posthogPayload])
  return (<a {...props} onClick={onClick}>
      {children}
        </a>)
}