import { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import { hasMultipleNextSteps } from "../utils";
import useFlowBuilderStore from "./useFlowBuilderStore";

interface UseStepNodeProps {
  index?: number | null;
}

function useStepNode({ index = null }: UseStepNodeProps) {
  const { onSetStepToDelete } = useFlowBuilderStore();
  const { values, touched, errors } = useFormikContext<FlowData>();

  const [label, nextStepLabel, allowDeleteStep] = useMemo(() => {
    if (index !== null) {
      const step = values.steps?.[index];
      let nextStepName: string | null = null;

      if (step) {
        if (step.step_type === "GO_TO" && step.next_step_default) {
          const nextStep = values.steps.find(
            (currentStep) => currentStep.id === step.next_step_default
          );
          if (nextStep) {
            nextStepName = nextStep.name ?? null;
          }
        }

        return [step.name ?? null, nextStepName, !hasMultipleNextSteps(step)];
      }
    }
    return [null, null, false];
  }, [values.steps, index]);

  const error = useMemo(() => {
    if (index !== null && touched.steps && errors.steps) {
      return Boolean(touched.steps[index] && errors.steps[index]);
    }
    return null;
  }, [index, touched.steps, errors.steps]);

  const handleDelete = useCallback(() => {
    const currentStep = index !== null && values.steps?.[index];

    if (currentStep && !hasMultipleNextSteps(currentStep)) {
      onSetStepToDelete(currentStep.id);
    }
  }, [index, values.steps, onSetStepToDelete]);

  return {
    label,
    nextStepLabel,
    error,
    allowDeleteStep,
    handleDelete,
  };
}

export default useStepNode;
