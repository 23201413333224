import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  CloudDownloadIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { debounce } from "lodash";
import { WhatsAppMessage } from "@hilos/types/private-schema";
import Loading from "./Loading";

interface MediaImageProps {
  setShow: (show: boolean) => void;
  message: WhatsAppMessage;
  content_url?: string;
}

export default function MediaImage({
  setShow,
  message,
  content_url,
}: MediaImageProps) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [downloading, setDownloading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoad = useCallback(
    debounce(() => {
      setLoading(false);
    }, 600),
    []
  );

  const handleDownload = () => {
    setDownloading(true);
    const hiddenElement = document.createElement("a");
    hiddenElement.href = content_url ?? message.content_url ?? "";
    hiddenElement.download = message.id;
    hiddenElement.click();
    setDownloading(false);
  };

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  const handleRefresh = useCallback(() => {
    setError(false);
    setLoading(true);
  }, []);

  useEffect(() => {
    return () => {
      handleLoad.cancel();
    };
  }, []);

  if (error) {
    return (
      <div
        className="inline-block transform overflow-hidden bg-black bg-opacity-70 text-left align-bottom shadow-xl transition-all max-h-screen   sm:align-middle"
        onClick={handleRefresh}
      >
        <RefreshIcon className="h-8 w-8 cursor-pointer" />
      </div>
    );
  }
  return (
    <div className="w-screen h-screen flex items-center justify-center relative">
      <Transition
        show={showNav}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-40 px-2 right-0 flex top-0 bg-white py-3 w-full">
          <button
            type="button"
            className="mr-auto rounded-md p-2"
            onClick={() => setShow(false)}
          >
            <ChevronLeftIcon className="h-6 w-6 text-gray-700" />
          </button>
          <button
            onClick={handleDownload}
            className="rounded-md hover:font-bold hover:border-2  flex items-center text-xs font-semibold text-hilos border border-hilos p-2"
          >
            <span className="mr-2">{t("download", "Download")}</span>
            {downloading ? (
              <Loading showText={false} className="h-3 p-0 w-3" />
            ) : (
              <CloudDownloadIcon className="h-4 w-4" />
            )}
          </button>
        </div>
      </Transition>
      <div
        onClick={() => setShowNav(!showNav)}
        className="h-screen w-screen relative flex items-center justify-center"
      >
        <>
          <Transition
            show={loading}
            className="align-center absolute z-30 flex h-full w-60 justify-center"
          >
            <Transition.Child
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Loading color="text-white" />
              {t("loading", "Loading...")}
            </Transition.Child>
          </Transition>
          <img
            className="object-contain max-h-screen"
            src={message.content_url ?? ""}
            alt={message.body ?? ""}
            onLoad={handleLoad}
            onError={handleError}
          />{" "}
        </>
      </div>
    </div>
  );
}
