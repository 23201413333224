import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import HelpDocsLink from "src/components/HelpDocsLink";
import { InboxContactViewData } from "src/hooks/useInboxContactViews";
import { AVAILABLE_ORDERING_FIELDS, ConversationsFilter } from "./InboxMeta";
import InboxParamsFiltersForm from "./InboxParamsFiltersForm";
import InboxParamsFormButtons from "./InboxParamsFormButtons";

export interface InboxParamsValues {
  view: InboxContactViewData | null;
  ordering: string | null;
  filters: ConversationsFilter[];
}

export type InboxParamsFormikProps = FormikProps<InboxParamsValues>;

interface InboxParamsFormProps {
  isEditingView?: boolean;
  values: InboxParamsValues;
  onClose: () => void;
  onSubmit: (params: InboxParamsValues) => void;
}

const schema = yup.object().shape({
  ordering: yup.string(),
});

function InboxParamsForm({
  isEditingView,
  values,
  onClose,
  onSubmit,
}: InboxParamsFormProps) {
  const { t } = useTranslation();
  const availableFields = useMemo(
    () =>
      AVAILABLE_ORDERING_FIELDS.map((filter) => ({
        ...filter,
        label: t(filter.label),
      })),
    [t]
  );

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {(formik) => (
        <form
          autoComplete="off"
          noValidate
          onSubmit={formik.handleSubmit}
          className="px-4"
        >
          {isEditingView && (
            <div className="flex flex-col space-y-2 pb-4">
              <TextInputField
                type="text"
                label=""
                maxLength={100}
                name="view.name"
              />
              <SwitchField
                label={t("inbox:inbox-params-form.public-label", "Is public?")}
                help={t(
                  "inbox:inbox-params-form.public-help",
                  "If set, anyone on your team can see and use this view."
                )}
                name="view.is_public"
              />
              <TextInputField
                type="text"
                label={t("description")}
                maxLength={150}
                name="view.description"
              />
            </div>
          )}
          <InboxParamsFiltersForm formik={formik} />

          <div className="flex flex-row justify-between">
            <div className="mt-4 text-sm font-semibold uppercase tracking-wide text-gray-500">
              {t("sort-by", "Sort by")}
            </div>
            <HelpDocsLink
              href="https://hilos.io/docs/user/using-hilos/inbox/finding-a-conversation"
              className="self-end text-xs"
            />
          </div>
          <div className="w-full py-3">
            <SelectorField options={availableFields} name="ordering" />
          </div>

          <hr className="my-4" />

          <div className="flex flex-row flex-wrap items-center justify-between">
            <InboxParamsFormButtons
              isEditingView={isEditingView}
              formik={formik}
              onClose={onClose}
              onSubmit={formik.handleSubmit}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default InboxParamsForm;
