import { ChevronDownIcon } from "@heroicons/react/outline";
import DropdownMenu from "src/components/DropdownMenu";
import { classNames } from "../../../Helpers";

interface ConversationMessageMenuProps {
  isFocused: boolean;
  isOutbound: boolean;
  items: { (active: boolean): void }[];
}

function ConversationMessageMenu({
  isFocused,
  isOutbound,
  items,
}: ConversationMessageMenuProps) {
  return (
    <div className="absolute -top-2 -right-2">
      <DropdownMenu
        width="50"
        icon={ChevronDownIcon}
        buttonClassName={classNames(
          "flex h-10 w-14 items-start justify-end pt-2 pr-2",
          "sm:bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] to-transparent from-45% to-70%",
          "sm:transition-opacity sm:duration-300 sm:opacity-0 group-hover:opacity-100 ui-open:opacity-100",
          isOutbound
            ? `${isFocused ? "from-indigo-700" : "from-indigo-400"} text-white`
            : isFocused
            ? "from-gray-500 text-white"
            : "from-gray-200 text-gray-600"
        )}
        offsetMenu={[-8, -16]}
        menuItems={items}
      />
    </div>
  );
}

export default ConversationMessageMenu;
