import { useTranslation } from "react-i18next";
import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";
import { TimeoutMeta } from "../../Helpers";

interface FlowExecutionStepMenuDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepMenuDetail({
  executionStep,
}: FlowExecutionStepMenuDetailProps) {
  const { t } = useTranslation();
  const maxWaitTimeOption = TimeoutMeta.MAX_WAIT_TIME_OPTIONS.find(
    (at) => at.value === executionStep.step.max_wait_time_unit
  );

  return (
    <div className="space-y-4">
      {executionStep.status === "COMPLETED" && (
        <div>
          <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
            {t(
              "flow-executions:execution-steps-detail.execution-result",
              "Execution result"
            )}
          </h3>

          {(executionStep.validation_failed_attempts || 0) >=
          (executionStep.step.max_answer_attempts || 1) ? (
            <p className="text-red-400">
              {t(
                "flow-executions:execution-steps-detail.menu.max-attempts",
                "Step marked as completed after too many failed attempts ({attempts} out of {allowed} allowed)",
                {
                  attempts: executionStep.validation_failed_attempts,
                  allowed: executionStep.step.max_answer_attempts,
                }
              )}
            </p>
          ) : (
            <p className="mt-1 break-all rounded-md border border-neutral-light bg-gray-50 p-2 text-xs font-normal text-black">
              <code>
                {JSON.stringify(
                  executionStep.execution_result?.answer,
                  null,
                  2
                )}
              </code>
            </p>
          )}
        </div>
      )}

      {executionStep.status === "EXPIRED" && (
        <p className="text-red-400">
          {t(
            "flow-executions:execution-steps-detail.expired",
            "Step expired after waiting for"
          )}{" "}
          {executionStep.step.max_wait_time_amount}{" "}
          {maxWaitTimeOption
            ? t(maxWaitTimeOption.label)
            : executionStep.step.max_wait_time_unit}{" "}
          {t(
            "flow-executions:execution-steps-detail.expired-without-answer",
            "without answer"
          )}
          .
        </p>
      )}
    </div>
  );
}
