import { ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  FilterFn,
  Row,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Text } from "@tremor/react";
import { DebouncedInput } from "src/components/Form/DebouncedInput";

interface LocalTableProps<T> {
  tableTitle: JSX.Element;
  columns: ColumnDef<T>[];
  data: T[];
  columnVisibility?: VisibilityState;
  enableColumnSorting?: boolean;
  disableSearch?: boolean;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  console.log("Running fuzzyfilter", row, columnId, value);
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export default function LocalTable<T>({
  columns,
  tableTitle,
  data,
  columnVisibility,
  enableColumnSorting = false,
  disableSearch = false,
}: LocalTableProps<T>) {
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibilityLocal, setColumnVisibility] = useState(
    columnVisibility ?? {}
  );
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnVisibility: columnVisibilityLocal,
      globalFilter,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    // debugTable: true,
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 35,
    overscan: 10,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <div>
      <div className="border-t border-b border-gray-200 bg-white shadow sm:rounded-lg sm:border">
        <div className="flex items-center justify-between border-b py-2">
          <div className="flex grow items-center px-2 sm:px-4 lg:px-4">
            <div>
              <Text>{tableTitle}</Text>
            </div>
          </div>
          {!disableSearch && (
            <div className="grow-1 align-center sm:grow-0">
              <form onSubmit={(ev) => ev.preventDefault()}>
                <DebouncedInput
                  value={globalFilter ?? ""}
                  onChange={(value) => setGlobalFilter(String(value))}
                  placeholder={t("search", "Search")}
                />
              </form>
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <div className="inline-block min-w-full py-2 px-2 align-middle">
            <div
              className="container h-96 overflow-auto"
              ref={tableContainerRef}
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.getSize() }}
                          >
                            {header.isPlaceholder ? null : enableColumnSorting ? (
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                <div className="flex flex-row justify-center">
                                  {{
                                    asc: <ArrowUpIcon className="h-3 w-3" />,
                                    desc: <ArrowDownIcon className="h-3 w-3" />,
                                  }[header.column.getIsSorted() as string] ??
                                    null}
                                </div>
                              </div>
                            ) : (
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {paddingTop > 0 && (
                    <tr>
                      <td style={{ height: `${paddingTop}px` }} />
                    </tr>
                  )}
                  {virtualRows.map((virtualRow) => {
                    const row = rows[virtualRow.index] as Row<T>;
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="max-w-[250px] truncate py-1"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                  {paddingBottom > 0 && (
                    <tr>
                      <td style={{ height: `${paddingBottom}px` }} />
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end pt-1 text-sm text-gray-500">
        {/* t("row", "{{count}} row") */}
        {/* t("row_many", "{{count}} rows") */}
        {/* t("row_other", "{{count}} rows") */}
        {t("row", "row", {
          count: rows.length,
        })}
      </div>
    </div>
  );
}

interface LocalTableFormatProps {
  children: ReactNode;
}

export function LocalTableHeaderFormat({ children }: LocalTableFormatProps) {
  return (
    <div className="px-2 py-1 first-letter:uppercase text-sm font-medium text-gray-700">
      {children}
    </div>
  );
}

export function LocalTableCellFormat({ children }: LocalTableFormatProps) {
  return (
    <div className="py-1 px-2 text-sm text-center w-full text-gray-600">
      {children}
    </div>
  );
}
