import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LockClosedIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import TextInputField from "../../components/Form/TextInputField";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import { API_ROUTES, buildAPIRoute } from "../../router/router";
import * as meta from "./ChangePasswordMeta";

export default function ChangePasswordForm() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = async (formData, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.post(buildAPIRoute(API_ROUTES.CHANGE_PASSWORD), formData);
      setSuccess(true);
      setSubmitted(true);
    } catch (err) {
      setSuccess(false);
      if (err.response.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(t("error-general"));
      }
      setSubmitted(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto mt-6 max-w-3xl">
        <Formik
          validationSchema={meta.schema}
          onSubmit={(values, { setSubmitting }) =>
            onSubmit(values, setSubmitting)
          }
          enableReinitialize={false}
          initialValues={meta.getInitialValues()}
        >
          {(formik) => (
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              className="space-y-4"
            >
              <div className="my-4">
                <APIErrors
                  APIError={backendError}
                  APIValidationErrors={backendValidationErrors}
                  fieldTranslations={meta.FIELDS}
                ></APIErrors>
              </div>

              <TextInputField
                placeholder={t(meta.FIELDS.oldpassword.placeholder)}
                label={t(meta.FIELDS.oldpassword.label)}
                name={meta.FIELDS.oldpassword.key}
                type="password"
                icon={LockClosedIcon}
              />

              <TextInputField
                placeholder={t(meta.FIELDS.password1.placeholder)}
                label={t(meta.FIELDS.password1.label)}
                name={meta.FIELDS.password1.key}
                type="password"
                icon={LockClosedIcon}
                help={t(meta.FIELDS.password1.help)}
              />

              <TextInputField
                placeholder={t(meta.FIELDS.password2.placeholder)}
                label={t(meta.FIELDS.password2.label)}
                name={meta.FIELDS.password2.key}
                type="password"
                icon={LockClosedIcon}
              />

              <div className="mt-5">
                <div className="text-right">
                  <StateButton
                    isSubmitting={formik.isSubmitting}
                    submitted={submitted}
                    success={success}
                  ></StateButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
