import * as Sentry from "@sentry/browser";

import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import {
  ContactTag,
  ContactTagDetail,
  related_objects,
} from "@hilos/types/private-schema";
import DeleteObject, { onDeleteFn } from "src/components/DeleteObject";
import { PencilAltIcon, PlusSmIcon, TagIcon } from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

import APIErrors from "src/components/base/APIErrors";
import HeaderNavigation from "../../components/HeaderNavigation";
import { Link } from "react-router-dom";
import LinkTracked from "src/components/Posthog/LinkTracked";
import Table from "src/components/Table/Table";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { hasItems } from "src/helpers/utils";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { usePostHog } from "posthog-js/react";

export default function ContactTagList() {
  const { t } = useTranslation();
  const [triggerReload, setTriggerReload] = useState(false);

  const posthog = usePostHog();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const timeout = useCleanTimeout();

  const getRelatedObjectLabel = useCallback(
    (object: related_objects) => {
      switch (object.model) {
        case "MessageBlast":
          return (
            <ul>
              {/* t("templates:related-objects.broadcast_one", "{{count}} broadcast") */}
              {/* t("templates:related-objects.broadcast_other", "{{count}} broadcasts") */}
              {t("templates:related-objects.broadcast", {
                count: object.count,
              })}
            </ul>
          );
        case "Contact":
          return (
            <ul>
              {/* t("templates:related-objects.contact_one", "{{count}} contact") */}
              {/* t("templates:related-objects.contact_other", "{{count}} contacts") */}
              {t("templates:related-objects.contact", {
                count: object.count,
              })}
            </ul>
          );
        default:
          break;
      }
    },
    [t]
  );

  const onDelete: onDeleteFn<ContactTag> = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.CONTACT_TAG_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setDeleteSuccess(false);
      if (errorAxios?.response?.status === 400) {
        Sentry.captureException(err);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(t("error-general"));
      }
      timeout.current = setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings:account-config.title"),
              url: buildRoute("config-account"),
            },
            {
              name: t("contact-tags", "Contact tags"),
              url: buildRoute("config-inbox-contact-tag-list"),
            },
          ]}
        />

        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <TagIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("contact-tags")}
            </h2>
          </div>
          <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
            <span className="md:ml-3">
              <LinkTracked
                className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                to={buildRoute("config-inbox-contact-tag-create")}
                posthogEvent="click contact tag create"
              >
                <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {t("settings:contact-tags.create", "Create contact tag")}
              </LinkTracked>
            </span>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6">
          <div className="mt-6 md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:contact-tags.description">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Organize and edit your contact tags.
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Read more about our contact tags and how you can use them{" "}
                    <a
                      href="https://hilos.io/docs/user/using-hilos/inbox/contact-tab#contact-tags"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      here
                    </a>
                    .
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <div className="my-4">
                    <APIErrors
                      APIError={backendError}
                      APIValidationErrors={backendValidationErrors}
                      fieldTranslations={{}}
                    />
                  </div>
                  <Table<ContactTagDetail>
                    baseURL={buildAPIRoute(API_ROUTES.CONTACT_TAG_LIST_DETAIL)}
                    paginated={true}
                    triggerReload={triggerReload}
                    tableHead={
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                          >
                            {t("name")}
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                          >
                            {`${t("settings:contact-tags.usage", "Usage")}`}
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                          ></th>
                        </tr>
                      </thead>
                    }
                    tableKey={(contactTag) => String(contactTag.id)}
                    tableRow={(contactTag) => (
                      <>
                        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                          <code className="mr-2 text-indigo-500">
                            {contactTag.name}
                          </code>
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                          {hasItems(contactTag.related_objects) &&
                            contactTag.related_objects.map((related_object) => (
                              <p>{getRelatedObjectLabel(related_object)}</p>
                            ))}
                        </td>

                        <td className="whitespace-nowrap flex flex-row justify-evenly px-4 py-3 text-sm text-gray-600">
                          <Link
                            className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                            to={buildRoute("config-inbox-contact-tag-update", {
                              ":id": contactTag.id,
                            })}
                          >
                            <PencilAltIcon
                              className="-ml-0.5 mr-2 h-4 w-4"
                              aria-hidden="true"
                            />
                            {t("update")}
                          </Link>
                          <DeleteObject
                            obj={contactTag}
                            objDescription={String(contactTag.name)}
                            actionDescription={t(
                              "settings:contact-tags.delete_tag_confirmation",
                              "When deleting this contact tag, it will be removed from the contacts and broadcasts its related to."
                            )}
                            onDelete={onDelete}
                            deleteSuccess={deleteSuccess}
                            deleteSubmitted={deleteSubmitted}
                          />
                        </td>
                      </>
                    )}
                    dataName={t("contact-tags", "Contact tags")}
                    createObject={
                      <>
                        <Trans i18nKey="settings:contact-tags.no-tags-yet">
                          <h4>
                            Looks like you don't have any contact tags yet
                          </h4>
                          <p className="text-sm text-gray-500">
                            Why don't you create one?
                          </p>
                          <LinkTracked
                            className="mt-2 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                            to={buildRoute("config-inbox-contact-tag-create")}
                            posthogEvent="click contact tag create"
                          >
                            <PlusSmIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Create contact tag
                          </LinkTracked>
                        </Trans>
                      </>
                    }
                  ></Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
