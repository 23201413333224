import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import { CogIcon, LightningBoltIcon } from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";
import axios, { AxiosError } from "axios";

import { DateTime } from "luxon";
import DeleteObject from "../../components/DeleteObject";
import HeaderNavigation from "../../components/HeaderNavigation";
import { InboxAction } from "@hilos/types/private-schema";
import InboxActionButtonPreview from "./InboxActionButtonPreview";
import Table from "src/components/Table/Table";
import { useState } from "react";
import { userToString } from "../../Helpers";

export default function InboxActionList() {
  const [triggerReload, setTriggerReload] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const { t } = useTranslation();

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.INBOX_ACTION_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
    } catch (err) {
      type AxiosValidationError = AxiosError<{ status: number; data: unknown }>;
      const axiosErr: AxiosValidationError = err as AxiosValidationError;
      setDeleteSuccess(false);
      if (axiosErr?.response?.status === 400) {
        console.log("error", axiosErr);
        setBackendValidationErrors(axiosErr?.response?.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: "Settings",
                url: buildRoute("config-account"),
              },
              {
                name: "Inbox Actions",
                url: buildRoute("config-inbox-action-list"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <LightningBoltIcon
                  className="mr-2 h-7 w-7"
                  aria-hidden="true"
                />
                {t("inbox-actions")}
              </h2>
              <p className="mt-1 flex items-center text-sm text-gray-600">
                <Trans i18nKey="settings:inbox-actions.description">
                  Add integrations to your Inbox, available from the Actions Tab
                  (the one with a <CogIcon className="mx-1 h-5 w-5" /> icon on
                  the right).
                  {/* <HelpDocsLink
                    href="https://hilos.io/docs/user/inbox-actions"
                    className="ml-1 text-blue-400"
                  /> */}
                </Trans>
              </p>
            </div>
            <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
              <span className="md:ml-3">
                {/* <Link
                  to={buildRoute("config-inbox-action-create")}
                  className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                >
                  <PlusSmIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  New Inbox Action
                </Link> */}
              </span>
            </div>
          </div>
        </div>

        <div className="min-h-screen bg-gray-50">
          <div className="h-full sm:px-6 sm:pt-8 lg:px-8">
            <Table<InboxAction>
              baseURL={buildAPIRoute(API_ROUTES.INBOX_ACTION_LIST_CREATE)}
              paginated={true}
              triggerReload={triggerReload}
              tableHead={
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("name")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("action-type", "Action type")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("active")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("preview")}
                    </th>
                    <th scope="col" className="px-4 py-3"></th>
                  </tr>
                </thead>
              }
              tableRow={(action) => (
                <>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                    {/* <Link
                      className="text-blue font-medium"
                      to={buildRoute("config-inbox-action-detail", {
                        ":id": action.id,
                      })}
                    > */}
                    {action.name}
                    {/* </Link> */}
                    <p className="text-sm text-gray-500">
                      Created by {userToString(action.created_by)} @{" "}
                      <time className="text-gray-500" dateTime="">
                        {DateTime.fromISO(
                          action.created_on as string
                        ).toLocaleString(DateTime.DATETIME_SHORT)}
                      </time>
                    </p>
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    <span>{action.action_type}</span>
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {action.is_active ? (
                      <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
                        Active
                      </span>
                    ) : (
                      <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-800">
                        INACTIVE
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    <InboxActionButtonPreview
                      buttonClasses={action.button_classes as string}
                      buttonLabel={action.button_label}
                      buttonIcon={action.button_icon as string}
                    />
                  </td>

                  <td className="flex items-center px-4 py-3">
                    <DeleteObject
                      obj={action}
                      objDescription={action.name}
                      actionDescription="After deletion you won't be able to use this action from the inbox."
                      onDelete={onDelete}
                      deleteSuccess={deleteSuccess}
                      deleteSubmitted={deleteSubmitted}
                    />
                  </td>
                </>
              )}
              dataName={t("inbox-actions", "Inbox actions")}
              noResults={
                <>
                  <h4>Couldn't find any inbox actions with these filters.</h4>
                  <p className="text-muted">Remove filters to try again.</p>
                </>
              }
              createObject={
                <>
                  <h4>Looks like you don't have any inbox actions yet.</h4>
                  <p className="text-sm text-gray-500">
                    Why don't you create one?
                  </p>
                  {/* <Link
                    to={buildRoute("config-inbox-action-create")}
                    className="mt-2 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                  >
                    <PlusSmIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    New inbox action
                  </Link> */}
                </>
              }
            ></Table>
          </div>
        </div>
      </div>
    </>
  );
}
