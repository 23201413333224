import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ChartSquareBarIcon,
  InboxIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {
  UsageDetailPeriodInboxData,
  UsageDetailPeriodMACData,
  UsageSummaryData,
} from "@hilos/types/analytics";
import HeaderNavigation from "src/components/HeaderNavigation";
import Loading from "src/components/Loading";
import LocalTable, {
  LocalTableCellFormat,
  LocalTableHeaderFormat,
} from "src/components/LocalTable";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";
import {
  useUsageDetailAnalytics,
  useUsageSummaryAnalytics,
} from "src/hooks/useUsageAnalytics";
import { classNames } from "src/Helpers";
import { buildRoute } from "src/router/router";

export default function UsageReport() {
  const { t } = useTranslation();
  const { usageDetail } = useUsageDetailAnalytics();
  const { usageSummary } = useUsageSummaryAnalytics();
  const { formatWithLocale } = useDateFnsFormatLocale();

  const getFieldChange = (obj: UsageSummaryData | undefined, field: string) => {
    if (!obj) {
      return 0;
    }
    if (!obj.previous[field]) {
      return 0;
    }
    return (
      ((obj.current[field] - obj.previous[field]) / obj.previous[field]) * 100
    );
  };

  const inboxUsageColumnsTable = useMemo(() => {
    const columnHelper = createColumnHelper<UsageDetailPeriodInboxData>();
    const defaultColumns = [
      columnHelper.accessor((row) => row?.email, {
        id: "email",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("settings:usage-report.inbox-detail.email", "Agent email")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.count, {
        id: "count",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("settings:usage-report.inbox-detail.count", "Messages sent")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
    ];
    return defaultColumns;
  }, [t]);

  const macUsageColumnsTable = useMemo(() => {
    const columnHelper = createColumnHelper<UsageDetailPeriodMACData>();
    const defaultColumns = [
      columnHelper.accessor(
        (row) => {
          const date = row?.date;
          if (date) {
            return formatWithLocale(new Date(date), "LLL d, uuuu");
          }
        },
        {
          id: "period_date",
          cell: (info) => (
            <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
          ),
          header: () => (
            <>
              <LocalTableHeaderFormat>
                {t("settings:usage-report.mac-detail.count", "Day")}
              </LocalTableHeaderFormat>
            </>
          ),
        }
      ),
      columnHelper.accessor((row) => row?.count, {
        id: "count",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("settings:usage-report.mac-detail.count", "Contacts reached")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
    ];
    return defaultColumns;
  }, [t, formatWithLocale]);

  if (!usageSummary || !usageDetail) {
    return <Loading />;
  }

  console.log(usageDetail);

  return (
    <div className="h-full bg-gray-50">
      <div className="overflow-y-auto min-h-screen">
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("reports:usage-reports.label", "Hilos usage report"),
                url: buildRoute("reports"),
              },
            ]}
          />

          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <Trans i18nKey="reports:usage-reports.description">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <ChartSquareBarIcon
                    className="mr-2 h-7 w-7"
                    aria-hidden="true"
                  />
                  Hilos Usage Reports
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  See how many Agent Seats and Active Contacts
                </p>
              </Trans>
            </div>
          </div>
        </div>

        <div className="bg-gray-50">
          <div className="sm:px-6 sm:pt-4 lg:px-8">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t("overview")}{" "}
                <span className="text-sm font-normal text-gray-500">
                  ({t("settings:current-billing-period")})
                </span>
              </h3>
            </div>

            <div className="space-y-6">
              <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                    <div className="absolute rounded-md bg-indigo-500 p-3">
                      <InboxIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 truncate font-medium text-gray-700">
                      {t("reports:inbox-seats-used.label", "Inbox Seats Used")}
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                    <p className="text-2xl font-semibold text-gray-900">
                      {usageSummary?.current.inbox_users_in_period}
                    </p>
                    <p
                      className={classNames(
                        getFieldChange(usageSummary, "inbox_users_in_period") >
                          0
                          ? "text-green-600"
                          : "text-red-600",
                        "ml-2 flex items-baseline text-sm font-semibold"
                      )}
                    >
                      {getFieldChange(usageSummary, "inbox_users_in_period") >
                      0 ? (
                        <ArrowSmUpIcon
                          className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowSmDownIcon
                          className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                          aria-hidden="true"
                        />
                      )}
                      <span className="sr-only">
                        {" "}
                        {getFieldChange(usageSummary, "inbox_users_in_period") >
                        0
                          ? t("increased", "Increased")
                          : t("decreased", "Decreased")}{" "}
                        {t("by")}{" "}
                      </span>
                      {getFieldChange(
                        usageSummary,
                        "inbox_users_in_period"
                      ).toFixed(0)}
                      %
                      <span className="ml-1 text-xs font-normal text-gray-500">
                        {t(
                          "reports:from-previous-period",
                          "From previous period"
                        )}{" "}
                        ({usageSummary.previous.inbox_users_in_period})
                      </span>
                    </p>
                  </dd>
                </div>
                <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                    <div className="absolute rounded-md bg-indigo-500 p-3">
                      <UserIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 truncate font-medium text-gray-700">
                      {t(
                        "reports:unique-contacts.label",
                        "Unique Active Contacts used"
                      )}
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                    <p className="text-2xl font-semibold text-gray-900">
                      {usageSummary?.current["macs_in_period"]}
                    </p>
                    <p
                      className={classNames(
                        getFieldChange(usageSummary, "macs_in_period") > 0
                          ? "text-green-600"
                          : "text-red-600",
                        "ml-2 flex items-baseline text-sm font-semibold"
                      )}
                    >
                      {getFieldChange(usageSummary, "macs_in_period") > 0 ? (
                        <ArrowSmUpIcon
                          className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowSmDownIcon
                          className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                          aria-hidden="true"
                        />
                      )}
                      <span className="sr-only">
                        {" "}
                        {getFieldChange(usageSummary, "macs_in_period") > 0
                          ? t("increased", "Increased")
                          : t("decreased", "Decreased")}{" "}
                        {t("by")}{" "}
                      </span>
                      {getFieldChange(usageSummary, "macs_in_period").toFixed(
                        0
                      )}
                      %
                      <span className="ml-1 text-xs font-normal text-gray-500">
                        {t(
                          "reports:from-previous-period",
                          "From previous period"
                        )}{" "}
                        ({usageSummary.previous["macs_in_period"]})
                      </span>
                    </p>
                  </dd>
                </div>
              </dl>

              <div className="grid grid-cols-3 grid-rows-1 gap-x-6">
                <div>
                  <LocalTable<UsageDetailPeriodInboxData>
                    tableTitle={
                      <>{t("settings:usage-report.inbox-detail.title")}</>
                    }
                    columns={
                      inboxUsageColumnsTable as unknown as ColumnDef<UsageDetailPeriodInboxData>[]
                    }
                    data={usageDetail.current.inbox_users_in_period}
                    columnVisibility={{
                      should_be_included: false,
                    }}
                    enableColumnSorting={true}
                    disableSearch={true}
                  />
                </div>
                <div className="col-span-2">
                  <LocalTable<UsageDetailPeriodMACData>
                    tableTitle={
                      <>{t("settings:usage-report.mac-detail.title")}</>
                    }
                    columns={
                      macUsageColumnsTable as unknown as ColumnDef<UsageDetailPeriodMACData>[]
                    }
                    data={usageDetail.current.macs_in_period_per_day}
                    columnVisibility={{
                      should_be_included: false,
                    }}
                    enableColumnSorting={true}
                    disableSearch={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
