import { useMemo } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { HTTPRequest } from "@hilos/types/inbox_action";

interface HTTPResponseRenderRequestProps {
  request: HTTPRequest;
}

export default function HTTPResponseRenderRequest({
  request,
}: HTTPResponseRenderRequestProps) {
  const parsedRequestBody = useMemo(() => {
    if (!request) {
      return null;
    }
    if (!request.body) {
      return null;
    }
    try {
      return JSON.stringify(JSON.parse(request.body), null, 2);
    } catch {
      return JSON.stringify(request.body);
    }
  }, [request]);

  const URLPreview = useMemo(() => {
    if (!request) {
      return null;
    }
    if (request.params) {
      let params = Object.keys(request.params).map((param, index) =>
        param
          ? (index === 0 ? "?" : "&") +
            encodeURIComponent(param) +
            (request.params[param]
              ? "=" + encodeURIComponent(request.params[param])
              : "")
          : ""
      );
      return request.url + params.join("");
    }
    return request.url;
  }, [request]);

  if (!request) {
    return (
      <pre className="my-6 overflow-scroll whitespace-pre-wrap break-words break-all rounded-md border border-neutral-light bg-gray-50 p-2 font-mono text-xs font-normal text-black">
        No request data available.
      </pre>
    );
  }

  return (
    <div className="my-6 space-y-4">
      <Disclosure as="div">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`${
                open
                  ? "bg-gray-100 text-gray-600"
                  : "text-gray-500 hover:bg-gray-50 hover:text-gray-900 focus:hover:bg-gray-50"
              } group flex w-full items-center justify-between text-xs font-medium uppercase tracking-wide`}
            >
              Headers
              <ChevronDownIcon
                className={`${open ? "rotate-180 transform" : ""} h-4 w-4`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="mt-2 text-sm/5 text-white/50">
              <dl className="space-y-1 overflow-scroll sm:divide-y sm:divide-gray-200">
                {Object.keys(request.headers).map((headerKey) => (
                  <div
                    className="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-2"
                    key={headerKey}
                  >
                    <dt className="text-sm font-medium text-gray-500">
                      {headerKey}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                      <code className="mt-1 break-all rounded-lg bg-gray-50 p-2 font-mono text-xs font-normal text-black">
                        {request.headers[headerKey]}
                      </code>
                    </dd>
                  </div>
                ))}
              </dl>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div>
        <h3 className="text-xs font-medium uppercase tracking-wide text-gray-500">
          URL
        </h3>
        <p className="mt-1 break-all rounded-md border border-neutral-light bg-gray-50 p-2 text-xs font-normal text-black">
          <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 uppercase">
            {request.method}
          </span>{" "}
          <code>{URLPreview}</code>
        </p>
      </div>

      <div>
        <h3 className="text-xs font-medium uppercase tracking-wide text-gray-500">
          Body
        </h3>
        <pre className="mt-1 overflow-scroll whitespace-pre-wrap break-words break-all rounded-md border border-neutral-light bg-gray-50 p-2 font-mono text-xs font-normal text-black">
          {parsedRequestBody}
        </pre>
      </div>
    </div>
  );
}
