import { useTranslation } from "react-i18next";

export default function TeamBadge({ assignmentStrategy }) {
  const { t } = useTranslation();
  switch (assignmentStrategy) {
    case "ROUND_ROBIN":
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
          {t("settings:assignment-strategy.round-robin.label")}
        </span>
      );
    case "LEAST_BUSY":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
          {t("settings:assignment-strategy.least-busy.label")}
        </span>
      );
    case "USE_DEFAULT":
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
          {t("settings:assignment-strategy.use-default.label", "Use Default")}
        </span>
      );
    default:
      return null;
  }
}
