import { useMemo, useRef } from "react";
import { Popover } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { Placement } from "@popperjs/core";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import usePopper, { Portal } from "src/hooks/usePopper";

const timeoutDuration = 120;

interface TooltipProps {
  button?: JSX.Element;
  content: JSX.Element;
  placement?: Placement;
}
export default function Tooltip({
  button = (
    <InformationCircleIcon
      className="h-4 w-4 text-gray-400"
      aria-hidden="true"
    />
  ),
  content,
  placement = "bottom",
}: TooltipProps) {
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const timeOutRef = useCleanTimeout();
  const [triggerRefCallback, containerRefCallback] = usePopper({
    placement: placement,
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  const triggerRefLocalCallback = useMemo(
    () => (referenceDomNode: HTMLButtonElement | null) => {
      triggerRef.current = referenceDomNode;
      triggerRefCallback(referenceDomNode);
    },
    [triggerRefCallback]
  );

  const handleEnter = (isOpen) => {
    if (timeOutRef.current) clearTimeout(timeOutRef.current);
    !isOpen && triggerRef?.current?.click();
  };

  const handleLeave = (isOpen) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && triggerRef.current?.click();
    }, timeoutDuration);
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <div
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
          className="flex flex-row"
        >
          <Popover.Button ref={triggerRefLocalCallback}>
            {button}
          </Popover.Button>

          <Portal>
            <Popover.Panel
              className="absolute left-1/2 z-50 mt-3 -translate-x-1/2 translate-y-2 transform px-4"
              ref={containerRefCallback}
            >
              <div className="overflow-hidden rounded-lg bg-white shadow">
                {content}
              </div>
            </Popover.Panel>
          </Portal>
        </div>
      )}
    </Popover>
  );
}
