import { useTranslation } from "react-i18next";
import LineTabs from "src/components/LineTabs";
import MappedValuesField from "../../MappedValuesField";
import TextInputFieldWithVariables from "../../TextInputFieldWithVariables";

interface FlowBuilderStepContactUpdateProps {
  index: number;
}

function FlowBuilderStepContactUpdate({
  index,
}: FlowBuilderStepContactUpdateProps) {
  const { t } = useTranslation();

  return (
    <div className="mt-2">
      <LineTabs
        labels={[
          t("flows:steps.contact-update.tabs.default", "Default"),
          t("flows:steps.contact-update.tabs.custom", "Custom"),
        ]}
      >
        <div className="space-y-4 mt-2">
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name={"contact_first_name"}
            currentStepIndex={index}
            label={t(
              "flows:steps.contact-update.contact-first-name.label",
              "First name"
            )}
          />
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name="contact_last_name"
            currentStepIndex={index}
            label={t(
              "flows:steps.contact-update.contact-last-name.label",
              "Last name"
            )}
          />
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name="contact_email"
            currentStepIndex={index}
            label={t("flows:steps.contact-update.contact-email.label", "Email")}
          />
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name="contact_external_url"
            currentStepIndex={index}
            label={t(
              "flows:steps.contact-update.contact-external-url.label",
              "External url"
            )}
            help={t(
              "flows:steps.contact-update.contact-external-url.help",
              "Use this field to set the URL to your CRM's contact detail. We'll show this in the Inbox sidebar so you can quickly move to your CRM from the conversation."
            )}
          />
        </div>
        <MappedValuesField
          path={`steps.${index}`}
          name={"contact_custom_properties"}
          objectName={t(
            "flows:steps.contact-update.custom-contact-property",
            "Custom contact property"
          )}
          currentStepIndex={index}
          addButtonLabel={t(
            "flows:steps.contact-update.add-custom-property",
            "Add property"
          )}
        />
      </LineTabs>
    </div>
  );
}

export default FlowBuilderStepContactUpdate;
