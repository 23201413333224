import { useMemo } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { HTTPResponse } from "@hilos/types/inbox_action";

interface HTTPResponseRenderResponseProps {
  response: HTTPResponse;
}

export default function HTTPResponseRenderResponse({
  response,
}: HTTPResponseRenderResponseProps) {
  const parsedResponseText = useMemo(() => {
    if (!response) {
      return null;
    }
    if (!response.response) {
      return JSON.stringify(response, null, 2);
    }
    try {
      return JSON.stringify(JSON.parse(response.response), null, 2);
    } catch {
      return JSON.stringify(response.response, null, 2);
    }
  }, [response]);

  if (!response) {
    return (
      <pre className="my-6 overflow-scroll whitespace-pre-wrap break-words break-all rounded-md border border-neutral-light bg-gray-50 p-2 font-mono text-xs font-normal text-black">
        {parsedResponseText}
      </pre>
    );
  }

  return (
    <div className="my-6 space-y-4">
      {response.headers && (
        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open
                    ? "bg-gray-100 text-gray-600"
                    : "text-gray-500 hover:bg-gray-50 hover:text-gray-900 focus:hover:bg-gray-50"
                } group flex w-full items-center justify-between text-xs font-medium uppercase tracking-wide`}
              >
                Headers
                <ChevronDownIcon
                  className={`${open ? "rotate-180 transform" : ""} h-4 w-4`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="mt-2 text-sm/5 text-white/50">
                <dl className="space-y-1 overflow-scroll sm:divide-y sm:divide-gray-200">
                  {response.headers &&
                    Object.keys(response.headers).map((headerKey) => (
                      <div
                        className="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-2"
                        key={headerKey}
                      >
                        <dt className="text-sm font-medium text-gray-500">
                          {headerKey}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                          <code className="mt-1 break-all rounded-lg bg-gray-50 p-2 font-mono text-xs font-normal text-black">
                            {response.headers && response.headers[headerKey]}
                          </code>
                        </dd>
                      </div>
                    ))}
                </dl>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}

      {response.redirect_data && (
        <div>
          <h3 className="text-xs font-medium uppercase tracking-wide text-gray-500">
            Redirect
          </h3>
          <div className="text-sm font-bold text-yellow-400">
            This request had at least 1 redirect
          </div>
          <div className="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-2">
            <dt className="text-sm font-medium text-gray-500">Initial URL</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
              <code className="mt-1 break-all rounded-lg bg-gray-50 p-2 font-mono text-xs font-normal text-black">
                {response.redirect_data.initial_url}
              </code>
            </dd>
          </div>
          <div className="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-2">
            <dt className="text-sm font-medium text-gray-500">Final URL</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
              <code className="mt-1 break-all rounded-lg bg-gray-50 p-2 font-mono text-xs font-normal text-black">
                {response.redirect_data.final_url}
              </code>
            </dd>
          </div>
          <div className="mt-2 border-t border-yellow-400 pt-2 text-xs text-yellow-400">
            If you are having problems with the expected behavior, please
            consider using the final url.
          </div>
        </div>
      )}

      <div>
        <h3 className="text-xs font-medium uppercase tracking-wide text-gray-500">
          Body
        </h3>
        <pre className="mt-1 overflow-scroll whitespace-pre-wrap break-words break-all rounded-md border border-neutral-light bg-gray-50 p-2 font-mono text-xs font-normal text-black">
          {parsedResponseText}
        </pre>
      </div>
    </div>
  );
}
