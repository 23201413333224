import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { ArrowSmRightIcon, CheckIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import ComboboxField from "src/components/Form/ComboboxField";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames, formatPhone, userToString } from "../../Helpers";
import { ERROR_MESSAGES } from "../../constants/Form";
import { API_ROUTES } from "../../router/router";

export interface OptionLabel {
  active: boolean;
  selected: boolean;
  option: { label: string; value: string };
}

export const contactFormatOptionLabel = ({
  active,
  selected,
  option,
}: OptionLabel) => (
  <div className={selected ? "border-l-2 pl-2 border-hilos" : ""}>
    <div>
      <p className={classNames("truncate", selected && "font-semibold")}>
        {option.label.split("-")[0]}
      </p>
      <p
        className={classNames(
          "truncate text-gray-500 text-xs mt-1",
          selected && "font-semibold"
        )}
      >
        {option.label.split("-")[1]}
      </p>
    </div>

    {selected && (
      <span
        className={classNames(
          "absolute inset-y-0 right-0 flex items-center pr-4 text-hilos"
        )}
      >
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      </span>
    )}
  </div>
);

interface NewConversationFormProps {
  onClose: () => void;
}

const initialValues = {
  phone: "",
  notes: "",
  assigned: [],
};

const schema = yup.object().shape({
  phone: yup
    .string()
    .test(
      "is-valid-phone-number",
      ERROR_MESSAGES.validPhoneNumber,
      (value, context) => (value && isPossiblePhoneNumber(value)) || false
    ),
});

function NewConversationForm({ onClose }: NewConversationFormProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const { data } = await axios.post(
          API_ROUTES.INBOX_CONTACT_LIST_CREATE,
          formData
        );

        if (data && data.id) {
          navigate("/inbox/conversation/:id".replace(":id", data.id));
          onClose();
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    [onClose, navigate]
  );

  // TODO: Add debounce to search phone number
  const handleSearchPhoneNumber = useCallback(async (search: string) => {
    try {
      const { data } = await axios.get(API_ROUTES.CONTACT_CREATE_LIST, {
        params: { page_size: 5, search },
      });
      if (data && data.results) {
        return data.results.map((c) => ({
          label: `${userToString(c)} - ${formatPhone(c.canonical_phone)}`,
          value: c.canonical_phone,
        }));
      }
    } catch {}
    return [];
  }, []);

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit} className="">
          <div className="space-y-6 pt-6 pb-5">
            {session?.account.has_multiple_active_channels && (
              <ChannelSelectField
                name="channel"
                label={t(
                  "inbox:new-conversation-form.channel.label",
                  "Channel to use"
                )}
              />
            )}
            <ComboboxField
              name="phone"
              loadOptions={handleSearchPhoneNumber}
              placeholder={t("search", "Search")}
              label={t(
                "inbox:new-conversation-form.phone.label",
                "Phone or contact"
              )}
              help={t(
                "inbox:new-conversation-form.phone.help",
                "Search for phone numbers or contact names."
              )}
              formatOptionLabel={contactFormatOptionLabel}
            />
          </div>

          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {formik.isSubmitting ? (
              <>
                <svg
                  className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {t("starting", "Starting...")}
              </>
            ) : (
              <div className="inline-flex items-center">
                {t("inbox:start-conversation", "Start a conversation")}
                <ArrowSmRightIcon
                  className="ml-1 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              </div>
            )}
          </button>
        </form>
      )}
    </Formik>
  );
}

export default NewConversationForm;
