import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepBaseSchema } from "../flow";

export const FlowStepMessageSchema = (t: TFunction) =>
  FlowStepBaseSchema(t).shape({
    body: yup
      .string()
      .nullable()
      .when("body_type", {
        is: "text",
        then: (schema) =>
          schema
            .required(t(ERROR_MESSAGES.REQUIRED))
            .max(1024, t(ERROR_MESSAGES.MAX_CHARACTERS)),
      }),
    body_type: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    body_file_type: yup
      .string()
      .nullable()
      .when("body_type", {
        is: (bodyType) => bodyType !== "text",
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    body_file_url: yup
      .string()
      .nullable()
      .when("body_file_type", {
        is: (bodyFileType) => ["URL"].includes(bodyFileType),
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    body_file: yup
      .string()
      .nullable()
      .when("body_file_type", {
        is: (bodyFileType) => ["UPLOAD"].includes(bodyFileType),
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
  });

export const FlowStepMessageFormat = (step: FlowStepData) => {
  const nextStep = { ...step };

  if (nextStep.body_file_type === "URL") {
    delete nextStep.body_file;
  } else if (nextStep.body_file_type === "UPLOAD") {
    delete nextStep.body_file_url;
  }
  if (nextStep.body_type === "text") {
    delete nextStep.body_file_url;
    delete nextStep.body_file;
    delete nextStep.body_file_type;
  } else {
    delete nextStep.body;
  }

  return nextStep;
};
