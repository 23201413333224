import { DragEventHandler, useCallback, useState } from "react";

interface UseDropZoneParams {
  disabled?: boolean;
}

function useDropZone({ disabled = false }: UseDropZoneParams | undefined = {}) {
  const [isDropzoneActive, setDropzoneActive] = useState(false);

  const onDrop = useCallback(() => setDropzoneActive(false), []);

  const onDragOver = useCallback<DragEventHandler>(
    (event) => {
      if (!disabled) {
        event.preventDefault();
      }
    },
    [disabled]
  );

  const onDragEnter = useCallback(() => setDropzoneActive(true), []);

  const onDragLeave = useCallback(() => setDropzoneActive(false), []);

  return {
    isDropzoneActive,
    onDrop,
    onDragOver,
    onDragEnter,
    onDragLeave,
  };
}

export default useDropZone;
