import { ChangeEvent, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  ArrowRightIcon,
  CheckIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { CopyIcon } from "lucide-react";
import { FlowData } from "@hilos/types/flow";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import { getRequiredVariablesFromTextValue } from "src/helpers/variables";
import useChatbot from "src/hooks/useChatbot";
import { copyTextToClipboard } from "src/Helpers";
import { buildRoute } from "src/router/router";
import { TriggerCategoryProps } from "../FlowBuilderTrigger";
import FlowAdvancedOptionsDisclosure from "../components/FlowBuilderAdvancedOptionsDisclosure";
import { MESSAGE_TYPE_TRIGGER } from "../constants/triggers";
import TriggerCategorySelector from "./TriggerCategorySelector";

function InboundMessageTrigger({
  handleTriggerTypeChange,
  handleTriggerConfigChange,
}: TriggerCategoryProps & {
  handleTriggerConfigChange: (triggerConfig: any) => void;
}) {
  const [t] = useTranslation();
  const [copied, setCopied] = useState(false);
  const { values } = useFormikContext<FlowData>();
  const { data: chatbot } = useChatbot(values.channel as unknown as number);
  const params = useParams();

  const handleCopy = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event?.preventDefault();
    copyTextToClipboard(values.trigger_config.message_content_to_match);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const inboundTriggerVariableNames = useMemo(() => {
    return values.trigger_config.has_variables &&
      values.trigger_config.message_content_to_match
      ? getRequiredVariablesFromTextValue(
          values.trigger_config.message_content_to_match
        )
      : [];
  }, [values.trigger_config]);

  return (
    <>
      <div className="pb-4">
        <h2 className="text-lg font-medium text-gray-900">
          {t(
            "flows:trigger.inbound-trigger.title",
            "Trigger when a message comes in"
          )}
        </h2>
      </div>

      <div className="space-y-4">
        <TriggerCategorySelector
          handleTriggerTypeChange={handleTriggerTypeChange}
          triggersForCategory={MESSAGE_TYPE_TRIGGER}
        />

        {values.trigger_type === "INBOUND_ANY_MESSAGE" && (
          <>
            {chatbot && chatbot.id !== params.id && (
              <>
                <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-2 space-y-2">
                      <Trans i18nKey="flows:active-chatbot-warning">
                        <p className="text-sm text-yellow-700">
                          You already have an active flow that triggers when a
                          customer messages you with anything (a Chatbot).
                        </p>
                        <p className="text-sm text-yellow-700">
                          When you click run now, this will replace{" "}
                          <a
                            href={buildRoute("flow-detail", {
                              ":id": chatbot.id,
                            })}
                            target="_blank"
                            rel="noreferrer"
                            className="font-bold underline"
                          >
                            your current chatbot
                          </a>{" "}
                          with this chatbot.
                        </p>
                        <ul className="ml-4 list-disc text-sm text-yellow-700">
                          <li>
                            The current flow (shown above) will be canceled.
                          </li>
                          <li>The new flow will run with new messages.</li>
                          <li>
                            Contacts that are still in progress with the
                            previous flow will be able to finish it.
                          </li>
                        </ul>
                      </Trans>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="border-l-4 border-blue-400 bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <Trans i18nKey="flow-executions:any-message-help">
                  <div className="ml-2 space-y-2">
                    <p className="text-sm text-blue-700">
                      This flow will trigger on any message received, as long
                      as:
                    </p>
                    <ul className="ml-4 list-disc text-sm text-blue-700">
                      <li>
                        There are no flows configured to run with the specific
                        text received and
                      </li>
                      <li>
                        The conversation is <strong>CLOSED</strong>.
                      </li>
                    </ul>
                    <p className="text-sm text-blue-700">
                      Everytime a conversation finishes, mark it as{" "}
                      <strong>CLOSED</strong> on the Inbox to make sure this
                      flow will trigger if the contact writes again.
                    </p>
                  </div>
                </Trans>
              </div>
            </div>
          </>
        )}

        {values.trigger_type === "INBOUND_SPECIFIC_MESSAGE" && (
          <>
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <InformationCircleIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-blue-400"
                  />
                </div>
                <Trans i18nKey="flow-executions:create-wa-button">
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      Your customers can trigger this flow from a WhatsApp
                      button on your website.
                    </p>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                      <Link
                        to={buildRoute("wa-widget")}
                        className="flex flex-row whitespace-nowrap items-center font-medium text-blue-700 hover:text-blue-600"
                      >
                        Create your whatsapp button
                        <ArrowRightIcon className="h-3 w-3 ml-1" />
                      </Link>
                    </p>
                  </div>
                </Trans>
              </div>
            </div>
            <div className="flex flex-row items-end gap-3">
              <TextInputField
                name="trigger_config.message_content_to_match"
                placeholder={t(
                  "flow-executions:input.inbound_start_message.placeholder",
                  "Hey, I'm interested in..."
                )}
                label={t(
                  "flow-executions:input.inbound_start_message.label",
                  "Text to look for in inbound messages to start this flow"
                )}
                type="text"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleTriggerConfigChange({
                    message_content_to_match: event.target.value,
                  });
                }}
              />
              <button
                className="aspect-square h-fit flex justify-center rounded-md border p-2 text-sm font-medium border-gray-400 bg-transparent text-gray-600 hover:border-gray-700 hover:text-gray-600 hover:bg-gray-50 active:bg-gray-100 disabled:bg-gray-300 disabled:text-gray-400"
                onClick={handleCopy}
                disabled={values.trigger_config.message_content_to_match === ""}
              >
                {copied ? (
                  <CheckIcon className="h-5 w-5" />
                ) : (
                  <CopyIcon className="h-5 w-5" />
                )}
              </button>
            </div>

            <SwitchField
              label={t(
                "flow-executions:input.inbound_start_message_match_exact.label",
                "Match exact message?"
              )}
              name="trigger_config.match_exact_message_content"
              help={t(
                "flow-executions:input.inbound_start_message_match_exact.help",
                "If not, we'll check if the message received contains the above text."
              )}
            />
          </>
        )}

        {values.trigger_type === "INBOUND_SPECIFIC_MESSAGE" && (
          <FlowAdvancedOptionsDisclosure
            defaultOpen={values.trigger_config.has_variables}
          >
            <SwitchField
              label={t(
                "flow-executions:input.has-variables.label",
                "Extract variables from text?"
              )}
              name="trigger_config.has_variables"
              help={t(
                "flow-executions:input.has-variables.help",
                "Useful to get data out of the trigger message, like 'Hey I'm interested in the product {{productName}}."
              )}
              onChange={(hasVariables) =>
                handleTriggerConfigChange({ has_variables: hasVariables })
              }
            />
            {values.trigger_config.has_variables && (
              <div className="rounded-md bg-blue-50 p-4 space-y-2">
                <div className="text-sm text-blue-700">
                  <p>
                    {t(
                      "flows:trigger.inbound-trigger.extract.instructions",
                      "We'll extract all text (letters and numbers only) inside brackets like"
                    )}{" "}
                    <code>{"{{myVariable}}"}</code>.
                  </p>
                </div>
                <div>
                  {inboundTriggerVariableNames.length > 0 ? (
                    <>
                      <p>
                        {t(
                          "flows:trigger.inbound-trigger.extract.variables-found",
                          "Variables found:"
                        )}
                      </p>
                      <div>
                        {inboundTriggerVariableNames.map((varName, idx) => (
                          <span
                            key={idx}
                            className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 m-0.5"
                          >
                            {varName}
                          </span>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p>
                      {t(
                        "flows:trigger.inbound-trigger.extract.no-variables-found",
                        "No variables found."
                      )}
                    </p>
                  )}
                </div>
              </div>
            )}
          </FlowAdvancedOptionsDisclosure>
        )}
      </div>
    </>
  );
}

export default InboundMessageTrigger;
