import {
  CONTACT_EDITABLE_FIELDS,
  getContactVariables,
  hasItems,
} from "src/helpers/utils";
import { CheckIcon, PencilIcon } from "@heroicons/react/outline";
import {
  ContactDetailRead,
  PatchedContactEdit,
} from "@hilos/types/private-schema";
import { useCallback, useMemo, useState } from "react";

import ContactInfoItem from "./ContactInfoItem";
import ContactInfoReorderItem from "./ContactInfoReorderItem";
import { Reorder } from "framer-motion";
import { useCustomFields } from "src/hooks/useContactCustomField";
import useHilosStore from "src/hooks/useHilosStore";
import { useTranslation } from "react-i18next";

interface ContactInfoProps {
  contact: ContactDetailRead;
  onUpdateContact: (data: Partial<PatchedContactEdit>) => void;
}

const HIDDEN_CONTACT_FIELDS = ["phone", "default_conversation_url"];

interface VisibleFields {
  [key: string]: boolean;
}

function ContactInfo({ contact, onUpdateContact }: ContactInfoProps) {
  const { t } = useTranslation();
  const { session, updateSession } = useHilosStore();
  const [visibleFields, setVisibleFields] = useState<VisibleFields>({});
  const [availableFields, setAvailableFields] = useState<string[]>([]);
  const [showAvailableFields, setShowAvailableFields] = useState(false);
  const { contactCustomFields } = useCustomFields();

  const fields = useMemo(() => {
    if (!session) {
      return [];
    }

    if (hasItems(session.inbox_contact_info_fields)) {
      return (session.inbox_contact_info_fields || []).filter(
        (id) => !HIDDEN_CONTACT_FIELDS.includes(id)
      );
    }

    return [...CONTACT_EDITABLE_FIELDS];
  }, [session]);

  const handleSaveAllowedFields = useCallback(() => {
    const nextInboxContactInfoFields = availableFields.reduce(
      (currentAllowedFields, fieldId) => {
        if (visibleFields[fieldId]) {
          currentAllowedFields.push(fieldId);
        }
        return currentAllowedFields;
      },
      [] as string[]
    );

    updateSession({
      inbox_contact_info_fields: nextInboxContactInfoFields,
    });
    setShowAvailableFields(false);
  }, [availableFields, visibleFields, updateSession]);

  const handleShowAvailableFields = useCallback(() => {
    const nextAvailableFields = (getContactVariables(contactCustomFields) || [])
      .filter((id) => !HIDDEN_CONTACT_FIELDS.includes(id))
      .sort((a, b) => {
        const indexA = fields.indexOf(a);
        const indexB = fields.indexOf(b);
        return (
          (indexA > -1 ? indexA : Infinity) - (indexB > -1 ? indexB : Infinity)
        );
      });

    setAvailableFields(nextAvailableFields);
    setVisibleFields(
      fields.reduce(
        (nextVisibleFields, field) => ({
          ...nextVisibleFields,
          [field]: true,
        }),
        {}
      )
    );
    setShowAvailableFields(true);
  }, [fields, contactCustomFields]);

  const handleChangeFields = useCallback((id) => {
    setVisibleFields((prevVisibleFields) => ({
      ...prevVisibleFields,
      [id]: !prevVisibleFields[id],
    }));
  }, []);

  if (!session) {
    return null;
  }

  return (
    <div className="mt-2 w-full">
      <div className="flex items-center px-3">
        <div className="flex-1 text-xs font-medium uppercase tracking-wide text-gray-500">
          {t("information", "Information")}
        </div>
        <div className="flex-shrink-0">
          <button
            className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
            type="button"
            onClick={(_) => {
              if (showAvailableFields) {
                handleSaveAllowedFields();
              } else {
                handleShowAvailableFields();
              }
            }}
          >
            {showAvailableFields ? (
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <PencilIcon className="h-4 w-4" aria-hidden="true" />
            )}
          </button>
        </div>
      </div>
      <div className="w-full space-y-1.5 overflow-hidden pt-2">
        {showAvailableFields ? (
          <Reorder.Group
            axis="y"
            values={availableFields}
            onReorder={setAvailableFields}
            className="flex flex-1 flex-col space-y-0.5 px-3 pt-0.5"
          >
            {availableFields.map((id, index) => (
              <ContactInfoReorderItem
                key={id}
                id={id}
                index={index}
                isVisible={visibleFields[id]}
                onChangeIsVisible={handleChangeFields}
              />
            ))}
          </Reorder.Group>
        ) : (
          fields.map((id) => (
            <ContactInfoItem
              key={id}
              id={id}
              contact={contact}
              onUpdateContact={onUpdateContact}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default ContactInfo;
