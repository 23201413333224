import { useState } from "react";
import { classNames } from "src/Helpers";

interface LineTabsProps {
  labels: string[];
  disabled?: boolean;
}

function LineTabs({
  labels,
  disabled = false,
  children,
}: React.PropsWithChildren<LineTabsProps>) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!children || !Array.isArray(children)) {
    return null;
  }

  return (
    <>
      <nav>
        <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
          {labels.map((label, currentIndex) => {
            const selected = currentIndex === selectedIndex;
            return (
              <li key={currentIndex} className="md:flex-1">
                <button
                  type="button"
                  onClick={(_) => !disabled && setSelectedIndex(currentIndex)}
                  className={classNames(
                    "group flex w-full flex-col border-l-4 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0",
                    selected
                      ? "border-blue-600"
                      : "border-gray-200 hover:border-gray-300",
                    disabled && "opacity-50 cursor-default"
                  )}
                  aria-current="step"
                >
                  <span
                    className={classNames(
                      "flex items-center text-tiny font-semibold uppercase tracking-wide",
                      selected ? "text-blue-600" : "text-gray-600"
                    )}
                  >
                    {label}
                  </span>
                </button>
              </li>
            );
          })}
        </ol>
      </nav>
      {children[selectedIndex]}
    </>
  );
}

export default LineTabs;
