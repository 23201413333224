import {
  DocumentTextIcon,
  MicrophoneIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import {
  WhatsAppMessage,
  WhatsAppMessageWithoutContext,
} from "@hilos/types/private-schema";
import InteractiveMessage from "src/containers/inbox/Conversation/MessageType/Interactive";
import UnsupportedMessage from "src/containers/inbox/Conversation/MessageType/UnsupportedMessage";
import ContactsMessage from "../containers/inbox/Conversation/MessageType/Contacts";
import LocationMessage from "../containers/inbox/Conversation/MessageType/Location";
import WhatsAppTemplateTextPreview from "../containers/wa/WhatsAppTemplateTextPreview";
import { getValuesFromTemplateContent } from "../helpers/template";

interface MessageRenderPreviewProps {
  message: WhatsAppMessage | WhatsAppMessageWithoutContext;
  isPreviewInMessage?: boolean;
}

function MessageRenderPreview({
  message,
  isPreviewInMessage = false,
}: MessageRenderPreviewProps) {
  if (message.msg_type === "text" || message.msg_type === "button") {
    return (
      <p className="whitespace-pre-wrap break-words text-left truncate line-clamp-2 text-gray-500">
        {message.body}
      </p>
    );
  }

  if (message.msg_type === "image") {
    return (
      <div className="flex items-center opacity-70">
        <img
          className="w-10 mr-2 h-10 rounded-lg object-cover"
          src={message.content_url ?? ""}
          alt={message.body ?? ""}
        />
        <p className="mt-2 whitespace-pre-wrap break-words text-left truncate line-clamp-2">
          {message.body}
        </p>
      </div>
    );
  }

  if (message.msg_type === "sticker") {
    return (
      <div className="flex items-center opacity-70">
        <img
          src={message.content_url ?? ""}
          className="w-10 mr-2 h-10 rounded-lg object-cover"
          alt={message.body ?? ""}
        />
        <p className="whitespace-pre-wrap break-words text-left truncate line-clamp-2">
          {message.body}
        </p>
      </div>
    );
  }

  if (message.msg_type === "document") {
    return (
      <div className="flex items-center opacity-70">
        <div className="h-7 w-7 flex items-center bg-white bg-opacity-60 rounded-md mr-2 p-1">
          <DocumentTextIcon className="h-4 w-4 text-gray-500" />
        </div>

        <p className="whitespace-pre-wrap break-words text-left truncate line-clamp-2">
          {message.body}
        </p>
      </div>
    );
  }

  if (message.msg_type === "voice") {
    return (
      <div className="flex items-center opacity-70">
        <div className="h-7 w-7 flex items-center bg-white bg-opacity-60 rounded-md mr-2 p-1">
          <MicrophoneIcon className="h-4 w-4 text-gray-500" />
        </div>
        <p className="whitespace-pre-wrap break-words text-left truncate line-clamp-2">
          {message.body}
        </p>
      </div>
    );
  }

  if (message.msg_type === "video") {
    return (
      <div className="flex items-center opacity-70">
        <div className="h-7 w-7 flex items-center bg-white bg-opacity-60 rounded-md mr-2 p-1">
          <PlayIcon className="h-4 w-4 text-gray-500" />
        </div>
        <p className="whitespace-pre-wrap break-words text-left truncate line-clamp-2">
          {message.body}
        </p>
      </div>
    );
  }

  if (message.msg_type === "audio") {
    return (
      <div className="flex items-center opacity-70">
        <div className="h-7 w-7 flex items-center bg-white bg-opacity-60 rounded-md mr-2 p-1">
          <PlayIcon className="h-4 w-4 text-gray-500" />
        </div>
        <p className="whitespace-pre-wrap break-words text-left truncate line-clamp-2">
          {message.body}
        </p>
      </div>
    );
  }

  if (message.msg_type === "location") {
    return <LocationMessage message={message} isPreview />;
  }

  if (message.msg_type === "contacts") {
    return <ContactsMessage message={message} isPreview />;
  }

  if (message.msg_type === "template" && message.whatsapp_template) {
    return (
      <WhatsAppTemplateTextPreview
        template={message.whatsapp_template}
        values={getValuesFromTemplateContent(message.content)}
        isPreviewInMessage={isPreviewInMessage}
      />
    );
  }

  if (message.msg_type === "interactive") {
    return <InteractiveMessage message={message} isPreview />;
  }

  if (message.msg_type === "unknown" || message.msg_type === "unsupported") {
    return <UnsupportedMessage message={message} isPreview />;
  }

  return null;
}

export default MessageRenderPreview;
