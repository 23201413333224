import { useTranslation } from "react-i18next";
import { ResultEnum } from "@hilos/types/private-schema";

interface ContactImportDataResultBadgeProps {
  result: ResultEnum;
}
export default function ContactImportDataResultBadge({
  result,
}: ContactImportDataResultBadgeProps) {
  const { t } = useTranslation();
  switch (result) {
    case "CREATED":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium text-green-800">
          {t(result)}
        </span>
      );
    case "UPDATED":
      return (
        <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-xs font-medium text-blue-800">
          {t(result)}
        </span>
      );
    case "IGNORED":
      return (
        <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-xs font-medium text-yellow-800">
          {t(result)}
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800">
          {result}
        </span>
      );
  }
}
