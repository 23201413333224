import { useCallback, useMemo, useState } from "react";

export type handleActionButtonFn<T, P> = (data: T) => Promise<P>

interface useCreateStateButtonArgs<T, P> {
  handleActionButton: handleActionButtonFn<T, P>;
}

export const stateButtonBtnClasses = {
  btnClassesSuccess:
    "focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-100 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm",
  btnClassesError:
    "focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-50 px-3 py-2 text-sm font-medium leading-4 text-yellow-800 shadow-sm",
};

function useCreateStateButton<T, P = unknown>({
  handleActionButton,
}: useCreateStateButtonArgs<T, P>) {
  // Cancel Btn variables
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const stateBtn = useMemo(
    () => ({
      success,
      setSuccess,
      submitted,
      setSubmitted,
      isSubmitting,
      setIsSubmitting,
      disabled,
      setDisabled,
    }),
    [disabled, isSubmitting, submitted, success]
  );

  const handleChangeStatus = useCallback(
    (data: T) => {
      return async () => {
        let error: unknown | null | undefined;
        let response: P | null | undefined;
        try {
          stateBtn.setIsSubmitting(true);
          response = await handleActionButton(data);
          stateBtn.setIsSubmitting(false);
          stateBtn.setSuccess(true);
        } catch (e) {
          stateBtn.setSuccess(false);
          // Sentry.captureException(e)
          console.error(e);
          error = e;
        } finally {
          stateBtn.setIsSubmitting(false);
          stateBtn.setSubmitted(true);
          setTimeout(() => {
            stateBtn.setSubmitted(false);
          }, 1000 * 2);
          if (error) {
            throw error;
          }
        }
        return response;
      };
    },
    [handleActionButton, stateBtn]
  );

  return [stateBtn, handleChangeStatus] as [
    typeof stateBtn,
    typeof handleChangeStatus
  ];
}

export default useCreateStateButton;

export const createStateButton = useCreateStateButton;
