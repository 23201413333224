import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import {
  ClipboardListIcon,
  LightningBoltIcon,
  XIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import QuickReplyTemplateSelect from "src/containers/inbox/Conversation/QuickReplyTemplateSelect";
import StateControlButton from "src/components/StateControlButton";
import APIErrors from "../../../components/base/APIErrors";
import { API_ROUTES, buildAPIRoute } from "../../../router/router";
import {
  CATEGORIES,
  getTemplateVarsFromTemplate,
} from "../../wa/WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "../../wa/WhatsAppTemplatePreview";
import WhatsAppTemplatePreviewForm, {
  initialTemplatePreviewValues,
} from "../../wa/WhatsAppTemplatePreviewForm";
import WhatsAppTemplateSelect from "../../wa/WhatsAppTemplateSelect";
import QuickRepliesModal from "./QuickRepliesModal";

function BottomBarTemplateSend({ inboxContact }) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialTemplatePreviewValues);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [tab, setTab] = useState("menu");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const templateHasVars = useMemo(() => {
    if (!selectedTemplate) {
      return false;
    }
    return getTemplateVarsFromTemplate(selectedTemplate).length > 0;
  }, [selectedTemplate]);

  const cancelButtonRef = useRef(null);

  const handleSelectTemplate = useCallback(
    (template) => {
      setSelectedTemplate(template);
      setFormValues({ ...initialTemplatePreviewValues });
    },
    [setSelectedTemplate]
  );

  const sendTemplateMessage = async () => {
    if (!selectedTemplate) {
      return;
    }
    const variableData = [
      formValues.headerURL,
      formValues.headerVar,
      ...formValues.bodyVars,
      ...formValues.buttonVars,
    ].filter((x) => x);
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.INBOX_WHATSAPP_TEMPLATE_SEND, {
          ":id": selectedTemplate.id,
        }),
        { phone: inboxContact.contact.phone, variables: variableData }
      );
      setSelectedTemplate(undefined);
      setFormValues(initialTemplatePreviewValues);
      setOpen(false);
    } catch (err) {
      if (err?.response?.status === 400) {
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          "An error occurred while sending your message. Please try again."
        );
      }
      // The exception needs to be thrown again for the StateControlButton
      // be able to detect that the action failed
      throw err;
    }
  };

  const handleClose = () => {
    setSelectedTemplate(undefined);
    setOpen(false);
    setTab("menu");
  };

  const FIELDS = {
    phone: {
      label: "Contact",
    },
    variables: {
      label: "Variables",
    },
  };

  const handleCleanUp = useCallback(() => {
    setOpen(false);
    setSelectedTemplate(undefined);
    setBackendError("");
    setBackendValidationErrors({});
  }, []);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 block pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleClose}
                    ref={cancelButtonRef}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {tab === "menu" && (
                  <>
                    <div className="sm:flex">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ClipboardListIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="text-lg font-medium leading-6 text-gray-900">
                          {t(
                            "inbox:select-type-template-send",
                            "Select a type of template to send"
                          )}
                        </div>
                        <div className="my-4">
                          <APIErrors
                            APIError={backendError}
                            APIValidationErrors={backendValidationErrors}
                            fieldTranslations={FIELDS}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 flex gap-2">
                      <button
                        type="button"
                        className="flex w-full items-center justify-center rounded-md border border-gray-400 bg-transparent px-4 py-4 font-medium text-gray-600 "
                        onClick={() => {
                          setTab("quick-reply");
                          setOpen(true);
                        }}
                      >
                        <LightningBoltIcon
                          className="mr-3 h-6 w-6"
                          aria-hidden="true"
                        />
                        {t("inbox:prefilled-template", "Prefilled Template")}
                      </button>
                      <button
                        type="button"
                        className="flex w-full items-center justify-center rounded-md border border-gray-400 bg-transparent px-4 py-4 font-medium text-gray-600 "
                        onClick={() => {
                          setTab("template");
                        }}
                      >
                        <ClipboardListIcon
                          className="mr-3 h-6 w-6"
                          aria-hidden="true"
                        />
                        {t("inbox:new-template", "New Template")}
                      </button>
                    </div>
                  </>
                )}
                {tab === "template" && (
                  <>
                    <div className="sm:flex">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ClipboardListIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="text-lg font-medium leading-6 text-gray-900">
                          {t(
                            "inbox:select-template",
                            "Select a template to send"
                          )}
                        </div>
                        <div className="my-4">
                          <APIErrors
                            APIError={backendError}
                            APIValidationErrors={backendValidationErrors}
                            fieldTranslations={FIELDS}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          whatsapp_template: "",
                        }}
                      >
                        {() => (
                          <form className="space-y-4">
                            <WhatsAppTemplateSelect
                              setSelectedTemplate={handleSelectTemplate}
                              fieldName="whatsapp_template"
                              channel={inboxContact.channel.id}
                              categories={Object.getOwnPropertyNames(
                                CATEGORIES
                              ).filter(
                                (category) =>
                                  category !== "AUTHENTICATION" &&
                                  category !== "OTP"
                              )}
                            />
                          </form>
                        )}
                      </Formik>
                    </div>
                    {selectedTemplate && (
                      <>
                        <div className="mt-8 text-center">
                          {templateHasVars && (
                            <p className="text-sm text-gray-500">
                              {t(
                                "inbox:template-with-variables",
                                "This template has variables, set the values you want to use for this message."
                              )}
                            </p>
                          )}
                        </div>

                        <div
                          className={`mt-4 grid grid-cols-1 gap-6 ${
                            templateHasVars ? "sm:grid-cols-2" : ""
                          }`}
                        >
                          {templateHasVars && (
                            <div>
                              <WhatsAppTemplatePreviewForm
                                template={selectedTemplate}
                                initialValues={formValues}
                                onTemplateValuesChange={setFormValues}
                              />
                            </div>
                          )}
                          <div>
                            <WhatsAppTemplatePreview
                              template={selectedTemplate}
                              values={formValues}
                            />
                          </div>
                        </div>

                        <div className="mt-8 sm:mt-6 sm:flex sm:flex-row-reverse">
                          <StateControlButton
                            btnClasses="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={sendTemplateMessage}
                            submittingText={t("sending", "Sending...")}
                            successText={t("sent", "Sent")}
                            initialText={
                              <>
                                {t("send", "Send")}
                                <FontAwesomeIcon
                                  icon={faPaperPlane}
                                  className="ml-1"
                                />
                              </>
                            }
                            onSuccess={handleCleanUp}
                          />
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => {
                              setOpen(false);
                              setSelectedTemplate(undefined);
                              setBackendError("");
                              setBackendValidationErrors({});
                            }}
                          >
                            {t("cancel", "Cancel")}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
                {tab === "quick-reply" && (
                  <>
                    <div className="sm:flex">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ClipboardListIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex items-center self-center text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="text-lg font-medium leading-6 text-gray-900">
                          {t(
                            "inbox:select-template",
                            "Select a template to send"
                          )}
                        </div>
                        <div className="my-4">
                          <APIErrors
                            APIError={backendError}
                            APIValidationErrors={backendValidationErrors}
                            fieldTranslations={FIELDS}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          whatsapp_template: "",
                        }}
                      >
                        {(formik) => (
                          <form className="space-y-4">
                            <QuickReplyTemplateSelect
                              fieldName="whatsapp_template"
                              onClose={handleClose}
                              inboxContact={inboxContact}
                              setShowQRModal={setShowQRModal}
                              formik={formik}
                            />
                          </form>
                        )}
                      </Formik>
                    </div>
                    {selectedTemplate && (
                      <>
                        <div className="mt-8 text-center">
                          {templateHasVars && (
                            <p className="text-sm text-gray-500">
                              {t(
                                "inbox:template-with-variables",
                                "This template has variables, set the values you want to use for this message."
                              )}
                            </p>
                          )}
                        </div>

                        <div
                          className={`mt-4 grid grid-cols-1 gap-6 ${
                            templateHasVars ? "sm:grid-cols-2" : ""
                          }`}
                        >
                          {templateHasVars && (
                            <div>
                              <WhatsAppTemplatePreviewForm
                                key={selectedTemplate.id}
                                template={selectedTemplate}
                                onTemplateValuesChange={setFormValues}
                              />
                            </div>
                          )}
                          <div>
                            <WhatsAppTemplatePreview
                              template={selectedTemplate}
                              values={formValues}
                            />
                          </div>
                        </div>

                        <div className="mt-8 sm:mt-6 sm:flex sm:flex-row-reverse">
                          <StateControlButton
                            btnClasses="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={sendTemplateMessage}
                            submittingText={t("sending", "Sending...")}
                            successText={t("sent", "Sent")}
                            initialText={
                              <>
                                {t("send", "Send")}
                                <FontAwesomeIcon
                                  icon={faPaperPlane}
                                  className="ml-1"
                                />
                              </>
                            }
                            onSuccess={handleCleanUp}
                          />
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={handleCleanUp}
                          >
                            {t("cancel", "Cancel")}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="min-w-0">
        <div className="relative">
          <div className="overflow-hidden rounded-lg border border-gray-300 bg-white text-gray-400 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
            <button
              className="w-full py-6 text-center"
              onClick={(_) => setOpen(true)}
            >
              {t(
                "inbox:start-template",
                "Select a template to open the conversation"
              )}
            </button>
          </div>
        </div>
      </div>
      <QuickRepliesModal
        inboxContact={inboxContact}
        show={showQRModal}
        onClose={() => {
          setShowQRModal(false);
        }}
      />
    </>
  );
}

export default BottomBarTemplateSend;
