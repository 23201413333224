import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { classNames } from "src/Helpers";

export default function ActionCard({ action }) {
  const { t } = useTranslation();

  return (
    <div
      key={action.title}
      className={
        " group relative order-last rounded-lg border shadow bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:col-span-3 md:col-span-3 lg:order-none"
      }
    >
      <div>
        <Link to={action.href} className="focus:outline-none">
          <span
            className={classNames(
              action.iconBackground,
              action.iconForeground,
              "inline-flex rounded-lg p-3 ring-4 ring-white"
            )}
          >
            {action.icon}
          </span>
        </Link>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium">
          <Link to={action.href} className="focus:outline-none">
            {t(action.title)}
          </Link>
        </h3>
        <div className="mt-2 space-y-2 text-sm text-gray-500">
          {action.text}
        </div>
        {action.actionButton && (
          <div className="mt-4 text-sm text-gray-500">
            {action.actionButton}
          </div>
        )}
      </div>
      <Link to={action.href} className="focus:outline-none">
        <span
          className="absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
          aria-hidden="true"
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
          </svg>
        </span>
      </Link>
    </div>
  );
}
