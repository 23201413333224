import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ShareIcon,
  SortAscendingIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";
import {
  FlowExecutionContactReadDetail,
  FlowExecutionStepReadDetail,
} from "@hilos/types/private-schema";
import Tabs, { TabsRef } from "src/components/Tabs";
import useFlowVersion from "src/hooks/useFlowVersion";
import FlowDetail from "../builder/FlowDetail";
import FlowExecutionContactExecutionStepsDetail from "./FlowExecutionContactExecutionStepsDetail";
import FlowExecutionContactStepsDetail from "./FlowExecutionContactStepsDetail";

interface FlowExecutionContactStepListProps {
  flowExecutionContact: FlowExecutionContactReadDetail;
}

function FlowExecutionContactStepList({
  flowExecutionContact,
}: FlowExecutionContactStepListProps) {
  const { t } = useTranslation();
  const tabsRef = useRef<TabsRef | null>(null);

  const [selectedExecutionStep, setSelectedExecutionStep] = useState<
    FlowExecutionStepReadDetail | undefined
  >(undefined);

  const { data: flowVersion } = useFlowVersion(
    flowExecutionContact.flow_version.id as string
  );

  const selectExecutionStep = (executionStep) => {
    setSelectedExecutionStep(executionStep);

    if (tabsRef.current) {
      tabsRef.current.focus(2);
    }
  };

  if (!flowVersion) {
    return null;
  }

  return (
    <Tabs
      ref={(ref) => (tabsRef.current = ref)}
      tabs={[
        {
          label: t("flow-viewer", "Flow Viewer"),
          icon: ViewGridIcon,
        },
        {
          label: t("flow-executions:step-list.tabs.flow-steps", "Flow Steps"),
          icon: ShareIcon,
        },
        {
          label: t(
            "flow-executions:step-list.tabs.executed-steps",
            "Executed Steps"
          ),
          icon: SortAscendingIcon,
        },
      ]}
      className="px-4 sm:px-6"
    >
      <FlowDetail
        flowVersion={flowVersion}
        flowExecutionId={
          flowExecutionContact.flow_execution
            ? flowExecutionContact.flow_execution
            : undefined
        }
        flowExecutionContactId={flowExecutionContact.id}
      />
      <FlowExecutionContactStepsDetail
        flowVersion={flowVersion}
        flowExecutionContact={flowExecutionContact}
        selectExecutionStep={selectExecutionStep}
      />
      <FlowExecutionContactExecutionStepsDetail
        flowVersion={flowVersion}
        flowExecutionContact={flowExecutionContact}
        selectedExecutionStep={selectedExecutionStep}
        setSelectedExecutionStep={setSelectedExecutionStep}
      />
    </Tabs>
  );
}

export default FlowExecutionContactStepList;
