import { Trans } from "react-i18next";
import {
  BanIcon,
  CalendarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  FolderIcon,
  GlobeAltIcon,
  KeyIcon,
  LightningBoltIcon,
  LocationMarkerIcon,
  PhoneIcon,
  PhotographIcon,
  PresentationChartLineIcon,
  ReplyIcon,
  TicketIcon,
  TruckIcon,
  UserCircleIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import * as yup from "yup";
import {
  BodyComponent,
  ButtonsComponent,
  HeaderComponent,
  URLButtonComponent,
  WhatsAppTemplate,
} from "@hilos/types/wa/templates";
import i18n, { getTranslationPayload as t } from "src/i18n";
import { ERROR_MESSAGES } from "../../constants/Form";
import { getTemplateComponents } from "../../helpers/template";

export const getInitialValues = (account) => {
  return {
    name: "new_template",
    channel: account ? account.default_channel : "",
    category: "",
    language: "es",
    allow_category_change: true,
    components: [
      initialHeaderValues,
      initialBodyValues,
      initialFooterValues,
      initialButtonsValues,
    ],
  };
};

export const getSchemaExamplesTemplateVars = (template: WhatsAppTemplate) => {
  return yup
    .object()
    .test(
      i18n.t("templates:provide-example.titles", "Provide all examples"),
      i18n.t(
        "templates:provide-example.body",
        "You must provide all the examples for your template."
      ),
      (value) => {
        const templateExamples: object = value;

        var examplesCount = 0;
        if (templateExamples) {
          Object.keys(templateExamples).forEach((exampleKey) => {
            if (["bodyVars", "buttonVars"].includes(exampleKey)) {
              templateExamples[exampleKey].forEach((example) => {
                if (example) {
                  examplesCount++;
                }
              });
            } else if (exampleKey !== "locationHeaderVars") {
              if (templateExamples[exampleKey]) {
                examplesCount++;
              }
            }
          });
        }

        if (template.category !== "AUTHENTICATION") {
          return (
            examplesCount === getTemplateVarsFromTemplate(template, true).length
          );
        }
        return true;
      }
    );
};

// Components Schemas
const evalIsDirectLinkWA = (string) => {
  const WA_DIRECT_LINKS_DOMAINS = ["wa.me", "api.whatsapp.com"];

  const domain = string.match(/^(?:https?:\/\/)?([^\/]+)/i)[1];

  return domain ? WA_DIRECT_LINKS_DOMAINS.includes(domain) : false;
};

export const CallToActionBtnSchema = {
  getSchema: function () {
    return yup.object().shape({
      text: yup
        .string()
        .required(ERROR_MESSAGES.required)
        .max(25, ERROR_MESSAGES.max),
      type: yup.string().required(ERROR_MESSAGES.required),
      // Phone
      phone_number: yup.string().when("type", {
        is: "PHONE_NUMBER",
        then: yup
          .string()
          .required(ERROR_MESSAGES.required)
          .max(20, ERROR_MESSAGES.max),
      }),
      // URL
      url: yup.string().when("type", {
        is: "URL",
        then: yup
          .string()
          .required(ERROR_MESSAGES.required)
          .max(2000, ERROR_MESSAGES.max)
          .test(
            i18n.t(
              "templates:errors.direct-link-wa.title",
              "Direct link to WA"
            ),
            i18n.t(
              "templates:errors.direct-link-wa.description",
              "Direct links to WhatsApp are not allowed in buttons."
            ),
            (value) => {
              return !evalIsDirectLinkWA(value);
            }
          ),
      }),
    });
  },
};

export const QuickReplyBtnSchema = {
  getSchema: function () {
    return yup.object().shape({
      text: yup
        .string()
        .required(ERROR_MESSAGES.required)
        .max(25, ERROR_MESSAGES.max),
    });
  },
};

const evalRegex = (string, regex) => {
  const result = regex.test(string);
  return !result;
};

export function urlImageIsValid(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
}

export const ComponentSchema = {
  getSchema: function () {
    return yup.object().shape({
      text: yup
        .string()
        .when("type", {
          is: "BODY",
          then: yup
            .string()
            .max(1024, ERROR_MESSAGES.max)
            .required(ERROR_MESSAGES.required)
            .test(
              i18n.t(
                "templates:errors.vars-at-beginning.title",
                "Vars at beggining"
              ),
              i18n.t(
                "templates:errors.vars-at-beginning.description",
                "The body text cannot contains variable parameters at the beginning."
              ),
              (value) => {
                return evalRegex(value, /^\{{(\d+)\}}/g);
              }
            )
            .test(
              i18n.t("templates:errors.vars-at-end.title", "Vars at end"),
              i18n.t(
                "templates:errors.vars-at-end.description",
                "The body text cannot contains variable parameters at the end."
              ),
              (value) => {
                return evalRegex(value, /\{{(\d+)\}}$/g);
              }
            )
            .test(
              i18n.t(
                "templates:errors.vars-next-to-each-other.title",
                "Vars next to each other"
              ),
              i18n.t(
                "templates:errors.vars-next-to-each-other.description",
                "The body text cannot contains variables next to each other."
              ),
              (value) => {
                return evalRegex(value, /\{{(\d+)\}}\s*\{{(\d+)\}}/g);
              }
            )
            .test(
              i18n.t(
                "templates:errors.line-break-number-exceeded.title",
                "Number of consecutive line breaks exceeded"
              ),
              i18n.t(
                "templates:errors.line-break-number-exceeded.description",
                "The body text cannot contain more than 2 consecutive line breaks."
              ),
              (value) => {
                return evalRegex(value, /[\n]{4,}/g);
              }
            ),
        })
        .when(["type", "format"], {
          is: (type, format) => type === "HEADER" && format === "TEXT",
          then: yup
            .string()
            .max(60, ERROR_MESSAGES.max)
            .required(ERROR_MESSAGES.required),
        }),
      format: yup.string().when("type", {
        is: "HEADER",
        then: yup
          .string()
          .required(ERROR_MESSAGES.required)
          .when(["type", "format", "category"], {
            is: (type, format, category) =>
              category !== "UTILITY" &&
              category !== "MARKETING" &&
              type === "HEADER" &&
              format === "LOCATION",
            then: yup
              .string()
              .required(
                "Location header is only available for UTILITY and MARKETING categories."
              ),
          }),
      }),
    });
  },
};

export const AuthenticationComponentSchema = {
  getSchema: function () {
    return yup.object().shape({
      add_security_recommendation: yup.bool(),
      code_expiration_minutes: yup
        .number()
        .nullable()
        .max(90, ERROR_MESSAGES.maxNumberValue)
        .min(1, ERROR_MESSAGES.minNumberValue),
    });
  },
};

export const schema = yup.lazy((values) => {
  return yup.object().shape({
    name: yup.string().required(ERROR_MESSAGES.required),
    category: yup.string().required(ERROR_MESSAGES.required),
    language: yup.string().required(ERROR_MESSAGES.required),
    components: yup.array().when("category", {
      is: "AUTHENTICATION",
      then: yup.array().of(AuthenticationComponentSchema.getSchema()),
      otherwise: yup.array().of(ComponentSchema.getSchema()),
    }),

    examples: getSchemaExamplesTemplateVars(values),
  });
});

export const getTemplateShortPreview = (template) => {
  if (!template) {
    return;
  }
  if (template.components) {
    const header = template.components.find((c) => c.type === "HEADER");
    if (header?.text) {
      return header.text;
    }
    const body = template.components.find((c) => c.type === "BODY");
    if (body?.text) {
      return body.text;
    }
  }
  return i18n.t("preview-not-available", "Preview not available.");
};

export const templateVarRegex = /{{\w{1,2}}}/g;

export const getTemplateVarsFromString = (templateBody) => {
  if (templateBody) {
    return templateBody.match(templateVarRegex)
      ? templateBody.match(templateVarRegex)
      : [];
  }
  return [];
};

export const getVariablesRequiredFromTemplate = (
  template?: WhatsAppTemplate
) => {
  const {
    header,
    body,
    buttons,
  }: {
    header: HeaderComponent | null;
    body: BodyComponent | null;
    buttons: ButtonsComponent | null;
  } = getTemplateComponents(template);

  const headerRequiresURL =
    header && ["IMAGE", "DOCUMENT", "VIDEO"].includes(header.format);
  const isHeaderLocation = header && header.format === "LOCATION";
  const headerRequiresVariable =
    header &&
    header.format === "TEXT" &&
    getTemplateVarsFromString(header.text).length > 0;
  let bodyVariables = getTemplateVarsFromString(body ? body.text : "");
  var buttonsVariables: string[] = [];
  if (buttons) {
    buttons.buttons.forEach((b) => {
      if (b.type === "URL" && getTemplateVarsFromString(b.url).length > 0) {
        buttonsVariables.push(b.text);
      }
    });
  }
  const otpButton = buttons
    ? buttons.buttons.find((b) => b.type === "OTP")
    : undefined;
  if (otpButton) {
    bodyVariables = ["{{ OTP Code}}"];
  }

  return {
    headerRequiresURL,
    isHeaderLocation,
    headerRequiresVariable,
    bodyVariables,
    buttonsVariables,
  };
};

export const getTemplateVarsFromTemplate = (
  template: WhatsAppTemplate,
  isExample = false
) => {
  // According to 360Dialog's editor,
  // - HEADER can only have 1 variable,
  // - BODY can have several variables,
  // - FOOTER can't have variables.
  // - BUTTONS can have 1 var if of type `URL`.
  const {
    header,
    body,
    buttons,
  }: {
    header: HeaderComponent | null;
    body: BodyComponent | null;
    buttons: ButtonsComponent | null;
  } = getTemplateComponents(template);

  const headerRequiresURL =
    header && ["IMAGE", "DOCUMENT", "VIDEO"].includes(header.format);
  const headerRequiresVariable =
    header &&
    header.format === "TEXT" &&
    getTemplateVarsFromString(header.text).length > 0;
  const isHeaderLocation = header && header.format === "LOCATION";

  const bodyVariables = getTemplateVarsFromString(body?.text);

  var urlButtonVars: string[] = [];

  buttons?.buttons.forEach((btn) => {
    if (
      btn.type === "URL" &&
      getTemplateVarsFromString((btn as URLButtonComponent).url).length > 0
    ) {
      urlButtonVars.push(
        `{{URL for button "${(btn as URLButtonComponent).text}"}}`
      );
    }
  });

  const otpButton = buttons
    ? buttons.buttons.find((b) => b.type === "OTP")
    : undefined;
  const buttonVariables =
    urlButtonVars.length > 0
      ? urlButtonVars
      : otpButton
      ? ["{{ OTP Code}}"]
      : [];

  const allVars = [...bodyVariables];
  if (headerRequiresURL) {
    allVars.unshift(`{{URL for header ${header.format.toLocaleLowerCase()}}}`);
  } else if (isHeaderLocation && !isExample) {
    allVars.unshift("{{Address}}");
    allVars.unshift("{{Name}}");
    allVars.unshift("{{Longitude}}");
    allVars.unshift("{{Latitude}}");
  } else if (headerRequiresVariable) {
    allVars.unshift("{{Header: 1}}");
  }
  if (buttonVariables.length > 0) {
    allVars.push(...buttonVariables);
  }

  return allVars;
};

export interface Category {
  value: string;
  label: string;
  icon: JSX.Element;
}

export interface SELECT_OPTION {
  value: string;
  label: string;
  icon: JSX.Element;
  help?: string;
}

export interface AvailableCategories {
  ACCOUNT_UPDATE: Category;
  PAYMENT_UPDATE: Category;
  PERSONAL_FINANCE_UPDATE: Category;
  SHIPPING_UPDATE: Category;
  RESERVATION_UPDATE: Category;
  ISSUE_RESOLUTION: Category;
  APPOINTMENT_UPDATE: Category;
  TRANSPORTATION_UPDATE: Category;
  TICKET_UPDATE: Category;
  ALERT_UPDATE: Category;
  AUTO_REPLY: Category;
  TRANSACTIONAL: Category;
  MARKETING: Category;
  UTILITY: Category;
  OTP: Category;
  AUTHENTICATION: Category;
}

export const CATEGORIES = {
  ACCOUNT_UPDATE: {
    value: "ACCOUNT_UPDATE",
    // i18n.t("templates:categories.account-update.label", "Account Update"),
    label: "templates:categories.account-update.label",
    icon: <UserCircleIcon className="mr-2 h-5 w-5 " />,
  },
  PAYMENT_UPDATE: {
    value: "PAYMENT_UPDATE",
    // i18n.t("templates:categories.payment-update.label", "Payment Update"),
    label: "templates:categories.payment-update.label",
    icon: <CurrencyDollarIcon className="mr-2 h-5 w-5 " />,
  },
  PERSONAL_FINANCE_UPDATE: {
    value: "PERSONAL_FINANCE_UPDATE",
    // i18n.t("templates:categories.personal-finance-update.label", "Personal Finance Update"),
    label: "templates:categories.personal-finance-update.label",
    icon: <PresentationChartLineIcon className="mr-2 h-5 w-5 " />,
  },
  SHIPPING_UPDATE: {
    value: "SHIPPING_UPDATE",
    // i18n.t("templates:categories.shipping-update.label", "Shipping Update"),
    label: "templates:categories.shipping-update.label",
    icon: <TruckIcon className="mr-2 h-5 w-5 " />,
  },
  RESERVATION_UPDATE: {
    value: "RESERVATION_UPDATE",
    // i18n.t("templates:categories.reservation-update.label", "Reservation Update"),
    label: "templates:categories.reservation-update.label",
    icon: <CalendarIcon className="mr-2 h-5 w-5 " />,
  },
  ISSUE_RESOLUTION: {
    value: "ISSUE_RESOLUTION",
    // i18n.t("templates:categories.issue-resolution.label", "Issue Resolution"),
    label: "templates:categories.issue-resolution.label",
    icon: <ExclamationCircleIcon className="mr-2 h-5 w-5 " />,
  },
  APPOINTMENT_UPDATE: {
    value: "APPOINTMENT_UPDATE",
    // i18n.t("templates:categories.appointment-update.label", "Appointment Update"),
    label: "templates:categories.appointment-update.label",
    icon: <ClockIcon className="mr-2 h-5 w-5 " />,
  },
  TRANSPORTATION_UPDATE: {
    value: "TRANSPORTATION_UPDATE",
    // i18n.t("templates:categories.transportation-update.label", "Transportation Update"),
    label: "templates:categories.transportation-update.label",
    icon: <TruckIcon className="mr-2 h-5 w-5 " />,
  },
  TICKET_UPDATE: {
    value: "TICKET_UPDATE",
    // i18n.t("templates:categories.ticket-update.label", "Ticket Update"),
    label: "templates:categories.ticket-update.label",
    icon: <TicketIcon className="mr-2 h-5 w-5 " />,
  },
  ALERT_UPDATE: {
    value: "ALERT_UPDATE",
    // i18n.t("templates:categories.alert-update.label", "Alert Update"),
    label: "templates:categories.alert-update.label",
    icon: <ExclamationIcon className="mr-2 h-5 w-5 " />,
  },
  AUTO_REPLY: {
    value: "AUTO_REPLY",
    // i18n.t("templates:categories.auto-reply.label", "Auto Reply"),
    label: "templates:categories.auto-reply.label",
    icon: <ReplyIcon className="mr-2 h-5 w-5 " />,
  },
  // New template types
  TRANSACTIONAL: {
    value: "TRANSACTIONAL",
    // i18n.t("templates:categories.transactional.label", "Transactional"),
    label: "templates:categories.transactional.label",
    // i18n.t("templates:categories.transactional.help", "Send account updates, order updates, alerts, and more to share important information."),
    help: "templates:categories.transactional.help",
    icon: <LightningBoltIcon className="mr-2 h-5 w-5 " />,
    available: false,
  },
  UTILITY: {
    value: "UTILITY",
    // i18n.t("templates:categories.utility.label", "Utility"),
    label: "templates:categories.utility.label",
    // i18n.t("templates:categories.utility.help", "Send account updates, order updates, alerts, and more for an existing customer."),
    help: "templates:categories.utility.help",
    icon: <LightningBoltIcon className="mr-2 h-5 w-5 " />,
    available: true,
  },
  MARKETING: {
    value: "MARKETING",
    // i18n.t("templates:categories.marketing.label", "Marketing"),
    label: "templates:categories.marketing.label",
    // i18n.t("templates:categories.marketing.help", "Send promotional offers, product announcements, and more to increase awareness and engagement."),
    help: "templates:categories.marketing.help",
    icon: <PresentationChartLineIcon className="mr-2 h-5 w-5 " />,
    available: true,
  },
  OTP: {
    value: "OTP",
    // i18n.t("templates:categories.otp.label", "One-Time Password"),
    label: "templates:categories.otp.label",
    // i18n.t("templates:categories.otp.help", "Send codes that allow your customers to access their accounts."),
    help: "templates:categories.otp.help",
    icon: <KeyIcon className="mr-2 h-5 w-5 " />,
    available: false,
  },
  AUTHENTICATION: {
    value: "AUTHENTICATION",
    // i18n.t("templates:categories.authentication.label", "Authentication"),
    label: "templates:categories.authentication.label",
    // i18n.t("templates:categories.authentication.help", "Send codes to verify a transaction or login"),
    help: "templates:categories.authentication.help",
    icon: <KeyIcon className="mr-2 h-5 w-5 " />,
    available: true,
  },
};

export const FORMAT_TYPES: SELECT_OPTION[] = [
  {
    value: "NONE",
    // i18n.t("none", "None"),
    label: "none",
    icon: <BanIcon className="mr-2 h-5 w-5 " />,
  },
  {
    value: "TEXT",
    // i18n.t("text", "Text"),
    label: "text",
    // i18n.t("templates:format-types.text.help", "Text type, can contain a variable."),
    help: "templates:format-types.text.help",
    icon: <DocumentTextIcon className="mr-2 h-5 w-5 " />,
  },
  {
    value: "IMAGE",
    // i18n.t("image", "Image"),
    label: "image",
    // i18n.t("templates:format-types.image.help", "Add a single image")
    help: "templates:format-types.image.help",
    icon: <PhotographIcon className="mr-2 h-5 w-5 " />,
  },
  {
    value: "VIDEO",
    // i18n.t("video", "Video"),
    label: "video",
    // i18n.t("templates:format-types.video.help", "Add a video")
    help: "templates:format-types.video.help",
    icon: <VideoCameraIcon className="mr-2 h-5 w-5 " />,
  },
  {
    value: "DOCUMENT",
    // i18n.t("document", "Document"),
    label: "document",
    // i18n.t("templates:format-types.document.help", "Attach a document")
    help: "templates:format-types.document.help",
    icon: <FolderIcon className="mr-2 h-5 w-5 " />,
  },
  {
    value: "LOCATION",
    // i18n.t("location", "Location"),
    label: "location",
    // i18n.t("templates:format-types.location.help", "Add a location")
    help: "templates:format-types.location.help",
    icon: <LocationMarkerIcon className="mr-2 h-5 w-5 " />,
  },
];

export const BUTTON_TYPES: { [key: string]: SELECT_OPTION } = {
  QUICK_REPLY: {
    value: "QUICK_REPLY",
    // i18n.t("quick-reply", "Quick Reply"),
    label: "quick-reply",
    // i18n.t("templates:button-types.quick-reply.help", "Add buttons that allows users to reply to your message.")
    help: "templates:button-types.quick-reply.help",
    icon: <LightningBoltIcon className="mr-2 h-5 w-5 " />,
  },
  URL: {
    value: "URL",
    // i18n.t("visit-site", "Visit Website"),
    label: "visit-site",
    // i18n.t("templates:call-to-action-types.url.help", "Allow users to visit your website.")
    help: "templates:call-to-action-types.url.help",
    icon: <GlobeAltIcon className="mr-2 h-5 w-5 " />,
  },
  PHONE_NUMBER: {
    value: "PHONE_NUMBER",
    // i18n.t("call-phone-number", "Call phone number"),
    label: "call-phone-number",
    // i18n.t("templates:call-to-action-types.phone-number.help", "Allow users to call you.")
    help: "templates:call-to-action-types.phone-number.help",
    icon: <PhoneIcon className="mr-2 h-5 w-5 " />,
  },
};

export const FIELDS = {
  name: {
    key: "name",
    // i18n.t("templates:fields.name.label", "Name"),
    label: "templates:fields.name.label",
    help: "",
    placeholder: "template_name",
  },
  channel: {
    key: "channel",
    // i18n.t("templates:fields.channel.label", "Channel for this template"),
    label: "templates:fields.channel.label",
    placeholder: "",
    // i18n.t("templates:fields.channel.help", "This is the channel this template will be available for."),
    help: "templates:fields.channel.help",
  },
  category: {
    key: "category",
    // i18n.t("templates:fields.category.label", "Category"),
    label: "templates:fields.category.label",
    placeholder: "",
    help: (
      <>
        <Trans i18nKey="templates:learn-more-about-categories">
          To know more about these categories please visit{" "}
          <a
            href="https://developers.facebook.com/docs/whatsapp/api/messages/message-templates/"
            className="text-blue-600"
            target="_blank"
            rel="noreferrer"
          >
            official docs.
          </a>
        </Trans>
      </>
    ),
  },
  allow_category_change: {
    key: "allow_category_change",
    label: t(
      "templates:fields.allow_category_change.label",
      "Allow category change?"
    ),
    help: t(
      "templates:fields.allow_category_change.help",
      "If not enabled, Meta will automatically reject templates they feel are miscategorized instead of automatically switching them to the correct category."
    ),
  },
  language: {
    key: "language",
    label: t("templates:fields.language.label", "Language"),
    help: "",
  },
  components: {
    key: "components",
    label: t("templates:fields.components.label", "Components"),
  },
  examples: {
    key: "examples",
    label: t("templates:fields.examples.label", "Examples"),
  },
  header: {
    format: {
      key: "format",
      label: t("templates:fields.header.format.label", "Format"),
    },
    text: {
      key: "text",
      label: t("templates:fields.header.text.label", "Text"),
      help: t(
        "templates:fields.header.text.help",
        "There's no limit for using variables."
      ),
    },
  },
  body: {
    key: "text",
    label: t("templates:fields.body.label", "Body text"),
    help: t(
      "templates:fields.body.help",
      "There's no limit for using variables."
    ),
  },
  footer: {
    key: "text",
    label: t("templates:fields.footer.label", "Footer text"),
  },
};

export const BUTTON_FIELDS = {
  type: {
    key: "type",
    label: t("templates:button-fields.type.label", "Type"),
  },
  text: {
    key: "text",
    label: t("templates:button-fields.text.label", "Text"),
  },
  phone_number: {
    key: "phone_number",
    label: t("templates:button-fields.phone-number.label", "Phone Number"),
    placeholder: t(
      "templates:button-fields.phone-number.placeholder",
      "+18003128382"
    ),
  },
  url: {
    key: "url",
    label: t("templates:button-fields.url.label", "Website URL"),
    placeholder: t(
      "templates:button-fields.url.placeholder",
      "https://www.example.com"
    ),
  },
};

export const AUTHENTICATION_OPTIONS = {
  add_security_recommendation: {
    key: "add_security_recommendation",
    label: t(
      "templates:button-fields.add_security_recommendation.label",
      "Add security recommendation"
    ),
  },
  add_code_expiration_minutes: {
    key: "add_code_expiration_minutes",
    label: t(
      "templates:button-fields.add_code_expiration_minutes.label",
      "Add expiration time for the code"
    ),
  },
  code_expiration_minutes: {
    key: "code_expiration_minutes",
    label: t(
      "templates:button-fields.code_expiration_minutes.label",
      "Expiration time (minutes)"
    ),
  },
  text: {
    key: "text",
    label: t(
      "templates:button-fields.authentication-text.label",
      "Copy Code Text"
    ),
    help: t(
      "templates:button-fields.authentication-text.help",
      "You can customize the button text for the copy code."
    ),
  },
};

export const STATUS = {
  DRAFT: "draft",
  APPROVED: "approved",
  IN_APPEAL: "in_appeal",
  PENDING: "pending",
  REJECTED: "rejected",
  PENDING_DELETION: "pending_deletion",
  DELETED: "deleted",
  DISABLED: "disabled",
};

export const SUPPORTED_LANGUAGES = {
  af: "Afrikaans",
  sq: "Albanian",
  ar: "Arabic",
  az: "Azerbaijani",
  bn: "Bengali",
  bg: "Bulgarian",
  ca: "Catalan",
  zh_CN: "Chinese (CHN)",
  zh_HK: "Chinese (HKG)",
  zh_TW: "Chinese (TAI)",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch",
  en: "English",
  en_GB: "English (UK)",
  en_US: "English (US)",
  et: "Estonian",
  fil: "Filipino",
  fi: "Finnish",
  fr: "French",
  ka: "Georgian",
  de: "German",
  el: "Greek",
  gu: "Gujarati",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  id: "Indonesian",
  ga: "Irish",
  it: "Italian",
  ja: "Japanese",
  kn: "Kannada",
  kk: "Kazakh",
  rw_RW: "Kinyarwanda",
  ko: "Korean",
  ky_KG: "Kyrgyz (Kyrgyzstan)",
  lo: "Lao",
  lv: "Latvian",
  lt: "Lithuanian",
  mk: "Macedonian",
  ms: "Malay",
  ml: "Malayalam",
  mr: "Marathi",
  nb: "Norwegian",
  fa: "Persian",
  pl: "Polish",
  pt_BR: "Portuguese (BR)",
  pt_PT: "Portuguese (POR)",
  pa: "Punjabi",
  ro: "Romanian",
  ru: "Russian",
  sr: "Serbian",
  sk: "Slovak",
  sl: "Slovenian",
  es: "Spanish",
  es_AR: "Spanish (ARG)",
  es_ES: "Spanish (SPA)",
  es_MX: "Spanish (MEX)",
  sw: "Swahili",
  sv: "Swedish",
  ta: "Tamil",
  te: "Telugu",
  th: "Thai",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  vi: "Vietnamese",
  zu: "Zulu",
};

export const initialHeaderValues = {
  type: "HEADER",
  format: "NONE",
  text: "",
  example: {},
};

export const initialBodyValues = {
  type: "BODY",
  text: "",
  example: {},
};

export const initialFooterValues = {
  type: "FOOTER",
  text: "",
};

export const initialButtonsValues = {
  type: "BUTTONS",
  buttons: [],
};
