import TextAreaField from "src/components/Form/TextAreaField";
import { Input } from "src/hooks/useWrappedSteps";
import TextAreaFieldWithVariables from "../TextAreaFieldWithVariables";
import TextInputField from "src/components/Form/TextInputField";
import TextInputFieldWithVariables from "../TextInputFieldWithVariables";
import SecretInputField from "src/components/Form/SecretInputField";

interface DynamicFormProps {
    path: string;
    currentStepIndex: number | null;
    inputs: Input[];
}

const InputFields = {
    "textarea": TextAreaField,
    "textarea_with_variables": TextAreaFieldWithVariables,
    "text": TextInputField,
    "text_with_variables": TextInputFieldWithVariables,
    "secret": SecretInputField,
}

export default function DynamicForm({
    path,
    currentStepIndex,
    inputs
}: DynamicFormProps){
    return (
        <>
        {inputs.map((input, index) => {
            const FieldComponent = InputFields[input.fieldtype];
            const cleanedInput = {...input};
            cleanedInput['name'] = `${path}.inputs.${input.key}`;
            return <FieldComponent {...cleanedInput}/>
        })}
        </>
    )
}