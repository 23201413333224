import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { buildRoute } from "src/router/router";
import { TRIGGER_TYPES } from "../builder/constants/triggers";

export default function OutboundCampaignSegmentTriggerDetail({ flow }) {
  const { t } = useTranslation();
  const triggerData = TRIGGER_TYPES[flow.trigger_type];

  return (
    <>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("trigger", "Trigger")}
      </h3>
      <div className="flex items-center space-x-1">
        <div>
          <triggerData.icon className="h-8 w-8 text-gray-800" />
        </div>
        <div className="font-medium text-gray-900">
          <p className="text-xl">{t(triggerData.name)}</p>
        </div>
      </div>
      <div className="text-sm text-gray-700 mt-2">
        <Trans i18nKey="flows:trigger-detail.outbound-campaign-segment">
          <div className="space-y-4">
            <p>
              This flow will be triggered by filtering from your existing
              contacts.
            </p>
            <p>
              Make sure to test which contacts are found with the filters
              selected before sending.
            </p>
            <p>
              If you're using{" "}
              <code className="bg-gray-100 rounded-md text-indigo-500 py-1 px-1">
                {"{{contact.****}}"}
              </code>{" "}
              variables in your flow, make sure your CSV includes a column with
              the values for each variable.
            </p>
          </div>
        </Trans>
        <Link
          to={buildRoute("flow-execution-create", {
            ":id": flow.id,
          })}
          className="inline-flex items-center rounded-md border border-hilos bg-gradient-to-r from-hilos to-red-400 px-3 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 mt-4"
        >
          {t(
            "flows:trigger-detail.outbound-campaign-segment-button",
            "Upload contact CSV"
          )}
          <ArrowSmRightIcon className="h-5 w-5 text-white mr-1" />
        </Link>
      </div>
    </>
  );
}
