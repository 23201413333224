import { useEffect, useRef } from "react";

export default function useCleanInterval() {
  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearInterval(timeout.current);
        timeout.current = null;
      }
    };
  }, [timeout]);

  return timeout;
}
