import { useTranslation } from "react-i18next";
import { Dialog } from "@headlessui/react";
import LoaderSpinner from "src/components/LoaderSpinner";

export default function TechProviderOnboardingConnecting() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
        <LoaderSpinner className="text-blue-600 h-8 w-8 ml-0.5" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title
          as="h3"
          className="text-base font-semibold leading-6 text-gray-900"
        >
          {t(
            "channel:tech-provider.connecting-title",
            "Connecting your new number..."
          )}
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {t(
              "channel:tech-provider.connecting-description",
              "Usually this takes only a few seconds."
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
