import { useCallback, useEffect, useRef, useState } from "react";
import {
  Edge,
  Node,
  XYPosition,
  applyEdgeChanges,
  applyNodeChanges,
  isEdge,
  isNode,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStore,
} from "reactflow";
import { timer } from "d3-timer";
import { isEqual } from "lodash";
import { FlowNodeData } from "@hilos/types/flow";
import {
  getHierarchyPointNodes,
  nodeCountSelector,
  nodesInitializedSelector,
} from "../utils";

const options = { duration: 300 };

interface UseFlowLayoutParams {
  initialEdges?: Edge[];
  initialNodes?: Node<FlowNodeData>[];
  onSetStepTouched?: ((index: number) => void) | null;
}

type Transition = {
  id: string;
  from: XYPosition;
  to: XYPosition;
  node: Node<FlowNodeData>;
};

export type SelectedItemState = Node<FlowNodeData> | Edge | null;

function useFlowLayout({
  initialEdges = [],
  initialNodes = [],
  onSetStepTouched = null,
}: UseFlowLayoutParams = {}) {
  const hasInitialFocusRef = useRef(false);
  const lastPositionToFitRef = useRef<XYPosition | null>(null);
  const nodeCount = useStore(nodeCountSelector);
  const nodesInitialized = useStore(nodesInitializedSelector);
  const [transitions, setTransitions] = useState<Transition[]>([]);
  const [selectedItem, setSelectedItem] = useState<SelectedItemState>(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const { fitBounds, getNode } = useReactFlow();

  const onSelectItem = useCallback(
    (nextSelectedItem: SelectedItemState) => {
      let positionToFit: XYPosition | null = null;

      if (
        nextSelectedItem &&
        // @ts-ignore
        nextSelectedItem.source &&
        // @ts-ignore
        nextSelectedItem.target
      ) {
        //@ts-ignore
        const targetNode = getNode(nextSelectedItem.target);
        //@ts-ignore
        const sourceNode = getNode(nextSelectedItem.source);

        if (targetNode && sourceNode) {
          const targetX = targetNode.position.x;
          const targetY = targetNode.position.y;
          const sourceX = sourceNode.position.x;
          const sourceY = sourceNode.position.y;
          const width = targetX - sourceX;
          const height = targetY - sourceY;

          positionToFit = {
            x: sourceX + width / 2 || 0,
            y: sourceY + height / 2 || 0,
          };
        }
      }

      if (selectedItem !== nextSelectedItem) {
        if (nextSelectedItem) {
          //@ts-ignore
          if (nextSelectedItem.position) {
            positionToFit = {
              //@ts-ignore
              x: nextSelectedItem.position.x || 0,
              //@ts-ignore
              y: nextSelectedItem.position.y || 0,
            };
            //@ts-ignore
          } else if (nextSelectedItem.source && nextSelectedItem.target) {
            //@ts-ignore
            const targetNode = getNode(nextSelectedItem.target);
            //@ts-ignore
            const sourceNode = getNode(nextSelectedItem.source);

            if (targetNode && sourceNode) {
              const targetX = targetNode.position.x;
              const targetY = targetNode.position.y || 160;
              const sourceX = sourceNode.position.x;
              const sourceY = sourceNode.position.y;
              const width = targetX - sourceX;
              const height = targetY - sourceY;

              positionToFit = {
                x: sourceX + width / 2 || 0,
                y: sourceY + height / 2 + 200 || 0,
              };
            }
          }

          if (
            positionToFit &&
            !isEqual(positionToFit, lastPositionToFitRef.current)
          ) {
            lastPositionToFitRef.current = positionToFit;
            fitBounds(
              { ...positionToFit, width: 300, height: 300 },
              { duration: 400, padding: 2 }
            );
          }
        }

        if (
          selectedItem &&
          selectedItem.data &&
          selectedItem.data.index !== undefined &&
          onSetStepTouched !== null
        ) {
          onSetStepTouched(selectedItem.data.index);
        }
      }

      setSelectedItem(nextSelectedItem);
    },
    [fitBounds, getNode, selectedItem, onSetStepTouched]
  );

  const onUpdateTransitions = useCallback(
    (nodes: Node<FlowNodeData>[], edges: Edge[]) => {
      // run the layout and get back the nodes with their updated positions
      const pointNodes = getHierarchyPointNodes(nodes, edges);

      // to interpolate and animate the new positions, we create objects that contain the current and target position of each node
      const nextTransitions: Transition[] = pointNodes.map((pointNode) => {
        const node = pointNode.data;
        const currentNode = getNode(node.id) || {};
        const position = { x: pointNode.x, y: pointNode.y };

        return {
          id: node.id,
          // this is where the node currently is placed
          from: currentNode["position"] || position,
          // this is where we want the node to be placed
          to: position,
          position,
          node,
        };
      });

      setEdges(edges);
      setTransitions(nextTransitions);
    },
    [getNode, setEdges]
  );

  const onClearSelectItem = useCallback(() => {
    if (selectedItem) {
      if (isNode(selectedItem)) {
        setNodes((prevNodes) =>
          applyNodeChanges(
            [{ type: "select", selected: false, id: selectedItem.id }],
            prevNodes
          )
        );
      } else if (isEdge(selectedItem)) {
        setEdges((prevEdges) =>
          applyEdgeChanges(
            [{ type: "select", selected: false, id: selectedItem.id }],
            prevEdges
          )
        );
      }
      setSelectedItem(null);
    }
  }, [selectedItem, setNodes, setEdges]);

  useEffect(() => {
    if (!nodeCount || !nodesInitialized) {
      return;
    }

    if (!hasInitialFocusRef.current) {
      hasInitialFocusRef.current = true;

      if (nodeCount > 2 || !lastPositionToFitRef.current) {
        lastPositionToFitRef.current = { x: 0, y: 0 };
      }

      fitBounds(
        {
          ...lastPositionToFitRef.current,
          width: 300,
          height: 300,
        },
        { duration: 400, padding: 2 }
      );
    }
  }, [nodeCount, nodesInitialized, fitBounds]);

  useEffect(() => {
    // create a timer to animate the nodes to their new positions
    const t = timer((elapsed: number) => {
      // this is the final step of the animation
      if (elapsed > options.duration) {
        const nextNodes: Node<FlowNodeData>[] = [];
        let positionToFit: XYPosition | null = null;

        // we are moving the nodes to their destination
        // this needs to happen to avoid glitches
        for (const { node, to } of transitions) {
          if (node.selected) {
            positionToFit = to;
          }

          nextNodes.push({
            ...node,
            id: node.id,
            position: to,
            data: { ...node.data },
            type: node.type,
          });
        }

        setNodes(nextNodes);

        if (
          positionToFit &&
          !isEqual(positionToFit, lastPositionToFitRef.current)
        ) {
          lastPositionToFitRef.current = positionToFit;
          fitBounds(
            { ...positionToFit, width: 300, height: 300 },
            { duration: 400, padding: 2 }
          );
        }

        // stop the animation
        t.stop();
      } else {
        const s = elapsed / options.duration;

        const transitionNodes = transitions.map(({ node, from, to }) => {
          return {
            ...node,
            id: node.id,
            position: {
              // simple linear interpolation
              x: from.x + (to.x - from.x) * s,
              y: from.y + (to.y - from.y) * s,
            },
            data: { ...node.data },
            type: node.type,
          };
        });

        setNodes(transitionNodes);
      }
    });

    return () => {
      t.stop();
    };
  }, [fitBounds, setNodes, transitions]);

  return {
    nodes,
    edges,
    selectedItem,
    onSelectItem,
    onClearSelectItem,
    onEdgesChange,
    onNodesChange,
    onUpdateTransitions,
  };
}

export default useFlowLayout;
