import { ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GroupBase } from "react-select";
import { ExclamationIcon, ShareIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import { useStepField } from "../../../hooks/useStepField";
import useVariables from "../../../hooks/useVariables";

function formatGroupLabel<Option>({
  // @ts-ignore
  icon: RenderIcon,
  label,
}: GroupBase<Option>): ReactNode {
  return (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <RenderIcon
          className={`aria-hidden="true" h-4 w-4 text-hilos ${
            RenderIcon === ShareIcon ? "rotate-90" : ""
          }`}
        />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {label}
      </div>
    </div>
  );
}

function FlowBuilderOptionsFromVariable({
  currentStepIndex,
  maxOptionsLength,
}) {
  const [t] = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [optionsFromVariable] = useStepField({
    index: currentStepIndex,
    name: "options_from_variable",
  });

  const { options, hasMissingActionTest, handleGetOptionsFromVariableData } =
    useVariables({
      currentStepIndex,
      allowedVariableTypes: ["step"],
      allowedVariableDataTypes: ["list"],
    });

  const optionsFromSelectedVariable = useMemo(
    () => handleGetOptionsFromVariableData(optionsFromVariable),
    [optionsFromVariable, handleGetOptionsFromVariableData]
  );

  const handleSelectDataSourceVariable = useCallback(() => {
    setFieldValue(`steps.${currentStepIndex}.option_from_variable_value`, null);
    setFieldValue(`steps.${currentStepIndex}.option_from_variable_title`, null);
  }, [currentStepIndex, setFieldValue]);

  return (
    <div>
      {hasMissingActionTest && (
        <div className="my-2 flex items-center rounded-md bg-yellow-50 p-4">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
          <div className="ml-3">
            <div className="text-xs text-yellow-700">
              To use results of an action step, a test request is required to
              map available variables.
            </div>
          </div>
        </div>
      )}
      {maxOptionsLength && (
        <div className="my-2 flex items-center rounded-md bg-yellow-50 p-4">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
          <div className="ml-3">
            <div className="text-xs text-yellow-700">
              Only the first {maxOptionsLength} results of the variable will be
              used.
            </div>
          </div>
        </div>
      )}

      <div className="flex w-full flex-col space-y-4 py-2">
        <SelectorField
          name={`steps.${currentStepIndex}.options_from_variable`}
          label={t(
            "flows:steps.question.options-from-variable.label",
            "Data source for options"
          )}
          placeholder={t(
            "flows:steps.question.options-from-variable.placeholder",
            "Select variable"
          )}
          options={options}
          formatGroupLabel={formatGroupLabel}
          onSelect={handleSelectDataSourceVariable}
        />
        <SelectorField
          name={`steps.${currentStepIndex}.option_from_variable_value`}
          label={t(
            "flows:steps.question.option-from-variable-value.label",
            "Value of each option"
          )}
          placeholder={t(
            "flows:steps.question.option-from-variable-value.placeholder",
            "Select value"
          )}
          options={optionsFromSelectedVariable}
          formatGroupLabel={formatGroupLabel}
        />
        <SelectorField
          name={`steps.${currentStepIndex}.option_from_variable_title`}
          label={t(
            "flows:steps.question.option-from-variable-title.label",
            "Title of each option"
          )}
          placeholder={t(
            "flows:steps.question.option-from-variable-title.placeholder",
            "Select title"
          )}
          options={optionsFromSelectedVariable}
          formatGroupLabel={formatGroupLabel}
        />
        <TextInputField
          type="text"
          name={`steps.${currentStepIndex}.missing_options_message`}
          label={t(
            "flows:steps.question.missing-options-message.label",
            "Message to display when no options were found"
          )}
          placeholder={t(
            "flows:steps.question.missing-options-message.placeholder",
            "No options can be found..."
          )}
        />
        {/* TODO: Add functionality to select which step to go when no options exist */}
      </div>
    </div>
  );
}

export default FlowBuilderOptionsFromVariable;
