export const ERROR_MESSAGES = {
  required: "*Required",
  url: "*Must be a valid URL",
  number: "*Must be a number",
  percentage: "*Must only be a number (without the % character)",
  phoneNumber: "*Must have at least 10 digits",
  validPhoneNumber: "*Input a valid phone number",
  email: "*Must be a valid email",
  // Formik permite usar la variable min, length para mensajes de error
  // eslint-disable-next-line
  min: "*Must have at least ${min} characters",
  // eslint-disable-next-line
  max: "*Must have no more than ${max} characters",
  // eslint-disable-next-line
  minDate: "*Must be a date after ${min}",
  // eslint-disable-next-line
  minNumber: "*Must have at least ${min}",
  // eslint-disable-next-line
  minNumberValue: "*Must be at least ${min}",
  // eslint-disable-next-line
  maxNumber: "*Must have less than ${max}",
  // eslint-disable-next-line
  maxNumberValue: "*Must be less than ${max}",
  // eslint-disable-next-line
  minPercentage: "*Must be at least ${min}%",
  // eslint-disable-next-line
  maxPercentage: "*Must be no more than ${max}%",
  positive: "*Must be a positive number",
  // eslint-disable-next-line
  length_error: "*Must have ${length} characters",
  date: "*Must be a valid date",
  time: "*Must be a valid time",
};

export const KNOWN_EMAIL_PROVIDERS = [
  "gmail",
  "hotmail",
  "outlook",
  "yahoo",
  "msn",
  "aol",
  "live",
  "icloud",
];
