import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import { ANSWER_TYPES } from "../../../constants/steps/question";
import { useStepField } from "../../../hooks/useStepField";
import FlowBuilderMaxAnswerAttempts from "../../FlowBuilderMaxAnswerAttempts";
import FlowBuilderMsgFail from "../../FlowBuilderMsgFail";
import FlowBuilderWaitUntil from "../../FlowBuilderWaitUntil";
import FormatOptionLabel from "../../FormatOptionLabel";
import TextAreaFieldWithVariables from "../../TextAreaFieldWithVariables";
import FlowBuilderStepQuestionBool from "./FlowBuilderStepQuestionBool";
import FlowBuilderStepQuestionDate from "./FlowBuilderStepQuestionDate";
import FlowBuilderStepQuestionNumber from "./FlowBuilderStepQuestionNumber";
import FlowBuilderStepQuestionPhone from "./FlowBuilderStepQuestionPhone";
import FlowBuilderStepQuestionSingleOption from "./FlowBuilderStepQuestionSingleOption";

interface FlowBuilderStepQuestionProps {
  id: string;
  index: number;
}

const ANSWER_TYPE_COMPONENTS = {
  SINGLE_OPTION: FlowBuilderStepQuestionSingleOption,
  NUMBER: FlowBuilderStepQuestionNumber,
  PHONE: FlowBuilderStepQuestionPhone,
  BOOL: FlowBuilderStepQuestionBool,
  DATE: FlowBuilderStepQuestionDate,
};

const hasStepOptions = (answerType: string) => answerType === "SINGLE_OPTION";

function FlowBuilderStepQuestion({ id, index }: FlowBuilderStepQuestionProps) {
  const [t] = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [answerType, setAnswerType] = useStepField({
    index,
    name: "answer_type",
  });
  const [groupAnswersFromMultipleTexts, _] = useStepField({
    index,
    name: "group_answers_from_multiple_texts",
  });

  const handleChangeAnswerType = useCallback(
    (selectedAnswerType) => {
      const nextAnswerType =
        (selectedAnswerType && selectedAnswerType.value) || null;

      if (answerType) {
        if (hasStepOptions(answerType)) {
          if (!hasStepOptions(nextAnswerType)) {
            setFieldValue(`steps.${index}.body_type`, undefined);
            setFieldValue(`steps.${index}.answer_options`, undefined);
            setFieldValue(`steps.${index}.answer_options_render`, undefined);
            setFieldValue(
              `steps.${index}.has_options_from_variable`,
              undefined
            );
            setFieldValue(
              `steps.${index}.answer_options_render_list_button_title`,
              undefined
            );
          }
        } else {
          setFieldValue(`steps.${index}.answer_options`, undefined);
          setFieldValue(`steps.${index}.answer_allow_decimals`, undefined);
          setFieldValue(`steps.${index}.answer_has_range`, undefined);
          setFieldValue(`steps.${index}.answer_range_min`, undefined);
          setFieldValue(`steps.${index}.answer_range_max`, undefined);
          setFieldValue(
            `steps.${index}.answer_phone_default_country`,
            undefined
          );
        }
      }

      if (nextAnswerType) {
        if (nextAnswerType === "BOOL") {
          setFieldValue(`steps.${index}.answer_options`, ["Yes", "No"]);
        } else if (
          hasStepOptions(nextAnswerType) &&
          !hasStepOptions(answerType)
        ) {
          setFieldValue(`steps.${index}.answer_options`, [""]);
          setFieldValue(
            `steps.${index}.answer_options_render`,
            nextAnswerType === "SINGLE_OPTION" ? "BUTTONS" : "EMOJIS"
          );
        } else if (nextAnswerType === "ANY") {
          setFieldValue(`steps.${index}.answer_validation_message`, null);
        }
      }

      setAnswerType(nextAnswerType);
    },
    [index, answerType, setAnswerType, setFieldValue]
  );

  const RenderAnswerTypeComponent = useMemo(
    () => ANSWER_TYPE_COMPONENTS[answerType] || null,
    [answerType]
  );

  const answerTypeOptions = useMemo(
    () =>
      ANSWER_TYPES.map((option) => ({
        ...option,
        label: t(option.label),
        help: t(option.help),
      })),
    [t]
  );

  return (
    <div className="space-y-4">
      <TextAreaFieldWithVariables
        path={`steps.${index}`}
        name={"body"}
        label={t("flows:steps.message.body.label", "Message body")}
        placeholder={t(
          "flows:steps.message.body.placeholder",
          "Step 1 Welcome"
        )}
        rows={8}
        currentStepIndex={index}
      />
      <div className="space-y-2">
        <SelectorField
          name={`steps.${index}.answer_type`}
          label={t("flows:steps.question.answer-type.label", "Answer type")}
          options={answerTypeOptions}
          formatOptionLabel={FormatOptionLabel}
          isOptionDisabled={(option) => option.soon}
          onSelect={handleChangeAnswerType}
        />
        {RenderAnswerTypeComponent && (
          <RenderAnswerTypeComponent id={id} index={index} />
        )}
        {answerType === "FREE_TEXT" && (
          <>
            <SwitchField
              name={`steps.${index}.group_answers_from_multiple_texts`}
              label={t(
                "flows:steps.question.group-answers-from-multiple-texts.label",
                "Group multiple messages as final answer?"
              )}
              help={t(
                "flows:steps.question.group-answers-from-multiple-texts.help",
                "If people usually send you multiple messages as a response, using this will enable you to capture them all and use them as this step's answer"
              )}
            />
            {groupAnswersFromMultipleTexts && (
              <TextInputField
                name={`steps.${index}.minutes_to_wait_for_more_messages`}
                label={t(
                  "flows:steps.question.minutes-to-wait-for-more-messages.label",
                  "Minutes to wait from last message"
                )}
                help={t(
                  "flows:steps.question.minutes-to-wait-for-more-messages.help",
                  "Every time we receive a message we'll wait up to this many minutes before we consider this step as answered."
                )}
                placeholder={t(
                  "flows:steps.question.minutes-to-wait-for-more-messages.placeholder",
                  "1"
                )}
                type="number"
              />
            )}
          </>
        )}
        {answerType && answerType !== "ANY" && (
          <>
            <TextInputField
              name={`steps.${index}.answer_validation_message`}
              label={t(
                "flows:steps.question.answer-validation-message.label",
                "Answer validation message"
              )}
              help={t(
                "flows:steps.question.answer-validation-message.help",
                "If the recipient doesn't provide a value according to this question's answer type, we'll try again with this message."
              )}
              placeholder={t(
                "flows:steps.question.answer-validation-message.placeholder",
                "Please specify a proper value."
              )}
              type="text"
            />
            <FlowBuilderMaxAnswerAttempts id={id} index={index} />
          </>
        )}
      </div>
      <FlowBuilderWaitUntil id={id} index={index} />

      <FlowBuilderMsgFail id={id} index={index} />
    </div>
  );
}

export default FlowBuilderStepQuestion;
