import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { InboxContactRead } from "@hilos/types/private-schema";
import QuickReplies from "./QuickReplies";

interface QuickRepliesModalProps {
  show: boolean;
  inboxContact: InboxContactRead;
  onClose: () => void;
  onSetMessage: (message: string) => void;
}

function QuickRepliesModal({
  show,
  inboxContact,
  onClose,
  onSetMessage,
}: QuickRepliesModalProps) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="h-full w-full">
                    <QuickReplies
                      inboxContact={inboxContact}
                      onClose={onClose}
                      onSetMessage={onSetMessage}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default QuickRepliesModal;
