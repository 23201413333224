import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ArrowSmRightIcon,
  PlusSmIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { FieldArray, Form, Formik } from "formik";
import { Reorder } from "framer-motion";
import { TFunction } from "i18next";
import { v4 as uuid } from "uuid";
import * as yup from "yup1";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import TwoColumnFormContainer from "src/components/TwoColumnFormContainer";
import { ERROR_MESSAGES } from "src/constants/errors";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import WorkflowStateReorderItem from "./WorkflowStateReorderItem";

interface WorkflowFields {
  id: string;
  name: string;
  created_on: string;
  users: number[];
  states: { id: string; name: string; state_type: "ACTIVE" | "CLOSED" }[];
}

interface WorkflowFormProps {
  workflow?: WorkflowFields;
}

type CurrentFormStatus = "ready" | "submitted" | "success" | "error";

export const getWorkflowSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    custom_name_for_task: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    icon_image: yup.string().nullable(),
    hide_empty_columns: yup.boolean(),
    allow_assign_multiple_agents: yup.boolean(),
    only_assign_active_agents: yup.boolean(),
    reasign_after_inactivity_time: yup.string().nullable(),
    assignment_strategy: yup.string().nullable(),
    states: yup
      .array()
      .min(2, t(ERROR_MESSAGES.AT_LEAST_TWO_STATES))
      .required(t(ERROR_MESSAGES.REQUIRED))
      .of(
        yup.object({
          name: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
          state_type: yup
            .string()
            .oneOf(["ACTIVE", "CLOSED"])
            .required(t(ERROR_MESSAGES.REQUIRED)),
        })
      )
      .test(
        "atLeastOneActiveAndClosedState",
        t(ERROR_MESSAGES.AT_LEAST_ONE_ACTIVE_AND_CLOSED_STATE),
        function (states) {
          const missingStateTypes = new Set<string>(["ACTIVE", "CLOSED"]);

          for (const state of states) {
            missingStateTypes.delete(state.state_type);
          }

          if (missingStateTypes.size > 0) {
            return false;
          }

          return true;
        }
      ),
  });

export default function WorkflowForm({ workflow }: WorkflowFormProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [currentFormStatus, setCurrentFormStatus] =
    useState<CurrentFormStatus>("ready");

  const workflowSchema = useMemo(() => getWorkflowSchema(t), [t]);

  const initialValues = useMemo(() => {
    if (workflow) {
      return workflow;
    }
    return {
      hide_empty_columns: false,
      allow_assign_multiple_agents: false,
      states: [
        { name: "New", state_type: "ACTIVE" },
        { name: "Working", state_type: "ACTIVE" },
        { name: "Done", state_type: "CLOSED" },
      ],
    };
  }, [workflow]);

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setTouched }) => {
      setSubmitting(true);

      try {
        const result = workflow
          ? await axios.patch(
              buildAPIRoute(API_ROUTES.WORKFLOW_DETAIL, {
                ":id": workflow.id,
              }),
              values
            )
          : await axios.post(
              buildAPIRoute(API_ROUTES.WORKFLOW_LIST_CREATE),
              values
            );

        setSubmitting(false);
        if (result?.data?.id) {
          setCurrentFormStatus("success");
          navigate(buildRoute("workflow-board", { ":id": result.data.id }));
        } else {
          setCurrentFormStatus("error");
        }
      } catch {
        setSubmitting(false);
        setCurrentFormStatus("error");
      }
    },
    [workflow, navigate]
  );

  return (
    <Formik
      validationSchema={workflowSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={false}
    >
      {(formik) => (
        <Form className="space-y-4 pb-4">
          <TwoColumnFormContainer
            title={t("workflows:workflow-form.title", "Workflow")}
            description={t(
              "workflows:workflow-form.description",
              "Basic information about the workflow."
            )}
          >
            <div className="sm:col-span-4">
              <TextInputField
                name="name"
                type="text"
                label={t("workflows:workflow-form.name.label", "Name")}
                placeholder={t(
                  "workflows:workflow-form.name.placeholder",
                  "Support"
                )}
                help={t(
                  "workflows:workflow-form.name.help",
                  "Name to identify the worflow."
                )}
              />
            </div>
            <div className="sm:col-span-4">
              <TextInputField
                name="custom_name_for_task"
                type="text"
                label={t(
                  "workflows:workflow-form.custom-name-for-task.label",
                  "Custom name for task"
                )}
                placeholder={t(
                  "workflows:workflow-form.custom-name-for-task.placeholder",
                  "Ticket"
                )}
                help={t(
                  "workflows:workflow-form.custom-name-for-task.help",
                  "Set a name for your tasks according to the workflow."
                )}
              />
            </div>
            <div className="col-span-full">
              <SwitchField
                name="hide_empty_columns"
                label={t(
                  "workflows:workflow-form.hide-empty-column.label",
                  "Hide empty columns"
                )}
                help={t(
                  "workflows:workflow-form.hide-empty-column.help",
                  "If enabled, all columns without tasks will be hidden."
                )}
              />
            </div>
            <div className="col-span-full">
              <SwitchField
                name="allow_assign_multiple_agents"
                label={t(
                  "workflows:workflow-form.allow-assign-multiple-agents.label",
                  "Allow assign multiple agents"
                )}
                help={t(
                  "workflows:workflow-form.allow-assign-multiple-agents.help",
                  "You can enable it to allow tasks to be assigned to more than one user."
                )}
              />
            </div>
          </TwoColumnFormContainer>
          <TwoColumnFormContainer
            title={t("workflows:workflow-form.states.title", "States")}
            description={t(
              "workflows:workflow-form.states.description",
              "These states are represented in columns so that they can easily change state."
            )}
          >
            <div className="col-span-full">
              <FieldArray
                name="states"
                render={(arrayHelpers) => {
                  const states = arrayHelpers.form.values.states || [];
                  const errors = arrayHelpers.form.errors.states;
                  return (
                    <>
                      {Boolean(errors && typeof errors === "string") && (
                        <div className="rounded-md bg-red-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <XCircleIcon
                                className="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-red-700">
                                {errors as string}
                              </h3>
                            </div>
                          </div>
                        </div>
                      )}

                      {states.length > 0 && (
                        <table className="table w-full mb-4">
                          <thead>
                            <tr className="flex py-1 space-x-2">
                              <th />
                              <th className="grow">
                                {t(
                                  "workflows:workflow-form.states.name.title",
                                  "Name"
                                )}
                              </th>
                              <th className="w-32">
                                {t(
                                  "workflows:workflow-form.states.state_type.title",
                                  "State Type"
                                )}
                              </th>
                              <th className="w-11" />
                            </tr>
                          </thead>
                          <tbody>
                            <Reorder.Group
                              axis="y"
                              values={states}
                              onReorder={(nextStates) =>
                                arrayHelpers.form.setFieldValue(
                                  "states",
                                  nextStates
                                )
                              }
                            >
                              {states.map((state, index) => (
                                <WorkflowStateReorderItem
                                  key={state.id || state.temp_id}
                                  state={state}
                                  index={index}
                                  onDelete={arrayHelpers.handleRemove(index)}
                                />
                              ))}
                            </Reorder.Group>
                          </tbody>
                        </table>
                      )}

                      <button
                        type="button"
                        className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                        onClick={() => {
                          arrayHelpers.push({
                            name: t(
                              "workflows:workflow-form.states.state-placeholder",
                              "State {{ number }}",
                              { number: states.length + 1 }
                            ),
                            state_type: "ACTIVE",
                            temp_id: uuid(),
                          });
                        }}
                      >
                        <PlusSmIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        {t(
                          "workflows:workflow-form.states.add-state",
                          "Add state"
                        )}
                      </button>
                    </>
                  );
                }}
              />
            </div>
          </TwoColumnFormContainer>
          <div className="mt-5">
            <div className="text-right">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={currentFormStatus === "submitted"}
                success={currentFormStatus === "success"}
                disabled={
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                }
                submittingText={
                  !workflow
                    ? t("creating", "Creating...")
                    : t("updating", "Updating...")
                }
                successText={
                  !workflow ? t("created", "Created") : t("updated", "Updated")
                }
                initialText={
                  <>
                    {!workflow ? t("create", "Create") : t("update", "Update")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
