import {
  ChatIcon,
  ClipboardListIcon,
  CogIcon,
  InboxIcon,
  ShareIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import * as yup from "yup";
import { ERROR_MESSAGES } from "src/constants/Form";
import { getTranslationPayload as t } from "src/i18n";

export const schema = yup.object().shape({
  permissions: yup
    .array()
    .of(yup.string())
    .min(1, ERROR_MESSAGES.minNumberValue),
  available_channels: yup.array().of(yup.number()),
  first_name: yup.string().required(ERROR_MESSAGES.required),
  last_name: yup.string().required(ERROR_MESSAGES.required),
});

export const FIELDS = {
  first_name: {
    key: "first_name",
    label: t("settings:users.first-name.label", "First Name"),
    placeholder: t("settings:users.first-name.placeholder", "Juan"),
  },
  last_name: {
    key: "last_name",
    label: t("settings:users.last-name.label", "Last Name"),
    placeholder: t("settings:users.last-name.placeholder", "Solo"),
  },
  email: {
    key: "email",
    label: t("settings:users.email.label", "Email"),
  },
  role: {
    key: "role",
    label: t("settings:users.role.label", "Role"),
    help: t(
      "settings:users.role.help",
      "This will determine the permissions this user will have."
    ),
  },
  permissions: {
    key: "permissions",
    label: t(
      "settings:users.permissions.label",
      "Select the permissions you'd like this user to have"
    ),
  },
  channels: {
    key: "channels",
    label: t(
      "settings:users.channels.label",
      "Select the channels you want this user to see"
    ),
  },
};

export const PERMISSION_CATEGORIES = {
  ACCOUNT: {
    label: t("navigation:settings.title", "Settings"),
    icon: <CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />,
  },
  CONTACT: {
    label: t("navigation:contacts.title", "Contacts"),
    icon: <UserGroupIcon className="h-4 w-4 mr-1" aria-hidden="true" />,
  },
  FLOW: {
    label: t("navigation:flows.title", "Flows"),
    icon: <ShareIcon className="h-4 w-4 mr-1 rotate-90" aria-hidden="true" />,
  },
  BROADCAST: {
    label: t("navigation:broadcasts.title", "Broadcasts"),
    icon: <ChatIcon className="h-4 w-4 mr-1" aria-hidden="true" />,
  },
  INBOX: {
    label: t("navigation:inbox.title", "Inbox"),
    icon: <InboxIcon className="h-4 w-4 mr-1" aria-hidden="true" />,
  },
  WA_TEMPLATE: {
    label: t("navigation:templates.title", "WA Templates"),
    icon: <ClipboardListIcon className="h-4 w-4 mr-1" aria-hidden="true" />,
  },
};

export const PERMISSIONS = {
  change_account: {
    label: t(
      "settings:team.user.change-account.label",
      "Update account settings"
    ),
    value: "change_account",
    category: "ACCOUNT",
  },
  change_stripesubscription: {
    label: t(
      "settings:team.user.change-subscription.label",
      "Start or update Hilos subscription"
    ),
    value: "change_stripesubscription",
    category: "ACCOUNT",
  },
  add_flow: {
    label: t("settings:team.user.add-flow.label", "Create flows"),
    value: "add_flow",
    category: "FLOW",
  },
  change_flow: {
    label: t("settings:team.user.change-flow.label", "Update flows"),
    value: "change_flow",
    category: "FLOW",
  },
  delete_flow: {
    label: t("settings:team.user.delete-flow.label", "Delete flows"),
    value: "delete_flow",
    category: "FLOW",
  },
  view_flow: {
    label: t("settings:team.user.view-flow.label", "View flows"),
    value: "view_flow",
    category: "FLOW",
  },
  add_messageblast: {
    label: t("settings:team.user.add-messageblast.label", "Send broadcasts"),
    value: "add_messageblast",
    category: "BROADCAST",
  },
  view_messageblast: {
    label: t("settings:team.user.view-messageblast.label", "See broadcasts"),
    value: "view_messageblast",
    category: "BROADCAST",
  },
  view_inboxcontact: {
    label: t("settings:team.user.view-inboxcontact.label", "Use the Inbox"),
    value: "view_inboxcontact",
    category: "INBOX",
  },
  add_whatsapptemplate: {
    label: t(
      "settings:team.user.add-whatsapptemplate.label",
      "Create WhatsApp Templates"
    ),
    value: "add_whatsapptemplate",
    category: "WA_TEMPLATE",
  },
  delete_whatsapptemplate: {
    label: t(
      "settings:team.user.delete-whatsapptemplate.label",
      "Delete WhatsApp Templates"
    ),
    value: "delete_whatsapptemplate",
    category: "WA_TEMPLATE",
  },
  view_contact: {
    label: t("settings:team.user.view-contact.label", "View contacts"),
    value: "view_contact",
    category: "CONTACT",
  },
  add_contact: {
    label: t("settings:team.user.add-contact.label", "Create contacts"),
    value: "add_contact",
    category: "CONTACT",
  },
  change_contact: {
    label: t("settings:team.user.change-contact.label", "Update contacts"),
    value: "change_contact",
    category: "CONTACT",
  },
  delete_contact: {
    label: t("settings:team.user.delete-contact.label", "Delete contacts"),
    value: "delete_contact",
    category: "CONTACT",
  },
};
