import { getTranslationPayload as t } from "src/i18n";

export const CONVERSATION_ERROR_MESSAGES = {
  "131053": t(
    "errors:whatsapp-provider.131053",
    "Unable to upload media: check that the image/document/video exists and can be accessed."
  ),
  "131047": t(
    "errors:whatsapp-provider.131047",
    "More than 24 hours have passed since the recipient last replied to the sender number."
  ),
  "132001": t(
    "errors:whatsapp-provider.132001",
    "The template does not exist in the specified language or the template has not been approved."
  ),
  "131042": t(
    "errors:whatsapp-provider.131042",
    "Message failed to send because there were one or more errors related to your WhatsApp payment method. Check that you already have a credit card added on Facebook and that it's valid and active."
  ),
  "130472": t(
    "errors:whatsapp-provider.130472",
    "Message was not sent as part of a WhatsApp internal experiment. Learn more here: https://developers.facebook.com/docs/whatsapp/cloud-api/guides/experiments#marketing-message-experiment"
  ),
  "131026": t(
    "errors:whatsapp-provider.131026",
    "Unable to deliver message. Reasons can include: The recipient phone number is not a WhatsApp phone number, recipient has not accepted our new Terms of Service and Privacy Policy, recipient using an old WhatsApp version."
  ),
  "131031": t(
    "errors:whatsapp-provider.131031",
    "The WhatsApp Business Account associated with the app has been restricted or disabled for violating a platform policy, or we were unable to verify data included in the request against data set on the WhatsApp Business Account"
  ),
  "368": t(
    "errors:whatsapp-provider.368",
    "The WhatsApp Business Account associated with the app has been restricted or disabled for violating a platform policy."
  ),
  "132000": t(
    "errors:whatsapp-provider.132000",
    "The number of variable parameter values included in the request did not match the number of variable parameters defined in the template."
  ),
  "133010": t(
    "errors:whatsapp-provider.133010",
    "Phone number not registered on the WhatsApp Business Platform."
  ),
  "133004": t(
    "errors:whatsapp-provider.133004",
    "Server is temporarily unavailable. Check: https://metastatus.com/whatsapp-business-api"
  ),
  "132015": t(
    "errors:whatsapp-provider.132015",
    "Template is paused by WhatsApp due to low quality so it cannot be sent in a template message."
  ),
  "132012": t(
    "errors:whatsapp-provider.132012",
    "Variable parameter values formatted incorrectly. See https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/"
  ),
  "131021": t(
    "errors:whatsapp-provider.131021",
    "Sender and recipient phone number is the same."
  ),
  "131009": t(
    "errors:whatsapp-provider.131009",
    "One or more parameter values are invalid. this can be related to PHONE_NUMBER_ID, MEDIA_URL OR MEDIA_ID."
  ),
  "1013": t("errors:whatsapp-provider.1013", "Contact is not on WhatsApp."),
  "1009": t(
    "errors:whatsapp-provider.1009",
    "Parameter value is not valid: Value entered for a parameter is of the wrong type or other problem."
  ),
  "1008": t("errors:whatsapp-provider.1008", "Required parameter is missing."),
  "131000": t(
    "errors:whatsapp-provider.131000",
    "Message failed to send due to an unknown error. Try again. If the error persists, open a Direct Support ticket here: https://business.facebook.com/direct-support"
  ),
  "131045": t(
    "errors:whatsapp-provider.131045",
    "Message failed to send due to a phone number registration error. https://developers.facebook.com/docs/whatsapp/cloud-api/reference/registration"
  ),
  "131057": t(
    "errors:whatsapp-provider.131057",
    "The WhatsApp Business Account is in maintenance mode. One reason for this could be that the account is undergoing a throughput upgrade. https://developers.facebook.com/docs/whatsapp/cloud-api/reference/registration"
  ),
  "133000": t(
    "errors:whatsapp-provider.133000",
    "A previous deregistration attempt failed, deregister the number again before registering."
  ),
};
