import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const FlowStepConditionalMeta = {
  getInitialValues: function (step) {
    return {
      name: step?.name || "",
      step_type: "CONDITIONAL",
      next_step_default_idx: step?.next_step_default_idx || "",
      next_step_alternate_idx: "",
      conditions: [],
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      next_step_default_idx: yup.string(),
      next_step_alternate_idx: yup.string(),
      conditions: yup
        .array()
        .of(
          yup.object().shape({
            field: yup.string().required(ERROR_MESSAGES.required),
            comparison: yup.string().required(ERROR_MESSAGES.required),
            value: yup.string().required(ERROR_MESSAGES.required),
          })
        )
        .min(1, ERROR_MESSAGES.minNumber),
    });
  },
  validate: function (formValues, step) {
    // No extra validations needed
    return {};
  },
  formatForSave: function (step) {
    return step;
  },
  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    next_step_default_idx: {
      key: "next_step_default_idx",
      label: "Next step if true",
      help: "If you can't find the step you'd like to go to next, check that it has a name.",
    },
    next_step_alternate_idx: {
      key: "next_step_alternate_idx",
      label: "Next step if false",
      help: "If you can't find the step you'd like to go to next, check that it has a name.",
    },
    conditions: {
      field: {
        key: "field",
        label: "Field",
        help: "",
      },
      comparison: {
        key: "comparison",
        label: "Comparison",
        help: "",
      },
      value: {
        key: "value",
        label: "Value",
        help: "",
      },
    },
  },
};
