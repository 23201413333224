import { useTranslation } from "react-i18next";
import SelectorField from "../../../components/Form/SelectorField";
import FlowExecutionFormContactFile from "./FlowExecutionFormContactFile";
import FlowExecutionFormContactFilters from "./FlowExecutionFormContactFilters";
import * as meta from "./FlowExecutionMeta";

export default function FlowExecutionFormContactList({ formik }) {
  const { t } = useTranslation();
  const formatOptionLabel = ({ value, label, icon, help }) => (
    <div className="flex items-center py-3">
      <div className="mx-4">{icon}</div>
      <div>
        <h4 className="font-medium">{t(label)}</h4>
        {help && <p className="mb-0 text-xs">{t(help)}</p>}
      </div>
    </div>
  );

  return (
    <>
      <SelectorField
        label={t(meta.FIELDS.execute_for.label)}
        name={meta.FIELDS.execute_for.key}
        help={t(meta.FIELDS.execute_for.help)}
        options={meta.FLOW_EXECUTION_FOR}
        formatOptionLabel={formatOptionLabel}
        // helpDocsLink="https://hilos.io/docs/user/create-a-flow"
      />

      {formik.values[meta.FIELDS.execute_for.key] === "FILTERS" && (
        <FlowExecutionFormContactFilters formik={formik} />
      )}

      {formik.values[meta.FIELDS.execute_for.key] === "LIST" && (
        <FlowExecutionFormContactFile formik={formik} />
      )}
    </>
  );
}
