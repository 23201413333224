import { useTranslation } from "react-i18next";
import {
  BadgeCheckIcon,
  InformationCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { FormikProps } from "formik";
import { MessageBlastPublicEdit } from "@hilos/types/private-schema";
import {
  BroadcastFormExtraFormikFields,
  BroadcastFormSteps,
} from "src/containers/broadcasts/BroadcastForm";
import { FIELDS } from "./BroadcastMeta";

interface BroadcastFormStepNavProps {
  formik: FormikProps<MessageBlastPublicEdit & BroadcastFormExtraFormikFields>;
  currentStep: BroadcastFormSteps;
  setCurrentStep: (step: BroadcastFormSteps) => void;
}

export default function BroadcastFormStepNav({
  formik,
  currentStep,
  setCurrentStep,
}: BroadcastFormStepNavProps) {
  const { t } = useTranslation();
  const stepHasErrors = (step) => {
    // return false;
    let errorFields = {
      "broadcast-details": [
        formik.errors[FIELDS.name.key],
        formik.errors[FIELDS.whatsapp_template.key],
      ],
      contacts: [
        formik.errors[FIELDS.recipients_file_selected.key],
        formik.errors[FIELDS.columns.key],
        formik.errors[FIELDS.csv_file_parsed_contents.key],
      ],
      review: [
        formik.errors[FIELDS.start_on.key],
        formik.errors[FIELDS.has_priority.key],
      ],
    };
    if (formik.errors && errorFields[step].some((x) => x)) {
      return true;
    }
    return false;
  };
  const steps = [
    {
      // i18n.t("broadcasts:message-to-send", "Message to send")
      name: "broadcasts:message-to-send",
      icon: <InformationCircleIcon className="mr-2 h-5 w-5" />,
      stepName: "broadcast-details" as BroadcastFormSteps,
      current: currentStep === "broadcast-details",
      hasErrors: stepHasErrors("broadcast-details"),
    },
    {
      // i18n.t("broadcasts:contacts-to-send", "Contacts to send")
      name: "broadcasts:contacts-to-send",
      icon: <UserGroupIcon className="mr-2 h-5 w-5" />,
      stepName: "contacts" as BroadcastFormSteps,
      current: currentStep === "contacts",
      hasErrors: stepHasErrors("contacts"),
    },
    {
      // i18n.t("broadcasts:review", "Review")
      name: "broadcasts:review",
      icon: <BadgeCheckIcon className="mr-2 h-5 w-5" />,
      stepName: "review" as BroadcastFormSteps,
      current: currentStep === "review",
      hasErrors: stepHasErrors("review"),
    },
  ];

  return (
    <nav aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.hasErrors ? (
              <button
                type="button"
                onClick={(_) => setCurrentStep(step.stepName)}
                className="group flex w-full flex-col border-l-4 border-yellow-500 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                aria-current="step"
              >
                <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-yellow-500">
                  {step.icon} {t(step.name)}
                </span>
              </button>
            ) : (
              <>
                {step.current ? (
                  <button
                    type="button"
                    onClick={(_) => setCurrentStep(step.stepName)}
                    className="group flex w-full flex-col border-l-4 border-blue-600 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                    aria-current="step"
                  >
                    <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-blue-600">
                      {step.icon} {t(step.name)}
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(_) => setCurrentStep(step.stepName)}
                    className="group flex w-full flex-col border-l-4 border-gray-200 pl-4 pb-2 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                  >
                    <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-gray-600">
                      {step.icon} {t(step.name)}
                    </span>
                  </button>
                )}
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
