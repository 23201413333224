import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentTextIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { FileSource } from "../../../hooks/useMediaDropzone";

interface FilesPreviewProps {
  files: FileSource[];
  uploading: boolean;
  isSubmitting: boolean;
  handleCancel: () => void;
  handleFilePicker: () => void;
  handleMediaSubmit: () => void;
}

function FilesPreview({
  files,
  uploading,
  isSubmitting,
  handleCancel,
  handleFilePicker,
  handleMediaSubmit,
}: FilesPreviewProps) {
  const { t } = useTranslation();
  const [file, setFile] = useState<FileSource | null>(null);

  const isUploadDisabled = useMemo(
    () => isSubmitting || uploading,
    [isSubmitting, uploading]
  );

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      handleMediaSubmit();
    },
    [handleMediaSubmit]
  );

  const handleSelectFile = useCallback((nextFile: FileSource) => {
    return () => setFile(nextFile);
  }, []);

  useEffect(() => {
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  }, [files]);

  return (
    <div className="flex h-full cursor-default flex-col bg-gray-100">
      <div className="flex grow-0 justify-end px-2 pt-2">
        <button
          type="button"
          className="rounded-full p-2 hover:bg-gray-300"
          onClick={handleCancel}
        >
          <XIcon className="h-5 w-5 text-gray-400" />
          <span className="sr-only">{t("back", "Back")}</span>
        </button>
      </div>
      <div className="flex h-full grow items-center justify-center">
        <div className="self-center text-center">
          {file && (
            <>
              <div className="rounded-lg py-4 px-8">
                {file.source.type.includes("image") ? (
                  <img
                    src={file.preview}
                    alt=""
                    className="pointer-events-none max-h-72 w-full object-cover"
                  />
                ) : (
                  <DocumentTextIcon className="h-24 w-24 text-gray-500" />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mx-auto max-w-sm grow-0 px-4 pt-4">
        {file && (
          <p className="mb-2 overflow-hidden truncate text-gray-500">
            {file.source.name}
          </p>
        )}
      </div>
      <div className="grow-0 px-4 pt-4">
        <form noValidate onSubmit={onSubmit}>
          <div className="relative flex items-center justify-center">
            <button
              type="submit"
              disabled={isUploadDisabled}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {isUploadDisabled ? (
                <>
                  <svg
                    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {t("sending", "Sending...")}
                </>
              ) : (
                <>
                  {t("send", "Send")}
                  <FontAwesomeIcon
                    // @ts-ignore
                    icon={faPaperPlane}
                    className="ml-1"
                  />
                </>
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="grow-0 px-4 py-4">
        <ul className="grid grid-cols-4 gap-x-2 gap-y-2 sm:grid-cols-4 sm:gap-x-3 lg:grid-cols-4 xl:gap-x-4">
          {files.map((file) => (
            <li key={file.preview} className="relative">
              <button
                type="button"
                className="group overflow-hidden rounded-lg outline outline-1 outline-gray-300 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:outline-2"
                onClick={handleSelectFile(file)}
              >
                <div className="group block flex aspect-square w-20 items-center justify-center rounded-lg bg-gray-100 p-1 ">
                  {file.source.type.includes("image") ? (
                    <img
                      src={file.preview}
                      alt=""
                      className="pointer-events-none max-w-full rounded-lg object-cover group-hover:opacity-75"
                    />
                  ) : (
                    <DocumentTextIcon className="h-12 w-12 text-gray-500" />
                  )}
                </div>
              </button>
            </li>
          ))}
          <li className="relative">
            <button
              type="button"
              className="group cursor-pointer overflow-hidden rounded-lg outline outline-1 outline-gray-300 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:outline-2 disabled:bg-gray-300"
              onClick={handleFilePicker}
              disabled={isUploadDisabled}
            >
              <div className="group block flex aspect-square w-20 items-center justify-center rounded-lg bg-gray-100 p-1 text-gray-400">
                <PlusIcon className="h-5 w-5" />
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FilesPreview;
