import { Dispatch, SetStateAction, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import { Formik } from "formik";
import { axiosErr } from "@hilos/types/axios";
import { CrawlerSettings } from "@hilos/types/private-schema";
import { FIELDS } from "src/containers/integrations/AI/CrawlerSettingsMeta";
import ArrayInputField from "src/components/Form/ArrayInputField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { UpdateCrawlerSettingsDetailsFn } from "src/hooks/useCrawlSettings";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";

interface CrawlerSettingsProps {
  currentSettings: CrawlerSettings;
  setLoading: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  onUpdateCrawlerSettings: UpdateCrawlerSettingsDetailsFn;
}

export default function CrawlerSettingsComponent({
  currentSettings,
  setLoading,
  disabled,
  onUpdateCrawlerSettings,
}: CrawlerSettingsProps) {
  const { formatWithLocale } = useDateFnsFormatLocale();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const timeout = useCleanTimeout();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  async function formSubmit(
    config: CrawlerSettings,
    setSubmitting: (boolean) => void
  ) {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      setSubmitting(true);
      await onUpdateCrawlerSettings(config);
      setSuccess(true);
    } catch (e) {
      Sentry.captureException(e);
      setSuccess(false);
      const err = e as axiosErr;
      if (err?.response?.status === 400) {
        console.error("CrawlerSettings", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          t(
            "integrations:crawler.settings.error-save-text",
            "An error occured, please try again."
          )
        );
      }
    } finally {
      setSubmitting(false);
      setSubmitted(true);
      timeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    }
  }

  return (
    <Formik
      onSubmit={(values: CrawlerSettings, { setSubmitting }) =>
        formSubmit(values, setSubmitting)
      }
      enableReinitialize={true}
      initialValues={currentSettings}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div
            className={`mt-10 sm:mt-4 ${
              disabled && "opacity-50 hover:cursor-not-allowed"
            }`}
          >
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <Trans i18nKey="integrations:crawler.settings.description">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Web crawler settings
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Control the configuration of the Web Crawler.
                    </p>
                  </Trans>
                  <div className="text-gray-400 mt-1 text-sm">
                    {formik.values.last_crawled && (
                      <>
                        {t("integrations:crawler.settings.last-crawled")}{" "}
                        {formatWithLocale(
                          new Date(formik.values.last_crawled),
                          "d MMMM - hh:mm aaaa"
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 space-y-4">
                        <APIErrors
                          APIError={backendError}
                          APIValidationErrors={backendValidationErrors}
                          fieldTranslations={{}}
                        />
                        <TextInputField
                          label={t(FIELDS.day_resync_period.label)}
                          name={FIELDS.day_resync_period.key}
                          type="number"
                          min="1"
                          max="365"
                          optional={FIELDS.day_resync_period.optional}
                        />
                        <ArrayInputField
                          label={t(FIELDS.start_urls.label)}
                          name={FIELDS.start_urls.key}
                          type="text"
                          optional={FIELDS.start_urls.optional}
                          addButtonLabel={t(FIELDS.start_urls.add_button_label)}
                          formik={formik}
                        />
                        <ArrayInputField
                          label={t(FIELDS.allowed_domains.label)}
                          name={FIELDS.allowed_domains.key}
                          type="text"
                          optional={FIELDS.allowed_domains.optional}
                          addButtonLabel={t(
                            FIELDS.allowed_domains.add_button_label
                          )}
                          formik={formik}
                        />
                        <TextInputField
                          label={t(FIELDS.max_request_per_minute.label)}
                          name={FIELDS.max_request_per_minute.key}
                          type="number"
                          min="20"
                          optional={FIELDS.max_request_per_minute.optional}
                        />
                        <TextInputField
                          label={t(FIELDS.page_count.label)}
                          name={FIELDS.page_count.key}
                          type="number"
                          max="500"
                        />
                        <SwitchField
                          label={t(FIELDS.active.label)}
                          name={FIELDS.active.key}
                          optional={FIELDS.active.optional}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div
              className={`text-right ${
                disabled && "opacity-50 hover:cursor-not-allowed"
              }`}
            >
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                disabled={
                  disabled ||
                  (formik.errors &&
                    Object.getOwnPropertyNames(formik.errors).length > 0)
                }
                successText={t(
                  "integrations:crawler.settings.success-text",
                  "Crawler settings saved!"
                )}
                initialText={
                  <>
                    {t("save")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              ></StateButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
