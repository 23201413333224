import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  ArrowSmRightIcon,
  CashIcon,
  ChatIcon,
  CheckCircleIcon,
  CheckIcon,
  ExclamationCircleIcon,
  PencilAltIcon,
  PhotographIcon,
  SupportIcon,
  XIcon,
} from "@heroicons/react/outline";
import HeaderNavigation from "src/components/HeaderNavigation";
import { useChannel } from "src/hooks/useChannel";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPhone } from "src/Helpers";
import { getTranslationPayload as tp } from "src/i18n";
import { buildRoute } from "src/router/router";
import { ChannelStatus } from "src/types/channel";
import ChannelStatusBadge from "./ChannelStatusBadge";
import ChannelWhatsAppTutorialCards from "./ChannelWhatsAppTutorialCards";
import * as meta from "./Meta";

export const CLOUD_API_LINKS = [
  {
    title: tp("channel:whatsapp-links.update-profile", "Update WA profile"),
    href: "https://business.facebook.com/wa/manage/phone-numbers/",
    icon: (
      <PhotographIcon
        className="h-5 w-5 text-gray-500 mr-2"
        aria-hidden="true"
      />
    ),
  },
  {
    title: tp(
      "channel:whatsapp-links.payment-settings",
      "Go to WA Payment Settings"
    ),
    href: "https://business.facebook.com/billing_hub/payment_settings",
    icon: (
      <CashIcon className="h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
    ),
  },
  {
    title: tp(
      "channel:whatsapp-links.business-verification",
      "Business Verification"
    ),
    href: "https://business.facebook.com/settings/security",
    icon: (
      <CheckCircleIcon
        className="h-5 w-5 text-gray-500 mr-2"
        aria-hidden="true"
      />
    ),
  },
  {
    title: tp(
      "channel:whatsapp-links.meta-cloud-api-support",
      "Contact WA Support"
    ),
    href: "https://business.facebook.com/direct-support",
    icon: (
      <SupportIcon className="h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
    ),
  },
];

export default function ChannelCloudAPIDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const channelId = params.id as string;
  const { channel, updateChannel } = useChannel(channelId);
  const { session } = useHilosStore();

  const channelInfo = useMemo(() => {
    return meta.AVAILABLE_CHANNELS.find(
      (chInfo) => chInfo.provider === channel?.channel_provider
    );
  }, [channel]);

  const handleDisableChannel = useCallback(() => {
    updateChannel({
      status: ChannelStatus.INACTIVE,
    });
  }, [updateChannel]);

  const handleEnableChannel = useCallback(() => {
    updateChannel({
      status: ChannelStatus.ACTIVE,
    });
  }, [updateChannel]);

  if (!channel || !channelInfo) {
    return null;
  }

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: "Home",
              url: buildRoute("dashboard"),
            },
            {
              name: "Settings",
              url: buildRoute("config-account"),
            },
            {
              name: "WhatsApp Accounts",
              url: buildRoute("config-channel"),
            },
            {
              name: channel?.name,
              url: buildRoute("config-channel-cloud-api-details", {
                ":id": channelId,
              }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ChatIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("channel:update-channel-title", "Channel {{channel}}", {
                channel: channel.name,
              })}
            </h2>
          </div>
        </div>
      </div>

      <div className="mt-4 ml-2">
        <div className="py-4 px-4 sm:px-6 lg:px-8">
          <p className="mt-2 ml-2 flex items-center text-xl font-bold leading-7 text-gray-900 sm:truncate">
            {t("channel:channel-details.info-title", "About your channel")}
          </p>
        </div>

        <div className="mx-10 grid grid-cols-1 sm:grid-cols-3 lg:gap-6 items-start">
          <div className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
            <div className="flex flex-1 flex-col p-6">
              <div className="flex justify-center">
                <span className="flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                  <channelInfo.icon className="mr-1 text-lg" />
                  {channelInfo.type}
                </span>
              </div>
              <img
                className="mt-3 max-h-14 flex-shrink-0 object-scale-down"
                src={channelInfo.logo}
                alt=""
              />
            </div>

            {CLOUD_API_LINKS.map((cLink) => (
              <a
                href={cLink.href}
                key={cLink.href}
                target="_blank"
                rel="noreferrer"
                className="focus:outline-none"
              >
                <div className="group relative order-last divide-gray-200 p-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:col-span-3 md:col-span-3 lg:order-none lg:col-span-2 last:rounded-b-lg flex items-center  justify-between ">
                  <h3 className="text-sm text-left flex items-center">
                    {cLink.icon}
                    {t(cLink.title)}
                  </h3>
                  <ArrowSmRightIcon className="h-5 w-5 text-gray-400" />
                </div>
              </a>
            ))}
          </div>

          <div className="col-span-2 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
            <div className="flex flex-1 flex-col p-6 text-left space-y-3">
              <div className="text-xs font-medium uppercase tracking-wider text-gray-500">
                <span className="mr-2">{channel.name}</span>
                <ChannelStatusBadge status={channel.status} />
              </div>
              {channel.id === session?.account.default_channel && (
                <div className="flex items-center text-sm text-gray-500">
                  <CheckCircleIcon className="-ml-0.5 mr-1 h-5 w-5" />
                  {t("channel:default-channel", "Default channel")}
                </div>
              )}
              <h2 className="text-3xl font-medium text-gray-800">
                {formatPhone(channel.channel_id, true, true)}
              </h2>
              {channel.status === "INACTIVE" && channel.connection_error_msg && (
                <div className="rounded-md bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">
                        {t(
                          "channel:connection-failed",
                          "Channel connection failed"
                        )}
                      </h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <p>{channel.connection_error_msg}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                  <Link
                    to={buildRoute("config-channel-cloud-api-update", {
                      ":id": channelId,
                    })}
                    className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                  >
                    {channel.status === ChannelStatus.NEW ? (
                      <>
                        <span className="ml-1">
                          {t(
                            "channel:channel-details.finish-connect-button",
                            "Finish connecting this number"
                          )}
                        </span>
                        <ArrowSmRightIcon
                          className="h-5 w-5 text-gray-400 ml-2"
                          aria-hidden="true"
                        />
                      </>
                    ) : (
                      <>
                        <PencilAltIcon
                          className="h-5 w-5 text-gray-400 mr-2"
                          aria-hidden="true"
                        />
                        <span className="ml-1">
                          {t(
                            "channel:channel-details.update-button",
                            "Update channel"
                          )}
                        </span>
                      </>
                    )}
                  </Link>
                </div>

                {channel.status === ChannelStatus.ACTIVE && (
                  <div className="-ml-px flex w-0 flex-1">
                    <button
                      type="button"
                      onClick={handleDisableChannel}
                      className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-red-900 hover:text-red-500"
                    >
                      <XIcon
                        className="h-5 w-5 text-red-400 mr-2"
                        aria-hidden="true"
                      />
                      <span className="mr-1">
                        {t(
                          "channel:channel-details.disable-button",
                          "Deactivate channel"
                        )}
                      </span>
                    </button>
                  </div>
                )}
                {channel.status === ChannelStatus.INACTIVE &&
                  !channel.connection_error_msg && (
                    <div className="-ml-px flex w-0 flex-1">
                      <button
                        type="button"
                        onClick={handleEnableChannel}
                        className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-green-900 hover:text-green-500"
                      >
                        <CheckIcon
                          className="h-5 w-5 text-green-400 mr-2"
                          aria-hidden="true"
                        />
                        <span className="mr-1">
                          {t(
                            "channel:channel-details.enable-button",
                            "Activate channel"
                          )}
                        </span>
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        <ChannelWhatsAppTutorialCards />
      </div>
    </div>
  );
}
