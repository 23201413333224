import { getTranslationPayload as t } from "src/i18n";

/* eslint-disable no-template-curly-in-string */
export const ERROR_MESSAGES = {
  REQUIRED: t("errors:required", "*Required"),
  DUPLICATED_NAME: t("errors:duplicated-name", "*The name is already in use."),
  DOTS_NOT_ALLOWED: t(
    "errors:dots-not-allowed",
    "*Dots in the name are not allowed."
  ),
  INVALID_URL: t("errors:invalid-url", "*Must be a valid URL"),
  NUMBER: t("errors:number", "*Must be a number"),
  PERCENTAGE: t(
    "errors:percentage",
    "*Must only be a number (without the % character)"
  ),
  PHONE_NUMBER: t("errors:phone-number", "*Must have at least 10 digits"),
  INVALID_PHONE_NUMBER: t(
    "errors:invalid-phone-number",
    "*Input a valid phone number"
  ),
  INVALID_EMAIL: t("errors:invalid-email", "*Must be a valid email"),
  MIN_CHARACTERS: t(
    "errors:min-characters",
    "*Must have at least ${min} characters"
  ),
  MAX_CHARACTERS: t(
    "errors:max-characters",
    "*Must have no more than ${max} characters"
  ),
  MIN_DATE: t("errors:min-date", "*Must be a date after ${min}"),
  MIN_NUMBER: t("errors:min-number", "*Must have at least ${min}"),
  MIN_NUMBER_VALUE: t("errors:min-number-value", "*Must be at least ${min}"),
  MAX_NUMBER: t("errors:max-number", "*Must have less than ${max}"),
  MAX_NUMBER_VALUE: t("errors:max-number-value", "*Must be less than ${max}"),
  MIN_PERCENTAGE: t("errors:min-percentage", "*Must be at least ${min}%"),
  MAX_PERCENTAGE: t("errors:max-percentage", "*Must be no more than ${max}%"),
  POSITIVE: t("errors:positive", "*Must be a positive number"),
  MIN_LENGTH: t("errors:min-length", "*Must have ${length}"),
  INVALID_DATE: t("errors:invalid-date", "*Must be a valid date"),
  INVALID_TIME: t("errors:invalid-time", "*Must be a valid time"),
  REQUIRED_OPTION_BOOL: t(
    "errors:required-option-bool",
    "*Provide an option for Yes and another for No"
  ),
  EMPTY_VARIABLE: t("errors:empty-variable", "*Empty variable"),
  AT_LEAST_ONE_ACTIVE_AND_CLOSED_STATE: t(
    "errors:at-least-one-state-of-each-type",
    "There must be at least one state of each type (ACTION and CLOSED)."
  ),
  AT_LEAST_TWO_STATES: t(
    "errors:at-least-two-states",
    "Must have at least 2 states."
  ),
};
