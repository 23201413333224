import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  FlowExecutionContactReadDetail,
  FlowExecutionStepReadDetail,
  FlowStepEdit,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";
import { STEP_TYPES } from "../FlowStepMeta";
import FlowExecutionStepDetailContainer from "./FlowExecutionStepDetailContainer";

interface FlowExecutionContactExecutionStepsDetailProps {
  flowExecutionContact: FlowExecutionContactReadDetail;
  setSelectedExecutionStep: (x: FlowExecutionStepReadDetail) => void;
  selectedExecutionStep: FlowExecutionStepReadDetail | undefined;
  flowVersion: FlowVersionDetailRead;
}
export default function FlowExecutionContactExecutionStepsDetail({
  flowExecutionContact,
  setSelectedExecutionStep,
  selectedExecutionStep,
  flowVersion,
}: FlowExecutionContactExecutionStepsDetailProps) {
  const { t } = useTranslation();
  const { formatWithLocale } = useDateFnsFormatLocale();

  const getExecutedStep = (step) => {
    return flowExecutionContact.execution_steps.find(
      (s) => s.step.id === step.id
    );
  };

  const getExecutedStepColor = (step) => {
    const executedStep = getExecutedStep(step);
    if (!executedStep) {
      return "bg-gray-200 text-gray-700";
    } else {
      if (executedStep.has_error) {
        return "bg-red-200 text-red-700";
      }
    }
    return "bg-green-200 text-green-700";
  };

  const stepInfo = useCallback((step: FlowStepEdit) => {
    let step_type = step.step_type;
    if (step_type.includes("WRAPPED")) {
      // For WrappedActions lets reuse the Action info
      step_type = "ACTION";
    }
    return STEP_TYPES.find((st) => st.value === step_type);
  }, []);

  return (
    <div className="p-6 grid grid-cols-1 grid-rows-2 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6">
      <div className="flow-root">
        <ul className="mt-4 -mb-8">
          {flowExecutionContact.execution_steps.map((execution_step, idx) => (
            <li key={idx}>
              <div className="relative pb-8">
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={`flex h-10 w-10 items-center justify-center rounded-full ring-8 ring-gray-50 ${getExecutedStepColor(
                        execution_step.step
                      )}`}
                    >
                      {stepInfo(execution_step.step)?.icon}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4">
                    <div>
                      <div className="text-sm text-gray-500">
                        <button
                          type="button"
                          onClick={(_) =>
                            setSelectedExecutionStep(execution_step)
                          }
                          className="text-left font-medium text-blue-600"
                        >
                          <div>
                            {t("step-title", "Step")} {execution_step.step.name}
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime="">
                              {formatWithLocale(
                                new Date(execution_step.created_on as string),
                                "EEEE d MMMM - HH:mm"
                              )}
                            </time>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div>
                      <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
                        {execution_step.step.step_type}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="sm:col-span-2">
        {selectedExecutionStep && (
          <FlowExecutionStepDetailContainer
            executionStep={selectedExecutionStep}
            flowVersion={flowVersion}
          />
        )}
      </div>
    </div>
  );
}
