import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import useHilosStore from "src/hooks/useHilosStore";
import FlowAdvancedOptionsDisclosure from "./components/FlowBuilderAdvancedOptionsDisclosure";

function FlowBuilderDetails() {
  const [t] = useTranslation();
  const { session } = useHilosStore();
  const { values, setFieldValue } = useFormikContext<FlowData>();

  const handleChangeChannel = useCallback(() => {
    if (values.steps) {
      for (const index in values.steps) {
        switch (values.steps[index].step_type) {
          case "TEMPLATE":
            setFieldValue(`steps.${index}.whatsapp_template`, null);
            setFieldValue(`steps.${index}.whatsapp_template_selected`, null);
            setFieldValue(`steps.${index}.whatsapp_template_variables`, null);
            break;
          default:
            break;
        }
      }
    }
  }, [values, setFieldValue]);

  return (
    <div className="overflow-y-auto w-full h-full">
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-2xl">
          <div className="space-y-14">
            <div className="row mt-10 text-center">
              <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
                {t("flows:start-with-basics", "Let's start with the basics.")}
              </h1>
              <p className="mb-5 text-sm text-gray-500">
                {t(
                  "flows:first-instruction",
                  "Give this flow a name and choose how it starts."
                )}
              </p>
            </div>

            <div className="space-y-4">
              <TextInputField
                type="text"
                name="name"
                label={t("flows:name.label", "Flow name")}
                placeholder={t("flows:name.placeholder", "Flow #1")}
              />

              {session?.account.has_multiple_active_channels && (
                <ChannelSelectField
                  name="channel"
                  label={t(
                    "flows:channel.label",
                    "Channel to use for this flow"
                  )}
                  onChange={handleChangeChannel}
                />
              )}

              <FlowAdvancedOptionsDisclosure>
                <SwitchField
                  name="should_trigger_webhook"
                  label={t(
                    "flows:should_trigger_webhook.label",
                    "Trigger webhooks for this flow?"
                  )}
                  help={t(
                    "flows:should_trigger_webhook.help",
                    "Enable this to receive webhooks when a contact joins or finishes this flow."
                  )}
                />
              </FlowAdvancedOptionsDisclosure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlowBuilderDetails;
