import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { eachMinuteOfInterval, endOfDay, format, startOfDay } from "date-fns";
import { FieldArray, useFormikContext } from "formik";
import { TimeWindow } from "@hilos/types/flows/Flow";
import { AccountRead } from "@hilos/types/private-schema";
import { DAY_LABELS_LONG } from "src/containers/account/AccountMeta";
import CheckboxField from "src/components/Form/CheckboxField";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import { hasItems } from "src/helpers/utils";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames } from "src/Helpers";
import { buildRoute } from "src/router/router";
import { useStepField } from "../hooks/useStepField";

interface FlowBuilderTimeWindowsProps {
  index: number;
}

function FlowBuilderTimeWindows({ index }: FlowBuilderTimeWindowsProps) {
  const [t] = useTranslation();
  const { session } = useHilosStore();
  const account = session?.account as unknown as AccountRead;
  const [setTimeWindow] = useStepField({
    index,
    name: "set_time_window",
  });
  const [timeWindowType] = useStepField({
    index,
    name: "time_window_type",
  });
  const [timeWindows] = useStepField({
    index,
    name: "time_windows",
  });
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const formatOptionLabel = ({ value, label, disabled, ...props }) => (
    <div
      className={classNames(
        "flex items-center justify-between",
        disabled ? "text-gray-500" : ""
      )}
    >
      <h4 className="font-medium">{label}</h4>
      {disabled && (
        <div className="ml-2 text-right">
          <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
            {t("unavailable", "Unavailable")}
          </span>
        </div>
      )}
    </div>
  );

  const handleChangeSetTimeWindow = useCallback(
    (nextSetTimeWindow: boolean) => {
      const nextTimeWindows: TimeWindow[] = [];

      if (nextSetTimeWindow) {
        for (let weekday = 0; weekday <= 6; weekday++) {
          const currentTimeWindow =
            timeWindows &&
            timeWindows.find((timeWindow) => timeWindow.weekday === weekday);

          nextTimeWindows.push(
            currentTimeWindow || {
              weekday: weekday,
              start_at: null,
              end_at: null,
              is_available: false,
            }
          );
        }
      }

      setFieldValue(`steps.${index}.time_windows`, nextTimeWindows);
      setFieldTouched(`steps.${index}.time_windows`);
    },
    [index, timeWindows, setFieldValue, setFieldTouched]
  );

  const hourOptions = useMemo(() => {
    const today = new Date();
    const hoursFNS = eachMinuteOfInterval(
      {
        start: startOfDay(today),
        end: endOfDay(today),
      },
      { step: 30 }
    );
    return hoursFNS.map((h) => ({
      label: format(h, "HH:mm"),
      value: format(h, "HH:mm:ss"),
    }));
  }, []);

  const handleAvailableDayChange = useCallback(
    (timeWindowIndex: number) => (event) => {
      const isChecked = event.target.checked;
      setFieldValue(
        `steps.${index}.time_windows.${timeWindowIndex}.is_available`,
        isChecked
      );
      setFieldValue(
        `steps.${index}.time_windows.${timeWindowIndex}.start_at`,
        isChecked ? "00:00" : null
      );
      setFieldValue(
        `steps.${index}.time_windows.${timeWindowIndex}.end_at`,
        isChecked ? "23:30" : null
      );
      setFieldTouched(
        `steps.${index}.time_windows.${timeWindowIndex}.is_available`
      );
    },
    [index, setFieldValue, setFieldTouched]
  );

  return (
    <>
      <SwitchField
        name={`steps.${index}.set_time_window`}
        label={t(
          "flows:steps.question.set-time-window.label",
          "Only continue within a specific schedule"
        )}
        help={t(
          "flows:steps.question.set-time-window.help",
          "Useful if you'd like to wait a day to continue but only if it's between Mon-Fri."
        )}
        onChange={handleChangeSetTimeWindow}
      />

      {setTimeWindow && hasItems(timeWindows) && (
        <>
          {!account.has_working_hours && (
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">
                    {t(
                      "flows:time-window.set-working-hours",
                      "Set your account's working hours to be able to use them here."
                    )}
                  </p>
                  <p className="mt-3 text-sm md:ml-6 md:mt-0">
                    <Link
                      to={buildRoute("config-account-hours")}
                      className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                    >
                      {t("set", "Set")}
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          )}
          <SelectorField
            name={`steps.${index}.time_window_type`}
            label={t(
              "flows:time-window-type.label",
              "Choose the time window type"
            )}
            help={t(
              "flows:time-window-type.help",
              "Use your account's working hours or set a custom time window."
            )}
            options={[
              {
                label: t("flows:time-window.working-hours"),
                value: "IN_WORKING_HOURS",
                disabled: !account.has_working_hours,
              },
              {
                label: t("flows:time-window.custom"),
                value: "CUSTOM",
                disabled: false,
              },
            ]}
            formatOptionLabel={formatOptionLabel}
            isOptionDisabled={(option) => option.disabled}
          />

          {timeWindowType === "CUSTOM" && (
            <table className="mt-4 table-auto">
              <thead className="h-5">
                <tr className="h-5">
                  <th colSpan={2} className="w-full" />
                  <th className="px-10 text-sm text-gray-700">
                    {t("flows:steps.time-windows.start-at.label", "From")}
                  </th>
                  <th className="px-10 text-sm text-gray-700">
                    {t("flows:steps.time-windows.end-at.label", "Until")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <FieldArray
                  name={`steps.${index}.time_windows`}
                  render={() => (
                    <>
                      {timeWindows.map((timeWindow, timeWindowIndex) => {
                        const prefixTimeWindow = `steps.${index}.time_windows.${timeWindowIndex}`;
                        return (
                          <tr key={timeWindow.weekday} className="h-14 w-full">
                            <td className="min-w-0">
                              <CheckboxField
                                name={`${prefixTimeWindow}.is_available`}
                                onChange={handleAvailableDayChange(
                                  timeWindowIndex
                                )}
                              />
                            </td>
                            <td className="w-auto">
                              <label
                                htmlFor={`${prefixTimeWindow}.is_available`}
                                className="select-none text-sm font-medium text-gray-700"
                              >
                                {DAY_LABELS_LONG[timeWindow.weekday]}
                              </label>
                            </td>
                            {timeWindow.is_available && (
                              <>
                                <td className="px-1">
                                  <SelectorField
                                    name={`${prefixTimeWindow}.start_at`}
                                    options={hourOptions}
                                  />
                                </td>
                                <td className="px-1">
                                  <SelectorField
                                    name={`${prefixTimeWindow}.end_at`}
                                    options={hourOptions}
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  )}
                />
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
}

export default FlowBuilderTimeWindows;
