import { useTranslation } from "react-i18next";

export default function BroadcastStatusBadge({ status }) {
  const { t } = useTranslation();

  switch (status) {
    case "AWAITING_CONFIRMATION":
      return (
        <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-xs font-medium text-yellow-800">
          {t(
            "broadcasts:status.awaiting-confirmation",
            "Awaiting confirmation"
          )}
        </span>
      );
    case "RUNNING":
      return (
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium text-indigo-800">
          {t("broadcasts:status.running", "Running")}
        </span>
      );
    case "COMPLETED":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium text-green-800">
          {t("broadcasts:status.completed", "Completed")}
        </span>
      );
    case "CANCELED":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium text-red-800">
          {t("broadcasts:status.canceled", "Canceled")}
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800">
          {status}
        </span>
      );
  }
}
