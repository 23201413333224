import { FormikProps } from "formik";
import { SessionData } from "@hilos/types/hilos";
import {
  ContactCustomField,
  PatchedContactEdit,
  WhatsAppTemplateSimpleRead,
} from "@hilos/types/private-schema";
import {
  BodyComponent,
  ButtonComponents,
  ButtonsComponent,
  FooterComponent,
  HeaderComponent,
  TemplateComponent,
  TemplateContent,
  TemplateContentType,
  URLButtonComponent,
  WhatsAppTemplate,
} from "@hilos/types/wa/templates";
import { getTemplateVarsFromString } from "src/containers/wa/WhatsAppTemplateMeta";
import { getContactVariables, hasItems, hasKeys } from "./utils";

export interface TemplateContentValues {
  locationHeaderVars: {
    latitude: string | null;
    longitude: string | null;
    name: string | null;
    address: string | null;
  } | null;
  headerVar: string | null;
  headerURL: string | null;
  bodyVars: string[] | null;
  buttonVars: string[] | null;
}

interface InterpolateParams {
  [key: string]: string | number;
}

export const getTemplateComponents = (template?: WhatsAppTemplate | WhatsAppTemplateSimpleRead | null) => {
  let header: HeaderComponent | null = null;
  let body: BodyComponent | null = null;
  let footer: FooterComponent | null = null;
  let buttons: ButtonsComponent | null = null;

  if (template && hasItems(template.components)) {
    for (const component of template.components as TemplateComponent[]) {
      switch (component.type) {
        case "HEADER":
          header = component;
          break;
        case "BODY":
          body = component;
          break;
        case "FOOTER":
          footer = component;
          break;
        case "BUTTONS":
          buttons = component;
          break;
        default:
          break;
      }
    }
  }

  return { header, body, footer, buttons };
};

export const getTemplateQuickReplyOptions = (template: WhatsAppTemplate) => {
  const { buttons } = getTemplateComponents(template);

  return (
    buttons?.buttons
      .filter((button) => button.type === "QUICK_REPLY")
      .map((button) => button.text) ?? []
  );
};

export function getValuesFromContent(
  content: TemplateContent<TemplateContentType>[]
) {
  let header: TemplateContent<"header"> | null = null;
  let body: TemplateContent<"body"> | null = null;
  const buttons: TemplateContent<"button">[] = [];

  if (hasItems(content)) {
    for (const item of content) {
      switch (item.type) {
        case "header":
          header = item as TemplateContent<"header">;
          break;
        case "body":
          body = item as TemplateContent<"body">;
          break;
        case "button":
          buttons.push(item as TemplateContent<"button">);
          break;
        default:
          break;
      }
    }
  }

  return { header, body, buttons };
}

export const getValuesFromTemplateContent = (
  content: TemplateContent<TemplateContentType>[]
): TemplateContentValues => {
  // The only parts of a template that can have variables
  // Message component types are lowercase while Template's are uppercase.
  // Because fuck consistency, that's why. Thanks, Meta.
  const { header, body, buttons } = getValuesFromContent(content);
  let name: string | null = null;
  let address: string | null = null;
  let latitude: string | null = null;
  let longitude: string | null = null;
  let headerVar: string | null = null;
  let headerURL: string | null = null;
  let bodyVars: string[] | null = null;
  let buttonVars: string[] | null = null;

  if (header && hasItems(header.parameters)) {
    for (const param of header.parameters) {
      if (param.type === "text") {
        headerVar = param.text;
      } else if (param.type === "location") {
        name = param.location.name;
        address = param.location.address;
        latitude = param.location.latitude;
        longitude = param.location.longitude;
      } else {
        headerURL = param[param.type].link;
      }
    }
  }

  // Body will always exist
  if (body && hasItems(body.parameters)) {
    for (const param of body.parameters) {
      if (param.type === "text") {
        if (!bodyVars) {
          bodyVars = [];
        }
        bodyVars.push(param.text);
      }
    }
  }

  if (buttons && hasItems(buttons)) {
    const urlButtons = buttons.filter((b) => b.sub_type === "url");
    if (urlButtons.length > 0) {
      urlButtons.forEach((btn, idx) => {
        if (
          btn &&
          hasItems(btn.parameters) &&
          btn.parameters[0].type === "text"
        ) {
          if (!buttonVars) {
            buttonVars = [];
          }
          buttonVars?.push(btn.parameters[0].text);
        }
      });
    }
  }

  return {
    locationHeaderVars: {
      name,
      address,
      latitude,
      longitude,
    },
    headerVar,
    bodyVars,
    headerURL,
    buttonVars,
  };
};

export function interpolate(data: string, params: InterpolateParams) {
  if (!data || !params || !hasKeys(params)) {
    return data || "";
  }

  return data.replace(
    /{{([^{}]*)}}/g,
    (original: string, key: string): string => {
      const nextValue = params[key];
      if (typeof nextValue === "string" || typeof nextValue === "number") {
        return `${nextValue}`;
      }

      return original;
    }
  );
}

export const hasVarText = (text: string) => {
  const regex = /{{\d+}}/;
  return regex.test(text);
};

export const interpolateTextValue = (
  text: string,
  variables: null | (string | null)[]
) => {
  if (!text || !variables || !hasItems(variables) || typeof text !== "string") {
    return text || "";
  }

  return variables.reduce<string>(
    (nextText, value, index) =>
      nextText.replaceAll(`{{${index + 1}}}`, value || `{{${index + 1}}}`),
    text
  );
};

export const getButtonVarURLIndex = (
  buttons: ButtonComponents[],
  buttonIdx: number
) => {
  let targetIndex = 0;

  buttons.forEach((btn, idx) => {
    if (btn.type === "URL" && hasVarText(btn.url)) {
      if (idx < buttonIdx) {
        targetIndex++;
      } else if (idx === buttonIdx) {
        return targetIndex;
      }
    }
  });

  return targetIndex;
};

export const getComponentIdx = (
  type: string,
  formik: FormikProps<any>
): number =>
  formik.values.components.findIndex((component) => component.type === type);

export const getRenderedMessage = (
  message: string,
  contact: PatchedContactEdit,
  session: SessionData,
  customFields: ContactCustomField[]
) => {
  const variables = {
    "user.email": session.email,
    "user.first_name": session.first_name,
    "user.last_name": session.last_name,
    "user.full_name": `${session.first_name || ""} ${session.last_name || ""}`,
  };
  const contactAvailableVariables = getContactVariables(customFields);

  if (contact) {
    const contactMeta = contact.meta || {};

    for (const variableKey of contactAvailableVariables) {
      if (variableKey) {
        if (contact[variableKey]) {
          variables[`contact.${variableKey}`] = contact[variableKey];
        } else if (contactMeta[variableKey]) {
          variables[`contact.${variableKey}`] = contactMeta[variableKey];
        }
      }
    }
  }

  return interpolate(message, variables);
};

export const getBaseUrl = (btnIndex, buttons: ButtonsComponent | null) => {
  const urlButtons: ButtonComponents[] | undefined = buttons
    ? buttons.buttons.filter((b) => b.type === "URL")
    : undefined;

  return (
    urlButtons &&
    urlButtons[btnIndex] &&
    "url" in urlButtons[btnIndex] &&
    (urlButtons[btnIndex] as URLButtonComponent).url.replace(
      getTemplateVarsFromString(
        (urlButtons[btnIndex] as URLButtonComponent).url
      ),
      ""
    )
  );
};
