import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";

interface FlowExecutionStepTriggerFlowDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepTriggerFlowDetail({
  executionStep,
}: FlowExecutionStepTriggerFlowDetailProps) {
  return null;
}
