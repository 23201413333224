import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ArrowSmLeftIcon,
  ExclamationCircleIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import { FormikProps } from "formik";
import { AccountStatus } from "src/types/account";
import StateButton from "../../components/StateButton";
import WhatsAppTemplateFormMissingFieldsNotice from "./WhatsAppTemplateFormMissingFieldsNotice";
import {
  getTemplateVarsFromTemplate,
  urlImageIsValid,
} from "./WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "./WhatsAppTemplatePreview";
import WhatsAppTemplatePreviewForm from "./WhatsAppTemplatePreviewForm";
import { initialTemplatePreviewValues } from "./WhatsAppTemplatePreviewForm";

interface WhatsAppTemplateFormStepEndProps {
  formik: FormikProps<any>;
  setCurrentStep: (step: string) => void;
  submitted: boolean;
  success: boolean;
  accountStatus?: AccountStatus;
}

export default function WhatsAppTemplateFormStepEnd({
  formik,
  setCurrentStep,
  submitted,
  success,
  accountStatus,
}: WhatsAppTemplateFormStepEndProps) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialTemplatePreviewValues);
  const templateHasVars = useMemo(
    () => getTemplateVarsFromTemplate(formik.values, true).length > 0,
    [formik.values]
  );

  const handleOnChangeValues = (values) => {
    if (formValues !== formik.values?.examples) {
      formik.setFieldValue(`examples`, values);
    }
    setFormValues(values);
  };

  useEffect(() => {
    if (!templateHasVars) {
      formik.setFieldValue(`examples`, {});
    }
  }, [templateHasVars]);

  const setExampleValuesForComponents = () => {
    Object.getOwnPropertyNames(formValues).forEach((key) => {
      switch (key) {
        case "bodyVars":
        case "buttonVars":
          if (formValues[key].length > 0) {
            setExampleComponent(key);
          }
          break;

        default:
          if (formValues[key] !== "") {
            setExampleComponent(key);
          }
          break;
      }
    });
  };

  const setExampleComponent = (key: string) => {
    var componentIdx: number;

    switch (key) {
      case "headerVar":
        componentIdx = formik.values.components.findIndex(
          (component) => component.type === "HEADER"
        );
        if (componentIdx >= 0) {
          formik.setFieldValue(
            `components.${componentIdx}.example.header_text`,
            [formValues[key]]
          );
        }
        break;
      case "headerURL":
        componentIdx = formik.values.components.findIndex(
          (component) => component.type === "HEADER"
        );
        if (componentIdx >= 0) {
          formik.setFieldValue(
            `components.${componentIdx}.example.header_handle`,
            [formValues[key]]
          );
          if (formik.values.components[componentIdx].format === "IMAGE") {
            urlImageIsValid(formValues[key]).then((result) => {
              if (!result) {
                formik.setFieldError(
                  "header_handle",
                  // i18n.t("templates:errors.invalid-image-url.description", "Please enter a valid url for the image header example")
                  "templates:errors.invalid-image-url.description"
                );
                formik.setSubmitting(false);
              }
            });
          }
        }
        break;
      case "buttonVars":
        componentIdx = formik.values.components.findIndex(
          (component) => component.type === "BUTTONS"
        );

        if (componentIdx >= 0) {
          const buttonVarsValues: string[] = [...formValues[key]];
          formik.values.components[componentIdx].buttons.forEach(
            (btn, index) => {
              if (btn.type === "URL") {
                formik.setFieldValue(
                  `components.${componentIdx}.buttons.${index}.example`,
                  [buttonVarsValues.shift()]
                );
              }
            }
          );
        }
        break;
      case "bodyVars":
        componentIdx = formik.values.components.findIndex(
          (component) => component.type === "BODY"
        );
        if (componentIdx >= 0) {
          formik.setFieldValue(`components.${componentIdx}.example.body_text`, [
            formValues[key],
          ]);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <div className="row mt-10 text-center">
        <div className="mx-auto max-w-2xl text-left">
          <WhatsAppTemplateFormMissingFieldsNotice formik={formik} />
        </div>
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          {t("step-n-of-n", "Step {{step}} of {{total}}", {
            step: 3,
            total: 3,
          })}
        </h6>
        {templateHasVars && (
          <>
            <h1 className="mt-2 mb-1 text-2xl font-bold leading-7 text-gray-900">
              {t(
                "templates:template-content-example.title",
                "WhatsApp Template Content Example"
              )}{" "}
            </h1>

            <div className="bg-blue-50 text-left p-4 md:w-1/2 mx-auto rounded-md flex space-x-2 items-center">
              <ExclamationCircleIcon
                className="h-20 w-20 text-blue-700"
                aria-hidden="true"
              />
              <div className="text-sm text-blue-700">
                <Trans i18nKey="templates:template-examples">
                  <p className="font-bold">
                    This fields are not saved with your template
                  </p>
                  <p className="text-sm">
                    When you submit your template for approval, WhatsApp will
                    use this example to understand the type of message you want
                    to send. Any link or media you add here will not be saved
                    with your template.
                  </p>
                </Trans>
              </div>
            </div>
          </>
        )}
        {!templateHasVars && (
          <>
            <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
              {t("templates:template-preview", "WhatsApp Template Preview")}
            </h1>
            <p className="mb-5 text-sm text-gray-500">
              {t(
                "templates:template-no-vars",
                "This template has no variables, it will appear as shown to your customers."
              )}
            </p>
          </>
        )}
      </div>
      <div
        className={`mt-8 grid grid-cols-1 gap-6 ${
          templateHasVars ? "sm:grid-cols-2" : ""
        }`}
      >
        {templateHasVars && (
          <div>
            {formik.errors && formik.errors.examples && (
              <div className="rounded-md  p-4">
                <div className="flex">
                  <div className="flex-shrink-0 self-center">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-sm text-red-700">
                      <p>{formik.errors.examples as string}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {formik.errors && formik.errors.header_handle && (
              <div className="rounded-md  p-4">
                <div className="flex">
                  <div className="flex-shrink-0 self-center">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-sm text-red-700">
                      <p>{t(formik.errors.header_handle as string)}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <WhatsAppTemplatePreviewForm
              template={formik.values}
              onTemplateValuesChange={handleOnChangeValues}
              initialValues={formik.values?.examples}
              isExample={true}
            />
          </div>
        )}
        <div className="mt-6">
          <WhatsAppTemplatePreview
            template={formik.values}
            values={formValues}
          />
        </div>
      </div>
      <div className="mx-auto my-14 max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <hr className="my-5" />
          <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
            <div className="justify-self-start">
              <button
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-medium leading-4 text-gray-900 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-500"
                type="button"
                onClick={(_) => setCurrentStep("content")}
              >
                <ArrowSmLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {t("content", "Content")}
              </button>
            </div>
            <div className="col text-center">
              <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
                {t("step-n-of-n", "Step {{step}} of {{total}}", {
                  step: 3,
                  total: 3,
                })}
              </h6>
            </div>
            {accountStatus === AccountStatus.ACTIVE && (
              <div className="justify-self-end">
                <StateButton
                  isSubmitting={formik.isSubmitting}
                  submitted={submitted}
                  success={success}
                  submittingText={t("submitting", "Submitting...")}
                  successText={t("submitted", "Submitted!")}
                  initialText={
                    <>
                      <UploadIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      {t("submit", "Submit")}
                    </>
                  }
                  btnClasses="inline-flex items-center px-4 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  btnClassesSuccess="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-3 text-sm font-medium leading-4 text-green-800 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  btnClassesError="inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-3 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                  onClick={(_) =>
                    templateHasVars ? setExampleValuesForComponents() : null
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
