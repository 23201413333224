import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { User } from "@hilos/types/private-schema";
import HeaderNavigation from "src/components/HeaderNavigation";
import useUser from "src/hooks/useAccountMember";
import { useChannels } from "src/hooks/useChannel";
import { useRoles } from "src/hooks/useRole";
import { userToString } from "src/Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import * as meta from "./Meta";
import UserV2Form from "./UserV2Form";

export default function UserV2Update() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const user = useUser(parseInt(params.id || ""));
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const channels = useChannels();
  const roles = useRoles();

  if (!user) {
    return (
      <div className="card">
        <div className="card-body text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  const massageUser = (user: User) => {
    const availableChannelIds =
      user.available_channels?.map((ch) => ch.id) || [];

    const permissions = {};
    Object.getOwnPropertyNames(meta.PERMISSIONS).forEach(
      (permName) =>
        (permissions[permName] = user.permissions?.includes(permName))
    );
    const initialData = {
      available_channels: channels.map((ch) =>
        availableChannelIds.includes(ch.id)
      ),
      permissions: permissions,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      role: user.role,
    };
    return initialData;
  };

  const formSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");

    const nextChannels = channels
      .map((ch, idx) => (formData.available_channels[idx] ? ch.id : null))
      .filter((x) => x);
    const nextPermissions = Object.getOwnPropertyNames(
      formData.permissions
    ).filter((permName) => formData.permissions[permName]);

    try {
      await axios.patch(
        buildAPIRoute(API_ROUTES.USER_DETAIL, {
          ":id": params.id,
        }),
        {
          available_channels: nextChannels,
          permissions: nextPermissions,
          first_name: formData.first_name,
          last_name: formData.last_name,
          role: formData.role,
        }
      );
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        navigate(buildRoute("account-members"));
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:contacts.error-creating-contact",
            "An error occurred while creating your contact. Please try again."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings"),
              url: buildRoute("config-account"),
            },
            {
              name: t("team-members", "Team members"),
              url: buildRoute("account-members"),
            },
            {
              name: userToString(user),
              url: buildRoute("account-members", { ":id": params.id }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <FontAwesomeIcon
                //@ts-ignore
                icon={faUserFriends}
                className="mr-4 h-7 w-7"
                aria-hidden="true"
              />
              {t("settings:team.user.title", "{{user}}'s settings", {
                user: userToString(user),
              })}
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              {t(
                "settings:team.user.subtitle",
                "Set permissions and channels for this user."
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl">
          <UserV2Form
            data={massageUser(user)}
            channels={channels}
            roles={roles}
            formSubmit={formSubmit}
            success={success}
            submitted={submitted}
          />
        </div>
      </div>
    </div>
  );
}
