import { HTMLAttributeAnchorTarget, ReactNode } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

interface ActiveAwareLinkProps {
  children: (isActive: boolean) => ReactNode;
  to: string;
  className: (isActive: boolean) => string;
  target?: HTMLAttributeAnchorTarget;
  isMatchEnd?: boolean;
  onClick?: (e) => void;
}

export default function ActiveAwareLink({
  children,
  to,
  className = () => "",
  target,
  isMatchEnd = true,
  ...props
}: ActiveAwareLinkProps) {
  let resolved = useResolvedPath(to);
  let isActive = !!useMatch({ path: resolved.pathname, end: isMatchEnd });

  return (
    <>
      {target ? (
        <a href={to} {...props} className={className(isActive)} target={target}>
          {children(isActive)}
        </a>
      ) : (
        <Link to={to} {...props} className={className(isActive)}>
          {children(isActive)}
        </Link>
      )}
    </>
  );
}
