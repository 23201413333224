import { useTranslation } from "react-i18next";
import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";

interface FlowExecutionStepTemplateDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepTemplateDetail({
  executionStep,
}: FlowExecutionStepTemplateDetailProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="space-y-4">
        <div>
          <h3 className="mt-4 text-tiny font-medium uppercase tracking-wider text-gray-500">
            {t(
              "flow-executions:execution-steps-detail.execution-result",
              "Execution result"
            )}
          </h3>

          <p className="mt-1 break-all rounded-md border border-neutral-light bg-gray-50 p-2 text-xs font-normal text-black">
            <code>
              {JSON.stringify(executionStep.execution_result?.answer, null, 2)}
            </code>
          </p>
        </div>
      </div>
    </>
  );
}
