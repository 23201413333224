import { FormEvent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PlusSmIcon } from "@heroicons/react/outline";
import { FieldArray } from "formik";
import { FormikProps } from "formik";
import AdvancedOptionDivider from "src/components/Form/AdvancedOptionDivider";
import HelpDocsLink from "src/components/HelpDocsLink";
import SelectorField from "../../components/Form/SelectorField";
import TextInputField from "../../components/Form/TextInputField";
import { getComponentIdx } from "../../helpers/template";
import * as meta from "./WhatsAppTemplateMeta";

const AVAILABLE_BUTTON_TYPES = Object.getOwnPropertyNames(
  meta.BUTTON_TYPES
).map((key) => ({
  value: key,
  label: meta.BUTTON_TYPES[key].label,
  help: meta.BUTTON_TYPES[key].help,
  icon: meta.BUTTON_TYPES[key].icon,
}));

const getAvailableButtons = (formik, buttonIdx = undefined) => {
  // Template can have up to:
  // 1 Phone number button
  // 2 URL buttons
  // Max number of buttons: 10
  // little hacky to valid the button type combination -> https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/components#quick-reply-buttons
  var availableButtons = AVAILABLE_BUTTON_TYPES;
  const existingButtonType =
    buttonIdx !== undefined
      ? formik.values.components[getComponentIdx("BUTTONS", formik)].buttons[
          buttonIdx
        ].type
      : null;

  const lastButtonType =
    buttonIdx !== undefined && buttonIdx && buttonIdx > 0
      ? formik.values.components[getComponentIdx("BUTTONS", formik)].buttons[
          buttonIdx - 1
        ].type
      : null;

  const prevBtnTypes =
    buttonIdx !== undefined
      ? formik.values.components[getComponentIdx("BUTTONS", formik)].buttons
          .slice(0, buttonIdx)
          .map((button) => button.type)
      : [];

  const categoryCount = {
    URL_PHONE_NUMBER: 0,
    QUICK_REPLY: 0,
  };

  prevBtnTypes.forEach((type) => {
    if (type === "URL" || type === "PHONE_NUMBER") {
      categoryCount.URL_PHONE_NUMBER += 1;
    } else if (type === "QUICK_REPLY") {
      categoryCount.QUICK_REPLY += 1;
    }
  });

  switch (lastButtonType) {
    case "URL":
    case "PHONE_NUMBER":
      if (categoryCount.QUICK_REPLY > 0)
        availableButtons = availableButtons.filter(
          (btn) => btn.value !== "QUICK_REPLY"
        );
      break;

    case "QUICK_REPLY":
      if (categoryCount.URL_PHONE_NUMBER > 0)
        availableButtons = availableButtons.filter(
          (btn) => btn.value !== "PHONE_NUMBER" && btn.value !== "URL"
        );
      break;

    default:
      break;
  }

  const URLBtnLength = formik.values.components[
    getComponentIdx("BUTTONS", formik)
  ].buttons.filter((button) => button.type === "URL").length;
  const PhoneBtnLength = formik.values.components[
    getComponentIdx("BUTTONS", formik)
  ].buttons.filter((button) => button.type === "PHONE_NUMBER").length;
  const QRBtnLength = formik.values.components[
    getComponentIdx("BUTTONS", formik)
  ].buttons.filter((button) => button.type === "QUICK_REPLY").length;

  if (URLBtnLength + PhoneBtnLength + QRBtnLength > 10 && !existingButtonType) {
    return [];
  }
  if (URLBtnLength > 1 && existingButtonType !== "URL") {
    availableButtons = availableButtons.filter((type) => type.value !== "URL");
  }
  if (PhoneBtnLength > 0 && existingButtonType !== "PHONE_NUMBER") {
    availableButtons = availableButtons.filter(
      (type) => type.value !== "PHONE_NUMBER"
    );
  }

  return availableButtons;
};

interface WhatsAppTemplateFormButtonsComponentProps {
  formik: FormikProps<any>;
  onChangeInputValidate: (
    field: string,
    e: FormEvent<HTMLTextAreaElement> | FormEvent<HTMLInputElement>,
    formikFieldName: string,
    index?: number
  ) => void;
  addVariableButton: (
    field: string,
    count: number,
    max: number,
    idx?: number
  ) => JSX.Element;
  buttonVarsCount: number[];
}

export default function WhatsAppTemplateFormButtonsComponent({
  formik,
  onChangeInputValidate,
  addVariableButton,
  buttonVarsCount,
}: WhatsAppTemplateFormButtonsComponentProps) {
  const { t } = useTranslation();

  const formatOptionLabel = ({
    value,
    label,
    icon,
    help,
  }: {
    value: string;
    label: string;
    icon: JSX.Element;
    help: string;
  }): JSX.Element => {
    return (
      <div className="flex items-center py-3">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{t(label, label)}</h4>
          {help && <p className="mb-0 text-xs">{t(help, help)}</p>}
        </div>
      </div>
    );
  };

  const onSelectButtonType = (buttonIdx, option) => {
    let newButton = {
      type: option.value,
      text: "",
    };

    switch (option.value) {
      case "URL":
        newButton["url"] = "";
        break;
      case "PHONE_NUMBER":
        newButton["phone_number"] = "";
        break;

      default:
        break;
    }

    formik.setFieldValue(
      `components.${getComponentIdx("BUTTONS", formik)}.buttons.${buttonIdx}`,
      newButton
    );
  };

  return (
    <div className="mt-5 mb-10 bg-white p-5 shadow sm:rounded-md">
      <div className="flex flex-row justify-between">
        <div className="flex">
          {t("buttons", "Buttons")}{" "}
          <span className="ml-4 inline-flex items-center justify-center rounded-full bg-neutral-extralight px-2 py-1 text-xs font-semibold leading-none text-neutral">
            {t("optional", "Optional")}
          </span>
        </div>
        <HelpDocsLink href="https://hilos.io/docs/user/using-hilos/templates/creating-a-template" />
      </div>
      <p className="mt-2 mb-4 text-sm text-gray-400">
        {t(
          "templates:template-content.buttons.description",
          "Create up to 10 buttons that let customers respond to your message or take action."
        )}
      </p>

      <FieldArray
        name={`components.${getComponentIdx("BUTTONS", formik)}.buttons`}
        validateOnChange={true}
        render={(arrayHelpers) => (
          <>
            <ul className="">
              {formik.values.components[getComponentIdx("BUTTONS", formik)]
                .buttons &&
                formik.values.components[
                  getComponentIdx("BUTTONS", formik)
                ].buttons.map((button, bIdx) => (
                  <AdvancedOptionDivider
                    keyVal={bIdx}
                    title={`${t("button", "Button")} ${bIdx + 1}`}
                    handleRemove={() => arrayHelpers.remove(bIdx)}
                  >
                    <SelectorField
                      key={bIdx}
                      label={t(
                        meta.BUTTON_FIELDS.type.label,
                        meta.BUTTON_FIELDS.type.label
                      )}
                      name={`components.${getComponentIdx(
                        "BUTTONS",
                        formik
                      )}.buttons.${bIdx}.${meta.BUTTON_FIELDS.type.key}`}
                      // @ts-ignore
                      options={getAvailableButtons(formik, bIdx)}
                      // @ts-ignore
                      formatOptionLabel={formatOptionLabel}
                      onSelect={(option) => {
                        onSelectButtonType(bIdx, option);
                      }}
                    />
                    {button.type === "QUICK_REPLY" && (
                      <div className="py-2 pl-4">
                        <TextInputField
                          name={`components.${getComponentIdx(
                            "BUTTONS",
                            formik
                          )}.buttons.${bIdx}.${meta.BUTTON_FIELDS.text.key}`}
                          label={t(
                            meta.BUTTON_FIELDS.text.label,
                            meta.BUTTON_FIELDS.text.label
                          )}
                          type="text"
                          containerClassName="relative rounded-md shadow-sm"
                          maxLength={25}
                          onChange={(e) =>
                            onChangeInputValidate(
                              "button",
                              e,
                              `components.${getComponentIdx(
                                "BUTTONS",
                                formik
                              )}.buttons.${bIdx}.${meta.BUTTON_FIELDS.text.key}`
                            )
                          }
                        />
                      </div>
                    )}
                    {button.type === "PHONE_NUMBER" && (
                      <div className="py-2 pl-4 grid gap-2">
                        <TextInputField
                          name={`components.${getComponentIdx(
                            "BUTTONS",
                            formik
                          )}.buttons.${bIdx}.${meta.BUTTON_FIELDS.text.key}`}
                          label={t(
                            meta.BUTTON_FIELDS.text.label,
                            meta.BUTTON_FIELDS.text.label
                          )}
                          type="text"
                          containerClassName="relative rounded-md shadow-sm"
                          maxLength={25}
                          onChange={(e) =>
                            onChangeInputValidate(
                              "button",
                              e,
                              `components.${getComponentIdx(
                                "BUTTONS",
                                formik
                              )}.buttons.${bIdx}.${meta.BUTTON_FIELDS.text.key}`
                            )
                          }
                        />
                        <TextInputField
                          name={`components.${getComponentIdx(
                            "BUTTONS",
                            formik
                          )}.buttons.${bIdx}.${
                            meta.BUTTON_FIELDS.phone_number.key
                          }`}
                          label={t(
                            meta.BUTTON_FIELDS.phone_number.label,
                            meta.BUTTON_FIELDS.phone_number.label
                          )}
                          placeholder={t(
                            meta.BUTTON_FIELDS.phone_number.placeholder,
                            meta.BUTTON_FIELDS.phone_number.placeholder
                          )}
                          type="tel"
                          containerClassName="relative rounded-md shadow-sm"
                          maxLength={20}
                        />
                      </div>
                    )}
                    {button.type === "URL" && (
                      <div className="py-2 pl-4">
                        <TextInputField
                          name={`components.${getComponentIdx(
                            "BUTTONS",
                            formik
                          )}.buttons.${bIdx}.${meta.BUTTON_FIELDS.text.key}`}
                          label={t(
                            meta.BUTTON_FIELDS.text.label,
                            meta.BUTTON_FIELDS.text.label
                          )}
                          type="text"
                          containerClassName="relative rounded-md shadow-sm"
                          maxLength={25}
                          onChange={(e) =>
                            onChangeInputValidate(
                              "button",
                              e,
                              `components.${getComponentIdx(
                                "BUTTONS",
                                formik
                              )}.buttons.${bIdx}.${meta.BUTTON_FIELDS.text.key}`
                            )
                          }
                        />
                        <div className="mt-2">
                          <TextInputField
                            name={`components.${getComponentIdx(
                              "BUTTONS",
                              formik
                            )}.buttons.${bIdx}.${meta.BUTTON_FIELDS.url.key}`}
                            label={t(
                              meta.BUTTON_FIELDS.url.label,
                              meta.BUTTON_FIELDS.url.label
                            )}
                            placeholder={t(
                              meta.BUTTON_FIELDS.url.placeholder,
                              meta.BUTTON_FIELDS.url.placeholder
                            )}
                            type="text"
                            containerClassName="relative rounded-md shadow-sm"
                            maxLength={2000}
                            onChange={(e) => {
                              onChangeInputValidate(
                                "button_url",
                                e,
                                `components.${getComponentIdx(
                                  "BUTTONS",
                                  formik
                                )}.buttons.${bIdx}.${
                                  meta.BUTTON_FIELDS.url.key
                                }`,
                                bIdx
                              );
                            }}
                          />
                          <p className="mt-2 text-xs text-gray-400">
                            <Trans i18nKey="templates:url-variable">
                              One variable{" "}
                              <code className=" bg-neutral-extralight text-neutral">
                                {"{{1}}"}
                              </code>{" "}
                              can be added at the end of the URL to create a
                              personalized link.
                            </Trans>
                          </p>
                          {formik.values.components[
                            getComponentIdx("BUTTONS", formik)
                          ].buttons[bIdx].url && (
                            <div className="mt-1">
                              {addVariableButton(
                                "button",
                                buttonVarsCount[bIdx],
                                1,
                                bIdx
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </AdvancedOptionDivider>
                ))}
            </ul>
            {formik.values.components[getComponentIdx("BUTTONS", formik)]
              .buttons.length < 10 && (
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                  onClick={(_) =>
                    arrayHelpers.push({
                      type: "",
                      text: "",
                    })
                  }
                >
                  <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                  {t("add-button", "Add button")}
                </button>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
}
