import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ArrowSmRightIcon,
  ChatAlt2Icon,
  CheckCircleIcon,
  ClockIcon,
  DeviceMobileIcon,
  FlagIcon,
} from "@heroicons/react/outline";
import AnchorTracked from "src/components/Posthog/AnchorTracked";
import { classNames } from "src/Helpers";

export default function ChannelWhatsAppTutorialCards() {
  const { t } = useTranslation();

  const TUTORIAL_CARDS = [
    {
      title: t(
        "channel:tutorials.wa-api-vs-wa-business.title",
        "WhatsApp API vs WhatsApp Business"
      ),
      href: "https://hilos.io/docs/user/whatsapp-guides/wa-vs-api",
      icon: <DeviceMobileIcon className="h-6 w-6" aria-hidden="true" />,
      iconForeground: "text-hilos",
      iconBackground: "bg-hilos-light",
      text: (
        <>
          <p>
            {t(
              "channel:tutorials.wa-api-vs-wa-business.intro",
              "The WhatsApp Business App and the WhatsApp API have several key differences."
            )}
          </p>
          <p>
            {t(
              "channel:tutorials.wa-api-vs-wa-business.text",
              "Get to know what changes when you move from WA Business to the WhatsApp API with Hilos."
            )}
          </p>
        </>
      ),
      actionButton: (
        <AnchorTracked
          href="https://hilos.io/docs/user/whatsapp-guides/wa-vs-api"
          rel="noreferrer"
          target="_blank"
          className="inline-flex items-center rounded-md border border-hilos bg-white px-4 py-2 text-sm font-medium text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
          posthogEvent="schedule-call clicked"
        >
          {t("channel:tutorials.wa-learn-more", "Learn more")}
          <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
        </AnchorTracked>
      ),
    },
    {
      title: t(
        "channel:tutorials.business-verification.title",
        "Business Verification"
      ),
      href: "https://hilos.io/docs/unlisted/business-verification#steps-to-complete-facebook-business-verification",
      icon: <CheckCircleIcon className="h-6 w-6" aria-hidden="true" />,
      iconForeground: "text-hilos",
      iconBackground: "bg-hilos-light",
      text: (
        <>
          <p>
            {t(
              "channel:tutorials.business-verification.intro",
              "Facebook's Business Verification is needed to increase your outbound messaging limits."
            )}
          </p>
          <p>
            {t(
              "channel:tutorials.business-verification.text",
              "Learn what are the requirements and how to verify your business."
            )}
          </p>
        </>
      ),
      actionButton: (
        <AnchorTracked
          href="https://hilos.io/docs/unlisted/business-verification#steps-to-complete-facebook-business-verification"
          rel="noreferrer"
          target="_blank"
          className="inline-flex items-center rounded-md border border-hilos bg-white px-4 py-2 text-sm font-medium text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
          posthogEvent="schedule-call clicked"
        >
          {t("learn-more", "Learn more")}
          <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
        </AnchorTracked>
      ),
    },
    {
      title: t("channel:tutorials.messaging-limits.title", "Messaging Limits"),
      href: "https://hilos.io/docs/user/whatsapp-guides/messaging-limits",
      icon: <ChatAlt2Icon className="h-6 w-6" aria-hidden="true" />,
      iconForeground: "text-hilos",
      iconBackground: "bg-hilos-light",
      text: (
        <>
          <p>
            {t(
              "channel:tutorials.messaging-limits.intro",
              "Learn all about WA's messaging limits and how to get them increased."
            )}
          </p>
          <p>
            {t(
              "channel:tutorials.messaging-limits.text",
              "These limits depend on your messaging volume, your business verification status and your phone number quality."
            )}
          </p>
        </>
      ),
      actionButton: (
        <AnchorTracked
          href="https://hilos.io/docs/user/whatsapp-guides/messaging-limits"
          rel="noreferrer"
          target="_blank"
          className="inline-flex items-center rounded-md border border-hilos bg-white px-4 py-2 text-sm font-medium text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
          posthogEvent="schedule-call clicked"
        >
          {t("learn-more", "Learn more")}
          <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
        </AnchorTracked>
      ),
    },
    {
      title: t("channel:tutorials.number-quality.title", "Number Quality"),
      href: "https://hilos.io/docs/user/whatsapp-guides/number-quality",
      icon: <FlagIcon className="h-6 w-6" aria-hidden="true" />,
      iconForeground: "text-hilos",
      iconBackground: "bg-hilos-light",
      text: (
        <>
          <p>
            {t(
              "channel:tutorials.number-quality.intro",
              "This is a measure of how many people mark your messages as spam or block your number."
            )}
          </p>
          <p>
            {t(
              "channel:tutorials.number-quality.text",
              "This affects how many outbound conversations you can have, as well as if a template gets paused, or your account blocked."
            )}
          </p>
        </>
      ),
      actionButton: (
        <AnchorTracked
          href="https://hilos.io/docs/user/whatsapp-guides/number-quality"
          rel="noreferrer"
          target="_blank"
          className="inline-flex items-center rounded-md border border-hilos bg-white px-4 py-2 text-sm font-medium text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
          posthogEvent="schedule-call clicked"
        >
          {t("learn-more", "Learn more")}
          <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
        </AnchorTracked>
      ),
    },
    {
      title: t(
        "channel:tutorials.conversation-window.title",
        "24hr Conversation Window"
      ),
      href: "https://hilos.io/docs/user/whatsapp-guides/24h-window",
      icon: <ClockIcon className="h-6 w-6" aria-hidden="true" />,
      iconForeground: "text-hilos",
      iconBackground: "bg-hilos-light",
      text: (
        <>
          <p>
            {t(
              "channel:tutorials.conversation-window.intro",
              "WhatsApp conversations happen in a 24hr conversation window."
            )}
          </p>
          <p>
            {t(
              "channel:tutorials.conversation-window.text",
              "Learn about how this works, when is a template required to start a conversation, and WA's conversation-based pricing."
            )}
          </p>
        </>
      ),
      actionButton: (
        <AnchorTracked
          href="https://hilos.io/docs/user/whatsapp-guides/24h-window"
          rel="noreferrer"
          target="_blank"
          className="inline-flex items-center rounded-md border border-hilos bg-white px-4 py-2 text-sm font-medium text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
          posthogEvent="schedule-call clicked"
        >
          {t("learn-more", "Learn more")}
          <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
        </AnchorTracked>
      ),
    },
  ];

  return (
    <>
      <div className="py-4 px-4 sm:px-6 lg:px-8 mt-6">
        <p className="mt-2 ml-2 flex items-center text-xl font-bold leading-7 text-gray-900 sm:truncate">
          {t(
            "channel:channel-details.channel-tutorials-title",
            "Learn more about how the WhatsApp API works"
          )}
        </p>
      </div>
      <div className="mx-10 grid sm:grid-cols-6 lg:grid-cols-6 lg:gap-6 mb-4">
        {TUTORIAL_CARDS.map((action) => (
          <div
            key={action.href}
            className="group relative order-last rounded-lg border border-gray-200 bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:col-span-3 md:col-span-3 lg:order-none lg:col-span-2"
          >
            <div>
              <Link to={action.href} className="focus:outline-none">
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    "inline-flex rounded-lg p-3 ring-4 ring-white"
                  )}
                >
                  {action.icon}
                </span>
              </Link>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <Link to={action.href} className="focus:outline-none">
                  {t(action.title)}
                </Link>
              </h3>
              <div className="mt-2 space-y-2 text-sm text-gray-500">
                {action.text}
              </div>
              {action.actionButton && (
                <div className="mt-4 text-sm text-gray-500">
                  {action.actionButton}
                </div>
              )}
            </div>
            <Link to={action.href} className="focus:outline-none">
              <span
                className="absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
