import { useTranslation } from "react-i18next";
import {
  FlowDetailWithoutStepsRead,
  FlowExecutionReadDetail,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import AnalyticsCard from "src/components/Analytics/AnalyticsCard";

interface FlowAnalyticsSummaryProps {
  flowObject:
    | FlowDetailWithoutStepsRead
    | FlowVersionDetailRead
    | FlowExecutionReadDetail;
}

export default function FlowAnalyticsSummary({
  flowObject,
}: FlowAnalyticsSummaryProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-end mt-2 text-gray-500 text-sm px-6 italic">
        (
        {t(
          "flows:analytics.sync-time",
          "These analytics are updated every 10 minutes"
        )}
        )
      </div>
      <div className="grid grid-cols-2 grid-rows-2 gap-4 mt-2 mb-4 px-6 sm:grid-cols-3 lg:grid-cols-6 lg:grid-rows-1">
        <AnalyticsCard
          label={t("flows:analytics.num_contacts", "Contacts")}
          tooltipText={t(
            "flows:analytics.num_contacts-tooltip",
            "Total contacts that have gone through this flow."
          )}
          value={flowObject.num_contacts || 0}
          total={flowObject.num_contacts || 0}
        />

        <AnalyticsCard
          label={t("flows:analytics.expired", "Expired")}
          tooltipText={t(
            "flows:analytics.expired-tooltip",
            "Contacts that stopped responding and didn't finish this flow."
          )}
          value={flowObject.expired || 0}
          total={flowObject.num_contacts || 0}
          showPercentage
        />

        <AnalyticsCard
          label={t("flows:analytics.ongoing", "Ongoing")}
          tooltipText={t(
            "flows:analytics.ongoing-tooltip",
            "Contacts that are still active in this flow."
          )}
          value={flowObject.running || 0}
          total={flowObject.num_contacts || 0}
          showPercentage
        />

        <AnalyticsCard
          label={t("flows:analytics.canceled", "Canceled")}
          tooltipText={t(
            "flows:analytics.canceled-tooltip",
            "Contacts whose flow execution has been canceled."
          )}
          value={flowObject.canceled || 0}
          total={flowObject.num_contacts || 0}
          showPercentage
        />

        <AnalyticsCard
          label={t("flows:analytics.failed", "Failed")}
          tooltipText={t(
            "flows:analytics.failed-tooltip",
            "Contacts that couldn't finish this flow due to an error."
          )}
          value={flowObject.failed || 0}
          total={flowObject.num_contacts || 0}
          showPercentage
        />

        <AnalyticsCard
          label={t("flows:analytics.completed", "Completed")}
          tooltipText={t(
            "flows:analytics.completed-tooltip",
            "Contacts that successfully completed this flow."
          )}
          value={flowObject.completed || 0}
          total={flowObject.num_contacts || 0}
          showPercentage
        />
      </div>
    </>
  );
}
