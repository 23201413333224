import { useQuery } from "react-query";
import axios from "axios";
import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import FlowExecutionStepDetail from "../flow_execution_contact/FlowExecutionStepDetail";

interface FlowViewerStepExecutionDetailProps {
  id: string;
}

const fetchFlowExecutionStepData = async ({ queryKey }) => {
  const [_, id] = queryKey;
  if (!id) {
    return null;
  }

  const { data } = await axios.get<FlowExecutionStepReadDetail>(
    buildAPIRoute(API_ROUTES.FLOW_EXECUTION_STEP_DETAIL, {
      ":id": id,
    })
  );

  return data;
};

function FlowViewerStepExecutionDetail({
  id,
}: FlowViewerStepExecutionDetailProps) {
  const { data, isLoading } = useQuery(
    ["flow_execution_step", id],
    fetchFlowExecutionStepData
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return <FlowExecutionStepDetail executionStep={data} />;
}

export default FlowViewerStepExecutionDetail;
