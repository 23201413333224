import { useTranslation } from "react-i18next";
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import { ConversationContent } from "@hilos/types/private-schema";
import useConversationContentSearch from "../../../../hooks/useConversationContentSearch";
import ConversationContentSearchResults from "./ConversationContentSearchResults";

interface ConversationContentSearchParams {
  inboxContactId: string;
  focusedConversationContentId: string | null;
  onClose: () => void;
  onFocusConversationContent: (data: ConversationContent) => void;
}

function ConversationContentSearch({
  inboxContactId,
  focusedConversationContentId,
  onClose,
  onFocusConversationContent,
}: ConversationContentSearchParams) {
  const { t } = useTranslation();
  const { pages, hasNextPage, handleNextPage, handleChangeSearch } =
    useConversationContentSearch({ inboxContactId });

  return (
    <aside className="flex h-full w-full flex-shrink-0 flex-col bg-white lg:w-72">
      <div className="flex h-16 w-full flex-col justify-center bg-gray-100 px-4">
        <div className="flex items-center space-x-2">
          <div className="flex grow items-baseline space-x-2">
            <h2 className="text-md font-medium text-gray-900">
              {t("inbox:search-in-messages", "Search in messages")}
            </h2>
          </div>
          <button
            type="button"
            className="rounded-md text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="w-42 relative grow-0 bg-gray-100">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          className="block w-full border-0 border-transparent bg-gray-100 py-2 pl-10 pr-4 focus:ring-0 sm:text-sm"
          type="search"
          placeholder={t("search", "Search")}
          onChange={handleChangeSearch}
        />
      </div>
      <div className="relative flex flex-1 flex-col overflow-y-auto border-t border-gray-200 ">
        <ConversationContentSearchResults
          pages={pages}
          hasNextPage={hasNextPage}
          focusedConversationContentId={focusedConversationContentId}
          handleNextPage={handleNextPage}
          onFocusConversationContent={onFocusConversationContent}
        />
      </div>
    </aside>
  );
}

export default ConversationContentSearch;
