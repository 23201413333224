import { XIcon } from "@heroicons/react/outline";
import { FlowExecutionContactStatusEnum } from "@hilos/types/private-schema";
import StateButton from "src/components/StateButton";
import { useCreateStateButtonsContactHook } from "src/hooks/useFlowExecutionContactStatus";
import { FlowExecutionContactStatusPayload } from "src/types/flows/FlowExecutionContact";

interface FlowExecutionContactControlsButtonsProps {
  flowExecutionContactId: string;
  flowExecutionContactStatus: FlowExecutionContactStatusEnum | undefined;
  iconOnly?: boolean;
  btnClasses?: string;
  onFlowExecutionContactStatusUpdateSuccess: (
    nextStatus: FlowExecutionContactStatusPayload
  ) => void;
}

export default function FlowExecutionContactControlsButtons({
  flowExecutionContactId,
  flowExecutionContactStatus,
  iconOnly = false,
  btnClasses = "",
  onFlowExecutionContactStatusUpdateSuccess = () => null,
}: FlowExecutionContactControlsButtonsProps) {
  const { cancelState, handleCancel, stateButtonBtnClasses } =
    useCreateStateButtonsContactHook({
      flowExecutionContactId,
      flowExecutionContactStatus,
      onFlowExecutionContactStatusUpdateSuccess,
    });
  return (
    <>
      {flowExecutionContactStatus &&
        !["CANCELED", "COMPLETED"].includes(flowExecutionContactStatus) && (
          <>
            {!cancelState.disabled && (
              <StateButton
                btnClasses={`focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-red-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-700 shadow-sm ${
                  cancelState.disabled
                    ? "disabled:opacity-50"
                    : "cursor-pointer hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-100"
                } ${btnClasses}`}
                {...stateButtonBtnClasses}
                submittingText={iconOnly ? "" : "Canceling..."}
                successText={iconOnly ? "" : "Canceled"}
                initialText={
                  iconOnly ? (
                    <>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </>
                  ) : (
                    <>
                      <XIcon
                        className="-ml-0.5 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />{" "}
                      Cancel
                    </>
                  )
                }
                onClick={handleCancel}
                isSubmitting={cancelState.isSubmitting}
                submitted={cancelState.submitted}
                success={cancelState.success}
              />
            )}
          </>
        )}
    </>
  );
}
