import { useCallback, useMemo, useState } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import axios from "axios";
import { debounce } from "lodash";
import { CursorPageData } from "@hilos/types/hilos";
import {
  UserNotification,
  UserNotificationUnseenCount,
} from "@hilos/types/private-schema";
import { hasItems } from "src/helpers/utils";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

type FetchScheduledMessagesParams = QueryFunctionContext<
  [string, string, string]
>;

export async function fetchUserNotificationUnseenCount() {
  const { data } = await axios.get<UserNotificationUnseenCount>(
    API_ROUTES.USER_NOTIFICATIONS_UNSEEN_COUNT
  );
  return data.count || 0;
}

export async function fetchUserNotifications({
  signal,
  pageParam,
  queryKey,
}: FetchScheduledMessagesParams): Promise<CursorPageData<UserNotification> | null> {
  const { data } = await axios.get<CursorPageData<UserNotification>>(
    API_ROUTES.USER_NOTIFICATIONS_LIST,
    {
      signal,
      params: {
        cursor: pageParam,
        search: queryKey[1] || undefined,
        seen: queryKey[2] === "ALL" ? undefined : queryKey[2] === "READ",
      },
    }
  );
  return data;
}

export async function markNotificationAsRead(id: string, seen: boolean) {
  const { data } = await axios.patch<[UserNotification]>(
    buildAPIRoute(API_ROUTES.USER_NOTIFICATIONS_UPDATE, { ":id": id }),
    { seen: seen }
  );
  queryClient.invalidateQueries({ queryKey: ["user_notifications"] });
  return data;
}

export async function markNotificationsAsSeen() {
  await axios.post<[UserNotification]>(
    buildAPIRoute(API_ROUTES.USER_NOTIFICATIONS_SEEN)
  );
  queryClient.invalidateQueries({ queryKey: ["user_notifications"] });
}

export function useUserNotifications() {
  const [search, setSearch] = useState("");
  const [seenFilter, setSeenFilter] = useState<string>("ALL");

  const {
    data,
    refetch,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ["user_notifications", search, seenFilter],
    fetchUserNotifications,
    {
      getNextPageParam: (lastPage, pages) =>
        (lastPage && lastPage.next) || undefined,
      getPreviousPageParam: (lastPage, pages) =>
        (lastPage && lastPage.previous) || undefined,
      retry: 3,
    }
  );

  const handleChangeSearch = useCallback(
    debounce((event) => {
      const nextSearch = event.target.value;
      setSearch(nextSearch);
    }, 500),
    []
  );

  const [pages, count] = useMemo(() => {
    if (data && hasItems(data.pages)) {
      const lastPage = data.pages[data.pages.length - 1];
      return [data.pages, (lastPage && lastPage.count) || 0];
    }
    return [[], 0];
  }, [data]);

  return {
    pages,
    count,
    refetch,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    seenFilter,
    setSeenFilter,
    handleChangeSearch,
  };
}
