import { ArrowSmRightIcon, PhotographIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";
import TextInputField from "../../components/Form/TextInputField";
import InboxActionButtonPreview from "./InboxActionButtonPreview";
import * as meta from "./InboxActionMeta";

interface InboxActionFormBaseFieldsProps {
  formik: FormikProps<any>;
  setCurrentStep: (s: string) => void;
}

export default function InboxActionFormBaseFields({
  formik,
  setCurrentStep,
}: InboxActionFormBaseFieldsProps) {
  return (
    <div>
      <div className="row mt-10 text-center">
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          Step 1 of 2
        </h6>
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          What will your button do?
        </h1>
        <p className="mb-5 text-sm text-gray-500">
          Create and personalize the button that will trigger your Inbox Action.
        </p>
      </div>

      <div className="mt-10 grid grid-cols-3 grid-rows-1 gap-6 sm:grid-cols-1">
        <div className="col-span-2">
          <h6 className="text-tiny uppercase tracking-wider text-gray-500">
            Button setup
          </h6>

          <div className="col-span-2 mt-5 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <TextInputField
                  placeholder={meta.InboxAction.FIELDS.name.placeholder}
                  label={meta.InboxAction.FIELDS.name.label}
                  name={meta.InboxAction.FIELDS.name.key}
                  help={meta.InboxAction.FIELDS.name.help}
                  optional={meta.InboxAction.FIELDS.name.optional}
                  type="text"
                />
                <TextInputField
                  placeholder={meta.InboxAction.FIELDS.button_label.placeholder}
                  label={meta.InboxAction.FIELDS.button_label.label}
                  name={meta.InboxAction.FIELDS.button_label.key}
                  help={meta.InboxAction.FIELDS.button_label.help}
                  optional={meta.InboxAction.FIELDS.button_label.optional}
                  type="text"
                />
                <TextInputField
                  placeholder={meta.InboxAction.FIELDS.button_icon.placeholder}
                  label={meta.InboxAction.FIELDS.button_icon.label}
                  name={meta.InboxAction.FIELDS.button_icon.key}
                  help={meta.InboxAction.FIELDS.button_icon.help}
                  optional={meta.InboxAction.FIELDS.button_icon.optional}
                  type="url"
                  icon={PhotographIcon}
                />
                <TextInputField
                  placeholder={
                    meta.InboxAction.FIELDS.button_classes.placeholder
                  }
                  label={meta.InboxAction.FIELDS.button_classes.label}
                  name={meta.InboxAction.FIELDS.button_classes.key}
                  help={meta.InboxAction.FIELDS.button_classes.help}
                  optional={meta.InboxAction.FIELDS.button_classes.optional}
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h6 className="text-tiny uppercase tracking-wider text-gray-500">
            Button preview
          </h6>
          <div className="mt-2 overflow-hidden">
            <InboxActionButtonPreview
              buttonClasses={
                formik.values[meta.InboxAction.FIELDS.button_classes.key]
              }
              buttonLabel={
                formik.values[meta.InboxAction.FIELDS.button_icon.key]
              }
              buttonIcon={
                formik.values[meta.InboxAction.FIELDS.button_label.key]
              }
            />
          </div>
        </div>
      </div>

      <div className="mx-auto my-14 max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <hr className="my-5" />
          <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
            <div className=""></div>
            <div className="col text-center">
              <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
                Step 1 of 2
              </h6>
            </div>
            <div className="justify-self-end">
              <button
                className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                type="button"
                onClick={(_) => setCurrentStep("http")}
              >
                Action Detail
                <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
