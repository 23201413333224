import { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { HTTPFormMeta } from "../../components/HTTPForm/Meta";
import APIErrors from "../../components/base/APIErrors";
import InboxActionFormBaseFields from "./InboxActionFormBaseFields";
import InboxActionFormHTTPFields from "./InboxActionFormHTTPFields";
import * as meta from "./InboxActionMeta";

export default function InboxActionForm({
  contact,
  formSubmit,
  success,
  submitted,
}) {
  const [currentStep, setCurrentStep] = useState("attrs");
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = (values, setSubmitting) => {
    const formData = { ...values };
    const metaAttributes = {};
    formData.meta.forEach((attr) => (metaAttributes[attr.key] = attr.value));
    formData.meta = metaAttributes;
    formData.source = "website";

    formSubmit(
      formData,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  const validationSchema = yup.object().shape({
    ...meta.InboxAction.schema,
    ...HTTPFormMeta.getSchema(),
  });

  const FIELDS = { ...meta.InboxAction.FIELDS, ...HTTPFormMeta.FIELDS };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
      initialValues={contact}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={FIELDS}
            />
          </div>

          {currentStep === "attrs" && (
            // name, lang and category
            <InboxActionFormBaseFields
              formik={formik}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === "http" && (
            // template content
            <InboxActionFormHTTPFields
              formik={formik}
              setCurrentStep={setCurrentStep}
              submitted={submitted}
              success={success}
            />
          )}
        </form>
      )}
    </Formik>
  );
}
