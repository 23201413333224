import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepMaxAttemptsDetailProps {
  step: FlowStepEdit;
  getStepById: (id: string) => FlowStepEdit;
}

export default function FlowStepMaxAttemptsDetail({
  step,
  getStepById,
}: FlowStepMaxAttemptsDetailProps) {
  const { t } = useTranslation();
  if (!step.has_max_answer_attempts) {
    return null;
  }

  return (
    <div>
      {step.answer_failed_next_step && (
        <p>
          {t(
            "flow-executions:steps-detail.max-attempts.if-reached",
            "If the user fails to answer"
          )}{" "}
          <span className="text-gray-900 font-medium">
            {step.max_answer_attempts} time(s)
          </span>
          , {t("go-to")}{" "}
          <span className="text-gray-900 font-medium">
            {getStepById(step.answer_failed_next_step).name}
          </span>
        </p>
      )}
    </div>
  );
}
