import { CodeIcon, PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import { FieldArray } from "formik";
import TextInputField from "../../components/Form/TextInputField";
import FieldAddVariableContainer from "../Form/FieldAddVariableContainer";
import InputActionButton from "../Form/InputActionButton";
import { HTTPFormProps } from "./HTTPForm";
import { HTTPFormMeta } from "./Meta";

export default function HTTPFormBodyStructured({
  formik,
  formPrefix,
  formValues,
  availableVariables,
}: HTTPFormProps) {
  return (
    <FieldArray
      name={`${formPrefix}.body_params`}
      render={(arrayHelpers) => (
        <>
          <ul className="mt-2">
            {formik.values.current_draft.steps &&
              formValues.body_params &&
              formValues.body_params.length > 0 &&
              formValues.body_params.map((item, cIdx) => (
                <div key={cIdx} className="my-2 flex items-start">
                  <div className="grid grow grid-cols-3 gap-2">
                    <div className="">
                      <TextInputField
                        name={`${formPrefix}.body.${cIdx}.${HTTPFormMeta.FIELDS.body_params.form_key.key}`}
                        placeholder={
                          HTTPFormMeta.FIELDS.body_params.form_key.placeholder
                        }
                        type="text"
                      />
                    </div>
                    <div className="col-span-2">
                      <FieldAddVariableContainer
                        formik={formik}
                        fieldName={`${formPrefix}.body_params.${cIdx}.${HTTPFormMeta.FIELDS.body_params.form_value.key}`}
                        currentValue={
                          formValues.params[cIdx][
                            HTTPFormMeta.FIELDS.body_params.form_value.key
                          ]
                        }
                        availableVariables={availableVariables}
                      >
                        {(setShowVariableSelect) => (
                          <TextInputField
                            name={`${formPrefix}.body_params.${cIdx}.${HTTPFormMeta.FIELDS.body_params.form_value.key}`}
                            placeholder={
                              HTTPFormMeta.FIELDS.body_params.form_value
                                .placeholder
                            }
                            type="text"
                            action={
                              <InputActionButton
                                onClick={() => setShowVariableSelect(true)}
                                type="button"
                              >
                                <CodeIcon className="h-5 w-5" />
                              </InputActionButton>
                            }
                          />
                        )}
                      </FieldAddVariableContainer>
                    </div>
                  </div>
                  <div className="ml-4 grow-0">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={(_) => arrayHelpers.remove(cIdx)}
                    >
                      <TrashIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              ))}
          </ul>

          <div className="mt-2">
            <button
              type="button"
              className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
              onClick={(_) => {
                arrayHelpers.push({ key: "", value: "" });
              }}
            >
              <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              Add Item
            </button>
          </div>
        </>
      )}
    ></FieldArray>
  );
}
