import { useCallback } from "react";
import { format } from "date-fns";
import useDateFnsCurrentLocale from "./useDateFnsCurrentLocale";

export default function useDateFnsFormatLocale() {
  const { currentLocale } = useDateFnsCurrentLocale();
  const formatWithLocale = useCallback(
    (
      date: Date | number,
      formatStr: string,
      options?: Record<string, unknown>
    ) => format(date, formatStr, { ...options, locale: currentLocale }),
    [currentLocale]
  );
  return { currentLocale, formatWithLocale };
}
