import * as yup from "yup";
import { CrawlerSettings } from "@hilos/types/private-schema";
import { getTranslationPayload as t } from "src/i18n";


export const getInitialValues = (): CrawlerSettings => {
  return {
    id: "",
    day_resync_period: 7,
    allowed_domains: [],
    start_urls: [],
    max_request_per_minute: 60,
    active: true,
    last_crawled: "",
    depth_limit: 0,
    page_count: 200,
  };
};

export const FIELDS = {
  day_resync_period: {
    key: "day_resync_period",
    label: t(
      "integrations:crawler.settings.day-resync-period",
      "Day Resync Period"
    ),
    optional: false,
  },
  allowed_domains: {
    key: "allowed_domains",
    label: t(
      "integrations:crawler.settings.allowed-domains",
      "Allowed Domains"
    ),
    add_button_label: t(
      "integrations:crawler.settings.add-domain",
      "Add domain"
    ),
    optional: false,
  },
  start_urls: {
    key: "start_urls",
    label: t("integrations:crawler.settings.start-urls", "Start URLs"),
    optional: false,
    add_button_label: t(
      "integrations:crawler.settings.add-start-url",
      "Add URL"
    ),
  },
  max_request_per_minute: {
    key: "max_request_per_minute",
    label: t(
      "integrations:crawler.settings.max-request-per-minute",
      "Max Requests per Minute"
    ),
    optional: false,
  },
  active: {
    key: "active",
    label: t("integrations:crawler.settings.active", "Active"),
    optional: false,
  },
  last_crawled: {
    key: "last_crawled",
    label: t("integrations:crawler.settings.last-crawled", "Last Crawled"),
  },
  depth_limit: {
    key: "depth_limit",
    label: t("integrations:crawler.settings.depth-limit", "Depth Limit"),
  },
  page_count: {
    key: "page_count",
    label: t("integrations:crawler.settings.page-count", "Page Count"),
  }
};

export const schema = yup.object().shape({
  id: yup.string(),
});