import { Dispatch, SetStateAction } from "react";
import { Trans } from "react-i18next";
import { HubspotApp } from "@hilos/types/private-schema";
import HubspotSyncComponent from "src/containers/integrations/Hubspot/HubspotSyncComponent";

interface HubspotSyncProps {
  connectedApp: HubspotApp;
  setLoading: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}

export default function HubspotSync({
  connectedApp,
  setLoading,
  disabled,
}: HubspotSyncProps) {
  return (
    <div
      className={`mt-10 sm:mt-4 ${disabled && "cursor-not-allowed opacity-50"}`}
    >
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <Trans i18nKey="integrations:hubspot.sync.description">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Initial Sync
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                You can start the sync process to import all your existing
                contacts to Hilos.
              </p>
            </Trans>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <HubspotSyncComponent
                    connectedApp={connectedApp}
                    setLoading={setLoading}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
