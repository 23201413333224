import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useField } from "formik";
import { API_ROUTES, buildAPIRoute } from "../../router/router";
import SelectorField from "./SelectorField";

interface BroadcastSearchFieldProps {
  name: string;
}

interface Option {
  label: string;
  value: string;
}

function BroadcastSearchField({ name }: BroadcastSearchFieldProps) {
  const [options, setOptions] = useState<Option[]>([]);
  const [field] = useField({ name });

  const handleSearchPhoneNumber = useCallback(async (search: string) => {
    try {
      const { data } = await axios.get(API_ROUTES.MESSAGE_BLAST_CREATE_LIST, {
        params: { search },
      });

      if (data && data.results) {
        return data.results.map((result) => ({
          value: result.id,
          label: result.name,
        }));
      }
    } catch {}
    return [];
  }, []);

  useEffect(() => {
    const loadSelectedOption = async () => {
      if (field.value) {
        const { data } = await axios.get(
          buildAPIRoute(API_ROUTES.MESSAGE_BLAST_DETAIL, {
            ":id": field.value,
          })
        );

        if (data && data.id) {
          setOptions([
            {
              value: data.id,
              label: data.name,
            },
          ]);
        }
      }
    };

    loadSelectedOption();
  }, [field.value]);

  const handleSelectOption = useCallback(
    (option: Option) => setOptions([option]),
    [setOptions]
  );

  return (
    <SelectorField
      name={name}
      isMulti={false}
      isCreatable={false}
      options={options}
      onSelect={handleSelectOption}
      loadOptions={handleSearchPhoneNumber}
      placeholder="Write to search..."
    />
  );
}

export default BroadcastSearchField;
