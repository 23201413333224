import { getPercentage } from "src/Helpers";
import Tooltip from "../Tooltip";

interface AnalyticsCardProps {
  label: string;
  tooltipText: string;
  value: number;
  total: number;
  showPercentage?: boolean;
}

export default function AnalyticsCard({
  label,
  tooltipText,
  value,
  total,
  showPercentage,
}: AnalyticsCardProps) {
  return (
    <div className="rounded-md border border-gray-200 bg-white py-4 px-2 text-center shadow-sm space-y-1">
      <div className="inline-flex items-center space-x-1">
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {label}
        </h3>
        {tooltipText && (
          <Tooltip
            content={
              <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3 ">
                {tooltipText}
              </div>
            }
          />
        )}
      </div>
      <p className="text-3xl font-medium text-gray-900">{value}</p>
      {showPercentage && (
        <p className="text-sm font-light text-gray-900">
          {getPercentage(value, total)}%
        </p>
      )}
    </div>
  );
}
