import useCleanTimeout from "src/hooks/useCleanTimeout";
import { ReactElement, useCallback, useState } from "react";
import StateButton from "src/components/StateButton";

interface StateControlButtonProps {
  onClick: () => void;
  btnClasses: string;
  successText: string;
  submittingText: string;
  initialText: ReactElement;
  disabled?: boolean;
  onSuccess: () => void;
}

export default function StateControlButton({
  onClick,
  btnClasses,
  successText,
  submittingText,
  initialText,
  onSuccess,
  disabled = false,
}: StateControlButtonProps) {
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const cleanTimeout = useCleanTimeout()

  const handleClick = useCallback(async () => {
    let error: unknown | null | undefined;

    setIsSubmitting(true);
    try {
      const response = await onClick();
      setSuccess(true);
      onSuccess();
      return response;
    } catch (err) {
      setSuccess(false);
      console.error(err);
      error = err;
    } finally {
      setIsSubmitting(false);
      setSubmitted(true);
      cleanTimeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
      if (error) {
        throw error;
      }
    }
  }, [cleanTimeout, onClick, onSuccess]);

  return (
    <StateButton
      btnClasses={`${btnClasses} ${disabled ? "disabled:opacity-50" : ""}`}
      btnClassesSuccess="focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-100 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
      btnClassesError="focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-50 px-3 py-2 text-sm font-medium leading-4 text-yellow-800 shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
      submittingText={submittingText}
      successText={successText}
      initialText={initialText}
      onClick={handleClick}
      isSubmitting={isSubmitting}
      submitted={submitted}
      success={success}
    />
  );
}
