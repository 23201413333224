import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import { ERROR_MESSAGES } from "src/constants/errors";
import { getDictFromKeyValueList } from "../../utils";
import { FlowStepBaseSchema } from "../flow";

export const FlowStepActionSchema = (t: TFunction) =>
  FlowStepBaseSchema(t).shape({
    action_request_method: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    action_request_url: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    action_request_body: yup.mixed().nullable(),
    action_responses: yup
      .array()
      .of(
        yup.object({
          id: yup.string().notRequired(),
          created_on: yup.date(),
          data: yup.mixed().required(t(ERROR_MESSAGES.REQUIRED)),
        })
      )
      .min(1, t(ERROR_MESSAGES.MIN_NUMBER)),
  });

export const FlowStepActionFormat = (step: FlowStepData) => {
  const nextStep = { ...step };

  if (nextStep.action_request_headers) {
    //@ts-ignore
    nextStep.action_request_headers = getDictFromKeyValueList(
      nextStep.action_request_headers
    );
  }

  if (nextStep.action_request_params) {
    //@ts-ignore
    nextStep.action_request_params = getDictFromKeyValueList(
      nextStep.action_request_params
    );
  }

  if (
    nextStep.action_request_body &&
    typeof nextStep.action_request_body === "object"
  ) {
    nextStep.action_request_body = JSON.stringify(nextStep.action_request_body);
  }

  return nextStep;
};
