import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import { HilosVariableData } from "@hilos/types/hilos";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepActionFormat, FlowStepActionSchema } from "./steps/action";
import { FlowStepConditionalSchema } from "./steps/conditional";
import { FlowStepConditionalMultipleSchema } from "./steps/conditional_multiple";
import { FlowStepConversationSchema } from "./steps/conversation";
import { FlowStepHubspotSchema } from "./steps/hubspot";
import { FlowStepMenuFormat, FlowStepMenuSchema } from "./steps/menu";
import { FlowStepMessageFormat, FlowStepMessageSchema } from "./steps/message";
import {
  FlowStepQuestionFormat,
  FlowStepQuestionSchema,
} from "./steps/question";
import { FlowStepTagSchema } from "./steps/tag";
import { FlowStepTeamSchema } from "./steps/team";
import {
  FlowStepTemplateFormat,
  FlowStepTemplateSchema,
} from "./steps/template";
import { FlowStepTemplateMenuSchema } from "./steps/template_menu";
import { FlowStepTriggerFlowSchema } from "./steps/trigger";
import {
  FlowStepWhatsAppFlowFormat,
  FlowStepWhatsAppFlowSchema,
} from "./steps/wa_flow";

export const GLOBAL_VARIABLES: HilosVariableData[] = [
  {
    id: "global.CONTACT_EXECUTION_ID",
    name: "global.Contact Execution Id",
    source: "global",
    data_type: "text",
    path: null,
    transform: null,
  },
  {
    id: "global.TIME",
    name: "global.Time",
    source: "global",
    data_type: "time",
    path: null,
    transform: null,
  },
  {
    id: "global.DATE",
    name: "global.Date",
    source: "global",
    data_type: "date",
    path: null,
    transform: null,
  },
  {
    id: "global.WEEKDAY",
    name: "global.Weekday",
    source: "global",
    data_type: "datetime",
    path: null,
    transform: null,
  },
  {
    id: "global.DAY",
    name: "global.Day",
    source: "global",
    data_type: "datetime",
    path: null,
    transform: null,
  },
  {
    id: "global.IS_IN_WORKING_HOURS",
    name: "global.Is in working hours?",
    source: "global",
    data_type: "bool",
    path: null,
    transform: null,
  },
];

export const INBOUND_TRIGGER_BASE_VARIABLES: {
  [key: string]: Partial<HilosVariableData> &
    Pick<HilosVariableData, "data_type">;
} = {
  "trigger.timestamp": {
    description:
      "The date when we received the message, in ISO format (e.g. 2024-01-01T09:44:12)",
    data_type: "datetime",
  },
  "trigger.status": {
    description:
      "The message status. For inbound messages it will always be 'received'.",
    data_type: "text",
  },
  "trigger.msg_type": {
    description:
      "The message type. Can be image, text, audio, video, voice, sticker, etc.",
    data_type: "text",
  },
  "trigger.body": {
    description:
      "The message content, if it was a text message, empty otherwise.",
    data_type: "text",
  },
  "trigger.content_type": {
    description:
      "The message content type, if it was a media message (e.g. image/png).",
    data_type: "text",
  },
  "trigger.content_url": {
    description: "The message media url. Use this to show or download it",
    data_type: "text",
  },
};

export const FLOW_STEP_SCHEMA = {
  TEMPLATE: FlowStepTemplateSchema,
  TEMPLATE_MENU: FlowStepTemplateMenuSchema,
  MESSAGE: FlowStepMessageSchema,
  QUESTION: FlowStepQuestionSchema,
  WA_FLOW: FlowStepWhatsAppFlowSchema,
  MENU: FlowStepMenuSchema,
  CONDITIONAL: FlowStepConditionalSchema,
  CONDITIONAL_MULTIPLE: FlowStepConditionalMultipleSchema,
  ACTION: FlowStepActionSchema,
  CONVERSATION: FlowStepConversationSchema,
  TAG: FlowStepTagSchema,
  TEAM: FlowStepTeamSchema,
  TRIGGER_FLOW: FlowStepTriggerFlowSchema,
  HUBSPOT_CONTACT_GET: FlowStepHubspotSchema,
};

export const FLOW_STEP_FORMAT = {
  MESSAGE: FlowStepMessageFormat,
  TEMPLATE: FlowStepTemplateFormat,
  TEMPLATE_MENU: FlowStepTemplateFormat,
  ACTION: FlowStepActionFormat,
  QUESTION: FlowStepQuestionFormat,
  WA_FLOW: FlowStepWhatsAppFlowFormat,
  MENU: FlowStepMenuFormat,
};

export const getFlowSchema = (t: TFunction) => {
  return yup.object().shape({
    name: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    contact_can_run_multiple_times: yup.boolean(),
    contact_execution_multiple_type: yup
      .mixed()
      .when("contact_can_run_multiple_times", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    contact_execution_max_frequency_quantity: yup
      .string()
      .nullable()
      .when(
        ["contact_can_run_multiple_times", "contact_execution_multiple_type"],
        {
          is: (canRun, type) => canRun && type === "CUSTOM",
          then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
        }
      ),
    contact_execution_max_frequency_unit: yup
      .string()
      .nullable()
      .when(
        ["contact_can_run_multiple_times", "contact_execution_multiple_type"],
        {
          is: (canRun, type) => canRun && type === "CUSTOM",
          then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
        }
      ),
    trigger_type: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    trigger_config: yup.object().shape({
      message_content_to_match: yup.string().when("trigger_type", {
        is: "INBOUND_SPECIFIC_MESSAGE",
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    }),
    steps: yup.array().of(
      yup.lazy((value) => {
        if (value.step_type && FLOW_STEP_SCHEMA[value.step_type]) {
          return FLOW_STEP_SCHEMA[value.step_type](t);
        }
        return yup.mixed();
      })
    ),
    should_trigger_webhook: yup.boolean(),
  });
};

export const getStepFormat = (step: FlowStepData) => {
  if (!step || !step.step_type) {
    return null;
  }
  if (FLOW_STEP_FORMAT[step.step_type]) {
    return FLOW_STEP_FORMAT[step.step_type](step);
  }

  return step;
};
