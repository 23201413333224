import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { UsageDetailData, UsageSummaryData } from "@hilos/types/analytics";
import { API_ROUTES } from "../router/router";

export function useUsageSummaryAnalytics() {
  const fetchUsageSummary = useCallback(async () => {
    const url =
      API_ROUTES.ANALYTICS_USAGE_SUMMARY +
      "?period_type=RELATIVE_DATES&period_type_type=MONTH";
    const { data } = await axios.get<UsageSummaryData>(url);
    return data;
  }, []);

  const { data } = useQuery("usage_summary", fetchUsageSummary);

  return {
    usageSummary: data || {
      current: {
        inbox_users_in_period: 0,
        macs_in_period: 0,
        start_on: "",
        end_on: "",
      },
      previous: {
        inbox_users_in_period: 0,
        macs_in_period: 0,
        start_on: "",
        end_on: "",
      },
    },
  };
}

export function useUsageDetailAnalytics() {
  const fetchUsageDetail = useCallback(async () => {
    const url =
      API_ROUTES.ANALYTICS_USAGE_DETAIL +
      "?period_type=RELATIVE_DATES&period_type_type=MONTH";
    const { data } = await axios.get<UsageDetailData>(url);
    return data;
  }, []);

  const { data } = useQuery("usage_detail", fetchUsageDetail);

  return {
    usageDetail: data || {
      current: {
        inbox_users_in_period: [],
        macs_in_period_per_day: [],
        start_on: "",
        end_on: "",
      },
    },
  };
}
