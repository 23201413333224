import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import MappedValuesField from "src/containers/flow/builder/components/MappedValuesField";
import TextInputFieldWithVariables from "src/containers/flow/builder/components/TextInputFieldWithVariables";
import SelectorField from "src/components/Form/SelectorField";
import LineTabs from "src/components/LineTabs";
import useHubspotApp from "src/hooks/useHubSpot";
import { useHubspotContactProperties } from "src/hooks/useHubspotContactProperties";
import { classNames } from "src/Helpers";
import HubspotCtaInstallApp from "./HubspotCtaInstallApp";

interface FlowBuilderStepHubspotContactUpsertProps {
  index: number;
}

function FlowBuilderStepHubspotContactUpsert({
  index,
}: FlowBuilderStepHubspotContactUpsertProps) {
  const { t } = useTranslation();
  const { data: hubspotApp } = useHubspotApp();
  const { data, fieldsOptions } = useHubspotContactProperties();

  const isInstalled = useMemo(() => !!hubspotApp?.is_connected, [hubspotApp]);
  const hubspotContactPropertiesOptions = useMemo(
    () =>
      data
        .map((property) => ({
          label: property.label,
          value: property.name,
        }))
        .sort((a, b) => a.label.length - b.label.length),
    [data]
  );

  return (
    <div className="mt-2">
      {!isInstalled && (
        <div className="my-4">
          <HubspotCtaInstallApp />
        </div>
      )}
      <LineTabs
        labels={[
          t("flows:steps.hubspot-contact-upsert.tabs.default", "Default"),
          t("flows:steps.hubspot-contact-upsert.tabs.custom", "Custom"),
        ]}
        disabled={!isInstalled}
      >
        <div
          className={classNames("space-y-4 mt-2", !isInstalled && "opacity-50")}
        >
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name={"firstname"}
            currentStepIndex={index}
            label={t(
              "flows:steps.hubspot-contact-upsert.contact-first-name.label",
              "First name"
            )}
            disabled={!isInstalled}
          />
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name="lastname"
            currentStepIndex={index}
            label={t(
              "flows:steps.hubspot-contact-upsert.contact-last-name.label",
              "Last name"
            )}
            disabled={!isInstalled}
          />
          <TextInputFieldWithVariables
            type="text"
            path={`steps.${index}`}
            name="email"
            currentStepIndex={index}
            label={t(
              "flows:steps.hubspot-contact-upsert.contact-email.label",
              "Email"
            )}
            disabled={!isInstalled}
          />
        </div>
        <MappedValuesField
          path={`steps.${index}`}
          objectName={t(
            "flows:steps.hubspot-contact-upsert.add-custom-property",
            "HubSpot custom property"
          )}
          name={"contact_custom_properties"}
          currentStepIndex={index}
          addButtonLabel={t(
            "flows:steps.hubspot-contact-upsert.add-custom-property",
            "Add property"
          )}
          fieldsOptions={fieldsOptions}
          customOptionsNoticeMessage={t(
            "flows:steps.hubspot-contact-upsert.custom-properties-notice",
            "Make sure that the values you send in the variable are one of the following. Without an exact match, Hubspot will not be able to update the contact."
          )}
          KeyComponent={(indexKey) => (
            <SelectorField
              isCreatable={false}
              name={`steps.${index}.contact_custom_properties.${indexKey}.key`}
              options={hubspotContactPropertiesOptions}
            />
          )}
        />
      </LineTabs>
    </div>
  );
}

export default FlowBuilderStepHubspotContactUpsert;
