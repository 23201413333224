import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import {
  ConversationsSummaryByAgentInPeriodData,
  InboxUsageSummaryByAgentData,
} from "@hilos/types/analytics";
import {
  LocalTableCellFormat,
  LocalTableHeaderFormat,
} from "src/components/LocalTable";
import { API_ROUTES } from "../router/router";

export function useInboxUsageSummaryMessages(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url =
      API_ROUTES.ANALYTICS_INBOX_USAGE_AGENT_SUMMARY_MESSAGES_PER_AGENT +
      "?" +
      params;
    const { data } = await axios.get(url);
    return data;
  }, [params]);

  const { data } = useQuery(
    "inbox_usage_summary_by_agent_messages" + params,
    fetchUsageSummary,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    inboxUsageSummaryMessages: data || undefined,
  };
}

export function useInboxUsageSummaryConversations(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url =
      API_ROUTES.ANALYTICS_INBOX_USAGE_AGENT_SUMMARY_CONVOS_PER_AGENT +
      "?" +
      params;
    const { data } = await axios.get(url);
    return data;
  }, [params]);

  const { data } = useQuery(
    "inbox_usage_summary_by_agent_conversations" + params,
    fetchUsageSummary,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    inboxUsageSummaryConversations: data || undefined,
  };
}

export function useInboxUsageSummaryAnalytics(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url = API_ROUTES.ANALYTICS_INBOX_USAGE_AGENT_SUMMARY + "?" + params;
    const { data } = await axios.get<InboxUsageSummaryByAgentData>(url);
    return data;
  }, [params]);

  const { data } = useQuery(
    "inbox_usage_summary_by_agent" + params,
    fetchUsageSummary,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { t } = useTranslation();

  const columnsTable = useMemo(() => {
    const columnHelper =
      createColumnHelper<ConversationsSummaryByAgentInPeriodData>();
    const defaultColumns = [
      columnHelper.accessor((row) => row?.user, {
        id: "user",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:agent", "Agent")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.received_chats, {
        id: "received_chats",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:usage-inbox.table.received-chats", "Received chats")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.distinct_contacts, {
        id: "distinct_contacts",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:usage-inbox.table.distinct-contacts",
                "Distinct contacts"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_duration, {
        id: "average_duration",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:usage-inbox.table.average-duration",
                "Average duration (HH:MM:SS)"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_first_response, {
        id: "average_first_response",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:usage-inbox.table.average-first-response",
                "Average first response (HH:MM:SS)"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_satisfaction_rating, {
        id: "average_satisfaction_rating",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:usage-inbox.table.average-satisfaction",
                "Average rating"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
    ];
    return defaultColumns;
  }, [t]);

  return {
    columnsTable,
    inboxUsageSummary: data || ({} as InboxUsageSummaryByAgentData),
  };
}
