import { DateTime } from "luxon";

export const getConnectionColor = (lastMessageTimestamp) => {
  if (
    !lastMessageTimestamp ||
    DateTime.now()
      .diff(DateTime.fromISO(lastMessageTimestamp), "hour")
      .toObject().hours >= 24
  ) {
    return "red";
  } else if (
    DateTime.now()
      .diff(DateTime.fromISO(lastMessageTimestamp), "hour")
      .toObject().hours > 20
  ) {
    return "yellow";
  } else {
    return "green";
  }
};
