import { useState } from "react";
import { motion } from "framer-motion";
import { Separator } from "src/components/ui/separator";
import WorkflowBoardTaskConversation from "./WorkflowBoardTaskConversation";
import WorkflowBoardTaskHeader from "./WorkflowBoardTaskHeader";
import WorkflowBoardTaskDetails from "./details/WorkflowBoardTaskDetails";

interface WorkflowBoardTaskProps {
  taskId: string;
  workflowId: string;
  onClose: () => void;
}

export default function WorkflowBoardTask({
  taskId,
  workflowId,
  onClose,
}: WorkflowBoardTaskProps) {
  const [showDetails, setShowDetails] = useState(true);

  return (
    <div className="sticky w-full h-full">
      <div className="flex h-full flex-col overflow-hidden">
        <WorkflowBoardTaskHeader
          taskId={taskId}
          workflowId={workflowId}
          onClose={onClose}
          onSwitchShowDetails={() =>
            setShowDetails((prevShowDetails) => !prevShowDetails)
          }
        />
        <Separator />
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: "auto" }}
          exit={{
            opacity: 0,
            width: 0,
          }}
          transition={{
            opacity: { duration: 0.2 },
            width: { duration: 0.4 },
            layout: { duration: 0.3 },
          }}
          layout
          className="flex flex-1 overflow-hidden"
        >
          <div className="flex flex-1 bg-gray-100 overflow-hidden">
            <WorkflowBoardTaskConversation
              taskId={taskId}
              workflowId={workflowId}
            />
            {showDetails && (
              <WorkflowBoardTaskDetails
                taskId={taskId}
                workflowId={workflowId}
              />
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}
