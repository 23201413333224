import { useCallback, useState } from "react";
import {
  ClipboardCheckIcon,
  ClipboardCopyIcon,
  XIcon,
} from "@heroicons/react/outline";

interface CopyToClipboardIconProps {
  value: string;
  text?: string;
}

export default function CopyPhoneToClipboardButton({
  value,
  text,
}: CopyToClipboardIconProps) {
  const [copied, setCopied] = useState(undefined);
  const [copyError, setCopyError] = useState(false);
  const handleCopyValue = useCallback(async (value) => {
    try {
      await navigator.clipboard.writeText(value);
    } catch (err) {
      console.log(err);
      setCopyError(true);
    } finally {
      setCopied(value);
      setTimeout(() => {
        setCopied(undefined);
      }, 1000 * 2);
    }
  }, []);

  return (
    <>
      <div className="flex flex-inline group justify-center">
        <span className="mr-1">{text}</span>
        <span className="hidden group-hover:block">
          {copied === value ? (
            <>
              {copyError ? (
                <button
                  className="rounded-md border bg-yellow-100 px-1 py-0.5 text-yellow-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                  type="button"
                  disabled={true}
                >
                  <XIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              ) : (
                <button
                  className="rounded-md border bg-green-100 px-1 py-0.5 text-green-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2"
                  type="button"
                  disabled={true}
                >
                  <ClipboardCheckIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              )}
            </>
          ) : (
            <button
              className="rounded-md border bg-gray-100 px-1 py-0.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
              type="button"
              onClick={(_) => handleCopyValue(value)}
            >
              <ClipboardCopyIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          )}
        </span>
      </div>
    </>
  );
}
