import { useTranslation } from "react-i18next";
import { Disclosure, Transition } from "@headlessui/react";
import {
  ChevronRightIcon,
  TagIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { InboxContactRead } from "@hilos/types/private-schema";
import { getDateFromTimestamp } from "src/helpers/date";
import { hasItems } from "src/helpers/utils";
import { useConversationList } from "src/hooks/useConversationList";
import { userToString } from "src/Helpers";
import WhatsAppMessageOneLinePreview from "../WhatsAppMessageOneLinePreview";

interface ConversationListProps {
  inboxContact: InboxContactRead;
}

function ConversationList({ inboxContact }: ConversationListProps) {
  const { t } = useTranslation();
  const { conversations } = useConversationList(
    inboxContact.contact.id as string
  );

  return (
    <div className="px-3">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex min-w-full flex-1 grow items-center justify-between text-xs font-medium uppercase tracking-wide text-gray-500">
              {t("inbox:previous-conversations", "Previous Conversations")}
              <ChevronRightIcon
                className={`mr-1.5 h-4 w-4 text-gray-500 ${
                  open
                    ? "rotate-90 transform transition-transform duration-100 ease-in-out"
                    : ""
                }`}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-in"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="space-y-2 border-b border-gray-200 text-sm text-gray-500">
                <div>
                  <ul className="divide-y divide-gray-200">
                    {conversations.results.map((conversation) => (
                      <li key={conversation.id} id={conversation.id}>
                        <div className="relative space-x-2">
                          <div className="my-2 flex justify-between">
                            <div>
                              <p className="text-xs font-medium text-gray-800">
                                {conversation.first_message ? (
                                  <WhatsAppMessageOneLinePreview
                                    body={conversation.first_message.body}
                                    type={conversation.first_message.msg_type}
                                    deleted={
                                      conversation.first_message.is_deleted
                                    }
                                  />
                                ) : (
                                  t("inbox:no-messages", "No messages yet...")
                                )}
                              </p>
                              {hasItems(conversation.tags) && (
                                <p className="mt-1 flex items-center text-gray-500">
                                  <TagIcon className="mr-1 h-4 w-4" />
                                  <div className="flex flex-wrap items-center">
                                    {conversation.tags?.map((tag) => (
                                      <span
                                        key={tag.name}
                                        className="m-0.5 flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800"
                                      >
                                        {t(tag.name)}
                                      </span>
                                    ))}
                                  </div>
                                </p>
                              )}
                              {conversation.assigned.length > 0 && (
                                <p className="mt-0.5 flex items-center text-xs text-gray-500">
                                  <UserGroupIcon className="mr-1 h-4 w-4" />
                                  {conversation.assigned.map((user) => (
                                    <span className="mr-1" key={user.id}>
                                      @{userToString(user)}
                                    </span>
                                  ))}
                                </p>
                              )}
                            </div>
                            <div className="whitespace-nowrap text-right text-xs font-medium text-gray-500">
                              <time>
                                {getDateFromTimestamp(conversation.created_on)}
                              </time>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
export default ConversationList;
