import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepHubspotContactGetDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepHubspotContactGetDetail({
  step,
}: FlowStepHubspotContactGetDetailProps) {
  const { t } = useTranslation();
  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:steps-detail.hubspot-contact-get.custom-properties",
            "Properties to retreive"
          )}
        </h3>

        {step.custom_properties?.join(", ")}
      </div>
    </div>
  );
}
