import { PropsWithChildren, useEffect, useRef } from "react";
import {
  autoScrollForElements,
  autoScrollWindowForElements,
} from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import type { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/types";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { bind } from "bind-event-listener";
import invariant from "tiny-invariant";
import { indexOf } from "src/helpers/utils";
import { classNames } from "src/Helpers";

export type MoveCardParams = {
  id: string;
  startColumnId: string;
  finishColumnId: string;
  itemIndexInFinishColumn?: number;
};

interface BoardProps<T extends { id: string }> extends PropsWithChildren {
  instanceId: symbol;
  tasksByState: { [key: string]: Map<string, T> };
  selectedTaskId: string | null;
  onMoveCard: (params: MoveCardParams) => void;
}

export default function Board<T extends { id: string }>({
  instanceId,
  tasksByState,
  selectedTaskId,
  children,
  onMoveCard,
}: BoardProps<T>) {
  const boardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return combine(
      monitorForElements({
        canMonitor({ source }) {
          return source.data.instanceId === instanceId;
        },
        onDrop(args) {
          const { location, source } = args;
          // didn't drop on anything
          if (!location.current.dropTargets.length) {
            return;
          }
          // need to handle drop

          // 1. remove element from original position
          // 2. move to new position

          // Dragging a card
          if (source.data.type === "card") {
            const itemId = source.data.itemId;
            invariant(typeof itemId === "string");
            const sourceId = source.data.state;

            invariant(typeof sourceId === "string");
            const sourceColumn = tasksByState[sourceId];

            if (location.current.dropTargets.length === 1) {
              const [destinationColumnRecord] = location.current.dropTargets;
              const destinationId = destinationColumnRecord.data.columnId;
              invariant(typeof destinationId === "string");
              const destinationColumn = tasksByState[destinationId];
              invariant(destinationColumn);

              // reordering in same column
              if (sourceColumn === destinationColumn) {
                return;
              }

              // moving to a new column
              onMoveCard({
                id: itemId,
                startColumnId: sourceId,
                finishColumnId: destinationId,
              });
              return;
            }

            // dropping in a column (relative to a card)
            if (location.current.dropTargets.length === 2) {
              const [destinationCardRecord, destinationColumnRecord] =
                location.current.dropTargets;
              const destinationColumnId = destinationColumnRecord.data.columnId;
              invariant(typeof destinationColumnId === "string");
              const destinationColumn = tasksByState[destinationColumnId];

              const indexOfTarget = indexOf(
                destinationColumn,
                (item) => item.id === destinationCardRecord.data.itemId
              );

              const closestEdgeOfTarget: Edge | null = extractClosestEdge(
                destinationCardRecord.data
              );

              if (sourceColumn === destinationColumn) {
                return;
              }

              // case 2: moving into a new column relative to a card
              const destinationIndex =
                closestEdgeOfTarget === "bottom"
                  ? indexOfTarget + 1
                  : indexOfTarget;

              onMoveCard({
                id: itemId,
                startColumnId: sourceId,
                finishColumnId: destinationColumnId,
                itemIndexInFinishColumn: destinationIndex,
              });
            }
          }
        },
      })
    );
  }, [instanceId, tasksByState, onMoveCard]);

  useEffect(() => {
    const element = boardRef.current;
    invariant(element);
    let operation: AbortController | null = null;

    return combine(
      monitorForElements({
        onDragStart: async () => {
          operation = new AbortController();

          if (operation.signal.aborted) {
            return;
          }

          const cleanup = combine(
            autoScrollForElements({
              element,
            }),
            autoScrollWindowForElements()
          );

          bind(operation.signal, {
            type: "abort",
            listener: cleanup,
            options: { once: true },
          });
        },
        onDrop() {
          operation?.abort();
        },
      })
    );
  }, []);

  return (
    <div
      ref={boardRef}
      className={classNames(
        "flex flex-1 flex-col overflow-hidden",
        selectedTaskId ? "bg-gray-50" : "bg-white py-4"
      )}
    >
      {children}
    </div>
  );
}
