import * as comparisonMeta from "../ComparisonMeta";

import { API_ROUTES, buildAPIRoute } from "../../../router/router";
import {
  CheckIcon,
  PlusSmIcon,
  SearchIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";
import { formatPhone, userToString } from "../../../Helpers";
import { useMemo, useState } from "react";

import { FieldArray } from "formik";
import RadioButtonsField from "../../../components/Form/RadioButtonsField";
import SelectorField from "../../../components/Form/SelectorField";
import Table from "src/components/Table/Table";
import TextInputField from "../../../components/Form/TextInputField";
import { getContactVariables } from "../../../helpers/utils";
import { useCustomFields } from "src/hooks/useContactCustomField";

export default function FlowExecutionFormContactFilters({ formik }) {
  const { t } = useTranslation();
  const [triggerReload, setTriggerReload] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const { contactCustomFields } = useCustomFields();

  const formattedContactVars = useMemo(() => {
    const contactVars = getContactVariables(contactCustomFields);
    const nextFormattedContactVars = contactVars.map((contactVar) => ({
      label: `contact.${contactVar}`,
      value: contactVar,
    }));
    return nextFormattedContactVars;
  }, [contactCustomFields]);

  const searchForContacts = () => {
    if (!formik?.errors?.contact_filters) {
      setShowContacts(true);
      setTriggerReload(!triggerReload);
    }
  };

  const translatedOptions = comparisonMeta.COMPARISON_OPTIONS.slice(0, 4).map(
    (option) => ({ ...option, label: t(option.label, option.label) })
  );

  return (
    <>
      <div>
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          {t("flow-executions:filters.label", "Filters")}
        </h6>
        <p className="text-sm text-gray-500">
          {t(
            "flow-executions:filters.description",
            "Segment your existing contacts using these filters, which will be AND'ed together."
          )}
        </p>
      </div>

      <FieldArray
        name="contact_filters"
        render={(arrayHelpers) => (
          <div className="space-y-4">
            <ul>
              {formik.values.contact_filters &&
                formik.values.contact_filters.map((filter, cIdx) => (
                  <div key={cIdx} className="flex items-start">
                    <div className="mb-2 grid grow grid-cols-1 grid-rows-3 gap-4 md:grid-cols-3 md:grid-rows-1">
                      <SelectorField
                        name={`contact_filters.${cIdx}.${comparisonMeta.FIELDS.field.key}`}
                        help={comparisonMeta.FIELDS.field.help}
                        options={formattedContactVars}
                      />
                      <SelectorField
                        name={`contact_filters.${cIdx}.${comparisonMeta.FIELDS.comparison.key}`}
                        options={translatedOptions}
                        onSelect={() => {
                          formik.setFieldValue(
                            `contact_filters.${cIdx}.${comparisonMeta.FIELDS.value.key}`,
                            ""
                          );
                        }}
                      />

                      {filter.comparison === "isnull" ? (
                        <RadioButtonsField
                          options={[
                            {
                              value: "true",
                              label: t("yes", "Yes"),
                              disabled: false,
                              icon: CheckIcon,
                            },
                            {
                              value: "false",
                              label: t("no", "No"),
                              disabled: false,
                              icon: XIcon,
                            },
                          ]}
                          name={`contact_filters.${cIdx}.${comparisonMeta.FIELDS.value.key}`}
                        />
                      ) : (
                        <TextInputField
                          name={`contact_filters.${cIdx}.${comparisonMeta.FIELDS.value.key}`}
                          placeholder={t(
                            comparisonMeta.FIELDS.value.placeholder
                          )}
                          type="text"
                          containerClassName="relative rounded-md shadow-sm mb-2"
                        />
                      )}
                    </div>
                    <div className="ml-4 mt-0.5 grow-0">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={(_) => arrayHelpers.remove(cIdx)}
                      >
                        <TrashIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                ))}
            </ul>

            {formik?.errors?.contact_filters &&
              typeof formik.errors.contact_filters === "string" && (
                <div className="mt-1.5 text-xs text-red-500">
                  {formik.errors.contact_filters}
                </div>
              )}

            <div className="flex items-center">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                onClick={(_) =>
                  arrayHelpers.push(comparisonMeta.getInitialValues())
                }
              >
                <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {t("flow-executions:filters.add-filter-button", "Add filter")}
              </button>
              <button
                type="button"
                className="ml-4 inline-flex items-center justify-center rounded-md border border-indigo-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-indigo-500 shadow-sm hover:bg-indigo-500 hover:text-white focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-indigo-500"
                onClick={(_) => searchForContacts()}
              >
                <SearchIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {formik?.errors?.contact_filters
                  ? t(
                      "flow-executions:filters.set-filters",
                      "Set up filters to see filtered contacts"
                    )
                  : t("flow-executions:filters.show-contacts", "Show contacts")}
              </button>
            </div>

            <div>
              {formik.values.contact_filters.length > 0 && showContacts && (
                <Table
                  baseURL={buildAPIRoute(API_ROUTES.CONTACT_FILTERS)}
                  initialSearchParams={formik.values.contact_filters}
                  paginated={true}
                  tableHead={
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                        >
                          {t("phone", "Phone")}
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                        >
                          {t("name", "Name")}
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                        >
                          {t("source", "Source")}
                        </th>
                      </tr>
                    </thead>
                  }
                  tableRow={(contact) => (
                    <>
                      <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                        {formatPhone(contact.phone, true, true)}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                        {userToString(contact)}
                      </td>
                      <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                        {contact.source}
                      </td>
                    </>
                  )}
                  triggerReload={triggerReload}
                  dataName={t("contacts", "Contacts")}
                  createObject={
                    <>
                      <Trans i18nKey="contacts:no-contacts-with-filters">
                        <h4>Couldn't find any contacts with these filters.</h4>
                        <p className="text-muted">
                          Remove filters to try again.
                        </p>
                      </Trans>
                    </>
                  }
                ></Table>
              )}
            </div>
          </div>
        )}
      ></FieldArray>
    </>
  );
}
