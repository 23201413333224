import { useTranslation } from "react-i18next";
import { CashIcon } from "@heroicons/react/outline";
import { addDays, format } from "date-fns";
import LoaderSpinner from "src/components/LoaderSpinner";
import { useStripeUpcomingInvoice } from "src/hooks/useStripe";
import { formatPrice } from "src/Helpers";

function SubscriptionNextPeriodDetail() {
  const { t } = useTranslation();
  const { invoice } = useStripeUpcomingInvoice();

  return (
    <div className="space-y-3">
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("settings:billing.next-period-title", "Next billing period")}
      </h3>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="bg-white py-6 px-4 sm:p-6">
          {invoice ? (
            <div className="space-y-4">
              <h2 id="plan-heading" className="flex items-center text-gray-900">
                <CashIcon className="mr-2 h-5 w-5 text-hilos" />
                <div className="text-sm">
                  {t(
                    "settings:billing.next-period.next-invoice-on",
                    "Your next invoice on"
                  )}{" "}
                  <span className="text-base font-medium">
                    {format(
                      addDays(new Date(invoice.start_on as string), 1),
                      "d/MMM"
                    )}
                  </span>{" "}
                  {t("settings:billing.next-period.will-be-for", "will be for")}{" "}
                  <span className="text-base font-medium text-hilos">
                    {formatPrice(invoice.total / 100)}{" "}
                    {invoice.currency.toLocaleUpperCase()}
                  </span>
                  ,{" "}
                  {t(
                    "settings:billing.next-period.based-on-usage",
                    "based on current period usage."
                  )}
                </div>
              </h2>
              <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                      >
                        {t("description", "Description")}
                      </th>
                      <th
                        scope="col"
                        className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        {t("quantity", "Quantity")}
                      </th>
                      <th
                        scope="col"
                        className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        {t("unit-price", "Unit price")}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                      >
                        {t("amount", "Amount")}{" "}
                        <span className="text-xs text-gray-500">
                          ({invoice.currency.toLocaleUpperCase()})
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoice.line_items.map((item) => (
                      <tr key={item.id} className="border-b border-gray-200">
                        <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                          <div className="text-gray-900">
                            {item.description
                              ? item.description
                              : item.price.name}
                          </div>
                          <div className="mt-0.5 text-gray-500 sm:hidden">
                            {item.description} hours at{" "}
                            {formatPrice((item.unit_amount || 0) / 100)}
                          </div>
                        </td>
                        <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                          {item.quantity}
                        </td>
                        <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                          {formatPrice((item.unit_amount || 0) / 100)}
                        </td>
                        <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                          {formatPrice(item.amount / 100)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        scope="row"
                        colSpan={3}
                        className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                      >
                        {t("total", "Total")}
                      </th>
                      <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                        {formatPrice(invoice.total / 100)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          ) : (
            <LoaderSpinner />
          )}
        </div>
      </div>
    </div>
  );
}

export default SubscriptionNextPeriodDetail;
