import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlusSmIcon } from "@heroicons/react/outline";
import { FieldArray, useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import useVariables from "../../../hooks/useVariables";
import FlowBuilderStepConditionalCondition, {
  SelectVariableParams,
} from "./FlowBuilderStepConditionalCondition";

interface FlowBuilderStepConditionalProps {
  index: number;
}

function FlowBuilderStepConditional({
  index,
}: FlowBuilderStepConditionalProps) {
  const { t } = useTranslation();
  const { options, handleGetVariable } = useVariables({
    currentStepIndex: index,
  });
  const { values, setFieldValue } = useFormikContext<FlowData>();

  const conditions = useMemo(
    () => values.steps?.[index]?.conditions || [],
    [values, index]
  );

  const handleSelectVariable = useCallback(
    ({ name, variable }: SelectVariableParams) => {
      if (variable) {
        setFieldValue(`${name}.field`, variable.id);
      } else {
        setFieldValue(`${name}.field`, "");
      }

      setFieldValue(`${name}.value`, "");
      setFieldValue(`${name}.comparison`, "");
    },
    [setFieldValue]
  );

  return (
    <div className="space-y-2">
      <div>
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          {t("flows:steps.conditional.title", "Conditions")}
        </h6>
        <p className="text-sm text-gray-500">
          {t(
            "flows:steps.conditional.instruction",
            "These conditions will be AND'ed together when evaluating."
          )}
        </p>
      </div>
      <FieldArray
        name={`steps.${index}.conditions`}
        render={(arrayHelpers) => (
          <>
            <ul className="divide-y divide-gray-200">
              {conditions.map((condition, currentConditionIndex) => {
                const id = `steps.${index}.conditions.${currentConditionIndex}`;

                return (
                  <FlowBuilderStepConditionalCondition
                    key={id}
                    name={id}
                    options={options}
                    condition={condition}
                    onGetVariable={handleGetVariable}
                    onSelectVariable={handleSelectVariable}
                    onRemoveCondition={arrayHelpers.handleRemove(
                      currentConditionIndex
                    )}
                    conditionIndex={currentConditionIndex}
                  />
                );
              })}
            </ul>
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                onClick={arrayHelpers.handlePush({
                  field: "",
                  comparison: "",
                  value: "",
                })}
              >
                <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {t("flows:steps.conditional.add-condition", "Add condition")}
              </button>
            </div>
          </>
        )}
      />
    </div>
  );
}

export default FlowBuilderStepConditional;
