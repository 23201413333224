import { useTranslation } from "react-i18next";
import {
  ExclamationCircleIcon,
  RefreshIcon,
  SupportIcon,
} from "@heroicons/react/outline";
import * as Sentry from "@sentry/react";
import useHilosStore from "src/hooks/useHilosStore";
import usePostHogHelpers from "src/hooks/usePostHogHelpers";
import i18n from "src/i18n";
import Button from "../Button";

const ErrorComponent = ({ error, type }) => {
  const { language } = i18n;
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const { posthog, maybeStartRecording } = usePostHogHelpers();
  let userErrorId: string | undefined;

  if (!error) {
    return null;
  }

  const handleRefresh = () => {
    window.location.reload();
    try {
      maybeStartRecording();
    } catch (error) {
      console.log(error);
    }
  };

  try {
    userErrorId = `${posthog?.get_distinct_id()}-${session?.id}`;
    Sentry.getCurrentHub()?.setTag("posthogId", userErrorId);
    Sentry.captureException(error);
    posthog?.capture("$exception", { error: error });
  } catch (error) {
    console.error(error);
  }

  switch (type) {
    case "page": {
      return (
        <div className="h-full w-full flex justify-center">
          <div className="flex md:w-2/4 flex-col space-y-3 self-center text-center">
            <ExclamationCircleIcon className="h-10 w-10 mx-auto" />
            <h1 className="text-gray-800">
              {t("errors:error-title", "Something went wrong")}
            </h1>
            <p className="text-gray-500">
              {t(
                "errors:error-subtitle",
                "An error has occurred while trying to load this page, our team has been notified about this."
              )}
            </p>
            <div className="grid grid-cols-2 gap-x-3">
              <Button className="my-3" onClick={handleRefresh}>
                <RefreshIcon className="h-5 w-5 mr-2" />
                {t("errors:try-again", "Try again")}
              </Button>
              <a
                href={
                  language && language.toLowerCase().indexOf("es") === 0
                    ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
                    : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos."
                }
              >
                <Button className="my-3 w-full" onClick={handleRefresh}>
                  <SupportIcon className="h-5 w-5 mr-2" />
                  {t("errors:contact-support", "Contact support")}
                </Button>
              </a>
            </div>
            <p className="text-gray-500">{`${error.message} (${userErrorId})`}</p>
          </div>
        </div>
      );
    }

    case "component": {
      return (
        <div className="flex px-3 py-2 flex-row justify-center align-middle">
          <ExclamationCircleIcon className="h-10 w-10 mr-3 my-auto" />
          <div className="w-content">
            <h1>{t("errors:error-title", "Something went wrong")}</h1>
            <p className="text-gray-500">{`${error.message} (${userErrorId})`}</p>
          </div>
        </div>
      );
    }

    case "section": {
      return (
        <div className="h-full w-full flex justify-center">
          <div className="flex w-3/4 flex-col space-y-3 self-center text-center">
            <ExclamationCircleIcon className="h-10 w-10 mx-auto" />
            <h1>{t("errors:error-title", "Something went wrong")}</h1>
            <p className="text-gray-500">
              {t(
                "errors:error-subtitle",
                "An error has occurred while trying to load this page. If the problem persists please contact support."
              )}
            </p>
            <p className="text-gray-500">{`${error.message} (${userErrorId})`}</p>
          </div>
        </div>
      );
    }

    default: {
      return null;
    }
  }
};

export default ErrorComponent;
