// @flow
import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
import { getTranslationPayload as t } from "src/i18n";
import { ERROR_MESSAGES } from "../../constants/Form";

export const getInitialValues = () => {
  return {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    default_channel: "",
    meta: [addMeta()],
  };
};

export const schema = yup.object().shape({
  first_name: yup.string().nullable(true),
  last_name: yup.string().nullable(true),
  phone: yup
    .string()
    .test(
      "is-valid-phone-number",
      ERROR_MESSAGES.validPhoneNumber,
      (value, context) => value && isPossiblePhoneNumber(value)
    ),
  email: yup.string().email(ERROR_MESSAGES.email).nullable(true),
  default_channel: yup.string().required(ERROR_MESSAGES.required),
  meta: yup
    .array()
    .of(
      yup.object().shape({
        key: yup.string(),
        value: yup.string().when("key", {
          is: true,
          then: yup.string().required(ERROR_MESSAGES.required),
        }),
      })
    )
    .min(0),
});

export const FIELDS = {
  first_name: {
    key: "first_name",
    label: t("components:input.first-name.label", "First Name"),
    optional: true,
    help: t("components:input.first-name.help", ""),
    placeholder: t("components:input.first-name.placeholder", "María"),
  },
  last_name: {
    key: "last_name",
    label: t("components:input.last-name.label", "Last Name"),
    optional: true,
    placeholder: t("components:input.last-name.placeholder", "Rodríguez"),
    help: t("components:input.last-name.help", ""),
  },
  phone: {
    key: "phone",
    label: t("components:input.phone.label", "Phone"),
    placeholder: t(
      "components:input.phone.placeholder",
      "Use E.164 format, including country code"
    ),
    help: t(
      "components:input.phone.help",
      "E.164 are formatted [+] [country code] [subscriber number including area code] and can have a maximum of fifteen digits."
    ),
  },
  email: {
    key: "email",
    label: t("components:input.email.label", "Email"),
    optional: true,
    placeholder: t("components:input.email.placeholder", "maria@gmail.com"),
    help: t("components:input.email.help", ""),
  },
  external_url: {
    key: "external_url",
    label: t("components:input.url.label", "External URL"),
    optional: true,
    placeholder: t("components:input.url.placeholder", "https://hilos.io"),
    help: t(
      "components:input.url.help",
      "Use this field to set the URL to your CRM's contact detail. We'll show this in the Inbox sidebar so you can quickly move to your CRM from the conversation."
    ),
  },
  default_channel: {
    key: "default_channel",
    label: t("contacts:input.default_channel.label", "Default channel to use"),
    help: t(
      "contacts:input.default_channel.help",
      "Select which channel will be used as a default for this contact."
    ),
  },
  meta: {
    key: {
      key: "key",
      label: t("components:input.attribute.label", "Attribute"),
      placeholder: t("components:input.attribute.placeholder", "Favorite taco"),
    },
    value: {
      key: "value",
      label: t("value", "Value"),
      placeholder: "Beef steak",
    },
  },
};

export const addMeta = () => {
  return { key: "", value: "" };
};

export const CSV_FIELDS = {
  contacts: {
    key: "contacts",
    label: t("contacts-csv", "Contacts CSV"),
  },
};
