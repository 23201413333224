import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NodeChange, applyNodeChanges, useReactFlow } from "reactflow";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/outline";
import { FormikErrors } from "formik";
import { TFunction } from "i18next";
import { FlowData, FlowStepData } from "@hilos/types/flow";
import { hasItems } from "src/helpers/utils";
import { STEP_FIELDS_BY_KEY } from "./constants/flow";
import { countFieldsWithStringValue } from "./helpers/flow";

interface FlowStepErrorsProps {
  values: FlowData;
  errors: FormikErrors<FlowData>;
  onClearSelectItem: () => void;
}

interface StepErrorData {
  total: number;
  errors: string | FormikErrors<FlowStepData>;
}

interface StepErrorItemProps {
  index: number;
  steps: FlowStepData[];
  total: number;
  errors: string | FormikErrors<FlowStepData>;
  onClose: () => void;
  onSelectStep: (id: string) => void;
}

function getErrorMessage(
  t: TFunction<"translation", undefined, "translation">,
  errors: string | FormikErrors<FlowStepData>
) {
  if (["string", "number"].includes(typeof errors)) {
    return String(errors);
  }

  if (errors === null || errors === undefined) {
    return "";
  }

  if (Array.isArray(errors)) {
    return (
      <ul className="list-none hover:list-disc space-y-1 pl-5">
        {errors.map((error, index) => {
          if (!error) {
            return null;
          }
          return (
            <li key={index}>
              <strong>{index}</strong>: {getErrorMessage(t, error)}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <ul className="list-none hover:list-disc space-y-1 pl-5">
      {Object.entries(errors).map(([step_type, error]) => (
        <li key={step_type}>
          <strong>{t(STEP_FIELDS_BY_KEY[step_type])}</strong>:{" "}
          {getErrorMessage(t, error as FormikErrors<FlowStepData>)}
        </li>
      ))}
    </ul>
  );
}

function StepErrorItem({
  steps,
  index,
  total,
  errors,
  onClose,
  onSelectStep,
}: StepErrorItemProps) {
  const { t } = useTranslation();

  if (!total || !steps[index] || !steps[index].id) {
    return null;
  }

  return (
    <li
      className="cursor-pointer"
      onClick={() => {
        onClose();
        onSelectStep(steps[index].id);
      }}
    >
      <div className="flex flex-1 w-full justify-between pr-4 py-1">
        <strong className="truncate break-words">
          {steps[index].name ||
            t("flows:flow-step-errors.step-without-name", "Step without name")}
        </strong>
        <div className="bg-red-100 text-red-700 rounded-md w-4 text-center font-semibold">
          {total}
        </div>
      </div>
      <ul className="list-none hover:list-disc space-y-1">
        {getErrorMessage(t, errors)}
      </ul>
    </li>
  );
}

function FlowStepErrors({
  values,
  errors,
  onClearSelectItem,
}: FlowStepErrorsProps) {
  const { t } = useTranslation();
  const { setNodes } = useReactFlow();

  const { errorsCount, errorsByStep } = useMemo(() => {
    let nextErrorsCount = 0;
    const nextErrorsByStep: StepErrorData[] = [];
    if (
      errors &&
      errors.steps &&
      Array.isArray(errors.steps) &&
      errors.steps.length > 0
    ) {
      for (const currentStepErrors of errors.steps) {
        const total = countFieldsWithStringValue(currentStepErrors);
        nextErrorsCount += total;
        nextErrorsByStep.push({
          total,
          errors: currentStepErrors,
        });
      }
    }

    return {
      errorsCount: nextErrorsCount,
      errorsByStep: nextErrorsByStep,
    };
  }, [errors]);

  const handleSelectStep = useCallback(
    (id: string) => {
      setNodes((nodes) =>
        applyNodeChanges(
          [
            ...nodes.reduce(
              (nodesToChange, node) => {
                if (node.selected && node.id !== id) {
                  nodesToChange.push({
                    type: "select",
                    id: node.id,
                    selected: false,
                  });
                }
                return nodesToChange;
              },
              [
                {
                  type: "select",
                  id,
                  selected: true,
                },
              ] as NodeChange[]
            ),
          ],
          nodes
        )
      );
    },
    [setNodes]
  );

  if (!errorsByStep.length || !hasItems(values.steps)) {
    return null;
  }

  return (
    <Disclosure
      as="div"
      defaultOpen
      className="absolute left-4 top-4 z-10 w-4/12 rounded-lg bg-red-50 shadow-xl"
    >
      <div className="flex flex-col w-full">
        <Disclosure.Button
          onClick={onClearSelectItem}
          className="flex w-full justify-between items-center p-3 focus:outline-none"
        >
          <div className="flex space-x-2">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            <h3 className="text-sm font-medium text-red-700">
              {t(
                "flows:flow-step-errors.title",
                "{{count}} errors were found",
                {
                  count: errorsCount,
                }
              )}
            </h3>
          </div>
          <ChevronDownIcon className="h-4 w-4 text-red-700 transform transition-transform ui-open:rotate-180" />
        </Disclosure.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          className="max-h-[55vh] overflow-y-auto overflow-x-hidden"
        >
          <Disclosure.Panel>
            {({ close }) => (
              <div className="pl-10 pt-2 pb-4 text-sm text-red-700 h-full w-full">
                <ul className="space-y-1 w-full">
                  {errorsByStep.map((currentStepErrors, index) => (
                    <StepErrorItem
                      key={`step-${index}`}
                      index={index}
                      steps={values.steps}
                      total={currentStepErrors.total}
                      errors={currentStepErrors.errors}
                      onClose={close}
                      onSelectStep={handleSelectStep}
                    />
                  ))}
                </ul>
              </div>
            )}
          </Disclosure.Panel>
        </Transition>
      </div>
    </Disclosure>
  );
}

export default FlowStepErrors;
