import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { InboxUsageSummaryData } from "@hilos/types/analytics";
import { API_ROUTES } from "../router/router";

export function useInboxUsageSummaryCards(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url = API_ROUTES.ANALYTICS_INBOX_USAGE_SUMMARY_CARDS + "?" + params;
    const { data } = await axios.get(url);
    return data;
  }, [params]);

  const { data } = useQuery(
    "inbox_usage_summary_cards" + params,
    fetchUsageSummary,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    inboxUsageSummaryCards: data || undefined,
  };
}

export function useInboxUsageSummaryConversationSource(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url =
      API_ROUTES.ANALYTICS_INBOX_USAGE_SUMMARY_CONVOS_PER_SOURCE + "?" + params;
    const { data } = await axios.get(url);
    return data;
  }, [params]);

  const { data } = useQuery(
    "inbox_usage_summary_convos_per_source" + params,
    fetchUsageSummary,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    inboxUsageSummaryConvoSource: data || undefined,
  };
}

export function useInboxUsageSummaryAnalytics(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url = API_ROUTES.ANALYTICS_INBOX_USAGE_SUMMARY + "?" + params;
    const { data } = await axios.get<InboxUsageSummaryData>(url);
    return data;
  }, [params]);

  const { data } = useQuery("inbox_usage_summary" + params, fetchUsageSummary, {
    refetchOnWindowFocus: false,
  });

  return {
    inboxUsageSummary: data || ({} as InboxUsageSummaryData),
  };
}
