import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BeakerIcon, ExclamationIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import { ERROR_MESSAGES } from "src/constants/Form";
import { AccountStatus } from "src/types/account";
import APIErrors from "../../components/base/APIErrors";
import useHilosStore from "../../hooks/useHilosStore";
import WhatsAppTemplateAutosave from "./WhatsAppTemplateAutosave";
import WhatsAppTemplateFormStepAttrs from "./WhatsAppTemplateFormStepAttrs";
import WhatsAppTemplateFormStepContent from "./WhatsAppTemplateFormStepContent";
import WhatsAppTemplateFormStepContentAuthentication from "./WhatsAppTemplateFormStepContentAuthentication";
import WhatsAppTemplateFormStepEnd from "./WhatsAppTemplateFormStepEnd";
import WhatsAppTemplateFormStepNav from "./WhatsAppTemplateFormStepNav";
import * as meta from "./WhatsAppTemplateMeta";

interface WhatsAppTemplateFormProps {
  WhatsAppTemplate;
  formSubmit: (
    values: WhatsAppTemplate,
    setSubmitting: (b: boolean) => void,
    setBackendValidationErrors: (o: object) => void,
    setBackendError: (s: string) => void
  ) => void;
  success: boolean;
  submitted: boolean;
}

export default function WhatsAppTemplateForm({
  WhatsAppTemplate,
  formSubmit,
  success,
  submitted,
}: WhatsAppTemplateFormProps) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState("attrs");
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const { session } = useHilosStore();

  const cleanValuesForSubmit = (values): WhatsAppTemplate => {
    const newVals = JSON.parse(JSON.stringify(values));
    newVals.components.forEach((component, index) => {
      if (component.example && Object.keys(component.example).length === 0) {
        delete component.example;
      }
      if (component.type === "HEADER") {
        if (values.category === "AUTHENTICATION") {
          delete newVals.components[index];
        }
        if (component.format === "NONE") {
          delete newVals.components[index];
        } else if (component.format !== "TEXT") {
          if (newVals.components[index].text) {
            delete newVals.components[index].text;
          }
        }
      }
      if (component.type === "BODY") {
        if (values.category !== "AUTHENTICATION") {
          if (newVals.components[index]["add_security_recommendation"]) {
            delete newVals.components[index]["add_security_recommendation"];
          }
        }
      }
      if (component.type === "FOOTER") {
        delete newVals.components[index]["add_code_expiration_minutes"];
        if (values.category !== "AUTHENTICATION") {
          if (newVals.components[index]["code_expiration_minutes"]) {
            delete newVals.components[index]["code_expiration_minutes"];
          }
        }
        if (!component.text && values.category !== "AUTHENTICATION") {
          delete newVals.components[index];
        }
      }
      if (component.type === "BUTTONS") {
        if (!component.buttons.length) {
          delete newVals.components[index];
        }
        component.buttons.forEach((button, idx) => {
          newVals.components[index].buttons[idx].text = button.text.trim();
          if (button.type === "URL") {
            const url = button.url;
            const varCount = url.match(/{{([0-9])}}/g)?.length;

            if (!varCount) {
              delete newVals.components[index].buttons[idx].example;
            }
          }
        });
      }
    });
    if (newVals.examples) delete newVals.examples;
    // Filtering null components
    newVals.components = [...newVals.components.filter((comp) => comp)];
    return newVals;
  };

  const onSubmit = (values, setSubmitting) => {
    formSubmit(
      values,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  const validate = (values: WhatsAppTemplate) => {
    let errors = {
      components: values.components ? values.components.map((c) => ({})) : [],
    };
    let hasErrors = false;
    values.components?.forEach((component, index) => {
      if (component.type === "BUTTONS") {
        errors.components[index]["buttons"] = [];
        component.buttons.forEach((button, idx) => {
          errors.components[index]["buttons"][idx] = {};
          if (button.type === "URL") {
            const re =
              /^((http|https):\/\/)?(www\.)?(?!.*(http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.*?(\{{2}\d+\}{2})?$/;

            const schema = yup
              .string()
              .matches(re, ERROR_MESSAGES.url)
              .required();

            if (!schema.isValidSync(button.url)) {
              hasErrors = true;
              errors["components"][index]["buttons"][idx]["url"] =
                ERROR_MESSAGES.url;
            }
          }
        });
      }
    });
    return hasErrors ? errors : {};
  };

  const getStatusNotice = (status) => {
    switch (status) {
      case AccountStatus.IN_SANDBOX:
        return (
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <BeakerIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-blue-800">
                  {t(
                    "templates:account-in-sandbox",
                    "You are currently in sandbox mode and can't save templates"
                  )}
                </h3>
                <div className="text-sm text-blue-700">
                  <p>
                    {t(
                      "templates:check-template-builder",
                      "Check out the template builder to see what is possible or connect a number to build your own templates!"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );

      case AccountStatus.NEW || AccountStatus.INACTIVE:
        return (
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  {t(
                    "templates:account-not-connected",
                    "Your account is not connected to WhatsApp"
                  )}
                </h3>
                <div className="text-sm text-yellow-700">
                  <p>
                    {t(
                      "templates:save-disabled",
                      "You won't be able to save this template."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <Formik
      validationSchema={meta.schema}
      onSubmit={(values, { setSubmitting }) =>
        onSubmit(cleanValuesForSubmit(values), setSubmitting)
      }
      enableReinitialize={true}
      initialValues={WhatsAppTemplate}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validate}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <WhatsAppTemplateFormStepNav
              formik={formik}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          </div>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
            {session && session.account && session.account.status && (
              <>{getStatusNotice(session.account.status)}</>
            )}
          </div>
          {currentStep === "attrs" && (
            // name, lang and category
            <WhatsAppTemplateFormStepAttrs
              formik={formik}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === "content" && (
            // template content
            <>
              {!formik.values[meta.FIELDS.category.key] ||
              formik.values[meta.FIELDS.category.key] !== "AUTHENTICATION" ? (
                <WhatsAppTemplateFormStepContent
                  formik={formik}
                  setCurrentStep={setCurrentStep}
                />
              ) : (
                <WhatsAppTemplateFormStepContentAuthentication
                  formik={formik}
                  setCurrentStep={setCurrentStep}
                  submitted={submitted}
                  success={success}
                  accountStatus={session?.account.status}
                />
              )}
            </>
          )}
          {currentStep === "end" && (
            // examples
            <WhatsAppTemplateFormStepEnd
              formik={formik}
              setCurrentStep={setCurrentStep}
              submitted={submitted}
              success={success}
              accountStatus={session?.account.status}
            />
          )}
          <WhatsAppTemplateAutosave formik={formik} />
        </form>
      )}
    </Formik>
  );
}
