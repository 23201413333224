import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { PaginatedResponse } from "@hilos/types/django/PaginatedResponse";
import { FlowExecutionContactListSimple } from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES } from "../router/router";

function getURLQuery({ contactId, isActive }: useFlowExecutionContactsArgs) {
  let urlQuery = `?contact=${contactId}`;

  if (isActive !== null) {
    urlQuery += `&is_active=${isActive}`;
  }
  return urlQuery;
}
interface useFlowExecutionContactsArgs {
  contactId: string;
  isActive?: boolean;
}

export function useFlowExecutionContacts({
  contactId,
  isActive,
}: useFlowExecutionContactsArgs) {
  const urlQuery = getURLQuery({ contactId, isActive });
  const fetchFlowExecutionContact = useCallback(async () => {
    const { data } = await axios.get<
      PaginatedResponse<FlowExecutionContactListSimple>
    >(API_ROUTES.FLOW_EXECUTION_CONTACT_LIST + urlQuery);
    return data;
  }, [urlQuery]);

  const { data } = useQuery(
    ["flow_execution_contact_list", contactId, isActive],
    fetchFlowExecutionContact
  );

  return data;
}

export default useFlowExecutionContacts;

interface useUpdateFlowExecutionContactsQueryArgs {
  contactId: string;
  flowExecutionContactId: string;
  isActive?: boolean;
}

export const useUpdateFlowExecutionContactsQuery = ({
  contactId,
  flowExecutionContactId,
  isActive,
}: useUpdateFlowExecutionContactsQueryArgs) =>
  useCallback(
    (
      newFlowExecutionContactContactExecutionData: Partial<FlowExecutionContactListSimple>
    ) => {
      const currentData = queryClient.getQueryData<
        PaginatedResponse<FlowExecutionContactListSimple>
      >(["flow_execution_contact_list", contactId, isActive]);
      const updatedContactExecutions = currentData?.results?.map(
        (contactExecution) => {
          if (contactExecution.id === flowExecutionContactId) {
            return {
              ...contactExecution,
              ...newFlowExecutionContactContactExecutionData,
            };
          }
          return contactExecution;
        }
      );
      queryClient.setQueryData(
        ["flow_execution_contact_list", contactId, isActive],
        { ...currentData, results: updatedContactExecutions }
      );
    },
    [contactId, flowExecutionContactId, isActive]
  );
