import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import useFieldWithVariables from "src/containers/flow/builder/hooks/useFieldWithVariables";
import useVariables from "src/containers/flow/builder/hooks/useVariables";
import FieldContainer from "src/components/Form/FieldContainer";
import { classNames } from "../../../../Helpers";
import CreateVariableModal from "./CreateVariableModal";
import FlowBuilderSelectVariable from "./FlowBuilderSelectVariable";

interface TextAreaFieldWithVariablesProps
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, "action"> {
  path: string;
  name: string;
  help?: string;
  optional?: boolean;
  currentStepIndex?: number | null;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
}

function TextAreaFieldWithVariables({
  path,
  name,
  label,
  rows,
  help,
  optional,
  currentStepIndex,
  icon: RenderIcon,
  ...props
}: TextAreaFieldWithVariablesProps) {
  const { t } = useTranslation();
  const [showVariables, setShowVariables] = useState(false);
  const [newVar, setNewVar] = useState("");
  const [showCreateVariableModal, setShowCreateVariableModal] = useState(false);
  const { options, isInboundFlow, hasMissingActionTest, handleGetVariable } =
    useVariables({ currentStepIndex });
  const {
    field,
    error,
    valueWithVariables,
    handleAddVariable,
    handleChange,
    handleCursorPosition,
  } = useFieldWithVariables({
    path,
    name,
    handleGetVariable,
  });

  const handleSelectOption = useCallback(
    (option) => {
      setShowVariables(false);
      if (option && option.value) {
        handleAddVariable(option.value);
      }
    },
    [handleAddVariable]
  );

  const handleCreateOption = useCallback((inputValue) => {
    setNewVar(inputValue);
    setShowCreateVariableModal(true);
  }, []);

  const handleCloseCreateVariableModal = useCallback(() => {
    setShowCreateVariableModal(false);
    setNewVar("");
  }, []);

  return (
    <div>
      <FieldContainer
        name={field.name}
        label={label}
        help={help}
        optional={optional}
        error={error}
      >
        <div className="relative mt-1 rounded-md shadow-sm">
          {RenderIcon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <RenderIcon className="h-5 w-5 text-gray-500" />
            </div>
          )}
          <textarea
            className={classNames(
              "block w-full focus:border-indigo-500 focus:ring-indigo-500",
              "rounded-md border-gray-300 sm:text-sm",
              RenderIcon && "pl-10",
              error && "border-red-500 focus:border-red-500 focus:ring-red-500"
            )}
            id={field.name}
            rows={rows || 3}
            {...field}
            {...props}
            onKeyUp={handleCursorPosition}
            onKeyDown={handleCursorPosition}
            onClick={handleCursorPosition}
            value={valueWithVariables || ""}
            onChange={handleChange}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-start pr-3 pt-2">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </FieldContainer>

      <div className="mt-2 flex flex-row items-end justify-end">
        {showVariables ? (
          <FlowBuilderSelectVariable
            options={options}
            isInboundFlow={isInboundFlow}
            hasMissingActionTest={hasMissingActionTest}
            onClose={() => setShowVariables(false)}
            onSelectVariable={handleSelectOption}
            onCreateVariable={handleCreateOption}
          />
        ) : (
          <button
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            type="button"
            onClick={() => setShowVariables(true)}
          >
            {t("add-variable")}
          </button>
        )}
      </div>

      {showCreateVariableModal && (
        <CreateVariableModal
          isOpen={showCreateVariableModal}
          onClose={handleCloseCreateVariableModal}
          addVariable={handleAddVariable}
          inputValue={newVar}
        />
      )}
    </div>
  );
}

export default TextAreaFieldWithVariables;
