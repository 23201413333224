import { PropsWithChildren } from "react";
import { classNames } from "../../Helpers";

interface InputActionButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: "reset" | "button" | "submit";
}

function InputActionButton({
  children,
  type = "submit",
  className,
  ...props
}: PropsWithChildren<InputActionButtonProps>) {
  return (
    <button
      type={type}
      className={classNames(
        "relative inline-flex items-center space-x-2 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-4 py-2",
        "text-sm font-medium text-gray-700",
        "hover:bg-gray-100 focus:outline-none focus:ring-0",
        "disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-300 disabled:shadow-none",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}

export default InputActionButton;
