import Button from "src/components/Button";
import { Link } from "react-router-dom";
import Loading from "src/components/Loading";
import { PhoneIcon } from "@heroicons/react/outline";
import Tabs from "src/components/Tabs";
import WidgetForm from "./WidgetForm";
import { useChannelAvailability } from "src/hooks/useChannel";
import { useTranslation } from "react-i18next";

const WAWidgetContainer = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useChannelAvailability();

  if (isLoading) {
    return <Loading />;
  }

  if (data.length === 0) {
    return (
      <div>
        <div className="h-screen w-full flex justify-center items-center">
          <div className="flex md:w-2/4 flex-col space-y-3 self-center text-center">
            <PhoneIcon className="h-10 w-10 mx-auto" />
            <h1 className="text-gray-800">
              {t(
                "widget:no-channels.title",
                "Seems like you don't have channels yet"
              )}
            </h1>
            <p className="text-gray-500">
              {t(
                "widget:no-channels.subtitle",
                "What are you waiting for? Connect your WhatsApp number to create your own WhatsApp widget!"
              )}
            </p>
            <div className="gap-x-3">
              <Link to="/config/channel">
                <Button className="my-3">
                  {t("widget:connect-channel", "Connect a channel")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Tabs
        tabs={data.map(({ channel }) => ({
          label: channel.name,
        }))}
        className="px-4 sm:px-6"
      >
        {data.map(({ channel }) => (
          <WidgetForm key={channel.id} channel={channel} />
        ))}
      </Tabs>
    </div>
  );
};

export default WAWidgetContainer;
