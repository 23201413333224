import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";
import { userAvatar, userToString } from "src/Helpers";
import FlowStepTagDetailComponent from "./components/FlowStepTagDetailComponent";

interface FlowStepConversationDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepConversationDetail({
  step,
}: FlowStepConversationDetailProps) {
  const { t } = useTranslation();
  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:steps-detail.conversation.users-to-assign",
            "Users to assign"
          )}
          :
        </h3>
        <ul>
          {step.assign_to_users?.map((user, idx) => (
            <li className="flex items-center" key={idx}>
              <span className="text-xs mr-1">
                {userAvatar(user, "h-6 w-6")}
              </span>
              <span>{userToString(user)}</span>
            </li>
          ))}
        </ul>
      </div>
      <FlowStepTagDetailComponent step={step} />
    </div>
  );
}
