import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { getIn, useFormikContext } from "formik";
import LineTabs from "src/components/LineTabs";
import { hasItems } from "src/helpers/utils";
import { classNames } from "src/Helpers";

function FormatedResponseData({ data }) {
  const formatedData = useMemo(() => {
    if (!data) {
      return null;
    }

    try {
      return JSON.stringify(JSON.parse(data), null, 2);
    } catch {
      return JSON.stringify(data, null, 2);
    }
  }, [data]);

  return (
    <pre className="mt-1 overflow-scroll whitespace-pre-wrap break-words break-all rounded-md border border-neutral-light bg-gray-50 p-2 font-mono text-xs font-normal text-black">
      {formatedData}
    </pre>
  );
}

function getActionResponseColor(data: any) {
  if (!data || !data.status || data.status > 400) {
    return "bg-red-400";
  }

  if (data.status > 300) {
    return "bg-yellow-400";
  }

  return "bg-green-400";
}

function FlowBuilderStepActionResponse({ name }) {
  const { t } = useTranslation();
  const [showActionResponse, setShowActionResponse] = useState(0);
  const { values } = useFormikContext();
  const responses = useMemo(() => getIn(values, name) || null, [values, name]);

  if (!hasItems(responses)) {
    return null;
  }

  return (
    <>
      <h3 className="text-tiny font-semibold uppercase tracking-wide text-gray-600">
        Responses
      </h3>
      <div className="my-6 space-y-4">
        <ul className="-mb-8">
          {responses.map((response, index) => (
            <li key={`response-index-${index}`}>
              <div className="relative pb-4">
                {index !== responses.length - 1 && (
                  <span
                    className="absolute left-1.5 top-0.5 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                )}
                <div className="relative flex flex-col">
                  <button
                    type="button"
                    onClick={() =>
                      setShowActionResponse(
                        showActionResponse === index ? undefined : index
                      )
                    }
                    className="flex flex-row w-full justify-between items-center"
                  >
                    <div className="flex flex-row space-x-3 items-center w-full">
                      <span
                        className={classNames(
                          "h-3 w-3 rounded-full ring ring-gray-200",
                          getActionResponseColor(response.data)
                        )}
                      />
                      <p className="text-sm text-gray-500 overflow-hidden truncate break-all line-clamp-1">
                        {t("{{ date, PPpp }}", {
                          date: new Date(response.created_on),
                        })}
                      </p>
                    </div>
                    <ChevronRightIcon
                      className={`h-4 w-4 text-gray-400 ${
                        showActionResponse === index
                          ? "rotate-90 transform transition-transform duration-100 ease-in-out"
                          : ""
                      }`}
                    />
                  </button>
                  <Transition
                    show={showActionResponse === index}
                    enter="transition duration-100 ease-in"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className="pl-6 pt-4"
                  >
                    <LineTabs
                      labels={[
                        t("flows:steps.action.response.body", "Body"),
                        t("flows:steps.action.response.headers", "Headers"),
                      ]}
                    >
                      <FormatedResponseData data={response.data.response} />
                      <dl className="space-y-1 overflow-scroll sm:divide-y sm:divide-gray-200">
                        {response.data.headers &&
                          Object.keys(response.data.headers).map(
                            (headerKey) => (
                              <div
                                key={headerKey}
                                className="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-2"
                              >
                                <dt className="text-sm font-medium text-gray-500">
                                  {headerKey}
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                  <code className="mt-1 break-all rounded-lg bg-gray-50 p-2 font-mono text-xs font-normal text-black">
                                    {response.data.headers &&
                                      response.data.headers[headerKey]}
                                  </code>
                                </dd>
                              </div>
                            )
                          )}
                      </dl>
                    </LineTabs>
                  </Transition>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default FlowBuilderStepActionResponse;
