import axios from "axios";
import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import {
  BodyComponent,
  ButtonsComponent,
  HeaderComponent,
  HeaderTextComponent,
  URLButtonComponent,
  WhatsAppTemplate,
} from "@hilos/types/wa/templates";
import { getVariablesRequiredFromTemplate } from "src/containers/wa/WhatsAppTemplateMeta";
import { getTemplateComponents } from "src/helpers/template";
import { hasItems } from "src/helpers/utils";
import { textVariableRegex } from "src/helpers/variables";
import { ERROR_MESSAGES } from "src/constants/errors";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { FlowStepAnswerSchema } from "../flow";

export const FlowStepTemplateSchema = (t: TFunction) =>
  FlowStepAnswerSchema(t).shape({
    whatsapp_template: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    save_contact_answer: yup.bool(),
    validate_answer_with_buttons: yup.bool(),
    whatsapp_template_selected: yup
      .mixed()
      .required(t(ERROR_MESSAGES.REQUIRED)),
    group_answers_from_multiple_texts: yup.bool(),
    minutes_to_wait_for_more_messages: yup
      .number()
      .when("group_answers_from_multiple_texts", {
        is: true,
        then: (schema) =>
          schema
            .min(1, t(ERROR_MESSAGES.MIN_NUMBER_VALUE))
            .required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    whatsapp_template_variables: yup.lazy((_, options) => {
      const nextSchemaShape = {};

      try {
        const templateSelected = options.parent["whatsapp_template_selected"];
        const {
          headerRequiresURL,
          headerRequiresVariable,
          bodyVariables,
          buttonsVariables,
          isHeaderLocation,
        } = getVariablesRequiredFromTemplate(templateSelected);

        if (headerRequiresURL) {
          nextSchemaShape["headerURL"] = yup
            .string()
            .required(t(ERROR_MESSAGES.REQUIRED));
        }
        if (headerRequiresVariable) {
          nextSchemaShape["headerVar"] = yup
            .string()
            .required(t(ERROR_MESSAGES.REQUIRED));
        }
        if (isHeaderLocation) {
          nextSchemaShape["locationHeaderVars"] = yup.object().shape({
            name: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
            address: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
            latitude: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
            longitude: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
          });
        }
        if (bodyVariables.length > 0) {
          nextSchemaShape["bodyVars"] = yup
            .array()
            .length(bodyVariables.length)
            .of(yup.string().required(t(ERROR_MESSAGES.REQUIRED)));
        }
        if (buttonsVariables.length > 0) {
          nextSchemaShape["buttonVars"] = yup
            .array()
            .length(buttonsVariables.length)
            .of(yup.string().required(t(ERROR_MESSAGES.REQUIRED)));
        }
      } catch {}

      return yup.object().shape(nextSchemaShape);
    }),
    answer_validation_message: yup
      .string()
      .nullable()
      .when("validate_answer_with_buttons", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
  });

export function getTemplateVariablesFromText(
  templateBody
): RegExpMatchArray | [] {
  if (templateBody) {
    return (templateBody as string).match(textVariableRegex) || [];
  }
  return [];
}

function getUrlFromButtons(buttons: ButtonsComponent | null) {
  if (buttons) {
    for (const button of buttons.buttons) {
      if (button.type === "URL") {
        return button.url || "";
      }
    }
  }

  return "";
}

export const getRequiredVariablesFromTemplate = (
  template?: WhatsAppTemplate | null
) => {
  const {
    header,
    body,
    buttons,
  }: {
    header: HeaderComponent | null;
    body: BodyComponent | null;
    buttons: ButtonsComponent | null;
  } = getTemplateComponents(template);

  const headerFormat =
    header &&
    header.format &&
    ["IMAGE", "DOCUMENT", "VIDEO", "TEXT", "LOCATION"].includes(header.format)
      ? header.format
      : null;
  const hasHeaderURL =
    headerFormat !== null &&
    ["IMAGE", "DOCUMENT", "VIDEO"].includes(headerFormat);
  const hasHeaderVariables =
    headerFormat === "TEXT" &&
    hasItems(
      getTemplateVariablesFromText((header as HeaderTextComponent).text || "")
    );
  const textBodyVariables = getTemplateVariablesFromText(
    (body && body.text) || ""
  );

  var buttonVariables: string[] = [];

  if (buttons && buttons.buttons && hasItems(buttons.buttons)) {
    const urlButtons = buttons.buttons.filter((b) => b.type === "URL");
    if (urlButtons.length > 0) {
      urlButtons.forEach((btn, idx) => {
        if (
          hasItems(
            getTemplateVariablesFromText((btn as URLButtonComponent).url)
          )
        ) {
          buttonVariables?.push(btn.text);
        }
      });
    }
  }

  const bodyVariables =
    template?.category === "AUTHENTICATION"
      ? ["{{ OTP Code}}"]
      : textBodyVariables;
  return {
    headerFormat,
    bodyVariables,
    buttonVariables,
    hasHeaderURL,
    hasHeaderVariables,
  };
};

export const getWhatsappTemplateData = async (id: string) => {
  try {
    if (!id) {
      return null;
    }

    const { data } = await axios.get<WhatsAppTemplate>(
      buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE, { ":id": id })
    );

    return data || null;
  } catch {
    return null;
  }
};

export const FlowStepTemplateFormat = (step: FlowStepData) => {
  const nextStep = { ...step };

  if (!nextStep.has_max_wait_time) {
    delete nextStep.max_wait_time_amount;
    delete nextStep.max_wait_time_unit;
  }

  if (!nextStep.has_max_answer_attempts) {
    delete nextStep.max_answer_attempts;
    nextStep.answer_failed_next_step = null;
  }

  return nextStep;
};
