import { useCallback } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useField, useFormikContext } from "formik";
import { classNames } from "../../Helpers";
import AddVariableForm from "../../containers/inbox/Conversation/AddVariableForm";
import FieldContainer from "./FieldContainer";

interface TextAreaVariablesFieldProps
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, "action"> {
  name: string;
  help?: string;
  optional?: boolean;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
}

function TextAreaVariablesField({
  name,
  label,
  rows,
  help,
  optional,
  icon: RenderIcon,
  ...props
}: TextAreaVariablesFieldProps) {
  const [field, meta] = useField({ name });
  const { setFieldValue } = useFormikContext();
  const error = meta.touched && meta.error;

  const handleAddVariable = useCallback(
    (variable) => {
      const prevValue = field.value;
      let nextValueWithVariable = "";

      if (prevValue) {
        let separator = "";

        if (prevValue[prevValue.length - 1] !== " ") {
          separator = " ";
        }

        nextValueWithVariable = `${prevValue}${separator}${variable}`;
      }

      setFieldValue(field.name, nextValueWithVariable || variable);
    },
    [field, setFieldValue]
  );

  return (
    <div>
      <FieldContainer
        name={name}
        label={label}
        help={help}
        optional={optional}
        error={error}
      >
        <div className="relative mt-1 rounded-md shadow-sm">
          {RenderIcon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <RenderIcon className="h-5 w-5 text-gray-500" />
            </div>
          )}
          <textarea
            className={classNames(
              "block w-full focus:border-indigo-500 focus:ring-indigo-500",
              "border-gray-300 sm:text-sm",
              RenderIcon && "pl-10",
              error && "border-red-500"
            )}
            id={name}
            rows={rows || 3}
            {...field}
            {...props}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-start pr-3 pt-2">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </FieldContainer>
      <AddVariableForm onAddVariable={handleAddVariable} />
    </div>
  );
}

export default TextAreaVariablesField;
