import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowSmRightIcon,
  ExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

interface APIErrorsProps {
  APIError: string;
  APIValidationErrors: object;
  fieldTranslations: object;
  extraInfo?: string | JSX.Element;
}

export default function APIErrors({
  APIError,
  APIValidationErrors,
  fieldTranslations,
  extraInfo,
}: APIErrorsProps) {
  const { t } = useTranslation();
  const transformErrorsRecursively = (errorList, fieldName) => {
    // Can be a string, an array or a nested error obj
    // If your error is not properly showing, check how you're raising the
    // serializers.ValidationError from your API endpoint.
    // Usually you'd do something like:
    // raise serializers.ValidationError({fieldName: 'errorMsg'})
    const fieldDescription = fieldTranslations[fieldName]
      ? fieldTranslations[fieldName].label
      : fieldName;

    if (typeof errorList[fieldName] === "string") {
      return (
        <li key={fieldName}>
          {fieldDescription && (
            <>
              <strong>{fieldDescription}</strong>:{" "}
            </>
          )}
          {errorList[fieldName]}
        </li>
      );
    } else if (Array.isArray(errorList[fieldName])) {
      const fieldDescription = fieldTranslations[fieldName]
        ? t(fieldTranslations[fieldName].label)
        : fieldName;

      const stringErrors = errorList[fieldName].filter(
        (error) => typeof error === "string"
      );
      const arrayErrors = errorList[fieldName].filter((error) =>
        Array.isArray(error)
      );
      const objErrors = errorList[fieldName].filter(
        (error) => typeof error === "object" && !Array.isArray(error)
      );

      return (
        <li key={fieldName}>
          <strong>{fieldDescription}</strong>: {stringErrors.join(" ")}
          {arrayErrors.length > 0 && (
            <ul>
              {arrayErrors.map((errors, idx) =>
                transformErrorsRecursively(errors, idx)
              )}
            </ul>
          )}
          {objErrors.length > 0 && (
            <ul>
              {objErrors.map((error) =>
                Object.getOwnPropertyNames(error).map((nestedFieldName) =>
                  transformErrorsRecursively(error, nestedFieldName)
                )
              )}
            </ul>
          )}
        </li>
      );
    } else if (typeof errorList[fieldName] === "object") {
      const fieldDescription = fieldTranslations[fieldName]
        ? fieldTranslations[fieldName].label
        : fieldName;

      return (
        <li key={fieldName}>
          <strong>{fieldDescription}</strong>:
          <ul>
            {Object.getOwnPropertyNames(errorList[fieldName]).map(
              (nestedFieldName) =>
                transformErrorsRecursively(
                  errorList[fieldName],
                  nestedFieldName
                )
            )}
          </ul>
        </li>
      );
    }
  };

  const getValidationErrorList = (errorList) => {
    return Object.getOwnPropertyNames(errorList).map((fieldName, idx) => (
      <Fragment key={idx}>
        {fieldName === "non_field_errors" ? (
          <>
            {errorList["non_field_errors"].map((error, eIdx) => (
              <li key={eIdx}>{JSON.stringify(error)}</li>
            ))}
          </>
        ) : (
          <>{transformErrorsRecursively(errorList, fieldName)}</>
        )}
      </Fragment>
    ));
  };

  return (
    <>
      {APIError ? (
        <div className="rounded-md bg-yellow-50 p-4">
          {APIError === "Error with 360 Dialog Services." ? (
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="mr-2">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Unable to connect to WhatsApp
                  </h3>
                  <div className="mt-1 text-sm text-yellow-700">
                    <p>
                      We tried sending the template to WhatsApp, but their
                      servers are not responding. You can see if there are any
                      issues with WhatsApp in 360Dialog's website. Please try
                      again later or contact support if it's still failing.
                    </p>
                  </div>
                </div>
              </div>

              <a
                href="https://status.360dialog.com/"
                target="_blank"
                rel="noreferrer"
                className="mt-2 inline-flex flex-shrink-0 grow-0 rounded-md border border-yellow-700 bg-white p-3 text-sm font-medium text-yellow-700 md:ml-auto"
              >
                Check WhatsApp status
                <ArrowSmRightIcon
                  className=" ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </a>
            </div>
          ) : (
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Oh no!
                  </h3>
                  <div className="mt-1 text-sm text-yellow-700">
                    <p>{APIError}</p>
                  </div>
                </div>
                {extraInfo && (
                  <div className="mt-2 border-t border-yellow-800 pt-2 text-xs text-yellow-700">
                    {extraInfo}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {Object.getOwnPropertyNames(APIValidationErrors).length > 0 && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-700">
                    There were errors found:
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul className="list-disc space-y-1 pl-5">
                      {getValidationErrorList(APIValidationErrors)}
                    </ul>
                  </div>
                  {extraInfo && (
                    <div className="mt-2 border-t border-red-800 pt-2 text-xs text-red-700">
                      {extraInfo}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
