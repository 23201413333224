import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import SelectorField from "src/components/Form/SelectorField";

export interface VariableOption {
  label: string;
  value: string;
  selected: boolean;
}

export interface VariableOptionGroup {
  label: string;
  icon: (props: any) => JSX.Element;
  options: VariableOption[];
}

interface BroadcastFormSelectVariableProps {
  name: string;
  variableOptionsGroup: VariableOptionGroup[];
}

const formatOptionLabel = (
  data: VariableOption,
  formatOptionLabelMeta: FormatOptionLabelMeta<VariableOption>
) => (
  <div
    className={`${
      data.selected && formatOptionLabelMeta.context === "menu"
        ? "opacity-40 "
        : ""
    } `}
  >
    <div>{data.label}</div>
  </div>
);

export default function BroadcastFormSelectVariable({
  name,
  variableOptionsGroup,
}: BroadcastFormSelectVariableProps) {
  return (
    <SelectorField<VariableOption, VariableOptionGroup>
      name={name}
      options={variableOptionsGroup}
      isOptionDisabled={(option: VariableOption) => option.selected}
      formatOptionLabel={formatOptionLabel}
    />
  );
}
