import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import {
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import languageEncoding from "detect-file-encoding-and-language";
import { FormikProps, useField } from "formik";
import { ParseResult, parse } from "papaparse";
import { FlowExecutionEdit } from "@hilos/types/private-schema";
import { CsvFile } from "src/containers/broadcasts/BroadcastForm";
import FieldContainer from "src/components/Form/FieldContainer";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { DropzoneStyles } from "../../../components/base/Form";
import FlowExecutionFormContactFileContactList from "./FlowExecutionFormContactFileContactList";
import * as meta from "./FlowExecutionMeta";

interface FlowExecutionFormContactFileProps {
  formik: FormikProps<
    FlowExecutionEdit & {
      contact_list_file: string;
    }
  >;
}

export default function FlowExecutionFormContactFile({
  formik,
}: FlowExecutionFormContactFileProps) {
  const { t } = useTranslation();
  const [file, setFile] = useState<CsvFile>({
    source: undefined,
    preview: undefined,
  });
  const parseInProgress = useRef(false);
  const [showHelp, setShowHelp] = useState(false);
  const toggleShowHelp = () => {
    setShowHelp(!showHelp);
  };
  const cleanTimeout = useCleanTimeout();

  const handleSetFile = useCallback(
    async (csvFile?: CsvFile) => {
      if (!csvFile) {
        return;
      }
      setFile(csvFile);
      if (csvFile.source && !parseInProgress.current) {
        parseInProgress.current = true;
        const encodingResults = await languageEncoding(csvFile.source);
        // @ts-expect-error For some unknown reason TS tells us that
        // there is no correct function overload when we add the encoding
        parse(csvFile.source, {
          header: false,
          encoding: encodingResults.encoding,
          complete: (results: ParseResult<string[]>) => {
            let hasError = false;

            if (results.data.length <= 0) {
              formik.setFieldError(
                meta.FIELDS.contact_list_file.key,
                t(
                  "flow-executions:no-rows-detected",
                  "We couldn't detect any rows in your CSV file."
                )
              );
              hasError = true;
            }

            const headers = results.data[0];
            if (!headers.find((h) => h.toLowerCase() === "phone")) {
              formik.setFieldError(
                meta.FIELDS.contact_list_file.key,
                t(
                  "flow-executions:no-phone-column-found",
                  "We couldn't find a *phone* column in your CSV, make sure it exists."
                )
              );
              hasError = true;
            }

            if (!hasError) {
              formik.setFieldError(
                meta.FIELDS.contact_list_file.key,
                undefined
              );

              formik.setFieldValue(
                meta.FIELDS.contact_list.key,
                results.data
                  .filter((dataRecord) => dataRecord[0])
                  .map((dataRecord) => [true, ...dataRecord])
              );
            }

            parseInProgress.current = false;
          },
        });
        cleanTimeout.current = setTimeout(() => {
          parseInProgress.current = false;
        }, 5000);
      } else if (csvFile.source == null) {
        // Remove the old contact_list
        formik.setFieldValue(meta.FIELDS.contact_list.key, undefined);
        // Remove any error related to the old contact_list
        formik.setFieldError(meta.FIELDS.contact_list.key, undefined);
      }
    },
    [cleanTimeout, formik, setFile, t]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: "text/csv",
      onDrop: (acceptedFiles, fileRejections) => {
        fileRejections.forEach((file) => {
          formik.setFieldError(
            formik.errors[meta.FIELDS.contact_list_file.key],
            `${file.file.name} ${t(
              "flow-executions:csv-not-valid",
              "is not a valid .csv file."
            )}`
          );
        });

        const nextFiles = acceptedFiles.map((file) => {
          if (file.size > 1024 * 20000) {
            formik.setFieldError(
              formik.errors[meta.FIELDS.contact_list_file.key],
              t(
                "flow-executions:csv-too-big",
                "The file is too big. Maximum size is 20MB."
              )
            );
          }

          formik.setFieldValue(meta.FIELDS.contact_list_file.key, file.name);
          formik.setFieldError(meta.FIELDS.contact_list_file.key, undefined);
          return {
            source: file,
            preview: URL.createObjectURL(file),
          };
        });
        handleSetFile(nextFiles[0]);
      },
    });

  const style = useMemo(
    () =>
      ({
        ...DropzoneStyles.baseStyle,
        ...(isDragActive ? DropzoneStyles.activeStyle : {}),
        ...(isDragAccept ? DropzoneStyles.acceptStyle : {}),
      } as React.CSSProperties),
    [isDragActive, isDragAccept]
  );

  const handleCleanFile = () => {
    formik.setFieldError(meta.FIELDS.contact_list_file.key, "");
    formik.setFieldError(meta.FIELDS.contact_list.key, "");
    formik.setFieldValue(meta.FIELDS.contact_list_file.key, "");
    formik.setFieldValue(meta.FIELDS.contact_list.key, []);
    setFile({
      source: undefined,
      preview: undefined,
    });
  };

  const [_, metaRecipientsFile] = useField(meta.FIELDS.contact_list_file.key);
  const errorRecipientsFile =
    metaRecipientsFile.touched && metaRecipientsFile.error;

  return (
    <>
      {file && file.source ? (
        <FieldContainer
          name={meta.FIELDS.contact_list_file.key}
          error={errorRecipientsFile}
        >
          <div className="flex items-stretch rounded-lg border border-gray-200 p-3 shadow-sm">
            <div className="grow self-center text-sm">
              {formik.values[meta.FIELDS.contact_list_file.key]}
            </div>
            <div className="self-center text-right">
              <button
                className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                type="button"
                onClick={handleCleanFile}
              >
                <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                {t("remove")}
              </button>
            </div>
          </div>
        </FieldContainer>
      ) : (
        <FieldContainer
          name={meta.FIELDS.contact_list_file.key}
          error={errorRecipientsFile}
        >
          <div {...getRootProps({ className: "dropzone", style: style })}>
            <input {...getInputProps()} />
            <p className="mb-0 flex items-center pb-0">
              <DocumentTextIcon className="mr-2 h-4 w-4" aria-hidden="true" />{" "}
              {t("csv-drop", "Click here to select a .csv or drag & drop it.")}
            </p>
          </div>
        </FieldContainer>
      )}

      <p>
        {formik.errors && formik.errors.contact_list_file && (
          <span className="mt-1.5 text-xs text-red-500">
            {formik.errors.contact_list_file}
          </span>
        )}
      </p>

      {formik.values[meta.FIELDS.contact_list.key] &&
        formik.values[meta.FIELDS.contact_list.key].length > 0 && (
          <FlowExecutionFormContactFileContactList formik={formik} />
        )}

      <div>
        <button
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          type="button"
          onClick={(_) => toggleShowHelp()}
        >
          <QuestionMarkCircleIcon className="mr-2 h-4 w-4" aria-hidden="true" />
          {t("flows:contact-file-help.not-sure", "Not sure what to upload?")}
        </button>
      </div>

      {showHelp && (
        <div className="mt-2 rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
          <div className="space-y-3 text-sm text-gray-500">
            <Trans i18nKey="flows:contact-file-help.info">
              <p>
                Make sure you upload a .csv file with a first row of headers,
                with at least one column with the value{" "}
                <code className="font-medium">phone</code>. Phones must be in{" "}
                <a
                  href="https://www.twilio.com/docs/glossary/what-e164"
                  target="_blank"
                  className="font-medium text-blue-500"
                  rel="noreferrer"
                >
                  E.164 format
                </a>
                .
              </p>
              <p>
                To set the contacts' first name, last name, email and external
                url, add columns with the following headers:{" "}
                <code className="font-medium text-indigo-500">first_name</code>,{" "}
                <code className="font-medium text-indigo-500">last_name</code>,{" "}
                <code className="font-medium text-indigo-500">email</code>,{" "}
                <code className="font-medium text-indigo-500">
                  external_url
                </code>
                . All other columns will be saved as additional attributes.
              </p>
              <div>
                <p>An example of how the recipients .csv file should look:</p>
                <div className="overflow-auto font-mono text-indigo-500">
                  <table>
                    <tbody>
                      <tr>
                        <td>phone,</td>
                        <td>first_name,</td>
                        <td>last_name,</td>
                        <td>email,</td>
                        {/* TODO: Try to find why the external_url is bold and centered */}
                        <th>external_url,</th>
                        <td>suscribed,</td>
                      </tr>
                      <tr>
                        <td>+525576565089,</td>
                        <td>Alfonso,</td>
                        <td>,</td>
                        <td>alfonso@hilos.com,</td>
                        <td>https://hilos.io/profile/alfonso,</td>
                        <td>true</td>
                      </tr>
                      <tr>
                        <td>+525576565060,</td>
                        <td>Ilyich,</td>
                        <td>Lenin,</td>
                        <td>,</td>
                        <td>https://hilos.io/profile/ilyich,</td>
                        <td>false</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Trans>
          </div>
        </div>
      )}
    </>
  );
}
