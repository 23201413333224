import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Table } from "@tanstack/react-table";
import { classNames } from "src/Helpers";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
}

const MAX_PAGES_TO_SHOW = 5;

export function DataTablePagination<TData>({
  table,
}: DataTablePaginationProps<TData>) {
  const [t] = useTranslation();
  const totalPages = table.getPageCount();
  const currentPageIndex = table.getState().pagination.pageIndex;
  const currentPage = currentPageIndex + 1;

  const currentAvailablePages = useMemo(() => {
    let startPage = Math.max(
      1,
      currentPage - Math.floor(MAX_PAGES_TO_SHOW / 2)
    );
    let endPage = Math.min(totalPages, startPage + MAX_PAGES_TO_SHOW - 1);

    if (endPage - startPage + 1 < MAX_PAGES_TO_SHOW) {
      startPage = Math.max(1, endPage - MAX_PAGES_TO_SHOW + 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  }, [totalPages, currentPage]);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {t("previous")}
        </button>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {t("next")}
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <p className="text-sm text-gray-700">
          <Trans
            i18nKey="showing-page-results"
            values={{
              showing: table.getRowModel().rows.length,
              count: table.getRowCount(),
            }}
            defaults="Showing <0>{{showing}}</0> of <0>{{count}}</0> results"
            components={[<strong className="font-medium" />]}
          />
        </p>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">{t("previous")}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {currentAvailablePages.map((page, index) => (
              <button
                key={page}
                onClick={() => table.setPageIndex(page - 1)}
                disabled={page === currentPage}
                className={classNames(
                  "relative items-center px-4 py-2 text-sm font-semibold focus:z-20",
                  page === currentPage
                    ? "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0",
                  Math.max(currentPage - page, page - currentPage) <= 1 ||
                    (currentPage === 1 && page === 3) ||
                    (currentPage === totalPages && totalPages - 2 === page)
                    ? "inline-flex"
                    : "hidden xl:inline-flex"
                )}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">{t("next")}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
