import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon, CheckCircleIcon } from "@heroicons/react/outline";
import axios from "axios";
import { StripeCustomerPortalData } from "@hilos/types/billing";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import {
  useStripeProductList,
  useStripeSubscription,
} from "src/hooks/useStripe";
import { formatPrice } from "src/Helpers";
import { API_ROUTES } from "src/router/router";
import { axiosErr } from "src/types/axios";
import SubscribedPlanDetailModal from "./SubscribedPlanDetailModal";
import SubscriptionCurrentPeriodDetail from "./SubscriptionCurrentPeriodDetail";
import SubscriptionNextPeriodDetail from "./SubscriptionNextPeriodDetail";
import SubscriptionStatusBadge from "./SubscriptionStatusBadge";

export default function SubscriptionV1ActivePlan() {
  const { t } = useTranslation();
  const { products } = useStripeProductList();
  const { subscription } = useStripeSubscription();
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = async (ev) => {
    ev.preventDefault();
    setBackendValidationErrors({});
    setBackendError("");
    setIsSubmitting(true);

    try {
      const response = await axios.get<StripeCustomerPortalData>(
        API_ROUTES.STRIPE_CUSTOMER_PORTAL
      );
      setIsSubmitting(false);
      if (!response.data.url) {
        setBackendError(
          t(
            "settings:billing.stripe-error",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
        return;
      }
      setSuccess(true);
      window.location.assign(response.data.url);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;

      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:billing.stripe-error",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setIsSubmitting(false);
      setSubmitted(true);
    }
  };

  if (!subscription) {
    return null;
  }

  // All subscriptions must have a plan product
  const subscription_item = subscription.items.find(
    (i) => !i.price.product.is_addon
  );
  if (!subscription_item) {
    return <></>;
  }
  const plan = products.find(
    (product) => product.id === subscription_item.price.product.id
  );
  if (!plan) {
    return <></>;
  }
  const addons = products.filter((product) => product.is_addon);

  return (
    <>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <Trans i18nKey="settings:current-subscription">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Your current subscription
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                You can see your past invoices, change your default payment
                method and update your subscription from here.
              </p>
            </Trans>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-4 bg-white px-4 py-5 sm:p-6">
              <div className="space-y-2 text-left">
                {subscription.items.length < 1 ? (
                  <>
                    <Trans i18nKey="settings:billing.subscription-active" s>
                      <h2
                        id="plan-heading"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Subscription active! Couldn't load subscription
                        information, please{" "}
                        <a
                          className="flex items-center"
                          href="https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20hilos."
                        >
                          {" "}
                          contact us{" "}
                        </a>{" "}
                        if this error persists.
                      </h2>
                    </Trans>
                  </>
                ) : (
                  <>
                    <h2
                      id="plan-heading"
                      className="flex items-center text-gray-900"
                    >
                      <CheckCircleIcon className="mr-2 h-5 w-5 text-green-500" />
                      {t(
                        "settings:billing.currently-subscribed-to",
                        "You're currently subscribed to"
                      )}
                      <span className="ml-1 text-lg font-medium leading-6">
                        {plan.name}
                      </span>
                      <SubscribedPlanDetailModal plan={plan} addons={addons} />
                    </h2>
                    <p className="flex items-center text-sm text-gray-500">
                      {t(
                        "settings:billing.current-subscription",
                        "Your subscription is currently"
                      )}
                      <span className="ml-1">
                        <SubscriptionStatusBadge status={subscription.status} />
                      </span>
                    </p>
                  </>
                )}
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="space-y-4">
                  {backendError ||
                    (backendValidationErrors && (
                      <APIErrors
                        APIError={backendError}
                        APIValidationErrors={backendValidationErrors}
                        fieldTranslations={{}}
                      />
                    ))}

                  <StateButton
                    isSubmitting={isSubmitting}
                    submitted={submitted}
                    success={success}
                    submittingText={t("submitting")}
                    successText={t("redirecting")}
                    initialText={
                      <>
                        {t(
                          "settings:billing.go-to-stripe",
                          "Go to your customer portal in Stripe"
                        )}
                        <ArrowSmRightIcon
                          className="ml-1 h-5 w-5"
                          aria-hidden="true"
                        />
                      </>
                    }
                  />
                </div>
              </form>
              {subscription.status !== "active" && (
                <div className="text-sm text-gray-500">
                  {subscription.last_invoice ? (
                    <div className="text-gray-900">
                      <Trans i18nKey="settings:billing.invoice-pending">
                        <p>
                          Your last invoice of{" "}
                          <span className="font-semibold">
                            {formatPrice(subscription.last_invoice.total / 100)}
                          </span>{" "}
                          {subscription.last_invoice.currency} is still pending.
                        </p>
                        <a
                          href={
                            subscription.last_invoice.stripe_invoice_url || "#"
                          }
                          className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-5 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                        >
                          Pay now <ArrowSmRightIcon className="ml-1 h-5 w-5" />
                        </a>
                      </Trans>
                    </div>
                  ) : (
                    t(
                      "settings:billing.invoice-pending-2",
                      "Your last invoice is still pending."
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 items-start gap-6 sm:grid-cols-2">
          <SubscriptionCurrentPeriodDetail subscription={subscription} />
          <SubscriptionNextPeriodDetail />
        </div>
      </div>
    </>
  );
}
