import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { AnnouncementCard } from "@hilos/types/private-schema/models/AnnouncementCard";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useAnnouncements() {
  const fetchAnnouncements = useCallback(async () => {
    const { data } = await axios.get<AnnouncementCard[]>(
      buildAPIRoute(API_ROUTES.ANNOUNCEMENT_LIST, {
        templates: true,
      })
    );
    return data;
  }, []);

  const { data, refetch, isLoading } = useQuery(
    "announcements",
    fetchAnnouncements
  );

  return { data, refetch, isLoading };
}

export default useAnnouncements;
