"use client";

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AlignJustify,
  Blocks,
  BookOpen,
  ChartColumnIncreasing,
  ClipboardList,
  Inbox,
  KeyRound,
  LifeBuoy,
  LucideProps,
  MessageCircleDashed,
  MessageCircleMore,
  Send,
  Settings,
  Share2,
  SquareActivity,
  SquareArrowOutUpRight,
  SquareMousePointer,
  Tag,
  User,
  Users,
  Webhook,
} from "lucide-react";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from "src/components/ui/sidebar";
import { buildRoute } from "src/router/router";
import { NavFooter } from "./NavFooter";
import { NavHeader } from "./NavHeader";
import { NavMain } from "./NavMain";
import { NavSecondary } from "./NavSecondary";

const FlowIcon = (props: Omit<LucideProps, "ref">) => (
  <Share2 className="rotate-90" {...props} />
);

export const navigation = [
  {
    // i18n.t("navigation:contacts.title", "Contacts")
    name: "navigation:contacts.title",
    url: buildRoute("contact-list"),
    icon: Users,
    permission: "view_contact",
  },
  {
    // i18n.t("navigation:templates.title", "WA Templates")
    name: "navigation:templates.title",
    url: buildRoute("whatsapp-template-list"),
    icon: ClipboardList,
  },
  {
    // i18n.t("navigation:inbox.title", "Inbox")
    name: "navigation:inbox.title",
    url: "/inbox",
    icon: Inbox,
    isMatchEnd: false,
    permission: "view_inboxcontact",
  },
  {
    // i18n.t("navigation:broadcasts.title", "Broadcasts")
    name: "navigation:broadcasts.title",
    url: buildRoute("broadcast-list"),
    icon: MessageCircleMore,
    permission: "view_messageblast",
  },
  {
    // i18n.t("navigation:flows.title", "Flows")
    name: "navigation:flows.title",
    url: buildRoute("flow-list"),
    icon: FlowIcon,
    permission: "view_flow",
  },
  {
    // i18n.t("navigation:reports.title", "Reports")
    name: "navigation:reports.title",
    url: buildRoute("reports"),
    icon: ChartColumnIncreasing,
    items: [
      {
        // i18n.t("navigation:reports.summary.title", "Overview")
        name: "navigation:reports.summary.title",
        url: buildRoute("reports", { ":type": "summary" }),
        icon: ChartColumnIncreasing,
      },
      {
        // i18n.t("navigation:reports.by-agent.title", "By Agent")
        name: "navigation:reports.by-agent.title",
        url: buildRoute("reports", { ":type": "by-agent" }),
        icon: User,
      },
      {
        // i18n.t("navigation:reports.by-tag.title", "By Tag")
        name: "navigation:reports.by-tag.title",
        url: buildRoute("reports", { ":type": "by-tag" }),
        icon: Tag,
      },
      {
        // i18n.t("navigation:reports.detail.title", "Details")
        name: "navigation:reports.detail.title",
        url: buildRoute("reports", { ":type": "detail" }),
        icon: AlignJustify,
      },
      {
        // i18n.t("navigation:reports.widget.title", "Widget")
        name: "navigation:reports.widget.title",
        url: buildRoute("reports", { ":type": "widget" }),
        icon: MessageCircleDashed,
      },
    ],
  },
  {
    // i18n.t("navigation:integrations.title", "Integrations")
    name: "navigation:integrations.title",
    url: "/dev/:type",
    icon: Blocks,
    isMatchEnd: false,
    items: [
      {
        // i18n.t("navigation:integrations.widget.title", "Whatsapp Button")}
        name: "navigation:integrations.widget.title",
        url: buildRoute("wa-widget"),
        icon: SquareMousePointer,
      },
      {
        // i18n.t("navigation:integrations.api-keys.title", "API Keys")
        name: "navigation:integrations.api-keys.title",
        url: buildRoute("api-keys"),
        icon: KeyRound,
      },
      {
        // i18n.t("navigation:integrations.external-apps.title", "External Apps")
        name: "navigation:integrations.external-apps.title",
        url: buildRoute("external-apps"),
        icon: SquareArrowOutUpRight,
      },
      {
        // i18n.t("navigation:integrations.webhooks.title", "Webooks")
        name: "navigation:integrations.webhooks.title",
        url: buildRoute("webhook-list"),
        icon: Webhook,
      },
      {
        // i18n.t("navigation:integrations.dev-monitoring.title", "Dev Monitoring")
        name: "navigation:integrations.dev-monitoring.title",
        url: buildRoute("dev-monitoring", { ":tab": "webhook-logs" }),
        icon: SquareActivity,
      },
      {
        // i18n.t("navigation:integrations.api-docs.title", "API Docs")
        name: "navigation:integrations.api-docs.title",
        url: "https://hilos.io/docs/apidocs",
        target: "_blank",
        icon: BookOpen,
      },
    ],
  },
  {
    // i18n.t("navigation:settings.title", "Settings")
    name: "navigation:settings.title",
    url: buildRoute("config-account"),
    icon: Settings,
    isMatchEnd: false,
    permission: "change_account",
  },
];

interface AppSidebarProps extends React.ComponentProps<typeof Sidebar> {
  onSwitchShowNotifications: () => void;
}

export function AppSidebar({
  onSwitchShowNotifications,
  ...props
}: AppSidebarProps) {
  const { i18n } = useTranslation();
  const { isMobile } = useSidebar();

  const help = useMemo(
    () => [
      {
        // i18n.t("navigation:support.getting-started", "Getting started")}
        name: "navigation:support.getting-started",
        url: "https://hilos.io/docs/user/getting-started/prerequisites",
        icon: LifeBuoy,
      },
      {
        // i18n.t("navigation:support.chat", "Chat with support")}
        name: "navigation:support.chat",
        url:
          i18n.language && i18n.language.toLowerCase().indexOf("es") === 0
            ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
            : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos.",
        icon: WhatsAppIcon,
      },
      {
        // i18n.t("navigation:support.feedback", "Feedback")}
        name: "navigation:support.feedback",
        url: "https://hilos.canny.io/feedback",
        icon: Send,
      },
    ],
    [i18n.language]
  );

  return (
    <Sidebar collapsible="icon" side={isMobile ? "right" : "left"} {...props}>
      <SidebarHeader>
        <NavHeader />
      </SidebarHeader>
      <SidebarContent>
        <NavMain
          items={navigation}
          onSwitchShowNotifications={onSwitchShowNotifications}
        />
        <NavSecondary items={help} className="mt-auto" />
      </SidebarContent>
      <SidebarFooter>
        <NavFooter />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
