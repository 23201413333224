import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { PatchedContactEdit } from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import Loading from "src/components/Loading";
import StateControlButton from "src/components/StateControlButton";
import useHilosStore from "src/hooks/useHilosStore";
import APIErrors from "../../../components/base/APIErrors";
import { API_ROUTES, buildAPIRoute } from "../../../router/router";
import { getTemplateVarsFromTemplate } from "../../wa/WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "../../wa/WhatsAppTemplatePreview";
import WhatsAppTemplatePreviewForm, {
  initialTemplatePreviewValues,
} from "../../wa/WhatsAppTemplatePreviewForm";

interface QuickReplyTemplateSendProps {
  contact: PatchedContactEdit;
  setSelectedTemplate: (t) => void;
  selectedTemplate: WhatsAppTemplate | undefined;
  loading: boolean;
  onCloseModal: () => void;
  onCancel?: () => void;
  variables: string[];
}

function QuickReplyTemplateSend({
  contact,
  setSelectedTemplate,
  selectedTemplate,
  loading,
  onCloseModal,
  onCancel,
  variables,
}: QuickReplyTemplateSendProps) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialTemplatePreviewValues);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const templateHasVars = useMemo(() => {
    if (!selectedTemplate) {
      return false;
    }
    return getTemplateVarsFromTemplate(selectedTemplate).length > 0;
  }, [selectedTemplate]);

  const sendTemplateMessage = async () => {
    const variableData = [
      formValues.headerURL,
      formValues.headerVar,
      ...formValues.bodyVars,
      ...formValues.buttonVars,
    ].filter((x) => x);
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.INBOX_WHATSAPP_TEMPLATE_SEND, {
          ":id": selectedTemplate?.id,
        }),
        { phone: contact.phone, variables: variableData }
      );
      handleCleanUp();
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      if (errorAxios?.response?.status === 400) {
        setBackendValidationErrors(errorAxios?.response?.data);
      } else {
        setBackendError(
          "An error occurred while sending your message. Please try again."
        );
      }
      // The exception needs to be thrown again for the StateControlButton
      // be able to detect that the action failed
      throw err;
    }
  };

  const handleCleanUp = useCallback(() => {
    setSelectedTemplate(undefined);
    setFormValues(initialTemplatePreviewValues);
    setBackendError("");
    setBackendValidationErrors({});
    onCloseModal();
  }, [onCloseModal, setSelectedTemplate]);

  const handleCancel = () => {
    onCloseModal();
    setBackendError("");
    setBackendValidationErrors({});
  };

  if (!selectedTemplate) {
    return <></>;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="my-4">
        <APIErrors
          APIError={backendError}
          APIValidationErrors={backendValidationErrors}
          fieldTranslations={{}}
        />
      </div>
      <div className="mt=4 text-center">
        {templateHasVars && (
          <p className="text-sm text-gray-500">
            {t(
              "inbox:template-with-variables",
              "This template has variables, set the values you want to use for this message."
            )}
          </p>
        )}
      </div>

      <div
        className={`mt-4 grid grid-cols-1 gap-6 ${
          templateHasVars ? "sm:grid-cols-2" : ""
        }`}
      >
        {templateHasVars && (
          <div>
            <WhatsAppTemplatePreviewForm
              key={selectedTemplate.id}
              template={selectedTemplate}
              onTemplateValuesChange={setFormValues}
              initialValues={
                variables ? variables : initialTemplatePreviewValues
              }
            />
          </div>
        )}
        <div>
          <WhatsAppTemplatePreview
            template={selectedTemplate}
            values={formValues}
          />
        </div>
      </div>

      <div className="mt-8 sm:mt-6 sm:flex sm:flex-row-reverse">
        <StateControlButton
          btnClasses="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={sendTemplateMessage}
          submittingText={t("sending", "Sending...")}
          successText={t("sent", "Sent")}
          initialText={
            <>
              {t("send", "Send")}
              <FontAwesomeIcon
                icon={faPaperPlane as IconProp}
                className="ml-1"
              />
            </>
          }
          onSuccess={handleCleanUp}
        />
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onCancel ? onCancel : handleCancel}
        >
          {t("cancel", "Cancel")}
        </button>
      </div>
    </>
  );
}

export default QuickReplyTemplateSend;
