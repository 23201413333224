// @flow
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BanIcon,
  TerminalIcon,
} from "@heroicons/react/outline";
import * as yup from "yup";
import { ERROR_MESSAGES } from "../../constants/Form";
import * as stepMeta from "./FlowStepMeta";

export const schema = yup.object().shape({
  name: yup.string().required(ERROR_MESSAGES.required),
  channel: yup.string(),
  execution_type: yup.string().required(ERROR_MESSAGES.required),
  flow_execution_variables: yup.array().of(yup.string()),
  steps: yup
    .array()
    .of(stepMeta.FlowStepBase.getSchema())
    .min(1, ERROR_MESSAGES.min),
});

export const FIELDS = {
  name: {
    key: "name",
    label: "Flow name",
    help: "",
    placeholder: "Flow #1",
  },
  channel: {
    key: "channel",
    label: "Channel to use for this flow",
    help: "",
    placeholder: "Select a channel",
  },
  execution_type: {
    key: "execution_type",
    label: "How will this flow start?",
    help: "",
  },
  flow_execution_variables: {
    key: "flow_execution_variables",
    label: "What execution variables will this flow have?",
    help: "The variables declared here will be available in the flow steps to use and you will provide them when creating a execution of this flow.",
  },
  steps: {
    key: "steps",
    label: "Steps",
  },
};

export const FLOW_EXECUTION_TYPES = [
  {
    label: "Inbound message",
    value: "INBOUND",
    icon: <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Triggers when we get a text containing a specific message from a customer.",
  },
  {
    label: "Outbound message",
    value: "OUTBOUND",
    icon: <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />,
    help: "Can be triggered with existing contacts, a .csv upload, or via our API.",
  },
];

export const FLOW_END_ACTIONS = [
  {
    label: "No action",
    value: "NO_ACTION",
    icon: <BanIcon className="h-5 w-5" aria-hidden="true" />,
    help: "No action; all answers will be available as a .csv download.",
  },
  {
    label: "Webhook",
    value: "WEBHOOK",
    icon: <TerminalIcon className="h-5 w-5" aria-hidden="true" />,
    help: "We'll send each users' answers as they finish the flow.",
  },
];
