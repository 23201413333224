import { ExclamationCircleIcon } from "@heroicons/react/outline";
import FieldContainer from "./FieldContainer";
import { classNames } from "../../Helpers";
import { useField } from "formik";
import useTouchField from "src/containers/flow/builder/hooks/useTouchField";

export interface TextInputFieldProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "action"> {
  name: string;
  type?: string;
  label?: string;
  help?: string | React.ReactElement;
  optional?: boolean;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  action?: React.ReactElement;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
  innerRef?: React.Ref<HTMLInputElement>;
}

function TextInputField({
  label,
  name,
  help,
  optional,
  className,
  containerClassName,
  disabled,
  action,
  icon: RenderIcon,
  innerRef,
  ...props
}: TextInputFieldProps) {
  const [field, meta, helpers] = useField({ name });
  const error = meta.touched && meta.error;

  useTouchField(helpers);

  return (
    <FieldContainer
      name={name}
      label={label}
      help={help}
      optional={optional}
      error={error}
    >
      <div className={classNames(label && "mt-1", "flex")}>
        <div
          className={classNames(
            "relative flex flex-grow items-stretch border border-gray-300 focus-within:z-10",
            "focus-within:border-indigo-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500",
            action ? "rounded-none rounded-l-md" : "rounded-md",
            containerClassName,
            !disabled &&
              error &&
              "border-red-500 focus-within:border-red-500 focus-within:ring-red-500",
            disabled && "border-gray-200 shadow-none"
          )}
        >
          {RenderIcon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <RenderIcon
                className={classNames(
                  "h-5 w-5",
                  disabled ? "text-gray-300" : "text-gray-500"
                )}
              />
            </div>
          )}
          <input
            id={name}
            className={classNames(
              "w-full border-none border-gray-300 placeholder-gray-400 sm:text-sm",
              "focus:border-0 focus:outline-none focus:ring-0 sm:text-sm",
              "disabled:bg-gray-50 disabled:text-gray-300 disabled:placeholder-gray-200",
              action ? "rounded-none rounded-l-md" : "rounded-md",
              RenderIcon && "pl-10",
              error && "pr-10",
              className
            )}
            ref={innerRef}
            disabled={disabled}
            {...field}
            value={field.value || ""}
            {...props}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 z-10 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {action}
      </div>
    </FieldContainer>
  );
}

export default TextInputField;
