import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useField, useFormikContext } from "formik";

export const TimePicker = ({
  label,
  prepend,
  appendIcon,
  appendButton,
  placeholder,
  formGroupClasses,
  inputClasses,
  hideInputError,
  hideError,
  help,
  optional,
  ...props
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const isInvalid = meta.touched && meta.error;
  const value = field.value;

  const onChange = (e) => {
    setFieldValue(field.name, e.target.value);
  };

  const timePicker = (
    <>
      {prepend && (
        <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
          {prepend}
        </div>
      )}
      <InputMask mask="99:99" value={value} onChange={onChange}>
        {(_) => (
          <input
            type="text"
            className={`${
              inputClasses
                ? inputClasses
                : "block w-full border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            } 
            ${appendButton ? "rounded-none rounded-l-md " : "rounded-md"}
            ${prepend ? "pl-10" : ""}
            ${appendIcon ? "pr-10" : ""}
            ${isInvalid && !hideInputError ? "border-red-500" : ""}`}
            id={props.name}
            placeholder={props.placeholder}
            name={props.name}
          />
        )}
      </InputMask>

      {!hideError && meta.error && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      )}
      {appendIcon && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {appendIcon}
        </div>
      )}
    </>
  );

  return (
    <div>
      {label && (
        <label
          htmlFor={props.name}
          className="mb-1 block text-sm font-medium text-gray-700"
        >
          {t(label, label)}{" "}
          {optional && (
            <>
              <span className="text-xs text-gray-500">(optional)</span>
            </>
          )}
        </label>
      )}
      {appendButton ? (
        <div
          className={`${
            formGroupClasses
              ? formGroupClasses
              : "relative flex rounded-md shadow-sm"
          }`}
        >
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            {timePicker}
          </div>
          {appendButton(isInvalid && !hideInputError)}
        </div>
      ) : (
        <div
          className={`${
            formGroupClasses
              ? formGroupClasses
              : "relative flex rounded-md shadow-sm"
          }`}
        >
          {timePicker}
        </div>
      )}
      {!hideError && meta.error && (
        <div className="mt-1.5 text-xs text-red-500">{meta.error}</div>
      )}
      {help && <div className=" mt-1.5 text-xs text-gray-500">{help}</div>}
    </div>
  );
};

export const DropzoneStyles = {
  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fff",
    color: "#12263F",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
};
