import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SwitchField from "src/components/Form/SwitchField";
import useFlowBuilderStore from "../hooks/useFlowBuilderStore";
import { useStepField } from "../hooks/useStepField";

interface FlowBuilderMsgFailProps {
  id: string;
  index: number;
}

function FlowBuilderMsgFail({ id, index }: FlowBuilderMsgFailProps) {
  const [t] = useTranslation();
  const hasPendingRefresh = useRef(false);
  const [nextStepFail] = useStepField({
    index,
    name: "next_step_failed",
  });
  const [hasMsgFailPath] = useStepField({
    index,
    name: "has_msg_fail_path",
  });
  const { onFlowRefresh } = useFlowBuilderStore();

  const handleChangeHasFailPath = useCallback(() => {
    hasPendingRefresh.current = true;
  }, []);

  useEffect(() => {
    if (hasPendingRefresh.current) {
      onFlowRefresh(id);
      hasPendingRefresh.current = false;
    }
  }, [hasMsgFailPath, id, onFlowRefresh]);

  return (
    <div className="border-t border-gray-200 pt-2 space-y-4">
      <SwitchField
        disabled={hasMsgFailPath && !!nextStepFail}
        name={`steps.${index}.has_msg_fail_path`}
        label={t(
          "flows:steps.has-msg-fail-path.label",
          "Do something if message fails to send?"
        )}
        help={t(
          "flows:steps.has-msg-fail-path.help",
          "Enable this to be able to set further steps if the message fails to send."
        )}
        onChange={handleChangeHasFailPath}
      />
    </div>
  );
}

export default FlowBuilderMsgFail;
