import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ChatIcon } from "@heroicons/react/outline";
import { WhatsAppMessage } from "@hilos/types/private-schema";
import MessageRender from "../../components/MessageRender";

interface MessageModalPreviewProps {
  previewElement: JSX.Element;
  message: WhatsAppMessage;
}

export default function MessageModalPreview({
  previewElement,
  message,
}: MessageModalPreviewProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-sky-100">
                    <ChatIcon
                      className="h-6 w-6 text-sky-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("message")} {message.id}
                    </Dialog.Title>
                    <div className="mt-4 space-y-4 px-2 text-left">
                      <div>
                        <MessageRender message={message} />
                      </div>

                      {message.status === "failed" && (
                        <>
                          <hr className="text-gray-200" />
                          <div className="text-sm text-gray-500">
                            <div className="text-tiny font-medium uppercase tracking-wider text-red-500">
                              {t("error-detail", "Error detail")}
                            </div>
                            {message.provider_error_message && (
                              <p className="mt-1">
                                {message.provider_error_message}
                              </p>
                            )}
                            <p className="mt-1">
                              {t("error")} {message.provider_error_code}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {t("close")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <button type="button" onClick={(_) => setOpen(true)}>
        {previewElement}
      </button>
    </>
  );
}
