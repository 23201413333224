import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./router/router";

import Conversation from "./containers/inbox/Conversation/Conversation";
import ConversationDetails from "./containers/inbox/Conversation/ConversationDetails";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import HilosProvider from "./HilosProvider";
import Inbox from "./containers/inbox/Inbox";
import NotFound from "./router/NotFound";
import PrivateRoute from "./router/PrivateRoute";
import ScheduledMessageList from "./containers/inbox/ScheduledMessageList";
import { ServiceWorkerConfig } from "./serviceWorkerRegistration";
import SupportWidget from "./components/SupportWidget/SupportWidget";
import { Toaster } from "sonner";
import WithoutConversation from "./containers/inbox/Conversation/WithoutConversation";

interface AppProps {
  config: ServiceWorkerConfig;
}

function App({ config }: AppProps) {
  // Whenever we load app.hilos.io, save every k,v for later attribution
  const searchParams = new URLSearchParams(window.location.search);
  const RELEVANT_PARAMS = [
    "utm_source",
    "utm_term",
    "utm_campaign",
    "utm_medium",
    "referrer",
  ];
  RELEVANT_PARAMS.forEach((param) => {
    if (searchParams.has(param)) {
      if (!localStorage.getItem(param)) {
        localStorage.setItem(param, searchParams.get(param) || "");
      }
    }
  });

  return (
    <BrowserRouter>
      <Toaster closeButton expand={false} position="bottom-right" />
      <HilosProvider config={config}>
        <Routes>
          <Route
            path="/inbox"
            element={
              <PrivateRoute>
                <ErrorBoundary type="page" key={"inbox"}>
                  <Inbox />
                </ErrorBoundary>
              </PrivateRoute>
            }
          >
            <Route
              path="scheduled-messages"
              element={<ScheduledMessageList />}
            />
            <Route path="conversation" element={<WithoutConversation />} />

            <Route path="conversation" element={<WithoutConversation />} />
            <Route
              path="conversation/:inboxContactId"
              element={<Conversation />}
            >
              <Route path="details" element={<ConversationDetails />} />
            </Route>
            <Route
              path="conversation/:inboxContactId/message/:messageId"
              element={<Conversation />}
            >
              <Route path="details" element={<ConversationDetails />} />
            </Route>
          </Route>

          {PRIVATE_ROUTES.filter(({ hideSidenav }) => !hideSidenav).map(
            (route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  <PrivateRoute routeName={route.name}>
                    <ErrorBoundary type="page" key={idx}>
                      {route.component}
                    </ErrorBoundary>
                  </PrivateRoute>
                }
              />
            )
          )}
          {PRIVATE_ROUTES.filter(({ hideSidenav }) => hideSidenav).map(
            (route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  <ErrorBoundary type="page" key={idx}>
                    {route.component}
                  </ErrorBoundary>
                }
              />
            )
          )}

          {PUBLIC_ROUTES.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <ErrorBoundary type="page" key={idx}>
                  {route.component}
                </ErrorBoundary>
              }
            />
          ))}

          <Route element={<NotFound />} />
        </Routes>
        <SupportWidget />
      </HilosProvider>
    </BrowserRouter>
  );
}

export default App;
