import {
  ArrowSmLeftIcon,
  UploadIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";

import { FormikProps } from "formik";
import HTTPForm from "../../components/HTTPForm/HTTPForm";
import StateButton from "../../components/StateButton";
import { getContactVariables } from "../../helpers/utils";
import { useCallback } from "react";
import { useCustomFields } from "src/hooks/useContactCustomField";

interface InboxActionFormHTTPFieldsProps {
  formik: FormikProps<any>;
  setCurrentStep: (s: string) => void;
  submitted: boolean;
  success: boolean;
}

export default function InboxActionFormHTTPFields({
  formik,
  setCurrentStep,
  submitted,
  success,
}: InboxActionFormHTTPFieldsProps) {
  const { contactCustomFields } = useCustomFields();

  const getAvailableVariables = useCallback(() => {
    const contactVars = getContactVariables(contactCustomFields);
    const formattedContactVars = contactVars.map((contactVar) => ({
      label: `contact.${contactVar}`,
      value: `{{contact.${contactVar}}}`,
    }));

    const groupedVars = [
      {
        label: "Contact Variables",
        icon: UserCircleIcon,
        options: formattedContactVars,
      },
    ];

    return groupedVars;
  }, [contactCustomFields]);

  return (
    <div>
      <div className="row mt-10 text-center">
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          Step 2 of 2
        </h6>
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          Customize your HTTP Action
        </h1>
        <p className="mb-5 text-sm text-gray-500">
          Specify what the button will do when pressed.
        </p>
      </div>

      <div className="mt-10 grid grid-cols-3 grid-rows-1 gap-6 sm:grid-cols-1">
        <div className="col-span-2">
          <h6 className="text-tiny uppercase tracking-wider text-gray-500">
            Action detail
          </h6>

          <div className="mt-5 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <HTTPForm
                  formik={formik}
                  formPrefix="request"
                  formValues={formik.values.request}
                  availableVariables={getAvailableVariables()}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h6 className="text-tiny uppercase tracking-wider text-gray-500">
            Test your action
          </h6>
          <div className="mt-2 overflow-hidden">Request</div>
        </div>
      </div>

      <div className="mx-auto my-14 max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <hr className="my-5" />
          <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
            <div className="justify-self-start">
              <button
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-medium leading-4 text-gray-900 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-500"
                type="button"
                onClick={(_) => setCurrentStep("attrs")}
              >
                <ArrowSmLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Details
              </button>
            </div>
            <div className="col text-center">
              <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
                Step 2 of 2
              </h6>
            </div>
            <div className="justify-self-end">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                submittingText="Submitting..."
                successText="Submitted!"
                initialText={
                  <>
                    <UploadIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    Submit
                  </>
                }
                btnClasses="inline-flex items-center px-4 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                btnClassesSuccess="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-3 text-sm font-medium leading-4 text-green-800 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                btnClassesError="inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-3 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
