import { ConversationStatusEnum } from "@hilos/types/private-schema";
import { getTranslationPayload as t } from "src/i18n";

interface OperatorValue {
  label: string;
  value: string;
}

export interface ComparisonOperators {
  _eq?: OperatorValue;
  _neq?: OperatorValue;
  _is_null?: OperatorValue;
  _gte?: OperatorValue;
  _gt?: OperatorValue;
  _lte?: OperatorValue;
  _lt?: OperatorValue;
  _in?: OperatorValue;
}

interface Filter {
  label: string;
  operators: ComparisonOperators;
}

export interface AvailableFilterFields {
  assigned: Filter;
  status: Filter;
  is_unread: Filter;
  is_archived: Filter;
  last_message_on: Filter;
  contact_tags: Filter;
  last_message_status: Filter;
  last_conversation_tags: Filter;
  last_message_is_outbound: Filter;
  last_outbound_message_was_read: Filter;
  assigned_to_current_user: Filter;
  channel: Filter;
}

export interface ConversationsFilter {
  field: keyof AvailableFilterFields;
  operator: keyof ComparisonOperators;
  value: string | boolean | number | Date | number[];
}

export const AVAILABLE_FILTER_FIELDS: AvailableFilterFields = {
  channel: {
    // i18n.t("inbox:labels.channel", "Channel")
    label: "inbox:labels.channel",
    operators: {
      _in: {
        // i18n.t("is-one-of", "is one of")
        label: "is one of",
        value: "object",
      },
    },
  },
  status: {
    // i18n.t("inbox:labels.status", "Status")
    label: "inbox:labels.status",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "text",
      },
      _neq: {
        // i18n.t("is-not", "is not")
        label: "is-not",
        value: "text",
      },
    },
  },
  assigned: {
    // i18n.t("inbox:labels.assigned", "Assigned to")
    label: "inbox:labels.assigned",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "object",
      },
      _neq: {
        // i18n.t("is-not", "is not")
        label: "is-not",
        value: "object",
      },
      _is_null: {
        // i18n.t("inbox:labels.has-someone-assigned", "has someone assigned")
        label: "inbox:labels.has-someone-assigned",
        value: "bool",
      },
    },
  },
  assigned_to_current_user: {
    // i18n.t("inbox:labels.assigned-to-me", "Assigned to me")
    label: "inbox:labels.assigned-to-me",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "bool",
      },
    },
  },
  contact_tags: {
    // i18n.t("inbox:labels.contact-tags", "Contact tags")
    label: "inbox:labels.contact-tags",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "object",
      },
      _neq: {
        // i18n.t("is-not", "is not")
        label: "is-not",
        value: "object",
      },
      _is_null: {
        // i18n.t("inbox:labels.has-any-tags", "has any tags")
        label: "inbox:labels.has-any-tags",
        value: "bool",
      },
    },
  },
  last_conversation_tags: {
    // i18n.t("inbox:labels.conversation-tags", "Conversation tags")
    label: "inbox:labels.conversation-tags",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "object",
      },
      _neq: {
        // i18n.t("is-not", "is not")
        label: "is-not",
        value: "object",
      },
      _is_null: {
        // i18n.t("inbox:labels.has-any-tags", "has any tags")
        label: "inbox:labels.has-any-tags",
        value: "bool",
      },
    },
  },
  last_message_status: {
    // i18n.t("inbox:labels.last-message-status", "Last message status")
    label: "inbox:labels.last-message-status",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "text",
      },
      _neq: {
        // i18n.t("is-not", "is not")
        label: "is-not",
        value: "text",
      },
    },
  },
  is_unread: {
    // i18n.t("unread", "Unread")
    label: "unread",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "bool",
      },
    },
  },
  is_archived: {
    // i18n.t("inbox:labels.is-archived", "Is Archived?")
    label: "inbox:labels.is-archived",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "bool",
      },
    },
  },
  last_message_on: {
    // i18n.t("inbox:labels.time-of-last-message", "Time of last message")
    label: "inbox:labels.time-of-last-message",
    operators: {
      _gte: {
        // i18n.t("inbox:labels.on-this-date-or-later", "on this date or later")
        label: "inbox:labels.on-this-date-or-later",
        value: "datetime",
      },
      _gt: {
        // i18n.t("inbox:labels.later-than-this-date", "later than this date")
        label: "inbox:labels.later-than-this-date",
        value: "datetime",
      },
      _lte: {
        // i18n.t("inbox:labels.on-this-date-or-before", "on this date or before")
        label: "inbox:labels.on-this-date-or-before",
        value: "datetime",
      },
      _lt: {
        // i18n.t("inbox:labels.before-this-date", "before this date")
        label: "inbox:labels.before-this-date",
        value: "datetime",
      },
    },
  },
  last_message_is_outbound: {
    // i18n.t("inbox:labels.contact-last-message", "Contact's last message was answered?")
    label: "inbox:labels.contact-last-message",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "bool",
      },
    },
  },
  last_outbound_message_was_read: {
    // i18n.t("inbox:labels.last-message-read-by-contact", "Last message read by contact?")
    label: "inbox:labels.last-message-read-by-contact",
    operators: {
      _eq: {
        // i18n.t("is", "is")
        label: "is",
        value: "bool",
      },
    },
  },
};

export const FILTER_FIELDS_WITHOUT_COMPARISONS = [
  "is_unread",
  "is_archived",
  "assigned_to_current_user",
  "last_message_is_outbound",
  "last_outbound_message_was_read",
];

export const AVAILABLE_ORDERING_FIELDS = [
  {
    value: "-last_message_on",
    // i18n.t("inbox:labels.newest-message-first", "Newest message first")
    label: "inbox:labels.newest-message-first",
  },
  {
    value: "last_message_on",
    // i18n.t("inbox:labels.oldest-message-first", "Oldest message first")
    label: "inbox:labels.oldest-message-first",
  },
];

export interface ConversationStatusInfo {
  next_steps: ConversationStatusEnum[];
  label: string[];
}

export type ConversationsStatusInfo = {
  [key in ConversationStatusEnum]: ConversationStatusInfo;
};

export const CONVERSATION_STATUS: ConversationsStatusInfo = {
  // NOT_CONTACTED: {
  //   next_steps: [ConversationStatus.NEW],
  //   // i18n.t("inbox:labels.not-contacted", "Not contacted")
  //   label: "inbox:labels.not-contacted",
  // },
  CREATED: {
    next_steps: ['NEW'],
    label: t("inbox:labels.not-contacted", "Not contacted"),
  },
  FLOW: {
    next_steps: [],
    label: t("inbox:labels.in-flow", "In Flow"),
  },
  NEW: {
    next_steps: ['IN_PROGRESS', 'CLOSED'],
    label: t("new", "New"),
  },
  IN_PROGRESS: {
    next_steps: ['NEW', 'CLOSED'],
    label: t("in-progress", "In Progress"),
  },
  RESOLVED: {
    next_steps: [],
    label: t("resolved", "Resolved"),
  },
  CLOSED: {
    next_steps: ['NEW', 'IN_PROGRESS'],
    label: t("closed", "Closed"),
  },
};

export const CONVERSATION_STATUS_OPTIONS = Object.entries(
  CONVERSATION_STATUS
).map(([status, { label }]) => ({
  label,
  value: status,
}));

export const MESSAGE_STATUS_OPTIONS = [
  {
    label: t("inbox:labels.sent", "Sent"),
    value: "sent",
  },
  {
    label: t("inbox:labels.delivered", "Delivered"),
    value: "delivered",
  },
  {
    label: t("inbox:labels.received", "Received"),
    value: "received",
  },
  {
    label: t("inbox:labels.read", "Read"),
    value: "read",
  },
  {
    label: t("inbox:labels.undelivered", "Undelivered"),
    value: "undelivered",
  },
  {
    label: t("inbox:labels.failed", "Failed"),
    value: "failed",
  },
  {
    label: t("inbox:labels.deleted", "Deleted"),
    value: "deleted",
  },
];

export function getConversationStatus(
  status: ConversationStatusEnum,
  has_measure_customer_satisfaction_per_conversation: boolean | null = false
) {
  let CONVERSATION_STATUS: ConversationsStatusInfo = {
    // NOT_CONTACTED: {
    //   next_steps: [ConversationStatus.NEW],
    //   // i18n.t("inbox:labels.not-contacted", "Not contacted")
    //   label: "inbox:labels.not-contacted",
    // },
    CREATED: {
      next_steps: ['NEW'],
      label: t("inbox:labels.not-contacted", "Not contacted"),
    },
    FLOW: {
      next_steps: [],
      label: t("inbox:labels.in-flow", "In Flow"),
    },
    NEW: {
      next_steps: [
        'IN_PROGRESS',
        has_measure_customer_satisfaction_per_conversation
          ? 'RESOLVED'
          : 'CLOSED',
      ],
      label: t("new", "New"),
    },
    IN_PROGRESS: {
      next_steps: [
        'NEW',
        has_measure_customer_satisfaction_per_conversation
          ? 'RESOLVED'
          : 'CLOSED',
      ],
      label: t("in-progress", "In Progress"),
    },
    RESOLVED: {
      next_steps: [],
      label: t("resolved", "Resolved"),
    },
    CLOSED: {
      next_steps: ['NEW', 'IN_PROGRESS'],
      label: t("closed", "Closed"),
    },
  };

  return CONVERSATION_STATUS[status];
}
