import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { TimeoutMeta } from "src/containers/flow/Helpers";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import useFlowBuilderStore from "../hooks/useFlowBuilderStore";
import { useStepField } from "../hooks/useStepField";
import FlowBuilderTimeWindows from "./FlowBuilderTimeWindows";

interface FlowBuilderWaitUntilProps {
  id: string;
  index: number;
}

function FlowBuilderWaitUntil({ id, index }: FlowBuilderWaitUntilProps) {
  const [t] = useTranslation();
  const hasPendingRefresh = useRef(false);
  const { setFieldValue } = useFormikContext();
  const [nextStepAlternate] = useStepField({
    index,
    name: "next_step_alternate",
  });
  const [hasMaxWaitTime] = useStepField({
    index,
    name: "has_max_wait_time",
  });
  const [maxWaitTimeUnit] = useStepField({
    index,
    name: "max_wait_time_unit",
  });
  const [maxWaitTimeAmount] = useStepField({
    index,
    name: "max_wait_time_amount",
  });
  const { onFlowRefresh } = useFlowBuilderStore();

  const handleChangeHasDelay = useCallback(
    (nextHasDelay: boolean) => {
      setFieldValue(
        `steps.${index}.max_wait_time_amount`,
        nextHasDelay ? "24" : null,
        false
      );
      setFieldValue(
        `steps.${index}.max_wait_time_unit`,
        nextHasDelay ? "HOUR" : null,
        false
      );
      hasPendingRefresh.current = true;
    },
    [index, setFieldValue]
  );

  const showNotice = useMemo(() => {
    switch (maxWaitTimeUnit) {
      case "MINUTE":
        return maxWaitTimeAmount >= 60 * 24;
      case "HOUR":
        return maxWaitTimeAmount >= 24;
      case "DAY":
        return maxWaitTimeAmount >= 1;
    }
    return false;
  }, [maxWaitTimeUnit, maxWaitTimeAmount]);

  useEffect(() => {
    if (hasPendingRefresh.current) {
      onFlowRefresh(id);
      hasPendingRefresh.current = false;
    }
  }, [hasMaxWaitTime, id, onFlowRefresh]);

  return (
    <div className="border-t border-gray-200 pt-2 space-y-4">
      <SwitchField
        disabled={hasMaxWaitTime && !!nextStepAlternate}
        name={`steps.${index}.has_max_wait_time`}
        label={t("flows:steps.has-max-wait-time.label", "Set max wait time?")}
        help={t(
          "flows:steps.has-max-wait-time.help",
          "Set a maximum time to wait for a response before continuing."
        )}
        onChange={handleChangeHasDelay}
      />

      {hasMaxWaitTime && (
        <div className="space-y-2">
          <div>
            <p className="mb-1 block text-sm font-medium text-gray-700">
              {t(
                "flows:steps.max-wait-time-amount.label",
                "Wait for a response for"
              )}
            </p>
            <div className="grid grid-cols-3 items-center">
              <div className="col-span-2 w-full">
                <TextInputField
                  name={`steps.${index}.max_wait_time_amount`}
                  placeholder="24"
                  type="number"
                />
              </div>
              <div className="ml-2 w-full">
                <SelectorField
                  name={`steps.${index}.max_wait_time_unit`}
                  options={TimeoutMeta.MAX_WAIT_TIME_OPTIONS.map((option) => ({
                    label: t(option.label),
                    value: option.value,
                  }))}
                />
              </div>
            </div>
          </div>

          <FlowBuilderTimeWindows index={index} />

          {showNotice && (
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-yellow-700">
                    <p>
                      {t(
                        "flows:steps.delay.notice",
                        "Since you're waiting more than 24 hours, use a TEMPLATE step to continue the conversation."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FlowBuilderWaitUntil;
