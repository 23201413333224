import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  CheckCircleIcon,
  DuplicateIcon,
  ExclamationCircleIcon,
  PencilAltIcon,
  RefreshIcon,
  ReplyIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { DateTime } from "luxon";
import DropdownMenu from "src/components/DropdownMenu";
import PermissionsChecker from "src/components/PermissionsCheck";
import RobotIcon from "src/components/icons/RobotIcon";
import { classNames, userToString } from "src/Helpers";
import { SessionData } from "src/types/hilos";
import { FlowListRead } from "src/types/private-schema";
import { buildRoute } from "../../router/router";
import ChannelBadge from "../config/channel/ChannelBadge";
import FlowTriggerBadge from "./FlowTriggerBadge";

interface FlowListTableRowProps {
  flow: FlowListRead;
  session: SessionData | null;
  discardUnpublishedChanges: (flow: FlowListRead) => Promise<void>;
  duplicateFlow: (flowId: string) => Promise<void>;
  upgradeFlow: (flowId: string) => Promise<boolean>;
  handleDeleteAction: (flow: FlowListRead) => void;
  handleManageTemplateAction: (flow: FlowListRead) => void;
}

export default function FlowListTableRow({
  flow,
  session,
  discardUnpublishedChanges,
  duplicateFlow,
  upgradeFlow,
  handleDeleteAction,
  handleManageTemplateAction,
}: FlowListTableRowProps) {
  const { t } = useTranslation();
  const [failedToUpgrade, setFailedToUpgrade] = useState<boolean>(false);

  const handleUpgradeFlow = useCallback(async () => {
    const isUpgraded = await upgradeFlow(flow.id);
    if (!isUpgraded) {
      setFailedToUpgrade(true);
    }
  }, [flow.id, upgradeFlow]);

  const flowHasBeenPublished =
    flow.status === "PUBLISHED" || flow.current_version;

  return (
    <>
      <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900 justify-between">
        <div className="flex flex-row w-full">
          {flow.is_legacy && (
            <span className="px-2 rounded-lg mr-2 text-xs py-1 flex bg-slate-100 text-slate-500">
              <RefreshIcon className="w-3 h-3 self-center mr-1" />
              {t("flows:outdated-flow", "Outdated flow")}
            </span>
          )}
          {flow.is_template && (
            <span className="px-2 rounded-lg mr-2 text-xs py-1 flex bg-slate-100 text-slate-500">
              Template
            </span>
          )}
          <Link
            className="font-medium flex items-center text-blue-600"
            to={buildRoute("flow-detail", {
              ":id": flow.id,
            })}
          >
            {flow.trigger_type === "INBOUND_ANY_MESSAGE" && (
              <span className="px-2 rounded-lg mr-2 text-xs py-1 flex bg-slate-100 text-slate-500">
                <RobotIcon className="w-3 h-3 self-center mr-1" />
                Chatbot
              </span>
            )}
            {flow.name}
          </Link>
        </div>
        <div>
          <time className="text-gray-500" dateTime="">
            Created on{" "}
            {DateTime.fromISO(flow.created_on as string).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          </time>
        </div>
      </td>
      <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
        {userToString(flow.created_by)}
      </td>
      <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
        <FlowTriggerBadge triggerType={flow.trigger_type} />
      </td>
      {session?.account.has_used_multiple_channels && (
        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
          <ChannelBadge channel={flow.channel} />
        </td>
      )}
      <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
        {flow.is_active ? (
          <CheckCircleIcon className="h-5 w-5 text-green-500" />
        ) : (
          <XCircleIcon className="h-5 w-5 text-red-500" />
        )}
      </td>
      <td className="px-4 py-3 text-sm text-gray-600 sticky right-10 bg-white whitespace-nowrap">
        <div className="flex row justify-between">
          {Boolean(flow.upgrade_version && !flow.legacy_version) ? (
            <div className="flex flex-col items-start text-right">
              <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-xs font-semibold uppercase text-blue-800">
                {t("flows:upgrading-legacy-flow", "Upgrading")}
              </span>
              <div className="mt-1 flex items-center text-xs text-gray-500">
                <ExclamationCircleIcon className="mr-1 h-4 w-4" />
                {t(
                  "flows:unpublished-changes-with-flow-upgrade",
                  "Upgrade to be published"
                )}{" "}
                -
                <button
                  type="button"
                  className="ml-1 text-blue-500 font-medium"
                  onClick={(_) => discardUnpublishedChanges(flow)}
                >
                  {t("flows:unpublished-changes-discard", "Discard")}
                </button>
              </div>
            </div>
          ) : (
            <>
              {flowHasBeenPublished ? (
                <div className="flex flex-col items-start">
                  <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-semibold uppercase text-green-800">
                    {t("flows:published", "Published")}
                  </span>
                  {session?.account.feature_flags.includes("upgrade_flow_v2") &&
                  failedToUpgrade ? (
                    <Link
                      className="ml-1 mt-1 text-xs text-red-500 font-medium"
                      to={buildRoute("flow-update", { ":id": flow.id })}
                    >
                      {t(
                        "flows:failed-to-upgrade-flow",
                        "Errors were found in the flow, fix them before upgrading."
                      )}
                    </Link>
                  ) : (
                    flow.is_legacy && (
                      <button
                        className="ml-1 mt-1 text-xs text-blue-500 font-medium"
                        onClick={handleUpgradeFlow}
                      >
                        {t(
                          "flows:upgrade-to-new-flow-builder",
                          "Upgrade this flow to the new flow builder"
                        )}
                      </button>
                    )
                  )}
                  {flow.status === "DRAFT" && (
                    <div className="mt-1 flex items-center text-xs text-gray-500">
                      <ExclamationCircleIcon className="mr-1 h-4 w-4" />
                      {t("flows:unpublished-changes", "Unpublished changes")} -
                      <button
                        type="button"
                        className="ml-1 text-blue-500 font-medium"
                        onClick={(_) => discardUnpublishedChanges(flow)}
                      >
                        {t("flows:unpublished-changes-discard", "Discard")}
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-start text-left">
                  <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-semibold uppercase text-indigo-800">
                    {t("flows:status.draft", "Draft")}
                  </span>
                  <div className="mt-1 flex items-center text-xs text-gray-500">
                    <ExclamationCircleIcon className="mr-1 h-4 w-4" />
                    {t("flows:unpublished-changes", "Unpublished changes")} -
                    <button
                      type="button"
                      className="ml-1 text-blue-500 font-medium"
                      onClick={(_) => discardUnpublishedChanges(flow)}
                    >
                      {t("flows:unpublished-changes-discard", "Discard")}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </td>
      <td className="px-4 py-3 text-sm text-gray-900 w-10 sticky right-0 bg-white">
        <DropdownMenu
          menuItems={[
            (active) => (
              <PermissionsChecker
                permission="change_flow"
                notice={
                  <button
                    className="text-gray-400 line-through group flex w-full items-center px-4 py-2 text-sm"
                    type="button"
                    disabled
                  >
                    <PencilAltIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("update", "Update")}
                  </button>
                }
              >
                <Link
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "flex w-full items-center px-4 py-2 text-sm"
                  )}
                  to={buildRoute(
                    flow.is_legacy && !flow.upgrade_version
                      ? "flow-update"
                      : "flow-editor",
                    {
                      ":id": flow.id,
                    }
                  )}
                >
                  <PencilAltIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {t("update", "Update")}
                </Link>
              </PermissionsChecker>
            ),
            (active) =>
              flow.needs_update ? null : (
                <PermissionsChecker
                  permission="add_flow"
                  notice={
                    <button
                      className="text-gray-400 line-through group flex w-full items-center px-4 py-2 text-sm"
                      type="button"
                      disabled
                    >
                      <DuplicateIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t("flows:duplicate-flow", "Duplicate flow")}
                    </button>
                  }
                >
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex w-full items-center px-4 py-2 text-sm"
                    )}
                    type="button"
                    onClick={(_) => duplicateFlow(flow.id)}
                  >
                    <DuplicateIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("flows:duplicate-flow", "Duplicate flow")}
                  </button>
                </PermissionsChecker>
              ),
            (active) =>
              flow.needs_update ? null : (
                <PermissionsChecker
                  permission="change_flow"
                  notice={
                    <button
                      className="text-gray-400 line-through group flex w-full items-center px-4 py-2 text-sm"
                      type="button"
                      disabled
                    >
                      <ReplyIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t(
                        "flows:unpublished-changes-discard-button",
                        "Discard draft"
                      )}
                    </button>
                  }
                >
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex w-full items-center px-4 py-2 text-sm"
                    )}
                    type="button"
                    onClick={(_) => discardUnpublishedChanges(flow)}
                  >
                    <ReplyIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t(
                      "flows:unpublished-changes-discard-button",
                      "Discard draft"
                    )}
                  </button>
                </PermissionsChecker>
              ),
            (active) => (
              <PermissionsChecker
                permission="delete_flow"
                notice={
                  <button
                    className="text-gray-400 line-through group flex w-full items-center px-4 py-2 text-sm"
                    type="button"
                    disabled
                  >
                    <TrashIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("delete", "Delete")}
                  </button>
                }
              >
                <button
                  className={classNames(
                    active ? "bg-red-100 text-red-800" : "text-gray-700",
                    "group flex w-full items-center px-4 py-2 text-sm"
                  )}
                  type="button"
                  onClick={() => handleDeleteAction(flow)}
                >
                  <TrashIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {t("delete", "Delete")}
                </button>
              </PermissionsChecker>
            ),
            (active) => {
              session?.account.feature_flags.includes("add-flow-templates") && (
                <button
                  className={classNames(
                    active ? "bg-neutral-dark" : "",
                    "group flex w-full items-center bg-neutral px-4 py-2 text-sm font-semibold text-white"
                  )}
                  type="button"
                  onClick={() => handleManageTemplateAction(flow)}
                >
                  {t("flows:manage-template", "Manage Template")}
                </button>
              );
            },
          ]}
        />
      </td>
    </>
  );
}
