import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepAnswerSchema } from "../flow";

export const FlowStepQuestionSchema = (t: TFunction) =>
  FlowStepAnswerSchema(t).shape({
    missing_options_message: yup
      .string()
      .nullable()
      .when("has_options_from_variable", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    answer_type: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    answer_validation_message: yup
      .string()
      .nullable()
      .when("answer_type", {
        is: (value) => value !== "ANY",
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    answer_options: yup
      .array()
      .nullable()
      .when(["answer_type", "has_options_from_variable"], {
        is: (answerType, hasOptionsFromVariable) =>
          answerType === "SINGLE_OPTION" && !hasOptionsFromVariable,
        then: (schema) =>
          schema
            .of(yup.string().required(t(ERROR_MESSAGES.REQUIRED)))
            .min(1, t(ERROR_MESSAGES.MIN_NUMBER)),
      })
      .when(["answer_type", "has_options_from_variable"], {
        is: (answerType, hasOptionsFromVariable) =>
          answerType === "BOOL" && !hasOptionsFromVariable,
        then: (schema) =>
          schema
            .of(yup.string().required(t(ERROR_MESSAGES.REQUIRED)))
            .min(2, t(ERROR_MESSAGES.REQUIRED_OPTION_BOOL))
            .max(2, t(ERROR_MESSAGES.REQUIRED_OPTION_BOOL)),
      }),
    has_options_from_variable: yup.bool(),
    options_from_variable: yup
      .string()
      .nullable()
      .when("has_options_from_variable", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    option_from_variable_value: yup
      .string()
      .nullable()
      .when("has_options_from_variable", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    option_from_variable_title: yup
      .string()
      .nullable()
      .when("has_options_from_variable", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    group_answers_from_multiple_texts: yup.bool(),
    minutes_to_wait_for_more_messages: yup
      .number()
      .when("group_answers_from_multiple_texts", {
        is: true,
        then: (schema) =>
          schema
            .min(1, t(ERROR_MESSAGES.MIN_NUMBER_VALUE))
            .required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    body: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    answer_allow_decimals: yup.bool(),
    answer_phone_default_country: yup.string(),
    answer_has_range: yup.bool(),
    answer_range_min: yup
      .string()
      .nullable()
      .when("answer_has_range", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    answer_range_max: yup
      .string()
      .nullable()
      .when("answer_has_range", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    answer_options_render: yup.string().when("answer_type", {
      is: "SINGLE_OPTION",
      then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
    }),
    answer_options_render_list_button_title: yup
      .string()
      .nullable()
      .when("answer_options_render", {
        is: "LIST",
        then: (schema) =>
          schema
            .max(20, t(ERROR_MESSAGES.MAX_CHARACTERS))
            .required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    save_answer_in_contact_meta: yup.bool(),
    answer_contact_meta_property: yup
      .string()
      .nullable()
      .max(50, t(ERROR_MESSAGES.MAX_CHARACTERS))
      .when("save_answer_in_contact_meta", {
        is: true,
        then: (schema) =>
          schema
            .max(50, t(ERROR_MESSAGES.MAX_CHARACTERS))
            .required(t(ERROR_MESSAGES.REQUIRED)),
      }),
  });

export const FlowStepQuestionFormat = (step: FlowStepData) => {
  const nextStep = { ...step };

  nextStep.answer_options = nextStep.answer_options?.filter(
    (option) => option.length > 0
  );

  if (!nextStep.has_max_wait_time) {
    delete nextStep.max_wait_time_amount;
    delete nextStep.max_wait_time_unit;
  }

  if (!nextStep.has_max_answer_attempts) {
    delete nextStep.max_answer_attempts;
    nextStep.answer_failed_next_step = null;
  }

  return nextStep;
};
