import { useTranslation } from "react-i18next";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import { useStepField } from "../../../hooks/useStepField";

interface FlowBuilderStepQuestionNumberProps {
  index: number;
}

function FlowBuilderStepQuestionNumber({
  index,
}: FlowBuilderStepQuestionNumberProps) {
  const [t] = useTranslation();
  const [answerHasRange] = useStepField({
    index,
    name: "answer_has_range",
  });

  return (
    <>
      <div className="space-y-2">
        <SwitchField
          name={`steps.${index}.answer_allow_decimals`}
          label={t(
            "flows:steps.question.answer-allow-decimals.label",
            "Allow decimals in answer?"
          )}
        />
        <SwitchField
          name={`steps.${index}.answer_has_range`}
          label={t(
            "flows:steps.question.answer-has-range.label",
            "Limit answers to values in a specific range?"
          )}
        />
      </div>
      {answerHasRange && (
        <div className="grid grid-cols-2 items-start space-x-4">
          <div>
            <TextInputField
              name={`steps.${index}.answer_range_min`}
              label={t(
                "flows:steps.question.answer-range-min.label",
                "Minimum value expected (included)"
              )}
              type="number"
              placeholder="0"
            />
          </div>
          <div>
            <TextInputField
              name={`steps.${index}.answer_range_max`}
              label={t(
                "flows:steps.question.answer-range-max.label",
                "Maximum value expected (included)"
              )}
              type="number"
              placeholder="100"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default FlowBuilderStepQuestionNumber;
