import { Trans } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

export default function ReportsDocsNotice() {
  return (
    <div className="rounded-lg border-blue-400 bg-blue-50 p-4 mb-3">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-2">
          <p className="text-sm text-blue-700">
            <Trans i18nKey="reports:documentation-link">
              Want to know how to best use these reports? Head over to our{" "}
              <a
                href="https://hilos.io/docs/user/using-hilos/reports/inbox-reports"
                target="_blank"
                rel="noreferrer"
                className="font-medium"
              >
                docs to learn more
              </a>
              .
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
}
