import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MESSAGE_BODY_TYPES } from "../../../constants/steps/message";
import FlowBuilderMedia from "../../FlowBuilderMedia";
import TextAreaFieldWithVariables from "../../TextAreaFieldWithVariables";

interface FlowBuilderStepMessageBodyProps {
  index: number;
  bodyType: string;
  bodyFileType?: "URL" | "UPLOAD";
  setBodyFileType: (bodyFileType: string, validate: boolean) => void;
}

function FlowBuilderStepMessageBody({
  index,
  bodyType,
  bodyFileType,
  setBodyFileType,
}: FlowBuilderStepMessageBodyProps) {
  const [t] = useTranslation();

  const handleChangelFileSource = useCallback(
    (nextFileSource) => {
      setBodyFileType(nextFileSource, false);
    },
    [setBodyFileType]
  );

  const { value, icon, inputLabel, inputHelp } = useMemo(() => {
    return MESSAGE_BODY_TYPES[bodyType] || {};
  }, [bodyType]);

  if (bodyType === "text") {
    return (
      <TextAreaFieldWithVariables
        path={`steps.${index}`}
        name={"body"}
        label={t(inputLabel)}
        placeholder={t(
          "flows:steps.message.body.placeholder",
          "Step 1 Welcome"
        )}
        rows={8}
        currentStepIndex={index}
      />
    );
  }

  return (
    <FlowBuilderMedia
      enableVariables
      key={`messsage-media-${bodyType}`}
      path={`steps.${index}`}
      name="body_file_url"
      currentStepIndex={index}
      fileUploadFieldName="body_file"
      label={t(inputLabel)}
      help={t(inputHelp)}
      media={value}
      icon={icon}
      fileSource={bodyFileType || "URL"}
      onChangeFileSource={handleChangelFileSource}
    />
  );
}

export default FlowBuilderStepMessageBody;
