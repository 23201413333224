import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/react";
import QRCode from "qrcode";
import Loading from "src/components/Loading";
import useHilosStore from "src/hooks/useHilosStore";

export default function RegisterUserPhone() {
  const { t } = useTranslation();
  const [QR, setQR] = useState("");
  const [url, setUrl] = useState<string | undefined>(undefined);
  const { session, reloadSession } = useHilosStore();

  useEffect(() => {
    const interval = setInterval(() => {
      reloadSession();
    }, 1000 * 3);
    return () => clearInterval(interval);
  }, [reloadSession]);

  const generateQRCode = useCallback(() => {
    if (session) {
      const url = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SANDBOX_NUMBER}&text=${session.phone_connect_code}`;

      setUrl(url);
      QRCode.toDataURL(
        url,
        {
          width: 120,
          margin: 2,
          color: {
            dark: "#486581",
            light: "#ffffff",
          },
        },
        (err, url) => {
          if (err) return captureException(err);
          setQR(url);
        }
      );
    }
  }, [session]);

  useEffect(() => {
    generateQRCode();
  }, []);

  if (!session) {
    return null;
  }

  return (
    <div className="mx-auto block max-w-[500px]">
      <div className="flex text-gray-800 items-center font-bold">
        <div className="h-10 w-10 rounded-full flex items-center justify-center text-blue-500 mr-2 bg-blue-100">
          <FontAwesomeIcon icon={faWhatsapp as IconProp} aria-hidden="true" />
        </div>
        <div className="text-lg">
          {t("flows:register-phone.title", "Register your phone on Hilos")}
        </div>
      </div>

      <Trans i18nKey="flows:register-phone.description">
        <div className="text-sm text-gray-800 mt-2 space-y-1">
          <p>
            Scan the QR Code or click on the button to save your phone to your
            Hilos profile.
          </p>
          <p>This way we'll know it's you testing this flow.</p>
        </div>
      </Trans>

      {url ? (
        <div className="mt-2">
          <div>
            {QR ? (
              <img className="mx-auto rounded-lg p-3" src={QR} alt="qr-code" />
            ) : (
              <div className="m-2 flex h-[170px] min-h-[170px] min-w-[170px] animate-pulse flex-col items-center rounded-lg p-3 text-center">
                {t("loading")}
              </div>
            )}
          </div>
          <div className="text-center">
            <a
              href={url}
              rel="noreferrer"
              target="_blank"
              className="spread-shadow-gree h-fit mb-4 inline-block rounded-lg bg-green-600 py-3 px-4 text-xs font-semibold text-white justify-self-center"
            >
              <FontAwesomeIcon
                icon={faWhatsapp as IconProp}
                aria-hidden="true"
                className="mr-2"
              />
              {t("open-whatsapp-web", "Open in WhatsApp")}
            </a>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
