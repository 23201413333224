import { useTranslation } from "react-i18next";

interface SubscriptionStatusBadgeProps {
  status: string;
}

export default function SubscriptionStatusBadge({
  status,
}: SubscriptionStatusBadgeProps) {
  const { t } = useTranslation();
  switch (status) {
    case "incomplete":
    case "incomplete_expired":
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
          {t("incomplete-expired", "Incomplete Expired")}
        </span>
      );
    case "trialing":
      return (
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium uppercase text-indigo-800">
          {t("trialing", "Trialing")}
        </span>
      );
    case "active":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
          {t("active")}
        </span>
      );
    case "canceled":
    case "unpaid":
    case "past_due":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-800">
          {t("past-due")}
        </span>
      );
    default:
      return null;
  }
}
