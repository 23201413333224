import { Trans } from "react-i18next";

function FormatOptionLabel({ value, label, icon: RenderIcon, help, ...props }) {
  return (
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center">
        <div className="mx-4">
          <RenderIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <div>
          <h4 className="font-medium">{label}</h4>
          {help && <p className="text-xs">{help}</p>}
        </div>
      </div>
      {props.soon && (
        <div className="ml-2 text-right">
          <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
            <Trans i18nKey="flows:editor.coming-soon">Coming soon!</Trans>
          </span>
        </div>
      )}
    </div>
  );
}

export default FormatOptionLabel;
