import { InboxContactRead } from "@hilos/types/private-schema";
import ConversationFlowExecutionControls from "./ConversationFlowExecutionControls";
import InboxActionList from "./InboxActionList";

interface ActionsTabProps {
  inboxContact: InboxContactRead;
}

function ActionsTab({ inboxContact }: ActionsTabProps) {
  if (!inboxContact) {
    return null;
  }

  return (
    <div className="mt-2 grow space-y-5">
      <ConversationFlowExecutionControls inboxContact={inboxContact} />
      <InboxActionList inboxContact={inboxContact} />
    </div>
  );
}

export default ActionsTab;
