import { useTranslation } from "react-i18next";
import { getTimeFromTimestamp } from "src/helpers/date";
import { userToString } from "src/Helpers";
import { UserData } from "src/types/hilos";

export interface EventWrapProps {
  /** Conversation event, can be a component or a translation string */
  event: string | JSX.Element;
  /** Optional Classnames to override default styles for div */
  className?: string;
  /** Optional timestamp to display ` @ HH:mm` */
  timestamp?: string;
  /** Optional user to display name or username next to timestamp */
  user?: UserData;
  /** Optional options to pass to the translation function */
  toptions?: object;
  /** Optional extra info to display along with the label*/
  extra?: JSX.Element | string;
}

/** Component to format the alerts on inbox
 *
 * @param event `string` or `JSX.Element` - The event to be displayed, can be a custom JSX element or a string, if it is a string it should be the i18n key
 * @param className `string` `optional` - classNames to override the default ones
 * @default "text-right flex justify-end"
 * @param timestamp `string` `optional` - The timestamp of the event
 * @param user {@link UserData} `optional` - The user that triggered the event
 * @param toptions `object` `optional` - The options to be passed to the t function
 * @param extra `string` or `JSX.Element` `optional` - Extra info to display
 */
const EventWrap = ({
  event,
  className = "text-right flex justify-end",
  timestamp,
  user,
  toptions,
  extra,
}: EventWrapProps) => {
  const { t } = useTranslation();
  const options = {
    ...toptions,
  };
  const label = `${user ? ` - ${userToString(user)}` : ""} ${
    timestamp ? ` @ ${getTimeFromTimestamp(timestamp)}` : ""
  }`;

  return (
    <div className="flex flex-col text-right space-y-2">
      <div className={className}>
        {typeof event === "string" ? t(event, options) : event}
        {label}
      </div>
      {extra}
    </div>
  );
};

export default EventWrap;
