import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import PermissionsChecker from "src/components/PermissionsCheck";
import { transformNullsToUndefined } from "../../Helpers";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import useHilosStore from "../../hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute } from "../../router/router";
import * as meta from "./AccountMeta";

export default function AccountFormUpdate({ children }) {
  const { t } = useTranslation();
  const { session, reloadSession } = useHilosStore();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const initialValues = useMemo(() => {
    if (!session) {
      return null;
    }

    const account = { ...session.account };
    transformNullsToUndefined(account);

    return account;
  }, [session]);

  const validate = (values) => {
    let errors = {};
    if (values.has_working_hours) {
      values.working_hours.forEach((wh, idx) => {
        if (DateTime.fromISO(wh.end_at) <= DateTime.fromISO(wh.start_at)) {
          if (!errors.working_hours) {
            errors.working_hours = [];
          }
          errors.working_hours[idx] = t(
            "settings:working-hours.errors.invalid",
            "End time must be later than start time."
          );
        }
      });
    }
    return errors;
  };

  const handleSubmit = async (formValues, { setSubmitting }) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      const nextValues = Object.entries(formValues).reduce(
        (nextDiffValues, [key, value]) => {
          if (isEqual(initialValues[key], value)) {
            return nextDiffValues;
          }
          return { ...nextDiffValues, [key]: value };
        },
        {}
      );
      await axios.patch(
        buildAPIRoute(API_ROUTES.ACCOUNT_CREATE_DETAIL),
        nextValues
      );
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } catch (err) {
      console.log(err);
      setSuccess(false);
      if (err.response.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(t("error-general"));
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      reloadSession();
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      validationSchema={meta.updateSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={validate}
      initialValues={initialValues}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="sm:pb-0 pb-14">
            <div className="my-4">
              <APIErrors
                APIError={backendError}
                APIValidationErrors={backendValidationErrors}
                fieldTranslations={meta.FIELDS}
              ></APIErrors>
            </div>

            {children(initialValues, formik)}

            <div className="mt-5">
              <PermissionsChecker
                permission="change_account"
                action={t(
                  "settings:permissions.change_account",
                  "update the team"
                )}
              >
                <div className="text-right">
                  <StateButton
                    isSubmitting={formik.isSubmitting}
                    submitted={submitted}
                    success={success}
                    submittingText={t("updating-team", "Updating team...")}
                    successText={t("team-updated", "Team updated!")}
                    initialText={
                      <>
                        <span className="fe fe-save mr-2"></span>
                        {t("save-changes", "Save changes")}
                      </>
                    }
                  />
                </div>
              </PermissionsChecker>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
