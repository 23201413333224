import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PlusSmIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import { FieldArray, useFormikContext } from "formik";
import { debounce } from "lodash";
import { FlowData } from "@hilos/types/flow";
import AdvancedOptionDivider from "src/components/Form/AdvancedOptionDivider";
import Tooltip from "src/components/Tooltip";
import { removeFalsyValuesFromEndArray } from "src/helpers/utils";
import { classNames } from "src/Helpers";
import EditableTitleField from "../../../EditableTitleField";
import useFlowBuilderStore from "../../../hooks/useFlowBuilderStore";
import { useStepField } from "../../../hooks/useStepField";
import useVariables from "../../../hooks/useVariables";
import FlowBuilderStepConditionalCondition, {
  SelectVariableParams,
} from "../conditional/FlowBuilderStepConditionalCondition";

interface FlowBuilderStepConditionalMultipleProps {
  index: number;
  id: string;
}

function FlowBuilderStepConditionalMultiple({
  id,
  index,
}: FlowBuilderStepConditionalMultipleProps) {
  const { t } = useTranslation();
  const { options, handleGetVariable } = useVariables({
    currentStepIndex: index,
  });
  const { setFieldValue } = useFormikContext<FlowData>();
  const hasPendingRefresh = useRef(false);

  const [paths] = useStepField({
    index,
    name: "paths",
  });

  const [answerOptions, setAnswerOptions] = useStepField({
    index,
    name: "answer_options",
  });

  const [nextStepsForOptions, setNextStepOptions] = useStepField({
    index,
    name: "next_steps_for_options",
  });

  const { onFlowRefresh } = useFlowBuilderStore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefreshFlow = useCallback(
    debounce(async () => {
      onFlowRefresh(id);
    }, 350),
    [id, onFlowRefresh]
  );

  const handlePathRemove = useCallback(
    (arrayHelpers, index) => {
      arrayHelpers.remove(index);
      const newAnswerOptions = answerOptions.filter((_, i) => i !== index);
      setAnswerOptions(newAnswerOptions);
      const newNextStepsForOptions = nextStepsForOptions.filter(
        (_, i) => i !== index
      );
      setNextStepOptions(removeFalsyValuesFromEndArray(newNextStepsForOptions));
      hasPendingRefresh.current = true;
    },
    [answerOptions, nextStepsForOptions, setAnswerOptions, setNextStepOptions]
  );

  const handleSelectVariable = useCallback(
    ({ name, variable }: SelectVariableParams) => {
      if (variable) {
        setFieldValue(`${name}.field`, variable.id);
      } else {
        setFieldValue(`${name}.field`, "");
      }

      setFieldValue(`${name}.value`, "");
      setFieldValue(`${name}.comparison`, "");
    },
    [setFieldValue]
  );

  useEffect(() => {
    handleRefreshFlow();
  }, [handleRefreshFlow, answerOptions]);

  useEffect(() => {
    if (hasPendingRefresh.current) {
      handleRefreshFlow();
      hasPendingRefresh.current = false;
    }
  }, [handleRefreshFlow, paths]);

  return (
    <div className="space-y-2">
      <FieldArray
        name={`steps.${index}.paths`}
        render={(arrayHelpersPaths) => (
          <div key={arrayHelpersPaths.name}>
            <ul className="">
              {paths?.map((path, currentPathIndex) => {
                const idPath = `steps.${index}.paths.${currentPathIndex}`;
                const disablePathRemove = Boolean(
                  nextStepsForOptions?.[currentPathIndex]
                );
                return (
                  <div
                    key={idPath}
                    className="mt-1 mb-3 border-b border-gray-200"
                  >
                    <FieldArray
                      name={`steps.${index}.paths.${currentPathIndex}.conditions`}
                      render={(arrayHelpers) => (
                        <AdvancedOptionDivider
                          keyVal={arrayHelpersPaths.name}
                          title={
                            <EditableTitleField
                              name={`steps.${index}.answer_options.${currentPathIndex}`}
                              className={classNames(
                                "pr-2 text-lg font-medium outline-none bg-neutral-extralight"
                              )}
                              maxCharSize={19}
                            />
                          }
                          buttons={
                            disablePathRemove ? (
                              <Tooltip
                                button={
                                  <TrashIcon className="h-4 w-4 text-gray-400 mr-2" />
                                }
                                content={
                                  <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3 ">
                                    {t(
                                      "flows:steps.conditional-multiple.tooltip-remove-path-disabled",
                                      "Remove all the steps under this path to be able to remove it."
                                    )}
                                  </div>
                                }
                                placement="left"
                              />
                            ) : (
                              <TrashIcon
                                className="h-4 w-4 text-red-500 mr-2"
                                onClick={() =>
                                  handlePathRemove(
                                    arrayHelpersPaths,
                                    currentPathIndex
                                  )
                                }
                              />
                            )
                          }
                        >
                          <div key={arrayHelpers.name} className="mt-0">
                            {path.conditions?.map(
                              (condition, currentConditionIndex) => {
                                const id = `${idPath}.conditions.${currentConditionIndex}`;

                                return (
                                  <FlowBuilderStepConditionalCondition
                                    key={id}
                                    name={id}
                                    options={options}
                                    condition={condition}
                                    onGetVariable={handleGetVariable}
                                    onSelectVariable={handleSelectVariable}
                                    onRemoveCondition={arrayHelpers.handleRemove(
                                      currentConditionIndex
                                    )}
                                    conditionIndex={currentConditionIndex}
                                  />
                                );
                              }
                            )}
                            <div className="mt-4">
                              <button
                                type="button"
                                className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                                onClick={arrayHelpers.handlePush({
                                  field: "contact.phone",
                                  comparison: "icontains",
                                  value: "+52",
                                })}
                              >
                                <PlusSmIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                {t(
                                  "flows:steps.conditional.add-condition",
                                  "Add condition"
                                )}
                              </button>
                            </div>
                          </div>
                        </AdvancedOptionDivider>
                      )}
                    />
                  </div>
                );
              })}
            </ul>
            <div className="mt-8">
              <button
                type="button"
                className="inline-flex w-full items-center justify-center rounded-md border border-red-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-red-500 shadow-sm hover:bg-red-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-red-500"
                onClick={() => {
                  arrayHelpersPaths.push({
                    conditions: [
                      {
                        field: "contact.phone",
                        comparison: "icontains",
                        value: "+52",
                      },
                    ],
                  });
                  const currentAnswerOptions = answerOptions ?? [];
                  setAnswerOptions([
                    ...currentAnswerOptions,
                    t(
                      "flows:steps.conditional-multiple.path",
                      "Path {{number}}",
                      {
                        number: currentAnswerOptions.length + 1,
                      }
                    ),
                  ]);
                  hasPendingRefresh.current = true;
                }}
              >
                <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {t("flows:steps.conditional.add-path", "Add path")}
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default FlowBuilderStepConditionalMultiple;
