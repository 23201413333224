import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowSmRightIcon, ExclamationIcon } from "@heroicons/react/outline";
import useHilosStore from "src/hooks/useHilosStore";
import { useStripeSubscription } from "src/hooks/useStripe";
import { buildRoute } from "src/router/router";

function SubscriptionPaymentFailedNotice() {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const { subscription } = useStripeSubscription();
  if (!session?.account) {
    return null;
  }

  return (
    <div className="bg-red-50 p-3">
      <div className="space-y-2">
        <div className="flex justify-center">
          <ExclamationIcon
            className="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="flex-1 items-center space-y-1">
          <p className="text-sm font-semibold text-red-700">
            {t(
              "settings:subscription-payment-failed-notice.title",
              "We weren't able to charge your account for this month's usage."
            )}
          </p>
          <p className="text-sm text-red-700">
            {t(
              "settings:subscription-payment-failed-notice.subtitle",
              "To avoid losing access to Hilos, pay your outstanding invoice before the 10th of this month."
            )}
          </p>
        </div>
        <div className="w-full">
          {subscription?.last_invoice?.stripe_invoice_url ? (
            <a
              href={subscription?.last_invoice?.stripe_invoice_url || ""}
              target="_blank"
              rel="noreferrer"
              className="inline-flex w-full justify-center rounded-md border border-red-700 bg-white px-2 py-1.5 text-sm font-medium text-red-700"
            >
              {t(
                "settings:subscription-payment-failed-notice.button",
                "Pay now"
              )}

              <ArrowSmRightIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
            </a>
          ) : (
            <Link
              to={buildRoute("config-account-subscription")}
              className="inline-flex w-full justify-center rounded-md border border-red-700 bg-white px-2 py-1.5 text-sm font-medium text-red-700"
            >
              {t(
                "settings:subscription-payment-failed-notice.button",
                "Pay now"
              )}
              <ArrowSmRightIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPaymentFailedNotice;
