import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import { InformationCircleIcon, UploadIcon } from "@heroicons/react/solid";
import { FieldArray, FormikProps } from "formik";
import { AccountStatusEnum } from "@hilos/types/private-schema";
import SwitchField from "src/components/Form/SwitchField";
import HelpDocsLink from "src/components/HelpDocsLink";
import StateButton from "src/components/StateButton";
import chatBackground from "src/assets/img/Chat_Background.png";
import TextInputField from "../../components/Form/TextInputField";
import { getComponentIdx } from "../../helpers/template";
import WhatsAppTemplateFormMissingFieldsNotice from "./WhatsAppTemplateFormMissingFieldsNotice";
import * as meta from "./WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "./WhatsAppTemplatePreview";
import { initialTemplatePreviewAuthenticationValues } from "./WhatsAppTemplatePreviewForm";

interface WhatsAppTemplateFormStepContentAuthenticationProps {
  formik: FormikProps<any>;
  setCurrentStep: (step: string) => void;
  accountStatus?: AccountStatusEnum;
  submitted: boolean;
  success: boolean;
}

export default function WhatsAppTemplateFormStepContentAuthentication({
  formik,
  setCurrentStep,
  accountStatus,
  submitted,
  success,
}: WhatsAppTemplateFormStepContentAuthenticationProps) {
  const { t } = useTranslation();
  const [formValues, _] = useState(initialTemplatePreviewAuthenticationValues);

  return (
    <div>
      <div className="row mt-10 text-center">
        <div className="mx-auto max-w-2xl text-left">
          <WhatsAppTemplateFormMissingFieldsNotice formik={formik} />
        </div>
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          {t("step-n-of-n", "Step {{step}} of {{total}}", {
            step: 2,
            total: 2,
          })}
        </h6>
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          {t("templates:template-content.title", "Template content")}
        </h1>
        <p className="mb-5 text-sm text-gray-500">
          {t(
            "templates:template-content.description",
            "Select the content you want to include in this template."
          )}
        </p>
      </div>

      <div className="mt-10 grid-rows-1 gap-6 md:grid md:grid-cols-5">
        <div className=" md:col-span-3">
          {/* Buttons */}
          <div className="mt-5 mb-10 bg-white p-5 shadow sm:rounded-md">
            <div className="flex flex-row justify-between">
              <div className="flex">
                {t(
                  "templates:template-content.authentication.title",
                  "Authentication Template"
                )}{" "}
              </div>
              <HelpDocsLink
                href="https://hilos.io/docs/user/using-hilos/templates/creating-a-template"
                className="text-sm"
              />
            </div>

            <div className="bg-blue-50 p-4 rounded-md flex space-x-2 items-center my-2 ">
              <InformationCircleIcon
                className="h-6 w-6 text-blue-400"
                aria-hidden="true"
              />
              <div className="text-sm text-blue-700">
                <Trans i18nKey="templates:authentication-template">
                  <p className="font-bold">
                    Authentication templates are different than the rest.
                  </p>
                  <p className="text-sm">
                    Content for authentication message templates can't be
                    edited. You can add additional content from the options
                    below.
                  </p>
                </Trans>
              </div>
            </div>

            <div className="mt-4">
              <h3 className="font-medium text-sm">
                {t(
                  "templates:template-content.authentication.message-content",
                  "Select the content you'd like to add to your template."
                )}
              </h3>
              <div className="space-y-4 mt-2">
                <SwitchField
                  label={t(
                    meta.AUTHENTICATION_OPTIONS.add_security_recommendation
                      .label,
                    meta.AUTHENTICATION_OPTIONS.add_security_recommendation
                      .label
                  )}
                  name={`components.${getComponentIdx("BODY", formik)}.${
                    meta.AUTHENTICATION_OPTIONS.add_security_recommendation.key
                  }`}
                />

                <SwitchField
                  label={t(
                    meta.AUTHENTICATION_OPTIONS.add_code_expiration_minutes
                      .label,
                    meta.AUTHENTICATION_OPTIONS.add_code_expiration_minutes
                      .label
                  )}
                  name={`components.${getComponentIdx("FOOTER", formik)}.${
                    meta.AUTHENTICATION_OPTIONS.add_code_expiration_minutes.key
                  }`}
                  onChange={(checked) => {
                    formik.setFieldValue(
                      `components.${getComponentIdx(
                        "FOOTER",
                        formik
                      )}.code_expiration_minutes`,
                      checked ? 10 : undefined
                    );
                  }}
                />

                {formik.values.components[getComponentIdx("FOOTER", formik)]
                  .add_code_expiration_minutes && (
                  <TextInputField
                    name={`components.${getComponentIdx(
                      "FOOTER",
                      formik
                    )}.code_expiration_minutes`}
                    label={t(
                      meta.AUTHENTICATION_OPTIONS.code_expiration_minutes.label,
                      meta.AUTHENTICATION_OPTIONS.code_expiration_minutes.label
                    )}
                    type="number"
                    max={90}
                    min={1}
                  />
                )}
              </div>
            </div>

            <div className="mt-4">
              <div className="space-y-4 mt-2">
                <FieldArray
                  name={`components.${getComponentIdx(
                    "BUTTONS",
                    formik
                  )}.buttons`}
                  validateOnChange={true}
                  render={() => (
                    <ul className="space-y-2">
                      {formik.values.components[
                        getComponentIdx("BUTTONS", formik)
                      ].buttons &&
                        formik.values.components[
                          getComponentIdx("BUTTONS", formik)
                        ].buttons.map((button, bIdx) => (
                          <div key={bIdx}>
                            <TextInputField
                              name={`components.${getComponentIdx(
                                "BUTTONS",
                                formik
                              )}.buttons.${bIdx}.text`}
                              label={t(
                                meta.AUTHENTICATION_OPTIONS.text.label,
                                meta.AUTHENTICATION_OPTIONS.text.label
                              )}
                              help={t(
                                meta.AUTHENTICATION_OPTIONS.text.help,
                                meta.AUTHENTICATION_OPTIONS.text.help
                              )}
                              type="text"
                              maxLength={25}
                              min={1}
                            />
                          </div>
                        ))}
                    </ul>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="mb-10 p-5 shadow sm:rounded-md md:col-span-2"
          style={{
            backgroundImage: `url(${chatBackground})`,
          }}
        >
          <h6 className="mb-2 text-tiny uppercase tracking-wider text-gray-500">
            {t("templates:preview", "Template preview")}
          </h6>
          <WhatsAppTemplatePreview
            template={formik.values}
            values={formValues}
          />
        </div>
      </div>
      <div className="mx-auto my-14 max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <WhatsAppTemplateFormMissingFieldsNotice formik={formik} />
          <hr className="my-5" />
          <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
            <div className="justify-self-start">
              <button
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-medium leading-4 text-gray-900 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-500"
                type="button"
                onClick={(_) => setCurrentStep("attrs")}
              >
                <ArrowSmLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {t("details", "Details")}
              </button>
            </div>
            <div className="col text-center">
              <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
                {t("step-n-of-n", "Step {{step}} of {{total}}", {
                  step: 2,
                  total: 3,
                })}
              </h6>
            </div>
            {accountStatus === "ACTIVE" && (
              <div className="justify-self-end">
                <StateButton
                  isSubmitting={formik.isSubmitting}
                  submitted={submitted}
                  success={success}
                  submittingText={t("submitting", "Submitting...")}
                  successText={t("submitted", "Submitted!")}
                  initialText={
                    <>
                      <UploadIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      {t("submit", "Submit")}
                    </>
                  }
                  btnClasses="inline-flex items-center px-4 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  btnClassesSuccess="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-3 text-sm font-medium leading-4 text-green-800 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  btnClassesError="inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-3 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
