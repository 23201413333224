import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import {
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
  ChatIcon,
  ExclamationIcon,
  PencilAltIcon,
  UserIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { formatPhone, truncateWords, userToString } from "../../Helpers";

import ContactPhoneStatusIndicator from "./ContactPhoneStatusIndicator";
import { DateTime } from "luxon";
import DeleteObject from "../../components/DeleteObject";
import HeaderNavigation from "../../components/HeaderNavigation";
import { Link } from "react-router-dom";
import MessageRender from "../../components/MessageRender";
import Table from "src/components/Table/Table";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function ContactDetail() {
  const { t } = useTranslation();
  const params = useParams();

  const [contact, setContact] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [outboundMessageCount, setOutboundMessageCount] = useState(0);
  const [inboundMessageCount, setInboundMessageCount] = useState(0);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.CONTACT_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
    } catch (err) {
      setDeleteSuccess(false);
      if (err.response.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          t("error-general", "An error occurred, please try again.")
        );
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  useEffect(() => {
    async function getContact() {
      try {
        const response = await axios.get(
          buildAPIRoute(API_ROUTES.CONTACT_DETAIL, {
            ":id": params.id,
          })
        );
        const outboundMsgs = await axios.get(
          buildAPIRoute(API_ROUTES.TEXT_MESSAGE_LIST) +
            `?contact=${params.id}&direction=OUTBOUND`
        );
        const inboundMsgs = await axios.get(
          buildAPIRoute(API_ROUTES.TEXT_MESSAGE_LIST) +
            `?contact=${params.id}&direction=INBOUND`
        );
        setContact(response.data);
        setOutboundMessageCount(outboundMsgs.data.count);
        setInboundMessageCount(inboundMsgs.data.count);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    getContact();
  }, [params]);

  return (
    <>
      {!loading && contact ? (
        <div className="h-screen overflow-y-auto bg-gray-50">
          {/* Header */}
          <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
            <HeaderNavigation
              navPages={[
                {
                  name: t("contacts", "Contacts"),
                  url: buildRoute("contact-list"),
                },
                {
                  name: formatPhone(contact.phone),
                  url: buildRoute("contact-detail", { ":id": contact.id }),
                },
              ]}
            />
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <UserIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                  {formatPhone(contact.phone)}
                </h2>
              </div>
            </div>
          </div>

          <div className="my-6 grid grid-cols-1 grid-rows-2 gap-4 px-6 md:grid-cols-4 md:grid-rows-1">
            <div className="col-span-2 rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("summary", "Summary")}
              </h3>
              <div className="justify-left mt-2 flex items-center text-sm">
                <div className="grow-0 text-gray-400">
                  <UserIcon className="-ml-1 mr-4 h-7 w-7" aria-hidden="true" />
                </div>
                <div className="grow-1 w-full">
                  <p className="justify-left flex items-center text-base font-medium">
                    {formatPhone(contact.phone, true, true)}{" "}
                    <ContactPhoneStatusIndicator
                      status={contact.phone_status}
                    />
                  </p>
                  {contact.phone_status === "INVALID" && (
                    <p className="text-sm font-medium text-yellow-800">
                      {t(
                        "contacts:contact-not-on-wa",
                        "WhatsApp reports this number isn't on WhatsApp."
                      )}
                    </p>
                  )}
                  <p>{userToString(contact)}</p>
                  <p className="text-gray-400">{contact.email}</p>
                  <p className="text-gray-400">
                    {t("created-on")}{" "}
                    {DateTime.fromISO(contact.created_on).toLocaleString(
                      DateTime.DATETIME_SHORT_WITH_SECONDS
                    )}
                  </p>
                </div>
                <div className="grow-0">
                  <Link
                    className="focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                    to={buildRoute("contact-update", {
                      ":id": contact.id,
                    })}
                  >
                    <PencilAltIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("edit", "Edit")}
                  </Link>
                  <DeleteObject
                    obj={contact}
                    objDescription={contact.phone}
                    actionDescription="When deleting this contact, messages already sent will still reach them."
                    onDelete={onDelete}
                    deleteSuccess={deleteSuccess}
                    deleteSubmitted={deleteSubmitted}
                    buttonClasses="inline-flex items-center justify-center w-full px-3 py-2 border border-red-600 shadow-sm text-sm leading-4 font-medium rounded-md text-red-600 bg-white hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  ></DeleteObject>
                </div>
              </div>
            </div>

            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("inbound-messages", "Inbound Messages")}
              </h3>
              <p className="mt-2 text-3xl font-medium text-gray-900">
                {inboundMessageCount}
              </p>
            </div>
            <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-center shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("outbound-messages", "Outbound Messages")}
              </h3>
              <p className="mt-2 text-3xl font-medium text-gray-900">
                {outboundMessageCount}
              </p>
            </div>
          </div>

          <div className="my-6 md:px-6">
            <Table
              baseURL={buildAPIRoute(API_ROUTES.TEXT_MESSAGE_LIST)}
              paginated={true}
              initialSearchParams={{
                contact: contact.id,
              }}
              tableHead={
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    ></th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("phone", "Phone")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("content", "Content")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("sent-on", "Sent on")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("delivered-on", "Delivered on")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("read-on", "Read on")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("failed-on", "Failed on")}
                    </th>
                  </tr>
                </thead>
              }
              tableRow={(message, idx) => (
                <>
                  <td className="whitespace-nowrap py-3 pl-4 text-sm text-gray-900">
                    {message.direction === "OUTBOUND" ? (
                      <ArrowSmRightIcon
                        className="-ml-1 h-7 w-7"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmLeftIcon
                        className="-ml-1 h-7 w-7"
                        aria-hidden="true"
                      />
                    )}
                  </td>
                  <td className="whitespace-nowrap py-3 pr-4 text-sm text-gray-900">
                    <p className="font-medium">{message.id}</p>
                    <p className="text-gray-600">{message.direction}</p>
                    {message.status === "failed" ? (
                      <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium uppercase text-red-800">
                        {message.status}
                      </span>
                    ) : (
                      <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium uppercase text-green-800">
                        {message.status}
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                    <MessageRender
                      previewElement={
                        <span className="flex items-center font-medium text-blue-500">
                          {message.status === "failed" ? (
                            <ExclamationIcon className="mr-1 h-4 w-4 text-red-500" />
                          ) : (
                            <ChatIcon className="mr-1 h-4 w-4 text-gray-500" />
                          )}
                          {truncateWords(message.body, 3)}
                        </span>
                      }
                      message={message}
                    />
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {message.sent_on
                      ? DateTime.fromISO(message.sent_on).toLocaleString(
                          DateTime.DATETIME_SHORT_WITH_SECONDS
                        )
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {message.delivered_on
                      ? DateTime.fromISO(message.delivered_on).toLocaleString(
                          DateTime.DATETIME_SHORT_WITH_SECONDS
                        )
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {message.read_on
                      ? DateTime.fromISO(message.read_on).toLocaleString(
                          DateTime.DATETIME_SHORT_WITH_SECONDS
                        )
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {message.status === "failed" ? (
                      <div className="font-size-sm">
                        {message.failed_on &&
                          DateTime.fromISO(message.failed_on).toLocaleString(
                            DateTime.DATETIME_SHORT_WITH_SECONDS
                          )}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                </>
              )}
              dataName={t("messages", "Messages")}
              noResults={
                <>
                  <Trans i18nKey="contacts:no-messages-for-this-contact">
                    <h4>Couldn't find any messages for this contact.</h4>
                    <p className="text-muted">Why not send one?</p>
                  </Trans>
                </>
              }
            ></Table>
          </div>
        </div>
      ) : (
        <>
          {!loading && (
            <div className="d-flex min-vh-100 align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-5 col-xl-4 my-5">
                    <div className="text-center">
                      <h6 className="text-uppercase text-muted mb-4">
                        {t("errors:404", "Error 404")}
                      </h6>

                      <h1 className="display-4 mb-3">
                        {t("contacts:contact-not-found", "Contact not found")}
                      </h1>

                      <Link
                        to={buildRoute("contact-list")}
                        className="btn btn-primary mt-4"
                      >
                        <span className="fe fe-arrow-left mr-2"></span>
                        {t("contacts:go-to-contact-list", "Go to contact list")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
