import { Locale } from "date-fns"
import { useMemo } from "react";

import {enUS, es} from 'date-fns/locale'

import { useTranslation } from "react-i18next";

const locales = {
  'en': enUS,
  es
}

export default function useDateFnsCurrentLocale() {
  const {i18n} = useTranslation()
  const currentLocale = useMemo<Locale>(() => {
    return locales[i18n.languages[0]]
  }, [i18n.languages])
  return {currentLocale};
}