import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { CheckIcon, ChevronRightIcon, XIcon } from "@heroicons/react/outline";
import { Formik, FormikHelpers } from "formik";
import Button from "src/components/Button";
import RadioButtonsField from "src/components/Form/RadioButtonsField";
import SelectorField from "src/components/Form/SelectorField";
import useAccount from "src/hooks/useAccount";
import { classNames, userToString } from "src/Helpers";
import { CONVERSATION_STATUS } from "./InboxMeta";

interface InboxContactBulkAction {
  action_type:
    | "STATUS"
    | "MUTE"
    | "ARCHIVE"
    | "BLOCK"
    | "UNREAD"
    | "ASSIGN"
    | null;
  action_value: boolean | string | number | null;
}

const BOOL_ACTIONS = ["MUTE", "ARCHIVE", "BLOCK", "UNREAD"];

interface ConversationListActionProps {
  isSelectedAll: boolean;
  totalConversations: number;
  totalSelectedContacts: number;
  onSelectAll: () => void;
  onUnselectAll: () => void;
  onInboxAction: (action: InboxContactBulkAction) => void;
}

type InboxContactBulkActionData = FormikHelpers<InboxContactBulkAction>;

const INITIAL_ACTION_VALUES: InboxContactBulkAction = {
  action_type: null,
  action_value: null,
};

function ConversationListAction({
  isSelectedAll,
  totalConversations,
  totalSelectedContacts,
  onSelectAll,
  onUnselectAll,
  onInboxAction,
}: ConversationListActionProps) {
  const { t } = useTranslation();
  const [showActions, setShowActions] = useState(false);
  const { members } = useAccount();

  const conversationStatusOptions = useMemo(
    () =>
      Object.entries(CONVERSATION_STATUS)
        .filter(([value]) => !["NOT_CONTACTED", "FLOW"].includes(value))
        .map(([value, status]) => ({
          label: t(status.label),
          value,
        })),
    [t]
  );

  const actionTypeOptions = useMemo(
    () => [
      {
        label: t("inbox:labels.change-status", "Change status"),
        value: "STATUS",
      },
      {
        label: t("inbox:labels.assign", "Assign"),
        value: "ASSIGN",
      },
      {
        label: t("inbox:labels.mute", "Mute"),
        value: "MUTE",
      },
      {
        label: t("inbox:labels.archive", "Archive"),
        value: "ARCHIVE",
      },
      {
        label: t("inbox:labels.mark-as-unread", "Mark as unread"),
        value: "UNREAD",
      },
    ],
    [t]
  );

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        onSelectAll();
      } else {
        onUnselectAll();
      }
    },
    [onSelectAll, onUnselectAll]
  );

  const handleSubmitInboxAction = useCallback(
    (values: InboxContactBulkAction, formik: InboxContactBulkActionData) => {
      onInboxAction(values);
      setShowActions(false);
      formik.setValues({ ...INITIAL_ACTION_VALUES });
    },
    [onInboxAction]
  );

  if (!isSelectedAll && !totalSelectedContacts) {
    return null;
  }

  return (
    <div className="border-b border-gray-200 bg-gray-50">
      <div className="flex flex-row min-w-full items-center pl-3 justify-between">
        <label className="inline-block text-xs font-normal text-gray-500 text-center">
          <input
            type="checkbox"
            checked={isSelectedAll}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0 mr-3"
            onChange={handleSelectAll}
          />
          {t("inbox:select-all", "Select All")}
        </label>
        <button
          onMouseDown={(event) => {
            if (event.detail > 1) {
              event.preventDefault();
            }
          }}
          onClick={() => setShowActions((prevShowActions) => !prevShowActions)}
          className="flex items-center pr-4 py-2 text-xs font-medium text-gray-500"
        >
          {t("inbox:selected-contacts", "{{count}} selected", {
            count: isSelectedAll ? totalConversations : totalSelectedContacts,
          })}
          <ChevronRightIcon
            className={classNames(
              "ml-2 h-4 w-4 text-gray-400",
              showActions &&
                "rotate-90 transform transition-transform duration-100 ease-in-out"
            )}
          />
        </button>
      </div>
      <Transition
        show={showActions}
        enter="transition-all duration-100 ease-in"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition-all duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Formik
          enableReinitialize
          onSubmit={handleSubmitInboxAction}
          initialValues={INITIAL_ACTION_VALUES}
        >
          {({ values, touched, setFieldValue, isSubmitting, handleSubmit }) => (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              className="flex flex-col py-4 text-sm text-gray-500 px-3 space-y-2"
            >
              <SelectorField
                options={actionTypeOptions}
                singleValueStyles={{
                  color: "#4f46e5",
                  fontWeight: 600,
                }}
                name="action_type"
                onSelect={() => setFieldValue("action_value", null)}
                placeholder={t(
                  "inbox:labels.select-action",
                  "Select action to execute"
                )}
              />

              {values && values.action_type && (
                <>
                  {values.action_type === "STATUS" && (
                    <SelectorField
                      options={conversationStatusOptions}
                      singleValueStyles={{
                        color: "#4f46e5",
                        fontWeight: 600,
                      }}
                      name="action_value"
                      placeholder={t(
                        "inbox:labels.select-status",
                        "Select a status"
                      )}
                    />
                  )}

                  {values.action_type === "ASSIGN" && (
                    <SelectorField
                      options={members.map((user) => ({
                        label: userToString(user),
                        value: user.id,
                      }))}
                      singleValueStyles={{
                        color: "#4f46e5",
                        fontWeight: 600,
                      }}
                      name="action_value"
                      placeholder={t("write-to-search", "Write to search...")}
                    />
                  )}

                  {BOOL_ACTIONS.includes(values.action_type) && (
                    <RadioButtonsField
                      options={[
                        {
                          value: true,
                          label: t("yes", "Yes"),
                          disabled: false,
                          icon: CheckIcon,
                        },
                        {
                          value: false,
                          label: t("no", "No"),
                          disabled: false,
                          icon: XIcon,
                        },
                      ]}
                      name="action_value"
                    />
                  )}
                </>
              )}

              <div className="flex flex-row justify-end pt-2">
                <Button
                  type="submit"
                  disabled={
                    !Boolean(
                      values.action_type &&
                        values.action_value !== undefined &&
                        values.action_value !== null
                    )
                  }
                  isSubmitting={isSubmitting}
                  icon={<CheckIcon className="ml-1 -mr-1 h-5 w-5" />}
                >
                  {t("apply", "Apply")}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Transition>
    </div>
  );
}

export default ConversationListAction;
