import { useTranslation } from "react-i18next";
import { InboxContactRead } from "@hilos/types/private-schema";
import ConversationFlowExecutionContactControlsButtons from "src/containers/inbox/Conversation/ActionTab/ConversationFlowExecutionContactControlsButtons";
import { useFlowExecutionContacts } from "src/hooks/useFlowExecutionContacts";

interface ConversationFlowExecutionControlsProps {
  inboxContact: InboxContactRead;
}

function ConversationFlowExecutionControls({
  inboxContact,
}: ConversationFlowExecutionControlsProps) {
  const { t } = useTranslation();
  const flowExecutions = useFlowExecutionContacts({
    contactId: inboxContact.contact.id as string,
    isActive: true,
  });

  const inFlow =
    (inboxContact.last_conversation &&
      inboxContact.last_conversation.status === "FLOW") ||
    !!flowExecutions?.results.length;
  if (!inFlow) {
    return (
      <div className="mt-1 space-x-1 px-3 text-xs text-gray-500">
        {t(
          "inbox:contact-not-in-flow",
          "No flows are currently executing for this contact."
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center px-3">
        <div className="flex-1 text-xs font-medium uppercase tracking-wide text-gray-500">
          {t("inbox:flow-executions-controls", "Flow Execution Controls")}
        </div>
      </div>
      <div className="mt-1 space-x-1 px-3">
        {flowExecutions?.results.length === 0 ? (
          <div className="text-xs text-gray-500">
            {t(
              "inbox:no-flow-executions",
              "There are no flow executions to control."
            )}
          </div>
        ) : (
          flowExecutions?.results.map((flowExecutionContact) => (
            <ConversationFlowExecutionContactControlsButtons
              flowExecutionContact={flowExecutionContact}
              contactId={inboxContact.contact.id}
              isActive={true}
              key={flowExecutionContact.id}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default ConversationFlowExecutionControls;
