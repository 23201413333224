import { useTranslation } from "react-i18next";
import {
  ArrowSmRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import TextAreaField from "../../../components/Form/TextAreaField";
import { ERROR_MESSAGES } from "../../../constants/Form";

export default function UserInvitationForm({ inviteAccountMembers }) {
  const { t } = useTranslation();
  const initialValues = {
    emails: "",
  };

  const schema = yup.object().shape({
    emails: yup.string().required(ERROR_MESSAGES.required),
  });

  const validate = (values) => {
    let errors = {};
    const emails = values.emails.split(",").map((e) => e.trim());
    const emailSchema = yup.string().email();
    emails.forEach((e) => {
      if (!emailSchema.isValidSync(e)) {
        errors = { emails: `Email ${e} is not valid.` };
      }
    });
    return errors;
  };

  const onSubmit = async (formData, setSubmitting) => {
    const emails = formData.emails
      .split(",")
      .map((e) => e.trim())
      .filter((e) => e);
    setSubmitting(true);
    await inviteAccountMembers(emails);
    setSubmitting(false);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
      validate={validate}
      initialValues={initialValues}
    >
      {(formik) => (
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          className="space-y-2 pt-6 pb-5"
        >
          <TextAreaField
            name="emails"
            placeholder="timcook@mycompany.com, stevejobs@mycompany.com..."
            label={t(
              "settings:team.invite-members.label",
              "Emails to send invitations to"
            )}
            help={t(
              "settings:team.invite-members.help",
              "Separate emails by commas to invite several team members."
            )}
          />

          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
              <p className="text-sm ml-1 text-blue-700">
                {t(
                  "settings:team.invite-members.3-day-title",
                  "These invitations will be valid for 3 days."
                )}
              </p>
            </div>
            <p className="mt-2 text-xs text-blue-700 whitespace-nowrap">
              {t(
                "settings:team.invite-members.3-day-description",
                "You can send a new invitation if the previous one expired."
              )}
            </p>
          </div>

          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {formik.isSubmitting ? (
              <>
                <svg
                  className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {t("saving")}
              </>
            ) : (
              <div className="inline-flex items-center">
                {t("send-invitation", "Send invitation")}
                <ArrowSmRightIcon
                  className="ml-1 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              </div>
            )}
          </button>
        </form>
      )}
    </Formik>
  );
}
