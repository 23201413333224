import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { ChatbotDetailRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useChatbot(channelId?: number) {
  const fetchChatbot = useCallback(async () => {
    try {
      const { data } = await axios.get<ChatbotDetailRead>(
        buildAPIRoute(API_ROUTES.CHATBOT_DETAIL, {
          ":id": channelId,
        })
      );
      return data;
    } catch (error) {
      return undefined;
    }
  }, [channelId]);

  const { data, refetch, isLoading } = useQuery(
    ["chatbot_detail", channelId],
    fetchChatbot
  );

  return { data, refetch, isLoading };
}

export default useChatbot;
