import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";
import useTeam from "src/hooks/useTeam";
import { userAvatar } from "src/Helpers";
import FlowStepTagDetailComponent from "./components/FlowStepTagDetailComponent";

interface FlowStepTeamDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepTeamDetail({ step }: FlowStepTeamDetailProps) {
  const { t } = useTranslation();
  const teamId =
    step.assign_to_teams && step.assign_to_teams.length > 0
      ? step.assign_to_teams[0]
      : undefined;
  const team = useTeam(teamId);

  if (!team) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:steps-detail.team.team-to-assign",
            "Team to assign"
          )}
          :
        </h3>

        <p className="text-gray-900">{team?.name}</p>

        <ul>
          {team.users &&
            team.users.map((user) => (
              <li className="flex items-center" key={user.id}>
                <span className="text-xs mr-1">
                  {userAvatar(
                    user,
                    "h-6 w-6 text-xs ring-2 ring-white group-hover:ring-gray-300"
                  )}
                </span>
              </li>
            ))}
        </ul>
      </div>
      <FlowStepTagDetailComponent step={step} />
    </div>
  );
}
