import { SVGProps } from "react";

function NumberIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M960 3120 l0 -80 -160 0 -160 0 0 -160 0 -160 160 0 160 0 0 -400 0 -400 -160 0 -160 0 0 -160 0 -160 480 0 480 0 0 160 0 160 -160 0 -160 0 0 640 0 640 -160 0 -160 0 0 -80z" />
        <path d="M2080 3040 l0 -160 320 0 320 0 0 -160 0 -160 -203 0 c-197 0 -204 -1 -254 -25 -60 -30 -136 -108 -163 -168 -18 -39 -20 -69 -20 -404 l0 -363 480 0 480 0 0 160 0 160 -320 0 -320 0 0 160 0 160 203 0 c197 0 204 1 254 25 60 30 136 108 163 168 18 38 20 67 20 288 0 243 0 246 -25 296 -30 60 -108 136 -168 163 -39 18 -69 20 -404 20 l-363 0 0 -160z" />
        <path d="M3520 3040 l0 -160 320 0 320 0 0 -160 0 -160 -240 0 -240 0 0 -160 0 -160 240 0 240 0 0 -160 0 -160 -320 0 -320 0 0 -160 0 -160 363 0 c335 0 365 2 404 20 60 27 138 103 168 163 l25 51 0 566 0 566 -25 51 c-30 60 -108 136 -168 163 -39 18 -69 20 -404 20 l-363 0 0 -160z" />
      </g>
    </svg>
  );
}

export default NumberIcon;
