import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/outline";
import {
  PLATFORM,
  getNotificationSupport,
  requestNotificationPermission,
} from "../../helpers/notifications";

function BrowserNotificationStatus() {
  const { t } = useTranslation();
  const [hasNotificationSupport, setHasNotificationSupport] = useState(false);
  const [permission, setPermission] =
    useState<NotificationPermission>("default");

  useEffect(() => {
    const nextHasNotificationSupport = getNotificationSupport();
    setHasNotificationSupport(nextHasNotificationSupport);
    if (nextHasNotificationSupport) {
      const initNotificationPermission = async () => {
        const nextPermission = await requestNotificationPermission();
        setPermission(nextPermission);
      };
      initNotificationPermission();
    }
  }, []);

  if (PLATFORM !== "BROWSER" || isMobile) {
    return null;
  }

  if (!hasNotificationSupport) {
    return (
      <div className="bg-indigo-50 py-3 px-2">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-indigo-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-1 flex-1 md:flex md:justify-between">
            <p className="text-sm text-indigo-700">
              {t(
                "notifications.not-supported",
                "Your browser doesn't support web notifications; switch to a newer one to be notified on new conversations or messages."
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (permission === "granted") {
    return (
      <div className="bg-indigo-50 py-3 px-2">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
          </div>
          <div className="ml-1 flex-1 md:flex md:justify-between">
            <p className="text-sm text-indigo-700">
              {t("notifications.enabled", "Notifications enabled")}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-indigo-50 py-3 px-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-indigo-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-1 flex-1 md:flex">
          <p className="text-sm text-indigo-700">
            {t("notifications.disabled", "Notifications disabled")}
          </p>
          {permission !== "denied" && (
            <>
              <p className="text-sm text-indigo-700">,</p>
              <button
                className="ml-1 text-sm text-blue-700"
                type="button"
                onClick={requestNotificationPermission}
              >
                {t("click-to-enable", "Click to enable")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrowserNotificationStatus;
