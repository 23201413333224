import { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { throttle } from "lodash";
import Loading from "src/components/Loading";
import { classNames } from "src/Helpers";

interface InfiniteScrollProps {
  className?: string;
  isReverse?: boolean;
  isFetchingNextPage?: boolean;
  isFetchingPreviousPage?: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  onNextPage: () => void;
  onPreviousPage?: () => void;
}

function InfiniteScroll({
  children,
  className,
  isReverse = false,
  isFetchingNextPage = false,
  isFetchingPreviousPage = false,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage,
}: PropsWithChildren<InfiniteScrollProps>) {
  const isFetchingNextPageRef = useRef(false);
  const isFetchingPreviousPageRef = useRef(false);
  const focusingConversationContentRef = useRef(false);
  const listInnerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    throttle(() => {
      if (listInnerRef.current && !focusingConversationContentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

        if (isReverse) {
          const clientScroll = scrollHeight + scrollTop;

          if (clientScroll <= clientHeight) {
            if (!isFetchingNextPageRef.current && hasNextPage) {
              isFetchingNextPageRef.current = true;
              onNextPage();
            }
          } else if (onPreviousPage && scrollTop >= -clientHeight) {
            if (!isFetchingPreviousPageRef.current && hasPreviousPage) {
              isFetchingPreviousPageRef.current = true;
              onPreviousPage();
            }
          }
        } else {
          if (scrollHeight - scrollTop <= clientHeight + 100) {
            if (!isFetchingNextPageRef.current && hasNextPage) {
              isFetchingNextPageRef.current = true;
              onNextPage();
            }
          } else if (onPreviousPage && scrollTop <= 100) {
            if (!isFetchingPreviousPageRef.current && hasPreviousPage) {
              isFetchingPreviousPageRef.current = true;
              onPreviousPage();
            }
          }
        }
      }
    }, 300),
    [isReverse, hasNextPage, hasPreviousPage, onNextPage, onPreviousPage]
  );

  useEffect(() => {
    isFetchingNextPageRef.current = isFetchingNextPage;
  }, [isFetchingNextPage]);

  useEffect(() => {
    isFetchingPreviousPageRef.current = isFetchingPreviousPage;
  }, [isFetchingPreviousPage]);

  return (
    <div
      ref={listInnerRef}
      className={classNames(
        "flex flex-1 overflow-y-auto",
        isReverse ? "flex-col-reverse" : "flex-col",
        className
      )}
      onScroll={handleScroll}
    >
      {isFetchingPreviousPage && <Loading />}
      {children}
      {isFetchingNextPage && <Loading />}
    </div>
  );
}

export default InfiniteScroll;
