import { FlowStepData } from "@hilos/types/flow";

interface FlowExecutionStepContactUpdateDetailProps {
  step: FlowStepData;
}

export default function FlowExecutionStepContactUpdateDetail({
  step,
}: FlowExecutionStepContactUpdateDetailProps) {
  return null;
}
