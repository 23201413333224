import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UpdateInboxContactFn } from "src/hooks/useInboxContactDetails";
import MenuDropdown from "./MenuDropdown";

interface ConversationHeaderOptionsProps {
  isUnread: boolean | null;
  isSilenced: boolean | null;
  isArchived: boolean | null;
  isBlocked: boolean | null;
  handleUpdateInboxContact: UpdateInboxContactFn;
}

function ConversationHeaderOptions({
  isUnread,
  isSilenced,
  isArchived,
  isBlocked,
  handleUpdateInboxContact,
}: ConversationHeaderOptionsProps) {
  const { t } = useTranslation();
  const options = useMemo(() => {
    const nextOptions = [
      {
        id: "is_silenced",
        label: isSilenced
          ? t("inbox:labels.unmute", "Unmute")
          : t("inbox:labels.mute", "Mute"),
        onClick: () =>
          handleUpdateInboxContact({
            is_silenced: !isSilenced,
          }),
      },
      {
        id: "is_archived",
        label: isArchived
          ? t("inbox:labels.unarchive", "Unarchive")
          : t("inbox:labels.archive", "Archive"),
        onClick: () =>
          handleUpdateInboxContact({
            is_archived: !isArchived,
          }),
      },
      {
        id: "is_blocked",
        label: isBlocked
          ? t("inbox:labels.remove-block", "Remove block")
          : t("inbox:labels.block", "Block"),
        onClick: () =>
          handleUpdateInboxContact({
            is_blocked: !isBlocked,
          }),
      },
    ];

    if (!isUnread) {
      nextOptions.push({
        id: "is_unread",
        label: t("inbox:labels.mark-as-unread", "Mark as unread"),
        onClick: () =>
          handleUpdateInboxContact({
            is_unread: true,
          }),
      });
    }

    return nextOptions;
  }, [
    isSilenced,
    isUnread,
    isArchived,
    isBlocked,
    handleUpdateInboxContact,
    t,
  ]);

  return <MenuDropdown options={options} />;
}

export default ConversationHeaderOptions;
