import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { Trans, useTranslation } from "react-i18next";

import { DateTime } from "luxon";
import { IntegrationEventLog } from "@hilos/types/private-schema";
import IntegrationEventLogDetail from "src/containers/integrations/IntegrationEventLogDetail";
import IntegrationEventLogTypeIcon from "src/containers/integrations/IntegrationEventLogTypeIcon";
import Table from "src/components/Table/Table";
import { classNames } from "src/Helpers";
import { useState } from "react";

export default function IntegrationEventLogs() {
  const { t } = useTranslation();
  const [selectedEvent, setSelectedEvent] = useState<
    IntegrationEventLog | undefined
  >(undefined);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:px-6 lg:px-8">
      <div
        className={selectedEvent ? "col-span-2 md:mt-6" : "col-span-3 md:mt-6"}
      >
        <Table
          baseURL={buildAPIRoute(API_ROUTES.INTEGRATION_EVENT_LOG)}
          paginated={true}
          createObject={
            <>
              <Trans i18nKey="integrations:events.no-logs-available">
                <h4>No logs available</h4>
                <p className="text-sm text-gray-500">
                  Once you connect Hilos to your CRM, any errors we find while
                  syncing data will appear here.
                </p>
              </Trans>
            </>
          }
          tableHead={
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="pl-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                ></th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("title")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("source")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("contact")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("flow")}
                </th>
                <th
                  scope="col"
                  className="pr-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("timestamp")}
                </th>
              </tr>
            </thead>
          }
          tableRow={(log: IntegrationEventLog) => (
            <>
              <td
                className={classNames(
                  "whitespace-nowrap pl-4 py-3 text-sm text-gray-500",
                  log === selectedEvent ? "bg-gray-50" : ""
                )}
              >
                <IntegrationEventLogTypeIcon logType={log.log_type} />
              </td>
              <td
                className={classNames(
                  "whitespace-nowrap px-4 py-3 text-sm text-gray-900",
                  log === selectedEvent ? "bg-gray-50" : ""
                )}
              >
                <button
                  type="button"
                  onClick={(_) => setSelectedEvent(log)}
                  className=" text-left"
                >
                  {log.title}
                  <code className="block font-mono text-xs font-normal text-indigo-600">
                    {log.id}
                  </code>
                </button>
              </td>
              <td
                className={classNames(
                  "whitespace-nowrap px-4 py-3 text-sm text-gray-900",
                  log === selectedEvent ? "bg-gray-50" : ""
                )}
              >
                <button type="button" onClick={(_) => setSelectedEvent(log)}>
                  {log.source}
                </button>
              </td>
              <td
                className={classNames(
                  "whitespace-nowrap px-4 py-3 text-sm text-gray-900",
                  log === selectedEvent ? "bg-gray-50" : ""
                )}
              >
                <button
                  type="button"
                  onClick={(_) => setSelectedEvent(log)}
                  className="text-left"
                >
                  <div className="capitalize">{log.contact_phone}</div>
                  <code className="block font-mono text-xs font-normal text-indigo-600">
                    {log.contact_id}
                  </code>
                </button>
              </td>
              <td
                className={classNames(
                  "whitespace-nowrap px-4 py-3 text-sm text-gray-900",
                  log === selectedEvent ? "bg-gray-50" : ""
                )}
              >
                <button
                  type="button"
                  onClick={(_) => setSelectedEvent(log)}
                  className="text-left"
                >
                  <div className="capitalize">{log.flow_name}</div>
                  <code className="block font-mono text-xs font-normal text-indigo-600">
                    {log.flow_id}
                  </code>
                </button>
              </td>
              <td
                className={classNames(
                  "whitespace-nowrap pr-4 py-3 text-sm text-gray-500",
                  log === selectedEvent ? "bg-gray-50" : ""
                )}
              >
                <button type="button" onClick={(_) => setSelectedEvent(log)}>
                  {DateTime.fromISO(log.timestamp as string).toLocaleString(
                    DateTime.DATETIME_SHORT
                  )}
                </button>
              </td>
            </>
          )}
          dataName={t("integration-event-logs", "Integration event logs")}
          noResults={
            <>
              <Trans i18nKey="integrations:events.no-logs-available">
                <h4>No logs available</h4>
                <p className="text-sm text-gray-500">
                  Once you connect Hilos to your CRM, any errors we find while
                  syncing data will appear here.
                </p>
              </Trans>
            </>
          }
        ></Table>
      </div>
      {selectedEvent && (
        <div className="mt-5 md:mt-6 border-t border-b border-gray-200 bg-white shadow sm:rounded-lg sm:border">
          <div className="sm:overflow-hidden sm:rounded-md bg-white px-4 py-5 sm:p-6">
            <IntegrationEventLogDetail logEvent={selectedEvent} />
          </div>
        </div>
      )}
    </div>
  );
}
