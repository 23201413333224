import { useTranslation } from "react-i18next";

interface FlowExecutionStepDetailNavProps {
  currentStep: string;
  setCurrentStep: (x: string) => void;
}

export default function FlowExecutionStepDetailNav({
  currentStep,
  setCurrentStep,
}: FlowExecutionStepDetailNavProps) {
  const { t } = useTranslation();
  const STEPS = [
    {
      name: t(
        "flow-executions:flow-execution-step.nav.step-detail",
        "Step Detail"
      ),
      stepName: "step",
      current: currentStep === "step",
    },
    {
      name: t(
        "flow-executions:flow-execution-step.nav.execution-step-result",
        "Execution Result"
      ),
      stepName: "execution-step",
      current: currentStep === "execution-step",
    },
  ];

  return (
    <nav aria-label="Nav">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-5">
        {STEPS.map((step) => (
          <li key={step.name} className="md:flex-1">
            <>
              {step.current ? (
                <button
                  type="button"
                  onClick={(_) => setCurrentStep(step.stepName)}
                  className="group flex w-full flex-col border-l-4 border-blue-600 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                  aria-current="step"
                >
                  <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-blue-600">
                    {t(step.name)}
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={(_) => setCurrentStep(step.stepName)}
                  className="group flex w-full flex-col border-l-4 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0 border-gray-200 hover:border-gray-300"
                >
                  <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-gray-600">
                    {t(step.name)}
                  </span>
                </button>
              )}
            </>
          </li>
        ))}
      </ol>
    </nav>
  );
}
