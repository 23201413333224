import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

export default function DisclosureCard({ action, index }) {
  const { t } = useTranslation();

  return (
    <Disclosure
      as="div"
      key={index}
      className={`p-2 my-3 ${
        action.variant
          ? "bg-gradient-to-tl from-hilos-alt-300 via-hilos-alt-500 to-hilos-alt-700"
          : "bg-white"
      } shadow rounded-md`}
    >
      {({ open }) => (
        <>
          <dt>
            <Disclosure.Button className="flex rounded-md p-1 relative overflow-clip w-full items-center justify-between text-left text-gray-900 ">
              <div
                className={`z-0 h-56 -ml-10 absolute left-0 ${
                  open ? "w-80" : "w-40"
                } bg-${action.color} bg-opacity-5 rotate-12`}
              />
              <div
                className={`z-0 h-56 -ml-10 absolute left-0 ${
                  open ? "w-72" : "w-36"
                } bg-${action.color} bg-opacity-5 rotate-12`}
              />
              <div className="z-10 w-full flex py-2 flex-wrap items-center">
                <div className="flex flex-nowrap items-center">
                  <div
                    className={`ml-4 rounded-full flex items-center justify-center h-12 w-12 bg-${action.color} bg-opacity-10`}
                  >
                    <div
                      className={`rounded-full flex items-center justify-center h-8 w-8 bg-${action.color}`}
                    >
                      {action.icon}
                    </div>
                  </div>
                  <span
                    className={`z-10 ml-4 text-base ${
                      action.variant ? "text-white" : "text-neutral-dark"
                    } font-semibold leading-7`}
                  >
                    {t(action.title)}
                  </span>
                </div>
                <div className="w-full flex mt-2">
                  <div
                    className={`font-semibold text-sm rounded-md  ${
                      action.variant
                        ? "bg-opacity-20 bg-white text-white"
                        : " text-slate-500"
                    } bg-white  ml-2 px-3 py-1 self-center `}
                  >
                    {action.estimatedTimeMin} min
                  </div>
                  <div
                    className={`z-10 ml-auto flex h-10 rounded-full ${
                      action.variant
                        ? "bg-opacity-20 bg-white text-white"
                        : "bg-gray-100 text-gray-600"
                    }   w-10 items-center justify-center`}
                  >
                    {open ? (
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </div>
                </div>
              </div>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel className="mt-2">
            <div className="">{action.firstSection}</div>
            {action.secondSection && (
              <div className="">{action.secondSection}</div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
