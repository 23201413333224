import { useState } from "react";
import ChannelFilterField from "../Form/ChannelFilterField";

interface TableFilterProps {
  filterName: string;
  onFilterChange: (key: string, val: string) => void;
}

export default function TableFilter({
  filterName,
  onFilterChange,
}: TableFilterProps) {
  const [selected, setSelected] = useState<number[] | string[]>();

  const handleChannelFilterChange = (selectedChannels) => {
    const filterValue = selectedChannels.map((channel) => channel.value);
    setSelected(filterValue);
    if (filterValue.length > 0) {
      onFilterChange("channel__in", filterValue.join(","));
    } else {
      onFilterChange("channel__in", "");
    }
  };

  if (filterName === "channel") {
    return (
      <ChannelFilterField
        handleOptionChange={handleChannelFilterChange}
        //@ts-ignore
        selected={selected}
      />
    );
  }
  return null;
}
