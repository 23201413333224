import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import {
  ArrowSmRightIcon,
  ClipboardListIcon,
  DocumentDownloadIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";

import HeaderNavigation from "src/components/HeaderNavigation";
import InvoiceStatusBadge from "./InvoiceStatusBadge";
import { Link } from "react-router-dom";
import { StripeInvoice } from "@hilos/types/private-schema";
import Table from "src/components/Table/Table";
import { format } from "date-fns";
import { formatPrice } from "src/Helpers";

export default function AccountInvoiceList() {
  const { t } = useTranslation();
  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings"),
              url: buildRoute("config-account"),
            },
            {
              name: t("settings:payment-history.label"),
              url: buildRoute("config-payment-history"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <Trans i18nKey="settings:payment-history.description">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ClipboardListIcon
                  className="mr-4 h-7 w-7"
                  aria-hidden="true"
                />
                Past invoices
              </h2>
              <p className="mt-1 text-sm text-gray-600">
                See and download past Stripe invoices.
              </p>
            </Trans>
          </div>
        </div>
      </div>

      <div className="min-h-screen bg-gray-50">
        <div className="h-full sm:px-6 sm:pt-8 lg:px-8">
          <Table
            baseURL={buildAPIRoute(API_ROUTES.STRIPE_INVOICE_LIST)}
            paginated={true}
            tableHead={
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("name")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("period")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("status")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t("total", "Total")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                  ></th>
                </tr>
              </thead>
            }
            tableRow={(invoice: StripeInvoice) => (
              <>
                <td className="whitespace-nowrap px-4 py-3 text-sm font-medium text-gray-900">
                  {invoice.stripe_invoice_url ? (
                    <a
                      href={invoice.stripe_invoice_url}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center text-blue-500"
                    >
                      {invoice.stripe_name}
                      <ExternalLinkIcon className="ml-1 h-4 w-4" />
                    </a>
                  ) : (
                    <>{invoice.stripe_name}</>
                  )}
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                  {format(new Date(invoice.start_on as string), "d/MMM/yy")} -{" "}
                  {format(new Date(invoice.end_on as string), "d/MMM/yy")}
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                  <InvoiceStatusBadge status={invoice.status} />
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                  <span className="font-semibold">
                    {formatPrice(invoice.total / 100)}
                  </span>{" "}
                  {invoice.currency}
                </td>
                <td className="whitespace-nowrap px-4 py-3 text-sm text-blue-500">
                  {invoice.stripe_invoice_pdf_url ? (
                    <a
                      href={invoice.stripe_invoice_pdf_url}
                      rel="noreferrer"
                      target="_blank"
                      className="flex items-center"
                    >
                      {t("download-pdf", "Download PDF")}
                      <DocumentDownloadIcon className="ml-1 h-5 w-5" />
                    </a>
                  ) : (
                    <Link
                      to={buildRoute("config-account-subscription")}
                      className="flex items-center"
                    >
                      {t("see-details", "See details")}
                      <ArrowSmRightIcon className="ml-1 h-5 w-5" />
                    </Link>
                  )}
                </td>
              </>
            )}
            dataName={t("past-invoices", "Past invoices")}
            noResults={
              <>
                <Trans i18nKey="settings:payment-history.no-invoices">
                  <h4>No invoices found.</h4>
                  <p className="text-gray-500">
                    They will appear here when they're paid.
                  </p>
                </Trans>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}
