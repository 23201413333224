import { useMemo } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import { Label } from "src/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import useAccount from "src/hooks/useAccount";
import useUser from "src/hooks/useAccountMember";
import { getUserAvatarSource, userToString } from "src/Helpers";

interface UserSelectorProps {
  users?: number[];
}

function SelectUser({ userId }) {
  const user = useUser(userId);

  const [avatar, name] = useMemo(
    () => [getUserAvatarSource(user), userToString(user)],
    [user]
  );

  if (!user) {
    return null;
  }

  return (
    <SelectItem value={userId}>
      <span className="flex items-center gap-2">
        <Avatar>
          <AvatarImage src={avatar} />
          <AvatarFallback>{name.slice(0, 2).toUpperCase()}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col w-full items-start">
          <span className="block text-sm font-medium leading-none">{name}</span>
          <span className="mt-0.5 block text-xs text-muted-foreground">
            {user.email}
          </span>
        </div>
      </span>
    </SelectItem>
  );
}

export default function UserSelector({ users = [] }: UserSelectorProps) {
  const { members } = useAccount();

  return (
    <div className="space-y-2">
      <Label htmlFor="select-40">Assigned to</Label>
      <Select>
        <SelectTrigger
          id="select-40"
          className="h-auto ps-2 [&>span]:flex [&>span]:items-center [&>span]:gap-2 [&>span_img]:shrink-0"
        >
          <SelectValue placeholder="Choose a member" />
        </SelectTrigger>
        <SelectContent className="[&_*[role=option]>span]:end-2 [&_*[role=option]>span]:start-auto [&_*[role=option]]:pe-8 [&_*[role=option]]:ps-2">
          {members.map((member) => (
            <SelectUser key={member.id} userId={member.id} />
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
