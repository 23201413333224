import { useState } from "react";
import useHilosStore from "src/hooks/useHilosStore";
import SubscriptionV2CheckoutModal from "./SubscriptionV2CheckoutModal";
import SubscriptionV2PlanComparison from "./SubscriptionV2PlanComparison";
import SubscriptionV2SelectPlanEnterprise from "./SubscriptionV2SelectPlanEnterprise";

interface SubscriptionV2SelectPlanProps {
  currency: string;
}

export default function SubscriptionV2SelectPlan({
  currency,
}: SubscriptionV2SelectPlanProps) {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const { session } = useHilosStore();

  const handleHideCheckout = () => {
    setSelectedPlan(null);
  };

  return (
    <>
      {session?.account.pricing_plan_selected === "ENTERPRISE" ? (
        <SubscriptionV2SelectPlanEnterprise
          currency={currency}
          onSelectPlan={setSelectedPlan}
        />
      ) : (
        <SubscriptionV2PlanComparison
          currency={currency}
          onSelectPlan={setSelectedPlan}
          activePlan="LAUNCH"
          disableActivePlan={false}
        />
      )}

      {selectedPlan && (
        <SubscriptionV2CheckoutModal
          selectedPlan={selectedPlan}
          handleHide={handleHideCheckout}
        />
      )}
    </>
  );
}
