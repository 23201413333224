import { ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { GroupBase, Options, OptionsOrGroups } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { useField, useFormikContext } from "formik";
import useTouchField from "src/containers/flow/builder/hooks/useTouchField";
import { BROWSER_IS_SAFARI_IOS, hasItems } from "src/helpers/utils";
import { classNames } from "../../Helpers";
import FieldContainer from "./FieldContainer";

interface SelectorFieldProps<Option, Group extends GroupBase<Option>> {
  name: string;
  label?: string;
  help?: string;
  optional?: boolean;
  isMulti?: boolean;
  isCreatable?: boolean;
  isOptionDisabled?: (option: Option, selectValue: Options<Option>) => boolean;
  placeholder?: string;
  options?: OptionsOrGroups<Option, Group>;
  defaultOptions?: boolean;
  renderHelp?: JSX.Element;
  onSelect?: (option: Option) => void;
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsOrGroups<Option, Group>) => void
  ) => Promise<OptionsOrGroups<Option, Group>> | void;
  helpDocsLink?: string;
  singleValueStyles?: { [key: string]: any };
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
  formatGroupLabel?: (data: GroupBase<Option>) => ReactNode;
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>
  ) => ReactNode;
  filterOption?: (
    candidate: FilterOptionOption<Option>,
    input: string
  ) => boolean;
}

function getValueFromOptions(options: any, value: string) {
  if (hasItems(options)) {
    for (const option of options) {
      if (hasItems(option.options)) {
        const groupOption = option.options.find(
          (optionItem) => optionItem.value === value
        );

        if (groupOption) {
          return groupOption;
        }
      } else if (option.value === value) {
        return option;
      }
    }
  }

  if (!value) {
    return;
  }

  return { label: value, value };
}

export const getSelectorStyles = (
  error: boolean | string = false,
  singleValueStyles = {}
) => ({
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  singleValue: (base) => ({
    ...base,
    ...singleValueStyles,
  }),
  control: (base, state) => ({
    ...base,
    // state.isFocused can display different borderColor if you need it
    borderColor: state.isFocused ? "#ddd" : error ? "#e63757" : "#ddd",
    // overwrittes hover style
    "&:hover": {
      borderColor: state.isFocused ? "#ddd" : error ? "#e63757" : "#ddd",
    },
    "*": {
      boxShadow: "none !important",
    },
  }),
  option: (base, { isFocused, isSelected, isDisabled }) => {
    return {
      ...base,
      wordBreak: "break-all",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#FFF5F7"
        : isFocused
        ? "#FFF5F7"
        : undefined,
      color: isDisabled
        ? "#d1d5db"
        : isSelected
        ? "#d54466"
        : isFocused
        ? "#d54466"
        : undefined,
      fontSize: "small",
      paddingLeft: "30px",
    };
  },
  placeholder: (base) => ({
    ...base,
    fontSize: "small",
  }),
});

function SelectorField<Option, Group extends GroupBase<Option>>({
  name,
  label,
  options,
  help,
  optional,
  isMulti = false,
  isCreatable = false,
  placeholder,
  renderHelp,
  onSelect,
  loadOptions,
  helpDocsLink,
  singleValueStyles = {},
  ...props
}: SelectorFieldProps<Option, Group>) {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const error = meta.touched && meta.error;

  useTouchField(helpers);

  const RenderSelector = useMemo(() => {
    if (loadOptions) {
      if (isCreatable) {
        return AsyncCreatableSelect;
      }
      return AsyncSelect;
    }

    if (isCreatable) {
      return CreatableSelect;
    }

    return Select;
  }, [loadOptions, isCreatable]);

  const styles = useMemo(
    () => getSelectorStyles(error, singleValueStyles),
    [error, singleValueStyles]
  );

  const value = useMemo(() => {
    if (!field.value) {
      return null;
    }

    if (isMulti) {
      return field.value.map((optionValue) =>
        getValueFromOptions(options, optionValue)
      );
    }

    return getValueFromOptions(options, field.value);
  }, [isMulti, options, field.value]);

  const handleBlur = useCallback(
    (event) => {
      event.preventDefault();
      setFieldTouched(field.name);
    },
    [setFieldTouched, field.name]
  );

  const handleChange = useCallback(
    (nextOption) => {
      const nextValue = isMulti
        ? nextOption.map((option) => option.value)
        : nextOption.value;

      setFieldValue(field.name, nextValue);

      if (onSelect) {
        onSelect(nextOption);
      }
    },
    [field.name, isMulti, onSelect, setFieldValue]
  );

  return (
    <FieldContainer
      name={name}
      label={t(label || "")}
      help={help ? t(help) : renderHelp}
      optional={optional}
      error={error}
      helpDocsLink={helpDocsLink}
    >
      <div className={classNames(label && "mt-1")}>
        <RenderSelector
          isMulti={isMulti}
          value={value || null}
          options={options}
          menuShouldBlockScroll
          menuPosition={BROWSER_IS_SAFARI_IOS ? "absolute" : "fixed"}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          styles={styles}
          placeholder={t(placeholder || "")}
          onBlur={handleBlur}
          onChange={handleChange}
          loadOptions={loadOptions}
          {...props}
        />
      </div>
    </FieldContainer>
  );
}

export default SelectorField;
