import { useCallback } from "react";
import useHilosStore from "src/hooks/useHilosStore";
import i18n from "src/i18n";

const LangToggle = () => {
  const { language, changeLanguage } = i18n;
  const { updateSession } = useHilosStore();

  const handleChangeLanguage = useCallback(
    (nextLang) => {
      changeLanguage(nextLang);
      updateSession({
        default_language: nextLang,
      });
    },
    [changeLanguage, updateSession]
  );

  return (
    <div className="lang-toggle">
      {language && language.toLowerCase().indexOf("en") === 0 ? (
        <button
          type="button"
          onClick={() => handleChangeLanguage("es")}
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          🇲🇽
        </button>
      ) : (
        <button
          type="button"
          onClick={() => handleChangeLanguage("en")}
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          🇺🇸
        </button>
      )}
    </div>
  );
};

export default LangToggle;
