import { useMemo } from "react";

interface ConversationStatusIndicatorProps {
  color?: string;
}

function ConversationStatusIndicator({
  color = "red",
}: ConversationStatusIndicatorProps) {
  const spanClassName = useMemo(() => {
    return {
      green: "ml-1 text-green-500",
      yellow: "ml-1 text-yellow-500",
      red: "ml-1 text-red-500",
    }[color];
  }, [color]);

  return (
    <span className={spanClassName}>
      <svg width="8" height="8">
        <circle cx="4" cy="4" r="4" fill="currentColor"></circle>
      </svg>
    </span>
  );
}

export default ConversationStatusIndicator;
