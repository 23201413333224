import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  ClockIcon,
  InformationCircleIcon,
  PlayIcon,
  UserIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";
import { DateTime } from "luxon";
import FlowExecutionContactList from "src/containers/flow/flow_execution_contact/FlowExecutionContactList";
import Tabs from "src/components/Tabs";
import {
  useFlowExecution,
  useUpdateFlowExecutionDetailQuery,
} from "src/hooks/useFlowExecution";
import useFlowVersion from "src/hooks/useFlowVersion";
import { userToString } from "../../../Helpers";
import HeaderNavigation from "../../../components/HeaderNavigation";
import { buildRoute } from "../../../router/router";
import FlowAnalyticsSummary from "../FlowAnalyticsSummary";
import FlowDetail from "../builder/FlowDetail";
import FlowExecutionControlsButtons from "../flow_execution_contact/FlowExecutionControlsButtons";
import FlowVersionResultDownload from "../flow_version/FlowVersionResultsDownload";
import FlowExecutionStatusBadge from "./FlowExecutionStatusBadge";

export default function FlowExecutionDetail() {
  const params = useParams();
  const flowExecutionId = params.id as string;
  const { t } = useTranslation();

  const flowExecution = useFlowExecution(flowExecutionId);
  const { data: flowVersion } = useFlowVersion(flowExecution?.flow_version.id);

  const onFlowExecutionStatusUpdateSuccess =
    useUpdateFlowExecutionDetailQuery(flowExecutionId);

  if (!flowVersion || !flowExecution) {
    return null;
  }

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: "Flow list",
              url: buildRoute("flow-list"),
            },
            {
              name: `Flow ${flowExecution?.flow?.name}`,
              url: buildRoute("flow-detail", {
                ":id": flowExecution?.flow?.id,
              }),
            },
            {
              name: `Flow execution ${flowExecution?.id}`,
              url: buildRoute("flow-execution-detail", {
                ":id": flowExecution?.id,
              }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <PlayIcon className="mr-4 h-7 w-7 rotate-90" aria-hidden="true" />
              Flow execution {flowExecution?.id}
            </h2>
          </div>
        </div>
      </div>

      {flowExecution && (
        <>
          <div className="my-6 grid grid-cols-1 grid-rows-2 gap-4 px-6 md:grid-cols-4 md:grid-rows-1">
            <div className="col-span-2 rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                Summary
              </h3>
              {flowExecution.status === "READY" &&
                (flowExecution.start_on ? (
                  <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ClockIcon
                          className="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <div className="text-sm text-blue-700">
                          {t("scheduled-on", "Scheduled on")}
                          <span className="font-semibold ml-2">
                            {DateTime.fromISO(
                              flowExecution.start_on
                            ).toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-blue-700">
                          {t(
                            "flow-executions:ready-to-start",
                            "This flow will start in a few seconds..."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="justify-left mt-2 flex items-center text-sm">
                <div className="grow-0 text-gray-400">
                  <PlayIcon className="-ml-1 mr-4 h-7 w-7" aria-hidden="true" />
                </div>
                <div className="mr-4 w-full grow text-sm">
                  <p className="text-base font-medium text-indigo-600">
                    {flowExecution.id}
                  </p>
                  <div>
                    <FlowExecutionStatusBadge status={flowExecution.status} />
                  </div>
                  <p>Execution for {flowExecution?.flow?.name}</p>
                  <p className="text-gray-400">
                    Created by{" "}
                    <span className="font-medium text-gray-700">
                      {userToString(flowExecution.created_by)}
                    </span>
                  </p>
                  <p className="text-gray-400">
                    Created on{" "}
                    <span className="font-medium text-gray-700">
                      {flowExecution?.created_on &&
                        DateTime.fromISO(
                          flowExecution?.created_on
                        ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
                    </span>
                  </p>
                </div>
                <div className="grow-0">
                  <FlowExecutionControlsButtons
                    flowExecutionId={flowExecutionId}
                    flowExecutionStatus={flowExecution.status}
                    onFlowExecutionStatusUpdateSuccess={
                      onFlowExecutionStatusUpdateSuccess
                    }
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                Contacts in flow
              </h3>
              <div className="mt-1 text-sm">
                <div className="header-title">{flowExecution.num_contacts}</div>

                {flowExecution.contact_filters &&
                  flowExecution.contact_filters.length > 0 && (
                    <>
                      <h3 className="mt-2 text-tiny font-medium uppercase tracking-wider text-gray-500">
                        Filters applied
                      </h3>
                      {flowExecution.contact_filters?.map(
                        (flowExecutionFilter, idx) => (
                          <p key={idx} className="text-sm text-gray-600">
                            {flowExecutionFilter.field} -{" "}
                            {flowExecutionFilter.comparison} -{" "}
                            {flowExecutionFilter.value}
                          </p>
                        )
                      )}
                    </>
                  )}
              </div>

              <div className="mt-2">
                <FlowVersionResultDownload
                  flowVersion={flowExecution.flow_version}
                  flowExecutionId={flowExecutionId}
                />
              </div>
            </div>
          </div>

          <FlowAnalyticsSummary flowObject={flowExecution} />

          <Tabs
            tabs={[
              {
                label: t("flow-viewer", "Flow Viewer"),
                icon: ViewGridIcon,
              },
              {
                label: t("contacts", "Contacts"),
                icon: UserIcon,
              },
            ]}
            className="px-4 sm:px-6"
          >
            <FlowDetail
              flowVersion={flowVersion}
              flowExecutionId={flowExecutionId}
            />
            <FlowExecutionContactList
              flowId={flowExecution.flow.id}
              flowExecutionId={flowExecutionId}
            />
          </Tabs>
        </>
      )}
    </div>
  );
}
