import { useTranslation } from "react-i18next";
import Editor from "react-simple-code-editor";
import { GlobeAltIcon } from "@heroicons/react/outline";
import { DateTime } from "luxon";
import { highlight, languages } from "prismjs";
import { WebhookSubscriptionLogBasic } from "@hilos/types/private-schema";
import useWebhookSubscriptionLog from "src/hooks/useWebhookSubscriptionLog";

interface WebhookSubscriptionLogDetailProps {
  webhookSubscriptionBasic: WebhookSubscriptionLogBasic;
}

export default function WebhookSubscriptionLogDetail({
  webhookSubscriptionBasic,
}: WebhookSubscriptionLogDetailProps) {
  const { t } = useTranslation();
  const webhookEnpointLogDetail = useWebhookSubscriptionLog(
    webhookSubscriptionBasic.id as string
  );

  if (!webhookEnpointLogDetail) {
    return (
      <div className="card">
        <div className="card-body text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">{t("loading")}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="">
        <dl className="text-sm sm:divide-y sm:divide-gray-200">
          <div className="pb-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:pb-3">
            <dt className="text-sm font-medium text-gray-500">
              {t("id", "ID")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <code className="text-indigo-500 font-mono text-sm">
                {webhookEnpointLogDetail.id}
              </code>
            </dd>
          </div>
          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500">Event</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <code className="text-indigo-500">
                {webhookEnpointLogDetail.event}
              </code>
            </dd>
          </div>

          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500">
              {t("timestamp")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <time
                dateTime={DateTime.fromISO(
                  webhookEnpointLogDetail.timestamp as string
                ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              >
                {DateTime.fromISO(
                  webhookEnpointLogDetail.timestamp as string
                ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              </time>
            </dd>
          </div>

          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500">
              {t("api-version", "API version")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {webhookEnpointLogDetail.api_version}
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-4">
        <div className="flex flex-cols justify-between items-center">
          <h6 className="text-tiny uppercase tracking-wider text-gray-400">
            {t("response")}
          </h6>
          {webhookEnpointLogDetail.succeeded ? (
            <code className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium text-green-800">
              HTTP {webhookEnpointLogDetail.response_status}
            </code>
          ) : (
            <code className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium text-red-800">
              HTTP{" "}
              {(webhookEnpointLogDetail.response_status as number) > 0
                ? webhookEnpointLogDetail.response_status
                : "N/A"}
            </code>
          )}
        </div>
        <div className="mt-2 flex items-center justify-between">
          <div className="justify-left flex items-center">
            <GlobeAltIcon
              className="h-3 w-3 text-gray-400"
              aria-hidden="true"
            />
            <code className="ml-1 text-xs text-indigo-500">
              {webhookEnpointLogDetail.request_url}
            </code>
          </div>
        </div>
        {webhookEnpointLogDetail.response_body ? (
          <div className="mt-3 overflow-x-auto rounded-md bg-gray-100">
            {(webhookEnpointLogDetail.response_content_type as string).includes(
              "json"
            ) ? (
              <code className="mt-2 whitespace-pre p-2 text-xs">
                <Editor
                  placeholder={t(
                    "integrations:webhooks.response-body.placeholder",
                    "Response body"
                  )}
                  value={JSON.stringify(
                    JSON.parse(webhookEnpointLogDetail.response_body),
                    null,
                    2
                  )}
                  onValueChange={(body) => null}
                  readOnly
                  className="my-1 bg-slate-50"
                  highlight={(body) => highlight(body, languages.json, "json")}
                  padding={10}
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                    minHeight: 100,
                  }}
                />
              </code>
            ) : (
              <code className="mt-2 whitespace-pre p-2 text-xs">
                {JSON.stringify(webhookEnpointLogDetail.response_body, null, 2)}
              </code>
            )}
          </div>
        ) : (
          <span className="text-sm text-gray-500">
            {t(
              "integrations:webhooks.no-response-received",
              "No response received."
            )}
          </span>
        )}

        <hr className="my-4" />
        <h6 className="text-tiny uppercase tracking-wider text-gray-400">
          {t("request", "Request")}
        </h6>

        <div className="mt-3 overflow-x-auto rounded-md bg-gray-100">
          <code className="mt-2 whitespace-pre p-2 text-xs">
            <Editor
              placeholder="Request body"
              value={JSON.stringify(
                JSON.parse(
                  webhookEnpointLogDetail.request_body as unknown as string
                ),
                null,
                2
              )}
              onValueChange={(body) => null}
              readOnly
              className="my-1 bg-slate-50"
              highlight={(body) => highlight(body, languages.json, "json")}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                minHeight: 100,
              }}
            />
          </code>
        </div>
      </div>
    </>
  );
}
