import { useTranslation } from "react-i18next";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { userAvatar } from "../../../../Helpers";

export default function ContactsMessage({ message, isPreview = false }) {
  const { t } = useTranslation();

  return (
    <ul className="space-y-2">
      {message.contacts.map((contact, idx) => (
        <li
          key={idx}
          className={`col-span-1 rounded-lg ${
            !isPreview ? "bg-white divide-y divide-gray-200" : ""
          } `}
        >
          <div className="flex w-full items-center justify-between space-x-6 p-4">
            <div className="mr-4">
              {userAvatar({
                first_name: contact.name.first_name,
                last_name: contact.name.last_name,
              })}
            </div>
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {contact.name.formatted_name}
                </h3>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">
                {contact.phones.length > 0 && contact.phones[0].phone}
              </p>
            </div>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              {contact.emails.length > 0 && !isPreview && (
                <div className="flex w-0 flex-1">
                  <a
                    href={`mailto:${contact.emails[0].email}`}
                    className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-600"
                  >
                    <MailIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{t("email", "Email")}</span>
                  </a>
                </div>
              )}
              {contact.phones.length > 0 && !isPreview && (
                <div className="-ml-px flex w-0 flex-1">
                  <a
                    href={`tel:${contact.phones[0].phone}`}
                    className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-600"
                  >
                    <PhoneIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{t("call", "Call")}</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
