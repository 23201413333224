import { useTranslation } from "react-i18next";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import Loading from "src/components/Loading";
import { hasItems } from "src/helpers/utils";
import { InboxContactViewData } from "src/hooks/useInboxContactViews";
import ConversationListViewItem, {
  onDeleteViewFn,
} from "./ConversationListViewItem";

interface ConversationListViewListProps {
  inboxContactViews: InboxContactViewData[];
  currentViewId: string | null;
  isLoading?: boolean;
  isInboxManager: boolean;
  currentAvailableChannels: ChannelAvailabilityData[];
  allowQueryCountWithLimit: boolean;
  allowQueriesWithSQLFunctions: boolean;
  onPinView: (id: string, isPinned: boolean) => void;
  onSelectView: (id: string) => void;
  onUpdateView: (id: string) => void;
  onDeleteView: onDeleteViewFn;
}

function ConversationListViewList({
  inboxContactViews,
  currentViewId,
  isLoading,
  isInboxManager,
  currentAvailableChannels,
  allowQueryCountWithLimit,
  allowQueriesWithSQLFunctions,
  onPinView,
  onSelectView,
  onUpdateView,
  onDeleteView,
}: ConversationListViewListProps) {
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  if (!hasItems(inboxContactViews)) {
    return (
      <div className="space-y-1 py-2 px-4">
        <h1 className="text-center text-sm font-medium text-gray-900">
          {t(
            "inbox:views.no-views.title",
            "Looks like you don’t have any views yet."
          )}
        </h1>
        <p className="text-center text-sm text-gray-500">
          {t(
            "inbox:views.no-views.description",
            "Save sets of filters to quickly get to the conversations you use the most."
          )}
        </p>
      </div>
    );
  }

  return (
    <>
      {inboxContactViews.map((view, index) => (
        <ConversationListViewItem
          key={`${view.id}_${index}`}
          view={view}
          currentViewId={currentViewId}
          isInboxManager={isInboxManager}
          currentAvailableChannels={currentAvailableChannels}
          allowQueryCountWithLimit={allowQueryCountWithLimit}
          allowQueriesWithSQLFunctions={allowQueriesWithSQLFunctions}
          onSelect={onSelectView}
          onUpdate={onUpdateView}
          onDelete={onDeleteView}
          onPinView={onPinView}
        />
      ))}
    </>
  );
}

export default ConversationListViewList;
