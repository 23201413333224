import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";
import { FlowExecutionStepReadDetailStatusEnum } from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import { classNames } from "src/Helpers";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import FlowViewerStepExecutionContactList from "./FlowViewerStepExecutionContactList";
import { EXECUTION_STATUS_TYPES } from "./constants/flow";

type FlowExecutionsByStatus = {
  [key in FlowExecutionStepReadDetailStatusEnum]?: number;
};

interface FlowViewerStepExecutionListProps {
  stepId: string;
  flowExecutionId?: string;
  flowExecutionContactId?: string;
}

async function fetchFlowStepAnalytics({ queryKey }) {
  const [_, stepId, flowExecutionId, flowExecutionContactId] = queryKey;
  const executionsByStatus: FlowExecutionsByStatus = {};
  let totalExecutionsCount = 0;

  const { data } = await axios.get(
    buildAPIRoute(API_ROUTES.FLOW_STEP_ANALYTICS, {
      ":id": stepId,
    }),
    {
      params: {
        flow_execution: flowExecutionId,
        flow_execution_contact: flowExecutionContactId,
      },
    }
  );

  if (Array.isArray(data)) {
    for (const execution of data) {
      if (execution) {
        const executionCount = execution.executions_count || 0;
        executionsByStatus[execution.status] = executionCount;
        totalExecutionsCount += executionCount;
      }
    }
  }

  return { totalExecutionsCount, executionsByStatus };
}

function FlowViewerStepExecutionList({
  stepId,
  flowExecutionId,
  flowExecutionContactId,
}: FlowViewerStepExecutionListProps) {
  const [t] = useTranslation();

  const { data, isLoading } = useQuery(
    ["flow_step_analytics", stepId, flowExecutionId, flowExecutionContactId],
    fetchFlowStepAnalytics
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="w-full pt-2 pb-4 grid grid-cols-3 grid-rows-2 grid-flow-row">
        <div className="col-span-1 grid grid-rows-3 place-content-center place-items-center border-r border-gray-200 py-2">
          <span className="col-span-1 text-xs font-semibold text-gray-600">
            {t("flows:viewer.execution-step-detail.total-executions", "TOTAL")}
          </span>
          <span className="col-span-1 font-bold text-2xl text-gray-800 leading-none">
            {data.totalExecutionsCount}
          </span>
        </div>
        {EXECUTION_STATUS_TYPES.map(({ id, label }, index) => (
          <div
            key={id}
            className={classNames(
              "col-span-1 grid grid-rows-3 place-content-center place-items-center py-2",
              index > 1 && "border-t border-gray-200",
              ![1, 4].includes(index) && "border-r border-gray-200"
            )}
          >
            <span className="col-span-1 text-xs font-semibold text-gray-600">
              {t(label)}
            </span>
            <span className="col-span-1 font-bold text-2xl text-gray-800 leading-none">
              {data.executionsByStatus[id] || 0}
            </span>
            <span className="col-span-1 text-xs text-gray-600">
              {Math.round(
                Math.max(
                  ((data.executionsByStatus[id] || 0) * 100) /
                    data.totalExecutionsCount || 0,
                  0
                )
              )}
              {" %"}
            </span>
          </div>
        ))}
      </div>
      <FlowViewerStepExecutionContactList
        stepId={stepId}
        flowExecutionId={flowExecutionId}
        flowExecutionContactId={flowExecutionContactId}
      />
    </>
  );
}

export default FlowViewerStepExecutionList;
