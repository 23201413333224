import { useTranslation } from "react-i18next";
import { useStripeProductList } from "src/hooks/useStripe";
import SubscriptionExtrasCard from "./SubscriptionExtrasCard";
import SubscriptionPlanCard from "./SubscriptionPlanCard";

interface SubscriptionV1SelectPlanProps {
  currency: string;
}

export default function SubscriptionV1SelectPlan({
  currency,
}: SubscriptionV1SelectPlanProps) {
  const { t } = useTranslation();
  const { products } = useStripeProductList();
  const plans = products.filter((product) => !product.is_addon);
  const addons = products.filter((product) => product.is_addon);

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {t("settings:subscription.select-plan", "Select a plan")}
        </h3>
        <p className="mt-1 flex items-center text-sm text-gray-600">
          {t("settings:pricing.showing-plans", "Showing plans in")}
          <span className="ml-1 font-medium text-gray-900">
            ${currency.toLocaleUpperCase()}
          </span>
        </p>
      </div>
      <div className="mt-2 space-y-6 bg-gray-100">
        {plans.map((product) => (
          <div
            className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none"
            key={product.id}
          >
            <SubscriptionPlanCard
              product={product}
              showCheckout={true}
              currency={currency}
            />
          </div>
        ))}

        <SubscriptionExtrasCard addons={addons} asCard={true} />
      </div>
    </div>
  );
}
