import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MailIcon } from "@heroicons/react/outline";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import useHilosStore from "src/hooks/useHilosStore";
import {
  COUNTRIES_WITH_FLAGS,
  formatCountryOptionLabel,
} from "src/constants/Country";
import { buildRoute } from "src/router/router";
import AccountFormUpdate from "./AccountFormUpdate";
import * as meta from "./AccountMeta";

export default function AccountFormBasicComponent() {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  return (
    <AccountFormUpdate>
      {() => (
        <>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:basic-info">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Basic Info
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Basic information about your team/company.
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3">
                      <TextInputField
                        placeholder={t(meta.FIELDS.name.placeholder)}
                        label={t(meta.FIELDS.name.label)}
                        name={meta.FIELDS.name.key}
                        type="text"
                      />
                    </div>

                    <div className="col-span-3">
                      <SelectorField
                        options={meta.AVAILABLE_TIMEZONES}
                        name={meta.FIELDS.default_timezone.key}
                        help={t(meta.FIELDS.default_timezone.help)}
                        label={t(meta.FIELDS.default_timezone.label)}
                        placeholder={t(
                          meta.FIELDS.default_timezone.placeholder
                        )}
                        formatOptionLabel={meta.formatTimezoneOptionLabel}
                      />
                    </div>

                    <div className="col-span-3">
                      <SelectorField
                        placeholder={t(meta.FIELDS.country.placeholder)}
                        label={t(meta.FIELDS.country.label)}
                        name={meta.FIELDS.country.key}
                        options={COUNTRIES_WITH_FLAGS}
                        formatOptionLabel={formatCountryOptionLabel}
                      />
                    </div>

                    <div className="col-span-3">
                      <TextInputField
                        placeholder={t(meta.FIELDS.billing_email.placeholder)}
                        label={t(meta.FIELDS.billing_email.label)}
                        help={t(meta.FIELDS.billing_email.help)}
                        name={meta.FIELDS.billing_email.key}
                        type="email"
                        icon={MailIcon}
                      />
                    </div>

                    <div className="col-span-3">
                      <SelectorField
                        placeholder={t(
                          meta.FIELDS.phone_default_country.placeholder
                        )}
                        label={t(meta.FIELDS.phone_default_country.label)}
                        help={t(meta.FIELDS.phone_default_country.help)}
                        name={meta.FIELDS.phone_default_country.key}
                        options={COUNTRIES_WITH_FLAGS}
                        formatOptionLabel={formatCountryOptionLabel}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {session.role.name === "Admin" && (
            <div className="mt-10 sm:mt-4">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1"></div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div className="bg-white px-4 py-5 sm:p-6 text-right flex items-center">
                      <div className="text-sm text-gray-500 mr-2">
                        {t(
                          "settings:close-account",
                          "Don't want to keep using Hilos?"
                        )}
                      </div>
                      <Link
                        to={buildRoute("disable-account")}
                        className="inline-flex items-center text-sm text-hilos "
                      >
                        {t("settings:close-account-link", "Close your account")}
                        .
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </AccountFormUpdate>
  );
}
