import * as yup from "yup";
import { getKeyValueArray, hasItems } from "src/helpers/utils";
import { ERROR_MESSAGES } from "src/constants/Form";
import { getTranslationPayload as t } from "src/i18n";
import { FlowStepActionMeta } from "./form/steps/action/Meta";

export const getStepsAsArray = (steps) => {
  if (steps.length < 1) return [];
  // Does a DF traversal of the tree representation of flow steps
  const root = steps[0];
  const stepsRep: string[] = [];

  const visitStep = (step) => {
    if (step) {
      // Saves step as its JSON rep to make it easy to evaluate equality
      if (!stepsRep.includes(JSON.stringify(step))) {
        stepsRep.push(JSON.stringify(step));
        visitStep(steps[step.next_step_default_idx]);
        visitStep(steps[step.next_step_alternate_idx]);
        if (step.next_steps_for_options_idx) {
          step.next_steps_for_options_idx.forEach((stepIdx) => {
            visitStep(steps[stepIdx]);
          });
        }
      }
    }
  };

  visitStep(root);
  // De-JSONing steps
  return stepsRep.map((step) => JSON.parse(step));
};

export const TimeWindowMeta = {
  getInitialValues: function () {
    const weekdays = [0, 1, 2, 3, 4, 5, 6];
    return weekdays.map((weekday) => ({
      weekday: weekday,
      is_available: true,
      start_at: null,
      end_at: null,
    }));
  },
  getSchema: function () {
    return yup.object().shape({
      weekday: yup.number(),
      is_available: yup.bool(),
      start_at: yup
        .string()
        .nullable()
        .when("is_available", {
          is: true,
          then: yup.string().nullable().required(ERROR_MESSAGES.required),
        }),
      end_at: yup
        .string()
        .nullable()
        .when("is_available", {
          is: true,
          then: yup.string().nullable().required(ERROR_MESSAGES.required),
        }),
    });
  },
};

export const DelayMeta = {
  MAX_WAIT_TIME_OPTIONS: [
    {
      value: "SECOND",
      label: t("seconds", "Seconds"),
    },
    {
      value: "MINUTE",
      label: t("minutes", "Minutes"),
    },
    {
      value: "HOUR",
      label: t("hours", "Hours"),
    },
    {
      value: "DAY",
      label: t("days", "Days"),
    },
  ],
};

export const TimeoutMeta = {
  MAX_WAIT_TIME_OPTIONS: [
    {
      value: "MINUTE",
      label: t("minutes", "Minutes"),
    },
    {
      value: "HOUR",
      label: t("hours", "Hours"),
    },
    {
      value: "DAY",
      label: t("days", "Days"),
    },
  ],
};

export function getActionStepBodyType(actionRequestHeaders) {
  if (hasItems(actionRequestHeaders)) {
    const actionRequestHeader = actionRequestHeaders.find(
      (header) =>
        !header.key.localeCompare("Content-Type", undefined, {
          sensitivity: "accent",
        })
    );

    if (actionRequestHeader && actionRequestHeader.value) {
      const flowStepActionOption = FlowStepActionMeta.BODY_TYPES.find(
        (type) => type.value === actionRequestHeader.value
      );

      if (flowStepActionOption) {
        return flowStepActionOption;
      }
    }
  }

  return { label: "NONE", value: "NONE", type: "OTHER" };
}

export function massageFlow(flow) {
  const formFlow = { ...flow };
  const version = formFlow.current_draft
    ? formFlow.current_draft
    : formFlow.current_version;
  formFlow.channel = formFlow.channel.id;
  formFlow.execution_type = version.execution_type;
  formFlow.steps = version.steps.filter((step) => !step.is_deleted);
  formFlow.steps = massageSteps(formFlow.steps);
  return formFlow;
}

export function massageSteps(steps) {
  const formSteps = [...steps];
  formSteps.forEach((step) => {
    if (!step.next_step_default_idx && step.next_step_default_idx !== 0) {
      step.next_step_default_idx = "";
    }
    if (!step.next_step_alternate_idx && step.next_step_alternate_idx !== 0) {
      step.next_step_alternate_idx = "";
    }
    if (
      !step.answer_failed_next_step_idx &&
      step.answer_failed_next_step_idx !== 0
    ) {
      step.answer_failed_next_step_idx = "";
    }
    if (step.step_type === "ACTION") {
      step.action_request_headers = getKeyValueArray(
        step.action_request_headers
      );

      const bodyType = getActionStepBodyType(step.action_request_headers);
      if (bodyType.type === "STRUCTURED") {
        try {
          const jsonBody = JSON.parse(step.action_request_body);
          step.action_request_body = getKeyValueArray(jsonBody);
        } catch {}
      }

      step.action_request_params = getKeyValueArray(step.action_request_params);
      if (step.action_test_response_data) {
        const responseObj = {
          response: step.action_test_response_data.response,
          "content-type": step.action_test_response_data["content-type"],
          status: step.action_test_response_data.status,
        };
        step.action_test_response_data = responseObj;
      }
    }
  });
  return formSteps;
}
