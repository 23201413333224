import { useTranslation } from "react-i18next";
import {
  FlowStepEdit,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import Linkify from "src/components/Linkify";
import { ANSWER_OPTIONS_RENDER_TYPES } from "../../builder/constants/steps/question";
import FlowStepMaxAttemptsDetail from "./components/FlowStepMaxAttemptsDetail";
import FlowStepMaxWaitTimeDetail from "./components/FlowStepMaxWaitTimeDetail";

interface FlowStepMenuDetailProps {
  step: FlowStepEdit;
  flowVersion: FlowVersionDetailRead;
  stepInfo: FlowStepEdit;
  getStepById: (id: string) => FlowStepEdit;
}

export default function FlowStepMenuDetail({
  step,
  flowVersion,
  stepInfo,
  getStepById,
}: FlowStepMenuDetailProps) {
  const { t } = useTranslation();
  const nextSteps = step.next_steps_for_options.map((id) =>
    id ? getStepById(id as string) : null
  );

  const optionRenderTypeData = ANSWER_OPTIONS_RENDER_TYPES.find(
    (at) => at.value === step.answer_options_render
  );

  return (
    <div className="space-y-4">
      <div className="w-80 space-y-2 rounded-lg bg-whatsapp-outgoing py-2 px-3 text-left text-sm text-gray-900">
        <p className="whitespace-pre-wrap break-words">
          <Linkify message={step.body || ""} />
        </p>
      </div>
      <p>
        {t(
          "flow-executions:steps-detail.menu.options-render-type",
          "Showing results as"
        )}{" "}
        <span className="font-medium text-gray-900">
          {optionRenderTypeData && t(optionRenderTypeData.label)}
        </span>
        .
      </p>
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t("flow-executions:steps-detail.menu.next-steps", "Next steps")}:
        </h3>
        <ul>
          {step.answer_options?.map((option, idx) => (
            <li key={idx}>
              {t(
                "flow-executions:steps-detail.menu.next-steps-if-user-chooses",
                "If the user chooses"
              )}{" "}
              <span className="text-gray-900 font-medium">{option}</span>, go to{" "}
              {nextSteps[idx] && (
                <span className="text-gray-900 font-medium">
                  {nextSteps[idx]?.name}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
      <p>
        {t(
          "flow-executions:steps-detail.menu.alternate-step",
          "If the user answered with anything but these options, answer with"
        )}{" "}
        <span className="text-gray-900 font-medium">
          "{step.answer_validation_message}"
        </span>
      </p>
      {step.has_max_wait_time && (
        <FlowStepMaxWaitTimeDetail step={step} getStepById={getStepById} />
      )}
      {step.has_max_answer_attempts && (
        <FlowStepMaxAttemptsDetail step={step} getStepById={getStepById} />
      )}
    </div>
  );
}
