import { NodeTypes } from "reactflow";
import AddForkStepNode from "./AddForkStepNode";
import AddStepNode from "./AddStepNode";
import StepNode from "./StepNode";
import TriggerNode from "./TriggerNode";
import UnreachableStepNode from "./UnreachableStepNode";

const nodeTypes: NodeTypes = {
  step: StepNode,
  trigger: TriggerNode,
  add_step_node: AddStepNode,
  add_fork_step_node: AddForkStepNode,
  unreachable_step_node: UnreachableStepNode,
};

export default nodeTypes;
