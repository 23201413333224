import { HTTPRequest, KeyValueList } from "@hilos/types/inbox_action";
import {
  FlowStepEdit,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import HTTPResponseRenderRequest from "src/components/HTTPResponseRender/HTTPResponseRenderRequest";

interface FlowStepActionDetailProps {
  step: FlowStepEdit;
  flowVersion: FlowVersionDetailRead;
  stepInfo: FlowStepEdit;
  getStepById: (id: string) => FlowStepEdit;
}

export default function FlowStepActionDetail({
  step,
  flowVersion,
  stepInfo,
  getStepById,
}: FlowStepActionDetailProps) {
  let body = "";
  if (step.action_request_body) {
    if (typeof step.action_request_body === "string") {
      body = step.action_request_body;
    } else {
      body = JSON.stringify(step.action_request_body);
    }
  }
  let headers: KeyValueList = [];
  let params: KeyValueList = [];

  if (
    step.action_request_headers &&
    Array.isArray(step.action_request_headers)
  ) {
    headers = step.action_request_headers.map((d) => ({
      key: d[0],
      value: d[1],
    }));
  }

  if (step.action_request_params && Array.isArray(step.action_request_params)) {
    params = step.action_request_params.map((d) => ({
      key: d[0],
      value: d[1],
    }));
  }

  const request: HTTPRequest = {
    headers: headers,
    body: body,
    params: params,
    url: step.action_request_url || "",
    method: step.action_request_method as string,
  };

  return <HTTPResponseRenderRequest request={request} />;
}
