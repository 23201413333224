// @flow
import {
  SwitchHorizontalIcon,
  UserAddIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import * as yup from "yup";
import { ERROR_MESSAGES } from "src/constants/Form";
import { getTranslationPayload as t } from "src/i18n";

export const getInitialValues = () => {
  return {
    name: "",
    users: [],
    assignment_strategy: "",
    default_assignee: "",
  };
};

export const schema = yup.object().shape({
  name: yup.string().required(ERROR_MESSAGES.required),
  users: yup.array().of(yup.number()),
  assignment_strategy: yup.string().required(ERROR_MESSAGES.required),
  default_assignee: yup.string(),
});

export const FIELDS = {
  name: {
    key: "name",
    label: t("settings:team-name.label", "Team name"),
    placeholder: t("settings:team-name.placeholder", "Sales"),
  },
  users: {
    key: "users",
    label: t("settings:team-members.label", "Team members"),
    optional: true,
    help: t(
      "settings:team-members.help",
      "These are the users used to assign conversations."
    ),
  },
  assignment_strategy: {
    key: "assignment_strategy",
    label: t(
      "settings:assignment-strategy.label",
      "How should we assign users?"
    ),
  },
  default_assignee: {
    key: "default_assignee",
    label: t("settings:default-assignee.label", "Default assignee"),
  },
  reassign_on_inactivity: {
    key: "reassign_on_inactivity",
    label: t(
      "settings:team-reassign-inactivity.label",
      "Reassign if agent doesn't answer a conversation after X time?"
    ),
  },
  reassign_after: {
    key: "reassign_after",
    label: t(
      "settings:team-reassign-after.label",
      "Reassign after this many minutes"
    ),
    help: t(
      "settings:team-reassign-after.help",
      "We'll reassign conversations that haven't been seen by the originally assigned user after these minutes, using the same strategy."
    ),
  },
};

export const ASSIGNMENT_STRATEGY_OPTIONS = [
  {
    key: "ROUND_ROBIN",
    label: t("settings:assignment-strategy.round-robin.label", "Round robin"),
    icon: <SwitchHorizontalIcon className="h-5 w-5" />,
    description: t(
      "settings:assignment-strategy.round-robin.description",
      "Assigns users in a round robin fashion, starting from the first to the last and then again to the first."
    ),
  },
  {
    key: "LEAST_BUSY",
    label: t("settings:assignment-strategy.least-busy.label", "Least busy"),
    icon: <UserAddIcon className="h-5 w-5" />,
    description: t(
      "settings:assignment-strategy.least-busy.description",
      "Assigns the user with the least active conversations (those in NEW or IN PROGRESS)"
    ),
  },
  {
    key: "USE_DEFAULT",
    label: t("settings:assignment-strategy.use-default.label", "Use default"),
    icon: <UserCircleIcon className="h-5 w-5" />,
    description: t(
      "settings:assignment-strategy.use-default.description",
      "Always assigns the same user. For example if you wish to always assign the team's manager."
    ),
  },
];
