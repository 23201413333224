import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { StripeProductAvailableList } from "@hilos/types/private-schema";
import useHilosStore from "src/hooks/useHilosStore";
import SubscriptionExtrasCard from "./SubscriptionExtrasCard";
import SubscriptionPlanCard from "./SubscriptionPlanCard";

interface SubscribedPlanDetailModalProps {
  plan: StripeProductAvailableList;
  addons: StripeProductAvailableList[];
}

export default function SubscribedPlanDetailModal({
  plan,
  addons,
}: SubscribedPlanDetailModalProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { session } = useHilosStore();

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShow}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:align-middle">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-full bg-white p-2 text-gray-400 hover:text-gray-500"
                      onClick={() => setShow(false)}
                    >
                      <span className="sr-only">{t("close")}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <SubscriptionPlanCard
                    product={plan}
                    showCheckout={false}
                    currency={session?.account.payment_currency || "usd"}
                  />
                  <div className="flex-1 px-4 py-4 lg:px-10 lg:py-8">
                    <SubscriptionExtrasCard addons={addons} asCard={false} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <button
        className="ml-2 text-sm font-normal text-blue-500"
        type="button"
        onClick={(_) => setShow(true)}
      >
        {t("view-details")}
      </button>
    </>
  );
}
