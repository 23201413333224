import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  ArrowSmRightIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import logo from "../../assets/img/hilos_imago.png";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import { NewPasswordForm } from "./NewPasswordForm";

export default function ResetPasswordFromKey() {
  const { t } = useTranslation();
  const params = useParams();
  const [isKeyValid, setIsKeyValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    async function testKey() {
      try {
        await axios.get(
          buildAPIRoute(API_ROUTES.RESET_PASSWORD_KEY, {
            ":key": params.key,
          })
        );
        setIsKeyValid(true);
      } catch (err) {
        setIsKeyValid(false);
      }
    }
    if (params.key) {
      testKey();
    }
  }, [params]);

  const onSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.RESET_PASSWORD_KEY, {
          ":key": params.key,
        }),
        formData
      );
      setSuccess(true);
      setSubmitted(true);
    } catch (err) {
      setSuccess(false);
      if (err.response.status === 400) {
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          "An error occurred while saving your new password, please try again."
        );
      }
      setSubmitted(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            {isKeyValid ? (
              <>
                <div>
                  <img className="h-12 w-auto" src={logo} alt="Workflow" />
                  <Trans i18nKey="settings:password-reset.description">
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                      Password reset
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                      Set a new password for your Hilos account.
                    </p>
                  </Trans>
                </div>

                <div className="mt-8">
                  <div className="mt-6">
                    {submitted && success ? (
                      <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <Trans i18nKey="settings:reset-password.success">
                              <h3 className="text-sm font-medium text-green-800">
                                Success!
                              </h3>
                              <div className="mt-2 space-y-1 text-sm text-green-700">
                                <p>
                                  You can now log in with your new password.
                                </p>
                              </div>
                            </Trans>
                            <div className="mt-4">
                              <div className="-mx-2 -my-1.5 flex">
                                <Link
                                  to={buildRoute("login")}
                                  className="flex items-center rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                >
                                  {t("log-in")}
                                  <ArrowSmRightIcon
                                    className="ml-1 h-5 w-5"
                                    aria-readonly={true}
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NewPasswordForm
                        onSubmit={onSubmit}
                        success={success}
                        submitted={submitted}
                      ></NewPasswordForm>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <img className="h-12 w-auto" src={logo} alt="Workflow" />
                  <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                    {t("settings:password-reset.label", "Reset Password")}
                  </h2>
                </div>

                <div className="mt-8">
                  <div className="mt-6">
                    <div className="rounded-md bg-yellow-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <Trans i18nKey="settings:reset-password.link-not-valid">
                            <h3 className="text-sm font-medium text-yellow-800">
                              Sorry, this link is no longer valid.
                            </h3>
                            <div className="mt-2 space-y-1 text-sm text-yellow-700">
                              <p>
                                If you wish to reset your password, try again
                                through here:
                              </p>
                            </div>
                          </Trans>
                          <div className="mt-4">
                            <div className="-mx-2 -my-1.5 flex">
                              <Link
                                to={buildRoute("reset-password")}
                                className="flex items-center rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                              >
                                {t("settings:reset-password.label")}
                                <ArrowSmRightIcon
                                  className="ml-2 h-5 w-5"
                                  aria-readonly={true}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
