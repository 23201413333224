import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CalendarIcon, PlayIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import { FlowExecutionReadDetail } from "@hilos/types/private-schema";
import DatePickerField from "src/components/Form/DatePickerField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { hasKeys } from "src/helpers/utils";
import { FlowExecutionCreateFormSubmit } from "./FlowExecutionCreate";
import FlowExecutionFormContactList from "./FlowExecutionFormContactList";
import * as meta from "./FlowExecutionMeta";

interface FlowExecutionFormProps {
  flowExecution: Partial<
    Omit<FlowExecutionReadDetail, "contact_filters" | "contact_lists">
  >;
  formSubmit: FlowExecutionCreateFormSubmit;
  success: boolean;
  submitted: boolean;
  submittingText?: string;
  successText?: string;
  initialText?: JSX.Element;
}

export default function FlowExecutionForm({
  flowExecution,
  formSubmit,
  success,
  submitted,
  submittingText,
  successText,
  initialText,
}: FlowExecutionFormProps) {
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const { t } = useTranslation();

  const getRunButtonText = (data: Partial<FlowExecutionReadDetail>) => {
    if (data.start_on) {
      const date = new Date(data.start_on);
      return (
        <>
          <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          {t("flow-executions:run-on-button-text", "Run on {{dateFmt}}", {
            dateFmt: date.toLocaleString(),
          })}
        </>
      );
    }
    return initialText;
  };

  const cleanValuesForSubmit = (values) => {
    const newVals = { ...values };
    if (values.contact_list && values.contact_list.length > 0) {
      const [headers, ...contact_data] = values.contact_list;
      const [_, ...headersWithoutCheckbox] = headers;
      // Filter only those selected with the checkbox
      const selected_contacts = contact_data.filter((c) => c[0]);

      // Transform to an array of objects with the headers as keys:
      const transformed_contact_list = selected_contacts.map((data) =>
        headersWithoutCheckbox.reduce(
          (nextContactList, header, headerIndex) => {
            if (
              [
                "phone",
                "email",
                "first_name",
                "last_name",
                "source",
                "tags",
                "external_id",
                "external_url",
                "default_assignees",
              ].includes(header)
            ) {
              nextContactList[header] = data[headerIndex + 1];
            } else {
              if (!nextContactList["meta"]) {
                nextContactList["meta"] = {};
              }
              nextContactList["meta"][header] = data[headerIndex + 1];
            }
            return nextContactList;
          },
          {}
        )
      );

      newVals.contact_list = transformed_contact_list;
    }

    if (!newVals.start_on) {
      delete newVals.start_on;
    }
    return newVals;
  };

  const onSubmit = (values, setSubmitting) => {
    formSubmit(
      cleanValuesForSubmit(values),
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  const validate = (values) => {
    let errors = {};
    if (values.execute_for === "LIST") {
      if (values.contact_list.length < 1) {
        errors = {
          contact_list: "Upload a .csv with least one contact.",
        };
        return errors;
      }
      // Removing headers
      const [_, ...contact_data] = values.contact_list;
      // The checkbox is on the first column
      const selected_contacts = contact_data.filter((c) => c[0]);

      if (selected_contacts.length < 1) {
        errors = {
          contact_list: t(
            "flow-executions:errors.min-contact-length",
            "Select at least one contact from the .csv."
          ),
        };
      }
    } else if (
      values.execute_for === "FILTERS" &&
      values.contact_filters.length === 0
    ) {
      errors = {
        contact_filters: t(
          "flow-executions:errors.min-filter-length",
          "Set at least one filter."
        ),
      };
    }

    return errors;
  };

  return (
    <Formik
      validationSchema={meta.schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
      initialValues={flowExecution}
      validate={validate}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit} className="space-y-4">
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>

          <DatePickerField
            showTimeInput
            placeholder={meta.FIELDS.start_on.placeholder}
            label={t(meta.FIELDS.start_on.label)}
            name={meta.FIELDS.start_on.key}
            help={t(meta.FIELDS.start_on.help)}
            optional={meta.FIELDS.start_on.optional}
            dateFormat="dd/MM/yyyy h:mm aa"
            minDate={new Date()}
            icon={CalendarIcon}
          />

          <div>
            <SwitchField
              label={t(meta.FIELDS.has_priority.label)}
              name={meta.FIELDS.has_priority.key}
              help={t(meta.FIELDS.has_priority.help)}
              // helpDocsLink="https://hilos.io/docs/user/create-a-flow"
            />
          </div>
          <FlowExecutionFormContactList formik={formik} />

          {flowExecution.flow_execution_variables &&
            hasKeys(flowExecution.flow_execution_variables) && (
              <>
                <hr className="my-5" />
                <p className="text-base font-medium text-gray-700">
                  {t(meta.FIELDS.flow_execution_variables.label)}
                </p>
                <p className="mt-1.5 text-xs text-gray-500">
                  {t(meta.FIELDS.flow_execution_variables.help)}
                </p>
                {Object.keys(flowExecution.flow_execution_variables).map(
                  (variable) => (
                    <TextInputField
                      name={`${meta.FIELDS.flow_execution_variables.key}.${variable}`}
                      label={variable}
                      key={variable}
                      type="text"
                    />
                  )
                )}
              </>
            )}

          <div className="mt-6">
            <div className="text-right">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                submittingText={submittingText}
                successText={successText}
                initialText={
                  !formik.values.start_on
                    ? initialText
                    : getRunButtonText(formik.values)
                }
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
