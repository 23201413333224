import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PlusSmIcon, UploadIcon, UserIcon } from "@heroicons/react/outline";
import HelpDocsLink from "src/components/HelpDocsLink";
import AccountStatusNotice from "src/components/Notice/AccountStatusNotice";
import { classNames } from "src/Helpers";
import HeaderNavigation from "../../components/HeaderNavigation";
import { buildRoute } from "../../router/router";
import ContactImportList from "./ContactImportList";
import ContactList from "./ContactList";

export default function ContactMain() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState("contacts");

  const TABS = [
    {
      name: t("contacts", "Contacts"),
      value: "contacts",
      icon: UserIcon,
      current: currentTab === "contacts",
    },
    {
      name: t("csv-imports", "CSV Imports"),
      value: "imports",
      icon: UploadIcon,
      current: currentTab === "imports",
    },
  ];

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        <AccountStatusNotice />
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home", "Home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("contacts", "Contacts"),
                url: buildRoute("contact-list"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <Trans i18nKey="contacts:manage-contacts">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <UserIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                  Contacts
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Manage your contacts information.
                  <HelpDocsLink
                    href="https://hilos.io/docs/user/using-hilos/contacts/creating-a-contact"
                    className="ml-1 text-blue-400"
                  />
                </p>
              </Trans>
            </div>
            <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
              <span className="md:ml-3">
                <Link
                  to={buildRoute("contact-create")}
                  className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                >
                  <PlusSmIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  {t("contacts:new-contact", "New Contact")}
                </Link>
                <Link
                  to={buildRoute("contact-upload-csv")}
                  className="ml-2 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                >
                  <UploadIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  {t("upload-csv", "Upload CSV")}
                </Link>
              </span>
            </div>
          </div>
        </div>

        <div className="px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              {t("select-a-tab", "Select a tab")}
            </label>
            <select
              id="tabs"
              name="tabs"
              className="mb-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={TABS.find((tab) => tab.current)?.value}
              onChange={(e) => setCurrentTab(e.target.value)}
            >
              {TABS.map((tab) => (
                <option key={tab.value} value={tab.value}>
                  {t(tab.name)}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {TABS.map((tab) => (
                  <button
                    key={tab.name}
                    type="button"
                    className={classNames(
                      tab.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                      "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                    onClick={(_) => setCurrentTab(tab.value)}
                  >
                    <tab.icon
                      className={classNames(
                        tab.current
                          ? "text-indigo-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "-ml-0.5 mr-2 h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                    <span>{t(tab.name)}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {currentTab === "contacts" && <ContactList />}

        {currentTab === "imports" && <ContactImportList />}
      </div>
    </>
  );
}
