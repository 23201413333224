import { HTMLProps } from "react";
import { faMeta } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MetaIcon(props: HTMLProps<HTMLElement>) {
  //@ts-ignore
  const icon = <FontAwesomeIcon icon={faMeta} {...props} />;
  return icon;
}

export default MetaIcon;
