import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepConditionalDetaiMultiplelProps {
  step: FlowStepEdit;
}

export default function FlowStepConditionalMultipleDetail({
  step,
}: FlowStepConditionalDetaiMultiplelProps) {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("flow-executions:steps-detail.conditional-multiple.paths", "Paths")}
      </h3>
      {step.paths?.map((path, idx) => (
        <div key={idx} className="text-gray-900 space-x-1">
          <div>
            <h4 className="text-tiny font-medium uppercase  text-gray-400">
              {step.answer_options?.[idx]} -{" "}
              {t(
                "flow-executions:steps-detail.conditional.conditions",
                "Conditions"
              )}{" "}
            </h4>
            {path.conditions.map((condition, idx) => (
              <div key={idx} className="text-gray-900 space-x-1">
                {"{{"}
                <span className="text-gray-700">{condition.field}</span>
                {"}}"}
                <span className="text-gray-900 font-medium">
                  {condition.comparison}
                </span>
                <span className="text-gray-700">{condition.value}</span>
                {idx < path.conditions.length - 1 && (
                  <span className="text-gray-500">AND</span>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
