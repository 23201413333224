import { useTranslation } from "react-i18next";
import HelpDocsLink from "src/components/HelpDocsLink";

interface FieldContainerProps {
  name: string;
  label?: string;
  help?: string | JSX.Element;
  optional?: boolean;
  error?: string | boolean;
  helpDocsLink?: string;
}

function FieldContainer({
  label,
  name,
  error,
  help,
  optional,
  children,
  helpDocsLink,
}: React.PropsWithChildren<FieldContainerProps>) {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="flex flex-row justify-between">
        {label && (
          <label htmlFor={name} className="text-sm font-medium text-gray-700">
            {label}{" "}
            {optional && (
              <span className="text-xs lowercase text-gray-500">
                ({t("optional", "optional")})
              </span>
            )}
          </label>
        )}
        {helpDocsLink && (
          <HelpDocsLink href={helpDocsLink} className="text-xs" />
        )}
      </div>
      {children}
      {error && <div className="mt-1.5 text-xs text-red-500">{error}</div>}
      {/* @ts-ignore */}
      {help && <div className="mt-1.5 text-xs text-gray-500">{help}</div>}
    </div>
  );
}

export default FieldContainer;
