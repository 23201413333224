import { useMemo } from "react";
import { TagData } from "@hilos/types/conversation";
import { hasItems } from "src/helpers/utils";
import { useConversationTags } from "src/hooks/useTags";

interface ConversationTagListProps {
  tags?: { tag: TagData }[] | null;
}

interface ConversationTagListByIdProps {
  tags: number[];
}

function ConversationTagList({ tags }: ConversationTagListProps) {
  if (!tags || !tags.length) {
    return null;
  }

  return (
    <div className="overflow-x-auto no-scrollbar flex flex-nowrap space-x-1 my-2">
      {tags.map(
        ({ tag }) =>
          tag && (
            <div
              key={tag.id}
              className="bg-pink-100 whitespace-nowrap rounded-md px-2 py-1 text-xs text-pink-700"
            >
              {tag.name}
            </div>
          )
      )}
    </div>
  );
}

export function ConversationTagListById({
  tags,
}: ConversationTagListByIdProps) {
  const data = useConversationTags();

  const currentTagsFiltered = useMemo(() => {
    const nextTagsFiltered: { tag: TagData }[] = [];

    if (data && hasItems(tags) && hasItems(data.tags)) {
      for (const tag of data.tags) {
        if (tags.includes(tag.id)) {
          nextTagsFiltered.push({ tag });
        }
      }
    }

    return nextTagsFiltered;
  }, [data, tags]);

  return <ConversationTagList tags={currentTagsFiltered} />;
}

export default ConversationTagList;
