import {
  DocumentTextIcon,
  PhotographIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import { getTranslationPayload as t } from "src/i18n";
import TextIcon from "../../components/icons/TextIcon";

export const MESSAGE_BODY_TYPES = {
  text: {
    value: "TEXT",
    icon: TextIcon,
    label: t("flows:message-body-types.text.label", "Text"),
    help: t(
      "flows:message-body-types.text.help",
      "Text message, can contain variables."
    ),
    inputLabel: t("flows:message-body-types.text.input-label", "Message body"),
  },
  image: {
    value: "IMAGE",
    icon: PhotographIcon,
    accept: "image/png,image/jpg,image/jpeg",
    label: t("flows:message-body-types.image.label", "Image"),
    help: t(
      "flows:message-body-types.image.help",
      "Sends a single image, PNG or JPG"
    ),
    inputLabel: t("flows:message-body-types.image.input-label", "Image URL"),
    inputHelp: t(
      "flows:message-body-types.image.input-help",
      "Make sure it's a public .png or .jpg file and no larger than 15MB."
    ),
  },
  document: {
    value: "DOCUMENT",
    icon: DocumentTextIcon,
    accept: "application/pdf",
    label: t("flows:message-body-types.document.label", "Document"),
    help: t("flows:message-body-types.document.help", "Sends a PDF file"),
    inputLabel: t(
      "flows:message-body-types.document.input-label",
      "Document URL"
    ),
    inputHelp: t(
      "flows:message-body-types.document.input-help",
      "Make sure it's a public .pdf file and no larger than 15MB."
    ),
  },
  video: {
    value: "VIDEO",
    icon: VideoCameraIcon,
    accept: "video/mp4",
    label: t("flows:message-body-types.video.label", "Video"),
    help: t("flows:message-body-types.video.help", "Sends an MP4 video"),
    inputLabel: t("flows:message-body-types.video.input-label", "Video URL"),
    inputHelp: t(
      "flows:message-body-types.video.input-help",
      "Make sure it's a public .mp4 file and no larger than 15MB. If you want to use an external video from youtube, etc, use a direct download link. Otherwise we won't be able to use it."
    ),
  },
};

export type MessageBodyTypes = keyof typeof MESSAGE_BODY_TYPES;
