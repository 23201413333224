import { useCallback, useMemo, useState } from "react";
import {
  QueryFunctionContext,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import axios from "axios";
import { debounce } from "lodash";
import { CursorPageData, UserData } from "@hilos/types/hilos";
import { SimpleChannel } from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import { API_ROUTES, buildAPIRoute } from "../router/router";

export interface QuickReplyTagData {
  name: string;
}

export interface QuickReplyData {
  id: string;
  text?: string;
  created_on: string;
  created_by: UserData | null;
  tags?: QuickReplyTagData[];
  template?: WhatsAppTemplate;
  template_name?: string;
  template_variables?: string[];
}

export type QuickReplyPayload = Omit<
  QuickReplyData,
  "id" | "created_on" | "created_by"
>;

type FetchQuickRepliesParams = QueryFunctionContext<
  [string, number, string, boolean]
>;

const createQuickReplyMutation = async (params: QuickReplyPayload) => {
  const { data } = await axios.post(
    buildAPIRoute(API_ROUTES.QUICK_REPLY_LIST_CREATE),
    params
  );

  return data;
};

const deleteQuickReplyMutation = async (id: string) => {
  const { data } = await axios.delete(
    buildAPIRoute(API_ROUTES.QUICK_REPLY_DETAIL, { ":id": id })
  );

  return data;
};

async function fetchQuickReplyTags() {
  const { data } = await axios.get<QuickReplyTagData[]>(
    API_ROUTES.QUICK_REPLY_TAG_LIST
  );
  return data;
}

async function fetchQuickReplies({
  signal,
  pageParam,
  queryKey,
}: FetchQuickRepliesParams): Promise<CursorPageData<QuickReplyData> | null> {
  const { data } = await axios.get<CursorPageData<QuickReplyData>>(
    API_ROUTES.QUICK_REPLY_LIST_CREATE,
    {
      signal,
      params: {
        cursor: pageParam,
        channel: queryKey[1],
        search: queryKey[2] || undefined,
        only_text: queryKey[3] || false,
      },
    }
  );
  return data;
}

export default function useQuickReplies(
  channel: SimpleChannel,
  showOnlyTextQuickReplies = false
) {
  const [search, setSearch] = useState("");
  const { data: tags } = useQuery(["quick_reply_tags"], fetchQuickReplyTags);
  const {
    data,
    refetch,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ["quick_replies", channel.id, search, showOnlyTextQuickReplies],
    fetchQuickReplies,
    {
      getNextPageParam: (lastPage, pages) =>
        (lastPage && lastPage.next) || undefined,
      getPreviousPageParam: (lastPage, pages) =>
        (lastPage && lastPage.previous) || undefined,
      retry: 3,
    }
  );

  const { mutateAsync: createQuickReply } = useMutation(
    createQuickReplyMutation,
    {
      onSuccess: (data) => {
        if (data) {
          refetch();
        }
      },
    }
  );

  const { mutate: deleteQuickReply } = useMutation(deleteQuickReplyMutation, {
    onSuccess: () => {
      refetch();
    },
  });

  const handleChangeSearch = useCallback(
    debounce((nextSearch) => {
      setSearch(nextSearch);
    }, 500),
    []
  );

  const pages = useMemo(() => {
    if (!data || !data.pages) {
      return null;
    }

    return data.pages;
  }, [data]);

  return {
    tags,
    pages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    createQuickReply,
    refetch,
    deleteQuickReply,
    handleChangeSearch,
  };
}

export function useQuickReplyUpdateDetails() {
  const updateQuickReply = useCallback(
    async (payload: QuickReplyPayload, quickReplyId: string) => {
      const { data } = await axios.patch(
        buildAPIRoute(API_ROUTES.QUICK_REPLY_DETAIL, {
          ":id": quickReplyId,
        }),
        payload
      );
      return data;
    },
    []
  );

  return {
    updateQuickReply,
  };
}
