import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { WhatsAppMessage } from "@hilos/types/private-schema";

interface RequestWelcomeProps {
  message: WhatsAppMessage;
  isPreview?: boolean;
}

export default function RequestWelcome({
  message,
  isPreview,
}: RequestWelcomeProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`flex items-center flex-col ${
        !isPreview
          ? "w-full justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm"
          : "text-gray-500"
      }`}
    >
      <div className="flex items-center">
        <ExclamationCircleIcon className="h-4 w-4 mr-1" />
        {t(
          "inbox:conversation.request_welcome",
          "User requested welcome message"
        )}
      </div>
    </div>
  );
}
