import { ExclamationIcon } from "@heroicons/react/outline";

interface KeyValueFieldWithVariablesNoticeProps {
  noticeMessage?: string;
  showNotice: boolean;
  variables: string[];
}

export default function KeyValueFieldWithVariablesNotice({
  noticeMessage,
  showNotice,
  variables,
}: KeyValueFieldWithVariablesNoticeProps) {
  return (
    <>
      {showNotice && (
        <div className="rounded-md bg-yellow-50 p-4 mt-4">
          <div className="flex items-center ">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <div className="text-sm text-yellow-700">
                <p>{noticeMessage}</p>
                <p>{variables.join(", ")}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
