import React from "react";
import ErrorComponent from "./ErrorComponent";

interface ErrorBoundaryProps {
  type?: "component" | "page" | "section";
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.setState({
      ...this.state,
      error: error,
      errorCode: error.response?.status, // Resulting ID from Posthog & Sentry
    });
  }

  render() {
    return this.state.hasError ? (
      <ErrorComponent type={this.props.type || "component"} {...this.state} />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
