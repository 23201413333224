import { useMemo } from "react";
import { Trans } from "react-i18next";
import { useFormikContext } from "formik";
import { t } from "i18next";
import { FlowData } from "@hilos/types/flow";
import { FlowDetailRead } from "@hilos/types/private-schema";
import { getRequiredVariablesFromTextValue } from "src/helpers/variables";

interface FlowBuilderTestTriggerHelpProps {
  flow: FlowDetailRead;
}

export default function FlowBuilderTestTriggerHelp({
  flow,
}: FlowBuilderTestTriggerHelpProps) {
  const { values } = useFormikContext<FlowData>();
  const inboundTriggerVariableNames = useMemo(() => {
    return flow.trigger_config.has_variables &&
      flow.trigger_config.message_content_to_match
      ? getRequiredVariablesFromTextValue(
          flow.trigger_config.message_content_to_match
        )
      : [];
  }, [flow]);

  const usesHubSpotSteps = useMemo(() => {
    const hubspotStepsInUse = values.steps.filter((step) =>
      ["HUBSPOT_CONTACT_UPSERT", "HUBSPOT_CONTACT_GET"].includes(step.step_type)
    );
    return hubspotStepsInUse.length > 0;
  }, [values]);

  return (
    <div className="rounded-md bg-blue-50 p-4 mb-4">
      {flow.trigger_type === "INBOUND_ANY_MESSAGE" && (
        <Trans i18nKey="flows:test-modal.trigger-help.inbound-any-message">
          <div className="text-sm text-blue-700">
            <p>
              This flow will trigger when your contact sends{" "}
              <strong>ANY</strong> message.
            </p>
          </div>
        </Trans>
      )}

      {flow.trigger_type === "INBOUND_SPECIFIC_MESSAGE" && (
        <div className="text-sm text-blue-700">
          {flow.trigger_config.has_variables ? (
            <div className="space-y-2">
              <p>
                {t(
                  "flows:test-modal.trigger-help.inbound-specific-message-w-vars",
                  "This flow will trigger when your contact sends a message with a text that matches:"
                )}
              </p>
              <div className="rounded-md bg-gray-50 p-2">
                <code className="text-sm font-medium text-gray-900">
                  {flow.trigger_config.message_content_to_match}
                </code>
              </div>
              <div>
                <p>
                  {t(
                    "flows:test-modal.trigger-help.inbound-specific-message-w-vars-extract",
                    "We'll extract the following variables from the text:"
                  )}
                </p>
                {inboundTriggerVariableNames.map((varName) => (
                  <span
                    key={varName}
                    className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 m-0.5"
                  >
                    {varName}
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <div className="space-y-3">
              <p>
                {flow.trigger_config.match_exact_message_content
                  ? t(
                      "flows:test-modal.trigger-help.inbound-specific-message-match-exact",
                      "Send the exact text"
                    )
                  : t(
                      "flows:test-modal.trigger-help.inbound-specific-message-contains",
                      "Send a message that contains the text"
                    )}{" "}
                <span className="rounded-md bg-gray-50 p-2">
                  <code className="text-sm font-medium text-gray-900">
                    {flow.trigger_config.message_content_to_match}
                  </code>
                </span>
                {t(
                  "flows:test-modal.trigger-help.inbound-specific-message-final",
                  "to test this flow as if a contact had sent it."
                )}
              </p>
            </div>
          )}
        </div>
      )}

      {flow.trigger_type === "OUTBOUND_CAMPAIGN_API" && (
        <div className="text-sm text-blue-700">
          <Trans i18nKey="flows:test-modal.trigger-help.outbound-campaign-api">
            <p>
              This flow will be triggered by making an HTTP request to Hilos'{" "}
              <a href="https://hilos.io/docs/developer/apidocs/flow-execution/post-apiflow-run">
                <code>/api/flow/{"<id>"}/run</code>
              </a>{" "}
              API endpoint.
            </p>
          </Trans>
          {flow.flow_execution_variables &&
            flow.flow_execution_variables.length > 0 && (
              <p>
                {t(
                  "flows:test-modal.trigger-help.outbound-w-vars",
                  "Be sure to include all the execution variables defined in this flow in your request!"
                )}
              </p>
            )}
        </div>
      )}

      {flow.trigger_type === "OUTBOUND_CAMPAIGN_CSV" && (
        <Trans i18nKey="flows:test-modal.trigger-help.outbound-campaign-csv">
          <div className="text-sm text-blue-700">
            <p>
              This flow will be triggered by manually uploading a CSV with
              contacts to import.
            </p>
            <p>
              If you're using <code>{"{{contact.****}}"}</code> variables in
              your flow, make sure your CSV includes a column with the values
              for each variable!
            </p>
          </div>
        </Trans>
      )}

      {flow.trigger_type === "OUTBOUND_CAMPAIGN_SEGMENT" && (
        <div className="text-sm text-blue-700">
          <Trans i18nKey="flows:test-modal.trigger-help.outbound-campaign-segment">
            <p>
              This flow will be triggered by filtering from your existing
              contacts.
            </p>
          </Trans>
          {flow.flow_execution_variables &&
            flow.flow_execution_variables.length > 0 && (
              <p>
                {t(
                  "flows:test-modal.trigger-help.outbound-w-vars",
                  "Be sure to include all the execution variables defined in this flow in your request!"
                )}
              </p>
            )}
        </div>
      )}

      {flow.trigger_type === "OUTBOUND_ANY" && (
        <div className="text-sm text-blue-700">
          <Trans i18nKey="flows:test-modal.trigger-help.outbound-any">
            <p>
              This flow can be triggered from HubSpot, Zapier, API, uploading a
              CSV with contacts or by filtering existing contacts.
            </p>
            <p>
              This works just like the old "Outbound Execution" flow triggers.
            </p>
          </Trans>
          {flow.flow_execution_variables &&
            flow.flow_execution_variables.length > 0 && (
              <p>
                {t(
                  "flows:test-modal.trigger-help.outbound-w-vars",
                  "Be sure to include all the execution variables defined in this flow in your request!"
                )}
              </p>
            )}
        </div>
      )}

      {flow.trigger_type === "INTEGRATIONS_HUBSPOT" && (
        <Trans i18nKey="flows:test-modal.trigger-help.integrations-hubspot">
          <div className="text-sm text-blue-700">
            <p>
              {t(
                "flows:test-modal.trigger-help.integrations-hubspot",
                "This flow will be triggered from HubSpot."
              )}
            </p>
            {usesHubSpotSteps && (
              <p>
                {t(
                  "flows:test-modal.trigger-help.integrations-hubspot-steps",
                  "When testing make sure a contact with your phone number (see above) already exists in HubSpot and it's synced to Hilos to properly test."
                )}
              </p>
            )}
          </div>
        </Trans>
      )}

      {flow.trigger_type === "INTEGRATIONS_ZAPIER" && (
        <Trans i18nKey="flows:test-modal.trigger-help.integrations-zapier">
          <div className="text-sm text-blue-700">
            <p>
              This flow will be triggered from a Zapier{" "}
              <a href="https://zapier.com/apps/hilos/integrations">
                <span className="rounded-md bg-gray-50 p-2">
                  <code className="text-sm font-medium text-gray-900">
                    Start a Flow for a Phone Number
                  </code>
                </span>
              </a>{" "}
              action.
            </p>
            <p>
              For testing we'll simulate that Zapier triggered it when you click
              on the button below.
            </p>
          </div>
        </Trans>
      )}
    </div>
  );
}
