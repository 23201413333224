import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const FlowStepTriggerFlowMeta = {
  getInitialValues: function (step) {
    return {
      name: step?.name || "",
      step_type: "TRIGGER_FLOW",
      next_flow: "",
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      next_flow: yup.string().required(ERROR_MESSAGES.required),
    });
  },
  validate: function () {
    // No extra validations needed
    return null;
  },
  formatForSave: function (step) {
    return step;
  },
  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    next_flow: {
      key: "next_flow",
      label: "Next flow",
      help: "",
    },
  },
};
