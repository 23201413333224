import { useTranslation } from "react-i18next";
import AvailableFlowSelector from "src/components/AvailableFlowSelector";

interface FlowBuilderStepTriggerFlowProps {
  index: number;
}

function FlowBuilderStepTriggerFlow({
  index,
}: FlowBuilderStepTriggerFlowProps) {
  const [t] = useTranslation();

  return (
    <div className="space-y-4">
      <AvailableFlowSelector
        name={`steps.${index}.next_flow`}
        label={t("flows:steps.trigger-flow.label", "Next flow")}
        help={t("flows:steps.trigger-flow.help", "Select the flow to start.")}
        placeholder={t("type-to-search")}
      />
    </div>
  );
}
export default FlowBuilderStepTriggerFlow;
