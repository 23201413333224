import { TFunction } from "i18next";
import * as yup from "yup1";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepBaseSchema } from "../flow";

export const FlowStepTeamSchema = (t: TFunction) =>
  FlowStepBaseSchema(t).shape({
    assign_to_teams: yup
      .array()
      .required(t(ERROR_MESSAGES.REQUIRED))
      .of(yup.number().required(t(ERROR_MESSAGES.REQUIRED)))
      .min(1, t(ERROR_MESSAGES.MIN_NUMBER)),
    contact_tags: yup.array().nullable().of(yup.string()),
    conversation_tags: yup.array().nullable().of(yup.string()),
  });
