import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { UserData } from "@hilos/types/hilos";
import { API_ROUTES } from "../router/router";

function useAccount() {
  const fetchAccountMembers = useCallback(async () => {
    const { data } = await axios.get<UserData[]>(
      API_ROUTES.USER_LIST
    );
    return data;
  }, []);

  const { data } = useQuery("users", fetchAccountMembers);

  return { members: data || [] };
}

export default useAccount;
