import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {
  StripeInvoice,
  StripePaymentMethod,
  StripeProductAvailableList,
  StripeSubscription,
} from "@hilos/types/private-schema";
import { API_ROUTES } from "../router/router";

export function useStripeProductList() {
  const fetchStripeProductList = useCallback(async () => {
    const url = API_ROUTES.STRIPE_PRODUCT_LIST;
    const { data } = await axios.get<StripeProductAvailableList[]>(url);
    return data;
  }, []);

  const { data } = useQuery("stripe_products", fetchStripeProductList);

  return { products: data || [] };
}

export function useStripePaymentMethods() {
  const fetchStripePaymentMethods = useCallback(async () => {
    const url = API_ROUTES.STRIPE_PAYMENT_METHOD_LIST;
    const { data } = await axios.get<StripePaymentMethod[]>(url);
    return data;
  }, []);

  const { data } = useQuery(
    "stripe_payment_methods",
    fetchStripePaymentMethods
  );

  return { payment_methods: data || [] };
}

export function useStripeSubscription() {
  const fetchStripeSubscription = useCallback(async () => {
    const url = API_ROUTES.STRIPE_SUBSCRIPTION_DETAIL;
    const { data } = await axios.get<StripeSubscription | null>(url);
    return data;
  }, []);

  const { data, refetch } = useQuery(
    "stripe_subscription",
    fetchStripeSubscription
  );

  return { subscription: data, refetch };
}

export function useStripeUpcomingInvoice() {
  const fetchStripeUpcomingInvoice = useCallback(async () => {
    const url = API_ROUTES.STRIPE_INVOICE_UPCOMING;
    const { data } = await axios.get<StripeInvoice>(url);
    return data;
  }, []);

  const { data } = useQuery(
    "stripe_upcoming_invoice",
    fetchStripeUpcomingInvoice
  );

  return { invoice: data || null };
}
