import { InformationCircleIcon } from "@heroicons/react/outline";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export default function ContactCSVTemplateDownload() {
  const { t } = useTranslation();
  const handleCSVDownload = useCallback(() => {
    const exportedFilename = "contact_template.csv";
    const csvContent = [
      "phone,first_name,last_name,email,external_url,attribute_example_1,attribute_example_2",
      "+52112233445,John,Smith,test@example.com,https://hilos.io/,value_for_variable_1,value_for_variable_2",
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, []);

  return (
    <div>
      <div className="justify-left mt-1.5 my-4 flex items-start text-xs text-gray-500">
        <InformationCircleIcon
          className="mr-1 h-4 w-4 text-blue-400"
          aria-hidden="true"
        />
        <button
          type="button"
          className="text-left text-blue-500"
          onClick={handleCSVDownload}
          aria-label={t(
            "broadcasts:download-csv-template",
            "Download CSV template"
          )}
        >
          {t(
            "broadcasts:download-csv-template",
            "Download here a .csv template to see to how this .csv file should be formatted."
          )}
        </button>
      </div>
    </div>
  );
}
