import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepAnswerSchema } from "../flow";

export const FlowStepMenuSchema = (t: TFunction) =>
  FlowStepAnswerSchema(t).shape({
    answer_validation_message: yup
      .string()
      .required(t(ERROR_MESSAGES.REQUIRED)),
    answer_options: yup
      .array()
      .nullable()
      .notRequired()
      .when("answer_options_render", {
        is: (val) => ["LIST", "BUTTONS"].includes(val),
        then: (schema) =>
          schema.of(
            yup
              .string()
              .max(20, t(ERROR_MESSAGES.MAX_CHARACTERS))
              .required(t(ERROR_MESSAGES.REQUIRED))
          ),
        otherwise: (schema) =>
          schema.of(yup.string().min(1, t(ERROR_MESSAGES.MIN_NUMBER))),
      }),
    next_steps_for_options: yup.array().of(yup.string().nullable()),
    whatsapp_template: yup.string(),
    body: yup
      .string()
      .nullable()
      .notRequired()
      .when("whatsapp_template", {
        is: (val) => !val,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    answer_options_render: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    answer_options_render_list_button_title: yup
      .string()
      .nullable()
      .notRequired()
      .when("answer_options_render", {
        is: "LIST",
        then: (schema) =>
          schema
            .max(20, t(ERROR_MESSAGES.MAX_CHARACTERS))
            .required(t(ERROR_MESSAGES.REQUIRED)),
      }),
  });

export const FlowStepMenuFormat = (step: FlowStepData) => {
  const nextStep = { ...step };

  if (!nextStep.has_max_wait_time) {
    delete nextStep.max_wait_time_amount;
    delete nextStep.max_wait_time_unit;
  }

  if (!nextStep.has_max_answer_attempts) {
    delete nextStep.max_answer_attempts;
    nextStep.answer_failed_next_step = null;
  }

  return nextStep;
};
