import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { PaginatedConversationReadSimple } from "@hilos/types/conversation";
import { API_ROUTES } from "../router/router";

export function useConversationList(contactId: string) {
  const fetchConversationList = useCallback(async () => {
    const url = API_ROUTES.CONVERSATION_LIST + `?contact=${contactId}`;
    const { data } = await axios.get<PaginatedConversationReadSimple>(url);
    return data;
  }, [contactId]);

  const { data } = useQuery(
    ["conversations", contactId],
    fetchConversationList
  );

  return { conversations: data || { count: 0, results: [] } };
}
