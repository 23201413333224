import { FlowStepEdit } from "@hilos/types/private-schema";
import FlowStepTagDetailComponent from "./components/FlowStepTagDetailComponent";

interface FlowStepTagDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepTagDetail({ step }: FlowStepTagDetailProps) {
  return <FlowStepTagDetailComponent step={step} />;
}
