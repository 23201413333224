import * as Sentry from "@sentry/browser";
import axios from "axios";
import Pushy from "pushy-sdk-web";
import { toast } from "sonner";
import { BROWSER_IS_SAFARI } from "./utils";

function isAndroid() {
  try {
    // @ts-ignore
    return typeof window.UtilityJSBridge !== "undefined";
  } catch {}
  return false;
}

function isIOS() {
  try {
    return (
      // @ts-ignore
      typeof window.webkit !== "undefined" &&
      // @ts-ignore
      typeof window.webkit.messageHandlers !== "undefined"
    );
  } catch {}
  return false;
}

export const PLATFORM = isAndroid() ? "ANDROID" : isIOS() ? "IOS" : "BROWSER";

export function getNotificationSupport() {
  return "Notification" in window;
}

export function getNotificationPermissions() {
  return getNotificationSupport() && window.Notification.permission;
}

export function isDocumentFocused() {
  return document.hasFocus();
}

export function requestNotificationPermission(): Promise<NotificationPermission> {
  return new Promise((resolve) => {
    const permission = getNotificationPermissions();

    function handlePermissionResult(permission) {
      resolve(permission || "default");
    }

    if (permission && permission !== "default") {
      resolve(permission);
    } else {
      if (BROWSER_IS_SAFARI) {
        window.Notification.requestPermission(handlePermissionResult);
      } else {
        window.Notification.requestPermission().then(handlePermissionResult);
      }
    }
  });
}

export async function hasNotificationDevice(authToken: string | null = null) {
  try {
    await Pushy.validateDeviceCredentials();
  } catch {
    await deleteCurrentNotificationDevice(authToken);
    return false;
  }
  return true;
}

export async function registerNotificationDevice(
  deviceToken: string,
  authToken: string
) {
  if (!deviceToken) {
    return false;
  }
  try {
    const result = await axios.post(
      "/notification-device",
      {
        token: deviceToken,
        platform: PLATFORM,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "content-type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (result.status === 202) {
      return true;
    }
  } catch {}

  return false;
}

export async function registerPushyNotification(authToken: string) {
  try {
    const deviceToken = await Pushy.register({
      appId: process.env.REACT_APP_PUSHY_APP_ID,
    });

    return registerNotificationDevice(deviceToken, authToken);
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes("Failed to subscribe the device:")) {
        toast.error("Notifications are disabled.", {
          description: "Allow notification permissions for this page.",
        });
      } else if (
        error.message.includes("Web push is not supported by this browser")
      ) {
        toast.error("Notifications not supported.", {
          description:
            "Web push notifications are not supported by this browser.",
        });
      } else {
        Sentry.captureException(error);
      }
    }
  }

  return false;
}

export async function unregisterServiceWorkers() {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (let registration of registrations) {
      registration.unregister();
    }
    return true;
  } catch {
    return false;
  }
}

export async function deleteCurrentNotificationDevice(
  authToken: string | null = null
) {
  try {
    const deviceToken = localStorage.getItem("pushyToken");

    if (deviceToken) {
      localStorage.removeItem("pushyToken");
      localStorage.removeItem("pushyTokenAppId");
      localStorage.removeItem("pushyTokenAuth");

      unregisterServiceWorkers();

      if (authToken) {
        const result = await axios.delete(
          `/notification-device/${deviceToken}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "content-type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (result.status === 202) {
          return true;
        }
      }
    }
  } catch (err) {
    Sentry.captureException(err);
  }

  return false;
}
