import { NodeTypes } from "reactflow";
import StepNode from "./StepNode";
import TriggerNode from "./TriggerNode";

const nodeTypes: NodeTypes = {
  step: StepNode,
  trigger: TriggerNode,
};

export default nodeTypes;
