import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import {
  ArrowSmRightIcon,
  ClipboardIcon,
  ShareIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import HeaderNavigation from "src/components/HeaderNavigation";
import PermissionsChecker from "src/components/PermissionsCheck";
import useFlowTemplateDetail from "src/hooks/useFlowTemplateDetail";
import image_placeholder from "src/assets/img/wa/template_image_placeholder.svg";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import FlowTemplateList from "./FlowTemplateList";

export default function FlowTemplateDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const flowTemplateId = params.id as string;
  const { t } = useTranslation();

  const { flowTemplate } = useFlowTemplateDetail(flowTemplateId);

  const handleDuplicateFlowTemplate = useCallback(
    async (flowId) => {
      try {
        const { data } = await axios.post(
          buildAPIRoute(API_ROUTES.FLOW_DUPLICATE, {
            ":id": flowId,
          })
        );

        if (data) {
          navigate(
            buildRoute(data.is_legacy ? "flow-update" : "flow-editor", {
              ":id": data.id,
            })
          );
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    [navigate]
  );

  return (
    <div className="relative flex h-full flex-col">
      <div className="h-screen overflow-y-auto bg-gray-50">
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("flow-templates"),
                url: "/flow?tab=templates",
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ClipboardIcon className="mr-4 h-7 w-7" aria-hidden="true" />
                {flowTemplate?.preview_title}
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-5">
          <div className="col-span-2 w-full lg:m-4">
            <div className="px-6">
              <img
                src={flowTemplate?.img_url || image_placeholder}
                className="w-full rounded-md lg:mt-10"
                alt="img from URL"
              />
            </div>
            <div className="mx-4 mt-4 flex flex-wrap">
              {flowTemplate?.tags &&
                flowTemplate?.tags.map((tag) => (
                  <span className="mx-1 my-2 rounded-lg bg-blue-100 px-4 py-2 text-xs font-semibold text-blue-500">
                    #{tag}
                  </span>
                ))}
            </div>
          </div>
          <div className="col-span-3 p-6">
            <div className="text-2xl font-semibold text-gray-700">
              {flowTemplate?.title}
            </div>
            <div className="mt-6 whitespace-pre-line text-sm text-neutral">
              {flowTemplate?.description}
            </div>
            <div className="mt-10 gap-4 flex justify-end">
              {flowTemplate?.help_url && (
                <a
                  href={flowTemplate.help_url}
                  target="_blank"
                  rel="noreferrer"
                  className="shrink-0 inline-flex items-center rounded-md  px-6 py-3 text-sm font-medium underline text-hilos"
                >
                  {t(
                    "flows:templates.learn-more",
                    "Learn more about this template"
                  )}
                </a>
              )}
              <PermissionsChecker
                permission="add_flow"
                action={t("settings:permissions.add_flow", "create new flows")}
              >
                <button
                  type="button"
                  onClick={() => {
                    handleDuplicateFlowTemplate(flowTemplate?.flow);
                  }}
                  className="soft-shadow shrink-0 inline-flex items-center rounded-md border border-transparent bg-hilos px-6 py-3 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                >
                  {t("flows:templates.start-button", "Start with this flow")}
                  <ArrowSmRightIcon
                    className="ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </PermissionsChecker>
            </div>
          </div>
        </div>
        <div className="w-full my-4 px-10">
          <div className="bg-blue-gray-light rounded-md p-4">
            <p className="ml-4 flex items-center text-lg mb-2 font-semibold text-gray-600 mt-4">
              <div className="p-2 mr-4 bg-white bg-opacity-50 rounded-full inline-flex items-center justify-center">
                <ShareIcon className=" text-gray-400 rotate-90 h-4 w-4" />
              </div>
              {t("flows:templates.more-templates", "More templates")}
            </p>
            <FlowTemplateList currentTemplateId={flowTemplateId} />
          </div>
        </div>
      </div>
    </div>
  );
}
