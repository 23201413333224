import { useEffect } from "react";

/**
 * Adds the required scripts by the `zapier-app-directory` to the DOM
 *
 * These are the scripts/styles it will mount
 *  - `<script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"></script>`
 *  - `<link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"/>`
 * @see https://zapier.com/partner/embed/app-directory/create?app=hilos
 * @see https://stackoverflow.com/a/34425083/13737199
 */
export default function useZapierEmbedScript() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
    script.type = "module";

    document.body.appendChild(script);

    const styleLink = document.createElement("link");

    styleLink.href =
      "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
    styleLink.rel = "stylesheet";

    document.body.appendChild(styleLink);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(styleLink);
    };
  }, []);
}
