import { PropsWithChildren } from "react";

function ConversationEventContainer({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <div className="relative w-full flex justify-end p-1 text-xs text-gray-400">
        <div className="w-full flex justify-end p-1 text-xs text-gray-400">
          {children}
        </div>
      </div>
    </>
  );
}

export default ConversationEventContainer;
