import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FlowDetailRead } from "@hilos/types/private-schema";
import RegisterUserPhone from "src/components/RegisterUserPhone";
import useHilosStore from "src/hooks/useHilosStore";
import FlowBuilderTestInbound from "./FlowBuilderTestInbound";
import FlowBuilderTestOutbound from "./FlowBuilderTestOutbound";

interface FlowTestModalProps {
  show: boolean;
  onClose: (show: boolean) => void;
  flow: FlowDetailRead;
}

export default function FlowTestModal({
  show,
  onClose,
  flow,
}: FlowTestModalProps) {
  const { session } = useHilosStore();

  if (!session) {
    return null;
  }

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={onClose}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                {session.phone ? (
                  <>
                    {flow.trigger_type === "INBOUND_ANY_MESSAGE" ||
                    flow.trigger_type === "INBOUND_SPECIFIC_MESSAGE" ? (
                      <FlowBuilderTestInbound flow={flow} />
                    ) : (
                      <FlowBuilderTestOutbound flow={flow} />
                    )}
                  </>
                ) : (
                  <RegisterUserPhone />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
