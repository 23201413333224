import { useCallback, useLayoutEffect, useRef } from "react";
import { EyeIcon } from "@heroicons/react/solid";
import { Reorder, useDragControls } from "framer-motion";
import ReorderIcon from "src/components/icons/ReorderIcon";
import { classNames } from "src/Helpers";
import { getContactFieldName } from "./ContactInfoItem";

interface ContactInfoReorderItemProps {
  id: string;
  index: number;
  isVisible: boolean;
  onChangeIsVisible: (id: string) => void;
}

function ContactInfoReorderItem({
  id,
  index,
  isVisible,
  onChangeIsVisible,
}: ContactInfoReorderItemProps) {
  const controls = useDragControls();
  const isClickedRef = useRef(false);
  const lastIndexRef = useRef(index);
  const currentIndexRef = useRef(index);

  const handleChange = useCallback(() => {
    if (isClickedRef.current) {
      onChangeIsVisible(id);
      isClickedRef.current = false;
    }
  }, [id, onChangeIsVisible]);

  const handlePointerUp = useCallback(() => {
    if (currentIndexRef.current !== lastIndexRef.current) {
      lastIndexRef.current = currentIndexRef.current;
    } else {
      isClickedRef.current = true;
    }
  }, []);

  useLayoutEffect(() => {
    currentIndexRef.current = index;
  }, [index]);

  return (
    <Reorder.Item value={id} dragListener={false} dragControls={controls}>
      <button
        onClick={handleChange}
        onPointerUp={handlePointerUp}
        onPointerDown={(event) => controls.start(event)}
        className="group flex w-full select-none flex-row items-center rounded py-1 px-2 hover:bg-gray-300"
      >
        <ReorderIcon className="h-3 w-3 fill-gray-500 group-hover:fill-gray-800" />
        <div className="ml-2 flex w-full flex-row items-center justify-between">
          <span className="w-52 select-none truncate text-left text-sm font-medium text-gray-500 group-hover:text-gray-800 sm:w-96 md:w-64 lg:w-52">
            {getContactFieldName(id)}{" "}
          </span>
          <EyeIcon
            className={classNames(
              "h-4 w-4",
              isVisible
                ? "text-indigo-500 group-hover:text-indigo-600"
                : "text-gray-300 group-hover:text-gray-400"
            )}
          />
        </div>
      </button>
    </Reorder.Item>
  );
}

export default ContactInfoReorderItem;
