import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { User } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useUser(id: number) {
  const fetchAccountMember = useCallback(async () => {
    const { data } = await axios.get<User>(
      buildAPIRoute(API_ROUTES.USER_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data } = useQuery(["user", id], fetchAccountMember, {
    refetchOnWindowFocus: true,
  });

  return data;
}

export default useUser;
