import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useField, useFormikContext } from "formik";
import { hasItems } from "src/helpers/utils";
import { classNames } from "src/Helpers";
import Loading from "../Loading";
import FieldContainer from "./FieldContainer";

interface ComboboxFieldProps {
  loadOptions: (inputValue) => Promise<any>;
  name: string;
  placeholder: string;
  label: string;
  help: string;
  formatOptionLabel?: ({ active, selected, option }) => JSX.Element;
  onChange?: (value) => void;
}

function getValueFromOptions(options: any, value: string) {
  if (hasItems(options)) {
    for (const option of options) {
      if (hasItems(option.options)) {
        const groupOption = option.options.find(
          (optionItem) => optionItem.value === value
        );

        if (groupOption) {
          return groupOption;
        }
      } else if (option.value === value) {
        return option;
      }
    }
  }

  if (!value) {
    return;
  }

  return { label: value, value };
}

export default function ComboboxField({
  loadOptions,
  name,
  placeholder,
  label,
  help,
  formatOptionLabel,
  onChange,
}: ComboboxFieldProps) {
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  const error = meta.touched && meta.error;

  const handleChangeInput = async (inputValue: string) => {
    if (inputValue) {
      setFieldValue(field.name, inputValue);
    }
    setIsLoading(true);
    const result = await loadOptions(inputValue);
    setOptions(result);
    setIsLoading(false);
  };

  const handleChange = useCallback(
    (nextOption) => {
      setFieldValue(field.name, nextOption.value);
      if (onChange) {
        onChange(nextOption.value);
      }
    },
    [field.name, setFieldValue, onChange]
  );

  const handleBlur = useCallback(() => {
    setFieldTouched(field.name);
  }, [field.name, setFieldTouched]);

  const value = useMemo(() => {
    if (field.value === undefined) {
      return null;
    }
    const val = getValueFromOptions(options, field.value);
    return val;
  }, [options, field.value]);

  return (
    <FieldContainer
      name={name}
      label={t(label || "")}
      help={t(help)}
      error={error}
    >
      <Combobox
        as="div"
        by="value"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <div className="relative mt-2">
          <Combobox.Input
            className={`w-full rounded-md border ${
              error ? "border-red-500" : "border-gray-300"
            } shadow-sm bg-white py-2 pl-3 pr-12 text-gray-900 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
            onChange={(e) => handleChangeInput(e.target.value)}
            displayValue={(val: { label: string; value: string }) => {
              return (val && val.value) || "";
            }}
            placeholder={placeholder}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            {!isLoading ? (
              <ChevronDownIcon
                className="h-5 w-5 text-gray-300"
                aria-hidden="true"
              />
            ) : (
              <Loading
                className="h-3 w-3 mr-2 text-gray-400"
                showText={false}
              />
            )}
          </Combobox.Button>

          {options.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option, idx) => (
                <Combobox.Option
                  key={idx}
                  value={option}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 z-10",
                      active ? "bg-hilos-light" : ""
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      {formatOptionLabel ? (
                        formatOptionLabel({ active, selected, option })
                      ) : (
                        <>
                          <div className="flex">
                            <span
                              className={classNames(
                                "truncate",
                                selected && "font-semibold"
                              )}
                            >
                              {option.label}
                            </span>
                          </div>

                          {selected && (
                            <span
                              className={classNames(
                                "absolute inset-y-0 right-0 flex items-center pr-4 text-hilos"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </FieldContainer>
  );
}
