import { ChatIcon, ShareIcon, UserGroupIcon } from "@heroicons/react/outline";
import marketingImg from "src/assets/img/dashboard/guides/marketing-guide.png";
import newAgentImg from "src/assets/img/dashboard/guides/new-agent-guide.png";
import supportImg from "src/assets/img/dashboard/guides/support-guide.png";
import { getTranslationPayload as t } from "src/i18n";

export const NEXT_STEPS = [
  {
    module: "inbox",
    title: t("dashboard:next-steps.message.title", "Send your first message"),
    description: t(
      "dashboard:next-steps.message.description",
      "Send your first message to one of the numbers connected to the sandbox."
    ),
    icon: ChatIcon,
    baseColor: "green",
    href: "/inbox",
  },
  {
    module: "flows",
    title: t(
      "dashboard:next-steps.flows.title",
      "Try creating your first Flow!"
    ),
    description: t(
      "dashboard:next-steps.flows.description",
      "Create and test your first automation in Hilos using Flows!"
    ),
    icon: ShareIcon,
    baseColor: "blue",
    href: "/flow",
  },
  {
    module: "teams",
    title: t("dashboard:next-steps.team.title", "Invite people to your team"),
    description: t(
      "dashboard:next-steps.team.description",
      "Invite more people to create their own users, and discover Hilos together!"
    ),
    icon: UserGroupIcon,
    baseColor: "orange",
    href: "/config/account-members",
  },
];

export const GUIDES = [
  {
    title: t("dashboard:guides.marketing.title", "Hilos for Marketing"),
    description: t(
      "dashboard:guides.marketing.description",
      "Discover where and how WhatsApp Marketing works best, how to run promotional campaigns, send mass messages, and more."
    ),
    image: marketingImg,
    time: 5,
    href: "https://hilos.io/docs/guides/hilos-for-marketing",
  },
  {
    title: t("dashboard:guides.support.title", "Hilos for Support"),
    description: t(
      "dashboard:guides.support.description",
      "Learn the basic structure of a support team, create your support chatbot, understand how views and filters work, and more."
    ),
    image: supportImg,
    time: 10,
    href: "https://hilos.io/docs/guides/hilos-for-support",
  },
  {
    title: t("dashboard:guides.new-agent.title", "I'm a new agent"),
    description: t(
      "dashboard:guides.new-agent.description",
      "Learn the essentials if you're new to Hilos as a new agent. Our introduction to Hilos will surely help you!"
    ),
    image: newAgentImg,
    time: 7,
    href: "https://hilos.io/docs/guides/new-agent",
  },
];
