import { useEffect, useRef } from "react";
import { FieldHelperProps } from "formik";

function useTouchField(helpers: FieldHelperProps<any>) {
  const helpersRef = useRef(helpers);

  useEffect(() => {
    helpersRef.current = helpers;
  }, [helpers]);

  useEffect(() => {
    return () => {
      helpersRef.current.setTouched(true);
    };
  }, []);
}

export default useTouchField;
