import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { TagData } from "@hilos/types/conversation";
import { API_ROUTES } from "../router/router";

export function useContactTags() {
  const fetchTags = useCallback(async () => {
    const { data } = await axios.get<TagData[]>(API_ROUTES.CONTACT_TAG_CREATE_LIST);
    return data;
  }, []);

  const { data } = useQuery("contact_tags", fetchTags);

  return { tags: data || [] };
}

export function useConversationTags() {
  const fetchTags = useCallback(async () => {
    const { data } = await axios.get<TagData[]>(
      API_ROUTES.CONVERSATION_TAG_CREATE_LIST
    );
    return data;
  }, []);

  const { data } = useQuery("conversation_tags", fetchTags);

  return { tags: data || [] };
}
