import {
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GlobeAltIcon,
  LightningBoltIcon,
  ShareIcon,
  UserCircleIcon,
  VariableIcon,
} from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { debounce } from "lodash";
import { FlowData } from "@hilos/types/flow";
import { HilosVariableData, HilosVariableTypeData } from "@hilos/types/hilos";
import { filterByStepType } from "src/helpers/flow";
import { hasItems } from "src/helpers/utils";
import {
  getAllowedPrevSteps,
  getContactVariables,
} from "src/helpers/variables";
import { useCustomFields } from "src/hooks/useContactCustomField";
import { GLOBAL_VARIABLES } from "../helpers/steps";

interface UseVariablesParams {
  currentStepIndex?: number | null;
  allowedVariableDataTypes?: HilosVariableTypeData[];
  allowedVariableTypes?: ("contact" | "flow" | "step" | "global" | "trigger")[];
}

export interface OptionVariable {
  label: string;
  value: string;
  variable: HilosVariableData | null;
}

export interface OptionVariableGroup {
  label: string;
  options: OptionVariable[];
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

const getOptionFromVariable = (
  variable: HilosVariableData
): OptionVariable => ({
  value: variable.id,
  label: variable.name,
  variable,
});

const getOptionsFromVariables = (
  variables: HilosVariableData[],
  {
    allowedVariableDataTypes = null,
    fromVariableSourceId = null,
  }: {
    allowedVariableDataTypes?: HilosVariableTypeData[] | null;
    fromVariableSourceId?: string | null;
  }
) =>
  variables.reduce((options, variable) => {
    if (
      (!allowedVariableDataTypes ||
        allowedVariableDataTypes.includes(variable.data_type)) &&
      (fromVariableSourceId
        ? variable.from_variable_source_id === fromVariableSourceId
        : !variable.from_variable_source_id)
    ) {
      options.push(getOptionFromVariable(variable));
    }

    return options;
  }, [] as OptionVariable[]);

function useVariables({
  currentStepIndex = null,
  allowedVariableDataTypes,
  allowedVariableTypes = ["flow", "step", "contact", "global", "trigger"],
}: UseVariablesParams) {
  const { contactCustomFields } = useCustomFields();
  const { values } = useFormikContext<FlowData>();
  const [lastUpdateAt, setLastUpdatedAt] = useState(+new Date());
  const variablesByRef = useRef<{
    [K in "id" | "name"]: { [key: string]: HilosVariableData };
  }>({ id: {}, name: {} });

  const handlePrevStepsData = useCallback(
    debounce(
      (steps) => ({
        hasMissingActionTest: Boolean(
          steps.some(
            filterByStepType(
              "ACTION",
              currentStepIndex,
              (step) => !hasItems(step.action_responses)
            )
          ) ||
            steps.some(
              filterByStepType(
                "HUBSPOT_CONTACT_GET",
                currentStepIndex,
                (step) => !hasItems(step.action_responses)
              )
            )
        ),
        allowedPrevSteps: getAllowedPrevSteps(steps, currentStepIndex),
      }),
      350,
      { leading: true, trailing: true }
    ),
    [currentStepIndex]
  );

  const { hasMissingActionTest, allowedPrevSteps } = useMemo(
    () => handlePrevStepsData(values.steps),
    [values.steps, handlePrevStepsData]
  );

  const [isInboundFlow, contactVariables] = useMemo(() => {
    const nextContactVariables = getContactVariables(contactCustomFields);
    const nextIsInboundFlow = [
      "INBOUND_SPECIFIC_MESSAGE",
      "INBOUND_ANY_MESSAGE",
    ].includes(values.trigger_type);

    return [nextIsInboundFlow, nextContactVariables];
  }, [values.trigger_type, contactCustomFields]);

  const options: OptionVariableGroup[] = useMemo(() => {
    const flowVariableOptions: OptionVariable[] = [];
    const triggerVariableOptions: OptionVariable[] = [];
    const stepVariableOptions: OptionVariable[] = [];

    if (
      allowedVariableTypes.some((variablesType) =>
        ["flow", "trigger", "step"].includes(variablesType)
      )
    ) {
      for (const variable of values.variables) {
        if (allowedVariableTypes.includes(variable.source)) {
          if (
            allowedVariableDataTypes &&
            !allowedVariableDataTypes.includes(variable.data_type)
          ) {
            continue;
          }

          switch (variable.source) {
            case "flow":
              flowVariableOptions.push(getOptionFromVariable(variable));
              break;
            case "trigger":
              triggerVariableOptions.push(getOptionFromVariable(variable));
              break;
            case "step":
              if (
                (!allowedPrevSteps ||
                  (variable.step_id &&
                    allowedPrevSteps.includes(variable.step_id))) &&
                !variable.from_variable_source_id
              ) {
                stepVariableOptions.push(getOptionFromVariable(variable));
              }
              break;
            default:
              break;
          }
        }
      }
    }

    return allowedVariableTypes.map((variableType) => {
      switch (variableType) {
        case "flow":
          return {
            label: "Flow Variables",
            icon: VariableIcon,
            options: flowVariableOptions,
          };
        case "trigger":
          return {
            label: "Trigger Variables",
            icon: LightningBoltIcon,
            options: triggerVariableOptions,
          };
        case "step":
          return {
            label: "Step Variables",
            icon: ShareIcon,
            options: stepVariableOptions,
          };
        case "contact":
          return {
            label: "Contact Variables",
            icon: UserCircleIcon,
            options: getOptionsFromVariables(contactVariables, {
              allowedVariableDataTypes,
            }),
          };
        default:
          return {
            label: "Global Variables",
            icon: GlobeAltIcon,
            options: getOptionsFromVariables(GLOBAL_VARIABLES, {
              allowedVariableDataTypes,
            }),
          };
      }
    });
  }, [
    values.variables,
    contactVariables,
    allowedPrevSteps,
    allowedVariableTypes,
    allowedVariableDataTypes,
  ]);

  const handleGetVariable = useCallback(
    (value: string, key: string = "id"): HilosVariableData | null =>
      (Boolean(lastUpdateAt) && variablesByRef.current[key][value]) || null,
    [lastUpdateAt]
  );

  const handleGetOptionsFromVariableData = useCallback(
    (id: string) => {
      if (!values.variables) {
        return [];
      }

      return getOptionsFromVariables(values.variables, {
        fromVariableSourceId: id,
        allowedVariableDataTypes: ["text", "number", "bool"],
      });
    },
    [values.variables]
  );

  useEffect(() => {
    const nextVariablesById = {};
    const nextVariablesByName = {};

    for (const variable of values.variables
      .concat(contactVariables)
      .concat(GLOBAL_VARIABLES)) {
      nextVariablesById[variable.id] = variable;
      nextVariablesByName[variable.name] = variable;
    }

    variablesByRef.current = {
      id: nextVariablesById,
      name: nextVariablesByName,
    };
    setLastUpdatedAt(+new Date());
  }, [values.variables, contactVariables]);

  return {
    options,
    isInboundFlow,
    hasMissingActionTest,
    handleGetVariable,
    handleGetOptionsFromVariableData,
  };
}

export default useVariables;
