import * as yup from "yup";
import { Role } from "@hilos/types/private-schema";
import { ERROR_MESSAGES } from "src/constants/Form";
import { getTranslationPayload as t } from "src/i18n";

export const getInitialValues = (roles: Role[]) => {
  return {
    invitations: [
      {
        email: "",
        role: roles.find((role) => role.name === "Admin")?.id,
      },
    ],
  };
};

export const schema = yup.object().shape({
  invitations: yup
    .array(
      yup.object().shape({
        email: yup
          .string()
          .email(ERROR_MESSAGES.email)
          .required(ERROR_MESSAGES.required),
        role: yup.string().required(ERROR_MESSAGES.required),
      })
    )
    .min(1, ERROR_MESSAGES.minNumber),
});

export const FIELDS = {
  email: {
    key: "email",
    label: t("settings:users.email.label", "Email"),
  },
  role: {
    key: "role",
    label: t("settings:users.role.label", "Role"),
  },
};
