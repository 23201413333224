import * as meta from "./WhatsAppTemplateMeta";

import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import {
  CheckCircleIcon,
  ClipboardListIcon,
  DuplicateIcon,
  ExclamationIcon,
  EyeIcon,
  PencilAltIcon,
  RefreshIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import { AccountStatus } from "src/types/account";
import AccountStatusNotice from "src/components/Notice/AccountStatusNotice";
import ChannelBadge from "../config/channel/ChannelBadge";
import DeleteObjectModal from "../../components/DeleteObjectModal";
import DropdownMenu from "../../components/DropdownMenu";
import EmptyStateComponent from "src/components/EmptyStates/EmptyStateComponent";
import HeaderNavigation from "../../components/HeaderNavigation";
import HelpDocsLink from "src/components/HelpDocsLink";
import Loading from "src/components/Loading";
import OnboardingModuleModal from "src/containers/onboarding_module/OnboardingModuleModal";
import PermissionsChecker from "src/components/PermissionsCheck";
import StateButton from "../../components/StateButton";
import Table from "src/components/Table/Table";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import WhatsAppTemplateCreateButton from "./WhatsAppTemplateCreateButton";
import WhatsAppTemplatePreviewModal from "./WhatsAppTemplatePreviewModal";
import WhatsAppTemplateStatusBadge from "./WhatsAppTemplateStatusBadge";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { classNames } from "../../Helpers";
import useHilosStore from "../../hooks/useHilosStore";
import { wrapWords } from "../../Helpers";

export default function WhatsAppTemplateList() {
  const { t } = useTranslation();
  const { session, updateSession } = useHilosStore();
  const [triggerReload, setTriggerReload] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [syncSuccess, setSyncSuccess] = useState(false);
  const [syncSubmitted, setSyncSubmitted] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    WhatsAppTemplate | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [templateRelatedObjects, setTemplateRelatedObjects] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const syncTemplates = async () => {
    setIsSubmitting(true);
    try {
      await axios.get(buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_SYNC));
      setTriggerReload(!triggerReload);
      setSyncSuccess(true);
      setSyncSubmitted(true);
    } catch (err) {
      setSyncSuccess(false);
      setSyncSubmitted(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setSubmitting(true);
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      setTriggerReload(!triggerReload);
      setShowDeleteModal(false);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setDeleteSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t("templates:error-general", "An error occurred, please try again.")
        );
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  const handleShowTemplate = useCallback(async (templateId, show) => {
    try {
      await axios.patch(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE, {
          ":id": templateId,
        }),
        { show_in_sandbox: show }
      );
      setTriggerReload((t) => !t);
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  const handleDuplicateTemplate = useCallback(
    async (templateId) => {
      try {
        const { data } = await axios.post(
          buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_DUPLICATE, {
            ":id": templateId,
          })
        );
        setTriggerReload((t) => !t);

        if (data) {
          navigate(
            buildRoute("whatsapp-template", {
              ":id": data.id,
            })
          );
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    [navigate]
  );

  const getRelatedObjectLabel = (object) => {
    switch (object.model) {
      case "MessageBlast":
        return (
          <ul>
            {/* t("templates:related-objects.broadcast_one", "{{count}} broadcast") */}
            {/* t("templates:related-objects.broadcast_other", "{{count}} broadcasts") */}
            {t("templates:related-objects.broadcast", { count: object.count })}
          </ul>
        );
      case "QuickReply":
        return (
          <ul>
            {/* t("templates:related-objects.quick-reply_one", "{{count}} quick reply") */}
            {/* t("templates:related-objects.quick-reply_other", "{{count}} quick replies") */}
            {t("templates:related-objects.quick-reply", {
              count: object.count,
            })}
          </ul>
        );
      case "FlowStepTemplate":
        return (
          <ul>
            {/* t("templates:related-objects.flow-step_one", "{{count}} flow step") */}
            {/* t("templates:related-objects.flow-step_other", "{{count}} flow steps") */}
            {t("templates:related-objects.flow-step", {
              count: object.count,
            })}
          </ul>
        );
      // break;

      default:
        break;
    }
  };

  const handleDeleteAction = async (template) => {
    setIsLoading(true);
    setSelectedTemplate(template);
    setShowDeleteModal(true);
    try {
      const { data } = await axios.get(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_RELATED_OBJECTS, {
          ":id": template.id,
        })
      );
      setTriggerReload((t) => !t);

      if (data) {
        setTemplateRelatedObjects(data.related_objects);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseHelpModal = useCallback(() => {
    if (!session?.visited_modules.includes("templates")) {
      updateSession({
        visited_modules: [...(session?.visited_modules || []), "templates"],
      });
    }
    setShowHelpModal(false);
  }, []);

  useEffect(() => {
    setShowHelpModal(
      session ? !session.visited_modules.includes("templates") : false
    );
  }, []);

  if (!session) {
    return null;
  }

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        <AccountStatusNotice />

        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home", "Home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("whatsapp-templates", "WhatsApp Templates"),
                url: buildRoute("whatsapp-template-list"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ClipboardListIcon
                  className="mr-2 h-7 w-7"
                  aria-hidden="true"
                />
                {t("whatsapp-templates", "WhatsApp Templates")}
              </h2>
              {session.account.status === AccountStatus.NEW ||
              session.account.status === AccountStatus.IN_SANDBOX ? (
                <p className="mt-1 text-sm text-gray-500">
                  <Trans i18nKey="templates:sandbox-templates">
                    These are the message templates you can use in your Sandbox
                    to{" "}
                    <a
                      href="https://developers.facebook.com/docs/whatsapp/overview#sending-messages"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      open conversations with your contacts
                    </a>
                    . Once you are connected to WhatsApp, you'll be able to
                    create your own templates.{" "}
                    <HelpDocsLink
                      href="https://hilos.io/docs/user/using-hilos/templates/creating-a-template"
                      className="ml-1 text-blue-500"
                    />
                  </Trans>
                </p>
              ) : (
                <p className="mt-1 text-sm text-gray-500">
                  <Trans i18nKey="templates:user-templates">
                    These are the message templates you can use to{" "}
                    <a
                      href="https://developers.facebook.com/docs/whatsapp/overview#sending-messages"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      open conversations with your contacts
                    </a>
                    . We sync these from WhatsApp every 5 minutes.{" "}
                    <HelpDocsLink
                      href="https://hilos.io/docs/user/using-hilos/templates/creating-a-template"
                      className="ml-1 text-blue-400"
                    />
                  </Trans>
                </p>
              )}
            </div>
            <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
              <span className="md:ml-3">
                <div className="flex flex-col items-start  gap-2 md:flex-row">
                  <PermissionsChecker
                    permission="add_whatsapptemplate"
                    showNotice={false}
                  >
                    <WhatsAppTemplateCreateButton
                      accountStatus={session.account.status}
                    />
                  </PermissionsChecker>
                  {session.account &&
                    session.account.status === AccountStatus.ACTIVE && (
                      <StateButton
                        isSubmitting={isSubmitting}
                        success={syncSuccess}
                        submitted={syncSubmitted}
                        submittingText="Sync from WhatsApp now"
                        successText="Sync successful!"
                        className="inline-flex items-center rounded-md border border-hilos bg-white px-4 py-2 text-sm font-medium text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                        type="button"
                        onClick={(_) => syncTemplates()}
                        buttonType="button"
                        initialText={
                          <>
                            <RefreshIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            {t(
                              "templates:sync-templates",
                              "Sync from WhatsApp now"
                            )}
                          </>
                        }
                      />
                    )}
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className="min-h-screen bg-gray-50">
          <div className="h-full sm:px-6 sm:pt-8 lg:px-8">
            <Table
              baseURL={buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_LIST)}
              paginated={true}
              triggerReload={triggerReload}
              filters={["channel"]}
              tableHead={
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("name", "Name")}
                    </th>
                    {session?.account.has_used_multiple_channels && (
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                      >
                        {t("channel", "Channel")}
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("category", "Category")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("language", "Language")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("status", "Status")}
                    </th>
                    <th scope="col" className="py-3 px-4"></th>
                  </tr>
                </thead>
              }
              tableRow={(template: WhatsAppTemplate) => (
                <>
                  <td className="whitespace-nowrap px-4 py-3 font-mono text-sm text-gray-900">
                    <WhatsAppTemplatePreviewModal
                      template={template}
                      disableFileUpload={true}
                    />
                    <div className="mt-1 text-xs text-gray-600">
                      {template.id}
                    </div>
                    <div className="mt-1 text-xs text-gray-400">
                      {wrapWords(meta.getTemplateShortPreview(template), 10)}
                    </div>
                  </td>
                  {session?.account.has_used_multiple_channels && (
                    <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                      <ChannelBadge channel={template.channel} />
                    </td>
                  )}
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {template.category && meta.CATEGORIES[template.category] && (
                      <div className="flex items-center">
                        {meta.CATEGORIES[template.category].icon}
                        {t(meta.CATEGORIES[template.category].label)}
                      </div>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {meta.SUPPORTED_LANGUAGES[template.language]}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-center text-sm text-gray-600">
                    <WhatsAppTemplateStatusBadge
                      status={template.status}
                      reason={template.rejected_reason}
                    />
                  </td>
                  {session.account &&
                    session.account.status === AccountStatus.ACTIVE && (
                      <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-900">
                        <DropdownMenu
                          menuItems={[
                            (active) =>
                              template.status === meta.STATUS.DRAFT && (
                                <button
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "group flex w-full items-center px-4 py-2 text-left text-sm"
                                  )}
                                  type="button"
                                  onClick={() =>
                                    navigate(
                                      buildRoute("whatsapp-template", {
                                        ":id": template.id,
                                      })
                                    )
                                  }
                                >
                                  <PencilAltIcon
                                    className="-ml-0.5 mr-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  {t("continue-editing", "Continue editing")}
                                </button>
                              ),
                            (active) => (
                              <PermissionsChecker
                                permission="add_whatsapptemplate"
                                notice={
                                  <button
                                    className="text-gray-400 line-through flex w-full items-center px-4 py-2 text-left text-sm"
                                    type="button"
                                    disabled
                                  >
                                    <DuplicateIcon
                                      className="-ml-0.5 mr-2 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                    {t(
                                      "templates:duplicate-template",
                                      "Duplicate template"
                                    )}
                                  </button>
                                }
                              >
                                <button
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "flex w-full items-center px-4 py-2 text-left text-sm"
                                  )}
                                  type="button"
                                  onClick={(_) =>
                                    handleDuplicateTemplate(template.id)
                                  }
                                >
                                  <DuplicateIcon
                                    className="-ml-0.5 mr-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  {t(
                                    "templates:duplicate-template",
                                    "Duplicate template"
                                  )}
                                </button>
                              </PermissionsChecker>
                            ),
                            (active) =>
                              template.channel.id.toString() ===
                                process.env.REACT_APP_SANDBOX_CHANNEL_ID &&
                              template.status === meta.STATUS.APPROVED ? (
                                <button
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "flex w-full items-center px-4 py-2 text-left text-sm"
                                  )}
                                  type="button"
                                  onClick={(_) =>
                                    handleShowTemplate(
                                      template.id,
                                      !template.show_in_sandbox
                                    )
                                  }
                                >
                                  <EyeIcon
                                    className="-ml-0.5 mr-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  {template.show_in_sandbox
                                    ? t(
                                        "templates:hide_sandbox",
                                        "Hide from sandbox"
                                      )
                                    : t(
                                        "templates:show_in_sandbox",
                                        "Show in Sandbox"
                                      )}
                                </button>
                              ) : null,
                            (active) => (
                              <PermissionsChecker
                                permission="delete_whatsapptemplate"
                                notice={
                                  <button
                                    className="text-gray-400 line-through flex w-full items-center px-4 py-2 text-left text-sm"
                                    type="button"
                                    disabled
                                  >
                                    <TrashIcon
                                      className="-ml-0.5 mr-2 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                    {t("delete", "Delete")}
                                  </button>
                                }
                              >
                                <button
                                  className={classNames(
                                    active
                                      ? "bg-red-100 text-red-800"
                                      : "text-gray-700",
                                    "group flex w-full items-center px-4 py-2 text-sm"
                                  )}
                                  type="button"
                                  onClick={() => handleDeleteAction(template)}
                                >
                                  <TrashIcon
                                    className="-ml-0.5 mr-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  {t("delete", "Delete")}
                                </button>
                              </PermissionsChecker>
                            ),
                          ]}
                        />
                      </td>
                    )}
                </>
              )}
              dataName={t("templates", "Templates")}
              noResults={
                <>
                  <h4>
                    {t(
                      "templates:no-template-with-filters.title",
                      "Couldn't find any templates with these filters."
                    )}
                  </h4>
                  <p className="text-muted">
                    {t(
                      "templates:no-template-with-filters.description",
                      "Remove filters to try again."
                    )}
                  </p>
                </>
              }
              createObject={
                <EmptyStateComponent routeName="templates" showInfo={true}>
                  <WhatsAppTemplateCreateButton
                    accountStatus={session.account.status}
                  />
                </EmptyStateComponent>
              }
            ></Table>
          </div>
        </div>
      </div>
      {selectedTemplate && (
        <DeleteObjectModal
          obj={selectedTemplate}
          objDescription={selectedTemplate.name}
          actionDescription={
            !selectedTemplate.is_sample
              ? t(
                  "templates:delete-warning",
                  "When deleting this template, you won't be able to use it anywhere."
                )
              : ""
          }
          onDelete={onDelete}
          deleteSuccess={deleteSuccess}
          deleteSubmitted={deleteSubmitted}
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          disableButtons={selectedTemplate.is_sample}
        >
          <div>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {selectedTemplate.is_sample ? (
                  <Trans i18nKey="templates:sample-template">
                    <div className="my-2 rounded-md bg-yellow-50 p-3 text-sm font-medium text-yellow-700">
                      <p className="flex">
                        <ExclamationIcon
                          className="mr-2 h-5 w-5 text-yellow-600"
                          aria-hidden="true"
                        />{" "}
                        Sample templates cannot be deleted.
                      </p>
                    </div>
                    <p className="mt-4 text-sm text-gray-500">
                      Enable the{" "}
                      <Link
                        to={buildRoute("config-templates")}
                        className="font-semibold text-blue-500"
                      >
                        hide WhatsApp's sample templates
                      </Link>{" "}
                      option in your settings to hide them from your template
                      list.
                    </p>
                  </Trans>
                ) : (
                  <>
                    {templateRelatedObjects.length > 0 ? (
                      <div className="my-2 rounded-md bg-yellow-50 p-3 text-sm font-medium text-yellow-700">
                        <p className="flex">
                          <ExclamationIcon
                            className="mr-2 h-5 w-5 text-yellow-600"
                            aria-hidden="true"
                          />{" "}
                          {t(
                            "templates:unsafe-delete",
                            "This template is being used in the following objects:"
                          )}
                        </p>
                        <div className="ml-2 mt-1 font-normal">
                          {templateRelatedObjects.map((object) => {
                            return getRelatedObjectLabel(object);
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="my-2 rounded-md bg-green-50 p-3 text-sm font-medium text-green-700">
                        <p className="flex">
                          <CheckCircleIcon
                            className="mr-2 h-5 w-5 text-green-600"
                            aria-hidden="true"
                          />{" "}
                          {t(
                            "templates:safe-delete",
                            "This template is not being used and it is safe to delete it."
                          )}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </DeleteObjectModal>
      )}
      <OnboardingModuleModal
        routeName="templates"
        show={showHelpModal}
        handleClose={handleCloseHelpModal}
      />
    </>
  );
}
