import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "@heroicons/react/outline";
import axios from "axios";
import { debounce } from "lodash";
import { classNames } from "src/Helpers";
import Loading from "../Loading";
import TableFilter from "./TableFilter";
import TablePagination from "./TablePagination";

interface TableProps<T> {
  baseURL: string;
  paginated?: boolean;
  bordered?: boolean;
  tableHead?: JSX.Element;
  tableRow: (dataRow: T) => JSX.Element | Array<JSX.Element>;
  dataName: string;
  noResults?: JSX.Element;
  createObject?: JSX.Element;
  triggerReload?: boolean;
  initialSearchParams?: Record<string, unknown>;
  tableKey?: (dataRow: T) => string;
  initialResults?: Array<T>;
  noAPICalls?: boolean;
  filters?: Array<string>;
}

export interface TableSearchParams {
  search?: string;
  page?: number;
}

export default function Table<T>({
  baseURL,
  paginated,
  bordered = true,
  tableHead,
  tableRow,
  dataName,
  noResults,
  createObject = <></>,
  triggerReload = false,
  initialSearchParams,
  tableKey,
  initialResults,
  filters = [],
}: TableProps<T>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(initialResults);
  const [count, setCount] = useState<number | undefined>(undefined);
  const [searchParams, setSearchParams] = useState<TableSearchParams>({});

  const getAPIURL = useCallback(() => {
    const usp = new URLSearchParams();
    Object.getOwnPropertyNames(searchParams).forEach((n) =>
      usp.set(n, searchParams[n])
    );
    let initialSearchParamsString: URLSearchParams | string | undefined =
      undefined;
    if (initialSearchParams) {
      if (Array.isArray(initialSearchParams)) {
        initialSearchParams.forEach((isp) => {
          const u = new URLSearchParams();
          Object.getOwnPropertyNames(isp).forEach((n) => u.set(n, isp[n]));
          initialSearchParamsString = initialSearchParamsString
            ? `${initialSearchParamsString}&${u}`
            : u;
        });
      } else {
        const u = new URLSearchParams();
        Object.getOwnPropertyNames(initialSearchParams).forEach((n) =>
          u.set(n, initialSearchParams[n] as string)
        );
        initialSearchParamsString = u;
      }
    }
    const params = [initialSearchParamsString, usp];
    let url = baseURL;
    params
      .filter((p) => p)
      .forEach((p) => {
        url === baseURL ? (url = `${url}?${p}`) : (url = `${url}&${p}`);
      });
    return url;
  }, [initialSearchParams, baseURL, searchParams]);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(getAPIURL());
      setResults(paginated ? response.data.results : response.data);
      setCount(paginated ? response.data.count : response.data.length);
    } catch (err) {
      console.log(err);
      setResults(undefined);
      setCount(undefined);
    }
    setLoading(false);
  }, [getAPIURL, paginated]);

  const handleSearchChangeDebounced = useCallback(
    debounce((ev) => {
      setSearchParams({ search: ev.target.value });
    }, 500),
    []
  );

  const handleUpdateParams = useCallback((key, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    getData();
  }, [searchParams, triggerReload, getData]);

  if (!results) {
    return <Loading />;
  }

  if (!count && !Object.getOwnPropertyNames(searchParams).length) {
    return <div className="w-full text-center">{createObject}</div>;
  }

  return (
    <>
      <div
        className={classNames(
          bordered &&
            "border-t border-b border-gray-200 bg-white shadow sm:rounded-lg sm:border ",
          "overflow-hidden"
        )}
      >
        <div className="flex items-center justify-between border-b py-2">
          <div className="flex grow items-center px-2 sm:px-4 lg:px-4">
            <div>
              <h5 className="text-sm font-medium uppercase tracking-wide text-gray-500 ">
                {`${dataName}`}
              </h5>
            </div>
          </div>
          <div className="grow-1 align-center sm:grow-0">
            <form
              onSubmit={(ev) => ev.preventDefault()}
              className="flex items-center"
            >
              {filters.map((filter) => (
                <div className="relative w-60 grow-0 px-1" key={filter}>
                  <TableFilter
                    filterName={filter}
                    onFilterChange={handleUpdateParams}
                  />
                </div>
              ))}
              <div className="w-42 relative grow-0">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  className="block w-full border-0 border-transparent py-2 pl-10 focus:ring-0 sm:text-sm bg-transparent"
                  type="search"
                  placeholder={t("search", "Search")}
                  onChange={(event) => {
                    setLoading(true);
                    handleSearchChangeDebounced(event);
                  }}
                />
              </div>
            </form>
          </div>
          <div className={`grow-0 ${loading ? "visible" : "invisible"}`}>
            <Loading showText={false} />
          </div>
        </div>

        <div
          className={`flex overflow-x-auto min-w-full ${
            !paginated ? "border-b border-gray-200" : ""
          }`}
        >
          <table className="min-w-full divide-y divide-gray-200">
            {tableHead}

            <tbody className="divide-y divide-gray-200">
              {results.map((data) => (
                // @ts-expect-error If no tableKey is passed we try to use obj id
                <tr key={tableKey ? tableKey(data) : data?.id}>
                  {tableRow(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {paginated && (
          <TablePagination
            searchParams={searchParams}
            results={results}
            count={count}
            updateSearchParams={handleUpdateParams}
            dataName={dataName}
          />
        )}
      </div>

      {count === 0 && (
        <div className="mt-4 bg-gray-50 text-center text-sm text-gray-500">
          {noResults}
        </div>
      )}
    </>
  );
}
