import {
  ArrowSmRightIcon,
  ChatIcon,
  CollectionIcon,
  HeartIcon,
  InboxIcon,
  NewspaperIcon,
  PhoneIcon,
  ShareIcon,
  SupportIcon,
} from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";

import { AccountStatus } from "src/types/account";
import ActionCard from "./ActionCard";
import AnchorTracked from "src/components/Posthog/AnchorTracked";
import AnnouncementsSection from "./AnnouncementsSection";
import DisclosureCard from "./DisclosureCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LinkTracked from "src/components/Posthog/LinkTracked";
import MainDashboardCard from "./MainDashboardCard";
import NewDashboard from "./new/NewDashboard";
import QRCode from "qrcode";
import SandboxDashboard from "./sandbox/SandboxDashboard";
import { buildRoute } from "../../router/router";
import { captureException } from "@sentry/react";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import logo from "src/assets/img/logo512.png";
import useHilosStore from "../../hooks/useHilosStore";

interface onboardingCard {
  title: string;
  estimatedTimeMin: number;
  icon: JSX.Element;
  color: string;
  firstSection: JSX.Element;
  secondSection?: JSX.Element;
  availableForStatus: AccountStatus[];
  variant?: boolean;
}

export default function Dashboard() {
  const { t } = useTranslation();
  const { session, reloadSession } = useHilosStore();
  const QRSandboxCode = localStorage.getItem("qr_sandbox_code");
  const urlWhatsAppCode = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SANDBOX_NUMBER}&text=${session?.phone_connect_code}`;

  useEffect(() => {
    const interval = setInterval(() => {
      reloadSession();
    }, 1000 * 3);
    return () => clearInterval(interval);
  }, [reloadSession]);

  const generateQRCode = useCallback(() => {
    QRCode.toDataURL(
      urlWhatsAppCode,
      {
        width: 170,
        margin: 2,
        color: {
          dark: "#486581",
          light: "#ffffff",
        },
      },
      (err, url) => {
        if (err) return captureException(err);

        localStorage.setItem("qr_sandbox_code", url);
      }
    );
  }, [urlWhatsAppCode]);

  useEffect(() => {
    if (session?.account.status === AccountStatus.NEW || !QRSandboxCode) {
      generateQRCode();
    }
  }, [QRSandboxCode, generateQRCode, session?.account.status]);

  const onboardingActionCards: onboardingCard[] = [
    {
      // i18n.t("dashboard:jtbd.create-widget.title", "Add a WhatsApp button to your page")
      title: "dashboard:jtbd.create-widget.title",
      estimatedTimeMin: 7,
      icon: <PhoneIcon className="h-5 w-5 text-white" aria-hidden="true" />,
      color: "pink-600",
      firstSection: (
        <div className="p-2">
          <div className="mt-4 sm:mx-4 rounded-md overflow-clip ">
            <iframe
              className="w-full h-60"
              src="https://www.youtube.com/embed/U-2yKonLpTo"
              title="YouTube video player"
              // @ts-ignore
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      ),
      secondSection: (
        <>
          <div className="mt-4 ml-4 p-2">
            <Trans i18nKey="dashboard:jtbd.create-widget.description">
              <p className="text-lg font-medium">
                Trigger the Chatbot your client needs{" "}
              </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                From your WhatsApp button with Hilos you can trigger different
                flows from your website. You can create a button for each
                channel in your Hilos account and with the same button you can
                offer several experiences triggering specific flows; from your
                general support chatbot to a flow to profile your leads.
              </p>
            </Trans>
            <LinkTracked
              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
              to={buildRoute("wa-widget")}
              posthogEvent="click whatsapp widget create"
            >
              {t(
                "dashboard:jtbd.create-widget.button",
                "Create WhatsApp Widget"
              )}
              <ArrowSmRightIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </LinkTracked>
          </div>
        </>
      ),
      availableForStatus: [AccountStatus.ACTIVE],
    },
    {
      // i18n.t("dashboard:jtbd.already-connected.title", "Send your first message and set up your account")
      title: "dashboard:jtbd.already-connected.title",
      estimatedTimeMin: 7,
      icon: <ChatIcon className="h-5 w-5 text-white" aria-hidden="true" />,
      color: "green-600",
      firstSection: (
        <div className="p-2">
          <div className="mt-4 sm:mx-4 rounded-md overflow-clip ">
            <iframe
              className="w-full h-60"
              src="https://www.youtube.com/embed/U-2yKonLpTo"
              title="YouTube video player"
              // @ts-ignore
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      ),
      secondSection: (
        <>
          <div className="mt-4 ml-4 p-2">
            <Trans i18nKey="dashboard:jtbd.already-connected.description">
              <p className="text-lg font-medium">
                Start communicating with your clients{" "}
              </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                Complete your WhatsApp profile. After watching this video, you
                will learn how to create templates to initiate conversations
                with your clients, set up the schedule for your WhatsApp
                account, and enable an automatic response for messages received
                outside of this schedule.
              </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                {" "}
                You will also have a step-by-step guide to verify your business
                account and reach out to over 250 clients per day.
              </p>
            </Trans>
            <LinkTracked
              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
              to={buildRoute("whatsapp-template-list")}
              posthogEvent="click contact tag create"
            >
              {t(
                "dashboard:jtbd.already-connected.button",
                "Create my first template"
              )}
              <ArrowSmRightIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </LinkTracked>
          </div>
        </>
      ),
      availableForStatus: [AccountStatus.ACTIVE],
    },
    {
      // i18n.t("dashboard:jtbd.organize-leads.title", "Organize your leads automatically")
      title: "dashboard:jtbd.organize-leads.title",
      estimatedTimeMin: 5,
      icon: (
        <CollectionIcon className="h-5 w-5 text-white" aria-hidden="true" />
      ),
      color: "blue-500",
      firstSection: (
        <div className="p-2">
          <div className="mt-4 sm:mx-4 rounded-md overflow-clip ">
            <iframe
              className="w-full h-60"
              src="https://www.youtube.com/embed/qmjt5mH1fpo"
              title="YouTube video player"
              // @ts-ignore
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      ),
      secondSection: (
        <>
          <div className="mt-4 ml-4 p-2">
            <Trans i18nKey="dashboard:jtbd.organize-leads.description">
              <p className="text-lg font-medium">
                Chatbot and Automatic Responses{" "}
              </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                Manage the flood of chats that arrive at your business every
                day.
              </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                {" "}
                Create a Chatbot that will assist you in handling messages for
                your business. After watching this video, you will have the
                step-by-step process to create it. You will be able to add
                labels to your conversations and contacts, as well as
                automatically assign conversations to someone on your team.
              </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                Forget about the chaos of managing numerous messages, create
                default views to keep your inbox organized.
              </p>
            </Trans>
            <LinkTracked
              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
              to={buildRoute("flow-list")}
              posthogEvent="click flow create"
            >
              {t("dashboard:jtbd.organize-leads.button", "Create chatbot")}
              <ArrowSmRightIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </LinkTracked>
          </div>
        </>
      ),
      availableForStatus: [
        AccountStatus.NEW,
        AccountStatus.IN_SANDBOX,
        AccountStatus.ACTIVE,
      ],
    },
    {
      // i18n.t("dashboard:jtbd.broadcast.title", "Marketing - Manual Campaigns with Broadcasts")
      title: "dashboard:jtbd.broadcast.title",
      estimatedTimeMin: 5,
      icon: <NewspaperIcon className="h-5 w-5 text-white" aria-hidden="true" />,
      color: "yellow-400",
      firstSection: (
        <div className="p-2">
          <div className="mt-4 sm:mx-4 rounded-md overflow-clip ">
            <iframe
              className="w-full h-60"
              src="https://www.youtube.com/embed/FrtORkkjtK0"
              title="YouTube video player"
              // @ts-ignore
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      ),
      secondSection: (
        <>
          <div className="mt-4 ml-4 p-2">
            <Trans i18nKey="dashboard:jtbd.broadcast.description">
              <p className="text-lg font-medium">Mass Messaging </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                Start sending WhatsApp campaigns manually: send promotions to
                your list of clients without copying and pasting.
              </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                {" "}
                After watching this video, you will be able to send personalized
                promotions to hundreds or thousands of contacts.
              </p>
              <p className="mt-2 text-sm text-justify text-gray-500">
                Learn the step-by-step process to send a Broadcast, how your
                .csv document containing your contact list should look like to
                avoid errors in sending your campaign, and the analytics you can
                analyze after sending.
              </p>
            </Trans>
            <LinkTracked
              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
              to={buildRoute("broadcast-list")}
              posthogEvent="click contact tag create"
            >
              {t("dashboard:jtbd.broadcast.button", "Send my first campaign")}
              <ArrowSmRightIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </LinkTracked>
          </div>
        </>
      ),
      availableForStatus: [
        AccountStatus.NEW,
        AccountStatus.IN_SANDBOX,
        AccountStatus.ACTIVE,
      ],
    },
    {
      // i18n.t("dashboard:sandbox.title", "Test Hilos | Connect your sandbox")
      // title: "dashboard:sandbox.title",
      title: "Automatiza tu WhatsApp - Conecta tu número",
      variant: true,
      estimatedTimeMin: 20,
      icon: (
        <>
          <img src={logo} className="h-5 w-5" alt="Hilos logo" />
        </>
      ),
      color: "white",
      firstSection: (
        <div className="relative col-span-1 my-4 gap-10 rounded-md sm:col-span-6  md:flex">
          <div className="flex self-center">
            <div className="shrink-0 rounded-full bg-white bg-opacity-20 p-10 md:ml-5">
              <div className="inline-flex rounded-full bg-green-700 p-6 text-white ring-4 ring-white">
                <FontAwesomeIcon
                  // @ts-ignore
                  icon={faWhatsapp}
                  aria-hidden="true"
                  className=" h-12 w-12"
                />
              </div>
            </div>
          </div>
          <div className="mt-8 text-white w-full">
            <h3 className="text-xl font-bold">
              {t(
                "dashboard:connect-number",
                "Connect your number in 20 minutes"
              )}
            </h3>
            <div className="mt-2 space-y-2">
              <p>
                {t(
                  "dashboard:connect-number-subtitle",
                  "Connect to WhatsApp's API, and level up your WhatsApp by automating and organising your conversations."
                )}
              </p>
            </div>
            <div className="mt-8 w-full flex justify-end ">
              <Link
                to={buildRoute("config-channel-cloud-api-onboarding")}
                className="z-10 lg:mr-10 inline-flex items-center rounded-md border border-transparent bg-white px-4 py-3 text-sm font-semibold text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
              >
                {t("dashboard:activation.label", "Start now")}
                <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
              </Link>
            </div>
          </div>
          <span className="absolute -right-24 -bottom-24 z-0 h-96 w-96 rounded-full bg-white opacity-10 md:top-0"></span>
        </div>
      ),
      availableForStatus: [AccountStatus.NEW, AccountStatus.INACTIVE],
    },
  ];

  const actions = [
    {
      // i18n.t("dashboard:support.title", "Need some Help?")
      title: "dashboard:support.title",
      href: "https://api.whatsapp.com/send?phone=5215592257050&text=I%20need%20help%20with%20my%20Hilos%20Account.",
      icon: <SupportIcon className="h-6 w-6" aria-hidden="true" />,
      iconForeground: "text-hilos",
      iconBackground: "bg-hilos-light",
      text: (
        <>
          <p>
            {t(
              "dashboard:support.intro",
              "Anytime you need us, we're here to help."
            )}
          </p>
          <p>
            <Trans i18nKey="dashboard:support.text">
              Also feel free to take a look at our
              <AnchorTracked
                href="https://hilos.io/docs/user/getting-started/prerequisites"
                className="font-semibold text-hilos"
                posthogEvent="docs getting-started clicked"
              >
                docs
              </AnchorTracked>
              , there's guides and video tutorials to help you build what you
              need with Hilos.
            </Trans>
          </p>
        </>
      ),
      actionButton: (
        <>
          <AnchorTracked
            href="https://api.whatsapp.com/send?phone=5215592257050&text=Hi%2C%20I%20need%20help%20with%20my%20Hilos%20Account."
            rel="noreferrer"
            target="_blank"
            className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
            posthogEvent="schedule-call clicked"
          >
            {t("dashboard:support.button", "Schedule a call")}
            <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
          </AnchorTracked>
        </>
      ),
      avaliableForStatus: [AccountStatus.ACTIVE, AccountStatus.INACTIVE],
    },
    {
      title: t("dashboard:inbox.title", "Check out your Inbox"),
      href: "/inbox",
      icon: <InboxIcon className="h-6 w-6" aria-hidden="true" />,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      text: (
        <>
          <p>
            {t(
              "dashboard:inbox.intro",
              "Hilos comes with a full-featured inbox for your team."
            )}
          </p>
          <p>
            {t(
              "dashboard:inbox.text",
              "Assign team members to conversations, add tags and notes, and use the conversation status to keep track of which customers need attention."
            )}
          </p>
        </>
      ),
      avaliableForStatus: [AccountStatus.ACTIVE, AccountStatus.INACTIVE],
    },
    {
      // t("dashboard:flow.title", "Build a basic flow")
      title: "dashboard:flow.title",
      href: buildRoute("flow-list"),
      icon: <ShareIcon className="h-6 w-6 rotate-90" />,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      text: (
        <>
          <p>{t("dashboard:flow.title", "No code required")}</p>
          <p>
            {t(
              "dashboard:flow.text",
              "Automate your processes, asking questions and save the answers, applying conditional logic and send your customer's data where you need it."
            )}
          </p>
        </>
      ),
      avaliableForStatus: [AccountStatus.ACTIVE, AccountStatus.INACTIVE],
    },
    {
      // i18n.t("dashboard:team.title", "Invite your teammates")
      title: "dashboard:team.title",
      href: buildRoute("account-members"),
      // @ts-ignore
      icon: <FontAwesomeIcon icon={faUserFriends} className="h-6 w-6" />,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
      text: (
        <>
          <p>
            {t(
              "dashboard:team.intro",
              "Invite your team members so they can also build flows and use the Inbox."
            )}
          </p>
          <p>
            <span className="mr-1 inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
              {t("dashboard:soon", "Soon")}
            </span>
            {t(
              "dashboard:team.text",
              "Organise everyone into subteams like Operations, Sales, Support, etc."
            )}
          </p>
        </>
      ),
      avaliableForStatus: [AccountStatus.ACTIVE, AccountStatus.INACTIVE],
    },
  ];

  const getAvailableActionCards = useMemo((): onboardingCard[] | [] => {
    if (session) {
      return onboardingActionCards.filter((card) =>
        card.availableForStatus.includes(session?.account.status)
      );
    }
    return [];
  }, [session]);

  const DashboardContent = useCallback(
    (session, status) => {
      switch (status) {
        case AccountStatus.IN_SANDBOX:
          return (
            <SandboxDashboard
              session={session}
              QRCode={QRSandboxCode}
              urlWACode={urlWhatsAppCode}
            />
          );
        case AccountStatus.NEW:
          return (
            <NewDashboard
              session={session}
              QRCode={QRSandboxCode}
              urlWACode={urlWhatsAppCode}
            />
          );

        default:
          return (
            <>
              <div className="rounded-md bg-hilos-light p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <HeartIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-hilos"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-hilos">
                      {t(
                        "dashboard:referral-banner",
                        "Enjoying Hilos and think it's a good fit for someone you know? Refer them to get a $99 USD discount on your next invoice."
                      )}
                    </p>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                      <a
                        href="https://www.notion.so/hilos/Programa-de-Referidos-1e5425a261194773b8b44e450c1a6e87"
                        className="whitespace-nowrap font-medium text-hilos hover:text-hilos"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("learn-more", "Learn more")}
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-screen overflow-y-auto gap-4 overflow-x-clip lg:grid lg:grid-cols-5 bg-gray-50 px-4 pb-16 pt-4 sm:px-10 sm:pb-10 sm:pt-4">
                <div className="flex-col lg:col-span-3 lg:h-screen lg:no-scrollbar lg:overflow-y-scroll">
                  <div>
                    {session &&
                    session.account &&
                    session.account.status !== AccountStatus.ACTIVE ? (
                      <Trans i18nKey="dashboard:welcome-to-hilos">
                        <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-neutral-dark  sm:truncate sm:text-3xl">
                          Welcome to Hilos!
                        </h2>
                        <p className="mt-1 text-neutral">
                          Let's get your WhatsApp account connected to start
                          sending and automating messages.
                        </p>
                      </Trans>
                    ) : (
                      <Trans i18nKey="dashboard:welcome-to-hilos-active">
                        <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-neutral-dark  sm:truncate sm:text-3xl">
                          Welcome to hilos!
                        </h2>
                      </Trans>
                    )}
                  </div>
                  <div className="mt-4">
                    <MainDashboardCard
                      urlWhatsAppCode={urlWhatsAppCode}
                      QRSandboxCode={QRSandboxCode}
                      session={session}
                    />
                  </div>
                  <div className="gap-4">
                    <div className="pt-4 lg:hidden lg:pt-0">
                      <AnnouncementsSection session={session} />
                    </div>
                    <div className="mt-8">
                      <Trans i18nKey="dashboard:get-started">
                        <h2 className="mt-2 flex items-center text-xl font-bold leading-7 text-neutral-dark  sm:truncate">
                          Get started
                        </h2>
                        <p className="mt-1 text-sm text-neutral">
                          Check out all the things Hilos offers to you
                        </p>
                      </Trans>
                      <div className="mb-4">
                        {getAvailableActionCards.map(
                          (action: onboardingCard, index) => (
                            <DisclosureCard action={action} index={index} />
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 grid gap-3 overflow-hidden sm:grid-cols-6  lg:grid-cols-6">
                    {actions
                      .filter((action) =>
                        action.avaliableForStatus?.includes(
                          session?.account?.status
                        )
                      )
                      .map((action) => (
                        <ActionCard action={action} />
                      ))}
                  </div>
                </div>
                <div className="lg:col-span-2 lg:mt-4 hidden lg:block bg-white p-2 border lg:h-screen  rounded-md lg:no-scrollbar lg:overflow-y-scroll">
                  <AnnouncementsSection session={session} />
                </div>
              </div>
            </>
          );
      }
    },
    [session]
  );

  return (
    <>
      {!session ? (
        <div className="card">
          <div className="card-body text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        DashboardContent(session, session.account.status)
      )}
    </>
  );
}
