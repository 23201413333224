import { useState } from "react";
import { HTTPActionResponse } from "@hilos/types/inbox_action";
import HTTPResponseNav from "./HTTPResponseNav";
import HTTPResponseRenderRequest from "./HTTPResponseRenderRequest";
import HTTPResponseRenderResponse from "./HTTPResponseRenderResponse";

interface HTTPResponseProps {
  response: HTTPActionResponse;
}

export default function HTTPResponseRender({ response }: HTTPResponseProps) {
  const [currentStep, setCurrentStep] = useState("request");
  console.log(response);

  return (
    <>
      <HTTPResponseNav
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
      {currentStep === "request" && (
        <HTTPResponseRenderRequest request={response.request} />
      )}
      {currentStep === "response" && (
        <HTTPResponseRenderResponse response={response.response} />
      )}
    </>
  );
}
