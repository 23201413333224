import { Menu } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import usePopper from "src/hooks/usePopper";
import MenuDropdownOptions, { MenuOption } from "./MenuDropdownOptions";

interface MenuDropdownProps {
  options: MenuOption[];
}

function MenuDropdown({ options }: MenuDropdownProps) {
  const [trigger, container] = usePopper({
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
          ref={trigger}
        >
          <DotsVerticalIcon className="h-5 w-5" />
        </Menu.Button>
      </div>

      <MenuDropdownOptions container={container} options={options} />
    </Menu>
  );
}

export default MenuDropdown;
