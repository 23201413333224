import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ArrowSmRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { FieldArray, Formik } from "formik";
import { Channel, Role } from "@hilos/types/private-schema";
import ChannelBadge from "src/containers/config/channel/ChannelBadge";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { formatPhone } from "src/Helpers";
import * as meta from "./Meta";

interface UserV2FormProps {
  data: { available_channels: boolean[]; permissions: any };
  channels: Channel[];
  roles: Role[];
  formSubmit: (
    formData: any,
    setSubmitting: any,
    setBackendValidationErrors: any,
    setBackendError: any
  ) => void;
  success: boolean;
  submitted: boolean;
}

export default function UserV2Form({
  data,
  channels,
  roles,
  formSubmit,
  success,
  submitted,
}: UserV2FormProps) {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) =>
        formSubmit(
          values,
          setSubmitting,
          setBackendValidationErrors,
          setBackendError
        )
      }
      enableReinitialize={true}
      initialValues={data}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:users.basic-info">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Basic Info
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Here you can change all the basic information for this user.
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-4 bg-white px-4 py-5 sm:p-6">
                  <TextInputField
                    placeholder={t(meta.FIELDS.first_name.placeholder)}
                    label={t(meta.FIELDS.first_name.label)}
                    name={meta.FIELDS.first_name.key}
                    type="text"
                  />

                  <TextInputField
                    placeholder={t(meta.FIELDS.last_name.placeholder)}
                    label={t(meta.FIELDS.last_name.label)}
                    name={meta.FIELDS.last_name.key}
                    type="text"
                  />

                  <TextInputField
                    // @ts-ignore
                    label={t(meta.FIELDS.email.label)}
                    type="email"
                    name={meta.FIELDS.email.key}
                    readOnly
                    help={
                      <Trans i18nKey="settings:switch-email-accounts">
                        <div className="flex items-center">
                          <ExclamationCircleIcon className="h4 w-4 mr-1" />
                          <p>
                            Do you need to switch email accounts? Let us know at{" "}
                            <a
                              href="mailto:hey@hilos.io"
                              className="text-blue-500"
                            >
                              hey@hilos.io
                            </a>
                            .
                          </p>
                        </div>
                      </Trans>
                    }
                  />

                  <SelectorField
                    label={t(meta.FIELDS.role.label)}
                    help={t(meta.FIELDS.role.help)}
                    name={meta.FIELDS.role.key}
                    options={roles.map((role) => ({
                      label: role.name,
                      value: role.id,
                    }))}
                    isOptionDisabled={(option) =>
                      // @ts-ignore
                      option.label === "Admin" && data.role !== option.value
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 sm:mt-4">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <Trans i18nKey="settings:users.update.channels">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Channels
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Select the channels this user will have access to.
                    </p>
                  </Trans>
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-2 bg-white px-4 py-5 sm:p-6">
                    <FieldArray
                      name="available_channels"
                      render={(_) => (
                        <>
                          {formik.values.available_channels.map((_, idx) => (
                            <div key={idx}>
                              <SwitchField
                                name={`available_channels.${idx}`}
                                label={
                                  <div className="flex items-center space-x-2">
                                    <div className="text-sm text-gray-500">
                                      {channels[idx].channel_type ===
                                        "WHATSAPP" &&
                                        formatPhone(
                                          channels[idx].channel_id,
                                          true,
                                          true
                                        )}
                                    </div>
                                    <ChannelBadge channel={channels[idx]} />
                                  </div>
                                }
                              />
                            </div>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="text-right">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                btnClasses={`inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 ${
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                    ? "disabled:opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                }
                submittingText={t("updating")}
                successText={t("updated")}
                initialText={
                  <>
                    {t("update")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
