function BottomBarMessageIconButton(props: React.HTMLProps<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type="button"
      className="flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
    />
  );
}

export default BottomBarMessageIconButton;
