import { useCallback, useState } from "react";
import { Card, SegmentedControl } from "@radix-ui/themes";
import { Theme } from "@radix-ui/themes";
import { TableSearchParams } from "src/components/Table/Table";
import TablePagination from "src/components/Table/TablePagination";
import { hasItems } from "src/helpers/utils";
import useHilosTask from "src/hooks/useHilosTasks";

export default function HilosTaskList() {
  const { data, isLoading } = useHilosTask();
  const [value, setValue] = useState("today");
  const [count, setCount] = useState(0);
  const [searchParams, setSearchParams] = useState<TableSearchParams>({});
  const handleUpdateParams = useCallback((key, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [key]: value,
    }));
  }, []);

  const items = [{ id: 1 }, { id: 2 }, { id: 3 }];
  return (
    <Theme className="bg-gray-50 min-h-lvh">
      <div className="flex flex-col bg-gray-50 ">
        <div className="flex justify-center mt-2">
          <SegmentedControl.Root
            defaultValue="today"
            value={value}
            onValueChange={(value) => {
              if (value) setValue(value);
            }}
          >
            <SegmentedControl.Item value="today">Today</SegmentedControl.Item>
            <SegmentedControl.Item value="drafts">
              Coming up
            </SegmentedControl.Item>
            <SegmentedControl.Item value="no-due-date'">
              No due date
            </SegmentedControl.Item>
            <SegmentedControl.Item value="complete">
              Complete
            </SegmentedControl.Item>
          </SegmentedControl.Root>
        </div>
      </div>
      <div className="pt-2 bg-gray-50">
        <ul className="space-y-3">
          {hasItems(data) &&
            data &&
            data.map((item) => (
              <li
                key={item.id}
                className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6"
              >
                <div className="flex">
                  {/* <div className="mr-4 shrink-0 self-center">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 200 200"
                    preserveAspectRatio="none"
                    aria-hidden="true"
                    className="h-16 w-16 border border-gray-300 bg-white text-gray-300"
                  >
                    <path
                      d="M0 0l200 200M0 200L200 0"
                      strokeWidth={1}
                      vectorEffect="non-scaling-stroke"
                    />
                  </svg>
                </div> */}
                  <div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center align-middle">
                        <input
                          id={item.id}
                          name="comments"
                          type="checkbox"
                          aria-describedby={`description-${item.id}`}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(ev) => {
                            console.log("changed", ev.target.checked, item);
                          }}
                        />
                      </div>
                      <div className="flex-col ml-3">
                        <label htmlFor={item.id} className="font-medium ">
                          <h6 className="text-sm font-bold">{item.title}</h6>
                        </label>
                        <div className="text-sm/6">
                          <span
                            id={`description-${item.id}`}
                            className="text-gray-500"
                          >
                            {item.description}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        <TablePagination
          searchParams={searchParams}
          results={data}
          count={count}
          updateSearchParams={handleUpdateParams}
          dataName="Task"
        />
      </div>
    </Theme>
  );
}
