import { SVGProps } from "react";

function UnpinIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M 3.7070312 2.2929688 L 2.2929688 3.7070312 L 8.3730469 9.7871094 L 7.1835938 10.769531 L 5.7070312 9.2929688 L 4.2929688 10.707031 L 8.09375 14.507812 L 3 19.599609 L 3 21 L 4.4003906 21 L 9.4921875 15.90625 L 13.292969 19.707031 L 14.707031 18.292969 L 13.318359 16.904297 L 14.287109 15.701172 L 20.292969 21.707031 L 21.707031 20.292969 L 15.548828 14.134766 L 15.558594 14.123047 L 14.136719 12.701172 L 14.126953 12.712891 L 11.347656 9.9335938 L 11.357422 9.9238281 L 9.9355469 8.5019531 L 9.9257812 8.5117188 L 3.7070312 2.2929688 z M 14.707031 2.2929688 L 13.292969 3.7070312 L 14.40625 4.8203125 L 11.486328 7.2246094 L 12.908203 8.6464844 L 15.814453 6.2519531 L 17.761719 8.1972656 L 15.396484 11.134766 L 16.820312 12.558594 L 19.195312 9.609375 L 20.292969 10.707031 L 21.707031 9.2929688 L 14.707031 2.2929688 z M 9.7949219 11.208984 L 12.865234 14.279297 L 11.900391 15.478516 L 8.6074219 12.185547 L 9.7949219 11.208984 z"
      />
    </svg>
  );
}

export default UnpinIcon;
