import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import { axiosErr } from "@hilos/types/axios";
import {
  MessageBlastPublicEdit,
  MessageBlastRead,
} from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import BroadcastForm, {
  BroadcastFormExtraFormikFields,
  BroadcastFormSubmit,
} from "src/containers/broadcasts/BroadcastForm";
import HeaderNavigation from "src/components/HeaderNavigation";
import { WhatsAppTemplateProvider } from "src/hooks/WhatsAppTemplateProvider";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";

export default function BroadcastCreate() {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<WhatsAppTemplate>();

  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [isTutorialInProgress, setIsTutorialInProgress] = useState(false);
  const [initialValues, setInitialValues] = useState(
    {} as MessageBlastPublicEdit & BroadcastFormExtraFormikFields
  );
  const { t } = useTranslation();

  const cleanTimeout = useCleanTimeout();

  const formSubmit: BroadcastFormSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      const response = await axios.post<MessageBlastRead>(
        buildAPIRoute(API_ROUTES.MESSAGE_BLAST_PUBLIC_CREATE_LIST),
        formData
      );
      posthog?.capture("broadcast created", {
        broadcast_name: response.data.name,
        broadcast_id: response.data.id,
        created_on: response.data.created_on,
        template_id: formData.whatsapp_template,
      });
      setSubmitting(false);
      setSuccess(true);
      cleanTimeout.current = setTimeout(() => {
        navigate(buildRoute("broadcast-detail", { ":id": response.data.id }));
      }, 1000 * 2);
    } catch (error) {
      posthog?.capture("broadcast_creation failed", {
        recipients_sample: formData.recipients.slice(0, 10),
        template_id: formData.whatsapp_template,
        error: error,
      });
      const err = error as axiosErr;
      setSuccess(false);
      console.error(err);
      console.error(err?.response);
      console.error(err?.request);
      if (err?.response?.status === 400) {
        setBackendValidationErrors(err.response.data);
      } else {
        t(
          "broadcasts:backend-error",
          "An error occurred while creating your message blast. Please try again."
        );
      }
      cleanTimeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-slate-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("broadcasts"),
              url: buildRoute("broadcast-list"),
            },
            {
              name: t("broadcasts:new-broadcast", "New broadcast"),
              url: buildRoute("broadcast-create"),
            },
          ]}
        />
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-4xl">
          <div data-tour="broadcast-form">
            <div>
              <WhatsAppTemplateProvider>
                <BroadcastForm
                  isTutorialInProgress={isTutorialInProgress}
                  broadcast={initialValues}
                  formSubmit={formSubmit}
                  success={success}
                  submitted={submitted}
                  setSelectedTemplate={setSelectedTemplate}
                  selectedTemplate={selectedTemplate}
                  requiredFields={requiredFields}
                  setRequiredFields={setRequiredFields}
                />
              </WhatsAppTemplateProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
