import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { FilterIcon } from "@heroicons/react/outline";
import Button from "src/components/Button";
import { CalculateQueryParamsParams } from "./FiltersMeta";
import ReportFilters from "./ReportFilters";

interface ReportFiltersModalProps {
  params: CalculateQueryParamsParams;
  setParams: (p: CalculateQueryParamsParams) => void;
}

export default function ReportFiltersModal({
  params,
  setParams,
}: ReportFiltersModalProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  // Date and working hours are always set
  let numParamsSet = 2;
  if (params.emails.length > 0) numParamsSet += 1;
  if (params.contactTags.length > 0) numParamsSet += 1;
  if (params.conversationTags.length > 0) numParamsSet += 1;

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={() => setShow(false)}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-full w-full">
                      <ReportFilters
                        params={params}
                        setParams={setParams}
                        setShow={setShow}
                      />
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Button
        type="button"
        onClick={(_) => setShow(true)}
        icon={<FilterIcon className="ml-1 -mr-1 h-5 w-5" />}
      >
        {t("reports:filter", "Filter")} ({numParamsSet}{" "}
        {t("reports:set", "set")})
      </Button>
    </>
  );
}
