import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import useHilosStore from "src/hooks/useHilosStore";
import FreeTrialDaysRemainingNotice from "./FreeTrialDaysRemainingNotice";
import SubscriptionIncompleteNotice from "./SubscriptionIncompleteNotice";
import SubscriptionPaymentFailedNotice from "./SubscriptionPaymentFailedNotice";

export default function NoticeManager() {
  const { pathname } = useLocation();
  const { session } = useHilosStore();

  const NOTICES = useMemo(() => {
    return [
      // Put here all the notices we want to show a user. Notices that appear first
      // for the same path will be shown, the rest will be shown only if `activate`
      // returns true.
      {
        paths: ["*"],
        component: <SubscriptionIncompleteNotice />,
        activate: () => {
          return (
            session &&
            session.account &&
            ["incomplete", "canceled"].includes(
              session.account.current_subscription?.status || ""
            )
          );
        },
      },
      {
        paths: ["*"],
        component: <SubscriptionPaymentFailedNotice />,
        activate: () => {
          return (
            session &&
            session.account &&
            ["past_due", "unpaid"].includes(
              session.account.current_subscription?.status || ""
            )
          );
        },
      },
      {
        paths: ["*"],
        component: <FreeTrialDaysRemainingNotice />,
        activate: () => {
          return (
            session &&
            !session.account.current_subscription &&
            session.account.remaining_free_trial_days < 4
          );
        },
      },
      // Commenting these notices because we need to fix Inbox CSS issues first,
      // otherwise these notices push the entire interface down and people can't
      // use it.

      // {
      //   paths: [
      //     buildRoute("config-account"),
      //     buildRoute("contact-list"),
      //     buildRoute("flow-list"),
      //     "/inbox",
      //     buildRoute("message-blast-list"),
      //     buildRoute("whatsapp-template-list"),
      //   ],
      //   component: <AccountStatusNotice />,
      //   activate: () => {
      //     return true;
      //   },
      // },
    ];
  }, []);

  let Component: React.ReactElement | null = null;

  NOTICES.forEach((notice) => {
    if (!Component) {
      notice.paths.forEach((path) => {
        if (path === "*" || matchPath(path, pathname)) {
          if (notice.activate()) {
            Component = notice.component;
            return;
          }
        }
      });
    }
  });

  return <>{Component}</>;
}
