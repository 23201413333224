import { Trans, useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useStripePaymentMethods } from "src/hooks/useStripe";
import amexLogo from "../../../assets/img/payment-methods/amex.svg";
import mastercardLogo from "../../../assets/img/payment-methods/mastercard.svg";
import visaLogo from "../../../assets/img/payment-methods/visa.svg";
import GoToStripeCustomerPortal from "./GoToStripeCustomerPortal";

export default function PaymentMethodList() {
  const { t } = useTranslation();
  const { payment_methods } = useStripePaymentMethods();

  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <Trans i18nKey="settings:billing.payment-methods.description">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Your payment methods
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              You can change them anytime, they're safely stored in Stripe.
            </p>
            <p className="mt-1 text-sm text-gray-600">
              We don't store any credit card information on our servers.
            </p>
          </Trans>
        </div>
      </div>
      <div className="mt-5 md:col-span-2 md:mt-0">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-4 bg-white px-4 py-5 sm:p-6">
            <div className="-mx-4 rounded-lg border border-gray-100 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <tbody>
                  {payment_methods.map((pm, idx) => (
                    <tr key={pm.stripe_id} className="border-b border-gray-100">
                      {pm.payment_method_type === "card" ? (
                        <>
                          <td className="flex items-center px-2 py-1.5 pl-4 text-sm text-gray-500">
                            {pm.card_brand === "visa" && (
                              <img
                                src={visaLogo}
                                className="mr-2 h-10 w-10 self-center"
                                alt={`${pm.card_brand} logo`}
                              />
                            )}
                            {pm.card_brand === "mastercard" && (
                              <img
                                src={mastercardLogo}
                                className="mr-2 h-10 w-10 self-center"
                                alt={`${pm.card_brand} logo`}
                              />
                            )}
                            {pm.card_brand === "amex" && (
                              <img
                                src={amexLogo}
                                className="mr-2 h-10 w-10 self-center"
                                alt={`${pm.card_brand} logo`}
                              />
                            )}
                            <span className="capitalize">
                              {pm.card_funding}
                            </span>
                          </td>
                          <td className="px-2 py-1.5 text-sm font-medium text-gray-900">
                            <div className="flex items-center space-x-2">
                              <span>**** - {pm.card_last4}</span>
                              {pm.is_default && (
                                <span className="inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
                                  Default
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="px-2 py-1.5 text-sm text-gray-500">
                            Expires on {pm.card_exp_month}/
                            {format(
                              new Date(parseInt(pm.card_exp_year), 1, 1),
                              "yy"
                            )}
                          </td>
                          <td className="px-2 py-1.5 text-sm text-gray-500">
                            {t("added-on", "Added on")}{" "}
                            {format(
                              new Date(pm.created_on as string),
                              "d/MMM/yy"
                            )}
                          </td>
                        </>
                      ) : (
                        <td className="px-2 py-1.5 text-sm text-gray-500">
                          {pm.payment_method_type}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <GoToStripeCustomerPortal
              buttonText={t(
                "settings:billing.payment-methods.add-method",
                "Add payment method"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
