import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { UserIcon } from "@heroicons/react/outline";
import axios from "axios";
import HeaderNavigation from "../../components/HeaderNavigation";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import ContactForm from "./ContactForm";
import * as meta from "./ContactMeta";

export default function ContactCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const formSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.post(buildAPIRoute(API_ROUTES.CONTACT_CREATE_LIST), formData);
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        navigate(buildRoute("contact-list"));
      }, 1000 * 2);
    } catch (err) {
      setSuccess(false);
      if (err.response.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          t(
            "contacts:error-general",
            "An error occurred while creating your contact. Please try again."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("contacts", "Contacts"),
              url: buildRoute("contact-list"),
            },
            {
              name: t("contacts:new-contact", "New contact"),
              url: buildRoute("contact-create"),
            },
          ]}
        />

        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <UserIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("contacts:new-contact", "New contact")}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl">
          <ContactForm
            contact={meta.getInitialValues()}
            formSubmit={formSubmit}
            success={success}
            submitted={submitted}
            action="create"
          ></ContactForm>
        </div>
      </div>
    </div>
  );
}
