import { getTranslationPayload as t } from "src/i18n";

export const FIELDS = {
  sync_hubspot_owners: {
    key: "sync_hubspot_owners",
    label: t(
      "integrations:hubspot.sync-hubspot-owners.label",
      "Sync HubSpot owners to Hilos contact default assignees"
    ),
    help: t(
      "integrations:hubspot.sync-hubspot-owners.help",
      "We use the hubspot_owner_id field to determine who to assign in Hilos. All HS owners must also be Hilos users to assign them."
    ),
  },
  sync_inbound_contacts: {
    key: "sync_inbound_contacts",
    label: t(
      "integrations:hubspot.sync-inbound-contacts.label",
      "Sync inbound created contacts to HubSpot"
    ),
    help: t(
      "integrations:hubspot.sync-inbound-contacts.help",
      "When a unregistered phone number sends a message a contact is created in Hilos, with this option enabled it will be pushed to HubSpot."
    ),
  },
};

export const ALL_SCOPES = [
  "timeline",
  "oauth",
  "tickets",
  "e-commerce",
  "crm.objects.contacts.read",
  "crm.objects.contacts.write",
  "crm.objects.owners.read",
  "crm.objects.deals.read",
  "crm.schemas.deals.read",
  "crm.objects.deals.write",
  "crm.schemas.contacts.read",
];
