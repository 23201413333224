import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ConversationsSummaryInPeriodData } from "@hilos/types/analytics";
import Loading from "src/components/Loading";
import LocalTable, {
  LocalTableCellFormat,
  LocalTableHeaderFormat,
} from "src/components/LocalTable";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";
import { useInboxUsageSummaryAnalytics } from "src/hooks/useInboxUsageSummaryAnalytics";
import { API_ROUTES } from "src/router/router";
import InboxUsageDownloadCSVButton from "../InboxUsageDownloadCSVButton";

export type PeriodTypeType = "WEEK" | "DAY" | "MONTH";

interface InboxSummaryTableProps {
  params: string;
  period_type_type: PeriodTypeType;
  tableTitle: string;
  dateColumnTitle: string;
}

export default function InboxSummaryTable({
  params,
  period_type_type,
  tableTitle,
  dateColumnTitle,
}: InboxSummaryTableProps) {
  const { t } = useTranslation();
  const { formatWithLocale } = useDateFnsFormatLocale();

  const { inboxUsageSummary } = useInboxUsageSummaryAnalytics(
    params + `&period_type_type=${period_type_type}`
  );

  const columnsTable = useMemo(() => {
    const columnHelper = createColumnHelper<ConversationsSummaryInPeriodData>();
    const defaultColumns = [
      columnHelper.accessor(
        (row) => {
          const week_of = row?.period_date;
          if (week_of) {
            // Got to autocomplete date information so the browser doesn't
            // assume it's UTC and show it incorrectly
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
            const weekOfWithTime = week_of + "T00:00:00";
            return formatWithLocale(new Date(weekOfWithTime), "LLL d, uuuu");
          }
        },
        {
          id: "period_date",
          cell: (info) => (
            <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
          ),
          header: () => (
            <>
              <LocalTableHeaderFormat>{dateColumnTitle}</LocalTableHeaderFormat>
            </>
          ),
        }
      ),
      columnHelper.accessor((row) => row?.received_chats, {
        id: "received_chats",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:received-chats", "Received chats")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.distinct_contacts, {
        id: "distinct_contacts",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:distinct-contacts", "Distinct contacts")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_duration, {
        id: "average_duration",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:table.average-duration",
                "Average duration (HH:MM:SS)"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_first_response, {
        id: "average_first_response",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:table.average-first-response",
                "Average first response (HH:MM:SS)"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.average_satisfaction_rating, {
        id: "average_satisfaction_rating",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:table.average-rating", "Average rating")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
    ];
    return defaultColumns;
  }, [formatWithLocale, t, dateColumnTitle]);

  if (!inboxUsageSummary || !inboxUsageSummary.period) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex flex-row-reverse">
        <InboxUsageDownloadCSVButton
          url={API_ROUTES.ANALYTICS_INBOX_USAGE_SUMMARY}
          params={params + `&period_type_type=${period_type_type}`}
          report_type="Inbox Summary"
        />
      </div>
      <LocalTable<ConversationsSummaryInPeriodData>
        tableTitle={<>{tableTitle}</>}
        columns={
          columnsTable as unknown as ColumnDef<ConversationsSummaryInPeriodData>[]
        }
        data={inboxUsageSummary.period.conversations_summary_in_period}
        columnVisibility={{
          should_be_included: false,
        }}
        enableColumnSorting={true}
        disableSearch={true}
      />
    </div>
  );
}
