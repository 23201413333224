import { useTranslation } from "react-i18next";
import { Dialog } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import useHilosStore from "src/hooks/useHilosStore";

export default function TechProviderOnboardingError({ error, handleClose }) {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  return (
    <>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <XCircleIcon aria-hidden="true" className="text-red-600 h-6 w-6" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {t(
              "channel:channel-connection-error.title",
              "Error while connecting your number:"
            )}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{error}</p>
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-500">
              {t(
                "channel:channel-connection-error.description",
                "Our team has been notified and will reach out to {{email}} to help you connect your number.",
                { email: session?.email }
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleClose}
        >
          {t("channel:channel-connection-error.button", "Close and try again")}
        </button>
      </div>
    </>
  );
}
