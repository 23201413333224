// @flow
import * as yup from "yup";
import { getTranslationPayload as t } from "src/i18n";
import { ERROR_MESSAGES } from "../../constants/Form";

export const getInitialValues = () => {
  return {
    first_name: "",
    last_name: "",
    email: "",
    notify_when_msg_comes_in_after_hours: false,
  };
};

export const schema = yup.object().shape({
  first_name: yup.string().required(ERROR_MESSAGES.required),
  last_name: yup.string().required(ERROR_MESSAGES.required),
  email: yup
    .string()
    .email(ERROR_MESSAGES.email)
    .required(ERROR_MESSAGES.required),
  notify_when_msg_comes_in_after_hours: yup.boolean(),
  default_language: yup.string().required(ERROR_MESSAGES.required).nullable(),
});

export const FIELDS = {
  first_name: {
    key: "first_name",
    label: t("settings:users.first-name.label", "First Name"),
    placeholder: t("settings:users.first-name.placeholder", "Juan"),
  },
  last_name: {
    key: "last_name",
    label: t("settings:users.last-name.label", "Last Name"),
    placeholder: t("settings:users.last-name.placeholder", "Solo"),
  },
  email: {
    key: "email",
    label: t("settings:users.email.label"),
  },
  default_language: {
    key: "default_language",
    label: t(
      "settings:users.default-language.label",
      "Your preferred language"
    ),
  },
  notify_when_msg_comes_in_after_hours: {
    key: "notify_when_msg_comes_in_after_hours",
    label: t(
      "settings:users.notify-after-hours.label",
      "Notify me when a message comes in after working hours?"
    ),
    help: t(
      "settings:users.notify-after-hours.help",
      "We'll send you an email with a link to the conversation."
    ),
  },
};
