import { useMemo } from "react";
import { gql, useSubscription } from "@apollo/client";
import { InboxIcon } from "@heroicons/react/outline";
import { usePostHog } from "posthog-js/react";
import { isFeatureEnabled } from "src/helpers/utils";
import useHilosStore from "src/hooks/useHilosStore";

const INBOX_CONTACTS_UNREAD_SUBSCRIPTION = gql`
  subscription OnInboxContactsUnread {
    api_inboxcontact(
      where: {
        _or: [{ is_unread: { _eq: true } }, { unseen_messages: { _neq: 0 } }]
      }
      limit: 1
    ) {
      id
    }
  }
`;

function InboxIconWithIndicator() {
  const posthog = usePostHog();
  const { session } = useHilosStore();

  const allowUnseenConversationsIndicator = useMemo(
    () =>
      isFeatureEnabled({
        posthog,
        session,
        key: "inbox_unseen_conversations_indicator",
      }),
    [posthog, session]
  );

  const { data } = useSubscription(INBOX_CONTACTS_UNREAD_SUBSCRIPTION, {
    skip: !allowUnseenConversationsIndicator,
  });

  const hasUnseenConversations = useMemo(
    () => data?.api_inboxcontact?.length > 0,
    [data]
  );

  return (
    <div className="relative mr-2 h-5 w-5">
      {hasUnseenConversations && (
        <div className="absolute mr-0 flex h-2 w-2 self-end">
          <span className="flex h-2 w-2">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-hilos opacity-75" />
            <span className="relative inline-flex h-2 w-2 rounded-full bg-hilos" />
          </span>
        </div>
      )}
      <InboxIcon className="h-5 w-5" aria-hidden="true" />
    </div>
  );
}

export default InboxIconWithIndicator;
