import { Trans, useTranslation } from "react-i18next";
import { TRIGGER_TYPES } from "../builder/constants/triggers";

export default function InboundAnyMessageTriggerDetail({ flow }) {
  const { t } = useTranslation();
  const triggerData = TRIGGER_TYPES[flow.trigger_type];

  return (
    <>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("trigger", "Trigger")}
      </h3>
      <div className="flex items-center space-x-1">
        <div>
          <triggerData.icon className="h-8 w-8 text-gray-800" />
        </div>
        <div className="font-medium text-gray-900">
          <p className="text-xl">{t(triggerData.name)}</p>
        </div>
      </div>
      <div className="text-sm text-gray-700 mt-2">
        <div>
          <Trans i18nKey="flow-executions:any-message-help">
            <div className="ml-2 space-y-2">
              <p className="text-sm">
                This flow will trigger on any message received, as long as:
              </p>
              <ul className="ml-4 list-disc text-sm">
                <li>
                  There are no flows configured to run with the specific text
                  received and
                </li>
                <li>
                  The conversation is <strong>CLOSED</strong>.
                </li>
              </ul>
              <p className="text-sm">
                Everytime a conversation finishes, mark it as{" "}
                <strong>CLOSED</strong> on the Inbox to make sure this flow will
                trigger if the contact writes again.
              </p>
            </div>
          </Trans>
        </div>
      </div>
    </>
  );
}
