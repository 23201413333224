import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ClipboardListIcon, XIcon } from "@heroicons/react/outline";
import QuickReplyTemplateSend from "./QuickReplyTemplateSend";

function QuickReplyTemplateSendModal({
  contact,
  setOpen,
  open,
  setSelectedTemplate,
  selectedTemplate,
  loading,
  onCloseQKModal,
  variables,
  onCancel,
}) {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation();

  if (!selectedTemplate) {
    return <></>;
  }

  const onClose = () => {
    setOpen(false);
    onCloseQKModal();
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
                <div>
                  <div className="absolute top-0 right-0 block pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onCancel}
                      ref={cancelButtonRef}
                    >
                      <span className="sr-only">{t("close", "Close")}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mb-4 items-center sm:flex">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ClipboardListIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="ml-4 self-center text-lg font-medium text-gray-900"
                    >
                      <p className="text-base font-normal text-gray-400">
                        {t("template-name", "Template name")}
                      </p>
                      {selectedTemplate.name}
                    </Dialog.Title>
                    <div className="my-4"></div>
                  </div>
                </div>
                <QuickReplyTemplateSend
                  contact={contact}
                  setSelectedTemplate={setSelectedTemplate}
                  selectedTemplate={selectedTemplate}
                  loading={loading}
                  onCloseModal={onClose}
                  onCancel={onCancel}
                  variables={variables}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default QuickReplyTemplateSendModal;
