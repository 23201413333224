import { SVGProps } from "react";

function RobotIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.2732 9.67252C18.1049 9.26585 17.744 9.16835 17.4999 9.14502V6.66669C17.4999 5.74752 16.7524 5.00002 15.8332 5.00002H10.8332V3.84169C11.0874 3.61335 11.2499 3.28502 11.2499 2.91669C11.2499 2.58517 11.1182 2.26722 10.8838 2.0328C10.6493 1.79838 10.3314 1.66669 9.99987 1.66669C9.66835 1.66669 9.35041 1.79838 9.11599 2.0328C8.88157 2.26722 8.74987 2.58517 8.74987 2.91669C8.74987 3.28502 8.91237 3.61335 9.16654 3.84169V5.00002H4.16654C3.24737 5.00002 2.49987 5.74752 2.49987 6.66669V9.16419L2.43154 9.16919C2.22151 9.18435 2.02502 9.27845 1.88155 9.43259C1.73809 9.58672 1.65829 9.78945 1.6582 10V11.6667C1.6582 11.8877 1.746 12.0997 1.90228 12.2559C2.05856 12.4122 2.27052 12.5 2.49154 12.5H2.49987V16.6667C2.49987 17.5859 3.24737 18.3334 4.16654 18.3334H15.8332C16.7524 18.3334 17.4999 17.5859 17.4999 16.6667V12.5C17.7209 12.5 17.9328 12.4122 18.0891 12.2559C18.2454 12.0997 18.3332 11.8877 18.3332 11.6667V10.0517C18.3428 9.92236 18.3223 9.79257 18.2732 9.67252ZM4.16654 16.6667V6.66669H15.8332L15.834 9.99669L15.8332 10V11.6667L15.834 11.6709L15.8349 16.6667H4.16654Z"
        fill="currentColor"
      />
      <path
        d="M7.08398 11.6666C7.77434 11.6666 8.33398 10.9205 8.33398 9.99998C8.33398 9.07951 7.77434 8.33331 7.08398 8.33331C6.39363 8.33331 5.83398 9.07951 5.83398 9.99998C5.83398 10.9205 6.39363 11.6666 7.08398 11.6666Z"
        fill="currentColor"
      />
      <path
        d="M12.916 11.6666C13.6064 11.6666 14.166 10.9205 14.166 9.99998C14.166 9.07951 13.6064 8.33331 12.916 8.33331C12.2257 8.33331 11.666 9.07951 11.666 9.99998C11.666 10.9205 12.2257 11.6666 12.916 11.6666Z"
        fill="currentColor"
      />
      <path
        d="M6.66602 13.3333H13.3327V15H6.66602V13.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default RobotIcon;
