import ReactFlow, { Background, Controls, MiniMap, Node } from "reactflow";
import "reactflow/dist/style.css";
import { FlowNodeData } from "@hilos/types/flow";
import { FlowVersionDetailRead } from "@hilos/types/private-schema";
import edgeTypes from "./EdgeTypes/viewer";
import FlowViewerFilters from "./FlowViewerFilters";
import FlowViewerStepDetail from "./FlowViewerStepDetail";
import nodeTypes from "./NodeTypes/viewer";
import useFlowLayout from "./hooks/useFlowLayout";
import useFlowViewer from "./hooks/useFlowViewer";

interface FlowViewerProps {
  flowVersion: FlowVersionDetailRead;
  flowExecutionId?: string;
  flowExecutionContactId?: string;
}

const getNodeColor = (node: Node<FlowNodeData>) => {
  if (node.selected) {
    return "#93c5fd";
  }
  return "#e2e2e2";
};

function FlowViewer({
  flowVersion,
  flowExecutionId,
  flowExecutionContactId,
}: FlowViewerProps) {
  const {
    nodes,
    edges,
    selectedItem,
    onSelectItem,
    onClearSelectItem,
    onNodesChange,
    onEdgesChange,
    onUpdateTransitions,
  } = useFlowLayout();
  const { status, setStatus, stepsRef, onNodeClick } = useFlowViewer({
    selectedItem,
    flowVersion,
    flowExecutionId,
    flowExecutionContactId,
    onSelectItem,
    onUpdateTransitions,
  });

  return (
    <ReactFlow
      fitView
      fitViewOptions={{
        padding: 2,
      }}
      proOptions={{
        account: "paid-pro",
        hideAttribution: true,
      }}
      minZoom={0.2}
      autoPanOnNodeDrag={false}
      multiSelectionKeyCode={null}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodes={nodes}
      edges={edges}
      onNodeClick={onNodeClick}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
    >
      <Controls
        showInteractive={false}
        position="bottom-left"
        className="ml-56 bg-white opacity-40 hover:opacity-100"
      />
      <MiniMap
        nodeColor={getNodeColor}
        nodeStrokeWidth={3}
        zoomable
        pannable
        position="bottom-left"
        style={{ boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.08)" }}
      />
      <FlowViewerStepDetail
        stepsRef={stepsRef}
        selectedItem={selectedItem}
        flowExecutionId={flowExecutionId}
        flowExecutionContactId={flowExecutionContactId}
        onClearSelectItem={onClearSelectItem}
      />
      <FlowViewerFilters status={status} setStatus={setStatus} />
      <Background color="#4b5563" className="bg-gray-100" gap={16} />
    </ReactFlow>
  );
}

export default FlowViewer;
