import { useCallback, useMemo } from "react";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { InboxContactRead } from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";

interface ConversationContentListScrollToProps {
  startOfList: boolean | null;
  inboxContact: InboxContactRead;
  hasFirstPageLoaded: boolean;
  onScrollToStartOfList: () => void;
  onCloseConversationSearch: () => void;
}

function ConversationContentListScrollTo({
  startOfList,
  inboxContact,
  hasFirstPageLoaded,
  onScrollToStartOfList,
  onCloseConversationSearch,
}: ConversationContentListScrollToProps) {
  const handleScrollTo = useCallback(() => {
    onCloseConversationSearch();
    if (hasFirstPageLoaded) {
      onScrollToStartOfList();
    } else {
      queryClient.resetQueries(["conversation_content", inboxContact.id]);
    }
  }, [
    inboxContact,
    hasFirstPageLoaded,
    onCloseConversationSearch,
    onScrollToStartOfList,
  ]);

  const unseenMessagesCount = useMemo(
    () => (inboxContact.is_unread && inboxContact.unseen_messages) || 0,
    [inboxContact]
  );

  return (
    <div className="relative">
      <div className="absolute right-10 bottom-10 z-10 pb-3">
        <div className="fixed">
          <Transition
            show={startOfList === false || !hasFirstPageLoaded}
            enter="transition duration-300"
            enterFrom="opacity-50 scale-0"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-0"
          >
            <button
              onClick={handleScrollTo}
              className="flex justify-center z-10"
            >
              {unseenMessagesCount > 0 && (
                <div className="absolute -mt-1.5">
                  <div className="flex max-w-fit h-4 rounded-full bg-indigo-500 items-center justify-center px-2">
                    <span className="text-center text-white text-xs font-bold">
                      {unseenMessagesCount}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex w-10 h-10 rounded-full bg-gray-200 shadow-lg justify-center items-center">
                <ChevronDownIcon className="h-6 text-gray-700" />
              </div>
            </button>
          </Transition>
        </div>
      </div>
    </div>
  );
}

export default ConversationContentListScrollTo;
