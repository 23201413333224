import { HTMLAttributes } from "react";

function TextIcon(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="h-5 w-5" aria-hidden="true" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="4 7 4 4 20 4 20 7"></polyline>
        <line x1="9" y1="20" x2="15" y2="20"></line>
        <line x1="12" y1="4" x2="12" y2="20"></line>
      </svg>
    </div>
  );
}

export default TextIcon;
