import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { CursorPageData } from "@hilos/types/hilos";
import { WABAFlowList } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWhatsAppFlows(channel: number | null) {
  const fetchWhatsAppFlows = useCallback(async () => {
    const { data } = await axios.get<CursorPageData<WABAFlowList>>(
      buildAPIRoute(API_ROUTES.WHATSAPP_FLOW_LIST, { ":id": channel }),
      {
        params: {
          page_size: 1000,
        },
      }
    );
    return data;
  }, [channel]);

  const { data: waFlows = { results: [] } } = useQuery(
    ["whatsapp_flow_list", channel],
    fetchWhatsAppFlows
  );

  return {
    waFlows,
  };
}

export default useWhatsAppFlows;
