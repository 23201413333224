import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import { ParseResult } from "papaparse";
import {
  ContactTag,
  MessageBlastPublicEdit,
  MessageBlastPublicRecipientLenient,
} from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import BroadcastFormStepContacts from "src/containers/broadcasts/BroadcastFormStepContacts";
import BroadcastFormStepDetails from "src/containers/broadcasts/BroadcastFormStepDetails";
import BroadcastFormStepNav from "src/containers/broadcasts/BroadcastFormStepNav";
import BroadcastFormStepReview from "src/containers/broadcasts/BroadcastFormStepReview";
import APIErrors from "src/components/base/APIErrors";
import useHilosStore from "src/hooks/useHilosStore";
import { ERROR_MESSAGES } from "src/constants/Form";
import { AccountStatus } from "src/types/account";
import * as meta from "./BroadcastMeta";

export interface CsvFile {
  source?: File;
  preview?: string;
}

export type BroadcastFormSteps = "broadcast-details" | "contacts" | "review";

export type BroadcastFormSubmit = (
  formData: MessageBlastPublicEdit,
  setSubmitting: (value: boolean) => void,
  setBackendValidationErrors: (value: Record<string, unknown>) => void,
  setBackendError: (value: string) => void
) => void;

export interface BroadcastFormExtraFormikFields {
  csv_file_parsed_contents: ParseResult<string[]>;
  columns: (string | undefined)[];
  fields: string[];
  recipients_file_selected: string;
  media_help_upload_url?: string;
}

export function BroadcastTransformColumnMappingsToRecipient(
  columns: (string | undefined)[],
  rowValues: string[]
) {
  /**
   * `columns` is an array of strings with the following format
   * `["phone", "data.0", "data.1", "data.2", ...]`
   * This variable is the transformed data to
   * ```[
   *  ["phone", undefined, 0],
   *  ["data", "0", 1],
   *  ["data", "1", 2],
   *  ["data", "2", 3],
   * ]```
   *
   * And is used to transform the CSV row to the Recipent object
   */
  const variableMappings = columns
    .filter((variable) => variable !== "do-not-import")
    .map((variable, columnIndex) => {
      if (variable) {
        const [source, index] = variable.split(".", 2);
        return [source, index, columnIndex];
      }
      return [undefined, undefined, columnIndex];
    });
  const recipient: MessageBlastPublicRecipientLenient = {
    phone: "",
    data: [],
  };

  variableMappings.forEach(([source, index, columnIndex]) => {
    if (!source || columnIndex == null) {
      return;
    }
    if (!index) {
      // We store the phone as `"phone"` so the index will be undefined
      // in those cases just use the source to map the object
      recipient[source] = rowValues[columnIndex];
    } else {
      recipient[source][index] = rowValues[columnIndex];
    }
  });
  return recipient;
}

interface BroadcastFormProps {
  broadcast: MessageBlastPublicEdit & BroadcastFormExtraFormikFields;
  formSubmit: BroadcastFormSubmit;
  setSelectedTemplate: (template: WhatsAppTemplate) => void;
  selectedTemplate?: WhatsAppTemplate;
  success: boolean;
  submitted: boolean;
  requiredFields: string[];
  setRequiredFields: (fields: string[]) => void;
  isTutorialInProgress: boolean;
}

export default function BroadcastForm({
  broadcast,
  formSubmit,
  success,
  submitted,
  setSelectedTemplate,
  requiredFields,
  setRequiredFields,
  isTutorialInProgress,
  selectedTemplate,
}: BroadcastFormProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [backendError, setBackendError] = useState("");
  const [currentStep, setCurrentStep] =
    useState<BroadcastFormSteps>("broadcast-details");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [file, setFile] = useState<CsvFile>({
    source: undefined,
    preview: undefined,
  });

  const validate = (
    values: MessageBlastPublicEdit & Partial<BroadcastFormExtraFormikFields>
  ) => {
    if (!values.whatsapp_template) {
      return { [meta.FIELDS.whatsapp_template.key]: ERROR_MESSAGES.required };
    }
    if (!values.name) {
      return { [meta.FIELDS.name.key]: ERROR_MESSAGES.required };
    }
    const csvColsQuantity =
      values.csv_file_parsed_contents?.data[0].length ?? 0;
    if ((values.columns?.length ?? 0) > csvColsQuantity) {
      return {
        [meta.FIELDS.csv_file_parsed_contents.key]:
          // i18n.t("broadcasts:need-more-cols", "Your CSV file doesn't have the minimum required columns to match all the required template variables.")
          "broadcasts:need-more-cols",
      };
    }
  };

  const onSubmit = (
    values: MessageBlastPublicEdit & BroadcastFormExtraFormikFields,
    setSubmitting
  ) => {
    const broadcastData: MessageBlastPublicEdit &
      Partial<BroadcastFormExtraFormikFields> = { ...values };
    // In formik we handle the contact tags as a string[] but when submitting
    // to the backend we need to transform it to an array of ContactTags
    const contactTags = broadcastData.contact_tags as unknown as string[];

    broadcastData.recipients = values.csv_file_parsed_contents.data
      .filter((row) => row[0])
      .map((row) =>
        BroadcastTransformColumnMappingsToRecipient(values.columns, row)
      );

    // Remove the data that the endpoint doesn't need
    delete broadcastData.csv_file_parsed_contents;
    delete broadcastData.columns;
    delete broadcastData.fields;
    delete broadcastData.recipients_file_selected;

    // Transform the `string[]` contact_tags to the expected `ContactTag[]`
    delete broadcastData.contact_tags;

    broadcastData.contact_tags = contactTags?.map(
      (contactTag) => ({ name: contactTag } as unknown as ContactTag)
    );

    formSubmit(
      broadcastData,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  return (
    <Formik<MessageBlastPublicEdit & BroadcastFormExtraFormikFields>
      validationSchema={meta.schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      validate={validate}
      enableReinitialize={true}
      initialValues={broadcast}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit} className="space-y-4">
          <div className="mb-4">
            <BroadcastFormStepNav
              formik={formik}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          </div>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>
          <div className="my-4">
            {!isTutorialInProgress &&
              session &&
              session.account &&
              session.account.status === AccountStatus.IN_SANDBOX && (
                <div className="rounded-md bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0 self-center">
                      <ExclamationIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">
                        {t("account-in-sandbox", "Account in Sandbox")}
                      </h3>
                      <div className="text-sm text-yellow-700">
                        <p>
                          {t(
                            "broadcasts:account-in-sandbox",
                            "Remember that broadcasts can only be sent to your connected sandbox number."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>

          {currentStep === "broadcast-details" && (
            <BroadcastFormStepDetails
              formik={formik}
              setCurrentStep={setCurrentStep}
              setRequiredFields={setRequiredFields}
              requiredFields={requiredFields}
              isTutorialInProgress={isTutorialInProgress}
              setSelectedTemplate={setSelectedTemplate}
              selectedTemplate={selectedTemplate}
            />
          )}

          {currentStep === "contacts" && (
            <BroadcastFormStepContacts
              formik={formik}
              setFile={setFile}
              file={file}
              setCurrentStep={setCurrentStep}
              requiredFields={requiredFields}
              isTutorialInProgress={isTutorialInProgress}
              selectedTemplate={selectedTemplate}
            />
          )}

          {currentStep === "review" && (
            <BroadcastFormStepReview
              formik={formik}
              setCurrentStep={setCurrentStep}
              isTutorialInProgress={isTutorialInProgress}
              submitted={submitted}
              selectedTemplate={selectedTemplate}
              success={success}
            />
          )}
        </form>
      )}
    </Formik>
  );
}
