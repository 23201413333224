import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { FormikProps, useField } from "formik";
import {
  CategoryEnum,
  MessageBlastPublicEdit,
} from "@hilos/types/private-schema";
import {
  BroadcastFormExtraFormikFields,
  BroadcastFormSteps,
} from "src/containers/broadcasts/BroadcastForm";
import * as meta from "src/containers/broadcasts/BroadcastMeta";
import {
  CATEGORIES,
  getVariablesRequiredFromTemplate,
} from "src/containers/wa/WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "src/containers/wa/WhatsAppTemplatePreview";
import { initialTemplatePreviewValues } from "src/containers/wa/WhatsAppTemplatePreviewForm";
import WhatsAppTemplateSelect from "src/containers/wa/WhatsAppTemplateSelect";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import CopyToClipboardButton from "src/components/Form/CopyToClipboardButton";
import FileUploadField from "src/components/Form/FileUploadField";
import TextInputField from "src/components/Form/TextInputField";
import { getTemplateComponents } from "src/helpers/template";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames } from "src/Helpers";
import { WhatsAppTemplate } from "src/types/wa/templates";

interface BroadcastFormStepDetailsProps {
  setCurrentStep: (step: BroadcastFormSteps) => void;
  formik: FormikProps<MessageBlastPublicEdit & BroadcastFormExtraFormikFields>;
  setSelectedTemplate: (template: WhatsAppTemplate) => void;
  selectedTemplate?: WhatsAppTemplate;
  setRequiredFields: (fields: string[]) => void;
  requiredFields: string[];
  isTutorialInProgress: boolean;
}

export default function BroadcastFormStepDetails({
  formik,
  setCurrentStep,
  setSelectedTemplate,
  selectedTemplate,
  setRequiredFields,
  requiredFields,
  isTutorialInProgress,
}: BroadcastFormStepDetailsProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [mediaHelpUploadUrlField] = useField({ name: "media_help_upload_url" });

  const { headerRequiresURL } =
    getVariablesRequiredFromTemplate(selectedTemplate);

  const { header } = getTemplateComponents(selectedTemplate);

  const [previewValues, setPreviewValues] = useState(
    initialTemplatePreviewValues
  );

  const handleSelectTemplate = useCallback(
    (template) => {
      setSelectedTemplate(template);
      setPreviewValues({ ...initialTemplatePreviewValues });
    },
    [setSelectedTemplate]
  );

  const handleSetRequiredFields = useCallback(
    (fields: string[]) => {
      setRequiredFields(fields);
      formik.setFieldValue("fields", fields);
      // The first element must be an empty string to map correctly the columns
      // The first column is a boolean where we save if the row should be
      // included in the broadcasts
      const columnsMappings = [undefined, "phone"];
      fields.forEach((_, index) => {
        columnsMappings.push(`data.${index}`);
      });
      formik.setFieldValue(meta.FIELDS.columns.key, columnsMappings);
      if (formik.values[meta.FIELDS.csv_file_parsed_contents.key]) {
        // The CSV file has already been selected we need to validate that the
        // columns are at least the number of required fields
        const csvColsQuantity =
          formik.values.csv_file_parsed_contents.data[0].length;
        if (columnsMappings.length > csvColsQuantity) {
          formik.setFieldError(
            meta.FIELDS.csv_file_parsed_contents.key,
            // i18n.t("broadcasts:need-more-cols", "Your CSV file doesn't have the minimum required columns to match all the required template variables.")
            "broadcasts:need-more-cols"
          );
        } else {
          formik.setFieldError(
            meta.FIELDS.csv_file_parsed_contents.key,
            undefined
          );
        }
      }
    },
    [formik, setRequiredFields]
  );

  const nextButtonEnabled = useMemo(() => {
    return !!formik.values.name && !!formik.values.whatsapp_template;
  }, [formik.values.name, formik.values.whatsapp_template]);

  return (
    <div className="space-y-14">
      <div className="row mt-10 text-center">
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          {t(
            "broadcasts:pick-message-to-send.title",
            "Pick the message to send"
          )}
        </h1>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-4xl">
          <div
            className="grid grid-cols-1 sm:grid-cols-5 sm:gap-12"
            data-tour="broadcast-form"
          >
            <div className="col-span-3">
              <div className="mb-4">
                <TextInputField
                  placeholder={t(meta.FIELDS.name.placeholder)}
                  label={t(meta.FIELDS.name.label)}
                  name={meta.FIELDS.name.key}
                  type="text"
                />
              </div>

              {session?.account.has_multiple_active_channels && (
                <ChannelSelectField
                  name={meta.FIELDS.channel.key}
                  label={t(meta.FIELDS.channel.label)}
                  help={t(meta.FIELDS.channel.help)}
                />
              )}

              <WhatsAppTemplateSelect
                setSelectedTemplate={handleSelectTemplate}
                setRequiredFields={handleSetRequiredFields}
                fieldName={meta.FIELDS.whatsapp_template.key}
                fieldLabel={t(meta.FIELDS.whatsapp_template.label)}
                renderFieldHelp={meta.FIELDS.whatsapp_template.help}
                channel={formik.values[meta.FIELDS.channel.key]}
                categories={
                  Object.getOwnPropertyNames(CATEGORIES).filter(
                    (category) =>
                      category !== "AUTHENTICATION" && category !== "OTP"
                  ) as CategoryEnum[]
                }
              />
            </div>

            <div className="col-span-2 mt-6 pb-6 sm:mt-0 sm:border-l sm:border-gray-200 sm:pl-12">
              <h6 className="text-tiny uppercase tracking-wider text-gray-400">
                {t(
                  "broadcasts:selected-template-preview",
                  "Selected template preview"
                )}
              </h6>
              <div className="my-2" data-tour="broadcast-template-preview">
                {selectedTemplate && (
                  <WhatsAppTemplatePreview
                    template={selectedTemplate}
                    values={previewValues}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="py-4">
            {headerRequiresURL && header && header.format !== "LOCATION" && (
              <>
                <p>
                  {t(
                    "broadcasts:upload-file-help",
                    "Here you can upload a file to get a URL that you can use to send the media in this Broadcast."
                  )}
                </p>
                <span className="text-xs text-gray-500">({t("optional")})</span>
                <FileUploadField
                  name="media_help_upload_url"
                  media={header.format}
                />
              </>
            )}
            {mediaHelpUploadUrlField.value && (
              <div className="py-4">
                <TextInputField
                  label={t(
                    "broadcasts:upladed-file-url.label",
                    "Pubilc sharable URL"
                  )}
                  help={t(
                    "broadcasts:upladed-file-url.help",
                    "You can use this URL to send your Broadcast, make sure you include this URL in the corresponding column in your CSV file"
                  )}
                  name="media_help_upload_url"
                  type="text"
                  readOnly
                  action={
                    <div
                      className={classNames(
                        "relative inline-flex items-center space-x-2 rounded-r-md border border-l-0 border-gray-300 bg-white px-4 py-2",
                        "text-sm font-medium text-gray-700"
                      )}
                    >
                      <CopyToClipboardButton
                        value={mediaHelpUploadUrlField.value}
                      />
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="my-14">
        <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
          <div className="col col-start-2 text-center ">
            <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
              {t("step-n-of-n", "Step {{step}} of {{total}}", {
                step: 1,
                total: 3,
              })}
            </h6>
          </div>
          <div className="justify-self-end">
            <button
              className={classNames(
                "inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm",
                nextButtonEnabled
                  ? "hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                  : "opacity-75"
              )}
              type="button"
              disabled={!nextButtonEnabled}
              onClick={(_) => setCurrentStep("contacts")}
            >
              {t("contacts")}
              <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
