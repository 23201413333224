import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { StripeSubscription } from "@hilos/types/private-schema";
import { useUsageSummaryAnalytics } from "src/hooks/useUsageAnalytics";
import { buildRoute } from "src/router/router";

interface SubscriptionCurrentPeriodDetailProps {
  subscription: StripeSubscription;
}

function SubscriptionCurrentPeriodDetail({
  subscription,
}: SubscriptionCurrentPeriodDetailProps) {
  const { t } = useTranslation();
  const { usageSummary } = useUsageSummaryAnalytics();

  return (
    <div className="space-y-3">
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("settings:billing.current-period", "Current billing period")}
      </h3>
      <div className="my-6 grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div className="col-span-2 rounded-md border border-gray-200 bg-white px-4 py-5 text-left shadow-sm sm:px-6">
          <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
            {t("settings:billing.start-end", "Period start & end")}
          </h3>
          <p className="mt-2 text-3xl font-medium text-gray-900">
            <time dateTime={subscription.current_period_start}>
              {format(new Date(subscription.current_period_start), "d/MMM")}
            </time>{" "}
            -{" "}
            <time dateTime={subscription.current_period_end}>
              {format(new Date(subscription.current_period_end), "d/MMM")}
            </time>
          </p>
        </div>

        <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-left shadow-sm sm:px-6">
          <Link
            className="flex items-center text-tiny font-medium uppercase tracking-wider text-blue-500"
            to={buildRoute("reports")}
          >
            {t("settings:billing.seats-used", "Inbox seats used")}
            <ExternalLinkIcon className="ml-1 h-3 w-3" />
          </Link>
          <p className="mt-2 text-3xl font-medium text-gray-900">
            {usageSummary.current.inbox_users_in_period}
          </p>
        </div>
        <div className="rounded-md border border-gray-200 bg-white px-4 py-5 text-left shadow-sm sm:px-6">
          <Link
            className="flex items-center text-tiny font-medium uppercase tracking-wider text-blue-500"
            to={buildRoute("reports")}
          >
            {t("settings:billing.active-contacts-used", "Active Contacts used")}
            <ExternalLinkIcon className="ml-1 h-3 w-3" />
          </Link>
          <p className="mt-2 text-3xl font-medium text-gray-900">
            {usageSummary.current.macs_in_period}
          </p>
        </div>
      </div>
    </div>
  );
}

export default memo(SubscriptionCurrentPeriodDetail);
