import { Trans, useTranslation } from "react-i18next";
import {
  FlowStepEdit,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import WhatsAppTemplatePreview from "src/containers/wa/WhatsAppTemplatePreview";
import useWhatsAppTemplate from "src/hooks/useWhatsAppTemplate";
import FlowStepMaxAttemptsDetail from "./components/FlowStepMaxAttemptsDetail";
import FlowStepMaxWaitTimeDetail from "./components/FlowStepMaxWaitTimeDetail";

interface FlowStepTemplateMenuDetailProps {
  step: FlowStepEdit;
  flowVersion: FlowVersionDetailRead;
  stepInfo: FlowStepEdit;
  getStepById: (id: string) => FlowStepEdit;
}

export default function FlowStepTemplateMenuDetail({
  step,
  flowVersion,
  stepInfo,
  getStepById,
}: FlowStepTemplateMenuDetailProps) {
  const { t } = useTranslation();
  const template = useWhatsAppTemplate(step.whatsapp_template);

  if (!template) {
    return null;
  }
  return (
    <div className="space-y-4">
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("preview", "Preview")}
      </h3>
      <WhatsAppTemplatePreview
        template={template}
        //@ts-ignore
        values={step.whatsapp_template_variables}
      />

      {step.next_action !== "CONTINUE" ? (
        <>
          <p>
            {t(
              "flow-executions:steps-detail.template.save-contact-answer",
              "We'll wait for the user response before continuing and save their answer"
            )}
            .
          </p>
          {step.next_action === "BUTTON_RESPONSE" && (
            <>
              <p>
                {t(
                  "flow-executions:steps-detail.template.validate-answer-with-buttons",
                  "We'll also validate that the user's response is one of these template buttons."
                )}
              </p>
              <p>
                {t(
                  "flow-executions:steps-detail.question.validation-message",
                  "If the user's answer is not valid, answer with"
                )}{" "}
                <span className="text-gray-900 font-medium">
                  "{step.answer_validation_message}"
                </span>
              </p>
            </>
          )}
        </>
      ) : (
        <Trans i18nKey="flow-executions:steps-detail.template.wait-for-answer">
          <p>We won't wait for the user's response to continue, if we can.</p>
          <p>
            If the next is also a template, it will be sent right after this
            one, otherwise if it's a Message, Question or Menu step, we'll wait
            for the user response to continue.
          </p>
        </Trans>
      )}

      {step.has_max_wait_time && (
        <FlowStepMaxWaitTimeDetail step={step} getStepById={getStepById} />
      )}
      {step.has_max_answer_attempts && (
        <FlowStepMaxAttemptsDetail step={step} getStepById={getStepById} />
      )}
    </div>
  );
}
