import { useCallback } from "react";
import { Transition } from "@headlessui/react";
import { InboxContactViewData } from "src/hooks/useInboxContactViews";
import { ConversationsFilter } from "./InboxMeta";
import InboxParamsForm, { InboxParamsValues } from "./InboxParamsForm";

interface ConversationListInboxParamsProps {
  view: InboxContactViewData | null;
  ordering: string | null;
  filters: ConversationsFilter[];
  showFilters: boolean;
  onCloseFilters: () => void;
  onChangeFilters: (params: InboxParamsValues) => void;
}

function ConversationListInboxParams({
  view,
  ordering,
  filters,
  showFilters,
  onCloseFilters,
  onChangeFilters,
}: ConversationListInboxParamsProps) {
  const handleSubmitView = useCallback(
    (values: InboxParamsValues) => {
      const nextValues = { ...values, view: null };
      onChangeFilters(nextValues);
      onCloseFilters();
    },
    [onCloseFilters, onChangeFilters]
  );

  return (
    <Transition
      show={showFilters}
      enter="transition duration-100 ease-in"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <div
        className="border-b border-gray-200 py-3 text-sm text-gray-500"
        data-tour="filter-menu"
      >
        <InboxParamsForm
          values={{ view, ordering, filters }}
          onSubmit={handleSubmitView}
          onClose={onCloseFilters}
        />
      </div>
    </Transition>
  );
}

export default ConversationListInboxParams;
