import { FlowStepData } from "@hilos/types/flow";

interface FlowExecutionStepTagDetailProps {
  step: FlowStepData;
}

export default function FlowExecutionStepTagDetail({
  step,
}: FlowExecutionStepTagDetailProps) {
  return null;
}
