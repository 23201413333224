import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/outline";
import HeaderNavigation from "src/components/HeaderNavigation";
import Loading from "src/components/Loading";
import useCrawlerSettings, {
  useCrawlerSettingsUpdate,
} from "src/hooks/useCrawlSettings";
import {
  useOpenAISettings,
  useOpenAISettingsUpdate,
} from "src/hooks/useOpenAISettings";
import { buildRoute } from "src/router/router";
import CrawlerSettings from "./CrawlerSettings";
import OpenAISettings from "./OpenAISettings";

export default function AISettings() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { data: openAISettings, isLoading: openAILoading } =
    useOpenAISettings();
  const { data: crawlerSettings, isLoading: crawlerLoading } =
    useCrawlerSettings();
  const { handleUpdateOpenAISettings } = useOpenAISettingsUpdate();
  const { handleUpdateCrawlerSettings } = useCrawlerSettingsUpdate();
  useEffect(() => {
    setLoading(openAILoading || crawlerLoading);
  }, [crawlerLoading, openAILoading]);

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t(
                "integrations:external-apps.header-navigation-title",
                "External apps"
              ),
              url: buildRoute("external-apps"),
            },
            {
              name: t("integrations:ai.title", "ChatGPT Settings"),
              url: buildRoute("integrations-chatgpt"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <PlusIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("integrations:ai.title", "ChatGPT Settings")}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto mt-6 max-w-7xl px-4 sm:px-6 lg:px-8">
        <div>
          {loading || openAISettings == null || crawlerSettings == null ? (
            <Loading />
          ) : (
            <>
              <OpenAISettings
                currentSettings={openAISettings}
                onUpdateOpenAISettings={handleUpdateOpenAISettings}
                setLoading={setLoading}
                disabled={false}
              />
              <CrawlerSettings
                currentSettings={crawlerSettings}
                onUpdateCrawlerSettings={handleUpdateCrawlerSettings}
                disabled={false}
                setLoading={setLoading}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
