import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepContactUpdateDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepContactUpdateDetail({
  step,
}: FlowStepContactUpdateDetailProps) {
  const { t } = useTranslation();
  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:steps-detail.contact-update.basic-properties",
            "Basic properties to update"
          )}
        </h3>

        <ul>
          {step.contact_first_name && (
            <li key="contact_first_name">
              <dl className="flex space-x-2">
                <dt>{t("first-name")}:</dt>
                <dd className="grow text-gray-900 font-medium">
                  {step.contact_first_name}
                </dd>
              </dl>
            </li>
          )}
          {step.contact_last_name && (
            <li key="contact_last_name">
              <dl className="flex space-x-2">
                <dt>{t("last-name")}:</dt>
                <dd className="grow text-gray-900 font-medium">
                  {step.contact_last_name}
                </dd>
              </dl>
            </li>
          )}
          {step.contact_email && (
            <li key="contact_email">
              <dl className="flex space-x-2">
                <dt>{t("email")}:</dt>
                <dd className="grow text-gray-900 font-medium">
                  {step.contact_email}
                </dd>
              </dl>
            </li>
          )}
          {step.contact_external_url && (
            <li key="contact_external_url">
              <dl className="flex space-x-2">
                <dt>{t("contacts:external-url")}:</dt>
                <dd className="grow text-gray-900 font-medium">
                  {step.contact_external_url}
                </dd>
              </dl>
            </li>
          )}
        </ul>
      </div>

      <div>
        <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
          {t(
            "flow-executions:steps-detail.contact-update.custom-properties",
            "Custom attributes to update"
          )}
        </h3>

        <ul>
          {step.contact_custom_properties?.map((customProp, idx) => (
            <li key={idx}>
              <dl className="flex space-x-2">
                <dt>{customProp.key}:</dt>
                <dd className="grow text-gray-900 font-medium">
                  {customProp.value}
                </dd>
              </dl>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
