import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BeakerIcon, PlayIcon } from "@heroicons/react/outline";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { FlowDetailRead, FlowTestRead } from "@hilos/types/private-schema";
import LoaderSpinner from "src/components/LoaderSpinner";
import useCleanInterval from "src/hooks/useCleanInterval";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPhone } from "src/Helpers";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import FlowAdvancedOptionsDisclosure from "../components/FlowBuilderAdvancedOptionsDisclosure";
import FlowBuilderTestExecutionStarted from "./FlowBuilderTestExecutionStarted";
import FlowBuilderTestQR from "./FlowBuilderTestQR";
import FlowBuilderTestTriggerHelp from "./FlowBuilderTestTriggerHelp";

interface FlowBuilderTestInboundProps {
  flow: FlowDetailRead;
}

export default function FlowBuilderTestInbound({
  flow,
}: FlowBuilderTestInboundProps) {
  // For INBOUND_* triggers we first check if the user's phone is saved, if not
  // we save it first with a QR and then wait for their next message as the
  // message the customer would send to start this flow
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [flowTest, setFlowTest] = useState<FlowTestRead | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const interval = useCleanInterval();

  const checkFlowTest = async () => {
    try {
      const response = await axios.get<FlowTestRead>(
        buildAPIRoute(API_ROUTES.FLOW_TEST_DETAIL, { ":id": flow.id })
      );
      setFlowTest(response.data);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      if (errorAxios?.response?.status === 404) {
        setFlowTest(null);
      }
    }
  };

  const createFlowTest = async () => {
    try {
      const response = await axios.post<FlowTestRead>(
        buildAPIRoute(API_ROUTES.FLOW_TEST_CREATE),
        {
          flow: flow.id,
          flow_version: flow.test_version.id,
          flow_execution_variables: {},
        }
      );
      setSubmitting(false);
      setFlowTest(response.data);
      interval.current = setInterval(() => {
        checkFlowTest();
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (!session) {
    return null;
  }

  return (
    <div className="mx-auto block">
      <div className="flex text-gray-800 items-center font-bold">
        <div className="h-10 w-10 rounded-full flex items-center justify-center text-blue-500 mr-4 bg-blue-100">
          <BeakerIcon className="h-6 w-6" />
        </div>
        <div className="text-lg">
          {t("flows:test-modal.title", "Test your flow!")}
        </div>
      </div>

      <div>
        <FlowAdvancedOptionsDisclosure
          buttonText={t("flows:test-modal.trigger-details", "Trigger details")}
        >
          <FlowBuilderTestTriggerHelp flow={flow} />
        </FlowAdvancedOptionsDisclosure>

        <div className="pb-2">
          {!flowTest ? (
            <>
              <div className="text-lg text-gray-800 font-medium">
                {t("flows:test-modal.ready-to-test", "Ready to test?")}
              </div>
              <div className="mt-1 text-sm text-gray-600">
                <p>
                  {t(
                    "flows:test-modal.test-inbound-instructions.click",
                    "Click the button below to start."
                  )}
                </p>
                <p>
                  {t(
                    "flows:test-modal.test-inbound-instructions.send",
                    "Then, send a message from your phone just like your contact would do!"
                  )}
                </p>
              </div>
              <button
                type="button"
                className="mt-4 w-full inline-flex items-center justify-center rounded-md border bg-white px-8 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-green-500 focus:ring-green-500 hover:bg-green-50 text-green-600 border-green-300 disabled:cursor-default disabled:hover:bg-white disabled:text-gray-300 disabled:border-gray-300"
                disabled={submitting}
                onClick={createFlowTest}
              >
                {submitting ? (
                  <>
                    <LoaderSpinner />
                    {t("flows:test-modal.creating-test-button", "Starting...")}
                  </>
                ) : (
                  <>
                    <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    {t("flows:test-modal.start-test-button", "Start test")}
                  </>
                )}
              </button>
            </>
          ) : (
            <>
              {flowTest.flow_execution_contact ? (
                <FlowBuilderTestExecutionStarted flowTest={flowTest} />
              ) : (
                <div>
                  <div className="text-lg text-gray-800 font-medium">
                    {t("flows:test-modal.all-set", "All set!")}
                  </div>
                  <Trans i18nKey="flows:test-modal.inbound.description">
                    <div className="text-sm text-gray-600 space-y-1">
                      Scan the QR code below to send a message from{" "}
                      <span className="inline-flex items-center rounded-md bg-pink-100 px-2 py-1 text-xs font-medium text-pink-700">
                        {/* @ts-ignore */}
                        {{ phone: formatPhone(session.phone) }}
                      </span>{" "}
                      and trigger this flow.
                    </div>
                  </Trans>
                  <div className="grid grid-cols-2 items-center">
                    <div>
                      <FlowBuilderTestQR
                        channel_id={flow.channel.channel_id}
                        prefilled_text={
                          flow.trigger_config.message_content_to_match
                        }
                      />
                    </div>
                    <div>
                      <div className="flex items-center justify-center self-center rounded-sm p-2 font-semibold text-hilos">
                        <svg
                          className="-ml-1 mr-3 h-5 w-5 animate-spin text-hilos"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <p className="text-sm">
                          {t(
                            "flows:test-modal.waiting-for-message",
                            "Waiting for your message"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
