import { useCallback } from "react";
import * as Sentry from "@sentry/browser";
import { usePostHog } from "posthog-js/react";
import { CurrentUser } from "@hilos/types/private-schema";

export default function usePostHogHelpers() {
  const posthog = usePostHog();
  const identifyUser = useCallback(
    (user: CurrentUser, event?: string) => {
      try {
        if (!posthog || process.env.NODE_ENV !== "production") {
          return
        }
        const userInfo = {
          account_status: user.account?.status,
          email: user.email,
          account_id: user.account?.id,
          account_name: user.account?.name,
          date_joined: user.date_joined,
        };
        // Add the userInfo to the PostHog person object
        posthog.identify(String(user.id), userInfo);
        // Add the userInfo to the super properties to be included
        // to all the events we start sending
        // https://posthog.com/docs/sdks/js#super-properties
        posthog.register(userInfo);
        if (event) {
          posthog.capture(event);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [posthog]
  );
  const resetPostHog = useCallback(() => {
    try {
      if (!posthog || process.env.NODE_ENV !== "production") {
        return
      }
      // There is a bug in posthog where calling `.reset()` when the library
      // has not been initialized i.e. development, it will throw an error
      // https://github.com/PostHog/posthog-js/issues/323
      posthog.reset();
      // Remove the super properties
      posthog.unregister("account_status");
      posthog.unregister("email");
      posthog.unregister("account_id");
      posthog.unregister("account_name");
      posthog.unregister("date_joined");
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [posthog]);

  const maybeStartRecording = useCallback(() => {
    if (!posthog || process.env.NODE_ENV !== "production") {
        return
    }
    try {
      if (!posthog.sessionRecordingStarted) {
        posthog.startSessionRecording();
      }
    } catch (error) {
      console.error(error);
    }
  }, [posthog]);

  return { identifyUser, resetPostHog, maybeStartRecording, posthog };
}
