import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { StripeCustomerPortalData } from "@hilos/types/billing";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { useStripePaymentMethods } from "src/hooks/useStripe";
import { API_ROUTES } from "src/router/router";
import { axiosErr } from "src/types/axios";

export default function GoToStripeCustomerPortal({ buttonText }) {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const { payment_methods } = useStripePaymentMethods();

  const onSubmit = async (ev) => {
    ev.preventDefault();
    setBackendValidationErrors({});
    setBackendError("");
    setIsSubmitting(true);

    //@ts-ignore
    const stripeKey: string =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY
        : process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY;
    const stripe = await loadStripe(stripeKey);
    if (!stripe) {
      return;
    }

    try {
      const response = await axios.get<StripeCustomerPortalData>(
        API_ROUTES.STRIPE_CUSTOMER_PORTAL
      );
      setIsSubmitting(false);
      if (!response.data.url) {
        setBackendError(
          t(
            "settings:billing.error-stripe",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
        return;
      }
      setSuccess(true);
      window.location.assign(response.data.url);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;

      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:billing.error-stripe",
            "Couldn't start a Stripe secure session, please try again later."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setIsSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <div className="space-y-4">
        {backendError ||
          (backendValidationErrors && (
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={{}}
            />
          ))}

        <StateButton
          isSubmitting={isSubmitting}
          submitted={submitted}
          success={success}
          submittingText="Processing..."
          successText="Redirecting..."
          btnClasses={
            payment_methods.length > 0
              ? "inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-hilos shadow-sm focus:outline-none ring-inset ring-hilos ring-1"
              : "inline-flex items-center rounded-md bg-gradient-to-r from-hilos to-red-400 px-3 py-2 text-sm font-semibold text-white shadow-sm focus:outline-none ring-inset ring-hilos ring-1"
          }
          initialText={
            <>
              {buttonText}
              <ArrowSmRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
            </>
          }
        />
      </div>
    </form>
  );
}
