interface HTTPResponseNavProps {
  currentStep: string;
  setCurrentStep: (x: string) => void;
}

export default function HTTPResponseNav({
  currentStep,
  setCurrentStep,
}: HTTPResponseNavProps) {
  const steps = [
    {
      name: "Request",
      stepName: "request",
      current: currentStep === "request",
    },
    {
      name: "Response",
      stepName: "response",
      current: currentStep === "response",
    },
  ];

  return (
    <nav aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-5">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            <>
              {step.current ? (
                <button
                  type="button"
                  onClick={(_) => setCurrentStep(step.stepName)}
                  className="group flex w-full flex-col border-l-4 border-blue-600 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                  aria-current="step"
                >
                  <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-blue-600">
                    {step.name}
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={(_) => setCurrentStep(step.stepName)}
                  className="group flex w-full flex-col border-l-4 border-gray-200 pl-4 pb-2 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                >
                  <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-gray-600">
                    {step.name}
                  </span>
                </button>
              )}
            </>
          </li>
        ))}
      </ol>
    </nav>
  );
}
