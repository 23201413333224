import { useMemo } from "react";
import { Trans } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import * as yup from "yup";
import { BroadcastFormExtraFormikFields } from "@hilos/containers/broadcasts/BroadcastForm";
import { AccountData } from "@hilos/types/account";
import { MessageBlastPublicEdit } from "@hilos/types/private-schema";
import { ERROR_MESSAGES } from "src/constants/Form";
import { getTranslationPayload as t } from "src/i18n";

export const getInitialValues = (account: AccountData) => {
  return {
    name: "",
    start_on: "",
    is_active: "",
    channel: account ? account.default_channel : "",
    see_advanced_options: false,
    template_body: "",
    whatsapp_template: "",
    recipients_file_selected: "",
  };
};

export const getInitialValuesTutorial: (
  templateId: string
) => MessageBlastPublicEdit & BroadcastFormExtraFormikFields = (templateId) => {
  return {
    id: 1,
    created_by: "",
    recipients: [
      {
        phone: "+52111111111",
        data: [],
      },
    ],
    name: "[Broadcast Tutorial] My first broadcast",
    start_on: "",
    is_active: "",
    see_advanced_options: true,
    template_body: "",
    whatsapp_template: templateId,
    recipients_file_selected: "broadcast_example.csv",
    has_priority: true,
    csv_file_parsed_contents: {
      data: [],
      errors: [],
      meta: {
        delimiter: ",",
        linebreak: "\n",
        aborted: false,
        truncated: false,
        cursor: 0,
      },
    },
    columns: [],
    fields: [],
  };
};

export const schema = yup.object().shape({
  // tab: broadcast-details
  name: yup.string().required(ERROR_MESSAGES.required),
  whatsapp_template: yup.string().required(ERROR_MESSAGES.required),
  recipients_file_selected: yup.string().required(ERROR_MESSAGES.required),
  channel: yup.string(),

  // tab: contacts
  columns: yup
    .array()
    .of(yup.string())
    .test(
      "has-the-required-fields",
      // i18n.t("broadcasts:missing-required-variables", "There are some missing required variables")
      "broadcasts:missing-required-variables",
      (mappedColumns, context) => {
        const requiredFields = context.parent.fields as string[] | undefined;
        const requiredColumns =
          requiredFields?.map((_, index) => `data.${index}`) ?? [];
        requiredColumns.push("phone");
        if (!mappedColumns) {
          // No column has been mapped yet
          // All broadcasts required the `contact.phone` variable
          return false;
        }
        return requiredColumns.every((field) => mappedColumns.includes(field));
      }
    ),
  csv_file_parsed_contents: yup.object().shape({
    results: yup.array().min(1),
  }),
  // In this field we store the required fields by the template to validate
  // the columns field
  fields: yup.array().of(yup.string()).min(0),

  // tab: review

  start_on: yup.date().min(Date(), ERROR_MESSAGES.minDate).nullable(),
  has_priority: yup.bool(),
});

export const FIELDS = {
  // broadcast-details
  name: {
    key: "name",
    label: t("broadcasts:broadcast-name.label", "Broadcast name"),
    help: t("broadcasts:broadcast-name.help", "Name of the broadcast"),
    placeholder: t("broadcasts:broadcast-name.placeholder", "Broadcast #1"),
  },
  channel: {
    key: "channel",
    label: t("broadcasts:channel.label", "Channel to use"),
    placeholder: "",
    help: t(
      "broadcasts:channel.help",
      "Select which channel to send this broadcast from."
    ),
  },
  whatsapp_template: {
    key: "whatsapp_template",
    label: t("broadcasts:whatsapp-template.label", "WhatsApp template"),
    help: (
      <>
        <Trans i18nKey="broadcasts:select-approved-template">
          Select from your approved templates. If you need to add another, you
          can do so in{" "}
          <a
            href="https://app.hilos.io/template"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            our template builder
          </a>
          .
        </Trans>
      </>
    ),
  },
  recipients_file_selected: {
    key: "recipients_file_selected",
  },
  // contacs
  start_on: {
    key: "start_on",
    label: t("send-on", "Send on"),
    optional: true,
    placeholder: t("broadcasts:send-on.placeholder", "Select a date"),
    help: t(
      "broadcasts:send-on.help",
      "An optional start date. If not set, we'll send the messages inmediately."
    ),
  },

  has_priority: {
    key: "has_priority",
    label: t(
      "broadcasts:has-priority.label",
      "Send broadcast even if there are open conversations?"
    ),
    help: t(
      "broadcasts:has-priority.help",
      "If set, sending this broadcast will automatically close any open conversations for all recipients."
    ),
  },
  contact_tags: {
    key: "contact_tags",
    label: t(
      "broadcasts:contact-tags.label",
      "Add tags to contacts on this broadcast?"
    ),
    help: t(
      "broadcasts:contact-tags.help",
      "You can later use these tags in the Inbox to filter contacts that replied to this broadcast."
    ),
    placeholder: t(
      "broadcasts:contact-tags.placeholder",
      "Start writing to search or create a new tag..."
    ),
  },
  see_advanced_options: {
    key: "see_advanced_options",
    label: t("broadcasts:see-advanced-options.label", "See advanced options"),
    help: t(
      "broadcasts:see-advanced-options.help",
      "Schedule this broadcast, add contact tags, and configure behaviour for contacts with open conversations."
    ),
  },
  csv_file_parsed_contents: {
    key: "csv_file_parsed_contents",
  },
  columns: {
    // Here we store the mapping between the CSV columns to Hilos variables
    key: "columns",
  },
};
