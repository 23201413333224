import { FlowDetailWithoutStepsRead } from "@hilos/types/private-schema";
import { TRIGGER_TYPES } from "./builder/constants/triggers";

interface FlowDetailTriggerProps {
  flow: FlowDetailWithoutStepsRead;
}

export default function FlowDetailTrigger({ flow }: FlowDetailTriggerProps) {
  if (!flow) {
    return null;
  }

  const Component = TRIGGER_TYPES[flow.trigger_type].detail;

  return <Component flow={flow} />;
}
