import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import { FlowVersionDetailRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useFlowVersion(id: string | null = null) {
  const posthog = usePostHog();

  const fetchFlowVersion = useCallback(async () => {
    if (!id) {
      return null;
    }

    const { data } = await axios.get<FlowVersionDetailRead>(
      buildAPIRoute(API_ROUTES.FLOW_VERSION_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data, refetch } = useQuery(
    ["flow_version_detail", id],
    fetchFlowVersion
  );

  const setFlowVersionAsCurrent = useCallback(async () => {
    const response = await axios.post(
      buildAPIRoute(API_ROUTES.FLOW_VERSION_SET_CURRENT, {
        ":id": id,
      })
    );
    posthog?.capture("chatbot set as current", {
      chatbot_name: response?.data?.name,
      chatbot_id: response?.data?.id,
    });
  }, [id, posthog]);

  const createDraftFromVersion = useCallback(async () => {
    const response = await axios.post(
      buildAPIRoute(API_ROUTES.FLOW_VERSION_CREATE_DRAFT_FROM_VERSION, {
        ":id": id,
      })
    );
    posthog?.capture("chatbot new version created", {
      chatbot_name: response?.data?.name,
      chatbot_id: response?.data?.id,
    });
  }, [id, posthog]);

  return {
    data,
    refetch,
    setFlowVersionAsCurrent,
    createDraftFromVersion,
  };
}

export default useFlowVersion;
