import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowSmRightIcon,
  CalendarIcon,
  ChatAltIcon,
  ClipboardListIcon,
  TagIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { format } from "date-fns";
import { CursorPageData } from "@hilos/types/hilos";
import { PatchedContactEdit } from "@hilos/types/private-schema";
import ChannelBadge from "src/containers/config/channel/ChannelBadge";
import Button from "src/components/Button";
import Loading from "src/components/Loading";
import { userToString } from "../../../Helpers";
import { hasItems } from "../../../helpers/utils";
import { QuickReplyData } from "../../../hooks/useQuickReplies";
import InfiniteScroll from "./InfiniteScroll";

interface QuickRepliesListProps {
  pages: (CursorPageData<QuickReplyData> | null)[] | null;
  isLoading?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  onNextPage: () => void;
  contact: PatchedContactEdit | null | undefined;
  onCloseModal?: () => void;
  handleSelectQuickReplyTemplate: (QuickReplyData) => void;
  setShowQRModal: (f) => void;
  channel: number;
}

function QuickRepliesTemplateSelectList({
  pages,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  onNextPage,
  onCloseModal,
  handleSelectQuickReplyTemplate,
  setShowQRModal,
  channel,
}: QuickRepliesListProps) {
  const { t } = useTranslation();
  useEffect(() => {}, [pages]);

  if (isLoading) {
    return <Loading />;
  }

  if (
    pages &&
    pages.length > 0 &&
    pages[0] &&
    pages[0].count &&
    pages[0].count > 0
  ) {
    return (
      <>
        <InfiniteScroll
          className="h-auto grow"
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          onNextPage={onNextPage}
        >
          <>
            {pages.map(
              (page, pageIndex) =>
                page &&
                hasItems(page.results) &&
                page.results.map(
                  (reply, index) =>
                    reply.template &&
                    reply.template.channel.id === channel && (
                      <div key={`${reply.id}_${pageIndex}_${index}`}>
                        <button
                          type="button"
                          className="w-full"
                          onClick={() => handleSelectQuickReplyTemplate(reply)}
                        >
                          <div className="w-full border-b border-gray-200 py-3 px-4 hover:bg-gray-100 sm:px-6">
                            <div className="align-center flex justify-between truncate text-sm font-medium text-indigo-600">
                              <div className="flex items-center">
                                <span className="mr-2 rounded-lg bg-blue-50 p-1 text-xs font-medium text-blue-700">
                                  <ClipboardListIcon
                                    className=" h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </span>
                                {reply.template_name}
                              </div>
                              <ChannelBadge channel={reply.template.channel} />
                            </div>
                            {reply.tags && hasItems(reply.tags) && (
                              <p className="flex items-center text-xs text-gray-500">
                                <TagIcon
                                  className="mr-1 h-4 w-4 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                {reply.tags.map(({ name }) => name).join(", ")}
                              </p>
                            )}
                            <div className="mt-2 flex items-center justify-between space-x-2">
                              <p className="flex items-center text-xs text-gray-500">
                                <UserIcon
                                  className="mr-1 h-4 w-4 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                {t("created-by", "Created by")}{" "}
                                {userToString(reply.created_by)}
                              </p>
                              <div className="flex items-center text-xs text-gray-500">
                                <CalendarIcon
                                  className="mr-1 h-4 w-4 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>
                                  {t("created-on", "Created on")}{" "}
                                  <time dateTime={reply.created_on}>
                                    {format(
                                      new Date(reply.created_on),
                                      "d/M/yyyy"
                                    )}
                                  </time>
                                </p>
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    )
                )
            )}
          </>
        </InfiniteScroll>
      </>
    );
  }

  return (
    <div className="py-5 text-center">
      <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {t(
          "inbox:quick-replies.empty-quick-replies",
          "No quick replies found."
        )}
      </h3>
      <p className="my-4 text-sm font-medium text-gray-600">
        {t(
          "inbox:quick-replies.help-create-template-quick-reply",
          "Remember that you can create prefilled templates by quick replies."
        )}
      </p>
      <Button
        type="button"
        onClick={() => {
          setShowQRModal(true);
          if (onCloseModal) onCloseModal();
        }}
        icon={<ArrowSmRightIcon className="ml-1 -mr-1 h-5 w-5" />}
      >
        {t("inbox:quick-replies.create-quick-reply", "Create a quick reply")}
      </Button>
    </div>
  );
}

export default QuickRepliesTemplateSelectList;
