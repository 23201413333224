import { useMemo } from "react";
import { ChatAltIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";
import { VariableOptionGroup } from "src/containers/broadcasts/BroadcastFormSelectVariable";
import * as meta from "src/containers/broadcasts/BroadcastMeta";

interface useBroadcastVariablesProps {
  requiredFields: string[];
  formik: FormikProps<any>;
}

export default function useBroadcastVariables({
  requiredFields,
  formik,
}: useBroadcastVariablesProps) {
  const variableOptionsGroup: VariableOptionGroup[] = useMemo(() => {
    const selectedVariables: string[] =
      formik.values[meta.FIELDS.columns.key] ?? [];
    return [
      {
        label: "Broadcast Variables",
        icon: ChatAltIcon,
        options: [
          {
            label: "Ignore column",
            value: "do-not-import",
            selected: false,
          },
          {
            label: `Phone`,
            value: `phone`,
            selected: selectedVariables.includes(`phone`),
          },
          ...requiredFields.map((variable, index) => ({
            label: `Variable: ${variable}`,
            value: `data.${index}`,
            selected: selectedVariables.includes(`data.${index}`),
          })),
        ],
      },
    ];
  }, [formik.values, requiredFields]);

  return { variableOptionsGroup };
}
