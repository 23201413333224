import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { VariableIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import {
  WhatsAppTemplate,
  WhatsAppTemplateValues,
} from "@hilos/types/wa/templates";
import { getBaseUrl, getTemplateComponents } from "src/helpers/template";
import { getRequiredVariablesFromTemplate } from "../../../helpers/steps/template";
import TextInputFieldWithVariables from "../../TextInputFieldWithVariables";
import FlowBuilderStepTemplateHeader from "./FlowBuilderStepTemplateHeader";

interface FlowBuilderStepTemplateFormProps {
  index: number;
  template: WhatsAppTemplate;
  variables: WhatsAppTemplateValues;
}

function FlowBuilderStepTemplateForm({
  index,
  template,
  variables,
}: FlowBuilderStepTemplateFormProps) {
  const [t] = useTranslation();
  const { buttons } = getTemplateComponents(template);
  const { headerFormat, bodyVariables, hasHeaderVariables, buttonVariables } =
    useMemo(() => getRequiredVariablesFromTemplate(template), [template]);

  const { values } = useFormikContext<FlowData>();

  return (
    <div className="space-y-4">
      {headerFormat &&
        headerFormat !== "TEXT" &&
        headerFormat !== "LOCATION" && (
          <FlowBuilderStepTemplateHeader index={index} media={headerFormat} />
        )}
      {hasHeaderVariables && (
        <TextInputFieldWithVariables
          key={template.id}
          type="text"
          path={`steps.${index}.whatsapp_template_variables`}
          name="headerVar"
          placeholder={t("components.inputs.name.placeholder", "John...")}
          label={t(
            "templates:header-value-for-variable",
            "Header - Value for variable {{1}}"
          )}
          icon={VariableIcon}
          variableSeparator=""
          currentStepIndex={index}
        />
      )}
      {headerFormat && headerFormat === "LOCATION" && (
        <>
          <TextInputFieldWithVariables
            key={template.id}
            type="text"
            path={`steps.${index}.whatsapp_template_variables`}
            name="locationHeaderVars.latitude"
            placeholder={t(
              "components.inputs.location.latitude.placeholder",
              "37.483307"
            )}
            label={t("templates:header-location-latitude", "Latitude")}
            icon={VariableIcon}
            variableSeparator=""
            currentStepIndex={index}
          />
          <TextInputFieldWithVariables
            key={template.id}
            type="text"
            path={`steps.${index}.whatsapp_template_variables`}
            name="locationHeaderVars.longitude"
            placeholder={t(
              "components.inputs.location.longitude.placeholder",
              "122.148981"
            )}
            label={t("templates:header-location-longitude", "Longitude")}
            icon={VariableIcon}
            variableSeparator=""
            currentStepIndex={index}
          />
          <TextInputFieldWithVariables
            key={template.id}
            type="text"
            path={`steps.${index}.whatsapp_template_variables`}
            name="locationHeaderVars.name"
            placeholder={t(
              "components.inputs.location.name.placeholder",
              "Pablo Morales"
            )}
            label={t("templates:header-location-name", "Name")}
            icon={VariableIcon}
            variableSeparator=""
            currentStepIndex={index}
          />
          <TextInputFieldWithVariables
            key={template.id}
            type="text"
            path={`steps.${index}.whatsapp_template_variables`}
            name="locationHeaderVars.address"
            placeholder={t(
              "components.inputs.location.address.placeholder",
              "1 Hacker Way, Menlo Park, CA 94025"
            )}
            label={t("templates:header-location-address", "Address")}
            icon={VariableIcon}
            variableSeparator=""
            currentStepIndex={index}
          />
        </>
      )}
      {bodyVariables.map((variable, variableIndex) => (
        <TextInputFieldWithVariables
          key={`${template.id}-${variableIndex}`}
          type="text"
          path={`steps.${index}.whatsapp_template_variables`}
          name={`bodyVars[${variableIndex}]`}
          placeholder={
            template.category === "AUTHENTICATION"
              ? "123456"
              : t("components.inputs.fullname.placeholder", "John Smith")
          }
          label={
            template.category === "AUTHENTICATION"
              ? t("templates:template-value-for-otp", "OTP Code")
              : `${t(
                  "templates:template-value-for",
                  "Body - Value for variable"
                )} ${variable}`
          }
          icon={VariableIcon}
          variableSeparator=""
          currentStepIndex={index}
        />
      ))}
      {buttonVariables.map((variable, variableIndex) => (
        <TextInputFieldWithVariables
          key={`${template.id}-${variableIndex}`}
          type="text"
          path={`steps.${index}.whatsapp_template_variables`}
          name={`buttonVars[${variableIndex}]`}
          placeholder="guide_es.pdf"
          label={`${t(
            "templates:button-variable-for-url",
            "URL variable for button"
          )} "${variable}"`}
          icon={VariableIcon}
          help={`${t("final-url", "Final URL:")} ${getBaseUrl(
            variableIndex,
            buttons
          )}${
            values.steps[index].whatsapp_template_variables?.buttonVars[
              variableIndex
            ]
          }`}
          variableSeparator=""
          currentStepIndex={index}
        />
      ))}
    </div>
  );
}

export default FlowBuilderStepTemplateForm;
