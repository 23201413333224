import { useCallback, useState } from "react";
import { WorkflowTaskEdit } from "@hilos/types/private-schema";
import WorkflowBoardAddTask from "./WorkflowBoardAddTask";
import {
  WorkflowBoardStore,
  useWorkflowBoardContext,
} from "./WorkflowBoardContext";
import WorkflowBoardFilters from "./WorkflowBoardFilters";
import Board from "./board/Board";
import { Column } from "./board/Column";
import useWorkflowTasks from "./useWorkflowTasks";

interface WorkflowBoardDataProps {
  id: string;
}

export default function WorkflowBoardData({ id }: WorkflowBoardDataProps) {
  const [newWorkflowTask, setNewWorkflowTask] =
    useState<Partial<WorkflowTaskEdit> | null>(null);

  const { instanceId, columns, states, tasksByState, onMoveCard } =
    useWorkflowBoardContext<WorkflowBoardStore>();

  useWorkflowTasks({
    workflowId: id,
  });

  const onShowAddTask = useCallback((state: string) => {
    setNewWorkflowTask({
      state,
    });
  }, []);

  return (
    <Board
      instanceId={instanceId}
      tasksByState={tasksByState}
      onMoveCard={onMoveCard}
    >
      <WorkflowBoardAddTask
        workflowId={id}
        states={states}
        initialValues={newWorkflowTask}
        onClose={() => setNewWorkflowTask(null)}
      />
      <WorkflowBoardFilters states={states} />
      <div className="flex flex-1 flex-row justify-start gap-2 p-4 sm:px-6 sm:pb-6 lg:px-8 overflow-hidden overflow-x-scroll">
        {states.map(
          (state) =>
            Boolean(state.id && columns.includes(state.id)) && (
              <Column
                key={state.id}
                id={state.id}
                title={state.name}
                tasks={tasksByState[state.id]}
                onShowAddTask={onShowAddTask}
              />
            )
        )}
      </div>
    </Board>
  );
}
