import * as Sentry from "@sentry/browser";
import axios from "axios";
import { CurrentUser, PublicUserInvitation } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

const getCookieDomain = () => {
  return process.env.NODE_ENV === "production"
    ? `;domain=${process.env.REACT_APP_PROD_WS_COOKIE_DOMAIN}`
    : "";
};

export const setCookieString = (token = "") => {
  let cookie = `token=${btoa(token)};path=/${getCookieDomain()}`;

  if (token) {
    cookie += ";secure;samesite=strict";
  }

  document.cookie = cookie;
};

export function getItemFromStorage(key) {
  if (!key) {
    return null;
  }
  try {
    const value = localStorage.getItem(key);

    if (!value) {
      return null;
    }

    return JSON.parse(value);
  } catch {}
  return null;
}

export async function getUser(token) {
  if (!token) {
    return null;
  }
  try {
    const response = await axios.get<CurrentUser>("/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      Sentry.setUser({
        email: response.data.email,
        ip_address: "{{auto}}",
        account: response.data.account ? response.data.account.id : undefined,
      });

      // @ts-ignore
      window.dataLayer.push({
        user_id: response.data.email,
      });

      // @ts-ignore
      if ("UserLeap" in window && window.UserLeap !== undefined) {
        // UserLeap is defined via GTM
        // Ensures UserLeap does not re-survey the same user and increases tracking accuracy
        // @ts-ignore
        window.UserLeap("setUserId", response.data.email);
        // Sets visitor's email address to enable email surveys
        // @ts-ignore
        window.UserLeap("setEmail", response.data.email);
      }
      return response.data;
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log("Error when getting user", err);
  }
  return null;
}

export async function getCredentials(data) {
  const response = await axios.post(API_ROUTES.TOKEN, data);
  if (response.data && response.data.access) {
    return {
      token_access: response.data.access,
      token_refresh: response.data.refresh,
    };
  }
  return null;
}

export async function getInvitation(id) {
  const response = await axios.get<PublicUserInvitation>(
    buildAPIRoute(API_ROUTES.PUBLIC_USER_INVITATION_DETAIL, {
      ":id": id,
    })
  );

  if (response.data) {
    return response.data;
  }

  return null;
}

export async function signup(data) {
  const response = await axios.post(API_ROUTES.SIGNUP, data);
  return response;
}

export async function logoutUser() {
  const response = await axios.post(API_ROUTES.LOGOUT);
  return response;
}

export async function updateUser(data) {
  const response = await axios.patch(API_ROUTES.ME, data);

  if (response.data) {
    return response.data;
  }

  return null;
}
