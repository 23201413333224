import { API_ROUTES, buildAPIRoute, buildRoute } from "../../../router/router";
import { CollectionIcon, PlayIcon } from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

import { DateTime } from "luxon";
import { FlowVersionSimpleRead } from "@hilos/types/private-schema";
import { Link } from "react-router-dom";
import Table from "src/components/Table/Table";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import { userToString } from "../../../Helpers";

interface FlowVersionListProps {
  flowId: string;
}
export default function FlowVersionList({ flowId }: FlowVersionListProps) {
  const { t } = useTranslation();
  const [triggerReload, setTriggerReload] = useState(false);
  const posthog = usePostHog();

  const setFlowVersionAsCurrent = useCallback(
    async (flowVersionId) => {
      try {
        const response = await axios.post(
          buildAPIRoute(API_ROUTES.FLOW_VERSION_SET_CURRENT, {
            ":id": flowVersionId,
          })
        );
        posthog?.capture("chatbot set as current", {
          chatbot_name: response?.data?.name,
          chatbot_id: response?.data?.id,
        });
        setTriggerReload((t) => !t);
      } catch (err) {
        console.log("error", err);
      }
    },
    [posthog]
  );

  return (
    <div className="py-6 sm:px-6">
      <Table
        baseURL={buildAPIRoute(API_ROUTES.FLOW_VERSION_LIST)}
        initialSearchParams={{ flow: flowId }}
        paginated={true}
        triggerReload={triggerReload}
        tableHead={
          <thead>
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              ></th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("flows:version.name", "Version Name")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("published-on", "Published On")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("published-by", "Published By")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("flows:analytics.num_contacts", "Contacts")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("flows:analytics.ongoing", "Ongoing")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("flows:analytics.completed", "Completed")}
              </th>
              <th
                scope="col"
                className="pl-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              ></th>
            </tr>
          </thead>
        }
        tableRow={(flowVersion: FlowVersionSimpleRead) => (
          <>
            <td className="whitespace-nowrap py-3 pl-4 text-sm text-gray-900">
              <div className="h-8 w-8 rounded-full flex items-center justify-center bg-gray-100">
                <CollectionIcon
                  className=" h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-900">
              <Link
                className="font-medium text-blue-600"
                to={buildRoute("flow-version-detail", {
                  ":id": flowVersion.id,
                })}
              >
                {flowVersion.version_name}
                {flowVersion.is_current_version && (
                  <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800 ml-2">
                    {t("flows:current-version", "Current")}
                  </span>
                )}
              </Link>
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              <p className="text-sm text-gray-500">
                {DateTime.fromISO(
                  flowVersion.created_on as string
                ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              </p>
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {userToString(flowVersion.published_by)}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {flowVersion.num_contacts}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {flowVersion.running}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {flowVersion.completed}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {!flowVersion.is_current_version && (
                <button
                  className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                  type="button"
                  onClick={(_) =>
                    setFlowVersionAsCurrent(flowVersion.id as string)
                  }
                >
                  <PlayIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {t("flows:set-version-as-current", "Set as current version")}
                </button>
              )}
            </td>
          </>
        )}
        dataName={t("flow-versions", "Flow Versions")}
        noResults={
          <div className="my-4">
            <Trans i18nKey="flows:no-versions-yet">
              <h4>
                Looks like this flow's first version hasn't been published yet.
              </h4>
              <p className="text-sm text-gray-500">
                Publish your flow to get started.
              </p>
            </Trans>
          </div>
        }
        createObject={
          <div className="my-4">
            <Trans i18nKey="flows:no-versions-yet">
              <h4>
                Looks like this flow's first version hasn't been published yet.
              </h4>
              <p className="text-sm text-gray-500">
                Publish your flow to get started.
              </p>
            </Trans>
          </div>
        }
      ></Table>
    </div>
  );
}
