import { Fragment, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Listbox, Popover, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import { AxiosError } from "axios";
import { addDays, eachMinuteOfInterval, format } from "date-fns";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import {
  getConversationCloseDate,
  getScheduleMessageFromDate,
} from "src/helpers/inbox";
import useScheduledMessages from "src/hooks/useScheduledMessages";

interface ScheduledMessageMenuProps {
  inboxContactId: string;
  text: string;
  lastMsgTimestamp: Date;
  onScheduleMessage: () => void;
}

export default function ScheduledMessageMenu({
  inboxContactId,
  text,
  lastMsgTimestamp,
  onScheduleMessage,
}: ScheduledMessageMenuProps) {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const { createScheduleMessage } = useScheduledMessages();

  const getAvailableDatesBeforeConversationClose = useCallback(
    (conversationCloseDate: Date) => {
      const startDate = getScheduleMessageFromDate();
      return eachMinuteOfInterval(
        {
          start: startDate,
          end: conversationCloseDate,
        },
        {
          step: 30,
        }
      );
    },
    []
  );

  const today = new Date().getDate();
  const tomorrow = addDays(new Date(), 1).getDate();

  const conversationCloseDate = getConversationCloseDate(lastMsgTimestamp);
  const conversationCloseDay =
    conversationCloseDate.getDate() === today
      ? t("today", "today")
      : t("tomorrow", "tomorrow");
  const conversationCloseTime = format(conversationCloseDate, "HH:mm");

  const availableDateChoices = getAvailableDatesBeforeConversationClose(
    conversationCloseDate
  );
  const todayChoices = availableDateChoices.filter(
    (d) => d.getDate() === today
  );
  const tomorrowChoices = availableDateChoices.filter(
    (d) => d.getDate() === tomorrow
  );
  const availableDayOptions = useMemo(() => {
    const availableDayChoices: { label: string; value: number }[] = [];
    if (todayChoices.length > 0) {
      availableDayChoices.push({
        value: today,
        label: t("inbox:scheduled-message.today", "Today)"),
      });
    }
    if (tomorrowChoices.length > 0) {
      availableDayChoices.push({
        value: tomorrow,
        label: t("inbox:scheduled-message.tomorrow", "Tomorrow)"),
      });
    }
    return availableDayChoices;
  }, [t, today, todayChoices, tomorrow, tomorrowChoices]);

  const defaultSelectedDate = availableDateChoices[0];
  const [selectedDay, setSelectedDay] = useState(defaultSelectedDate.getDate());
  const handleSelectDay = (day) => {
    setSelectedDay(day);
    setSelectedTime(defaultSelectedDate);
  };
  const [selectedTime, setSelectedTime] = useState(defaultSelectedDate);

  const handleSubmit = useCallback(
    async (event, onClose) => {
      event.preventDefault();
      setBackendError("");
      setBackendValidationErrors({});
      setSubmitted(false);
      setSubmitted(false);
      setIsSubmitting(true);
      try {
        const data = await createScheduleMessage({
          send_on: selectedTime.toISOString(),
          inbox_contact: inboxContactId,
          text: text,
        });
        if (data) {
          setSuccess(true);
          setIsSubmitting(false);
          setTimeout(() => {
            onScheduleMessage();
            onClose();
          }, 1000 * 0.5);
        }
      } catch (err) {
        type axiosErr =
          | AxiosError<{ status?: number; data?: unknown }, unknown>
          | undefined;
        const errorAxios: axiosErr = err as axiosErr;
        if (errorAxios?.response?.status === 400) {
          setBackendValidationErrors(errorAxios.response.data);
        } else {
          setBackendError(
            t("error-general", "An error occurred, please try again.")
          );
        }
        setIsSubmitting(false);
        setSuccess(false);
        setTimeout(() => {
          setSubmitted(false);
        }, 1000 * 2);
      } finally {
        setIsSubmitting(false);
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
        }, 1000 * 2);
      }
    },
    [
      selectedTime,
      createScheduleMessage,
      inboxContactId,
      text,
      onScheduleMessage,
      t,
    ]
  );

  return (
    <div className=" h-full w-full">
      <Popover className="relative h-full w-full ">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex h-full w-full  items-center justify-center px-1 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <ClockIcon
                className={`${open ? "" : "text-opacity-70"}
                  mx-1 h-4 w-4 text-white transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static={true}
                className="absolute right-0 bottom-10 z-30 mt-3 w-80 max-w-sm transform px-4 sm:px-0 lg:max-w-3xl"
              >
                <div className=" rounded-lg bg-white p-3 shadow-lg ring-1 ring-opacity-5">
                  <div className="z-40  text-gray-700">
                    <div className="my-2">
                      <APIErrors
                        APIError={backendError}
                        APIValidationErrors={backendValidationErrors}
                        fieldTranslations={{
                          send_on: {
                            key: "send_on",
                            label: t(
                              "inbox:scheduled-message.select.help",
                              "Select a date before the closing conversation time"
                            ),
                          },
                        }}
                      ></APIErrors>
                    </div>

                    <div className="mb-2 rounded-sm bg-yellow-50 p-2 text-xs font-normal text-yellow-700">
                      <Trans
                        i18nKey="inbox:scheduled-message.conversation-close-time-warning"
                        values={{
                          conversationCloseDay,
                          conversationCloseTime,
                        }}
                        defaults="This conversation window will close <0>{{conversationCloseDay}} at {{conversationCloseTime}} hours.</0>"
                        components={[<span className="font-bold" />]}
                      />
                    </div>
                    <div className="">
                      {t(
                        "inbox:scheduled-message.select.label",
                        "Schedule Message for"
                      )}
                      <div className="mt-2 flex items-end">
                        <Listbox value={selectedDay} onChange={handleSelectDay}>
                          <div className="relative mt-1 w-full ">
                            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-200 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className="block truncate">
                                {selectedDay === today ? "Today" : "Tomorrow"}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon
                                  className="h-4 w-4 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute bottom-10 z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {availableDayOptions.map((day) => (
                                  <Listbox.Option
                                    key={day.value}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? "bg-amber-100 text-amber-900"
                                          : "text-gray-900"
                                      }`
                                    }
                                    value={day.value}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {t(day.label)}
                                        </span>
                                        {selected && (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                        <div className="mx-2 self-center">at</div>
                        <Listbox
                          name="send_on"
                          value={selectedTime}
                          onChange={setSelectedTime}
                        >
                          <div className="relative mt-1 w-full ">
                            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-200 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className="block truncate">
                                {format(selectedTime, "HH:mm")}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon
                                  className="h-4 w-4 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute bottom-10 z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {availableDateChoices
                                  .filter(
                                    (date) => date.getDate() === selectedDay
                                  )
                                  .map((date) => (
                                    <Listbox.Option
                                      key={date.toISOString()}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                          active
                                            ? "bg-amber-100 text-amber-900"
                                            : "text-gray-900"
                                        }`
                                      }
                                      value={date}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${
                                              selected
                                                ? "font-medium"
                                                : "font-normal"
                                            }`}
                                          >
                                            {format(date, "HH:mm")}
                                          </span>
                                          {selected && (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="text-right">
                      <StateButton
                        isSubmitting={isSubmitting}
                        submitted={submitted}
                        success={success}
                        onClick={(e) => handleSubmit(e, close)}
                        btnClasses="w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                        submittingText={t(
                          "inbox:scheduled-message.progress",
                          "Scheduling message"
                        )}
                        successText={t(
                          "inbox:scheduled-message.done",
                          "Message scheduled"
                        )}
                        initialText={
                          <>
                            {t(
                              "inbox:schedule-message.action",
                              "Schedule message"
                            )}
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
