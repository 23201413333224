import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery, useMutation } from "react-query";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { CursorPageData } from "@hilos/types/hilos";
import {
  ConversationContent,
  CreateWhatsAppMessage,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";
import {
  getPaginationCursor,
  updateConversationContentData,
} from "../helpers/conversation";
import { API_ROUTES } from "../router/router";

interface FetchConversationContentParams {
  signal?: AbortSignal;
  pageParam?: string;
}

interface UseConversationContentParams {
  inboxContactId?: string;
}

export type SendMessageFn = (
  data: CreateWhatsAppMessage
) => Promise<WhatsAppMessage>;

export interface ConversationContentItemValue {
  options: {
    showDetails: boolean;
    hasTodaySeparator: boolean;
    separator: string | null;
  };
  content: ConversationContent;
}

function useConversationContent({
  inboxContactId,
}: UseConversationContentParams) {
  const initialCursorRef = useRef<string | null>(null);
  const [isUpdatingCursor, setIsUpdatingCursor] = useState(false);

  const fetchConversationContent = useCallback(
    async ({ signal, pageParam }: FetchConversationContentParams) => {
      if (!inboxContactId) {
        return null;
      }
      const { data } = await axios.get<CursorPageData<ConversationContent>>(
        API_ROUTES.CONVERSATION_CONTENT.replace(":id", inboxContactId),
        {
          signal,
          params: {
            cursor: initialCursorRef.current || pageParam,
          },
        }
      );

      initialCursorRef.current = null;
      return data;
    },
    [inboxContactId]
  );

  const createConversationContent = useCallback(
    async (params) => {
      if (!inboxContactId) {
        return null;
      }
      const { data } = await axios.post(
        API_ROUTES.CONVERSATION_MESSAGE_CREATE.replace(":id", inboxContactId),
        params
      );
      return data;
    },
    [inboxContactId]
  );

  const {
    data,
    isError,
    isLoading,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    refetch,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage: handleNextPage,
    fetchPreviousPage: handlePreviousPage,
  } = useInfiniteQuery(
    ["conversation_content", inboxContactId],
    fetchConversationContent,
    {
      getNextPageParam: (lastPage, pages) =>
        (lastPage && lastPage.next) || undefined,
      getPreviousPageParam: (lastPage, pages) =>
        (lastPage && lastPage.previous) || undefined,
      retry: false,
    }
  );

  const {
    mutateAsync: createConversationContentMutation,
    isLoading: isSubmitting,
  } = useMutation(createConversationContent, {
    mutationKey: ["conversation_content_add_mutation", inboxContactId],
    onMutate: async (newMessage: CreateWhatsAppMessage) => {
      await queryClient.cancelQueries({
        queryKey: ["conversation_content", inboxContactId],
      });

      const timestamp = new Date().toISOString();
      const newConversationContent = {
        id: uuid(),
        timestamp,
        submitting: true,
        content_type: "MESSAGE",
        event: null,
        message: {
          ...newMessage,
          timestamp,
          direction: "OUTBOUND",
        },
      } as unknown as ConversationContent;

      queryClient.setQueryData(
        ["conversation_content", inboxContactId],
        updateConversationContentData(newConversationContent, false)
      );

      return { newConversationContent };
    },
    onError: (error, newConversationContent, context) => {
      queryClient.invalidateQueries({
        queryKey: ["conversation_content", inboxContactId],
      });
    },
  });

  const pages = useMemo(() => (data && data.pages) || [], [data]);

  const hasFirstPageLoaded = useMemo(
    () => pages.some((page) => page && !page.previous),
    [pages]
  );

  const handleSendMessage = useCallback<SendMessageFn>(
    (nextMessage) => createConversationContentMutation(nextMessage),
    [createConversationContentMutation]
  );

  const handleChangeInitialCursor = useCallback(
    async (timestamp: string) => {
      const initialCursor = getPaginationCursor(timestamp);

      if (initialCursor) {
        setIsUpdatingCursor(true);

        initialCursorRef.current = initialCursor;
        await refetch();
      }
    },
    [refetch]
  );

  useEffect(() => {
    if (!isFetching && !initialCursorRef.current) {
      setIsUpdatingCursor(false);
    }
  }, [isFetching]);

  return {
    pages,
    isError,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isSubmitting,
    isUpdatingCursor,
    hasNextPage,
    hasPreviousPage,
    hasFirstPageLoaded,
    refetch,
    handleNextPage,
    handlePreviousPage,
    handleSendMessage,
    handleChangeInitialCursor,
  };
}

export default useConversationContent;
