import { useCallback, useState } from "react";
import { ExternalLinkIcon, VideoCameraIcon } from "@heroicons/react/outline";

interface MessageVideoProps {
  src: string;
}

function MessageVideo({ src }: MessageVideoProps) {
  const [error, setError] = useState(false);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  if (error) {
    return (
      <a
        href={src}
        target="_blank"
        rel="noreferrer"
        className="flex items-center text-sm"
      >
        <VideoCameraIcon className="h-12 w-12 text-gray-500" />
        <ExternalLinkIcon className="ml-2 h-4 w-4" />
      </a>
    );
  }

  return (
    <div className="group relative flex min-h-min min-h-max w-60 flex-row overflow-hidden rounded-lg">
      <video controls onError={handleError}>
        <source src={src} />
      </video>
    </div>
  );
}

export default MessageVideo;
