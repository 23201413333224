import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";

export default function ContactPhoneStatusIndicator({ status }) {
  switch (status) {
    case "UNKNOWN":
      return <></>;
    case "INVALID":
      return (
        <ExclamationIcon className="ml-1 h-5 w-5 text-xs font-medium text-yellow-500" />
      );
    case "VALID":
      return (
        <CheckIcon className="ml-1 h-5 w-5 text-xs font-medium text-green-500" />
      );
  }
}
