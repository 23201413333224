import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { debounce } from "lodash";
import { FlowData } from "@hilos/types/flow";
import { HilosVariableData } from "@hilos/types/hilos";
import {
  getCurrentStepVariables,
  getMappedItemsById,
  getRequiredVariablesFromStep,
} from "src/helpers/variables";
import useWrappedSteps from "src/hooks/useWrappedSteps";
import EditableTitleField from "../EditableTitleField";
import { SelectedItemState } from "../hooks/useFlowLayout";

interface FlowBuilderStepDetailProps {
  selectedItem: SelectedItemState;
  onClearSelectItem: () => void;
}

function FlowBuilderStepDetail({
  selectedItem,
  onClearSelectItem,
}: FlowBuilderStepDetailProps) {
  const [t] = useTranslation();
  const flowDataRef = useRef<FlowData | null>(null);
  const lastIndexRef = useRef<number | null>(null);
  const lastUpdatedStepIndexRef = useRef<Set<number>>(new Set());
  const { values, setFieldValue } = useFormikContext<FlowData>();
  const { STEP_TYPES_DYNAMIC } = useWrappedSteps();

  const id = useMemo(
    () => (selectedItem && selectedItem.id) || null,
    [selectedItem]
  );

  const [show, index, step] = useMemo(() => {
    if (id && flowDataRef.current) {
      const steps = flowDataRef.current.steps || [];
      const nextIndex = steps.findIndex((step) => step.id === id);

      if (nextIndex !== -1) {
        const nextStepData = steps[nextIndex];

        if (nextStepData && nextStepData.step_type) {
          const nextStepValue =
            STEP_TYPES_DYNAMIC[nextStepData.step_type] || null;
          return [true, nextIndex, nextStepValue];
        }
      }
    }

    return [false, null, null];
  }, [id, STEP_TYPES_DYNAMIC]);

  const handleNameKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === ".") {
        event.preventDefault();
      }
    },
    []
  );

  const handleUpdateVariablesData = useMemo(
    () =>
      debounce(
        async () => {
          if (!flowDataRef.current) {
            return;
          }

          const nextSteps = flowDataRef.current.steps || [];
          const nextVariables: HilosVariableData[] =
            flowDataRef.current.variables.filter(
              (variable) => variable.source !== "step"
            );
          const variablesById = getMappedItemsById<HilosVariableData>(
            flowDataRef.current.variables
          );

          for (const currentStepIndex in nextSteps) {
            const currentStepData = nextSteps[currentStepIndex];
            if (currentStepData) {
              const prevStepVariables = (currentStepData.variables || []).map(
                (variableId) => variablesById.get(variableId)
              );
              const nextStepVariables = getCurrentStepVariables(
                currentStepData,
                prevStepVariables
              );
              const nextStepVariableIds = nextStepVariables.map(
                (variable) => variable.id
              );

              if (
                lastUpdatedStepIndexRef.current.has(Number(currentStepIndex))
              ) {
                lastUpdatedStepIndexRef.current.delete(
                  Number(currentStepIndex)
                );
                const stepRequiredVariables = getRequiredVariablesFromStep(
                  currentStepData,
                  ["step.", "flow.", "trigger.", "flow_execution_variables."]
                );

                nextSteps[currentStepIndex].required_variables =
                  stepRequiredVariables;
              }

              nextSteps[currentStepIndex].variables = nextStepVariableIds;
              nextVariables.push(...nextStepVariables);
            }
          }

          setFieldValue("variables", nextVariables);
          setFieldValue("steps", nextSteps);
        },
        350,
        {
          leading: false,
          trailing: true,
        }
      ),
    [setFieldValue]
  );

  useEffect(() => {
    flowDataRef.current = values || null;
  }, [values]);

  useEffect(() => {
    if (index !== lastIndexRef.current) {
      if (lastIndexRef.current !== null) {
        lastUpdatedStepIndexRef.current.add(lastIndexRef.current);
      }
      lastIndexRef.current = index;

      handleUpdateVariablesData();
    }
  }, [index, handleUpdateVariablesData]);

  return (
    <Transition
      show={show}
      enter="transition duration-300 ease-out"
      enterFrom="transform scale-90 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-90 opacity-0"
      className="absolute right-4 top-4 z-10 h-auto w-5/12 rounded-lg bg-gray-50 py-2 shadow-xl"
    >
      {step && id && (
        <>
          <div className="flex w-full flex-row px-4 justify-between">
            <div className="flex flex-row items-center">
              <step.icon className="h-4 w-4 text-gray-700" />
              <h1 className="text-sm ml-1 text-gray-700">{t(step.name)}</h1>
            </div>
            <button
              type="button"
              className="text-gray-700 focus:outline-none"
              onClick={onClearSelectItem}
            >
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mx-3 border-b p-1">
            <EditableTitleField
              name={`steps.${index}.name`}
              maxCharSize={19}
              onKeyDown={handleNameKeyDown}
            />
          </div>
          <div className="max-h-[65vh] w-full overflow-y-auto p-4 pt-2 md:max-h-[70vh]">
            <step.component
              key={`steps.${index}.component`}
              id={id}
              index={index}
            />
          </div>
        </>
      )}
    </Transition>
  );
}

export default FlowBuilderStepDetail;
