import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";
import { TimeoutMeta } from "src/containers/flow/Helpers";
import FlowStepTimeWindowDetail from "./FlowStepTimeWindowDetail";

interface FlowStepMaxWaitTimeDetailProps {
  step: FlowStepEdit;
  getStepById: (id: string) => FlowStepEdit;
}

export default function FlowStepMaxWaitTimeDetail({
  step,
  getStepById,
}: FlowStepMaxWaitTimeDetailProps) {
  const { t } = useTranslation();

  if (!step.has_max_wait_time) {
    return null;
  }

  const maxWaitTimeOption = TimeoutMeta.MAX_WAIT_TIME_OPTIONS.find(
    (at) => at.value === step.max_wait_time_unit
  );

  return (
    <div className="space-y-2">
      {step.next_step_alternate && (
        <p>
          {t(
            "flow-executions:steps-detail.timeout.if-hasnt-answered",
            "If the user hasn't answered in"
          )}{" "}
          <span className="text-gray-900 font-medium">
            {step.max_wait_time_amount}{" "}
            {maxWaitTimeOption
              ? t(maxWaitTimeOption.label)
              : step.max_wait_time_unit}
          </span>
          , {t("go-to", "go to")}{" "}
          <span className="text-gray-900 font-medium">
            {getStepById(step.next_step_alternate).name}
          </span>
        </p>
      )}

      {step.set_time_window && <FlowStepTimeWindowDetail step={step} />}
    </div>
  );
}
