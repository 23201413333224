import {
  FlowStepEdit,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import Linkify from "src/components/Linkify";
import MessageImage from "src/components/MessageImage";

interface FlowStepMessageDetailProps {
  step: FlowStepEdit;
  flowVersion: FlowVersionDetailRead;
  stepInfo: FlowStepEdit;
  getStepById: (id: string) => FlowStepEdit;
}

export default function FlowStepMessageDetail({
  step,
  flowVersion,
  stepInfo,
  getStepById,
}: FlowStepMessageDetailProps) {
  return (
    <div className="space-y-4">
      <div className="w-80 space-y-2 rounded-lg bg-whatsapp-outgoing py-2 px-3 text-left text-sm text-gray-900">
        {step.body_type === "image" && (
          <MessageImage
            source={
              step.body_file_url
                ? step.body_file_url
                : step.body_file
                ? step.body_file.url
                : ""
            }
            name={step.name}
            showMenu={false}
            isInbound={false}
          />
        )}
        {step.body && (
          <p className="whitespace-pre-wrap break-words">
            <Linkify message={step.body || ""} />
          </p>
        )}
      </div>
    </div>
  );
}
