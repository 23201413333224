import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon, RefreshIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { ExternalAppSyncStatus, HubspotApp } from "@hilos/types/private-schema";
import StateButton from "src/components/StateButton";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES } from "src/router/router";

interface SyncCompontentProps {
  connectedApp?: HubspotApp;
  setLoading: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}

export default function SyncComponent({
  connectedApp,
  setLoading,
  disabled,
}: SyncCompontentProps) {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const status: ExternalAppSyncStatus =
    connectedApp?.initial_sync_status || "NOT_SYNCED";

  async function updateSyncStatus(status: ExternalAppSyncStatus) {
    if (disabled) {
      return;
    }
    setSubmitted(false);
    setIsSubmitting(true);
    try {
      const responseHubspotSync = await axios.post(
        API_ROUTES.HUBSPOT_CONTACT_SYNC,
        {
          initial_sync_status: status,
        }
      );
      setSuccess(true);
      const responseHubspotSyncData: {
        status: ExternalAppSyncStatus;
        next_page_contact_sync?: string;
      } = responseHubspotSync.data;
      const updatedProviderFields = {
        ...connectedApp,
        initial_sync_status: responseHubspotSyncData.status,
        next_page_contact_sync: responseHubspotSyncData.next_page_contact_sync,
      } as HubspotApp;
      queryClient.setQueryData("hubspot_app", {
        ...updatedProviderFields,
      });
    } catch (err) {
      Sentry.captureException(err);
      setSuccess(false);
    } finally {
      setSubmitted(true);
      setIsSubmitting(false);
      setLoading(false);
    }
  }

  switch (status) {
    case "NOT_SYNCED":
    case "SYNC_PAUSED":
      return (
        <div className="flex justify-center">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              updateSyncStatus("SYNCYNG");
            }}
          >
            <StateButton
              isSubmitting={isSubmitting}
              submitted={submitted}
              success={success}
              btnClasses={`focus:ring-offset- inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 ${
                disabled
                  ? "opacity-50 hover:cursor-not-allowed"
                  : "hover:cursor-pointer"
              }`}
              submittingText={t(
                "integrations:hubspot.sync.submittingText",
                "Starting syncing..."
              )}
              successText={t(
                "integrations:hubspot.sync.successText",
                "Sync in progress"
              )}
              initialText={
                <>
                  <RefreshIcon
                    className="h-5 w-5 md:-ml-1 md:mr-2"
                    aria-hidden="true"
                  />
                  <span className="hidden md:block">{t("sync", "Sync")}</span>
                </>
              }
            />
          </form>
        </div>
      );
    case "SYNCYNG":
    case "SYNC_QUEUED":
      return (
        <div className="flex flex-col space-y-2">
          <div className="flex justify-center">
            <div className="focus:ring-offset- inline-flex items-center rounded-md border border-transparent bg-orange-400 px-6 py-3 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500">
              <RefreshIcon
                className="h-5 w-5 md:-ml-1 md:mr-2 animate-spin"
                aria-hidden="true"
              />
              <span className="hidden md:block">
                {t("integrations:hubspot.sync.in-progress", "Sync in progress")}
              </span>
            </div>
          </div>
          <div className="text-gray-600 font-sm">
            {t(
              "integrations:hubspot.sync.in-progress-help",
              "This process may take some time depending on how many contacts are in your HubSpot account"
            )}
          </div>
        </div>
      );
    case "SYNCED":
      return (
        <div className="flex justify-center">
          <div
            className={`inline-flex items-center rounded-full bg-green-600 px-6 py-3 text-sm font-medium text-white ${
              disabled && "opacity-50 hover:cursor-not-allowed"
            }`}
          >
            <CheckIcon
              className="h-5 w-5 md:-ml-1 md:mr-2"
              aria-hidden="true"
            />
            <span className="hidden md:block">{t("synced", "Synced")}</span>
          </div>
        </div>
      );
  }
}
