// @flow
import * as yup from "yup";

import { ERROR_MESSAGES } from "../../constants/Form";

export const getInitialValues = () => {
  return {
    field: "",
    comparison: "",
    value: "",
  };
};

export const schema = yup.object().shape({
  field: yup.string().required(ERROR_MESSAGES.required),
  comparison: yup.string().required(ERROR_MESSAGES.required),
  value: yup.string().required(ERROR_MESSAGES.required),
});

export const FIELDS = {
  field: {
    key: "field",
    // t("flows:filters.field", "Field"),
    label: "Field",
    help: "",
    placeholder: "source",
  },
  comparison: {
    key: "comparison",
    // t("flows:filters.comparison", "Comparison"),
    label: "Comparison",
    help: "All comparisons are case-insensitive.",
  },
  value: {
    key: "value",
    // t("flows:filters.value", "Value"),
    label: "Value",
    help: "",
    placeholder: "contacts.csv",
  },
};

export const COMPARISON_OPTIONS = [
  // t("flows:filters.is", "Is (equals)"),
  { label: "flows:filters.is", value: "exact" },
  // t("flows:filters.is-not", "Is not"),
  { label: "flows:filters.is-not", value: "!exact" },
  // t("flows:filters.contains", "Contains"),
  { label: "flows:filters.contains", value: "icontains" },
  // t("flows:filters.does-not-contain", "Does not contain"),
  { label: "flows:filters.does-not-contain", value: "!icontains" },
  // t("flows:filters.is-empty", "Is empty"),
  { label: "flows:filters.is-empty", value: "isnull" },
  { label: ">", value: "gt" },
  { label: ">=", value: "gte" },
  { label: "<", value: "lt" },
  { label: "<=", value: "lte" },
];

export const DATETIME_COMPARISON_OPTIONS = [
  // t("flows:filters.is", "Is (equals)"),
  { label: "flows:filters.is", value: "exact" },
  // t("flows:filters.is-not", "Is not"),
  { label: "flows:filters.is-not", value: "!exact" },
  // t("flows:filters.gte", "Greater than or equal"),
  { label: "flows:filters.gte", value: "gte" },
  // t("flows:filters.lte", "Less than or equal"),
  { label: "flows:filters.lte", value: "lte" },
];

export const WEEKDAY_OPTIONS = [
  // t("flows:filters.monday", "Monday [0]"),
  { label: "flows:filters.monday", value: "0" },
  // t("flows:filters.tuesday", "Tuesday [1]"),
  { label: "flows:filters.tuesday", value: "1" },
  // t("flows:filters.wednesday", "Wednesday [2]"),
  { label: "flows:filters.wednesday", value: "2" },
  // t("flows:filters.thursday", "Thursday [3]"),
  { label: "flows:filters.thursday", value: "3" },
  // t("flows:filters.friday", "Friday [4]"),
  { label: "flows:filters.friday", value: "4" },
  // t("flows:filters.saturday", "Saturday [5]"),
  { label: "flows:filters.saturday", value: "5" },
  // t("flows:filters.sunday", "Sunday [6]"),
  { label: "flows:filters.sunday", value: "6" },
];

export const GLOBAL_VARS = [
  // t("time", "Time"),
  { label: "time", value: "TIME" },
  // t("date", "Date"),
  { label: "date", value: "DATE" },
  // t("weekday", "Weekday"),
  { label: "weekday", value: "WEEKDAY" },
  // t("day", "Day"),
  { label: "day", value: "DAY" },
  // t("in-working-hours", "In working hours?"),
  { label: "in-working-hours", value: "IS_IN_WORKING_HOURS" },
];

export const BOOLEAN_VARS = ["IS_IN_WORKING_HOURS"];
