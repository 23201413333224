import { useCallback, useState } from "react";
import {
  ClipboardCheckIcon,
  ClipboardCopyIcon,
  XIcon,
} from "@heroicons/react/outline";

interface CopyToClipboardButtonProps {
  value: string;
  text?: string;
}

export default function CopyToClipboardButton({
  value,
  text,
}: CopyToClipboardButtonProps) {
  const [copied, setCopied] = useState(undefined);
  const [copyError, setCopyError] = useState(false);
  const handleCopyValue = useCallback(async (value) => {
    try {
      await navigator.clipboard.writeText(value);
    } catch (err) {
      console.log(err);
      setCopyError(true);
    } finally {
      setCopied(value);
      setTimeout(() => {
        setCopied(undefined);
      }, 1000 * 2);
    }
  }, []);
  return copied === value ? (
    <>
      {copyError ? (
        <button
          className="rounded-md  border-transparent bg-yellow-500 px-2 py-1.5 text-sm font-medium leading-4 text-white shadow-sm hover:bg-yellow-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
          type="button"
          disabled={true}
        >
          <XIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      ) : (
        <button
          className="rounded-md  border-transparent bg-green-600 px-2 py-1.5 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          type="button"
          disabled={true}
        >
          <ClipboardCheckIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      )}
    </>
  ) : (
    <button
      className="rounded-md border flex flex-inline bg-white px-2 py-1.5 text-sm font-medium leading-4 text-gray-400 shadow-sm hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
      type="button"
      onClick={(_) => handleCopyValue(value)}
    >
      <ClipboardCopyIcon className="h-4 w-4" aria-hidden="true" />
      {text}
    </button>
  );
}
