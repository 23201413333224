import { useTranslation } from "react-i18next";
import FlowTag from "src/components/FlowTag";
import SwitchField from "src/components/Form/SwitchField";
import {
  contactTagsColorClasses,
  conversationTagsColorClasses,
} from "src/components/Tags";
import { useContactTags, useConversationTags } from "src/hooks/useTags";

interface FlowBuilderStepTagProps {
  index: number;
}

function FlowBuilderStepTag({ index }: FlowBuilderStepTagProps) {
  const [t] = useTranslation();
  const { tags: availableContactTags } = useContactTags();
  const { tags: availableConversationTags } = useConversationTags();

  return (
    <div className="space-y-4">
      <FlowTag
        name={`steps.${index}.conversation_tags`}
        title={t(
          "flows:steps.tag.conversation-tags.description",
          "Add conversation tags"
        )}
        availableTags={availableConversationTags}
        {...conversationTagsColorClasses}
      />
      <FlowTag
        name={`steps.${index}.contact_tags`}
        title={t(
          "flows:steps.tag.contact-tags.description",
          "Add contact tags"
        )}
        availableTags={availableContactTags}
        {...contactTagsColorClasses}
      />
      <SwitchField
        name={`steps.${index}.append_tags`}
        label={t("flows:steps.tag.append-tags.label", "Append tags?")}
        help={t(
          "flows:steps.tag.append-tags.help",
          "If set these tags will be appended instead of replacing existing ones."
        )}
      />
    </div>
  );
}

export default FlowBuilderStepTag;
