import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import useFlowBuilderStore from "../hooks/useFlowBuilderStore";
import { useStepField } from "../hooks/useStepField";

interface FlowBuilderMaxAnswerAttemptsProps {
  id: string;
  index: number;
}

function FlowBuilderMaxAnswerAttempts({
  id,
  index,
}: FlowBuilderMaxAnswerAttemptsProps) {
  const [t] = useTranslation();
  const hasPendingRefresh = useRef(false);
  const [hasMaxAnswerAttempts] = useStepField({
    index,
    name: "has_max_answer_attempts",
  });
  const [answerFailedNextStep] = useStepField({
    index,
    name: "answer_failed_next_step",
  });
  const { onFlowRefresh } = useFlowBuilderStore();

  const handleChangeHasMaxAnswerAttempts = useCallback(() => {
    hasPendingRefresh.current = true;
  }, []);

  useEffect(() => {
    if (hasPendingRefresh.current) {
      onFlowRefresh(id);
      hasPendingRefresh.current = false;
    }
  }, [hasMaxAnswerAttempts, id, onFlowRefresh]);

  return (
    <div className="border-t border-gray-200 pt-2 space-y-4">
      <SwitchField
        disabled={hasMaxAnswerAttempts && !!answerFailedNextStep}
        name={`steps.${index}.has_max_answer_attempts`}
        label={t(
          "flows:steps.question.has-max-answer-attempts.label",
          "Keep track of answer attempts?"
        )}
        help={t(
          "flows:steps.question.has-max-answer-attempts.help",
          "Set how many attempts a user can make and what happens after."
        )}
        onChange={handleChangeHasMaxAnswerAttempts}
      />

      {hasMaxAnswerAttempts && (
        <TextInputField
          name={`steps.${index}.max_answer_attempts`}
          placeholder="2"
          type="number"
          label={t(
            "flows:steps.question.max-answer-attempts.label",
            "Allow this many answer attempts"
          )}
        />
      )}
    </div>
  );
}

export default FlowBuilderMaxAnswerAttempts;
