import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";
import { ArrowSmRightIcon, MailIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import { classNames } from "src/Helpers";
import PasswordField from "../../components/Form/PasswordField";
import TextInputField from "../../components/Form/TextInputField";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import { ERROR_MESSAGES } from "../../constants/Form";
import { buildRoute } from "../../router/router";

const schema = yup.object().shape({
  email: yup
    .string()
    .email(ERROR_MESSAGES.email)
    .required(ERROR_MESSAGES.required),
  password1: yup
    .string()
    .min(8, ERROR_MESSAGES.min)
    .required(ERROR_MESSAGES.required),
  password2: yup
    .string()
    .min(8, ERROR_MESSAGES.min)
    .required(ERROR_MESSAGES.required),
  default_language: yup.string().required(ERROR_MESSAGES.required),
});

export const SignupForm = ({
  onSubmit,
  email = "",
  backendError,
  backendValidationErrors,
  success,
  submitted,
}) => {
  const { t, i18n } = useTranslation();
  const initialValues = {
    email,
    password1: "",
    password2: "",
    default_language: i18n.languages[0],
  };

  const FIELDS = {
    email: {
      key: "email",
      label: t("components:input.email.label"),
      placeholder: t("components:input.email.placeholder"),
    },
    password1: {
      key: "password1",
      label: t("components:input.new-password.label"),
      placeholder: t("components:input.new-password.placeholder"),
      help: t("components:input.new-password.help"),
    },
    password2: {
      key: "password2",
      label: t("components:input.repeat-password.label"),
      placeholder: t("components:input.repeat-password.placeholder"),
    },
    default_language: {
      key: "default_language",
      label: t("components:input.default-language.label"),
    },
  };

  const LANG_OPTIONS = [
    {
      key: "en",
      label: <img src="https://api.hilos.io/static/flags/us.gif" alt="en" />,
    },
    {
      key: "es",
      label: <img src="https://api.hilos.io/static/flags/mx.gif" alt="es" />,
    },
  ];

  const handleChange = useCallback((formik, option) => {
    formik.setFieldValue(FIELDS.default_language.key, option);
    i18n.changeLanguage(option);
  }, []);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnMount={false}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit} className="space-y-4">
          <APIErrors
            APIError={backendError}
            APIValidationErrors={backendValidationErrors}
            fieldTranslations={FIELDS}
          />

          <TextInputField
            placeholder={FIELDS.email.placeholder}
            label={FIELDS.email.label}
            name={FIELDS.email.key}
            icon={MailIcon}
            type="email"
            disabled={email !== ""}
          />

          <PasswordField
            placeholder={FIELDS.password1.placeholder}
            label={FIELDS.password1.label}
            name={FIELDS.password1.key}
            help={FIELDS.password1.help}
          />

          <PasswordField
            placeholder={FIELDS.password2.placeholder}
            label={FIELDS.password2.label}
            name={FIELDS.password2.key}
          />
          <RadioGroup
            value={formik.values[FIELDS.default_language.key]}
            onChange={(val) => handleChange(formik, val)}
          >
            <RadioGroup.Label className="sr-only">
              Phone Number Options
            </RadioGroup.Label>
            <div className="-space-y-px flex gap-2 rounded-md bg-white">
              {LANG_OPTIONS.map((lang) => (
                <RadioGroup.Option
                  key={lang.key}
                  value={lang.key}
                  className={({ checked }) =>
                    classNames(
                      checked
                        ? "z-10 border-indigo-200 bg-indigo-50"
                        : "border-gray-200",
                      "rounded-md relative flex cursor-pointer border py-2 px-4 focus:outline-none"
                    )
                  }
                >
                  {({ active, checked }) => (
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          checked
                            ? "bg-indigo-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-indigo-600" : "",
                          "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <span className="ml-3 flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(
                            checked ? "text-indigo-900" : "text-gray-900",
                            "block text-xl"
                          )}
                        >
                          {lang.label}
                        </RadioGroup.Label>
                      </span>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>

          <div>
            <StateButton
              isSubmitting={formik.isSubmitting}
              disabled={
                formik.errors &&
                Object.getOwnPropertyNames(formik.errors).length > 0
              }
              submittingText={t("sending")}
              initialText={
                <>
                  {t("sign-up")}
                  <ArrowSmRightIcon
                    className="ml-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </>
              }
              successText={t("redirecting", "Redirecting...")}
              submitted={submitted}
              success={success}
            />
          </div>

          <div className="mt-3 text-left text-xs text-gray-400">
            <Trans i18nKey="login:already-have-an-account">
              Already have an account?{" "}
              <Link
                to={buildRoute("login")}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Log in.
              </Link>
            </Trans>
          </div>
        </form>
      )}
    </Formik>
  );
};
