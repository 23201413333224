import { useTranslation } from "react-i18next";
import {
  AnnotationIcon,
  ClipboardListIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { FormikProps } from "formik";
import * as meta from "./WhatsAppTemplateMeta";

interface WhatsAppTemplateFormStepNavProps {
  formik: FormikProps<any>;
  currentStep: string;
  setCurrentStep: (step: string) => void;
}

export default function WhatsAppTemplateFormStepNav({
  formik,
  currentStep,
  setCurrentStep,
}: WhatsAppTemplateFormStepNavProps) {
  const { t } = useTranslation();
  const stepHasErrors = (step: string) => {
    let errorFields = {
      attrs: [
        formik.errors[meta.FIELDS.name.key],
        formik.errors[meta.FIELDS.category.key],
        formik.errors[meta.FIELDS.language.key],
      ],
      content: [formik.errors[meta.FIELDS.components.key]],
      end: [formik.errors[meta.FIELDS.examples.key]],
    };
    if (formik.errors && errorFields[step].some((x) => x)) {
      return true;
    }
    return false;
  };

  const steps = [
    {
      name: t("details", "Details"),
      icon: <InformationCircleIcon className="mr-2 h-5 w-5" />,
      stepName: "attrs",
      current: currentStep === "attrs",
      hasErrors: stepHasErrors("attrs"),
      available: true,
    },
    {
      name: t("content", "Content"),
      icon: <ClipboardListIcon className="mr-2 h-5 w-5" />,
      stepName: "content",
      current: currentStep === "content",
      hasErrors: stepHasErrors("content"),
      available: true,
    },
    {
      name: t("examples", "Examples"),
      icon: <AnnotationIcon className="mr-2 h-5 w-5" />,
      stepName: "end",
      hasErrors: stepHasErrors("end"),
      current: currentStep === "end",
      available: formik.values[meta.FIELDS.category.key] !== "AUTHENTICATION",
    },
  ];

  return (
    <nav aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps
          .filter((step) => step.available)
          .map((step) => (
            <li key={step.name} className="md:flex-1">
              {step.hasErrors ? (
                <button
                  type="button"
                  onClick={(_) => setCurrentStep(step.stepName)}
                  className="group flex w-full flex-col border-l-4 border-yellow-500 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                  aria-current="step"
                >
                  <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-yellow-500">
                    {step.icon} {step.name}
                  </span>
                </button>
              ) : (
                <>
                  {step.current ? (
                    <button
                      type="button"
                      onClick={(_) => setCurrentStep(step.stepName)}
                      className="group flex w-full flex-col border-l-4 border-blue-600 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                      aria-current="step"
                    >
                      <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-blue-600">
                        {step.icon} {step.name}
                      </span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={(_) => setCurrentStep(step.stepName)}
                      className="group flex w-full flex-col border-l-4 border-gray-200 pl-4 pb-2 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                    >
                      <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-gray-600">
                        {step.icon} {step.name}
                      </span>
                    </button>
                  )}
                </>
              )}
            </li>
          ))}
      </ol>
    </nav>
  );
}
