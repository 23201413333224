import { useTranslation } from "react-i18next";
import SecretInputField from "src/components/Form/SecretInputField";
import TextInputField from "src/components/Form/TextInputField";
import * as meta from "./MetaCloudAPISettingsMeta";

export default function MetaCloudAPIForm() {
  const { t } = useTranslation();

  return (
    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3">
          <TextInputField
            placeholder={meta.FIELDS.app_id.placeholder}
            label={t(meta.FIELDS.app_id.label)}
            name={`settings.${meta.FIELDS.app_id.key}`}
            help={t(
              "channel:cloud-api.fields.app_id.help",
              meta.FIELDS.app_id.help
            )}
            type="text"
          />
        </div>
        <div className="col-span-3">
          <TextInputField
            placeholder={meta.FIELDS.phone_number_id.placeholder}
            label={t(meta.FIELDS.phone_number_id.label)}
            name={`settings.${meta.FIELDS.phone_number_id.key}`}
            help={t(
              "channel:cloud-api.fields.phone_number_id.help",
              meta.FIELDS.phone_number_id.help
            )}
            type="text"
          />
        </div>
        <div className="col-span-3">
          <TextInputField
            placeholder={meta.FIELDS.whatsapp_business_account_id.placeholder}
            label={t(meta.FIELDS.whatsapp_business_account_id.label)}
            name={`settings.${meta.FIELDS.whatsapp_business_account_id.key}`}
            help={t(
              "channel:cloud-api.fields.waba_id.help",
              meta.FIELDS.whatsapp_business_account_id.help
            )}
            type="text"
          />
        </div>
        <div className="col-span-3">
          <SecretInputField
            name={`settings.${meta.FIELDS.app_token.key}`}
            readOnlyName={`settings.${meta.FIELDS.app_token.readOnlyKey}`}
            placeholder={meta.FIELDS.app_token.placeholder}
            label={t(meta.FIELDS.app_token.label)}
            help={t(
              "channel:cloud-api.fields.app_token.help",
              meta.FIELDS.app_token.help
            )}
          />
        </div>
        <div className="col-span-3">
          <SecretInputField
            name={`settings.${meta.FIELDS.access_token.key}`}
            readOnlyName={`settings.${meta.FIELDS.access_token.readOnlyKey}`}
            placeholder={meta.FIELDS.access_token.placeholder}
            label={t(meta.FIELDS.access_token.label)}
            help={t(
              "channel:cloud-api.fields.access_token.help",
              meta.FIELDS.access_token.help
            )}
          />
        </div>
      </div>
    </div>
  );
}
