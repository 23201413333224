import { useTranslation } from "react-i18next";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/outline";

function FlowAdvancedOptionsDisclosure({
  children,
  defaultOpen = false,
  buttonText = "advanced-options",
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex min-w-full flex-1 grow items-center justify-between text-xs font-medium uppercase tracking-wide text-gray-500 py-4">
              {t(buttonText)}
              <ChevronRightIcon
                className={`h-4 w-4 text-gray-500 ${
                  open
                    ? "rotate-90 transform transition-transform duration-100 ease-in-out"
                    : ""
                }`}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-in"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="space-y-4 text-sm text-gray-500">
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}

export default FlowAdvancedOptionsDisclosure;
