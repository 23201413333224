import { useCallback, useEffect } from "react";
import { CodeIcon, PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import { FieldArray } from "formik";
import TextInputField from "../../components/Form/TextInputField";
import FieldAddVariableContainer from "../Form/FieldAddVariableContainer";
import InputActionButton from "../Form/InputActionButton";
import { HTTPFormProps } from "./HTTPForm";
import { HTTPFormMeta } from "./Meta";

export default function HTTPFormHeaders({
  formik,
  formPrefix,
  formValues,
  availableVariables,
}: HTTPFormProps) {
  const maybeUpdateBodyType = useCallback(
    (headerKey: string, headerValue: string) => {
      if (!headerValue) {
        return;
      }
      console.log(headerKey, headerValue);
      if (headerKey.toLowerCase() === "content-type") {
        const bodyTypeFromHeader = HTTPFormMeta.BODY_TYPES.find(
          (type) => type.value === headerValue.toLowerCase()
        );
        if (bodyTypeFromHeader) {
          console.log("setting val", bodyTypeFromHeader);
          formik.setFieldValue(
            `${formPrefix}.${HTTPFormMeta.FIELDS.body_type.key}`,
            bodyTypeFromHeader.value
          );
        }
      }
    },
    [formPrefix, formik]
  );

  useEffect(() => {
    formValues.headers.forEach((header) => {
      maybeUpdateBodyType(header.key, header.value);
    });
  }, [formValues.headers, maybeUpdateBodyType]);

  return (
    <FieldArray
      name={`${formPrefix}.headers`}
      render={(arrayHelpers) => (
        <>
          <ul className="mt-2">
            {formValues &&
              formValues.headers &&
              formValues.headers.length > 0 &&
              formValues.headers.map((header, cIdx) => (
                <div key={cIdx} className="my-2 flex items-start">
                  <div className="grid grow grid-cols-3 gap-2">
                    <div>
                      <TextInputField
                        name={`${formPrefix}.headers.${cIdx}.${HTTPFormMeta.FIELDS.headers.key.key}`}
                        placeholder={
                          HTTPFormMeta.FIELDS.headers.key.placeholder
                        }
                        type="text"
                      />
                    </div>
                    <div className="col-span-2">
                      <FieldAddVariableContainer
                        formik={formik}
                        fieldName={`${formPrefix}.headers.${cIdx}.${HTTPFormMeta.FIELDS.headers.value.key}`}
                        currentValue={
                          formValues.headers[cIdx][
                            HTTPFormMeta.FIELDS.headers.value.key
                          ]
                        }
                        availableVariables={availableVariables}
                      >
                        {(setShowVariableSelect) => (
                          <TextInputField
                            name={`${formPrefix}.headers.${cIdx}.${HTTPFormMeta.FIELDS.headers.value.key}`}
                            placeholder={
                              HTTPFormMeta.FIELDS.headers.value.placeholder
                            }
                            type="text"
                            action={
                              <InputActionButton
                                onClick={() => setShowVariableSelect(true)}
                                type="button"
                              >
                                <CodeIcon className="h-5 w-5" />
                              </InputActionButton>
                            }
                          />
                        )}
                      </FieldAddVariableContainer>
                    </div>
                  </div>
                  <div className="ml-4 grow-0">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={(_) => arrayHelpers.remove(cIdx)}
                    >
                      <TrashIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              ))}
          </ul>

          <div className="mt-2">
            <button
              type="button"
              className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
              onClick={(_) => {
                arrayHelpers.push({ key: "", value: "" });
              }}
            >
              <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              Add Header
            </button>
          </div>
        </>
      )}
    ></FieldArray>
  );
}
