import { useEffect, useRef } from "react";

export default function useCleanTimeout() {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [timeout]);

  return timeout;
}
