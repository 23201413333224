import { useCallback, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { UploadedAccountFile } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

interface useFileHelpersProps {
  url?: string;
}

export default function useFileHelpers({ url }: useFileHelpersProps) {
  const uploadedFileId = useMemo(() => {
    if (!url?.includes("https://api.hilos.io")) {
      return undefined;
    }
    const urlParts = url.split("/");
    return urlParts.splice(-1)[0];
  }, [url]);

  const [uploadedFile, setUploadedFile] = useState<UploadedAccountFile | null>(
    null
  );
  const deleteUploadedFile = useCallback(async () => {
    if (!uploadedFile) {
      return;
    }
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.PUBLIC_FILE_DETAIL, {
          ":id": uploadedFile.id,
        })
      );
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }, [uploadedFile]);

  const uploadFile = useCallback(async (formData: FormData) => {
    const { data } = await axios.post<UploadedAccountFile>(
      API_ROUTES.PUBLIC_FILE_UPLOAD,
      formData
    );

    setUploadedFile(data);
    return data.url;
  }, []);

  const getUploadedFile = useCallback(async () => {
    if (uploadedFileId) {
      const { data } = await axios.get(
        buildAPIRoute(API_ROUTES.PUBLIC_FILE_DETAIL, {
          ":id": uploadedFileId,
        })
      );
      setUploadedFile(data);
    }
  }, [uploadedFileId]);

  useEffect(() => {
    getUploadedFile();
  }, []);

  return { deleteUploadedFile, uploadedFile, setUploadedFile, uploadFile };
}
