import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { CurrencyDollarIcon } from "@heroicons/react/outline";
import { capitalize } from "src/Helpers";
import SubscriptionV2CheckoutModalForm from "./SubscriptionV2CheckoutModalForm";

interface SubscriptionV2CheckoutModalProps {
  selectedPlan: string;
  handleHide: (x: boolean) => void;
}

export default function SubscriptionV2CheckoutModal({
  selectedPlan,
  handleHide,
}: SubscriptionV2CheckoutModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <Transition.Root show={selectedPlan !== null} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={handleHide}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="flex text-gray-800 items-center font-bold">
                  <div className="h-10 w-10 rounded-full flex items-center justify-center text-hilos mr-4 bg-red-100">
                    <CurrencyDollarIcon className="h-6 w-6" />
                  </div>
                  <div>
                    <div className="text-lg">
                      {t(
                        "settings:billing.v2.checkout.title",
                        "Awesome! Let's go with the {{selectedPlan}} plan.",
                        { selectedPlan: capitalize(selectedPlan) }
                      )}
                    </div>
                    <div className="text-sm text-gray-800 font-normal">
                      {t(
                        "settings:billing.v2.checkout.description",
                        "We'll charge you a prorated amount now."
                      )}
                    </div>
                  </div>
                </div>

                <SubscriptionV2CheckoutModalForm selectedPlan={selectedPlan} />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
