import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import axios from "axios";
import { BroadcastStatusEnum } from "@hilos/types/private-schema";
import StateControlButton from "src/components/StateControlButton";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

interface BroadcastControlsButtonsProps {
  broadcastId: string;
  broadcastStatus?: BroadcastStatusEnum;
  onSuccess: () => void;
}

export default function BroadcastControlsButtons({
  broadcastId,
  broadcastStatus,
  onSuccess,
}: BroadcastControlsButtonsProps) {
  const { t } = useTranslation();

  const handleChangeStatus = useCallback(
    async (data) => {
      return await axios.patch(
        buildAPIRoute(API_ROUTES.MESSAGE_BLAST_STATUS, {
          ":id": broadcastId,
        }),
        data
      );
    },
    [broadcastId]
  );

  return (
    <>
      {broadcastStatus === "AWAITING_CONFIRMATION" && (
        <>
          <StateControlButton
            btnClasses="inline-flex items-center rounded-md border border-green-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-green-600 shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            onClick={() => handleChangeStatus({ status: "CONFIRMED" })}
            submittingText={t("confirming", "Confirming...")}
            successText={t("confirmed", "Confirmed")}
            initialText={
              <>
                <CheckIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />{" "}
                {t("confirm", "Confirm")}
              </>
            }
            onSuccess={onSuccess}
          />
          <StateControlButton
            btnClasses="inline-flex items-center rounded-md border border-yellow-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-yellow-600 shadow-sm hover:bg-yellow-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
            onClick={() => handleChangeStatus({ status: "CANCELED" })}
            submittingText={t("canceling", "Canceling...")}
            successText={t("canceled", "Canceled")}
            initialText={
              <>
                <XIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />{" "}
                {t("cancel", "Cancel")}
              </>
            }
            onSuccess={onSuccess}
          />
        </>
      )}
    </>
  );
}
