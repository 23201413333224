import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BanIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import SelectorField from "src/components/Form/SelectorField";
import { hasItems } from "src/helpers/utils";
import { STEP_TYPES } from "../../../constants/steps";

interface FlowBuilderStepGoToProps {
  id: string;
  index: number;
}

function formatOptionLabel(data) {
  const RenderIcon =
    (data.type && STEP_TYPES[data.type] && STEP_TYPES[data.type].icon) ||
    BanIcon;

  return (
    <div className="flex flex-nowrap -ml-4 py-1 flex-row justify-start items-center">
      <RenderIcon aria-hidden="true" className="h-4 w-4" />
      <div className="ml-2 font-medium text-sm capitalize">{data.label}</div>
    </div>
  );
}

function FlowBuilderStepGoTo({ id, index }: FlowBuilderStepGoToProps) {
  const [t] = useTranslation();

  const { values } = useFormikContext<FlowData>();

  const options = useMemo(() => {
    if (!hasItems(values.steps)) {
      return [];
    }

    return values.steps.reduce((nextOptions, step) => {
      if (
        step.id !== id &&
        step.step_type !== "GO_TO" &&
        step.next_step_default !== id &&
        step.next_step_alternate !== id &&
        step.answer_failed_next_step !== id &&
        (!hasItems(step.next_steps_for_options) ||
          !step.next_steps_for_options.includes(id))
      ) {
        nextOptions.push({
          value: step.id,
          label: step.name,
          type: step.step_type,
        });
      }
      return nextOptions;
    }, [] as any[]);
  }, [values, id]);

  return (
    <div className="space-y-4">
      <SelectorField
        name={`steps.${index}.next_step_default`}
        label={t(
          "flows:steps.go-to.next-step-default.label",
          "Select a step available to go"
        )}
        options={options}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
}

export default FlowBuilderStepGoTo;
