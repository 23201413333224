import { KeyboardEventHandler } from "react";
import { PencilIcon } from "@heroicons/react/outline";
import { useField } from "formik";

interface EditableTitleFieldProps {
  name: string;
  maxCharSize: number;
  className?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

function EditableTitleField({
  name,
  maxCharSize,
  className = "bg-gray-50 pr-2 text-lg font-medium outline-none",
  onKeyDown,
}: EditableTitleFieldProps) {
  const [field, meta] = useField({ name });

  const error = meta.touched && meta.error;

  return (
    <div>
      <div className="group flex flex-row items-center">
        <input
          id={name}
          {...field}
          value={field.value || ""}
          size={Math.min(
            Math.max((field.value && field.value.length) || 0, 1),
            maxCharSize
          )}
          className={className}
          onKeyDown={onKeyDown}
        />
        <label htmlFor={name} className="h-full w-full">
          <PencilIcon className="pointer-events-none h-4 w-4" />
        </label>
      </div>
      {error && <div className="text-xs text-red-500">{error}</div>}
    </div>
  );
}

export default EditableTitleField;
