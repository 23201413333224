import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";
import { BroadcastFormExtraFormikFields } from "@hilos/containers/broadcasts/BroadcastForm";
import { MessageBlastPublicEdit } from "@hilos/types/private-schema";

interface BroadcastFormStepErrorsNoticeProps {
  formik: FormikProps<MessageBlastPublicEdit & BroadcastFormExtraFormikFields>;
}

export default function BroadcastFormStepErrorsNotice({
  formik,
}: BroadcastFormStepErrorsNoticeProps) {
  const { t } = useTranslation();
  return (
    <>
      {formik.errors && Object.getOwnPropertyNames(formik.errors).length > 0 && (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                {t("broadcasts:missing-fields", "Missing fields")}
              </h3>
              <div className="text-sm text-yellow-700">
                <p>
                  {t(
                    "broadcasts:missing-fields-details",
                    "There are fields with missing data, complete them to send this broadcast."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
