import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { ShareIcon, TrashIcon } from "@heroicons/react/outline";
import { TFunction } from "i18next";
import { Condition } from "@hilos/types/flow";
import { HilosVariableData } from "@hilos/types/hilos";
import SelectorField from "src/components/Form/SelectorField";
import {
  BOOLEAN_COMPARISON_OPTIONS,
  COMPARISON_OPTIONS,
  DATETIME_COMPARISON_OPTIONS,
} from "../../../constants/steps/conditional";
import { GLOBAL_VARIABLES } from "../../../helpers/steps";
import {
  OptionVariable,
  OptionVariableGroup,
} from "../../../hooks/useVariables";
import FlowBuilderStepConditionalField from "./FlowBuilderStepConditionalField";

export interface SelectVariableParams {
  name: string;
  variable: HilosVariableData | null;
}

interface FlowBuilderStepConditionalConditionProps {
  name: string;
  options: OptionVariableGroup[];
  condition: Condition;
  conditionIndex: number;
  onGetVariable: (id: string) => HilosVariableData | null;
  onSelectVariable: (params: SelectVariableParams) => void;
  onRemoveCondition: () => void;
}

const formatGroupLabel =
  (t: TFunction<"translation", undefined, "translation">) =>
  ({ icon: RenderIcon, label }) => {
    return (
      <div className="flex flex-nowrap py-1">
        <div className="rounded-full bg-hilos-light p-1">
          <RenderIcon
            className={`aria-hidden="true" h-4 w-4 text-hilos ${
              RenderIcon === ShareIcon ? "rotate-90" : ""
            }`}
          />
        </div>
        <div className="ml-2 self-center text-sm capitalize text-neutral-light">
          {t(label)}
        </div>
      </div>
    );
  };

function FlowBuilderStepConditionalCondition({
  name,
  options,
  condition,
  onGetVariable,
  onSelectVariable,
  onRemoveCondition,
  conditionIndex,
}: FlowBuilderStepConditionalConditionProps) {
  const { t } = useTranslation();
  const comparisonOptions = useMemo(() => {
    const isGlobalVariable = GLOBAL_VARIABLES.find(
      (variable) => variable.id === condition.field
    );
    if (isGlobalVariable) {
      if (isGlobalVariable.data_type === "bool") {
        return BOOLEAN_COMPARISON_OPTIONS;
      }
      return DATETIME_COMPARISON_OPTIONS;
    }

    return COMPARISON_OPTIONS;
  }, [condition.field]);

  const currentOption = useMemo<OptionVariable>(() => {
    const variable = onGetVariable(condition.field);

    if (!variable) {
      return { value: "", label: "", variable: null };
    }

    return {
      value: variable.id,
      label: variable.name,
      variable,
    };
  }, [condition.field, onGetVariable]);

  const handleChange = useCallback(
    (nextOption: SingleValue<OptionVariable>) => {
      const variable = (nextOption && nextOption.variable) || null;
      onSelectVariable({ name, variable });
    },
    [name, onSelectVariable]
  );

  return (
    <div className="flex items-start pt-2 pb-1 pl-4">
      <div className="grid grow grid-cols-3 grid-rows-2 gap-2">
        <div className="col-span-3 flex items-center">
          {conditionIndex === 0 ? (
            <p className="text-gray-700 font-semibold">
              {t("flows:steps.conditional.where", "Where")}
            </p>
          ) : (
            <p className="text-gray-700 font-semibold">
              {t("flows:steps.conditional.and", "and")}
            </p>
          )}
          <div className="w-full ml-3">
            <Select
              value={currentOption}
              options={options}
              onChange={handleChange}
              getOptionLabel={(option) => option.label}
              // @ts-ignore
              formatGroupLabel={formatGroupLabel(t)}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              menuPlacement="auto"
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: "#ddd",
                  "&:hover": {
                    borderColor: "#ddd",
                  },
                  boxShadow: "none",
                  "*": {
                    boxShadow: "none !important",
                  },
                }),
                option: (base, { isFocused, isSelected }) => {
                  return {
                    ...base,
                    wordBreak: "break-all",
                    backgroundColor: isSelected
                      ? "#d54466"
                      : isFocused
                      ? "#d54466"
                      : undefined,
                    color: isSelected ? "#fff" : isFocused ? "#fff" : undefined,
                    fontSize: "small",
                    paddingLeft: "30px",
                  };
                },
              }}
            />
          </div>
        </div>
        <div className="col-span-1">
          <SelectorField
            name={`${name}.comparison`}
            options={comparisonOptions}
            // @ts-ignore
            getOptionLabel={(option) => t(option.label)}
          />
        </div>
        <div className="col-span-2 flex items-center justify-between">
          <FlowBuilderStepConditionalField
            name={`${name}.value`}
            field={condition.field}
            comparison={condition.comparison}
          />
          <div className="ml-1">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              onClick={onRemoveCondition}
            >
              <TrashIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlowBuilderStepConditionalCondition;
