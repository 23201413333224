import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "@apollo/client/utilities";
import { PlayIcon } from "@heroicons/react/outline";
import axios from "axios";
import { getIn, useFormikContext } from "formik";
import { useStepField } from "src/containers/flow/builder/hooks/useStepField";
import SelectorField from "src/components/Form/SelectorField";
import StateButton from "src/components/StateButton";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useHubspotApp from "src/hooks/useHubSpot";
import { useHubspotContactProperties } from "src/hooks/useHubspotContactProperties";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import FlowBuilderStepActionResponse from "../../action/FlowBuilderStepActionResponse";
import HubspotCtaInstallApp from "./HubspotCtaInstallApp";

interface FlowBuilderStepHubspotContactRetreiveProps {
  index: number;
}

function FlowBuilderStepHubspotContactRetreive({
  index,
}: FlowBuilderStepHubspotContactRetreiveProps) {
  const { t } = useTranslation();
  const { data: hubspotApp } = useHubspotApp();
  const { data } = useHubspotContactProperties();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);

  const [hubspotContactProperities] = useStepField({
    index,
    name: "custom_properties",
  });
  const { values, setFieldValue } = useFormikContext();
  const timeout = useCleanTimeout();

  const isInstalled = useMemo(() => !!hubspotApp?.is_connected, [hubspotApp]);
  const hubspotContactPropertiesOptions = useMemo(
    () =>
      data
        .map((property) => ({
          label: property.label,
          value: property.name,
        }))
        .sort((a, b) => a.label.length - b.label.length),
    [data]
  );

  const handleTestAction = useCallback(async () => {
    setIsSubmitting(true);
    const originalStep = getIn(values, `steps.${index}`, {});
    const currentStep = cloneDeep(originalStep);
    try {
      const response = await axios.post(
        buildAPIRoute(API_ROUTES.HUBSPOT_CONTACT_GET_STEP_TEST),
        {
          custom_properties: hubspotContactProperities,
        }
      );
      if (response.status === 201 && typeof response.data === "object") {
        if (!currentStep["action_responses"]) {
          currentStep["action_responses"] = [];
        }

        currentStep["action_responses"].unshift({
          created_on: new Date(),
          data: response.data,
        });
      }
      setSuccess(true);
      setFieldValue(`steps.${index}`, currentStep);
    } catch (error) {
      setSuccess(false);
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setSubmitted(true);
      timeout.current = setTimeout(() => {
        setSuccess(false);
        setSubmitted(false);
      }, 2000);
    }
  }, [hubspotContactProperities, index, setFieldValue, timeout, values]);

  return (
    <div className="mt-2">
      {!isInstalled && (
        <div className="my-4">
          <HubspotCtaInstallApp />
        </div>
      )}
      <SelectorField
        name={`steps.${index}.custom_properties`}
        label={t(
          "flows:steps.hubspot-contact-retreive.custom-properties.label",
          "Custom properties"
        )}
        help={t(
          "flows:steps.hubspot-contact-retreive.custom-properties.help",
          "Select the custom properties you want to retrieve from HubSpot"
        )}
        options={hubspotContactPropertiesOptions}
        isMulti={true}
      />
      <StateButton
        isSubmitting={isSubmitting}
        submitted={submitted}
        success={success}
        submittingText={t(
          "flows:steps.hubspot-contact-retreive.submitting",
          "Getting example..."
        )}
        successText={t(
          "flows:steps.hubspot-contact-retreive.get-example-success",
          "Example retrieved"
        )}
        initialText={
          <>
            <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            {t(
              "flows:steps.hubspot-contact-retreive.get-example",
              "Get example"
            )}
          </>
        }
        btnClasses="mt-4 inline-flex w-full items-center justify-center rounded-md border border-green-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-green-500 shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-500"
        btnClassesSuccess="mt-4 inline-flex w-full items-center justify-center rounded-md border border-green-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-green-500 shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-500"
        btnClassesError="mt-4 inline-flex w-full items-center justify-center rounded-md border border-red-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-red-500 shadow-sm hover:bg-red-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-red-500"
        onClick={handleTestAction}
      />
      <FlowBuilderStepActionResponse name={`steps.${index}.action_responses`} />
    </div>
  );
}

export default FlowBuilderStepHubspotContactRetreive;
