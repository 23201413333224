import {
  DragEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Edge,
  Node,
  NodeMouseHandler,
  getConnectedEdges,
  useOnSelectionChange,
  useReactFlow,
  useStoreApi,
} from "reactflow";
import { FieldArrayRenderProps, getIn, useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import { FlowData, FlowNodeData, FlowStepData } from "@hilos/types/flow";
import { HilosVariableData } from "@hilos/types/hilos";
import { hasItems } from "src/helpers/utils";
import { getStepWithoutVariable } from "src/helpers/variables";
import { BASE_STEP_NAMES } from "../constants/flow";
import { STEP_TYPES_WITHOUT_NEXT, StepTypes } from "../constants/steps";
import {
  getHierarchyPointNodes,
  getInitialStepValues,
  getNextStepId,
  getNodesFromData,
  hasMultipleNextSteps,
  isEqualId,
  updateNextStepId,
  updateStepData,
} from "../utils";
import useFlowBuilderStore from "./useFlowBuilderStore";
import { SelectedItemState } from "./useFlowLayout";

interface UseFlowBuilderParams {
  nodes: Node<FlowNodeData>[];
  edges: Edge[];
  helpers: FieldArrayRenderProps;
  selectedItem: SelectedItemState;
  onSelectItem: (selectedItem: SelectedItemState) => void;
  onUpdateTransitions: (nodes: Node<FlowNodeData>[], edges: Edge[]) => void;
}

interface InsertConnectionParams {
  id: string;
  isEdge: boolean;
  type: StepTypes;
}

interface AddConnectionParams {
  id: string;
  type: StepTypes;
}

type UpdateStepsDataFn = (params: {
  type?: "add_step" | "insert_step" | "move_step" | "move_path";
  step: Partial<FlowStepData> & Pick<FlowStepData, "id" | "step_type">;
  toOptionId?: string | null;
  toTargetStepId?: string | null;
  toSourceStepId?: string | null;
  fromTargetStepId?: string | null;
  fromSourceStepId?: string | null;
}) => void;

function useFlowBuilder({
  nodes,
  edges,
  helpers,
  selectedItem,
  onSelectItem,
  onUpdateTransitions,
}: UseFlowBuilderParams) {
  const store = useStoreApi();
  const [t] = useTranslation();
  const isAddingStep = useRef(false);
  const isDeletingStepRef = useRef(false);
  const handleDeleteStepRef = useRef<((id: string) => void) | null>(null);
  const hasNodesInitializedRef = useRef(false);
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FlowData>();
  const { getNode, getEdge, getEdges, getNodes } = useReactFlow();
  const {
    hasPendingRefresh,
    nextStepIdToDelete,
    nextSelectedNodeId,
    onNodeDragging,
    onSetStepToDelete,
    onFlowRefresh,
    onCompleteFlowRefresh,
  } = useFlowBuilderStore();

  useOnSelectionChange({
    onChange: ({ nodes: selectedNodes, edges: selectedEdges }) => {
      onSelectItem(selectedNodes[0] || selectedEdges[0] || null);
    },
  });

  const onUpdateStepsData = useCallback<UpdateStepsDataFn>(
    ({
      type = "add_step",
      step,
      toOptionId = null,
      toTargetStepId = null,
      toSourceStepId = null,
      fromTargetStepId = null,
      fromSourceStepId = null,
    }) => {
      const currentSteps = getIn(values, "steps");
      const currentFirstStepId = getIn(values, "first_step");

      if (["add_step", "insert_step"].includes(type)) {
        const baseStepName = t(BASE_STEP_NAMES[step.step_type]);
        const baseStepNameCounter = currentSteps.filter(
          (currentStep) =>
            currentStep.name && currentStep.name.indexOf(baseStepName) === 0
        ).length;

        step.name = `${baseStepName} ${baseStepNameCounter + 1}`;
      }
      const fromStepIndex = currentSteps.findIndex(
        (currentStep) => currentStep.id === step.id
      );

      if (fromStepIndex !== -1) {
        // If is moving first step, change first_step field to last next step id
        if (currentFirstStepId === step.id) {
          if (type !== "move_path") {
            setFieldValue("first_step", fromTargetStepId);
          }
        } else if (fromSourceStepId) {
          // If it has a previous step, change the next step it had to the nearest one.
          const fromSourceStepIndex = currentSteps.findIndex(
            (step) => step.id === fromSourceStepId
          );

          if (fromSourceStepIndex !== -1) {
            const fromSourceStep = updateNextStepId(
              currentSteps[fromSourceStepIndex],
              fromTargetStepId,
              step.id
            );

            currentSteps[fromSourceStepIndex] = fromSourceStep;
            helpers.replace(fromSourceStepIndex, fromSourceStep);
          }
        }
      }

      if (!toSourceStepId) {
        setFieldValue("first_step", step.id);

        if (toTargetStepId) {
          step = updateNextStepId(step, toTargetStepId, fromTargetStepId);
        }

        if (fromStepIndex !== -1) {
          helpers.remove(fromStepIndex);
        }
        helpers.unshift(step);
        setFieldTouched("steps.[0].name", true, false);
        return;
      }

      if (hasItems(currentSteps)) {
        const toSourceStepIndex = currentSteps.findIndex((currentStep) =>
          isEqualId(currentStep.id, toSourceStepId)
        );

        if (toSourceStepIndex !== -1) {
          const { nextStep, toSourceStep } = updateStepData({
            step,
            toSourceStep: { ...currentSteps[toSourceStepIndex] },
            toOptionId,
            toTargetStepId,
            fromTargetStepId,
          });

          helpers.replace(toSourceStepIndex, toSourceStep);
          setFieldTouched(`steps.[${toSourceStepIndex}].name`, true, false);

          if (fromStepIndex !== -1) {
            if (type !== "move_path") {
              helpers.replace(fromStepIndex, nextStep);
            }
          } else {
            const toStepIndex = toSourceStepIndex + 1;

            helpers.insert(toStepIndex, nextStep);

            setFieldTouched(`steps.[${toStepIndex}].name`, true, false);
          }
          return;
        }
      }

      if (fromStepIndex !== -1) {
        helpers.remove(fromStepIndex);
      }

      helpers.push(step);
      setFieldTouched(
        `steps.[${Math.max(currentSteps.length - 1, 0)}].name`,
        true,
        false
      );

      return;
    },
    [t, helpers, setFieldValue, setFieldTouched, values]
  );

  const onAddConnection = useCallback(
    ({ id, type }: AddConnectionParams) => {
      const edgeToReplace = getEdge(id);

      if (!edgeToReplace || !edgeToReplace.source || !edgeToReplace.target) {
        return;
      }

      const parentNode: Node<FlowNodeData> | null =
        getNode(edgeToReplace.source) || null;
      const nodeToReplace: Node<FlowNodeData> | null =
        getNode(edgeToReplace.target) || null;

      if (!parentNode || !nodeToReplace) {
        return;
      }

      if (
        parentNode.data &&
        parentNode.data.type &&
        STEP_TYPES_WITHOUT_NEXT.includes(parentNode.data.type)
      ) {
        return;
      }

      const stepId = nodeToReplace.id;

      onUpdateStepsData({
        type: "add_step",
        step: getInitialStepValues(stepId, type, t),
        toOptionId: (edgeToReplace.data && edgeToReplace.data.optionId) || null,
        toSourceStepId: parentNode.type !== "trigger" ? parentNode.id : null,
      });
      onFlowRefresh(stepId);
    },
    [getEdge, getNode, onUpdateStepsData, t, onFlowRefresh]
  );

  const onInsertConnection = useCallback(
    ({ id, isEdge, type }: InsertConnectionParams) => {
      let edge: Edge | null = null;

      if (isEdge) {
        edge = getEdge(id) || null;
      } else {
        const sourceNode = getNode(id) || null;

        if (sourceNode) {
          const connectedEdges = getConnectedEdges([sourceNode], getEdges());

          if (hasItems(connectedEdges)) {
            for (const connectedEdge of connectedEdges) {
              if (connectedEdge.type && connectedEdge.source === id) {
                if (
                  ["add_step_edge", "add_fork_step_edge"].includes(
                    connectedEdge.type
                  )
                ) {
                  onAddConnection({ id: connectedEdge.id, type });
                  return;
                } else {
                  edge = connectedEdge;
                }

                break;
              }
            }
          }
        }
      }

      if (!edge || STEP_TYPES_WITHOUT_NEXT.includes(type)) {
        return;
      }

      const targetNode = getNode(edge.target);
      const sourceNode = getNode(edge.source);

      if (!targetNode || !sourceNode) {
        return;
      }

      const stepId = uuid();

      onUpdateStepsData({
        type: "insert_step",
        step: getInitialStepValues(stepId, type, t),
        toSourceStepId: sourceNode.type !== "trigger" ? edge.source : null,
        toTargetStepId: edge.target,
        toOptionId: edge.sourceHandle,
      });
      onFlowRefresh(stepId);
    },
    [
      getNode,
      onUpdateStepsData,
      t,
      onFlowRefresh,
      getEdge,
      getEdges,
      onAddConnection,
    ]
  );

  const onAddStep = useCallback(
    (type: StepTypes) => {
      if (!isAddingStep.current) {
        isAddingStep.current = true;

        if (selectedItem && selectedItem.type) {
          const id = selectedItem.id;
          const isAdd = selectedItem.type.includes("add_");
          const isEdge = selectedItem.type.includes("_edge");

          if (isAdd) {
            if (isEdge) {
              onAddConnection({ id, type });
            }
          } else {
            onInsertConnection({ id, isEdge, type });
          }
        } else {
          const firstAddStepNode = getNodes().find(
            (node) => node.type === "add_step_node"
          );

          if (firstAddStepNode) {
            // TODO: Add functionality to add first step node
          }
        }
        isAddingStep.current = false;
      }
    },
    [selectedItem, getNodes, onInsertConnection, onAddConnection]
  );

  const onMoveStep = useCallback(
    ({ stepId, toEdgeId, isMovingPath }) => {
      const currentSteps = getIn(values, "steps");
      const fromStepIndex = currentSteps.findIndex(
        (step) => step.id === stepId
      );
      const fromStep = { ...currentSteps[fromStepIndex] };
      const fromNode = getNode(stepId);
      const toEdge = getEdge(toEdgeId);
      const toParentNode = toEdge && getNode(toEdge.source);

      if (
        !fromNode ||
        !toParentNode ||
        (!isMovingPath && hasMultipleNextSteps(fromStep))
      ) {
        return false;
      }

      const prevEdges = getEdges();

      const fromConnectedEdges = getConnectedEdges([fromNode], prevEdges);
      let fromOptionId: string | null = null;
      let fromParentNode: Node<FlowNodeData> | null = null;
      let fromChildNode: Node<FlowNodeData> | null = null;

      if (
        toParentNode.data &&
        STEP_TYPES_WITHOUT_NEXT.includes(toParentNode.data.type)
      ) {
        return false;
      }

      for (const connectedEdge of fromConnectedEdges) {
        if (!connectedEdge.type) {
          continue;
        }
        if (connectedEdge.target === stepId) {
          if (fromParentNode) {
            return false;
          }
          fromOptionId = connectedEdge.id;
          fromParentNode = getNode(connectedEdge.source) || null;
        } else if (
          connectedEdge.source === stepId &&
          !isMovingPath &&
          !connectedEdge.type.includes("add_")
        ) {
          if (fromChildNode) {
            return false;
          }
          fromChildNode = getNode(connectedEdge.source) || null;
        }
      }

      if (
        !fromParentNode ||
        (fromParentNode.id === toParentNode.id &&
          (typeof toEdge.sourceHandle !== "string" ||
            !fromOptionId ||
            fromOptionId === toEdge.sourceHandle)) ||
        (fromChildNode && fromChildNode.id === toParentNode.id)
      ) {
        return false;
      }

      const toChildNode = getNode(toEdge.target);
      const toTargetIsAddStep =
        toEdge.type &&
        ["add_step_edge", "add_fork_step_edge"].includes(toEdge.type);

      if (!toChildNode) {
        return false;
      }

      onUpdateStepsData({
        type: isMovingPath ? "move_path" : "move_step",
        step: fromStep,
        toOptionId: toEdge.sourceHandle,
        toTargetStepId: toTargetIsAddStep ? null : toEdge.target,
        toSourceStepId: toParentNode.type !== "trigger" ? toEdge.source : null,
        fromSourceStepId: fromParentNode.id,
        fromTargetStepId: !isMovingPath ? getNextStepId(fromStep) : null,
      });
      onFlowRefresh(stepId);
    },
    [values, getNode, getEdge, getEdges, onUpdateStepsData, onFlowRefresh]
  );

  const onDrop = useCallback<DragEventHandler>(
    (event: React.DragEvent<HTMLDivElement>) => {
      const type = event.dataTransfer.getData("application/flow:type");

      if (
        !isAddingStep.current &&
        event.target instanceof Element &&
        ["add", "move"].includes(type)
      ) {
        isAddingStep.current = true;
        const edgeElement = event.target.closest(".react-flow__edge > g");
        const edgeId = edgeElement && edgeElement.getAttribute("id");

        switch (type) {
          case "add":
            const edgeType =
              edgeElement && edgeElement.getAttribute("data-type");

            if (edgeId && edgeType) {
              const isAdd = edgeType.includes("add_");
              const stepType = event.dataTransfer.getData(
                "application/flow:step-type"
              ) as StepTypes;

              if (isAdd) {
                onAddConnection({
                  id: edgeId,
                  type: stepType,
                });
              } else {
                onInsertConnection({
                  id: edgeId,
                  isEdge: true,
                  type: stepType,
                });
              }
            }
            break;
          case "move":
            if (edgeId) {
              const stepId = event.dataTransfer.getData(
                "application/flow:step-id"
              ) as StepTypes;
              const isMovingPath =
                event.dataTransfer.getData(
                  "application/flow:is-moving-path"
                ) === "true";

              onMoveStep({
                stepId,
                toEdgeId: edgeId,
                isMovingPath,
              });
            }

            break;
          default:
            break;
        }

        isAddingStep.current = false;
      }
    },
    [onMoveStep, onAddConnection, onInsertConnection]
  );

  const onNodeClick = useCallback<NodeMouseHandler>(
    (_, node) => {
      const { addSelectedNodes } = store.getState();
      if (!node.selected && addSelectedNodes) {
        addSelectedNodes([node.id]);
      }
    },
    [store]
  );

  const onDragStart = useCallback(
    (event) => {
      const stepId = event.dataTransfer.getData("application/flow:step-id");
      const stepType = event.dataTransfer.getData("application/flow:step-type");

      const pointNodes = getHierarchyPointNodes(nodes, edges, stepId);
      const nextStepIds = pointNodes.reduce((nextStepIds, pointNode) => {
        if (pointNode.data.data.type === "GO_TO") {
          if (pointNode.data.data.next_step_default) {
            nextStepIds.push(pointNode.data.data.next_step_default);
          }
          if (pointNode.data.data.next_step_alternate) {
            nextStepIds.push(pointNode.data.data.next_step_alternate);
          }
          if (pointNode.data.data.answer_failed_next_step) {
            nextStepIds.push(pointNode.data.data.answer_failed_next_step);
          }
        }
        if (pointNode.id) {
          nextStepIds.push(pointNode.id);
        }

        return nextStepIds;
      }, [] as string[]);
      const currentPointNode = pointNodes.find(
        (pointNode) => pointNode.id === stepId
      );
      const isMovingPath = Boolean(
        currentPointNode &&
          currentPointNode.children &&
          currentPointNode.children.filter(
            (children) => children.data.type === "step"
          ).length > 1
      );

      event.dataTransfer.setData(
        "application/flow:is-moving-path",
        isMovingPath
      );

      onNodeDragging({
        id: stepId,
        type: stepType,
        nextStepIds,
        isMovingPath,
      });
    },
    [nodes, edges, onNodeDragging]
  );

  const onDragEnd = useCallback(() => onNodeDragging(null), [onNodeDragging]);

  const handleDeleteStep = useCallback(
    async (stepId: string) => {
      const prevSteps = values.steps;

      if (!prevSteps) {
        return;
      }

      const currentStep = prevSteps.find((prevStep) => prevStep.id === stepId);

      if (!currentStep) {
        return;
      }

      let nextFirstStepId = values.first_step;
      const isFirstStep = currentStep.id === nextFirstStepId;
      const nextStepId = getNextStepId(currentStep);
      const currentStepVariables = currentStep.variables || [];
      const nextSteps: FlowStepData[] = [];

      if (isFirstStep) {
        nextFirstStepId = nextStepId;
      }

      for (const prevStep of prevSteps) {
        if (prevStep.id !== currentStep.id) {
          let nextStep: FlowStepData = { ...prevStep };
          if (!isFirstStep) {
            if (isEqualId(prevStep.next_step_default, currentStep.id)) {
              nextStep.next_step_default = nextStepId;
            } else if (
              isEqualId(prevStep.next_step_alternate, currentStep.id)
            ) {
              nextStep.next_step_alternate = nextStepId;
            } else if (
              isEqualId(prevStep.answer_failed_next_step, currentStep.id)
            ) {
              nextStep.answer_failed_next_step = nextStepId;
            } else if (
              hasItems(prevStep.next_steps_for_options) &&
              prevStep.next_steps_for_options.includes(currentStep.id)
            ) {
              const nextStepOptions: (string | null)[] = [];
              for (const nextOptionStepId of prevStep.next_steps_for_options) {
                if (isEqualId(nextOptionStepId, currentStep.id)) {
                  nextStepOptions.push(nextStepId);
                } else {
                  nextStepOptions.push(nextOptionStepId);
                }
              }
              nextStep.next_steps_for_options = nextStepOptions;
            }
          }
          const prevStepRequiredVariables = prevStep.required_variables || [];

          if (
            currentStepVariables.length > 0 &&
            prevStepRequiredVariables.length > 0
          ) {
            const nextStepRequiredVariables: string[] = [];

            for (const variableId of prevStepRequiredVariables) {
              if (currentStepVariables.includes(variableId)) {
                nextStep = getStepWithoutVariable(nextStep, variableId);
              } else {
                nextStepRequiredVariables.push(variableId);
              }
            }
            nextStep.required_variables = nextStepRequiredVariables;
          }

          nextSteps.push(nextStep);
        }
      }

      const nextVariables: HilosVariableData[] = (
        values.variables || []
      ).filter(
        (variable) =>
          !(
            variable &&
            // @ts-ignore
            [variable.step, variable.step_id].includes(currentStep.id)
          )
      );

      const { nodes: nextNodes, edges: nextEdges } = getNodesFromData({
        steps: nextSteps,
        firstStepId: nextFirstStepId,
        triggerType: values.trigger_type,
      });

      setFieldValue("first_step", nextFirstStepId);
      setFieldValue("variables", nextVariables);
      setFieldValue("steps", nextSteps);
      onUpdateTransitions(nextNodes, nextEdges);
      onSetStepToDelete();

      isDeletingStepRef.current = false;
    },
    [
      values.trigger_type,
      values.first_step,
      values.steps,
      values.variables,
      setFieldValue,
      onSetStepToDelete,
      onUpdateTransitions,
    ]
  );

  useEffect(() => {
    if (nextStepIdToDelete && !isDeletingStepRef.current) {
      isDeletingStepRef.current = true;
      if (handleDeleteStepRef.current) {
        handleDeleteStepRef.current(nextStepIdToDelete);
      }
    }
  }, [nextStepIdToDelete]);

  useLayoutEffect(() => {
    handleDeleteStepRef.current = handleDeleteStep;
  }, [handleDeleteStep]);

  useEffect(() => {
    if (
      !isDeletingStepRef.current &&
      (hasPendingRefresh || !hasNodesInitializedRef.current)
    ) {
      hasNodesInitializedRef.current = true;
      const { nodes: nextNodes, edges: nextEdges } = getNodesFromData({
        steps: values.steps,
        triggerType: values.trigger_type,
        firstStepId: values.first_step,
        nextSelectedNodeId,
      });
      onUpdateTransitions(nextNodes, nextEdges);
      onCompleteFlowRefresh();
    }
  }, [
    values.trigger_type,
    values.first_step,
    values.steps,
    hasPendingRefresh,
    nextSelectedNodeId,
    onCompleteFlowRefresh,
    onUpdateTransitions,
  ]);

  return {
    onDrop,
    onAddStep,
    onNodeClick,
    onDragStart,
    onDragEnd,
  };
}

export default useFlowBuilder;
