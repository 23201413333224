import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";

interface FlowExecutionStepMessageDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepMessageDetail({
  executionStep,
}: FlowExecutionStepMessageDetailProps) {
  return null;
}
