import {
  ArrowSmRightIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";

export default function StateButton({
  isSubmitting,
  submitted,
  success,
  btnClasses = "inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2",
  btnClassesSuccess = "inline-flex items-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2",
  btnClassesError = "inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-2 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2",
  submittingText = "Saving...",
  successText = "Saved!",
  buttonType = "submit",
  initialText = (
    <>
      Save
      <ArrowSmRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
    </>
  ),
  ...rest
}) {
  return (
    <>
      {isSubmitting ? (
        <button
          className={`${btnClasses} cursor-progress`}
          type={buttonType}
          disabled={true}
        >
          <svg
            className="-ml-1 mr-3 h-5 w-5 animate-spin "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {submittingText}
        </button>
      ) : (
        <>
          {submitted ? (
            <>
              {success ? (
                <button
                  className={btnClassesSuccess}
                  type={rest.buttonType ? rest.buttonType : "submit"}
                  disabled={true}
                >
                  <CheckIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                  {successText}
                </button>
              ) : (
                <button
                  className={btnClassesError}
                  type={rest.buttonType ? rest.buttonType : "submit"}
                  disabled={false}
                  {...rest}
                >
                  <ExclamationCircleIcon
                    className="mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                  An error occurred! Try again
                </button>
              )}
            </>
          ) : (
            <button
              className={btnClasses}
              type={rest.buttonType ? rest.buttonType : "submit"}
              {...rest}
            >
              {initialText}
            </button>
          )}
        </>
      )}
    </>
  );
}
