import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { PencilAltIcon, XIcon } from "@heroicons/react/outline";
import axios, { AxiosError } from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { Flow } from "@hilos/types/flows/Flow";
import {
  FlowTemplateDetailRead,
  FlowTemplateSimpleListRead,
} from "@hilos/types/private-schema";
import CheckboxField from "src/components/Form/CheckboxField";
import SelectorField from "src/components/Form/SelectorField";
import TextAreaField from "src/components/Form/TextAreaField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import image_placeholder from "src/assets/img/wa/template_image_placeholder.svg";
import { API_ROUTES, buildAPIRoute } from "../../router/router";

const schema = yup.object().shape({
  title: yup.string(),
  description: yup.string(),
  tags: yup.array().of(yup.string()),
  preview_title: yup.string(),
  preview_description: yup
    .string()
    .max(120, "Preview description must be at most 120 characters"),
});

interface FlowTemplateFormProps {
  setShow: (show: boolean) => void;
  flow: Flow;
  flowTemplate: FlowTemplateDetailRead | null;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<FlowTemplateSimpleListRead[], unknown>>;
}

export default function FlowTemplateFormModal({
  setShow,
  flow,
  flowTemplate,
  refetch,
}: FlowTemplateFormProps) {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const getInitialValuesFromTemplate = useCallback(
    () => ({
      title: flowTemplate?.title,
      description: flowTemplate?.description,
      tags: flowTemplate?.tags,
      img_url: flowTemplate?.img_url,
      help_url: flowTemplate?.help_url,
      preview_title: flowTemplate?.preview_title,
      preview_description: flowTemplate?.preview_description,
      preview_image: flowTemplate?.preview_image,
      show_in_home: flowTemplate?.show_in_home,
    }),
    [flowTemplate]
  );

  const initialEmptyValues = useMemo(
    () => ({
      title: "",
      description: "",
      tags: [],
      img_url: "",
      help_url: "",
      preview_title: "",
      preview_description: "",
      preview_image: "",
      show_in_home: "",
    }),
    []
  );

  useEffect(() => {
    if (flowTemplate) {
      setIsUpdate(true);
    }
  }, [flowTemplate]);

  const onSubmit = useCallback(
    async (formData) => {
      setSubmitting(true);
      setBackendValidationErrors({});
      setBackendError("");
      try {
        const { data } = isUpdate
          ? await axios.patch(
              buildAPIRoute(API_ROUTES.FLOW_TEMPLATE_DETAIL, {
                ":id": flowTemplate?.id,
              }),
              {
                ...formData,
                flow_pk: flow.id,
              }
            )
          : await axios.post(API_ROUTES.FLOW_CREATE_TEMPLATE_LIST, {
              ...formData,
              flow_pk: flow.id,
            });
        setSubmitting(false);
        if (data) {
          setShow(false);
        }
      } catch (err) {
        type axiosErr =
          | AxiosError<{ status?: number; data?: unknown }, unknown>
          | undefined;
        const errorAxios: axiosErr = err as axiosErr;
        setSuccess(false);
        if (errorAxios?.response?.status === 400) {
          setBackendValidationErrors(errorAxios.response.data);
        } else {
          setBackendError("An error occurred, please try again.");
        }
      } finally {
        setSubmitting(false);
        refetch();
      }
    },
    [flow.id, flowTemplate?.id, isUpdate, refetch, setShow]
  );

  const onDelete = useCallback(async () => {
    setBackendValidationErrors({});
    setBackendError("");
    setDeleteSubmitting(true);
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.FLOW_TEMPLATE_DETAIL, {
          ":id": flowTemplate?.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
    } catch (err) {
      type axiosErr =
        | AxiosError<{ status?: number; data?: unknown }, unknown>
        | undefined;
      const errorAxios: axiosErr = err as axiosErr;
      setDeleteSuccess(false);
      if (errorAxios?.response?.status === 400) {
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
    } finally {
      setDeleteSubmitting(false);
      setDeleteSubmitted(false);
      setIsSubmitted(true);
    }
  }, [flowTemplate]);

  return (
    <>
      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setShow(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-center">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <PencilAltIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <div className="pr-6 text-lg font-medium leading-6 text-gray-900">
            <p className="mb-1 text-sm text-gray-600">Flow template</p>
            {flow.name}
          </div>
          <div className="mt-2">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={{}}
            ></APIErrors>
          </div>
        </div>
      </div>

      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={
          flowTemplate ? getInitialValuesFromTemplate() : initialEmptyValues
        }
      >
        {(formik) => (
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="mt-4">
              <div className="text-lg font-semibold">
                Flow template page info
              </div>
              <div className="mt-2">
                <TextInputField
                  label="Title"
                  name="title"
                  placeholder="Title"
                  type="text"
                />
              </div>
              <div className="mt-4">
                <TextAreaField
                  label="Description"
                  name="description"
                  placeholder="Desc"
                  type="text"
                />
              </div>
              <div className="mt-4 text-sm font-semibold">
                <p className="mb-1 text-neutral-dark">Tags for template</p>
                <SelectorField isMulti isCreatable name="tags" options={[]} />
              </div>
              <div className="mt-2">
                <TextInputField
                  label="Image Url"
                  name="img_url"
                  placeholder="url"
                  type="text"
                />
                <img
                  src={formik.values["img_url"] || image_placeholder}
                  className="mt-2 self-center"
                  alt="Couldn't load from URL"
                />
              </div>

              <div className="mt-2">
                <TextInputField
                  label="Help Url"
                  name="help_url"
                  placeholder="url"
                  type="text"
                />
              </div>
            </div>
            <hr className="mt-8" />
            <div className="mt-4">
              <div className="text-lg font-semibold">Preview info</div>
              <div className="mt-2">
                <TextInputField
                  label="Titl"
                  name="preview_title"
                  placeholder="Titl"
                  type="text"
                />
              </div>
              <div className="mt-4">
                <TextAreaField
                  label="Description"
                  name="preview_description"
                  placeholder="Preview description"
                  type="text"
                  maxLength={120}
                />
              </div>
              <div className="mt-2">
                <TextInputField
                  label="Image Url"
                  name="preview_image"
                  placeholder="preview url"
                  type="text"
                />
                <img
                  src={formik.values["preview_image"] || image_placeholder}
                  className="mt-2 self-center"
                  alt="Couldn't load from URL"
                />
              </div>
              <div className="mt-4">
                <CheckboxField name="show_in_home" label="Show in home" />
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <StateButton
                isSubmitting={submitting}
                submitted={isSubmitted}
                success={success}
                btnClasses="w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-700 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                submittingText="Saving..."
                successText="Saved"
                initialText={<>Save</>}
              />
            </div>
          </form>
        )}
      </Formik>
      {flowTemplate && (
        <form noValidate onSubmit={onDelete}>
          <StateButton
            isSubmitting={deleteSubmitting}
            submitted={deleteSubmitted}
            success={deleteSuccess}
            btnClasses="w-full mr-auto inline-flex items-center justify-left rounded-md border border-transparent underline px-4 py-2 text-xs text-red-600 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
            submittingText="Deleting..."
            successText="Deleted"
            initialText={<>Remove it from templates</>}
          />
        </form>
      )}
    </>
  );
}
