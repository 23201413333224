import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useField } from "formik";
import { classNames } from "../../Helpers";
import FieldContainer from "./FieldContainer";

interface TextAreaFieldProps
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, "action"> {
  name: string;
  help?: string;
  optional?: boolean;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
}

function TextAreaField({
  name,
  label,
  rows,
  help,
  optional,
  icon: RenderIcon,
  ...props
}: TextAreaFieldProps) {
  const [field, meta] = useField({ name });
  const error = meta.touched && meta.error;

  return (
    <FieldContainer
      name={name}
      label={label}
      help={help}
      optional={optional}
      error={error}
    >
      <div className="relative mt-1 rounded-md shadow-sm">
        {RenderIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <RenderIcon className="h-5 w-5 text-gray-500" />
          </div>
        )}
        <textarea
          className={classNames(
            "block w-full focus:border-indigo-500 focus:ring-indigo-500",
            "rounded-md border-gray-300 sm:text-sm",
            RenderIcon && "pl-10",
            error && "border-red-500 focus:border-red-500 focus:ring-red-500"
          )}
          id={name}
          rows={rows || 3}
          {...field}
          {...props}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-start pr-3 pt-2">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    </FieldContainer>
  );
}

export default TextAreaField;
