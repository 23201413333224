import { useTranslation } from "react-i18next";
import RobotIcon from "src/components/icons/RobotIcon";

export default function TriggerEmptyState() {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full relative">
      <div className="relative block rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-96 mx-auto">
        <div className="mt-4">
          <RobotIcon className="h-10 w-10 text-gray-400 mx-auto" />
        </div>
        <span className="mt-2 block text-sm font-semibold text-gray-600">
          {t(
            "flows:trigger.integrations-empty",
            "Choose how to trigger your flow to continue"
          )}
        </span>
      </div>
    </div>
  );
}
