import { useTranslation } from "react-i18next";
import { CheckIcon, PencilAltIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import RadioButtonsField from "src/components/Form/RadioButtonsField";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import { EXECUTION_FREQUENCY_UNITS } from "../constants/flow";

function FlowTriggerMultipleContactExecutionAllowedForm() {
  const [t] = useTranslation();
  const { values } = useFormikContext<FlowData>();

  return (
    <div className="space-y-4">
      <SwitchField
        name="contact_can_run_multiple_times"
        label={t(
          "flows:contact_can_run_multiple_times.label",
          "Can a contact go through this flow multiple times?"
        )}
        help={t(
          "flows:contact_can_run_multiple_times.help",
          "Use this to avoid sending multiple messages to this contact."
        )}
      />

      {values.contact_can_run_multiple_times && (
        <>
          <RadioButtonsField
            name="contact_execution_multiple_type"
            options={[
              {
                value: "UNLIMITED",
                label: t(
                  "flows:contact_can_run_multiple_times.unlimited",
                  "Unlimited times"
                ),
                disabled: false,
                icon: CheckIcon,
              },
              {
                value: "CUSTOM",
                label: t(
                  "flows:contact_can_run_multiple_times.custom",
                  "Custom"
                ),
                disabled: false,
                icon: PencilAltIcon,
              },
            ]}
          />

          {values.contact_execution_multiple_type === "CUSTOM" && (
            <div className="text-sm font-medium text-gray-700">
              {t(
                "flows:contact_execution_multiple_type.label",
                "Allow a contact to go through the flow if at least this time has passed"
              )}
              :
              <div className="flex items-center space-x-3 mt-1">
                <TextInputField
                  name="contact_execution_max_frequency_quantity"
                  type="number"
                  placeholder="3"
                />
                <div className="w-full">
                  <SelectorField
                    name="contact_execution_max_frequency_unit"
                    options={EXECUTION_FREQUENCY_UNITS.map((unit) => ({
                      label: t(unit.label),
                      value: unit.value,
                    }))}
                    placeholder={t(
                      "flows:contact_execution_max_frequency_unit.placeholder",
                      "Select a unit"
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FlowTriggerMultipleContactExecutionAllowedForm;
