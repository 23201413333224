import { useEffect } from "react";
import { FlowExecutionContactStatusEnum } from "@hilos/types/private-schema";
import useCreateStateButton from "src/hooks/useCreateStateButton";
import { useFlowExecutionContactUpdateDetails } from "src/hooks/useFlowExecutionContactDetail";
import {
  FlowExecutionContactStatus,
  FlowExecutionContactStatusPayload,
} from "src/types/flows/FlowExecutionContact";

interface useCreateStateButtonsContactHookArgs {
  flowExecutionContactId: string;
  flowExecutionContactStatus?: FlowExecutionContactStatusEnum;
  onFlowExecutionContactStatusUpdateSuccess: (
    nextStatus: FlowExecutionContactStatusPayload
  ) => void;
}

export function useCreateStateButtonsContactHook({
  flowExecutionContactId,
  flowExecutionContactStatus,
  onFlowExecutionContactStatusUpdateSuccess,
}: useCreateStateButtonsContactHookArgs) {
  const { handleUpdateFlowExecutionContactStatus, isSubmittingStatus } =
    useFlowExecutionContactUpdateDetails({
      flowExecutionContactId,
      onFlowExecutionContactStatusUpdateSuccess,
    });

  const [cancelState, handleCancelState] = useCreateStateButton({
    handleActionButton: handleUpdateFlowExecutionContactStatus,
  });

  const stateButtonBtnClasses = {
    btnClassesSuccess:
      "focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-100 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm",
    btnClassesError:
      "focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-50 px-3 py-2 text-sm font-medium leading-4 text-yellow-800 shadow-sm",
  };

  // Check which buttons need to be enabled

  useEffect(() => {
    let deleteDisabledInitial = true;

    switch (flowExecutionContactStatus) {
      case FlowExecutionContactStatus.READY:
      case FlowExecutionContactStatus.RUNNING:
        deleteDisabledInitial = false;
        break;
    }
    cancelState.setDisabled(deleteDisabledInitial);
  }, [cancelState, flowExecutionContactStatus]);

  const handleCancel = handleCancelState({
    status: FlowExecutionContactStatus.CANCELED,
  });
  return {
    cancelState,
    handleCancel,
    isSubmittingStatus,
    stateButtonBtnClasses,
  };
}
