// @flow
import { CodeIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import * as yup from "yup";
import { ERROR_MESSAGES } from "../../constants/Form";

export const INBOX_ACTION_TYPES = [
  {
    value: "HTTP",
    label: "HTTP Request",
    icon: <CodeIcon className="h-5 w-5" aria-hidden="true" />,
  },
];

export const InboxAction = {
  getInitialValues: function () {
    return {
      name: "",
      action_type: "HTTP",
      button_label: "Create Zendesk ticket",
      button_icon: "",
      button_classes:
        "px-4 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
    };
  },
  FIELDS: {
    name: {
      key: "name",
      label: "Name of this action",
      placeholder: "Create ticket on Zendesk",
      optional: false,
      help: "",
    },
    button_label: {
      key: "button_label",
      placeholder: "Create Zendesk Action",
      label: "What will the button label be?",
      help: "This is how your teammates will be able to see it in the Inbox > Actions tab.",
      optional: false,
    },
    button_icon: {
      key: "button_icon",
      label: "Button icon",
      placeholder: "https://mysite.com/myimage.png",
      optional: true,
      help: "You can paste here an image url to render in the button.",
    },
    button_classes: {
      key: "button_classes",
      label: "Button classes",
      placeholder: "px-3 py-2",
      optional: true,
      help: (
        <span className="flex items-center">
          Personalize each button by using base
          <a
            href="https://tailwindcss.com/"
            target="_blank"
            rel="noreferrer"
            className="ml-0.5 flex items-center text-blue-500"
          >
            TailwindCSS classes
            <ExternalLinkIcon className="ml-0.5 h-4 w-4" />
          </a>
        </span>
      ),
    },
  },
  schema: {
    name: yup.string().required(ERROR_MESSAGES.required),
    action_type: yup.string().required(ERROR_MESSAGES.required),
    button_label: yup.string().required(ERROR_MESSAGES.required),
    button_icon: yup.string(),
    button_classes: yup.string(),
  },
};
