import { useTranslation } from "react-i18next";
import { Card, Metric, Text } from "@tremor/react";
import Loading from "src/components/Loading";
import Tooltip from "src/components/Tooltip";
import { useInboxUsageSummaryCards } from "src/hooks/useInboxUsageSummaryAnalytics";
import { paramsToQueryString } from "../FiltersMeta";

export default function InboxSummaryCards({ params }) {
  const { t } = useTranslation();
  const paramsQS = paramsToQueryString(params);
  const { inboxUsageSummaryCards } = useInboxUsageSummaryCards(paramsQS);

  return (
    <div className="flex flex-row justify-items-stretch space-x-6">
      {!inboxUsageSummaryCards ? (
        <Loading />
      ) : (
        <>
          <div>
            <Card className="max-w-xs mx-auto px-4 py-3 sm:px-6 sm:py-4">
              <Text className="flex items-center">
                {t(
                  "reports:summary.conversations-count",
                  "Conversations in period"
                )}
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3 ">
                      {t(
                        "reports:summary.conversations-count-tooltip",
                        "Amount of conversations started by your contacts in the current time frame."
                      )}
                    </div>
                  }
                />
              </Text>
              <Metric>
                {inboxUsageSummaryCards?.period?.conversations_count}
              </Metric>
            </Card>
          </div>
          <div>
            <Card className="max-w-xs mx-auto px-4 py-3 sm:px-6 sm:py-4">
              <Text className="flex items-center">
                {t("reports:summary.messages-count", "Messages in period")}
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3 ">
                      {t(
                        "reports:summary.conversations-count-tooltip",
                        "Amount of conversations started by your contacts in the current time frame."
                      )}
                    </div>
                  }
                />
              </Text>
              <Metric>{inboxUsageSummaryCards?.period?.messages_count}</Metric>
            </Card>
          </div>
        </>
      )}
    </div>
  );
}
