import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ContactDetailRead,
  ContactTag,
  PatchedContactEdit,
} from "@hilos/types/private-schema";
import Tags, { contactTagsColorClasses } from "src/components/Tags";
import { UpdateContactFn } from "src/hooks/useInboxContactDetails";
import { useContactTags } from "src/hooks/useTags";

interface ContactTagListProps {
  contact: ContactDetailRead;
  onUpdateContact: UpdateContactFn;
}

function ContactTagList({ contact, onUpdateContact }: ContactTagListProps) {
  const { t } = useTranslation();
  const { tags } = useContactTags();

  const currentTags = useMemo(() => contact.tags ?? [], [contact.tags]);

  const handleUpdateTags = useCallback(
    (nextTags: ContactTag[]) => {
      onUpdateContact({ tags: nextTags, overwrite_tags: true });
    },
    [onUpdateContact]
  );

  const handleRemoveTag = useCallback(
    (tagToRemove) => {
      return () => {
        const nextTags = [...currentTags];
        handleUpdateTags(
          nextTags.filter((tag) => tag.name !== tagToRemove.name)
        );
      };
    },
    [currentTags, handleUpdateTags]
  );

  return (
    <div>
      <Tags
        data-tour="contact-tags"
        title={t("inbox:contact-tags", "Contact Tags")}
        availableTags={tags}
        currentTags={currentTags}
        onUpdateTags={handleUpdateTags}
        onRemoveTag={handleRemoveTag}
        {...contactTagsColorClasses}
      />
    </div>
  );
}

export default ContactTagList;
