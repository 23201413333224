import ExternalAppCard, { ExternalAppCardProps } from "./ExternalAppCard";
import { Trans, useTranslation } from "react-i18next";

import HeaderNavigation from "../../components/HeaderNavigation";
import { PlusIcon } from "@heroicons/react/outline";
import { buildRoute } from "../../router/router";
import useExternalApps from "src/hooks/useExternalApps";
import useHilosStore from "src/hooks/useHilosStore";
import { useMemo } from "react";
import { useZapierApps } from "src/hooks/useZapierApps";
import useZapierEmbedScript from "src/hooks/useZapierEmbedScript";

const ExternalAppsActive: ExternalAppCardProps[] = [
  // {
  //   key: "ChatGPT",
  //   icon: "/images/integrations/chatgpt_logo.png",
  //   // i18n.t("integrations:external-apps.chatgpt.title", "ChatGPT")
  //   title: "integrations:external-apps.chatgpt.title",
  //   // i18n.t("integrations:external-apps.chatgpt.description", "Configure ChatGPT to work with Hilos")
  //   description: "integrations:external-apps.chatgpt.description",
  //   to: "/integrations/chatgpt",
  //   connected: true,
  //   featureFlag: "inbox-ai",
  // },
];

const ExternalAppsInstallable: ExternalAppCardProps[] = [
  {
    key: "HubSpot",
    icon: "/images/integrations/hubspot.avif",
    // i18n.t("integrations:external-apps.hubspot.title", "HubSpot")
    title: "integrations:external-apps.hubspot.title",
    // i18n.t("integrations:external-apps.hubspot.description", "Sync Hubspot contacts to Hilos and more...")
    description: "integrations:external-apps.hubspot.description",
    to: "/integrations/hubspot",
  },
  // {
  //   key: "salesforce",
  //   icon: "/images/integrations/salesforce.avif",
  //   // i18n.t("integrations:external-apps.salesforce.title", "Salesforce")
  //   title: "integrations:external-apps.salesforce.title",
  //   // i18n.t("integrations:external-apps.salesforce.description", "Sync Salesforce contacts to Hilos and more...")
  //   description: "integrations:external-apps.salesforce.description",
  //   to: "/integrations/salesforce",
  // },
  // {
  //   key: "pipedrive",
  //   icon: "/images/integrations/pipedrive.avif",
  //   // i18n.t("integrations:external-apps.pipedrive.title", "Pipedrive")
  //   title: "integrations:external-apps.pipedrive.title",
  //   // i18n.t("integrations:external-apps.pipedrive.description", "Sync Pipedrive contacts to Hilos and more...")
  //   description: "integrations:external-apps.pipedrive.description",
  //   to: "/integrations/pipedrive",
  // },
  // {
  //   key: "close",
  //   icon: "/images/integrations/close.avif",
  //   // i18n.t("integrations:external-apps.close.title", "Close.io")
  //   title: "integrations:external-apps.close.title",
  //   // i18n.t("integrations:external-apps.close.description", "Sync Close contacts to Hilos and more....io")
  //   description: "integrations:external-apps.close.description",
  //   to: "/integrations/close",
  // },
  // {
  //   key: "zoho",
  //   icon: "/images/integrations/zoho.avif",
  //   // i18n.t("integrations:external-apps.zoho.title", "Zoho")
  //   title: "integrations:external-apps.zoho.title",
  //   // i18n.t("integrations:external-apps.zoho.description", "Sync Zoho contacts to Hilos and more...")
  //   description: "integrations:external-apps.zoho.description",
  //   to: "/integrations/zoho",
  // },
];

export default function ExternalAppsList() {
  const { t } = useTranslation();
  // const [externalApps, setExternalApps] = useState<ExternalApp[]>([]);
  const zapierApps = useZapierApps();
  const installedExternalApps = useExternalApps();
  const { session } = useHilosStore();

  const disableExternalAppInstall = useMemo(
    () => !!(installedExternalApps?.length && installedExternalApps.length > 0),
    [installedExternalApps]
  );
  const externalAppsInfo = useMemo(() => {
    return ExternalAppsInstallable.map((externalAppInstall) => {
      const installedApp = installedExternalApps?.find(
        (external) => external.app_type === externalAppInstall.key
      );
      if (!installedApp) {
        return externalAppInstall;
      }
      if (installedApp.app_type === "HubSpot") {
        return {
          ...externalAppInstall,
          connected: !!installedApp.id,
        };
      }
      return {
        ...externalAppInstall,
        to: externalAppInstall.to + `/${installedApp.id}`,
        connected: !!installedApp.id,
      };
    });
  }, [installedExternalApps]);

  const externalAppsActiveAvailable = useMemo(() => {
    return ExternalAppsActive.filter((externalApp) => {
      if (externalApp.featureFlag) {
        return session?.account.feature_flags?.includes(
          externalApp.featureFlag
        );
      }
      return true;
    });
  }, [session?.account.feature_flags]);

  useZapierEmbedScript();

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t(
                "integrations:external-apps.header-navigation-title",
                "External apps"
              ),
              url: buildRoute("external-apps"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <PlusIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("integrations:external-apps.label", "External Apps")}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 space-y-4">
          <div className="px-4 sm:px-0">
            <Trans i18nKey="integrations:external-apps.description">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                These external apps are available to use with your account.
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Read more about our API and how you can use hilos.io with your
                existing platforms{" "}
                <a
                  href="https://hilos.io/docs/apidocs"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500"
                >
                  here
                </a>
                .
              </p>
            </Trans>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
            {externalAppsActiveAvailable.map((app) => (
              <ExternalAppCard
                key={app.key}
                title={app.title}
                description={app.description}
                icon={app.icon}
                to={app.to}
                connected={app.connected}
                useI18n={true}
              />
            ))}
            {externalAppsInfo.map((app) => (
              <ExternalAppCard
                key={app.key}
                title={app.title}
                description={app.description}
                icon={app.icon}
                to={app.to}
                connected={app.connected}
                disableInstall={disableExternalAppInstall}
                useI18n={true}
              />
            ))}
            {zapierApps.apps.map((app) => (
              <ExternalAppCard
                key={app.uuid}
                title={app.title}
                description={app.description}
                icon={app.images.url_64x64}
                link={app.url}
                loading={app.loading}
              />
            ))}
          </div>
          <zapier-full-experience
            client-id="DGppUgv9rzC0lbqZjdPUQ1pDQesiqJSEMgYepgtP"
            theme="light"
            intro-copy-display="show"
            app-search-bar-display="show"
            app-categories="crm,sales-crm,email"
            zap-style="card"
            zap-call-to-action-display="show"
            zap-create-from-scratch-display="show"
          />
        </div>
      </div>
    </div>
  );
}
