import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ConnectButton } from "360dialog-connect-button";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  LinkIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import { axiosErr } from "@hilos/types/axios";
import Loading from "src/components/Loading";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { hasItems } from "src/helpers/utils";
import { useChannels } from "src/hooks/useChannel";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames, formatPhone } from "src/Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import { ChannelProvider, ChannelType } from "src/types/channel";
import ChannelForm from "./ChannelForm";
import ChannelProxyForm from "./ChannelProxyForm";
import * as d360Meta from "./Dialog360SettingsMeta";
import * as meta from "./Meta";

export interface D360ChannelOption {
  id: string;
  name: string;
  number: string;
}

interface Dialog360Channel {
  id: string;
  status: string;
  setup_info: {
    phone_name: string;
    phone_number: string;
  };
  created_at: string;
}

interface GetChannelsParams {
  client: string;
  channels: string[];
}

async function getD360Channels({
  client,
  channels,
}: GetChannelsParams): Promise<D360ChannelOption[]> {
  if (hasItems(channels)) {
    try {
      const { data, status } = await axios.post(API_ROUTES.DIALOG360_CHANNELS, {
        client_id: client,
        channel_ids: channels,
      });

      if (status === 200 && hasItems(data)) {
        return data.map((channel: Dialog360Channel) => ({
          ...channel,
          name: channel.setup_info.phone_name,
          number: channel.setup_info.phone_number,
        }));
      }
    } catch {}
  }

  return [];
}

function Dialog360Form() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reloadSession, session } = useHilosStore();
  const channels = useChannels();
  const [d360Channels, setD360Channels] = useState<D360ChannelOption[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const handleLoadD360Channels = useCallback(async (formik, data) => {
    setLoading(true);
    const nextChannels = await getD360Channels(data);
    setD360Channels(nextChannels);
    setLoading(false);

    formik.setFieldValue("channel_id", "+" + nextChannels[0].number);
    formik.setFieldTouched("channel_id");

    formik.setFieldValue("settings.d360_channel_id", nextChannels[0].id);
    formik.setFieldTouched("d360_channel_id");

    formik.setFieldValue("settings.d360_channel_name", nextChannels[0].name);
    formik.setFieldTouched("d360_channel_name");
  }, []);

  const onSubmit = async (values, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.post(buildAPIRoute(API_ROUTES.CHANNEL_LIST_CREATE), values);
      setSubmitting(false);
      setSuccess(true);
      reloadSession();
      setTimeout(() => {
        navigate(buildRoute("config-channel"));
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      console.log(err);
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          "Couldn't save your new channel information. Please try again later."
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  const initialValues = useMemo(() => {
    return {
      ...meta.getInitialValues(
        ChannelType.WHATSAPP,
        ChannelProvider.D360_CLOUD_API
      ),
      settings: d360Meta.getInitialValues(),
    };
  }, []);

  const hasAtLeastOneActiveChannel = useMemo(() => {
    return channels.filter((ch) => ch.status === "ACTIVE").length > 1;
  }, [channels]);

  return (
    <Formik
      validationSchema={d360Meta.schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
      initialValues={initialValues}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t("channel:add-phone-number", "Add your new phone number")}
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  {t(
                    "channel:add-phone-number-description",
                    "Here you can see and change your channel configuration."
                  )}
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="overflow-hidden bg-white shadow sm:rounded-md">
                <APIErrors
                  APIError={backendError}
                  APIValidationErrors={backendValidationErrors}
                  fieldTranslations={meta.FIELDS}
                />

                {hasAtLeastOneActiveChannel && (
                  <>
                    {session?.account.current_subscription ? (
                      <div className="rounded-t-md bg-blue-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon
                              className="h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm text-blue-700">
                              {t(
                                "channel:billing-add-more-channels-notice",
                                "You're about to add an extra channel to your account. Once connected we'll automatically charge your credit card for $50 USD/mo, prorated."
                              )}
                            </p>
                            <p className="mt-3 text-sm">
                              <a
                                href="https://hilos.io/pricing"
                                target="_blank"
                                rel="noreferrer"
                                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                              >
                                {t("learn-more", "Learn more")}
                                <span aria-hidden="true"> &rarr;</span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="rounded-t-md bg-blue-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon
                              className="h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm text-blue-700">
                              {t(
                                "channel:billing-add-more-channels-subscribe-first",
                                "Start your Hilos subscription to add additional WhatsApp channels."
                              )}
                            </p>
                            <p className="mt-3 text-sm">
                              <Link
                                to={buildRoute("config-account-subscription")}
                                target="_blank"
                                rel="noreferrer"
                                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                              >
                                {t("subscribe-now", "Subscribe now")}
                                <span aria-hidden="true"> &rarr;</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="px-4 pt-5 space-y-4">
                  <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      {t("channel:360-dialog.setup", "360Dialog Setup")}
                    </h2>
                    {d360Channels && d360Channels.length > 0 ? (
                      <div className="rounded-md bg-green-50 p-4 mt-2">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-green-800">
                              {t(
                                "channel:360-dialog.setup-success",
                                "Connection successful!"
                              )}
                            </h3>
                            <div className="mt-2 text-sm text-green-700">
                              <div>
                                {t(
                                  "channel:360-dialog.setup-success-detail",
                                  "We'll set up channel"
                                )}{" "}
                                <span className="font-medium">
                                  {d360Channels[0].name} -{" "}
                                  {formatPhone(
                                    "+" + d360Channels[0].number,
                                    true,
                                    true
                                  )}
                                </span>
                                .
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="mt-1 mr-4 text-sm text-gray-500 flex items-center">
                        <LinkIcon className="h-5 w-5 mr-1" />
                        {t(
                          "channel:360-dialog.setup-instructions",
                          "Connect to 360Dialog to add a phone number to Hilos"
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={classNames(
                    "py-3",
                    d360Channels && d360Channels.length > 0
                      ? "hidden"
                      : "px-4 mt-2"
                  )}
                >
                  <ConnectButton
                    label={t("channel:360-dialog.setup-button", "Connect")}
                    partnerId="peRGSUPA"
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                    callback={(data) => handleLoadD360Channels(formik, data)}
                    queryParameters={{
                      // @ts-ignore
                      redirect_url: window.location.href,
                    }}
                  />
                </div>
                {d360Channels && (
                  <>
                    {loading ? (
                      <Loading />
                    ) : (
                      <ChannelForm disablePhoneEditing />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <ChannelProxyForm formik={formik} />

          {(session?.account.current_subscription ||
            !hasAtLeastOneActiveChannel) && (
            <div className="mt-5">
              <div className="text-right">
                <StateButton
                  isSubmitting={formik.isSubmitting}
                  submitted={submitted}
                  success={success}
                  disabled={
                    formik.errors &&
                    Object.getOwnPropertyNames(formik.errors).length > 0
                  }
                  submittingText={t("channel:saving", "Saving...")}
                  successText={t("channel:saved", "Channel saved!")}
                  initialText={
                    <>
                      <span className="fe fe-save mr-2"></span>
                      {t("channel:save-and-connect", "Save & Connect")}
                    </>
                  }
                />
              </div>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
}

export default Dialog360Form;
