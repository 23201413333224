import { Trans, useTranslation } from "react-i18next";
import SwitchField from "src/components/Form/SwitchField";
import AccountFormUpdate from "./AccountFormUpdate";
import * as meta from "./AccountMeta";

export default function AccountConversationListSettingsComponent() {
  const { t } = useTranslation();
  return (
    <AccountFormUpdate>
      {(_, formik) => (
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <Trans i18nKey="settings:conversation-list.title">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Conversation list settings
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Here you can set the information you want to see in the
                  conversation list.
                </p>
              </Trans>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <SwitchField
                  name={`inbox_settings.${meta.FIELDS.inbox_settings.show_conversation_status.key}`}
                  label={t(
                    meta.FIELDS.inbox_settings.show_conversation_status.label
                  )}
                  help={t(
                    meta.FIELDS.inbox_settings.show_conversation_status.help
                  )}
                />
              </div>
              <hr />
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <SwitchField
                  name={`inbox_settings.${meta.FIELDS.inbox_settings.show_channel.key}`}
                  label={t(meta.FIELDS.inbox_settings.show_channel.label)}
                  help={t(meta.FIELDS.inbox_settings.show_channel.help)}
                />
              </div>
              <hr />
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <SwitchField
                  name={`inbox_settings.${meta.FIELDS.inbox_settings.show_assignees.key}`}
                  label={t(meta.FIELDS.inbox_settings.show_assignees.label)}
                  help={t(meta.FIELDS.inbox_settings.show_assignees.help)}
                />
              </div>
              <hr />
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <SwitchField
                  name={`inbox_settings.${meta.FIELDS.inbox_settings.show_conversation_tags.key}`}
                  label={t(
                    meta.FIELDS.inbox_settings.show_conversation_tags.label
                  )}
                  help={t(
                    meta.FIELDS.inbox_settings.show_conversation_tags.help
                  )}
                />
              </div>
              <hr />
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <SwitchField
                  name={`inbox_settings.${meta.FIELDS.inbox_settings.show_contact_tags.key}`}
                  label={t(meta.FIELDS.inbox_settings.show_contact_tags.label)}
                  help={t(meta.FIELDS.inbox_settings.show_contact_tags.help)}
                />
              </div>
              <hr />
            </div>
          </div>
        </div>
      )}
    </AccountFormUpdate>
  );
}
