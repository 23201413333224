import { useTranslation } from "react-i18next";

interface StatusBadgeProps {
  status: string;
  reason?: string | null;
}

export default function WhatsAppTemplateStatusBadge({
  status,
  reason,
}: StatusBadgeProps) {
  const { t } = useTranslation();
  switch (status.toLowerCase()) {
    case "created":
      return (
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium uppercase text-indigo-800">
          {t("templates:status.created", "Created")}
        </span>
      );
    case "pending":
      return (
        <div className="flex flex-col py-0.5">
          <span className="w-fit self-center inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
            {t("templates:status.pending", "In review (WA)")}
          </span>
          <span className="text-gray-800 text-xs">
            {t(
              "templates:status.pending-time",
              "FB may take up to 48h to approve a template"
            )}
          </span>
        </div>
      );
    case "pending_rejection":
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
          {t("templates:status.pending-rejection", "Pending Rejection")}
        </span>
      );
    case "draft":
      return (
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium uppercase text-indigo-800">
          {t("templates:status.draft", "Draft")}
        </span>
      );
    case "submitted":
      return (
        <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-xs font-medium uppercase text-blue-800">
          {t("templates:status.submitted", "Submitted")}
        </span>
      );
    case "approved":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
          {t("templates:status.approved", "Approved")}
        </span>
      );
    case "rejected":
      return (
        <>
          <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-800">
            {t("templates:status.rejected", "Rejected")}
          </span>
          {reason && (
            <div className="pt-0.5 text-xs text-red-600">
              {t("templates:rejected-reason", "Reason")}: {reason}
            </div>
          )}
        </>
      );
    case "deleted":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-800">
          {t("templates:status.deleted", "Deleted")}
        </span>
      );
    case "disabled":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-800">
          {t("templates:status.disabled", "Disabled")}
        </span>
      );
    case "paused":
      return (
        <div>
          <div className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-xs font-medium uppercase text-yellow-800">
            {t("templates:status.paused", "Paused")}
          </div>
          <div>
            {t(
              "templates:status.paused-help",
              "Reasons: your customers were blocking or reporting your phone number after receiving your message or your message had a low read rate."
            )}
          </div>
        </div>
      );
  }
  return (
    <span className="w-fit self-center inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
      {t("templates:status.created", "Created")}
    </span>
  );
}
