import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { InformationCircleIcon, PlusIcon } from "@heroicons/react/outline";
import useHilosStore from "src/hooks/useHilosStore";

interface HubspotInstallComponentProps {
  forceConnectURL: string;
  exchange_error: unknown | undefined;
}

export default function HubspotInstallComponent({
  forceConnectURL,
  exchange_error,
}: HubspotInstallComponentProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  return (
    <>
      {exchange_error && (
        <div
          className="mb-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
          role="alert"
        >
          <Trans i18nKey="integrations:hubspot.auth-error">
            <span className="font-medium">An error ocurred!</span> We couldn't
            authenticate to HubSpot. Please try again{" "}
            <Link to={forceConnectURL} className="text-blue-500">
              here
            </Link>
          </Trans>
        </div>
      )}

      {session?.account.pricing_model_version === "v1" && (
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              {session?.account.current_subscription ? (
                <p className="text-sm text-blue-700">
                  {t(
                    "integrations:external-apps.non-free-app.hubspot.description",
                    "This integration is not included in your base plan. We'll add it to your suscription when you connect it."
                  )}
                </p>
              ) : (
                <p className="text-sm text-blue-700">
                  {t(
                    "integrations:external-apps.non-free-app.hubspot.trial",
                    "You can try this integration for free, once you start your subscription this will be added to your monthly cost."
                  )}
                </p>
              )}
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <a
                  href="https://hilos.io/pricing"
                  target="_blank"
                  rel="noreferrer"
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  {t("learn-more", "Learn more")}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="flex items-center justify-center">
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          <Link
            to={forceConnectURL}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusIcon className="h-5 w-5 md:-ml-1 md:mr-2" aria-hidden="true" />
            <span className="hidden md:block">{t("connect")}</span>
          </Link>
        </h2>
      </div>
    </>
  );
}
