import { useState } from "react";
import { EyeIcon, EyeOffIcon, LockClosedIcon } from "@heroicons/react/outline";
import TextInputField, { TextInputFieldProps } from "./TextInputField";

type PasswordFieldProps = Omit<TextInputFieldProps, "icon" | "action">;

function PasswordField({
  children,
  type = "submit",
  ...props
}: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextInputField
      {...props}
      type={showPassword ? "text" : "password"}
      icon={LockClosedIcon}
      className="password"
      action={
        <button
          className="relative inline-flex items-center space-x-2 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-0"
          type="button"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          ) : (
            <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </button>
      }
    />
  );
}

export default PasswordField;
