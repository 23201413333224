import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from "reactflow";
import { classNames } from "src/Helpers";
import useDropZone from "../../hooks/useDropZone";
import useFlowEdge from "../../hooks/useFlowEdge";
import { NEXT_OPTION_TYPES } from "../../utils";

function UnrechableForkStepEdge({
  id,
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  selected,
  source,
}: EdgeProps) {
  const { t } = useTranslation();
  const isUnreachable = useMemo(
    () => Boolean(data && data.isUnreachable),
    [data]
  );
  const { labelRef, isValidDropzone, onMouseEnter, onMouseLeave } = useFlowEdge(
    {
      id: source,
      selected,
      isUnreachable,
      enterClassName: "indigo-600",
      leaveClassName: "gray-400",
      allowMoveStepPath: true,
    }
  );
  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const label = useMemo(() => {
    if (!data || !data.label) {
      return "...";
    }

    if (
      data.isUnreachable ||
      (data.optionId && NEXT_OPTION_TYPES.includes(data.optionId))
    ) {
      return t(data.label);
    }

    return data.label || "...";
  }, [t, data]);

  return (
    <g
      id={id}
      data-type="unreachable_fork_step_edge"
      className={classNames(
        "group block",
        "opacity-60 group-hover:opacity-100 cursor-not-allowed"
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <path
        fill="none"
        style={style}
        className="stroke-transparent stroke-[40px]"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        id={id}
        fill="none"
        style={style}
        strokeDasharray={"6 4"}
        className={`stroke-2 ${"stroke-red-400 group-hover:stroke-red-400"}`}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${targetX}px,${edgeCenterY}px)`,
          }}
        >
          <div
            ref={(ref) => {
              labelRef.current = ref;
            }}
            className={classNames(
              "flex max-w-[220px] items-center justify-center rounded border-2 bg-white px-1 font-semibold",
              "border-red-600 text-red-600"
            )}
          >
            <span className="truncate break-all">{label}</span>
          </div>
        </div>
      </EdgeLabelRenderer>
      <g className="block" transform={`translate(${targetX}, ${targetY})`}>
        <rect
          x={-10}
          y={0}
          width={20}
          ry={4}
          rx={4}
          height={20}
          className={`fill-white stroke-2 ${"stroke-red-600"}`}
        />
        <text className={`select-none ${"fill-red-600"}`} y={15} x={-5}>
          x
        </text>
      </g>
    </g>
  );
}

export default UnrechableForkStepEdge;
