import { TFunction } from "i18next";
import * as yup from "yup1";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepBaseSchema } from "../flow";

export const FlowStepHubspotSchema = (t: TFunction) =>
  FlowStepBaseSchema(t).shape({
    custom_properties: yup
      .array()
      .of(yup.string())
      .min(1, t(ERROR_MESSAGES.MIN_NUMBER))
      .required(t(ERROR_MESSAGES.REQUIRED)),
    action_responses: yup
      .array()
      .of(
        yup.object({
          id: yup.string().notRequired(),
          created_on: yup.date(),
          data: yup.mixed().required(t(ERROR_MESSAGES.REQUIRED)),
        })
      )
      .min(1, t(ERROR_MESSAGES.MIN_NUMBER)),
  });
