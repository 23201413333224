import { Dispatch, SetStateAction, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { InformationCircleIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import { Formik } from "formik";
import { VesselApp } from "@hilos/types/private-schema";
import SwitchField from "src/components/Form/SwitchField";
import StateButton from "src/components/StateButton";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { UpdateVesselDetailsFn } from "src/hooks/useVesselExternalApp";
import i18n from "src/i18n";

interface VesselSettingsProps {
  connectedApp: VesselApp;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onUpdateVesselDetails: UpdateVesselDetailsFn;
  disabled: boolean;
}

export default function VesselSettings({
  connectedApp,
  setLoading,
  onUpdateVesselDetails,
  disabled,
}: VesselSettingsProps) {
  const { t } = useTranslation();
  const { language } = i18n;
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const timeout = useCleanTimeout();

  async function formSubmit(
    config: VesselApp,
    setSubmitting: (boolean) => void
  ) {
    try {
      setSubmitting(true);
      await onUpdateVesselDetails(config);
      setSuccess(true);
    } catch (e) {
      Sentry.captureException(e);
      setSuccess(false);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
      timeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    }
  }

  return (
    <Formik
      onSubmit={(values: VesselApp, { setSubmitting }) =>
        formSubmit(values, setSubmitting)
      }
      enableReinitialize={true}
      initialValues={connectedApp}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div
            className={`mt-10 sm:mt-4 ${
              disabled && "opacity-50 hover:cursor-not-allowed"
            }`}
          >
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <Trans i18nKey="integrations:vessel.settings.description">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Settings
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Control the configuration of the integration.
                    </p>
                  </Trans>
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="rounded-t-md bg-yellow-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <InformationCircleIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-yellow-700">
                            {t(
                              "integrations:external-apps.non-free-app.disable-notice",
                              "If you'd like to disable this integration and remove it from your subscription, reach out to us."
                            )}
                          </p>
                          <p className="mt-3 text-sm">
                            <a
                              href={
                                language &&
                                language.toLowerCase().indexOf("es") === 0
                                  ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
                                  : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos."
                              }
                              target="_blank"
                              rel="noreferrer"
                              className="whitespace-nowrap font-medium text-yellow-700 hover:text-blue-600"
                            >
                              {t("chat-with-support", "Chat with support")}
                              <span aria-hidden="true"> &rarr;</span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 space-y-4">
                        <SwitchField
                          label={t(
                            "integrations:vessel.feat_sync_external_users.label",
                            "Sync {{crm}} contact owners to Hilos contact default assignees",
                            {
                              crm: connectedApp.integration_id,
                            }
                          )}
                          name="feat_sync_external_users"
                          help={t(
                            "integrations:vessel.feat_sync_external_users.help",
                            "The {{crm}} user must also be a Hilos agent to be able to assign the conversations to them.",
                            {
                              crm: connectedApp.integration_id,
                            }
                          )}
                        />
                        <SwitchField
                          label={t(
                            "integrations:vessel.feat_sync_inbound_contacts.label",
                            "Sync inbound created contacts to {{crm}}",
                            {
                              crm: connectedApp.integration_id,
                            }
                          )}
                          name="feat_sync_inbound_contacts"
                          help={t(
                            "integrations:vessel.feat_sync_inbound_contacts.help",
                            "When a unregistered phone number sends a message a contact is created in Hilos, with this option enabled it will be pushed to {{crm}}.",
                            {
                              crm: connectedApp.integration_id,
                            }
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div
              className={`text-right ${
                disabled && "opacity-50 hover:cursor-not-allowed"
              }`}
            >
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                disabled={
                  disabled ||
                  (formik.errors &&
                    Object.getOwnPropertyNames(formik.errors).length > 0)
                }
                successText="Settings saved!"
                initialText={
                  <>
                    {t("save")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
