import * as Tooltip from "@radix-ui/react-tooltip";
import * as Yup from "yup";

import {
  BASE_PUBLIC_API_URL,
  PUBLIC_API_ROUTES,
  WIDGET_BASE_URL,
  buildAPIRoute,
} from "src/router/router";
import { Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";

import APIErrors from "src/components/base/APIErrors";
import StateButton from "src/components/StateButton";
import TextInputField from "src/components/Form/TextInputField";
import WidgetPreview from "./WidgetPreview";
import axios from "axios";
import { copyTextToClipboard } from "src/Helpers";
import { formatPhone } from "src/Helpers";
import { useTranslation } from "react-i18next";

interface FormValues {
  id?: string;
  channel: string;
  phone: string;
  default_text: string;
  button_1_text: string;
  button_2_text: string;
  button_3_text: string;
  bg_color: string;
  icon_color: string;
  padding: number;
  position: "BOTTOM_RIGHT" | "BOTTOM_LEFT";
}

const WidgetForm = ({ channel }) => {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [copied, setCopied] = useState(false);
  const formikInstanceRef = useRef<FormikProps<FormValues> | null>(null);
  const [initialValues, setInitialValues] = useState<FormValues | null>(null);

  useEffect(() => {
    const fetchButtonSettings = async () => {
      try {
        if (channel.whatsapp_button) {
          const response = await axios.get(
            buildAPIRoute(PUBLIC_API_ROUTES.WHATSAPP_BUTTON_DETAIL, {
              ":id": channel.whatsapp_button,
            }),
            {
              baseURL: BASE_PUBLIC_API_URL,
            }
          );
          if (response.data) {
            setInitialValues(response.data);
          } else {
            console.error("Missing whatsapp button");
          }
        } else {
          setInitialValues({
            channel: channel.id,
            phone: formatPhone(channel.channel_id, true, false),
            default_text: "",
            button_1_text: "",
            button_2_text: "",
            button_3_text: "",
            bg_color: "#72D66F",
            icon_color: "#FFFFFF",
            padding: 20,
            position: "BOTTOM_RIGHT",
          });
        }
      } catch (error) {
        console.error("Error fetching button settings:", error);
      }
    };

    fetchButtonSettings();
  }, [channel]);

  const validationSchema = Yup.object({
    default_text: Yup.string().required("Required"),
    bg_color: Yup.string().matches(/^#[0-9A-F]{6}$/i, "Invalid color"),
    icon_color: Yup.string().matches(/^#[0-9A-F]{6}$/i, "Invalid color"),
    padding: Yup.number().min(0).max(100),
  });

  const handleCopyCode = () => {
    const codeToCopy = `<script src="${WIDGET_BASE_URL}/widget.cjs.production.min.js" data-widget-id="${formikInstanceRef.current?.values.id}"></script>`;
    copyTextToClipboard(codeToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      let response;
      if (values.id) {
        // Update existing button
        response = await axios.put<FormValues>(
          buildAPIRoute(PUBLIC_API_ROUTES.WHATSAPP_BUTTON_DETAIL, {
            ":id": values.id,
          }),
          values,
          {
            baseURL: BASE_PUBLIC_API_URL,
          }
        );
      } else {
        // Create new button
        response = await axios.post<FormValues>(
          buildAPIRoute(PUBLIC_API_ROUTES.WHATSAPP_BUTTON_CREATE),
          values,
          {
            baseURL: BASE_PUBLIC_API_URL,
          }
        );
      }
      setInitialValues(response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  if (!initialValues) {
    return <div>Loading...</div>;
  }

  return (
    <div className="px-8">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          if (!formikInstanceRef.current) {
            formikInstanceRef.current = formik;
          }

          return (
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="my-4">
                <APIErrors
                  APIError={backendError}
                  APIValidationErrors={backendValidationErrors}
                  fieldTranslations={{
                    phone: "Phone",
                    default_text: "Default Text",
                    button_1_text: "Quick Response 1",
                    button_2_text: "Quick Response 2",
                    button_3_text: "Quick Response 3",
                    bg_color: "Background Color",
                    icon_color: "Icon Color",
                    padding: "Padding",
                    position: "Position",
                  }}
                />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="col-span-1 space-y-6">
                  <TextInputField
                    label={t("widget:phone", "Phone number")}
                    name="phone"
                    type="text"
                    disabled
                  />

                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div>
                          <TextInputField
                            label={t("widget:default_text", "Default text")}
                            name="default_text"
                            type="text"
                          />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent w-80 text-sm bg-slate-100 text-slate-600 px-3 py-2 rounded-md -mb-4"
                          sideOffset={5}
                        >
                          {t(
                            "widget:trigger-tooltip",
                            "If you wish to trigger a flow, be sure to check that the text exactly matches the trigger."
                          )}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>

                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div>
                          <TextInputField
                            label={t("widget:button-1-text", "Quick reply 1")}
                            name="button_1_text"
                            type="text"
                          />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent w-80 text-sm bg-slate-100 text-slate-600 px-3 py-2 rounded-md -mb-4"
                          sideOffset={5}
                        >
                          {t(
                            "widget:trigger-tooltip",
                            "If you wish to trigger a flow, be sure to check that the text exactly matches the trigger."
                          )}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div>
                          <TextInputField
                            label={t("widget:button-2-text", "Quick reply 2")}
                            name="button_2_text"
                            type="text"
                          />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent w-80 text-sm bg-slate-100 text-slate-600 px-3 py-2 rounded-md -mb-4"
                          sideOffset={5}
                        >
                          {t(
                            "widget:trigger-tooltip",
                            "If you wish to trigger a flow, be sure to check that the text exactly matches the trigger."
                          )}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div>
                          <TextInputField
                            label={t("widget:button-3-text", "Quick reply 3")}
                            name="button_3_text"
                            type="text"
                          />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent w-80 text-sm bg-slate-100 text-slate-600 px-3 py-2 rounded-md -mb-4"
                          sideOffset={5}
                        >
                          {t(
                            "widget:trigger-tooltip",
                            "If you wish to trigger a flow, be sure to check that the text exactly matches the trigger."
                          )}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                  <div className="grid grid-cols-3 gap-6">
                    <div className="flex flex-col">
                      <label
                        htmlFor="bg_color"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("widget:bg-color", "Background color")}
                      </label>
                      <input
                        name="bg_color"
                        type="color"
                        value={formik.values.bg_color}
                        onChange={formik.handleChange}
                        className="h-full w-full rounded-md"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="icon_color"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("widget:icon-color", "Icon color")}
                      </label>
                      <input
                        name="icon_color"
                        type="color"
                        value={formik.values.icon_color}
                        onChange={formik.handleChange}
                        className="h-full w-full rounded-md"
                      />
                    </div>
                    <TextInputField
                      label={t("widget:padding", "Padding around (px)")}
                      name="padding"
                      type="number"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("widget:position", "Widget position")}
                    </label>
                    <div className="mt-2 flex items-center space-x-4">
                      <img
                        src="https://cdn.prod.website-files.com/62e06c89457f36d21c5b726f/66db83c8dd607b08df3bf449_left.png"
                        alt="Left Position"
                        onClick={() =>
                          formik.setFieldValue("position", "BOTTOM_LEFT")
                        }
                        className={`cursor-pointer w-2/5 ${
                          formik.values.position === "BOTTOM_LEFT"
                            ? "border-2 rounded-md border-blue-500"
                            : ""
                        }`}
                      />
                      <img
                        src="https://cdn.prod.website-files.com/62e06c89457f36d21c5b726f/66db83c864d18200cd929242_right.png"
                        alt="Right Position"
                        onClick={() =>
                          formik.setFieldValue("position", "BOTTOM_RIGHT")
                        }
                        className={`cursor-pointer w-2/5 ${
                          formik.values.position === "BOTTOM_RIGHT"
                            ? "border-2 rounded-md border-blue-500"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex justify-between">
                    <Tooltip.Provider>
                      <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                          <button
                            type="button"
                            disabled={
                              formik.isSubmitting ||
                              formik.values.id === undefined
                            }
                            onClick={handleCopyCode}
                            className="p-2 text-sm bg-gray-300 rounded disabled:cursor-not-allowed disabled:bg-gray-100 transition-colors transition-duration-200 transition-ease-in-out"
                          >
                            {copied
                              ? t("widget:copied", "Code copied")
                              : t("widget:copy", "Copy code")}
                          </button>
                        </Tooltip.Trigger>
                        {(formik.isSubmitting ||
                          formik.values.id === undefined) && (
                          <Tooltip.Portal>
                            <Tooltip.Content
                              className="bg-slate-100 rounded-md p-2 shadow-md"
                              sideOffset={5}
                            >
                              {t(
                                "widget:save-first",
                                "Please save changes first"
                              )}
                              <Tooltip.Arrow className="fill-slate-100" />
                            </Tooltip.Content>
                          </Tooltip.Portal>
                        )}
                      </Tooltip.Root>
                    </Tooltip.Provider>
                    <StateButton
                      isSubmitting={formik.isSubmitting}
                      submitted={false}
                      success={false}
                      disabled={
                        formik.errors &&
                        Object.getOwnPropertyNames(formik.errors).length > 0
                      }
                      submittingText={t("widget:submitting", "Saving...")}
                      successText={t("widget:success", "Changes saved!")}
                      initialText={
                        <span>{t("widget:save", "Save changes")}</span>
                      }
                    />
                  </div>
                </div>
                <div className="relative col-span-1 bg-white border border-gray-400 aspect-square w-full rounded-md">
                  <WidgetPreview formValues={formik.values} />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WidgetForm;
