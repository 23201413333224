import {
  AtSymbolIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  CursorClickIcon,
  DocumentAddIcon,
  DocumentTextIcon,
  EmojiHappyIcon,
  HashtagIcon,
  InformationCircleIcon,
  LinkIcon,
  LocationMarkerIcon,
  PhoneIcon,
  PhotographIcon,
  VideoCameraIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import { getTranslationPayload as t } from "src/i18n";
import NumberIcon from "../../components/icons/NumberIcon";
import TextIcon from "../../components/icons/TextIcon";

export const ANSWER_TYPES = [
  {
    value: "FREE_TEXT",
    label: t("flows:steps.question.answer-types.free-text.label", "Free Text"),
    help: t(
      "flows:steps.question.answer-types.free-text.help",
      "Allow a free text response"
    ),
    icon: TextIcon,
  },
  {
    value: "SINGLE_OPTION",
    label: t(
      "flows:steps.question.answer-types.single-option.label",
      "Single Option"
    ),
    help: t(
      "flows:steps.question.answer-types.single-option.help",
      "Allows a single option from a list"
    ),
    icon: CheckIcon,
  },
  {
    value: "NUMBER",
    label: t("flows:steps.question.answer-types.number.label", "Number"),
    help: t(
      "flows:steps.question.answer-types.number.help",
      "Allows a valid number"
    ),
    icon: HashtagIcon,
  },
  {
    value: "LOCATION",
    label: t("flows:steps.question.answer-types.location.label", "Location"),
    help: t(
      "flows:steps.question.answer-types.location.help",
      "Allows a valid location and saves its latitude and longitude"
    ),
    icon: LocationMarkerIcon,
  },
  {
    value: "URL",
    label: t("flows:steps.question.answer-types.url.label", "URL"),
    help: t("flows:steps.question.answer-types.url.help", "Allows a valid URL"),
    icon: LinkIcon,
  },
  {
    value: "EMAIL",
    label: t("flows:steps.question.answer-types.email.label", "Email"),
    help: t(
      "flows:steps.question.answer-types.email.help",
      "Allows a valid email"
    ),
    icon: AtSymbolIcon,
  },
  {
    value: "FILE",
    label: t("flows:steps.question.answer-types.file.label", "File"),
    help: t(
      "flows:steps.question.answer-types.file.help",
      "Allows any file: document, image or video"
    ),
    icon: DocumentAddIcon,
  },
  {
    value: "DOCUMENT",
    label: t("flows:steps.question.answer-types.document.label", "Document"),
    help: t(
      "flows:steps.question.answer-types.document.help",
      "Allows a PDF file"
    ),
    icon: DocumentTextIcon,
  },
  {
    value: "IMAGE",
    label: t("flows:steps.question.answer-types.image.label", "Image"),
    help: t("flows:steps.question.answer-types.image.help", "Allows an image"),
    icon: PhotographIcon,
  },
  {
    value: "VIDEO",
    label: t("flows:steps.question.answer-types.video.label", "Video"),
    help: t("flows:steps.question.answer-types.video.help", "Allows a video"),
    icon: VideoCameraIcon,
  },
  {
    value: "PHONE",
    label: t("flows:steps.question.answer-types.phone.label", "Phone"),
    help: t(
      "flows:steps.question.answer-types.phone.help",
      "Allows a valid phone"
    ),
    icon: PhoneIcon,
  },
  {
    value: "BOOL",
    label: t("flows:steps.question.answer-types.bool.label", "Yes/No"),
    help: t(
      "flows:steps.question.answer-types.bool.help",
      "Allows a simple Yes/No answer"
    ),
    soon: true,
    icon: InformationCircleIcon,
  },
  {
    value: "DATE",
    label: t("flows:steps.question.answer-types.date.label", "Date"),
    help: t(
      "flows:steps.question.answer-types.date.help",
      "Allows a valid date, supports several formats"
    ),
    icon: CalendarIcon,
  },
  {
    value: "TIME",
    label: t("flows:steps.question.answer-types.time.label", "Time"),
    help: t(
      "flows:steps.question.answer-types.time.help",
      "Allows a valid time"
    ),
    soon: true,
    icon: ClockIcon,
  },
  {
    value: "DATETIME",
    label: t("flows:steps.question.answer-types.datetime.label", "Datetime"),
    help: t(
      "flows:steps.question.answer-types.datetime.help",
      "Requires both a date and time"
    ),
    soon: true,
    icon: CalendarIcon,
  },
  {
    value: "ANY",
    label: t(
      "flows:steps.question.answer-types.any-message.label",
      "Any message"
    ),
    help: t(
      "flows:steps.question.answer-types.any-message.help",
      "Allows any kind of message: text, media, etc"
    ),
    icon: CheckCircleIcon,
  },
];

export const ANSWER_OPTIONS_RENDER_TYPES = [
  {
    label: t(
      "flows:steps.question.answer-options-render-types.buttons.label",
      "Buttons"
    ),
    value: "BUTTONS",
    icon: CursorClickIcon,
    help: t(
      "flows:steps.question.answer-options-render-types.buttons.help",
      "Will show the options as buttons, max 3."
    ),
    multiple: false,
  },
  {
    label: t(
      "flows:steps.question.answer-options-render-types.list.label",
      "List of options"
    ),
    value: "LIST",
    icon: ViewListIcon,
    help: t(
      "flows:steps.question.answer-options-render-types.list.help",
      "Will show the options as a dropdown list, max 10."
    ),
    multiple: false,
  },
  {
    label: t(
      "flows:steps.question.answer-options-render-types.emojis.label",
      "Emojis"
    ),
    value: "EMOJIS",
    icon: EmojiHappyIcon,
    help: t(
      "flows:steps.question.answer-options-render-types.emojis.help",
      "Will show the options as a list of emoji - option text."
    ),
    multiple: true,
  },
  {
    label: t(
      "flows:steps.question.answer-options-render-types.numbers.label",
      "Numbers"
    ),
    value: "NUMBERS",
    icon: NumberIcon,
    help: t(
      "flows:steps.question.answer-options-render-types.numbers.help",
      "Will show the options as a list of number - option text."
    ),
    multiple: true,
  },
];

export const TIME_WINDOW_TYPES = [
  {
    label: t("flows:time-window.working-hours", "In working hours"),
    value: "IN_WORKING_HOURS",
  },
  {
    label: t("flows:time-window.custom", "Custom"),
    value: "CUSTOM",
  },
];
