import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowSmRightIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { CalendarIcon } from "@heroicons/react/outline";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { FormikProps } from "formik";
import { ParseResult } from "papaparse";
import { MessageBlastPublicEdit } from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import {
  BroadcastFormExtraFormikFields,
  BroadcastFormSteps,
} from "src/containers/broadcasts/BroadcastForm";
import BroadcastFormStepErrorsNotice from "src/containers/broadcasts/BroadcastFormStepErrorsNotice";
import * as meta from "src/containers/broadcasts/BroadcastMeta";
import WhatsAppTemplatePreview from "src/containers/wa/WhatsAppTemplatePreview";
import { initialTemplatePreviewValues } from "src/containers/wa/WhatsAppTemplatePreviewForm";
import DatePickerField from "src/components/Form/DatePickerField";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import StateButton from "src/components/StateButton";
import { useContactTags } from "src/hooks/useTags";

interface BroadcastFormStepReviewProps {
  setCurrentStep: (step: BroadcastFormSteps) => void;
  formik: FormikProps<MessageBlastPublicEdit & BroadcastFormExtraFormikFields>;
  isTutorialInProgress: boolean;
  selectedTemplate?: WhatsAppTemplate;
  success: boolean;
  submitted: boolean;
}

export default function BroadcastFormStepReview({
  formik,
  isTutorialInProgress,
  success,
  submitted,
  selectedTemplate,
  setCurrentStep,
}: BroadcastFormStepReviewProps) {
  const { t } = useTranslation();
  const { tags } = useContactTags();

  const numberOfMessages = useMemo(() => {
    const results = formik.values[meta.FIELDS.csv_file_parsed_contents.key] as
      | ParseResult<string[]>
      | undefined;
    if (!results?.data) {
      return 0;
    }
    return results.data.filter((result) => result[0]).length;
  }, [formik.values]);
  return (
    <div className="space-y-14">
      <div className="row mt-10 text-center">
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          {t("broadcasts:review-header.title")}
        </h1>
        <p className="mb-5 text-sm text-gray-500">
          {t(
            "broadcasts:review-header.description",
            "Configure the advanced options for the Broadcast"
          )}
        </p>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-4xl">
          <div
            className="grid grid-cols-1 sm:grid-cols-6"
            data-tour="broadcast-form"
          >
            <div className="col-span-3">
              {/* Left */}
              <div className="mx-4 space-y-4">
                {numberOfMessages > 0 ? (
                  <div className="mx-2 rounded-md bg-blue-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-blue-700">
                          <span className="font-medium">
                            {t(
                              "broadcasts:number-of-messages-to-send",
                              "You are about to send"
                            )}
                            :{" "}
                          </span>

                          <span className="font-bold">
                            {numberOfMessages} {t("messages", "Messages")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mx-2  rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">
                          {t(
                            "broadcasts:missing-recipients.title",
                            "Missing recipients"
                          )}
                        </h3>
                        <div className="text-sm text-yellow-700">
                          <p>
                            {t(
                              "broadcasts:missing-recipients.details",
                              "You need to select some recipients to send this broadcast."
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedTemplate && (
                  <WhatsAppTemplatePreview
                    template={selectedTemplate}
                    values={initialTemplatePreviewValues}
                  />
                )}
              </div>
            </div>

            <div className="col-span-3 mt-6 pl-4 sm:mt-0 sm:border-l sm:border-gray-200">
              {/* Right */}
              <div data-tour="broadcast-form-ad-opt-toggle" className="mt-4">
                <SwitchField
                  name={meta.FIELDS.see_advanced_options.key}
                  label={t(meta.FIELDS.see_advanced_options.label)}
                  help={t(meta.FIELDS.see_advanced_options.help)}
                />
              </div>
              {formik.values[meta.FIELDS.see_advanced_options.key] && (
                <div className="my-4 space-y-4">
                  <div data-tour="broadcast-form-send-on">
                    <DatePickerField
                      showTimeInput
                      placeholder={t(meta.FIELDS.start_on.placeholder)}
                      label={t(meta.FIELDS.start_on.label)}
                      name={meta.FIELDS.start_on.key}
                      help={t(meta.FIELDS.start_on.help)}
                      optional={meta.FIELDS.start_on.optional}
                      dateFormat="dd/MM/yyyy h:mm aa"
                      minDate={new Date()}
                      icon={CalendarIcon}
                    />
                  </div>
                  <div data-tour="broadcast-form-tags">
                    <SelectorField
                      isMulti
                      isCreatable
                      name={meta.FIELDS.contact_tags.key}
                      label={t(meta.FIELDS.contact_tags.label)}
                      help={t(meta.FIELDS.contact_tags.help)}
                      placeholder={t(meta.FIELDS.contact_tags.placeholder)}
                      options={tags.map((tag) => ({
                        label: tag.name,
                        value: tag.name,
                      }))}
                    />
                  </div>
                  <div data-tour="broadcast-send-close-toggle">
                    <SwitchField
                      label={t(meta.FIELDS.has_priority.label)}
                      name={meta.FIELDS.has_priority.key}
                      help={t(meta.FIELDS.has_priority.help)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BroadcastFormStepErrorsNotice formik={formik} />
      <div className="my-14">
        <hr className="my-5" />
        <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
          <div className="col justify-self-start">
            <button
              className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
              type="button"
              onClick={(_) => setCurrentStep("contacts")}
            >
              <ArrowSmLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              {t("contacts")}
            </button>
          </div>
          <div className="col text-center">
            <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
              {t("step-n-of-n", "Step {{step}} of {{total}}", {
                step: 3,
                total: 3,
              })}
            </h6>
          </div>
          <div className="justify-self-end">
            {!isTutorialInProgress && numberOfMessages > 0 && (
              <div className="text-right">
                <StateButton
                  isSubmitting={formik.isSubmitting}
                  submitted={submitted}
                  success={success}
                  submittingText={t("creating")}
                  successText={t("created")}
                  btnClasses="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-3 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                  btnClassesSuccess="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-3 text-sm font-medium leading-4 text-green-800 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  btnClassesError="inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-3 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                  initialText={
                    <>
                      {t("broadcasts:send-n-messages", {
                        messages: numberOfMessages,
                        defaultValue: "Send {{messages}} messages",
                      })}
                      <ArrowSmRightIcon
                        className="ml-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
