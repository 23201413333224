import { memo } from "react";
import { Handle, Position } from "reactflow";

const AddStepNode = () => {
  return (
    <div className={"mx-[6.875rem] h-1 w-5"}>
      <Handle
        className="invisible"
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className="invisible"
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>
  );
};

export default memo(AddStepNode);
