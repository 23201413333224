import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InformationCircleIcon, PlayIcon } from "@heroicons/react/outline";
import axios from "axios";
import { getIn, useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import { FlowData } from "@hilos/types/flow";
import { WABAFlowList } from "@hilos/types/private-schema";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import LineTabs from "src/components/LineTabs";
import StateButton from "src/components/StateButton";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useHilosStore from "src/hooks/useHilosStore";
import useWhatsAppFlows from "src/hooks/useWhatsAppFlows";
import { formatPhone } from "src/Helpers";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { getWhatsAppFlowData } from "../../../helpers/steps/wa_flow";
import { useStepField } from "../../../hooks/useStepField";
import FlowAdvancedOptionsDisclosure from "../../FlowBuilderAdvancedOptionsDisclosure";
import FlowBuilderMaxAnswerAttempts from "../../FlowBuilderMaxAnswerAttempts";
import FlowBuilderMsgFail from "../../FlowBuilderMsgFail";
import FlowBuilderWaitUntil from "../../FlowBuilderWaitUntil";
import TextAreaFieldWithVariables from "../../TextAreaFieldWithVariables";
import TextInputFieldWithVariables from "../../TextInputFieldWithVariables";

interface FlowBuilderStepWhatsAppFlowProps {
  id: string;
  index: number;
}

function FlowBuilderStepWhatsAppFlow({
  id,
  index,
}: FlowBuilderStepWhatsAppFlowProps) {
  const [t] = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { session } = useHilosStore();
  const [success, setSuccess] = useState(false);
  const { values } = useFormikContext<FlowData>();
  const { waFlows } = useWhatsAppFlows(values.channel as unknown as number);
  const timeout = useCleanTimeout();
  const [WAFlowSelected, setWAFlowSelected] = useStepField({
    index,
    name: "wa_flow_selected",
  });
  const [body, setBody] = useStepField({
    index,
    name: "body",
  });
  const [header, setHeader] = useStepField({
    index,
    name: "header_text",
  });
  const [footer, setFooter] = useStepField({
    index,
    name: "footer_text",
  });
  const [cta, setCTA] = useStepField({
    index,
    name: "cta",
  });

  const formatOptionLabel = ({ value, label, waFlow }) => {
    return (
      <div className="py-2">
        <div className="flex items-center justify-between">
          {waFlow && (
            <>
              <div>
                <h4 className="break-all font-medium">{label}</h4>
              </div>
            </>
          )}
        </div>
        <div className="truncate text-xs space-x-1">
          {waFlow &&
            waFlow.categories.map((category) => (
              <span
                className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-600 ring-1 ring-inset ring-indigo-500/10"
                key={category}
              >
                {category}
              </span>
            ))}
        </div>
      </div>
    );
  };

  const waFlowOptions = useMemo(() => {
    return waFlows.results.map((f: WABAFlowList) => {
      return {
        label: f.name,
        value: f.id,
        waFlow: f,
      };
    });
  }, [waFlows]);

  const handleTestAction = useCallback(async () => {
    setIsSubmitting(true);
    const originalStep = getIn(values, `steps.${index}`, {});
    const currentStep = cloneDeep(originalStep);
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.WHATSAPP_FLOW_TEST, {
          ":id": WAFlowSelected.id,
        }),
        {
          step_id: currentStep.id,
          cta: currentStep.cta,
          header_text: currentStep.header_text,
          footer_text: currentStep.footer_text,
          flow_action: "navigate",
          flow_navigate_screen_id: currentStep.flow_navigate_screen_id,
          wa_flow: WAFlowSelected.id,
          data: currentStep.data,
          channel: values.channel,
          body: currentStep.body,
        }
      );
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setSubmitted(true);
      timeout.current = setTimeout(() => {
        setSuccess(false);
        setSubmitted(false);
      }, 2000);
    }
  }, [WAFlowSelected, index, timeout, values]);

  const availableWAFlowScreens = useMemo(() => {
    if (!WAFlowSelected) {
      return [];
    }
    return (
      (WAFlowSelected.json &&
        WAFlowSelected.json.screens
          .filter((screen) => !screen.terminal)
          .map((screen) => ({
            label: screen.title,
            value: screen.id,
          }))) ||
      []
    );
  }, [WAFlowSelected]);

  const handleWAFlowChange = useCallback(
    async (nextWaFlowId) => {
      const details = await getWhatsAppFlowData(nextWaFlowId);
      setWAFlowSelected(details);
    },
    [setWAFlowSelected]
  );

  return (
    <div className="space-y-2">
      <SelectorField
        label={t("flows:steps.wa-flow.wa-flow.label", "WhatsApp flow to send")}
        name={`steps.${index}.wa_flow`}
        options={waFlowOptions}
        //@ts-ignore
        formatOptionLabel={formatOptionLabel}
        onSelect={(option) => {
          //@ts-ignore
          handleWAFlowChange(option.value);
        }}
      />

      <div className="pt-4">
        {!!WAFlowSelected && (
          <LineTabs
            labels={[
              t("flows:steps.wa-flow.tabs.build", "Configure"),
              t("flows:steps.wa-flow.tabs.flow-preview", "Flow preview"),
              t("flows:steps.wa-flow.tabs.test", "Test"),
            ]}
          >
            <div className="space-y-2 pt-4">
              {body && (
                <div className="flex items-center justify-around">
                  <div className="w-80 space-y-2">
                    <div className="space-y-2 rounded-lg bg-whatsapp-outgoing py-2 px-3 text-left text-sm text-gray-900">
                      {header && <div className="font-bold">{header}</div>}

                      <div className="whitespace-pre-line">{body}</div>

                      {footer && (
                        <div className="text-xs text-gray-500">{footer}</div>
                      )}

                      {cta && (
                        <div className="space-y-0.5">
                          <button
                            type="button"
                            className="w-full rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
                          >
                            {cta}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <TextInputFieldWithVariables
                path={`steps.${index}`}
                name="header_text"
                label={t(
                  "flows:steps.wa-flow.header_text.label",
                  "Message header text"
                )}
                placeholder={t(
                  "flows:steps.wa-flow.header_text.placeholder",
                  "Optional"
                )}
                type="text"
                optional
              />

              <TextAreaFieldWithVariables
                path={`steps.${index}`}
                name="body"
                label={t("flows:steps.message.body.label", "Message body")}
                placeholder={t(
                  "flows:steps.message.body.placeholder",
                  "Step 1 Welcome"
                )}
                rows={8}
                currentStepIndex={index}
              />

              <TextInputFieldWithVariables
                path={`steps.${index}`}
                name="footer_text"
                label={t(
                  "flows:steps.wa-flow.footer_text.label",
                  "Message footer text"
                )}
                placeholder={t(
                  "flows:steps.wa-flow.footer_text.placeholder",
                  "Optional"
                )}
                type="text"
                optional
              />

              <TextInputFieldWithVariables
                path={`steps.${index}`}
                name="cta"
                label={t("flows:steps.wa-flow.cta.label", "CTA Button text")}
                placeholder={t(
                  "flows:steps.wa-flow.cta.placeholder",
                  "Start now"
                )}
                type="text"
              />

              <SelectorField
                label={t(
                  "flows:steps.wa-flow.screen.label",
                  "Screen to start on"
                )}
                name={`steps.${index}.flow_navigate_screen_id`}
                help={t(
                  "flows:steps.wa-flow.screen.help",
                  "This will be the first screen shown to your contact when sent."
                )}
                options={availableWAFlowScreens}
              />

              <TextInputField
                name={`steps.${index}.answer_validation_message`}
                label={t(
                  "flows:steps.question.answer-validation-message.label",
                  "Answer validation message"
                )}
                help={t(
                  "flows:steps.question.answer-validation-message.help",
                  "If the recipient doesn't provide a value according to this question's answer type, we'll try again with this message."
                )}
                placeholder={t(
                  "flows:steps.question.answer-validation-message.placeholder",
                  "Please specify a proper value."
                )}
                type="text"
              />

              <FlowAdvancedOptionsDisclosure>
                <TextAreaFieldWithVariables
                  path={`steps.${index}`}
                  name="data"
                  label={t("flows:steps.wa-flow.data.label", "Flow data")}
                  placeholder={t(
                    "flows:steps.wa-flow.data.placeholder",
                    "Data required by this WhatsApp Flow"
                  )}
                  rows={8}
                />
              </FlowAdvancedOptionsDisclosure>
              <FlowBuilderMaxAnswerAttempts id={id} index={index} />

              <FlowBuilderWaitUntil id={id} index={index} />

              <FlowBuilderMsgFail id={id} index={index} />
            </div>
            <div className="pt-4">
              {WAFlowSelected.preview_url && (
                <iframe
                  src={WAFlowSelected.preview_url}
                  width="430"
                  height="800"
                  title="Flow preview"
                ></iframe>
              )}
            </div>
            <div className="pt-4">
              <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-blue-400"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      {t(
                        "flows:steps.wa-flow.send-explanation",
                        "Before testing, make sure you send a message to this flow's channel so we're able to send you this flow to your number ({{number}}).",
                        {
                          number: formatPhone(session?.phone),
                        }
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <StateButton
                isSubmitting={isSubmitting}
                submitted={submitted}
                success={success}
                submittingText={t(
                  "flows:steps.wa-flow.submitting",
                  "Sending flow"
                )}
                successText={t(
                  "flows:steps.wa-flow.send-example-success",
                  "Flow sent to your phone!"
                )}
                initialText={
                  <>
                    <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    {t("flows:steps.wa-flow.send-example", "Send test flow")}
                  </>
                }
                btnClasses="mt-4 inline-flex w-full items-center justify-center rounded-md border border-green-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-green-500 shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-500"
                btnClassesSuccess="mt-4 inline-flex w-full items-center justify-center rounded-md border border-green-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-green-500 shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-500"
                btnClassesError="mt-4 inline-flex w-full items-center justify-center rounded-md border border-red-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-red-500 shadow-sm hover:bg-red-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-red-500"
                onClick={handleTestAction}
              />
            </div>
          </LineTabs>
        )}
      </div>
    </div>
  );
}

export default FlowBuilderStepWhatsAppFlow;
