import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import DeleteObjectModal from "src/components/DeleteObjectModal";
import HeaderNavigation from "../../components/HeaderNavigation";
import useHilosStore from "../../hooks/useHilosStore";
import useUser from "../../hooks/useUser";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";

export default function AccountDisableConfirmation() {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const { logout } = useUser();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState("disable");
  const [selectedActionDescription, setSelectedActionDescription] = useState(
    t(
      "settings:account-disable-option.button",
      "Are you sure you'd like to disable your account? When you're ready to reactivate your Hilos account, shoot us an email at hey@hilos.io."
    )
  );

  const makeRequest = useCallback(
    async (
      url,
      formData,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    ) => {
      setBackendValidationErrors({});
      setBackendError("");
      try {
        await axios.post(url);
        setDeleteSubmitted(false);
        setDeleteSuccess(true);
        setShowDeleteModal(false);
        logout();
      } catch (err) {
        setDeleteSuccess(false);
        const errorAxios: axiosErr = err as axiosErr;
        if (errorAxios?.response?.status === 400) {
          Sentry.captureException(errorAxios);
          setBackendValidationErrors(errorAxios.response.data);
        } else {
          setBackendError(
            t("errors.error-general", "An error occurred, please try again.")
          );
        }
        setTimeout(() => {
          setDeleteSubmitted(false);
        }, 1000 * 2);
      } finally {
        setSubmitting(false);
        setDeleteSubmitted(false);
      }
    },
    [t, logout]
  );

  const handleDisable = useCallback(
    async (
      formData,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    ) => {
      const url = buildAPIRoute(API_ROUTES.ACCOUNT_DISABLE);
      makeRequest(
        url,
        formData,
        setSubmitting,
        setBackendValidationErrors,
        setBackendError
      );
    },
    [makeRequest]
  );

  const handleDelete = useCallback(
    async (
      formData,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    ) => {
      const url = buildAPIRoute(API_ROUTES.ACCOUNT_DELETE);
      makeRequest(
        url,
        formData,
        setSubmitting,
        setBackendValidationErrors,
        setBackendError
      );
    },
    [makeRequest]
  );

  const handleClick = useCallback(
    (action) => {
      setSelectedAction(action);
      setShowDeleteModal(true);
      if (action === "disable") {
        setSelectedActionDescription(
          t(
            "settings:account-disable-option.modal-description",
            "Are you sure you'd like to disable your account? When you're ready to reactivate your Hilos account, shoot us an email at hey@hilos.io."
          )
        );
      } else {
        setSelectedActionDescription(
          t(
            "settings:account-delete-option.modal-description",
            "Are you sure you'd like to completely delete your account? All your data will be LOST. If you want to use Hilos again, you'll need to sign up and start a new account."
          )
        );
      }
    },
    [t]
  );

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-100 pb-4">
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("settings"),
                url: buildRoute("config-account"),
              },
              {
                name: t("settings:close-account-title", "Close account"),
                url: buildRoute("disable-account"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h3 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                {session &&
                  t("settings:account-disable.title", "Disable your account")}
              </h3>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto mt-6 max-w-3xl">
            <p className="mt-1 text-sm text-gray-600 text-center">
              {t(
                "settings:account-disable-options",
                "You can temporarily disable your account or delete it completely."
              )}
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-10">
              <div className="space-y-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t(
                    "settings:account-disable-option.title",
                    "Disable your account"
                  )}
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  {t(
                    "settings:account-disable-option.description",
                    "When you plan on continuing your Hilos subscription later and would like to keep all your company's conversations, contacts, flows, templates and channels."
                  )}
                </p>

                <button
                  type="button"
                  onClick={() => handleClick("disable")}
                  className="inline-flex items-center rounded-md border border-hilos bg-white px-4 py-2 text-sm font-medium text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                >
                  {t(
                    "settings:account-disable-option.button",
                    "Disable my account"
                  )}
                </button>
              </div>

              <div className="space-y-3">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t(
                    "settings:account-delete-option.title",
                    "Delete your account"
                  )}
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  {t(
                    "settings:account-delete-option.description",
                    "This will completely delete your account's information, including all messages, contacts, flows, etc."
                  )}
                </p>
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        {t(
                          "settings:account-delete-option.irreversible-title",
                          "This is an irreversible action!"
                        )}
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        {t(
                          "settings:account-delete-option.irreversible-description",
                          "If you choose to delete your account, all data from your account will be completely lost. Make sure this is what you want before continuing."
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => handleClick("delete")}
                  className="inline-flex items-center rounded-md border border-hilos bg-white px-4 py-2 text-sm font-medium text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                >
                  {t(
                    "settings:account-delete-option.button",
                    "Delete account and all my data"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeleteObjectModal
        obj={session?.account}
        objDescription={session?.account.name as string}
        title={
          selectedAction === "disable"
            ? t("settings:account-disable-option.button", "Disable my account")
            : t(
                "settings:account-delete-option.button",
                "Delete account and all my data"
              )
        }
        deleteButtonText={
          selectedAction === "disable"
            ? t(
                "settings:account-disable-option.confirm-button",
                "Yes, disable it"
              )
            : t(
                "settings:account-delete-option.confirm-button",
                "Yes, delete all my data"
              )
        }
        actionDescription={selectedActionDescription}
        onDelete={selectedAction === "disable" ? handleDisable : handleDelete}
        deleteSuccess={deleteSuccess}
        deleteSubmitted={deleteSubmitted}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      />
    </>
  );
}
