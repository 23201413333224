import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { TagData } from "@hilos/types/conversation";
import { getSelectorStyles } from "src/components/Form/SelectorField";
import { BROWSER_IS_SAFARI_IOS } from "src/helpers/utils";

interface TagsProps {
  btnClass: string;
  spanClass: string;
  title: string;
  availableTags: TagData[];
  currentTags: TagData[];
  onRemoveTag: (tag: TagData) => () => void;
  onUpdateTags: (nextTags: TagData[]) => void;
}

export default function Tags({
  btnClass,
  spanClass,
  title,
  availableTags,
  currentTags,
  onRemoveTag,
  onUpdateTags,
}: TagsProps) {
  const { t } = useTranslation();

  const styles = useMemo(() => getSelectorStyles(), []);

  const options = useMemo(() => {
    const currentTagNames = currentTags.map((currentTag) => currentTag.name);

    return availableTags.map((tag) => ({
      label: tag.name,
      value: tag.id,
      isDisabled: currentTagNames.includes(tag.name),
    }));
  }, [availableTags, currentTags]);

  const handleChange = useCallback(
    (nextOption) => {
      const nextTags = currentTags || [];

      if (nextOption) {
        nextTags.push({ name: nextOption.label, id: 0 });
      }

      onUpdateTags(nextTags.map((tag) => ({ ...tag, id: 0 })));
    },
    [currentTags, onUpdateTags]
  );

  const handleCreateOption = useCallback(
    (nextOptionName) => {
      const nextTags = currentTags || [];

      if (nextOptionName) {
        nextTags.push({ name: nextOptionName, id: 0 });
      }

      onUpdateTags(nextTags.map((tag) => ({ ...tag, id: 0 })));
    },
    [currentTags, onUpdateTags]
  );

  return (
    <div className="flex flex-col px-3 space-y-1">
      <div className="text-xs font-medium uppercase tracking-wide text-gray-500 mb-1">
        {title}
      </div>
      <div className="w-full">
        <CreatableSelect
          value={null}
          menuShouldBlockScroll
          menuPosition={BROWSER_IS_SAFARI_IOS ? "absolute" : "fixed"}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          styles={styles}
          placeholder={t(
            "components:tags.placeholder",
            "Type to search or create tags"
          )}
          formatCreateLabel={(inputValue) =>
            t("components:tags.create", 'Create "{{inputValue}}"', {
              inputValue,
            })
          }
          onChange={handleChange}
          onCreateOption={handleCreateOption}
          options={options}
        />
      </div>
      <div className="flex flex-wrap items-center gap-1 pt-1">
        {currentTags.map((tag, idx) => (
          <span
            key={JSON.stringify(tag) + idx}
            className={`inline-flex items-center rounded-full py-0.5 pl-2.5 pr-1 text-sm font-medium ${spanClass}`}
          >
            {tag.name}
            <button
              type="button"
              className={`ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none ${btnClass}`}
              onClick={onRemoveTag(tag)}
            >
              <span className="sr-only">Remove tag</span>
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}

export const conversationTagsColorClasses = {
  spanClass: "bg-pink-100 text-pink-800",
  btnClass:
    "text-pink-400 hover:bg-pink-200 hover:text-pink-500 focus:bg-pink-500 focus:text-white",
};

export const contactTagsColorClasses = {
  spanClass: "bg-indigo-100 text-indigo-700",
  btnClass:
    "text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white",
};
