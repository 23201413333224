import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import { axiosErr } from "@hilos/types/axios";
import { Channel } from "@hilos/types/private-schema";
import HeaderNavigation from "src/components/HeaderNavigation";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useHilosStore from "src/hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import TechProviderConnectingModal from "./TechProviderConnectingModal";
import TechProviderOnboardingInstructions from "./TechProviderOnboardingInstructions";

export default function TechProviderOnboarding() {
  const { t } = useTranslation();
  const { session, reloadSession } = useHilosStore();
  const [searchParams] = useSearchParams();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(searchParams.get("error") as string);
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [connectionStatus, setConnectionStatus] = useState<
    "CONNECTING" | "SUCCESS" | "ERROR" | "NOT_STARTED"
  >("NOT_STARTED");
  const timeout = useCleanTimeout();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const posthog = usePostHog();

  const sessionInfoListener = (event) => {
    if (event.origin !== "https://www.facebook.com") return;
    console.log(event);
    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        // if user finishes the Embedded Signup flow
        posthog.capture(`wa-embedded-signup event ${data.event}`);
        if (data.event === "FINISH") {
          // TODO: Do something with Posthog to track this
          const { phone_number_id, waba_id } = data.data;
        }
        // if user cancels the Embedded Signup flow
        else {
          // TODO: Do something with Posthog to track this
          const { currentStep } = data.data;
        }
      }
    } catch {
      // Don’t parse info that’s not a JSON
    }
  };

  window.addEventListener("message", sessionInfoListener);

  const exchangeCodeForAccessToken = useCallback(
    async (code) => {
      setConnectionStatus("CONNECTING");
      setError("");
      setShowModal(true);

      try {
        const response = await axios.post(
          buildAPIRoute(API_ROUTES.CLOUD_API_EXCHANGE_CODE),
          {
            code,
          }
        );
        const channels: Channel[] = response.data;
        setConnectionStatus("SUCCESS");
        reloadSession();
        if (channels.length === 1) {
          const channel = channels[0];
          setChannel(channel);

          timeout.current = setTimeout(() => {
            navigate(
              buildRoute("config-channel-cloud-api-details", {
                ":id": channel.id,
              })
            );
          }, 1000 * 2);
        } else {
          timeout.current = setTimeout(() => {
            navigate(buildRoute("config-channel"));
          }, 1000 * 2);
        }
      } catch (err) {
        posthog.capture(`wa-embedded-signup exchange error`);
        const errorAxios: axiosErr = err as axiosErr;
        if (errorAxios?.response?.status === 400) {
          setError(errorAxios?.response?.data as string);
        } else {
          setError(
            t(
              "channel:embedded-signup.generic-error",
              "Something went wrong and our team has been notified. Please try again later."
            )
          );
        }
        setConnectionStatus("ERROR");
      } finally {
        setSubmitting(false);
        setShowModal(true);
      }
    },
    [t, navigate, timeout, posthog, reloadSession]
  );

  const handleClose = () => {
    setShowModal(false);
    // window.location.reload();
  };

  const launchWhatsAppSignup = useCallback(() => {
    //@ts-ignore
    const FB = window.FB;
    setConnectionStatus("CONNECTING");
    setSubmitting(true);
    FB.login(
      function (response) {
        console.log(response);
        if (response.authResponse) {
          const code = response.authResponse.code;
          exchangeCodeForAccessToken(code);
        } else {
          posthog.capture(`wa-embedded-signup login cancelled`);
          const errorMsg = t(
            "channel:embedded-signup.flow-canceled",
            "Facebook login was cancelled or was not completed. Please try again!"
          );
          setError(errorMsg);
          searchParams.set("error", errorMsg);
          // window.location.reload();
        }
      },
      {
        config_id: process.env.REACT_APP_FB_LOGIN_CONFIG_ID, // configuration ID goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          sessionInfoVersion: 2, //  Receive Session Logging Info
          setup: {
            business: {
              name: session?.account.name,
              email: session?.email,
              // timezone: "UTC-08:00",
            },
          },
        },
      }
    );
  }, [session, exchangeCodeForAccessToken, t, searchParams, posthog]);

  return (
    <div className="h-screen overflow-y-auto bg-gray-50 pb-4">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: "Home",
              url: buildRoute("dashboard"),
            },
            {
              name: "Settings",
              url: buildRoute("config-account"),
            },
            {
              name: "WhatsApp Accounts",
              url: buildRoute("config-channel"),
            },
            {
              name: t("settings:tech-provider.title", "Connecting your number"),
              url: buildRoute("config-channel-cloud-api-onboarding"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              {t("settings:tech-provider.title", "Connecting your number")}
            </h2>
          </div>
        </div>
      </div>
      <TechProviderOnboardingInstructions
        launchWhatsAppSignup={launchWhatsAppSignup}
        submitting={submitting}
      />
      <TechProviderConnectingModal
        connectionStatus={connectionStatus}
        error={error}
        channel={channel}
        show={showModal}
        handleClose={handleClose}
      />
    </div>
  );
}
