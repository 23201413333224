import { InfiniteData } from "react-query";
import { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { isSameMinute, isValid } from "date-fns";
import { CursorPageData } from "@hilos/types/hilos";
import {
  ConversationContent,
  InboxContactRead,
} from "@hilos/types/private-schema";
import { getTimestamp } from "./date";
import { hasItems } from "./utils";

/** Gets the content history for the current conversation using pagination
 * @param pages - The pages of the current conversation
 * @see {@link CursorPageData} - The type of the pages
 * @see {@link ConversationContent} - The generic used for `results` in CursorPageData
 * @param currentPageIndex `number` - The current page index
 * @param currentIndex `number` - The current content index
 * @returns `object` with `currentContent`, `prevContent`, `nextContent` as {@link ConversationContent} or `null` if there is no content
 * @see {@link ConversationContent}
 */
export function getContentHistory(
  pages: (CursorPageData<ConversationContent> | null)[],
  currentPageIndex: number,
  currentIndex: number
): {
  currentContent: ConversationContent | null;
  prevContent: ConversationContent | null;
  nextContent: ConversationContent | null;
} {
  const currentPage = pages[currentPageIndex];
  const currentContent = currentPage && currentPage.results[currentIndex];
  let prevContent: ConversationContent | null = null;
  let nextContent: ConversationContent | null = null;

  if (currentIndex > 0) {
    prevContent =
      (currentPage && currentPage.results[currentIndex - 1]) || null;
  } else if (currentPageIndex > 0) {
    const prevPage = pages[currentPageIndex - 1];
    prevContent =
      (prevPage && prevPage.results[prevPage.results.length - 1]) || null;
  }

  if (currentPage && currentIndex < currentPage.results.length - 1) {
    nextContent = currentPage && currentPage.results[currentIndex + 1];
  } else if (currentPageIndex < pages.length - 1) {
    const nextPage = pages[currentPageIndex + 1];
    nextContent = (nextPage && nextPage.results[0]) || null;
  }

  return { currentContent, prevContent, nextContent };
}

/** Gets the timestamp from a content passed
 * @param content {@link ConversationContent} or `null` - The content to get the timestamp from, receives
 * @returns The timestamp as {@link Date} or `null` if there is no content
 */
export function getTimestampFromContent(content: ConversationContent | null) {
  if (!content) {
    return null;
  }
  if (content.message) {
    return getTimestamp(content.message.timestamp);
  }
  if (content.event && content.event.timestamp) {
    return getTimestamp(content.event.timestamp);
  }
  return null;
}

/** Determines whether to show the message details in the conversation view.
 * @param currentContent {@link ConversationContent} or `null` - The current message content.
 * @param prevContent {@link ConversationContent} or `null` - The previous message content.
 * @param currentTimestamp {@link Date} or `null` - The current message timestamp.
 * @param prevTimestamp {@link Date} or `null` - The previous message timestamp.
 * @returns A `boolean` indicating whether to show the message details.
 */
export function getShowDetails(
  currentContent: ConversationContent | null,
  prevContent: ConversationContent | null,
  currentTimestamp: Date | null,
  prevTimestamp: Date | null
) {
  if (!prevContent || !currentContent) {
    return true;
  }

  if (prevContent.event && currentContent.message) {
    return true;
  }

  if (prevContent.message && currentContent.message) {
    if (prevContent.message.direction !== currentContent.message.direction) {
      return true;
    }

    if (
      prevContent.message.direction === "OUTBOUND" &&
      (!currentContent.message.sent_by ||
        !prevContent.message.sent_by ||
        prevContent.message.sent_by.id !== currentContent.message.sent_by.id)
    ) {
      return true;
    }

    if (
      !prevTimestamp ||
      (currentTimestamp && !isSameMinute(prevTimestamp, currentTimestamp))
    ) {
      return true;
    }
  }

  return false;
}

/** Finds a page result by ID in an array of pages.
 * @param id `string` - The ID to search for.
 * @param pages `array` - The array of pages to search through.
 * @see {@link CursorPageData} - The type of the pages with a generic type for `results`.
 * @param filterIndexBy - An optional function to filter the index by, the output should be a boolean.
 * @default null
 * @returns An object containing information about the found item's index and the page it was found on.
 */
export function findPageResultById<
  T extends InboxContactRead | ConversationContent
>(
  id: string,
  pages: CursorPageData<T>[],
  filterIndexBy: ((item: T) => boolean) | null = null,
  ignoreSumbittingItems: boolean = true
) {
  let pageIndex = 0;
  let itemIndex = 0;
  let prevPageIndex: number | null = null;
  let prevItemIndex: number | null = null;
  let lastPageIndex = 0;
  let hasItem = false;
  let hasNewIndex = false;
  let hasPageWithItems = false;

  for (const currentPageIndex in pages) {
    if (pages[currentPageIndex] && hasItems(pages[currentPageIndex].results)) {
      const itemsToDeleteByIndex: number[] = [];

      lastPageIndex = +currentPageIndex;

      if (!hasPageWithItems) {
        hasPageWithItems = true;
      }

      for (const currentItemIndex in pages[currentPageIndex].results) {
        const currentItem = pages[currentPageIndex].results[currentItemIndex];
        if (currentItem) {
          // @ts-ignore
          if (ignoreSumbittingItems && currentItem.submitting) {
            itemsToDeleteByIndex.push(+currentItemIndex);
            continue;
          }
          if (!hasItem) {
            if (currentItem.id === id) {
              hasItem = true;

              if (hasNewIndex) {
                prevPageIndex = +currentPageIndex;
                prevItemIndex = +currentItemIndex - itemsToDeleteByIndex.length;
              } else {
                pageIndex = +currentPageIndex;
                itemIndex = +currentItemIndex - itemsToDeleteByIndex.length;
              }

              break;
            }

            if (!hasNewIndex && filterIndexBy && filterIndexBy(currentItem)) {
              hasNewIndex = true;
              pageIndex = +currentPageIndex;
              itemIndex = +currentItemIndex - itemsToDeleteByIndex.length;
            }
          }
        }
      }

      if (itemsToDeleteByIndex.length > 0) {
        pages[currentPageIndex].results = pages[
          currentPageIndex
        ].results.filter(
          (_, currentItemIndex) =>
            !itemsToDeleteByIndex.includes(currentItemIndex)
        );
      }
    }

    if (hasItem) {
      break;
    }
  }

  return {
    pageIndex,
    itemIndex,
    prevPageIndex,
    prevItemIndex,
    lastPageIndex,
    hasItem,
    hasNewIndex,
    hasPageWithItems,
  };
}

/** Updates the pages of an infinite query with a new item. Outputs an array of pages.
 * @summary This function is used to update the pages of an infinite query with a new item.
 * @param item {@link InboxContactRead}, {@link ConversationContent} - The item to get the pages from.
 * @param prevData {@link InfiniteData} - The previous data of the infinite query.
 * @see {@link InfiniteData} and {@link CursorPageData} - The type of the previous data.
 * @param filterIndexBy - An optional function to filter the index by, the output should be a boolean.
 * @default null
 */
export function getNextPages<T extends InboxContactRead | ConversationContent>(
  item: T,
  prevData: InfiniteData<CursorPageData<T>> | undefined,
  filterIndexBy: ((item: T) => boolean) | null = null,
  ignoreSumbittingItems: boolean = true
) {
  const nextPages = JSON.parse(
    JSON.stringify((prevData && prevData.pages) || [])
  );
  const {
    pageIndex,
    itemIndex,
    prevPageIndex,
    prevItemIndex,
    lastPageIndex,
    hasPageWithItems,
    hasItem,
    hasNewIndex,
  } = findPageResultById(
    item.id,
    nextPages,
    filterIndexBy,
    ignoreSumbittingItems
  );

  if (hasNewIndex) {
    if (prevPageIndex !== null && prevItemIndex !== null) {
      if (nextPages[prevPageIndex].results[prevItemIndex]) {
        nextPages[prevPageIndex].results.splice(prevItemIndex, 1);

        nextPages[prevPageIndex].results = [
          ...nextPages[prevPageIndex].results,
        ];
      }
    }

    nextPages[pageIndex].results.splice(itemIndex, 0, item);
    nextPages[pageIndex].results = [...nextPages[pageIndex].results];
  } else if (hasItem) {
    nextPages[pageIndex].results[itemIndex] = item;
    nextPages[pageIndex].results = [...nextPages[pageIndex].results];
  } else {
    if (hasPageWithItems) {
      nextPages[lastPageIndex].results.push(item);
    } else {
      if (!nextPages.length || !nextPages[0]) {
        nextPages[0] = {
          next: null,
          previous: null,
          results: [],
        };
      }

      nextPages[0].results.unshift(item);
    }
  }

  return nextPages;
}

/** Function to update the pages of an infinite query with new ConversationContent
 * @param data {@link ConversationContent} - The conversation data to update the pages with
 * @returns An {@link InfiniteData} object containing {@link CursorPageData} of type {@link ConversationContent} - The updated pages
 */
export function updateConversationContentData(
  data: ConversationContent,
  ignoreSumbittingItems: boolean = true
) {
  return (
    prevData: InfiniteData<CursorPageData<ConversationContent>> | undefined
  ) => {
    const itemTimestamp = data.timestamp;
    const nextPageParams = [...((prevData && prevData.pageParams) || [])];

    const filterIndexBy = (item: ConversationContent) =>
      Boolean(
        itemTimestamp && item.timestamp && item.timestamp <= itemTimestamp
      );

    const nextPages = getNextPages(
      data,
      prevData,
      filterIndexBy,
      ignoreSumbittingItems
    );

    return {
      pageParams: nextPageParams,
      pages: nextPages,
    };
  };
}

/** Function to return the string for the days separator in a conversation.
 * Takes in a value of type {@link Date} and returns a string with the day of the week if the date is not today, otherwise returns "Today"
 * @param value a string to be passed to a {@link Date} constructor
 * @returns `string` with either the day of the week or "Today" or `null` if the value is not a valid {@link Date}
 */
export function getPaginationCursor(value: string) {
  const date = new Date(value);

  if (!isValid(date)) {
    return null;
  }

  const timestamp = date.toISOString();
  const position = encodeURIComponent(timestamp);

  const cursor = `r=1&p=${position}`;

  return window.btoa(cursor);
}

/** Function to check if a document can be shown in the DocViewer of the conversation directly
 * @param contentType `string` - The content type of the message to check against the docRenderers
 * @returns `boolean` - The result of the check (true if the content type is supported, false otherwise)
 */
export function isMsgContentTypeSupported(contentType: string): boolean {
  for (const docRenderer of DocViewerRenderers) {
    if (docRenderer.fileTypes.includes(contentType)) {
      return true;
    }
  }
  return false;
}
