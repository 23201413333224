import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import useHilosStore from "../../hooks/useHilosStore";

export default function AccountSubscriptionIncompleteNotice() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  if (!session) {
    return null;
  }

  return (
    <div className="bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            {t(
              "settings:subscription-incomplete-notice.title",
              "There was a problem processing your payment to start your subscription."
            )}
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            {t(
              "settings:subscription-incomplete-notice.subtitle",
              "To get started, select a plan and complete the payment process."
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
