import { Trans, useTranslation } from "react-i18next";
import { PhoneIcon, PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import { FieldArray } from "formik";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import AccountFormUpdate from "./AccountFormUpdate";
import * as meta from "./AccountMeta";

export default function AccountNotificationSettingsComponent() {
  const { t } = useTranslation();
  return (
    <AccountFormUpdate>
      {(_, formik) => (
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <Trans i18nKey="settings:notifications.title">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Notification settings
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Here you can set to be notified when sending more than X
                  messages through a Broadcast or Flow.
                </p>
              </Trans>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <SwitchField
                  name={`notification_settings.${meta.FIELDS.notification_settings.notify_with_toast.key}`}
                  label={t(
                    meta.FIELDS.notification_settings.notify_with_toast.label
                  )}
                  help={t(
                    meta.FIELDS.notification_settings.notify_with_toast.help
                  )}
                />

                <SwitchField
                  name={`notification_settings.${meta.FIELDS.notification_settings.require_confirmation_for_mass_send.key}`}
                  label={t(
                    meta.FIELDS.notification_settings
                      .require_confirmation_for_mass_send.label
                  )}
                  help={t(
                    meta.FIELDS.notification_settings
                      .require_confirmation_for_mass_send.help
                  )}
                />

                {formik.values.notification_settings &&
                  formik.values.notification_settings[
                    meta.FIELDS.notification_settings
                      .require_confirmation_for_mass_send.key
                  ] && (
                    <>
                      <TextInputField
                        name={`notification_settings.${meta.FIELDS.notification_settings.confirmation_min.key}`}
                        label={t(
                          meta.FIELDS.notification_settings.confirmation_min
                            .label
                        )}
                        type="number"
                      />

                      <div className="mt-4">
                        <FieldArray
                          name={`notification_settings.${meta.FIELDS.notification_settings.confirmation_phones.key}`}
                          render={(arrayHelpers) => (
                            <>
                              <h6 className="text-sm font-medium text-gray-700">
                                {t(
                                  meta.FIELDS.notification_settings
                                    .confirmation_phones.label
                                )}
                              </h6>
                              <ul className="mt-2">
                                {formik.values.notification_settings &&
                                  formik.values.notification_settings
                                    .confirmation_phones &&
                                  formik.values.notification_settings.confirmation_phones.map(
                                    (phone, idx) => (
                                      <li
                                        key={idx}
                                        className="mt-2 border-t border-gray-200 pt-2"
                                      >
                                        <div className="flex items-center">
                                          <div className="w-full">
                                            <TextInputField
                                              name={`notification_settings.${meta.FIELDS.notification_settings.confirmation_phones.key}.${idx}`}
                                              placeholder={t(
                                                meta.FIELDS
                                                  .notification_settings
                                                  .confirmation_phones
                                                  .placeholder
                                              )}
                                              type="text"
                                              icon={PhoneIcon}
                                            />
                                          </div>
                                          <div className="ml-4 grow-0">
                                            <button
                                              type="button"
                                              className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                              onClick={(_) =>
                                                arrayHelpers.remove(idx)
                                              }
                                            >
                                              <TrashIcon
                                                className="h-4 w-4"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div>
                                        </div>

                                        {formik.errors.notification_settings &&
                                          formik.errors[
                                            meta.FIELDS.notification_settings
                                              .confirmation_phones.key
                                          ] &&
                                          formik.errors[
                                            meta.FIELDS.notification_settings
                                              .confirmation_phones.key
                                          ][idx] &&
                                          typeof formik.errors[
                                            meta.FIELDS.notification_settings
                                              .confirmation_phones.key
                                          ][idx] === "string" && (
                                            <div className="mt-1.5 text-xs text-red-500">
                                              {
                                                formik.errors[
                                                  meta.FIELDS
                                                    .notification_settings
                                                    .confirmation_phones.key
                                                ][idx]
                                              }
                                            </div>
                                          )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              {formik.errors.notification_settings &&
                                formik.errors.notification_settings[
                                  meta.FIELDS.notification_settings
                                    .confirmation_phones.key
                                ] &&
                                typeof formik.errors.notification_settings[
                                  meta.FIELDS.notification_settings
                                    .confirmation_phones.key
                                ] === "string" && (
                                  <div className="mt-1.5 text-xs text-red-500">
                                    {
                                      formik.errors.notification_settings[
                                        meta.FIELDS.notification_settings
                                          .confirmation_phones.key
                                      ]
                                    }
                                  </div>
                                )}

                              <div className="mt-4">
                                <button
                                  type="button"
                                  className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                                  onClick={(_) => arrayHelpers.push("")}
                                >
                                  <PlusSmIcon
                                    className="mr-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  {t(
                                    "settings:notifications.add-phone",
                                    "Add phone"
                                  )}
                                </button>
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </AccountFormUpdate>
  );
}
