import { HTMLProps } from "react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function WhatsAppIcon(props: HTMLProps<HTMLElement>) {
  //@ts-ignore
  const icon = <FontAwesomeIcon icon={faWhatsapp} {...props} />;
  return icon;
}

export default WhatsAppIcon;
