import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureException } from "@sentry/react";
import QRCode from "qrcode";
import Loading from "src/components/Loading";
import useHilosStore from "src/hooks/useHilosStore";

interface FlowBuilderTestQRProps {
  channel_id: string;
  prefilled_text?: string;
}

export default function FlowBuilderTestQR({
  channel_id,
  prefilled_text,
}: FlowBuilderTestQRProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [QR, setQR] = useState("");
  const [url, setUrl] = useState<string | undefined>(undefined);

  const generateQRCode = useCallback(() => {
    const cleanedPhone = channel_id.replace(/\D/g, "");
    const url = `https://api.whatsapp.com/send?phone=${cleanedPhone}${
      prefilled_text ? "&text=" + prefilled_text : ""
    }`;

    setUrl(url);
    QRCode.toDataURL(
      url,
      {
        width: 120,
        margin: 2,
        color: {
          dark: "#486581",
          light: "#ffffff",
        },
      },
      (err, url) => {
        console.log(err, url);
        if (err) return captureException(err);
        setQR(url);
      }
    );
  }, [channel_id, prefilled_text]);

  useEffect(() => {
    generateQRCode();
  }, []);

  if (!session) {
    return null;
  }

  return (
    <div>
      {url ? (
        <div>
          <div>
            {QR ? (
              <img className="mx-auto rounded-lg p-3" src={QR} alt="qr-code" />
            ) : (
              <div className="m-2 flex h-[170px] min-h-[170px] min-w-[170px] animate-pulse flex-col items-center rounded-lg p-3 text-center">
                {t("loading")}
              </div>
            )}
          </div>
          <div className="text-center">
            <a
              href={url}
              rel="noreferrer"
              target="_blank"
              className="spread-shadow-gree h-fit mb-4 inline-block rounded-lg bg-green-600 py-3 px-4 text-xs font-semibold text-white justify-self-center"
            >
              <FontAwesomeIcon
                icon={faWhatsapp as IconProp}
                aria-hidden="true"
                className="mr-2"
              />
              {t("open-whatsapp-web", "Open in WhatsApp")}
            </a>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
