import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  Edge,
  Node,
  NodeMouseHandler,
  useOnSelectionChange,
  useStoreApi,
} from "reactflow";
import axios from "axios";
import { FlowNodeData, FlowStepData } from "@hilos/types/flow";
import { FlowVersionDetailRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { getNodesFromData, getUpdatedSteps } from "../utils";
import { SelectedItemState } from "./useFlowLayout";

interface UseFlowViewerParams {
  flowVersion: FlowVersionDetailRead;
  flowExecutionId?: string;
  flowExecutionContactId?: string;
  selectedItem: SelectedItemState;
  onSelectItem: (selectedItem: SelectedItemState) => void;
  onUpdateTransitions: (nodes: Node<FlowNodeData>[], edges: Edge[]) => void;
}

async function fetchFlowAnalyticsData({ queryKey }) {
  const [_, id, flowExecutionId, flowExecutionContactId] = queryKey;
  const { data } = await axios.get(
    buildAPIRoute(API_ROUTES.FLOW_VERSION_ANALYTICS, {
      ":id": id,
    }),
    {
      params: {
        flow_execution: flowExecutionId,
        flow_execution_contact: flowExecutionContactId,
      },
    }
  );

  return data;
}

function useFlowViewer({
  flowVersion,
  flowExecutionId,
  flowExecutionContactId,
  onSelectItem,
  onUpdateTransitions,
}: UseFlowViewerParams) {
  const store = useStoreApi();
  const [status, setStatus] = useState<string | undefined>("COMPLETED");
  const hasNodesInitializedRef = useRef(false);
  const stepsRef = useRef<FlowStepData[]>([]);

  const { data, isLoading } = useQuery(
    ["flow_viewer", flowVersion.id, flowExecutionId, flowExecutionContactId],
    fetchFlowAnalyticsData,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  useOnSelectionChange({
    onChange: ({ nodes: selectedNodes, edges: selectedEdges }) => {
      onSelectItem(selectedNodes[0] || selectedEdges[0] || null);
    },
  });

  const onNodeClick = useCallback<NodeMouseHandler>(
    (_, node) => {
      const { addSelectedNodes } = store.getState();
      if (!node.selected && addSelectedNodes) {
        addSelectedNodes([node.id]);
      }
    },
    [store]
  );

  useEffect(() => {
    const loadInitialValues = async () => {
      if (isLoading) {
        return;
      }
      if (!hasNodesInitializedRef.current) {
        hasNodesInitializedRef.current = true;
        let nextSteps: FlowStepData[] = [];
        if (flowVersion.first_step) {
          const { steps } = await getUpdatedSteps(
            flowVersion.first_step,
            flowVersion.steps as unknown as FlowStepData[]
          );
          nextSteps = steps;
        }

        stepsRef.current = nextSteps;
      }

      const extraNodeDataByStepId = {};

      if (data) {
        const hasMultipleContactExecutions = !(
          flowExecutionId && flowExecutionContactId
        );

        for (const [key, item] of Object.entries(data)) {
          const filteredItem = {};
          const filteredItemMax = {};
          let maxCount = 0;
          if (status) {
            Object.entries(item as Record<string, number>).forEach(
              ([statusKey, count]) => {
                maxCount = Math.max(maxCount, count);
                if (statusKey === status) {
                  filteredItem[statusKey] = count;
                }
              }
            );
            filteredItemMax[status] = maxCount;
          }
          extraNodeDataByStepId[key] = {
            executions_by_status: filteredItem,
            max_executions_by_status: filteredItemMax,
            has_multiple_contact_executions: hasMultipleContactExecutions,
          };
        }
      }
      const { nodes: nextNodes, edges: nextEdges } = getNodesFromData({
        steps: stepsRef.current,
        triggerType: flowVersion.flow.trigger_type,
        firstStepId: flowVersion.first_step,
        allowAddStep: false,
        extraNodeDataByStepId,
      });

      onUpdateTransitions(nextNodes, nextEdges);
    };

    loadInitialValues();
  }, [
    flowVersion,
    data,
    onUpdateTransitions,
    isLoading,
    flowExecutionId,
    flowExecutionContactId,
    status,
  ]);

  return {
    status,
    setStatus,
    stepsRef,
    onNodeClick,
  };
}

export default useFlowViewer;
