import { Trans } from "react-i18next";
import * as yup from "yup";
import { OpenAISettings } from "@hilos/types/private-schema";
import { getTranslationPayload as t } from "src/i18n";


export const getInitialValues = (): OpenAISettings => {
  return {
    id: '',
    base_prompt: "",
    system_prompt: "",
    message_context_length: 1000,
    content_context_length: 2000,
    temperature: 0.0,
    api_key: "",
    api_key_ro: "",
    current_conversation_messages_count_for_context: 10,
    
  };
};

export const FIELDS = {
  base_prompt: {
    key: "base_prompt",
    label: t("integrations:chatgpt.settings.base-prompt.label", "Base Prompt"),
    optional: false,
  },
  system_prompt: {
    key: "system_prompt",
    label: t(
      "integrations:chatgpt.settings.system-prompt.label",
      "System Prompt"
    ),
    optional: false,
  },
  message_context_length: {
    key: "message_context_length",
    label: t(
      "integrations:chatgpt.settings.message-context-length.label",
      "Message Context Length"
    ),
    optional: false,
  },
  content_context_length: {
    key: "content_context_length",
    label: t(
      "integrations:chatgpt.settings.content-context-length.label",
      "Content Context Length"
    ),
    optional: false,
  },
  temperature: {
    key: "temperature",
    label: t("integrations:chatgpt.settings.temperature.label", "Temperature"),
    optional: false,
    placeholder: "0.0",
  },
  api_key: {
    key: "api_key",
    label: t("integrations:chatgpt.settings.api-key.label", "API Key"),
    optional: false,
    help: (
      <Trans i18nKey="integrations:chatgpt.settings.api-key.help">
        Get it{" "}
        <a
          href="https://platform.openai.com/account/api-keys"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-500"
        >
          from OpenAI here.
        </a>
      </Trans>
    ),
  },
  model: {
    key: "model",
    label: t("integrations:chatgpt.settings.model.label", "Model"),
    optional: false,
    placeholder: "gpt-3.5-turbo",
    // help: t("integrations:chatgpt.settings.model.help", "gpt-3.5-turbo"),
  },
  max_tokens: {
    key: "max_tokens",
    label: t("integrations:chatgpt.settings.max-tokens.label", "Max Tokens"),
    optional: false,
  },
  api_key_ro: {
    key: "api_key_ro",
    label: t("integrations:chatgpt.settings.api-key-ro", "API Key"),
    optional: false,
  },
  current_conversation_messages_count_for_context: {
    key: "current_conversation_messages_count_for_context",
    label: t(
      "integrations:chatgpt.settings.current_conversation_messages_count_for_context",
      "Conversation Messages Count for Context"
    ),
    optional: false,
  },
};

export const schema = yup.object().shape({
  id: yup.string(),
});