import { useEffect, useMemo, useRef, useState } from "react";
import { startOfDay } from "date-fns";
import {
  ConversationContent,
  InboxContactRead,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import MessageMediaModal from "src/components/MessageMediaModal";
import {
  getContentHistory,
  getShowDetails,
  getTimestampFromContent,
} from "src/helpers/conversation";
import useOnScreen from "src/hooks/useOnScreen";
import { CursorPageData } from "src/types/hilos";
import chatBackground from "../../../assets/img/Chat_Background.png";
import { HandleMountConversationContent } from "./Conversation";
import ConversationContentItem from "./ConversationContentItem";
import ConversationContentItemSeparator from "./ConversationContentItemSeparator";
import ConversationContentListScrollTo from "./ConversationContentListScrollToBottom";

interface ConversationContentListProps {
  pages: (CursorPageData<ConversationContent> | null)[];
  inboxContact: InboxContactRead;
  isUpdatingCursor: boolean;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
  setIsLastMessageFocused: (isLastMessageFocused: boolean) => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  hasFirstPageLoaded: boolean;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  handleSelectContextMessage: (content: ConversationContent) => void;
  handleSelectForwardMessage: (message: WhatsAppMessage) => void;
  focusedConversationContentId: string | null;
  onMountConversationContent: HandleMountConversationContent;
  onFocusMessage: (messageId: string) => Promise<void>;
  onCloseConversationSearch: () => void;
}

interface GroupedByDateReturn {
  content: ConversationContent;
  showDetails: boolean;
}

// TODO: Use and refactor InfiniteScroll component to standardize their use on all components
function ConversationContentList({
  pages,
  inboxContact,
  isUpdatingCursor,
  isFetchingNextPage,
  isFetchingPreviousPage,
  setIsLastMessageFocused,
  hasNextPage,
  hasPreviousPage,
  hasFirstPageLoaded,
  handleNextPage,
  handlePreviousPage,
  onMountConversationContent,
  focusedConversationContentId,
  handleSelectContextMessage,
  onFocusMessage,
  onCloseConversationSearch,
  handleSelectForwardMessage,
}: ConversationContentListProps) {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const nextPageLoadingRef = useRef(false);
  const previousPageLoadingRef = useRef(false);
  const [startOfList, startOfListRef, onScrollToStartOfList] = useOnScreen();
  const [endOfList, endOfListRef] = useOnScreen();
  const [showMediaModal, setShowMediaModal] = useState(true);
  const [selectedMessage, setSelectedMessage] = useState<
    WhatsAppMessage | undefined
  >(undefined);

  const groupedByDate = useMemo(
    () => [
      ...pages.reduce((nextGroupedByDate, page, currentPageIndex) => {
        for (const currentIndex in page?.results) {
          const { currentContent, prevContent } = getContentHistory(
            pages,
            currentPageIndex,
            +currentIndex
          );

          const currentTimestamp = getTimestampFromContent(currentContent);

          if (!currentTimestamp || !currentContent) {
            continue;
          }

          const prevTimestamp = getTimestampFromContent(prevContent);

          const showDetails = getShowDetails(
            currentContent,
            prevContent,
            currentTimestamp,
            prevTimestamp
          );

          const formattedDate = startOfDay(currentTimestamp).toISOString();
          const nextConversationContents =
            nextGroupedByDate.get(formattedDate) || [];

          nextConversationContents.push({
            content: currentContent,
            showDetails,
          });

          nextGroupedByDate.set(formattedDate, nextConversationContents);
        }

        return nextGroupedByDate;
      }, new Map<string, GroupedByDateReturn[]>()),
    ],
    [pages, isFetchingNextPage, isFetchingPreviousPage]
  );

  useEffect(() => {
    if (endOfList) {
      if (!nextPageLoadingRef.current && hasNextPage) {
        nextPageLoadingRef.current = true;
        handleNextPage();
      }
    }
  }, [endOfList, hasNextPage, handleNextPage]);

  useEffect(() => {
    if (startOfList) {
      if (!previousPageLoadingRef.current && hasPreviousPage) {
        previousPageLoadingRef.current = true;
        handlePreviousPage();
      }
      if (!hasPreviousPage) {
        setIsLastMessageFocused(true);
      }
    } else {
      setIsLastMessageFocused(false);
    }
  }, [
    startOfList,
    hasPreviousPage,
    handlePreviousPage,
    setIsLastMessageFocused,
  ]);

  useEffect(() => {
    nextPageLoadingRef.current = isFetchingNextPage;
  }, [isFetchingNextPage]);

  useEffect(() => {
    previousPageLoadingRef.current = isFetchingPreviousPage;
  }, [isFetchingPreviousPage]);

  if (isUpdatingCursor) {
    return <Loading />;
  }

  const handleSelectMedia = (message: WhatsAppMessage) => {
    setSelectedMessage(message);
    setShowMediaModal(true);
  };

  return (
    <div
      ref={listInnerRef}
      className="relative h-full flex flex-1 flex-col space-y-1.5 overflow-y-auto p-3 sm:px-4"
      style={{
        backgroundImage: `url(${chatBackground})`,
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <ConversationContentListScrollTo
        startOfList={startOfList}
        inboxContact={inboxContact}
        hasFirstPageLoaded={hasFirstPageLoaded}
        onScrollToStartOfList={onScrollToStartOfList}
        onCloseConversationSearch={onCloseConversationSearch}
      />
      <div ref={startOfListRef} />
      {isFetchingPreviousPage && <Loading />}
      {groupedByDate.map(([date, currentConversationContents]) => (
        <div
          className="relative flex flex-col gap-2"
          key={`${date}_${currentConversationContents.length}`}
        >
          <ConversationContentItemSeparator date={date} />
          <div className="flex flex-col-reverse gap-2">
            {currentConversationContents.map(({ content, showDetails }) => (
              <ConversationContentItem
                key={`${date}_${currentConversationContents.length}_${content.id}`}
                inboxContact={inboxContact}
                showDetails={showDetails}
                content={content}
                isFocused={content.id === focusedConversationContentId}
                onMountConversationContent={onMountConversationContent}
                handleSelectContextMessage={handleSelectContextMessage}
                onFocusMessage={onFocusMessage}
                handleSelectMedia={handleSelectMedia}
                handleSelectForwardMessage={handleSelectForwardMessage}
              />
            ))}
          </div>
        </div>
      ))}
      {isFetchingNextPage && <Loading />}
      <div ref={endOfListRef} />
      {selectedMessage && (
        <MessageMediaModal
          show={showMediaModal}
          setShow={setShowMediaModal}
          message={selectedMessage}
        />
      )}
    </div>
  );
}

export default ConversationContentList;
