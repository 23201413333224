import { useTranslation } from "react-i18next";
import { XIcon } from "@heroicons/react/outline";
import { Reorder } from "framer-motion";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import ReorderIcon from "src/components/icons/ReorderIcon";
import { classNames } from "src/Helpers";

interface WorkflowStateReorderItemProps {
  state: any;
  index: number;
  onDelete: () => void;
}

function WorkflowStateReorderItem({
  state,
  index,
  onDelete,
}: WorkflowStateReorderItemProps) {
  const [t] = useTranslation();

  return (
    <Reorder.Item value={state}>
      <tr className="flex items-start py-1 space-x-2">
        <td className="self-center">
          <ReorderIcon className="h-3 w-3 fill-gray-500 group-hover:fill-gray-800" />
        </td>
        <td className="grow">
          <TextInputField
            type="text"
            name={`states.${index}.name`}
            placeholder={t(
              "workflows:workflow-form.states.name.placeholder",
              "Done"
            )}
          />
        </td>
        <td className="w-32">
          <SelectorField
            name={`states.${index}.state_type`}
            options={["ACTIVE", "CLOSED"].map((stateType) => ({
              label: stateType,
              value: stateType,
            }))}
          />
        </td>
        <td className="w-11">
          <button
            type="button"
            className={classNames(
              "inline-flex w-full items-center justify-center rounded-md border border-red-500 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-700",
              "hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2",
              "disabled:hover:bg-gray-50 disabled:focus:ring-0 disabled:border-gray-300 disabled:text-gray-300"
            )}
            onClick={onDelete}
          >
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </td>
      </tr>
    </Reorder.Item>
  );
}

export default WorkflowStateReorderItem;
