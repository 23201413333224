import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";
import axios from "axios";
import {
  ConversationsSummaryDetailInPeriodData,
  InboxUsageDetailData,
} from "@hilos/types/analytics";
import {
  LocalTableCellFormat,
  LocalTableHeaderFormat,
} from "src/components/LocalTable";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";
import { API_ROUTES } from "../router/router";

export function useInboxUsageDetailAnalytics(params: string) {
  const fetchUsageDetail = useCallback(async () => {
    const url = API_ROUTES.ANALYTICS_INBOX_USAGE_DETAIL + "?" + params;
    const { data } = await axios.get<InboxUsageDetailData>(url);
    return data;
  }, [params]);

  const { data } = useQuery("inbox_usage_detail" + params, fetchUsageDetail, {
    refetchOnWindowFocus: false,
  });
  const { t } = useTranslation();
  const { formatWithLocale } = useDateFnsFormatLocale();

  const columnsTable = useMemo(() => {
    const columnHelper =
      createColumnHelper<ConversationsSummaryDetailInPeriodData>();
    const defaultColumns = [
      columnHelper.accessor(
        (row) => {
          const conversationStart = row?.conversation_start_date;
          if (conversationStart) {
            return formatWithLocale(
              new Date(conversationStart),
              "d/LLL/uuuu HH:MM:SS"
            );
          }
        },
        {
          id: "conversation-start",
          cell: (info) => (
            <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
          ),
          header: () => (
            <>
              <LocalTableHeaderFormat>
                {t(
                  "reports:detail.table.conversation-start",
                  "Conversation start"
                )}
              </LocalTableHeaderFormat>
            </>
          ),
        }
      ),
      columnHelper.accessor((row) => row?.phone, {
        id: "phone",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>{t("phone")}</LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.contact, {
        id: "contact",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>{t("contact")}</LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.contact_tags, {
        id: "contact_tags",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>{t("contact-tags")}</LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.agent, {
        id: "agent",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>{t("agents")}</LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.csat_result, {
        id: "customer-satisfaction",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:detail.table.customer-satisfaction", "CSAT Result")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.percentage, {
        id: "customer-satisfaction-percentage",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:detail.table.customer-satisfaction-percentage",
                "Rating Percentage"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.conversation_status, {
        id: "status-conversation",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:detail.table.status-conversation",
                "Status conversation"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.conversation_tags, {
        id: "conversation-tags",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("conversation-tags")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.conversation_direction, {
        id: "conversation-direction",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:detail.table.conversation-direction",
                "Conversation direction"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.conversation_source, {
        id: "conversation-source",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:detail.table.conversation-source",
                "Conversation source"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.conversation_source_name, {
        id: "conversation-source-name",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:detail.table.conversation-source-name",
                "Conversation source name"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.duration, {
        id: "duration",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t("reports:detail.table.duration", "Duration (HH:MM:SS)")}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
      columnHelper.accessor((row) => row?.first_response_time, {
        id: "first-response-time",
        cell: (info) => (
          <LocalTableCellFormat>{info.getValue()}</LocalTableCellFormat>
        ),
        header: () => (
          <>
            <LocalTableHeaderFormat>
              {t(
                "reports:detail.table.first-response-time",
                "First response time (HH:MM:SS)"
              )}
            </LocalTableHeaderFormat>
          </>
        ),
      }),
    ];
    return defaultColumns;
  }, [formatWithLocale, t]);

  return {
    columnsTable,
    inboxUsageDetail:
      data ||
      ({
        1: {
          conversations: [[""]],
          start_on: "",
          end_on: "",
        },
      } as InboxUsageDetailData),
  };
}
