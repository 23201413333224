import Loading from "src/components/Loading";
import TagsSelector from "src/components/TagsSelector";
import UserSelector from "src/components/UserSelector";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/components/ui/accordion";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";

export default function WorkflowBoardTaskDetails({
  taskId,
  workflowId,
}: {
  taskId: string;
  workflowId: string;
}) {
  const { task, isLoading } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!task) {
    return null;
  }

  const { contact } = task.inbox_contact;

  return (
    <div className="flex flex-col min-w-64 sm:max-w-80 h-full overflow-hidden p-4 space-y-4 bg-gray-50 border-l border-gray-200">
      <dl className="text-sm">
        <div className="flex items-center justify-between">
          <dt className="font-medium text-muted-foreground">First name</dt>
          <dd className="font-semibold text-foreground">
            {contact.first_name}
          </dd>
        </div>
        <div className="flex items-center justify-between">
          <dt className="font-medium text-muted-foreground">Last name</dt>
          <dd className="font-semibold text-foreground">{contact.last_name}</dd>
        </div>
        <div className="flex items-center justify-between">
          <dt className="font-medium text-muted-foreground">Email</dt>
          <dd className="font-semibold text-foreground">
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </dd>
        </div>
        <div className="flex items-center justify-between">
          <dt className="font-medium text-muted-foreground">Source</dt>
          <dd className="font-semibold text-foreground">{contact.source}</dd>
        </div>
        {contact.external_url && (
          <div className="flex items-center justify-between">
            <dt className="font-medium text-muted-foreground">URL</dt>
            <dd className="font-semibold text-foreground">
              <a href={contact.external_url}>{contact.external_url}</a>
            </dd>
          </div>
        )}
      </dl>
      <UserSelector users={task.assigned_users} />
      <TagsSelector contact={contact} />
      <Accordion
        type="multiple"
        className="flex flex-col h-full w-full overflow-y-auto"
        defaultValue={["details", "assigned-to"]}
      >
        <AccordionItem value="related-tasks">
          <AccordionTrigger>Related tasks</AccordionTrigger>
          <AccordionContent></AccordionContent>
        </AccordionItem>
        <AccordionItem value="notes" className="border-none">
          <AccordionTrigger>Notes</AccordionTrigger>
          <AccordionContent></AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
