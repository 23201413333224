import {
  ExtendedRefs,
  FloatingContext,
  FloatingFocusManager,
  ReferenceType,
} from "@floating-ui/react";
import EmojiPickerReact, { EmojiClickData } from "emoji-picker-react";

interface EmojiPickerProps {
  isOpen: boolean;
  isMounted: boolean;
  styles: React.CSSProperties;
  refs: ExtendedRefs<ReferenceType>;
  context: FloatingContext<ReferenceType>;
  getReferenceProps: (userProps?: any) => Record<string, unknown>;
  getFloatingProps: (userProps?: any) => Record<string, unknown>;
  onSelectEmoji: (emoji: EmojiClickData) => void;
}

function EmojiPicker({
  isOpen,
  isMounted,
  styles,
  refs,
  context,
  getFloatingProps,
  getReferenceProps,
  onSelectEmoji,
}: EmojiPickerProps) {
  return (
    <div className="fixed">
      {isMounted && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={styles}
            {...getFloatingProps()}
            className="rounded-lg bg-white shadow-lg"
          >
            <EmojiPickerReact
              onEmojiClick={(emoji) => {
                onSelectEmoji(emoji);
              }}
              lazyLoadEmojis
              skinTonesDisabled
              previewConfig={{ showPreview: false }}
              searchDisabled
              height={300}
              width="100%"
            />
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
}

export default EmojiPicker;
