import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import {
  FlowExecutionContactStatusPayload,
} from "@hilos/types/flows/FlowExecutionContact";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES, buildAPIRoute } from "../router/router";
import { FlowExecutionContactReadDetail } from "@hilos/types/private-schema";

interface useFlowExecutionContactDetailArgs {
  flowExecutionContactId?: string;
}

export function useFlowExecutionContactCancelCurrent(
  inboxContactId: string,
) {
  const cancelCurrentFlowExecution = useCallback(async () => {
    const { data } = await axios.post(
      buildAPIRoute(API_ROUTES.FLOW_EXECUTION_CONTACT_CANCEL_CURRENT, {
        ":id": inboxContactId,
      })
    );
    return data;
  }, [inboxContactId]);

  return cancelCurrentFlowExecution;
}

export function useFlowExecutionContactDetail({
  flowExecutionContactId,
}: useFlowExecutionContactDetailArgs) {
  const fetchFlowExecutionContact = useCallback(async () => {
    const { data } = await axios.get<FlowExecutionContactReadDetail>(
      buildAPIRoute(API_ROUTES.FLOW_EXECUTION_CONTACT_DETAIL, {
        ":id": flowExecutionContactId,
      })
    );
    return data;
  }, [flowExecutionContactId]);

  const { data } = useQuery(
    ["flow_execution_contact_detail", flowExecutionContactId],
    fetchFlowExecutionContact
  );

  return data;
}

export default useFlowExecutionContactDetail;

export const useUpdateFlowExecutionContactDetailQuery = (
  flowExecutionContactId: string
) =>
  useCallback(
    (newFlowExecutionContactData: Partial<FlowExecutionContactReadDetail>) => {
      const currentData = queryClient.getQueryData<FlowExecutionContactReadDetail>([
        "flow_execution_contact_detail",
        flowExecutionContactId,
      ]);
      queryClient.setQueryData(
        ["flow_execution_contact_detail", flowExecutionContactId],
        {
          ...currentData,
          ...newFlowExecutionContactData,
        }
      );
    },
    [flowExecutionContactId]
  );

interface useFlowExecutionContactDetailsParams {
  flowExecutionContactId: string;
  onFlowExecutionContactStatusUpdateSuccess: (
    nextStatus: FlowExecutionContactStatusPayload
  ) => void;
}
export function useFlowExecutionContactUpdateDetails({
  flowExecutionContactId,
  onFlowExecutionContactStatusUpdateSuccess,
}: useFlowExecutionContactDetailsParams) {
  const updateFlowExecutionStatus = useCallback(
    async (payload: FlowExecutionContactStatusPayload) => {
      const { data } = await axios.patch<FlowExecutionContactStatusPayload>(
        buildAPIRoute(API_ROUTES.FLOW_EXECUTION_CONTACT_STATUS, {
          ":id": flowExecutionContactId,
        }),
        payload
      );
      return data;
    },
    [flowExecutionContactId]
  );

  const {
    mutateAsync: handleUpdateFlowExecutionContactStatus,
    isLoading: isSubmittingStatus,
  } = useMutation(updateFlowExecutionStatus, {
    onSuccess: onFlowExecutionContactStatusUpdateSuccess,
  });

  return {
    handleUpdateFlowExecutionContactStatus,
    isSubmittingStatus,
  };
}
