import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { useField } from "formik";
import { TagData } from "@hilos/types/conversation";
import { getSelectorStyles } from "src/components/Form/SelectorField";
import { BROWSER_IS_SAFARI_IOS, hasItems } from "src/helpers/utils";

interface FlowTagProps {
  btnClass: string;
  spanClass: string;
  name: string;
  title: string;
  availableTags: TagData[];
}

export default function FlowTag({
  btnClass,
  spanClass,
  name,
  title,
  availableTags,
}: FlowTagProps) {
  const { t } = useTranslation();
  const [field, _, helpers] = useField<string[]>(name);

  const tags = useMemo(() => field.value || [], [field.value]);

  const styles = useMemo(() => getSelectorStyles(), []);

  const options = useMemo(
    () =>
      availableTags.map((tag) => ({
        label: tag.name,
        value: tag.id,
        isDisabled: tags.includes(tag.name),
      })),
    [availableTags, tags]
  );

  const handleRemoveTag = useCallback(
    (tagToRemove: string) => {
      return () => helpers.setValue(tags.filter((tag) => tag !== tagToRemove));
    },
    [tags, helpers]
  );

  const handleChange = useCallback(
    (nextOption) => {
      if (nextOption) {
        helpers.setValue([...tags, nextOption.label]);
      }
    },
    [tags, helpers]
  );

  const handleCreateOption = useCallback(
    (nextOptionName) => {
      if (nextOptionName) {
        helpers.setValue([...tags, nextOptionName]);
      }
    },
    [tags, helpers]
  );

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex-1 text-xs font-medium uppercase tracking-wide text-gray-500">
        {title}
      </div>
      <div className="w-full">
        <CreatableSelect
          value={null}
          menuShouldBlockScroll
          menuPosition={BROWSER_IS_SAFARI_IOS ? "absolute" : "fixed"}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          styles={styles}
          placeholder={t(
            "components:tags.placeholder",
            "Type to search or create tags"
          )}
          formatCreateLabel={(inputValue) =>
            t("components:tags.create", 'Create "{{inputValue}}"', {
              inputValue,
            })
          }
          onChange={handleChange}
          onCreateOption={handleCreateOption}
          options={options}
        />
      </div>
      {hasItems(tags) && (
        <div className="flex flex-wrap items-center gap-1 pt-1">
          {tags.map((tag, idx) => (
            <span
              key={JSON.stringify(tag) + idx}
              className={`mr-1 inline-flex items-center rounded-full py-0.5 pl-2.5 pr-1 text-sm font-medium ${spanClass}`}
            >
              {tag}
              <button
                type="button"
                className={`ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none ${btnClass}`}
                onClick={handleRemoveTag(tag)}
              >
                <span className="sr-only">Remove tag</span>
                <svg
                  className="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
