import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowSmRightIcon, MailIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";
import StateButton from "src/components/StateButton";
import useHilosStore from "src/hooks/useHilosStore";

interface UserInvitationV2ConfirmationModalProps {
  formik: FormikProps<any>;
  success: boolean;
  submitted: boolean;
}

export default function UserInvitationV2ConfirmationModal({
  formik,
  success,
  submitted,
}: UserInvitationV2ConfirmationModalProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { session } = useHilosStore();

  const submitDisabled = useMemo(() => {
    return (
      formik.isSubmitting ||
      Object.getOwnPropertyNames(formik.errors).length > 0
    );
  }, [formik.errors, formik.isSubmitting]);

  const usersNotIncludedInCurrentPlan = useMemo(() => {
    return (
      session?.account.current_billed_users +
      formik.values.invitations.length -
      (session?.account.users_price?.included_items || 0)
    );
  }, [formik.values.invitations.length, session]);

  const usersToBeAdded = useMemo(() => {
    if (
      (session?.account.current_billed_users || 0) >
      (session?.account.users_price?.included_items || 0)
    ) {
      return formik.values.invitations.length;
    }
    return usersNotIncludedInCurrentPlan;
  }, [
    formik.values.invitations.length,
    session,
    usersNotIncludedInCurrentPlan,
  ]);

  useEffect(() => {
    if (!formik.isSubmitting && success) {
      setShow(false);
    }
  }, [formik.isSubmitting, success]);

  const submitButton = (
    <StateButton
      form="invite-user-form"
      isSubmitting={formik.isSubmitting}
      submitted={submitted}
      success={success}
      disabled={
        formik.isSubmitting ||
        submitDisabled ||
        Object.getOwnPropertyNames(formik.errors).length > 0
      }
      className={`flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 ${
        submitDisabled ? "disabled:opacity-50 cursor-not-allowed" : ""
      }`}
      submittingText={t("sending")}
      successText={t("sent")}
      initialText={
        <>
          {t("settings:users.invite.button", "Send invitations")}
          <ArrowSmRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
        </>
      }
    />
  );

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setShow(false)}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="flex text-gray-800 items-center font-bold">
                  <div className="h-10 w-10 rounded-full flex items-center justify-center text-hilos mr-4 bg-red-100">
                    <MailIcon className="h-6 w-6" />
                  </div>
                  <div>
                    <div className="text-lg">
                      {t(
                        "settings:users.invite.confirmation.title",
                        "Inviting {{newUsers}} additional user(s)",
                        { newUsers: usersToBeAdded }
                      )}
                    </div>
                    <div className="text-sm text-gray-500 font-normal">
                      {t(
                        "settings:users.invite.confirmation.description",
                        "This is a billable event."
                      )}
                    </div>
                  </div>
                </div>

                <div className="text-sm text-gray-900 my-4">
                  {t(
                    "settings:users.invite.confirmation.price-confirmation",
                    "Sending these invitations will result in us charging you {{newUserPrice}} (prorated) per extra user to your account.",
                    {
                      newUserPrice: `$${
                        session?.account.users_price?.currency_options[
                          session?.account.payment_currency
                        ]["unit_amount"] / 100
                      } ${session?.account.payment_currency.toUpperCase()}`,
                    }
                  )}
                </div>

                <div className="mt-8 sm:mt-6 flex flex-row-reverse space-x-2">
                  <div className="ml-2">{submitButton}</div>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setShow(false)}
                  >
                    {t("cancel", "Cancel")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {!session?.account.current_subscription ||
      usersNotIncludedInCurrentPlan < 1 ? (
        <>{submitButton}</>
      ) : (
        <button
          type="button"
          disabled={submitDisabled}
          onClick={() => setShow(true)}
          className={`ml-auto flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 ${
            submitDisabled ? "disabled:opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {t("settings:users.invite.button", "Send invitations")}
          <ArrowSmRightIcon className="ml-1 h-5 w-5" />
        </button>
      )}
    </>
  );
}
