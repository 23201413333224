import { memo, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Handle, NodeProps, Position, useUpdateNodeInternals } from "reactflow";
import { TrashIcon } from "@heroicons/react/outline";
import { FlowNodeData } from "@hilos/types/flow";
import useWrappedSteps from "src/hooks/useWrappedSteps";
import { classNames } from "src/Helpers";
import { STEP_TYPES_WITHOUT_NEXT } from "../../constants/steps";
import useStepNode from "../../hooks/useStepNode";

function StepNode({
  id,
  data,
  targetPosition,
  sourcePosition,
  selected,
}: NodeProps<FlowNodeData>) {
  const [t] = useTranslation();
  const updateNodeInternals = useUpdateNodeInternals();
  const { label, nextStepLabel, error, allowDeleteStep, handleDelete } =
    useStepNode({
      index: data.index,
    });

  const { STEP_TYPES_DYNAMIC } = useWrappedSteps();

  const step = useMemo(
    () => (data.type && STEP_TYPES_DYNAMIC[data.type]) || null,
    [STEP_TYPES_DYNAMIC, data.type]
  );

  const handleDragStart = useCallback(
    (event) => {
      if (event.dataTransfer) {
        event.dataTransfer.setData("application/flow:type", "move");
        event.dataTransfer.setData("application/flow:step-id", id);
        event.dataTransfer.setData("application/flow:step-type", data.type);
        event.dataTransfer.effectAllowed = "move";
      }
    },
    [id, data.type]
  );

  const [contanierColor, titleColor, labelColor] = useMemo(() => {
    if (error) {
      return [
        selected ? "bg-red-100" : "bg-red-50",
        "text-red-800",
        "text-red-700",
      ];
    }

    if (selected) {
      return ["bg-blue-100", "text-blue-800", "text-blue-700"];
    }

    return ["bg-white", "text-gray-600", "text-gray-700"];
  }, [selected, error]);

  useEffect(() => {
    updateNodeInternals(id);
  }, [id, updateNodeInternals, data.options]);

  if (!step) {
    return null;
  }

  return (
    <div
      draggable
      id={id}
      className={classNames(
        "nopan group mx-10 w-40 rounded-lg p-2 shadow-md",
        contanierColor
      )}
      onDragStart={handleDragStart}
    >
      <Handle
        className="pointer-events-none"
        type="target"
        position={targetPosition || Position.Top}
        isConnectable={false}
      />

      {data.options
        ? data.options.map((option) => (
            <Handle
              key={option.id}
              className="pointer-events-none"
              type="source"
              id={option.id}
              position={sourcePosition || Position.Bottom}
              isConnectable={false}
            />
          ))
        : data.type &&
          !STEP_TYPES_WITHOUT_NEXT.includes(data.type) && (
            <Handle
              className="pointer-events-none"
              type="source"
              position={sourcePosition || Position.Bottom}
              isConnectable={false}
            />
          )}
      <div className="pointer-events-none flex-1">
        <div className="flex flex-row items-center">
          <div className="flex flex-row flex-1 w-28 items-center">
            <step.icon
              className={classNames(
                "shrink-0",
                nextStepLabel && label ? "h-4 w-4" : "h-5 w-5",
                titleColor
              )}
            />
            <div className="flex flex-col px-1 line-clamp-2">
              <h1
                className={classNames(
                  "font-semibold truncate",
                  nextStepLabel ? "text-xs" : label ? "text-sm" : "text-md",
                  titleColor
                )}
              >
                {t(step.name)}
              </h1>
              {nextStepLabel && (
                <h1
                  className={classNames(
                    "font-bold truncate text-tiny w-28 pr-1",
                    titleColor
                  )}
                >
                  {nextStepLabel}
                </h1>
              )}
            </div>
          </div>
          <div className="shrink-0 flex-row hidden group-hover:flex gap-2 pointer-events-auto">
            {allowDeleteStep && (
              <button type="button" onClick={handleDelete}>
                <TrashIcon className={classNames("w-5 h-5", titleColor)} />
              </button>
            )}
          </div>
        </div>
        <p
          className={classNames("text-md line-clamp-2 font-medium", labelColor)}
        >
          {label}
        </p>
      </div>
    </div>
  );
}

export default memo(StepNode);
