import { useTranslation } from "react-i18next";
import { ReactFlowProvider } from "reactflow";
import { FlowVersionDetailRead } from "@hilos/types/private-schema";
import FlowViewer from "./FlowViewer";

interface FlowDetailProps {
  flowVersion: FlowVersionDetailRead;
  flowExecutionId?: string;
  flowExecutionContactId?: string;
}

function FlowDetail({
  flowVersion,
  flowExecutionId,
  flowExecutionContactId,
}: FlowDetailProps) {
  const { t } = useTranslation();

  if (!flowVersion) {
    return null;
  }

  return (
    <>
      <div className="flex flex-1 h-full flex-col justify-center text-center sm:hidden p-4">
        <h4>
          {t(
            "flows:mobile-not-available.title",
            "This page is not available on mobile devices."
          )}
        </h4>
        <p className="text-sm text-gray-500">
          {t(
            "flows:mobile-not-available.description",
            "Switch to a desktop or laptop to continue."
          )}
        </p>
      </div>
      <div className="hidden h-full sm:flex sm:flex-1 flex-col">
        <ReactFlowProvider>
          <FlowViewer
            flowVersion={flowVersion}
            flowExecutionId={flowExecutionId}
            flowExecutionContactId={flowExecutionContactId}
          />
        </ReactFlowProvider>
      </div>
    </>
  );
}

export default FlowDetail;
