import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ClipboardListIcon, XIcon } from "@heroicons/react/outline";
import { CategoryEnum } from "@hilos/types/private-schema";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import useHilosStore from "src/hooks/useHilosStore";
import {
  CATEGORIES,
  getTemplateVarsFromTemplate,
} from "../../wa/WhatsAppTemplateMeta";
import WhatsAppTemplatePreview from "../../wa/WhatsAppTemplatePreview";
import WhatsAppTemplatePreviewForm, {
  initialTemplatePreviewValues,
} from "../../wa/WhatsAppTemplatePreviewForm";
import WhatsAppTemplateSelect from "../../wa/WhatsAppTemplateSelect";

function QuickReplyTemplateModal({
  onClose,
  onCancel,
  open,
  setSelectedTemplate,
  selectedTemplate,
  formik,
  isUpdate,
}) {
  const [formValues, setFormValues] = useState(initialTemplatePreviewValues);
  const { t } = useTranslation();
  const { session } = useHilosStore();

  const templateHasVars = useMemo(() => {
    if (!selectedTemplate) {
      return false;
    }
    return getTemplateVarsFromTemplate(selectedTemplate).length > 0;
  }, [selectedTemplate]);

  const cancelButtonRef = useRef(null);

  const handleSelectTemplate = useCallback(
    (template) => {
      setSelectedTemplate(template);
      setFormValues({ ...initialTemplatePreviewValues });
    },
    [setSelectedTemplate]
  );

  const onSelectTemplate = useCallback(
    (formValues) => {
      formik.setFieldValue("template_variables", formValues);
      onClose();
    },
    [formik, onClose]
  );

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={onCancel}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 block pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {!isUpdate ? (
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ClipboardListIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {t(
                          "inbox:quick-replies.save-template",
                          "Select a template to save as quick reply"
                        )}
                      </Dialog.Title>
                      <div className="mt-4 space-y-4">
                        {session?.account.has_multiple_active_channels && (
                          <ChannelSelectField
                            name="channel"
                            label={t(
                              "inbox:channel-select.label",
                              "Select a channel"
                            )}
                          />
                        )}
                        <WhatsAppTemplateSelect
                          setSelectedTemplate={handleSelectTemplate}
                          fieldName="template"
                          // @ts-ignore
                          channel={formik.values.channel}
                          categories={
                            Object.getOwnPropertyNames(CATEGORIES).filter(
                              (category) =>
                                category !== "AUTHENTICATION" &&
                                category !== "OTP"
                            ) as CategoryEnum[]
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mb-4 items-center sm:flex">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ClipboardListIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="ml-4 mb-4 self-center text-lg font-medium text-gray-900"
                    >
                      <p className="text-base font-normal text-gray-400">
                        {t("template-name", "Template name")}
                      </p>
                      {selectedTemplate?.name}
                    </Dialog.Title>
                  </div>
                )}

                {selectedTemplate && (
                  <>
                    <div className="mt-8 text-center">
                      {templateHasVars && (
                        <p className="text-sm text-gray-500">
                          {t(
                            "inbox:template-with-variables",
                            "This template has variables, set the values you want to use for this message."
                          )}
                        </p>
                      )}
                    </div>

                    <div
                      className={`mt-4 grid grid-cols-1 gap-6 ${
                        templateHasVars ? "sm:grid-cols-2" : ""
                      }`}
                    >
                      {templateHasVars && (
                        <div>
                          <WhatsAppTemplatePreviewForm
                            key={selectedTemplate.id}
                            template={selectedTemplate}
                            onTemplateValuesChange={setFormValues}
                            enableVariables={true}
                            initialValues={formik.values.template_variables}
                          />
                        </div>
                      )}
                      <div>
                        <WhatsAppTemplatePreview
                          template={selectedTemplate}
                          values={formValues}
                        />
                      </div>
                    </div>

                    <div className="mt-8 sm:mt-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => onSelectTemplate(formValues)}
                      >
                        {!isUpdate
                          ? t(
                              "templates:select-this-template",
                              "Select this template"
                            )
                          : t("continue-editing", "Continue editing")}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={onCancel}
                      >
                        {t("cancel", "Cancel")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default QuickReplyTemplateModal;
