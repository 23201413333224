import { useEffect } from "react";
import { getDataFromJSON } from "src/helpers/utils";
import useHilosWebSockets from "src/hooks/useHilosWebSockets";
import { API_ROUTES } from "src/router/router";
import {
  WorkflowBoardStore,
  useWorkflowBoardContext,
} from "./WorkflowBoardContext";

interface WorkflowTaskParams {
  workflowId: string | undefined;
}

export default function useWorkflowTasks({ workflowId }: WorkflowTaskParams) {
  const { onUpdateTask, onUpdateState } =
    useWorkflowBoardContext<WorkflowBoardStore>();

  const { lastJsonMessage } = useHilosWebSockets({
    path: API_ROUTES.WS_WORKFLOW,
    params: { ":id": workflowId },
  });

  useEffect(() => {
    if (lastJsonMessage) {
      const data = getDataFromJSON(lastJsonMessage.data);

      switch (lastJsonMessage.type) {
        case "workflow_data":
          break;
        case "workflow_task_data":
          onUpdateTask(data);
          break;
        case "workflow_state_data":
          onUpdateState(data);
          break;
        default:
          break;
      }
    }
  }, [lastJsonMessage, onUpdateTask, onUpdateState]);
}
