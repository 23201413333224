import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import FlowTag from "src/components/FlowTag";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import {
  contactTagsColorClasses,
  conversationTagsColorClasses,
} from "src/components/Tags";
import { useContactTags, useConversationTags } from "src/hooks/useTags";
import { useTeams } from "src/hooks/useTeam";
import { userAvatar } from "src/Helpers";

interface FlowBuilderStepTeamProps {
  index: number;
}

const formatOptionLabel = ({ value, label, team }) => (
  <div className="flex items-center justify-between">
    <div>
      <h4 className="font-medium">{label}</h4>
    </div>
    <div className="ml-2 flex justify-end -space-x-1 overflow-hidden">
      {team &&
        team.users.map((user) => (
          <Fragment key={user.id}>
            {userAvatar(
              user,
              "h-6 w-6 text-xs ring-2 ring-white group-hover:ring-gray-300"
            )}
          </Fragment>
        ))}
    </div>
  </div>
);

function FlowBuilderStepTeam({ index }: FlowBuilderStepTeamProps) {
  const [t] = useTranslation();
  const teams = useTeams();
  const { tags: availableContactTags } = useContactTags();
  const { tags: availableConversationTags } = useConversationTags();

  return (
    <div className="space-y-4">
      <SelectorField
        isMulti
        isCreatable={false}
        name={`steps.${index}.assign_to_teams`}
        // @ts-ignore
        formatOptionLabel={formatOptionLabel}
        options={teams.map((team) => ({
          label: team.name,
          value: team.id,
          team: team,
        }))}
        label={t(
          "flows:steps.team.assign-to-teams.label",
          "Team(s) to assign this conversation to"
        )}
        help={t(
          "flows:steps.team.assign-to-teams.help",
          "We'll auto assign a user from that team according to the team's assignment settings."
        )}
        placeholder={t(
          "flows:steps.team.assign-to-teams.placeholder",
          "Type to search teams"
        )}
      />
      <SwitchField
        name={`steps.${index}.append_tags`}
        label={t("flows:steps.tag.append-tags.label", "Append tags?")}
        help={t(
          "flows:steps.tag.append-tags.help",
          "If set these tags will be appended instead of replacing existing ones."
        )}
      />
      <FlowTag
        name={`steps.${index}.conversation_tags`}
        title={t(
          "flows:steps.tag.conversation-tags.description",
          "Add conversation tags"
        )}
        availableTags={availableConversationTags}
        {...conversationTagsColorClasses}
      />
      <FlowTag
        name={`steps.${index}.contact_tags`}
        title={t(
          "flows:steps.tag.contact-tags.description",
          "Add contact tags"
        )}
        availableTags={availableContactTags}
        {...contactTagsColorClasses}
      />
    </div>
  );
}

export default FlowBuilderStepTeam;
