import { FlowExecution } from "./flows/FlowExecution";

export enum ChannelType {
  WHATSAPP = "WHATSAPP",
  EMAIL = "EMAIL",
  INSTAGRAM = "INSTAGRAM",
  FB_MESSENGER = "FB_MESSENGER",
  TELEGRAM = "TELEGRAM",
  SMS = "SMS",
  VOICE = "VOICE",
}

export enum ChannelProvider {
  TECH_PROVIDER_CLOUD_API = "TECH_PROVIDER_CLOUD_API",
  META_CLOUD_API = "META_CLOUD_API",
  D360_CLOUD_API = "D360_CLOUD_API",
  DIALOG360 = "360DIALOG",
}

export enum ChannelStatus {
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface ChannelData {
  id: number;
  channel_id: string;
  channel_type: ChannelType;
  channel_provider: ChannelProvider;
  name: string;
  status: ChannelStatus;
  is_default: boolean;
  created_on: string;
  is_sandbox: boolean;
  current_chatbot: FlowExecution;
}

export interface ChannelAvailabilityData {
  channel: ChannelData;
  is_available: boolean;
  is_contact_default: boolean;
  is_account_default: boolean;
  is_team_default: boolean;
  is_user_default: boolean;
  is_last_used: boolean;
}
