import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FlowStepEdit } from "@hilos/types/private-schema";
import { DAY_LABELS_LONG } from "src/containers/account/AccountMeta";
import { TIME_WINDOW_TYPES } from "src/containers/flow/builder/constants/steps/question";
import useHilosStore from "src/hooks/useHilosStore";
import { buildRoute } from "src/router/router";

interface FlowStepTimeWindowDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepTimeWindowDetail({
  step,
}: FlowStepTimeWindowDetailProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  if (!step.set_time_window) {
    return null;
  }

  const timeWindowType = TIME_WINDOW_TYPES.find(
    (at) => at.value === step.time_window_type
  );

  return (
    <div>
      {step.set_time_window && (
        <div>
          {t(
            "flow-executions:steps-detail.time-window.description",
            "This step has an {{timeWindowType}} active time window (all hours in {{timezone}})",
            {
              timeWindowType: t(timeWindowType?.label || ""),
              timezone: session?.account.default_timezone,
            }
          )}
          {step.time_window_type === "CUSTOM" && (
            <ul>
              {step.time_windows.map((tw) => (
                <li key={tw.weekday}>
                  {tw.is_available ? (
                    <>
                      <span className="text-gray-900 font-medium">
                        {DAY_LABELS_LONG[tw.weekday]}
                      </span>{" "}
                      {t("from")}{" "}
                      <span className="text-gray-900 font-medium">
                        {tw.start_at}
                      </span>{" "}
                      {t("until")}{" "}
                      <span className="text-gray-900 font-medium">
                        {tw.end_at}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-gray-900 font-medium">
                        {DAY_LABELS_LONG[tw.weekday]}
                      </span>{" "}
                      - {t("not-available", "Not available")}
                    </>
                  )}
                </li>
              ))}
            </ul>
          )}
          {step.time_window_type === "IN_WORKING_HOURS" && (
            <p>
              <Link
                to={buildRoute("config-account-hours")}
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              >
                {t(
                  "flows:time-window.check-working-hours",
                  "Check your account's working hours here."
                )}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          )}
        </div>
      )}
    </div>
  );
}
