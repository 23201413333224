import { useTranslation } from "react-i18next";
import LoaderSpinner from "src/components/LoaderSpinner";

export default function ContactImportStatusBadge({ status }) {
  const { t } = useTranslation();
  switch (status) {
    case "PENDING":
      return (
        <div className="inline-flex gap-2 items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800">
          {t(status)}{" "}
          <span className="-mr-3">
            <LoaderSpinner />
          </span>
        </div>
      );
    case "RUNNING":
      return (
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium text-indigo-800">
          {t(status)}
        </span>
      );
    case "COMPLETED":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium text-green-800">
          {t(status)}
        </span>
      );
    case "FAILED":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium text-red-800">
          {t(status)}
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800">
          {status}
        </span>
      );
  }
}
