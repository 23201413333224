import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWhatsAppTemplate(id?: string | null) {
  const fetchWhatsAppTemplate = useCallback(async () => {
    if (!id) return undefined;
    const { data } = await axios.get<WhatsAppTemplate>(
      buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE, { ":id": id })
    );
    return data;
  }, [id]);

  const { data } = useQuery(
    ["whatsapp_template_detail", id],
    fetchWhatsAppTemplate
  );

  return data;
}

export default useWhatsAppTemplate;
