import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowSmLeftIcon,
  ExclamationIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import i18n from "src/i18n";
import StateButton from "./StateButton";
import APIErrors from "./base/APIErrors";

export type onDeleteFn<T> = (
  { obj }: { obj: T },
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  setBackendValidationErrors: Dispatch<SetStateAction<{}>>,
  setBackendError: Dispatch<SetStateAction<string>>
) => void;

interface DeleteObjectProps<T> {
  obj: T;
  objDescription: string;
  actionDescription: string;
  onDelete: onDeleteFn<T>;
  deleteSuccess: boolean;
  deleteSubmitted: boolean;
  buttonClasses?: string;
  iconClasses?: string;
  deleteText?: string;
}

export default function DeleteObject<T>({
  obj,
  objDescription,
  actionDescription,
  onDelete,
  deleteSuccess,
  deleteSubmitted,
  buttonClasses,
  iconClasses,
  deleteText = i18n.t("delete", "Delete"),
}: DeleteObjectProps<T>) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const handleClose = () => !isSubmitting && setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    await onDelete(
      { obj: obj },
      setIsSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
    if (
      [isSubmitting, backendError, backendValidationErrors].every((x) => !x)
    ) {
      handleClose();
    }
  };

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setShow}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setShow(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t(
                        "delete-item",
                        "Are you sure you'd like to delete {{object}}?",
                        { object: objDescription }
                      )}
                    </Dialog.Title>
                    <div className="mt-2">
                      <APIErrors
                        APIError={backendError}
                        APIValidationErrors={backendValidationErrors}
                        fieldTranslations={{}}
                      ></APIErrors>
                      <p className="text-sm text-gray-500">
                        {actionDescription}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <form onSubmit={handleSubmit}>
                    <StateButton
                      isSubmitting={isSubmitting}
                      submitted={deleteSubmitted}
                      success={deleteSuccess}
                      btnClasses="w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      submittingText={t("deleting", "Deleting...")}
                      successText={t("deleted", "Deleted")}
                      initialText={
                        <>
                          <TrashIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />{" "}
                          {t("yes-delete", "Yes, delete")}
                        </>
                      }
                    />
                  </form>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setShow(false)}
                  >
                    <ArrowSmLeftIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {t("no-go-back", "No, go back")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <button
        className={
          buttonClasses
            ? buttonClasses
            : "inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        }
        type="button"
        onClick={handleShow}
      >
        <TrashIcon
          className={iconClasses ? iconClasses : "-ml-0.5 mr-2 h-4 w-4"}
          aria-hidden="true"
        />
        {deleteText}
      </button>
    </>
  );
}
