import { Disclosure, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { DateTime } from "luxon";
import { truncateWords } from "../../../Helpers";
import MessageRender from "../../../components/MessageRender";

export default function FlowExecutionContactStepListMessageList({
  flowExecutionStep,
}) {
  return (
    <div className="divide-y divide-gray-200">
      {flowExecutionStep.messages.map((message, idx) => (
        <Disclosure key={idx}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`
                  ${
                    open
                      ? "bg-gray-50 text-blue-700"
                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 focus:hover:bg-gray-50"
                  }
                  "group flex w-full items-center justify-between gap-4 px-4 py-3 text-sm font-medium
                `}
              >
                <div>
                  {message.message.direction === "INBOUND" ? (
                    <ArrowLeftIcon
                      className={`h-5 w-5 ${open ? "text-blue-700" : ""}`}
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowRightIcon
                      className={`h-5 w-5 ${open ? "text-blue-700" : ""}`}
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div
                  className="grow text-left text-sm text-blue-600"
                  id={message.message.id}
                >
                  {message.message.whatsapp_template ? (
                    <>{message.message.whatsapp_template.name}</>
                  ) : (
                    <>
                      {message.message.body &&
                        truncateWords(message.message.body.split("\n")[0])}
                    </>
                  )}
                  <small className="block text-xs text-gray-400">
                    {DateTime.fromISO(message.message.timestamp).toLocaleString(
                      DateTime.DATETIME_SHORT_WITH_SECONDS
                    )}
                  </small>
                </div>
                <ChevronDownIcon
                  className={`${open ? "rotate-180 transform" : ""} h-4 w-4 `}
                />
              </Disclosure.Button>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="px-5 py-4">
                  <div className="font-mono text-sm text-gray-500">
                    <MessageRender message={message.message} />
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
}
