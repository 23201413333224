import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BeakerIcon, PlayIcon } from "@heroicons/react/outline";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { FlowDetailRead, FlowTestRead } from "@hilos/types/private-schema";
import LoaderSpinner from "src/components/LoaderSpinner";
import useCleanInterval from "src/hooks/useCleanInterval";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPhone } from "src/Helpers";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import FlowAdvancedOptionsDisclosure from "../components/FlowBuilderAdvancedOptionsDisclosure";
import FlowBuilderTestExecutionStarted from "./FlowBuilderTestExecutionStarted";
import FlowBuilderTestFlowExecutionVariables from "./FlowBuilderTestFlowExecutionVariables";
import FlowBuilderTestTriggerHelp from "./FlowBuilderTestTriggerHelp";

interface FlowBuilderTestOutboundProps {
  flow: FlowDetailRead;
}

export default function FlowBuilderTestOutbound({
  flow,
}: FlowBuilderTestOutboundProps) {
  // For OUTBOUND_* triggers we first check if the user's phone is saved, if not
  // we save it first with a QR and then wait for the user to click on a button
  // to trigger this flow as if they were creating a FlowExecution (with their
  // respective flow_execution_variables)
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [flowTest, setFlowTest] = useState<FlowTestRead | null>(null);
  const [flowExecutionVariables, setFlowExecutionVariables] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const interval = useCleanInterval();

  const checkFlowTest = async () => {
    try {
      const response = await axios.get<FlowTestRead>(
        buildAPIRoute(API_ROUTES.FLOW_TEST_DETAIL, { ":id": flow.id })
      );
      setFlowTest(response.data);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      if (errorAxios?.response?.status === 404) {
        setFlowTest(null);
      }
    }
  };

  const createFlowTest = async () => {
    try {
      const response = await axios.post<FlowTestRead>(
        buildAPIRoute(API_ROUTES.FLOW_TEST_CREATE),
        {
          flow: flow.id,
          flow_version: flow.test_version.id,
          flow_execution_variables: flowExecutionVariables,
        }
      );
      setSubmitting(false);
      setFlowTest(response.data);
      interval.current = setInterval(() => {
        checkFlowTest();
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const allVariablesProvided = useMemo(() => {
    if (flow.flow_execution_variables) {
      const valuedVariables = flow.flow_execution_variables.filter(
        (varName) =>
          flowExecutionVariables[varName] &&
          flowExecutionVariables[varName].length > 0
      );
      return valuedVariables.length === flow.flow_execution_variables?.length;
    }
    return true;
  }, [flow.flow_execution_variables, flowExecutionVariables]);

  if (!session) {
    return null;
  }

  return (
    <div className="mx-auto block">
      <div className="flex text-gray-800 items-center font-bold">
        <div className="h-10 w-10 rounded-full flex items-center justify-center text-blue-500 mr-4 bg-blue-100">
          <BeakerIcon className="h-6 w-6" />
        </div>
        <div className="text-lg">
          {t("flows:test-modal.title", "Test your flow!")}
        </div>
      </div>

      <FlowAdvancedOptionsDisclosure
        buttonText={t("flows:test-modal.trigger-details", "Trigger details")}
      >
        <FlowBuilderTestTriggerHelp flow={flow} />
      </FlowAdvancedOptionsDisclosure>

      <div className="pb-2">
        {!flowTest ? (
          <>
            <div className="text-lg text-gray-800 font-medium">
              {t("flows:test-modal.ready-to-test", "Ready to test?")}
            </div>

            {flow.flow_execution_variables &&
              flow.flow_execution_variables.length > 0 && (
                <FlowBuilderTestFlowExecutionVariables
                  requiredVariables={flow.flow_execution_variables}
                  flowExecutionVariables={flowExecutionVariables}
                  setFlowExecutionVariables={setFlowExecutionVariables}
                  allVariablesProvided={allVariablesProvided}
                />
              )}

            <button
              type="button"
              className="mt-4 w-full inline-flex items-center justify-center rounded-md border bg-white px-8 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-green-500 focus:ring-green-500 hover:bg-green-50 text-green-600 border-green-300 disabled:cursor-default disabled:hover:bg-white disabled:text-gray-300 disabled:border-gray-300"
              disabled={!allVariablesProvided || submitting}
              onClick={createFlowTest}
            >
              {submitting ? (
                <>
                  <LoaderSpinner />
                  {t("flows:test-modal.creating-test-button", "Starting...")}
                </>
              ) : (
                <>
                  <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                  {t(
                    "flows:test-modal.send-test-button",
                    "Send test flow to {{phone}}",
                    {
                      phone: formatPhone(session.phone),
                    }
                  )}
                </>
              )}
            </button>
          </>
        ) : (
          <>
            {flowTest.flow_execution_contact ? (
              <FlowBuilderTestExecutionStarted flowTest={flowTest} />
            ) : (
              <div>
                <div className="text-lg text-gray-800 font-medium">
                  {t("flows:test-modal.running-flow", "Running flow...")}
                </div>

                <div>
                  <div className="flex items-center justify-center self-center rounded-sm p-2 font-semibold text-hilos">
                    <svg
                      className="-ml-1 mr-3 h-5 w-5 animate-spin text-hilos"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <p className="text-sm">
                      {t(
                        "flows:test-modal.waiting-for-message",
                        "Waiting for your message"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
