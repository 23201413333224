import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PlusSmIcon } from "@heroicons/react/outline";
import { StripeProductAvailableList } from "@hilos/types/private-schema";
import { formatPrice } from "src/Helpers";

interface SubscriptionExtrasCardProps {
  addons: StripeProductAvailableList[];
  asCard: boolean;
}

export default function SubscriptionExtrasCard({
  addons,
  asCard,
}: SubscriptionExtrasCardProps) {
  const { t } = useTranslation();
  const getPrevTier = useCallback(
    (product: StripeProductAvailableList, tierIdx: number) => {
      if (tierIdx <= 0) {
        return 0;
      }
      const prevIdx = tierIdx - 1;
      if (!product.available_price?.tiers) {
        return 0;
      }
      const upTo = product.available_price.tiers[prevIdx].up_to || 0;
      return upTo + 1;
    },
    []
  );

  return (
    <div>
      <div className="flex items-center">
        <h4 className="flex-shrink-0 pr-4 text-xl font-semibold text-hilos">
          {t("extras")}
        </h4>
        <div className="flex-1 border-t-2 border-gray-200" />
      </div>
      <div
        className={`mt-2 sm:overflow-hidden sm:rounded-md ${
          asCard ? "shadow" : ""
        }`}
      >
        <div className="flex-1 rounded-md bg-white px-4 py-4 lg:px-8 lg:py-4">
          <ul className="mt-6 space-y-6">
            {addons.map((addon) => (
              <li
                key={addon.name}
                className="grid grid-cols-1 gap-x-6 md:grid-cols-2"
              >
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <PlusSmIcon
                      className="h-5 w-5 text-hilos"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 font-medium text-gray-900">
                    {addon.features && (
                      <>
                        {t(`billing:extras.${addon.features[0]}.title`)}
                        <div className="mt-1 text-sm font-normal text-gray-500">
                          {t(`billing:extras.${addon.features[0]}.description`)}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {addon.available_price && addon.available_price.tiers ? (
                  <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            colSpan={2}
                            className="pb-3.5 pl-2 text-right text-sm font-medium text-gray-900 sm:pl-6 md:pl-0"
                          ></th>
                          <th
                            scope="col"
                            className="w-24 pb-3.5 pr-2 text-right text-sm font-medium text-gray-900 sm:pr-6 md:pr-0"
                          >
                            {t("settings:billing.unit-cost", "Unit Cost")}
                          </th>
                          <th
                            scope="col"
                            className="w-24 pb-3.5 pr-2 text-right text-sm font-medium text-gray-900 sm:pr-6 md:pr-0"
                          >
                            $
                            {addon.available_price.currency?.toLocaleUpperCase()}{" "}
                            /{" "}
                            {(addon.block_grouping || 0) > 1 ? "block" : "unit"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {addon.available_price.tiers.map((tier, tierIdx) => (
                          <tr
                            key={tierIdx}
                            className="border-b border-gray-200"
                          >
                            <td className="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                              {tierIdx === 0
                                ? "0"
                                : getPrevTier(addon, tierIdx).toLocaleString(
                                    "en"
                                  )}
                            </td>
                            <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-500 sm:pr-6 md:pr-0">
                              -{" "}
                              {tier.up_to
                                ? tier.up_to.toLocaleString("en")
                                : "∞"}{" "}
                            </td>

                            <td className="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                              {formatPrice(
                                tier.unit_amount ? tier.unit_amount / 100 : 0
                              )}
                            </td>
                            <td className="whitespace-nowrap px-2 py-2 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                              <span className="font-semibold text-hilos">
                                {formatPrice(
                                  tier.unit_amount
                                    ? (tier.unit_amount / 100) *
                                        (addon.block_grouping || 0)
                                    : 0
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="text-right text-sm text-gray-500">
                    <span className="text-lg font-semibold text-hilos">
                      {formatPrice(
                        addon.available_price
                          ? (addon.available_price.unit_amount || 0) / 100
                          : "-"
                      )}
                    </span>{" "}
                    {addon.available_price
                      ? addon.available_price.currency?.toLocaleUpperCase()
                      : "-"}
                    /ea
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className="my-4 text-sm text-gray-500">
            <Trans i18nKey="settings:billing.additional-seats">
              *We tally the additional inbox seats and Active Contacts used at{" "}
              <strong className="font-medium text-gray-900">
                end of the month
              </strong>{" "}
              and include them in your next month's invoice.
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
}
