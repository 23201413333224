import { useRef } from "react";
import { DocumentTextIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { WhatsAppMessage } from "@hilos/types/private-schema";
import RequestWelcome from "src/containers/inbox/Conversation/MessageType/RequestWelcome";
import SystemMessage from "src/containers/inbox/Conversation/MessageType/System";
import UnsupportedMessage from "src/containers/inbox/Conversation/MessageType/UnsupportedMessage";
import { isMsgContentTypeSupported } from "src/helpers/conversation";
import ButtonMessage from "../containers/inbox/Conversation/MessageType/Button";
import ContactsMessage from "../containers/inbox/Conversation/MessageType/Contacts";
import InteractiveMessage from "../containers/inbox/Conversation/MessageType/Interactive";
import LocationMessage from "../containers/inbox/Conversation/MessageType/Location";
import WhatsAppTemplatePreview from "../containers/wa/WhatsAppTemplatePreview";
import { getValuesFromTemplateContent } from "../helpers/template";
import Linkify from "./Linkify";
import MessageImage from "./MessageImage";
import MessageVideo from "./MessageVideo";

interface MessageRenderProps {
  showMenu?: boolean;
  message: WhatsAppMessage;
  handleSelectMedia?: (msg: WhatsAppMessage) => void;
}

function MessageRender({
  showMenu = false,
  message,
  handleSelectMedia = () => {},
}: MessageRenderProps) {
  const audioRef = useRef<HTMLAudioElement>(null);

  if (message.msg_type === "text") {
    return (
      <p className="whitespace-pre-wrap break-words">
        <Linkify message={message.body} />
      </p>
    );
  }

  if (message.msg_type === "image") {
    return (
      <div className="flex flex-col flex-wrap">
        <button onClick={() => handleSelectMedia(message)}>
          <MessageImage
            name={message.body}
            source={message.content_url}
            showMenu={showMenu}
            isInbound={message.direction === "INBOUND"}
          />
        </button>
        <p className="mt-2 whitespace-pre-wrap break-words">
          <Linkify message={message.body} />
        </p>
      </div>
    );
  }

  if (message.msg_type === "sticker") {
    return (
      <>
        <img
          src={message.content_url ?? ""}
          className="w-60 rounded-lg"
          alt={message.body ?? ""}
        />
        <p className="whitespace-pre-wrap break-words">
          <Linkify message={message.body} />
        </p>
      </>
    );
  }

  if (message.msg_type === "document") {
    return isMsgContentTypeSupported(message.content_type ?? "") ? (
      <>
        <div className="relative">
          <button
            onClick={() => handleSelectMedia(message)}
            className="flex items-center w-full justify-center text-sm bg-white rounded-md bg-opacity-30 pl-4 pr-8 py-2"
          >
            <div className=" rounded-full h-10 w-10 bg-white bg-opacity-40 text-gray-500 items-center justify-center flex">
              <DocumentTextIcon className="h-6 w-6" />
            </div>

            <div className=" ml-2 font-medium flex items-center">
              <span> Document </span>
            </div>
          </button>
          <a
            href={message.content_url ?? ""}
            target="_blank"
            rel="noreferrer"
            className="flex items-center absolute left-0 top-0 text-sm bg-white rounded-md bg-opacity-30 p-1"
          >
            <ExternalLinkIcon className="h-4 w-4" />
          </a>
        </div>
        <p className="whitespace-pre-wrap break-words">
          <Linkify message={message.body} />
        </p>
      </>
    ) : (
      <>
        <a
          href={message.content_url ?? ""}
          target="_blank"
          rel="noreferrer"
          className="flex items-center text-sm bg-white rounded-md bg-opacity-30 px-4 py-2"
        >
          <div className=" rounded-full h-10 w-10 bg-white bg-opacity-40 text-gray-500 items-center justify-center flex">
            <DocumentTextIcon className="h-6 w-6" />
          </div>

          <div className=" ml-2 font-medium flex items-center">
            <span> Document </span>{" "}
            <ExternalLinkIcon className="ml-2 h-4 w-4" />
          </div>
        </a>
        <p className="whitespace-pre-wrap break-words">
          <Linkify message={message.body} />
        </p>
      </>
    );
  }

  if (message.msg_type === "voice") {
    return (
      <div>
        <audio controls ref={audioRef} src={message.content_url ?? ""} />
      </div>
    );
  }

  if (message.msg_type === "video") {
    return (
      <div className="flex flex-col flex-wrap">
        <MessageVideo src={message.content_url ?? ""} />
        <p className="mt-2 whitespace-pre-wrap break-words">
          <Linkify message={message.body} />
        </p>
      </div>
    );
  }

  if (message.msg_type === "audio") {
    return (
      <div>
        <audio controls ref={audioRef} src={message.content_url ?? ""} />
      </div>
    );
  }

  if (message.msg_type === "location") {
    return <LocationMessage message={message} />;
  }

  if (message.msg_type === "contacts") {
    return <ContactsMessage message={message} />;
  }

  if (message.msg_type === "template" && message.whatsapp_template) {
    return (
      <WhatsAppTemplatePreview
        template={message.whatsapp_template}
        values={getValuesFromTemplateContent(message.content)}
        handleSelectMedia={() => handleSelectMedia(message)}
      />
    );
  }

  if (message.msg_type === "button") {
    return <ButtonMessage message={message} />;
  }

  if (message.msg_type === "interactive") {
    return <InteractiveMessage message={message} />;
  }

  if (message.msg_type === "system") {
    return <SystemMessage message={message} />;
  }

  if (message.msg_type === "request_welcome") {
    return <RequestWelcome message={message} />;
  }

  if (
    message.msg_type &&
    ["unknown", "unsupported", "ephemeral"].includes(message.msg_type)
  ) {
    return <UnsupportedMessage message={message} />;
  }
  return null;
}

export default MessageRender;
