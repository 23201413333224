import { gql } from "@apollo/client";

const INBOX_CONTACT_DATA_FIELDS = `
      id
      last_updated_on
      unseen_messages
      last_message_on
      is_unread
      contact__phone
      contact__first_name
      contact__last_name
      last_message__status
      last_message__direction
      last_message__is_deleted
      last_message__msg_type
      last_message__body
      last_message__content
      last_message__timestamp
      last_conversation__status
      last_inbound_message_on
      channel {
        name
        channel_type
        channel_provider
      }
      contact_tags {
        tag {
          id
          name
        }
      }
      conversation_tags {
        tag {
          id
          name
        }
      }
      conversation_assigned {
        user {
          id
          email
          first_name
          last_name
          profile_image
        }
      }`;

export const INBOX_CONTACT_FIELDS_FRAGMENT = gql`
  fragment InboxContactFields on api_inboxcontact {
    id
    last_updated_on
    unseen_messages
    last_message_on
    is_unread
    channel {
      name
      channel_type
      channel_provider
    }
    contact {
      phone
      first_name
      last_name
      tags {
        tag {
          id
          name
        }
      }
    }
    last_conversation {
      status
      tags {
        tag {
          id
          name
        }
      }
      assigned {
        user {
          id
          email
          first_name
          last_name
          profile_image
        }
      }
    }
    last_message {
      status
      direction
      is_deleted
      msg_type
      body
      content
      timestamp
    }
    last_inbound_message_on
  }
`;

export const GET_INBOX_CONTACTS_QUERY = gql`
  ${INBOX_CONTACT_FIELDS_FRAGMENT}
  query GetInboxContacts(
    $filters: api_inboxcontact_bool_exp!
    $orderBy: [api_inboxcontact_order_by!]
  ) {
    api_inboxcontact(where: $filters, limit: 11, order_by: $orderBy) {
      ...InboxContactFields
    }
  }
`;

export const GET_INBOX_CONTACT_DATA_QUERY = gql`
  query GetInboxContactData(
    $search: String
    $filters: inboxcontact_data_bool_exp
    $orderBy: [inboxcontact_data_order_by!]
  ) {
    api_inboxcontact: get_inboxcontact_data(
      args: { search: $search }
      where: $filters
      order_by: $orderBy
      limit: 11
    ) {
${INBOX_CONTACT_DATA_FIELDS}
    }
  }
`;

export const GET_INBOX_CONTACT_IDS_QUERY = `
  query GetInboxContactIds($filters: api_inboxcontact_bool_exp!) {
    api_inboxcontact(where: $filters) {
      id
    }
  }
`;

export const GET_INBOX_CONTACT_DATA_IDS_QUERY = `
  query GetInboxContactDataIds(
    $search: String
    $filters: inboxcontact_data_bool_exp!
  ) {
    api_inboxcontact: get_inboxcontact_data(
      args: { search: $search }
      where: $filters
    ) {
      id
    }
  }
`;

export const getInboxContactCountQuery = (
  allowQueryCountWithLimit: boolean,
  allowQueriesWithSQLFunctions: boolean
) => {
  const params = allowQueryCountWithLimit ? "limit: 1000" : "";

  if (allowQueriesWithSQLFunctions) {
    return gql`
      query GetInboxContactDataCount(
        $search: String
        $filters: inboxcontact_data_bool_exp!
      ) {
        api_inboxcontact_aggregate: get_inboxcontact_data_aggregate(
          args: { search: $search }
          where: $filters
          ${params}
        ) {
          aggregate {
            count
          }
        }
      }
    `;
  }

  return gql`
    query GetInboxContactsCount($filters: api_inboxcontact_bool_exp!) {
      api_inboxcontact_aggregate(where: $filters ${params}) {
        aggregate {
          count
        }
      }
    }
  `;
};

export const getInboxContactCountSubscription = (
  allowQueryCountWithLimit: boolean,
  allowQueriesWithSQLFunctions: boolean
) => {
  const params = allowQueryCountWithLimit ? "limit: 1000" : "";

  if (allowQueriesWithSQLFunctions) {
    return gql`
      subscription OnInboxContactDataCount(
        $search: String
        $filters: inboxcontact_data_bool_exp!
      ) {
        api_inboxcontact_aggregate: get_inboxcontact_data_aggregate(
          args: { search: $search }
          where: $filters
          ${params}
        ) {
          aggregate {
            count
          }
        }
      }
    `;
  }

  return gql`
      subscription OnInboxContactsCount($filters: api_inboxcontact_bool_exp!) {
        api_inboxcontact_aggregate(where: $filters ${params}) {
          aggregate {
            count
          }
        }
      }
    `;
};

export const INBOX_CONTACTS_UPDATE_LIVE_QUERY_SUBSCRIPTION = gql`
  ${INBOX_CONTACT_FIELDS_FRAGMENT}
  subscription OnInboxContactsUpdate(
    $filters: api_inboxcontact_bool_exp!
    $orderBy: [api_inboxcontact_order_by!]
  ) {
    api_inboxcontact(where: $filters, limit: 10, order_by: $orderBy) {
      ...InboxContactFields
    }
  }
`;

export const INBOX_CONTACTS_UPDATE_STREAMING_SUBSCRIPTION = gql`
  ${INBOX_CONTACT_FIELDS_FRAGMENT}
  subscription OnInboxContactsUpdateStreaming(
    $filters: api_inboxcontact_bool_exp!
    $last_updated_on: timestamptz!
  ) {
    api_inboxcontact_stream(
      where: $filters
      cursor: {
        initial_value: { last_updated_on: $last_updated_on }
        ordering: ASC
      }
      batch_size: 10
    ) {
      ...InboxContactFields
    }
  }
`;

export const INBOX_CONTACT_DATA_UPDATE_LIVE_QUERY_SUBSCRIPTION = gql`
  subscription OnInboxContactDataUpdate(
    $search: String
    $filters: inboxcontact_data_bool_exp
    $orderBy: [inboxcontact_data_order_by!]
  ) {
    api_inboxcontact: get_inboxcontact_data(
      args: { search: $search }
      where: $filters
      order_by: $orderBy
      limit: 10
    ) {
${INBOX_CONTACT_DATA_FIELDS}
    }
  }
`;
