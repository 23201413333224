import { Trans, useTranslation } from "react-i18next";
import {
  ArrowRightIcon,
  BookOpenIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { GUIDES } from "./DashboardMeta";

export default function GuidesSection() {
  const { t } = useTranslation();

  return (
    <div className="h-full px-6">
      <div className="flex items-center">
        <div className="h-12 w-12 mr-4 bg-hilos-light rounded-full inline-flex items-center justify-center">
          <BookOpenIcon className=" text-hilos-alt-700 h-7 w-7" />
        </div>
        <div>
          <Trans i18nKey="dashboard:guides.title">
            <h3 className=" flex items-center text-lg font-bold leading-7 text-neutral-dark  sm:truncate">
              Guides
            </h3>
            <p className=" text-sm text-neutral">
              Learn everything you need to know about Hilos with our start
              guides!
            </p>
          </Trans>
        </div>
      </div>
      <div className="mt-6">
        {GUIDES.map((guide) => (
          <div className="bg-white p-4 h-32 my-3 flex items-center relative rounded-lg border border-gray-200">
            <span className=" absolute right-4 top-3 bg-green-100 text-green-600 text-xs rounded-md px-3 py-1">
              {guide.time} min
            </span>
            <img
              src={guide.image}
              className="h-24 w-24 self-center rounded-lg"
              alt="Guide"
            />
            <div className="ml-4 mt-2 w-full">
              <p className="text-md font-semibold">{t(guide.title)}</p>
              <p className="text-xs text-gray-500">{t(guide.description)}</p>
              <div className="flex mt-4 w-full justify-end">
                <a
                  href={guide.href}
                  target="_blank"
                  rel="noreferrer"
                  className="flex bg-neutral px-6 py-1 text-white rounded-md items-center ml-auto text-xs font-medium"
                >
                  {t("dashboard:guides.cta", "See guide")}
                  <ArrowRightIcon className="h-3 w-3 ml-2" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full mt-2 text-hilos-alt-700 flex">
        <a
          href="https://hilos.io/docs/guides/new-agent/inbox/empezar-conversacion"
          target="_blank"
          rel="noreferrer"
          className="flex items-center ml-auto text-sm font-medium"
        >
          {t("dashboard:guides.more", "More guides")}
          <ChevronRightIcon className="h-4 w-4 ml-4" />
        </a>
      </div>
    </div>
  );
}
