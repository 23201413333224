import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";
import { enUS, es } from "date-fns/locale";
import LoaderSpinner from "src/components/LoaderSpinner";

const locales = {
  en: enUS,
  es,
};

interface UpdatedAtProps {
  updating: boolean;
  lastUpdatedAt: number | null;
}

function getLastUpdateLabel(
  lastUpdatedAt: number | null,
  language: string | null
) {
  if (!lastUpdatedAt) {
    return "";
  }

  return formatDistanceToNow(lastUpdatedAt, {
    addSuffix: true,
    locale: locales[language || "en"],
  });
}

function UpdatedAt({ updating, lastUpdatedAt }: UpdatedAtProps) {
  const { t, i18n } = useTranslation();
  const [lastUpdateLabel, setLastUpdateLabel] = useState(
    getLastUpdateLabel(lastUpdatedAt, i18n.languages[0])
  );

  useEffect(() => {
    if (!lastUpdatedAt) {
      return;
    }

    setLastUpdateLabel(getLastUpdateLabel(lastUpdatedAt, i18n.languages[0]));

    const updater = setInterval(() => {
      setLastUpdateLabel(getLastUpdateLabel(lastUpdatedAt, i18n.languages[0]));
    }, 30000);

    return () => clearInterval(updater);
  }, [i18n.languages, lastUpdatedAt]);

  if (updating) {
    return <LoaderSpinner />;
  }

  if (!lastUpdateLabel) {
    return null;
  }

  return (
    <span className="italic text-xs text-gray-400">
      {t("flows:updated", "Updated ")}
      {lastUpdateLabel}
    </span>
  );
}

export default UpdatedAt;
