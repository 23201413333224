import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getIn, useFormikContext } from "formik";
import MappedValuesField from "../MappedValuesField";

interface HTTPFormParamsProps {
  path: string;
  fieldURLName: string;
  fieldParamsName: string;
  currentStepIndex: number | null;
}

function HTTPFormParams({
  path,
  fieldURLName,
  fieldParamsName,
  currentStepIndex,
}: HTTPFormParamsProps) {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const urlPreview = useMemo(() => {
    const nextURL = getIn(values, `${path}.${fieldURLName}`) || "";
    const nextParams = getIn(values, `${path}.${fieldParamsName}`) || [];

    if (nextParams) {
      return nextParams.reduce((currentNextURL, nextParam, index) => {
        if (nextParam.key) {
          const nextQueryParam = `${encodeURIComponent(
            nextParam.key
          )}=${encodeURIComponent(nextParam.value)}`;

          if (index === 0) {
            return `${currentNextURL}?${nextQueryParam}`;
          }
          return `${currentNextURL}&${nextQueryParam}`;
        }

        return currentNextURL;
      }, nextURL);
    }

    return nextURL;
  }, [values, path, fieldURLName, fieldParamsName]);

  return (
    <div>
      <div className="text-xs font-semibold uppercase text-gray-700">
        {t("components:http-form.query.url-preview", "URL Preview")}
      </div>
      <p className="border-neutral-ligth mt-1 break-all rounded-md border bg-gray-50 p-2 text-xs font-normal text-black">
        <code>{urlPreview}</code>
      </p>
      <div className="mt-4 block text-xs font-semibold uppercase text-gray-700">
        {t("components:http-form.query.query-params-header", "Query Params")}
      </div>
      <MappedValuesField
        path={path}
        name={fieldParamsName}
        objectName={t("components:http-form.query.query-param", "Query param")}
        currentStepIndex={currentStepIndex}
        addButtonLabel={t(
          "components:http-form.query.add-query-params",
          "Add query param"
        )}
      />
    </div>
  );
}

export default HTTPFormParams;
