import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon, MinusIcon } from "@heroicons/react/outline";
import { classNames } from "src/Helpers";

interface SubscriptionV2PlanComparisonProps {
  currency: string;
  activePlan: string;
  onSelectPlan: (plan: string) => void;
  disableActivePlan: boolean;
}

export default function SubscriptionV2PlanComparison({
  currency,
  onSelectPlan,
  activePlan = "LAUNCH",
  disableActivePlan = false,
}: SubscriptionV2PlanComparisonProps) {
  const { t } = useTranslation();

  const tiers = [
    {
      name: "Launch",
      id: "LAUNCH",
      priceMonthly: { mxn: "$3,300", usd: "$199" },
      usd: "$",
      description: t(
        "settings:billing.v2.launch.description",
        "Everything necessary to get started."
      ),
      selected: activePlan === "LAUNCH",
    },
    {
      name: "Scale",
      id: "SCALE",
      priceMonthly: { mxn: "$6,700", usd: "$399" },
      description: t(
        "settings:billing.v2.scale.description",
        "Best value when your company is growing fast."
      ),
      selected: activePlan === "SCALE",
    },
    {
      name: "Enterprise",
      id: "ENTERPRISE",
      priceMonthly: { mxn: "Custom", usd: "Custom" },
      description: t(
        "settings:billing.v2.enterprise.description",
        "For companies with advanced reporting, support, or volume needs."
      ),
      selected: activePlan === "ENTERPRISE",
    },
  ];

  const sections = [
    {
      name: t(
        "settings:billing.v2.sections.includes.title",
        "What's included?"
      ),
      features: [
        {
          name: t("settings:billing.v2.dimensions.users.title", "Users"),
          tiers: {
            Launch: t(
              "settings:billing.v2.dimensions.users.launch",
              "3 included"
            ),
            Scale: t(
              "settings:billing.v2.dimensions.users.scale",
              "6 included"
            ),
            Enterprise: t("custom", "Custom"),
          },
          extra: {
            name: t("additional", "Additional"),
            tiers: {
              Launch: { mxn: "$520 MXN", usd: "$30 USD" },
              Scale: { mxn: "$480 MXN", usd: "$28 USD" },
              Enterprise: {
                mxn: t("custom", "Custom"),
                usd: t("custom", "Custom"),
              },
            },
          },
          type: "dimension",
        },
        {
          name: t(
            "settings:billing.v2.dimensions.cams.title",
            "Monthly Active Contacts"
          ),
          description: t(
            "settings:billing.v2.dimensions.cams.description",
            "(Contacts you message on the current month)"
          ),
          extra: {
            name: t("additional", "Additional"),
            tiers: {
              Launch: { mxn: "$0.85 MXN", usd: "$0.05 USD" },
              Scale: { mxn: "$0.75 MXN", usd: "$0.45 USD" },
              Enterprise: {
                mxn: t("custom", "Custom"),
                usd: t("custom", "Custom"),
              },
            },
          },
          tiers: {
            Launch: t(
              "settings:billing.v2.dimensions.cams.launch",
              "2,000/month"
            ),
            Scale: t(
              "settings:billing.v2.dimensions.cams.scale",
              "6,000/month"
            ),
            Enterprise: t("custom", "Custom"),
          },
          type: "dimension",
        },
        {
          name: t(
            "settings:billing.v2.dimensions.channels.title",
            "Connected WA numbers"
          ),
          extra: {
            name: t("additional", "Additional"),
            tiers: {
              Launch: { mxn: "$835 MXN", usd: "$50 USD" },
              Scale: { mxn: "$835 MXN", usd: "$50 USD" },
              Enterprise: {
                mxn: t("custom", "Custom"),
                usd: t("custom", "Custom"),
              },
            },
          },
          tiers: {
            Launch: t(
              "settings:billing.v2.dimensions.channels.launch",
              "1 included"
            ),
            Scale: t(
              "settings:billing.v2.dimensions.channels.scale",
              "2 included"
            ),
            Enterprise: t("custom", "Custom"),
          },
          type: "dimension",
        },
      ],
    },
    {
      name: t(
        "settings:billing.v2.sections.integrations.title",
        "Integrations"
      ),
      features: [
        {
          name: t(
            "settings:billing.v2.sections.integrations.mobile",
            "iOS & Android Apps"
          ),
          tiers: {
            Launch: true,
            Scale: true,
            Enterprise: true,
          },
          type: "check",
        },
        {
          name: t("settings:billing.v2.sections.integrations.zapier", "Zapier"),
          tiers: {
            Launch: true,
            Scale: true,
            Enterprise: true,
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.integrations.hubspot",
            "HubSpot"
          ),
          tiers: {
            Launch: true,
            Scale: true,
            Enterprise: true,
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.integrations.api",
            "API & Webhooks (Advanced)"
          ),
          tiers: {
            Launch: true,
            Scale: true,
            Enterprise: true,
          },
          type: "check",
        },
      ],
    },
    {
      name: t("settings:billing.v2.sections.flows.title", "Flows & Broadcasts"),
      features: [
        {
          name: t(
            "settings:billing.v2.sections.flows.steps.title",
            "Included steps"
          ),
          tiers: {
            Launch: t(
              "settings:billing.v2.sections.flows.steps.launch",
              "Basic steps"
            ),
            Scale: t(
              "settings:billing.v2.sections.flows.steps.scale",
              "Basic + Advanced Steps (Trigger flow, Teams)"
            ),
            Enterprise: t("all", "All"),
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.flows.quantity.title",
            "Flows you create"
          ),
          tiers: {
            Launch: t("unlimited", "Unlimited"),
            Scale: t("unlimited", "Unlimited"),
            Enterprise: t("unlimited", "Unlimited"),
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.flows.templates",
            "Flow templates and tips"
          ),
          tiers: {
            Launch: true,
            Scale: true,
            Enterprise: true,
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.flows.broadcasts.title",
            "Broadcasts"
          ),
          tiers: {
            Launch: t("unlimited", "Unlimited"),
            Scale: t("unlimited", "Unlimited"),
            Enterprise: t("unlimited", "Unlimited"),
          },
          type: "check",
        },
      ],
    },
    {
      name: t(
        "settings:billing.v2.sections.reporting.title",
        "Reporting & Management"
      ),
      features: [
        {
          name: t(
            "settings:billing.v2.sections.reporting.basic-reports",
            "Basic Reports & Analytics"
          ),
          tiers: {
            Launch: true,
            Scale: true,
            Enterprise: true,
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.reporting.custom-reports",
            "Custom Reports"
          ),
          tiers: {
            Enterprise: true,
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.reporting.reports.title",
            "Team permissions"
          ),
          tiers: {
            Launch: t(
              "settings:billing.v2.sections.reporting.reports.launch",
              "Basic admin & agent roles"
            ),
            Scale: t(
              "settings:billing.v2.sections.reporting.reports.scale",
              "Advanced roles"
            ),
            Enterprise: t(
              "settings:billing.v2.sections.reporting.reports.enterprise",
              "Customizable roles & permissions"
            ),
          },
          type: "check",
        },
      ],
    },
    {
      name: t("settings:billing.v2.sections.support.title", "Support"),
      features: [
        {
          name: t(
            "settings:billing.v2.sections.support.response-time",
            "Response time"
          ),
          tiers: {
            Launch: "24hr",
            Scale: "24hr",
            Enterprise: t("custom", "Custom"),
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.support.basic-channels",
            "Support via WhatsApp/Email"
          ),
          tiers: { Launch: true, Scale: true, Enterprise: true },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.support.slack",
            "Support via Slack"
          ),
          tiers: { Enterprise: true },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.support.training.title",
            "Onboarding & training"
          ),
          tiers: {
            Launch: t(
              "settings:billing.v2.sections.support.training.launch",
              "1 hour"
            ),
            Scale: t(
              "settings:billing.v2.sections.support.training.scale",
              "2 hours"
            ),
            Enterprise: t("custom", "Custom"),
          },
          type: "check",
        },
        {
          name: t(
            "settings:billing.v2.sections.support.kam",
            "Account Manager"
          ),
          tiers: { Enterprise: true },
          type: "check",
        },
      ],
    },
  ];

  return (
    <div className="bg-white py-6 sm:py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl px-6 lg:px-8">
        <div className="flex items-center">
          <h4 className="flex-shrink-0 pr-4 text-xl font-semibold text-hilos">
            {t(
              "settings:billing.v2.title",
              "Select the plan that fits your team best."
            )}
          </h4>
          <div className="flex-1 border-t-2 border-gray-200" />
        </div>

        <div className="flex items-center flex-col sm:flex-row sm:justify-between mb-4 sm:mb-0">
          <div className="mt-2 text-xs text-gray-600 grow">
            {t(
              "settings:billing.v2.whatsapp-fees",
              "WhatsApp additionally charges for using their API. Check their fees"
            )}{" "}
            <a
              href="https://hilos.io/pricing#whatsapp-api"
              target="_blank"
              className="text-blue-600 font-medium"
              rel="noreferrer"
            >
              {t("here", "here")}
            </a>
            .
          </div>
          <div className="mt-1 text-xs text-gray-600 text-left sm:w-auto w-full">
            {t("settings:billing.v2.showing-plans", "Showing plans in")}{" "}
            <span className="font-medium text-gray-900">
              {currency.toLocaleUpperCase()}
            </span>
          </div>
        </div>

        {/* xs to lg */}
        <div className="mx-auto max-w-md space-y-8 sm:mt-16 lg:hidden">
          {tiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.selected
                  ? "rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"
                  : "",
                "p-8"
              )}
            >
              <h3
                id={tier.id}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {tier.name}
              </h3>
              <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                <span className="text-4xl font-bold">
                  {tier.priceMonthly[currency]}
                </span>
                {tier.name !== "Enterprise" && (
                  <span className="text-sm font-semibold">
                    /{t("mo", "mo")}
                  </span>
                )}
              </p>

              {tier.id === "ENTERPRISE" ? (
                <div className="mt-4">
                  <a
                    href="mailto:hey@hilos.io"
                    className="w-full block rounded-md bg-white px-3 py-2 text-sm text-center font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 leading-6"
                  >
                    {t("settings:billing.v2.talk-to-sales", "Talk to sales")}
                  </a>
                </div>
              ) : (
                <button
                  type="button"
                  className={classNames(
                    !tier.selected
                      ? "bg-gradient-to-r from-hilos to-red-400 text-white hover:bg-hilos"
                      : "text-hilos ring-1 ring-inset ring-hilos hover:ring-hilos",
                    "w-full mt-4 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hilos"
                  )}
                  disabled={disableActivePlan && tier.id === activePlan}
                  onClick={() => onSelectPlan(tier.id)}
                >
                  {disableActivePlan && tier.id === activePlan
                    ? t(
                        "settings:billing.v2.current-plan-selected",
                        "Current plan"
                      )
                    : t("settings:billing.v2.select-plan", "Select plan")}
                </button>
              )}

              <button
                type="button"
                className={classNames(
                  tier.selected
                    ? "bg-gradient-to-r from-hilos to-red-400 text-white hover:bg-hilos"
                    : "text-hilos ring-1 ring-inset ring-hilos hover:ring-hilos",
                  "w-full mt-4 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hilos"
                )}
                onClick={() => onSelectPlan(tier.id)}
              >
                {t("settings:billing.v2.select-plan", "Select plan")}
              </button>
              <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul className="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className="flex gap-x-3">
                            <CheckIcon
                              className="h-6 w-5 flex-none text-hilos"
                              aria-hidden="true"
                            />
                            <span>
                              {feature.name}{" "}
                              {feature.type === "extra" && (
                                <span className="text-sm leading-6 text-gray-500">
                                  ({feature.tiers[tier.name][currency]})
                                </span>
                              )}
                              {["check", "dimension"].includes(
                                feature.type
                              ) && (
                                <>
                                  {typeof feature.tiers[tier.name] ===
                                  "string" ? (
                                    <span className="text-sm leading-6 text-gray-500">
                                      ({feature.tiers[tier.name]})
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate hidden mt-8 lg:block">
          <div className="relative -mx-8">
            {tiers.some((tier) => tier.selected) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (tiers.findIndex((tier) => tier.selected) + 1) * 25
                    }%`,
                  }}
                >
                  <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">
                {t(
                  "settings:billing.v2.select-plan-comparison",
                  "Plan comparison"
                )}
              </caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {tiers.map((tier) => (
                    <th
                      key={tier.id}
                      scope="col"
                      className="px-6 pt-6 xl:px-8 xl:pt-8"
                    >
                      <div className="text-sm font-semibold leading-7 text-gray-900">
                        {tier.name}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.id} className="px-6 xl:px-8">
                      <div className="gap-x-1 text-gray-900">
                        <div className="text-4xl font-bold">
                          {tier.priceMonthly[currency]}
                        </div>
                        <div
                          className={classNames(
                            tier.name === "Enterprise"
                              ? "text-white"
                              : " text-gray-500",
                            "text-sm font-semibold leading-6"
                          )}
                        >
                          {currency.toUpperCase()}/{t("mo", "mo")}
                        </div>
                      </div>

                      {tier.id === "ENTERPRISE" ? (
                        <div className="mt-4">
                          <a
                            href="mailto:hey@hilos.io"
                            className="w-full block rounded-md bg-white px-3 py-2 text-sm text-center font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 leading-6"
                          >
                            {t(
                              "settings:billing.v2.talk-to-sales",
                              "Talk to sales"
                            )}
                          </a>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className={classNames(
                            !tier.selected
                              ? "bg-gradient-to-r from-hilos to-red-400 text-white hover:bg-hilos"
                              : "text-hilos ring-1 ring-inset ring-hilos hover:ring-hilos",
                            "w-full mt-4 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hilos"
                          )}
                          disabled={disableActivePlan && tier.id === activePlan}
                          onClick={() => onSelectPlan(tier.id)}
                        >
                          {disableActivePlan && tier.id === activePlan
                            ? t(
                                "settings:billing.v2.current-plan-selected",
                                "Current plan"
                              )
                            : t(
                                "settings:billing.v2.select-plan",
                                "Select plan"
                              )}
                        </button>
                      )}
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? "pt-8" : "pt-16",
                          "pb-4 text-sm font-semibold leading-6 text-gray-900"
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 text-sm font-normal leading-6 text-gray-900"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.name] === "string" ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {tier.id !== "ENTERPRISE" && feature.extra ? (
                                  <>
                                    <strong className="font-medium">
                                      {feature.tiers[tier.name]}
                                    </strong>

                                    <div className="text-center text-sm leading-6 text-gray-500">
                                      {feature.extra.name}:{" "}
                                      {feature.extra.tiers[tier.name][currency]}{" "}
                                    </div>
                                  </>
                                ) : (
                                  feature.tiers[tier.name]
                                )}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CheckIcon
                                    className="mx-auto h-5 w-5 text-hilos"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="mx-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.name] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
