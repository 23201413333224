import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { debounce } from "lodash";
import { FlowData } from "@hilos/types/flow";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import WhatsAppTemplatePreview from "src/containers/wa/WhatsAppTemplatePreview";
import WhatsAppTemplateSelect from "src/containers/wa/WhatsAppTemplateSelect";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import { getTemplateQuickReplyOptions } from "src/helpers/template";
import { hasItems, removeFalsyValuesFromEndArray } from "src/helpers/utils";
import { getRequiredVariablesFromTemplate } from "../../../helpers/steps/template";
import useFlowBuilderStore from "../../../hooks/useFlowBuilderStore";
import { useStepField } from "../../../hooks/useStepField";
import FlowBuilderMaxAnswerAttempts from "../../FlowBuilderMaxAnswerAttempts";
import FlowBuilderMsgFail from "../../FlowBuilderMsgFail";
import FlowBuilderWaitUntil from "../../FlowBuilderWaitUntil";
import ConfirmTemplateChangeModal from "./ConfirmTemplateChangeModal";
import FlowStepTemplateForm from "./FlowBuilderStepTemplateForm";

interface FlowBuilderStepTemplateMenuProps {
  id: string;
  index: number;
}

type NextAction = "ANY_RESPONSE" | "BUTTON_RESPONSE" | "CONTINUE" | undefined;

function FlowBuilderStepTemplateMenu({
  id,
  index,
}: FlowBuilderStepTemplateMenuProps) {
  const [t] = useTranslation();
  const { values } = useFormikContext<FlowData>();

  const nextTemplateSelectedRef = useRef<WhatsAppTemplate | undefined>();
  const [showChangeTemplateModal, setShowChangeTemplateModal] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [modalConfirmButton, setModalConfirmButton] = useState("");
  const [modalActionDescription, setModalActionDescription] = useState("");

  const { onFlowRefresh } = useFlowBuilderStore();

  const [templateSelected, setTemplateSelected] = useStepField({
    index,
    name: "whatsapp_template_selected",
  });
  const [_, setTemplateIdSelected] = useStepField({
    index,
    name: "whatsapp_template",
  });
  const [templateVariables, setTemplateVariables] = useStepField({
    index,
    name: "whatsapp_template_variables",
  });
  const [answerOptions, setAnswerOptions] = useStepField({
    index,
    name: "answer_options",
  });
  const [nextStepsForOptions, setNextStepOptions] = useStepField({
    index,
    name: "next_steps_for_options",
  });
  const [nextAction, setNextAction] = useStepField({
    index,
    name: "next_action",
  });

  const previousNextAction = useRef<NextAction>(nextAction);
  const selectedNextAction = useRef<NextAction>(nextAction);

  const quickReplyOptionsSelectedTemplate = useMemo(() => {
    if (!templateSelected) {
      return [];
    }
    return getTemplateQuickReplyOptions(templateSelected);
  }, [templateSelected]);

  const hasQuickReplyButtonsSelectedTemplate = useMemo(() => {
    return hasItems(quickReplyOptionsSelectedTemplate);
  }, [quickReplyOptionsSelectedTemplate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleRefreshFlow = useCallback(
    debounce(async () => {
      onFlowRefresh(id);
    }, 750),
    [nextStepsForOptions, setNextStepOptions]
  );

  const handleTemplateMenuOptions = useCallback(
    (
      nextTemplateSelected: WhatsAppTemplate | undefined,
      isSelectedTurnIntoMenu: boolean
    ) => {
      const nextOptionsFromSelectedTemplate =
        nextTemplateSelected &&
        getTemplateQuickReplyOptions(nextTemplateSelected);
      const hasNextTemplateQuickReplyButtons = hasItems(
        nextOptionsFromSelectedTemplate
      );
      const nextTurnIntoMenu =
        isSelectedTurnIntoMenu && hasNextTemplateQuickReplyButtons;
      if (isSelectedTurnIntoMenu && !hasNextTemplateQuickReplyButtons) {
        // If the newly selected template has no buttons, reset the nextStep
        setNextAction("ANY_RESPONSE");
        previousNextAction.current = "ANY_RESPONSE";
        selectedNextAction.current = "ANY_RESPONSE";
      }
      const nextOptionsIds = removeFalsyValuesFromEndArray(nextStepsForOptions);

      setAnswerOptions(nextTurnIntoMenu ? nextOptionsFromSelectedTemplate : []);
      setNextStepOptions(nextOptionsIds);
    },
    [nextStepsForOptions, setAnswerOptions, setNextStepOptions, setNextAction]
  );

  const handleSetTurnIntoMenu = useCallback(
    (isSelectedTurnIntoMenu: boolean) => {
      if (!isSelectedTurnIntoMenu) {
        // Show modal to confirm the change
        setModalTitle(
          t(
            "flows:steps.template-menu.turn-into-menu.title",
            "Disable menu functionality?"
          )
        );
        setModalActionDescription(
          t(
            "flows:steps.template-menu.turn-into-menu.action-description",
            "If you disable the menu functionality, the quick reply buttons paths will be removed and the flow will need to re-arrenged or updated to be published."
          )
        );
        setModalConfirmButton(
          t(
            "flows:steps.template-menu.disable-menu-confirm",
            "Yes, disable menu"
          )
        );
        setShowChangeTemplateModal(true);
      } else {
        handleTemplateMenuOptions(templateSelected, isSelectedTurnIntoMenu);
      }
    },
    [handleTemplateMenuOptions, t, templateSelected]
  );

  const handleTemplateChange = useCallback(() => {
    if (nextTemplateSelectedRef.current != null) {
      const { bodyVariables, buttonVariables } =
        getRequiredVariablesFromTemplate(nextTemplateSelectedRef.current);
      setTemplateSelected(nextTemplateSelectedRef.current);
      setTemplateVariables({
        headerURL: "",
        headerVar: "",
        bodyVars: bodyVariables.map(() => ""),
        buttonVars: buttonVariables.map(() => ""),
      });
    }
    handleTemplateMenuOptions(
      nextTemplateSelectedRef.current,
      nextAction === "BUTTON_RESPONSE"
    );
    previousNextAction.current = selectedNextAction.current;
  }, [
    handleTemplateMenuOptions,
    setTemplateSelected,
    setTemplateVariables,
    previousNextAction,
    nextAction,
  ]);

  const handleMenuChangeCancel = useCallback(() => {
    setTemplateIdSelected(templateSelected?.id);
    setNextAction(previousNextAction.current);
  }, [setTemplateIdSelected, setNextAction, templateSelected?.id]);

  const setSelectedTemplate = useCallback(
    (nextTemplateSelected: WhatsAppTemplate | undefined) => {
      nextTemplateSelectedRef.current = nextTemplateSelected;

      // Logic to check if the new template has less quick reply buttons than the old one
      // If so, show a modal to confirm the change
      if (
        nextAction === "BUTTON_RESPONSE" &&
        nextTemplateSelected &&
        getTemplateQuickReplyOptions(nextTemplateSelected).length <
          quickReplyOptionsSelectedTemplate.length
      ) {
        setModalTitle(
          t(
            "flows:steps.template-menu.change-template.title",
            "Change template"
          )
        );
        setModalActionDescription(
          t(
            "flows:steps.template-menu.change-template.action-description",
            "The template you selected has less quick reply buttons, this will create that some paths become unreachable you will need re-arrenge or delete some steps to be able to publish the flow."
          )
        );
        setModalConfirmButton(
          t(
            "flows:steps.template.change-template.confirm",
            "Yes, change template"
          )
        );
        setShowChangeTemplateModal(true);
      } else {
        handleTemplateChange();
      }
    },
    [
      handleTemplateChange,
      quickReplyOptionsSelectedTemplate.length,
      t,
      nextAction,
    ]
  );

  useEffect(() => {
    debouncedHandleRefreshFlow();
    return debouncedHandleRefreshFlow.cancel;
  }, [debouncedHandleRefreshFlow, templateSelected, answerOptions]);

  useEffect(() => {
    // Clean up the next steps for options array
    // This should be done when ever the flow is refreshed but
    // I don't know how to do that
    // Or maybe I could add some extra logic in the `utils.ts` > `getOptionsFromStep`
    // To handle when there aren't any options and is only an array of empty strings
    // And then in the validation of the flow, remove those empty strings
    const nextOptionsIds = removeFalsyValuesFromEndArray(nextStepsForOptions);
    setNextStepOptions(nextOptionsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NEXT_ACTION_OPTIONS = [
    {
      label: t(
        "flows:steps.template-menu.next-steps.any-response",
        "Wait for any response and continue"
      ),
      value: "ANY_RESPONSE",
      available: true,
    },
    {
      label: t(
        "flows:steps.template-menu.next-steps.button-response",
        "Wait until user selects a button"
      ),
      value: "BUTTON_RESPONSE",
      available: hasQuickReplyButtonsSelectedTemplate,
    },
    {
      label: t(
        "flows:steps.template-menu.next-steps.continue",
        "Don't wait for a response and continue flow"
      ),
      value: "CONTINUE",
      available: true,
    },
  ];

  const handleChangeNextActionOptions = useCallback(
    (selectedNextStepOption) => {
      selectedNextAction.current = selectedNextStepOption.value;
      if (selectedNextStepOption.value === "BUTTON_RESPONSE") {
        handleSetTurnIntoMenu(true);
        // These require no confirmation, so setting refs directly
        previousNextAction.current = selectedNextStepOption.value;
      } else {
        if (previousNextAction.current === "BUTTON_RESPONSE") {
          // Choosing this requires confirmation that you'd like to disable the menu
          handleSetTurnIntoMenu(false);
        } else {
          // These require no confirmation, so setting refs directly
          previousNextAction.current = selectedNextStepOption.value;
        }
      }
    },
    [handleSetTurnIntoMenu]
  );

  return (
    <div className="space-y-4">
      <WhatsAppTemplateSelect
        channel={values.channel as unknown as number}
        fieldName={`steps.${index}.whatsapp_template`}
        fieldLabel={t(
          "flows:steps.template.whatsapp-template.label",
          "WhatsApp template to use"
        )}
        setSelectedTemplate={setSelectedTemplate}
      />

      {!!templateSelected && (
        <>
          <WhatsAppTemplatePreview
            template={templateSelected}
            values={templateVariables || {}}
          />

          <div>
            <FlowStepTemplateForm
              index={index}
              template={templateSelected}
              variables={templateVariables}
            />
          </div>

          <SelectorField
            name={`steps.${index}.next_action`}
            label={t(
              "flows:steps.template.next-actions.label",
              "After sending this message..."
            )}
            options={NEXT_ACTION_OPTIONS.filter((option) => option.available)}
            onSelect={handleChangeNextActionOptions}
          />
        </>
      )}
      {nextAction === "BUTTON_RESPONSE" && (
        <TextInputField
          type="text"
          name={`steps.${index}.answer_validation_message`}
          label={t(
            "flows:steps.template.answer-validation-message.label",
            "Answer validation message"
          )}
          help={t(
            "flows:steps.template.answer-validation-message.help",
            "If the contact answers with something other than the button values, we'll answer with this message."
          )}
          placeholder={t(
            "flows:steps.template.answer-validation-message.placeholder",
            "Please choose an option to continue."
          )}
        />
      )}
      {nextAction === "BUTTON_RESPONSE" && (
        <FlowBuilderMaxAnswerAttempts id={id} index={index} />
      )}
      <FlowBuilderWaitUntil id={id} index={index} />

      <FlowBuilderMsgFail id={id} index={index} />

      <ConfirmTemplateChangeModal
        obj={nextTemplateSelectedRef.current}
        title={modalTitle}
        actionDescription={modalActionDescription}
        confirmButton={modalConfirmButton}
        show={showChangeTemplateModal}
        setShow={setShowChangeTemplateModal}
        onOk={handleTemplateChange}
        onCancel={handleMenuChangeCancel}
      />
    </div>
  );
}

export default FlowBuilderStepTemplateMenu;
