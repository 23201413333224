import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon, SaveIcon } from "@heroicons/react/outline";
import Button from "src/components/Button";
import { InboxParamsFormikProps } from "./InboxParamsForm";

interface InboxParamsFormButtonsProps {
  formik: InboxParamsFormikProps;
  isEditingView?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function InboxParamsFormButtons({
  formik,
  isEditingView,
  onClose,
  onSubmit,
}: InboxParamsFormButtonsProps) {
  const { t } = useTranslation();
  const { dirty, values, isSubmitting, setFieldValue } = formik;

  const handleClearFilters = useCallback(() => {
    setFieldValue("view", null);
    setFieldValue("filters", []);
    setFieldValue("ordering", "-last_message_on");
    onSubmit();
  }, [onSubmit, setFieldValue]);

  if (isEditingView) {
    return (
      <>
        <Button
          type="button"
          variant="outlined"
          isSubmitting={isSubmitting}
          onClick={onClose}
        >
          {t("cancel", "Cancel")}
        </Button>
        <Button
          type="submit"
          disabled={!dirty}
          isSubmitting={isSubmitting}
          icon={<SaveIcon className="ml-1 -mr-1 h-5 w-5" />}
        >
          {t("save", "Save")}
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        type="button"
        variant="outlined"
        disabled={!values.filters.length}
        isSubmitting={isSubmitting}
        onClick={handleClearFilters}
      >
        {t("clear-all", "Clear all")}
      </Button>
      <Button
        type="submit"
        disabled={!dirty}
        isSubmitting={isSubmitting}
        icon={<CheckIcon className="ml-1 -mr-1 h-5 w-5" />}
        id="apply-filter-btn"
      >
        {t("apply", "Apply")}
      </Button>
    </>
  );
}

export default InboxParamsFormButtons;
