import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {
  ArrowSmRightIcon,
  BeakerIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { AccountStatus } from "src/types/account";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";

export default function AccountStatusNotice() {
  const { session } = useHilosStore();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  if (!session) {
    return null;
  }

  const getTitle = () => {
    switch (pathname) {
      case "/template":
        return t(
          "templates:account-in-sandbox",
          "You are currently in sandbox mode and can't save templates"
        );

      default:
        return t("account-in-sandbox", "Your account is in Sandbox");
    }
  };

  const getSubtitle = () => {
    switch (pathname) {
      case "/template":
        return t(
          "templates:check-template-builder",
          "Check out the template builder to see what is possible or connect a number to build your own templates!"
        );

      default:
        return t(
          "account-in-sandbox-notice",
          "Whenever you're ready to use your company's phone number you can connect it here."
        );
    }
  };

  return (
    <>
      {session.account.status === AccountStatus.NEW && (
        <div className="bg-yellow-50 p-3">
          <div className="flex">
            <div className="flex-shrink-0 self-center">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <div className="self-center">
                <p className="text-sm font-semibold text-yellow-700">
                  It seems Hilos is not yet connected to WhatsApp.
                </p>
                <p className="text-sm text-yellow-700">
                  Try all of Hilos' features by connecting your phone number to
                  our sandbox. This way you'll be able to send and receive
                  messages from the number you connect.
                </p>
              </div>
              <Link
                to={buildRoute("dashboard")}
                className="mt-2 inline-flex rounded-md border border-yellow-700 bg-white p-3 text-sm font-medium text-yellow-700 md:ml-auto"
              >
                Activate Sandbox
                <ArrowSmRightIcon
                  className=" ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
      {session.account.status === AccountStatus.IN_SANDBOX && (
        <div className="bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0 self-center">
              <BeakerIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <div className="self-center">
                <p className="text-sm font-semibold text-blue-700">
                  {getTitle()}
                </p>
                <p className="text-sm text-blue-500">{getSubtitle()}</p>
              </div>
              <Link
                to={buildRoute("config-channel")}
                className="mt-2 inline-flex rounded-md border border-blue-700 bg-white p-3 text-sm font-medium text-blue-700 md:ml-auto"
              >
                {t("activate-account", "Connect your number")}
                <ArrowSmRightIcon
                  className=" ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
