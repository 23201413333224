import { Link } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { buildRoute } from "src/router/router";
import useHilosStore from "../../hooks/useHilosStore";

export default function AccountSubscriptionMXCFDINotice() {
  const { session } = useHilosStore();

  if (!session) {
    return null;
  }

  return (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-blue-800">
            Información para empresas operando en México 🇲🇽:
          </h3>
          <div className="mt-2 text-sm text-blue-700">
            <ul className="list-disc space-y-1 pl-5">
              <li>
                Debido a una limitante con nuestro proveedor de pagos, mostramos
                los precios en MXN. Si deseas pagar con una tarjeta cuya moneda
                sea diferente, cambia el país de tu empresa en{" "}
                <Link
                  to={buildRoute("config-account-basic")}
                  className="font-medium text-blue-800"
                >
                  la configuración de tu cuenta
                </Link>
                .
              </li>
              <li>
                <span className="font-medium text-blue-800">Facturación:</span>{" "}
                Después de realizar el pago recibirás un correo de Gigstack con
                instrucciones para subir los datos fiscales y generar CFDIs en
                cada pago.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
