import { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import { WorkflowStateRead } from "@hilos/types/private-schema";
import { Input } from "src/components/ui/input";
import useAccount from "src/hooks/useAccount";
import { userToString } from "src/Helpers";
import {
  WorkflowBoardStore,
  useWorkflowBoardContext,
} from "./WorkflowBoardContext";
import { WorkflowBoardFilterOptions } from "./WorkflowBoardFilterOptions";

interface WorkflowBoardFiltersProps {
  states: WorkflowStateRead[];
}

export default function WorkflowBoardFilters({
  states,
}: WorkflowBoardFiltersProps) {
  const [t] = useTranslation();
  const { members } = useAccount();
  const { columns, onUpdateColumns, onUpdateFilters } =
    useWorkflowBoardContext<WorkflowBoardStore>();

  const handleSearch = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      onUpdateFilters({ search: event.target.value || "" });
    }, 400),
    [onUpdateFilters]
  );

  const handleAssignedTo = useCallback(
    (assigned: number[]) => {
      onUpdateFilters({ assigned });
    },
    [onUpdateFilters]
  );

  return (
    <div className="flex items-center justify-start w-full pt-4 px-8 space-x-4">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder={t(
            "workflows:filters.search.placeholder",
            "Filter tasks..."
          )}
          onChange={handleSearch}
          className="h-8 w-[150px] lg:w-[250px] focus-visible:outline-none focus-visible:ring-offset-0 focus-visible:ring-1 focus-visible:ring-zinc-400"
        />
        <WorkflowBoardFilterOptions
          title={t("workflows:filters.assigned-to.title", "Assigned to")}
          options={members.map((user) => ({
            value: user.id,
            label: userToString(user),
          }))}
          // @ts-ignore
          onUpdateSelected={handleAssignedTo}
        />
        <WorkflowBoardFilterOptions
          title={t("workflows:filters.states.title", "States")}
          options={states.map((state) => ({
            value: state.id,
            label: state.name || "",
          }))}
          selected={columns}
          minSelection={2}
          onUpdateSelected={onUpdateColumns}
        />
      </div>
    </div>
  );
}
