import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowSmRightIcon, ExclamationIcon } from "@heroicons/react/outline";
import useHilosStore from "src/hooks/useHilosStore";
import { buildRoute } from "src/router/router";

function SubscriptionIncompleteNotice() {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  if (!session?.account) {
    return null;
  }

  return (
    <div className="bg-red-50 p-3">
      <div className="space-y-2">
        <div className="flex justify-center">
          <ExclamationIcon
            className="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="flex-1 items-center space-y-1">
          <p className="text-sm font-semibold text-red-700">
            {t(
              "settings:subscription-incomplete-notice.title",
              "There was a problem processing your payment to start your subscription."
            )}
          </p>
          <p className="text-sm text-red-700">
            {t(
              "settings:subscription-incomplete-notice.subtitle",
              "To get started, please make sure the payment method you added is active."
            )}
          </p>
        </div>
        <div className="w-full">
          <Link
            to={buildRoute("config-account-subscription")}
            className="inline-flex w-full justify-center rounded-md border border-red-700 bg-white px-2 py-1.5 text-sm font-medium text-red-700"
          >
            {t(
              "settings:subscription-incomplete-notice.button",
              "Start subscription"
            )}
            <ArrowSmRightIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionIncompleteNotice;
