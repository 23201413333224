import { PropsWithChildren, useEffect, useRef, useState } from "react";
import axios from "axios";
import { StoreApi } from "zustand/vanilla";
import { WorkflowRead, WorkflowTaskRead } from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import { hasItems } from "src/helpers/utils";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import {
  WorkflowBoardContext,
  WorkflowBoardStore,
  createWorkflowBoardStore,
} from "./WorkflowBoardContext";

type WorkflowBoardProviderProps = PropsWithChildren<{ id: string }>;

export function WorkflowBoardProvider({
  id,
  children,
}: WorkflowBoardProviderProps) {
  const storeRef = useRef<StoreApi<WorkflowBoardStore> | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getInitialStore = async () => {
      setLoading(true);

      const { data: workflowData } = await axios.get<WorkflowRead>(
        buildAPIRoute(API_ROUTES.WORKFLOW_DETAIL, {
          ":id": id,
        })
      );
      const { data: workflowTaskData } = await axios.get<WorkflowTaskRead[]>(
        buildAPIRoute(API_ROUTES.WORKFLOW_TASK_LIST_CREATE, {
          ":workflow_id": id,
        })
      );

      if (workflowData && hasItems(workflowData.states)) {
        storeRef.current = createWorkflowBoardStore(
          id,
          workflowTaskData || [],
          workflowData.states
        );
      }

      setLoading(false);
    };

    getInitialStore();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (!storeRef.current) {
    return null;
  }

  return (
    <WorkflowBoardContext.Provider value={storeRef.current}>
      {children}
    </WorkflowBoardContext.Provider>
  );
}
