import { useEffect, useState } from "react";

function getVisibilityPropertyNames() {
  // Opera 12.10 and Firefox 18 and later support
  if (typeof document.hidden !== "undefined") {
    return ["hidden", "visibilitychange"];
  }

  // @ts-ignore
  if (typeof document.msHidden !== "undefined") {
    return ["msHidden", "msvisibilitychange"];
  }

  // @ts-ignore
  if (typeof document.webkitHidden !== "undefined") {
    return ["webkitHidden", "webkitvisibilitychange"];
  }

  return ["hidden", "visibilitychange"];
}

const [hidden, visibilityChange] = getVisibilityPropertyNames();
const hasWindowEvents = "focus" in window && "blur" in window;

const isDocumentVisible = () => !document[hidden];

function usePageVisibility() {
  const [isFocused, setIsFocused] = useState(isDocumentVisible());
  const [isVisible, setIsVisible] = useState(isDocumentVisible());

  useEffect(() => {
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const handleChangeVisibility = () => setIsVisible(isDocumentVisible());

    if (hasWindowEvents) {
      window.addEventListener("focus", handleFocus);
      window.addEventListener("blur", handleBlur);
    }

    document.addEventListener(visibilityChange, handleChangeVisibility);

    return () => {
      if (hasWindowEvents) {
        window.removeEventListener("focus", handleFocus);
        window.removeEventListener("blur", handleBlur);
      }

      document.removeEventListener(visibilityChange, handleChangeVisibility);
    };
  }, []);

  return { isFocused, isVisible };
}

export default usePageVisibility;
