import { API_ROUTES, buildAPIRoute } from "../../router/router";

import { Broadcast } from "../../types/broadcast";
import { DateTime } from "luxon";
import { DownloadIcon } from "@heroicons/react/outline";
import axios from "axios";
import { classNames } from "src/Helpers";
import { useTranslation } from "react-i18next";

interface BroadcastCSVDownloadProps {
  broadcast: Broadcast;
  className?: string;
}

export default function BroadcastCSVDownload({
  broadcast,
  className,
}: BroadcastCSVDownloadProps) {
  const { t } = useTranslation();
  const downloadResults = async () => {
    try {
      const response = await axios.get(
        buildAPIRoute(API_ROUTES.MESSAGE_BLAST_DOWNLOAD, {
          ":id": broadcast.id,
        })
      );
      const hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURI(response.data);
      hiddenElement.target = "_blank";
      //provide the name for the CSV file to be downloaded
      hiddenElement.download = `${
        broadcast.name
      } Results ${DateTime.now().toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS
      )}.csv`;
      hiddenElement.click();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <button
      className={classNames(
        "inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-3 py-2 text-sm font-medium leading-4 text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
        className
      )}
      type="button"
      onClick={downloadResults}
    >
      <DownloadIcon className="mr-1 h-5 w-5" aria-hidden="true" />
      {t("broadcasts:download-results", "Download Results")}
    </button>
  );
}
