import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getRequiredVariablesFromTextValue } from "src/helpers/variables";
import { TRIGGER_TYPES } from "../builder/constants/triggers";

export default function InboundSpecificMessageTriggerDetail({ flow }) {
  const { t } = useTranslation();
  const triggerData = TRIGGER_TYPES[flow.trigger_type];

  const inboundTriggerVariableNames = useMemo(() => {
    return flow.trigger_config.has_variables &&
      flow.trigger_config.message_content_to_match
      ? getRequiredVariablesFromTextValue(
          flow.trigger_config.message_content_to_match
        )
      : [];
  }, [flow]);

  return (
    <>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("trigger", "Trigger")}
      </h3>
      <div className="flex items-center space-x-1">
        <div>
          <triggerData.icon className="h-8 w-8 text-gray-800" />
        </div>
        <div className="font-medium text-gray-900">
          <p className="text-xl">{t(triggerData.name)}</p>
        </div>
      </div>
      <div className="text-sm text-gray-700 mt-2">
        {flow.trigger_config.has_variables ? (
          <div className="space-y-2">
            <p>
              {t(
                "flows:trigger-detail.inbound-specific-message-w-vars",
                "This flow will trigger when your contact sends a message with a body that matches:"
              )}
            </p>
            <div className="rounded-md bg-gray-100 p-2">
              <code className="text-sm font-medium text-gray-900">
                {flow.trigger_config.message_content_to_match}
              </code>
            </div>
            <div>
              <p>
                {t(
                  "flows:trigger-detail.inbound-specific-message-w-vars-extract",
                  "We'll extract the following variables from the message body:"
                )}
              </p>
              <div className="mt-1">
                {inboundTriggerVariableNames.map((varName) => (
                  <span
                    key={varName}
                    className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 m-0.5"
                  >
                    {varName}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-3">
            <p>
              {flow.trigger_config.match_exact_message_content
                ? t(
                    "flows:trigger-detail.inbound-specific-message-match-exact",
                    "This flow will trigger when a contact sends a message with the exact text"
                  )
                : t(
                    "flows:trigger-detail.inbound-specific-message-contains",
                    "This flow will trigger when a contact sends a message that contains the text"
                  )}
              :{" "}
            </p>
            <p>
              <span className="rounded-md bg-gray-50 p-2">
                <code className="text-sm font-medium text-gray-900">
                  {flow.trigger_config.message_content_to_match}
                </code>
              </span>
            </p>
          </div>
        )}
      </div>
    </>
  );
}
