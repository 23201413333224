import { useState } from "react";
import FieldAddVariableForm from "./FieldAddVariableForm";

export default function FieldAddVariableContainer({
  formik,
  fieldName,
  currentValue,
  availableVariables,
  children,
}) {
  const [showVariableSelect, setShowVariableSelect] = useState(false);

  const addVariable = (value) => {
    const nextValue = currentValue ? `${currentValue} ${value}` : value;
    formik.setFieldValue(fieldName, nextValue);
  };

  return (
    <div className="space-y-3">
      {children(setShowVariableSelect)}
      {showVariableSelect && (
        <FieldAddVariableForm
          addVariable={addVariable}
          availableVariables={availableVariables}
          setShowVariableSelect={setShowVariableSelect}
          helpLabel="You can insert variables from available contact fields."
        />
      )}
    </div>
  );
}
