import * as yup from "yup";
import { TimeWindowMeta } from "src/containers/flow/Helpers";
import { getVariablesRequiredFromTemplate } from "src/containers/wa/WhatsAppTemplateMeta";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const FlowStepTemplateMeta = {
  getInitialValues: function (step) {
    return {
      name: step?.name || "",
      whatsapp_template: "",
      step_type: "TEMPLATE",
      whatsapp_template_selected: "",
      next_step_default_idx: step?.next_step_default_idx || "",
      save_contact_answer: false,
      validate_answer_with_buttons: false,
      answer_validation_message: "",
      whatsapp_template_variables: {
        headerURL: "",
        headerVar: "",
        bodyVars: [],
        buttonVars: [],
      },
      max_wait_time_amount: "24",
      max_wait_time_unit: "HOUR",
      set_time_window: false,
      time_window_type: "CUSTOM",
      time_windows: [],
      has_max_answer_attempts: false,
      max_answer_attempts: 0,
      answer_failed_next_step_idx: "",
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      whatsapp_template: yup.string().required(ERROR_MESSAGES.required),
      max_answer_attempts: yup.string().when("has_max_answer_attempts", {
        is: true,
        then: yup.number().required(ERROR_MESSAGES.required),
      }),
      answer_failed_next_step_idx: yup
        .string()
        .when("has_max_answer_attempts", {
          is: true,
          then: yup.string().required(ERROR_MESSAGES.required),
        }),
      save_contact_answer: yup.bool(),
      validate_answer_with_buttons: yup.bool(),
      answer_validation_message: yup
        .string()
        .when("validate_answer_with_buttons", {
          is: true,
          then: yup.string().required(ERROR_MESSAGES.required),
        }),
      max_wait_time_amount: yup
        .number()
        .min(0, ERROR_MESSAGES.minNumberValue)
        .required(ERROR_MESSAGES.required),
      max_wait_time_unit: yup.string().required(ERROR_MESSAGES.required),
      set_time_window: yup.bool(),
      time_window_type: yup.string().when("set_time_window", {
        is: true,
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      time_windows: yup.mixed().when(["set_time_window", "time_window_type"], {
        is: (setTimeWindow, timeWindowType) =>
          setTimeWindow && timeWindowType === "CUSTOM",
        then: yup
          .array()
          .of(TimeWindowMeta.getSchema())
          .min(6, ERROR_MESSAGES.length_error),
      }),
    });
  },
  validate: function (formValues, step) {
    // Sometimes this variable isn't set. I don't know why yet but this does
    // not allow the flow to be saved, so we'll fill it with the default value
    // here.
    if (!step.whatsapp_template_variables) {
      step.whatsapp_template_variables = {
        headerURL: "",
        headerVar: "",
        bodyVars: [],
        buttonVars: [],
      };
    }

    // Make sure all variables are set
    const errors = { whatsapp_template_variables: {} };
    // Also making sure a template was selected when trying to save.

    if (!step.whatsapp_template_selected) {
      errors["whatsapp_template"] = ERROR_MESSAGES.required;
    }
    const template = step.whatsapp_template_selected;
    const {
      headerRequiresURL,
      headerRequiresVariable,
      bodyVariables,
      buttonsVariables,
    } = getVariablesRequiredFromTemplate(template);

    if (headerRequiresURL && !step.whatsapp_template_variables.headerURL) {
      errors["whatsapp_template_variables"]["headerURL"] =
        ERROR_MESSAGES.required;
    }
    if (headerRequiresVariable && !step.whatsapp_template_variables.headerVar) {
      errors["whatsapp_template_variables"]["headerURL"] =
        ERROR_MESSAGES.required;
    }
    if (
      bodyVariables.length !==
      step.whatsapp_template_variables.bodyVars.filter((v) => v).length
    ) {
      // Set each missing var as required
      errors["whatsapp_template_variables"]["bodyVars"] = [];
      bodyVariables.forEach((_, index) => {
        if (!step.whatsapp_template_variables.bodyVars[index]) {
          errors["whatsapp_template_variables"]["bodyVars"][index] =
            ERROR_MESSAGES.required;
        }
      });
    }
    if (
      step.whatsapp_template_variables.buttonVars &&
      buttonsVariables.length !==
        step.whatsapp_template_variables.buttonVars.filter((v) => v).length
    ) {
      errors["whatsapp_template_variables"]["buttonVars"] = [];
      buttonsVariables.forEach((_, index) => {
        if (!step.whatsapp_template_variables.buttonVars[index]) {
          errors["whatsapp_template_variables"]["buttonVars"][index] =
            ERROR_MESSAGES.required;
        }
      });
    }
    if (Object.keys(errors.whatsapp_template_variables).length > 0) {
      return errors;
    }
    return {};
  },
  formatForSave: function (step) {
    const nextStep = { ...step };
    delete nextStep.whatsapp_template_selected;
    if (!nextStep.answer_validation_message) {
      delete nextStep.answer_validation_message;
    }
    return nextStep;
  },
  FIELDS: {
    whatsapp_template: {
      key: "whatsapp_template",
      label: "WhatsApp template to use",
      help: "",
    },
    whatsapp_template_selected: {
      key: "whatsapp_template_selected",
    },
    next_step_default_idx: {
      key: "next_step_default_idx",
      label: "Next step",
      help: "If you can't find the step you'd like to go to next, check that it has a name.",
      placeholder: "Step 1 Welcome",
    },
    save_contact_answer: {
      key: "save_contact_answer",
      label: "Save contact's answer?",
      help: "If set, we'll wait for an answer from the contact and save it, as if it were a Question step.",
    },
    validate_answer_with_buttons: {
      key: "validate_answer_with_buttons",
      label: "Validate contact answer?",
      help: "If set, we'll validate that the contact's answer is one of this template's buttons.",
    },
    answer_validation_message: {
      key: "answer_validation_message",
      label: "Answer validation message",
      help: "If the contact answers with something other than the button values, we'll answer with this message.",
      placeholder: "Please choose an option to continue.",
    },
    whatsapp_template_variables: {
      key: "whatsapp_template_variables",
      headerURL: {
        key: "headerURL",
      },
      headerVar: {
        key: "headerVar",
      },
      bodyVars: {
        key: "bodyVars",
      },
      buttonVars: {
        key: "buttonVars",
      },
    },
  },
};
