import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { MenuIcon, SupportIcon, XIcon } from "@heroicons/react/outline";
import AlertIconWithIndicator from "src/components/AlertIconWithIndicator";
import LangToggle from "src/components/LangToggle";
import i18n from "src/i18n";
import { classNames, userAvatar, userToString } from "../Helpers";
import logoImago from "../assets/img/hilos_imago_opt.webp";
import useHilosStore from "../hooks/useHilosStore";
import { buildRoute } from "../router/router";
import Navigation from "./Navigation";
import NotificationSlideOver from "./NotificationSlideOver";
import UserNotificationList from "./user/UserNotificationList";

function MobileMenu() {
  const { language } = i18n;
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const { version } = useHilosStore();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const handleCloseMenu = useCallback(() => {
    setMobileMenuOpen(false);
  }, [setMobileMenuOpen]);

  return (
    <>
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 md:hidden"
          onClose={handleCloseMenu}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />
            <div className="fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
                enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
                enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
                leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
                leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
              >
                <nav
                  className="fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
                  aria-label="Global"
                >
                  <div className="flex h-16 items-center justify-between px-4 sm:px-6">
                    <button onClick={handleCloseMenu} type="button">
                      <Link to={buildRoute("dashboard")}>
                        <img
                          className="block h-8 w-auto"
                          src={logoImago}
                          alt="Hilos"
                        />
                      </Link>
                    </button>

                    <button
                      type="button"
                      className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                      onClick={handleCloseMenu}
                    >
                      <span className="sr-only">
                        {t("navigation:close-main-menu", "Close main menu")}
                      </span>
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                    <Navigation onCloseMenu={handleCloseMenu} />
                  </div>
                  <hr className="border-t border-gray-200" />
                  <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                    <h3 className="px-2 text-tiny uppercase tracking-wider text-gray-400">
                      {t("help", "Help")}
                    </h3>
                    <button onClick={handleCloseMenu} type="button">
                      <ul>
                        <li className="px-2 py-2 text-sm text-gray-400">
                          <a
                            className="flex items-center"
                            href="https://hilos.io/docs/user/getting-started/prerequisites"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <SupportIcon className="mr-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />{" "}
                            {t("getting-started", "Getting started")}
                          </a>
                        </li>
                        <li className="px-2 py-2 text-sm text-gray-400">
                          <a
                            className="flex items-center"
                            href={
                              language &&
                              language.toLowerCase().indexOf("es") === 0
                                ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
                                : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos."
                            }
                          >
                            <FontAwesomeIcon
                              // @ts-ignore
                              icon={faWhatsapp}
                              className="ml-0.5 mr-3 text-lg"
                            />{" "}
                            {t("chat-with-support", "Chat with support")}
                          </a>
                        </li>
                      </ul>
                    </button>
                    <ul>
                      <li className="px-2 py-2 text-left">
                        <LangToggle />
                      </li>
                    </ul>
                  </div>

                  <div className="border-t border-gray-200 px-2 py-3 pb-3">
                    <div className="max-w-8xl mx-auto px-2 sm:px-4">
                      <button onClick={handleCloseMenu} type="button">
                        <Link
                          to={buildRoute("account-profile")}
                          className="group block w-full flex-shrink-0"
                        >
                          <div className="flex items-center">
                            <div>{userAvatar(session)}</div>
                            <div className="ml-3">
                              <p className="text-left text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                {userToString(session)}
                              </p>
                              <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                {t("navigation:view-profile", "View profile")}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </button>
                    </div>
                  </div>
                  {version && (
                    <div className="absolute bottom-1 right-1">
                      <span
                        className={classNames(
                          "text-sm font-bold text-gray-500"
                        )}
                      >
                        {version}
                      </span>
                    </div>
                  )}
                </nav>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="md:hidden">
        <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
          <div>
            <img className="h-8 w-auto" src={logoImago} alt="Hilos" />
          </div>
          <div>
            <button
              type="button"
              className="inline-flex text-gray-700 h-12 w-12 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <AlertIconWithIndicator />
            </button>
            <button
              type="button"
              className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">
                {t("open-sidebar", "Open sidebar")}
              </span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <NotificationSlideOver
        show={showNotifications}
        onClose={() => setShowNotifications(false)}
      >
        <UserNotificationList onClose={() => setShowNotifications(false)} />
      </NotificationSlideOver>
    </>
  );
}

export default MobileMenu;
