import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { API_ROUTES } from "../router/router";
import { User } from "@hilos/types/private-schema";

export function useAccountMemberEmails() {
  const fetchEmails = useCallback(async () => {
    const { data } = await axios.get<User[]>(API_ROUTES.USER_LIST);
    return data.map((user) => user.email);
  }, []);

  const { data } = useQuery("user_emails", fetchEmails);

  return { emails: data || [] };
}
