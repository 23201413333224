import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { cloneDeep, get, set } from "lodash";
import {
  ContactDetailRead,
  PatchedContactEdit,
} from "@hilos/types/private-schema";
import {
  CONTACT_BASE_FIELDS,
  CONTACT_EDITABLE_FIELDS,
} from "src/helpers/utils";
import { classNames } from "src/Helpers";

interface ContactInfoItemProps {
  id: string;
  contact: ContactDetailRead;
  onUpdateContact: (data: Partial<PatchedContactEdit>) => void;
}

function getJSONValue(value: string) {
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
}

export function getContactFieldName(id: string) {
  return (
    {
      first_name: "First name",
      last_name: "Last name",
      email: "Email",
      source: "Source",
      external_url: "URL",
    }[id] ||
    id ||
    ""
  );
}

function getContactFieldValue(id: string, contact: ContactDetailRead) {
  if (CONTACT_BASE_FIELDS.includes(id)) {
    return get(contact, id);
  }
  return get(contact.meta, id);
}

const NON_EDITABLE_CONTACT_FIELDS: string[] = [];

function ContactInfoItem({
  id,
  contact,
  onUpdateContact,
}: ContactInfoItemProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isClickingOutsideRef = useRef(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const isEditable = useMemo(
    () => !NON_EDITABLE_CONTACT_FIELDS.includes(id),
    [id]
  );

  const [title, value, isJSONValue] = useMemo(() => {
    const nextTitle = getContactFieldName(id);
    let nextValue = getContactFieldValue(id, contact);
    let nextIsJSONValue = false;

    if (
      nextValue === undefined ||
      (!nextValue && CONTACT_EDITABLE_FIELDS.includes(id))
    ) {
      nextValue = "";
    }

    if (typeof nextValue !== "string") {
      nextValue = JSON.stringify(nextValue);
      nextIsJSONValue = true;
    }

    return [nextTitle, nextValue, nextIsJSONValue];
  }, [id, contact]);

  const handleSave = useCallback(() => {
    if (inputRef.current) {
      const valid = inputRef.current.checkValidity();

      if (valid) {
        setIsEditMode(false);
        if (CONTACT_EDITABLE_FIELDS.includes(id)) {
          onUpdateContact({ [id]: editedValue || "" });
        } else {
          const nextMeta = cloneDeep(contact.meta ?? {});
          set(
            nextMeta,
            id,
            isJSONValue ? getJSONValue(editedValue) : editedValue
          );
          onUpdateContact({ meta: nextMeta });
        }
      } else {
        inputRef.current.reportValidity();
      }
    }
  }, [id, editedValue, contact, isJSONValue, onUpdateContact]);

  const handleKeyUp = useCallback(
    (event) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        handleSave();
      }
    },
    [handleSave]
  );

  const handleCancel = useCallback(() => {
    if (!isClickingOutsideRef.current) {
      setIsEditMode(false);
      setEditedValue(value || "");

      if (inputRef.current) {
        inputRef.current.value = value || "";
      }
    }
    isClickingOutsideRef.current = false;
  }, [value]);

  useEffect(() => {
    if (isEditMode) {
      inputRef.current?.focus();
    }
  }, [isEditMode]);

  useEffect(() => {
    setEditedValue(value || "");
    if (inputRef.current) {
      inputRef.current.value = value || "";
    }
  }, [value]);

  return (
    <div className="inline-flex w-full flex-1 items-baseline px-3 py-0.5">
      <span
        title={title}
        className="w-20 truncate text-sm font-semibold text-gray-500"
      >
        {t(title)}
      </span>
      <div
        className="group flex w-full flex-1 pl-3 pr-2"
        onClick={(event) => {
          event.preventDefault();
          if (isEditable && !isEditMode) {
            setIsEditMode(true);
          }
        }}
      >
        <input
          ref={inputRef}
          type={
            id === "email" ? "email" : id === "external_url" ? "url" : "text"
          }
          defaultValue={value || ""}
          disabled={!isEditable || !isEditMode}
          onKeyUp={handleKeyUp}
          onBlur={handleCancel}
          onChange={(event) => setEditedValue(event.target.value)}
          className={classNames(
            "w-full border-0 border-b border-transparent bg-gray-100 p-0 pr-10 text-sm font-medium text-gray-900 outline-none focus:ring-0",
            isEditMode
              ? "focus:invalid:border-b-1.5 border-b-gray-400 invalid:border-b-pink-500 focus:border-b-indigo-500 focus:invalid:border-b-pink-600"
              : "truncate"
          )}
        />
        <div className="absolute right-5">
          <div className="flew-row align-center flex justify-center">
            {isEditMode ? (
              <>
                <button onClick={handleCancel}>
                  <XIcon className="h-4 w-4 text-gray-400 hover:text-red-500" />
                </button>
                <button
                  onClick={handleSave}
                  onMouseDown={() => {
                    isClickingOutsideRef.current = true;
                  }}
                >
                  <CheckIcon className="h-5 w-5 text-gray-400 hover:text-indigo-500" />
                </button>
              </>
            ) : (
              isEditable && (
                <button
                  className="hidden group-hover:block"
                  onClick={() => {
                    setIsEditMode(true);
                  }}
                >
                  <PencilIcon className="h-4 w-4 text-gray-700" />
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInfoItem;
