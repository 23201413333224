import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { FlowStepEdit } from "@hilos/types/private-schema";
import useFlow from "src/hooks/useFlow";
import { buildRoute } from "src/router/router";

interface FlowStepTriggerFlowDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepTriggerFlowDetail({
  step,
}: FlowStepTriggerFlowDetailProps) {
  const { t } = useTranslation();
  const { flow } = useFlow(step.next_flow);

  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t(
          "flow-executions:steps-detail.trigger-flow.flow-to-trigger",
          "Flow to trigger"
        )}
      </h3>

      <p className="text-blue-500 font-medium">
        <Link
          to={buildRoute("flow-detail", { ":id": step.next_flow })}
          target="_blank"
          className="flex items-center"
        >
          {flow?.name}
          <ExternalLinkIcon className="h-4 w-4 ml-1" />
        </Link>
      </p>
    </div>
  );
}
