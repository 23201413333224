import {
  CursorClickIcon,
  EmojiHappyIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import * as yup from "yup";
import { TimeWindowMeta, getStepsAsArray } from "src/containers/flow/Helpers";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const FlowStepMenuMeta = {
  getInitialValues: function (step) {
    return {
      name: step?.name || "",
      step_type: "MENU",
      answer_type: "SINGLE_OPTION",
      body: step?.body || "",
      body_type: "text",
      answer_options: [""],
      answer_options_render: "LIST",
      next_steps_for_options_idx: [""],
      answer_options_render_list_button_title: "",
      max_wait_time_amount: "24",
      max_wait_time_unit: "HOUR",
      set_time_window: false,
      time_windows: [],
      time_window_type: "CUSTOM",
      has_max_answer_attempts: false,
      max_answer_attempts: 0,
      answer_failed_next_step_idx: "",
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      answer_type: yup.string().required(ERROR_MESSAGES.required),
      answer_validation_message: yup.string().required(ERROR_MESSAGES.required),
      answer_options: yup.mixed().when("answer_options_render", {
        is: (val) => ["LIST", "BUTTONS"].includes(val),
        then: yup
          .array()
          .of(
            yup
              .string()
              .max(20, ERROR_MESSAGES.max)
              .required(ERROR_MESSAGES.required)
          ),
        otherwise: yup
          .array()
          .of(yup.string().min(1, ERROR_MESSAGES.minNumber)),
      }),
      next_step_alternate_idx: yup.string(),
      max_answer_attempts: yup.string().when("has_max_answer_attempts", {
        is: true,
        then: yup.number().required(ERROR_MESSAGES.required),
      }),
      answer_failed_next_step_idx: yup
        .string()
        .when("has_max_answer_attempts", {
          is: true,
          then: yup.string().required(ERROR_MESSAGES.required),
        }),
      next_steps_for_options_idx: yup.array().of(yup.string().nullable()),
      whatsapp_template: yup.string(),
      body: yup.string().when("whatsapp_template", {
        is: (val) => val,
        then: yup.string(),
        otherwise: yup.string().required(ERROR_MESSAGES.required),
      }),
      answer_options_render: yup.string().when("answer_type", {
        is: "SINGLE_OPTION",
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      answer_options_render_list_button_title: yup
        .string()
        .when("answer_options_render", {
          is: "LIST",
          then: yup
            .string()
            .max(20, ERROR_MESSAGES.max)
            .required(ERROR_MESSAGES.required),
        }),
      max_wait_time_amount: yup
        .number()
        .min(0, ERROR_MESSAGES.minNumberValue)
        .required(ERROR_MESSAGES.required),
      max_wait_time_unit: yup.string().required(ERROR_MESSAGES.required),
      set_time_window: yup.bool(),
      time_window_type: yup.string().when("set_time_window", {
        is: true,
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      time_windows: yup.mixed().when(["set_time_window", "time_window_type"], {
        is: (setTimeWindow, timeWindowType) =>
          setTimeWindow && timeWindowType === "CUSTOM",
        then: yup
          .array()
          .of(TimeWindowMeta.getSchema())
          .min(6, ERROR_MESSAGES.length_error),
      }),
    });
  },
  validate: function () {
    return {};
  },
  formatForSave: function (step) {
    return step;
  },
  ANSWER_OPTIONS_RENDER_TYPES: [
    {
      label: "Buttons",
      value: "BUTTONS",
      icon: <CursorClickIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Will show the options as buttons, max 3.",
      single_option_only: true,
      available_twilio: false,
    },
    {
      label: "List of options",
      value: "LIST",
      icon: <ViewListIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Will show the options as a dropdown list, max 10.",
      single_option_only: true,
      available_twilio: false,
    },
    {
      label: "Emojis",
      value: "EMOJIS",
      icon: <EmojiHappyIcon className="h-5 w-5" aria-hidden="true" />,
      help: "Will show the options as a list of 1️⃣  - option text.",
      single_option_only: false,
      available_twilio: true,
    },
    {
      label: "Numbers",
      value: "NUMBERS",
      icon: "1...10",
      help: "Will show the options as a list of number - option text.",
      single_option_only: false,
      available_twilio: true,
    },
  ],
  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    body: {
      key: "body",
      label: "Message body",
      help: "",
      placeholder:
        "Hey, thanks for contacting us. What do you need help with today?",
    },
    answer_validation_message: {
      key: "answer_validation_message",
      label: "Answer validation message",
      help: "If the recipient doesn't provide a value according to this question's answer type, we'll try again with this message.",
      placeholder: "Please specify a proper value.",
    },
    answer_options: {
      key: "answer_options",
      label: "Answer options",
      help: "If the recipient doesn't provide a value according to this question's answer type, we'll try again with this message.",
      placeholder: "Check order status.",
    },
    next_steps_for_options_idx: {
      key: "next_steps_for_options_idx",
    },
    answer_options_render: {
      key: "answer_options_render",
      label: "How would you like to show these options?",
    },
    answer_options_render_list_button_title: {
      key: "answer_options_render_list_button_title",
      label: "Open list button text",
      placeholder: "For example, Available Options",
    },
  },
};
