import { useState } from "react";
import { UpdateInboxContactFn } from "@hilos/hooks/useInboxContactDetails";
import {
  ConversationContent,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import ForwardMessageModal from "../inbox/Conversation/ForwardMessageModal";
import WorkflowBoardTaskContent from "./WorkflowBoardTaskContent";
import WorkflowBoardTaskFooter from "./WorkflowBoardTaskFooter";

export default function WorkflowBoardTaskConversation({ taskId, workflowId }) {
  const { task, isLoading: isLoadingTask } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });
  const [selectedContextContent, setSelectedContextContent] =
    useState<ConversationContent | null>(null);
  const [selectedForwardMessage, setSelectedForwardMessage] =
    useState<WhatsAppMessage | null>(null);

  // @ts-ignore
  const handleUpdateInboxContact: UpdateInboxContactFn = () => {};

  if (isLoadingTask) {
    return <Loading />;
  }

  if (!task) {
    return null;
  }

  return (
    <div className="flex flex-col w-full h-full">
      <WorkflowBoardTaskContent
        inboxContact={task.inbox_contact}
        onSelectContextContent={setSelectedContextContent}
        onSelectForwardMessage={setSelectedForwardMessage}
      />
      <WorkflowBoardTaskFooter
        inboxContact={task.inbox_contact}
        context={selectedContextContent}
        onUpdateInboxContact={handleUpdateInboxContact}
        onSelectContextContent={setSelectedContextContent}
        onFocusConversationContent={() => {}}
        onDisableMessageMediaUpload={() => {}}
      />
      {selectedForwardMessage && (
        <ForwardMessageModal
          message={selectedForwardMessage}
          onSelectForwardMessage={setSelectedForwardMessage}
        />
      )}
    </div>
  );
}
