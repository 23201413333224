const InboxNote = ({ note }: { note: string }) => {
  return (
    <>
      <div className="flex flex-col mb-2">
        <div className="flex flex-row justify-end">
          <div className="w-content bg-yellow-100 border-2 border-dashed border-yellow-300 bg-opacity-60 p-5 rounded-lg flex flex-row justify-end align-baseline text-right">
            <div>
              <div className="text-gray-900 max-w-md itallic break-words">
                {note}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InboxNote;
