import { ShareIcon, UserCircleIcon, XIcon } from "@heroicons/react/outline";
import { useCallback, useMemo, useState } from "react";

import Select from "react-select";
import { getContactVariables } from "../../../helpers/utils";
import { useCustomFields } from "src/hooks/useContactCustomField";
import { useTranslation } from "react-i18next";

interface AddVariableFormProps {
  onAddVariable: (value: number) => void;
}

const selectorStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (base) => ({
    ...base,
    borderColor: "#ddd",
    "&:hover": {
      borderColor: "#ddd",
    },
    boxShadow: "none",
    "*": {
      boxShadow: "none !important",
    },
  }),
  option: (base) => ({
    ...base,
    paddingLeft: "3rem",
  }),
};

const USER_VARIABLES = ["first_name", "last_name", "full_name", "email"];

function getOptionsByVariables(type: string, data: string[]) {
  return data.map((item) => ({
    label: `${type}.${item}`,
    value: `{{${type}.${item}}}`,
  }));
}

function AddVariableForm({ onAddVariable }: AddVariableFormProps) {
  const { t } = useTranslation();
  const [showVariableSelect, setShowVariableSelect] = useState(false);
  const { contactCustomFields } = useCustomFields();

  const variables = useMemo(() => {
    const contactVars = getContactVariables(contactCustomFields);
    const userOptions = getOptionsByVariables("user", USER_VARIABLES);
    const contactOptions = getOptionsByVariables("contact", contactVars);

    return [
      {
        // t("inbox:quick-replies.variables.user", "User Variables")
        label: t("inbox:quick-replies.variables.user"),
        icon: UserCircleIcon,
        options: userOptions,
      },
      {
        // t("inbox:quick-replies.variables.contact", "Contact Variables")
        label: t("inbox:quick-replies.variables.contact"),
        icon: UserCircleIcon,
        options: contactOptions,
      },
    ];
  }, [t, contactCustomFields]);

  const handleChangeVariable = useCallback(
    (option) => {
      onAddVariable(option.value);
      setShowVariableSelect(false);
    },
    [onAddVariable]
  );

  const formatGroupLabel = useCallback(
    (data) => (
      <div className="flex flex-nowrap py-1">
        <div className="rounded-full bg-hilos-light p-1">
          <data.icon
            aria-hidden="true"
            className={`h-4 w-4 text-hilos ${
              data.icon === ShareIcon ? "rotate-90" : ""
            }`}
          />
        </div>
        <div className="ml-2 self-center text-sm capitalize text-hilos">
          {data.label}
        </div>
      </div>
    ),
    []
  );

  if (!showVariableSelect) {
    return (
      <div className="my-2 flex w-full justify-end">
        <button
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          type="button"
          onClick={(_) => setShowVariableSelect(true)}
        >
          {t("inbox:quick-replies.variables.add", "Add Variable")}
        </button>
      </div>
    );
  }

  return (
    <div className="my-2 w-full">
      <p className="my-1 text-xs text-gray-500">
        You can insert variables from available contact fields.
      </p>

      <div className="flex items-center">
        <div className="grow">
          <Select
            menuPosition="fixed"
            menuPlacement="auto"
            menuPortalTarget={document.body}
            options={variables}
            formatGroupLabel={formatGroupLabel}
            onChange={handleChangeVariable}
            styles={selectorStyles}
          />
        </div>
        <div className="ml-2 grow-0">
          <button
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
            type="button"
            onClick={() => setShowVariableSelect(false)}
          >
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddVariableForm;
