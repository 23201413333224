import { useTranslation } from "react-i18next";
import {
  ExclamationCircleIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import {
  WhatsAppMessage,
  WhatsAppMessageWithoutContext,
} from "@hilos/types/private-schema";

interface UnsupportedMessageProps {
  message: WhatsAppMessage | WhatsAppMessageWithoutContext;
  isPreview?: boolean;
}

export default function UnsupportedMessage({
  message,
  isPreview,
}: UnsupportedMessageProps) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`flex items-center flex-col ${
          !isPreview
            ? "w-full justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm"
            : "text-gray-500"
        }`}
      >
        <div className="flex items-center">
          <ExclamationCircleIcon className="h-4 w-4 mr-1" />
          {t("unsupported-message", "Unsupported message")}: {message.msg_type}
        </div>
        <a
          className="break-all font-semibold underline flex items-center w-full justify-start"
          target="_blank"
          rel="noreferrer"
          href="https://hilos.io/docs/user/whatsapp-guides/unsupported-messages#unsupported-message-types"
        >
          <ExternalLinkIcon className="h-4 w-4 mr-1" />
          {t("learn-more", "Learn more")}
        </a>
      </div>
    </>
  );
}
