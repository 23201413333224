import { useCallback } from "react";
import { UseMutateAsyncFunction, useMutation, useQuery } from "react-query";
import axios from "axios";
import { HilosTask } from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES } from "src/router/router";

export type UpdateOpenAIDetailsFn = UseMutateAsyncFunction<
  HilosTask | null,
  unknown,
  HilosTask,
  unknown
>;

export function useHilosTask() {
  const fetchHilosTask = useCallback(async () => {
    const { data } = await axios.get<HilosTask[]>(API_ROUTES.HILOS_TASK_LIST);
    return data;
  }, []);

  const { data, refetch, isLoading } = useQuery("hilos_task", fetchHilosTask);

  return { data, refetch, isLoading };
}

export default useHilosTask;
