import { useCallback, useState } from "react";
import { Transition } from "@headlessui/react";
import { RefreshIcon } from "@heroicons/react/outline";
import { debounce } from "lodash";
import Loading from "src/components/Loading";
import MessageMenu from "./MessageMenu";

interface MessageImageProps {
  name?: string | null;
  source?: string | null;
  showMenu: boolean;
  isInbound: boolean;
}

function MessageImage({
  source,
  name,
  showMenu,
  isInbound,
}: MessageImageProps) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoad = useCallback(
    debounce(() => {
      setLoading(false);
    }, 600),
    []
  );

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  const handleRefresh = useCallback(() => {
    setError(false);
    setLoading(true);
  }, []);

  if (error) {
    return (
      <div
        className="flex w-full justify-center p-2 align-middle"
        onClick={handleRefresh}
      >
        <RefreshIcon className="h-8 w-8 cursor-pointer" />
      </div>
    );
  }

  return (
    <div className="group relative flex min-h-min w-60 flex-row overflow-hidden rounded-lg">
      <Transition
        show={loading}
        className={`align-center absolute z-30 flex h-full w-60 justify-center ${
          isInbound ? "bg-gray-200" : "bg-indigo-400"
        }`}
      >
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Loading color={isInbound ? "text-black" : "text-white"} />
        </Transition.Child>
      </Transition>
      {!loading && showMenu && source && <MessageMenu url={source} />}
      <img
        className="w-60 rounded-lg object-cover"
        src={source ?? ""}
        alt={name ?? ""}
        onLoad={handleLoad}
        onError={handleError}
      />
      <div className="h-20 w-0" />
    </div>
  );
}

export default MessageImage;
