import { useCallback, useEffect, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClipboardIcon } from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import QRCode from "qrcode";
import AnchorTracked from "src/components/Posthog/AnchorTracked";
import useHilosStore from "src/hooks/useHilosStore";
import { AccountStatus } from "src/types/account";

export default function ConnectToSandboxCard() {
  const { t } = useTranslation();
  const { session, reloadSession } = useHilosStore();
  const QRSandboxCode = localStorage.getItem("qr_sandbox_code");
  const urlWhatsAppCode = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SANDBOX_NUMBER}&text=${session?.phone_connect_code}`;
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = urlWhatsAppCode;

      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";

      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);

      setIsCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard", error);
    }
  };

  useEffect(() => {
    if (session?.account.status === AccountStatus.NEW || !QRSandboxCode) {
      generateQRCode();
    }
  }, [session?.account.status]);

  const generateQRCode = useCallback(() => {
    QRCode.toDataURL(
      urlWhatsAppCode,
      {
        width: 170,
        margin: 2,
        color: {
          dark: "#486581",
          light: "#ffffff",
        },
      },
      (err, url) => {
        if (err) return captureException(err);
        localStorage.setItem("qr_sandbox_code", url);
      }
    );
  }, [urlWhatsAppCode]);

  useEffect(() => {
    const interval = setInterval(() => {
      reloadSession();
    }, 1000 * 3);
    return () => clearInterval(interval);
  }, [reloadSession]);

  return (
    <div className="mx-auto mt-10 block max-w-[500px]">
      {isBrowser && (
        <div className="py-6 text-center grid grid-cols-1 md:grid-cols-5 justify-center align-middle">
          <div className="md:col-span-2">
            <h3 className="text-sm font-bold text-center text-gray-500 justify-self-center">
              {t(
                "settings:onboarding.open-on-your-computer",
                "Open on your computer"
              )}
            </h3>
          </div>
          <div className="md:col-span-1"></div>
          <div className="md:col-span-2">
            <h3 className="text-sm font-bold text-gray-500">
              {t(
                "settings:onboarding.open-on-your-phone",
                "Open on your phone"
              )}
            </h3>
          </div>
          <div className="col-span-2 flex flex-col items-center justify-center h-full">
            <a
              href={urlWhatsAppCode}
              rel="noreferrer"
              target="_blank"
              className="spread-shadow-gree h-fit mb-4 inline-block rounded-lg bg-green-600 py-3 px-4 text-xs font-semibold text-white justify-self-center"
            >
              <FontAwesomeIcon
                icon={faWhatsapp as IconProp}
                aria-hidden="true"
                className="mr-2"
              />
              {t("send-whatsapp", "Send WhatsApp")}
            </a>
            <div className="w-full flex justify-center mt-4">
              <button
                onClick={handleCopyClick}
                disabled={isCopied}
                className=" border-dashed border-gray-500 border rounded-md px-2 py-1 flex text-xs text-gray-500 font-semibold"
              >
                <ClipboardIcon className="w-4 h-4 mr-2" />
                {isCopied
                  ? t("dashboard:sandbox.copied-link", "Link copied!")
                  : t("dashboard:sandbox.copy-link", "Copy link")}
              </button>
            </div>
          </div>
          <div className="col-span-1 text-gray-400 font-bold self-center">
            {t("or", "or")}
          </div>
          <div className="col-span-2 self-start">
            {QRSandboxCode ? (
              <img
                className="mx-auto rounded-lg p-3"
                src={QRSandboxCode}
                alt="qr-code"
              />
            ) : (
              <div className="m-2 flex h-[170px] min-h-[170px] min-w-[170px] animate-pulse flex-col items-center rounded-lg p-3 text-center">
                {t("loading")}
              </div>
            )}
          </div>
        </div>
      )}
      {isMobile && (
        <div className="py-6 text-center grid grid-cols-5 justify-center items-center">
          <div className="col-span-5 self-start">
            <h3 className="text-sm text-gray-500">
              {t("settings:onboarding.tap-to-open", "Tap on the button below")}
            </h3>
            <AnchorTracked
              href={urlWhatsAppCode}
              rel="noreferrer"
              target="_blank"
              className="spread-shadow-green mx-6 mt-4 mb-4 inline-block rounded-lg bg-green-600 py-3 px-4 text-xs font-semibold text-white self-center"
              posthogEvent="sandbox send-whatspp clicked"
            >
              <FontAwesomeIcon
                icon={faWhatsapp as IconProp}
                aria-hidden="true"
                className="mr-2"
              />
              {t("send-whatsapp", "Send WhatsApp")}
            </AnchorTracked>
          </div>
        </div>
      )}
    </div>
  );
}
