import { MouseEventHandler, ReactElement, RefCallback } from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "@headlessui/react";
import { Portal } from "src/hooks/usePopper";
import { classNames } from "src/Helpers";

export interface MenuOption {
  id: string;
  label: string;
  icon?: ReactElement;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

interface MenuDropdownOptionsProps {
  options: MenuOption[];
  container: RefCallback<HTMLElement | null>;
}

function MenuDropdownOptions({ options, container }: MenuDropdownOptionsProps) {
  const { t } = useTranslation();

  return (
    <Portal>
      <Menu.Items
        className="right-0 z-10 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        ref={container}
      >
        <div className="py-0">
          {options.map(({ id, label, disabled, icon = null, onClick }) => (
            <Menu.Item key={id}>
              {({ active }) => (
                <button
                  type="button"
                  disabled={disabled}
                  className={classNames(
                    "flex w-full flex-row px-4 py-2 text-left text-sm hover:rounded-md",
                    disabled
                      ? "text-gray-400"
                      : active
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-700"
                  )}
                  onClick={onClick}
                >
                  {icon}
                  {t(label, label)}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Portal>
  );
}

export default MenuDropdownOptions;
