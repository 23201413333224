import { useTranslation } from "react-i18next";
import { CalendarIcon, CheckIcon, XIcon } from "@heroicons/react/outline";
import DatePickerField from "src/components/Form/DatePickerField";
import RadioButtonsField from "src/components/Form/RadioButtonsField";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import { WEEKDAY_OPTIONS } from "../../../constants/steps/conditional";

interface FlowBuilderStepConditionalFieldProps {
  name: string;
  field: string;
  comparison: string;
}

function FlowBuilderStepConditionalField({
  name,
  field,
  comparison,
}: FlowBuilderStepConditionalFieldProps) {
  const { t } = useTranslation();
  if (comparison === "isnull" || field === "global.IS_IN_WORKING_HOURS") {
    return (
      <RadioButtonsField
        name={name}
        options={[
          {
            value: "true",
            label: t("yes", "Yes"),
            disabled: false,
            icon: CheckIcon,
          },
          {
            value: "false",
            label: t("no", "No"),
            disabled: false,
            icon: XIcon,
          },
        ]}
      />
    );
  }

  if (field === "global.TIME") {
    return <TextInputField name={name} type="time" />;
  }

  if (field === "global.DAY") {
    return <TextInputField name={name} type="number" />;
  }

  if (field === "global.DATE") {
    return (
      <DatePickerField
        // @ts-ignore
        // type="text"
        name={name}
        dateFormat="dd/MM/yyyy"
        minDate={new Date()}
        icon={CalendarIcon}
        containerClassName="relative rounded-md shadow-sm"
      />
    );
  }

  if (field === "global.WEEKDAY") {
    return (
      <SelectorField
        name={name}
        options={WEEKDAY_OPTIONS}
        // @ts-ignore
        getOptionLabel={(option) => t(option.label)}
      />
    );
  }

  return (
    <TextInputField
      name={name}
      type="text"
      containerClassName="relative rounded-md shadow-sm"
    />
  );
}

export default FlowBuilderStepConditionalField;
