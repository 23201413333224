import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/outline";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import { API_ROUTES } from "src/router/router";

export default function SubscriptionV2Resume() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const timeout = useCleanTimeout();

  const onSubmit = async (ev) => {
    ev.preventDefault();
    setBackendValidationErrors({});
    setBackendError("");
    setIsSubmitting(true);

    try {
      await axios.post(API_ROUTES.STRIPE_SUBSCRIPTION_RESUME);
      setIsSubmitting(false);
      setSuccess(true);
      window.location.reload();
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:billing.cancel.error",
            "There was an error while canceling your subscription, please try again later."
          )
        );
      }
      timeout.current = setTimeout(() => {
        setSubmitted(false);
        setSuccess(false);
      }, 1000 * 2);
    } finally {
      setSubmitted(true);
      setIsSubmitting(false);
    }
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <div className="space-y-4">
        {backendError ||
          (backendValidationErrors && (
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={{}}
            />
          ))}

        <StateButton
          isSubmitting={isSubmitting}
          submitted={submitted}
          success={success}
          submittingText="Processing..."
          successText="Success!"
          btnClasses="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-green-600 shadow-sm ring-inset ring-green-600 ring-1 hover:bg-green-50 sm:mt-0 sm:w-auto"
          initialText={
            <>
              <CheckCircleIcon className="h-5 w-5 mr-1" />
              {t("settings:billing.resume-plan", "Resume subscription")}
            </>
          }
        />
      </div>
    </form>
  );
}
