import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import { ConversationReadSimple } from "@hilos/types/private-schema";
import useAccount from "src/hooks/useAccount";
import { useTeams } from "src/hooks/useTeam";
import { userToString } from "../../../../Helpers";
import SelectorField from "../../../../components/Form/SelectorField";
import { UpdateConversationFn } from "../../../../hooks/useInboxContactDetails";

interface AssignmentFormProps {
  conversation: ConversationReadSimple;
  onClose: () => void;
  onUpdateConversation: UpdateConversationFn;
}

const schema = yup.object().shape({
  user: yup.number(),
  team: yup.number(),
});

const initialValues = {
  user: "",
  team: "",
};

function AssignmentForm({
  conversation,
  onClose,
  onUpdateConversation,
}: AssignmentFormProps) {
  const { t } = useTranslation();
  const { members } = useAccount();
  const teams = useTeams();

  const onSubmit = useCallback(
    async (values, setSubmitting) => {
      const nextValues = {};
      if (values.user) {
        const nextAssigned = [
          ...conversation.assigned.map((user) => ({ id: user.id })),
          { id: values.user },
        ].filter((user, index, assigned) => {
          return assigned.indexOf(user) === index;
        });
        nextValues["assigned"] = nextAssigned;
      }
      if (values.team) {
        nextValues["assigned_team"] = values.team;
      }
      onUpdateConversation(nextValues);
      setSubmitting(false);
      onClose();
    },
    [conversation, onUpdateConversation, onClose]
  );

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      initialValues={initialValues}
      enableReinitialize={true}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="space-y-2 px-3 py-2">
            <SelectorField
              name="user"
              label={t("inbox:assign-to-users", "Assign to one or more users")}
              isMulti={false}
              isCreatable={false}
              options={members.map((user) => ({
                label: userToString(user),
                value: user.id,
              }))}
              placeholder={t("inbox:search-users", "Type to search users")}
            />

            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-gray-100 px-2 text-sm text-gray-500">
                  {t("or", "or")}
                </span>
              </div>
            </div>

            <SelectorField
              name="team"
              label={t("inbox:assign-to-team", "Assign to a team")}
              isMulti={false}
              isCreatable={false}
              options={teams.map((team) => ({
                label: team.name,
                value: team.id,
              }))}
              placeholder={t("inbox:search-teams", "Type to search teams")}
            />
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {formik.isSubmitting ? (
                <>
                  <svg
                    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {t("saving", "Saving...")}
                </>
              ) : (
                <div className="inline-flex items-center">
                  {t("save", "Save")}
                  <ArrowSmRightIcon
                    className="ml-1 -mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default AssignmentForm;
