import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import { DelayMeta } from "src/containers/flow/Helpers";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import { useStepField } from "../../../hooks/useStepField";
import FlowBuilderTimeWindows from "../../FlowBuilderTimeWindows";

interface FlowBuilderStepDelayProps {
  index: number;
}

function FlowBuilderStepDelay({ index }: FlowBuilderStepDelayProps) {
  const [t] = useTranslation();
  const [maxWaitTimeUnit] = useStepField({
    index,
    name: "max_wait_time_unit",
  });
  const [maxWaitTimeAmount] = useStepField({
    index,
    name: "max_wait_time_amount",
  });

  const showNotice = useMemo(() => {
    switch (maxWaitTimeUnit) {
      case "SECOND":
        return maxWaitTimeAmount >= 60 * 60 * 24;
      case "MINUTE":
        return maxWaitTimeAmount >= 60 * 24;
      case "HOUR":
        return maxWaitTimeAmount >= 24;
      case "DAY":
        return maxWaitTimeAmount >= 1;
    }
    return false;
  }, [maxWaitTimeUnit, maxWaitTimeAmount]);

  return (
    <div className="space-y-4">
      <div>
        <p className="mb-1 block text-sm font-medium text-gray-700">
          {t("flows:steps.delay.wait-time.label", "Wait for")}
        </p>
        <div className="grid grid-cols-3 items-center">
          <div className="col-span-2 w-full">
            <TextInputField
              name={`steps.${index}.max_wait_time_amount`}
              placeholder="24"
              type="number"
            />
          </div>
          <div className="ml-2 w-full">
            <SelectorField
              name={`steps.${index}.max_wait_time_unit`}
              options={DelayMeta.MAX_WAIT_TIME_OPTIONS.map((option) => ({
                label: t(option.label),
                value: option.value,
              }))}
            />
          </div>
        </div>
      </div>

      <FlowBuilderTimeWindows index={index} />

      {showNotice && (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <div className="text-sm text-yellow-700">
                <p>
                  {t(
                    "flows:steps.delay.notice",
                    "Since you're waiting more than 24 hours, use a TEMPLATE step to continue the conversation."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FlowBuilderStepDelay;
