import { useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";

interface FlowStepConditionalDetailProps {
  step: FlowStepEdit;
}

export default function FlowStepConditionalDetail({
  step,
}: FlowStepConditionalDetailProps) {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("flow-executions:steps-detail.conditional.conditions", "Conditions")}
      </h3>

      {step.conditions.map((condition, idx) => (
        <div key={idx} className="text-gray-900 space-x-1">
          {"{{"}
          <span className="text-gray-700">{condition.field}</span>
          {"}}"}
          <span className="text-gray-900 font-medium">
            {condition.comparison}
          </span>
          <span className="text-gray-700">{condition.value}</span>
          {idx < step.conditions.length - 1 && (
            <span className="text-gray-500">AND</span>
          )}
        </div>
      ))}
    </div>
  );
}
