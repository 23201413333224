import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import SelectorField from "src/components/Form/SelectorField";
import {
  MESSAGE_BODY_TYPES,
  MessageBodyTypes,
} from "../../../constants/steps/message";
import { useStepField } from "../../../hooks/useStepField";
import FlowBuilderMsgFail from "../../FlowBuilderMsgFail";
import FormatOptionLabel from "../../FormatOptionLabel";
import FlowBuilderStepMessageBody from "./FlowBuilderStepMessageBody";

interface FlowBuilderStepMessageProps {
  id: string;
  index: number;
}

function FlowBuilderStepMessage({ id, index }: FlowBuilderStepMessageProps) {
  const [t] = useTranslation();
  const [, setBody] = useStepField({
    index,
    name: "body",
  });
  const [bodyType] = useStepField({
    index,
    name: "body_type",
  });
  const [, setBodyFile] = useStepField({
    index,
    name: "body_file",
  });
  const [, setBodyFileURL] = useStepField({
    index,
    name: "body_file_url",
  });
  const [bodyFileType, setBodyFileType] = useStepField({
    index,
    name: "body_file_type",
  });

  const messageBodyOptions = Object.entries(MESSAGE_BODY_TYPES).map(
    ([type, step]) => ({
      icon: step.icon,
      label: t(step.label),
      help: t(step.help),
      value: type as MessageBodyTypes,
    })
  );

  const handleBodyTypeChange = useCallback(
    (selectedStepBodyType) => {
      setBody("", false);
      setBodyFile("", false);
      setBodyFileURL("", false);
      setBodyFileType(
        selectedStepBodyType.value !== "text" ? "URL" : "",
        false
      );
    },
    [setBody, setBodyFile, setBodyFileURL, setBodyFileType]
  );

  return (
    <div className="space-y-2">
      <SelectorField
        name={`steps.${index}.body_type`}
        label={t("flows:steps.message.body-type.label", "Message type")}
        options={messageBodyOptions}
        formatOptionLabel={FormatOptionLabel}
        isOptionDisabled={(option) => option.soon}
        onSelect={handleBodyTypeChange}
      />
      {bodyType && (
        <FlowBuilderStepMessageBody
          index={index}
          bodyType={bodyType}
          bodyFileType={bodyFileType}
          setBodyFileType={setBodyFileType}
        />
      )}

      <FlowBuilderMsgFail id={id} index={index} />
    </div>
  );
}

export default FlowBuilderStepMessage;
