import { useTranslation } from "react-i18next";
import { ChatIcon } from "@heroicons/react/outline";
import HeaderNavigation from "src/components/HeaderNavigation";
import useHilosStore from "src/hooks/useHilosStore";
import { buildRoute } from "src/router/router";
import Dialog360Form from "./Dialog360Form";

export default function Channel360DialogConnect() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: "Home",
                url: buildRoute("dashboard"),
              },
              {
                name: "Settings",
                url: buildRoute("config-account"),
              },
              {
                name: "WhatsApp Accounts",
                url: buildRoute("config-channel"),
              },
              {
                name: "360Dialog",
                url: buildRoute("config-channel-360dialog-create"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ChatIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                {t(
                  "channel:360-dialog.connect-title",
                  "Migrate an existing WhatsApp API number"
                )}
              </h2>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto mt-6 max-w-3xl">
            {session && session.account && <Dialog360Form />}
          </div>
        </div>
      </div>
    </>
  );
}
