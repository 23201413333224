import {
  ChevronDown,
  ChevronUp,
  type LucideIcon,
  MoreVertical,
  X,
} from "lucide-react";
import { Button, ButtonProps } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Separator } from "src/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { contactToString, formatPhone } from "src/Helpers";

interface WorkflowBoardTaskProps {
  taskId: string;
  workflowId: string;
  onClose: () => void;
  onSwitchShowDetails: () => void;
}

interface ActionButtonProps extends ButtonProps {
  title: string;
  icon: LucideIcon;
}

function ActionButton({
  title,
  icon: RenderIcon,
  ...props
}: ActionButtonProps) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="icon" {...props}>
          <RenderIcon className="h-4 w-4" />
          <span className="sr-only">{title}</span>
        </Button>
      </TooltipTrigger>
      <TooltipContent>{title}</TooltipContent>
    </Tooltip>
  );
}

export default function WorkflowBoardTaskHeader({
  taskId,
  workflowId,
  onClose,
  onSwitchShowDetails,
}: WorkflowBoardTaskProps) {
  const { task } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  if (!task) {
    return null;
  }

  return (
    <div className="flex items-center p-2">
      <div className="flex items-center gap-2">
        <Button variant="ghost" size="sm" onClick={onSwitchShowDetails}>
          <div className="grid flex-1 text-left text-md leading-tight">
            <span className="truncate font-semibold">
              {contactToString(task.inbox_contact.contact)}
            </span>
            <span className="truncate text-xs">
              {formatPhone(task.inbox_contact.contact.phone, true, true)}
            </span>
          </div>
        </Button>
      </div>
      {/* <SelectStatus
        currentStateId={task.state}
        onUpdateWorkflowTask={handleUpdateWorkflowTask}
      />
      <Separator orientation="vertical" className="mx-2 h-6" /> */}
      <div className="ml-auto flex items-center gap-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreVertical className="h-4 w-4" />
              <span className="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Mark as unread</DropdownMenuItem>
            <DropdownMenuItem>Mute</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Separator orientation="vertical" className="mx-2 h-6" />
      <ActionButton
        title="Previous"
        icon={ChevronUp}
        // onClick={onPreviousTask}
      />
      <ActionButton
        title="Next"
        icon={ChevronDown}
        // onClick={onNextTask}
      />
      <ActionButton title="Close" icon={X} onClick={onClose} />
    </div>
  );
}
