import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../../../constants/Form";

export const FlowStepTeamConversationMeta = {
  getInitialValues: function (step) {
    return {
      name: step?.name || "",
      step_type: "TEAM",
      assign_to_teams: [],
      contact_tags: [],
      conversation_tags: [],
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      assign_to_teams: yup
        .array()
        .of(yup.number().required(ERROR_MESSAGES.required))
        .min(1, ERROR_MESSAGES.minNumber),
      contact_tags: yup.array().of(yup.string()),
      conversation_tags: yup.array().of(yup.string()),
    });
  },
  validate: function (formValues, step) {
    // Check that if flow is outbound, this step does not happen before a template step
    return {};
  },
  formatForSave: function (step) {
    return step;
  },
  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    assign_to_teams: {
      key: "assign_to_teams",
      label: "Team(s) to assign this conversation to",
      help: "We'll auto assign a user from that team according to the team's assignment settings.",
      placeholder: "Unassigned",
    },
    append_tags: {
      key: "append_tags",
      label: "Append tags?",
      help: "If set these tags will be appended instead of replacing existing ones.",
    },
    contact_tags: {
      key: "contact_tags",
      label: "Contact tags",
      help: "Tags to add to the contact",
    },
    conversation_tags: {
      key: "conversation_tags",
      label: "Conversation tags",
      help: "Tags to add to the conversation",
    },
  },
};
