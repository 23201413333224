import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import PermissionsChecker from "src/components/PermissionsCheck";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import logo from "src/assets/img/logo512.png";
import HeaderNavigation from "../../components/HeaderNavigation";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";
import AccountSubscriptionBillingEmailNotice from "./AccountSubscriptionBillingEmailNotice";
import AccountSubscriptionCancelledNotice from "./AccountSubscriptionCancelledNotice";
import AccountSubscriptionIncompleteNotice from "./AccountSubscriptionIncompleteNotice";
import AccountSubscriptionMXCFDINotice from "./AccountSubscriptionMXCFDINotice";
import AccountSubscriptionUnpaidNotice from "./AccountSubscriptionUnpaidNotice";
import AccountSubscriptionSelectPlan from "./billing/SubscriptionSelectPlan";
import SubscriptionStatusBadge from "./billing/SubscriptionStatusBadge";
import SubscriptionV1ActivePlan from "./billing/SubscriptionV1ActivePlan";
import SubscriptionV2ActivePlan from "./billing/SubscriptionV2ActivePlan";

export default function AccountSubscription() {
  const { t } = useTranslation();
  const { session, reloadSession } = useHilosStore();
  const timeout = useCleanTimeout();
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    if (
      searchParams.get("session_id") &&
      !session?.account.current_subscription
    ) {
      timeout.current = setTimeout(() => {
        reloadSession();
      }, 1000 * 3);
    }
  }, [
    session?.account.current_subscription,
    timeout,
    reloadSession,
    searchParams,
  ]);

  if (!session) {
    return null;
  }

  return (
    <div className="h-screen overflow-y-auto bg-gray-100">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings"),
              url: buildRoute("config-account"),
            },
            {
              name: t("settings:subscription.label", "Hilos Subscription"),
              url: buildRoute("config-account-subscription"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center space-x-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <img
                src={logo}
                className="mr-2 h-7 w-7"
                aria-hidden="true"
                alt="logo"
              />
              {t("settings:subscription.label", "Your Hilos Subscription")}
              {session.account.current_subscription?.status && (
                <SubscriptionStatusBadge
                  status={session.account.current_subscription.status}
                />
              )}
            </h2>
          </div>
        </div>
      </div>

      <AccountSubscriptionBillingEmailNotice />
      <PermissionsChecker
        permission="change_stripesubscription"
        action={t(
          "settings:permissions.change_stripesubscription",
          "change your subscription"
        )}
      >
        {session.account.current_subscription ? (
          <div className="mx-auto max-w-7xl">
            {["past_due", "unpaid"].includes(
              session.account.current_subscription.status
            ) && <AccountSubscriptionUnpaidNotice />}

            {["incomplete", "incomplete_expired"].includes(
              session.account.current_subscription.status
            ) && <AccountSubscriptionIncompleteNotice />}

            {session.account.current_subscription.status === "canceled" && (
              <AccountSubscriptionCancelledNotice />
            )}

            {["past_due", "unpaid", "active"].includes(
              session.account.current_subscription.status
            ) ? (
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-6">
                <div className="space-y-8">
                  {session.account.pricing_model_version === "v1" && (
                    <SubscriptionV1ActivePlan />
                  )}
                  {session.account.pricing_model_version === "v2" && (
                    <SubscriptionV2ActivePlan />
                  )}
                </div>
              </div>
            ) : (
              <AccountSubscriptionSelectPlan
                currency={session.account.payment_currency}
              />
            )}
          </div>
        ) : (
          <>
            {session.account.country === "MX" && (
              <AccountSubscriptionMXCFDINotice />
            )}
            <div className="mx-auto max-w-7xl sm:my-4">
              <AccountSubscriptionSelectPlan
                currency={session.account.payment_currency}
              />
            </div>
          </>
        )}
      </PermissionsChecker>
    </div>
  );
}
