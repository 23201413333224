import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/outline";
import HeaderNavigation from "src/components/HeaderNavigation";
import Loading from "src/components/Loading";
import useVesselExternalApp, {
  useVesselAppUpdateDetails,
} from "src/hooks/useVesselExternalApp";
import { buildRoute } from "src/router/router";
import VesselInstall from "./VesselInstall";
import VesselSettings from "./VesselSettings";
import VesselSync from "./VesselSync";

export default function Vessel() {
  const params = useParams();
  const integrationId = params.integrationId as string;
  const externalAppId = params.externalAppId;

  const { data: externalApp, isLoading } = useVesselExternalApp(externalAppId);

  const connected = useMemo(
    () => !!(externalAppId && externalApp),
    [externalApp, externalAppId]
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(isLoading);
    if (!connected) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { t } = useTranslation();
  const { handleUpdateVesselApp } = useVesselAppUpdateDetails(externalAppId);

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t(
                "integrations:external-apps.header-navigation-title",
                "External apps"
              ),
              url: buildRoute("external-apps"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              {connected ? (
                <>
                  <img
                    className="h-12 mr-2"
                    src={`/images/integrations/${integrationId}.avif`}
                    alt={`${integrationId} Icon`}
                  />
                  <span className="capitalize">
                    {t(`integrations:${integrationId}.title`, integrationId)}
                  </span>
                </>
              ) : (
                <>
                  <PlusIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                  <span className="capitalize">
                    {t(
                      `integrations:${integrationId}.connect`,
                      `Connect to ${integrationId}`
                    )}
                  </span>
                </>
              )}
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              <Trans i18nKey="integrations:error-logs">
                Any errors we find while syncing data will appear on the{" "}
                <Link
                  to={buildRoute("dev-monitoring")}
                  className="text-blue-600 hover:text-blue-400"
                >
                  Monitoring {">"} Event Logs page
                </Link>
                .
              </Trans>
            </p>
          </div>
        </div>
      </div>

      <div className="mx-auto mt-6 max-w-7xl px-4 sm:px-6 lg:px-8">
        <div>
          {loading ? (
            <Loading />
          ) : (
            <>
              {externalApp && connected ? (
                <>
                  {externalApp.contact_initial_sync_status !== "SYNCED" && (
                    <VesselSync
                      connectedApp={externalApp}
                      setLoading={setLoading}
                      disabled={false}
                    />
                  )}
                  <VesselSettings
                    connectedApp={externalApp}
                    onUpdateVesselDetails={handleUpdateVesselApp}
                    setLoading={setLoading}
                    disabled={false}
                  />
                </>
              ) : (
                <VesselInstall integrationId={integrationId} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
