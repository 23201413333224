import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import SwitchField from "src/components/Form/SwitchField";
import useHilosStore from "src/hooks/useHilosStore";
import SelectorField from "../../components/Form/SelectorField";
import TextInputField from "../../components/Form/TextInputField";
import { getComponentIdx } from "../../helpers/template";
import * as meta from "./WhatsAppTemplateMeta";

interface WhatsAppTemplateFormStepAttrsProps {
  formik: FormikProps<any>;
  setCurrentStep: (step: string) => void;
}

export default function WhatsAppTemplateFormStepAttrs({
  formik,
  setCurrentStep,
}: WhatsAppTemplateFormStepAttrsProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();
  const formatOptionLabel = ({
    value,
    label,
    icon,
    help,
  }: {
    value: string;
    label: string;
    icon: JSX.Element;
    help: string;
  }): JSX.Element => {
    return (
      <div className="flex items-center py-3">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{t(label, label)}</h4>
          {help && <p className="mb-0 text-xs">{t(help, help)}</p>}
        </div>
      </div>
    );
  };

  function onChangeTagInput(e) {
    const finalVal: string = e.target.value
      .toLowerCase()
      .replace(/[^a-z\d\s_]/gi, "")
      .replaceAll(" ", "_");
    formik.setFieldValue(meta.FIELDS.name.key, finalVal);
  }

  const AVAILABLE_CATEGORIES = Object.getOwnPropertyNames(meta.CATEGORIES)
    .filter((key) => meta.CATEGORIES[key].available)
    .map((key) => ({
      value: key,
      label: t(meta.CATEGORIES[key].label),
      help: t(meta.CATEGORIES[key].help),
      icon: meta.CATEGORIES[key].icon,
    }));

  const AVAILABLE_LANGUAGES = Object.getOwnPropertyNames(
    meta.SUPPORTED_LANGUAGES
  ).map((key) => ({
    value: key,
    label: meta.SUPPORTED_LANGUAGES[key],
  }));

  const onCategoryChange = (category: string) => {
    formik.setFieldValue(meta.FIELDS.category.key, category);
    if (category === "AUTHENTICATION") {
      formik.setFieldValue(`components.${getComponentIdx("BUTTONS", formik)}`, {
        type: "BUTTONS",
        buttons: [
          {
            type: "OTP",
            otp_type: "COPY_CODE",
            text: "Copy code",
          },
        ],
      });
    } else {
      formik.setFieldValue(
        `components.${getComponentIdx("BUTTONS", formik)}`,
        meta.initialButtonsValues
      );
    }
  };

  return (
    <div className="mx-auto max-w-3xl  space-y-14">
      <div className="row mt-10 text-center">
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          {t("step-n-of-n", "Step {{step}} of {{total}}", {
            step: 1,
            total: 3,
          })}
        </h6>
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          {t(
            "templates:start-with-basics.title",
            "Let's start with the basics"
          )}
        </h1>
        <p className="mb-5 text-sm text-gray-500">
          {t(
            "templates:start-with-basics.description",
            "Give this template a name, choose its category and its language."
          )}
        </p>
      </div>

      <div className="space-y-4">
        <TextInputField
          placeholder={t(meta.FIELDS.name.placeholder)}
          label={t(meta.FIELDS.name.label)}
          name={meta.FIELDS.name.key}
          help={t(meta.FIELDS.name.help)}
          type="text"
          maxLength={512}
          onChange={(e) => onChangeTagInput(e)}
        />

        {session?.account.has_multiple_active_channels && (
          <ChannelSelectField
            name={meta.FIELDS.channel.key}
            label={meta.FIELDS.channel.label}
            help={meta.FIELDS.channel.help}
          />
        )}

        <SelectorField
          label={t(meta.FIELDS.category.label, meta.FIELDS.category.label)}
          renderHelp={meta.FIELDS.category.help}
          name={meta.FIELDS.category.key}
          options={AVAILABLE_CATEGORIES}
          // @ts-ignore
          formatOptionLabel={formatOptionLabel}
          onSelect={(option: meta.Category) => onCategoryChange(option.value)}
        />

        <SwitchField
          label={t(meta.FIELDS.allow_category_change.label)}
          help={t(meta.FIELDS.allow_category_change.help)}
          name={meta.FIELDS.allow_category_change.key}
        />

        {formik.values[meta.FIELDS.category.key] !== "AUTHENTICATION" &&
          !formik.values[meta.FIELDS.allow_category_change.key] && (
            <div className="bg-blue-50 p-4 rounded-md flex space-x-2 items-center">
              <InformationCircleIcon
                className="h-6 w-6 text-blue-400"
                aria-hidden="true"
              />
              <div className="text-sm text-blue-700">
                <Trans i18nKey="templates:recategorization">
                  <p className="font-bold">
                    Your template category might change
                  </p>
                  <p className="text-sm">
                    When you submit your template for approval, WhatsApp might
                    recategorize it based on the content of your message, if it
                    is not the category you were expecting, try changing the
                    wording and submitting a new template.
                  </p>
                </Trans>
              </div>
            </div>
          )}

        <SelectorField
          label={t(meta.FIELDS.language.label)}
          name={meta.FIELDS.language.key}
          // @ts-ignore
          options={AVAILABLE_LANGUAGES}
          onSelect={(option) =>
            formik.setFieldValue(meta.FIELDS.language.key, option.value)
          }
        />
      </div>

      <div className="my-14">
        <hr className="my-5" />
        <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
          <div className=""></div>
          <div className="col text-center">
            <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
              {t("step-n-of-n", "Step {{step}} of {{total}}", {
                step: 1,
                total:
                  !formik.values[meta.FIELDS.category.key] ||
                  formik.values[meta.FIELDS.category.key] !== "AUTHENTICATION"
                    ? 3
                    : 2,
              })}
            </h6>
          </div>
          <div className="justify-self-end">
            <button
              className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
              type="button"
              onClick={(_) => setCurrentStep("content")}
            >
              {t("templates:template-content.label", "Template content")}
              <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
