import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { UserGroupIcon } from "@heroicons/react/outline";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { UserData } from "@hilos/types/hilos";
import HeaderNavigation from "src/components/HeaderNavigation";
import useTeam from "src/hooks/useTeam";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import TeamForm from "./TeamForm";

export default function TeamUpdate() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const teamId = params.id ? parseInt(params.id) : undefined;
  const team = useTeam(teamId);

  const massageTeam = (team) => {
    const nextTeam = { ...team };
    nextTeam.users = nextTeam.users.map((user: UserData) => user.id);
    nextTeam.default_assignee = nextTeam.default_assignee
      ? nextTeam.default_assignee.id
      : "";
    return nextTeam;
  };

  const formSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.put(
        buildAPIRoute(API_ROUTES.TEAM_DETAIL, {
          ":id": params.id,
        }),
        formData
      );
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        navigate(buildRoute("config-team-list"));
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:contacts.error-creating-contact",
            "An error occurred while creating your contact. Please try again."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-full overflow-y-auto bg-slate-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("settings:team.team-create"),
              url: buildRoute("config-team-create"),
            },
          ]}
        />

        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <UserGroupIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("update-team")} {team?.name}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl">
          {!team ? (
            <div className="card">
              <div className="card-body text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <TeamForm
              team={massageTeam(team)}
              formSubmit={formSubmit}
              success={success}
              submitted={submitted}
              action="update"
            />
          )}
        </div>
      </div>
    </div>
  );
}
