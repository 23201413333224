import { useTranslation } from "react-i18next";
import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";
import useTeam from "src/hooks/useTeam";

interface FlowExecutionStepTeamDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepTeamDetail({
  executionStep,
}: FlowExecutionStepTeamDetailProps) {
  const { t } = useTranslation();
  const teamId =
    executionStep.step.assign_to_teams &&
    executionStep.step.assign_to_teams.length > 0
      ? executionStep.step.assign_to_teams[0]
      : undefined;
  const team = useTeam(teamId);

  if (!team) {
    return null;
  }

  return (
    <div>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("flow-executions:execution-steps-detail.team", "Team assigned")}
      </h3>

      <p className="text-gray-900">{team?.name}</p>
    </div>
  );
}
