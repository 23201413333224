import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "reactflow/dist/style.css";
import { Popover, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { classNames } from "src/Helpers";
import {
  CANCELED_COLORS_PALETTE,
  COMPLETED_COLORS_PALETTE,
  EXECUTION_STATUS_TYPES,
  EXPIRED_COLORS_PALETTE,
  FAILED_COLORS_PALETTE,
  RUNNING_COLORS_PALETTE,
} from "./constants/flow";

interface FlowViewerFiltersProps {
  status?: string;
  setStatus: (value: string) => void;
}

function FlowViewerFilters({ status, setStatus }: FlowViewerFiltersProps) {
  const [t] = useTranslation();

  const [currentStatusLabel, currentStatusColors] = useMemo(() => {
    const currentExecutionStatusType = EXECUTION_STATUS_TYPES.find(
      (executionStatusType) => executionStatusType.id === status
    );

    if (currentExecutionStatusType) {
      const nextStatusColors =
        {
          COMPLETED: COMPLETED_COLORS_PALETTE,
          EXPIRED: EXPIRED_COLORS_PALETTE,
          CANCELED: CANCELED_COLORS_PALETTE,
          FAILED: FAILED_COLORS_PALETTE,
          RUNNING: RUNNING_COLORS_PALETTE,
        }[currentExecutionStatusType.id] || null;
      return [t(currentExecutionStatusType.label), nextStatusColors];
    }
    return [null, null];
  }, [t, status]);

  if (!status || !currentStatusLabel) {
    return null;
  }

  return (
    <div className="absolute right-0 bottom-0 z-10 h-auto p-4">
      <div className="flex w-full py-2 flex-col">
        <div className="flex w-full h-1.5 flex-row">
          {currentStatusColors !== null &&
            currentStatusColors.map(({ base }) => (
              <div
                key={`status-colors-${base}`}
                style={{ background: base }}
                className="flex-1 h-full"
              />
            ))}
        </div>
        <div className="flex w-full flex-row justify-between">
          <span className="text-xs text-gray-600">0%</span>
          <span className="text-xs text-gray-600">100%</span>
        </div>
      </div>
      <Popover as="div" className="relative inline-block text-left">
        <div>
          <Popover.Button className="group inline-flex items-center justify-between text-sm font-medium text-gray-700 hover:text-gray-900 outline-none w-56">
            {({ open }) => (
              <>
                <span>{t("flows:viewer.viewing-status", "Viewing")}</span>
                <div className="flex flex-row">
                  <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                    {currentStatusLabel}
                  </span>
                  <ChevronUpIcon
                    className={classNames(
                      "-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500",
                      open && "rotate-180"
                    )}
                    aria-hidden="true"
                  />
                </div>
              </>
            )}
          </Popover.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="absolute right-0 bottom-8 z-10 mt-2 origin-bottom-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
            {({ close }) => (
              <fieldset
                onChange={(event) => {
                  //@ts-ignore
                  const nextStatus = event.target.id;
                  setStatus(nextStatus);
                  close();
                }}
                className="space-y-4"
              >
                {EXECUTION_STATUS_TYPES.map(({ id, label }) => (
                  <div key={id} className="flex items-center">
                    <input
                      id={id}
                      name="notification-method"
                      type="radio"
                      defaultChecked={id === status}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor={id}
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t(label)}
                    </label>
                  </div>
                ))}
              </fieldset>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}

export default FlowViewerFilters;
