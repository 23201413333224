import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { UserIcon } from "@heroicons/react/outline";
import axios from "axios";
import { formatPhone, transformNullsToUndefined } from "../../Helpers";
import HeaderNavigation from "../../components/HeaderNavigation";
import useHilosStore from "../../hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import ContactForm from "./ContactForm";

export default function ContactUpdate() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [contact, setContact] = useState(undefined);
  const { reloadSession } = useHilosStore();

  useEffect(() => {
    async function getContact() {
      try {
        const response = await axios.get(
          buildAPIRoute(API_ROUTES.CONTACT_DETAIL, {
            ":id": params.id,
          })
        );
        transformNullsToUndefined(response.data);
        setContact(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    getContact();
  }, [params]);

  const massageContact = (contact) => {
    const formContact = { ...contact };
    formContact.meta = Object.getOwnPropertyNames(formContact.meta).map(
      (attr) => ({ key: attr, value: formContact.meta[attr] })
    );
    formContact.default_assignees = formContact.default_assignees.map(
      (user) => user.id
    );
    Object.getOwnPropertyNames(contact).forEach((k) => {
      if (contact[k] === null) contact[k] = "";
    });
    return formContact;
  };

  const formSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.put(
        buildAPIRoute(API_ROUTES.CONTACT_DETAIL, {
          ":id": params.id,
        }),
        formData
      );
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        navigate(buildRoute("contact-list"));
      }, 1000 * 2);
    } catch (err) {
      setSuccess(false);
      if (err.response.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          t(
            "contacts:contact-error",
            "An error occurred while creating your contact. Please try again."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      reloadSession();
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-slate-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: "Contacts",
              url: buildRoute("contact-list"),
            },
            {
              name: formatPhone(contact && contact.phone),
              url: buildRoute("contact-update", { ":id": params.id }),
            },
          ]}
        />

        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <UserIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("update-contact", "Update contact")}{" "}
              {contact && formatPhone(contact.phone)}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl">
          {!contact ? (
            <div className="card">
              <div className="card-body text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <ContactForm
              contact={massageContact(contact)}
              formSubmit={formSubmit}
              success={success}
              submitted={submitted}
            />
          )}
        </div>
      </div>
    </div>
  );
}
