import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { TFunction } from "i18next";
import { BODY_TYPES, BODY_TYPES_BY_GROUP } from "../../constants/http_form";

export interface VariableOption {
  label: string;
  value: string;
  type: string;
}

export interface VariableOptionGroup {
  label: string;
  icon: (props: any) => JSX.Element;
  options: VariableOption[];
}

const formatGroupLabel =
  (t: TFunction<"translation", undefined, "translation">) =>
  ({ label, icon: RenderIcon }: VariableOptionGroup) =>
    (
      <div className="flex flex-nowrap py-1">
        <div className="rounded-full bg-hilos-light p-1">
          <RenderIcon className="h-4 w-4 text-hilos" />
        </div>
        <div className="ml-2 self-center text-sm capitalize text-neutral-light">
          {t(label)}
        </div>
      </div>
    );

const formatOptionLabel =
  (t: TFunction<"translation", undefined, "translation">) =>
  ({ label }: VariableOption) =>
    (
      <div className="flex items-center py-3">
        <h4 className="text-sm">{t(label)}</h4>
      </div>
    );

function HTTPFormBodySelect({ contentType, onChangeContentType }) {
  const { t } = useTranslation();
  const defaultValue = useMemo(
    () =>
      BODY_TYPES.find((option) => option.value === contentType) as
        | VariableOption
        | undefined,
    [contentType]
  );

  return (
    <div className="grow">
      <Select<VariableOption, false, VariableOptionGroup>
        // @ts-ignore
        options={BODY_TYPES_BY_GROUP}
        formatGroupLabel={formatGroupLabel(t)}
        formatOptionLabel={formatOptionLabel(t)}
        defaultValue={defaultValue}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        onChange={onChangeContentType}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        menuPlacement="auto"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            borderColor: "#ddd",
            "&:hover": {
              borderColor: "#ddd",
            },
            boxShadow: "none",
            "*": {
              boxShadow: "none !important",
            },
          }),
          option: (base, { isFocused, isSelected }) => {
            return {
              ...base,
              wordBreak: "break-all",
              backgroundColor: isSelected
                ? "#d54466"
                : isFocused
                ? "#d54466"
                : undefined,
              color: isSelected ? "#fff" : isFocused ? "#fff" : undefined,
              fontSize: "small",
              paddingLeft: "30px",
            };
          },
        }}
      />
    </div>
  );
}

export default HTTPFormBodySelect;
