import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import { getTranslationPayload as t } from "src/i18n";
import PasswordField from "../../components/Form/PasswordField";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import { ERROR_MESSAGES } from "../../constants/Form";

const initialValues = {
  password1: "",
  password2: "",
};

const schema = yup.object().shape({
  password1: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .min(8, ERROR_MESSAGES.min),
  password2: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .min(8, ERROR_MESSAGES.min),
});

const FIELDS = {
  password1: {
    key: "password1",
    label: t("components.input.new-password.label", "New password"),
    placeholder: t("components.input.new-password.placeholder", "*******"),
    help: t(
      "components:input.new-password.help",
      "At least 8 characters, use letters and numbers."
    ),
  },
  password2: {
    key: "password2",
    label: t("components:input.confirm-password.label", "Repeat new password"),
    placeholder: t("components:input.confirm-password.placeholder", "*******"),
  },
};

export const NewPasswordForm = ({ onSubmit, success, submitted }) => {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) =>
        onSubmit(
          values,
          setSubmitting,
          setBackendValidationErrors,
          setBackendError
        )
      }
      enableReinitialize={true}
      initialValues={initialValues}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit} className="space-y-4">
          <APIErrors
            APIError={backendError}
            APIValidationErrors={backendValidationErrors}
            fieldTranslations={FIELDS}
          ></APIErrors>

          <PasswordField
            placeholder={t(FIELDS.password1.placeholder)}
            label={t(FIELDS.password1.label)}
            name={FIELDS.password1.key}
            help={t(
              "components:input.new-password.help",
              "At least 8 characters, use letters and numbers."
            )}
          />
          <PasswordField
            placeholder={t(FIELDS.password2.placeholder)}
            label={t(FIELDS.password2.label)}
            name={FIELDS.password2.key}
          />

          <StateButton
            isSubmitting={formik.isSubmitting}
            submitted={submitted}
            success={success}
            disabled={
              formik.errors &&
              Object.getOwnPropertyNames(formik.errors).length > 0
            }
            submittingText={t("saving")}
            successText={t("saved")}
            initialText={
              <>
                {t("settings:change-password.save", "Set new password")}
                <ArrowSmRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
              </>
            }
          ></StateButton>
        </form>
      )}
    </Formik>
  );
};
