import { Menu } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import usePopper, { Portal } from "../hooks/usePopper";

interface DropdownMenuProps {
  menuItems: { (active: boolean): void }[];
  width?: string;
  buttonClassName?: string;
  offsetMenu?: number[];
  icon?: (props: React.ComponentProps<"svg">) => JSX.Element;
}

function DropdownMenu({
  menuItems,
  width = "40",
  buttonClassName,
  offsetMenu = [0, 10],
  icon: RenderIcon = DotsVerticalIcon,
}: DropdownMenuProps) {
  const [trigger, container] = usePopper({
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: offsetMenu } }],
  });

  return (
    <Menu as="div" className="text-left">
      <div>
        <Menu.Button
          className={
            buttonClassName
              ? buttonClassName
              : "flex items-center text-gray-400 hover:text-gray-600"
          }
          ref={trigger}
        >
          <span className="sr-only">Open options</span>
          <RenderIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Portal>
        <Menu.Items
          className={`z-30 w-${width} rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          ref={container}
        >
          <div className="py-1">
            {menuItems.map((item, idx) => (
              <Menu.Item as="div" key={idx}>
                {/* @ts-ignore */}
                {({ active }) => item(active)}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Portal>
    </Menu>
  );
}

export default DropdownMenu;
