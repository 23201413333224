import { Link } from "react-router-dom";
import { buildRoute } from "./router";

function NotFound() {
  return (
    <div className="d-flex min-vh-100 align-items-center bg-auth border-top border-top-2 border-primary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-5 col-xl-4 my-5">
            <div className="text-center">
              <h6 className="text-uppercase text-muted mb-4">Error 404</h6>

              <h1 className="display-4 mb-3">Página no encontrada 😭</h1>

              <p className="text-muted mb-4">
                Lo importante es que tenemos salud.
              </p>

              <Link
                to={buildRoute("dashboard")}
                className="btn btn-lg btn-primary"
              >
                Regresar al portal
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
