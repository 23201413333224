import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { captureException } from "@sentry/react";
import axios from "axios";
import * as templatesMeta from "../containers/wa/WhatsAppTemplateMeta";
import { API_ROUTES, buildAPIRoute } from "../router/router";

const WhatsAppTemplateContext = createContext();

export function WhatsAppTemplateProvider({ children }) {
  const template = useWhatsAppTemplateProvider();
  return (
    <WhatsAppTemplateContext.Provider value={template}>
      {children}
    </WhatsAppTemplateContext.Provider>
  );
}

// Hook for child components to get the auth object and re-render when it changes.
export const useWhatsAppTemplate = () => {
  return useContext(WhatsAppTemplateContext);
};

function useWhatsAppTemplateProvider() {
  const [templates, setTemplates] = useState([]);

  const getTemplates = useCallback(async () => {
    try {
      const response = await axios.get(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_LIST) + "?page_size=255"
      );
      setTemplates(
        response.data.results.filter(
          (t) => t.status.toLowerCase() === templatesMeta.STATUS.APPROVED
        )
      );
    } catch (err) {
      captureException(err);
    }
  }, []);

  const getApprovedTemplates = useMemo(() => {
    return templates.filter(
      (t) => t.status.toLowerCase() === templatesMeta.STATUS.APPROVED
    );
  }, [templates]);

  const getTemplateById = (id) => {
    return templates.find((t) => t.id === id);
  };

  const getVariablesFromTemplate = (template, setRequiredFields) => {
    if (setRequiredFields) {
      setRequiredFields(
        template ? templatesMeta.getTemplateVarsFromTemplate(template) : []
      );
    }
  };

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line
  }, []);

  return {
    templates,
    getApprovedTemplates,
    getTemplateById,
    getVariablesFromTemplate,
  };
}
