import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import "@radix-ui/themes/styles.css";
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { captureException } from "@sentry/react";
import { createClient } from "graphql-ws";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import Loading from "src/components/Loading";
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import HilosLang from "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BASE_GRAPHQL_HTTP, BASE_GRAPHQL_WS } from "./router/router";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.NODE_ENV !== "production") {
  // Only development
  loadDevMessages();
  loadErrorMessages();
}

if (process.env.NODE_ENV === "production") {
  // Only Production
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    autocapture: false,
    disable_session_recording: true,
    session_recording: {
      maskAllInputs: true, // Important - this needs to be true for the below function to be called
      maskTextSelector: ".password, #sensitive", // masks all elements with the class "password" or the ID "sensitive"
      // @ts-expect-error
      // Example copied from posthog documentation
      maskInputFn: (text, element: Element) => {
        if (element?.attributes["type"]?.value === "password") {
          // If this is a password input, maskit
          return "*".repeat(text.length);
        }
        if (element?.className.includes("password")) {
          return "*".repeat(text.length);
        }
        if (element?.id.includes("sensitive")) {
          return "*".repeat(text.length);
        }
        // Otherwise, return it as is
        return text;
      },
    },
  });
  try {
    posthog.onFeatureFlags(function () {
      try {
        if (posthog.isFeatureEnabled("record_feature_flag")) {
          posthog.startSessionRecording();
        }
      } catch (error) {
        console.error(error);
      }
    });
  } catch (error) {
    console.error(error);
  }

  Sentry.init({
    dsn: "https://251c06b044c0411da6e2f19a71ac59f1@o553894.ingest.sentry.io/6006582",
    integrations: [
      new BrowserTracing(),
      new posthog.SentryIntegration(posthog, "chopmx", 6006582),
      new Sentry.Replay(),
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["Web push is not supported by this browser"],
    release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
    tracesSampleRate: 0.8,
    environment: process.env.REACT_APP_VERCEL_ENV,
  });

  // Initializing dataLayer for GTM
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });
}

let httpUrl =
  (posthog?.isFeatureEnabled("enable-new-graphql-http") &&
    process.env.REACT_APP_PROD_GRAPHQL_2_HTTP) ||
  (BASE_GRAPHQL_HTTP as string);
let wsUrl =
  (posthog?.isFeatureEnabled("enable-new-graphql-ws") &&
    process.env.REACT_APP_PROD_GRAPHQL_2_WS) ||
  (BASE_GRAPHQL_WS as string);

if (posthog?.isFeatureEnabled("custom-graphql")) {
  const customHttpUrl = process.env.REACT_APP_CUSTOM_GRAPHQL_HTTP;
  const customHttpWs = process.env.REACT_APP_CUSTOM_GRAPHQL_WS;
  console.log("Using custom graphql instance", customHttpUrl, customHttpWs);

  if (customHttpUrl && customHttpWs) {
    httpUrl = customHttpUrl;
    wsUrl = customHttpWs;
  }
}

const httpLink = new HttpLink({
  uri: httpUrl,
  credentials: "include",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: wsUrl,
    connectionParams: {
      credentials: "include",
    },
  })
);

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (process.env.NODE_ENV === "production") {
    if (
      Boolean(
        posthog.isFeatureEnabled("enable-graphql-capture-errors") ||
          process.env.REACT_APP_ENABLE_GRAPHQL_CAPTURE_ERRORS
      )
    ) {
      posthog.capture("graphql error", {
        graphQLErrors,
        operation: operation.operationName,
      });
    }
  }
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      switch (error.extensions.code) {
        case "concurrent-update":
          return forward(operation);
        case "unexpected":
          return forward(operation);
        default:
          captureException(error);
      }
    }
  }
});

const retryLink = new RetryLink({
  attempts: (count, operation) => {
    if (process.env.NODE_ENV === "production") {
      if (
        Boolean(
          posthog.isFeatureEnabled("enable-graphql-capture-retries") ||
            process.env.REACT_APP_ENABLE_GRAPHQL_CAPTURE_RETRIES
        )
      ) {
        posthog.capture("graphql retry", {
          count,
          operation: operation.operationName,
        });
      }
    }
    return true;
  },
  delay: (count, operation, error) =>
    Math.min(Math.max(count * 1000 * Math.random(), 500), 60000),
}).split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: from([errorLink, retryLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          api_inboxcontact: {
            keyArgs: false,
          },
          api_inboxcontact_aggregate: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

const config = {
  onUpdate: undefined,
  onSuccess: undefined,
};

root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <ApolloProvider client={client}>
        <ErrorBoundary type="page">
          <Suspense fallback={<Loading />}>
            <I18nextProvider i18n={HilosLang}>
              <App config={config} />
            </I18nextProvider>
          </Suspense>
        </ErrorBoundary>
      </ApolloProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
