import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef, Row, createColumnHelper } from "@tanstack/react-table";
import CheckboxField from "src/components/Form/CheckboxField";
import LocalTable from "src/components/LocalTable";
import { hasItems } from "src/helpers/utils";
import * as meta from "./FlowExecutionMeta";

function IncludeRow({ row }: { row: Row<string[]> }) {
  return (
    <div className="flex flex-row justify-center">
      <CheckboxField
        name={`${meta.FIELDS.contact_list.key}.${row.index + 1}.0`}
      />
    </div>
  );
}

export default function FlowExecutionFormContactFileContactList({ formik }) {
  const [parsedCSVData, setParsedCSVData] = useState<Array<string[]>>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const { t } = useTranslation();

  const numberOfRecipients = useMemo(() => {
    if (parsedCSVData.length < 1) {
      return 0;
    }
    return parsedCSVData.filter((result) => result[0]).length;
  }, [parsedCSVData]);

  const columnsTable = useMemo(() => {
    const columnHelper = createColumnHelper<string[]>();
    if (!hasItems(headers)) {
      return [];
    }

    const defaultColumns = [
      columnHelper.display({
        id: "include-row",
        cell: (props) => <IncludeRow row={props.row} />,
        header: () => (
          <>
            <div className="max-w-[100px] px-2 py-1 text-left text-tiny font-medium tracking-wider text-gray-500">
              {t("send")}
            </div>
          </>
        ),
      }),
      ...headers
        .map((row, index) =>
          columnHelper.accessor((row) => row[index], {
            header: () => (
              <>
                {/* Row {row} {index} */}
                <div className="min-w-[200px] px-2 py-1 text-left text-tiny font-medium tracking-wider text-gray-500">
                  {headers[index]}
                </div>
              </>
            ),
            id: `row_${index}`,
            cell: (info) => {
              if (info.row.original[0]) {
                // The row has the checkbox ticked i.e. should be included
                return (
                  <span className="px-2 text-sm text-gray-600">
                    {info.getValue()}
                  </span>
                );
              }
              // This row will be removed before creating the broadcast
              return (
                <span className="px-2 text-sm font-light text-gray-400 line-through">
                  {info.getValue()}
                </span>
              );
            },
          })
        )
        // We remove the first element of the array that is where we save
        // if the row should be included
        .filter((row, index) => index !== 0),
    ];
    return defaultColumns;
  }, [headers, t]);

  const computeDataCSV = useCallback(() => {
    if (formik.values[meta.FIELDS.contact_list.key]) {
      // We have already parsed the file contents
      const data = [...formik.values[meta.FIELDS.contact_list.key]];
      if (Array.isArray(data)) {
        const [headers, ...nextData] = data;
        setParsedCSVData(nextData);
        setHeaders(headers);
        return;
      }
    } else {
      setParsedCSVData([]);
      setHeaders([]);
    }
  }, [formik]);

  useEffect(() => {
    computeDataCSV();
  }, [computeDataCSV]);

  return (
    <LocalTable<string[]>
      tableTitle={
        <>
          {t("contacts", "Contacts")} (
          {/* t("contacts:selected_one", "selected {{count}}") */}
          {/* t("contacts:selected_other", "selected {{count}}") */}
          {t("table.selected", "selected", {
            count: numberOfRecipients,
          })}
          )
        </>
      }
      columns={columnsTable as unknown as ColumnDef<string[]>[]}
      data={parsedCSVData}
      columnVisibility={{
        should_be_included: false,
      }}
    />
  );
}
