import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { LockClosedIcon, LogoutIcon, UserIcon } from "@heroicons/react/outline";
import useUser from "src/hooks/useUser";
import { classNames } from "../../Helpers";
import HeaderNavigation from "../../components/HeaderNavigation";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";
import ChangePasswordForm from "./ChangePasswordForm";
import UserProfileForm from "./UserProfileForm";

const TABS = {
  GENERAL_INFO: "general-info",
  SECURITY: "security",
};

export default function Profile() {
  const { t } = useTranslation();
  const { logout } = useUser();
  const { session } = useHilosStore();

  const userData = {
    first_name: session?.first_name || "",
    last_name: session?.last_name || "",
    email: session?.email || "",
    default_language: session?.default_language || "",
  };

  const [currentTab, setCurrentTab] = useState(TABS.GENERAL_INFO);

  const tabs = [
    {
      name: t("general-info", "General info"),
      value: TABS.GENERAL_INFO,
      icon: UserIcon,
      current: currentTab === TABS.GENERAL_INFO,
    },
    {
      name: t("security", "Security"),
      value: TABS.SECURITY,
      icon: LockClosedIcon,
      current: currentTab === TABS.SECURITY,
    },
  ];

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <div className="h-screen">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("profile"),
              url: buildRoute("account-profile"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              {t("my-profile", "My profile")}
            </h2>
          </div>
          <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
            <span className="md:ml-3">
              <button
                onClick={handleLogout}
                className="inline-flex items-center rounded-md border border-red-500 px-4 py-2 text-sm font-medium text-red-500 shadow-sm hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                <LogoutIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {t("log-out", "Log out")}
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className="px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            {t("select-a-tab", "Select a tab")}
          </label>
          <select
            id="tabs"
            name="tabs"
            className="mb-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current)?.value}
            onChange={(e) => setCurrentTab(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.value} value={tab.value}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  type="button"
                  className={classNames(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                    "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                  onClick={(_) => setCurrentTab(tab.value)}
                >
                  <tab.icon
                    className={classNames(
                      tab.current
                        ? "text-indigo-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {currentTab === TABS.GENERAL_INFO && <UserProfileForm user={userData} />}

      {currentTab === TABS.SECURITY && <ChangePasswordForm />}
    </div>
  );
}
