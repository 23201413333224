import {
  CodeIcon,
  DotsHorizontalIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import { getTranslationPayload as t } from "src/i18n";

export const METHOD_TYPES = [
  {
    label: "GET",
    value: "GET",
    color: "rgb(59 130 246)", // text-blue-500
    help: t("components:http-form.method-types.get.help", "Make a get request"),
  },
  {
    label: "POST",
    value: "POST",
    color: "rgb(34 197 94)", // text-green-500
    help: t(
      "components:http-form.method-types.post.help",
      "Make a post request"
    ),
  },
  {
    label: "PUT",
    value: "PUT",
    color: "rgb(234 179 8)", // text-yellow-500
    help: t("components:http-form.method-types.put.help", "Make a put request"),
  },
  {
    label: "PATCH",
    value: "PATCH",
    color: "rgb(168 85 247)", // text-purple-500
    help: t(
      "components:http-form.method-types.patch.help",
      "Make a patch request"
    ),
  },
  {
    label: "DELETE",
    value: "DELETE",
    color: "rgb(239 68 68)", // text-red-500
    help: t(
      "components:http-form.method-types.delete.help",
      "Make a delete request"
    ),
  },
];

export const BODY_TYPES = [
  {
    label: t("components:http-form.body-types.multipart", "Multipart Form"),
    value: "multipart/form-data",
    type: "STRUCTURED",
  },
  {
    label: t("components:http-form.body-types.form", "Form URL Encoded"),
    value: "application/x-www-form-urlencoded",
    type: "STRUCTURED",
  },
  {
    label: t("components:http-form.body-types.json", "JSON"),
    value: "application/json",
    type: "TEXT",
  },
  {
    label: t("components:http-form.body-types.xml", "XML"),
    value: "application/xml",
    type: "TEXT",
  },
  {
    label: t("components:http-form.body-types.yaml", "YAML"),
    value: "text/yaml",
    type: "TEXT",
  },
  {
    label: t("components:http-form.body-types.plain", "Plain"),
    value: "text/plain",
    type: "TEXT",
  },
  {
    label: t("components:http-form.body-types.none", "None"),
    value: "NONE",
    type: "OTHER",
  },
];

export const BODY_TYPES_BY_GROUP = [
  {
    label: t(
      "components:http-form.body-types-by-group.structured",
      "Structured"
    ),
    icon: MenuIcon,
    options: BODY_TYPES.filter(({ type }) => type === "STRUCTURED"),
  },
  {
    label: t("components:http-form.body-types-by-group.text", "Text"),
    icon: CodeIcon,
    options: BODY_TYPES.filter(({ type }) => type === "TEXT"),
  },
  {
    label: t("components:http-form.body-types-by-group.other", "Other"),
    icon: DotsHorizontalIcon,
    options: BODY_TYPES.filter(({ type }) => type === "OTHER"),
  },
];
