import * as yup from "yup";

import { ERROR_MESSAGES, KNOWN_EMAIL_PROVIDERS } from "../../constants/Form";
import { guessCurrentCountry, guessCurrentTimezone } from "../../Helpers";

import { DateTime } from "luxon";
import { Link } from "react-router-dom";
// @flow
import { Trans } from "react-i18next";
import { getTranslationPayload as t } from "src/i18n";

export const getInitialValues = (email: string) => {
  const domainRegex = /[@]([^.@]+[.])*[^.@]+(?=[.][a-zA-Z]{2,}$)/;

  const emailDomainFound = email.match(domainRegex);
  let emailDomain = "";
  if (emailDomainFound) {
    emailDomain = emailDomainFound[0].substring(1);
  }

  const currentCountryGuess = guessCurrentCountry();

  return {
    name: KNOWN_EMAIL_PROVIDERS.includes(emailDomain) ? "" : emailDomain,
    default_timezone: guessCurrentTimezone(),
    country: currentCountryGuess,
    phone_default_country: "",
    onboarding_company_size: "",
    onboarding_company_role: "",
    onboarding_use_case: "",
    onboarding_use_case_other: "",
    onboarding_user_source: "",
    onboarding_user_source_other: "",
    onboarding_has_waba_provider: false,
    onboarding_waba_provider_select: "",
    onboarding_waba_provider: "",
    onboarding_phone_number: "",
    has_working_hours: false,
    inbox_add_name_before_message: false,
    measure_customer_satisfaction_per_conversation: false,
    autoclose_measure_customer_satisfaction_per_conversation: 24,
    should_autoclose_conversations: false,
    autoclose_conversations_after: 24,
    archive_conversations_when_closed: false,
    hide_sample_templates: false,
    agents_can_see_all_conversations: false,
    notification_settings: {
      notify_with_toast: false,
      require_confirmation_for_mass_send: false,
      confirmation_min: 500,
      confirmation_phones: [],
    },
    inbox_settings: {
      show_conversation_status: true,
      show_assignees: true,
      show_channel: true,
      show_conversation_tags: false,
      show_contact_tags: false,
    },
    add_as_default_assignee: false,
  };
};

const isValidTime = (val) => {
  const dt = DateTime.fromISO(val);
  return dt.isValid;
};

export const FIELDS = {
  name: {
    key: "name",
    label: t("settings:teams.pick-a-name", "Your company's name:"),
    help: "",
    placeholder: t("settings:teams.placeholder", "Google"),
  },
  default_timezone: {
    key: "default_timezone",
    label: t("settings:timezone.label", "Your team's default timezone"),
    help: t(
      "settings:timezone.help",
      "So we know when to send your queued messages and keep track of WhatsApp's rate limits."
    ),
  },
  country: {
    key: "country",
    label: t(
      "settings:country.label",
      "Which country is your company based in?"
    ),
    help: t("settings:country.help", "For billing purposes."),
  },
  phone_default_country: {
    key: "phone_default_country",
    label: t(
      "settings:phone_default_country.label",
      "Default country code to use for local phone numbers?"
    ),
    help: t(
      "settings.phone_default_country.help",
      "If we receive a phone number without a country code for a contact, we'll use this information to complete it."
    ),
  },
  billing_email: {
    key: "billing_email",
    label: t("settings:billing.checkout.billing_email.label", "Billing Email"),
    help: t(
      "settings:billing.checkout.billing_email.help",
      "The email you'd like to receive all your invoices at."
    ),
  },
  onboarding_phone_number: {
    key: "onboarding_phone_number",
    label: t(
      "settings:phone-number.label",
      "What's your WhatsApp phone number?"
    ),
    placeholder: t(
      "settings:phone-number.placeholder",
      "Including country code (+52...)"
    ),
  },
  onboarding_company_size: {
    key: "onboarding_company_size",
    label: t("settings:company-size.label", "How many employees do you have?"),
  },
  onboarding_company_role: {
    key: "onboarding_company_role",
    label: t("settings:company-role.label", "What's your role at"),
  },
  onboarding_use_case: {
    key: "onboarding_use_case",
    label: t(
      "settings:use-cases.label",
      "What do you want to achieve with Hilos?"
    ),
    help: t(
      "settings:use-cases.help",
      "We can get you personalized advice on how to best achieve what you're looking for."
    ),
  },
  onboarding_use_case_other: {
    key: "onboarding_use_case_other",
    label: t(
      "settings:use-cases.other.label",
      "Can you tell us a bit about what you'd like to achieve?"
    ),
    placeholder: t(
      "settings:use-cases.other.placeholder",
      "E.g. I'd like to send my customers notifications on their invoices."
    ),
  },
  onboarding_user_source: {
    key: "onboarding_user_source",
    label: t("settings:user-sources.label", "How did you hear about Hilos?"),
    help: t(
      "settings:user-sources.help",
      "Through social media, search engines, etc."
    ),
  },
  onboarding_user_source_other: {
    key: "onboarding_user_source_other",
    label: t(
      "settings:user-sources.other.label",
      "Can you tell us how you found us?"
    ),
    placeholder: t(
      "settings:user-sources.other.placeholder",
      "For example, I saw an advertisement on a website."
    ),
  },
  onboarding_has_waba_provider: {
    key: "onboarding_has_waba_provider",
    label: t(
      "settings:has-waba-provider.label",
      "Do you already have a WhatsApp Business API provider?"
    ),
  },
  onboarding_waba_provider_select: {
    key: "onboarding_waba_provider_select",
    label: t(
      "settings:waba-provider-select.label",
      "Which WhatsApp Business API provider are you using?"
    ),
  },
  onboarding_waba_provider: {
    key: "onboarding_waba_provider",
    label: t(
      "settings:wapi-provider.label",
      "Which WhatsApp Business API provider are you using?"
    ),
  },
  has_working_hours: {
    key: "has_working_hours",
    label: t("settings:working-hours.label", "Set working hours?"),
  },
  working_hours: {
    key: "working_hours",
    start_at: {
      key: "start_at",
      label: t("settings:working-hours.start-at", "Start at"),
    },
    end_at: {
      key: "end_at",
      label: t("settings:working-hours.end-at", "End at"),
    },
  },
  after_hours_response: {
    key: "after_hours_response",
    label: t(
      "settings:working-hours.after-hours.label",
      "Set an after hours response message:"
    ),
    help: t(
      "settings:working-hours.after-hours.help",
      "If set, Hilos will send this message in response to users contacting you out of your working hours."
    ),
    placeholder: t(
      "settings:working-hours.after-hours.placeholder",
      "Hey there, thanks for reaching out. Our working hours are X, we'll get back to you tomorrow."
    ),
  },
  timer_after_hours_response: {
    key: "timer_after_hours_response",
    label: t(
      "settings:working-hours.after-hours.timer.label",
      "Wait this long before sending automated message if the contact continue sending you messages."
    ),
    label2: t(
      "settings:working-hours.after-hours.timer.label2",
      "This message will be sent again after"
    ),
    help: t(
      "settings:working-hours.after-hours.timer.help",
      "For default, this message is sent again after 24 hours."
    ),
  },
  inbox_add_name_before_message: {
    key: "inbox_add_name_before_message",
    label: t(
      "settings:inbox-add-name-before-message.label",
      "Add sender's name before message?"
    ),
    help: (
      <>
        <Trans i18nKey="settings:add-name-before-message.details">
          <p>
            If set, every message sent to a contact via Inbox will begin with
            the name of the sender, then the message.
          </p>
          <p className="mt-1">
            Make sure everyone on your team has their name set in their{" "}
            <Link to={"/account/profile"} className="text-blue-500">
              profile
            </Link>
            .
          </p>
        </Trans>
      </>
    ),
  },
  measure_customer_satisfaction_per_conversation: {
    key: "measure_customer_satisfaction_per_conversation",
    label: t(
      "settings:customer-satisfaction.label",
      "Automatically send customer satisfaction question after conversation?"
    ),
    help: t(
      "settings:customer-satisfaction.help",
      "If set, the automated customer satisfaction question below will be sent every time a conversation is mark as resolved manually (by an agent) in the Inbox."
    ),
  },
  measure_customer_satisfaction_per_conversation_data: {
    channel: {
      key: "measure_customer_satisfaction_per_conversation_data.channel",
      label: t("settings:channel.label", "Channel to use for the survey"),
      placeholder: "",
      help: t(
        "settings:channel.help",
        "Select which channel to send this survey from."
      ),
    },
    body: {
      key: "measure_customer_satisfaction_per_conversation_data.body",
      label: t("settings:message-body.label", "Message body"),
      help: (
        <>
          <Trans i18nKey="settings:message-body.help">
            <p>This is the body message that will be sent to your customers.</p>
          </Trans>
        </>
      ),
    },
    answer_options_render_list_button_title: {
      key: "measure_customer_satisfaction_per_conversation_data.answer_options_render_list_button_title",
      label: t("settings:answer-options-list.label", "Open list button text"),
      placeholder: t(
        "settings:answer-options-list.label",
        "For example, Select an option"
      ),
    },
    answer_validation_message: {
      key: "measure_customer_satisfaction_per_conversation_data.answer_validation_message",
      label: t(
        "settings:answer-validation-message.label",
        "Answer validation message"
      ),
      help: t(
        "settings:answer-validation-message.help",
        "If the recipient doesn't provide a value according to this question's answer type, we'll try again with this message."
      ),
      placeholder: t(
        "settings:answer-validation-message.placeholder",
        "Please specify a proper value."
      ),
    },
    answer_options: {
      key: "measure_customer_satisfaction_per_conversation_data.answer_options",
      label: t("settings:answer-options.label", "Answer options"),
      placeholder: t("settings:answer-options.placeholder", "1-Excelent"),
    },
    has_max_answer_attempts: {
      key: "measure_customer_satisfaction_per_conversation_data.has_max_answer_attempts",
      label: t(
        "settings:has_max_answer_attempts.label",
        "Set max number of answer attempts?"
      ),
      help: t(
        "settings:has_max_answer_attempts.help",
        "If set, the conversation will be closed after exceeding the max number of given answer attempts."
      ),
    },
    max_answer_attempts: {
      key: "measure_customer_satisfaction_per_conversation_data.max_answer_attempts",
      label: t(
        "settings:max_answer_attempts.label",
        "Allow this many answer attempts"
      ),
    },
  },
  should_autoclose_conversations: {
    key: "should_autoclose_conversations",
    label: t(
      "settings:autoclose-conversations.label",
      "Automatically close conversations?"
    ),
  },
  add_as_default_assignee: {
    key: "add_as_default_assignee",
    label: t(
      "settings:add-as-default-assignee.label",
      "Assign first responder as the contact's default assignee?"
    ),
    help: t(
      "settings:add-as-default-assignee.help",
      "If set, we'll assign whoever answered a conversation as the contact's default assignee. Useful for sales teams."
    ),
  },
  autoclose_conversations_after: {
    key: "autoclose_conversations_after",
    label: t(
      "settings:autoclose-conversations-after.label",
      "Close them after this many hours:"
    ),
    help: t(
      "settings:autoclose-conversations-after.help",
      "If set, conversations will be closed after this many hours without a response."
    ),
  },
  autoclose_measure_customer_satisfaction_per_conversation: {
    key: "autoclose_measure_customer_satisfaction_per_conversation",
    label: t(
      "settings:autoclose-measure-conversations-after.label",
      "Close conversation after this many hours:"
    ),
    help: t(
      "settings:autoclose-measure-conversations-after.help",
      "Customer satisfaction surveys will be closed after this many hours without a response have elapsed."
    ),
  },
  archive_conversations_when_closed: {
    key: "archive_conversations_when_closed",
    label: t(
      "settings:archive-conversations-when-closed.label",
      "Archive conversations when closing them?"
    ),
    help: t(
      "settings:archive-conversations-when-closed.help",
      "If set, closed conversations will be automatically archived and won't show up by default in the Inbox. When the contact writes again, it will be unarchived automatically."
    ),
  },
  hide_sample_templates: {
    key: "hide_sample_templates",
    label: t(
      "settings:hide-sample-templates.label",
      "Hide WhatsApp's sample templates?"
    ),
    help: t(
      "settings:hide-sample-templates.help",
      "As it is not possible to delete the WhatsApp's sample templates, with this setting you can hide them from your template list."
    ),
  },
  agents_can_see_all_conversations: {
    key: "agents_can_see_all_conversations",
    label: t(
      "settings:agents-can-see-all-conversations.label",
      "Can team members marked as Agent see all conversations?"
    ),
    help: t(
      "settings:agents-can-see-all-conversations.help",
      "If not set, only Managers will be able to see all conversations while Agents will only be able to see those assigned to them."
    ),
  },
  notification_settings: {
    notify_with_toast: {
      key: "notify_with_toast",
      label: t(
        "settings:show-toast-notifications.label",
        "Show a notification inside Hilos when receiving a new message?"
      ),
      help: t(
        "settings:show-toast-notifications.help",
        "If set, we'll show a notification on the lower right part of Hilos whenever a new message comes in to a conversation you're assigned to."
      ),
    },
    require_confirmation_for_mass_send: {
      key: "require_confirmation_for_mass_send",
      label: t(
        "settings:require-confirmation-for-mass-send.label",
        "Require confirmation before starting a big broadcast or flow?"
      ),
      help: t(
        "settings:require-confirmation-for-mass-send.help",
        "If set, we'll notify you on WhatsApp so you can confirm or decline starting a big broadcast or flow."
      ),
    },
    confirmation_min: {
      key: "confirmation_min",
      label: t(
        "settings:confirmation-min-for-mass-send.label",
        "Require confirmation for broadcasts/flows above this many recipients:"
      ),
    },
    confirmation_phones: {
      key: "confirmation_phones",
      label: t(
        "settings:confirmation-phone.label",
        "Phone numbers to send confirmation flow"
      ),
      placeholder: "+52...",
    },
  },
  inbox_settings: {
    show_conversation_status: {
      key: "show_conversation_status",
      label: t(
        "settings:conversation-list.show-conversation-status.label",
        "Show conversation status"
      ),
      help: t(
        "settings:conversation-list.show-conversation-status.help",
        "Show the status of the conversation [NEW, CLOSED, IN_PROGRESS, IN_FLOW...]."
      ),
    },
    show_assignees: {
      key: "show_assignees",
      label: t(
        "settings:conversation-list.show-assignees.label",
        "Show assignees"
      ),
      help: t(
        "settings:conversation-list.show-assignees.help",
        "Show who are assigned to the conversation."
      ),
    },
    show_channel: {
      key: "show_channel",
      label: t("settings:conversation-list.show-channel.label", "Show channel"),
      help: t(
        "settings:conversation-list.show-channel.help",
        "Show the conversation channel."
      ),
    },
    show_conversation_tags: {
      key: "show_conversation_tags",
      label: t(
        "settings:conversation-list.show-conversation-tags.label",
        "Show conversation tags"
      ),
      help: t(
        "settings:conversation-list.show-conversation-tags.help",
        "Show a list of the conversation tags."
      ),
    },
    show_contact_tags: {
      key: "show_contact_tags",
      label: t(
        "settings:conversation-list.show-contact-tags.label",
        "Show contact tags"
      ),
      help: t(
        "settings:conversation-list.show-contact-tags.help",
        "Show a list of the contact tags."
      ),
    },
  },
};

export const workingHoursSchema = yup.object().shape({
  weekday: yup.number().required(ERROR_MESSAGES.required),
  is_available: yup.boolean(),
  start_at: yup.mixed().when("is_available", {
    is: true,
    then: yup
      .string()
      .required(ERROR_MESSAGES.required)
      .test("is-valid-time", ERROR_MESSAGES.time, (value, context) =>
        value ? isValidTime(value) : true
      )
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  end_at: yup.mixed().when("is_available", {
    is: true,
    then: yup
      .string()
      .required(ERROR_MESSAGES.required)
      .test("is-valid-time", ERROR_MESSAGES.time, (value, context) =>
        value ? isValidTime(value) : true
      )
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
});

export const createSchema = yup.object().shape({
  name: yup.string().required(ERROR_MESSAGES.required),
  default_timezone: yup.string().required(ERROR_MESSAGES.required),
  onboarding_company_size: yup.string().required(ERROR_MESSAGES.required),
  onboarding_company_role: yup.string().required(ERROR_MESSAGES.required),
  onboarding_user_source: yup.string().required(ERROR_MESSAGES.required),
  onboarding_user_source_other: yup.string(),
  onboarding_has_waba_provider: yup.bool(),
  onboarding_waba_provider_select: yup
    .string()
    .when("onboarding_has_waba_provider", {
      is: true,
      then: yup.string().required(ERROR_MESSAGES.required),
    }),
  onboarding_waba_provider: yup
    .string()
    .when("onboarding_waba_provider_select", {
      is: "OTHER",
      then: yup.string().required(ERROR_MESSAGES.required),
    }),
});

export const updateSchema = yup.object().shape({
  name: yup.string().required(ERROR_MESSAGES.required),
  default_timezone: yup.string().required(ERROR_MESSAGES.required),
  has_working_hours: yup.bool(),
  working_hours: yup.array().of(workingHoursSchema),
  measure_customer_satisfaction_per_conversation_data: yup
    .object()
    .when("measure_customer_satisfaction_per_conversation", {
      is: true,
      then: yup.object().shape({
        body: yup.string().required(ERROR_MESSAGES.required),
        answer_options_render_list_button_title: yup
          .string()
          .required(ERROR_MESSAGES.required),
        answer_validation_message: yup
          .string()
          .required(ERROR_MESSAGES.required),
        answer_options: yup
          .array()
          .of(yup.string().required(ERROR_MESSAGES.required))
          .min(1, ERROR_MESSAGES.minNumber),
      }),
    }),
  should_autoclose_conversations: yup.bool(),
  autoclose_conversations_after: yup
    .number()
    .when("should_autoclose_conversations", {
      is: (val) => val,
      then: yup
        .number()
        .required(ERROR_MESSAGES.required)
        .min(1, ERROR_MESSAGES.minNumberValue)
        .max(168, ERROR_MESSAGES.maxNumberValue),
    }),
  autoclose_measure_customer_satisfaction_per_conversation: yup
    .number()
    .required(ERROR_MESSAGES.required)
    .min(1, ERROR_MESSAGES.minNumberValue)
    .max(24, ERROR_MESSAGES.maxNumberValue),
  notification_settings: yup.object().shape({
    notify_with_toast: yup.boolean(),
    require_confirmation_for_mass_send: yup.boolean(),
    confirmation_min: yup.mixed().when("require_confirmation_for_mass_send", {
      is: true,
      then: yup
        .number()
        .min(0, ERROR_MESSAGES.minNumber)
        .required(ERROR_MESSAGES.required),
    }),
    confirmation_phones: yup
      .mixed()
      .when("require_confirmation_for_mass_send", {
        is: true,
        then: yup
          .array()
          .of(yup.string())
          .min(1, ERROR_MESSAGES.minNumber)
          .required(ERROR_MESSAGES.required),
      }),
  }),
});

export const formatTimezoneOptionLabel = ({
  value,
  label,
  tz_short_name,
  tz_offset,
}) => (
  <div className="text-sm">
    {label} -{" "}
    <span className="text-muted">
      {tz_short_name} {tz_offset}
    </span>
  </div>
);

export const DAY_LABELS_LONG = [
  // t("Monday", "Monday")
  "Monday",
  // t("Tuesday", "Tuesday")
  "Tuesday",
  // t("Wednesday", "Wednesday")
  "Wednesday",
  // t("Thursday", "Thursday")
  "Thursday",
  // t("Friday", "Friday")
  "Friday",
  // t("Saturday", "Saturday")
  "Saturday",
  // t("Sunday", "Sunday")
  "Sunday",
];

export const COMPANY_SIZES = [
  {
    label: "1 - 10",
    value: "ONE_TEN",
  },
  {
    label: "11 - 50",
    value: "TEN_FIFTY",
  },
  {
    label: "51 - 100",
    value: "FIFTY_HUNDRED",
  },
  {
    label: "101 - 500",
    value: "HUNDRED_5H",
  },
  {
    label: "500 - 1k",
    value: "FIVEH_THOUSAND",
  },
  {
    // t("over-1k", "Over 1k")
    label: "over-1k",
    value: "OVER_THOUSAND",
  },
];

export const COMPANY_ROLES = [
  {
    // t("settings:role.product", "Product")
    label: "settings:role.product",
    value: "PRODUCT",
  },
  {
    // t("settings:role.engineering", "Engineering")
    label: "settings:role.engineering",
    value: "ENGINEERING",
  },
  {
    // t("settings:role.marketing", "Marketing")
    label: "settings:role.marketing",
    value: "MARKETING",
  },
  {
    // t("settings:role.support", "Support")
    label: "settings:role.support",
    value: "SUPPORT",
  },
  {
    // t("settings:role.operations", "Operations")
    label: "settings:role.operations",
    value: "OPERATIONS",
  },
  {
    // t("settings:role.ceo", "CEO or Founder")
    label: "settings:role.ceo",
    value: "CEO_OR_FOUNDER",
  },
];

export const HILOS_USE_CASES = [
  {
    // t("settings:use-case.automation", "Automated sequences to activate/reactivate leads")
    label: "settings:use-case.automation",
    value: "AUTOMATION",
  },
  {
    // t("settings:use-case.centralization", "Centralize communication for my sales team")
    label: "settings:use-case.centralization",
    value: "CENTRALIZATION",
  },
  {
    // t("settings:use-case.profiling", "Profile clients through WhatsApp")
    label: "settings:use-case.profiling",
    value: "PROFILING",
  },
  {
    // t("settings:use-case.support", "Customer support/attention")
    label: "settings:use-case.support",
    value: "SUPPORT",
  },
  {
    // t("settings:use-case.notifications", "Notifications to clients (e.g., "Your order is on its way")")
    label: "settings:use-case.notifications",
    value: "NOTIFICATIONS",
  },
  {
    // t("settings:use-case.other", "Something else")
    label: "settings:use-case.other",
    value: "OTHER",
  },
];

export const USER_SOURCES = [
  {
    // t("settings:user-source.google", "Google")
    label: "settings:user-source.google",
    value: "GOOGLE",
  },
  {
    // t("settings:user-source.linkedin", "Linkedin")
    label: "settings:user-source.linkedin",
    value: "LINKEDIN",
  },
  {
    // t("settings:user-source.recommendation", "Recommendation")
    label: "settings:user-source.recommendation",
    value: "RECOMMENDATION",
  },
  {
    // t("settings:user-source.youtube", "Youtube")
    label: "settings:user-source.youtube",
    value: "YOUTUBE",
  },
  {
    // t("settings:user-source.mail", "Received a mail")
    label: "settings:user-source.mail",
    value: "MAIL",
  },
  {
    // t("settings:user-source.referred", "Referral Program (Partners)")
    label: "settings:user-source.referred",
    value: "REFERRED",
  },
  {
    // t("settings:user-source.hubspot", "Hubspot Marketplace")
    label: "settings:user-source.hubspot",
    value: "HUBSPOT",
  },
  {
    // t("settings:user-source.other", "Somewhere else")
    label: "settings:user-source.other",
    value: "OTHER",
  },
];

export const ONBOARDING_DEFAULT_WABA_PROVIDERS = [
  {
    label: "Twilio",
    value: "TWILIO",
  },
  {
    label: "360Dialog",
    value: "360DIALOG",
  },
  {
    label: "Gupshup",
    value: "GUPSHUP",
  },
  {
    label: "Wati",
    value: "WATI",
  },
  {
    // t("settings:wapi-provider.wab", "Not yet, using WhatsApp for Business")
    label: "settings:wapi-provider.wab",
    value: "WAB",
  },
  {
    // t("settings:wapi-provider.other", "Other provider")
    label: "settings:wapi-provider.other",
    value: "OTHER",
  },
];

// Exported from pytz:
// from pytz import common_timezones, timezone
// from datetime import datetime
// for c in common_timezones:
//     d = datetime(2020,1,1,0,0,0)
//     t = timezone(c)
//     d= t.localize(d)
//     print("{value: '%s', label: '%s', tz_short_name: '%s', tz_offset: '%s'}," % (c, c, d.strftime("%Z"), d.strftime("%z")))
export const AVAILABLE_TIMEZONES = [
  {
    value: "America/Adak",
    label: "America/Adak",
    tz_short_name: "HST",
    tz_offset: "-1000",
  },
  {
    value: "America/Anchorage",
    label: "America/Anchorage",
    tz_short_name: "AKST",
    tz_offset: "-0900",
  },
  {
    value: "America/Anguilla",
    label: "America/Anguilla",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Antigua",
    label: "America/Antigua",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Araguaina",
    label: "America/Araguaina",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "America/Argentina/Buenos_Aires",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Catamarca",
    label: "America/Argentina/Catamarca",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Cordoba",
    label: "America/Argentina/Cordoba",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Jujuy",
    label: "America/Argentina/Jujuy",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/La_Rioja",
    label: "America/Argentina/La_Rioja",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Mendoza",
    label: "America/Argentina/Mendoza",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    label: "America/Argentina/Rio_Gallegos",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Salta",
    label: "America/Argentina/Salta",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/San_Juan",
    label: "America/Argentina/San_Juan",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/San_Luis",
    label: "America/Argentina/San_Luis",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Tucuman",
    label: "America/Argentina/Tucuman",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Argentina/Ushuaia",
    label: "America/Argentina/Ushuaia",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Aruba",
    label: "America/Aruba",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Asuncion",
    label: "America/Asuncion",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Atikokan",
    label: "America/Atikokan",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Bahia",
    label: "America/Bahia",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Bahia_Banderas",
    label: "America/Bahia_Banderas",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Barbados",
    label: "America/Barbados",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Belem",
    label: "America/Belem",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Belize",
    label: "America/Belize",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Blanc-Sablon",
    label: "America/Blanc-Sablon",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Boa_Vista",
    label: "America/Boa_Vista",
    tz_short_name: "-04",
    tz_offset: "-0400",
  },
  {
    value: "America/Bogota",
    label: "America/Bogota",
    tz_short_name: "-05",
    tz_offset: "-0500",
  },
  {
    value: "America/Boise",
    label: "America/Boise",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Cambridge_Bay",
    label: "America/Cambridge_Bay",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Campo_Grande",
    label: "America/Campo_Grande",
    tz_short_name: "-04",
    tz_offset: "-0400",
  },
  {
    value: "America/Cancun",
    label: "America/Cancun",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Caracas",
    label: "America/Caracas",
    tz_short_name: "-04",
    tz_offset: "-0400",
  },
  {
    value: "America/Cayenne",
    label: "America/Cayenne",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Cayman",
    label: "America/Cayman",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Chicago",
    label: "America/Chicago",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Chihuahua",
    label: "America/Chihuahua",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Costa_Rica",
    label: "America/Costa_Rica",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Creston",
    label: "America/Creston",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Cuiaba",
    label: "America/Cuiaba",
    tz_short_name: "-04",
    tz_offset: "-0400",
  },
  {
    value: "America/Curacao",
    label: "America/Curacao",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Danmarkshavn",
    label: "America/Danmarkshavn",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "America/Dawson",
    label: "America/Dawson",
    tz_short_name: "PST",
    tz_offset: "-0800",
  },
  {
    value: "America/Dawson_Creek",
    label: "America/Dawson_Creek",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Denver",
    label: "America/Denver",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Detroit",
    label: "America/Detroit",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Dominica",
    label: "America/Dominica",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Edmonton",
    label: "America/Edmonton",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Eirunepe",
    label: "America/Eirunepe",
    tz_short_name: "-05",
    tz_offset: "-0500",
  },
  {
    value: "America/El_Salvador",
    label: "America/El_Salvador",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Fort_Nelson",
    label: "America/Fort_Nelson",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Fortaleza",
    label: "America/Fortaleza",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Glace_Bay",
    label: "America/Glace_Bay",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Goose_Bay",
    label: "America/Goose_Bay",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Grand_Turk",
    label: "America/Grand_Turk",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Grenada",
    label: "America/Grenada",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Guadeloupe",
    label: "America/Guadeloupe",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Guatemala",
    label: "America/Guatemala",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Guayaquil",
    label: "America/Guayaquil",
    tz_short_name: "-05",
    tz_offset: "-0500",
  },
  {
    value: "America/Guyana",
    label: "America/Guyana",
    tz_short_name: "-04",
    tz_offset: "-0400",
  },
  {
    value: "America/Halifax",
    label: "America/Halifax",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Havana",
    label: "America/Havana",
    tz_short_name: "CST",
    tz_offset: "-0500",
  },
  {
    value: "America/Hermosillo",
    label: "America/Hermosillo",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Indiana/Indianapolis",
    label: "America/Indiana/Indianapolis",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Indiana/Knox",
    label: "America/Indiana/Knox",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Indiana/Marengo",
    label: "America/Indiana/Marengo",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Indiana/Petersburg",
    label: "America/Indiana/Petersburg",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Indiana/Tell_City",
    label: "America/Indiana/Tell_City",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Indiana/Vevay",
    label: "America/Indiana/Vevay",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Indiana/Vincennes",
    label: "America/Indiana/Vincennes",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Indiana/Winamac",
    label: "America/Indiana/Winamac",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Inuvik",
    label: "America/Inuvik",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Iqaluit",
    label: "America/Iqaluit",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Jamaica",
    label: "America/Jamaica",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Juneau",
    label: "America/Juneau",
    tz_short_name: "AKST",
    tz_offset: "-0900",
  },
  {
    value: "America/Kentucky/Louisville",
    label: "America/Kentucky/Louisville",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Kentucky/Monticello",
    label: "America/Kentucky/Monticello",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Kralendijk",
    label: "America/Kralendijk",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/La_Paz",
    label: "America/La_Paz",
    tz_short_name: "-04",
    tz_offset: "-0400",
  },
  {
    value: "America/Lima",
    label: "America/Lima",
    tz_short_name: "-05",
    tz_offset: "-0500",
  },
  {
    value: "America/Los_Angeles",
    label: "America/Los_Angeles",
    tz_short_name: "PST",
    tz_offset: "-0800",
  },
  {
    value: "America/Lower_Princes",
    label: "America/Lower_Princes",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Maceio",
    label: "America/Maceio",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Managua",
    label: "America/Managua",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Manaus",
    label: "America/Manaus",
    tz_short_name: "-04",
    tz_offset: "-0400",
  },
  {
    value: "America/Marigot",
    label: "America/Marigot",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Martinique",
    label: "America/Martinique",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Matamoros",
    label: "America/Matamoros",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Mazatlan",
    label: "America/Mazatlan",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Menominee",
    label: "America/Menominee",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Merida",
    label: "America/Merida",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Metlakatla",
    label: "America/Metlakatla",
    tz_short_name: "AKST",
    tz_offset: "-0900",
  },
  {
    value: "America/Mexico_City",
    label: "America/Mexico_City",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Miquelon",
    label: "America/Miquelon",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Moncton",
    label: "America/Moncton",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Monterrey",
    label: "America/Monterrey",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Montevideo",
    label: "America/Montevideo",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Montserrat",
    label: "America/Montserrat",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Nassau",
    label: "America/Nassau",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/New_York",
    label: "America/New_York",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Nipigon",
    label: "America/Nipigon",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Nome",
    label: "America/Nome",
    tz_short_name: "AKST",
    tz_offset: "-0900",
  },
  {
    value: "America/Noronha",
    label: "America/Noronha",
    tz_short_name: "-02",
    tz_offset: "-0200",
  },
  {
    value: "America/North_Dakota/Beulah",
    label: "America/North_Dakota/Beulah",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/North_Dakota/Center",
    label: "America/North_Dakota/Center",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/North_Dakota/New_Salem",
    label: "America/North_Dakota/New_Salem",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Nuuk",
    label: "America/Nuuk",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Ojinaga",
    label: "America/Ojinaga",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Panama",
    label: "America/Panama",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Pangnirtung",
    label: "America/Pangnirtung",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Paramaribo",
    label: "America/Paramaribo",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Phoenix",
    label: "America/Phoenix",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "America/Port-au-Prince",
    label: "America/Port-au-Prince",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Port_of_Spain",
    label: "America/Port_of_Spain",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Porto_Velho",
    label: "America/Porto_Velho",
    tz_short_name: "-04",
    tz_offset: "-0400",
  },
  {
    value: "America/Puerto_Rico",
    label: "America/Puerto_Rico",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Punta_Arenas",
    label: "America/Punta_Arenas",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Rainy_River",
    label: "America/Rainy_River",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Rankin_Inlet",
    label: "America/Rankin_Inlet",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Recife",
    label: "America/Recife",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Regina",
    label: "America/Regina",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Resolute",
    label: "America/Resolute",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Rio_Branco",
    label: "America/Rio_Branco",
    tz_short_name: "-05",
    tz_offset: "-0500",
  },
  {
    value: "America/Santarem",
    label: "America/Santarem",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Santiago",
    label: "America/Santiago",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Santo_Domingo",
    label: "America/Santo_Domingo",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Sao_Paulo",
    label: "America/Sao_Paulo",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "America/Scoresbysund",
    label: "America/Scoresbysund",
    tz_short_name: "-01",
    tz_offset: "-0100",
  },
  {
    value: "America/Sitka",
    label: "America/Sitka",
    tz_short_name: "AKST",
    tz_offset: "-0900",
  },
  {
    value: "America/St_Barthelemy",
    label: "America/St_Barthelemy",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/St_Johns",
    label: "America/St_Johns",
    tz_short_name: "NST",
    tz_offset: "-0330",
  },
  {
    value: "America/St_Kitts",
    label: "America/St_Kitts",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/St_Lucia",
    label: "America/St_Lucia",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/St_Thomas",
    label: "America/St_Thomas",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/St_Vincent",
    label: "America/St_Vincent",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Swift_Current",
    label: "America/Swift_Current",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Tegucigalpa",
    label: "America/Tegucigalpa",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Thule",
    label: "America/Thule",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Thunder_Bay",
    label: "America/Thunder_Bay",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Tijuana",
    label: "America/Tijuana",
    tz_short_name: "PST",
    tz_offset: "-0800",
  },
  {
    value: "America/Toronto",
    label: "America/Toronto",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "America/Tortola",
    label: "America/Tortola",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "America/Vancouver",
    label: "America/Vancouver",
    tz_short_name: "PST",
    tz_offset: "-0800",
  },
  {
    value: "America/Whitehorse",
    label: "America/Whitehorse",
    tz_short_name: "PST",
    tz_offset: "-0800",
  },
  {
    value: "America/Winnipeg",
    label: "America/Winnipeg",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "America/Yakutat",
    label: "America/Yakutat",
    tz_short_name: "AKST",
    tz_offset: "-0900",
  },
  {
    value: "America/Yellowknife",
    label: "America/Yellowknife",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "Africa/Abidjan",
    label: "Africa/Abidjan",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Accra",
    label: "Africa/Accra",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Addis_Ababa",
    label: "Africa/Addis_Ababa",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Africa/Algiers",
    label: "Africa/Algiers",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Asmara",
    label: "Africa/Asmara",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Africa/Bamako",
    label: "Africa/Bamako",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Bangui",
    label: "Africa/Bangui",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Banjul",
    label: "Africa/Banjul",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Bissau",
    label: "Africa/Bissau",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Blantyre",
    label: "Africa/Blantyre",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Brazzaville",
    label: "Africa/Brazzaville",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Bujumbura",
    label: "Africa/Bujumbura",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Cairo",
    label: "Africa/Cairo",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Casablanca",
    label: "Africa/Casablanca",
    tz_short_name: "+01",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Ceuta",
    label: "Africa/Ceuta",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Conakry",
    label: "Africa/Conakry",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Dakar",
    label: "Africa/Dakar",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Dar_es_Salaam",
    label: "Africa/Dar_es_Salaam",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Africa/Djibouti",
    label: "Africa/Djibouti",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Africa/Douala",
    label: "Africa/Douala",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/El_Aaiun",
    label: "Africa/El_Aaiun",
    tz_short_name: "+01",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Freetown",
    label: "Africa/Freetown",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Gaborone",
    label: "Africa/Gaborone",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Harare",
    label: "Africa/Harare",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Johannesburg",
    label: "Africa/Johannesburg",
    tz_short_name: "SAST",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Juba",
    label: "Africa/Juba",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Africa/Kampala",
    label: "Africa/Kampala",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Africa/Khartoum",
    label: "Africa/Khartoum",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Kigali",
    label: "Africa/Kigali",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Kinshasa",
    label: "Africa/Kinshasa",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Lagos",
    label: "Africa/Lagos",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Libreville",
    label: "Africa/Libreville",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Lome",
    label: "Africa/Lome",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Luanda",
    label: "Africa/Luanda",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Lubumbashi",
    label: "Africa/Lubumbashi",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Lusaka",
    label: "Africa/Lusaka",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Malabo",
    label: "Africa/Malabo",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Maputo",
    label: "Africa/Maputo",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Maseru",
    label: "Africa/Maseru",
    tz_short_name: "SAST",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Mbabane",
    label: "Africa/Mbabane",
    tz_short_name: "SAST",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Mogadishu",
    label: "Africa/Mogadishu",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Africa/Monrovia",
    label: "Africa/Monrovia",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Nairobi",
    label: "Africa/Nairobi",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Africa/Ndjamena",
    label: "Africa/Ndjamena",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Niamey",
    label: "Africa/Niamey",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Nouakchott",
    label: "Africa/Nouakchott",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Ouagadougou",
    label: "Africa/Ouagadougou",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Porto-Novo",
    label: "Africa/Porto-Novo",
    tz_short_name: "WAT",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Sao_Tome",
    label: "Africa/Sao_Tome",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Africa/Tripoli",
    label: "Africa/Tripoli",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Africa/Tunis",
    label: "Africa/Tunis",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Africa/Windhoek",
    label: "Africa/Windhoek",
    tz_short_name: "CAT",
    tz_offset: "+0200",
  },
  {
    value: "Antarctica/Casey",
    label: "Antarctica/Casey",
    tz_short_name: "+08",
    tz_offset: "+0800",
  },
  {
    value: "Antarctica/Davis",
    label: "Antarctica/Davis",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Antarctica/DumontDUrville",
    label: "Antarctica/DumontDUrville",
    tz_short_name: "+10",
    tz_offset: "+1000",
  },
  {
    value: "Antarctica/Macquarie",
    label: "Antarctica/Macquarie",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Antarctica/Mawson",
    label: "Antarctica/Mawson",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Antarctica/McMurdo",
    label: "Antarctica/McMurdo",
    tz_short_name: "NZDT",
    tz_offset: "+1300",
  },
  {
    value: "Antarctica/Palmer",
    label: "Antarctica/Palmer",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "Antarctica/Rothera",
    label: "Antarctica/Rothera",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "Antarctica/Syowa",
    label: "Antarctica/Syowa",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Antarctica/Troll",
    label: "Antarctica/Troll",
    tz_short_name: "+00",
    tz_offset: "+0000",
  },
  {
    value: "Antarctica/Vostok",
    label: "Antarctica/Vostok",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Arctic/Longyearbyen",
    label: "Arctic/Longyearbyen",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Asia/Aden",
    label: "Asia/Aden",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Asia/Almaty",
    label: "Asia/Almaty",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Asia/Amman",
    label: "Asia/Amman",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Asia/Anadyr",
    label: "Asia/Anadyr",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Asia/Aqtau",
    label: "Asia/Aqtau",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Aqtobe",
    label: "Asia/Aqtobe",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Ashgabat",
    label: "Asia/Ashgabat",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Atyrau",
    label: "Asia/Atyrau",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Baghdad",
    label: "Asia/Baghdad",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Asia/Bahrain",
    label: "Asia/Bahrain",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Asia/Baku",
    label: "Asia/Baku",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Asia/Bangkok",
    label: "Asia/Bangkok",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Barnaul",
    label: "Asia/Barnaul",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Beirut",
    label: "Asia/Beirut",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Asia/Bishkek",
    label: "Asia/Bishkek",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Asia/Brunei",
    label: "Asia/Brunei",
    tz_short_name: "+08",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Chita",
    label: "Asia/Chita",
    tz_short_name: "+09",
    tz_offset: "+0900",
  },
  {
    value: "Asia/Choibalsan",
    label: "Asia/Choibalsan",
    tz_short_name: "+08",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Colombo",
    label: "Asia/Colombo",
    tz_short_name: "+0530",
    tz_offset: "+0530",
  },
  {
    value: "Asia/Damascus",
    label: "Asia/Damascus",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Asia/Dhaka",
    label: "Asia/Dhaka",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Asia/Dili",
    label: "Asia/Dili",
    tz_short_name: "+09",
    tz_offset: "+0900",
  },
  {
    value: "Asia/Dubai",
    label: "Asia/Dubai",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Asia/Dushanbe",
    label: "Asia/Dushanbe",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Famagusta",
    label: "Asia/Famagusta",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Asia/Gaza",
    label: "Asia/Gaza",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Asia/Hebron",
    label: "Asia/Hebron",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Asia/Ho_Chi_Minh",
    label: "Asia/Ho_Chi_Minh",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Hong_Kong",
    label: "Asia/Hong_Kong",
    tz_short_name: "HKT",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Hovd",
    label: "Asia/Hovd",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Irkutsk",
    label: "Asia/Irkutsk",
    tz_short_name: "+08",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Jakarta",
    label: "Asia/Jakarta",
    tz_short_name: "WIB",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Jayapura",
    label: "Asia/Jayapura",
    tz_short_name: "WIT",
    tz_offset: "+0900",
  },
  {
    value: "Asia/Jerusalem",
    label: "Asia/Jerusalem",
    tz_short_name: "IST",
    tz_offset: "+0200",
  },
  {
    value: "Asia/Kabul",
    label: "Asia/Kabul",
    tz_short_name: "+0430",
    tz_offset: "+0430",
  },
  {
    value: "Asia/Kamchatka",
    label: "Asia/Kamchatka",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Asia/Karachi",
    label: "Asia/Karachi",
    tz_short_name: "PKT",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Kathmandu",
    label: "Asia/Kathmandu",
    tz_short_name: "+0545",
    tz_offset: "+0545",
  },
  {
    value: "Asia/Khandyga",
    label: "Asia/Khandyga",
    tz_short_name: "+09",
    tz_offset: "+0900",
  },
  {
    value: "Asia/Kolkata",
    label: "Asia/Kolkata",
    tz_short_name: "IST",
    tz_offset: "+0530",
  },
  {
    value: "Asia/Krasnoyarsk",
    label: "Asia/Krasnoyarsk",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Kuala_Lumpur",
    label: "Asia/Kuala_Lumpur",
    tz_short_name: "+08",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Kuching",
    label: "Asia/Kuching",
    tz_short_name: "+08",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Kuwait",
    label: "Asia/Kuwait",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Asia/Macau",
    label: "Asia/Macau",
    tz_short_name: "CST",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Magadan",
    label: "Asia/Magadan",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Asia/Makassar",
    label: "Asia/Makassar",
    tz_short_name: "WITA",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Manila",
    label: "Asia/Manila",
    tz_short_name: "PST",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Muscat",
    label: "Asia/Muscat",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Asia/Nicosia",
    label: "Asia/Nicosia",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Asia/Novokuznetsk",
    label: "Asia/Novokuznetsk",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Novosibirsk",
    label: "Asia/Novosibirsk",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Omsk",
    label: "Asia/Omsk",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Asia/Oral",
    label: "Asia/Oral",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Phnom_Penh",
    label: "Asia/Phnom_Penh",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Pontianak",
    label: "Asia/Pontianak",
    tz_short_name: "WIB",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Pyongyang",
    label: "Asia/Pyongyang",
    tz_short_name: "KST",
    tz_offset: "+0900",
  },
  {
    value: "Asia/Qatar",
    label: "Asia/Qatar",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Asia/Qostanay",
    label: "Asia/Qostanay",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Asia/Qyzylorda",
    label: "Asia/Qyzylorda",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Riyadh",
    label: "Asia/Riyadh",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Asia/Sakhalin",
    label: "Asia/Sakhalin",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Asia/Samarkand",
    label: "Asia/Samarkand",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Seoul",
    label: "Asia/Seoul",
    tz_short_name: "KST",
    tz_offset: "+0900",
  },
  {
    value: "Asia/Shanghai",
    label: "Asia/Shanghai",
    tz_short_name: "CST",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Singapore",
    label: "Asia/Singapore",
    tz_short_name: "+08",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Srednekolymsk",
    label: "Asia/Srednekolymsk",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Asia/Taipei",
    label: "Asia/Taipei",
    tz_short_name: "CST",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Tashkent",
    label: "Asia/Tashkent",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Tbilisi",
    label: "Asia/Tbilisi",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Asia/Tehran",
    label: "Asia/Tehran",
    tz_short_name: "+0330",
    tz_offset: "+0330",
  },
  {
    value: "Asia/Thimphu",
    label: "Asia/Thimphu",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Asia/Tokyo",
    label: "Asia/Tokyo",
    tz_short_name: "JST",
    tz_offset: "+0900",
  },
  {
    value: "Asia/Tomsk",
    label: "Asia/Tomsk",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Ulaanbaatar",
    label: "Asia/Ulaanbaatar",
    tz_short_name: "+08",
    tz_offset: "+0800",
  },
  {
    value: "Asia/Urumqi",
    label: "Asia/Urumqi",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Asia/Ust-Nera",
    label: "Asia/Ust-Nera",
    tz_short_name: "+10",
    tz_offset: "+1000",
  },
  {
    value: "Asia/Vientiane",
    label: "Asia/Vientiane",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Asia/Vladivostok",
    label: "Asia/Vladivostok",
    tz_short_name: "+10",
    tz_offset: "+1000",
  },
  {
    value: "Asia/Yakutsk",
    label: "Asia/Yakutsk",
    tz_short_name: "+09",
    tz_offset: "+0900",
  },
  {
    value: "Asia/Yangon",
    label: "Asia/Yangon",
    tz_short_name: "+0630",
    tz_offset: "+0630",
  },
  {
    value: "Asia/Yekaterinburg",
    label: "Asia/Yekaterinburg",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Asia/Yerevan",
    label: "Asia/Yerevan",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Atlantic/Azores",
    label: "Atlantic/Azores",
    tz_short_name: "-01",
    tz_offset: "-0100",
  },
  {
    value: "Atlantic/Bermuda",
    label: "Atlantic/Bermuda",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "Atlantic/Canary",
    label: "Atlantic/Canary",
    tz_short_name: "WET",
    tz_offset: "+0000",
  },
  {
    value: "Atlantic/Cape_Verde",
    label: "Atlantic/Cape_Verde",
    tz_short_name: "-01",
    tz_offset: "-0100",
  },
  {
    value: "Atlantic/Faroe",
    label: "Atlantic/Faroe",
    tz_short_name: "WET",
    tz_offset: "+0000",
  },
  {
    value: "Atlantic/Madeira",
    label: "Atlantic/Madeira",
    tz_short_name: "WET",
    tz_offset: "+0000",
  },
  {
    value: "Atlantic/Reykjavik",
    label: "Atlantic/Reykjavik",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Atlantic/South_Georgia",
    label: "Atlantic/South_Georgia",
    tz_short_name: "-02",
    tz_offset: "-0200",
  },
  {
    value: "Atlantic/St_Helena",
    label: "Atlantic/St_Helena",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Atlantic/Stanley",
    label: "Atlantic/Stanley",
    tz_short_name: "-03",
    tz_offset: "-0300",
  },
  {
    value: "Australia/Adelaide",
    label: "Australia/Adelaide",
    tz_short_name: "ACDT",
    tz_offset: "+1030",
  },
  {
    value: "Australia/Brisbane",
    label: "Australia/Brisbane",
    tz_short_name: "AEST",
    tz_offset: "+1000",
  },
  {
    value: "Australia/Broken_Hill",
    label: "Australia/Broken_Hill",
    tz_short_name: "ACDT",
    tz_offset: "+1030",
  },
  {
    value: "Australia/Currie",
    label: "Australia/Currie",
    tz_short_name: "AEDT",
    tz_offset: "+1100",
  },
  {
    value: "Australia/Darwin",
    label: "Australia/Darwin",
    tz_short_name: "ACST",
    tz_offset: "+0930",
  },
  {
    value: "Australia/Eucla",
    label: "Australia/Eucla",
    tz_short_name: "+0845",
    tz_offset: "+0845",
  },
  {
    value: "Australia/Hobart",
    label: "Australia/Hobart",
    tz_short_name: "AEDT",
    tz_offset: "+1100",
  },
  {
    value: "Australia/Lindeman",
    label: "Australia/Lindeman",
    tz_short_name: "AEST",
    tz_offset: "+1000",
  },
  {
    value: "Australia/Lord_Howe",
    label: "Australia/Lord_Howe",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Australia/Melbourne",
    label: "Australia/Melbourne",
    tz_short_name: "AEDT",
    tz_offset: "+1100",
  },
  {
    value: "Australia/Perth",
    label: "Australia/Perth",
    tz_short_name: "AWST",
    tz_offset: "+0800",
  },
  {
    value: "Australia/Sydney",
    label: "Australia/Sydney",
    tz_short_name: "AEDT",
    tz_offset: "+1100",
  },
  {
    value: "Canada/Atlantic",
    label: "Canada/Atlantic",
    tz_short_name: "AST",
    tz_offset: "-0400",
  },
  {
    value: "Canada/Central",
    label: "Canada/Central",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "Canada/Eastern",
    label: "Canada/Eastern",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "Canada/Mountain",
    label: "Canada/Mountain",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "Canada/Newfoundland",
    label: "Canada/Newfoundland",
    tz_short_name: "NST",
    tz_offset: "-0330",
  },
  {
    value: "Canada/Pacific",
    label: "Canada/Pacific",
    tz_short_name: "PST",
    tz_offset: "-0800",
  },
  {
    value: "Europe/Amsterdam",
    label: "Europe/Amsterdam",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Andorra",
    label: "Europe/Andorra",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Astrakhan",
    label: "Europe/Astrakhan",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Europe/Athens",
    label: "Europe/Athens",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Belgrade",
    label: "Europe/Belgrade",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Berlin",
    label: "Europe/Berlin",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Bratislava",
    label: "Europe/Bratislava",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Brussels",
    label: "Europe/Brussels",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Bucharest",
    label: "Europe/Bucharest",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Budapest",
    label: "Europe/Budapest",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Busingen",
    label: "Europe/Busingen",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Chisinau",
    label: "Europe/Chisinau",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Copenhagen",
    label: "Europe/Copenhagen",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Dublin",
    label: "Europe/Dublin",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Europe/Gibraltar",
    label: "Europe/Gibraltar",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Guernsey",
    label: "Europe/Guernsey",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Europe/Helsinki",
    label: "Europe/Helsinki",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Isle_of_Man",
    label: "Europe/Isle_of_Man",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Europe/Istanbul",
    label: "Europe/Istanbul",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Europe/Jersey",
    label: "Europe/Jersey",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Europe/Kaliningrad",
    label: "Europe/Kaliningrad",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Kiev",
    label: "Europe/Kiev",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Kirov",
    label: "Europe/Kirov",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Europe/Lisbon",
    label: "Europe/Lisbon",
    tz_short_name: "WET",
    tz_offset: "+0000",
  },
  {
    value: "Europe/Ljubljana",
    label: "Europe/Ljubljana",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/London",
    label: "Europe/London",
    tz_short_name: "GMT",
    tz_offset: "+0000",
  },
  {
    value: "Europe/Luxembourg",
    label: "Europe/Luxembourg",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Madrid",
    label: "Europe/Madrid",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Malta",
    label: "Europe/Malta",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Mariehamn",
    label: "Europe/Mariehamn",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Minsk",
    label: "Europe/Minsk",
    tz_short_name: "+03",
    tz_offset: "+0300",
  },
  {
    value: "Europe/Monaco",
    label: "Europe/Monaco",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Moscow",
    label: "Europe/Moscow",
    tz_short_name: "MSK",
    tz_offset: "+0300",
  },
  {
    value: "Europe/Oslo",
    label: "Europe/Oslo",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Paris",
    label: "Europe/Paris",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Podgorica",
    label: "Europe/Podgorica",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Prague",
    label: "Europe/Prague",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Riga",
    label: "Europe/Riga",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Rome",
    label: "Europe/Rome",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Samara",
    label: "Europe/Samara",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Europe/San_Marino",
    label: "Europe/San_Marino",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Sarajevo",
    label: "Europe/Sarajevo",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Saratov",
    label: "Europe/Saratov",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Europe/Simferopol",
    label: "Europe/Simferopol",
    tz_short_name: "MSK",
    tz_offset: "+0300",
  },
  {
    value: "Europe/Skopje",
    label: "Europe/Skopje",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Sofia",
    label: "Europe/Sofia",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Stockholm",
    label: "Europe/Stockholm",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Tallinn",
    label: "Europe/Tallinn",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Tirane",
    label: "Europe/Tirane",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Ulyanovsk",
    label: "Europe/Ulyanovsk",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Europe/Uzhgorod",
    label: "Europe/Uzhgorod",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Vaduz",
    label: "Europe/Vaduz",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Vatican",
    label: "Europe/Vatican",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Vienna",
    label: "Europe/Vienna",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Vilnius",
    label: "Europe/Vilnius",
    tz_short_name: "EET",
    tz_offset: "+0200",
  },
  {
    value: "Europe/Volgograd",
    label: "Europe/Volgograd",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Europe/Warsaw",
    label: "Europe/Warsaw",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Zagreb",
    label: "Europe/Zagreb",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  {
    value: "Europe/Zaporozhye",
    label: "Europe/Zaporozhye",
    tz_short_name: "EET",

    tz_offset: "+0200",
  },
  {
    value: "Europe/Zurich",
    label: "Europe/Zurich",
    tz_short_name: "CET",
    tz_offset: "+0100",
  },
  { value: "GMT", label: "GMT", tz_short_name: "GMT", tz_offset: "+0000" },
  {
    value: "Indian/Antananarivo",
    label: "Indian/Antananarivo",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Indian/Chagos",
    label: "Indian/Chagos",
    tz_short_name: "+06",
    tz_offset: "+0600",
  },
  {
    value: "Indian/Christmas",
    label: "Indian/Christmas",
    tz_short_name: "+07",
    tz_offset: "+0700",
  },
  {
    value: "Indian/Cocos",
    label: "Indian/Cocos",
    tz_short_name: "+0630",
    tz_offset: "+0630",
  },
  {
    value: "Indian/Comoro",
    label: "Indian/Comoro",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Indian/Kerguelen",
    label: "Indian/Kerguelen",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Indian/Mahe",
    label: "Indian/Mahe",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Indian/Maldives",
    label: "Indian/Maldives",
    tz_short_name: "+05",
    tz_offset: "+0500",
  },
  {
    value: "Indian/Mauritius",
    label: "Indian/Mauritius",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Indian/Mayotte",
    label: "Indian/Mayotte",
    tz_short_name: "EAT",
    tz_offset: "+0300",
  },
  {
    value: "Indian/Reunion",
    label: "Indian/Reunion",
    tz_short_name: "+04",
    tz_offset: "+0400",
  },
  {
    value: "Pacific/Apia",
    label: "Pacific/Apia",
    tz_short_name: "+14",
    tz_offset: "+1400",
  },
  {
    value: "Pacific/Auckland",
    label: "Pacific/Auckland",
    tz_short_name: "NZDT",
    tz_offset: "+1300",
  },
  {
    value: "Pacific/Bougainville",
    label: "Pacific/Bougainville",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Pacific/Chatham",
    label: "Pacific/Chatham",
    tz_short_name: "+1345",
    tz_offset: "+1345",
  },
  {
    value: "Pacific/Chuuk",
    label: "Pacific/Chuuk",
    tz_short_name: "+10",
    tz_offset: "+1000",
  },
  {
    value: "Pacific/Easter",
    label: "Pacific/Easter",
    tz_short_name: "-05",
    tz_offset: "-0500",
  },
  {
    value: "Pacific/Efate",
    label: "Pacific/Efate",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Pacific/Enderbury",
    label: "Pacific/Enderbury",
    tz_short_name: "+13",
    tz_offset: "+1300",
  },
  {
    value: "Pacific/Fakaofo",
    label: "Pacific/Fakaofo",
    tz_short_name: "+13",
    tz_offset: "+1300",
  },
  {
    value: "Pacific/Fiji",
    label: "Pacific/Fiji",
    tz_short_name: "+13",
    tz_offset: "+1300",
  },
  {
    value: "Pacific/Funafuti",
    label: "Pacific/Funafuti",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Pacific/Galapagos",
    label: "Pacific/Galapagos",
    tz_short_name: "-06",
    tz_offset: "-0600",
  },
  {
    value: "Pacific/Gambier",
    label: "Pacific/Gambier",
    tz_short_name: "-09",
    tz_offset: "-0900",
  },
  {
    value: "Pacific/Guadalcanal",
    label: "Pacific/Guadalcanal",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Pacific/Guam",
    label: "Pacific/Guam",
    tz_short_name: "ChST",
    tz_offset: "+1000",
  },
  {
    value: "Pacific/Honolulu",
    label: "Pacific/Honolulu",
    tz_short_name: "HST",
    tz_offset: "-1000",
  },
  {
    value: "Pacific/Kiritimati",
    label: "Pacific/Kiritimati",
    tz_short_name: "+14",
    tz_offset: "+1400",
  },
  {
    value: "Pacific/Kosrae",
    label: "Pacific/Kosrae",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Pacific/Kwajalein",
    label: "Pacific/Kwajalein",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Pacific/Majuro",
    label: "Pacific/Majuro",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Pacific/Marquesas",
    label: "Pacific/Marquesas",
    tz_short_name: "-0930",
    tz_offset: "-0930",
  },
  {
    value: "Pacific/Midway",
    label: "Pacific/Midway",
    tz_short_name: "SST",
    tz_offset: "-1100",
  },
  {
    value: "Pacific/Nauru",
    label: "Pacific/Nauru",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Pacific/Niue",
    label: "Pacific/Niue",
    tz_short_name: "-11",
    tz_offset: "-1100",
  },
  {
    value: "Pacific/Norfolk",
    label: "Pacific/Norfolk",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Pacific/Noumea",
    label: "Pacific/Noumea",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Pacific/Pago_Pago",
    label: "Pacific/Pago_Pago",
    tz_short_name: "SST",
    tz_offset: "-1100",
  },
  {
    value: "Pacific/Palau",
    label: "Pacific/Palau",
    tz_short_name: "+09",
    tz_offset: "+0900",
  },
  {
    value: "Pacific/Pitcairn",
    label: "Pacific/Pitcairn",
    tz_short_name: "-08",
    tz_offset: "-0800",
  },
  {
    value: "Pacific/Pohnpei",
    label: "Pacific/Pohnpei",
    tz_short_name: "+11",
    tz_offset: "+1100",
  },
  {
    value: "Pacific/Port_Moresby",
    label: "Pacific/Port_Moresby",
    tz_short_name: "+10",
    tz_offset: "+1000",
  },
  {
    value: "Pacific/Rarotonga",
    label: "Pacific/Rarotonga",
    tz_short_name: "-10",
    tz_offset: "-1000",
  },
  {
    value: "Pacific/Saipan",
    label: "Pacific/Saipan",
    tz_short_name: "ChST",
    tz_offset: "+1000",
  },
  {
    value: "Pacific/Tahiti",
    label: "Pacific/Tahiti",
    tz_short_name: "-10",
    tz_offset: "-1000",
  },
  {
    value: "Pacific/Tarawa",
    label: "Pacific/Tarawa",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Pacific/Tongatapu",
    label: "Pacific/Tongatapu",
    tz_short_name: "+13",
    tz_offset: "+1300",
  },
  {
    value: "Pacific/Wake",
    label: "Pacific/Wake",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "Pacific/Wallis",
    label: "Pacific/Wallis",
    tz_short_name: "+12",
    tz_offset: "+1200",
  },
  {
    value: "US/Alaska",
    label: "US/Alaska",
    tz_short_name: "AKST",
    tz_offset: "-0900",
  },
  {
    value: "US/Arizona",
    label: "US/Arizona",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "US/Central",
    label: "US/Central",
    tz_short_name: "CST",
    tz_offset: "-0600",
  },
  {
    value: "US/Eastern",
    label: "US/Eastern",
    tz_short_name: "EST",
    tz_offset: "-0500",
  },
  {
    value: "US/Hawaii",
    label: "US/Hawaii",
    tz_short_name: "HST",
    tz_offset: "-1000",
  },
  {
    value: "US/Mountain",
    label: "US/Mountain",
    tz_short_name: "MST",
    tz_offset: "-0700",
  },
  {
    value: "US/Pacific",
    label: "US/Pacific",
    tz_short_name: "PST",
    tz_offset: "-0800",
  },
  { value: "UTC", label: "UTC", tz_short_name: "UTC", tz_offset: "+0000" },
];

export const WA_TIERS = {
  TIER_1: { label: "T1: 1k opened convos/day", maxConvos: "1k" },
  TIER_2: { label: "T2: 10k opened convos/day", maxConvos: "10k" },
  TIER_3: { label: "T3: 100k opened convos/day", maxConvos: "100k" },
  TIER_4: { label: "T4: Unlimited convos/day", maxConvos: "∞" },
};
