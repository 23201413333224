import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  InformationCircleIcon,
  PlayIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { DateTime } from "luxon";
import {
  FlowExecutionContactListSimple,
  FlowExecutionContactStatusEnum,
} from "@hilos/types/private-schema";
import DropdownMenu from "src/components/DropdownMenu";
import Table from "src/components/Table/Table";
import { classNames, formatPhone, userToString } from "../../../Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../../router/router";
import FlowExecutionContactStatusBadge from "./FlowExecutionContactStatusBadge";

interface FlowExecutionContactListProps {
  flowId?: string;
  flowExecutionId?: string;
  flowVersionId?: string;
}
export default function FlowExecutionContactList({
  flowId,
  flowExecutionId,
  flowVersionId,
}: FlowExecutionContactListProps) {
  const { t } = useTranslation();
  const params = { flow: flowId };
  if (flowExecutionId) {
    params["flow_execution"] = flowExecutionId;
  }
  if (flowVersionId) {
    params["flow_version"] = flowVersionId;
  }

  return (
    <div className="py-6 sm:px-6">
      <Table
        baseURL={buildAPIRoute(API_ROUTES.FLOW_EXECUTION_CONTACT_LIST)}
        initialSearchParams={params}
        paginated={true}
        tableHead={
          <thead>
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              ></th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("phone", "Phone")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("name", "Name")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("status", "Status")}
              </th>
            </tr>
          </thead>
        }
        tableRow={(contactExecution: FlowExecutionContactListSimple) => (
          <>
            <td className="whitespace-nowrap py-3 pl-4 text-sm text-gray-900">
              <div className="h-8 w-8 rounded-full flex items-center justify-center bg-gray-100">
                <UserIcon
                  className=" h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-900">
              <Link
                className="font-medium text-blue-600"
                to={buildRoute("flow-execution-contact-detail", {
                  ":id": contactExecution.id,
                })}
              >
                {formatPhone(contactExecution.contact.phone, true, true)}
              </Link>

              <p className="text-sm text-gray-500">
                {t("started-on", "Started on")}{" "}
                {DateTime.fromISO(
                  contactExecution.created_on as string
                ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              </p>
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              <div>{userToString(contactExecution.contact)}</div>
              {contactExecution.is_test && (
                <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                  <InformationCircleIcon className="h-4 w-4 mr-1" />
                  {t("test-execution", "Test execution")}
                </span>
              )}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              <FlowExecutionContactStatusBadge
                status={
                  contactExecution.status as FlowExecutionContactStatusEnum
                }
              />
              {["CANCELED", "FAILED", "READY"].includes(
                contactExecution.status as string
              ) && (
                <p className="mt-1 text-xs ">
                  {t("reason", "Reason")}:{" "}
                  <span className="text-red-500">
                    {contactExecution.status_reason}
                  </span>
                </p>
              )}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-900">
              <DropdownMenu
                width="50"
                menuItems={[
                  (active) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "flex w-full items-center px-4 py-2 text-sm"
                      )}
                      type="button"
                      to={`/contact/${contactExecution.contact.id}/update`}
                    >
                      <UserIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t("go-to-contact", "Go to contact")}
                    </Link>
                  ),
                ]}
              />
            </td>
          </>
        )}
        dataName={t("contact", "Contact")}
        noResults={
          <div className="my-4">
            <Trans i18nKey="flow-executions:no-contact-executions-yet">
              <h4>Couldn't find any contact executions for this flow run.</h4>
              <p className="text-sm text-gray-500">
                Remove any filters to try again.
              </p>
            </Trans>
          </div>
        }
        createObject={
          <div className="my-4">
            <Trans i18nKey="flow-executions:no-contact-executions-yet">
              <h4>Couldn't find any contact executions for this flow run.</h4>
              <p className="text-sm text-gray-500">
                Remove any filters to try again.
              </p>
            </Trans>
          </div>
        }
      ></Table>
    </div>
  );
}
