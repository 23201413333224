import { useTranslation } from "react-i18next";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPrice } from "src/Helpers";

interface SubscriptionV2SelectPlanEnterpriseProps {
  currency: string;
  onSelectPlan: (plan: string) => void;
}

export default function SubscriptionV2SelectPlanEnterprise({
  currency,
  onSelectPlan,
}: SubscriptionV2SelectPlanEnterpriseProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();

  if (!session?.account.plan_price) {
    return null;
  }

  const INCLUDES = [
    {
      name: t("settings:billing.v2.dimensions.users.title", "Users"),
      included: session.account.users_price.included_items,
      currency_options: session.account.users_price.currency_options,
    },
    {
      name: t(
        "settings:billing.v2.dimensions.cams.title",
        "Monthly Active Contacts"
      ),
      description: t(
        "settings:billing.v2.dimensions.cams.description",
        "(Contacts you message on the current month)"
      ),
      included: session.account.macs_price.included_items,
      currency_options: session.account.macs_price.currency_options,
    },
    {
      name: t(
        "settings:billing.v2.dimensions.channels.title",
        "Connected WA numbers"
      ),
      included: session.account.channels_price.included_items,
      currency_options: session.account.channels_price.currency_options,
    },
  ];

  return (
    <div className="bg-white py-6 sm:py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl lg:px-8">
        <div className="flex items-center">
          <h4 className="flex-shrink-0 pr-4 text-xl font-semibold text-hilos">
            {t(
              "settings:billing.v2.enterprise-title",
              "Your Hilos custom plan"
            )}{" "}
            -{" "}
            <span className="text-gray-900">
              {formatPrice(
                session.account.plan_price.unit_amount &&
                  (session.account.plan_price.unit_amount || 0) / 100
              )}
            </span>
            <span className="ml-1 text-sm text-gray-500">
              {session.account.plan_price.currency?.toLocaleUpperCase()}
              {t("per-month")}
            </span>
          </h4>
          <div className="flex-1 border-t-2 border-gray-200" />
        </div>

        <div>
          <div className="mt-2 text-xs text-gray-600 grow">
            {t(
              "settings:billing.v2.whatsapp-fees",
              "WhatsApp additionally charges for using their API. Check their fees"
            )}{" "}
            <a
              href="https://hilos.io/pricing#whatsapp-api"
              target="_blank"
              className="text-blue-600 font-medium"
              rel="noreferrer"
            >
              {t("here", "here")}
            </a>
            .
          </div>
        </div>

        <div className="mt-4 space-y-2 flex items-start justify-between flex-col sm:flex-row space-x-6">
          <div className="grow">
            <p className="text-sm font-semibold leading-7 text-gray-900">
              {t(
                "settings:billing.v2.enterprise-description",
                "Plan description"
              )}
            </p>

            <p className="whitespace-pre-line text-sm text-gray-700">
              {session.account.custom_plan_description}
            </p>

            <p className="text-sm font-semibold leading-7 text-gray-900 mt-6">
              {t(
                "settings:billing.v2.sections.includes.title",
                "What's included?"
              )}
            </p>

            <table className="sm:w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="pb-3.5 pl-2 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0"
                  ></th>
                  <th
                    scope="col"
                    className="w-24 pb-3.5 pr-2 text-right text-sm font-medium text-gray-900 sm:pr-6 md:pr-0"
                  >
                    {t("included", "Included")}
                  </th>
                  <th
                    scope="col"
                    className="w-24 sm:w-48 pb-3.5 pr-2 text-right text-sm font-medium text-gray-900 sm:pr-6 md:pr-0"
                  >
                    {t("additional", "Additional")} (
                    <span className="text-sm text-gray-500">
                      {t("unit", "unit")}
                      {t("per-month")}
                    </span>
                    )
                  </th>
                </tr>
              </thead>
              <tbody>
                {INCLUDES.map((dimension, idx) => (
                  <tr key={idx} className="border-b border-gray-200">
                    <td className="py-2 text-sm text-gray-700 sm:pr-6 md:pr-0">
                      {dimension.name}
                      {dimension.description && (
                        <div className="text-gray-500 text-xs">
                          {dimension.description}
                        </div>
                      )}
                    </td>
                    <td className="whitespace-nowrap pl-2 py-2 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                      {dimension.included?.toLocaleString(
                        session.default_language || "en"
                      )}
                    </td>

                    <td className="whitespace-nowrap pl-2 py-2 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                      {formatPrice(
                        (dimension.currency_options[
                          session.account.payment_currency.toLocaleLowerCase()
                        ].unit_amount || 0) / 100
                      )}{" "}
                      <span className="text-xs text-gray-400">
                        {session.account.plan_price.currency?.toLocaleUpperCase()}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="text-center w-full lg:flex lg:w-80 lg:flex-shrink-0 lg:flex-col lg:justify-center space-y-2">
            <button
              type="button"
              className="bg-gradient-to-r from-hilos to-red-400 text-white hover:bg-hilos w-full block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hilos mt-2"
              onClick={() => onSelectPlan("ENTERPRISE")}
            >
              {t("settings:billing.v2.select-plan", "Select plan")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
