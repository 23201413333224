import { PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import { FieldArray, FormikProps } from "formik";
import TextInputField from "./TextInputField";

function getNestedVariable(
  variable: string,
  variables: Record<string, unknown>
): Record<string, unknown> | string | unknown {
  // This function just helps us to access an object via a string with dot notation
  // Example:
  //   let variable = "flow.some_step_name";
  //   let variables = {flow: {some_step_name: {some_other_value: true}}};
  //   getNestedVariable(variable, variables);
  //   > {some_other_value: true}

  let variableNestedRoute = variable.split(".");
  let nestedVariables = variables;

  for (let nestedVariable of variableNestedRoute) {
    // @ts-expect-error Type '{}' is not assignable to type 'Record<string, unknown>'.
    nestedVariables = nestedVariables[nestedVariable] || {};
  }
  return nestedVariables;
}

interface ArrayInputFieldProps<T> {
  formik: FormikProps<T>;
  name: string;
  type?: string;
  label?: string;
  addButtonLabel: string;
  help?: string | React.ReactElement;
  optional?: boolean;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  action?: React.ReactElement;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
}

function ArrayInputField<T>({
  formik,
  name,
  label,
  addButtonLabel,
  ...props
}: ArrayInputFieldProps<T>) {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <h6 className="text-sm font-medium text-gray-700">{label}</h6>
          <ul className="mt-2">
            {formik.values[name]?.map((value, idx) => (
              <li key={idx} className="mt-2 border-t border-gray-200 pt-2">
                <div className="flex items-center">
                  <div className="w-full">
                    <TextInputField name={`${name}.${idx}`} {...props} />
                  </div>
                  <div className="ml-4 grow-0">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={(_) => arrayHelpers.remove(idx)}
                    >
                      <TrashIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                {typeof getNestedVariable(`${name}.${idx}`, formik.errors) ===
                  "string" && (
                  <div className="mt-1.5 text-xs text-red-500">
                    {
                      getNestedVariable(
                        `${name}.${idx}`,
                        formik.errors
                      ) as string
                    }
                  </div>
                )}
              </li>
            ))}
          </ul>
          {
            typeof getNestedVariable(name, formik.errors) === "string" && (
              <div className="mt-1.5 text-xs text-red-500">
                {getNestedVariable(name, formik.errors) as string}
              </div>
            )
          }

          <div className="mt-4">
            <button
              type="button"
              className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
              onClick={(_) => arrayHelpers.push("")}
            >
              <PlusSmIcon
                className="mr-2 h-5 w-5"
                aria-hidden="true"
              />
              {addButtonLabel}
            </button>
          </div>
        </>
      )}
    />
  );
}

export default ArrayInputField;
