import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/outline";

interface AdvancedOptionDividerProps {
  title: React.ReactNode;
  keyVal: string;
  children: React.ReactNode;
  buttons?: React.ReactNode;
  handleRemove?: () => void;
  className?: string;
}

export default function AdvancedOptionDivider({
  title,
  keyVal,
  children,
  buttons,
  handleRemove,
  className = "m-2",
}: AdvancedOptionDividerProps) {
  const { t } = useTranslation();
  const availableButtons = useMemo(() => {
    if (buttons) {
      return buttons;
    }
    if (handleRemove) {
      return (
        <button
          type="button"
          title={t("trash", "Trash")}
          className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          onClick={(_) => handleRemove()}
        >
          <TrashIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      );
    }
  }, [buttons, handleRemove, t]);
  return (
    <div key={keyVal} className="my-6 border-l-4 border-l-neutral">
      <div className="flex">
        <div className="w-fit rounded-r-md bg-neutral-extralight py-2 px-5 text-sm font-bold  text-neutral">
          {title}
        </div>
        <div className="ml-auto grow-0 flex items-center">
          {availableButtons}
        </div>
      </div>
      <div className={className}>{children}</div>
    </div>
  );
}
