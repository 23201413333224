import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { DateTime } from "luxon";
import {
  FlowExecutionContactReadDetail,
  FlowExecutionStepReadDetail,
  FlowStepEdit,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import { STEP_TYPES } from "../FlowStepMeta";

// import useWrappedSteps from "src/hooks/useWrappedSteps";

interface FlowExecutionContactStepsDetailProps {
  flowExecutionContact: FlowExecutionContactReadDetail;
  selectExecutionStep: (x: FlowExecutionStepReadDetail) => void;
  flowVersion: FlowVersionDetailRead;
}

export default function FlowExecutionContactStepsDetail({
  flowExecutionContact,
  selectExecutionStep,
  flowVersion,
}: FlowExecutionContactStepsDetailProps) {
  const { t } = useTranslation();
  // const { STEP_TYPES_LIST } = useWrappedSteps();

  const [selectedExecutionsSteps, setSelectedExecutionsSteps] = useState<
    FlowExecutionStepReadDetail[]
  >([]);
  const [selectedStep, setSelectedStep] = useState<FlowStepEdit | undefined>(
    undefined
  );

  const getExecutedSteps = (step) => {
    const executedSteps = flowExecutionContact.execution_steps.filter(
      (s) => s.step.id === step.id
    );
    return executedSteps;
  };

  const selectStep = (step) => {
    setSelectedStep(step);
    setSelectedExecutionsSteps(getExecutedSteps(step));
  };

  const getStepColor = (step) => {
    const executedSteps = getExecutedSteps(step);

    if (!executedSteps.length) {
      return "bg-gray-200 text-gray-700";
    }

    if (executedSteps.find((step) => step.has_error)) {
      return "bg-red-200 text-red-700";
    }
    return "bg-green-200 text-green-700";
  };

  const stepInfo = useCallback((step: FlowStepEdit) => {
    let step_type = step.step_type;
    if (step_type.includes("WRAPPED")) {
      // For WrappedActions lets reuse the Action info
      step_type = "ACTION";
    }
    return STEP_TYPES.find((st) => st.value === step_type);
  }, []);

  return (
    <div className="p-6 grid grid-cols-1 grid-rows-2 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6">
      <div className="flow-root">
        <ul className="mt-4 -mb-8">
          {flowVersion.steps.map((step, idx) => (
            <li key={idx}>
              <div className="relative pb-8">
                {idx !== flowVersion.steps.length - 1 ? (
                  <span
                    className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={`flex h-10 w-10 items-center justify-center rounded-full ring-8 ring-gray-50 ${getStepColor(
                        step
                      )}`}
                    >
                      {stepInfo(step)?.icon}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4">
                    <div className="self-center">
                      <div className="text-sm text-gray-500">
                        <button
                          type="button"
                          onClick={(_) => selectStep(step)}
                          className="text-left font-medium text-blue-600"
                        >
                          <div>
                            {t("step-title", "Step")} {step.name}
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="self-center">
                      <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
                        {step.step_type}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="sm:col-span-2">
        {selectedStep && (
          <div className="overflow-x-scroll rounded-md border border-gray-200  bg-white px-4 py-5 shadow-sm sm:px-6">
            <div className="flex items-start gap-6">
              <div className="grow-0 self-center">
                {stepInfo(selectedStep)?.icon}
              </div>

              <div>
                <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                  {t("flow-executions:step-details.title", "Step details")}
                </h3>
                <p className="text-indigo-600">{selectedStep.name}</p>
                <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
                  {selectedStep.step_type}
                </span>
              </div>

              <div>
                <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                  {selectedStep.step_type} {t("detail-title", "Detail")}
                </h3>
                {selectedStep.step_type === "MESSAGE" && (
                  <>
                    <p>
                      <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                        {selectedStep.body_type as string}
                      </span>
                    </p>
                    <p className="text-sm text-gray-500">
                      <code>{selectedStep.body}</code>
                    </p>
                  </>
                )}
                {selectedStep.step_type === "QUESTION" && (
                  <>
                    <p>
                      <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                        {selectedStep.answer_type as string}
                      </span>
                    </p>
                    {selectedStep.answer_type === "PHONE" &&
                      selectedStep.answer_phone_default_country && (
                        <p className="mt-1 text-sm text-gray-500">
                          {t(
                            "flow-executions:steps-detail.question.default-country-code",
                            "Default country code"
                          )}
                          :{" "}
                          {selectedStep.answer_phone_default_country as string}
                        </p>
                      )}
                    {["SINGLE_OPTION", "BOOL"].includes(
                      selectedStep.answer_type as string
                    ) && (
                      <p className="mt-1 text-sm text-gray-500">
                        {t(
                          "flow-executions:steps-detail.question.available-options",
                          "Available options"
                        )}
                        :{" "}
                        <strong className="font-medium text-gray-900">
                          {selectedStep.answer_options?.join(", ")}
                        </strong>
                      </p>
                    )}
                    <p className="mt-1 text-sm text-gray-500">
                      {t(
                        "flow-executions:steps-detail.question.validation-message",
                        "Validation message"
                      )}
                      :
                      <br />
                      <code className="text-indigo-500">
                        {selectedStep.answer_validation_message}
                      </code>
                    </p>
                  </>
                )}
                {selectedStep.step_type === "CONDITIONAL" && (
                  <>
                    <p className="mb-0">
                      {t(
                        "flow-executions:steps-detail.conditional.conditions",
                        "Conditions"
                      )}
                      :
                    </p>
                    {selectedStep.conditions.map((condition, cIdx) => (
                      <p className="mb-0" key={cIdx}>
                        {condition.field} {condition.comparison}{" "}
                        {condition.value}
                      </p>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div>
              <h3 className="mt-4 text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("executions-title", "Executions")}
              </h3>
              {selectedExecutionsSteps.length > 0 ? (
                <>
                  {selectedExecutionsSteps.map((execution_step, idx) => (
                    <button
                      type="button"
                      onClick={(_) => selectExecutionStep(execution_step)}
                      className="w-full text-left text-gray-500"
                    >
                      <div className="mt-2 flex items-center rounded-md border border-gray-200 p-4 text-sm">
                        {t(
                          "flow-executions:steps-detail.executions.executed-on",
                          "Executed on"
                        )}
                        :
                        <time
                          dateTime=""
                          className="ml-2 font-bold text-gray-700"
                        >
                          {DateTime.fromISO(
                            execution_step.created_on as string
                          ).toLocaleString(
                            DateTime.DATETIME_SHORT_WITH_SECONDS
                          )}
                        </time>
                        <ChevronRightIcon
                          className="ml-auto h-6 w-6 pr-2 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </button>
                  ))}
                </>
              ) : (
                <div className="mt-2 text-sm text-gray-500">
                  {t(
                    "flow-executions:steps-detail.executions.no-results",
                    "There's no executions for this step."
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
