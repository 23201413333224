import { useTranslation } from "react-i18next";
import { Dialog } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { Channel } from "@hilos/types/private-schema";

interface TechProviderOnboardingSuccessProps {
  channel?: Channel;
}

export default function TechProviderOnboardingSuccess({
  channel,
}: TechProviderOnboardingSuccessProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title
          as="h3"
          className="text-base font-semibold leading-6 text-gray-900"
        >
          {t("channel:tech-provider.success-title", "Success!")}
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {channel ? (
              <>
                {t(
                  "channel:tech-provider.success-description",
                  "The number {{number}} was connected succesfully.",
                  {
                    number: channel.channel_id,
                  }
                )}
              </>
            ) : (
              <>
                {t(
                  "channel:tech-provider.success-description",
                  "Your number was connected succesfully."
                )}
              </>
            )}
          </p>
          <p className="text-sm text-gray-500">
            {t(
              "channel:tech-provider.success-redirect",
              "Redirecting you to the channel details..."
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
