import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { useField, useFormikContext } from "formik";
import useTouchField from "src/containers/flow/builder/hooks/useTouchField";
import HelpDocsLink from "src/components/HelpDocsLink";
import { classNames } from "src/Helpers";

interface SwitchFieldProps {
  name: string;
  label?: string | React.ReactElement;
  help?: string | JSX.Element;
  optional?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  helpDocsLink?: string;
}

function SwitchField({
  name,
  label,
  help,
  optional,
  disabled = false,
  onChange,
  helpDocsLink,
}: SwitchFieldProps) {
  const [field, meta, helpers] = useField({ name });
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { t } = useTranslation();
  useTouchField(helpers);

  const handleChange = (value: boolean) => {
    setFieldValue(name, value);
    setFieldTouched(name, true);
    onChange && onChange(value);
  };

  const cursorClasses = useMemo(
    () => (disabled ? "cursor-not-allowed" : "cursor-pointer"),
    [disabled]
  );

  return (
    <Switch.Group
      as="div"
      className={classNames(cursorClasses, "flex items-center")}
    >
      <Switch
        disabled={disabled}
        checked={field.value}
        onChange={handleChange}
        className={classNames(
          field.value
            ? disabled
              ? "bg-indigo-300"
              : "bg-indigo-600"
            : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
          cursorClasses
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            field.value ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      <Switch.Label as="div" className="ml-2 flex-1">
        <div className="flex flex-row justify-between">
          <span className="text-sm text-gray-900">
            {label}{" "}
            {optional && (
              <>
                <span className="text-xs text-gray-500">(optional)</span>
              </>
            )}
          </span>
          {helpDocsLink && (
            <HelpDocsLink href={helpDocsLink} className="text-xs" />
          )}
        </div>
        {help && <div className="mt-1 text-xs text-gray-500">{help}</div>}
      </Switch.Label>
    </Switch.Group>
  );
}

export default SwitchField;
