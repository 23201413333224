import { FlowExecutionStepReadDetail } from "@hilos/types/private-schema";
import HTTPResponseRender from "src/components/HTTPResponseRender/HTTPResponseRender";

interface FlowExecutionStepActionDetailProps {
  executionStep: FlowExecutionStepReadDetail;
}

export default function FlowExecutionStepActionDetail({
  executionStep,
}: FlowExecutionStepActionDetailProps) {
  const response = {
    ...executionStep.request_data,
    ...executionStep.execution_result,
  };

  return (
    <>
      {/* @ts-ignore */}
      <HTTPResponseRender response={response} />
    </>
  );
}
