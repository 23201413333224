import { useTranslation } from "react-i18next";
import { FlowExecutionContactStatusEnum } from "@hilos/types/private-schema";

interface FlowExecutionContactStatusBadgeProps {
  status: FlowExecutionContactStatusEnum;
}

function FlowExecutionContactStatusBadge({
  status,
}: FlowExecutionContactStatusBadgeProps) {
  const { t } = useTranslation();
  switch (status) {
    case "CANCELED":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-700">
          {t("flows:status.canceled", "Canceled")}
        </span>
      );
    case "EXPIRED":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-700">
          {t("flows:status.expired", "Expired")}
        </span>
      );
    case "FAILED":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-700">
          {t("flows:status.failed", "Failed")}
        </span>
      );
    case "READY":
      return (
        <span className="inline-flex items-center rounded-full bg-teal-100 px-3 py-0.5 text-xs font-medium uppercase text-teal-800">
          {t("flows:status.ready", "Ready")}
        </span>
      );
    case "RUNNING":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
          {t("flows:status.running", "Running")}
        </span>
      );
    case "COMPLETED":
      return (
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium uppercase text-indigo-800">
          {t("flows:status.completed", "Completed")}
        </span>
      );
    default:
      return (
        <span className="inline-flex uppercase items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800">
          {status}
        </span>
      );
  }
}

export default FlowExecutionContactStatusBadge;
