import { DateRangePicker, DateRangePickerItem } from "@tremor/react";
import { Dialog, Switch } from "@headlessui/react";
import { useCallback, useState } from "react";

import AccountMemberEmailFilter from "src/components/Form/AccountMemberEmailFilter";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import Button from "src/components/Button";
import { CalculateQueryParamsParams } from "./FiltersMeta";
import ContactTagFilter from "src/components/Form/ContactTagFilter";
import ConversationTagFilter from "src/components/Form/ConversationTagFilter";
import { XIcon } from "@heroicons/react/solid";
import { classNames } from "src/Helpers";
import { startOfMonth } from "date-fns";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";
import { usePostHog } from "posthog-js/react";
import { useTranslation } from "react-i18next";

interface ReportFiltersProps {
  params: CalculateQueryParamsParams;
  setParams: (p: CalculateQueryParamsParams) => void;
  setShow: (p: boolean) => void;
}

export default function ReportFilters({
  params,
  setParams,
  setShow,
}: ReportFiltersProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { currentLocale } = useDateFnsFormatLocale();

  const [nextParams, setNextParams] = useState<CalculateQueryParamsParams>({
    ...params,
  });

  const setValue = useCallback(
    (field, val) => {
      const nextP = { ...nextParams };
      nextP[field] = val;
      setNextParams(nextP);
    },
    [nextParams]
  );

  const applyFilters = useCallback(() => {
    if (!nextParams.dateRange.from) {
      nextParams.dateRange.from = startOfMonth(new Date());
    }
    if (!nextParams.dateRange.to) {
      nextParams.dateRange.to = new Date();
    }
    setParams(nextParams);
    posthog?.capture("inbox_usage filters applied", nextParams);
    setShow(false);
  }, [posthog, setParams, nextParams, setShow]);

  return (
    <div className="flex h-full flex-col justify-start overflow-hidden">
      <div className="w-full space-y-2 py-3">
        <div className="flex items-center justify-between px-4 sm:px-6 py-3 sm:py-5">
          <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
            {t("reports:filters.title", "Choose filters")}
          </Dialog.Title>
          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="rounded-md text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-100"
              onClick={() => setShow(false)}
            >
              <span className="sr-only">{t("close-panel", "Close panel")}</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div className="px-4 sm:px-6 space-y-4">
        <div>
          <DateRangePicker
            className="max-w-md mx-auto"
            value={nextParams.dateRange}
            onValueChange={(val) => setValue("dateRange", val)}
            locale={currentLocale}
            color="rose"
            selectPlaceholder={t("reports:select", "Select...")}
          >
            <DateRangePickerItem key="today" value="today" from={new Date()}>
              {t("reports:date-range.today", "Today")}
            </DateRangePickerItem>
            <DateRangePickerItem
              key="last7days"
              value="last7days"
              from={new Date(new Date().setDate(new Date().getDate() - 7))}
            >
              {t("reports:date-range.last-7-days", "Last 7 days")}
            </DateRangePickerItem>
            <DateRangePickerItem
              key="last30days"
              value="last30days"
              from={new Date(new Date().setDate(new Date().getDate() - 30))}
            >
              {t("reports:date-range.last-30-days", "Last 30 days")}
            </DateRangePickerItem>
            <DateRangePickerItem
              key="monthToDate"
              value="monthToDate"
              from={startOfMonth(new Date())}
            >
              {t("reports:date-range.month-to-date", "Month to Date")}
            </DateRangePickerItem>
            <DateRangePickerItem
              key="yearToDate"
              value="yearToDate"
              from={new Date(new Date().getFullYear(), 0, 1)}
            >
              {t("reports:date-range.year-to-date", "Year to Date")}
            </DateRangePickerItem>
          </DateRangePicker>
        </div>
        <div className="my-2">
          <ContactTagFilter
            value={nextParams.contactTags}
            onValueChange={(val) => setValue("contactTags", val)}
          />
        </div>
        <div className="my-2">
          <ConversationTagFilter
            value={nextParams.conversationTags}
            onValueChange={(val) => setValue("conversationTags", val)}
          />
        </div>
        <div className="my-2">
          <AccountMemberEmailFilter
            value={nextParams.emails}
            onValueChange={(val) => setValue("emails", val)}
          />
        </div>
        <div>
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={nextParams.workingHours}
              onChange={(val) => setValue("workingHours", val)}
              className={classNames(
                nextParams.workingHours ? "bg-indigo-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">
                {t("reports:filters.working-hours", "Working hours")}?
              </span>
              <span
                aria-hidden="true"
                className={classNames(
                  nextParams.workingHours ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="div" className="ml-2 flex-1">
              <div className="flex flex-row justify-between">
                <span className="text-sm text-gray-900">
                  {t("reports:filters.working-hours", "Working hours")}?
                </span>
              </div>
            </Switch.Label>
          </Switch.Group>
        </div>
        <div className="mt-10 text-right">
          <Button
            type="button"
            onClick={applyFilters}
            icon={<ArrowSmRightIcon className="ml-1 -mr-1 h-5 w-5" />}
          >
            {t("reports:filters.apply-button", "Apply filters")}
          </Button>
        </div>
      </div>
    </div>
  );
}
