import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowSmRightIcon, CodeIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { axiosErr } from "@hilos/types/axios";
import TextInputField from "src/components/Form/TextInputField";
import APIErrors from "src/components/base/APIErrors";
import { ERROR_MESSAGES } from "src/constants/errors";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import useHilosStore from "../../hooks/useHilosStore";

export default function AccountSelectionCodeModal({ show, setShow }) {
  const { t } = useTranslation();
  const { session, reloadSession } = useHilosStore();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const handleRequest = async (values) => {
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.PUBLIC_USER_ACCEPT_INVITATION),
        { code: values.code }
      );
      await reloadSession();
      // navigate(buildRoute("home"));
    } catch (error) {
      const axiosErr = error as axiosErr;
      if (
        axiosErr?.response &&
        axiosErr.response.status === 400 &&
        axiosErr.response.data
      ) {
        setBackendValidationErrors(axiosErr.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
    }
  };

  const schema = yup.object().shape({
    code: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
  });

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 space-y-6">
                  <div className="flex text-gray-800 items-center font-bold">
                    <div className="h-10 w-10 rounded-full flex items-center justify-center text-blue-500 mr-4 bg-blue-100">
                      <CodeIcon className="h-6 w-6 -scale-x-100" />
                    </div>
                    <div>
                      <div className="text-lg">
                        {t(
                          "login:sign-up.invitation-request-code.title",
                          "Input the code to confirm your email"
                        )}
                      </div>
                      <div className="text-sm text-gray-500 font-normal">
                        {t(
                          "login:sign-up.invitation-request-code.subtitle",
                          "We sent this code to {{email}}",
                          { email: session?.email }
                        )}
                      </div>
                    </div>
                  </div>

                  <Formik
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={handleRequest}
                    initialValues={{ code: "" }}
                  >
                    {(formik) => (
                      <form
                        noValidate
                        onSubmit={formik.handleSubmit}
                        className="space-y-4"
                      >
                        <APIErrors
                          APIError={backendError}
                          APIValidationErrors={backendValidationErrors}
                          fieldTranslations={{}}
                        />

                        <TextInputField name="code" type="text" />

                        <button
                          type="submit"
                          disabled={formik.isSubmitting}
                          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          {formik.isSubmitting ? (
                            <>
                              <svg
                                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              {t("checking", "Checking...")}
                            </>
                          ) : (
                            <div className="inline-flex items-center">
                              {t("confirm", "Confirm")}
                              <ArrowSmRightIcon
                                className="ml-1 -mr-1 h-5 w-5"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </button>
                      </form>
                    )}
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
