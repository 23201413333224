import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCurrentNotificationDevice } from "src/helpers/notifications";
import { logoutUser, setCookieString } from "src/helpers/session";
import { buildRoute } from "../router/router";
import useHilosStore from "./useHilosStore";
import usePostHogHelpers from "./usePostHogHelpers";

function useUser() {
  const { resetPostHog } = usePostHogHelpers();
  const navigate = useNavigate();
  const { session, resetSession } = useHilosStore();

  const logout = useCallback(async () => {
    if (session) {
      await deleteCurrentNotificationDevice(session.token_access);
      localStorage.clear();
      setCookieString();
      resetPostHog();
      logoutUser();
      resetSession();
      navigate(buildRoute("login"));
    }
  }, [resetPostHog, navigate, session, resetSession]);

  return { logout };
}

export default useUser;
