import {
  BookOpenIcon,
  SpeakerphoneIcon,
  SupportIcon,
} from "@heroicons/react/solid";
import React, { useCallback, useMemo, useState } from "react";

import i18n from "src/i18n";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";
import supportLinks from "./supportLinks.json";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SupportLinkInfo {
  feature: string;
  supportLink: string;
}

const SupportWidget = React.memo(() => {
  const { t } = useTranslation();
  const { language } = i18n;
  const [widgetState, setWidgetState] = useState({
    isOpen: false,
    isReversed: false,
  });
  const location = useLocation();

  const { feature, supportLink } = useMemo((): SupportLinkInfo => {
    const path = location.pathname;
    for (const key in supportLinks) {
      if (path.startsWith(`/${key}`)) {
        return supportLinks[key];
      }
    }
    return supportLinks.default;
  }, [location.pathname]);

  const handleDragEnd = useCallback(
    (position: number) =>
      setWidgetState({
        ...widgetState,
        isReversed: position < window.innerHeight / 2,
      }),
    [widgetState]
  );

  const onClick = useCallback(() => {
    setWidgetState({
      ...widgetState,
      isOpen: !widgetState.isOpen,
    });
  }, [widgetState]);

  return (
    <>
      {!isMobile && (
        <motion.div
          drag="y"
          dragConstraints={{
            top: -window.innerHeight + 100,
            bottom: 0,
          }}
          onDragEnd={(event, info) => handleDragEnd(info.point.y)}
          className="absolute bottom-5 right-5 z-10 flex gap-4 flex-col items-end"
        >
          {widgetState.isOpen && (
            <div
              className={`absolute w-52 flex flex-col gap-2 py-4 bg-white text-gray-400 rounded-md shadow-lg text-sm ring-1 ring-gray-900/5 ${
                widgetState.isReversed ? "top-full mt-2" : "bottom-full mb-2"
              }`}
            >
              <a
                target="_blank"
                href={supportLink}
                rel="noreferrer nofollow"
                className="rounded-sm hover:text-indigo-500 px-4 py-2 font-bold gap-1 flex flex-row items-center"
              >
                <BookOpenIcon className="h-4 w-4" aria-hidden="true" />
                {t("support:feature-docs", { feature: t(feature) || "Hilos" })}
              </a>
              <a
                target="_blank"
                href={
                  language && language.toLowerCase().indexOf("es") === 0
                    ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
                    : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos."
                }
                rel="noreferrer nofollow"
                className="rounded-sm hover:text-indigo-500 px-4 py-2 font-bold flex gap-1 flex-row items-center"
              >
                <SupportIcon className="h-4 w-4" aria-hidden="true" />
                {t("navigation:support.chat", "Chat with support")}
              </a>
              <a
                target="_blank"
                href="https://hilos.canny.io/feedback"
                rel="noreferrer nofollow"
                className="rounded-sm hover:text-indigo-500 px-4 py-2 font-bold flex gap-1 flex-row items-center"
              >
                <SpeakerphoneIcon className="h-4 w-4" aria-hidden="true" />
                {t("navigation:support.feedback", "Feedback")}
              </a>
            </div>
          )}
          <button
            onClick={onClick}
            className={`rounded-full aspect-square font-bold text-lg h-10 shadow-md bg-white text-indigo-500 text-md ${
              widgetState.isReversed ? "order-first" : "order-last"
            }`}
          >
            ?
          </button>
        </motion.div>
      )}
    </>
  );
});

export default SupportWidget;
