import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlayIcon } from "@heroicons/react/outline";
import axios from "axios";
import { getIn, useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import FlowBuilderStepWrappedActionResponse from "src/containers/flow/builder/components/steps/action/FlowBuilderStepActionResponse";
import { hasItems } from "src/helpers/utils";
import useWrappedSteps, { WrappedChatGPT } from "src/hooks/useWrappedSteps";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { StepTypes } from "../../../constants/steps";
import DynamicForm from "../../DynamicForm/DynamicForm";

interface FlowBuilderStepWrappedActionProps {
  index: number;
}

function isValidKeyValue(item) {
  return item !== null && item !== undefined && item !== "";
}

function getCleanedValues(data) {
  if (hasItems(data)) {
    return data.filter(
      (item) => isValidKeyValue(item.key) && isValidKeyValue(item.value)
    );
  }
  return [];
}

function FlowBuilderStepWrappedAction({
  index,
}: FlowBuilderStepWrappedActionProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  const { STEP_TYPES_DYNAMIC } = useWrappedSteps();

  const step = useMemo(() => {
    // @ts-expect-error The formik values are not typed yet...
    return STEP_TYPES_DYNAMIC[values["steps"][index].step_type as StepTypes] as WrappedChatGPT;
  }, [STEP_TYPES_DYNAMIC, index, values]);

  const handleTestAction = useCallback(async () => {
    setLoading(true);
    const currentStep = cloneDeep(getIn(values, `steps.${index}`, {}));

    if (Array.isArray(currentStep.action_request_body)) {
      currentStep.action_request_body = getCleanedValues(
        currentStep.action_request_body
      );
    }
    currentStep.action_request_headers = getCleanedValues(
      currentStep.action_request_headers
    );
    currentStep.action_request_params = getCleanedValues(
      currentStep.action_request_params
    );

    try {
      const response = await axios.post(
        buildAPIRoute(API_ROUTES.FLOW_V2_STEP_WRAPPED_ACTION_TEST),
        currentStep
      );
      if (response.status === 201 && typeof response.data === "object") {
        if (!currentStep["action_responses"]) {
          currentStep["action_responses"] = [];
        }

        currentStep["action_responses"].unshift({
          created_on: new Date(),
          data: response.data,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFieldValue(`steps.${index}`, currentStep);
      setLoading(false);
    }
  }, [values, index, setFieldValue]);

  return (
    <div className="space-y-4">
      <DynamicForm
        path={`steps.${index}`}
        inputs={step.inputs}
        currentStepIndex={index}
      />
      <button
        type="button"
        className="mt-4 inline-flex w-full items-center justify-center rounded-md border border-green-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-green-500 shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-500"
        onClick={handleTestAction}
      >
        <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
        {loading
          ? t("flows:steps.action.requesting", "Requesting...")
          : t("flows:steps.action.test-request", "Test Request")}
      </button>
      <FlowBuilderStepWrappedActionResponse
        name={`steps.${index}.action_responses`}
      />
    </div>
  );
}

export default FlowBuilderStepWrappedAction;
