import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";

export default function HeaderNavigation({ navPages }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2 sm:space-x-4">
        {navPages.map((page, idx) => (
          <li key={idx}>
            {idx === 0 ? (
              <div className="flex">
                <Link
                  to={page.url}
                  className="text-xs font-medium text-gray-500 hover:text-gray-700"
                >
                  {page.name}
                </Link>
              </div>
            ) : (
              <div className="flex items-center">
                <ChevronRightIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <Link
                  to={page.url}
                  className="ml-2 sm:ml-4 text-xs font-medium text-gray-500 hover:text-gray-700"
                >
                  {page.name}
                </Link>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
