import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { ExternalAppRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useExternalApps() {
  const fetchExternalApps = useCallback(async () => {
    const { data } = await axios.get<ExternalAppRead[]>(
      buildAPIRoute(API_ROUTES.EXTERNAL_APP_LIST)
    );
    return data;
  }, []);

  const { data } = useQuery(["external_apps"], fetchExternalApps);

  return data;
}

export default useExternalApps;
