import useUser from "src/hooks/useAccountMember";
import { userToString } from "../../Helpers";
import Avatar from "./Avatar";

interface UserAvatarProps {
  data?: {
    first_name?: string;
    last_name?: string;
    email: string;
    profile_image?: string | null;
  };
  className?: string;
}

interface UserAvatarByIdProps {
  id: number;
  className?: string;
}

function UserAvatar({ data, className }: UserAvatarProps) {
  if (!data) {
    return null;
  }

  const name = userToString(data);
  const image = data.profile_image
    ? //@ts-ignore
      data.profile_image.thumbnail || data.profile_image
    : null;

  return <Avatar name={name} image={image} className={className} />;
}

export function UserAvatarById({ id, className }: UserAvatarByIdProps) {
  const data = useUser(id);
  if (!data) {
    return null;
  }

  return <UserAvatar data={data} className={className} />;
}

export default UserAvatar;
