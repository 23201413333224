import { useParams } from "react-router-dom";
import WorkflowBoardData from "./WorkflowBoardData";
import { WorkflowBoardProvider } from "./WorkflowBoardProvider";

export default function WorkflowBoard() {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <WorkflowBoardProvider id={id}>
      <WorkflowBoardData id={id} />
    </WorkflowBoardProvider>
  );
}
