import axios from "axios";
import { TFunction } from "i18next";
import * as yup from "yup1";
import { FlowStepData } from "@hilos/types/flow";
import { WABAFlowDetail } from "@hilos/types/private-schema";
import { ERROR_MESSAGES } from "src/constants/errors";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { FlowStepAnswerSchema } from "../flow";

export const FlowStepWhatsAppFlowSchema = (t: TFunction) =>
  FlowStepAnswerSchema(t).shape({
    wa_flow: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    wa_flow_selected: yup.mixed().required(t(ERROR_MESSAGES.REQUIRED)),
    body: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    answer_validation_message: yup
      .string()
      .required(t(ERROR_MESSAGES.REQUIRED)),
    cta: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    header_text: yup.string().nullable(),
    footer_text: yup.string().nullable(),
    flow_navigate_screen_id: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
  });

export const FlowStepWhatsAppFlowFormat = (step: FlowStepData) => {
  const nextStep = { ...step };

  if (!nextStep.has_max_wait_time) {
    delete nextStep.max_wait_time_amount;
    delete nextStep.max_wait_time_unit;
  }

  if (!nextStep.has_max_answer_attempts) {
    delete nextStep.max_answer_attempts;
    nextStep.answer_failed_next_step = null;
  }

  return nextStep;
};

export const getWhatsAppFlowData = async (id: string) => {
  try {
    if (!id) {
      return null;
    }

    const { data } = await axios.get<WABAFlowDetail>(
      buildAPIRoute(API_ROUTES.WHATSAPP_FLOW_DETAIL, { ":id": id })
    );

    return data || null;
  } catch {
    return null;
  }
};
