import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RadioGroup } from "@headlessui/react";
import { ArrowSmRightIcon, ClockIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Formik } from "formik";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import useAccount from "src/hooks/useAccount";
import { classNames, userToString } from "src/Helpers";
import * as meta from "./Meta";

export default function TeamForm({
  team,
  formSubmit,
  success,
  submitted,
  action,
}) {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const { members } = useAccount();

  const onSubmit = (values, setSubmitting) => {
    formSubmit(
      values,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  const handleChange = useCallback((formik, option) => {
    formik.setFieldValue(meta.FIELDS.assignment_strategy.key, option);
  }, []);

  return (
    <Formik
      validationSchema={meta.schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
      initialValues={team}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="py-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:team.team-basic-info">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Basic information
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Basic information about this team, including which users
                    belong to it.
                  </p>
                </Trans>
              </div>
            </div>
            <div className="pt-5 md:col-span-2 md:pt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3">
                      <TextInputField
                        placeholder={t(meta.FIELDS.name.placeholder)}
                        label={t(meta.FIELDS.name.label)}
                        name={meta.FIELDS.name.key}
                        type="text"
                      />
                    </div>
                    <div className="col-span-3">
                      <SelectorField
                        isMulti
                        name={meta.FIELDS.users.key}
                        label={t(meta.FIELDS.users.label)}
                        options={members.map((user) => ({
                          label: userToString(user),
                          value: user.id,
                        }))}
                        placeholder={t(
                          "type-to-search",
                          "Start typing to search..."
                        )}
                        help={t(meta.FIELDS.users.help)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-10 sm:pt-4">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <Trans i18nKey="settings:team.assigment-strategy.description">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Assignment strategy
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      When you assign a conversation to a team, choose how you
                      wish the conversation to be assigned to users of that
                      team.
                    </p>
                  </Trans>
                </div>
              </div>
              <div className="pt-5 md:col-span-2 md:pt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3">
                        <RadioGroup
                          value={
                            formik.values[meta.FIELDS.assignment_strategy.key]
                          }
                          onChange={(option) => handleChange(formik, option)}
                        >
                          <RadioGroup.Label className="sr-only">
                            {" "}
                            {t("server-size")}
                          </RadioGroup.Label>
                          <div className="space-y-4">
                            {meta.ASSIGNMENT_STRATEGY_OPTIONS.map(
                              (assignmentOption) => (
                                <RadioGroup.Option
                                  key={assignmentOption.key}
                                  value={assignmentOption.key}
                                  className={({ checked, active }) =>
                                    classNames(
                                      checked
                                        ? "border border-indigo-500"
                                        : "border-gray-300",
                                      active
                                        ? "border-indigo-500 ring-2 ring-indigo-500"
                                        : "",
                                      "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <div className="flex items-center">
                                      <div className="grow-0 pr-4">
                                        {assignmentOption.icon}
                                      </div>
                                      <span className="flex items-center">
                                        <span className="flex flex-col text-sm">
                                          <RadioGroup.Label
                                            as="span"
                                            className="font-medium text-gray-900"
                                          >
                                            {t(assignmentOption.label)}
                                          </RadioGroup.Label>
                                          <RadioGroup.Description
                                            as="span"
                                            className="text-gray-500"
                                          >
                                            <span className="block sm:inline">
                                              {t(assignmentOption.description)}
                                            </span>{" "}
                                            <span
                                              className="hidden sm:mx-1 sm:inline"
                                              aria-hidden="true"
                                            >
                                              &middot;
                                            </span>{" "}
                                          </RadioGroup.Description>
                                        </span>
                                      </span>
                                      <div className="w-10">
                                        <CheckCircleIcon
                                          className={classNames(
                                            !checked ? "invisible" : "",
                                            "shrink=-0 h-5 w-5 text-indigo-600 "
                                          )}
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </RadioGroup.Option>
                              )
                            )}
                          </div>
                        </RadioGroup>
                        {formik.errors &&
                          formik.errors.assignment_strategy &&
                          typeof formik.errors.assignment_strategy ===
                            "string" && (
                            <p className="my-2 text-sm text-red-500 ">
                              {formik.errors.assignment_strategy}
                            </p>
                          )}
                      </div>

                      {formik.values[meta.FIELDS.assignment_strategy.key] ===
                        "USE_DEFAULT" && (
                        <div className="col-span-3">
                          <SelectorField
                            name={meta.FIELDS.default_assignee.key}
                            label={t(meta.FIELDS.default_assignee.label)}
                            options={members.map((user) => ({
                              label: userToString(user),
                              value: user.id,
                            }))}
                            placeholder={t(
                              "type-to-search",
                              "Start typing to search..."
                            )}
                          />
                        </div>
                      )}

                      {formik.values[meta.FIELDS.assignment_strategy.key] !==
                        "USE_DEFAULT" && (
                        <div className="col-span-3 space-y-4">
                          <SwitchField
                            name={meta.FIELDS.reassign_on_inactivity.key}
                            label={t(meta.FIELDS.reassign_on_inactivity.label)}
                          />
                          {formik.values[
                            meta.FIELDS.reassign_on_inactivity.key
                          ] && (
                            <TextInputField
                              type="number"
                              name={meta.FIELDS.reassign_after.key}
                              label={t(meta.FIELDS.reassign_after.label)}
                              help={t(meta.FIELDS.reassign_after.help)}
                              icon={ClockIcon}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-4 text-right">
            <StateButton
              isSubmitting={formik.isSubmitting}
              submitted={submitted}
              success={success}
              disabled={
                formik.errors &&
                Object.getOwnPropertyNames(formik.errors).length > 0
              }
              submittingText={
                action === "create" ? t("creating") : t("updating")
              }
              successText={t("team-saved")}
              initialText={
                <>
                  {action === "create" ? t("create") : t("update")}
                  <ArrowSmRightIcon
                    className="ml-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </>
              }
            />
          </div>
        </form>
      )}
    </Formik>
  );
}
