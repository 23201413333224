import { Trans, useTranslation } from "react-i18next";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { TRIGGER_TYPES } from "../builder/constants/triggers";

export default function OutboundCampaignAPITriggerDetail({ flow }) {
  const { t } = useTranslation();
  const triggerData = TRIGGER_TYPES[flow.trigger_type];

  return (
    <>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("trigger", "Trigger")}
      </h3>
      <div className="flex items-center space-x-1">
        <div>
          <triggerData.icon className="h-8 w-8 text-gray-800" />
        </div>
        <div className="font-medium text-gray-900">
          <p className="text-xl">{t(triggerData.name)}</p>
        </div>
      </div>
      <div className="text-sm text-gray-700 mt-2">
        <div className="space-y-4">
          <Trans i18nKey="flows:trigger-detail.outbound-campaign-api">
            <p>
              This flow will be triggered by making an HTTP request to the{" "}
              <a
                href="https://hilos.io/docs/developer/apidocs/flow-execution/post-apiflow-run"
                target="_blank"
                rel="noreferrer"
              >
                <code className="bg-gray-100 rounded-md text-indigo-500 py-1 px-1">
                  /api/flow/:id/run
                </code>
              </a>{" "}
              API endpoint.
            </p>
          </Trans>

          {flow.flow_execution_variables &&
            flow.flow_execution_variables.length > 0 && (
              <p>
                {t(
                  "flows:trigger-detail.outbound-campaign-api-w-vars",
                  "Be sure to include all the execution variables defined in this flow in your request!"
                )}
              </p>
            )}

          <a
            href="https://hilos.io/docs/apidocs/flow-execution/post-apiflow-run"
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
          >
            {t(
              "flows:trigger-detail.outbound-campaign-api-button",
              "See documentation"
            )}
            <ArrowSmRightIcon className="h-5 w-5 text-gray-500 ml-1" />
          </a>
        </div>
      </div>
    </>
  );
}
