import { ReactNode, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import {
  DocumentTextIcon,
  PhotographIcon,
  TrashIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import { useField, useFormikContext } from "formik";
import { DropzoneStyles } from "src/components/base/Form";
import useFileHelpers from "src/hooks/useFileHelpers";

interface MediaInfo {
  accept: string;
  inputHelp?: string;
  icon?: ReactNode;
}

type AvailableMedia = "IMAGE" | "VIDEO" | "DOCUMENT" | "TEXT";

const MEDIA_INFO: Record<AvailableMedia, MediaInfo> = {
  TEXT: {
    accept: "text/plain",
  },
  IMAGE: {
    icon: <PhotographIcon className="h-5 w-5" aria-hidden="true" />,
    accept: "image/png,image/jpg,image/jpeg",
    // i18n.t("components:file-upload-field.media-info.image.input-help", "Make sure it's .png or .jpg file and no larger than 15MB.")
    inputHelp: "components:file-upload-field.media-info.image.input-help",
  },
  DOCUMENT: {
    icon: <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />,
    accept: "application/pdf",
    // i18n.t("components:file-upload-field.media-info.document.input-help", "Make sure it's a .pdf file and no larger than 15MB.")
    inputHelp: "components:file-upload-field.media-info.document.input-help",
  },
  VIDEO: {
    icon: <VideoCameraIcon className="h-5 w-5" aria-hidden="true" />,
    accept: "video/mp4",
    // i18n.t("components:file-upload-field.media-info.video.input-help", "Make sure .mp4 file and no larger than 15MB.")
    inputHelp: "components:file-upload-field.media-info.video.input-help",
  },
};

interface FileUploadFieldProps {
  name: string;
  media?: AvailableMedia;
  disableFileUpload?: boolean;
}

export default function FileUploadField({
  name,
  media = "IMAGE",
  disableFileUpload,
}: FileUploadFieldProps) {
  const [error, setError] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [field] = useField<string>(name);
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { deleteUploadedFile, uploadedFile, uploadFile, setUploadedFile } =
    useFileHelpers({ url: field.value });

  const handleOnDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    // setError("");
    fileRejections.forEach((file) => {
      setError(
        t(
          "components:file-upload-field.invalid-file-format",
          "{{filename}} is not a valid format.",
          {
            filename: file.file.name,
          }
        )
      );
    });

    acceptedFiles.map(async (file) => {
      if (disableFileUpload) {
        return;
      }
      if (file.size > 1024 * 15000) {
        setError(
          t(
            "components:file-upload-field.invalid-file-size",
            "Please upload a smaller file no larger than 15MB."
          )
        );
      }

      const formData = new FormData();
      formData.append("uploaded_file", file);

      setUploadingFile(true);
      try {
        const url = await uploadFile(formData);
        setFieldValue(name, url);
      } catch (e) {
        captureException(e);
      } finally {
        setUploadingFile(false);
      }
    });
  };

  const handleCleanFile = () => {
    deleteUploadedFile();
    setFieldValue(name, "");
    setUploadedFile(null);
  };

  const mediaType = MEDIA_INFO[media];

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      // @ts-ignore
      accept: mediaType.accept,
      onDrop: handleOnDrop,
      maxFiles: 1,
    });

  const style = useMemo(
    () => ({
      ...DropzoneStyles.baseStyle,
      ...(isDragActive ? DropzoneStyles.activeStyle : {}),
      ...(isDragAccept ? DropzoneStyles.acceptStyle : {}),
    }),
    [isDragActive, isDragAccept]
  );

  return (
    <div>
      {uploadedFile ? (
        <div className="flex items-stretch rounded-lg border border-gray-200 p-3 shadow-sm">
          {media === "IMAGE" && (
            <img
              alt="uploaded file"
              src={uploadedFile.url}
              className="mr-2 w-14 self-center"
            />
          )}
          {media === "VIDEO" && (
            <video loop className="mr-2 w-20 self-center">
              <source src={uploadedFile.url} type="video/mp4" />
              {t(
                "components:file-upload-field.browser-embeded-videos-not-supported",
                "Sorry, your browser doesn't support embedded videos."
              )}
            </video>
          )}

          <div className="grow self-center text-sm truncate">
            {uploadedFile.original_name}
          </div>
          <div className="self-center text-right">
            <button
              className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              type="button"
              onClick={handleCleanFile}
            >
              <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />
              {t("remove")}
            </button>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps({
            className: "dropzone",
            // @ts-ignore
            style: style,
          })}
        >
          <input {...getInputProps()} disabled={!!disableFileUpload} />
          <div className="mb-0 flex items-center pb-0">
            {mediaType.icon}
            {uploadingFile ? (
              <span className="ml-3">
                {t(
                  "components:file-upload-field.uploading-file",
                  "Uploading file..."
                )}
              </span>
            ) : (
              <div className="ml-3">
                <div>
                  {
                    // i18n.t("components:file-upload-field.select-drag-drop-text", "Click here to select a text or drag & drop it.")
                    // i18n.t("components:file-upload-field.select-drag-drop-image", "Click here to select a image or drag & drop it.")
                    // i18n.t("components:file-upload-field.select-drag-drop-video", "Click here to select a video or drag & drop it.")
                    // i18n.t("components:file-upload-field.select-drag-drop-document", "Click here to select a document or drag & drop it.")
                    t(
                      `components:file-upload-field.select-drag-drop-${media.toLowerCase()}`
                    )
                  }
                </div>
                <div className="text-xs text-gray-500">
                  {mediaType.inputHelp && t(mediaType.inputHelp)}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <p>
        {error && <span className="mt-1.5 text-xs text-red-500">{error}</span>}
      </p>
    </div>
  );
}
