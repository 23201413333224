import { TFunction } from "i18next";
import * as yup from "yup1";
import { getVariablesRequiredFromTemplate } from "src/containers/wa/WhatsAppTemplateMeta";
import { getTemplateQuickReplyOptions } from "src/helpers/template";
import { removeFalsyValuesFromEndArray } from "src/helpers/utils";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepAnswerSchema } from "../flow";

export const FlowStepTemplateMenuSchema = (t: TFunction) =>
  FlowStepAnswerSchema(t).shape({
    whatsapp_template: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    validate_answer_with_buttons: yup.bool(),
    whatsapp_template_selected: yup
      .mixed()
      .required(t(ERROR_MESSAGES.REQUIRED)),
    group_answers_from_multiple_texts: yup.bool(),
    minutes_to_wait_for_more_messages: yup
      .number()
      .when("group_answers_from_multiple_texts", {
        is: true,
        then: (schema) =>
          schema
            .min(1, t(ERROR_MESSAGES.MIN_NUMBER_VALUE))
            .required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    whatsapp_template_variables: yup.lazy((_, options) => {
      const nextSchemaShape = {};

      try {
        const templateSelected = options.parent["whatsapp_template_selected"];
        const {
          headerRequiresURL,
          headerRequiresVariable,
          bodyVariables,
          buttonsVariables,
          isHeaderLocation,
        } = getVariablesRequiredFromTemplate(templateSelected);

        if (headerRequiresURL) {
          nextSchemaShape["headerURL"] = yup
            .string()
            .required(t(ERROR_MESSAGES.REQUIRED));
        }
        if (headerRequiresVariable) {
          nextSchemaShape["headerVar"] = yup
            .string()
            .required(t(ERROR_MESSAGES.REQUIRED));
        }
        if (isHeaderLocation) {
          nextSchemaShape["locationHeaderVars"] = yup.object().shape({
            name: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
            address: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
            latitude: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
            longitude: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
          });
        }
        if (bodyVariables.length > 0) {
          nextSchemaShape["bodyVars"] = yup
            .array()
            .length(bodyVariables.length)
            .of(yup.string().required(t(ERROR_MESSAGES.REQUIRED)));
        }
        if (buttonsVariables.length > 0) {
          nextSchemaShape["buttonVars"] = yup
            .array()
            .length(buttonsVariables.length)
            .of(yup.string().required(t(ERROR_MESSAGES.REQUIRED)));
        }
      } catch {}

      return yup.object().shape(nextSchemaShape);
    }),
    answer_validation_message: yup
      .string()
      .nullable()
      .when("nextAction", {
        is: "BUTTON_REQUIRED",
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    next_steps_for_options: yup
      .array()
      .label("Next steps")
      .test(
        "unreachable-steps",
        "There still are unreachable steps",
        (value, options) => {
          const cleanedNextStepsForOptions =
            removeFalsyValuesFromEndArray(value);

          const templateSelected = options.parent["whatsapp_template_selected"];
          const turnIntoMenu =
            options.parent["next_action"] === "BUTTON_RESPONSE";
          const nextStepDefault = options.parent["next_step_default"];
          const nextOptionsFromSelectedTemplate =
            getTemplateQuickReplyOptions(templateSelected);
          if (!turnIntoMenu) {
            if (
              cleanedNextStepsForOptions &&
              cleanedNextStepsForOptions.length > 0
            ) {
              return false;
            }
          } else {
            if (nextStepDefault) {
              return false;
            }
          }
          return (
            cleanedNextStepsForOptions &&
            cleanedNextStepsForOptions.length <=
              nextOptionsFromSelectedTemplate.length
          );
        }
      ),
  });
