import { useTranslation } from "react-i18next";
import { ReplyIcon } from "@heroicons/react/outline";
import { classNames } from "src/Helpers";
import { ChannelProvider } from "src/types/channel";

export default function ReplyTo({
  active,
  inboxContact,
  handleSelectContextMessage,
  content,
}) {
  const { t } = useTranslation();
  if (
    ![
      ChannelProvider.META_CLOUD_API,
      ChannelProvider.D360_CLOUD_API,
      ChannelProvider.TECH_PROVIDER_CLOUD_API,
    ].includes(inboxContact.channel.channel_provider)
  ) {
    return null;
  }

  return (
    <button
      className={classNames(
        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
        "flex w-full items-center px-4 py-2 text-sm"
      )}
      onClick={() => handleSelectContextMessage(content)}
    >
      <ReplyIcon className="w-3 h-3 mr-1 text-gray-700" strokeWidth={3} />
      {t("reply-to", "Reply to")}
    </button>
  );
}
