import { get } from "lodash";
import { hasItems } from "./utils";

export function getVariableNameNormalized(name: string) {
  return name.replaceAll(/\s|-+/g, "_");
}

export function filterByStepType<T extends { [key: string]: any }>(
  name: string,
  currentStepIndex: number | null,
  filterBy: ((step: T, index: number) => boolean) | null = null
) {
  return (step: T, index: number) =>
    (currentStepIndex === null || index < currentStepIndex) &&
    step &&
    step.name &&
    step.step_type === name &&
    (!filterBy || filterBy(step, index));
}

export function getAvailableStepVariables(steps: any) {
  return steps.map((step) => getVariableNameNormalized(step.name));
}

export function getAvailableQuestionVariables(steps: any) {
  const variables: string[] = [];

  for (const step of steps) {
    if (step.answer_type === "LOCATION") {
      variables.push(`${getVariableNameNormalized(step.name)}.lat`);
      variables.push(`${getVariableNameNormalized(step.name)}.lng`);
    } else if (
      step.answer_type === "SINGLE_OPTION" &&
      step.has_options_from_variable
    ) {
      variables.push(`${getVariableNameNormalized(step.name)}.id`);
      variables.push(`${getVariableNameNormalized(step.name)}.title`);
    } else {
      variables.push(getVariableNameNormalized(step.name));
    }
  }

  return variables;
}

export function getAvailableActionVariables(
  steps: any,
  types: string[] = ["string", "number", "boolean"]
) {
  const variables: string[] = [];

  for (const step of steps) {
    if (hasItems(step.action_responses)) {
      for (const response of step.action_responses) {
        if (response.data) {
          variables.push(
            ...getKeysFromData(
              response.data,
              getVariableNameNormalized(step.name),
              types
            )
          );
        }
      }
    }
  }
  return [...new Set(variables)];
}

export function getArrayVariableByName(steps: any, name: string) {
  if (!name) {
    return null;
  }

  const path = name.split(".");
  const type = path[0];

  if (type === "flow") {
    for (const step of steps) {
      if (
        step &&
        step.name &&
        getVariableNameNormalized(step.name) === path[1]
      ) {
        if (
          step.step_type === "ACTION" ||
          step.step_type === "HUBSPOT_CONTACT_GET"
        ) {
          const responses = get(step, "action_responses", []);

          for (const response of responses) {
            if (response.data) {
              const items = get(response.data, [...path.slice(2)], null);

              if (hasItems(items)) {
                return items;
              }
            }
          }

          return null;
        }
        break;
      }
    }
  }

  return null;
}

export function getOptionsFromArrayVariable(data: any) {
  if (hasItems(data)) {
    for (const item of data) {
      if (item) {
        return getKeysFromData(item);
      }
    }
  }
  return [];
}

export function getKeysFromData(
  data: any,
  path: string = "",
  types: string[] = ["string", "number", "boolean"]
) {
  const keys: string[] = [];
  const typeOfData = typeof data;
  if (!data || typeOfData !== "object") {
    if (types.includes(typeOfData)) {
      keys.push(path);
    }
    return keys;
  }

  const isArray = Array.isArray(data);
  if (isArray) {
    keys.push(path + "|length");
  }

  if (types.includes(isArray ? "array" : "object")) {
    keys.push(path);
  }

  for (const key in data) {
    keys.push(
      ...getKeysFromData(
        data[key],
        path
          ? [path, getVariableNameNormalized(key)].join(".")
          : getVariableNameNormalized(key),
        types
      )
    );
  }

  return keys;
}

export function transformFormikErrorsForFormikTouched(data) {
  if (Array.isArray(data)) {
    return data.map(transformFormikErrorsForFormikTouched);
  } else if (typeof data === "object" && data !== null) {
    const transformed = {};
    for (const key in data) {
      transformed[key] = transformFormikErrorsForFormikTouched(data[key]);
    }
    return transformed;
  } else {
    return true;
  }
}
