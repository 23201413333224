import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import {
  CheckIcon,
  ClipboardCheckIcon,
  ClipboardCopyIcon,
  KeyIcon,
  PlusSmIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";

import { APIKey } from "@hilos/types/private-schema";
import HeaderNavigation from "../../components/HeaderNavigation";
import Table from "src/components/Table/Table";
import axios from "axios";
import { useState } from "react";
import { userToString } from "src/Helpers";

export default function APIKeyList() {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(undefined);
  const [copyError, setCopyError] = useState(false);
  const [triggerReload, setTriggerReload] = useState(false);

  const handleCopyKey = async (apiKey) => {
    try {
      await navigator.clipboard.writeText(apiKey.key);
    } catch (err) {
      console.log(err);
      setCopyError(true);
    } finally {
      setCopied(apiKey.key);
      setTimeout(() => {
        setCopied(undefined);
      }, 1000 * 2);
    }
  };

  const createKey = async () => {
    try {
      await axios.post(buildAPIRoute(API_ROUTES.API_KEY_LIST_CREATE));
      setTriggerReload(!triggerReload);
    } catch (err) {
      console.log(err);
    }
  };

  const deactivateKey = async (key) => {
    try {
      await axios.put(
        buildAPIRoute(API_ROUTES.API_KEY_DETAIL, { ":id": key.key }),
        { is_valid: false }
      );
      setTriggerReload(!triggerReload);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("api-keys", "API Keys"),
              url: buildRoute("api-keys"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <KeyIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("api-keys")}
            </h2>
          </div>
          <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
            <span className="md:ml-3">
              <button
                className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                type="button"
                onClick={(_) => createKey()}
              >
                <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {t("integrations:api.create-key", "Create API Key")}
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6">
          <div className="mt-6 md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="integrations:api.description">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Use these keys to connect Hilos with your platforms.
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Read more about our API and how you can use hilos.io with
                    your existing platforms{" "}
                    <a
                      href="https://hilos.io/docs/developer/getting-started/auth"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500"
                    >
                      here
                    </a>
                    .
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <Table
                    baseURL={buildAPIRoute(API_ROUTES.API_KEY_LIST_CREATE)}
                    paginated={false}
                    triggerReload={triggerReload}
                    tableHead={
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                          >
                            {t("token")}
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                          >
                            {t("created-by", "Created by")}
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                          >
                            {`${t("active")}?`}
                          </th>
                          <th scope="col" className="px-4 py-3"></th>
                        </tr>
                      </thead>
                    }
                    tableKey={(key: APIKey) => key.key}
                    tableRow={(key: APIKey) => (
                      <>
                        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                          <code className="mr-2 text-indigo-500">
                            {key.key}
                          </code>
                          {copied === key.key ? (
                            <>
                              {copyError ? (
                                <button
                                  className="rounded-md border border-transparent bg-yellow-500 px-2 py-1.5 text-sm font-medium leading-4 text-white shadow-sm hover:bg-yellow-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                                  type="button"
                                  disabled={true}
                                >
                                  <XIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </button>
                              ) : (
                                <button
                                  className="rounded-md border border-transparent bg-green-600 px-2 py-1.5 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                  type="button"
                                  disabled={true}
                                >
                                  <ClipboardCheckIcon
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}
                            </>
                          ) : (
                            <button
                              className="rounded-md border border-gray-400 bg-white px-2 py-1.5 text-sm font-medium leading-4 text-gray-400 shadow-sm hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                              type="button"
                              onClick={(_) => handleCopyKey(key)}
                            >
                              <ClipboardCopyIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </button>
                          )}
                          <div className="text-gray-500">
                            {t("created-on")}
                            {key.created_on &&
                              t(" {{ date, Pp }}", {
                                date: new Date(key.created_on),
                              })}
                            {key.last_used_on && (
                              <>
                                {` - ${t("last-used-on", "Last used on")}`}
                                {t(" {{ date, Pp }}", {
                                  date: new Date(key.last_used_on),
                                })}
                              </>
                            )}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-500">
                          {userToString(key.user)}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                          {key.is_valid ? (
                            <CheckIcon
                              className="h-4 w-4 text-green-600"
                              aria-hidden="true"
                            />
                          ) : (
                            <XIcon
                              className="h-4 w-4 text-red-600"
                              aria-hidden="true"
                            />
                          )}
                        </td>

                        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                          <button
                            className="rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            type="button"
                            onClick={(_) => deactivateKey(key)}
                          >
                            {t("deactivate", "Deactivate")}
                          </button>
                        </td>
                      </>
                    )}
                    dataName={t("api-keys", "API Keys")}
                    createObject={
                      <>
                        <Trans i18nKey="integrations:api.no-keys-yet">
                          <h4>Looks like you don't have any API keys yet</h4>
                          <p className="text-sm text-gray-500">
                            Why don't you create one? You'll need to create one
                            to create contacts and run flows via API.
                          </p>
                          <button
                            type="button"
                            className="mt-2 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                            onClick={(_) => createKey()}
                          >
                            <PlusSmIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            New API Key
                          </button>
                        </Trans>
                      </>
                    }
                  ></Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
