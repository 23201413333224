import { getTranslationPayload as t } from "src/i18n";

export const COMPARISON_OPTIONS = [
  {
    label: t("flows:steps.conditional.comparison-options.exact", "Is (equals)"),
    value: "exact",
  },
  {
    label: t("flows:steps.conditional.comparison-options.not-exact", "Is not"),
    value: "!exact",
  },
  {
    label: t("flows:steps.conditional.comparison-options.contains", "Contains"),
    value: "icontains",
  },
  {
    label: t(
      "flows:steps.conditional.comparison-options.not-contains",
      "Does not contain"
    ),
    value: "!icontains",
  },
  {
    label: t("flows:steps.conditional.comparison-options.is-null", "Is empty"),
    value: "isnull",
  },
  {
    label: t("flows:steps.conditional.comparison-options.gt", ">"),
    value: "gt",
  },
  {
    label: t("flows:steps.conditional.comparison-options.gte", ">="),
    value: "gte",
  },
  {
    label: t("flows:steps.conditional.comparison-options.lt", "<"),
    value: "lt",
  },
  {
    label: t("flows:steps.conditional.comparison-options.lte", "<="),
    value: "lte",
  },
];

export const DATETIME_COMPARISON_OPTIONS = [
  {
    label: t(
      "flows:steps.conditional.datetime-comparison-options.exact",
      "Is (equals)"
    ),
    value: "exact",
  },
  {
    label: t(
      "flows:steps.conditional.datetime-comparison-options.not-exact",
      "Is not"
    ),
    value: "!exact",
  },
  {
    label: t(
      "flows:steps.conditional.datetime-comparison-options.gte",
      "Greater than or equal"
    ),
    value: "gte",
  },
  {
    label: t(
      "flows:steps.conditional.datetime-comparison-options.lte",
      "Less than or equal"
    ),
    value: "lte",
  },
];

export const BOOLEAN_COMPARISON_OPTIONS = [
  {
    label: t(
      "flows:steps.conditional.datetime-comparison-options.exact",
      "Is (equals)"
    ),
    value: "exact",
  },
]

export const WEEKDAY_OPTIONS = [
  {
    label: t("flows:steps.conditional.weekday-options.monday", "Monday [0]"),
    value: "0",
  },
  {
    label: t("flows:steps.conditional.weekday-options.tuesday", "Tuesday [1]"),
    value: "1",
  },
  {
    label: t(
      "flows:steps.conditional.weekday-options.wednesday",
      "Wednesday [2]"
    ),
    value: "2",
  },
  {
    label: t(
      "flows:steps.conditional.weekday-options.thursday",
      "Thursday [3]"
    ),
    value: "3",
  },
  {
    label: t("flows:steps.conditional.weekday-options.friday", "Friday [4]"),
    value: "4",
  },
  {
    label: t(
      "flows:steps.conditional.weekday-options.saturday",
      "Saturday [5]"
    ),
    value: "5",
  },
  {
    label: t("flows:steps.conditional.weekday-options.sunday", "Sunday [6]"),
    value: "6",
  },
];
