// @flow
import * as yup from "yup";
import { getTranslationPayload as t } from "src/i18n";
import { ERROR_MESSAGES } from "../../constants/Form";

export const getInitialValues = () => {
  return {
    oldpassword: "",
    password1: "",
    password2: "",
  };
};

export const schema = yup.object().shape({
  oldpassword: yup.string().required(ERROR_MESSAGES.required),
  password1: yup.string().required(ERROR_MESSAGES.required),
  password2: yup.string().required(ERROR_MESSAGES.required),
});

export const FIELDS = {
  oldpassword: {
    key: "oldpassword",
    label: t("components:input.old-password.label", "Current password"),
    placeholder: t("components:input.old-password.placeholder", "*******"),
  },
  password1: {
    key: "password1",
    label: t("components:input.new-password.label", "New password"),
    placeholder: t("components:input.new-password.placeholder", "*******"),
    help: t(
      "components:input.new-password.help",
      "At least 8 characters, use letters and numbers."
    ),
  },
  password2: {
    key: "password2",
    label: t("components:input.confirm-password.label", "Repeat new password"),
    placeholder: t("components:input.confirm-password.placeholder", "*******"),
  },
};
