import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Disclosure, Transition } from "@headlessui/react";
import { CheckIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { InboxActionData, InboxActionLog } from "@hilos/types/inbox_action";
import { InboxContactRead } from "@hilos/types/private-schema";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import useCleanTimeout from "../../../../hooks/useCleanTimeout";
import useInboxActionDetails from "../../../../hooks/useInboxActionDetails";
import { useInboxActionList } from "../../../../hooks/useInboxActionList";
import InboxActionLogModal from "./InboxActionLogModal";

interface InboxActionListProps {
  inboxContact: InboxContactRead;
}

function InboxActionList({ inboxContact }: InboxActionListProps) {
  const { t } = useTranslation();
  const { inbox_actions } = useInboxActionList();
  const { executeAction } = useInboxActionDetails();
  const timeout = useCleanTimeout();

  const [executing, setExecuting] = useState(false);
  const [executingAction, setExecutingAction] =
    useState<InboxActionData | null>(null);
  const [executionSuccess, setExecutionSuccess] = useState(false);
  const [lastExecutionResult, setLastExecutionResult] =
    useState<InboxActionLog | null>(null);
  const [open, setOpen] = useState(false);

  const handleExecuteAction = async (action: InboxActionData) => {
    setExecuting(true);
    setExecutingAction(action);
    setLastExecutionResult(null);
    try {
      const result = await executeAction(
        inboxContact.contact.id as string,
        action
      );
      setExecutionSuccess(result.success);
      setLastExecutionResult(result);
      if (!result.success) {
        setOpen(true);
      }
      timeout.current = setTimeout(() => {
        setExecutingAction(null);
        setExecutionSuccess(false);
      }, 1000 * 2);
    } catch (err) {
      setExecutionSuccess(false);
      timeout.current = setTimeout(() => {
        setExecutingAction(null);
      }, 1000 * 2);
    } finally {
      setExecuting(false);
    }
  };

  return (
    <>
      <div className="px-3">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex min-w-full flex-1 grow items-center justify-between text-xs font-medium uppercase tracking-wide text-gray-500">
                {t("integrations", "Integrations")}
                <ChevronRightIcon
                  className={`mr-1.5 h-4 w-4 text-gray-500 ${
                    open
                      ? "rotate-90 transform transition-transform duration-100 ease-in-out"
                      : ""
                  }`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-in"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="py-3 text-sm text-gray-500">
                  <div>
                    <ul className="space-y-2">
                      {inbox_actions.results.map((action) => (
                        <li key={action.id} id={action.id}>
                          <button
                            type="button"
                            className={`inline-flex w-full items-center justify-center ${action.button_classes}`}
                            onClick={(_) => handleExecuteAction(action)}
                            disabled={executing}
                          >
                            {executingAction === action ? (
                              <>
                                {executionSuccess ? (
                                  <div className="inline-flex w-full items-center justify-center text-green-500">
                                    <CheckIcon className="text-green mr-1 h-5 w-5" />
                                    {t("success", "Success")}!
                                  </div>
                                ) : (
                                  <>
                                    <LoaderSpinner />
                                    {t("executing", "Executing...")}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {action.button_icon && (
                                  <img
                                    src={action.button_icon}
                                    className="mr-2 max-h-5 w-5"
                                    alt="Button icon"
                                  />
                                )}
                                {action.button_label}
                              </>
                            )}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
      {lastExecutionResult && (
        <InboxActionLogModal
          inboxActionLog={lastExecutionResult}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
}
export default InboxActionList;
