import { useTranslation } from "react-i18next";
import { ArrowRightIcon, CogIcon, SaveIcon } from "@heroicons/react/outline";
import ActiveAwareLink from "src/components/ActiveAwareLink";
import usePostHogHelpers from "src/hooks/usePostHogHelpers";
import { classNames } from "src/Helpers";

export interface ExternalAppCardProps {
  key: string;
  icon: string;
  title: string;
  description: string;
  link?: string;
  to?: string;
  connected?: boolean;
  disableInstall?: boolean;
  useI18n?: boolean;
  featureFlag?: string;
  loading?: boolean;
}

export default function ExternalAppCard({
  key,
  icon,
  title,
  description,
  link,
  to,
  connected = false,
  disableInstall = false,
  useI18n = false,
  loading = false,
}: ExternalAppCardProps) {
  const externalSite = !!link;
  const finalLink = disableInstall && !connected ? "" : link || to || "";
  const target = externalSite ? "_blank" : undefined;
  const { posthog } = usePostHogHelpers();
  const { t } = useTranslation();
  const classes = classNames(
    disableInstall && !connected && "opacity-50 cursor-not-allowed"
  );
  if (loading) {
    return (
      <div className="flex flex-col rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 h-28 animate-pulse">
        <div className="space-x-3 min-w-0 flex-1 relative flex items-center">
          <div className="flex-shrink-0 w-10 h-10 bg-gray-200"></div>
          <div className="min-w-0 flex-1 ml-3">
            <div className="h-4 bg-gray-200 rounded w-4/5 mb-2"></div>
            <div className="h-3 bg-gray-200 rounded"></div>
          </div>
        </div>
        <div className="space-x-3 min-w-0 flex-1 inline-flex items-end justify-end mt-3">
          <div className="inline-flex items-center">
            <div className="h-4 bg-gray-200 rounded w-16"></div>
            <div className="h-4 bg-gray-200 rounded w-4 ml-1"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <ActiveAwareLink
      to={finalLink}
      target={target}
      onClick={(e) => {
        posthog?.capture("external app card clicked", {
          external_app_link: finalLink,
          external_app_title: title,
        });
        if (disableInstall && !connected) {
          e.preventDefault();
        }
      }}
      className={() => `focus:outline-none ${classes}`}
    >
      {() => (
        <div className="flex flex-col rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 h-28">
          <div
            key={key}
            className="space-x-3 min-w-0 flex-1 relative flex items-center"
          >
            <div className="flex-shrink-0">
              <img className="h-10 w-10" src={icon} alt="" />
            </div>
            <div className="min-w-0 flex-1">
              <>
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">
                  {useI18n ? t(title) : title}
                </p>
                <p className="truncate text-sm text-gray-500">
                  {useI18n ? t(description) : description}
                </p>
              </>
            </div>
          </div>
          <div className="space-x-3 min-w-0 flex-1 inline-flex items-end justify-end">
            {externalSite ? (
              <button className="text-sm font-semibold leading-6 text-[#FF4F00] opacity-80 inline-flex items-center">
                {t("use", "Use")}
                <ArrowRightIcon className="h-4 w-4 ml-1" />
              </button>
            ) : (
              <button className="text-sm font-semibold leading-6 text-hilos opacity-80 inline-flex items-center">
                {connected ? (
                  <>
                    {t("settings", "Settings")}
                    <CogIcon className="h-4 w-4 ml-1" />
                  </>
                ) : disableInstall ? null : (
                  <>
                    {t("install", "Install")}
                    <SaveIcon className="h-4 w-4 ml-1" />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      )}
    </ActiveAwareLink>
  );
}
