import { classNames } from "../../Helpers";

interface AvatarProps {
  name: string;
  image?: string;
  className?: string;
}

function Avatar({ name, image, className }: AvatarProps) {
  // Master of the 4 elements

  if (image) {
    return (
      <img
        className={classNames("rounded-full", className)}
        // @ts-ignore
        src={image}
        alt=""
      />
    );
  }

  return (
    <span
      className={classNames(
        "inline-flex items-center justify-center rounded-full bg-gray-500",
        className
      )}
    >
      <span className="font-medium leading-none text-white">
        {name.slice(0, 2).toUpperCase()}
      </span>
    </span>
  );
}

export default Avatar;
