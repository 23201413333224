import { useCallback, useMemo, useRef, useState } from "react";

function useOnScreen(): [
  boolean | null,
  React.RefCallback<Element>,
  () => void
] {
  const [isVisible, setIsVisible] = useState<boolean | null>(null);
  const currentElementRef = useRef<HTMLDivElement>();
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting)),
    [setIsVisible]
  );

  const setElementRef = useCallback(
    (elementRef: Element | null) => {
      if (elementRef) {
        currentElementRef.current = elementRef as HTMLDivElement;
        observer.observe(elementRef);
      } else {
        observer.disconnect();
        setIsVisible(false);
      }
    },
    [observer, setIsVisible]
  );

  const handleScrollInto = useCallback(() => {
    if (currentElementRef.current) {
      currentElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return [isVisible, setElementRef, handleScrollInto];
}

export default useOnScreen;
