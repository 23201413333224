import { Trans, useTranslation } from "react-i18next";
import {
  ArrowSmRightIcon,
  BellIcon,
  ChartSquareBarIcon,
  ChatIcon,
  ClipboardListIcon,
  CogIcon,
  ExclamationCircleIcon,
  InboxIcon,
  NewspaperIcon,
  ShareIcon,
  SparklesIcon,
  UserGroupIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { DateTime } from "luxon";
import { AnnouncementCard } from "@hilos/types/private-schema/models/AnnouncementCard";
import Loading from "src/components/Loading";
import RobotIcon from "src/components/icons/RobotIcon";
import WhatsAppIcon from "src/components/icons/WhatsAppIcon";
import useAnnouncements from "src/hooks/useAnnouncements";
import { AccountStatus } from "src/types/account";
import { ModuleEnum } from "src/types/private-schema/models/ModuleEnum";

export default function AnnouncementSection({ session }) {
  const { t } = useTranslation();

  const { data: announcementCards } = useAnnouncements();

  const getModuleIcon = (module: ModuleEnum, className?: string) => {
    switch (module) {
      case "FLOWS":
        return <ShareIcon className={`rotate-90 ${className}`} />;
      case "BROADCASTS":
        return <ChatIcon className={className} />;
      case "TEMPLATES":
        return <ClipboardListIcon className={className} />;
      case "SETTINGS":
        return <CogIcon className={className} />;
      case "CONTACTS":
        return <UserGroupIcon className={className} />;
      case "INBOX":
        return <InboxIcon className={className} />;
      case "CHATBOT":
        return <RobotIcon className={className} />;
      case "REPORTS":
        return <ChartSquareBarIcon className={className} />;
      case "INTEGRATIONS":
        return <ViewGridAddIcon className={className} />;

      case "WHATSAPP":
        return <WhatsAppIcon className={className} />;

      case "DEFAULT":
        return <SparklesIcon className={className} />;

      default:
        return <SparklesIcon className={className} />;
    }
  };
  return (
    <div>
      <div className="bg-white overflow-hidden relative rounded-lg p-4 lg:flex items-center hidden">
        <div className="z-0 h-56 -ml-10 absolute left-0 w-40 bg-hilos-alt-700 bg-opacity-5 rotate-12" />
        <div className="z-0 h-56 -ml-10 absolute left-0 w-36 bg-hilos-alt-700 bg-opacity-5 rotate-12" />
        <div className="h-8 w-8 shrink-0 mr-4 rounded-full bg-hilos-alt-700 flex items-center justify-center">
          <BellIcon className=" h-5 w-5 text-white" />
        </div>
        <div>
          <Trans i18nKey="dashboard:announcements.title">
            <h2 className="flex items-center text-lg font-bold leading-7 text-hilos  sm:truncate">
              Updates and notices
            </h2>
            <p className="text-xs font-medium text-gray-800">
              Stay informed about the latest updates and news about Hilos!
            </p>
          </Trans>
        </div>
      </div>
      <div className="lg:hidden mt-4">
        <Trans i18nKey="dashboard:announcements.title">
          <h2 className="mt-2 flex items-center text-xl font-bold leading-7 text-neutral-dark  sm:truncate">
            Updates and notices
          </h2>
          <p className="mt-1 text-sm text-neutral">
            Stay informed about the latest updates and news about Hilos!
          </p>
        </Trans>
      </div>
      <div className="my-4">
        {announcementCards ? (
          announcementCards.length > 0 ? (
            <>
              {announcementCards
                .filter((announcement) => announcement.type === "URGENT")
                .map(
                  (announcement: AnnouncementCard, index) =>
                    announcement.account_status_to_show?.includes(
                      session?.account?.status
                    ) && (
                      <div className="bg-hilos-alt-500 text-white rounded-md mb-2 px-4 py-4">
                        <div>
                          <div className="flex items-center">
                            <div className="shrink-0 rounded-full mr-4 aspect-square  bg-hilos-alt-300  bg-opacity-40 p-2 md:ml-2">
                              <ExclamationCircleIcon className="h-8 w-8" />
                            </div>
                            <div>
                              <p className=" font-semibold mb-1 text-base">
                                {announcement.title}
                              </p>
                              <p className="text-sm">
                                {announcement.description}
                              </p>
                            </div>
                          </div>
                          <div className="flex mt-2 items-end">
                            <div className="text-xs text-gray-200 text-right">
                              {announcement.created_on &&
                                DateTime.fromISO(
                                  announcement.created_on
                                ).toLocaleString(DateTime.DATETIME_SHORT)}
                            </div>
                            {announcement.button_url && (
                              <a
                                href={announcement.button_url}
                                rel="noreferrer"
                                target="_blank"
                                className="ml-auto inline-flex items-center rounded-md border border-white px-3 py-2 text-xs font-semibold text-white shadow-sm"
                              >
                                {announcement.button_label}
                                <ArrowSmRightIcon
                                  className="ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                )}
              {announcementCards
                .filter((announcement) => announcement.type === "MAIN")
                .map(
                  (announcement: AnnouncementCard, index) =>
                    announcement.account_status_to_show?.includes(
                      session?.account?.status
                    ) && (
                      <div className="border border-gray-200 p-5 mb-2 rounded-md bg-white">
                        <div className="flex">
                          <div className="mr-4">
                            <div className="w-16 h-16 flex justify-center items-center shrink-0 bg-neutral bg-opacity-25 rounded-full">
                              <div className="w-12 h-12 flex justify-center items-center shrink-0 bg-neutral rounded-full">
                                {announcement.module &&
                                  getModuleIcon(
                                    announcement.module,
                                    "h-6 w-6 text-white"
                                  )}
                              </div>
                            </div>
                            {announcement.module !== "DEFAULT" && (
                              <div className="text-center text-xs font-semibold px-2 py-1 rounded-md bg-blue-100 mt-2 text-blue-600">
                                {announcement.module}
                              </div>
                            )}
                          </div>
                          <div>
                            <p className=" font-semibold mb-1 text-base">
                              {announcement.title}
                            </p>
                            <p className="text-gray-500 text-sm">
                              {announcement.description}
                            </p>
                          </div>
                        </div>
                        <div className="w-full mt-4 flex justify-end items-center">
                          <div className="text-xs text-gray-400 mr-auto">
                            {announcement.created_on &&
                              DateTime.fromISO(
                                announcement.created_on
                              ).toLocaleString(DateTime.DATETIME_SHORT)}
                          </div>
                          {announcement.button_url && (
                            <a
                              href={announcement.button_url}
                              rel="noreferrer"
                              target="_blank"
                              className="inline-flex items-center rounded-md border border-hilos px-3 py-2 text-xs font-semibold text-hilos shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                            >
                              {announcement.button_label}
                              <ArrowSmRightIcon
                                className="ml-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    )
                )}
              <div className="grid grid-cols-2 gap-4">
                {announcementCards
                  .filter((announcement) => announcement.type === "DEFAULT")
                  .map(
                    (announcement: AnnouncementCard, index) =>
                      announcement.account_status_to_show?.includes(
                        session?.account?.status
                      ) && (
                        <div className="container col-span-1 flex max-h-[400px] flex-col rounded-lg border border-gray-200 bg-white p-6">
                          <div className="flex align-top items-start mb-4">
                            <div className="w-12 h-12 flex justify-center items-center shrink-0 bg-neutral bg-opacity-25 rounded-full">
                              <div className="w-8 h-8 flex justify-center items-center shrink-0 bg-neutral rounded-full">
                                {announcement.module &&
                                  getModuleIcon(
                                    announcement.module,
                                    "h-4 w-4 text-white"
                                  )}
                              </div>
                            </div>
                            <div className="text-xs ml-auto  font-semibold px-2 py-1 rounded-md bg-blue-100 mt-2 text-blue-600">
                              {announcement.module}
                            </div>
                          </div>
                          <div className="mb-2">
                            <p className=" font-semibold mb-1 text-base">
                              {announcement.title}
                            </p>
                            <p className="text-gray-500 text-xs">
                              {announcement.description}
                            </p>
                          </div>
                          <div className="text-xs text-gray-400">
                            {announcement.created_on &&
                              DateTime.fromISO(
                                announcement.created_on
                              ).toLocaleString(DateTime.DATETIME_SHORT)}
                          </div>
                          <div className="mt-auto pt-2 flex justify-end">
                            <div>
                              {announcement.button_url && (
                                <a
                                  href={announcement.button_url}
                                  rel="noreferrer"
                                  target="_blank"
                                  className="inline-flex items-center rounded-md border-neutral border px-3 py-2 text-xs font-semibold text-neutral focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                >
                                  {announcement.button_label}
                                  <ArrowSmRightIcon
                                    className="ml-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
            </>
          ) : (
            <>
              <div className=" mt-10 mb-4 flex items-center justify-center">
                <NewspaperIcon className="w-20 h-20 text-gray-200" />
              </div>
              <p className="text-center mb-10 font-semibold text-gray-300">
                {t("dashboard:announcements.empty", "There's no new updates")}
              </p>
            </>
          )
        ) : (
          <div className=" my-10 flex items-center justify-center">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
