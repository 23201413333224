import { Trans, useTranslation } from "react-i18next";
import SwitchField from "src/components/Form/SwitchField";
import AccountFormUpdate from "./AccountFormUpdate";
import * as meta from "./AccountMeta";

export default function AccountTemplateSettingsComponent() {
  const { t } = useTranslation();
  return (
    <AccountFormUpdate>
      {() => (
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <Trans i18nKey="settings:template.title">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Template settings
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Settings related to your templates.
                </p>
              </Trans>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="self-center bg-white px-4 py-4 sm:p-6">
                <SwitchField
                  name={meta.FIELDS.hide_sample_templates.key}
                  label={t(meta.FIELDS.hide_sample_templates.label)}
                  help={t(meta.FIELDS.hide_sample_templates.help)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </AccountFormUpdate>
  );
}
