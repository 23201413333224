import { useCallback } from "react";
import { UseMutateAsyncFunction, useMutation, useQuery } from "react-query";
import axios from "axios";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES } from "src/router/router";
import { OpenAISettings } from "@hilos/types/private-schema";


export type UpdateOpenAIDetailsFn = UseMutateAsyncFunction<
  OpenAISettings | null,
  unknown,
  OpenAISettings,
  unknown
>;

export function useOpenAISettings() {
  const fetchOpenAI = useCallback(async () => {
    const { data } = await axios.get<OpenAISettings>(API_ROUTES.AI_SETTINGS);
    return data;
  }, []);

  const { data, refetch, isLoading } = useQuery("openai_settings", fetchOpenAI);

  return { data, refetch, isLoading };
}

export default useOpenAISettings;

export function useOpenAISettingsUpdate() {
  const updateOpenAIAppDetails = useCallback(
    async (payload: OpenAISettings ) => {
      const { data } = await axios.patch<OpenAISettings>(
        API_ROUTES.AI_SETTINGS,
        payload
      );
      return data;
    },
    []
  );

  const {
    mutateAsync: handleUpdateOpenAISettings,
    isLoading: isSubmittingStatus,
  } = useMutation(updateOpenAIAppDetails, {
    onSuccess: (nextData) => {
      let currentAppData = queryClient.getQueryData<OpenAISettings>("openai_settings");
      if (currentAppData) {
        currentAppData = {
          ...currentAppData,
          ...nextData,
        };
      }
      queryClient.setQueryData("openai_settings", currentAppData);
    },
  });

  return {
    handleUpdateOpenAISettings,
    isSubmittingStatus,
  };
}
