import { useTranslation } from "react-i18next";
import { TagData } from "@hilos/types/conversation";
import { ContactListRead, ContactTag } from "@hilos/types/private-schema";
import { Label } from "src/components/ui/label";
import { useContactTags } from "src/hooks/useTags";
import MultipleSelector, { Option } from "./MultipleSelector";

interface TagsSelectorProps {
  contact: ContactListRead;
}

const getOptionsFromTags = (tags: (TagData | ContactTag)[]): Option[] =>
  tags.map((tag) => ({ value: tag.name, label: tag.name }));

export default function TagsSelector({ contact }: TagsSelectorProps) {
  const { t } = useTranslation();
  const { tags } = useContactTags();

  return (
    <div className="space-y-2">
      <Label htmlFor="email">{t("inbox:contact-tags", "Contact Tags")}</Label>
      <MultipleSelector
        value={getOptionsFromTags(contact.tags)}
        options={getOptionsFromTags(tags)}
        creatable
        hideClearAllButton
        className="bg-white p-1 min-h-6 focus:ring"
        badgeClassName="rounded h-6"
        inputProps={{ className: "h-6" }}
      />
    </div>
  );
}
