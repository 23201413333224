import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowSmRightIcon, CodeIcon } from "@heroicons/react/outline";
import { buildRoute } from "src/router/router";

export default function OutboundAnyTriggerDetail({ flow }) {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("run-flow", "Run flow")}
      </h3>
      <div className="mt-2 text-sm text-gray-900 space-y-3">
        <Trans i18nKey="flow-executions:outbound.run-flow-instructions">
          <div className="space-y-2">
            <p>
              Start an outbound automated campaign for one or more contacts
              easily by uploading a .csv, filtering from your current contacts,
              or via our API.
            </p>
            <p>How would you like to do so?</p>
          </div>
        </Trans>

        <div className="flex items-center justify-between gap-x-2">
          <a
            href="https://hilos.io/docs/apidocs/flow-execution/post-apiflow-run"
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
          >
            <CodeIcon className="h-5 w-5 text-gray-500 mr-1" />
            {t("flow-executions:outbound.run.from-api", "From API")}
          </a>
          <Link
            to={buildRoute("flow-execution-create", {
              ":id": flow.id,
            })}
            className="inline-flex items-center rounded-md border border-hilos bg-gradient-to-r from-hilos to-red-400 px-3 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
          >
            {t("flow-executions:outbound.run.from-csv", "From CSV")}
            <ArrowSmRightIcon className="h-5 w-5 text-white mr-1" />
          </Link>
          <Link
            to={buildRoute("flow-execution-create", {
              ":id": flow.id,
            })}
            className="inline-flex items-center rounded-md border border-hilos bg-gradient-to-r from-hilos to-red-400 px-3 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
          >
            {t("flow-executions:outbound.run.from-contacts", "From contacts")}
            <ArrowSmRightIcon className="h-5 w-5 text-white mr-1" />
          </Link>
        </div>
      </div>
    </>
  );
}
