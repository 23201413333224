import * as meta from "./ContactMeta";

import {
  ArrowSmRightIcon,
  GlobeAltIcon,
  MailIcon,
  PhoneIcon,
  PlusSmIcon,
} from "@heroicons/react/outline";
import { FieldArray, Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";

import APIErrors from "../../components/base/APIErrors";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import CreateVariableModal from "./CreateVariableModal";
import StateButton from "../../components/StateButton";
import TextInputField from "../../components/Form/TextInputField";
import { useCustomFields } from "src/hooks/useContactCustomField";

export default function ContactForm({
  contact,
  formSubmit,
  success,
  submitted,
  action,
}) {
  const { t } = useTranslation();
  const { contactCustomFields } = useCustomFields();
  const [modalOpen, setModalOpen] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const newFieldRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = (values, setSubmitting) => {
    const formData = { ...values };
    const metaAttributes = {};
    formData.meta.forEach((attr) => {
      if (attr.value) {
        metaAttributes[attr.key] = attr.value;
      }
    });
    formData.meta = metaAttributes;
    formData.source = "website";

    formSubmit(
      formData,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  const initialContactValues = useMemo(() => {
    const initialMetaValues = contact.meta || [];

    for (const field of contactCustomFields || []) {
      if (
        !initialMetaValues.some((metaField) => metaField.key === field.name)
      ) {
        initialMetaValues.push({ key: field.name, value: "" });
      }
    }

    return {
      ...contact,
      meta: initialMetaValues,
    };
  }, [contact, contactCustomFields]);

  useEffect(() => {
    if (!modalOpen && newFieldRef.current) {
      newFieldRef.current.focus();
    }
  }, [modalOpen]);

  return (
    <Formik
      validationSchema={meta.schema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
      initialValues={initialContactValues}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            ></APIErrors>
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="contacts:contact-profile">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Profile
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Basic information about this contact. The only required
                    field is their phone.
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3">
                      <TextInputField
                        placeholder={t(meta.FIELDS.phone.placeholder)}
                        label={t(meta.FIELDS.phone.label)}
                        name={meta.FIELDS.phone.key}
                        help={t(meta.FIELDS.phone.help)}
                        type="tel"
                        icon={PhoneIcon}
                      />
                    </div>

                    <div className="col-span-3">
                      <TextInputField
                        placeholder={t(meta.FIELDS.first_name.placeholder)}
                        label={t(meta.FIELDS.first_name.label)}
                        name={meta.FIELDS.first_name.key}
                        help={t(meta.FIELDS.first_name.help)}
                        optional={meta.FIELDS.first_name.optional}
                        type="text"
                      />
                    </div>
                    <div className="col-span-3">
                      <TextInputField
                        placeholder={t(meta.FIELDS.last_name.placeholder)}
                        label={t(meta.FIELDS.last_name.label)}
                        name={meta.FIELDS.last_name.key}
                        help={t(meta.FIELDS.last_name.help)}
                        optional={meta.FIELDS.last_name.optional}
                        type="text"
                      />
                    </div>
                    <div className="col-span-3">
                      <TextInputField
                        placeholder={t(meta.FIELDS.email.placeholder)}
                        label={t(meta.FIELDS.email.label)}
                        name={meta.FIELDS.email.key}
                        help={t(meta.FIELDS.email.help)}
                        optional={meta.FIELDS.email.optional}
                        type="email"
                        icon={MailIcon}
                      />
                    </div>
                    <div className="col-span-3">
                      <TextInputField
                        placeholder={t(meta.FIELDS.external_url.placeholder)}
                        label={t(meta.FIELDS.external_url.label)}
                        name={meta.FIELDS.external_url.key}
                        help={t(meta.FIELDS.external_url.help)}
                        optional={meta.FIELDS.external_url.optional}
                        type="text"
                        icon={GlobeAltIcon}
                      />
                    </div>

                    <div className="col-span-3">
                      <ChannelSelectField
                        name={meta.FIELDS.default_channel.key}
                        label={t(meta.FIELDS.default_channel.label)}
                        help={t(meta.FIELDS.default_channel.help)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 sm:mt-4">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <Trans i18nKey="contacts:additional-attributes">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Additional attributes
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Set any number of additional attributes and use them to
                      filter contacts or as variables in messages.
                    </p>
                  </Trans>
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="flex flex-row justify-end">
                      <button
                        type="button"
                        className="mb-2 ml-auto inline-flex items-center rounded-md border border-indigo-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-indigo-600 shadow-sm hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={(_) => setModalOpen(true)}
                      >
                        <PlusSmIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {t("add", "Add")}
                      </button>
                    </div>
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3">
                        <FieldArray
                          name="meta"
                          render={(arrayHelpers) => (
                            <>
                              <div className="space-y-2">
                                {contactCustomFields.map((field) => {
                                  const metaIndex =
                                    formik.values.meta.findIndex(
                                      (metaField) =>
                                        metaField.key === field.name
                                    );
                                  const metaValue =
                                    formik.values.meta[metaIndex]?.value || "";

                                  return (
                                    <div
                                      className="grid grid-cols-2 items-center justify-between space-x-3"
                                      key={field.name}
                                    >
                                      <div className="grow">
                                        <label className="block text-sm font-medium text-gray-700">
                                          {field.name}
                                        </label>
                                      </div>
                                      <div className="grow">
                                        <TextInputField
                                          name={`meta.${metaIndex}.value`}
                                          placeholder={t(
                                            meta.FIELDS.meta.value.placeholder
                                          )}
                                          type="text"
                                          value={metaValue}
                                          onChange={formik.handleChange}
                                          innerRef={
                                            metaIndex ===
                                            formik.values.meta.length - 1
                                              ? newFieldRef
                                              : null
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {modalOpen && (
                                <CreateVariableModal
                                  isOpen={modalOpen}
                                  onClose={() => setModalOpen(false)}
                                  addVariable={(variable) => {
                                    arrayHelpers.push({
                                      key: variable,
                                      value: "",
                                    });
                                  }}
                                  inputValue=""
                                />
                              )}
                            </>
                          )}
                        ></FieldArray>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="text-right">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                disabled={
                  formik.errors &&
                  Object.getOwnPropertyNames(formik.errors).length > 0
                }
                submittingText={
                  action === "create"
                    ? t("creating", "Creating...")
                    : t("updating", "Updating...")
                }
                successText={t("changes-saved", "Changes saved!")}
                initialText={
                  <>
                    {action === "create"
                      ? t("create", "Create")
                      : t("update", "Update")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              ></StateButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
