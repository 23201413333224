import { useTranslation } from "react-i18next";

function DropzoneContainer() {
  const { t } = useTranslation();
  return (
    <div className="flex h-full items-center justify-center bg-gray-100">
      <div className="self-center text-center text-gray-500">
        <h2>{t("drop-files", "Drop your files here")}</h2>
      </div>
    </div>
  );
}

export default DropzoneContainer;
