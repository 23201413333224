import { API_ROUTES, buildAPIRoute } from "src/router/router";

import { ContactCustomField } from "@hilos/types/private-schema";
import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "react-query";

export function useCustomField(id: string) {
  const fetchCustomField = useCallback(async () => {
    const { data } = await axios.get<ContactCustomField>(
      buildAPIRoute(API_ROUTES.CONTACT_CUSTOM_FIELD_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data: customField, refetch } = useQuery(
    ["custom_field_detail", id],
    fetchCustomField
  );

  const updateCustomField = useCallback(
    async (data) => {
      const response = await axios.patch<ContactCustomField>(
        buildAPIRoute(API_ROUTES.CONTACT_CUSTOM_FIELD_DETAIL, {
          ":id": id,
        }),
        data
      );
      refetch();
      return response;
    },
    [id, refetch]
  );

  return {
    customField,
    updateCustomField,
  };
}

export function useCustomFields() {
  const fetchCustomFields = useCallback(async () => {
    const { data } = await axios.get<ContactCustomField[]>(
      buildAPIRoute(API_ROUTES.CONTACT_CUSTOM_FIELD_CREATE_LIST)
    );
    return data;
  }, []);

  const { data, refetch } = useQuery(["custom_fields"], fetchCustomFields);

  const createCustomField = useCallback(
    async (dataCustomField) => {
      const response = await axios.post<ContactCustomField>(
        buildAPIRoute(API_ROUTES.CONTACT_CUSTOM_FIELD_CREATE_LIST),
        dataCustomField
      );
      if (response.data) {
        await refetch();
      }
    },
    [refetch]
  );

  return {
    contactCustomFields: data || [],
    createCustomField,
  };
}
