import { useTranslation } from "react-i18next";
import { HandIcon } from "@heroicons/react/outline";
import useHilosStore from "src/hooks/useHilosStore";

const AdminWrap = ({ children, ...props }) => {
  const { session } = useHilosStore();
  const { t } = useTranslation();

  if (session?.role.name !== "Admin" && !props.page) {
    return null;
  } else if (session?.role.name !== "Admin" && props.page) {
    <div className="flex flex-col items-center justify-center h-screen">
      <HandIcon className="w-16 h-16 mb-3 text-gray-800" />
      <h1 className="text-4xl font-bold text-gray-800">
        {t("adminOnly.title", "Admin area.")}
      </h1>
      <p>
        {t(
          "adminOnly.subtitle",
          "This page is only accessible to manager roles"
        )}
      </p>
    </div>;
  }
  return children;
};

export default AdminWrap;
