import { API_ROUTES, buildAPIRoute } from "src/router/router";
import axios, { AxiosError } from "axios";
import useQuickReplies, { QuickReplyData } from "src/hooks/useQuickReplies";

import APIErrors from "src/components/base/APIErrors";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import { FormikProps } from "formik";
import { InboxContactRead } from "@hilos/types/private-schema";
import QuickRepliesTemplateSelectList from "./QuickRepliesTemplateSelectList";
import QuickReplyTemplateSend from "./QuickReplyTemplateSend";
import { SearchIcon } from "@heroicons/react/outline";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import { getRenderedMessage } from "src/helpers/template";
import { useCustomFields } from "src/hooks/useContactCustomField";
import useHilosStore from "src/hooks/useHilosStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface QuickReplyTemplateSelectProps {
  inboxContact: InboxContactRead;
  onClose: () => void | null;
  setShowQRModal: (f) => void;
  formik: FormikProps<any>;
}

export default function QuickReplyTemplateSelect({
  onClose,
  inboxContact,
  setShowQRModal,
  formik,
}: QuickReplyTemplateSelectProps) {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState<
    WhatsAppTemplate | undefined
  >(undefined);
  const [tab, setTab] = useState("select");
  const [loading, setLoading] = useState(false);
  const [templateVariables, setTemplateVariables] = useState<string[]>([]);
  const {
    pages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    handleChangeSearch,
  } = useQuickReplies(inboxContact.channel);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const { session } = useHilosStore();
  const { contactCustomFields } = useCustomFields();

  const getRenderedVariables = (variables) => {
    if (inboxContact.contact && session) {
      Object.entries(variables).forEach(([key, value]) => {
        if (Array.isArray(value))
          value.map(
            (val, idx) =>
              (variables[key][idx] = getRenderedMessage(
                val,
                inboxContact.contact,
                session,
                contactCustomFields
              ))
          );
        else if (typeof value === "string")
          variables[key] = getRenderedMessage(
            value,
            inboxContact.contact,
            session,
            contactCustomFields
          );
      });

      return variables;
    }
  };

  const handleSelectQuickReplyTemplate = async (quickReply: QuickReplyData) => {
    setLoading(true);
    setBackendValidationErrors({});
    setBackendError("");

    if (quickReply.template_variables) {
      quickReply.template_variables = getRenderedVariables(
        quickReply.template_variables
      );
    }

    try {
      const { data } = await axios.get(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE, {
          ":id": quickReply.template?.id,
        })
      );
      if (data) {
        setSelectedTemplate(data);
        setTab("send");
        handleChangeSearch("");

        if (quickReply.template_variables)
          setTemplateVariables(quickReply.template_variables);
      }
    } catch (err) {
      type axiosErr =
        | AxiosError<{ status?: number; data?: unknown }, unknown>
        | undefined;
      const errorAxios: axiosErr = err as axiosErr;

      if (errorAxios?.response?.status === 404) {
        setBackendError(
          t(
            "inbox:quick-replies.template-not-found-error",
            "Template not found, be sure this template is still available in your template list."
          )
        );
      } else if (errorAxios?.response?.status === 400) {
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "inbox:quick-replies.template-general-error",
            "There was an error loading this template."
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <APIErrors
        APIError={backendError}
        APIValidationErrors={backendValidationErrors}
        fieldTranslations={{}}
      />
      {tab === "select" && (
        <div className="space-y-4 overflow-clip">
          {session?.account.has_multiple_active_channels && (
            <ChannelSelectField
              name="channel"
              label={t("inbox:channel-select.label", "Select a channel")}
              contactId={inboxContact.contact.id}
            />
          )}
          <div className="relative w-full shrink-0">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4 sm:pl-6">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              className="block w-full rounded-lg border border-gray-200 py-2 pl-12 pr-4 text-gray-700 placeholder:text-gray-400 focus:ring-0 sm:pl-14 sm:text-sm"
              type="search"
              placeholder={t("search", "Search...")}
              onChange={(ev) => handleChangeSearch(ev.target.value)}
            />
          </div>
          <div className="mt-2 max-h-64 overflow-y-auto rounded-lg bg-gray-50">
            <QuickRepliesTemplateSelectList
              pages={pages}
              isLoading={isLoading}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              onNextPage={fetchNextPage}
              contact={inboxContact.contact}
              onCloseModal={onClose}
              handleSelectQuickReplyTemplate={handleSelectQuickReplyTemplate}
              setShowQRModal={setShowQRModal}
              channel={formik.values.channel}
            />
          </div>
        </div>
      )}
      {tab === "send" && (
        <div>
          <QuickReplyTemplateSend
            contact={inboxContact.contact}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            loading={loading}
            onCloseModal={onClose}
            variables={templateVariables}
            onCancel={() => setTab("select")}
          />
        </div>
      )}
    </div>
  );
}
