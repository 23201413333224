import { useTranslation } from "react-i18next";
import { FlowExecutionStatusEnum } from "@hilos/types/private-schema";
import LoaderSpinner from "src/components/LoaderSpinner";

interface FlowExecutionStatusBadgeProps {
  status: FlowExecutionStatusEnum;
}

export default function FlowExecutionStatusBadge({
  status,
}: FlowExecutionStatusBadgeProps) {
  const { t } = useTranslation();
  switch (status) {
    case "PROCESSING":
      return (
        <div className="inline-flex gap-2 uppercase items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800">
          {t("flows:status.processing", "Processing contacts")}{" "}
          <span className="-mr-3">
            <LoaderSpinner />
          </span>
        </div>
      );
    case "AWAITING_CONFIRMATION":
      return (
        <span className="inline-flex uppercase items-center rounded-full bg-yellow-100 px-3 py-0.5 text-xs font-medium text-yellow-800">
          {t("flows:status.awaiting-confirmation", "Awaiting confirmation")}
        </span>
      );
    case "RUNNING":
      return (
        <span className="inline-flex uppercase items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium text-indigo-800">
          {t("flows:status.running", "Running")}
        </span>
      );
    case "COMPLETED":
      return (
        <span className="inline-flex uppercase items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium text-green-800">
          {t("flows:status.completed", "Completed")}
        </span>
      );
    case "CANCELED":
      return (
        <span className="inline-flex uppercase items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium text-red-800">
          {t("flows:status.canceled", "Canceled")}
        </span>
      );
    default:
      return (
        <span className="inline-flex uppercase items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium text-gray-800">
          {status}
        </span>
      );
  }
}
