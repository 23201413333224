import { OperationVariables } from "@apollo/client";
import {
  SubscribeToMoreOptions,
  UpdateQueryFn,
} from "@apollo/client/core/watchQueryOptions";
import {
  DocumentIcon,
  EmojiHappyIcon,
  LocationMarkerIcon,
  MicrophoneIcon,
  MusicNoteIcon,
  PhotographIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/solid";
import axios from "axios";
import {
  addDays,
  compareAsc,
  compareDesc,
  isValid,
  parseJSON,
  roundToNearestMinutes,
} from "date-fns";
import set from "lodash/set";
import {
  ComparisonOperators,
  ConversationsFilter,
} from "@hilos/containers/inbox/InboxMeta";
import { InboxParamsValues } from "@hilos/containers/inbox/InboxParamsForm";
import { InboxContactViewData } from "@hilos/hooks/useInboxContactViews";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import { InboxContactReadV2Query } from "@hilos/types/conversation";
import { InboxParams, SessionData } from "@hilos/types/hilos";
import { InboxContactRead, MessageTypeEnum } from "@hilos/types/private-schema";
import { getTranslationPayload as t } from "src/i18n";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import {
  INBOX_CONTACTS_UPDATE_LIVE_QUERY_SUBSCRIPTION,
  INBOX_CONTACTS_UPDATE_STREAMING_SUBSCRIPTION,
  INBOX_CONTACT_DATA_UPDATE_LIVE_QUERY_SUBSCRIPTION,
} from "./queries";
import { hasItems } from "./utils";

export function decodeParams(data) {
  if (!data) {
    return null;
  }

  try {
    const decoded = atob(data);
    const value = JSON.parse(decoded);

    if (!value) {
      return null;
    }

    return value;
  } catch {
    return null;
  }
}

export function encodeParams(data) {
  if (!data) {
    return null;
  }
  try {
    const stringified = JSON.stringify(data);
    const encoded = btoa(stringified);
    if (!encoded) {
      return null;
    }

    return encoded.replace(/={1,2}$/, "");
  } catch {
    return null;
  }
}

export function getSearchParam<T>(
  searchParams: URLSearchParams,
  name: string
): T | null {
  try {
    return decodeParams(searchParams.get(name)) || null;
  } catch {
    return null;
  }
}

export function getValuesFromViewData(
  view: InboxContactViewData
): InboxParamsValues {
  if (typeof view.data === "string") {
    const data = JSON.parse(view.data);
    let ordering = data.ordering || null;

    if (data.sort && data.sort.field && data.sort.direction) {
      const isDesc = data.sort.direction === "DESC";
      const paramPrefixValue = isDesc ? "-" : "";
      const paramValue = data.sort.field;

      ordering = paramPrefixValue + paramValue;
    }

    return {
      view,
      ordering,
      filters: data.filters || [],
    };
  }
  const { filters, ordering } = view.data;

  return {
    view,
    ordering,
    filters,
  };
}

export function getWhatsAppMessageTypeIcon(type?: MessageTypeEnum) {
  switch (type) {
    case "sticker":
      return EmojiHappyIcon;
    case "document":
      return DocumentIcon;
    case "audio":
      return MusicNoteIcon;
    case "location":
      return LocationMarkerIcon;
    case "image":
      return PhotographIcon;
    case "video":
      return VideoCameraIcon;
    case "voice":
      return MicrophoneIcon;
    case "contacts":
      return UsersIcon;
    default:
      return null;
  }
}

export function getWhatsAppMessageTypeLabel(type?: MessageTypeEnum) {
  switch (type) {
    case "sticker":
      return t("inbox:message-type-label.sticker", "Sticker");
    case "document":
      return t("inbox:message-type-label.document", "Document");
    case "audio":
      return t("inbox:message-type-label.audio", "Audio");
    case "location":
      return t("inbox:message-type-label.location", "Location");
    case "image":
      return t("inbox:message-type-label.image", "Image");
    case "video":
      return t("inbox:message-type-label.video", "Video");
    case "voice":
      return t("inbox:message-type-label.voice", "Voice");
    case "contacts":
      return t("inbox:message-type-label.contacts", "Contacts");
    default:
      return null;
  }
}

export const getCleanedFilters = (prevFilters: ConversationsFilter[]) => {
  const nextFilters: ConversationsFilter[] = [];
  let hasLegacyFilters = false;

  for (const filter of prevFilters) {
    if (filter.field && filter.value !== undefined) {
      // @ts-ignore
      if (filter.comparison) {
        const operator: keyof ComparisonOperators | undefined = {
          __exact: "_eq",
          __notexact: "_neq",
          __isnull: "_is_null",
          __gte: "_gte",
          __gt: "_gt",
          __lte: "_lte",
          __lt: "_lt",
          __in: "_in",
          // @ts-ignore
        }[filter.comparison];

        if (!operator) {
          continue;
        }
        hasLegacyFilters = true;
        nextFilters.push({
          field:
            {
              contact__tags: "contact_tags",
              conversation__tags: "last_conversation_tags",
              client_read: "last_outbound_message_was_read",
              account_has_answered: "last_message_is_outbound",
            }[filter.field] || filter.field,
          operator,
          value: filter.value,
        });
      } else {
        nextFilters.push(filter);
      }
    }
  }

  return { hasLegacyFilters, nextFilters };
};

export function getInboxParamsFromView(view: InboxContactViewData) {
  const viewInboxParams = getValuesFromViewData(view);
  const { nextFilters } = getCleanedFilters(viewInboxParams.filters);

  return {
    ...viewInboxParams,
    filters: nextFilters,
  };
}

export async function getInboxParams(token: string) {
  if (token) {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const viewId = getSearchParam<string>(searchParams, "view");
      const search = getSearchParam<string>(searchParams, "search");
      let view: InboxContactViewData | null = null;

      if (viewId) {
        const result = await axios.get<InboxContactViewData>(
          buildAPIRoute(API_ROUTES.INBOX_CONTACT_VIEW_DETAIL, {
            ":id": viewId,
          }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status === 200 && result.data) {
          view = result.data;
        }

        if (view) {
          return {
            ...getInboxParamsFromView(view),
            search,
          };
        }
      }

      const ordering = getSearchParam<string>(searchParams, "ordering");
      const filters =
        getSearchParam<ConversationsFilter[]>(searchParams, "filters") || [];

      if (ordering || hasItems(filters)) {
        const { hasLegacyFilters, nextFilters } = getCleanedFilters(filters);
        if (hasLegacyFilters) {
          const nextEncodedFilters = encodeParams(nextFilters);
          if (nextEncodedFilters) {
            searchParams.set("filters", nextEncodedFilters);
          }
        }
        return {
          view,
          search,
          ordering,
          filters,
        };
      }
    } catch {}
  }

  return {
    view: null,
    search: null,
    ordering: "-last_message_on",
    filters: [],
  } as InboxParams;
}

export function getConversationCloseDate(lastMsgTimestamp: Date) {
  return addDays(lastMsgTimestamp, 1);
}

export function getScheduleMessageFromDate() {
  return roundToNearestMinutes(new Date(), {
    nearestTo: 30,
    roundingMethod: "ceil",
  });
}

const getQueryPathByKey = (
  key: string,
  allowQueriesWithSQLFunctions: boolean
) => {
  if (allowQueriesWithSQLFunctions) {
    return {
      status: "last_conversation__status",
      is_unread: "is_unread",
      is_archived: "is_archived",
      last_message_on: "last_message_on",
      last_message_status: "last_message__status",
      channel: "channel_id",
      contact_tags: "contact_tags",
      last_conversation_tags: "conversation_tags",
      assigned: "conversation_assigned",
      assigned_to_current_user: "conversation_assigned",
    }[key];
  }

  return {
    status: "last_conversation.status",
    is_unread: "is_unread",
    is_archived: "is_archived",
    last_message_on: "last_message_on",
    last_message_status: "last_message.status",
    channel: "channel_id",
    contact_tags: "contact.tags",
    last_conversation_tags: "last_conversation.tags",
    assigned: "last_conversation.assigned",
    assigned_to_current_user: "last_conversation.assigned",
  }[key];
};

function getSearchQuery(search: string, fields: string[] = []) {
  const nextSearchQuery: object[] = [];
  for (const field of fields) {
    nextSearchQuery.push(set({}, `${field}._ilike`, `%${search}%`));
  }
  return nextSearchQuery;
}

const getAvailableChannelIds = (channels: ChannelAvailabilityData[] = []) =>
  channels.reduce<number[]>((nextAvailableChannelIds, channel) => {
    if (channel.is_available && channel.channel.id) {
      nextAvailableChannelIds.push(channel.channel.id);
    }
    return nextAvailableChannelIds;
  }, []);

export function getQueryFilters({
  session,
  inboxParams,
  currentAvailableChannels = [],
  ignoreSearch = false,
  allowSearchByLastMessage,
  allowQueriesWithSQLFunctions = false,
}: {
  session: SessionData | null;
  inboxParams: InboxParams;
  currentAvailableChannels?: ChannelAvailabilityData[];
  ignoreSearch?: boolean;
  allowSearchByLastMessage?: boolean;
  allowQueriesWithSQLFunctions?: boolean;
}) {
  const { filters, search } = inboxParams;
  const nextQueryFilters: object[] = [];

  const currentAvailableChannelIds = getAvailableChannelIds(
    currentAvailableChannels
  );

  if (search && !ignoreSearch && !allowQueriesWithSQLFunctions) {
    const nextContactSearchQuery = getSearchQuery(search, [
      "phone",
      "first_name",
      "last_name",
    ]);

    if (allowSearchByLastMessage) {
      const nextSearchQuery = [
        {
          contact: {
            _or: nextContactSearchQuery,
          },
        },
        ...getSearchQuery(search, ["last_message.body"]),
      ];

      nextQueryFilters.push({
        _or: nextSearchQuery,
      });
    } else {
      nextQueryFilters.push({
        contact: {
          _or: nextContactSearchQuery,
        },
      });
    }
  }

  if (session) {
    if (
      !session.account.agents_can_see_all_conversations &&
      session.role.name !== "Admin"
    ) {
      nextQueryFilters.push({
        last_conversation: {
          assigned: {
            user_id: {
              _eq: session.id,
            },
          },
        },
      });
    }
  }

  nextQueryFilters.push({
    channel_id: {
      _in: currentAvailableChannelIds,
    },
  });

  for (const filter of filters) {
    if (filter.field && filter.operator && filter.value !== undefined) {
      const fieldVariable = {};
      const fieldKeyPath = getQueryPathByKey(
        filter.field,
        allowQueriesWithSQLFunctions
      );

      switch (filter.field) {
        case "assigned_to_current_user":
          if (session?.id) {
            const prefixFieldPath = filter.value ? "" : "_not.";

            set(
              fieldVariable,
              `${prefixFieldPath}${fieldKeyPath}.user_id._eq`,
              session.id
            );
          }
          break;
        case "last_message_is_outbound":
          set(
            fieldVariable,
            "last_message.direction._eq",
            filter.value ? "OUTBOUND" : "INBOUND"
          );
          break;
        case "last_outbound_message_was_read":
          set(fieldVariable, "last_message.direction._eq", "OUTBOUND");
          set(
            fieldVariable,
            `last_message.status.${filter.value ? "_eq" : "_neq"}`,
            "read"
          );
          break;
        case "assigned":
        case "contact_tags":
        case "last_conversation_tags":
          if (filter.operator === "_is_null") {
            set(
              fieldVariable,
              `${fieldKeyPath}_aggregate.count.predicate.${
                filter.value ? "_eq" : "_neq"
              }`,
              0
            );
            break;
          }

          const relatedFieldKey = {
            assigned: "user_id",
            contact_tags: "contacttag_id",
            last_conversation_tags: "conversationtag_id",
          }[filter.field];

          if (relatedFieldKey) {
            const prefixFieldPath = filter.operator === "_eq" ? "" : "_not.";

            set(
              fieldVariable,
              `${prefixFieldPath}${fieldKeyPath}.${relatedFieldKey}._eq`,
              filter.value
            );
          }

          break;
        default:
          set(
            fieldVariable,
            `${fieldKeyPath}.${filter.operator}`,
            filter.value
          );
          break;
      }
      nextQueryFilters.push(fieldVariable);
    }
  }

  if (!hasItems(nextQueryFilters)) {
    return {};
  }

  return {
    _and: nextQueryFilters,
  };
}

export function getQueryOrderBy(inboxParams: InboxParams) {
  const { ordering } = inboxParams;

  if (!ordering) {
    return {
      last_message_on: "desc",
    };
  }

  return {
    [ordering.replace("-", "")]: ordering[0] === "-" ? "desc" : "asc",
  };
}

export const getLimitByCursor = (
  data: InboxContactReadV2Query,
  lastInboxContactCursor: string | null,
  offset = 10
) => {
  if (data?.api_inboxcontact) {
    const inboxContactDataLength = data.api_inboxcontact.length;
    if (inboxContactDataLength <= offset) {
      return null;
    }

    if (lastInboxContactCursor) {
      let itemsAfterLastCursor = 0;
      for (const inboxContact of data.api_inboxcontact) {
        if (
          itemsAfterLastCursor > 0 ||
          !lastInboxContactCursor ||
          inboxContact.id === lastInboxContactCursor
        ) {
          itemsAfterLastCursor += 1;
          if (itemsAfterLastCursor > offset) {
            return data.api_inboxcontact[inboxContactDataLength - 1]
              ?.last_message_on;
          }
        }
      }
    } else {
      return data.api_inboxcontact[offset]?.last_message_on;
    }
  }
  return null;
};

export const getSubscribeToMoreOptions = ({
  allowStreamingSubscription,
  allowQueriesWithSQLFunctions,
  allowLastConversationSubscription,
  limitByCursor,
  filters,
  orderBy,
  cursor,
  extraParams,
  inboxContactId = null,
  lastUpdatedOn = new Date(),
}: {
  allowStreamingSubscription?: boolean;
  allowQueriesWithSQLFunctions?: boolean;
  allowLastConversationSubscription?: boolean;
  limitByCursor: string | null;
  filters: { _and?: object[] };
  orderBy: { [key: string]: string };
  cursor: string | null;
  extraParams: { [key: string]: any };
  inboxContactId?: string | null;
  lastUpdatedOn: Date;
}): SubscribeToMoreOptions<
  InboxContactReadV2Query,
  OperationVariables,
  any
> => {
  const hasSearchParam = "search" in extraParams;

  if (
    allowStreamingSubscription &&
    !(allowQueriesWithSQLFunctions && hasSearchParam)
  ) {
    const filtersWithInboxContact =
      allowLastConversationSubscription && inboxContactId && filters["_and"]
        ? { _or: [{ id: { _eq: inboxContactId } }, filters] }
        : filters;

    return {
      updateQuery: updateInboxContactDataStreaming(
        orderBy,
        allowQueriesWithSQLFunctions
      ),
      document: INBOX_CONTACTS_UPDATE_STREAMING_SUBSCRIPTION,
      variables: {
        filters: filtersWithInboxContact,
        last_updated_on: lastUpdatedOn,
      },
    };
  }
  const variables = getVariablesWithCursor({
    filters,
    orderBy,
    cursor,
    extraParams,
    limitByCursor,
    ignoreOrderBy: true,
  });

  return {
    updateQuery: updateInboxContactDataLiveQuery(allowQueriesWithSQLFunctions),
    document: allowQueriesWithSQLFunctions
      ? INBOX_CONTACT_DATA_UPDATE_LIVE_QUERY_SUBSCRIPTION
      : INBOX_CONTACTS_UPDATE_LIVE_QUERY_SUBSCRIPTION,
    variables,
  };
};

const getOperatorKeyFromOrderBy = ({
  orderBy,
  reversed = false,
  ignoreOrderBy = false,
}: {
  orderBy: any;
  reversed?: boolean;
  ignoreOrderBy?: boolean;
}) => {
  let key = reversed ? "_gt" : "_lt";

  const { field: orderByField, direction: orderDirection } =
    getOrderBy(orderBy)[0];

  if (!ignoreOrderBy && orderDirection === "asc") {
    key = reversed ? "_lt" : "_gt";
  }

  return { orderByField, key };
};

export const getVariablesWithCursor = ({
  filters,
  orderBy,
  cursor = null,
  extraParams,
  limitByCursor = null,
  ignoreOrderBy = false,
}: {
  filters: any;
  orderBy: any;
  cursor?: string | null;
  extraParams: { [key: string]: any };
  limitByCursor?: string | null;
  ignoreOrderBy?: boolean;
}) => {
  const cursorFilters: { [key: string]: any }[] = [];

  if (cursor) {
    const { orderByField, key } = getOperatorKeyFromOrderBy({
      orderBy,
      ignoreOrderBy,
    });

    cursorFilters.push({
      [orderByField]: {
        [key]: cursor,
      },
    });
  }

  if (limitByCursor && cursor !== limitByCursor) {
    const { orderByField, key } = getOperatorKeyFromOrderBy({
      orderBy,
      reversed: true,
    });

    cursorFilters.push({
      [orderByField]: {
        [key]: limitByCursor,
      },
    });
  }

  if (!cursorFilters.length) {
    return {
      ...extraParams,
      orderBy,
      filters,
    };
  }

  return {
    ...extraParams,
    orderBy,
    filters: {
      _and: [...cursorFilters, ...(filters["_and"] || [])],
    },
  };
};

export const compareValuesByField = (
  prev: object,
  next: object,
  field: string,
  isGreaterThan: boolean
) => (isGreaterThan ? prev[field] > next[field] : prev[field] < next[field]);

export const getCursorItems = (data) => {
  const count = data?.length || 0;
  return {
    count,
    first: data[0] || null,
    last: data[count - 1] || null,
  };
};

export const getOrderBy = (orderBy: { [key: string]: string } | null = null) =>
  Object.entries(orderBy || { last_updated_on: "desc" }).map(
    ([field, direction]) => ({ field, direction })
  );

export const getValidDate = (value) => {
  const date = parseJSON(value);
  return isValid(date) ? date : new Date(0);
};

const getInboxContactData = (
  inboxContact: any,
  allowQueriesWithSQLFunctions = false
) => {
  const nextInboxContact = { ...inboxContact };

  if (allowQueriesWithSQLFunctions) {
    if (inboxContact.contact) {
      nextInboxContact.contact__first_name = inboxContact.contact.first_name;
      nextInboxContact.contact__last_name = inboxContact.contact.last_name;
      nextInboxContact.contact__phone = inboxContact.contact.phone;
      nextInboxContact.contact_tags = inboxContact.contact.tags;
    }

    if (inboxContact.last_conversation) {
      nextInboxContact.last_conversation__status =
        inboxContact.last_conversation.status;
      nextInboxContact.conversation_assigned =
        inboxContact.last_conversation.assigned;
      nextInboxContact.conversation_tags = inboxContact.last_conversation.tags;
    }

    if (inboxContact.last_message) {
      nextInboxContact.last_message__status = inboxContact.last_message.status;
      nextInboxContact.last_message__body = inboxContact.last_message.body;
      nextInboxContact.last_message__msg_type =
        inboxContact.last_message.msg_type;
      nextInboxContact.last_message__is_deleted =
        inboxContact.last_message.is_deleted;
    }
  }

  return nextInboxContact;
};

export const updateInboxContactDataLiveQuery =
  (allowQueriesWithSQLFunctions?: boolean): UpdateQueryFn =>
  (prev, { subscriptionData, variables }) => {
    if (!subscriptionData.data?.api_inboxcontact?.length) {
      return prev;
    }

    const prevInboxContacts = prev.api_inboxcontact || [];
    if (prevInboxContacts.length === 0) {
      return subscriptionData.data;
    }

    const subscriptionInboxContacts =
      subscriptionData.data.api_inboxcontact || [];
    const nextInboxContacts: InboxContactRead[] = [];
    const { field: orderByField, direction: orderDirection } = getOrderBy(
      variables?.orderBy
    )[0];
    const isOrderByDesc = orderDirection === "desc";

    const {
      count: totalInboxContacts,
      first: firstInboxContact,
      last: lastInboxContact,
    } = getCursorItems(subscriptionInboxContacts);
    const hasMoreInboxContacts = totalInboxContacts === 10;

    let newContactsAdded = false;

    for (const prevInboxContact of prevInboxContacts) {
      if (
        compareValuesByField(
          prevInboxContact,
          firstInboxContact,
          orderByField,
          isOrderByDesc
        )
      ) {
        nextInboxContacts.push(prevInboxContact);
      } else {
        if (!newContactsAdded) {
          newContactsAdded = true;
          for (const nextInboxContact of subscriptionInboxContacts) {
            nextInboxContacts.push(
              getInboxContactData(
                nextInboxContact,
                allowQueriesWithSQLFunctions
              )
            );
          }
        }

        if (!hasMoreInboxContacts) {
          break;
        }

        if (
          compareValuesByField(
            prevInboxContact,
            lastInboxContact,
            orderByField,
            !isOrderByDesc
          )
        ) {
          nextInboxContacts.push(prevInboxContact);
        }
      }
    }

    return { api_inboxcontact: nextInboxContacts };
  };

const getLastCursorByItem = (
  orderByField: string,
  orderDirection: string,
  item: { [key: string]: string },
  cursor: string
) => {
  if (
    orderDirection === "asc"
      ? item[orderByField] > cursor
      : item[orderByField] < cursor
  ) {
    return item[orderByField];
  }
  return cursor;
};

export const updateInboxContactDataStreaming =
  (
    orderBy: { [key: string]: string },
    allowQueriesWithSQLFunctions = false
  ): UpdateQueryFn =>
  (prev, { subscriptionData }) => {
    if (!subscriptionData?.data?.api_inboxcontact_stream?.length) {
      return prev;
    }

    const nextInboxContactStream =
      subscriptionData.data.api_inboxcontact_stream;
    const prevInboxContacts = prev.api_inboxcontact || [];
    if (prevInboxContacts.length === 0) {
      return {
        api_inboxcontact: nextInboxContactStream,
      };
    }

    const nextInboxContacts: InboxContactRead[] = [];
    const pendingInboxContactsToUpdate = new Map();
    const { field: orderByField, direction: orderDirection } =
      getOrderBy(orderBy)[0];
    let cursor = new Date().toISOString();

    for (const inboxContact of nextInboxContactStream) {
      pendingInboxContactsToUpdate.set(inboxContact.id, inboxContact);
    }

    for (const prevInboxContact of prevInboxContacts) {
      const pendingInboxContact = pendingInboxContactsToUpdate.get(
        prevInboxContact.id
      );
      if (pendingInboxContact) {
        nextInboxContacts.push(
          getInboxContactData(pendingInboxContact, allowQueriesWithSQLFunctions)
        );
        pendingInboxContactsToUpdate.delete(prevInboxContact.id);
        cursor = getLastCursorByItem(
          orderByField,
          orderDirection,
          pendingInboxContact,
          cursor
        );
      } else {
        nextInboxContacts.push(prevInboxContact);
        cursor = getLastCursorByItem(
          orderByField,
          orderDirection,
          prevInboxContact,
          cursor
        );
      }
    }

    for (const pendingInboxContact of pendingInboxContactsToUpdate.values()) {
      if (
        pendingInboxContact[orderByField] && orderDirection === "asc"
          ? pendingInboxContact[orderByField] < cursor
          : pendingInboxContact[orderByField] > cursor
      ) {
        nextInboxContacts.unshift(pendingInboxContact);
      }
    }

    const compare = orderDirection === "asc" ? compareAsc : compareDesc;

    nextInboxContacts.sort((a, b) =>
      compare(getValidDate(a[orderByField]), getValidDate(b[orderByField]))
    );

    return { api_inboxcontact: nextInboxContacts };
  };
