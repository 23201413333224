import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";

interface ZapierApp {
  uuid: string;
  title: string;
  slug: string;
  description: string;
  image: string;
  url: string;
  links: {
    "mutual:zap_templates": string;
  };
  categories: Array<{
    slug: string;
  }>;
  images: {
    url_16x16: string;
    url_32x32: string;
    url_64x64: string;
    url_128x128: string;
  };
  app_latest: string;
  loading?: boolean;
}

interface ZapierQuery<T> {
  total: number;
  page: number;
  pages: number;
  per_page: number;
  objects: T[];
}

export function useZapierApps() {
  const fetchZapierApps = useCallback(async () => {
    const { data } = await axios.get<ZapierQuery<ZapierApp>>(
      "https://api.zapier.com/v1/apps?client_id=DGppUgv9rzC0lbqZjdPUQ1pDQesiqJSEMgYepgtP"
    );
    return data;
  }, []);

  const { data } = useQuery("zapier_apps", fetchZapierApps);

  const skeletonApps = Array.from({ length: 40 }).map((_, index) => ({
    uuid: `skeleton-${index}`,
    title: "",
    slug: "",
    description: "",
    image: "",
    url: "",
    links: {
      "mutual:zap_templates": "",
    },
    categories: [],
    images: {
      url_16x16: "",
      url_32x32: "",
      url_64x64: "",
      url_128x128: "",
    },
    app_latest: "",
    loading: true,
  }));

  return { apps: data?.objects || skeletonApps };
}
