import { ChatIcon, UserGroupIcon } from "@heroicons/react/outline";
import * as yup from "yup";
import RobotIcon from "src/components/icons/RobotIcon";
import { ERROR_MESSAGES } from "src/constants/Form";
import { getTranslationPayload as t } from "src/i18n";

export interface WebhookSubscriptionFormValues {
  target: string;
  is_active: boolean;
  events: {};
}

export const getInitialValues = (): WebhookSubscriptionFormValues => {
  const events = {};
  for (const property in WEBHOOK_EVENTS) {
    events[WEBHOOK_EVENTS[property].value] = false;
  }
  return {
    target: "",
    is_active: true,
    events: events,
  };
};

export const WEBHOOK_CATEGORIES = {
  CONTACT: {
    label: t("contact", "Contact"),
    icon: <UserGroupIcon className="h-4 w-4 mr-1" aria-hidden="true" />,
  },
  FLOW_EXECUTION_CONTACT: {
    label: t("flow-execution-contact", "Flow Execution Contact"),
    icon: <RobotIcon className="h-4 w-4 mr-1" aria-hidden="true" />,
  },
  MESSAGE: {
    label: t("message", "Message"),
    icon: <ChatIcon className="h-4 w-4 mr-1" aria-hidden="true" />,
  },
};

// Translation to avoid Formik issues with using field names with `.` in them
export const WEBHOOK_EVENTS_TRANSLATION = {
  contact_created: "contact.created",
  contact_updated: "contact.updated",
  contact_deleted: "contact.deleted",
  flow_execution_contact_completed: "flow_execution_contact.completed",
  flow_execution_contact_running_inbound:
    "flow_execution_contact.running_inbound",
  message_received: "message.received",
  message_sent: "message.sent",
  message_delivered: "message.delivered",
  message_read: "message.read",
  message_failed: "message.failed",
};

export const WEBHOOK_EVENTS = {
  contact_created: {
    label: t("created", "Created"),
    value: "contact_created",
    category: "CONTACT",
    feature_flag: null,
    description: t(
      "integrations:webhooks.contacts.created.description",
      "Occurs whenever a new contact is created."
    ),
  },
  contact_updated: {
    label: t("updated", "Updated"),
    value: "contact_updated",
    category: "CONTACT",
    feature_flag: null,
    description: t(
      "integrations:webhooks.contacts.updated.description",
      "Occurs whenever any property of a contact changes."
    ),
  },
  contact_deleted: {
    label: t("deleted", "Deleted"),
    value: "contact_deleted",
    category: "CONTACT",
    feature_flag: null,
    description: t(
      "integrations:webhooks.contacts.deleted.description",
      "Occurs whenever a contact is deleted."
    ),
  },
  fec_completed: {
    label: t("completed", "Completed"),
    value: "flow_execution_contact_completed",
    category: "FLOW_EXECUTION_CONTACT",
    feature_flag: null,
    description: t(
      "integrations:webhooks.flow-execution.completed.description",
      "Occurs when a Flow Execution Contact has been completed."
    ),
  },
  fec_joined: {
    label: t(
      "integrations:webhooks.flow-execution.joined-inbound",
      "Joined Inbound"
    ),
    value: "flow_execution_contact_running_inbound",
    category: "FLOW_EXECUTION_CONTACT",
    feature_flag: null,
    description: t(
      "integrations:webhooks.flow-execution.running.description",
      "Occurs when a Flow Execution Contact gets triggered by an Inbound message."
    ),
  },
  message_received: {
    label: t("received", "Received"),
    value: "message_received",
    category: "MESSAGE",
    feature_flag: null,
    description: t(
      "integrations:webhooks.message.received.description",
      "Whenever we receive a new message from WhatsApp"
    ),
  },
  message_sent: {
    label: t("sent", "Received"),
    value: "message_sent",
    category: "MESSAGE",
    feature_flag: null,
    description: t(
      "integrations:webhooks.message.sent.description",
      "Whenever WhatsApp successfully sends a message"
    ),
  },
  message_delivered: {
    label: t("delivered", "Delivered"),
    value: "message_delivered",
    category: "MESSAGE",
    feature_flag: null,
    description: t(
      "integrations:webhooks.message.delivered.description",
      "Whenever WhatsApp successfully delivers a message"
    ),
  },
  message_read: {
    label: t("read", "Read"),
    value: "message_read",
    category: "MESSAGE",
    feature_flag: null,
    description: t(
      "integrations:webhooks.message.read.description",
      "When a contact reads a sent message (this requires the contact to have read receipts enabled)"
    ),
  },
  message_failed: {
    label: t("failed", "Received"),
    value: "message_failed",
    category: "MESSAGE",
    feature_flag: null,
    description: t(
      "integrations:webhooks.message.failed.description",
      "Whenever a WhatsApp message fails to be delivered"
    ),
  },
};

export const FIELDS = {
  target: {
    key: "target",
    label: t("target-url", "Target URL"),
    optional: false,
    help: "The URL to receive the subscribed events",
    placeholder: t(
      "integrations:webhooks.target.placeholder",
      "https://example.com/hilos/webhook"
    ),
  },
  is_active: {
    key: "is_active",
    label: t("active", "Active"),
    optional: false,
    help: t(
      "integrations:webhooks.active.help",
      "Disable this to pause webhook reception"
    ),
  },
  events: {
    label: t(
      "integrations:webhooks.events-to-subscribe.label",
      "Events to subscribe"
    ),
    help: t(
      "integrations:webhooks.events-to-subscribe.help",
      "Select the events you wish to receive at this URL"
    ),
  },
};

export const schema = yup.object().shape({
  target: yup.string().url().required(ERROR_MESSAGES.required),
  is_active: yup.bool().required(ERROR_MESSAGES.required),
});
