import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { PaginatedInboxActionData } from "@hilos/types/inbox_action";
import { API_ROUTES } from "../router/router";

export function useInboxActionList() {
  const fetchInboxActionList = useCallback(async () => {
    const url = API_ROUTES.INBOX_ACTION_LIST_CREATE;
    const { data } = await axios.get<PaginatedInboxActionData>(url);
    return data;
  }, []);

  const { data } = useQuery("inbox_actions", fetchInboxActionList);

  return { inbox_actions: data || { count: 0, results: [] } };
}
