import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { HubspotContactCustomProperties } from "@hilos/types/private-schema";
import { API_ROUTES } from "src/router/router";

export function useHubspotContactProperties() {
  const fetchHubspotContactProperties = useCallback(async () => {
    const { data } = await axios.get<HubspotContactCustomProperties[]>(
      API_ROUTES.HUBSPOT_CONTACT_PROPERTIES
    );
    return data;
  }, []);

  const { data } = useQuery(
    "hubspot_contact_properties",
    fetchHubspotContactProperties,
    {
      refetchOnWindowFocus: false,
    }
  );
  const fieldsOptions = useMemo(() => {
    return Object.fromEntries(
      data?.map((property) => [property.name, property.options ?? []]) ?? []
    );
  }, [data]);
  return { data: data ?? [], fieldsOptions };
}
