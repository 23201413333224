import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { InformationCircleIcon } from "@heroicons/react/outline";

interface BroadcastFormCSVTemplateDownloadProps {
  requiredFields: string[];
  headerURL?: string;
}

export default function BroadcastFormCSVTemplateDownload({
  requiredFields,
  headerURL,
}: BroadcastFormCSVTemplateDownloadProps) {
  const { t, i18n } = useTranslation();
  const csvValues = useMemo(() => {
    const values: string[][] = [];
    for (let i = 0; i < 3; i++) {
      values.push([
        `'+52112233445${i}`,
        ...requiredFields.map((f) => {
          const variableNumber = f.replaceAll('{', '').replaceAll('}', '')
          return f.includes('URL') ? headerURL ?? 'https://hilos.io/api/file/p/c9f4f2e7-...' : t('broadcasts:value-for-variable', 'Value for variable {{variable}}', {variable: variableNumber})
        }),
      ]);
    }
    return values;
  // We use the i18n.language to recalculate the translation when the language
  // changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFields, t, i18n.language]);

  const colNames = useMemo(() => {
    const chars: string[] = [];
    for (var i = 65; i < 90; i++) {
      chars.push(String.fromCharCode(i));
    }
    return chars;
  }, []);

  const downloadCSVTemplate = () => {
    const exportedFilename = "broadcast_template.csv";
    const blob = new Blob([csvValues[0] as unknown as BlobPart], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div>
      <div className="my-2 space-y-2">
        <div className="mt-2 overflow-scroll font-mono text-sm text-gray-500">
          <table className="table">
            <tbody>
              <tr className="bg-gray-200 text-xs">
                <td className="border border-gray-300 py-2 pr-2"></td>
                {csvValues[0].map((r, idx) => (
                  <td
                    className="border border-gray-300 max-w-[250px] py-2 px-2 text-center"
                    key={idx}
                  >
                    {idx < colNames.length ? colNames[idx] : "A"}
                  </td>
                ))}
              </tr>
              {csvValues.map((rowValues, idx) => (
                <tr className="border border-gray-100 text-xs" key={idx}>
                  <td className="border border-gray-300 bg-gray-200 py-2 px-2">
                    {idx + 1}
                  </td>
                  {rowValues.map((variableField, vIdx) => (
                    <td
                      className={`border truncate max-w-[250px]  border-gray-200 py-2 px-2 ${
                        vIdx === 0 ? "font-semibold text-gray-900" : ""
                      }`}
                      key={vIdx}
                    >
                      {variableField}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="py-3 text-xs text-gray-500">
          <Trans i18nKey="broadcasts:csv-single-quote">
            *The{" "}
            <code className="font-mono font-medium text-indigo-500">'</code>{" "}
            (single quote) before the phone number is used to avoid Excel
            formatting a phone number as a number/formula.
          </Trans>
        </p>
      </div>

      <div className="justify-left mt-1.5 flex items-start text-xs text-gray-500">
        <InformationCircleIcon
          className="mr-1 h-4 w-4 text-blue-400"
          aria-hidden="true"
        />
        <button
          type="button"
          className="text-left text-blue-500"
          onClick={(_) => downloadCSVTemplate()}
        >
          {t(
            "broadcasts:download-csv-template",
            "Download here a .csv template to see to how this .csv file should be formatted."
          )}
        </button>
      </div>
    </div>
  );
}
