import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from "reactflow";
import { classNames } from "src/Helpers";
import useDropZone from "../../hooks/useDropZone";
import useFlowEdge from "../../hooks/useFlowEdge";
import { NEXT_OPTION_TYPES } from "../../utils";

function ForkStepEdge({
  id,
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  selected,
  source,
}: EdgeProps) {
  const { t } = useTranslation();
  const isUnreachable = useMemo(
    () => Boolean(data && data.isUnreachable),
    [data]
  );
  const { labelRef, isValidDropzone, onMouseEnter, onMouseLeave } = useFlowEdge(
    {
      id: source,
      selected,
      isUnreachable,
      enterClassName: "indigo-600",
      leaveClassName: "gray-400",
    }
  );
  const { isDropzoneActive, onDrop, onDragOver, onDragEnter, onDragLeave } =
    useDropZone({ disabled: !isValidDropzone });
  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const label = useMemo(() => {
    if (!data || !data.label) {
      return "...";
    }

    if (
      data.isUnreachable ||
      (data.optionId && NEXT_OPTION_TYPES.includes(data.optionId))
    ) {
      return t(data.label);
    }

    return data.label || "...";
  }, [t, data]);

  return (
    <g
      id={id}
      data-type="fork_step_edge"
      className={classNames(
        "group block",
        !selected && !isDropzoneActive && "opacity-60 group-hover:opacity-100"
      )}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <path
        id={id}
        fill="none"
        style={style}
        className={classNames(
          "stroke-2",
          isUnreachable
            ? "stroke-red-400"
            : isDropzoneActive
            ? isValidDropzone
              ? "stroke-indigo-600"
              : "stroke-red-600"
            : selected
            ? "stroke-indigo-600"
            : "stroke-gray-400 group-hover:stroke-indigo-600"
        )}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        fill="none"
        style={style}
        className="stroke-transparent stroke-[40px]"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${targetX}px,${edgeCenterY}px)`,
          }}
        >
          <div
            ref={(ref) => {
              labelRef.current = ref;
            }}
            className={classNames(
              "flex max-w-[220px] items-center justify-center rounded border-2 bg-white px-1 font-semibold",
              isUnreachable
                ? "border-red-600 text-red-600"
                : isDropzoneActive
                ? isValidDropzone
                  ? "border-indigo-600 text-indigo-600"
                  : "border-red-600 text-red-600"
                : selected
                ? "border-indigo-600 text-indigo-600"
                : "border-gray-400 text-gray-400"
            )}
          >
            <span className="truncate break-all">{label}</span>
          </div>
        </div>
      </EdgeLabelRenderer>
    </g>
  );
}

export default ForkStepEdge;
