import { memo, useEffect, useRef, useState } from "react";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { PlusSmIcon } from "@heroicons/react/outline";
import invariant from "tiny-invariant";
import { classNames } from "src/Helpers";
import {
  WorkflowTaskItem,
  useWorkflowBoardContext,
} from "../WorkflowBoardContext";
import { Card } from "./Card";

/**
 * Note: not making `'is-dragging'` a `State` as it is
 * a _parallel_ state to `'is-column-over'`.
 *
 * Our board allows you to be over the column that is currently dragging
 */
type ColumnState =
  | { type: "idle" }
  | { type: "is-card-over" }
  | { type: "is-column-over"; closestEdge: Edge | null };

// preventing re-renders with stable state objects
const idle: ColumnState = { type: "idle" };
const isCardOver: ColumnState = { type: "is-card-over" };

export const Column = memo(function Column({
  id: columnId,
  title,
  tasks,
  onShowAddTask,
}: {
  id: string;
  title: string;
  tasks: Map<string, WorkflowTaskItem>;
  onShowAddTask: (id: string) => void;
}) {
  const columnRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const cardListRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<ColumnState>(idle);

  const instanceId = useWorkflowBoardContext((state) => state.instanceId);
  const registerColumn = useWorkflowBoardContext(
    (state) => state.registerColumn
  );

  useEffect(() => {
    invariant(columnRef.current);
    invariant(headerRef.current);
    invariant(cardListRef.current);
    return combine(
      registerColumn({
        columnId,
        entry: {
          element: columnRef.current,
        },
      }),
      dropTargetForElements({
        element: cardListRef.current,
        getData: () => ({ columnId }),
        canDrop: ({ source }) => {
          return (
            source.data.instanceId === instanceId &&
            source.data.type === "card" &&
            source.data.state !== columnId
          );
        },
        getIsSticky: () => true,
        onDragEnter: () => setState(isCardOver),
        onDragLeave: () => setState(idle),
        onDragStart: () => setState(isCardOver),
        onDrop: () => setState(idle),
      }),
      autoScrollForElements({
        element: cardListRef.current,
        canScroll: ({ source }) =>
          source.data.instanceId === instanceId && source.data.type === "card",
      })
    );
  }, [columnId, registerColumn, instanceId]);

  return (
    <div
      ref={columnRef}
      itemID={`column-${columnId}`}
      className={classNames(
        "flex min-w-min rounded-lg bg-gray-100 overflow-hidden",
        state.type === "is-card-over" && "bg-gray-200"
      )}
    >
      <div className="flex flex-col min-w-min min-h-0 grow overflow-hidden">
        <div
          ref={headerRef}
          itemID={`column-header-${columnId}`}
          className={classNames(
            "flex row-auto w-60 p-2 text-gray-800 bg-gray-100 select-none justify-between items-center",
            state.type === "is-card-over" && "bg-gray-200"
          )}
        >
          <h6 itemID={`column-header-title-${columnId}`}>{title}</h6>
          <button
            className=""
            type="button"
            onClick={() => onShowAddTask(columnId)}
          >
            <PlusSmIcon className="h-5 w-5 text-gray-800" />
          </button>
        </div>
        <div
          ref={cardListRef}
          className="h-full w-full p-2 space-y-2 overflow-y-auto"
        >
          {Array.from(tasks, ([taskId, task]) => (
            <Card key={taskId} item={task} />
          ))}
        </div>
      </div>
    </div>
  );
});
