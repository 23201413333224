import { useEffect, useMemo } from "react";
import useWebSocket, { resetGlobalState } from "react-use-websocket";
import { buildAPIRoute } from "../router/router";

interface UseHilosWebSocketsParams {
  path: string;
  connect?: boolean;
  params?: { [key: string]: string | undefined };
}

const BASE_SOCKET_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_WS_LOCATION
    : process.env.REACT_APP_DEV_WS_LOCATION;

function useHilosWebSockets({
  path,
  connect = true,
  params = {},
}: UseHilosWebSocketsParams) {
  const websocketURL = useMemo(() => {
    if (!connect) {
      return null;
    }
    for (const key in params) {
      // If one of the parameters is undefined return null
      if (!params[key]) {
        return null;
      }
    }

    return BASE_SOCKET_URL + buildAPIRoute(path, params);
  }, [connect, path, params]);

  const data = useWebSocket<{ type: string; data: string }>(
    websocketURL,
    {
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      shouldReconnect: () => true,
    },
    Boolean(websocketURL)
  );

  useEffect(() => {
    if (websocketURL) {
      const handleUnload = () => resetGlobalState(websocketURL);

      window.addEventListener("unload", handleUnload);
      return () => {
        handleUnload();
        window.removeEventListener("unload", handleUnload);
      };
    }
  }, [websocketURL]);

  return data;
}

export default useHilosWebSockets;
