import { FlowExecutionContactListSimple } from "@hilos/types/private-schema";
import FlowExecutionContactControlsButtons from "src/containers/flow/flow_execution_contact/FlowExecutionContactControlsButtons";
import { useUpdateFlowExecutionContactsQuery } from "src/hooks/useFlowExecutionContacts";

interface ConversationFlowExecutionContactControlsButtonsProps {
  flowExecutionContact: FlowExecutionContactListSimple;
  contactId: string;
  isActive?: boolean;
}

function ConversationFlowExecutionContactControlsButtons({
  flowExecutionContact,
  contactId,
  isActive,
}: ConversationFlowExecutionContactControlsButtonsProps) {
  const onFlowExecutionContactStatusUpdateSuccess =
    useUpdateFlowExecutionContactsQuery({
      contactId,
      isActive,
      flowExecutionContactId: flowExecutionContact.id as string,
    });

  return (
    <div className="flex flex-col">
      <div className="text-xs text-gray-500">
        {flowExecutionContact.flow_name}
      </div>
      <div className="flex w-full flex-row items-center">
        <FlowExecutionContactControlsButtons
          flowExecutionContactId={flowExecutionContact.id}
          flowExecutionContactStatus={flowExecutionContact.status}
          iconOnly={true}
          btnClasses="mx-2"
          onFlowExecutionContactStatusUpdateSuccess={
            onFlowExecutionContactStatusUpdateSuccess
          }
        />
      </div>
    </div>
  );
}

export default ConversationFlowExecutionContactControlsButtons;
