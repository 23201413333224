interface FlowBuilderStepQuestionBoolProps {
  index: number;
}

function FlowBuilderStepQuestionBool({
  index,
}: FlowBuilderStepQuestionBoolProps) {
  return (
    <div className="space-y-2">
      <p className="block text-sm font-medium text-gray-700">
        Which options can the user choose from?
      </p>
      <p className="text-xs text-gray-500">
        We'll save the first option as <code>True</code>, the second as{" "}
        <code>False</code>.
      </p>
    </div>
  );
}

export default FlowBuilderStepQuestionBool;
