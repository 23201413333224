import { Trans, useTranslation } from "react-i18next";
import { TRIGGER_TYPES } from "../builder/constants/triggers";

export default function IntegrationsZapierTriggerDetail({ flow }) {
  const { t } = useTranslation();
  const triggerData = TRIGGER_TYPES[flow.trigger_type];

  const timeline = [
    {
      id: 1,
      content: (
        <Trans i18nKey="flows:trigger-detail.integrations-zapier-step-1">
          Use the{" "}
          <strong className="text-gray-800 font-medium">
            Start a Flow for a Phone Number Action
          </strong>{" "}
          to send this flow to a phone number.
        </Trans>
      ),
    },
    {
      id: 2,
      content: (
        <Trans i18nKey="flows:trigger-detail.integrations-zapier-step-2">
          There's no need to create a contact beforehand, but you can also first
          create / update a contact with the
          <strong className="text-gray-800 font-medium">
            Create or Update Contact Action
          </strong>{" "}
          and then the above step to send the flow.
        </Trans>
      ),
    },
  ];

  return (
    <>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("trigger", "Trigger")}
      </h3>
      <div className="flex items-center space-x-1">
        <div>
          <triggerData.icon className="h-6 w-6 text-gray-800" />
        </div>
        <div className="font-medium text-gray-900">
          <p className="text-xl">{t(triggerData.name)}</p>
        </div>
      </div>
      <div className="text-sm text-gray-700">
        <div className="flow-root">
          <ul className="-mb-4 mt-3">
            {timeline.map((event, eventIdx) => (
              <li key={event.id}>
                <div className="relative pb-4">
                  {eventIdx !== timeline.length - 1 ? (
                    <span
                      aria-hidden="true"
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    />
                  ) : null}
                  <div className="relative flex space-x-3 items-center">
                    <div>
                      <span className="flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white text-white bg-gray-400">
                        {event.id}
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-500">{event.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
