import { useTranslation } from "react-i18next";
import HeaderNavigation from "../../components/HeaderNavigation";
import { buildRoute } from "../../router/router";
import AccountNotificationSettingsComponent from "./AccountNotificationSettingsComponent";

export default function AccountNotificationSettings() {
  const { t } = useTranslation();

  return (
    <div className="h-screen overflow-y-auto bg-gray-100 pb-4">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("settings"),
              url: buildRoute("config-account"),
            },
            {
              name: t("settings:notifications.label", "Notification settings"),
              url: buildRoute("config-notifications"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              {t("settings:notifications.label", "Notification settings")}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl">
          <AccountNotificationSettingsComponent />
        </div>
      </div>
    </div>
  );
}
