import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobeAltIcon, UploadIcon } from "@heroicons/react/outline";
import FileUploadField from "src/components/Form/FileUploadField";
import TextInputField from "src/components/Form/TextInputField";
import TextInputVariablesField from "src/components/Form/TextInputVariablesField";
import { classNames } from "src/Helpers";

interface TextInputOrFileUploadFieldProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "action"> {
  media?: "IMAGE" | "VIDEO" | "DOCUMENT" | "TEXT";
  labelField: ReactNode;
  label: string;
  name: string;
  help?: string;
  placeholder?: string;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
  enableVariables?: boolean;
  disableFileUpload?: boolean;
}

const TABS = [
  {
    // i18n.t("components:text-input-or-file-upload-field.from-url", "From URL")
    name: "components:text-input-or-file-upload-field.from-url",
    value: "URL",
    icon: <GlobeAltIcon className="mr-2 h-4 w-4" />,
  },
  {
    // i18n.t("components:text-input-or-file-upload-field.upload-file", "Upload File")
    name: "components:text-input-or-file-upload-field.upload-file",
    value: "UPLOAD",
    icon: <UploadIcon className="mr-2 h-4 w-4" />,
  },
];

type MediaSource = "URL" | "UPLOAD";

export default function TextInputOrFileUploadField({
  media,
  label,
  labelField,
  name,
  help,
  placeholder,
  icon,
  enableVariables,
  disableFileUpload,
  ...props
}: TextInputOrFileUploadFieldProps) {
  const [mediaSource, setMediaSource] = useState<MediaSource>("URL");
  const switchTab = (newTab: string) => {
    setMediaSource(newTab as MediaSource);
  };
  const { t } = useTranslation();

  return (
    <>
      <div>
        {!disableFileUpload && (
          <>
            {labelField}
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                {t(
                  "components:text-input-or-file-upload-field.select-tab",
                  "Select a tab"
                )}
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue="From URL"
                onChange={(ev) => switchTab(ev.target.value)}
              >
                {TABS.map((tab) => (
                  <option
                    key={tab.name}
                    className="flex items-center"
                    value={mediaSource}
                  >
                    {tab.icon} {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {TABS.map((tab) => (
                    <button
                      type="button"
                      key={tab.name}
                      onClick={(_) => switchTab(tab.value)}
                      disabled={!!disableFileUpload}
                      className={classNames(
                        tab.value === mediaSource
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "flex grow items-center border-b-2 py-3 px-1 text-center text-sm font-medium",
                        !!disableFileUpload && "opacity-50"
                      )}
                      aria-current={
                        tab.value === mediaSource ? "page" : undefined
                      }
                    >
                      {tab.icon}
                      {t(tab.name)}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </>
        )}
      </div>

      {mediaSource === "URL" &&
        (enableVariables ? (
          <TextInputVariablesField
            placeholder={placeholder}
            label={label}
            name={name}
            icon={icon}
            help={help}
            {...props}
          />
        ) : (
          <>
            <TextInputField
              placeholder={placeholder}
              label={label}
              name={name}
              icon={icon}
              help={help}
              {...props}
            />
          </>
        ))}

      {mediaSource === "UPLOAD" && (
        <FileUploadField
          name={name}
          media={media}
          disableFileUpload={disableFileUpload}
        />
      )}
    </>
  );
}
