import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-yaml";
import "prismjs/themes/prism.css";
import TextAreaField from "../Form/TextAreaField";
import { HTTPFormProps } from "./HTTPForm";
import HTTPFormBodySelect from "./HTTPFormBodySelect";
import HTTPFormBodyStructured from "./HTTPFormBodyStructured";
import { HTTPFormMeta } from "./Meta";

export default function HTTPFormBody({
  formik,
  formPrefix,
  formValues,
  availableVariables,
}: HTTPFormProps) {
  const setContentType = (nextContentType: string) => {
    // Here we add/update content-type header and update bodyType
    const contentTypeIndex = formValues.headers
      ? formValues.headers.findIndex(
          (header) =>
            !header.key.localeCompare("Content-Type", undefined, {
              sensitivity: "accent",
            })
        )
      : -1;
    if (contentTypeIndex < 0 && nextContentType !== "NONE") {
      formValues.headers.push({
        key: "Content-Type",
        value: nextContentType,
      });
    } else {
      if (nextContentType !== "NONE") {
        formik.setFieldValue(`${formPrefix}.headers.${contentTypeIndex}`, {
          key: "Content-Type",
          value: nextContentType,
        });
      } else {
        formValues.headers.splice(contentTypeIndex, 1);
      }
    }
    const newBodyType = HTTPFormMeta.BODY_TYPES.find(
      (type) => type.value === nextContentType
    );
    if (!newBodyType) {
      return;
    }
    formik.setFieldValue(
      `${formPrefix}.${HTTPFormMeta.FIELDS.body_type.key}`,
      newBodyType
    );
  };

  const onChangeRequestBody = (body: string) => {
    formik.setFieldValue(`${formPrefix}.${HTTPFormMeta.FIELDS.body.key}`, body);
  };

  const getEditorLanguague = () => {
    if (formValues.body_type.includes("yaml")) {
      return "yaml";
    } else if (formValues.body_type.includes("xml")) {
      return "xml";
    } else {
      return "json";
    }
  };

  const getEditorGrammar = () => {
    if (formValues.body_type.includes("yaml")) {
      return languages.json;
    } else if (formValues.body_type.includes("xml")) {
      return languages.xml;
    } else {
      return languages.json;
    }
  };

  return (
    <div className="mt-4">
      <HTTPFormBodySelect
        setContentType={setContentType}
        formPrefix={formPrefix}
      />
      {formValues.body_type === "text/plain" && (
        <div className="mt-2">
          <TextAreaField
            label={HTTPFormMeta.FIELDS.body.label}
            name={`${formPrefix}.${HTTPFormMeta.FIELDS.body.key}`}
            help={HTTPFormMeta.FIELDS.body.help}
            placeholder={HTTPFormMeta.FIELDS.body.placeholder}
            rows={4}
          />

          <div className="mt-2 flex items-start justify-between">
            <div className="mr-4">
              {/* <FlowStepAddVariable
                formik={formik}
                addVariable={addVariable}
                currentStepIndex={index} 
              /> */}
            </div>
          </div>
        </div>
      )}
      {(formValues.body_type === "application/json" ||
        formValues.body_type === "application/xml" ||
        formValues.body_type === "text/yaml") && (
        <div className="container">
          <Editor
            placeholder="Type request body"
            value={formValues.body}
            onValueChange={(body) => onChangeRequestBody(body)}
            className="my-4 bg-slate-50"
            highlight={(body) =>
              highlight(body, getEditorGrammar(), getEditorLanguague())
            }
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              minHeight: 300,
            }}
          />
          <div className="mt-2 flex items-start justify-between">
            <div className="mr-4">
              {/* <FlowStepAddVariable
                formik={formik}
                addVariable={addVariable}
                currentStepIndex={index}
              /> */}
            </div>
          </div>
        </div>
      )}
      {formValues.body_type === "STRUCTURED" && (
        <HTTPFormBodyStructured
          formik={formik}
          formPrefix={formPrefix}
          formValues={formValues}
          availableVariables={availableVariables}
        />
      )}
    </div>
  );
}
