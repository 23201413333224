import useHilosStore from "src/hooks/useHilosStore";
import SubscriptionV1SelectPlan from "./SubscriptionV1SelectPlan";
import SubscriptionV2SelectPlan from "./SubscriptionV2SelectPlan";

interface SubscriptionSelectPlanProps {
  currency: string;
}

export default function SubscriptionSelectPlan({
  currency,
}: SubscriptionSelectPlanProps) {
  const { session } = useHilosStore();

  if (session?.account.pricing_model_version === "v2") {
    return <SubscriptionV2SelectPlan currency={currency} />;
  } else {
    return <SubscriptionV1SelectPlan currency={currency} />;
  }
}
