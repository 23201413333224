export const numberFormatter = (value: number) =>
  Intl.NumberFormat("us").format(value).toString();

export const durationFormatter = (value: number) => {
  const hours = Math.floor(value / 3600);
  const minutes = (value % 3600) / 60;

  const fmt = Intl.NumberFormat("us", {
    minimumIntegerDigits: 2,
    maximumFractionDigits: 0,
  });
  if (isNaN(hours) || isNaN(minutes)) {
    return "00:00";
  }
  return `${fmt.format(Math.abs(hours))}:${fmt.format(Math.abs(minutes))}`;
};

export function sumArray(array: any[], metric: string) {
  return array.reduce(
    (accumulator, currentValue) =>
      accumulator +
      (currentValue[metric] === null ? 0 : parseInt(currentValue[metric])),
    0
  );
}
