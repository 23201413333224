import { useMemo, useState } from "react";
import {
  autoUpdate,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react";

function useEmojiPopover() {
  const [open, setOpen] = useState(false);

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(8),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    placement: "top",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: "scale(0.9) translateY(10px)",
    },
    open: {
      opacity: 1,
      transform: "scale(1) translateY(0px)",
    },
    close: {
      opacity: 0,
      transform: "scale(0.9) translateY(10px)",
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const styles = useMemo(
    () => ({
      position: strategy,
      top: y ?? 0,
      left: x ?? 0,
      width: "max-content",
      ...transitionStyles,
    }),
    [transitionStyles, strategy, x, y]
  );

  return {
    open,
    isMounted,
    styles,
    refs,
    context,
    getReferenceProps,
    getFloatingProps,
  };
}

export default useEmojiPopover;
