import React from "react";
import {
  ConversationContent,
  InboxContactRead,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import MessageRender from "../../../components/MessageRender";
import { HandleMountConversationContent } from "./Conversation";
import ConversationEventFn from "./ConversationEvent";
import ConversationEventContainer from "./ConversationEventContainer";
import ConversationMessageContainer from "./ConversationMessageContainer";

interface ConversationContentItemProps {
  inboxContact: InboxContactRead;
  showDetails: boolean;
  content: ConversationContent & { submitting?: boolean };
  isFocused: boolean;
  onMountConversationContent: HandleMountConversationContent;
  handleSelectContextMessage: (contentMessage: ConversationContent) => void;
  onFocusMessage: (messageId: string) => Promise<void>;
  handleSelectMedia: (msg: WhatsAppMessage) => void;
  handleSelectForwardMessage: (message: WhatsAppMessage) => void;
}
const ELEMENT_TYPES = {
  EVENT: "EVENT",
  MESSAGE: "MESSAGE",
};

function ConversationContentItem({
  inboxContact,
  content,
  isFocused,
  showDetails,
  onMountConversationContent,
  handleSelectContextMessage,
  onFocusMessage,
  handleSelectMedia,
  handleSelectForwardMessage,
}: ConversationContentItemProps) {
  if (content.content_type === ELEMENT_TYPES.MESSAGE && content.message) {
    return (
      <ConversationMessageContainer
        content={content}
        message={content.message}
        inboxContact={inboxContact}
        showDetails={showDetails}
        isFocused={isFocused}
        isSubmitting={content.submitting}
        onMountConversationContent={onMountConversationContent}
        handleSelectContextMessage={handleSelectContextMessage}
        onFocusMessage={onFocusMessage}
        handleSelectForwardMessage={handleSelectForwardMessage}
      >
        <MessageRender
          message={content.message}
          handleSelectMedia={handleSelectMedia}
        />
      </ConversationMessageContainer>
    );
  }

  if (content.content_type === ELEMENT_TYPES.EVENT && content.event) {
    return (
      <ConversationEventContainer>
        <ConversationEventFn
          event={content.event}
          timestamp={content.event.timestamp}
        />
      </ConversationEventContainer>
    );
  }

  return null;
}

export default React.memo(
  ConversationContentItem,
  (prevProps, nextProps) =>
    prevProps.content === nextProps.content &&
    prevProps.isFocused === nextProps.isFocused &&
    prevProps.showDetails === nextProps.showDetails &&
    prevProps.handleSelectMedia === nextProps.handleSelectMedia &&
    prevProps.onMountConversationContent ===
      nextProps.onMountConversationContent
);
