import {
  STEP_TYPES,
  StepTypes,
} from "src/containers/flow/builder/constants/steps";
import { useCallback, useMemo } from "react";

import { API_ROUTES } from "src/router/router";
import { ActionStepDescription } from "src/containers/flow/builder/components/FlowBuilderAddStepDescription";
import { CodeIcon } from "@heroicons/react/outline";
import FlowBuilderStepWrappedAction from "src/containers/flow/builder/components/steps/wrapped_action/FlowBuilderStepWrappedAction";
import axios from "axios";
import useHilosStore from "src/hooks/useHilosStore";
import { useQuery } from "react-query";

export interface Input {
  key: string;
  label: string;
  type: string;
  fieldtype: string;
  required: boolean;
  default: string;
  dynamic: boolean;
}

interface Message {
  role: string;
  content: string;
}

interface ActionRequestBody {
  model: string;
  messages: Message[];
  temperature: number;
  max_tokens: number;
}

interface ActionStepFields {
  action_request_method: string;
  action_request_url: string;
  action_request_authorization_type: string;
  action_request_authorization_token_input: string;
  action_request_body: ActionRequestBody;
  action_request_body_escape_variables: boolean;
}

export interface WrappedChatGPT {
  name: string;
  inputs: Input[];
  action_step_fields: ActionStepFields;
  outputs: string;
}

interface WrappedSteps {
  WRAPPED_CHATGPT: WrappedChatGPT;
}

function useWrappedSteps() {
  const fetchWrappedSteps = useCallback(async () => {
    const { data } = await axios.get<WrappedSteps>(
      API_ROUTES.FLOW_WRAPPED_STEPS
    );
    return data;
  }, []);

  const { data } = useQuery(["flow_wrapped_steps"], fetchWrappedSteps);
  const { session } = useHilosStore();

  const STEP_TYPES_AVAILABLE = useMemo(() => {
    const STEPS = {};
    for (const [key, value] of Object.entries(STEP_TYPES)) {
      if ("featureFlag" in value) {
        if (
          session?.account.feature_flags.includes(value.featureFlag as string)
        ) {
          STEPS[key] = value;
        }
      } else {
        STEPS[key] = value;
      }
    }
    return STEPS as typeof STEP_TYPES;
  }, [session?.account.feature_flags]);

  const STEP_TYPES_DYNAMIC = useMemo(() => {
    if (!data || !session?.account.feature_flags.includes("wrapped_steps")) {
      return STEP_TYPES_AVAILABLE;
    }

    let wrappedSteps = {};

    Object.keys(data).forEach((key) => {
      let step = {
        type: key as StepTypes,
        icon: CodeIcon,
        component: FlowBuilderStepWrappedAction,
        docsLink: "https://hilos.io/docs/user/using-hilos/flows/steps/action-step",
        descriptionComponent: ActionStepDescription,
      };

      wrappedSteps[key] = { ...data[key], ...step };
    });

    return { ...STEP_TYPES_AVAILABLE, ...wrappedSteps };
  }, [STEP_TYPES_AVAILABLE, data, session?.account.feature_flags]);

  const STEP_TYPES_LIST = useMemo(() => {
    const STEP_TYPES_LIST_CONSTANT = Object.entries(STEP_TYPES_DYNAMIC).map(
      ([type, step]) => ({
        ...step,
        type: type as StepTypes,
      })
    );

    return STEP_TYPES_LIST_CONSTANT;
  }, [STEP_TYPES_DYNAMIC]);

  return {
    STEP_TYPES_DYNAMIC,
    STEP_TYPES_LIST,
  };
}

export default useWrappedSteps;
