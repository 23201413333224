import { useTranslation } from "react-i18next";
import Editor from "react-simple-code-editor";
import { DateTime } from "luxon";
import { highlight, languages } from "prismjs";
import { IntegrationEventLog } from "@hilos/types/private-schema";
import IntegrationEventLogTypeIcon from "src/containers/integrations/IntegrationEventLogTypeIcon";
import { hasKeys } from "src/helpers/utils";

interface LogEventDetailProps {
  logEvent: IntegrationEventLog;
}

export default function IntegrationEventLogDetail({
  logEvent,
}: LogEventDetailProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="">
        <dl className="text-sm sm:divide-y sm:divide-gray-200">
          <div className="pb-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:pb-3">
            <dt className="text-sm font-medium text-gray-500">
              {t("id", "ID")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <code className="text-indigo-500 font-mono text-sm">
                {logEvent.id}
              </code>
            </dd>
          </div>
          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500">
              {t("source", "Source")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <code className="text-indigo-500">{logEvent.source}</code>
            </dd>
          </div>

          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500">
              {t("timestamp", "Timestamp")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <time
                dateTime={DateTime.fromISO(
                  logEvent.timestamp as string
                ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              >
                {DateTime.fromISO(logEvent.timestamp as string).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS
                )}
              </time>
            </dd>
          </div>

          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500">{t("title")}</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {logEvent.title}
            </dd>
          </div>

          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500 capitalize">
              {t("contact")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {logEvent.contact_phone}
              <code className="block font-mono text-xs font-normal text-indigo-600">
                {logEvent.contact_id}
              </code>
            </dd>
          </div>
          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500 capitalize">
              {t("flow")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {logEvent.flow_name}
              <code className="block font-mono text-xs font-normal text-indigo-600">
                {logEvent.flow_id}
              </code>
            </dd>
          </div>

          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500">
              {t("description")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {logEvent.description}
            </dd>
          </div>
          <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
            <dt className="text-sm font-medium text-gray-500">
              {t("log-type", "Log Type")}
            </dt>
            <dd className="mt-1 inline-flex text-sm uppercase text-gray-900 sm:col-span-2 sm:mt-0">
              <IntegrationEventLogTypeIcon logType={logEvent.log_type} />
              {logEvent.log_type}
            </dd>
          </div>
          {hasKeys(logEvent.details) && (
            <div className="py-2 sm:py-3">
              <dt className="text-sm font-medium text-gray-500">
                {t("details")}
              </dt>
              <dd className="mt-1 inline-flex text-sm uppercase text-gray-900 sm:col-span-2 sm:mt-0">
                <div className="mt-3 overflow-x-auto rounded-md bg-gray-100">
                  <code className="mt-2 whitespace-pre p-2 text-xs">
                    <Editor
                      placeholder="Response body"
                      value={JSON.stringify(logEvent.details, null, 2)}
                      onValueChange={(body) => null}
                      readOnly
                      className="my-4 bg-slate-50"
                      highlight={(body) =>
                        highlight(body, languages.json, "json")
                      }
                      padding={10}
                      style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        minHeight: 100,
                      }}
                    />
                  </code>
                </div>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </>
  );
}
