import {
  CodeIcon,
  ExclamationCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { useCallback, useMemo, useState } from "react";

import AdvancedOptionDivider from "src/components/Form/AdvancedOptionDivider";
import CreateVariableModal from "./CreateVariableModal";
import FlowBuilderSelectVariable from "./FlowBuilderSelectVariable";
import KeyValueFieldWithVariablesNotice from "./KeyValueFieldWithVariablesNotice";
import SelectorField from "src/components/Form/SelectorField";
import { classNames } from "src/Helpers";
import { hasItems } from "src/helpers/utils";
import useFieldWithVariables from "src/containers/flow/builder/hooks/useFieldWithVariables";
import { useTranslation } from "react-i18next";
import useVariables from "src/containers/flow/builder/hooks/useVariables";

export interface KeyValueFieldWithVariablesProps {
  index: number;
  objectName: string;
  path: string;
  name: string;
  disabled?: boolean;
  currentStepIndex: number | null;
  variableSeparator?: string;
  onDelete: () => void;
  KeyComponent: JSX.Element;
  customOptions?: { label: string; value: string }[];
  customOptionsNoticeMessage?: string;
}

function KeyValueFieldWithVariables({
  index,
  objectName,
  path,
  name,
  disabled,
  currentStepIndex,
  variableSeparator,
  onDelete,
  KeyComponent,
  customOptions,
  customOptionsNoticeMessage,
}: KeyValueFieldWithVariablesProps) {
  const [t] = useTranslation();
  const { options, isInboundFlow, hasMissingActionTest, handleGetVariable } =
    useVariables({ currentStepIndex });
  const [newVar, setNewVar] = useState("");
  const [showCreateVariableModal, setShowCreateVariableModal] = useState(false);
  const {
    field,
    error,
    valueWithVariables,
    handleAddVariable,
    handleChange,
    handleCursorPosition,
  } = useFieldWithVariables({
    path,
    name: `${name}.value`,
    separator: variableSeparator,
    handleGetVariable,
  });
  const [showVariables, setShowVariables] = useState(false);
  const [useInputWithVariablesField, setUseInputWithVariablesField] = useState(
    Boolean(
      field.value &&
        !(customOptions?.map((option) => option.value) ?? []).includes(
          field.value
        )
    )
  );

  const hasCustomOptions = useMemo(
    () => hasItems(customOptions),
    [customOptions]
  );

  const overrideInputWithVariablesField = useMemo(
    () => Boolean(hasCustomOptions && useInputWithVariablesField),
    [hasCustomOptions, useInputWithVariablesField]
  );

  const shouldShowInputCustomVariables = useMemo(
    () => Boolean(overrideInputWithVariablesField || !hasCustomOptions),
    [hasCustomOptions, overrideInputWithVariablesField]
  );

  const handleSelectOption = useCallback(
    (option) => {
      setShowVariables(false);
      if (option && option.value) {
        handleAddVariable(option.value);
      }
    },
    [handleAddVariable]
  );

  const handleCreateOption = useCallback((inputValue) => {
    setNewVar(inputValue);
    setShowCreateVariableModal(true);
  }, []);

  const handleCloseCreateVariableModal = useCallback(() => {
    setShowCreateVariableModal(false);
    setNewVar("");
  }, []);

  return (
    <AdvancedOptionDivider
      keyVal={`${path}.${name}.${index}`}
      title={`${objectName} ${index + 1}`}
      handleRemove={onDelete}
      className="m-2 space-y-2"
      buttons={
        <div className="space-x-1">
          {hasCustomOptions && (
            <button
              className={classNames(
                "inline-flex items-center rounded-md border border-gray-600 bg-white px-3 py-2 text-sm font-medium leading-4  focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              )}
              type="button"
              onClick={() =>
                setUseInputWithVariablesField(!useInputWithVariablesField)
              }
            >
              <CodeIcon className="h-4 w-4" aria-hidden />
            </button>
          )}
          <button
            type="button"
            title={t("trash", "Trash")}
            className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={onDelete}
          >
            <TrashIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      }
    >
      {KeyComponent}
      {!shouldShowInputCustomVariables ? (
        <>
          <SelectorField
            isCreatable={false}
            name={`${path}.${name}.value`}
            options={customOptions}
          />
        </>
      ) : (
        <>
          <div className="col-span-2 flex flex-row">
            <div
              className={classNames(
                "relative flex flex-grow items-center h-[34px] border border-gray-300 focus-within:z-10",
                "focus-within:border-indigo-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500",
                !showVariables ? "rounded-none rounded-l-md" : "rounded-md",
                !disabled &&
                  error &&
                  "border-red-500 focus-within:border-red-500 focus-within:ring-red-500",
                disabled && "border-gray-200 shadow-none"
              )}
            >
              <input
                id={field.name}
                className={classNames(
                  "w-full border-none border-gray-300 placeholder-gray-400 sm:text-sm",
                  "focus:border-0 focus:outline-none focus:ring-0 sm:text-sm",
                  "disabled:bg-gray-50 disabled:text-gray-300 disabled:placeholder-gray-200",
                  "rounded-md pl-2 h-5",
                  error && "pr-10"
                )}
                disabled={disabled}
                {...field}
                placeholder={t(
                  "components:mapped-values-field.from-value.placeholder",
                  "Value"
                )}
                onKeyUp={handleCursorPosition}
                onKeyDown={handleCursorPosition}
                onClick={handleCursorPosition}
                value={valueWithVariables || ""}
                onChange={handleChange}
              />
              {error && (
                <div className="pointer-events-none absolute inset-y-0 right-0 z-10 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {!showVariables && (
              <button
                className={classNames(
                  "inline-flex items-center border-l-0 border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700",
                  "hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                  "rounded-r-md"
                )}
                type="button"
                onClick={() => setShowVariables(true)}
              >
                <CodeIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          {showVariables && (
            <div className="mb-2 flex flex-row items-end justify-end">
              <FlowBuilderSelectVariable
                options={options}
                isInboundFlow={isInboundFlow}
                onCreateVariable={handleCreateOption}
                hasMissingActionTest={hasMissingActionTest}
                onClose={() => setShowVariables(false)}
                onSelectVariable={handleSelectOption}
              />
            </div>
          )}
          <KeyValueFieldWithVariablesNotice
            showNotice={overrideInputWithVariablesField}
            variables={customOptions?.map((option) => option.value) ?? []}
            noticeMessage={customOptionsNoticeMessage}
          />
        </>
      )}
      {showCreateVariableModal && (
        <CreateVariableModal
          isOpen={showCreateVariableModal}
          onClose={handleCloseCreateVariableModal}
          addVariable={handleAddVariable}
          inputValue={newVar}
        />
      )}
    </AdvancedOptionDivider>
  );
}

export default KeyValueFieldWithVariables;
