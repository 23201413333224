import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
  MailIcon,
} from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import { getTranslationPayload as t } from "src/i18n";
import TextInputField from "../../components/Form/TextInputField";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import { ERROR_MESSAGES } from "../../constants/Form";
import { buildRoute } from "../../router/router";

const initialValues = {
  email: "",
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email(ERROR_MESSAGES.email)
    .required(ERROR_MESSAGES.required),
});

const FIELDS = {
  email: {
    key: "email",
    label: "components:input.email.label",
    placeholder: "components:input.email.placeholder",
  },
};

export const ResetPasswordForm = ({ onSubmit, success, submitted }) => {
  const { t } = useTranslation();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) =>
        onSubmit(
          values,
          setSubmitting,
          setBackendValidationErrors,
          setBackendError
        )
      }
      enableReinitialize={true}
      initialValues={initialValues}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit} className="space-y-4">
          <APIErrors
            APIError={backendError}
            APIValidationErrors={backendValidationErrors}
            fieldTranslations={FIELDS}
          ></APIErrors>

          <TextInputField
            placeholder={t(FIELDS.email.placeholder)}
            name={FIELDS.email.key}
            icon={MailIcon}
            type="email"
          />

          <div>
            <StateButton
              isSubmitting={formik.isSubmitting}
              submitted={submitted}
              success={success}
              disabled={
                formik.errors &&
                Object.getOwnPropertyNames(formik.errors).length > 0
              }
              submittingText={t("sending-email", "Sending email...")}
              successText={t("success")}
              initialText={
                <>
                  {t("settings:reset-password.label")}
                  <ArrowSmRightIcon
                    className="ml-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </>
              }
            ></StateButton>
          </div>

          <div className="mt-3">
            <Link
              to={buildRoute("login")}
              className="flex items-center text-sm text-gray-500"
            >
              <ArrowSmLeftIcon className="mr-1 h-5 w-5" aria-hidden="true" />
              {t("back")}
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
};
