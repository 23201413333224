import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ExclamationIcon } from "@heroicons/react/outline";
import { ArrowSmRightIcon } from "@heroicons/react/solid";
import { useStripeSubscription } from "src/hooks/useStripe";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";

export default function AccountSubscriptionUnpaidNotice() {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const { subscription } = useStripeSubscription();

  if (!session) {
    return null;
  }

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 grow">
          <h3 className="text-sm font-medium text-red-800">
            {t(
              "settings:subscription-payment-failed-notice.title",
              "We weren't able to charge your account for this month's usage."
            )}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            {t(
              "settings:subscription-payment-failed-notice.subtitle",
              "To avoid losing access to Hilos, pay your outstanding invoice before the 10th of this month."
            )}
          </div>
        </div>
        <div className="grow-0">
          {subscription?.last_invoice?.stripe_invoice_url ? (
            <a
              href={subscription?.last_invoice?.stripe_invoice_url || ""}
              target="_blank"
              rel="noreferrer"
              className="inline-flex w-full justify-center rounded-md border border-red-700 bg-white px-2 py-1.5 text-sm font-medium text-red-700"
            >
              {t(
                "settings:subscription-payment-failed-notice.button",
                "Pay now"
              )}

              <ArrowSmRightIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
            </a>
          ) : (
            <Link
              to={buildRoute("config-account-subscription")}
              className="inline-flex w-full justify-center rounded-md border border-red-700 bg-white px-2 py-1.5 text-sm font-medium text-red-700"
            >
              {t(
                "settings:subscription-payment-failed-notice.button",
                "Pay now"
              )}
              <ArrowSmRightIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
