import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { eachMinuteOfInterval, endOfDay, format, startOfDay } from "date-fns";
import { FieldArray } from "formik";
import SelectorField from "../../components/Form/SelectorField";
import SwitchField from "../../components/Form/SwitchField";
import TextAreaField from "../../components/Form/TextAreaField";
import { buildRoute } from "../../router/router";
import AccountFormUpdate from "./AccountFormUpdate";
import * as meta from "./AccountMeta";

export default function AccountFormHoursComponent() {
  const { t } = useTranslation();
  const today = new Date();
  const hoursFNS = eachMinuteOfInterval(
    {
      start: startOfDay(today),
      end: endOfDay(today),
    },
    { step: 30 }
  );
  const hourChoices = hoursFNS.map((h) => ({
    label: format(h, "HH:mm"),
    value: format(h, "HH:mm:ss"),
  }));

  return (
    <AccountFormUpdate>
      {(_, formik) => (
        <div className="mt-10 sm:mt-4">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:working-hours.description">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Working hours
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    You can set here the times your team is available for
                    customers.
                  </p>
                  <p className="mt-2 text-sm text-gray-600">
                    If a message comes in outside these hours, we'll send an
                    email to notify your team's users.
                  </p>
                  <p className="mt-2 text-sm text-gray-600">
                    Each user can set whether they want to be notified or not in
                    their{" "}
                    <Link
                      to={buildRoute("account-profile")}
                      className="text-blue-500"
                    >
                      profile
                    </Link>
                    .
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="bg-white px-4 py-4 sm:p-6">
                  <SwitchField
                    name={meta.FIELDS.has_working_hours.key}
                    label={t(meta.FIELDS.has_working_hours.label)}
                  />
                  {formik.values[meta.FIELDS.has_working_hours.key] && (
                    <>
                      <div className="grid grid-cols-3">
                        <div></div>
                        <div className="text-sm text-center text-gray-900">
                          {t(meta.FIELDS.working_hours.start_at.label)}
                        </div>
                        <div className="text-sm text-center text-gray-900">
                          {t(meta.FIELDS.working_hours.end_at.label)}
                        </div>
                      </div>
                      <div className="mt-2 divide-y divide-gray-200">
                        <FieldArray
                          name={meta.FIELDS.working_hours.key}
                          render={(_) => (
                            <>
                              {formik.values.working_hours.map((wh, idx) => (
                                <div
                                  key={wh.weekday}
                                  className="relative grid grid-cols-1 items-center py-4 sm:flex"
                                >
                                  <div className="flex items-center w-1/3">
                                    <input
                                      id={`${meta.FIELDS.working_hours.key}.${idx}.is_available`}
                                      name={`${meta.FIELDS.working_hours.key}.${idx}.is_available`}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      checked={
                                        formik.values[
                                          meta.FIELDS.working_hours.key
                                        ][idx].is_available
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `${meta.FIELDS.working_hours.key}.${idx}.is_available`,
                                          e.target.checked
                                        );
                                        if (!e.target.checked) {
                                          formik.setFieldValue(
                                            `${meta.FIELDS.working_hours.key}.${idx}.start_at`,
                                            null
                                          );
                                          formik.setFieldValue(
                                            `${meta.FIELDS.working_hours.key}.${idx}.end_at`,
                                            null
                                          );
                                        }
                                      }}
                                    />
                                    <div className="ml-2 min-w-0 flex-1 text-sm">
                                      <label
                                        htmlFor={`${meta.FIELDS.working_hours.key}.${idx}.is_available`}
                                        className="select-none font-medium text-gray-700"
                                      >
                                        {t(
                                          meta.DAY_LABELS_LONG[
                                            wh.weekday
                                          ].toLowerCase()
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-span-2 ml-3 mt-4 sm:mt-0 w-2/3">
                                    {wh.is_available && (
                                      <>
                                        <div className="flex items-start space-x-6">
                                          <div className="flex flex-col items-center w-1/2">
                                            <SelectorField
                                              name={`${meta.FIELDS.working_hours.key}.${idx}.start_at`}
                                              options={hourChoices}
                                            />
                                          </div>
                                          <div className="flex flex-col items-center w-1/2">
                                            <SelectorField
                                              name={`${meta.FIELDS.working_hours.key}.${idx}.end_at`}
                                              options={hourChoices}
                                            />
                                          </div>
                                        </div>
                                        {formik.errors[
                                          meta.FIELDS.working_hours.key
                                        ] &&
                                          formik.errors[
                                            meta.FIELDS.working_hours.key
                                          ][idx] &&
                                          typeof formik.errors[
                                            meta.FIELDS.working_hours.key
                                          ][idx] === "string" && (
                                            <div className="mt-1.5 text-xs text-red-500">
                                              {
                                                formik.errors[
                                                  meta.FIELDS.working_hours.key
                                                ][idx]
                                              }
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <Trans i18nKey="settings:automated-response.description">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Automated response
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Only available when working hours are activated.
                  </p>
                  <p className="mt-2 text-sm text-gray-600">
                    If a message comes in outside working hours, we'll answer
                    with this message.
                  </p>
                </Trans>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div
                  className={`bg-white px-4 py-4 sm:p-6 ${
                    !formik.values[meta.FIELDS.has_working_hours.key]
                      ? "pointer-events-none opacity-50"
                      : ""
                  }`}
                >
                  <div className="text-md mb-2 divide-y font-bold leading-6 text-gray-900">
                    {t(
                      "settings:automated-response.label",
                      "Automated response settings"
                    )}
                  </div>
                  <TextAreaField
                    type="text"
                    placeholder={t(
                      meta.FIELDS.after_hours_response.placeholder
                    )}
                    label={t(meta.FIELDS.after_hours_response.label)}
                    name={meta.FIELDS.after_hours_response.key}
                    help={t(meta.FIELDS.after_hours_response.help)}
                  />
                  <div className="mt-4 border-t border-gray-300 pt-2 text-xs text-gray-400">
                    {t(
                      "settings:automated-response.help",
                      "To avoid sending this message every time a contact sends a message after working hours, this message will be sent just once per 24 hours/contact."
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AccountFormUpdate>
  );
}
