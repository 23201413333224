import { useState } from "react";
import { useNavigate } from "react-router";
import { LightningBoltIcon } from "@heroicons/react/outline";
import axios, { AxiosError } from "axios";
import { HTTPFormMeta } from "../../components/HTTPForm/Meta";
import HeaderNavigation from "../../components/HeaderNavigation";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import InboxActionForm from "./InboxActionForm";
import * as meta from "./InboxActionMeta";

type AxiosValidationError = AxiosError<{ status: number; data: unknown }>;

export default function InboxActionCreate() {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const formSubmit = async (
    formData,
    setSubmitting: (b: boolean) => void,
    setBackendValidationErrors: (o: object) => void,
    setBackendError: (s: string) => void
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.INBOX_ACTION_LIST_CREATE),
        formData
      );
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        navigate(buildRoute("config-inbox-action-list"));
      }, 1000 * 2);
    } catch (err) {
      const axiosErr: AxiosValidationError = err as AxiosValidationError;
      setSuccess(false);
      if (axiosErr?.response?.status === 400) {
        console.log("error", axiosErr);
        setBackendValidationErrors(axiosErr?.response.data);
      } else {
        setBackendError(
          "An error occurred while creating your contact. Please try again."
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  const initialValues = {
    ...meta.InboxAction.getInitialValues(),
    request: HTTPFormMeta.getInitialValues(),
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: "Settings",
              url: buildRoute("config-account"),
            },
            {
              name: "Inbox Actions",
              url: buildRoute("config-inbox-action-list"),
            },
            {
              name: "New Inbox Action",
              url: buildRoute("config-inbox-action-create"),
            },
          ]}
        />

        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <LightningBoltIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              New Inbox Action
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-3xl">
          <InboxActionForm
            contact={initialValues}
            formSubmit={formSubmit}
            success={success}
            submitted={submitted}
          />
        </div>
      </div>
    </div>
  );
}
