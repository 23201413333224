import * as yup from "yup";
import { TimeWindowMeta } from "src/containers/flow/Helpers";
import { ERROR_MESSAGES } from "src/constants/Form";

export const FlowStepDelayMeta = {
  getInitialValues: function (step) {
    return {
      name: step.name,
      step_type: "DELAY",
      next_step_default_idx: step.next_step_default_idx,
      body: step?.body || "",
      has_max_wait_time: true,
      max_wait_time_amount: "24",
      max_wait_time_unit: "HOUR",
      set_time_window: false,
      time_window_type: "CUSTOM",
      time_windows: [],
    };
  },
  getSchema: function () {
    return yup.object().shape({
      name: yup.string().required(ERROR_MESSAGES.required),
      next_step_default_idx: yup.string(),
      max_wait_time_amount: yup
        .number()
        .min(0, ERROR_MESSAGES.minNumberValue)
        .required(ERROR_MESSAGES.required),
      max_wait_time_unit: yup.string().required(ERROR_MESSAGES.required),
      set_time_window: yup.bool(),
      time_window_type: yup.string().when("set_time_window", {
        is: true,
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
      time_windows: yup.mixed().when(["set_time_window", "time_window_type"], {
        is: (setTimeWindow, timeWindowType) =>
          setTimeWindow && timeWindowType === "CUSTOM",
        then: yup
          .array()
          .of(TimeWindowMeta.getSchema())
          .min(6, ERROR_MESSAGES.length_error),
      }),
    });
  },
  validate: function () {
    return {};
  },
  formatForSave: function (step) {
    const nextStep = { ...step };

    return nextStep;
  },

  FIELDS: {
    name: {
      key: "name",
      label: "Step name",
      help: "Use a unique name to be able to refer to this step",
      placeholder: "Give this step a name...",
    },
    next_step_default_idx: {
      key: "next_step_default_idx",
      label: "Next step",
      help: "If you can't find the step you'd like to go to next, check that it has a name.",
      placeholder: "Step 1 Welcome",
    },
    max_wait_time_amount: {
      key: "max_wait_time_amount",
      label: "Wait for",
      placeholder: "For example, Available Options",
    },
    max_wait_time_unit: {
      key: "max_wait_time_unit",
    },
    set_time_window: {
      key: "set_time_window",
      label: "Set a time window to continue?",
      help: "Useful if you'd like to wait a day to continue but only if it's between Mon-Fri.",
    },
    time_windows: {
      key: "time_windows",
      weekday: {
        key: "weekday",
        label: "Day of the week",
      },
      is_available: {
        key: "is_available",
        label: "Is available?",
      },
      start_at: {
        key: "start_at",
        label: "From",
      },
      end_at: {
        key: "end_at",
        label: "Until",
      },
    },
  },
};
