import { useTranslation } from "react-i18next";
import { PhoneIcon } from "@heroicons/react/outline";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import * as meta from "./Meta";

interface ChannelFormProps {
  disablePhoneEditing?: boolean;
}
export default function ChannelForm({
  disablePhoneEditing = false,
}: ChannelFormProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3">
          <TextInputField
            label={t(meta.FIELDS.name.label)}
            name={meta.FIELDS.name.key}
            help={t(meta.FIELDS.name.help)}
            type="text"
          />
        </div>
        <div className="col-span-3">
          <TextInputField
            placeholder={meta.FIELDS.channel_id.placeholder}
            label={t(meta.FIELDS.channel_id.label)}
            name={meta.FIELDS.channel_id.key}
            help={t(meta.FIELDS.channel_id.help)}
            icon={PhoneIcon}
            disabled={disablePhoneEditing}
            type="tel"
          />
        </div>
        <div className="col-span-3">
          <SwitchField
            label={t(meta.FIELDS.is_default.label)}
            name={meta.FIELDS.is_default.key}
            help={t(meta.FIELDS.is_default.help)}
          />
        </div>
      </div>
    </div>
  );
}
