import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "@heroicons/react/outline";
import axios from "axios";
import JsFileDownload from "js-file-download";
import { usePostHog } from "posthog-js/react";
import Button from "src/components/Button";
import LoaderSpinner from "src/components/LoaderSpinner";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";

interface InboxUsageDownloadCSVButtonProps {
  url: string;
  params: string;
  report_type?: string;
}

export default function InboxUsageDownloadCSVButton({
  url,
  params,
  report_type,
}: InboxUsageDownloadCSVButtonProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { formatWithLocale } = useDateFnsFormatLocale();

  const downloadResults = async () => {
    setLoading(true);
    posthog?.capture("inbox_usage download", {
      url,
      params,
      report_type,
    });
    try {
      const response = await axios.get<Blob>(url + ".csv?" + params);
      JsFileDownload(
        response.data,
        `${report_type} ${formatWithLocale(new Date(), "d-LLL-uuuu-HH-MM")}.csv`
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant="text"
      type="button"
      className="text-xs"
      onClick={downloadResults}
      disabled={loading}
    >
      {loading ? (
        <>
          <LoaderSpinner />
          {t(
            "flows:flow_execution.results.downloading_results",
            "Downloading..."
          )}
        </>
      ) : (
        <>
          <DownloadIcon className="mr-1 h-5 w-5" aria-hidden="true" />
          {t("reports:download-report-csv", "Download CSV")}
        </>
      )}
    </Button>
  );
}
