import { useField } from "formik";

interface CheckboxFieldProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "type"> {
  name: string;
  multiple?: boolean;
  optional?: boolean;
}

function CheckboxField({
  name,
  label,
  optional,
  multiple = false,
  ...props
}: CheckboxFieldProps) {
  const [field] = useField({ name, type: "checkbox", multiple: multiple });

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={name}
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        {...field}
        {...props}
      />
      <label htmlFor={name} className="ml-2 block text-sm text-gray-900">
        {label}{" "}
        {optional && <span className="text-xs text-gray-500">(optional)</span>}
      </label>
    </div>
  );
}

export default CheckboxField;
