import { Trans, useTranslation } from "react-i18next";
import { FlowStepEdit } from "@hilos/types/private-schema";
import { TimeoutMeta } from "../../Helpers";
import FlowStepTimeWindowDetail from "./components/FlowStepTimeWindowDetail";

interface FlowStepDelayDetailProps {
  step: FlowStepEdit;
  getStepById: (id: string) => FlowStepEdit;
}

export default function FlowStepDelayDetail({
  step,
  getStepById,
}: FlowStepDelayDetailProps) {
  const { t } = useTranslation();
  const maxWaitTimeOption = TimeoutMeta.MAX_WAIT_TIME_OPTIONS.find(
    (at) => at.value === step.max_wait_time_unit
  );

  return (
    <div className="space-y-2">
      <p>
        {t("flow-executions:steps-detail.delay.wait-for", "Wait for")}
        <span className="text-gray-900 font-medium">
          {" "}
          {step.max_wait_time_amount}{" "}
          {maxWaitTimeOption
            ? t(maxWaitTimeOption.label)
            : step.max_wait_time_unit}
        </span>
        , {t("go-to")}{" "}
        <span className="text-gray-900 font-medium">
          {getStepById(step.next_step_default as string)?.name}
        </span>
      </p>

      {step.set_time_window && <FlowStepTimeWindowDetail step={step} />}
    </div>
  );
}
