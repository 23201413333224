import AddForkStepEdge from "./AddForkStepEdge";
import AddStepEdge from "./AddStepEdge";
import ForkStepEdge from "./ForkStepEdge";
import StepEdge from "./StepEdge";
import UnrechableForkStepEdge from "./UnrechableForkStepEdge";

const edgeTypes = {
  step_edge: StepEdge,
  fork_step_edge: ForkStepEdge,
  add_step_edge: AddStepEdge,
  add_fork_step_edge: AddForkStepEdge,
  unreachable_fork_step_edge: UnrechableForkStepEdge,
};

export default edgeTypes;
