import "./Country.css";

export const formatCountryOptionLabel = ({
  value,
  label,
  icon,
  help,
  ...props
}) => (
  <div className="justify-left flex items-center">
    <div className="mr-2">
      <span className={icon}></span>
    </div>
    <div className="grow-1 text-sm">{label}</div>
  </div>
);

export const COUNTRIES_WITH_FLAGS = [
  {
    label: "Afghanistan",
    value: "AF",
    flag: "https://hilos.io/static/flags/af.gif",
    icon: "flag-sprite flag-a flag-_f",
  },
  {
    label: "Åland Islands",
    value: "AX",
    flag: "https://hilos.io/static/flags/ax.gif",
    icon: "flag-sprite flag-a flag-_x",
  },
  {
    label: "Albania",
    value: "AL",
    flag: "https://hilos.io/static/flags/al.gif",
    icon: "flag-sprite flag-a flag-_l",
  },
  {
    label: "Algeria",
    value: "DZ",
    flag: "https://hilos.io/static/flags/dz.gif",
    icon: "flag-sprite flag-d flag-_z",
  },
  {
    label: "American Samoa",
    value: "AS",
    flag: "https://hilos.io/static/flags/as.gif",
    icon: "flag-sprite flag-a flag-_s",
  },
  {
    label: "Andorra",
    value: "AD",
    flag: "https://hilos.io/static/flags/ad.gif",
    icon: "flag-sprite flag-a flag-_d",
  },
  {
    label: "Angola",
    value: "AO",
    flag: "https://hilos.io/static/flags/ao.gif",
    icon: "flag-sprite flag-a flag-_o",
  },
  {
    label: "Anguilla",
    value: "AI",
    flag: "https://hilos.io/static/flags/ai.gif",
    icon: "flag-sprite flag-a flag-_i",
  },
  {
    label: "Antarctica",
    value: "AQ",
    flag: "https://hilos.io/static/flags/aq.gif",
    icon: "flag-sprite flag-a flag-_q",
  },
  {
    label: "Antigua and Barbuda",
    value: "AG",
    flag: "https://hilos.io/static/flags/ag.gif",
    icon: "flag-sprite flag-a flag-_g",
  },
  {
    label: "Argentina",
    value: "AR",
    flag: "https://hilos.io/static/flags/ar.gif",
    icon: "flag-sprite flag-a flag-_r",
  },
  {
    label: "Armenia",
    value: "AM",
    flag: "https://hilos.io/static/flags/am.gif",
    icon: "flag-sprite flag-a flag-_m",
  },
  {
    label: "Aruba",
    value: "AW",
    flag: "https://hilos.io/static/flags/aw.gif",
    icon: "flag-sprite flag-a flag-_w",
  },
  {
    label: "Australia",
    value: "AU",
    flag: "https://hilos.io/static/flags/au.gif",
    icon: "flag-sprite flag-a flag-_u",
  },
  {
    label: "Austria",
    value: "AT",
    flag: "https://hilos.io/static/flags/at.gif",
    icon: "flag-sprite flag-a flag-_t",
  },
  {
    label: "Azerbaijan",
    value: "AZ",
    flag: "https://hilos.io/static/flags/az.gif",
    icon: "flag-sprite flag-a flag-_z",
  },
  {
    label: "Bahamas",
    value: "BS",
    flag: "https://hilos.io/static/flags/bs.gif",
    icon: "flag-sprite flag-b flag-_s",
  },
  {
    label: "Bahrain",
    value: "BH",
    flag: "https://hilos.io/static/flags/bh.gif",
    icon: "flag-sprite flag-b flag-_h",
  },
  {
    label: "Bangladesh",
    value: "BD",
    flag: "https://hilos.io/static/flags/bd.gif",
    icon: "flag-sprite flag-b flag-_d",
  },
  {
    label: "Barbados",
    value: "BB",
    flag: "https://hilos.io/static/flags/bb.gif",
    icon: "flag-sprite flag-b flag-_b",
  },
  {
    label: "Belarus",
    value: "BY",
    flag: "https://hilos.io/static/flags/by.gif",
    icon: "flag-sprite flag-b flag-_y",
  },
  {
    label: "Belgium",
    value: "BE",
    flag: "https://hilos.io/static/flags/be.gif",
    icon: "flag-sprite flag-b flag-_e",
  },
  {
    label: "Belize",
    value: "BZ",
    flag: "https://hilos.io/static/flags/bz.gif",
    icon: "flag-sprite flag-b flag-_z",
  },
  {
    label: "Benin",
    value: "BJ",
    flag: "https://hilos.io/static/flags/bj.gif",
    icon: "flag-sprite flag-b flag-_j",
  },
  {
    label: "Bermuda",
    value: "BM",
    flag: "https://hilos.io/static/flags/bm.gif",
    icon: "flag-sprite flag-b flag-_m",
  },
  {
    label: "Bhutan",
    value: "BT",
    flag: "https://hilos.io/static/flags/bt.gif",
    icon: "flag-sprite flag-b flag-_t",
  },
  {
    label: "Bolivia",
    value: "BO",
    flag: "https://hilos.io/static/flags/bo.gif",
    icon: "flag-sprite flag-b flag-_o",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "BQ",
    flag: "https://hilos.io/static/flags/bq.gif",
    icon: "flag-sprite flag-b flag-_q",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "BA",
    flag: "https://hilos.io/static/flags/ba.gif",
    icon: "flag-sprite flag-b flag-_a",
  },
  {
    label: "Botswana",
    value: "BW",
    flag: "https://hilos.io/static/flags/bw.gif",
    icon: "flag-sprite flag-b flag-_w",
  },
  {
    label: "Bouvet Island",
    value: "BV",
    flag: "https://hilos.io/static/flags/bv.gif",
    icon: "flag-sprite flag-b flag-_v",
  },
  {
    label: "Brazil",
    value: "BR",
    flag: "https://hilos.io/static/flags/br.gif",
    icon: "flag-sprite flag-b flag-_r",
  },
  {
    label: "British Indian Ocean Territory",
    value: "IO",
    flag: "https://hilos.io/static/flags/io.gif",
    icon: "flag-sprite flag-i flag-_o",
  },
  {
    label: "Brunei",
    value: "BN",
    flag: "https://hilos.io/static/flags/bn.gif",
    icon: "flag-sprite flag-b flag-_n",
  },
  {
    label: "Bulgaria",
    value: "BG",
    flag: "https://hilos.io/static/flags/bg.gif",
    icon: "flag-sprite flag-b flag-_g",
  },
  {
    label: "Burkina Faso",
    value: "BF",
    flag: "https://hilos.io/static/flags/bf.gif",
    icon: "flag-sprite flag-b flag-_f",
  },
  {
    label: "Burundi",
    value: "BI",
    flag: "https://hilos.io/static/flags/bi.gif",
    icon: "flag-sprite flag-b flag-_i",
  },
  {
    label: "Cabo Verde",
    value: "CV",
    flag: "https://hilos.io/static/flags/cv.gif",
    icon: "flag-sprite flag-c flag-_v",
  },
  {
    label: "Cambodia",
    value: "KH",
    flag: "https://hilos.io/static/flags/kh.gif",
    icon: "flag-sprite flag-k flag-_h",
  },
  {
    label: "Cameroon",
    value: "CM",
    flag: "https://hilos.io/static/flags/cm.gif",
    icon: "flag-sprite flag-c flag-_m",
  },
  {
    label: "Canada",
    value: "CA",
    flag: "https://hilos.io/static/flags/ca.gif",
    icon: "flag-sprite flag-c flag-_a",
  },
  {
    label: "Cayman Islands",
    value: "KY",
    flag: "https://hilos.io/static/flags/ky.gif",
    icon: "flag-sprite flag-k flag-_y",
  },
  {
    label: "Central African Republic",
    value: "CF",
    flag: "https://hilos.io/static/flags/cf.gif",
    icon: "flag-sprite flag-c flag-_f",
  },
  {
    label: "Chad",
    value: "TD",
    flag: "https://hilos.io/static/flags/td.gif",
    icon: "flag-sprite flag-t flag-_d",
  },
  {
    label: "Chile",
    value: "CL",
    flag: "https://hilos.io/static/flags/cl.gif",
    icon: "flag-sprite flag-c flag-_l",
  },
  {
    label: "China",
    value: "CN",
    flag: "https://hilos.io/static/flags/cn.gif",
    icon: "flag-sprite flag-c flag-_n",
  },
  {
    label: "Christmas Island",
    value: "CX",
    flag: "https://hilos.io/static/flags/cx.gif",
    icon: "flag-sprite flag-c flag-_x",
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "CC",
    flag: "https://hilos.io/static/flags/cc.gif",
    icon: "flag-sprite flag-c flag-_c",
  },
  {
    label: "Colombia",
    value: "CO",
    flag: "https://hilos.io/static/flags/co.gif",
    icon: "flag-sprite flag-c flag-_o",
  },
  {
    label: "Comoros",
    value: "KM",
    flag: "https://hilos.io/static/flags/km.gif",
    icon: "flag-sprite flag-k flag-_m",
  },
  {
    label: "Congo",
    value: "CG",
    flag: "https://hilos.io/static/flags/cg.gif",
    icon: "flag-sprite flag-c flag-_g",
  },
  {
    label: "Congo (the Democratic Republic of the)",
    value: "CD",
    flag: "https://hilos.io/static/flags/cd.gif",
    icon: "flag-sprite flag-c flag-_d",
  },
  {
    label: "Cook Islands",
    value: "CK",
    flag: "https://hilos.io/static/flags/ck.gif",
    icon: "flag-sprite flag-c flag-_k",
  },
  {
    label: "Costa Rica",
    value: "CR",
    flag: "https://hilos.io/static/flags/cr.gif",
    icon: "flag-sprite flag-c flag-_r",
  },
  {
    label: "Côte d'Ivoire",
    value: "CI",
    flag: "https://hilos.io/static/flags/ci.gif",
    icon: "flag-sprite flag-c flag-_i",
  },
  {
    label: "Croatia",
    value: "HR",
    flag: "https://hilos.io/static/flags/hr.gif",
    icon: "flag-sprite flag-h flag-_r",
  },
  {
    label: "Cuba",
    value: "CU",
    flag: "https://hilos.io/static/flags/cu.gif",
    icon: "flag-sprite flag-c flag-_u",
  },
  {
    label: "Curaçao",
    value: "CW",
    flag: "https://hilos.io/static/flags/cw.gif",
    icon: "flag-sprite flag-c flag-_w",
  },
  {
    label: "Cyprus",
    value: "CY",
    flag: "https://hilos.io/static/flags/cy.gif",
    icon: "flag-sprite flag-c flag-_y",
  },
  {
    label: "Czechia",
    value: "CZ",
    flag: "https://hilos.io/static/flags/cz.gif",
    icon: "flag-sprite flag-c flag-_z",
  },
  {
    label: "Denmark",
    value: "DK",
    flag: "https://hilos.io/static/flags/dk.gif",
    icon: "flag-sprite flag-d flag-_k",
  },
  {
    label: "Djibouti",
    value: "DJ",
    flag: "https://hilos.io/static/flags/dj.gif",
    icon: "flag-sprite flag-d flag-_j",
  },
  {
    label: "Dominica",
    value: "DM",
    flag: "https://hilos.io/static/flags/dm.gif",
    icon: "flag-sprite flag-d flag-_m",
  },
  {
    label: "Dominican Republic",
    value: "DO",
    flag: "https://hilos.io/static/flags/do.gif",
    icon: "flag-sprite flag-d flag-_o",
  },
  {
    label: "Ecuador",
    value: "EC",
    flag: "https://hilos.io/static/flags/ec.gif",
    icon: "flag-sprite flag-e flag-_c",
  },
  {
    label: "Egypt",
    value: "EG",
    flag: "https://hilos.io/static/flags/eg.gif",
    icon: "flag-sprite flag-e flag-_g",
  },
  {
    label: "El Salvador",
    value: "SV",
    flag: "https://hilos.io/static/flags/sv.gif",
    icon: "flag-sprite flag-s flag-_v",
  },
  {
    label: "Equatorial Guinea",
    value: "GQ",
    flag: "https://hilos.io/static/flags/gq.gif",
    icon: "flag-sprite flag-g flag-_q",
  },
  {
    label: "Eritrea",
    value: "ER",
    flag: "https://hilos.io/static/flags/er.gif",
    icon: "flag-sprite flag-e flag-_r",
  },
  {
    label: "Estonia",
    value: "EE",
    flag: "https://hilos.io/static/flags/ee.gif",
    icon: "flag-sprite flag-e flag-_e",
  },
  {
    label: "Eswatini",
    value: "SZ",
    flag: "https://hilos.io/static/flags/sz.gif",
    icon: "flag-sprite flag-s flag-_z",
  },
  {
    label: "Ethiopia",
    value: "ET",
    flag: "https://hilos.io/static/flags/et.gif",
    icon: "flag-sprite flag-e flag-_t",
  },
  {
    label: "Falkland Islands (Malvinas)",
    value: "FK",
    flag: "https://hilos.io/static/flags/fk.gif",
    icon: "flag-sprite flag-f flag-_k",
  },
  {
    label: "Faroe Islands",
    value: "FO",
    flag: "https://hilos.io/static/flags/fo.gif",
    icon: "flag-sprite flag-f flag-_o",
  },
  {
    label: "Fiji",
    value: "FJ",
    flag: "https://hilos.io/static/flags/fj.gif",
    icon: "flag-sprite flag-f flag-_j",
  },
  {
    label: "Finland",
    value: "FI",
    flag: "https://hilos.io/static/flags/fi.gif",
    icon: "flag-sprite flag-f flag-_i",
  },
  {
    label: "France",
    value: "FR",
    flag: "https://hilos.io/static/flags/fr.gif",
    icon: "flag-sprite flag-f flag-_r",
  },
  {
    label: "French Guiana",
    value: "GF",
    flag: "https://hilos.io/static/flags/gf.gif",
    icon: "flag-sprite flag-g flag-_f",
  },
  {
    label: "French Polynesia",
    value: "PF",
    flag: "https://hilos.io/static/flags/pf.gif",
    icon: "flag-sprite flag-p flag-_f",
  },
  {
    label: "French Southern Territories",
    value: "TF",
    flag: "https://hilos.io/static/flags/tf.gif",
    icon: "flag-sprite flag-t flag-_f",
  },
  {
    label: "Gabon",
    value: "GA",
    flag: "https://hilos.io/static/flags/ga.gif",
    icon: "flag-sprite flag-g flag-_a",
  },
  {
    label: "Gambia",
    value: "GM",
    flag: "https://hilos.io/static/flags/gm.gif",
    icon: "flag-sprite flag-g flag-_m",
  },
  {
    label: "Georgia",
    value: "GE",
    flag: "https://hilos.io/static/flags/ge.gif",
    icon: "flag-sprite flag-g flag-_e",
  },
  {
    label: "Germany",
    value: "DE",
    flag: "https://hilos.io/static/flags/de.gif",
    icon: "flag-sprite flag-d flag-_e",
  },
  {
    label: "Ghana",
    value: "GH",
    flag: "https://hilos.io/static/flags/gh.gif",
    icon: "flag-sprite flag-g flag-_h",
  },
  {
    label: "Gibraltar",
    value: "GI",
    flag: "https://hilos.io/static/flags/gi.gif",
    icon: "flag-sprite flag-g flag-_i",
  },
  {
    label: "Greece",
    value: "GR",
    flag: "https://hilos.io/static/flags/gr.gif",
    icon: "flag-sprite flag-g flag-_r",
  },
  {
    label: "Greenland",
    value: "GL",
    flag: "https://hilos.io/static/flags/gl.gif",
    icon: "flag-sprite flag-g flag-_l",
  },
  {
    label: "Grenada",
    value: "GD",
    flag: "https://hilos.io/static/flags/gd.gif",
    icon: "flag-sprite flag-g flag-_d",
  },
  {
    label: "Guadeloupe",
    value: "GP",
    flag: "https://hilos.io/static/flags/gp.gif",
    icon: "flag-sprite flag-g flag-_p",
  },
  {
    label: "Guam",
    value: "GU",
    flag: "https://hilos.io/static/flags/gu.gif",
    icon: "flag-sprite flag-g flag-_u",
  },
  {
    label: "Guatemala",
    value: "GT",
    flag: "https://hilos.io/static/flags/gt.gif",
    icon: "flag-sprite flag-g flag-_t",
  },
  {
    label: "Guernsey",
    value: "GG",
    flag: "https://hilos.io/static/flags/gg.gif",
    icon: "flag-sprite flag-g flag-_g",
  },
  {
    label: "Guinea",
    value: "GN",
    flag: "https://hilos.io/static/flags/gn.gif",
    icon: "flag-sprite flag-g flag-_n",
  },
  {
    label: "Guinea-Bissau",
    value: "GW",
    flag: "https://hilos.io/static/flags/gw.gif",
    icon: "flag-sprite flag-g flag-_w",
  },
  {
    label: "Guyana",
    value: "GY",
    flag: "https://hilos.io/static/flags/gy.gif",
    icon: "flag-sprite flag-g flag-_y",
  },
  {
    label: "Haiti",
    value: "HT",
    flag: "https://hilos.io/static/flags/ht.gif",
    icon: "flag-sprite flag-h flag-_t",
  },
  {
    label: "Heard Island and McDonald Islands",
    value: "HM",
    flag: "https://hilos.io/static/flags/hm.gif",
    icon: "flag-sprite flag-h flag-_m",
  },
  {
    label: "Holy See",
    value: "VA",
    flag: "https://hilos.io/static/flags/va.gif",
    icon: "flag-sprite flag-v flag-_a",
  },
  {
    label: "Honduras",
    value: "HN",
    flag: "https://hilos.io/static/flags/hn.gif",
    icon: "flag-sprite flag-h flag-_n",
  },
  {
    label: "Hong Kong",
    value: "HK",
    flag: "https://hilos.io/static/flags/hk.gif",
    icon: "flag-sprite flag-h flag-_k",
  },
  {
    label: "Hungary",
    value: "HU",
    flag: "https://hilos.io/static/flags/hu.gif",
    icon: "flag-sprite flag-h flag-_u",
  },
  {
    label: "Iceland",
    value: "IS",
    flag: "https://hilos.io/static/flags/is.gif",
    icon: "flag-sprite flag-i flag-_s",
  },
  {
    label: "India",
    value: "IN",
    flag: "https://hilos.io/static/flags/in.gif",
    icon: "flag-sprite flag-i flag-_n",
  },
  {
    label: "Indonesia",
    value: "ID",
    flag: "https://hilos.io/static/flags/id.gif",
    icon: "flag-sprite flag-i flag-_d",
  },
  {
    label: "Iran",
    value: "IR",
    flag: "https://hilos.io/static/flags/ir.gif",
    icon: "flag-sprite flag-i flag-_r",
  },
  {
    label: "Iraq",
    value: "IQ",
    flag: "https://hilos.io/static/flags/iq.gif",
    icon: "flag-sprite flag-i flag-_q",
  },
  {
    label: "Ireland",
    value: "IE",
    flag: "https://hilos.io/static/flags/ie.gif",
    icon: "flag-sprite flag-i flag-_e",
  },
  {
    label: "Isle of Man",
    value: "IM",
    flag: "https://hilos.io/static/flags/im.gif",
    icon: "flag-sprite flag-i flag-_m",
  },
  {
    label: "Israel",
    value: "IL",
    flag: "https://hilos.io/static/flags/il.gif",
    icon: "flag-sprite flag-i flag-_l",
  },
  {
    label: "Italy",
    value: "IT",
    flag: "https://hilos.io/static/flags/it.gif",
    icon: "flag-sprite flag-i flag-_t",
  },
  {
    label: "Jamaica",
    value: "JM",
    flag: "https://hilos.io/static/flags/jm.gif",
    icon: "flag-sprite flag-j flag-_m",
  },
  {
    label: "Japan",
    value: "JP",
    flag: "https://hilos.io/static/flags/jp.gif",
    icon: "flag-sprite flag-j flag-_p",
  },
  {
    label: "Jersey",
    value: "JE",
    flag: "https://hilos.io/static/flags/je.gif",
    icon: "flag-sprite flag-j flag-_e",
  },
  {
    label: "Jordan",
    value: "JO",
    flag: "https://hilos.io/static/flags/jo.gif",
    icon: "flag-sprite flag-j flag-_o",
  },
  {
    label: "Kazakhstan",
    value: "KZ",
    flag: "https://hilos.io/static/flags/kz.gif",
    icon: "flag-sprite flag-k flag-_z",
  },
  {
    label: "Kenya",
    value: "KE",
    flag: "https://hilos.io/static/flags/ke.gif",
    icon: "flag-sprite flag-k flag-_e",
  },
  {
    label: "Kiribati",
    value: "KI",
    flag: "https://hilos.io/static/flags/ki.gif",
    icon: "flag-sprite flag-k flag-_i",
  },
  {
    label: "Kuwait",
    value: "KW",
    flag: "https://hilos.io/static/flags/kw.gif",
    icon: "flag-sprite flag-k flag-_w",
  },
  {
    label: "Kyrgyzstan",
    value: "KG",
    flag: "https://hilos.io/static/flags/kg.gif",
    icon: "flag-sprite flag-k flag-_g",
  },
  {
    label: "Laos",
    value: "LA",
    flag: "https://hilos.io/static/flags/la.gif",
    icon: "flag-sprite flag-l flag-_a",
  },
  {
    label: "Latvia",
    value: "LV",
    flag: "https://hilos.io/static/flags/lv.gif",
    icon: "flag-sprite flag-l flag-_v",
  },
  {
    label: "Lebanon",
    value: "LB",
    flag: "https://hilos.io/static/flags/lb.gif",
    icon: "flag-sprite flag-l flag-_b",
  },
  {
    label: "Lesotho",
    value: "LS",
    flag: "https://hilos.io/static/flags/ls.gif",
    icon: "flag-sprite flag-l flag-_s",
  },
  {
    label: "Liberia",
    value: "LR",
    flag: "https://hilos.io/static/flags/lr.gif",
    icon: "flag-sprite flag-l flag-_r",
  },
  {
    label: "Libya",
    value: "LY",
    flag: "https://hilos.io/static/flags/ly.gif",
    icon: "flag-sprite flag-l flag-_y",
  },
  {
    label: "Liechtenstein",
    value: "LI",
    flag: "https://hilos.io/static/flags/li.gif",
    icon: "flag-sprite flag-l flag-_i",
  },
  {
    label: "Lithuania",
    value: "LT",
    flag: "https://hilos.io/static/flags/lt.gif",
    icon: "flag-sprite flag-l flag-_t",
  },
  {
    label: "Luxembourg",
    value: "LU",
    flag: "https://hilos.io/static/flags/lu.gif",
    icon: "flag-sprite flag-l flag-_u",
  },
  {
    label: "Macao",
    value: "MO",
    flag: "https://hilos.io/static/flags/mo.gif",
    icon: "flag-sprite flag-m flag-_o",
  },
  {
    label: "Madagascar",
    value: "MG",
    flag: "https://hilos.io/static/flags/mg.gif",
    icon: "flag-sprite flag-m flag-_g",
  },
  {
    label: "Malawi",
    value: "MW",
    flag: "https://hilos.io/static/flags/mw.gif",
    icon: "flag-sprite flag-m flag-_w",
  },
  {
    label: "Malaysia",
    value: "MY",
    flag: "https://hilos.io/static/flags/my.gif",
    icon: "flag-sprite flag-m flag-_y",
  },
  {
    label: "Maldives",
    value: "MV",
    flag: "https://hilos.io/static/flags/mv.gif",
    icon: "flag-sprite flag-m flag-_v",
  },
  {
    label: "Mali",
    value: "ML",
    flag: "https://hilos.io/static/flags/ml.gif",
    icon: "flag-sprite flag-m flag-_l",
  },
  {
    label: "Malta",
    value: "MT",
    flag: "https://hilos.io/static/flags/mt.gif",
    icon: "flag-sprite flag-m flag-_t",
  },
  {
    label: "Marshall Islands",
    value: "MH",
    flag: "https://hilos.io/static/flags/mh.gif",
    icon: "flag-sprite flag-m flag-_h",
  },
  {
    label: "Martinique",
    value: "MQ",
    flag: "https://hilos.io/static/flags/mq.gif",
    icon: "flag-sprite flag-m flag-_q",
  },
  {
    label: "Mauritania",
    value: "MR",
    flag: "https://hilos.io/static/flags/mr.gif",
    icon: "flag-sprite flag-m flag-_r",
  },
  {
    label: "Mauritius",
    value: "MU",
    flag: "https://hilos.io/static/flags/mu.gif",
    icon: "flag-sprite flag-m flag-_u",
  },
  {
    label: "Mayotte",
    value: "YT",
    flag: "https://hilos.io/static/flags/yt.gif",
    icon: "flag-sprite flag-y flag-_t",
  },
  {
    label: "Mexico",
    value: "MX",
    flag: "https://hilos.io/static/flags/mx.gif",
    icon: "flag-sprite flag-m flag-_x",
  },
  {
    label: "Micronesia (Federated States of)",
    value: "FM",
    flag: "https://hilos.io/static/flags/fm.gif",
    icon: "flag-sprite flag-f flag-_m",
  },
  {
    label: "Moldova",
    value: "MD",
    flag: "https://hilos.io/static/flags/md.gif",
    icon: "flag-sprite flag-m flag-_d",
  },
  {
    label: "Monaco",
    value: "MC",
    flag: "https://hilos.io/static/flags/mc.gif",
    icon: "flag-sprite flag-m flag-_c",
  },
  {
    label: "Mongolia",
    value: "MN",
    flag: "https://hilos.io/static/flags/mn.gif",
    icon: "flag-sprite flag-m flag-_n",
  },
  {
    label: "Montenegro",
    value: "ME",
    flag: "https://hilos.io/static/flags/me.gif",
    icon: "flag-sprite flag-m flag-_e",
  },
  {
    label: "Montserrat",
    value: "MS",
    flag: "https://hilos.io/static/flags/ms.gif",
    icon: "flag-sprite flag-m flag-_s",
  },
  {
    label: "Morocco",
    value: "MA",
    flag: "https://hilos.io/static/flags/ma.gif",
    icon: "flag-sprite flag-m flag-_a",
  },
  {
    label: "Mozambique",
    value: "MZ",
    flag: "https://hilos.io/static/flags/mz.gif",
    icon: "flag-sprite flag-m flag-_z",
  },
  {
    label: "Myanmar",
    value: "MM",
    flag: "https://hilos.io/static/flags/mm.gif",
    icon: "flag-sprite flag-m flag-_m",
  },
  {
    label: "Namibia",
    value: "NA",
    flag: "https://hilos.io/static/flags/na.gif",
    icon: "flag-sprite flag-n flag-_a",
  },
  {
    label: "Nauru",
    value: "NR",
    flag: "https://hilos.io/static/flags/nr.gif",
    icon: "flag-sprite flag-n flag-_r",
  },
  {
    label: "Nepal",
    value: "NP",
    flag: "https://hilos.io/static/flags/np.gif",
    icon: "flag-sprite flag-n flag-_p",
  },
  {
    label: "Netherlands",
    value: "NL",
    flag: "https://hilos.io/static/flags/nl.gif",
    icon: "flag-sprite flag-n flag-_l",
  },
  {
    label: "New Caledonia",
    value: "NC",
    flag: "https://hilos.io/static/flags/nc.gif",
    icon: "flag-sprite flag-n flag-_c",
  },
  {
    label: "New Zealand",
    value: "NZ",
    flag: "https://hilos.io/static/flags/nz.gif",
    icon: "flag-sprite flag-n flag-_z",
  },
  {
    label: "Nicaragua",
    value: "NI",
    flag: "https://hilos.io/static/flags/ni.gif",
    icon: "flag-sprite flag-n flag-_i",
  },
  {
    label: "Niger",
    value: "NE",
    flag: "https://hilos.io/static/flags/ne.gif",
    icon: "flag-sprite flag-n flag-_e",
  },
  {
    label: "Nigeria",
    value: "NG",
    flag: "https://hilos.io/static/flags/ng.gif",
    icon: "flag-sprite flag-n flag-_g",
  },
  {
    label: "Niue",
    value: "NU",
    flag: "https://hilos.io/static/flags/nu.gif",
    icon: "flag-sprite flag-n flag-_u",
  },
  {
    label: "Norfolk Island",
    value: "NF",
    flag: "https://hilos.io/static/flags/nf.gif",
    icon: "flag-sprite flag-n flag-_f",
  },
  {
    label: "North Korea",
    value: "KP",
    flag: "https://hilos.io/static/flags/kp.gif",
    icon: "flag-sprite flag-k flag-_p",
  },
  {
    label: "North Macedonia",
    value: "MK",
    flag: "https://hilos.io/static/flags/mk.gif",
    icon: "flag-sprite flag-m flag-_k",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
    flag: "https://hilos.io/static/flags/mp.gif",
    icon: "flag-sprite flag-m flag-_p",
  },
  {
    label: "Norway",
    value: "NO",
    flag: "https://hilos.io/static/flags/no.gif",
    icon: "flag-sprite flag-n flag-_o",
  },
  {
    label: "Oman",
    value: "OM",
    flag: "https://hilos.io/static/flags/om.gif",
    icon: "flag-sprite flag-o flag-_m",
  },
  {
    label: "Pakistan",
    value: "PK",
    flag: "https://hilos.io/static/flags/pk.gif",
    icon: "flag-sprite flag-p flag-_k",
  },
  {
    label: "Palau",
    value: "PW",
    flag: "https://hilos.io/static/flags/pw.gif",
    icon: "flag-sprite flag-p flag-_w",
  },
  {
    label: "Palestine, State of",
    value: "PS",
    flag: "https://hilos.io/static/flags/ps.gif",
    icon: "flag-sprite flag-p flag-_s",
  },
  {
    label: "Panama",
    value: "PA",
    flag: "https://hilos.io/static/flags/pa.gif",
    icon: "flag-sprite flag-p flag-_a",
  },
  {
    label: "Papua New Guinea",
    value: "PG",
    flag: "https://hilos.io/static/flags/pg.gif",
    icon: "flag-sprite flag-p flag-_g",
  },
  {
    label: "Paraguay",
    value: "PY",
    flag: "https://hilos.io/static/flags/py.gif",
    icon: "flag-sprite flag-p flag-_y",
  },
  {
    label: "Peru",
    value: "PE",
    flag: "https://hilos.io/static/flags/pe.gif",
    icon: "flag-sprite flag-p flag-_e",
  },
  {
    label: "Philippines",
    value: "PH",
    flag: "https://hilos.io/static/flags/ph.gif",
    icon: "flag-sprite flag-p flag-_h",
  },
  {
    label: "Pitcairn",
    value: "PN",
    flag: "https://hilos.io/static/flags/pn.gif",
    icon: "flag-sprite flag-p flag-_n",
  },
  {
    label: "Poland",
    value: "PL",
    flag: "https://hilos.io/static/flags/pl.gif",
    icon: "flag-sprite flag-p flag-_l",
  },
  {
    label: "Portugal",
    value: "PT",
    flag: "https://hilos.io/static/flags/pt.gif",
    icon: "flag-sprite flag-p flag-_t",
  },
  {
    label: "Puerto Rico",
    value: "PR",
    flag: "https://hilos.io/static/flags/pr.gif",
    icon: "flag-sprite flag-p flag-_r",
  },
  {
    label: "Qatar",
    value: "QA",
    flag: "https://hilos.io/static/flags/qa.gif",
    icon: "flag-sprite flag-q flag-_a",
  },
  {
    label: "Réunion",
    value: "RE",
    flag: "https://hilos.io/static/flags/re.gif",
    icon: "flag-sprite flag-r flag-_e",
  },
  {
    label: "Romania",
    value: "RO",
    flag: "https://hilos.io/static/flags/ro.gif",
    icon: "flag-sprite flag-r flag-_o",
  },
  {
    label: "Russia",
    value: "RU",
    flag: "https://hilos.io/static/flags/ru.gif",
    icon: "flag-sprite flag-r flag-_u",
  },
  {
    label: "Rwanda",
    value: "RW",
    flag: "https://hilos.io/static/flags/rw.gif",
    icon: "flag-sprite flag-r flag-_w",
  },
  {
    label: "Saint Barthélemy",
    value: "BL",
    flag: "https://hilos.io/static/flags/bl.gif",
    icon: "flag-sprite flag-b flag-_l",
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "SH",
    flag: "https://hilos.io/static/flags/sh.gif",
    icon: "flag-sprite flag-s flag-_h",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "KN",
    flag: "https://hilos.io/static/flags/kn.gif",
    icon: "flag-sprite flag-k flag-_n",
  },
  {
    label: "Saint Lucia",
    value: "LC",
    flag: "https://hilos.io/static/flags/lc.gif",
    icon: "flag-sprite flag-l flag-_c",
  },
  {
    label: "Saint Martin (French part)",
    value: "MF",
    flag: "https://hilos.io/static/flags/mf.gif",
    icon: "flag-sprite flag-m flag-_f",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "PM",
    flag: "https://hilos.io/static/flags/pm.gif",
    icon: "flag-sprite flag-p flag-_m",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "VC",
    flag: "https://hilos.io/static/flags/vc.gif",
    icon: "flag-sprite flag-v flag-_c",
  },
  {
    label: "Samoa",
    value: "WS",
    flag: "https://hilos.io/static/flags/ws.gif",
    icon: "flag-sprite flag-w flag-_s",
  },
  {
    label: "San Marino",
    value: "SM",
    flag: "https://hilos.io/static/flags/sm.gif",
    icon: "flag-sprite flag-s flag-_m",
  },
  {
    label: "Sao Tome and Principe",
    value: "ST",
    flag: "https://hilos.io/static/flags/st.gif",
    icon: "flag-sprite flag-s flag-_t",
  },
  {
    label: "Saudi Arabia",
    value: "SA",
    flag: "https://hilos.io/static/flags/sa.gif",
    icon: "flag-sprite flag-s flag-_a",
  },
  {
    label: "Senegal",
    value: "SN",
    flag: "https://hilos.io/static/flags/sn.gif",
    icon: "flag-sprite flag-s flag-_n",
  },
  {
    label: "Serbia",
    value: "RS",
    flag: "https://hilos.io/static/flags/rs.gif",
    icon: "flag-sprite flag-r flag-_s",
  },
  {
    label: "Seychelles",
    value: "SC",
    flag: "https://hilos.io/static/flags/sc.gif",
    icon: "flag-sprite flag-s flag-_c",
  },
  {
    label: "Sierra Leone",
    value: "SL",
    flag: "https://hilos.io/static/flags/sl.gif",
    icon: "flag-sprite flag-s flag-_l",
  },
  {
    label: "Singapore",
    value: "SG",
    flag: "https://hilos.io/static/flags/sg.gif",
    icon: "flag-sprite flag-s flag-_g",
  },
  {
    label: "Sint Maarten (Dutch part)",
    value: "SX",
    flag: "https://hilos.io/static/flags/sx.gif",
    icon: "flag-sprite flag-s flag-_x",
  },
  {
    label: "Slovakia",
    value: "SK",
    flag: "https://hilos.io/static/flags/sk.gif",
    icon: "flag-sprite flag-s flag-_k",
  },
  {
    label: "Slovenia",
    value: "SI",
    flag: "https://hilos.io/static/flags/si.gif",
    icon: "flag-sprite flag-s flag-_i",
  },
  {
    label: "Solomon Islands",
    value: "SB",
    flag: "https://hilos.io/static/flags/sb.gif",
    icon: "flag-sprite flag-s flag-_b",
  },
  {
    label: "Somalia",
    value: "SO",
    flag: "https://hilos.io/static/flags/so.gif",
    icon: "flag-sprite flag-s flag-_o",
  },
  {
    label: "South Africa",
    value: "ZA",
    flag: "https://hilos.io/static/flags/za.gif",
    icon: "flag-sprite flag-z flag-_a",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "GS",
    flag: "https://hilos.io/static/flags/gs.gif",
    icon: "flag-sprite flag-g flag-_s",
  },
  {
    label: "South Korea",
    value: "KR",
    flag: "https://hilos.io/static/flags/kr.gif",
    icon: "flag-sprite flag-k flag-_r",
  },
  {
    label: "South Sudan",
    value: "SS",
    flag: "https://hilos.io/static/flags/ss.gif",
    icon: "flag-sprite flag-s flag-_s",
  },
  {
    label: "Spain",
    value: "ES",
    flag: "https://hilos.io/static/flags/es.gif",
    icon: "flag-sprite flag-e flag-_s",
  },
  {
    label: "Sri Lanka",
    value: "LK",
    flag: "https://hilos.io/static/flags/lk.gif",
    icon: "flag-sprite flag-l flag-_k",
  },
  {
    label: "Sudan",
    value: "SD",
    flag: "https://hilos.io/static/flags/sd.gif",
    icon: "flag-sprite flag-s flag-_d",
  },
  {
    label: "Suriname",
    value: "SR",
    flag: "https://hilos.io/static/flags/sr.gif",
    icon: "flag-sprite flag-s flag-_r",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "SJ",
    flag: "https://hilos.io/static/flags/sj.gif",
    icon: "flag-sprite flag-s flag-_j",
  },
  {
    label: "Sweden",
    value: "SE",
    flag: "https://hilos.io/static/flags/se.gif",
    icon: "flag-sprite flag-s flag-_e",
  },
  {
    label: "Switzerland",
    value: "CH",
    flag: "https://hilos.io/static/flags/ch.gif",
    icon: "flag-sprite flag-c flag-_h",
  },
  {
    label: "Syria",
    value: "SY",
    flag: "https://hilos.io/static/flags/sy.gif",
    icon: "flag-sprite flag-s flag-_y",
  },
  {
    label: "Taiwan",
    value: "TW",
    flag: "https://hilos.io/static/flags/tw.gif",
    icon: "flag-sprite flag-t flag-_w",
  },
  {
    label: "Tajikistan",
    value: "TJ",
    flag: "https://hilos.io/static/flags/tj.gif",
    icon: "flag-sprite flag-t flag-_j",
  },
  {
    label: "Tanzania",
    value: "TZ",
    flag: "https://hilos.io/static/flags/tz.gif",
    icon: "flag-sprite flag-t flag-_z",
  },
  {
    label: "Thailand",
    value: "TH",
    flag: "https://hilos.io/static/flags/th.gif",
    icon: "flag-sprite flag-t flag-_h",
  },
  {
    label: "Timor-Leste",
    value: "TL",
    flag: "https://hilos.io/static/flags/tl.gif",
    icon: "flag-sprite flag-t flag-_l",
  },
  {
    label: "Togo",
    value: "TG",
    flag: "https://hilos.io/static/flags/tg.gif",
    icon: "flag-sprite flag-t flag-_g",
  },
  {
    label: "Tokelau",
    value: "TK",
    flag: "https://hilos.io/static/flags/tk.gif",
    icon: "flag-sprite flag-t flag-_k",
  },
  {
    label: "Tonga",
    value: "TO",
    flag: "https://hilos.io/static/flags/to.gif",
    icon: "flag-sprite flag-t flag-_o",
  },
  {
    label: "Trinidad and Tobago",
    value: "TT",
    flag: "https://hilos.io/static/flags/tt.gif",
    icon: "flag-sprite flag-t flag-_t",
  },
  {
    label: "Tunisia",
    value: "TN",
    flag: "https://hilos.io/static/flags/tn.gif",
    icon: "flag-sprite flag-t flag-_n",
  },
  {
    label: "Turkey",
    value: "TR",
    flag: "https://hilos.io/static/flags/tr.gif",
    icon: "flag-sprite flag-t flag-_r",
  },
  {
    label: "Turkmenistan",
    value: "TM",
    flag: "https://hilos.io/static/flags/tm.gif",
    icon: "flag-sprite flag-t flag-_m",
  },
  {
    label: "Turks and Caicos Islands",
    value: "TC",
    flag: "https://hilos.io/static/flags/tc.gif",
    icon: "flag-sprite flag-t flag-_c",
  },
  {
    label: "Tuvalu",
    value: "TV",
    flag: "https://hilos.io/static/flags/tv.gif",
    icon: "flag-sprite flag-t flag-_v",
  },
  {
    label: "Uganda",
    value: "UG",
    flag: "https://hilos.io/static/flags/ug.gif",
    icon: "flag-sprite flag-u flag-_g",
  },
  {
    label: "Ukraine",
    value: "UA",
    flag: "https://hilos.io/static/flags/ua.gif",
    icon: "flag-sprite flag-u flag-_a",
  },
  {
    label: "United Arab Emirates",
    value: "AE",
    flag: "https://hilos.io/static/flags/ae.gif",
    icon: "flag-sprite flag-a flag-_e",
  },
  {
    label: "United Kingdom",
    value: "GB",
    flag: "https://hilos.io/static/flags/gb.gif",
    icon: "flag-sprite flag-g flag-_b",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "UM",
    flag: "https://hilos.io/static/flags/um.gif",
    icon: "flag-sprite flag-u flag-_m",
  },
  {
    label: "United States of America",
    value: "US",
    flag: "https://hilos.io/static/flags/us.gif",
    icon: "flag-sprite flag-u flag-_s",
  },
  {
    label: "Uruguay",
    value: "UY",
    flag: "https://hilos.io/static/flags/uy.gif",
    icon: "flag-sprite flag-u flag-_y",
  },
  {
    label: "Uzbekistan",
    value: "UZ",
    flag: "https://hilos.io/static/flags/uz.gif",
    icon: "flag-sprite flag-u flag-_z",
  },
  {
    label: "Vanuatu",
    value: "VU",
    flag: "https://hilos.io/static/flags/vu.gif",
    icon: "flag-sprite flag-v flag-_u",
  },
  {
    label: "Venezuela",
    value: "VE",
    flag: "https://hilos.io/static/flags/ve.gif",
    icon: "flag-sprite flag-v flag-_e",
  },
  {
    label: "Vietnam",
    value: "VN",
    flag: "https://hilos.io/static/flags/vn.gif",
    icon: "flag-sprite flag-v flag-_n",
  },
  {
    label: "Virgin Islands (British)",
    value: "VG",
    flag: "https://hilos.io/static/flags/vg.gif",
    icon: "flag-sprite flag-v flag-_g",
  },
  {
    label: "Virgin Islands (U.S.)",
    value: "VI",
    flag: "https://hilos.io/static/flags/vi.gif",
    icon: "flag-sprite flag-v flag-_i",
  },
  {
    label: "Wallis and Futuna",
    value: "WF",
    flag: "https://hilos.io/static/flags/wf.gif",
    icon: "flag-sprite flag-w flag-_f",
  },
  {
    label: "Western Sahara",
    value: "EH",
    flag: "https://hilos.io/static/flags/eh.gif",
    icon: "flag-sprite flag-e flag-_h",
  },
  {
    label: "Yemen",
    value: "YE",
    flag: "https://hilos.io/static/flags/ye.gif",
    icon: "flag-sprite flag-y flag-_e",
  },
  {
    label: "Zambia",
    value: "ZM",
    flag: "https://hilos.io/static/flags/zm.gif",
    icon: "flag-sprite flag-z flag-_m",
  },
  {
    label: "Zimbabwe",
    value: "ZW",
    flag: "https://hilos.io/static/flags/zw.gif",
    icon: "flag-sprite flag-z flag-_w",
  },
];
