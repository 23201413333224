import { useCallback, useMemo, useRef } from "react";
import { STEP_TYPES_WITHOUT_NEXT } from "../constants/steps";
import useFlowBuilderStore from "./useFlowBuilderStore";

interface UseFlowEdgeParams {
  id: string;
  selected?: boolean;
  enterClassName: string;
  leaveClassName: string;
  isUnreachable?: boolean;
  allowMoveStepPath?: boolean;
}

function useFlowEdge({
  id,
  selected,
  enterClassName,
  leaveClassName,
  isUnreachable,
  allowMoveStepPath,
}: UseFlowEdgeParams) {
  const labelRef = useRef<HTMLDivElement | null>();
  const { currentNodeDragging } = useFlowBuilderStore();

  const isValidDropzone = useMemo(() => {
    if (
      isUnreachable ||
      !currentNodeDragging ||
      currentNodeDragging.id === id
    ) {
      return false;
    }

    if (STEP_TYPES_WITHOUT_NEXT.includes(currentNodeDragging.type)) {
      if (!allowMoveStepPath) {
        return false;
      }

      return !currentNodeDragging.nextStepIds.includes(id);
    }

    if (!currentNodeDragging.isMovingPath) {
      return true;
    }

    if (!allowMoveStepPath) {
      return false;
    }

    return !currentNodeDragging.nextStepIds.includes(id);
  }, [id, isUnreachable, allowMoveStepPath, currentNodeDragging]);

  const onMouseEnter = useCallback(() => {
    if (labelRef.current && !isUnreachable) {
      labelRef.current.className = labelRef.current.className.replaceAll(
        leaveClassName,
        enterClassName
      );
    }
  }, [leaveClassName, enterClassName, isUnreachable]);

  const onMouseLeave = useCallback(() => {
    if (labelRef.current && !selected && !isUnreachable) {
      labelRef.current.className = labelRef.current.className.replaceAll(
        enterClassName,
        leaveClassName
      );
    }
  }, [leaveClassName, enterClassName, selected, isUnreachable]);

  return {
    labelRef,
    isValidDropzone,
    onMouseEnter,
    onMouseLeave,
  };
}

export default useFlowEdge;
