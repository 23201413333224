import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useTranslation } from "react-i18next";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ExclamationCircleIcon,
  MicrophoneIcon,
  PauseIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { SendMessageFn } from "@hilos/hooks/useConversationContent";
import { BROWSER_IS_SAFARI } from "src/helpers/utils";
import { API_ROUTES } from "src/router/router";

interface BottomBarMessageAudioProps {
  handleSendMessage: SendMessageFn;
  onClose: () => void;
}

const LiveAudioVisualizer = React.lazy(async () => {
  const { LiveAudioVisualizer } = await import("react-audio-visualize");
  return { default: LiveAudioVisualizer };
});

const AUDIO_TYPE_EXT = BROWSER_IS_SAFARI ? "mp4" : "webm";

async function uploadAudioFile(blob: Blob) {
  try {
    const formData = new FormData();
    const outputName = `audio_${+new Date()}.${AUDIO_TYPE_EXT}`;
    const outputFile = new File([blob], outputName);

    formData.append("content_type", `audio/${AUDIO_TYPE_EXT}`);
    formData.append("uploaded_file", outputFile);

    const { data } = await axios.post(API_ROUTES.PUBLIC_FILE_UPLOAD, formData);
    return data;
  } catch {}
  return null;
}

function BottomBarMessageAudio({
  handleSendMessage,
  onClose,
}: BottomBarMessageAudioProps) {
  const { t } = useTranslation();
  const statusRef = useRef<"recording" | "uploading" | null>(null);

  const [error, setError] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isPaused,
    isRecording,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    () => setError(true),
    { mimeType: `audio/${AUDIO_TYPE_EXT}` }
  );

  const handleCloseRecorder = useCallback(() => {
    setShouldSave(false);
    stopRecording();
    onClose();
  }, [stopRecording, onClose]);

  const handleSendAudio = useCallback(() => {
    setShouldSave(true);
    stopRecording();
  }, [stopRecording]);

  useEffect(() => {
    if (shouldSave) {
      async function uploadRecordingBlob() {
        if (recordingBlob != null && statusRef.current !== "uploading") {
          statusRef.current = "uploading";
          const data = await uploadAudioFile(recordingBlob);

          if (data) {
            handleSendMessage({
              content_url: data.url,
              content_media: data.id,
              content_type: data.content_type,
            });
            onClose();
          }
        }
      }

      uploadRecordingBlob();
    }
  }, [shouldSave, recordingBlob, handleSendMessage, onClose]);

  useEffect(() => {
    if (!statusRef.current) {
      statusRef.current = "recording";
      startRecording();
    }
  }, [startRecording]);

  if (error) {
    return (
      <div className="flex w-full items-center justify-between py-2 space-x-2">
        <ExclamationCircleIcon className="w-6 h-6 shrink-0 text-gray-500" />
        <span className="text-md font-normal text-gray-500 ">
          {t(
            "inbox:audio-recording.missing-permissions",
            "Make sure you have permissions to use the microphone before sending your voice message."
          )}
        </span>
        <button
          type="button"
          onClick={handleCloseRecorder}
          className="flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
        >
          <XIcon className="w-5 h-5" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center p-2 border border-gray-300 bg-white rounded-md">
      <div className="flex justify-between space-x-2 w-full max-w-xs">
        <div className="flex w-full overflow-hidden">
          {mediaRecorder && (
            <Suspense fallback={<div />}>
              <LiveAudioVisualizer
                mediaRecorder={mediaRecorder}
                gap={2}
                height={30}
                width={400}
                barWidth={1}
                barColor={"#f76565"}
                minDecibels={-80}
              />
            </Suspense>
          )}
        </div>
        <div className="flex w-14 justify-end">
          <span className="text-md font-semibold text-gray-500">
            {Math.floor(recordingTime / 60)}:
            {String(recordingTime % 60).padStart(2, "0")}
          </span>
        </div>
      </div>
      <div className="flex space-x-1 items-center justify-between w-full max-w-xs">
        <button
          type="button"
          disabled={!isRecording}
          onClick={handleCloseRecorder}
          className="flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
        >
          <TrashIcon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">
            {t("inbox:audio-recording.discard-changes", "Discard recording")}
          </span>
        </button>
        <button
          type="button"
          disabled={!isRecording}
          onClick={togglePauseResume}
          className="flex h-8 w-8 items-center justify-center rounded-full text-pink-500 hover:text-pink-600 focus:text-pink-600"
        >
          {isPaused ? (
            <MicrophoneIcon className="h-6 w-6" />
          ) : (
            <PauseIcon className="h-8 w-8" />
          )}
        </button>
        <button
          type="button"
          disabled={!isRecording}
          onClick={handleSendAudio}
          className="flex h-8 w-8 items-center justify-center rounded-full bg-indigo-400 hover:bg-indigo-500 text-white focus:outline-none"
        >
          <FontAwesomeIcon
            // @ts-ignore
            icon={faPaperPlane}
            className="h-4 w-4"
          />
        </button>
      </div>
    </div>
  );
}

export default BottomBarMessageAudio;
