import * as Sentry from "@sentry/browser";
import { PostHog } from "posthog-js/react";
import { SessionData } from "@hilos/types/hilos";
import { ContactCustomField } from "@hilos/types/private-schema";
import { massageSteps } from "src/containers/flow/Helpers";

export const CONTACT_EDITABLE_FIELDS = [
  "first_name",
  "last_name",
  "source",
  "email",
  "external_url",
];

export const CONTACT_BASE_FIELDS = CONTACT_EDITABLE_FIELDS.concat([
  "phone",
  "default_conversation_url",
]);

export function getDataFromJSON(data: string) {
  if (!data) {
    return null;
  }
  try {
    return JSON.parse(data);
  } catch {}
  return null;
}

export function hasProp(data: any) {
  return data !== undefined && data !== null;
}

export function hasKeys(data: any) {
  return hasItems(Object.keys(data));
}

export function hasItems<T>(data: T) {
  return Array.isArray(data) && data.length > 0;
}

export function getLength<T>(data: T) {
  return (Array.isArray(data) && data.length) || 0;
}

export const isValidUUID = (value: string) =>
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(
    value
  );

export const getValueJoinedBy = (data: any[]) =>
  data.filter((value) => value !== "" && value !== null).join(".");

export function getContactVariables(customFields: ContactCustomField[] | null) {
  let contactCustomFields: string[] = [];

  if (customFields) {
    contactCustomFields = customFields.map((value) => value.name);
  }

  return Array.from(new Set([...CONTACT_BASE_FIELDS, ...contactCustomFields]));
}

export function getKeyValueArray(data: { [key: string]: any }) {
  try {
    if (!data) {
      return [];
    }

    if (Array.isArray(data)) {
      return data;
    }

    return Object.getOwnPropertyNames(data).map((key) => ({
      key: key,
      value: data[key],
    }));
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
}

export function getFlowData(flow) {
  if (!flow.raw_data) {
    return flow;
  }

  const data = flow.raw_data;
  data.channel = data.channel ? data.channel : flow.channel;

  if (data && hasItems(data.steps)) {
    let nextFlowSteps = [...data.steps];
    for (const index in nextFlowSteps) {
      const prevFlowStep = nextFlowSteps[index];
      if (
        prevFlowStep &&
        ["MENU", "QUESTION", "TEMPLATE"].includes(prevFlowStep.step_type) &&
        (!prevFlowStep.max_wait_time_amount || !prevFlowStep.max_wait_time_unit)
      ) {
        nextFlowSteps[index].max_wait_time_amount = "24";
        nextFlowSteps[index].max_wait_time_unit = "HOUR";
      }
    }
    nextFlowSteps = massageSteps(nextFlowSteps);

    return {
      ...data,
      steps: nextFlowSteps,
    };
  }

  return data;
}

const userAgent = navigator.userAgent;
export const BROWSER_IS_IOS =
  !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
export const BROWSER_IS_WEBKIT = !!userAgent.match(/WebKit/i);
export const BROWSER_IS_SAFARI =
  userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1;
export const BROWSER_IS_SAFARI_IOS =
  BROWSER_IS_IOS && BROWSER_IS_WEBKIT && !userAgent.match(/CriOS/i);

/** This function is useful when the user changes from a template of 3 quick
 * replies to a template of 1 quick reply, for example. In this case, we want
 * to remove the last 2 options from the array but only if the user has not
 * connected a step to those options.
 */
export function removeFalsyValuesFromEndArray(arr?: (string | null)[]) {
  if (!arr) {
    return [];
  }
  while (arr.length > 0 && !arr[arr.length - 1]) {
    arr.pop();
  }
  return arr;
}

export const indexOf = (items, callback) => {
  for (const index in items) {
    if (callback(items[index])) {
      return Number(index);
    }
  }
  return -1;
};

export const isFeatureEnabled = ({
  key,
  posthog,
  session,
}: {
  key: string;
  posthog: PostHog;
  session?: SessionData | null;
}) => {
  if (!session) {
    return false;
  }
  return Boolean(
    session.account.feature_flags.includes(key) || posthog.isFeatureEnabled(key)
  );
};
