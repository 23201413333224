import { useTranslation } from "react-i18next";
import { TriggerTypeEnum } from "@hilos/types/private-schema";
import {
  INTEGRATION_TYPE_TRIGGER,
  MESSAGE_TYPE_TRIGGER,
  OUTBOUND_CAMPAIGN_TYPE_TRIGGER,
  TRIGGER_TYPES,
} from "./builder/constants/triggers";

interface FlowTriggerBadgeProps {
  triggerType: TriggerTypeEnum;
}

export default function FlowTriggerBadge({
  triggerType,
}: FlowTriggerBadgeProps) {
  const { t } = useTranslation();
  const triggerData = TRIGGER_TYPES[triggerType];

  if (MESSAGE_TYPE_TRIGGER.includes(triggerType)) {
    return (
      <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
        <triggerData.icon className="h-4 w-4 mr-1" />
        {t(triggerData.name)}
      </span>
    );
  } else if (INTEGRATION_TYPE_TRIGGER.includes(triggerType)) {
    return (
      <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-xs font-medium uppercase text-blue-800">
        <triggerData.icon className="h-4 w-4 mr-1" />
        {t(triggerData.name)}
      </span>
    );
  } else if (OUTBOUND_CAMPAIGN_TYPE_TRIGGER.includes(triggerType)) {
    return (
      <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium uppercase text-indigo-800">
        <triggerData.icon className="h-4 w-4 mr-1" />
        {t(triggerData.name)}
      </span>
    );
  } else {
    return (
      <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
        {triggerType}
      </span>
    );
  }
}
