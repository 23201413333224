import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Channel } from "@hilos/types/private-schema";
import TechProviderOnboardingConnecting from "./TechProviderOnboardingConnecting";
import TechProviderOnboardingError from "./TechProviderOnboardingError";
import TechProviderOnboardingSuccess from "./TechProviderOnboardingSuccess";

interface TechProviderConnectingModalProps {
  connectionStatus: string;
  error?: string;
  channel?: Channel;
  show: boolean;
  handleClose: () => void;
}

export default function TechProviderConnectingModal({
  connectionStatus,
  error,
  channel,
  show,
  handleClose,
}: TechProviderConnectingModalProps) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                {connectionStatus === "CONNECTING" && (
                  <TechProviderOnboardingConnecting />
                )}
                {connectionStatus === "ERROR" && error && (
                  <TechProviderOnboardingError
                    error={error}
                    handleClose={handleClose}
                  />
                )}
                {connectionStatus === "SUCCESS" && (
                  <TechProviderOnboardingSuccess channel={channel} />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
