import i18n from "src/i18n";

interface HelpLinkProps {
  href: string;
  label?: string;
  target?: string;
  className?: string;
}
export default function HelpDocsLink({
  href,
  label = i18n.t("need-help", "Need Help?"),
  target = "_blank",
  className,
}: HelpLinkProps) {
  return (
    <a
      href={href}
      target={target}
      rel="noreferrer"
      className={` inline-flex items-center text-blue-500 ${className}`}
    >
      <span>{label}</span>
    </a>
  );
}
