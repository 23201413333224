export default function ChannelStatusBadge({ status }) {
  switch (status) {
    case "NEW":
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-xs font-medium uppercase text-gray-800">
          Pending
        </span>
      );
    case "ACTIVE":
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
          {status}
        </span>
      );
    case "INACTIVE":
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-xs font-medium uppercase text-red-800">
          {status}
        </span>
      );
    default:
      return null;
  }
}
