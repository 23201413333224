import { EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from "reactflow";
import { classNames } from "src/Helpers";
import useDropZone from "../../hooks/useDropZone";
import useFlowEdge from "../../hooks/useFlowEdge";

function StepEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  selected,
  source,
}: EdgeProps) {
  const { labelRef, isValidDropzone, onMouseEnter, onMouseLeave } = useFlowEdge(
    {
      id: source,
      selected,
      enterClassName: "block",
      leaveClassName: "hidden",
    }
  );
  const { isDropzoneActive, onDrop, onDragOver, onDragEnter, onDragLeave } =
    useDropZone({ disabled: !isValidDropzone });
  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <g
      id={id}
      data-type="step_edge"
      className={classNames(
        "group block",
        !selected && !isDropzoneActive && "opacity-60 group-hover:opacity-100"
      )}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <path
        id={id}
        fill="none"
        style={style}
        className={classNames(
          "stroke-2",
          isDropzoneActive
            ? isValidDropzone
              ? "stroke-indigo-600"
              : "stroke-red-600"
            : selected
            ? "stroke-indigo-600"
            : "stroke-gray-400 group-hover:stroke-indigo-600"
        )}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        fill="none"
        className="stroke-transparent stroke-[40px]"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          ref={(ref) => {
            labelRef.current = ref;
          }}
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${edgeCenterX}px,${edgeCenterY}px)`,
          }}
          className={selected || isDropzoneActive ? "block" : "hidden"}
        >
          <div
            className={classNames(
              "flex h-5 w-5 items-center justify-center rounded border-2 bg-white",
              isDropzoneActive
                ? isValidDropzone
                  ? "border-indigo-600"
                  : "border-red-600"
                : "border-indigo-600"
            )}
          >
            <span
              className={classNames(
                "-mt-[2px] text-center align-middle font-bold",
                isDropzoneActive
                  ? isValidDropzone
                    ? "text-indigo-600"
                    : "text-red-600"
                  : "text-indigo-600"
              )}
            >
              +
            </span>
          </div>
        </div>
      </EdgeLabelRenderer>
    </g>
  );
}

export default StepEdge;
