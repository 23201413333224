import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { debounce } from "lodash";
import HubspotInstallComponent from "src/containers/integrations/Hubspot/HubspotInstallComponent";
import { ALL_SCOPES } from "src/containers/integrations/Hubspot/HubspotMeta";
import HubspotSettings from "src/containers/integrations/Hubspot/HubspotSettings";
import HubspotSync from "src/containers/integrations/Hubspot/HubspotSync";
import Loading from "src/components/Loading";
import useHubspotApp, {
  useHubspotAppUpdateDetails,
} from "src/hooks/useHubSpot";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES, buildRoute } from "src/router/router";
import HeaderNavigation from "../../../components/HeaderNavigation";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function HubspotOauth() {
  const { t } = useTranslation();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [reconnectRequired, setReconnectRequired] = useState(false);

  const [exchange_error, setExchangeError] = useState<unknown | undefined>(
    undefined
  );
  const location = useLocation();
  const forceConnectURL = location.pathname + "?connect=true";
  const { data: connectedApp } = useHubspotApp();
  const { handleUpdateFlowExecutionStatus } = useHubspotAppUpdateDetails();

  async function startHubspotOauthFlow() {
    setLoading(true);
    try {
      const responseHubspotState = await axios.get(
        API_ROUTES.EXTERNAL_APP_OAUTH,
        {
          withCredentials: true,
          params: {
            app_type: "HubSpot",
          },
        }
      );
      window.location.assign(responseHubspotState.data.redirect_url);
    } catch (err) {
      Sentry.captureException(err);
      setLoading(false);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const maybe_exchange_token_or_load_hubspot_info = useCallback(
    debounce(async () => {
      try {
        const forceReconnect = query.get("connect") === "true";
        const hasNotBeenRedirectedFromHubspot = !query.get("code");
        // Validate and start the redirection process to HubSpot
        if (hasNotBeenRedirectedFromHubspot) {
          if (forceReconnect) {
            await startHubspotOauthFlow();
            return;
          }
          return;
        }
        setLoading(true);
        // Exchange the token in the backend
        const responseHubspotOauth = await axios.post(
          API_ROUTES.EXTERNAL_APP_OAUTH,
          {
            code: query.get("code"),
            app_type: "HubSpot",
            state: query.get("state"),
          },
          { withCredentials: true }
        );
        window.history.replaceState({}, "", location.pathname);
        queryClient.setQueryData("hubspot_app", responseHubspotOauth.data);
        setConnected(true);
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        setExchangeError(err);
        setLoading(false);
      }
    }, 250),
    [query, location.pathname]
  );

  useEffect(() => {
    maybe_exchange_token_or_load_hubspot_info();
  }, [maybe_exchange_token_or_load_hubspot_info]);

  useEffect(() => {
    if (connectedApp) {
      setLoading(false);
      setConnected(connectedApp.is_connected);
      const currentScopesSet = new Set(connectedApp.scopes);
      const missingScopes = new Set(
        [...ALL_SCOPES].filter((scope) => !currentScopesSet.has(scope))
      );
      setReconnectRequired(missingScopes.size > 0);
    }
  }, [connectedApp]);

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t(
                "integrations:external-apps.header-navigation-title",
                "External apps"
              ),
              url: buildRoute("external-apps"),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              {connected ? (
                <>
                  <img
                    className="h-12"
                    src="/images/integrations/hubspot.svg"
                    alt={`HubSpot Icon`}
                  />
                </>
              ) : (
                <>
                  <PlusIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                  {t("integrations:hubspot.connect", "Connect to HubSpot")}
                </>
              )}
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              <Trans i18nKey="integrations:error-logs">
                Any errors we find while syncing data will appear on the{" "}
                <Link
                  to={buildRoute("dev-monitoring")}
                  className="text-blue-600 hover:text-blue-400"
                >
                  Monitoring {">"} Event Logs page
                </Link>
                .
              </Trans>
            </p>
          </div>
        </div>
      </div>

      <div className="mx-auto mt-6 max-w-7xl px-4 sm:px-6 lg:px-8">
        <div>
          {loading ? (
            <Loading />
          ) : (
            <>
              {reconnectRequired && (
                <div className="rounded-md bg-yellow-50 p-4" role="alert">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <Trans i18nKey="integrations:hubspot.reconnect">
                        <h3 className="text-sm font-medium text-yellow-800">
                          Attention needed - HubSpot app re-connect required
                        </h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            We have updated our HubSpot integration and need new
                            permissions scopes to work correctly,{" "}
                            <Link
                              to={forceConnectURL}
                              className="text-blue-700 hover:text-blue-400"
                            >
                              click here to re-connect the app.{" "}
                            </Link>
                          </p>
                        </div>
                      </Trans>
                    </div>
                  </div>
                </div>
              )}
              {connectedApp && connected ? (
                <>
                  {connectedApp.initial_sync_status !== "SYNCED" && (
                    <HubspotSync
                      connectedApp={connectedApp}
                      setLoading={setLoading}
                      disabled={reconnectRequired}
                    />
                  )}

                  <HubspotSettings
                    connectedApp={connectedApp}
                    onUpdateHubspotDetails={handleUpdateFlowExecutionStatus}
                    setLoading={setLoading}
                    disabled={reconnectRequired}
                  />
                </>
              ) : (
                <HubspotInstallComponent
                  exchange_error={exchange_error}
                  forceConnectURL={forceConnectURL}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
