import InfiniteScroll from "react-infinite-scroll-component";
import { FetchNextPageOptions } from "react-query";
import { ChatAltIcon } from "@heroicons/react/outline";
import { ConversationContentSearchItemData } from "@hilos/hooks/useConversationContentSearch";
import { CursorPageData } from "@hilos/types/hilos";
import { ConversationContent } from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import { hasItems } from "src/helpers/utils";
import ConversationContentSearchItem from "./ConversationContentSearchItem";

interface ConversationContentSearchResultsProps {
  pages?: CursorPageData<ConversationContentSearchItemData>[];
  hasNextPage?: boolean;
  focusedConversationContentId: string | null;
  handleNextPage: (params?: FetchNextPageOptions) => void;
  onFocusConversationContent: (data: ConversationContent) => void;
}

function ConversationContentSearchResults({
  pages,
  hasNextPage,
  focusedConversationContentId,
  handleNextPage,
  onFocusConversationContent,
}: ConversationContentSearchResultsProps) {
  if (!pages) {
    return null;
  }

  if (!hasItems(pages) || !hasItems(pages[0].results)) {
    return (
      <div className="py-5 text-center">
        <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          No messages found.
        </h3>
      </div>
    );
  }

  return (
    <div
      id="search_messages"
      className="h-full divide-y divide-gray-200 overflow-y-auto"
    >
      <InfiniteScroll
        dataLength={pages.length}
        next={handleNextPage}
        hasMore={hasNextPage ?? false}
        scrollableTarget="search_messages"
        loader={<Loading />}
      >
        <>
          {pages.map(
            (page, pageIndex) =>
              page &&
              hasItems(page.results) &&
              page.results.map(({ content, startIndex, endIndex }, index) => (
                <ConversationContentSearchItem
                  key={`${content.id}_${pageIndex}_${index}`}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  conversationContent={content}
                  focusedConversationContentId={focusedConversationContentId}
                  onFocusConversationContent={onFocusConversationContent}
                />
              ))
          )}
        </>
      </InfiniteScroll>
    </div>
  );
}

export default ConversationContentSearchResults;
