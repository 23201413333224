import { useCallback } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useField, useFormikContext } from "formik";
import { classNames } from "../../Helpers";
import AddVariableForm from "../../containers/inbox/Conversation/AddVariableForm";
import FieldContainer from "./FieldContainer";

interface TextInputVariablesFieldProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "action"> {
  name: string;
  help?: string;
  optional?: boolean;
  icon?: (props: React.ComponentProps<"svg">) => React.ReactElement;
}

function TextInputVariablesField({
  name,
  label,
  rows,
  help,
  optional,
  icon: RenderIcon,
  ...props
}: TextInputVariablesFieldProps) {
  const [field, meta] = useField({ name });
  const { setFieldValue } = useFormikContext();
  const error = meta.touched && meta.error;

  const handleAddVariable = useCallback(
    (variable) => {
      const prevValue = field.value;
      let nextValueWithVariable = "";

      if (prevValue) {
        let separator = "";

        if (prevValue[prevValue.length - 1] !== " ") {
          separator = " ";
        }

        nextValueWithVariable = `${prevValue}${separator}${variable}`;
      }

      setFieldValue(field.name, nextValueWithVariable || variable);
    },
    [field, setFieldValue]
  );

  return (
    <div>
      <FieldContainer
        name={name}
        label={label}
        help={help}
        optional={optional}
        error={error}
      >
        <div className={classNames(label && "mt-1", "flex")}>
          <div
            className={classNames(
              "relative flex flex-grow items-stretch rounded-md border border-gray-300 p-2 focus-within:z-10",
              "focus-within:border-indigo-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500",
              error &&
                "border-red-500 focus-within:border-red-500 focus-within:ring-red-500"
            )}
          >
            {RenderIcon && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <RenderIcon className="h-5 w-5 text-gray-500" />
              </div>
            )}
            <input
              className={classNames(
                "w-full rounded-lg border-none border-gray-300 placeholder-gray-400 sm:text-sm",
                "focus:border-0 focus:outline-none focus:ring-0 sm:text-sm",
                "disabled:bg-gray-50 disabled:text-gray-300 disabled:placeholder-gray-200",
                RenderIcon && "pl-10",
                error && "pr-10"
              )}
              id={name}
              {...field}
              {...props}
            />
            {error && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-start pr-3 pt-2">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </div>
      </FieldContainer>
      <AddVariableForm onAddVariable={handleAddVariable} />
    </div>
  );
}

export default TextInputVariablesField;
