import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { TrashIcon } from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import axios from "axios";
import { UploadedFile } from "@hilos/types/uploaded_file";
import { DropzoneStyles } from "src/components/base/Form";
import { hasItems } from "src/helpers/utils";
import {
  AUDIO_MAX_SIZE,
  AUDIO_MIME_TYPES,
  DOCUMENT_MAX_SIZE,
  DOCUMENT_MIME_TYPES,
  IMAGE_MAX_SIZE,
  IMAGE_MIME_TYPES,
  STICKER_MAX_SIZE,
  STICKER_MIME_TYPES,
  VIDEO_MAX_SIZE,
  VIDEO_MIME_TYPES,
} from "src/hooks/useMediaDropzone";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { UPLOAD_MEDIA_FILE_TYPES } from "../constants/flow";

interface FlowBuilderMediaFileUploadProps {
  fileUploadId?: string;
  media?: "IMAGE" | "VIDEO" | "DOCUMENT";
  onDelete: () => void;
  onFileUploaded: (file: UploadedFile) => void;
}

async function fetchUploadedFile({ queryKey }) {
  if (!queryKey[1]) {
    return null;
  }

  const { data } = await axios.get(
    buildAPIRoute(API_ROUTES.PUBLIC_FILE_DETAIL, { ":id": queryKey[1] })
  );

  return data;
}

function FlowBuilderMediaFileUpload({
  media = "DOCUMENT",
  fileUploadId,
  onDelete,
  onFileUploaded,
}: FlowBuilderMediaFileUploadProps) {
  const [t] = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);

  const { data: file } = useQuery<UploadedFile | null>(
    ["FILE_UPLOADED_MEDIA", fileUploadId],
    fetchUploadedFile
  );

  const {
    label,
    help,
    accept,
    icon: RenderIcon,
  } = useMemo(() => UPLOAD_MEDIA_FILE_TYPES[media], [media]);

  const handleOnDrop = useCallback(
    async (acceptedFiles: File[]) => {
      for (const file of acceptedFiles) {
        const formData = new FormData();
        formData.append("uploaded_file", file);

        setUploading(true);
        try {
          const { data } = await axios.post(
            API_ROUTES.PUBLIC_FILE_UPLOAD,
            formData
          );

          onFileUploaded(data);
        } catch (e) {
          setFileUploadError(true);
          captureException(e);
        } finally {
          setUploading(false);
        }
      }
    },
    [onFileUploaded]
  );

  const handleDeleteFile = useCallback(async () => {
    onDelete();
    if (!fileUploadId) {
      return;
    }
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.PUBLIC_FILE_DETAIL, {
          ":id": fileUploadId,
        })
      );
    } catch (e) {
      captureException(e);
    }
  }, [fileUploadId, onDelete]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    fileRejections,
  } = useDropzone({
    accept,
    validator: (file) => {
      if (
        (AUDIO_MIME_TYPES.includes(file.type) && file.size >= AUDIO_MAX_SIZE) ||
        (DOCUMENT_MIME_TYPES.includes(file.type) &&
          file.size >= DOCUMENT_MAX_SIZE) ||
        (IMAGE_MIME_TYPES.includes(file.type) && file.size >= IMAGE_MAX_SIZE) ||
        (VIDEO_MIME_TYPES.includes(file.type) && file.size >= VIDEO_MAX_SIZE) ||
        (STICKER_MIME_TYPES.includes(file.type) &&
          file.size >= STICKER_MAX_SIZE)
      ) {
        return {
          message: "File exceeds the allowed size",
          code: "file-too-large",
        };
      }
      return null;
    },
    onDrop: handleOnDrop,
    multiple: false,
    maxFiles: 1,
  });

  const errors = useMemo(() => {
    if (fileUploadError) {
      return [
        t("flows:media.file-upload-error", "File upload failed, try again."),
      ];
    }

    const nextErrors: string[] = [];

    if (hasItems(fileRejections)) {
      for (const fileRejection of fileRejections) {
        if (hasItems(fileRejection.errors)) {
          for (const fileError of fileRejection.errors) {
            switch (fileError.code) {
              case "file-invalid-type":
                nextErrors.push(
                  t("flows:media.file-invalid-type", {
                    name: fileRejection.file.name,
                    defaultValue: "{{name}} is not a valid file type.",
                  })
                );
                break;
              case "file-too-large":
                nextErrors.push(
                  t(
                    "flows:media.file-too-large",
                    "Please upload a smaller file no larger than 15MB."
                  )
                );
                break;
              case "too-many-files":
                return [
                  t(
                    "flows:media.too-many-files",
                    "Select only one file to upload."
                  ),
                ];
              default:
                break;
            }
          }
        }
      }
    }

    return nextErrors;
  }, [t, fileUploadError, fileRejections]);

  const style = useMemo(
    () => ({
      ...DropzoneStyles.baseStyle,
      ...(isDragActive ? DropzoneStyles.activeStyle : {}),
      ...(isDragAccept ? DropzoneStyles.acceptStyle : {}),
    }),
    [isDragActive, isDragAccept]
  );

  return (
    <div>
      {file ? (
        <div className="flex items-stretch rounded-lg border border-gray-200 p-3 shadow-sm">
          {media === "IMAGE" && (
            <img
              alt={file.original_name || ""}
              src={file.url}
              className="mr-2 w-14 self-center"
            />
          )}
          {media === "VIDEO" && (
            <video loop className="mr-2 w-20 self-center">
              <source src={file.url} type="video/mp4" />
              {t(
                "flows:media.embedded-video-not-supported",
                "Sorry, your browser doesn't support embedded videos."
              )}
            </video>
          )}

          <div className="grow self-center text-sm truncate">
            {file.original_name}
          </div>
          <div className="self-center text-right">
            <button
              className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              type="button"
              onClick={handleDeleteFile}
            >
              <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />
              {t("remove", "Remove")}
            </button>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps({
            className: "dropzone",
            // @ts-ignore
            style,
          })}
        >
          <input {...getInputProps()} />
          <div className="mb-0 flex flex-col items-center pb-0">
            <RenderIcon className="h-10 w-10" aria-hidden="true" />
            {uploading ? (
              <span className="ml-3">
                {t("flows:media.uploading-file", "Uploading file...")}
              </span>
            ) : (
              <div className="ml-3">
                <div>{t(label)}</div>
                <div className="text-xs text-gray-500">{t(help)}</div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mt-1.5 space-y-1">
        {errors.map((error) => (
          <p className="text-xs text-red-500">{error}</p>
        ))}
      </div>
    </div>
  );
}

export default FlowBuilderMediaFileUpload;
