import { useTranslation } from "react-i18next";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { FlowExecutionStatusPayload } from "@hilos/types/flows/FlowExecution";
import { FlowExecutionStatusEnum } from "@hilos/types/private-schema";
import StateButton from "src/components/StateButton";
import { useCreateStateButtonsHook } from "src/hooks/useFlowExecutionStatus";

interface FlowExecutionControlsButtonsProps {
  flowExecutionId: string;
  flowExecutionStatus?: FlowExecutionStatusEnum;
  onFlowExecutionStatusUpdateSuccess: (
    nextStatus: FlowExecutionStatusPayload
  ) => void;
}

export default function FlowExecutionControlsButtons({
  flowExecutionId,
  flowExecutionStatus,
  onFlowExecutionStatusUpdateSuccess = () => null,
}: FlowExecutionControlsButtonsProps) {
  const { t } = useTranslation();
  const {
    cancelState,
    confirmState,
    handleCancel,
    handleConfirm,
    stateButtonBtnClasses,
  } = useCreateStateButtonsHook({
    flowExecutionId,
    flowExecutionStatus,
    onFlowExecutionStatusUpdateSuccess,
  });

  if (!flowExecutionStatus) {
    return null;
  }
  return (
    <>
      {flowExecutionStatus === "AWAITING_CONFIRMATION" && (
        <>
          <StateButton
            btnClasses="focus:ring-green-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-yellow-700 shadow-sm"
            {...stateButtonBtnClasses}
            submittingText={t("confirming", "Confirming...")}
            successText={t("confirmed", "Confirmed")}
            initialText={
              <>
                <CheckIcon
                  className="-ml-0.5 mr-2 h-5 w-5"
                  aria-hidden="true"
                />{" "}
                {t("confirm", "Confirm")}
              </>
            }
            onClick={handleConfirm}
            isSubmitting={confirmState.isSubmitting}
            submitted={confirmState.submitted}
            success={confirmState.success}
          />
          <StateButton
            btnClasses="focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-red-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-700 shadow-sm"
            {...stateButtonBtnClasses}
            submittingText={t("canceling", "Canceling...")}
            successText={t("canceled", "Canceled")}
            initialText={
              <>
                <XIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />{" "}
                {t("cancel", "Cancel")}
              </>
            }
            onClick={handleCancel}
            isSubmitting={cancelState.isSubmitting}
            submitted={cancelState.submitted}
            success={cancelState.success}
          />
        </>
      )}
      {!["CANCELED", "COMPLETED", "AWAITING_CONFIRMATION"].includes(
        flowExecutionStatus
      ) && (
        <>
          {!cancelState.disabled && (
            <StateButton
              btnClasses={`focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-red-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-700 shadow-sm ${
                cancelState.disabled
                  ? "disabled:opacity-50"
                  : "cursor-pointer hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-100"
              }`}
              {...stateButtonBtnClasses}
              submittingText={t("canceling", "Canceling...")}
              successText={t("canceled", "Canceled")}
              initialText={
                <>
                  <XIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />{" "}
                  {t("cancel", "Cancel")}
                </>
              }
              onClick={handleCancel}
              isSubmitting={cancelState.isSubmitting}
              submitted={cancelState.submitted}
              success={cancelState.success}
            />
          )}
        </>
      )}
    </>
  );
}
