import { CalculateQueryParamsParams, INITIAL_PARAMS } from "./FiltersMeta";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ChartSquareBarIcon } from "@heroicons/react/outline";
import HeaderNavigation from "src/components/HeaderNavigation";
import InboxByAgent from "./by-agent/InboxByAgent";
import InboxByTag from "./by-tag/InboxByTag";
import InboxDetail from "./detail/InboxDetail";
import InboxSummary from "./summary/InboxSummary";
import OnboardingModuleModal from "src/containers/onboarding_module/OnboardingModuleModal";
import ReportFiltersModal from "./FiltersModal";
import ReportMetricsModal from "./ReportMetricsModal";
import ReportsDocsNotice from "./ReportsDocsNotice";
import { buildRoute } from "src/router/router";
import { format } from "date-fns";
import { getTranslationPayload as t } from "src/i18n";
import useHilosStore from "src/hooks/useHilosStore";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const REPORT_TYPES = {
  summary: {
    title: t("reports:summary.title", "Conversation Reports (Summary)"),
    description: t(
      "reports:summary.description",
      "See how many contacts and conversations your team is reaching, grouped by date."
    ),
    component: InboxSummary,
  },
  "by-agent": {
    title: t("reports:by-agent.title"),
    description: t("reports:by-agent.description"),
    component: InboxByAgent,
  },
  "by-tag": {
    title: t("reports:by-tag.title"),
    description: t("reports:by-tag.description"),
    component: InboxByTag,
  },
  detail: {
    title: t("reports:detail.title"),
    description: t("reports:detail.description"),
    component: InboxDetail,
  },
};

export default function ReportsBase() {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] =
    useState<CalculateQueryParamsParams>(INITIAL_PARAMS);
  const params = useParams();
  const currentReport = params.type as string;
  const { session, updateSession } = useHilosStore();
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

  const Component = useMemo(() => {
    return REPORT_TYPES[currentReport].component;
  }, [currentReport]);

  const handleCloseHelpModal = useCallback(() => {
    if (!session?.visited_modules.includes("reports")) {
      updateSession({
        visited_modules: [...(session?.visited_modules || []), "reports"],
      });
    }
    setShowHelpModal(false);
  }, []);

  useEffect(() => {
    setShowHelpModal(
      session ? !session.visited_modules.includes("reports") : false
    );
  }, []);

  return (
    <div className="h-full bg-gray-50">
      <div className="overflow-y-auto">
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t(REPORT_TYPES[currentReport].title),
                url: buildRoute("reports", { ":type": currentReport }),
              },
            ]}
          />

          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ChartSquareBarIcon
                  className="mr-2 h-7 w-7"
                  aria-hidden="true"
                />
                {t(REPORT_TYPES[currentReport].title)}
              </h2>
              <p className="mt-1 text-sm text-gray-600">
                {t(REPORT_TYPES[currentReport].description)}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-gray-50">
          <div className="sm:px-6 sm:pt-4 lg:px-8">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t("overview")}{" "}
                <span className="text-sm font-normal text-gray-500">
                  {queryParams.dateRange.to && queryParams.dateRange.from && (
                    <>
                      ({format(queryParams.dateRange.from, "d MMM/yy")} -{" "}
                      {format(queryParams.dateRange.to, "d MMM/yy")})
                    </>
                  )}
                </span>
              </h3>
              <div className="flex items-center space-x-2">
                <ReportMetricsModal />
                <ReportFiltersModal
                  params={queryParams}
                  setParams={setQueryParams}
                />
              </div>
            </div>

            <ReportsDocsNotice />
            <Component params={queryParams} />
          </div>
        </div>
      </div>
      <OnboardingModuleModal
        routeName="reports"
        show={showHelpModal}
        handleClose={handleCloseHelpModal}
      />
    </div>
  );
}
