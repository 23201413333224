import { useCallback } from "react";
import { UseMutateAsyncFunction, useMutation, useQuery } from "react-query";
import axios from "axios";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES } from "src/router/router";
import { CrawlerSettings } from "@hilos/types/private-schema";

export type UpdateCrawlerSettingsDetailsFn = UseMutateAsyncFunction<
  CrawlerSettings | null,
  unknown,
  CrawlerSettings,
  unknown
>;

export function useCrawlerSettings() {
  const fetchCrawlerSettings = useCallback(async () => {
    const { data } = await axios.get<CrawlerSettings>(API_ROUTES.CRAWL_SETTINGS);
    return data;
  }, []);

  const { data, refetch, isLoading } = useQuery("crawler_settings", fetchCrawlerSettings);

  return { data, refetch, isLoading };
}

export default useCrawlerSettings;

export function useCrawlerSettingsUpdate() {
  const updateCrawlerSettingsAppDetails = useCallback(
    async (payload: CrawlerSettings ) => {
      const { data } = await axios.patch<CrawlerSettings>(
        API_ROUTES.CRAWL_SETTINGS,
        payload
      );
      return data;
    },
    []
  );

  const {
    mutateAsync: handleUpdateCrawlerSettings,
    isLoading: isSubmittingStatus,
  } = useMutation(updateCrawlerSettingsAppDetails, {
    onSuccess: (nextData) => {
      let currentAppData = queryClient.getQueryData<CrawlerSettings>("crawler_settings");
      if (currentAppData) {
        currentAppData = {
          ...currentAppData,
          ...nextData,
        };
      }
      queryClient.setQueryData("crawler_settings", currentAppData);
    },
  });

  return {
    handleUpdateCrawlerSettings,
    isSubmittingStatus,
  };
}
